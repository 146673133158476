import React, { useState, useEffect, useRef  }  from 'react';
import { BrowserRouter, Redirect, Route, Switch, Link, NavLink,
    useParams,
    useRouteMatch } from 'react-router-dom';

import reward from '../../images/auction/modal-success.png';
import rewardw from '../../images/auction/modal-success.png';




const ModalSuccess = (props) => {
    
    return (<>

    <div className="reward-modal">
        <div className="reward-modal-in">
            <div className="reward-modal-body">
                <div className="fire-left"></div>
                <div className="fire-right"></div>
                <div className="reward-modal-tit">
                    <picture>
                        <source srcSet={rewardw} type="image/webp" />
                        <source srcSet={reward} type="image/png" /> 
                        <img className="reward-img" src={rewardw} alt="" />
                    </picture>
                </div>
                <div className="reward-modal-card">
                    
                    <picture>
                        <source srcSet={`../images/${props.types == "weapon" ? "weapons" : "armor" }/all/${props.tmpl}.png`} type="image/webp" />
                        <source srcSet={`../images/${props.types == "weapon" ? "weapons" : "armor" }/all/${props.tmpl}.png`} type="image/png" /> 
                        <img src={`../images/${props.types == "weapon" ? "weapons" : "armor" }/all/${props.tmpl}.png`} alt="" />
                    </picture>
                    
                </div>
                
                
                <div className="reward-modal-close">
                    <button className="def-bt mid" onClick={() => props.closeModalPrise("close")}>
                        <span className="button-in">
                            <span className="button-name">
                                <span className="button-name-in">
                                    <span className="button-name-value">
                                        close
                                    </span> 
                                </span>
                            </span>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>        
    </>);
}

export default ModalSuccess;