import React, { useState, useEffect, useRef  }  from 'react';
import { BrowserRouter, Redirect, Route, Switch, Link, NavLink,
    useParams,
    useRouteMatch } from 'react-router-dom';

    import infoTop from '../../images/auction/modal-info-top.png';
    import infoTopw from '../../images/auction/modal-info-top.png';
    import infoBot from '../../images/auction/modal-info-bot.png';
    import infoBotw from '../../images/auction/modal-info-bot.png';




const ModalInfo = (props) => {
    
    return (<>

        <div className="info-modal">
            <div className="info-modal-in">
                <div className="info-modal-body">
                    <div className="info-modal-top">
                        <picture>
                            <source srcSet={infoTopw} type="image/webp" />
                            <source srcSet={infoTop} type="image/png" /> 
                            <img src={infoTopw} alt="" />
                        </picture>
                        <span>
                            information
                        </span>
                    </div>
                    <div className="info-modal-wrap">
                        <div className="info-modal-wrap-in">
                            <div className="info-modal-descr">
                                <div className="info-modal-descr-in" dangerouslySetInnerHTML={{__html: props.textInfo}}>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="info-modal-bottom">
                        <picture>
                            <source srcSet={infoBotw} type="image/webp" />
                            <source srcSet={infoBot} type="image/png" /> 
                            <img src={infoBotw} alt="" />
                        </picture>
                        <div className="info-modal-close">
                            <button className="def-bt mid" onClick={() => props.closeModalInfo(false)}>
                                <span className="button-in">
                                    <span className="button-name">
                                        <span className="button-name-in">
                                            <span className="button-name-value">
                                                close
                                            </span> 
                                        </span>
                                    </span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>    

    </>);
}

export default ModalInfo;