import React from 'react';
import modaltop from '../../../images/shop/modal-top.png';
import modalbot from '../../../images/shop/modal-bot.png';

const skillArreyNumer = [
    "elder",
    "healer",
    "priest",
    "dreneya",
    "druid",
    "mage",
    "tauren",
    "smith",
    "engineer",
    "berserker",
    "bard",
    "shooter",
    "archer",
    "warrior",
    "paladin"
]

// crystal, tlm, mini potion, bigpotion, sharpering
const alienResArreyNumer = [
    "",
    "",
    "small-space-power-potion",
    "space-power-potion",
    "space-sharpening-potion",
]

const Modal = (props) => {

    var activeHaveInvent = false;
    if (props.modal.item.type != "weapon" && props.modal.item.type != "book" && props.modal.item.type != "potion") {
        var countHaveItem;
        if (props.modal.item.rare == 'common' && props.inventareat.style.common > 0) {
            activeHaveInvent = true;
            countHaveItem = props.inventareat.style.common;
        } else if (props.modal.item.rare == 'uncommon' && props.inventareat.style.uncommon > 0) {
            activeHaveInvent = true;
            countHaveItem = props.inventareat.style.uncommon;
        } else if (props.modal.item.rare == 'rare' && props.inventareat.style.rare > 0) {
            activeHaveInvent = true;
            countHaveItem = props.inventareat.style.rare;
        } else if (props.modal.item.rare == 'epic' && props.inventareat.style.epic > 0) {
            activeHaveInvent = true;
            countHaveItem = props.inventareat.style.epic;
        } else if (props.modal.item.rare == 'legendary' && props.inventareat.style.legendary > 0) {
            activeHaveInvent = true;
            countHaveItem = props.inventareat.style.legendary;
        } else if (props.modal.item.rare == 'mythic' && props.inventareat.style.mythic > 0) {
            activeHaveInvent = true;
            countHaveItem = props.inventareat.style.mythic;
        }
    }

    var manyRes = 0;
    var getIndexClass = 0;

    //console.log("props", props);
    if (props.modal.item.type === "book") {
        getIndexClass = skillArreyNumer.indexOf(props.modal.item.img);
        manyRes = props.resInGame && props.resInGame.books !== undefined && props.resInGame.books.length ? props.resInGame.books[getIndexClass] : 0;
    } else if (props.modal.item.type === "potion") {
        //allAlienRes
        getIndexClass = alienResArreyNumer.indexOf(props.modal.item.img);

        manyRes = props.allAlienRes?.res?.[getIndexClass] ?? 0;
    }

    console.log("getIndexClass", getIndexClass);
    console.log("manyRes", manyRes);


    return (
        <>

            <div className={`modal-bay ${props.modal.showModalStatus ? "open" : null} ${props.modal.showAnimModal ? " anim" : null} ${props.modal.item.type === "potion" ? "pot" : null}`}>
                <div className="modal-bay-body">
                    <div className="modal-bay-body-in">

                        <div className="modal-bay-head">

                            <div className="modal-bay-top">
                                <button className="modal-bay-close" onClick={() => props.showCloseModal('close')}><span></span></button>
                                <img src={modaltop} alt="" />
                            </div>
                            <div className="modal-bay-wrap">
                                <div className={`have-item tooltip ${activeHaveInvent || props.weaponCount || manyRes ? 'active' : ''}`}>
                                    <div className="cust-tooltip bottom">
                                        <div className="tool-arrow"></div>
                                        <div className="tool-wrap">
                                            <div className="tool-wrap-1">
                                                <div className="tool-wrap-2">
                                                    {props.modal.item.type === "weapon" ?
                                                        <div className="tool-body">
                                                            {props.weaponCount ? `You already have ${props.weaponCount} of this item` : `You don't have this item yet`}
                                                        </div>
                                                        :
                                                        props.modal.item.type === "book" ?
                                                            <div className="tool-body">
                                                                {manyRes ? `You already have ${manyRes} of this item` : `You don't have this item yet`}
                                                            </div>
                                                            :
                                                            props.modal.item.type === "potion" ?
                                                                <div className="tool-body">
                                                                    {manyRes ? `You already have ${manyRes} of this item` : `You don't have this item yet`}
                                                                </div>
                                                                :
                                                                <div className="tool-body">
                                                                    {activeHaveInvent ? `You already have ${countHaveItem} of this food` : `You don't have this food yet`}
                                                                </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className={`action-shop tooltip ${activeHaveInvent ? 'active' : ''}`}>
                                <div className="cust-tooltip bottom">
                                    <div className="tool-arrow"></div>
                                    <div className="tool-wrap">
                                        <div className="tool-wrap-1">
                                            <div className="tool-wrap-2">
                                                <div className="tool-body">
                                                    Especially for Black Bamboo Friday! <br /> Buy 10 dishes and get one for free!
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                                <div className="modal-bay-tit">
                                    {props.modal.item.name}
                                </div>
                                <div className="modal-bay-part">
                                    {props.modal.item.type === "eat" ? props.modal.item.tooltip : props.modal.item.subTitModal}
                                </div>
                                <div className="modal-bay-thumb">
                                    <div className="modal-bay-thumb-in">
                                        {props.modal.item.type === "eat" ?
                                            <picture>
                                                <source srcSet={`./images/shop/${props.modal.item.imgBig}.webp`} type="image/webp" />
                                                <source srcSet={`./images/shop/${props.modal.item.imgBig}.png`} type="image/png" />
                                                <img src={`./images/shop/${props.modal.item.imgBig}.png`} alt="" />
                                            </picture>
                                            : props.modal.item.type === "weapon" ?
                                                <picture>
                                                    <source srcSet={`./images/weapons/all/${props.modal.item.imgBig}.webp`} type="image/webp" />
                                                    <source srcSet={`./images/weapons/all/${props.modal.item.imgBig}.png`} type="image/png" />
                                                    <img src={`./images/weapons/all/${props.modal.item.imgBig}.png`} alt="" />
                                                </picture>
                                                : props.modal.item.type === "book" ?
                                                    <picture>
                                                        <source srcSet={`./images/shop/icons/books/big/${props.modal.item.imgBig}.webp`} type="image/webp" />
                                                        <source srcSet={`./images/shop/icons/books/big/${props.modal.item.imgBig}.png`} type="image/png" />
                                                        <img src={`./images/shop/icons/books/big/${props.modal.item.imgBig}.png`} alt="" />
                                                    </picture>
                                                    : props.modal.item.type === "potion" ?
                                                        <picture>
                                                            <source srcSet={`./images/shop/potions/big/${props.modal.item.imgBig}.webp`} type="image/webp" />
                                                            <source srcSet={`./images/shop/potions/big/${props.modal.item.imgBig}.png`} type="image/png" />
                                                            <img src={`./images/shop/potions/big/${props.modal.item.imgBig}.png`} alt="" />
                                                        </picture>
                                                        : null}
                                        {/* <div className="modal-bay-thumb-text">
                                        sword part 1 of 3
                                    </div> */}
                                    </div>
                                </div>

                                <div className="total-sum">
                                    {props.modal.item.type === "potion" && props.modal.item.res === "gems" ?
                                        <div className="alien-have-res">
                                            you have: <span className='tlmtoken'>{props.tlmBalance} </span> and <span className={props.modal.item.res}>{props.allNFT?.counts?.["gems"] ?? 0}</span>
                                        </div>
                                        : props.modal.item.type === "potion" && props.modal.item.res === "resources" ? <div className="alien-have-res">
                                            you have: <span className='tlmtoken'>{props.tlmBalance} </span> and <span className={props.modal.item.res}>{props.allNFT?.counts?.[431181] ?? 0}</span>
                                        </div> : null}
                                    <div className="total-sum-top">
                                        total price
                                    </div>
                                    <div className="total-sum-body">
                                        {props.modal.item.type === "potion" ?
                                            <>
                                                <span className='tlmtoken'>
                                                    {parseInt(props.modal.item.price * props.modal.countModal * 10000) / 10000}
                                                </span>
                                                +
                                                <span className={`${props.modal.item.res}`}>
                                                    {parseInt(props.modal.item.resNum * props.modal.countModal)}
                                                </span>
                                            </>

                                            : <span className=''>
                                                {parseInt(props.modal.item.price * props.modal.countModal * 10000) / 10000}
                                            </span>
                                        }

                                    </div>
                                </div>

                                <div className="modal-bay-number">
                                    <div className="modal-bay-number-in">
                                        <div className="nav-number">
                                            <button onClick={() => props.changeCountBt('minus')} className="nav-number prev-n"><span>-</span></button>
                                        </div>
                                        <div className="modal-bay-number-inp">
                                            <input type="number" value={props.modal.countModal} onChange={props.inputCount} />
                                        </div>
                                        <div className="nav-number">
                                            <button onClick={() => props.changeCountBt('plus')} className="nav-number next-n"><span>+</span></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-bay-bt">
                                    {props.modal.item.type === "eat" ?
                                        <button className="def-bt mid" onClick={() => props.bayeat(props.modal.item.price, props.modal.item.rare, props.modal.countModal)}>
                                            <span className="button-in">
                                                <span className="button-name">
                                                    <span className="button-name-in">
                                                        <span className="button-name-value">
                                                            buy
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </button>
                                        : props.modal.item.type === "weapon" ?
                                            <button className="def-bt mid" onClick={() => props.bayweapon(props.modal.item.price, props.modal.item.img, props.modal.countModal, "weapon")}>
                                                <span className="button-in">
                                                    <span className="button-name">
                                                        <span className="button-name-in">
                                                            <span className="button-name-value">
                                                                buy
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </button>
                                            : props.modal.item.type === "book" ?
                                                <button className="def-bt mid" onClick={() => props.bayweapon(props.modal.item.price, getIndexClass, props.modal.countModal, "book")}>
                                                    <span className="button-in">
                                                        <span className="button-name">
                                                            <span className="button-name-in">
                                                                <span className="button-name-value">
                                                                    buy
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </button>
                                                : props.modal.item.type === "potion" ?
                                                    <button className="def-bt mid" onClick={() => props.baypotion(props.modal.item.price, props.modal.item.img, props.modal.countModal, props.modal.item.resNum * props.modal.countModal)}>
                                                        <span className="button-in">
                                                            <span className="button-name">
                                                                <span className="button-name-in">
                                                                    <span className="button-name-value">
                                                                        buy
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </button>
                                                    : null}
                                </div>
                            </div>
                            <div className="modal-bay-bot">
                                <img src={modalbot} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="modal-bay-bg-trans" onClick={() => props.closeThenk()}></div>
                </div>
                <div className="modal-bay-bg" ></div>
            </div>



            <div id="thank-modal-shop" className={`modal-bay ${props.showModalThank ? "open" : null}`}>
                <div className="modal-bay-body">
                    <div className="modal-bay-body-in">

                        <div className="modal-bay-head">

                            <div className="modal-bay-top">
                                <button className="modal-bay-close" onClick={() => props.closeThenk()}><span></span></button>
                                <img src={modaltop} alt="" />
                            </div>
                            <div className="modal-bay-wrap">

                                <div className="modal-bay-tit">
                                    Thank you
                                </div>
                                <div className="modal-bay-part">
                                    {props.modal.item.type == "eat" ? "the purchase was successful, time to feed your heroes." : props.modal.item.type == "potion" ? "the purchase was successful" : "the purchase was successful, you can start crafting"}
                                    <div className="action-text">
                                        {props.countModalThank == 10 ? "" : ""}
                                    </div>
                                </div>
                                {props.modal.item.type !== "potion" ?
                                    <>
                                        <div className="modal-thank-info">
                                            Purchase details
                                        </div>

                                        <div className="total-sum">

                                            <div className="total-sum-body">
                                                <span>
                                                    {parseInt(props.modal.item.price * props.countModalThank * 10000) / 10000}
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                    : null}

                                <div className="modal-bay-bt">
                                    <button className="def-bt mid" onClick={() => props.closeThenk()}>
                                        <span className="button-in">
                                            <span className="button-name">
                                                <span className="button-name-in">
                                                    <span className="button-name-value">
                                                        Close
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="modal-bay-bot">
                                <img src={modalbot} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="modal-bay-bg-trans" onClick={() => props.closeThenk()}></div>

                </div>
                <div className="modal-bay-bg" ></div>

            </div>

            <div id="cooking-modal-shop" className={`modal-bay ${props.cooking ? "open" : null}`}>
                <div className="modal-bay-body">
                    <div className="modal-bay-body-in">

                        <div className="modal-bay-head">

                            <div className="modal-bay-top">
                                <img src={modaltop} alt="" />
                            </div>
                            <div className="modal-bay-wrap">
                                {props.modal.item.type == "eat" ?
                                    <>
                                        <div className="modal-bay-tit">
                                            Your food is getting ready, wait a bit
                                        </div>
                                        <div className="loading-modal"></div>
                                        <div className="modal-thank-info">
                                            To cook this dish, we need bamboo pulp broth, salt, pepper, avocado and some secret ingredients.
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="modal-bay-tit">
                                            We are working on your order
                                        </div>
                                        <div className="loading-modal"></div>
                                        <div className="modal-thank-info">
                                            It can take a few minutes, please wait
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="modal-bay-bot">
                                <img src={modalbot} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="modal-bay-bg-trans" onClick={() => props.closeThenk()}></div>

                </div>
                <div className="modal-bay-bg" ></div>

            </div>

        </>);
}

export default Modal;