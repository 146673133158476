import React from 'react';
import modaltop from '../../images/shop/modal-top.png';
import modalbot from '../../images/shop/modal-bot.png';

const ModalThank = (props) => {
    return (
        <div id="thank-modal-shop" className={`modal-bay open thank-burn`}>
            <div className="modal-bay-body">
                <div className="modal-bay-body-in">

                    <div className="modal-bay-head">

                        <div className="modal-bay-top">
                            <button className="modal-bay-close" onClick={() => props.closeThank()}><span></span></button>
                            <img src={modaltop} alt="" />
                        </div>
                        <div className="modal-bay-wrap">

                            <div className="modal-bay-tit">
                                Thank you
                            </div>

                            <div className="total-sum">

                                <div className="was-add-points">
                                    <span>
                                        {props.manyBalls / 100}
                                    </span>
                                </div>
                            </div>
                            <div className="points-thank-info">
                                points was successfully added
                            </div>

                            <div className="modal-bay-bt">
                                <button className="def-bt mid" onClick={() => props.closeThank()}>
                                    <span className="button-in">
                                        <span className="button-name">
                                            <span className="button-name-in">
                                                <span className="button-name-value">
                                                    Close
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="modal-bay-bot">
                            <img src={modalbot} alt="" />
                        </div>
                    </div>
                </div>
                <div className="modal-bay-bg-trans" onClick={() => props.closeThank()}></div>

            </div>
            <div className="modal-bay-bg" ></div>

        </div>);
}

export default ModalThank;