const JEWLIST = 'JEWLIST';
const WAS_LOADING_JEW = 'WAS_LOADING_JEW';
const ADD_JEW_WHEN_REMOVE = 'ADD_JEW_WHEN_REMOVE';
const ADD_JEW_TO_PANDA = 'ADD_JEW_TO_PANDA';
const JEW_ON_PANDA = 'JEW_ON_PANDA';

let initialState = {
    allJew: null,
    /*wasbay: false,*/
    statusJew: false,
    pandaJew: null
};

const jewReduser = (state = initialState, action) => {

    switch(action.type) {
       
        case JEWLIST: {
           // console.log('EATLIST', action.eatlist);
            return {...state, allJew: action.jewlist}
        }
       
        case JEW_ON_PANDA: {
           // console.log('EATLIST', action.eatlist);
            return {...state, pandaJew: action.pandaJew}
        }
        case ADD_JEW_WHEN_REMOVE: {
            console.log('ADD_JEW_WHEN_REMOVE', action.jew);
            var new_obj = {
                asset_id: action.jew.asset_id,
                template: {
                    template_id: action.jew.tmpl
                },
                data: {
                    rarity:  action.jew.rarity,
                    element:  action.jew.element,
                    type:  'jewelry',
                    name:  'jewelry',
                }
            }
            return {
                ...state,
                allJew: [new_obj].concat(state.allJew),
            }
            
        }
        case ADD_JEW_TO_PANDA: {
            console.log('action.jew', action.jew);
            return { ...state, allJew: state.allJew.filter(n => n.asset_id !== action.jew).map( u =>  {
                return u;
                })
            }
        }
        case WAS_LOADING_JEW: {
            //console.log('EATLIST', action.eatlist);
            return {...state, statusJew: action.status}
        }
        

        default:
            return state;
    }
}

export const jewlist = (jewlist) => ({type: JEWLIST, jewlist })
export const setPandaJew = (pandaJew) => ({type: JEW_ON_PANDA, pandaJew })
export const statusLoadinJew = (status) => ({type: WAS_LOADING_JEW, status })
export const addJewWhenRemove = (jew) => ({type: ADD_JEW_WHEN_REMOVE,  jew })
export const addJewToPanda = (jew) => ({type: ADD_JEW_TO_PANDA,  jew })

export default jewReduser;
