import React from 'react';

import {connect} from "react-redux";

import ErrorStake from './ErrorStake';

class ErrorStakeComponent extends React.Component {

    constructor(props) {
        super(props)
    
        this.state = {
            showModal: false,
            showModalBg: false,
            //errorOnGame: false, // ошибка для вывода карточек в игре
        }

        //this.lvlUpPanda = this.lvlUpPanda.bind(this);
        
    }

    componentDidMount() {
        //console.log(this.props);
        //console.log(123123123123);
    }


    render() {
        return (<>
            <ErrorStake  {...this.props} {...this.state} />
        </>);
    }
}

let mapStateToProps = (state) => ({
    //error: state.errorRed.Error,
});

export default connect(mapStateToProps, {})(ErrorStakeComponent);