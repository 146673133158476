import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import Store from './GlobalState/Store';
import reportWebVitals from './reportWebVitals';
import { UserService } from './UserService';

import { BrowserRouter } from "react-router-dom";

import { UALProvider, withUAL } from 'ual-reactjs-renderer'
import { Wax } from '@eosdacio/ual-wax';
import { Anchor } from 'ual-anchor';
import { Wombat } from 'wombat-ual'
import supportsWebP from 'supports-webp';

import { JsonRpc } from 'eosjs'
import { Buffer } from 'buffer';

global.Buffer = Buffer;

const waxMainnet = {
  chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
  rpcEndpoints: [{
    protocol: 'https',
    //host: 'chain.wax.io',
    host: localStorage.getItem('rpc'),
    //host: 'wax.greymass.com',
    port: '443',
  }]
}


const endpoint = `${waxMainnet.rpcEndpoints[0].protocol}://${waxMainnet.rpcEndpoints[0].host}:${waxMainnet.rpcEndpoints[0].port}`;
const rpc = new JsonRpc(endpoint);

const anchor = new Anchor([waxMainnet], { appName: 'WAX OIG Governance Dashboard' });
const waxcloud = new Wax([waxMainnet], { appName: 'WAX OIG Governance Dashboard' });
const wombat = new Wombat([waxMainnet], { appName: 'Wombat' })

const UALConsumer = withUAL(App);

supportsWebP.then(supported => {
  if (supported) {
    document.body.classList.add("webp");
  } else {
    document.body.classList.add("no-webp");
  }
});

ReactDOM.render(
  <Provider store={Store}>
    <UALProvider
      store={Store} chains={[waxMainnet]}
      authenticators={[anchor, waxcloud, wombat]}
      appName={'WAX OIG Governance Dashboard'}>

      <UALConsumer />
    </UALProvider>
  </Provider>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
