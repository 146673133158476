import React from "react";
import Menu from '../../components/Menu';

import { connect } from "react-redux";
import {
  eatlist,
  partlist,
  wasLoadinEat,
  wasBuyingEat,
} from "../../GlobalState/shop-reducer";

import ErrorComponent from "../Error/ErrorComponent";
import getAllSkillBooksClass from '../Get/getAllSkillBooksClass';
import getAllAlienRes from '../Get/getAllAlienRes';
import { getAllNftOnWalletClass } from '../Get/getAllNftOnWalletClass'; // Adjust the import path if needed


import Store from "./Store/StoreComponent";
import Preloader from "../Preloader/Preloader";

import { JsonRpc } from "eosjs";
import "./../../css/shop.scss";

import musicError from "./../../music/error.mp3";
import buyEat from "./../../music/buy-eat-long.mp3";
import musicOpen from "./../../music/open.mp3";
import musicClose from "./../../music/close.mp3";

import { ExplorerApi } from "atomicassets"; // атомик для списка панд
import _ from "lodash";
import StoreComponent from "./Store/StoreComponent";
const fetch = require("node-fetch");
const api = new ExplorerApi(
  "https://" + localStorage.getItem('rpcA'),
  "atomicassets",
  { fetch, rateLimit: 4 }
);

// это транзакция отправки ИЗ
const cardTransferOut = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "transferout",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        from: "",
        to: "",
        asset_id: [],
      },
    },
  ],
};

const bayeat = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "payingame",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        storer: "",
        bamnum: "",
        ipfshash: "",
      },
    },
  ],
};

const baypotionTransaction = {
  actions: [
    {
      account: "alien.worlds",
      name: "transfer",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        from: "",
        to: process.env.REACT_APP_CONTRACT,
        quantity: "",
        memo: "buyslot",
      },
    },
    {
      account: "atomicassets",
      name: "transfer",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        from: "",
        to: process.env.REACT_APP_CONTRACT,
        asset_ids: [],
        memo: "",
      },
    }
  ],
};

var numRpc = 0;
var arrRpc = ['wax.pink.gg', 'wax.cryptolions.io', 'wax.greymass.com', 'api.wax.alohaeos.com', 'api.waxsweden.org', 'wax.eosphere.io', 'api.wax.greeneosio.com', 'wax.eu.eosamsterdam.net', 'api.wax.bountyblok.io']


class ShopComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      warning: {
        hasError: false,
        information: false,
        title: "",
        message: "",
        idAssets: "",
      },
      errorText: "",
      hasError: false,
      showFisrsModal: false,
      modalEat: {
        openModal: false,
        loadingChooseEat: false,
        //alleat: {},
        style: {
          common: "",
          uncommon: "",
          rare: "",
          epic: "",
          legendary: "",
          mythic: "",
        },
      },
      showModalThank: false,
      countModalThank: "1",
      cooking: false,
      resInGame: null,
      allNFT: null,
      tlmBalance: 0,
      allAlienRes: null
    };

    this.bayeat = this.bayeat.bind(this);
    this.clearError = this.clearError.bind(this);
    this.closeThenk = this.closeThenk.bind(this);
    this.goCooking = this.goCooking.bind(this);
    this.bayweapon = this.bayweapon.bind(this);
    this.baypotion = this.baypotion.bind(this);
    this.getAlienBalance = this.getAlienBalance.bind(this);

    this.audioMusicError = new Audio(musicError);
    this.audioBuyEat = new Audio(buyEat);
    this.audioOpen = new Audio(musicOpen);
    this.audioClose = new Audio(musicClose);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.userCheck();
    window.onbeforeunload = function () {
      this.onUnload();
      this.props.wasBuyingEat(false);
    }.bind(this);

    if (!this.props.wasbay && !this.props.statuseat) {
      this.GetPandaEatList();
    } else {
      //this.GetPandaEatListlocal();
      this.GetPandaEatList();
    }

    (async () => {

      let resInGame;
      try {
        resInGame = await getAllSkillBooksClass(this.props.activeUser.accountName);
      } catch (error) {
        console.error('Error fetching medals:', error);
        resInGame = []; // return an empty array if there is an error
      }
      this.setState({
        resInGame: resInGame
      });

      try {
        var shemas = ["gems", "resources"]
        const allNFT = await getAllNftOnWalletClass(this.props.activeUser.accountName, shemas);
        this.setState({ allNFT: allNFT });
      } catch (e) {
        console.error('Error fetching NFTs:', e);
        this.setState({
          hasError: true,
          errorText: e.message,
        });
      }

      try {
        const allAlienRes = await getAllAlienRes(this.props.activeUser.accountName);
        this.setState({ allAlienRes: allAlienRes });
      } catch (e) {
        console.error('Error fetching NFTs:', e);
        this.setState({
          hasError: true,
          errorText: e.message,
        });
      }

    })();



    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.remove("open-modal");
  }

  async GetPandaEatList() {
    (async () => {
      try {
        const activeUser = this.props.ual.activeUser;
        const accountName = await activeUser.getAccountName();
        let rpcc;
        if (this.props.ual.activeUser.rpc != undefined) {
          rpcc = this.props.ual.activeUser.rpc;
        } else if (
          this.props.ual.activeUser.rpc == undefined &&
          this.props.ual.activeUser.wax
        ) {
          rpcc = this.props.ual.activeUser.wax.rpc;
        } else {
          rpcc = new JsonRpc(localStorage.getItem("rpc"));
        }
        const results = await rpcc.get_table_rows({
          json: true,
          code: process.env.REACT_APP_CONTRACT, // contract who owns the table
          scope: process.env.REACT_APP_CONTRACT, // scope of the table
          table: "eatable", // name of the table as specified by the contract abi
          //"key_type": "i64",
          lower_bound: accountName,
          upper_bound: accountName,
          limit: 1,
          reverse: false,
          show_payer: false,
          index_position: 1,
        });

        if (results.rows.length) {
          const resultRow = results.rows[0].eat_count;

          this.props.eatlist(resultRow);

          this.props.wasLoadinEat(true);

          this.setState({
            ...this.state,
            modalEat: {
              ...this.state.modalEat,
              loadingChooseEat: false,
              style: {
                common: resultRow[0],
                uncommon: resultRow[1],
                rare: resultRow[2],
                epic: resultRow[3],
                legendary: resultRow[4],
                mythic: resultRow[5],
              },
            },
          });
        } else {
          this.props.eatlist([0, 0, 0, 0, 0, 0]);

          this.setState({
            ...this.state,
            modalEat: {
              ...this.state.modalEat,
              loadingChooseEat: false,
              style: {
                common: 0,
                uncommon: 0,
                rare: 0,
                epic: 0,
                legendary: 0,
                mythic: 0,
              },
            },
          });
        }
      } catch (e) {
        //console.log(e.message)
        console.warn(e);
        this.setState({
          ...this.state,
          hasError: true,
          errorText: e.message,
          modalEat: {
            ...this.state.modalEat,
            loadingChooseEat: false,
          },
        });
        console.log("\nCaught exception: " + e);
        if (localStorage.getItem("sound") == "true") {
          this.audioMusicError.currentTime = 0;
          this.audioMusicError.volume = 0.1;
          this.audioMusicError.play();
        }
      }
    })();
  }

  async GetPandaPartList(part) {
    (async () => {
      try {
        //console.log('try');
        //console.log('SHOP GetPandaEatList');
        const userName = this.props.ual.activeUser.accountName;
        const activeUser = this.props.ual.activeUser;

        const asset = await api
          .getAssets({
            limit: 500,
            owner: userName,
            collection_name: "nftpandawaxp",
            schema_name: ["weapon" /*, "promo"*/],
            template_id: part,
          })
          .then((assets) => {
            //console.log('byTemplate', assets);
            this.props.partlist(assets);

            this.setState({
              ...this.state,
              modalEat: {
                ...this.state.modalEat,
                loadingChooseEat: false,
              },
            });
          });
      } catch (e) {
        //console.log(e.message)
        console.warn(e);
        this.setState({
          ...this.state,
          hasError: true,
          errorText: e.message,
          modalEat: {
            ...this.state.modalEat,
            loadingChooseEat: false,
          },
        });
        console.log("\nCaught exception: " + e);
        if (localStorage.getItem("sound") == "true") {
          this.audioMusicError.currentTime = 0;
          this.audioMusicError.volume = 0.1;
          this.audioMusicError.play();
        }
      }
    })();
  }

  async GetPandaEatListlocal() {
    (async () => {
      try {
        //console.log('SHOP GetPandaEatListlocal');

        const assets = this.props.alleat;

        let common = 0;
        let uncommon = 0;
        let rare = 0;
        let epic = 0;
        let legendary = 0;
        let mythic = 0;
        for (var c in assets) {
          //console.log(byTemplate[c]);
          if (assets[c].template.template_id == 350909) {
            common += 1;
          } else if (assets[c].template.template_id == 350910) {
            uncommon += 1;
          } else if (assets[c].template.template_id == 350911) {
            rare += 1;
          } else if (assets[c].template.template_id == 350912) {
            epic += 1;
          } else if (assets[c].template.template_id == 350913) {
            legendary += 1;
          } else if (assets[c].template.template_id == 350914) {
            mythic += 1;
          }
        }

        this.setState({
          ...this.state,
          modalEat: {
            ...this.state.modalEat,
            loadingChooseEat: false,
            style: {
              common: common,
              uncommon: uncommon,
              rare: rare,
              epic: epic,
              legendary: legendary,
              mythic: mythic,
            },
          },
        });
      } catch (e) {
        //console.log(e.message)
        console.warn(e);
        this.setState({
          ...this.state,
          hasError: true,
          errorText: e.message,
          modalEat: {
            ...this.state.modalEat,
            loadingChooseEat: false,
          },
        });
        console.log("\nCaught exception: " + e);
        if (localStorage.getItem("sound") == "true") {
          this.audioMusicError.currentTime = 0;
          this.audioMusicError.volume = 0.1;
          this.audioMusicError.play();
        }
      }
    })();
  }

  async userCheck() {
    try {
      const activeUser = this.props.ual.activeUser;
      const accountName = await activeUser.getAccountName();
      let rpcc;
      if (this.props.ual.activeUser.rpc != undefined) {
        rpcc = this.props.ual.activeUser.rpc;
      } else if (
        this.props.ual.activeUser.rpc == undefined &&
        this.props.ual.activeUser.wax
      ) {
        rpcc = this.props.ual.activeUser.wax.rpc;
      } else {
        rpcc = new JsonRpc(localStorage.getItem("rpc"));
      }
      const results = await rpcc.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "usersnew", // name of the table as specified by the contract abi
        limit: 1,
        lower_bound: accountName,
        upper_bound: accountName,
        index_position: 1,
        reverse: false,
        show_payer: false,
      });
      const originRowUser = results;
      //console.log(originRowUser);
      let resultRow;
      if (originRowUser.rows.length) {
        resultRow = results.rows[0].inrent;
      } else {
        resultRow = 0;
      }

      if (resultRow == 1) {
        const resultRowName = results.rows[0].rent;
        /*this.GetPandaListOnGame(resultRowName);
                this.GetPandaListOnUser(resultRowName);*/
      } else {
        /*this.GetPandaListOnGame(accountName);
                this.GetPandaListOnUser(accountName);*/
      }
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
      if (localStorage.getItem("sound") == "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  async clearError() {
    this.setState({
      errorText: "",
      hasError: false,
    });
  }

  // покупка еды
  async bayeat(price, rarity, count) {
    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    bayeat.actions[0].authorization[0].actor = userName;
    bayeat.actions[0].data.storer = userName;
    bayeat.actions[0].data.bamnum = parseInt(
      parseFloat(price * count) * 10000
    );
    bayeat.actions[0].data.ipfshash = "buyeat " + rarity + " " + count + " ";
    console.log(bayeat);

    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");

    try {
      await activeUser
        .signTransaction(bayeat, { blocksBehind: 3, expireSeconds: 30 })
        .then((response) => {
          if (response.status === "executed") {
            //this.removePandaFromSlot(indexSlot)
            loadingSlot.classList.remove("loading");
            //console.log(response.status);

            this.props.updateBalance(
              false,
              parseFloat(price * count).toFixed(4)
            );

            let common = 0;
            let uncommon = 0;
            let rare = 0;
            let epic = 0;
            let legendary = 0;
            let mythic = 0;

            //console.log(byTemplate[c]);
            if (rarity == "common") {
              common = 1;
            } else if (rarity == "uncommon") {
              uncommon = 1;
            } else if (rarity == "rare") {
              rare = 1;
            } else if (rarity == "epic") {
              epic = 1;
            } else if (rarity == "legendary") {
              legendary = 1;
            } else if (rarity == "mythic") {
              mythic = 1;
            }

            this.setState({
              ...this.state,
              cooking: true,
              modalEat: {
                ...this.state.modalEat,
                openModal: false,
                loadingChooseEat: false,
                style: {
                  common: this.state.modalEat.style.common + common,
                  uncommon: this.state.modalEat.style.uncommon + uncommon,
                  rare: this.state.modalEat.style.rare + rare,
                  epic: this.state.modalEat.style.epic + epic,
                  legendary: this.state.modalEat.style.legendary + legendary,
                  mythic: this.state.modalEat.style.mythic + mythic,
                },
              },
              //showModalThank: true,
              countModalThank: count,
            });

            this.goCooking();
          }
        });
    } catch (error) {
      loadingSlot.classList.remove("loading");
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
      });
      if (localStorage.getItem("sound") == "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  // покупка еды
  async bayweapon(price, shema, count, type) {
    //console.log("shema", shema)

    var typeBayItem;
    if (type == "weapon") {
      typeBayItem = "buyweapon";
      this.GetPandaPartList(shema);
    } else {
      typeBayItem = "buybook";
    }

    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    bayeat.actions[0].authorization[0].actor = userName;
    bayeat.actions[0].data.storer = userName;
    bayeat.actions[0].data.bamnum = parseFloat(price * count) * 10000;
    bayeat.actions[0].data.ipfshash = typeBayItem + " " + shema + " " + count + " ";
    //console.log(bayeat);

    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");

    try {
      await activeUser
        .signTransaction(bayeat, { blocksBehind: 3, expireSeconds: 30 })
        .then((response) => {
          if (response.status === "executed") {

            loadingSlot.classList.remove("loading");
            this.props.updateBalance(
              false,
              parseFloat(price * count).toFixed(4)
            );

            if (type == "weapon") {
              this.setState({
                ...this.state,
                cooking: true,
                modalEat: {
                  ...this.state.modalEat,
                  openModal: false,
                  loadingChooseEat: false,
                },
                //showModalThank: true,
                countModalThank: count,
              });
              this.goSmackWepons(shema);
            } else {

              var newBooks = { ...this.state.resInGame };
              newBooks.books[shema] += count;

              this.setState({
                ...this.state,
                cooking: true,
                modalEat: {
                  ...this.state.modalEat,
                  openModal: false,
                  loadingChooseEat: false,
                },
                resInGame: newBooks,
                //showModalThank: true,
                countModalThank: count,
                modalEat: {
                  ...this.state.modalEat,
                  loadingChooseEat: false,
                },
                cooking: false,
                showModalThank: true,
              });
              this.props.wasBuyingEat(true);
              if (localStorage.getItem("sound") == "true") {
                this.audioBuyEat.currentTime = 0;
                this.audioBuyEat.volume = 0.5;
                this.audioBuyEat.play();
              }
            }
          }
        });
    } catch (error) {
      loadingSlot.classList.remove("loading");
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
      });
      if (localStorage.getItem("sound") == "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  // покупка еды
  async baypotion(price, shema, count, countres) {

    console.log("price, shema, count, countres", price, shema, count, countres)

    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;

    var allNftState = this.state.allNFT;

    function getFirstNAssets(parentKey, childKey, n) {
      if (!allNftState.hasOwnProperty(parentKey)) {
        return [];
      }

      let targetArray;

      if (childKey === "") {
        targetArray = allNftState[parentKey];
      } else if (allNftState[parentKey].hasOwnProperty(childKey)) {
        targetArray = allNftState[parentKey][childKey];
      } else {
        return [];
      }

      return targetArray.slice(0, n).map(item => item.asset_id);
    }

    let allResNft = []
    let numRes = 0

    if (shema === "space-power-potion") {
      allResNft = getFirstNAssets("gems", "", countres);
      numRes = 2;
    } else if (shema === "small-space-power-potion") {
      allResNft = getFirstNAssets("gems", "", countres);
      numRes = 3;
    } else {
      allResNft = getFirstNAssets("resources", "431181", countres);
      numRes = 4;
    }

    baypotionTransaction.actions[0].authorization[0].actor = userName;
    baypotionTransaction.actions[0].data.from = userName;
    baypotionTransaction.actions[0].data.quantity = `${parseInt(parseFloat(price * count)).toFixed(4)} TLM`;
    baypotionTransaction.actions[0].data.memo = "buyalienpotions";

    baypotionTransaction.actions[1].authorization[0].actor = userName;
    baypotionTransaction.actions[1].data.from = userName;
    baypotionTransaction.actions[1].data.memo = "buyalienpotions " + shema + " " + count + " ";
    baypotionTransaction.actions[1].data.asset_ids = allResNft;
    console.log(baypotionTransaction);

    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");

    try {
      await activeUser
        .signTransaction(baypotionTransaction, { blocksBehind: 3, expireSeconds: 30 })
        .then((response) => {
          if (response.status === "executed") {

            loadingSlot.classList.remove("loading");
            this.props.updateBalance(
              false,
              parseFloat(price * count).toFixed(4)
            );

            //var newBooks = { ...this.state.allAlienRes };
            //newBooks.res[numRes] += count;

            var statetlmBalance = parseFloat(this.state.tlmBalance);
            console.log("statetlmBalance", statetlmBalance);
            statetlmBalance -= parseFloat(price * count).toFixed(4);


            this.setState({
              ...this.state,
              cooking: true,
              modalEat: {
                ...this.state.modalEat,
                openModal: false,
                loadingChooseEat: false
              },
              //showModalThank: true,
              countModalThank: count,
              modalEat: {
                ...this.state.modalEat,
                loadingChooseEat: false,
              },
              cooking: false,
              showModalThank: true,
              tlmBalance: statetlmBalance
            });
            this.props.wasBuyingEat(true);
            if (localStorage.getItem("sound") == "true") {
              this.audioBuyEat.currentTime = 0;
              this.audioBuyEat.volume = 0.5;
              this.audioBuyEat.play();
            }

          }
        });
    } catch (error) {
      loadingSlot.classList.remove("loading");
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
      });
      if (localStorage.getItem("sound") == "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  async closeThenk() {
    document.getElementsByTagName("body")[0].classList.remove("open-modal");
    this.setState({
      ...this.state,
      showModalThank: false,
    });
    if (localStorage.getItem("sound") == "true") {
      this.audioClose.currentTime = 0;
      this.audioClose.volume = 0.1;
      this.audioClose.play();
    }
  }

  async goCooking() {
    (async () => {
      try {
        //console.log('try');
        const userName = this.props.ual.activeUser.accountName;
        const activeUser = this.props.ual.activeUser;

        const asset = await api
          .getAssets({
            limit: 500,
            owner: userName,
            collection_name: "nftpandawaxp",
            schema_name: ["food" /*, "promo"*/],
          })
          .then((assets) => {
            let common = 0;
            let uncommon = 0;
            let rare = 0;
            let epic = 0;
            let legendary = 0;
            let mythic = 0;
            for (var c in assets) {
              //console.log(byTemplate[c]);
              if (assets[c].template.template_id == 350909) {
                common += 1;
              } else if (assets[c].template.template_id == 350910) {
                uncommon += 1;
              } else if (assets[c].template.template_id == 350911) {
                rare += 1;
              } else if (assets[c].template.template_id == 350912) {
                epic += 1;
              } else if (assets[c].template.template_id == 350913) {
                legendary += 1;
              } else if (assets[c].template.template_id == 350914) {
                mythic += 1;
              }
            }

            //console.log('byTemplate', assets);
            this.props.eatlist(assets);

            this.setState({
              ...this.state,
              modalEat: {
                ...this.state.modalEat,
                loadingChooseEat: false,
                style: {
                  common: common,
                  uncommon: uncommon,
                  rare: rare,
                  epic: epic,
                  legendary: legendary,
                  mythic: mythic,
                },
              },
            });

            this.interval = setInterval(() => {
              //console.log('setTimeout');
              if (
                JSON.stringify(this.props.alleat) !== JSON.stringify(assets)
              ) {
                clearInterval(this.interval);
                this.setState({
                  ...this.state,
                  cooking: false,
                  showModalThank: true,
                });
                //console.log("YES IT DONE");
                this.props.wasBuyingEat(true);
                if (localStorage.getItem("sound") == "true") {
                  this.audioBuyEat.currentTime = 0;
                  this.audioBuyEat.volume = 0.5;
                  this.audioBuyEat.play();
                }
              }
              // if (wasLogin.finalnumber != 0 && Date.now() / 1000 < wasLogin.timer){
              //     clearInterval(this.interval);
              //     return;
              // }
              //console.log("NO YOU MUST WAIT IT DONE")
              this.GetPandaEatList();
            }, 4000);
          });
      } catch (e) {
        //console.log(e.message)
        console.warn(e);
        this.setState({
          ...this.state,
          hasError: true,
          errorText: e.message,
          modalEat: {
            ...this.state.modalEat,
            loadingChooseEat: false,
          },
        });
        console.log("\nCaught exception: " + e);
        if (localStorage.getItem("sound") == "true") {
          this.audioMusicError.currentTime = 0;
          this.audioMusicError.volume = 0.1;
          this.audioMusicError.play();
        }
      }
    })();
  }

  async goSmackWepons(shema) {
    (async () => {
      try {
        //console.log('try');
        const userName = this.props.ual.activeUser.accountName;
        const activeUser = this.props.ual.activeUser;

        const asset = await api
          .getAssets({
            limit: 500,
            owner: userName,
            collection_name: "nftpandawaxp",
            schema_name: ["weapon" /*, "promo"*/],
          })
          .then((assets) => {
            this.props.partlist(assets);

            this.setState({
              ...this.state,
              modalEat: {
                ...this.state.modalEat,
                loadingChooseEat: false,
              },
            });

            this.interval = setInterval(() => {
              //console.log('setTimeout');
              if (
                JSON.stringify(this.props.allpartlist) !==
                JSON.stringify(assets)
              ) {
                clearInterval(this.interval);
                this.setState({
                  ...this.state,
                  cooking: false,
                  showModalThank: true,
                });
                //console.log("YES IT DONE");
                this.props.wasBuyingEat(true);
                if (localStorage.getItem("sound") == "true") {
                  this.audioBuyEat.currentTime = 0;
                  this.audioBuyEat.volume = 0.5;
                  this.audioBuyEat.play();
                }
              }
              // if (wasLogin.finalnumber != 0 && Date.now() / 1000 < wasLogin.timer){
              //     clearInterval(this.interval);
              //     return;
              // }
              //console.log("NO YOU MUST WAIT IT DONE")
              this.GetPandaPartList(shema);
            }, 4000);
          });
      } catch (e) {
        //console.log(e.message)
        console.warn(e);
        this.setState({
          ...this.state,
          hasError: true,
          errorText: e.message,
          modalEat: {
            ...this.state.modalEat,
            loadingChooseEat: false,
          },
        });
        console.log("\nCaught exception: " + e);
        if (localStorage.getItem("sound") == "true") {
          this.audioMusicError.currentTime = 0;
          this.audioMusicError.volume = 0.1;
          this.audioMusicError.play();
        }
      }
    })();
  }

  async getAlienBalance() {
    try {
      const { ual: { activeUser } } = this.props;
      var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);

      //const account = this.props?.ual?.activeUser?.accountName;
      // var accountBalance = account?.core_liquid_balance;
      // accountBalance = (parseFloat(accountBalance).toFixed(4));

      // if (accountBalance == "NaN") {
      //   accountBalance = 0;
      // }

      var accountBalanceTLM = await rpcc.get_currency_balance("alien.worlds", activeUser.accountName, "TLM");
      accountBalanceTLM = (parseFloat(accountBalanceTLM).toFixed(4));
      if (accountBalanceTLM == "NaN") {
        accountBalanceTLM = 0;
      }
      this.setState({
        tlmBalance: accountBalanceTLM
      })
      //console.log("accountBalanceTLM", accountBalanceTLM);
    } catch (e) {
      console.warn(e)
      setTimeout(() => {
        this.getAlienBalance();
        if (numRpc == 8) {
          numRpc = 0;
        } else {
          numRpc++;
        }
      }, 1000);
    }
  }

  render() {
    return (
      <>
        <Menu  {...this.props.ual} wax={this.props.balanceWax} bam={this.props.balanceBam} game={this.props.balanceGame} updateBalance={this.props.updateBalance} />
        {this.state.hasError ? (
          <ErrorComponent
            errorMessage={this.state.errorText}
            clearError={this.clearError}
          />
        ) : null}
        <main className="shop-page">
          <div className="loading-block"></div>

          <div className="shafl">
            <StoreComponent
              {...this.props}
              modalEat={this.state.modalEat}
              bayeat={this.bayeat}
              bayweapon={this.bayweapon}
              baypotion={this.baypotion}
              showModalThank={this.state.showModalThank}
              countModalThank={this.state.countModalThank}
              closeThenk={this.closeThenk}
              cooking={this.state.cooking}
              resInGame={this.state.resInGame}
              getAlienBalance={this.getAlienBalance}
              allNFT={this.state.allNFT}
              tlmBalance={this.state.tlmBalance}
              allAlienRes={this.state.allAlienRes}
            />
          </div>

          <footer>
            <div className="footer-nav">
              <nav>
                <ul>
                  <li>
                    <a
                      className="foot-logo-1"
                      target="_blank"
                      href="https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&sort=created&symbol=WAX"
                    >
                      <span></span>
                      <i>Market</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-2"
                      target="_blank"
                      href="https://twitter.com/NftPanda/"
                    >
                      <span></span>
                      <i>Twitter</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-3"
                      target="_blank"
                      href="https://www.instagram.com/nftpanda/"
                    >
                      <span></span>
                      <i>Instagram</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-4"
                      target="_blank"
                      href="https://discord.gg/vCSgme4d5b"
                    >
                      <span></span>
                      <i>Discord</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-5"
                      target="_blank"
                      href={`https://wax.alcor.exchange/swap?output=${process.env.REACT_APP_VAL}-nftpandabamb&input=WAX-eosio.token`}
                    >
                      <span></span>
                      <i>{process.env.REACT_APP_VAL} Trade</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-6"
                      target="_blank"
                      href="https://discord.gg/vCSgme4d5b"
                    >
                      <span></span>
                      <i>Support</i>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </footer>
        </main>
      </>
    );
  }
}

let mapStateToProps = (state) => ({
  statuseat: state.showReduser.statuseat,
  alleat: state.showReduser.alleat,
  allpartlist: state.showReduser.allpartlist,
  wasbay: state.showReduser.wasbay,
});

export default connect(mapStateToProps, {
  eatlist,
  partlist,
  wasLoadinEat,
  wasBuyingEat,
})(ShopComponent);
