import React from "react";
import Menu from '../../components/Menu';

import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import {
  weaponlist,
  statusLoadinWeapon,
  addWeaponWhenRemove,
  addWeaponToPanda,
} from "../../GlobalState/weapon-reducer";

import ErrorComponent from "../Error/ErrorComponent";
import CraftHomeLoop from "./CraftHomeLoop";
import cryptoRandomString from "crypto-random-string";

import { JsonRpc } from "eosjs";
import "./../../css/craft.scss";

import musicError from "./../../music/error.mp3";
import musicOpenCraft from "./../../music/bt-craft.mp3";
import craftSuccess from "./../../music/craft-success.mp3";
import craftFail from "./../../music/craft-fail.mp3";
import newClick from "./../../music/newclick.mp3";

import { ExplorerApi } from "atomicassets"; // атомик для списка панд

const fetch = require("node-fetch");
const api = new ExplorerApi(
  "https://" + localStorage.getItem('rpcA'),
  "atomicassets",
  { fetch, rateLimit: 4 }
);

// это транзакция отправки ИЗ
const cardTransferOut = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "transferout",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        from: "",
        to: "",
        asset_id: [],
      },
    },
  ],
};

class CraftPageComponentWeapon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      craftweapon: [
        {
          headWeapon: "sword",
          allelements: [
            {
              weapon: 376557,
              element: "water",
              bluePrint: 376550,
              partOne: 376551,
              partTwo: 376554,
              partThree: 376555,
            },
            {
              weapon: 376570,
              element: "fire",
              bluePrint: 376561,
              partOne: 376562,
              partTwo: 376563,
              partThree: 376568,
            },
            {
              weapon: 376577,
              element: "wind",
              bluePrint: 376573,
              partOne: 376574,
              partTwo: 376575,
              partThree: 376576,
            },
            {
              weapon: 376582,
              element: "earth",
              bluePrint: 376578,
              partOne: 376579,
              partTwo: 376580,
              partThree: 376581,
            },
          ],
        },
        {
          headWeapon: "bow",
          allelements: [
            {
              weapon: 373659,
              element: "water",
              bluePrint: 373647,
              partOne: 373649,
              partTwo: 373650,
              partThree: 373653,
            },
            {
              weapon: 373669,
              element: "fire",
              bluePrint: 373661,
              partOne: 373665,
              partTwo: 373666,
              partThree: 373667,
            },
            {
              weapon: 373682,
              element: "wind",
              bluePrint: 373671,
              partOne: 373677,
              partTwo: 373678,
              partThree: 373679,
            },
            {
              weapon: 373736,
              element: "earth",
              bluePrint: 373683,
              partOne: 373732,
              partTwo: 373734,
              partThree: 373735,
            },
          ],
        },
        {
          headWeapon: "hammer",
          allelements: [
            {
              weapon: 375234,
              element: "water",
              bluePrint: 375229,
              partOne: 375231,
              partTwo: 375232,
              partThree: 375233,
            },
            {
              weapon: 375243,
              element: "fire",
              bluePrint: 375236,
              partOne: 375239,
              partTwo: 375240,
              partThree: 375241,
            },
            {
              weapon: 376145,
              element: "wind",
              bluePrint: 376141,
              partOne: 376142,
              partTwo: 376143,
              partThree: 376144,
            },
            {
              weapon: 376151,
              element: "earth",
              bluePrint: 376146,
              partOne: 376147,
              partTwo: 376149,
              partThree: 376150,
            },
          ],
        },
        {
          headWeapon: "axe",
          allelements: [
            {
              weapon: 373391,
              element: "water",
              bluePrint: 373376,
              partOne: 373380,
              partTwo: 373384,
              partThree: 373387,
            },
            {
              weapon: 373433,
              element: "fire",
              bluePrint: 373394,
              partOne: 373406,
              partTwo: 373427,
              partThree: 373431,
            },
            {
              weapon: 373448,
              element: "wind",
              bluePrint: 373439,
              partOne: 373442,
              partTwo: 373444,
              partThree: 373447,
            },
            {
              weapon: 373457,
              element: "earth",
              bluePrint: 373452,
              partOne: 373453,
              partTwo: 373454,
              partThree: 373456,
            },
          ],
        },
        {
          headWeapon: "stick",
          allelements: [
            {
              weapon: 376320,
              element: "water",
              bluePrint: 376312,
              partOne: 376313,
              partTwo: 376315,
              partThree: 376319,
            },
            {
              weapon: 376329,
              element: "fire",
              bluePrint: 376322,
              partOne: 376325,
              partTwo: 376327,
              partThree: 376328,
            },
            {
              weapon: 376374,
              element: "wind",
              bluePrint: 376330,
              partOne: 376334,
              partTwo: 376337,
              partThree: 376342,
            },
            {
              weapon: 376407,
              element: "earth",
              bluePrint: 376397,
              partOne: 376398,
              partTwo: 376399,
              partThree: 376400,
            },
          ],
        },
        {
          headWeapon: "mech",
          allelements: [
            {
              weapon: 376156,
              element: "water",
              bluePrint: 376152,
              partOne: 376153,
              partTwo: 376154,
              partThree: 376155,
            },
            {
              weapon: 376162,
              element: "fire",
              bluePrint: 376157,
              partOne: 376158,
              partTwo: 376160,
              partThree: 376161,
            },
            {
              weapon: 376172,
              element: "wind",
              bluePrint: 376165,
              partOne: 376166,
              partTwo: 376167,
              partThree: 376170,
            },
            {
              weapon: 376178,
              element: "earth",
              bluePrint: 376173,
              partOne: 376174,
              partTwo: 376175,
              partThree: 376176,
            },
          ],
        },
        {
          headWeapon: "claws",
          allelements: [
            {
              weapon: 374507,
              element: "water",
              bluePrint: 373737,
              partOne: 373741,
              partTwo: 373742,
              partThree: 373743,
            },
            {
              weapon: 374515,
              element: "fire",
              bluePrint: 374508,
              partOne: 374510,
              partTwo: 374511,
              partThree: 374512,
            },
            {
              weapon: 374525,
              element: "wind",
              bluePrint: 374519,
              partOne: 374521,
              partTwo: 374522,
              partThree: 374523,
            },
            {
              weapon: 374535,
              element: "earth",
              bluePrint: 374526,
              partOne: 374528,
              partTwo: 374531,
              partThree: 374532,
            },
          ],
        },
        {
          headWeapon: "slingshot",
          allelements: [
            {
              weapon: 376198,
              element: "water",
              bluePrint: 376193,
              partOne: 376194,
              partTwo: 376195,
              partThree: 376196,
            },
            {
              weapon: 376204,
              element: "fire",
              bluePrint: 376200,
              partOne: 376201,
              partTwo: 376202,
              partThree: 376203,
            },
            {
              weapon: 376302,
              element: "wind",
              bluePrint: 376295,
              partOne: 376296,
              partTwo: 376297,
              partThree: 376299,
            },
            {
              weapon: 376310,
              element: "earth",
              bluePrint: 376300,
              partOne: 376304,
              partTwo: 376306,
              partThree: 376308,
            },
          ],
        },
        {
          headWeapon: "gun",
          allelements: [
            {
              weapon: 374606,
              element: "water",
              bluePrint: 374597,
              partOne: 374598,
              partTwo: 374601,
              partThree: 374604,
            },
            {
              weapon: 374615,
              element: "fire",
              bluePrint: 374608,
              partOne: 374611,
              partTwo: 374612,
              partThree: 374614,
            },
            {
              weapon: 374640,
              element: "wind",
              bluePrint: 374629,
              partOne: 374632,
              partTwo: 374637,
              partThree: 374639,
            },
            {
              weapon: 374653,
              element: "earth",
              bluePrint: 374641,
              partOne: 374643,
              partTwo: 374648,
              partThree: 374651,
            },
          ],
        },
        {
          headWeapon: "dagger",
          allelements: [
            {
              weapon: 374544,
              element: "water",
              bluePrint: 374537,
              partOne: 374538,
              partTwo: 374539,
              partThree: 374542,
            },
            {
              weapon: 374570,
              element: "fire",
              bluePrint: 374545,
              partOne: 374558,
              partTwo: 374561,
              partThree: 374565,
            },
            {
              weapon: 374590,
              element: "wind",
              bluePrint: 374585,
              partOne: 374587,
              partTwo: 374588,
              partThree: 374589,
            },
            {
              weapon: 374596,
              element: "earth",
              bluePrint: 374591,
              partOne: 374592,
              partTwo: 374593,
              partThree: 374594,
            },
          ],
        },
      ],
      activeWeaponName: "",
      activeRecipe: {
        weapon: "",
        element: "",
        bluePrint: "",
        partOne: "",
        partTwo: "",
        partThree: "",
      },
      openRecipe: false,
      openRecipeIndex: "",
      openRecipeIndexIn: "",
      counPartWeapon: {
        bluePrint: "",
        partOne: "",
        partTwo: "",
        partThree: "",
        resource: [],
      },
      allparts: {}, // все части этого оружия который хотят скрафтить
      allThisWeapon: {}, // все оружия
      modalResult: {
        open: false, // показать модалку результата
        loading: true, // ожидание результата
      },
      craftpay: false, //
      modalcraft: {
        top: "Craft weapon",
        title: "The blacksmith is ready to start work",
        button: "first",
        descr:
          `The blacksmith always takes money <span>(15 ${process.env.REACT_APP_VAL})<i></i></span> for the front, since nothing motivates like a salary. `,
      },
      /*
            top: "Craft step 2/2",
                            title: "Now the blacksmith is ready to start work",
                            button: "second",
                            descr: "hand over all parts of the weapon so that he can start working on his craft. It can take a few minutes, please wait..." */
      craftfail: "",
      craftResult: "",
      contractMedal: "",
      activeId: "",
      activeEl: "",
      loadingNft: true,
    };

    this.clearError = this.clearError.bind(this);
    this.openRecipe = this.openRecipe.bind(this);
    this.backFromRecipe = this.backFromRecipe.bind(this);
    this.craftpay = this.craftpay.bind(this);
    this.opencraftmodal = this.opencraftmodal.bind(this);
    this.craft = this.craft.bind(this);
    this.GetPandaPartList = this.GetPandaPartList.bind(this);
    //this.GetPandaWeaponList = this.GetPandaWeaponList.bind(this);
    this.closeModalResult = this.closeModalResult.bind(this);
    this.closeModalSteps = this.closeModalSteps.bind(this);
    this.medalCheck = this.medalCheck.bind(this);
    this.audioMusicError = new Audio(musicError);
    this.audioMusicOpenCraft = new Audio(musicOpenCraft);
    this.audioMusicCraftSuccess = new Audio(craftSuccess);
    this.audioMusicCraftfail = new Audio(craftFail);
    this.audioMusicNewClick = new Audio(newClick);
  }

  componentDidMount() {
    this.medalCheck();

    let urlpage = new URLSearchParams(window.location.search);

    let id = urlpage.get("id");
    let el = urlpage.get("el");

    if (id && el) {
      this.setState(
        {
          activeId: id,
          activeEl: el,
        },
        this.openRecipe(id, el)
      );
    }
  }

  async clearError() {
    this.setState({
      errorText: "",
      hasError: false,
    });
  }

  async medalCheck() {
    try {
      const activeUser = this.props.ual.activeUser;
      const accountName = await activeUser.getAccountName();
      //console.log(activeUser);
      //console.log(accountName);
      let rpcc;
      if (this.props.ual.activeUser.rpc != undefined) {
        rpcc = this.props.ual.activeUser.rpc;
      } else if (
        this.props.ual.activeUser.rpc == undefined &&
        this.props.ual.activeUser.wax
      ) {
        rpcc = this.props.ual.activeUser.wax.rpc;
      } else {
        rpcc = new JsonRpc(localStorage.getItem("rpc"));
      }
      const results = await rpcc.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "medaltop", // name of the table as specified by the contract abi
        //"table": "usersnew",    // name of the table as specified by the contract abi
        limit: 1,
        lower_bound: accountName,
        upper_bound: accountName,
        //lower_bound: 0,
        key_type: "i64",
        //"index_position": 2,
        //"limit": -1,
        reverse: !0,
        show_payer: false,
        index_position: 1,
      });
      const originRowUser = results.rows[0];
      console.log("originRowUser", originRowUser);

      var totalLuck = 0;
      if (results.rows[0]) {
        if (originRowUser.medal100) {
          totalLuck = 5;
          if (originRowUser.medal10) {
            totalLuck += 2.5;
            if (originRowUser.medal300) {
              totalLuck += 1.5;
            }
          } else if (originRowUser.medal300) {
            totalLuck += 1.5;
          }
        } else if (originRowUser.medal10) {
          totalLuck = 5;
          if (originRowUser.medal100) {
            totalLuck += 2.5;
            if (originRowUser.medal300) {
              totalLuck += 1.5;
            }
          } else if (originRowUser.medal300) {
            totalLuck += 1.5;
          }
        } else if (originRowUser.medal300) {
          totalLuck = 3;
          if (originRowUser.medal10) {
            totalLuck += 2.5;
            if (originRowUser.medal100) {
              totalLuck += 2.5;
            }
          } else if (originRowUser.medal100) {
            totalLuck += 2.5;
          }
        }
      }

      if (originRowUser != undefined) {
        this.setState({
          contractMedal: totalLuck,
        });
      }
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
    }
  }

  openRecipe(index, element, refresh) {
    //console.log("refresh", refresh);
    //console.log("index", index);
    //console.log("element", element);
    //console.log("this.state.craftweapon[index].allelements[element]", this.state.craftweapon[index].allelements[element]);
    var openRecipe;
    if (refresh == "refresh") {
      openRecipe = false;
    } else {
      openRecipe = true;
    }
    this.setState({
      ...this.state,
      openRecipe: openRecipe,
      activeId: index,
      activeEl: element,
      activeWeaponName: this.state.craftweapon[index].headWeapon,
      activeRecipe: this.state.craftweapon[index].allelements[element],
      loadingNft: true,
    });

    console.log("openRecipe", index, element, refresh, openRecipe);

    (async () => {
      try {
        let promRes = [];
        let weponParts = [];

        promRes.push(this.GetPandaPartList(431181, "res"));
        weponParts.push(
          this.GetPandaPartList(
            this.state.craftweapon[index].allelements[element],
            "parts"
          )
        );

        let resultRes = await Promise.all(promRes);
        let resultPart = await Promise.all(weponParts);
        var resultNew = [];
        resultNew.push(...resultRes[0], ...resultPart[0]);
        //console.log('result', ...resultRes);
        //console.log('resultPart', resultPart);

        let blueprint = 0;
        let partOne = 0;
        let partTwo = 0;
        let partThree = 0;
        if (resultPart[0] && resultPart[0].length) {
          for (var c in resultPart[0]) {
            //console.log(byTemplate[c]);
            if (
              resultPart[0][c].template.template_id ==
              this.state.activeRecipe.bluePrint
            ) {
              blueprint += 1;
            } else if (
              resultPart[0][c].template.template_id ==
              this.state.activeRecipe.partOne
            ) {
              partOne += 1;
            } else if (
              resultPart[0][c].template.template_id ==
              this.state.activeRecipe.partTwo
            ) {
              partTwo += 1;
            } else if (
              resultPart[0][c].template.template_id ==
              this.state.activeRecipe.partThree
            ) {
              partThree += 1;
            }
          }
        }

        this.setState({
          ...this.state,
          allparts: resultNew,
          counPartWeapon: {
            bluePrint: blueprint,
            partOne: partOne,
            partTwo: partTwo,
            partThree: partThree,
            resource: resultRes[0],
          },
          loadingNft: false,
        });

        /*this.setState({
                    ...this.state,
                    allparts: resultNew,
                    counPartWeapon: {
                        resource: result[0].length,
                        stone: resultPart[0].length
                    }
                });*/
      } catch (e) {
        //console.log(e.message)
        console.warn(e);
        this.setState({
          hasError: true,
          errorText: e.message,
        });
        console.log("\nCaught exception: " + e);
      }
    })();
    //this.GetPandaPartList(this.state.craftweapon[index].allelements[element]);
    //this.GetPandaWeaponList(this.state.craftweapon[index].allelements[element]);

    if (localStorage.getItem("sound") == "true") {
      this.audioMusicNewClick.currentTime = 0;
      this.audioMusicNewClick.volume = 0.4;
      this.audioMusicNewClick.play();
    }
  }

  async backFromRecipe(index, element) {
    this.setState({
      ...this.state,
      openRecipe: false,
      activeEl: "",
      activeId: "",
    });

    if (localStorage.getItem("sound") == "true") {
      this.audioMusicNewClick.currentTime = 0;
      this.audioMusicNewClick.volume = 0.4;
      this.audioMusicNewClick.play();
    }
  }

  async GetPandaPartList(parts, type) {
    /*(async () =>  {
            try {*/
    var schema;
    var limit = 100;
    if (type == "res") {
      schema = "resources";
      limit = 100;
    } else {
      schema = "weapon";
      limit = 1000;
    }
    console.log("parts", parts);
    console.log("type", type);
    console.log("schema", schema);

    const userName = this.props.ual.activeUser.accountName;
    //const activeUser = this.props.ual.activeUser;
    var arr = [];
    if (type == "res") {
      arr = [parts];
    } else {
      arr = [parts.bluePrint, parts.partOne, parts.partTwo, parts.partThree];
    }
    //arr.push();
    // console.log("arr", arr);
    // console.log("this.state.activeRecipe.bluePrint", this.state.activeRecipe.bluePrint);
    return await api
      .getAssets({
        limit: limit,
        owner: userName,
        collection_name: "nftpandawaxp",
        schema_name: schema,
        //schema_name: ["weapon"/*, "promo"*/],
        template_id: arr,
      })
      .then((assets) => {
        return assets;

        //console.log('GetPandaPartList', assets);
        /*let blueprint = 0;
            let partOne = 0;
            let partTwo = 0;
            let partThree = 0;
            for (var c in assets) {
                //console.log(byTemplate[c]);
                if(assets[c].template.template_id == this.state.activeRecipe.bluePrint) {
                    blueprint += 1;
                } else if(assets[c].template.template_id == this.state.activeRecipe.partOne) {
                    partOne += 1;
                } else if(assets[c].template.template_id == this.state.activeRecipe.partTwo) {
                    partTwo += 1;
                } else if(assets[c].template.template_id == this.state.activeRecipe.partThree) {
                    partThree += 1;
                } 
            }

            this.setState({
                ...this.state,
                allparts: assets,
                counPartWeapon: {
                    bluePrint: blueprint,
                    partOne: partOne,
                    partTwo: partTwo,
                    partThree: partThree,
                }
            });*/
      });
    /*} catch (e) {
            this.setState({
                hasError: true,
                errorText: e.message,
            });                
            console.log('\nCaught exception: ' + e);
            if(localStorage.getItem('sound') == 'true') {
                this.audioMusicError.currentTime = 0;
                this.audioMusicError.volume = 0.1;
                this.audioMusicError.play();
            }
        
    })();}*/
  }

  /*async GetPandaWeaponList(parts)   {
        (async () =>  {
            try {
                const userName = this.props.ual.activeUser.accountName;
                const activeUser = this.props.ual.activeUser;
                var arr = [parts.weapon];
                //arr.push();
                // console.log("arr", arr);
                // console.log("this.state.activeRecipe.bluePrint", this.state.activeRecipe.bluePrint);
                const asset = await api.getAssets({
                    limit: 1000,
                    owner: userName,
                    collection_name: 'nftpandawaxp',
                    schema_name: ["weapon"],
                    template_id: arr,
                    }).then(assets => {

                    //console.log('GetPandaWeaponList(parts)', assets);

                    this.setState({
                        ...this.state,
                        allThisWeapon: assets,
                    });
                });
            } catch (e) {
                this.setState({
                    hasError: true,
                    errorText: e.message,
                });                
                console.log('\nCaught exception: ' + e);
                if(localStorage.getItem('sound') == 'true') {
                    this.audioMusicError.currentTime = 0;
                    this.audioMusicError.volume = 0.1;
                    this.audioMusicError.play();
                }
            }
        })();
    }*/

  async opencraftmodal() {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("open-modal");
    // const number = cryptoRandomString({length: 14, type: 'numeric'});
    // const userName = this.props.ual.activeUser.accountName;
    // const activeUser = this.props.ual.activeUser;

    // try {
    //     let rpcc;
    //     if (this.props.ual.activeUser.rpc != undefined) {
    //         rpcc = this.props.ual.activeUser.rpc
    //     } else if(this.props.ual.activeUser.rpc == undefined && this.props.ual.activeUser.wax) {
    //         rpcc = this.props.ual.activeUser.wax.rpc
    //     } else {
    //         rpcc = new JsonRpc(localStorage.getItem('rpc'));
    //     }
    //     //const userName = this.props.ual.activeUser.accountName;
    //     const results = await rpcc.get_table_rows({
    //         "json": true,
    //         "code": process.env.REACT_APP_CONTRACT,    // contract who owns the table
    //         "scope": process.env.REACT_APP_CONTRACT,   // scope of the table
    //         "table": "oracletable",    // name of the table as specified by the contract abi
    //         //"key_type": "i64",
    //         "lower_bound": userName,
    //         "upper_bound": userName,
    //         //"limit": -1,
    //         //reverse: !0,
    //         show_payer: false,
    //         //index_position: 2,
    //     });
    //     const resultRow = results.rows;
    //     //console.log('resultRow', resultRow[0]);
    //     if(resultRow[0]) {
    //         if(resultRow[0].storer == userName) {
    //             this.setState({
    //                 ...this.setState,
    //                 modalcraft: {
    //                     top: "Craft",
    //                     title: "Now the blacksmith is ready to start work",
    //                     button: "second",
    //                     descr: "hand over all parts of the weapon so that he can start working on his craft. It can take a few minutes, please wait..."
    //                 }
    //             });
    //         }
    //     }
    // } catch (e) {
    //    // console.log(e.message)
    //     console.warn(e)
    //     this.setState({
    //         hasError: true,
    //         errorText: e.message,
    //     });
    //     console.log('\nCaught exception: ' + e);
    //     if(localStorage.getItem('sound') == 'true') {
    //         this.audioMusicError.currentTime = 0;
    //         this.audioMusicError.volume = 0.1;
    //         this.audioMusicError.play();
    //     }
    // }

    this.setState({
      ...this.setState,
      craftpay: true,
    });

    if (localStorage.getItem("sound") == "true") {
      this.audioMusicOpenCraft.currentTime = 0;
      this.audioMusicOpenCraft.volume = 0.3;
      this.audioMusicOpenCraft.play();
    }
  }

  async craftpay() {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");
    const number = cryptoRandomString({ length: 14, type: "numeric" });
    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;

    try {
      await activeUser
        .signTransaction(
          {
            actions: [
              {
                account: process.env.REACT_APP_TOKEN,
                name: "transfer",
                authorization: [
                  {
                    actor: userName, // use account that was logged in
                    permission: "active",
                  },
                ],
                data: {
                  from: userName,
                  to: process.env.REACT_APP_CONTRACT,
                  quantity: "15.0000 " + process.env.REACT_APP_VAL,
                  memo: "weaponpay " + this.state.activeRecipe.weapon + " work",
                },
              },
            ],
          },
          { blocksBehind: 3, expireSeconds: 30 }
        )
        .then((response) => {
          if (response.status === "executed") {
            loadingSlot.classList.remove("loading");
            this.props.updateBalance(false, parseFloat(15.0).toFixed(4));
            this.setState({
              ...this.setState,
              modalcraft: {
                top: "Craft",
                title: "Now the blacksmith is ready to start work",
                button: "second",
                descr:
                  "hand over all parts of the weapon so that he can start working on his craft. It can take a few minutes, please wait...",
              },
            });
          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
        chooseHeroLoading: false,
      });

      if (localStorage.getItem("sound") == "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
      loadingSlot.classList.remove("loading");
    }
  }

  async sendWombat(event) {
    const activeUser = this.props.ual.activeUser;
    const accountName = await activeUser.getAccountName();
    var dateNow = parseInt(Date.now() / 1000);
    var datas = {

      "username": accountName,
      "event": event,
      "eventtime": dateNow,
    }

  }

  async craft() {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");
    const number = cryptoRandomString({ length: 14, type: "numeric" });
    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    var blueprint = 0;
    var partOne = 0;
    var partTwo = 0;
    var partThree = 0;

    var resource1 = 0;
    var resource2 = 0;
    var resource3 = 0;
    var resource4 = 0;
    var resource5 = 0;

    // console.log('this.state.activeRecipe.bluePrint', this.state.activeRecipe.bluePrint);
    // console.log('this.state.activeRecipe.partOne', this.state.activeRecipe.partOne);
    // console.log('this.state.activeRecipe.partTwo', this.state.activeRecipe.partTwo);
    // console.log('this.state.activeRecipe.partThree', this.state.activeRecipe.partThree);

    this.state.allparts.map((u) => {
      console.log("u[0]", u.template.template_id);
      if (resource1 == 0 && u.template.template_id == 431181) {
        resource1 = u.asset_id;
      } else if (resource2 == 0 && u.template.template_id == 431181) {
        resource2 = u.asset_id;
      } else if (resource3 == 0 && u.template.template_id == 431181) {
        resource3 = u.asset_id;
      } else if (resource4 == 0 && u.template.template_id == 431181) {
        resource4 = u.asset_id;
      } else if (resource5 == 0 && u.template.template_id == 431181) {
        resource5 = u.asset_id;
      } else if (
        blueprint == 0 &&
        u.template.template_id == this.state.activeRecipe.bluePrint
      ) {
        //console.log(4444);
        blueprint = u.asset_id;
      } else if (
        partOne == 0 &&
        u.template.template_id == this.state.activeRecipe.partOne
      ) {
        partOne = u.asset_id;
      } else if (
        partTwo == 0 &&
        u.template.template_id == this.state.activeRecipe.partTwo
      ) {
        partTwo = u.asset_id;
      } else if (
        partThree == 0 &&
        u.template.template_id == this.state.activeRecipe.partThree
      ) {
        partThree = u.asset_id;
      }
    });

    console.log(
      "parts",
      blueprint,
      partOne,
      partTwo,
      partThree,
      resource1,
      resource2,
      resource3,
      resource4,
      resource5
    );
    // console.log("this.state.activeRecipe.weapon", this.state.activeRecipe.weapon);
    // console.log("number", number);

    //console.log("craft " + this.state.activeRecipe.weapon + " " + number + " for panda");

    try {
      await activeUser
        .signTransaction(
          {
            actions: [
              {
                account: "atomicassets",
                name: "transfer",
                authorization: [
                  {
                    actor: userName, // use account that was logged in
                    permission: "active",
                  },
                ],
                data: {
                  from: userName,
                  to: process.env.REACT_APP_CONTRACT,
                  asset_ids: [
                    blueprint,
                    partOne,
                    partTwo,
                    partThree,
                    resource1,
                    resource2,
                    resource3,
                    resource4,
                    resource5,
                  ],
                  memo:
                    "craft " +
                    this.state.activeRecipe.weapon +
                    " " +
                    number +
                    " for panda",
                },
              },
            ],
          },
          { blocksBehind: 3, expireSeconds: 30 }
        )
        .then((response) => {
          if (response.status === "executed") {
            loadingSlot.classList.remove("loading");
            this.props.updateBalance(false, parseFloat(15.0).toFixed(4));
            this.setState({
              ...this.setState,
              craftpay: false,
              modalResult: {
                open: true,
                loading: true,
              },
            });
            this.getresult();
            this.sendWombat("craft");
          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
        chooseHeroLoading: false,
      });
      if (localStorage.getItem("sound") == "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
      loadingSlot.classList.remove("loading");
    }
  }

  async getresult() {
    (async () => {
      try {
        //console.log('try');
        const userName = this.props.ual.activeUser.accountName;
        const activeUser = this.props.ual.activeUser;
        const weaponsID = this.state.activeRecipe.weapon;

        const asset = await api
          .getAssets({
            limit: 500,
            owner: userName,
            collection_name: "nftpandawaxp",
            schema_name: ["weapon" /*, "promo"*/],
            template_id: weaponsID,
          })
          .then((assets) => {
            // console.log('getresult', assets);
            // console.log('getresult this.state.allThisWeapon', this.state.allThisWeapon);
            // console.log('getresult this.state.allThisWeapon', this.state.allThisWeapon);

            //console.log('byTemplate', assets);
            // this.setState({
            //     allThisWeapon: assets
            // });

            this.interval = setInterval(() => {
              var perc = 55;
              if (this.state.contractMedal) {
                var perc = 55 + this.state.contractMedal;
              }

              if (this.state.craftfail <= perc && this.state.craftfail != 0) {
                clearInterval(this.interval);

                console.log("YES IT DONE");
                this.setState({
                  ...this.state,
                  craftResult: "success",
                  modalResult: {
                    ...this.state.modalResult,
                    loading: false,
                  },
                });

                if (localStorage.getItem("sound") == "true") {
                  this.audioMusicCraftSuccess.currentTime = 0;
                  this.audioMusicCraftSuccess.volume = 0.4;
                  this.audioMusicCraftSuccess.play();
                }
              } else if (this.state.craftfail > perc) {
                console.log("NOOOOOOOOOOOOOOOOO");

                clearInterval(this.interval);
                this.setState({
                  ...this.state,
                  craftResult: "fail",
                  modalResult: {
                    ...this.state.modalResult,
                    loading: false,
                  },
                });

                if (localStorage.getItem("sound") == "true") {
                  this.audioMusicCraftfail.currentTime = 0;
                  this.audioMusicCraftfail.volume = 0.3;
                  this.audioMusicCraftfail.play();
                }
              }

              //this.GetPandaWeaponList(this.state.activeRecipe);
              this.getUserNoRes();
            }, 10000);
          });
      } catch (e) {
        //console.log(e.message)
        console.warn(e);
        this.setState({
          ...this.state,
          hasError: true,
          errorText: e.message,
          modalEat: {
            ...this.state.modalEat,
            loadingChooseEat: false,
          },
        });
        console.log("\nCaught exception: " + e);
        if (localStorage.getItem("sound") == "true") {
          this.audioMusicError.currentTime = 0;
          this.audioMusicError.volume = 0.1;
          this.audioMusicError.play();
        }
      }
    })();
  }

  async getUserNoRes() {
    //const number = cryptoRandomString({length: 14, type: 'numeric'});
    const userName = this.props.ual.activeUser.accountName;
    //const activeUser = this.props.ual.activeUser;

    try {
      let rpcc;
      if (this.props.ual.activeUser.rpc != undefined) {
        rpcc = this.props.ual.activeUser.rpc;
      } else if (
        this.props.ual.activeUser.rpc == undefined &&
        this.props.ual.activeUser.wax
      ) {
        rpcc = this.props.ual.activeUser.wax.rpc;
      } else {
        rpcc = new JsonRpc(localStorage.getItem("rpc"));
      }
      //const userName = this.props.ual.activeUser.accountName;
      const results = await rpcc.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "usersnew", // name of the table as specified by the contract abi
        //"key_type": "i64",
        lower_bound: userName,
        upper_bound: userName,
        //"limit": -1,
        //reverse: !0,
        show_payer: false,
        //index_position: 2,
      });
      const resultRow = results.rows;
      // console.log('resultRow getUserNoRes', resultRow[0]);
      if (resultRow[0]) {
        this.setState({
          ...this.setState,
          craftfail: resultRow[0].random,
        });
      }
    } catch (e) {
      // console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
      if (localStorage.getItem("sound") == "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  async closeModalResult() {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.remove("open-modal");

    console.log("this.state.activeRecipe", this.state.activeRecipe);
    this.setState(
      {
        ...this.state,
        // counPartWeapon: {
        //     bluePrint: '',
        //     partOne: '',
        //     partTwo: '',
        //     partThree: '',
        // },
        allparts: {}, // все части этого оружия который хотят скрафтить
        allThisWeapon: {}, // все оружия
        modalResult: {
          open: false, // показать модалку результата
          loading: true, // ожидание результата
        },
        craftpay: false, //
        modalcraft: {
          top: "Craft",
          title: "First you have to pay the blacksmith for a job",
          button: "first",
          descr:
            "The blacksmith always takes money for the front, since nothing motivates like a salary",
        },
        craftfail: "",
        craftResult: "",
      },
      this.openRecipe(this.state.activeId, this.state.activeEl, "refresh")
    );

    // console.log("close result this.state.activeRecipe", this.state.activeRecipe);
    //this.GetPandaPartList(this.state.activeRecipe);
    //this.GetPandaWeaponList(this.state.activeRecipe);
    //this.openRecipe(this.state.activeId, this.state.activeEl, "refresh");

    if (localStorage.getItem("sound") == "true") {
      this.audioMusicNewClick.currentTime = 0;
      this.audioMusicNewClick.volume = 0.4;
      this.audioMusicNewClick.play();
    }
  }

  async closeModalSteps() {
    this.setState({
      ...this.setState,
      craftpay: false,
    });
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.remove("open-modal");

    if (localStorage.getItem("sound") == "true") {
      this.audioMusicNewClick.currentTime = 0;
      this.audioMusicNewClick.volume = 0.4;
      this.audioMusicNewClick.play();
    }
  }

  render() {
    return (
      <>
        <Menu  {...this.props.ual} wax={this.props.balanceWax} bam={this.props.balanceBam} game={this.props.balanceGame} updateBalance={this.props.updateBalance} />
        {this.state.hasError ? (
          <ErrorComponent
            errorMessage={this.state.errorText}
            clearError={this.clearError}
          />
        ) : null}
        <main className="craft-page weapon-craft-page">
          <div className="loading-block"></div>

          <div className="craft-wrap craft-page-bg">
            {this.state.openRecipe ||
              (this.state.activeEl &&
                this.state.activeEl >= 0 &&
                this.state.activeId &&
                this.state.activeId >= 0) ? null : (
              <div className="craft-body-choose">
                <div className="choose-weapon-craft">
                  <div className="choose-weapon-craft-in">
                    <Link className="bt-back" to="/craft">
                      <span></span>
                    </Link>
                    <CraftHomeLoop
                      {...this.props}
                      {...this.state}
                      openRecipe={this.openRecipe}
                    />
                  </div>
                </div>
              </div>
            )}

            {this.state.openRecipe ||
              (this.state.activeEl &&
                this.state.activeEl >= 0 &&
                this.state.activeId &&
                this.state.activeId >= 0) ? (
              <div className="craft-recipe">
                <div className="craft-recipe-in">
                  <div className="craft-recipe-top">
                    {/* <div className="bt-back">
                                        <span onClick={(e) => {this.backFromRecipe()}}></span>
                                    </div> */}
                    <Link
                      className="bt-back"
                      to="/craft-weapon-page"
                      onClick={(e) => {
                        this.backFromRecipe();
                      }}
                    >
                      <span></span>
                    </Link>
                    <div
                      className="bt-reload"
                      onClick={(e) => {
                        this.openRecipe(
                          this.state.activeId,
                          this.state.activeEl
                        );
                      }}
                    >
                      <span></span>
                    </div>
                  </div>
                  <div className="craft-recipe-wrap">
                    <div
                      className={`craft-recipe-wrap-in ${this.state.loadingNft ? "loading" : "ok"
                        }`}
                    >
                      {/* one part */}
                      <div
                        className={`one-craft-part blueprint ${this.state.counPartWeapon.bluePrint
                          ? "ok"
                          : "disabled"
                          }`}
                      >
                        <div className="one-craft-part-in">
                          <div className="one-craft-part-thumb">
                            <picture>
                              <source
                                srcSet={`/images/weapons/all/${this.state.activeRecipe.bluePrint}.webp`}
                                type="image/webp"
                              />
                              <source
                                srcSet={`/images/weapons/all/${this.state.activeRecipe.bluePrint}.png`}
                                type="image/png"
                              />
                              <img
                                src={`/images/weapons/all/${this.state.activeRecipe.bluePrint}.png`}
                                alt=""
                              />
                            </picture>
                          </div>
                          <div className="one-part-bay">
                            <NavLink className="def-bt min" to="/shop">
                              <span className="button-in">
                                <span className="button-name">
                                  <span className="button-name-in">
                                    <span className="button-name-value">
                                      buy
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </NavLink>
                          </div>
                          <div className="part-num">
                            {this.state.counPartWeapon.bluePrint
                              ? this.state.counPartWeapon.bluePrint
                              : 0}
                            /1
                          </div>

                          <div className="loading-block"></div>
                        </div>
                      </div>
                      {/* one part */}
                      {/* one part */}
                      <div
                        className={`one-craft-part part-w ${this.state.counPartWeapon.partOne ? "ok" : "disabled"
                          }`}
                      >
                        <div className="plus"></div>
                        <div className="one-craft-part-in">
                          <div className="one-craft-part-thumb">
                            <picture>
                              <source
                                srcSet={`/images/weapons/all/${this.state.activeRecipe.partOne}.webp`}
                                type="image/webp"
                              />
                              <source
                                srcSet={`/images/weapons/all/${this.state.activeRecipe.partOne}.png`}
                                type="image/png"
                              />
                              <img
                                src={`/images/weapons/all/${this.state.activeRecipe.partOne}.png`}
                                alt=""
                              />
                            </picture>
                          </div>
                        </div>
                        <div className="one-part-bay">
                          <NavLink className="def-bt min" to="/shop">
                            <span className="button-in">
                              <span className="button-name">
                                <span className="button-name-in">
                                  <span className="button-name-value">buy</span>
                                </span>
                              </span>
                            </span>
                          </NavLink>
                        </div>
                        <div className="part-num">
                          {this.state.counPartWeapon.partOne
                            ? this.state.counPartWeapon.partOne
                            : 0}
                          /1
                        </div>
                        <div className="loading-block"></div>
                      </div>
                      {/* one part */}
                      {/* one part */}
                      <div
                        className={`one-craft-part part-w ${this.state.counPartWeapon.partTwo ? "ok" : "disabled"
                          }`}
                      >
                        <div className="plus"></div>
                        <div className="one-craft-part-in">
                          <div className="one-craft-part-thumb">
                            <picture>
                              <source
                                srcSet={`/images/weapons/all/${this.state.activeRecipe.partTwo}.webp`}
                                type="image/webp"
                              />
                              <source
                                srcSet={`/images/weapons/all/${this.state.activeRecipe.partTwo}.png`}
                                type="image/png"
                              />
                              <img
                                src={`/images/weapons/all/${this.state.activeRecipe.partTwo}.png`}
                                alt=""
                              />
                            </picture>
                          </div>
                        </div>
                        <div className="one-part-bay">
                          <NavLink className="def-bt min" to="/shop">
                            <span className="button-in">
                              <span className="button-name">
                                <span className="button-name-in">
                                  <span className="button-name-value">buy</span>
                                </span>
                              </span>
                            </span>
                          </NavLink>
                        </div>
                        <div className="part-num">
                          {this.state.counPartWeapon.partTwo
                            ? this.state.counPartWeapon.partTwo
                            : 0}
                          /1
                        </div>
                        <div className="loading-block"></div>
                      </div>
                      {/* one part */}
                      {/* one part */}
                      <div
                        className={`one-craft-part part-w ${this.state.counPartWeapon.partThree
                          ? "ok"
                          : "disabled"
                          }`}
                      >
                        <div className="plus"></div>
                        <div className="one-craft-part-in">
                          <div className="one-craft-part-thumb">
                            <picture>
                              <source
                                srcSet={`/images/weapons/all/${this.state.activeRecipe.partThree}.webp`}
                                type="image/webp"
                              />
                              <source
                                srcSet={`/images/weapons/all/${this.state.activeRecipe.partThree}.png`}
                                type="image/png"
                              />
                              <img
                                src={`/images/weapons/all/${this.state.activeRecipe.partThree}.png`}
                                alt=""
                              />
                            </picture>
                          </div>
                        </div>
                        <div className="one-part-bay">
                          <NavLink className="def-bt min" to="/shop">
                            <span className="button-in">
                              <span className="button-name">
                                <span className="button-name-in">
                                  <span className="button-name-value">buy</span>
                                </span>
                              </span>
                            </span>
                          </NavLink>
                        </div>
                        <div className="part-num">
                          {this.state.counPartWeapon.partThree
                            ? this.state.counPartWeapon.partThree
                            : 0}
                          /1
                        </div>
                        <div className="loading-block"></div>
                      </div>
                      {/* one part */}
                      {/* one res */}
                      <div
                        className={`one-craft-part part-w res-part ${this.state.counPartWeapon.resource &&
                          this.state.counPartWeapon.resource.length >= 5
                          ? "ok"
                          : "disabled"
                          }`}
                      >
                        <div className="plus"></div>
                        <div className="one-craft-part-in">
                          <div className="one-craft-part-thumb">
                            <picture>
                              <source
                                srcSet={`/images/jew/all/431181.webp`}
                                type="image/webp"
                              />
                              <source
                                srcSet={`/images/jew/all/431181.png`}
                                type="image/png"
                              />
                              <img src={`/images/jew/all/431181.png`} alt="" />
                            </picture>
                          </div>
                        </div>
                        <div className="one-part-bay">
                          <a
                            className="def-bt min"
                            href="https://wax.atomichub.io/market?collection_name=nftpandawaxp&data:text.rarity=Common&order=desc&schema_name=resources&sort=created&symbol=WAX"
                            target="_blank"
                          >
                            <span className="button-in">
                              <span className="button-name">
                                <span className="button-name-in">
                                  <span className="button-name-value">buy</span>
                                </span>
                              </span>
                            </span>
                          </a>
                        </div>
                        <div className="part-num">
                          {this.state.counPartWeapon.resource &&
                            this.state.counPartWeapon.resource.length &&
                            this.state.counPartWeapon.resource.length}
                          /5
                        </div>
                        <div className="loading-block"></div>
                      </div>
                      {/* one res */}
                    </div>
                  </div>

                  <div className="price-craft">
                    <span>15</span>
                  </div>

                  <div className="craft-recipe-bt">
                    <div className="craft-bt">
                      {this.state.counPartWeapon.bluePrint &&
                        this.state.counPartWeapon.partOne &&
                        this.state.counPartWeapon.partTwo &&
                        this.state.counPartWeapon.partThree &&
                        this.state.counPartWeapon.resource &&
                        this.state.counPartWeapon.resource.length >= 5 ? (
                        <span
                          onClick={(e) => {
                            this.opencraftmodal();
                          }}
                        ></span>
                      ) : (
                        <span className="disabled"></span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {this.state.modalResult.open && (
              <div
                className={`modal-result ${this.state.modalResult.open ? "open" : null
                  } ${this.state.craftResult == "success" ? "success" : null} ${this.state.craftResult == "fail" ? "fail" : null
                  }`}
              >
                <div className="modal-result-body">
                  <div className="modal-result-body-in">
                    <div className="modal-result-head">
                      {!this.state.modalResult.loading ? (
                        <div
                          className="close-result"
                          onClick={(e) => {
                            this.closeModalResult();
                          }}
                        >
                          <span></span>
                        </div>
                      ) : null}
                      <div className="weapon-modal-name">
                        {this.state.activeWeaponName}
                      </div>
                      <div className="weapon-modal-wrap">
                        <div className="fail-res"></div>
                        <div className="success-res"></div>
                        {this.state.modalResult.loading ? (
                          <div className="loading-res"></div>
                        ) : null}
                        <div className="weapon-modal-img">
                          {this.state.activeRecipe.weapon ? (
                            <picture>
                              <source
                                srcSet={`/images/weapons/all/${this.state.activeRecipe.weapon}.webp`}
                                type="image/webp"
                              />
                              <source
                                srcSet={`/images/weapons/all/${this.state.activeRecipe.weapon}.png`}
                                type="image/png"
                              />
                              <img
                                src={`/images/weapons/all/${this.state.activeRecipe.weapon}.png`}
                                alt=""
                              />
                            </picture>
                          ) : null}
                        </div>
                        {!this.state.modalResult.loading ? (
                          <div className="close-bt">
                            <button
                              className="def-bt min"
                              onClick={(e) => {
                                this.closeModalResult();
                              }}
                            >
                              <span className="button-in">
                                <span className="button-name">
                                  <span className="button-name-in">
                                    <span className="button-name-value">
                                      close
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="modal-result-close-trans"></div>
                </div>
                <div className="modal-weapon-bg"></div>
              </div>
            )}

            {this.state.craftpay && (
              <div
                className={`modal-result craftpay ${this.state.craftpay ? "open" : null
                  }`}
              >
                <div className="modal-result-body">
                  <div className="modal-result-body-in">
                    <div className="modal-result-head">
                      <div
                        className="close-result"
                        onClick={(e) => {
                          this.closeModalResult();
                        }}
                      >
                        <span></span>
                      </div>

                      <div className="weapon-modal-name">
                        {this.state.modalcraft.top}
                      </div>
                      <div className="weapon-modal-wrap">
                        <div className="weapon-modal-img">
                          <div className="step-wrap">
                            <div className="step-title">
                              {this.state.modalcraft.title}
                            </div>
                            <div className="step-loader">
                              <div className="loading-res"></div>
                              <div className="step-bt">
                                {/* {this.state.modalcraft.button != "first" ?  */}
                                <button
                                  className="def-bt min"
                                  onClick={(e) => {
                                    this.craft();
                                  }}
                                >
                                  <span className="button-in">
                                    <span className="button-name">
                                      <span className="button-name-in">
                                        <span className="button-name-value pad1">
                                          craft
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </button>
                                {/* :
                                                            <button className="def-bt min" onClick={(e) => {this.craft()}}>
                                                                <span className="button-in">
                                                                    <span className="button-name">
                                                                        <span className="button-name-in">
                                                                            <span className="button-name-value">
                                                                            Craft
                                                                            </span> 
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </button>
                                                        } */}
                              </div>
                            </div>
                            {this.state.modalcraft.button == "first" ? (
                              <div className="info-55">
                                <span>Attention:</span> the chance of successful{" "}
                                <br /> crafting is{" "}
                                <i>
                                  {this.state.contractMedal
                                    ? 55 + this.state.contractMedal
                                    : "55"}
                                  %
                                </i>
                              </div>
                            ) : null}
                            <div
                              className="step-descr"
                              dangerouslySetInnerHTML={{
                                __html: this.state.modalcraft.descr,
                              }}
                            >
                              {/* {this.state.modalcraft.descr} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-result-close-trans"></div>
                </div>
                <div className="modal-weapon-bg"></div>
              </div>
            )}
          </div>

          <footer>
            <div className="footer-nav">
              <nav>
                <ul>
                  <li>
                    <a
                      className="foot-logo-1"
                      target="_blank"
                      href="https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&sort=created&symbol=WAX"
                    >
                      <span></span>
                      <i>Market</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-2"
                      target="_blank"
                      href="https://twitter.com/NftPanda/"
                    >
                      <span></span>
                      <i>Twitter</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-3"
                      target="_blank"
                      href="https://www.instagram.com/nftpanda/"
                    >
                      <span></span>
                      <i>Instagram</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-4"
                      target="_blank"
                      href="https://discord.gg/vCSgme4d5b"
                    >
                      <span></span>
                      <i>Discord</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-5"
                      target="_blank"
                      href={`https://wax.alcor.exchange/swap?output=${process.env.REACT_APP_VAL}-nftpandabamb&input=WAX-eosio.token`}
                    >
                      <span></span>
                      <i>{process.env.REACT_APP_VAL} Trade</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-6"
                      target="_blank"
                      href="https://discord.gg/vCSgme4d5b"
                    >
                      <span></span>
                      <i>Support</i>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </footer>
        </main>
      </>
    );
  }
}

// let mapStateToProps = (state) => ({
//     allinvent: state.inventoryReduser.allinvent,
// });

let mapStateToProps = (state) => ({
  /*allWeapon: state.weaponReduser.allWeapon,
    statusWeapon: state.weaponReduser.statusWeapon,*/
});

export default connect(mapStateToProps, {
  /*weaponlist,statusLoadinWeapon, addWeaponWhenRemove, addWeaponToPanda,*/
})(CraftPageComponentWeapon);
