import React, { useState } from 'react';
import DetalLoop from './DetalLoop';

import rewtop from '../../images/new-adv/mult-res-top.png';
import rewtopw from '../../images/new-adv/mult-res-top.webp';
import rewbot from '../../images/new-adv/mult-res-bot.png';
import rewbotw from '../../images/new-adv/mult-res-bot.webp';


const DetalMultInfo = (props) => {

    function handleClick() {
        props.setShowDetail(false);
    }

    return (
        <>
            <div className={`detal-mult-modal open anim`}>
                <div className="detal-mult-body">
                    <div className="detal-mult-body-in">
                        <div className="detal-mult-res">
                            <div className="detal-mult-top">
                                <picture>
                                    <source srcSet={rewtopw} type="image/webp" />
                                    <source srcSet={rewtop} type="image/png" />
                                    <img src={rewtop} alt="" />
                                </picture>
                            </div>
                            <div className="detal-mult-list">
                                <DetalLoop {...props} />

                            </div>
                            <div className="detal-mult-bot">
                                <picture>
                                    <source srcSet={rewbotw} type="image/webp" />
                                    <source srcSet={rewbot} type="image/png" />
                                    <img src={rewbot} alt="" />
                                </picture>

                                <div className="detal-close">
                                    <button className="def-bt norm" onClick={() => props.openModalResult(false, props.allSumVar)} >
                                        <span className="button-in">
                                            <span className="button-name">
                                                <span className="button-name-in">
                                                    <span className="button-name-value">
                                                        Close
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-weapon-bg-trans"></div>
                </div>
                <div className="modal-weapon-bg"></div>
            </div>

        </>);
}

export default DetalMultInfo;