import React, { useEffect, useRef } from "react";
import PandaVideoComp from './../PandaVideoComp';

import bgOpt from './../../images/BOSS/panda-options-bg.png'
import bgOptw from './../../images/BOSS/panda-options-bg.webp'

import pandaopt1 from './../../images/BOSS/option-1.png'
import pandaopt1w from './../../images/BOSS/option-1.webp'
import pandaopt2 from './../../images/BOSS/option-2.png'
import pandaopt2w from './../../images/BOSS/option-2.webp'
import pandaopt3 from './../../images/BOSS/option-3.png'
import pandaopt3w from './../../images/BOSS/option-3.webp'

import skillbg from './../../images/BOSS/skill-bg.png'
import skillbgw from './../../images/BOSS/skill-bg.webp'

import skill1 from './../../images/BOSS/skill1.png'
import skill1w from './../../images/BOSS/skill1.webp'
import skill2 from './../../images/BOSS/skill2.png'
import skill2w from './../../images/BOSS/skill2.webp'
import skill3 from './../../images/BOSS/skill3.png'
import skill3w from './../../images/BOSS/skill3.webp'



const ActivePandaInfo = (props) => {

    const widthScale = props.widthScale;
    const windowWidth = props.windowWidth;
    const pandaInfo = props.activePandaInfo;

    const pandaDetal = [
        {
            ico: [pandaopt1, pandaopt1w],
            name: "health",
            num: pandaInfo.onePanda.energy,
            size: [53, 46]
        },
        {
            ico: [pandaopt2, pandaopt2w],
            name: "armor",
            num: pandaInfo.onePanda.energy,
            size: [49, 49]
        },
        {
            ico: [pandaopt3, pandaopt3w],
            name: "magic Armor",
            num: pandaInfo.onePanda.energy,
            size: [42, 49]
        }
    ];

    const pandaSkills = [
        {
            ico: [skill1, skill1w],
            active: true,
            name: ""
        },
        {
            ico: [skill2, skill2w],
            active: false,
            name: ""
        },
        {
            ico: [skill3, skill3w],
            active: false,
            name: ""
        }
    ]

    console.log("pandaInfo", pandaInfo);

    return (

        <div className="active-panda-info">
            <div className="active-panda-thumb">
                <PandaVideoComp fullNameRare={pandaInfo.onePanda.name_pa} pandaName={pandaInfo.onePandaName} cardName={pandaInfo.cardRare + pandaInfo.imgRare} element={pandaInfo.onePanda.element} onlyThumb={true} />
            </div>
            <div className="active-panda-pref">
                <div className="active-panda-param">
                    {pandaDetal.map((item) => {
                        return (<div className="one-param">
                            <div className="param-bg">
                                <picture>
                                    <source srcSet={bgOptw} type="image/webp" />
                                    <source srcSet={bgOpt} type="image/png" />
                                    <img src={bgOptw} alt="" />
                                </picture>
                            </div>
                            <div className="one-param-in">
                                <div className="one-param-ico" style={{ width: 60 * widthScale, flex: `0 0 ${60 * widthScale}px`, marginLeft: 15 * widthScale }}>
                                    <picture>
                                        <source srcSet={item.ico[1]} type="image/webp" />
                                        <source srcSet={item.ico[0]} type="image/png" />
                                        <img src={item.ico[1]} width={widthScale * item.size[0]} height={widthScale * item.size[1]} alt="" />
                                    </picture>
                                </div>
                                <div className="one-param-descr">
                                    <div className="one-param-name" style={{ fontSize: 18 * widthScale }}>
                                        {item.name}
                                    </div>
                                    <div className="one-param-num" style={{ fontSize: 45 * widthScale }}>
                                        {item.num}
                                    </div>
                                </div>
                            </div>
                        </div>)
                    })}

                </div>
                <div className="active-panda-skill">
                    {pandaSkills.map((item) => {
                        return (
                            <div className={`one-skill-panda ${item.active ? "active" : "disabled"}`}>
                                <picture>
                                    <source srcSet={skillbgw} type="image/webp" />
                                    <source srcSet={skillbg} type="image/png" />
                                    <img src={skillbgw} width={widthScale * 110} height={widthScale * 115} alt="" />
                                </picture>
                                <div className="ico-skill">
                                    <picture>
                                        <source srcSet={item.ico[1]} type="image/webp" />
                                        <source srcSet={item.ico[0]} type="image/png" />
                                        <img src={item.ico[1]} width={widthScale * 103} height={widthScale * 102} alt="" />
                                    </picture>
                                </div>
                            </div>)
                    })}
                </div>
            </div>
        </div>

    );
};


export default ActivePandaInfo;