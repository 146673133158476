import React from 'react';
import Menu from '../../components/Menu';
import Slider from 'rc-slider';

import 'rc-slider/assets/index.css';
//import confetti from "https://cdn.skypack.dev/canvas-confetti@1";
import confetti from "canvas-confetti";
import { connect } from "react-redux";
import { questitems, setQuestInfo, setNewQuestInfo, changeTabs, canComplateQuest, getQuestDisp, getQuestDispNew, dis } from "../../GlobalState/quest-reducer"
import {
    BrowserRouter, Redirect, Route, Switch, Link, NavLink,
    useParams,
    useRouteMatch
} from 'react-router-dom';

import ErrorComponent from "../Error/ErrorComponent";

import { JsonRpc } from 'eosjs'
import './../../css/tavern.scss';

import bg from '../../images/tavern/tavern-bg.jpg';
import bgw from '../../images/tavern/tavern-bg.webp';

import barmen from '../../images/tavern/barmen.png';
import barmenw from '../../images/tavern/barmen.webp';

import art from '../../images/tavern/art-quest-for-new.png';
import artw from '../../images/tavern/art-quest-for-new.webp';

import reward from '../../images/tavern/you-reward.png';
import rewardw from '../../images/tavern/you-reward.webp';

import modaltop from '../../images/tavern/modal-top.png';
import modaltopw from '../../images/tavern/modal-top.webp';

import tit from '../../images/tavern/tit.png';
import titw from '../../images/tavern/tit.png';

import modalbot from '../../images/tavern/modal-bot.png';
import modalbotw from '../../images/tavern/modal-bot.webp';

import tab from '../../images/tavern/mob-tab.png';
import tabw from '../../images/tavern/mob-tab.webp';

import chestMin1 from '../../images/tavern/chest-mid-1.png';
import chestMin1W from '../../images/tavern/chest-mid-1.webp';

import lootDarg from '../../images/tavern/loot-darg.png';
import lootDargW from '../../images/tavern/loot-darg.webp';

import chest from '../../images/alien/alien-chest.png';
import chestw from '../../images/alien/alien-chest.webp';

import musicOpen from './../../music/open.mp3'
import musicClose from './../../music/close.mp3'
import musicError from './../../music/error.mp3'

import { ExplorerApi } from "atomicassets" // атомик для списка панд

import Modal from './Modal';

import OneTab from './OneTab';
import OneTabNewQuest from './OneTabNewQuest';
import { forEach, set } from 'lodash';


const fetch = require('node-fetch');

const api = new ExplorerApi("https://" + "wax.api.atomicassets.io", "atomicassets", { fetch, rateLimit: 4 });

var arrHub = [
    "aa-wax-public1.neftyblocks.com",
    "wax.api.atomicassets.io",
    "api.wax-aa.bountyblok.io",
    "aa.dapplica.io",
    "api.atomic.greeneosio.com",
    "atomic.wax.eosrio.io",
    "wax-aa.eu.eosamsterdam.net",
    "atomic-wax-mainnet.wecan.dev",
    "atomic.sentnl.io",
    // "atomic.tokengamer.io",
    // "atomic.ledgerwise.io",
    // "api-wax-aa.eosarabia.net",
    // "wax.hkeos.com/aa",
    // "wax-atomic.wizardsguild.one",
    // "wax-atomic.eosiomadrid.io",
    // "aa.wax.blacklusion.io",
    // "wax-atomic-api.eosphere.io",
    // "wax-aa.eosdublin.io"
]


var numRpc = 0;
var arrRpc = ['wax.pink.gg', 'wax.cryptolions.io', 'wax.greymass.com', 'api.wax.alohaeos.com', 'api.waxsweden.org', 'wax.eosphere.io', 'api.wax.greeneosio.com', 'wax.eu.eosamsterdam.net', 'api.wax.bountyblok.io']


const api2 = new ExplorerApi("https://" + arrHub[numRpc], "atomicassets", { fetch, rateLimit: 4 });



// это транзакция отправки ИЗ
const getQuest = {
    actions: [{
        account: process.env.REACT_APP_CONTRACT,
        name: 'getquest',
        authorization: [{
            actor: '', // use account that was logged in
            permission: 'active',
        }],
        data: {
            username: '',
            count: '',
        },

    }],
}

// это транзакция отправки ИЗ
const getNewQuest = {
    actions: [{
        account: process.env.REACT_APP_CONTRACT,
        name: 'getnewquest',
        authorization: [{
            actor: '', // use account that was logged in
            permission: 'active',
        }],
        data: {
            username: '',
            count: '',
        },

    }],
}

// это транзакция отправки ИЗ
const doneNewQuest = {
    actions: [{
        account: process.env.REACT_APP_CONTRACT,
        name: 'donenewquest',
        authorization: [{
            actor: '', // use account that was logged in
            permission: 'active',
        }],
        data: {
            username: '',
            count: '',
        },

    }],
}

const sendAllPart = {
    actions: [
        {
            account: 'atomicassets',
            name: 'transfer',
            authorization: [{
                actor: "", // use account that was logged in
                permission: 'active',
            }],
            data: {
                from: "",
                to: process.env.REACT_APP_CONTRACT,
                asset_ids: [],
                memo: "quest "
            },
        },
    ]
}

const cardTransferChest = {
    actions: [
        {
            account: "atomicassets",
            name: "transfer",
            authorization: [
                {
                    actor: "", // use account that was logged in
                    permission: "active",
                },
            ],
            data: {
                from: "",
                to: process.env.REACT_APP_CONTRACT,
                asset_ids: [],
                memo: "",
            },
        },
    ],
};

var barmenText;
let newData = {};
var arreyObj = [];
var myCanvas;

var myConfetti;

class TavernComponent extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            openQuest: false,
            barmenQuest: {
                show: false,
                anim: false,
                textShow: false,
                showAllQuest: false
            },
            typeQuest: "",
            canComplateLocal: false,
            activeTabLocal: 0,
            rewardModal: false,
            rewardId: [],
            allresgroupLocal: [],
            idAuction: "",
            openModalChest: false,
            allUserChest: [],
            openChest: false,
            animChest: false,
            canOpenChest: false,
            newListNFTFromChest: [],
            actualListNFTFromChest: [],
            activeChest: 0
        }

        this.clearError = this.clearError.bind(this);
        this.wordflick = this.wordflick.bind(this);
        this.openBarmen = this.openBarmen.bind(this);
        this.openBarmenQuest = this.openBarmenQuest.bind(this);
        this.GetQuestList = this.GetQuestList.bind(this);
        this.changeQuest = this.changeQuest.bind(this);
        this.getQuest = this.getQuest.bind(this);
        this.getNewQuest = this.getNewQuest.bind(this);
        this.canComplate = this.canComplate.bind(this);
        this.sendQuest = this.sendQuest.bind(this);
        this.sendQuestNew = this.sendQuestNew.bind(this);
        this.RewadrModal = this.RewadrModal.bind(this);
        this.userCheckLot = this.userCheckLot.bind(this);
        this.getQwestInfoAll = this.getQwestInfoAll.bind(this);
        this.getQwestInfoArt = this.getQwestInfoArt.bind(this);
        this.openChest = this.openChest.bind(this);
        this.openChestFront = this.openChestFront.bind(this);
        this.openNft = this.openNft.bind(this);
        this.nextNft = this.nextNft.bind(this);
        this.openChestModal = this.openChestModal.bind(this);
        this.getUserChest = this.getUserChest.bind(this);
        this.navChest = this.navChest.bind(this);

        this.audioOpen = new Audio(musicOpen);
        this.audioClose = new Audio(musicClose);
        this.audioMusicError = new Audio(musicError);

    }

    componentDidMount() {
        localStorage.setItem('tavernModal3', true);
        window.scrollTo(0, 0);
        var loadingSlot = document.getElementsByTagName("body")[0];
        loadingSlot.classList.remove("open-modal");
        this.userCheckLot();

        this.getQwestInfoAll();
        this.getQwestInfoArt();

        (async () => {
            let prom = []
            for (let i = 0; i < this.props.allTmpl.length; i++) {
                prom.push(this.GetQuestList(this.props.allTmpl[i]));
                //console.log(111);
            }
            let result = await Promise.all(prom);

            //console.log("result", result);
            //console.log(typeof arreyObj)
            //console.log(654);
            this.setState({
                ...this.state,
                allresgroupLocal: result
            });
            //this.props.questitems(arreyObj);
            if (this.state.allresgroupLocal) {
                this.canComplate(0);
            }
        })();

        //this.GetQuestList();
        barmenText = document.getElementsByClassName("before-quest-body")[0];
        this.props.hideQuestBt(false);

        myCanvas = document.getElementById('canvas');

        myConfetti = confetti.create(myCanvas, {
            resize: true,
            useWorker: true
        });
    }

    componentWillUnmount() {
        this.props.hideQuestBt(true);
    }

    async getQwestInfoAll() {
        try {
            const accountName = this.props.ual.activeUser.accountName;

            var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);

            await rpcc.get_table_rows({
                "json": true,
                "code": process.env.REACT_APP_CONTRACT,    // contract who owns the table
                "scope": process.env.REACT_APP_CONTRACT,   // scope of the table
                "table": "quests",    // name of the table as specified by the contract abi
                "limit": 1,
                lower_bound: accountName,
                upper_bound: accountName,
                "index_position": 1,
                reverse: false,
                show_payer: false
            }).then(result => {

                if (result.rows[0]) {

                    this.props.setQuestInfo(result.rows[0]);
                    /*if(result.rows[0].quest1) {
                      this.props.changeTabs(0);
                    } else if(result.rows[0].quest2) {
                      this.props.changeTabs(1);
                    }*/
                }
            });

        } catch (e) {
            //console.log(e.message)
            console.warn(e)
            // this.setState({
            //     hasError: true,
            //     errorText: e.message,
            // });
            console.log('\nCaught exception: ' + e);
            setTimeout(() => {
                this.getusers();
                if (numRpc == 8) {
                    numRpc = 0;
                } else {
                    numRpc++;
                }
            }, 1000);

        }

    }


    async getQwestInfoArt() {
        try {
            const accountName = this.props.ual.activeUser.accountName;

            var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);

            await rpcc.get_table_rows({
                "json": true,
                "code": process.env.REACT_APP_CONTRACT,    // contract who owns the table
                "scope": process.env.REACT_APP_CONTRACT,   // scope of the table
                "table": "questsnew",    // name of the table as specified by the contract abi
                "limit": 1,
                lower_bound: accountName,
                upper_bound: accountName,
                "index_position": 1,
                reverse: false,
                show_payer: false
            }).then(result => {

                if (result.rows[0]) {

                    this.props.setNewQuestInfo(result.rows[0]);
                    /*if(result.rows[0].quest1) {
                      this.props.changeTabs(0);
                    } else if(result.rows[0].quest2) {
                      this.props.changeTabs(1);
                    }*/
                }
            });

        } catch (e) {
            //console.log(e.message)
            console.warn(e)
            // this.setState({
            //     hasError: true,
            //     errorText: e.message,
            // });
            console.log('\nCaught exception: ' + e);
            setTimeout(() => {
                this.getusers();
                if (numRpc == 8) {
                    numRpc = 0;
                } else {
                    numRpc++;
                }
            }, 1000);

        }

    }

    async userCheckLot() {
        try {
            //console.log(555555555555555555555555555555555555555555555);
            const activeUser = this.props.ual.activeUser;
            const accountName = await activeUser.getAccountName();
            //console.log(activeUser);
            //console.log(accountName);
            // let rpcc;
            // if (this.props.ual.activeUser.rpc !== undefined) {
            //     rpcc = this.props.ual.activeUser.rpc
            // } else if (this.props.ual.activeUser.rpc === undefined && this.props.ual.activeUser.wax) {
            //     rpcc = this.props.ual.activeUser.wax.rpc
            // } else {
            //     rpcc = new JsonRpc(localStorage.getItem('rpc'));
            // }
            var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);
            const results = await rpcc.get_table_rows({
                "json": true,
                "code": process.env.REACT_APP_CONTRACT,    // contract who owns the table
                "scope": process.env.REACT_APP_CONTRACT,   // scope of the table
                "table": "auctiontab",    // name of the table as specified by the contract abi
                "limit": 1,
                reverse: 1,
                show_payer: false,
            });

            //console.log("results", results);

            const keyauto = results.rows[0].keyauto;

            this.setState({
                ...this.state,
                idAuction: keyauto
            });

        } catch (e) {
            //console.log(e.message)
            console.warn(e)
            this.props.hasError(true, e.message);

            var loadingSlot = document.getElementsByTagName("body")[0];
            loadingSlot.classList.remove("loading");

            console.log('\nCaught exception: ' + e);


            setTimeout(() => {
                this.userCheckLot();
                if (numRpc == 8) {
                    numRpc = 0;
                } else {
                    numRpc++;
                }
            }, 1000);
        }

    }

    async GetQuestList(tmpl) {

        //console.log("tmpltmpltmpltmpltmpltmpl", tmpl)
        const userName = this.props.ual.activeUser.accountName;
        const activeUser = this.props.ual.activeUser;
        var shemaname = []
        if (tmpl != 111111 && tmpl != 222222 && tmpl != 333333) {
            shemaname = ["resources", "weapon", "jewelry"]
        } else if (tmpl == 111111) {
            tmpl = [];
            shemaname = ["gems"]
        } else if (tmpl == 222222) {
            tmpl = [];
            shemaname = ["promo"]
        } else if (tmpl == 333333) {
            tmpl = [];
            shemaname = ["armor"]

        }


        /*console.log("tmpl", tmpl);
        console.log("shemaname", shemaname);*/
        return await api.getAssets({
            limit: 1000,
            owner: userName,
            collection_name: 'nftpandawaxp',
            schema_name: shemaname,
            template_id: tmpl,
        }).then(assets =>
            assets.map(item => {

                return {
                    asset_id: item.asset_id,
                    template_id: item.template.template_id,
                    shema: item.schema.schema_name,
                    rarity: shemaname == "gems" ? "none" : item.data.rarity
                }
            })
        )
    }

    async clearError() {
        this.setState({
            errorText: '',
            hasError: false
        });
    }

    wordflick(words) {

        var part,
            i = 0,
            offset = 0,
            len = words.length,
            forwards = true,
            skip_count = 0,
            skip_delay = 15,
            speed = 50;

        var ss = setInterval(function () {
            if (forwards) {
                if (offset >= words[i].length) {
                    ++skip_count;
                    if (skip_count == skip_delay) {
                        forwards = false;
                        skip_count = 0;
                    }
                }
            }
            else {
                if (offset == 0) {
                    forwards = true;
                    i++;
                    offset = 0;
                    if (i >= len) {
                        i = 0;
                    }
                }
            }
            part = words[i].substr(0, offset);
            if (skip_count == 0) {
                if (forwards && offset <= words[i].length) {
                    offset++;
                    //console.log(444);
                }
                else {
                    clearInterval(ss);
                }
            }


            barmenText.innerHTML = part;
        }, speed);
    };


    openBarmen(event, type) {
        if (event) {
            this.setState({
                ...this.state,
                openQuest: true,
                barmenQuest: {
                    show: true,
                    textShow: true
                },
                typeQuest: type
            });
            setTimeout(() => {
                this.setState({
                    ...this.state,
                    barmenQuest: {
                        ...this.state.barmenQuest,
                        anim: true
                    }
                });
                var words = [];

                if (type == "art") {
                    words = ['Hello Hero, especially for you, I have prepared a series of beginner quests for quickly exploring the Lands of Elgard. Special starter chests are waiting for you! Hurry up, Hero!'];
                } else {
                    words = ['Greetings traveler, I have heard rumors about your greatest adventures, perhaps you will not mind helping me in one business, and I will generously reward you for this?'];
                }
                this.wordflick(words);
                this.canComplate(0);
            }, 300);
        } else {
            barmenText.innerHTML = '';
        }
    };

    openBarmenQuest(event) {
        if (event) {
            this.setState({
                ...this.state,
                openQuest: true,
                barmenQuest: {
                    ...this.state.barmenQuest,
                    showAllQuest: true,
                    textShow: false,
                }
            });
        } else {
            this.setState({
                ...this.state,
                openQuest: false,
                barmenQuest: {
                    ...this.state.barmenQuest,
                    showAllQuest: false,
                    textShow: false,
                    showAllQuest: false,
                    anim: false,
                    show: false
                }
            });
            //console.log(barmenText);
            barmenText.innerHTML = '';
            //words = [];
        }
    };

    changeQuest(num) {
        // this.setState({
        //     ...this.state,
        //     activeTab: num,
        // });
        this.canComplate(num);
    };

    canComplate(num = 0) {
        var canComplate = false;
        var arBool = []

        if (this.state.typeQuest == "art") {
            this.props.questsArt[num].bring.map((item, index) => {

                canComplate = false;
                if (
                    this.props.questNew && this.props.questNew != undefined && this.props.questNew[item.nameTable] != undefined && this.props.questNew[item.nameTable] >= item.num
                    || item.type == "getrank" && this.props.allUserInfo.maxrank != undefined && this.props.allUserInfo.maxrank >= item.num ||
                    item.type == "repareweapon" && item.nameTable == "repare4" && this.props.questNew[item.nameTable] != undefined && this.props.questNew[item.nameTable].length && this.props.questNew[item.nameTable][0] >= item.num ||
                    item.type == "reparejew" && item.nameTable == "repare4" && this.props.questNew[item.nameTable] != undefined && this.props.questNew[item.nameTable].length && this.props.questNew[item.nameTable][1] >= item.num ||
                    item.type == "reparearmor" && item.nameTable == "repare4" && this.props.questNew[item.nameTable] != undefined && this.props.questNew[item.nameTable].length && this.props.questNew[item.nameTable][2] >= item.num ||
                    item.type == "buyoneslot" && this.props.allUserInfo.max_slots != undefined && this.props.allUserInfo.max_slots == 20 && this.props.questNew["quest" + (parseInt(this.props.activeTab) + 1)] == 1
                ) {
                    canComplate = true;
                }
                arBool.push(canComplate);
            });

            var can = arBool.includes(false);
            //console.log("arBool", arBool);
            //console.log("can", can);

            this.setState({
                //...this.state,
                activeTabLocal: num,
                canComplateLocal: !can
            });
        } else {
            console.log("this.state.allresgroupLocal", this.state.allresgroupLocal);
            this.props.quests[num].bring.map((item, index) => {
                //console.log("item.tmpl", item.tmpl);
                if (item.tmpl == "bamboo") {
                    canComplate = false;
                    if (item.num <= this.props.balanceGame) {
                        canComplate = true
                        /*console.log("item.num ", item.num );
                        console.log("this.props.balanceGame", this.props.balanceGame);
                        console.log("bamb true", canComplate);*/
                    }
                } else {
                    canComplate = false;


                    for (let i = 0; i < this.state.allresgroupLocal.length; i++) {



                        // if(this.state.allresgroupLocal[i][0].shema == "armor") {
                        //     const filteredArmor = this.state.allresgroupLocal[i].filter((armor) => armor.rarity == "Rare");
                        //     console.log("i", filteredArmor.length);
                        //     console.log(this.state.allresgroupLocal[i][0]);

                        // }
                        // console.log("this.state.allresgroupLocal[i].length", this.state.allresgroupLocal[i].length)
                        // console.log("this.state.allresgroupLocal[i].length && item.type == ", item.type == "craftuncarm1");



                        if (this.state.allresgroupLocal[i].length && item.tmpl == this.state.allresgroupLocal[i][0].template_id) {
                            //console.log("this.state", this.state.allresgroupLocal[i].length);
                            if (item.num <= this.state.allresgroupLocal[i].length) {
                                canComplate = true
                                //console.log("other true", canComplate);
                            }
                        } else if (this.state.allresgroupLocal[i].length && item.type == "gem" && this.state.allresgroupLocal[i][0].shema == "gems") {
                            if (item.num <= this.state.allresgroupLocal[i].length) {
                                canComplate = true
                                //console.log("other true", canComplate);
                            }
                        } else if (this.state.allresgroupLocal[i].length && item.type == "panda" && this.state.allresgroupLocal[i][0].shema == "promo") {
                            if (item.num <= this.state.allresgroupLocal[i].length) {
                                canComplate = true
                                //console.log("panda other true", canComplate);
                            }
                        } else if (this.state.allresgroupLocal[i].length && this.state.allresgroupLocal[i][0].shema == "armor") {

                            if (item.type == "questsenduncarm") {
                                const filteredArmor = this.state.allresgroupLocal[i].filter((armor) => armor.rarity == "Uncommon");
                                if (item.num <= filteredArmor.length) {
                                    canComplate = true
                                    console.log("questsenduncarm UNC true", canComplate);
                                }
                                console.log("filteredArmor.length UNC", filteredArmor.length)
                            }

                            if (item.type == "questsendrarearm") {
                                const filteredArmor = this.state.allresgroupLocal[i].filter((armor) => armor.rarity == "Rare");
                                if (item.num <= filteredArmor.length) {
                                    canComplate = true
                                    console.log("questsenduncarm RARE true", canComplate);
                                }
                                console.log("filteredArmor.length RARE", filteredArmor.length)
                            }

                        } else if (this.state.allresgroupLocal[i].length && item.type == "jewelry" && this.state.allresgroupLocal[i][0].shema == "jewelry") {
                            if (
                                item.tmpl1 == this.state.allresgroupLocal[i][0].template_id ||
                                item.tmpl2 == this.state.allresgroupLocal[i][0].template_id ||
                                item.tmpl3 == this.state.allresgroupLocal[i][0].template_id ||
                                item.tmpl4 == this.state.allresgroupLocal[i][0].template_id
                            ) {
                                canComplate = true
                            }

                        } else if (item.type == "advbest") {
                            if (item.num <= this.props.quest[item.nameTable]) {
                                canComplate = true
                                //console.log("advbest true", canComplate);
                            }
                        } else if (item.type == "advpanda") {
                            if (item.num <= this.props.quest[item.nameTable]) {
                                canComplate = true
                                //console.log("advpanda true", canComplate);
                            }
                        } else if (item.type == "onestory") {
                            if (item.num <= this.props.quest[item.nameTable]) {
                                canComplate = true
                                //console.log("onestory true", canComplate);
                            }
                        } else if (item.type == "questchange") {
                            if (item.num <= this.props.quest[item.nameTable]) {
                                canComplate = true
                                //console.log("questchange true", canComplate);
                            }
                        } else if (item.type == "questcraftuncarm") {
                            if (item.num <= this.props.quest[item.nameTable]) {
                                canComplate = true
                                //console.log("questchange true", canComplate);
                            }
                        } else if (item.type == "questchangewoodkves") {
                            if (item.num <= this.props.quest[item.nameTable]) {
                                canComplate = true
                                //console.log("questchange true", canComplate);
                            }
                        }

                    }
                }


                console.log("item 1", item);
                console.log("item.tmpl 2", item.tmpl);
                console.log("canComplate 3", canComplate);
                arBool.push(canComplate);
            });

            var can = arBool.includes(false);
            console.log("can 4", can);
            console.log("num 5", num);
            console.log("arBool 6", arBool);
            console.log("canComplate 7", canComplate);

            this.setState({
                //...this.state,
                activeTabLocal: num,
                canComplateLocal: !can
            });
        }


        //this.props.changeTabs(num);
        //this.props.canComplateQuest(canComplate);

        /*this.setState({
            ...this.state,
            canComplate: canComplate,
            activeTab: num,
        });*/
    };

    RewadrModal(event) {
        if (event == "close") {
            this.setState({
                ...this.state,
                rewardModal: false
            });

            var loadingSlot = document.getElementsByTagName("body")[0];
            loadingSlot.classList.remove("open-modal");
        }
    };

    async getQuest(count) {

        var loadingSlot = document.getElementsByTagName("body")[0];
        loadingSlot.classList.add("loading");

        const userName = this.props.ual.activeUser.accountName;
        const activeUser = this.props.ual.activeUser;
        getQuest.actions[0].authorization[0].actor = userName
        getQuest.actions[0].data.username = userName
        getQuest.actions[0].data.count = count;
        //console.log(getQuest);

        try {
            await activeUser.signTransaction(getQuest, { blocksBehind: 3, expireSeconds: 30, }).then((response) => {
                if (response.status === 'executed') {
                    loadingSlot.classList.remove("loading");

                    this.props.getQuestDisp(count);
                    this.props.changeTabs(count);
                    /*this.setState({
                        ...this.state,
                    });*/
                }
            });
        } catch (error) {
            //console.log(error.message)
            console.warn(error)
            loadingSlot.classList.remove("loading");
            console.warn(error)
            this.setState({
                hasError: true,
                errorText: error.message,
            });
            if (localStorage.getItem('sound') == 'true') {
                this.audioMusicError.currentTime = 0;
                this.audioMusicError.volume = 0.1;
                this.audioMusicError.play();
            }
        }
    }

    async getNewQuest(count) {

        var loadingSlot = document.getElementsByTagName("body")[0];
        loadingSlot.classList.add("loading");

        const userName = this.props.ual.activeUser.accountName;
        const activeUser = this.props.ual.activeUser;
        getNewQuest.actions[0].authorization[0].actor = userName
        getNewQuest.actions[0].data.username = userName
        getNewQuest.actions[0].data.count = count;
        //console.log(getNewQuest);

        try {
            await activeUser.signTransaction(getNewQuest, { blocksBehind: 3, expireSeconds: 30, }).then((response) => {
                if (response.status === 'executed') {
                    loadingSlot.classList.remove("loading");

                    this.props.getQuestDispNew(count);
                    this.props.changeTabs(count);
                    /*this.setState({
                        ...this.state,
                    });*/
                }
            });
        } catch (error) {
            //console.log(error.message)
            console.warn(error)
            loadingSlot.classList.remove("loading");
            console.warn(error)
            this.setState({
                hasError: true,
                errorText: error.message,
            });
            if (localStorage.getItem('sound') == 'true') {
                this.audioMusicError.currentTime = 0;
                this.audioMusicError.volume = 0.1;
                this.audioMusicError.play();
            }
        }
    }

    async sendQuest(count) {
        console.log("sendQuest", count);
        var countName;
        if (count == 0) {
            countName = "first"
        } else if (count == 1) {
            countName = "second"
        } else if (count == 2) {
            countName = "third"
        } else if (count == 3) {
            countName = "four"
        }

        var loadingSlot = document.getElementsByTagName("body")[0];
        loadingSlot.classList.add("loading");

        var asset_ids = [];
        var item431186;
        var arre431186 = [];
        var item431185;
        var arre431185 = [];
        var item431184;
        var arre431184 = [];
        var item431183;
        var arre431183 = [];
        var item431182;
        var arre431182 = [];
        var item431181;
        var arre431181 = [];
        var itemstone;
        var arrtone = [];
        var itempromo;
        var arrpromo = [];
        var itemjew;
        var arrjew = [];
        var jewTmpl1;
        var jewTmpl2;
        var jewTmpl3;
        var jewTmpl4;

        var itemarmorunc;
        var arrarmorunc = [];
        var itemarmorrare;
        var arrarmorrare = [];


        this.props.quests[count].bring.map((item, index) => {
            //console.log("item.tmpl", item.tmpl);
            if (item.tmpl == 431186) {
                item431186 = item.num;
            } else if (item.tmpl == 431185) {
                item431185 = item.num;
            } else if (item.tmpl == 431184) {
                item431184 = item.num;
            } else if (item.tmpl == 431183) {
                item431183 = item.num;
            } else if (item.tmpl == 431182) {
                item431182 = item.num;
            } else if (item.tmpl == 431181) {
                item431181 = item.num;
            } else if (item.type == "jewelry") {
                itemjew = item.num;
                jewTmpl1 = item.tmpl1;
                jewTmpl2 = item.tmpl2;
                jewTmpl3 = item.tmpl3;
                jewTmpl4 = item.tmpl4;
            } else if (item.type == "promo") {
                itempromo = item.num;
            } else if (item.type == "questsenduncarm") {
                itemarmorunc = item.num;
            } else if (item.type == "questsendrarearm") {
                itemarmorrare = item.num;
            } else if (item.type == "gem") {
                itemstone = item.num;
            }
        });

        /*
        quests[0].bring.map((tmpl) => {

        })
        */

        this.state.allresgroupLocal.map((u) => {
            if (u.length) {
                if (u[0].template_id == "431181" && u.length >= item431181) {
                    for (let i = 0; i < item431181; i++) {
                        arre431181.push(u[i].asset_id);
                    }
                } else if (u[0].template_id == "431186" && u.length >= item431186) {
                    for (let i = 0; i < item431186; i++) {
                        arre431186.push(u[i].asset_id);
                    }
                } else if (u[0].template_id == "431185" && u.length >= item431185) {
                    for (let i = 0; i < item431185; i++) {
                        arre431185.push(u[i].asset_id);
                    }
                } else if (u[0].template_id == "431184" && u.length >= item431184) {
                    for (let i = 0; i < item431184; i++) {
                        arre431184.push(u[i].asset_id);
                    }
                } else if (u[0].template_id == "431183" && u.length >= item431183) {
                    for (let i = 0; i < item431183; i++) {
                        arre431183.push(u[i].asset_id);
                    }
                } else if (u[0].template_id == "431182" && u.length >= item431182) {
                    for (let i = 0; i < item431182; i++) {
                        arre431182.push(u[i].asset_id);
                    }
                } else if (u[0].shema == "gems" && u.length >= itemstone) {
                    for (let i = 0; i < itemstone; i++) {
                        arrtone.push(u[i].asset_id);
                    }
                } else if (u[0].shema == "promo" && u.length >= itempromo) {
                    for (let i = 0; i < itempromo; i++) {
                        arrpromo.push(u[i].asset_id);
                    }
                } else if (u[0].shema == "armor") {
                    // console.log('u.length', u.length);
                    // console.log('itemarmorunc', itemarmorunc);
                    // console.log('itemarmorrare', itemarmorrare);

                    const filteredArmor = u.filter((armor) => armor.rarity == "Uncommon");
                    if (filteredArmor.length >= itemarmorunc) {
                        for (let i = 0; i < itemarmorunc; i++) {
                            arrarmorunc.push(filteredArmor[i].asset_id);
                        }
                    }

                    const filteredArmor2 = u.filter((armor) => armor.rarity == "Rare");
                    if (u.length >= itemarmorrare) {
                        for (let i = 0; i < itemarmorrare; i++) {
                            arrarmorrare.push(filteredArmor2[i].asset_id);
                        }
                    }
                }
                /*else if (u[0].shema == "armor" && u.length >= itemarmorrare) {
                    console.log("Rare");
                    for (let i = 0; i < itemarmorrare; i++) {
                        if(u[0].rarity == "Rare") {
                            arrarmorrare.push(u[i].asset_id);
                        }
                    }
                } */
                else if (u[0].shema == "jewelry" && u.length >= itemjew && arrjew.length != itemjew) {
                    console.log("itemjew", u);
                    for (let i = 0; i < itemjew; i++) {
                        if (
                            jewTmpl1 == u[i].template_id ||
                            jewTmpl2 == u[i].template_id ||
                            jewTmpl3 == u[i].template_id ||
                            jewTmpl4 == u[i].template_id
                        ) {
                            arrjew.push(u[i].asset_id);
                        }
                    }
                }
            }
        })

        /*console.log("arre431186", arre431186);
        console.log("arre431185", arre431185);
        console.log("arre431184", arre431184);
        console.log("arre431183", arre431183);
        console.log("arre431182", arre431182);
        console.log("arre431181", arre431181);
        console.log("arrtone", arrtone);
        console.log("arrpromo", arrpromo);
        console.log("arrjew", arrjew);*/
        asset_ids.push(...arre431186, ...arre431185, ...arre431184, ...arre431183, ...arre431182, ...arre431181, ...arrtone, ...arrpromo, ...arrarmorrare, ...arrarmorunc, ...arrjew);

        console.log("asset_ids", asset_ids);

        this.setState({
            ...this.state,
            rewardId: asset_ids
        });
        //console.log("asset_idsasset_ids", asset_ids);

        const userName = this.props.ual.activeUser.accountName;
        const activeUser = this.props.ual.activeUser;
        sendAllPart.actions[0].authorization[0].actor = userName
        sendAllPart.actions[0].data.from = userName;
        sendAllPart.actions[0].data.asset_ids = asset_ids;
        sendAllPart.actions[0].data.memo = "quest " + countName + " ";
        //console.log(sendAllPart);

        try {
            await activeUser.signTransaction(sendAllPart, { blocksBehind: 3, expireSeconds: 30, }).then((response) => {
                if (response.status === 'executed') {
                    loadingSlot.classList.remove("loading");
                    loadingSlot.classList.add("open-modal");
                    this.props.getQuestDisp(count, true);
                    this.props.dis(count);
                    this.setState({
                        ...this.state,
                        rewardModal: true
                    });
                }
            });
        } catch (error) {

            /*this.setState({
                ...this.state,
                rewardModal: true
            });*/
            //console.log(error.message)
            console.warn(error)
            loadingSlot.classList.remove("loading");
            console.warn(error)
            this.setState({
                hasError: true,
                errorText: error.message,
            });
            if (localStorage.getItem('sound') == 'true') {
                this.audioMusicError.currentTime = 0;
                this.audioMusicError.volume = 0.1;
                this.audioMusicError.play();
            }
        }


    }

    async sendQuestNew(count) {
        var loadingSlot = document.getElementsByTagName("body")[0];
        loadingSlot.classList.add("loading");

        const userName = this.props.ual.activeUser.accountName;
        const activeUser = this.props.ual.activeUser;
        doneNewQuest.actions[0].authorization[0].actor = userName
        doneNewQuest.actions[0].data.from = userName;
        doneNewQuest.actions[0].data.count = count;
        //console.log(sendAllPart);

        try {
            await activeUser.signTransaction(doneNewQuest, { blocksBehind: 3, expireSeconds: 30, }).then((response) => {
                if (response.status === 'executed') {
                    loadingSlot.classList.remove("loading");
                    loadingSlot.classList.add("open-modal");
                    this.props.getQuestDispNew(count, true);
                    this.props.dis(count);
                    this.setState({
                        ...this.state,
                        rewardModal: true
                    });
                }
            });
        } catch (error) {

            console.warn(error)
            loadingSlot.classList.remove("loading");
            console.warn(error)
            this.setState({
                hasError: true,
                errorText: error.message,
            });
            if (localStorage.getItem('sound') == 'true') {
                this.audioMusicError.currentTime = 0;
                this.audioMusicError.volume = 0.1;
                this.audioMusicError.play();
            }
        }


    }

    async openChest(event) {

        if (event) {
            var loadingSlot = document.getElementsByTagName("body")[0];
            loadingSlot.classList.add("loading");

            //console.log('dateBt eat id', dateBt);
            //console.log('pandaId', pandaId);
            let asset;
            if (this.state.activeChest == 0) {
                asset = this.state.allUserChest.filter(item => item.template_id == 626793)[0];
            } else {
                asset = this.state.allUserChest.filter(item => item.template_id == 738753)[0];
            }

            const userName = this.props.ual.activeUser.accountName;
            const activeUser = this.props.ual.activeUser;
            cardTransferChest.actions[0].authorization[0].actor = userName;
            cardTransferChest.actions[0].data.from = userName;
            cardTransferChest.actions[0].data.asset_ids = [asset.asset_id];
            cardTransferChest.actions[0].data.memo =
                "openchest ";
            console.log(cardTransferChest);
            try {
                await activeUser
                    .signTransaction(cardTransferChest, {
                        blocksBehind: 3,
                        expireSeconds: 30,
                    })
                    .then((response) => {
                        if (response.status === "executed") {
                            //loadingSlot.classList.remove("loading");
                            this.openChestFront(false);
                        }
                    });
            } catch (error) {
                //console.log(error.message)
                console.warn(error);
                loadingSlot.classList.remove("loading");

                this.setState({
                    hasError: true,
                    errorText: error.message,
                });

            }

        } else {
            this.setState({
                ...this.state,
                openChest: false,
                animChest: false
            });
        }
    };

    async openChestFront(actual) {
        const userName = this.props.ual.activeUser.accountName;
        var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);
        console.log("rpcc", rpcc);

        try {
            const results = await rpcc.get_table_rows({
                json: true,
                code: process.env.REACT_APP_CONTRACT,
                scope: process.env.REACT_APP_CONTRACT,
                table: "openchestnew",
                lower_bound: userName,
                upper_bound: userName,
                limit: 1,
                show_payer: false,
            });
            const resultRow = results.rows;

            if (resultRow[0]) {
                var listNFTFromChest = [],
                    canOpenChest = false;
                for (var i = 0; i < resultRow[0].result.length; i++) {
                    if (i == 0) {
                        listNFTFromChest.push(
                            {
                                id: resultRow[0].result[i],
                                open: false,
                                show: true,
                                timer: resultRow[0].timer
                            }
                        );
                    } else {
                        listNFTFromChest.push(
                            {
                                id: resultRow[0].result[i],
                                open: false,
                                show: false,
                                timer: resultRow[0].timer
                            }
                        );
                    }
                }

                if (actual == true) {
                    this.setState({
                        ...this.setState,
                        canOpenChest: false,
                        actualListNFTFromChest: listNFTFromChest
                    });
                } else {
                    if (this.state.actualListNFTFromChest && this.state.actualListNFTFromChest.length && (this.state.actualListNFTFromChest[0].timer != listNFTFromChest[0].timer) || this.state.actualListNFTFromChest && !this.state.actualListNFTFromChest.length) {
                        document.getElementsByTagName("body")[0].classList.remove("loading");
                        canOpenChest = true;

                        var allUserChest = [...this.state.allUserChest];
                        allUserChest.shift();


                        this.setState({
                            ...this.state,
                            openChest: true,
                            canOpenChest: canOpenChest,
                            newListNFTFromChest: listNFTFromChest,
                            actualListNFTFromChest: listNFTFromChest,
                            allUserChest: allUserChest
                        });

                        setTimeout(() => {
                            this.setState({
                                ...this.state,
                                animChest: true
                            });
                        }, 300);

                        this.confettiStart();

                    } else {
                        setTimeout(() => {
                            this.openChestFront(actual);
                        }, 2000);
                    }


                    // this.setState({
                    //     ...this.setState,
                    //     canOpenChest: canOpenChest,
                    //     newListNFTFromChest: listNFTFromChest,
                    //     actualListNFTFromChest: listNFTFromChest
                    // });
                }

            } else {
                console.log("ERROR NO TABLE ROW waiting");
                if (actual == true) {

                } else {
                    setTimeout(() => {
                        this.openChestFront(actual);
                    }, 2000);
                }

            }
        } catch (e) {
            console.warn(e);

            console.log("\nCaught exception: " + e);
            setTimeout(() => {

                if (numRpc == 8) {
                    numRpc = 0;
                } else {
                    numRpc++;
                }
                console.log("numRpc", numRpc);
                this.openChestFront(actual);
            }, 1000);
        }
    }


    confettiStart() {
        myConfetti({
            particleCount: 300,
            spread: 250,
            startVelocity: 60,
            origin: { y: 0.4 },
            shapes: ['star', 'circle', 'square'],
            //drift: 1
            scalar: 1
        });
    }

    openNft(index) {
        //console.log("index", index);
        var arrOpenNftClone = [...this.state.newListNFTFromChest]
        arrOpenNftClone[index].open = true;

        this.confettiStart();

        this.setState({
            ...this.state,
            newListNFTFromChest: arrOpenNftClone
        });

    };

    nextNft(index) {
        var arrOpenNftClone = [...this.state.newListNFTFromChest]
        arrOpenNftClone[index].open = false;
        arrOpenNftClone[index].show = false;
        arrOpenNftClone[index + 1].show = true;

        this.setState({
            ...this.state,
            newListNFTFromChest: arrOpenNftClone
        });

    };

    openChestModal(e) {
        //console.log("open checst");
        if (e) {
            this.setState({
                ...this.state,
                openModalChest: true
            })
            this.getUserChest();
            this.openChestFront(true);
        } else {
            this.setState({
                ...this.state,
                openModalChest: false
            })
        }


    }

    async getUserChest() {
        (async () => {
            try {
                const userName = this.props.ual.activeUser.accountName;
                await api2
                    .getAssets({
                        limit: 500,
                        owner: userName,
                        collection_name: "nftpandawaxp",
                        schema_name: ["chests" /*, "promo"*/],
                        //template_id: 437403,
                    })
                    .then((assets) => {

                        var filterAssets = assets.map(item => {
                            return {
                                asset_id: item.asset_id,
                                template_id: item.template.template_id,
                                shema: item.schema.schema_name
                            }
                        })

                        this.setState({
                            ...this.state,
                            allUserChest: filterAssets
                        });
                    });
            } catch (e) {
                setTimeout(() => {
                    this.getUserChest();
                    if (numRpc == 8) {
                        numRpc = 0;
                    } else {
                        numRpc++;
                    }
                }, 1000);
                console.log("\nCaught exception: " + e);
            }
        })();

    }



    navChest(num) {
        this.setState({
            ...this.state,
            activeChest: num
        })
    }

    render() {
        return (<>
            <Menu  {...this.props.ual} wax={this.props.balanceWax} bam={this.props.balanceBam} game={this.props.balanceGame} updateBalance={this.props.updateBalance} />
            {this.state.hasError ? <ErrorComponent
                errorMessage={this.state.errorText}
                clearError={this.clearError}
            /> : null}

            <Modal
                modal={this.state.modal}
                showModalThank={this.state.showModalThank}
                closeThenk={this.closeThenk}
                made={this.state.made}
            />



            {this.state.rewardModal &&
                <div className="reward-modal">
                    <div className="reward-modal-in">
                        <div className="reward-modal-body">
                            <div className="fire-left"></div>
                            <div className="fire-right"></div>
                            <div className="reward-modal-tit">
                                <picture>
                                    <source srcSet={rewardw} type="image/webp" />
                                    <source srcSet={reward} type="image/png" />
                                    <img className="reward-img" src={rewardw} alt="" />
                                </picture>
                            </div>
                            <div className="reward-modal-card">
                                {this.state.rewardId && this.state.rewardId.length && this.state.rewardId[0] % 4 == 0 ?
                                    <picture>
                                        <source srcSet={`./images/${this.props.quests[this.state.activeTabLocal].reward.type == "weapon" ? "weapons/all/" : this.props.quests[this.state.activeTabLocal].reward.type == "jewelry" ? "jew/all/" : "shop/"}${this.props.quests[this.state.activeTabLocal].reward.tmpl1}.webp`} type="image/webp" />
                                        <source srcSet={`./images/${this.props.quests[this.state.activeTabLocal].reward.type == "weapon" ? "weapons/all/" : this.props.quests[this.state.activeTabLocal].reward.type == "jewelry" ? "jew/all/" : "shop/"}${this.props.quests[this.state.activeTabLocal].reward.tmpl1}.png`} type="image/png" />
                                        <img src={`./images/${this.props.quests[this.state.activeTabLocal].reward.type == "weapon" ? "weapons/all/" : this.props.quests[this.state.activeTabLocal].reward.type == "jewelry" ? "jew/all/" : "shop/"}${this.props.quests[this.state.activeTabLocal].reward.tmpl1}.png`} alt="" />
                                    </picture>
                                    : null}
                                {this.state.rewardId && this.state.rewardId.length && this.state.rewardId[0] % 4 == 1 ?
                                    <picture>
                                        <source srcSet={`./images/${this.props.quests[this.state.activeTabLocal].reward.type == "weapon" ? "weapons/all/" : this.props.quests[this.state.activeTabLocal].reward.type == "jewelry" ? "jew/all/" : "shop/"}${this.props.quests[this.state.activeTabLocal].reward.tmpl2}.webp`} type="image/webp" />
                                        <source srcSet={`./images/${this.props.quests[this.state.activeTabLocal].reward.type == "weapon" ? "weapons/all/" : this.props.quests[this.state.activeTabLocal].reward.type == "jewelry" ? "jew/all/" : "shop/"}${this.props.quests[this.state.activeTabLocal].reward.tmpl2}.png`} type="image/png" />
                                        <img src={`./images/${this.props.quests[this.state.activeTabLocal].reward.type == "weapon" ? "weapons/all/" : this.props.quests[this.state.activeTabLocal].reward.type == "jewelry" ? "jew/all/" : "shop/"}${this.props.quests[this.state.activeTabLocal].reward.tmpl2}.png`} alt="" />
                                    </picture>
                                    : null}
                                {this.state.rewardId && this.state.rewardId.length && this.state.rewardId[0] % 4 == 2 ?
                                    <picture>
                                        <source srcSet={`./images/${this.props.quests[this.state.activeTabLocal].reward.type == "weapon" ? "weapons/all/" : this.props.quests[this.state.activeTabLocal].reward.type == "jewelry" ? "jew/all/" : "shop/"}${this.props.quests[this.state.activeTabLocal].reward.tmpl3}.webp`} type="image/webp" />
                                        <source srcSet={`./images/${this.props.quests[this.state.activeTabLocal].reward.type == "weapon" ? "weapons/all/" : this.props.quests[this.state.activeTabLocal].reward.type == "jewelry" ? "jew/all/" : "shop/"}${this.props.quests[this.state.activeTabLocal].reward.tmpl3}.png`} type="image/png" />
                                        <img src={`./images/${this.props.quests[this.state.activeTabLocal].reward.type == "weapon" ? "weapons/all/" : this.props.quests[this.state.activeTabLocal].reward.type == "jewelry" ? "jew/all/" : "shop/"}${this.props.quests[this.state.activeTabLocal].reward.tmpl3}.png`} alt="" />
                                    </picture>
                                    : null}
                                {this.state.rewardId && this.state.rewardId.length && this.state.rewardId[0] % 4 == 3 ?
                                    <picture>
                                        <source srcSet={`./images/${this.props.quests[this.state.activeTabLocal].reward.type == "weapon" ? "weapons/all/" : this.props.quests[this.state.activeTabLocal].reward.type == "jewelry" ? "jew/all/" : "shop/"}${this.props.quests[this.state.activeTabLocal].reward.tmpl4}.webp`} type="image/webp" />
                                        <source srcSet={`./images/${this.props.quests[this.state.activeTabLocal].reward.type == "weapon" ? "weapons/all/" : this.props.quests[this.state.activeTabLocal].reward.type == "jewelry" ? "jew/all/" : "shop/"}${this.props.quests[this.state.activeTabLocal].reward.tmpl4}.png`} type="image/png" />
                                        <img src={`./images/${this.props.quests[this.state.activeTabLocal].reward.type == "weapon" ? "weapons/all/" : this.props.quests[this.state.activeTabLocal].reward.type == "jewelry" ? "jew/all/" : "shop/"}${this.props.quests[this.state.activeTabLocal].reward.tmpl4}.png`} alt="" />
                                    </picture>
                                    : null}

                                {
                                    this.state.activeTabLocal == 6 || this.state.activeTabLocal == 7 ?

                                        //this.state.rewardId[1] % 10 <= 5 &&

                                        <div className="reward-modal-bonus-card">
                                            <picture>
                                                <source srcSet={`./images/bonus.webp`} type="image/webp" />
                                                <source srcSet={`./images/bonus.png`} type="image/png" />
                                                <img src={`./images/bonus.png`} alt="" />
                                            </picture>
                                        </div>

                                        : null
                                }

                            </div>
                            {this.props.quests[this.state.activeTabLocal].reward.type2 || this.state.typeQuest == "art" ?
                                <div className="reward-modal-card">
                                    {this.state.typeQuest == "barmenQuest" && this.state.rewardId && this.state.rewardId.length && this.state.rewardId[0] % 4 == 0 ?
                                        <picture>
                                            <source srcSet={`./images/${this.props.quests[this.state.activeTabLocal].reward.type2 == "weapon" ? "weapons/all/" : this.props.quests[this.state.activeTabLocal].reward.type2 == "jewelry" ? "jew/all/" : "shop/"}${this.props.quests[this.state.activeTabLocal].reward.tmpl5}.webp`} type2="image/webp" />
                                            <source srcSet={`./images/${this.props.quests[this.state.activeTabLocal].reward.type2 == "weapon" ? "weapons/all/" : this.props.quests[this.state.activeTabLocal].reward.type2 == "jewelry" ? "jew/all/" : "shop/"}${this.props.quests[this.state.activeTabLocal].reward.tmpl5}.png`} type2="image/png" />
                                            <img src={`./images/${this.props.quests[this.state.activeTabLocal].reward.type2 == "weapon" ? "weapons/all/" : this.props.quests[this.state.activeTabLocal].reward.type2 == "jewelry" ? "jew/all/" : "shop/"}${this.props.quests[this.state.activeTabLocal].reward.tmpl5}.png`} alt="" />
                                        </picture>
                                        : null}
                                    {this.state.typeQuest == "barmenQuest" && this.state.rewardId && this.state.rewardId.length && this.state.rewardId[0] % 4 == 1 ?
                                        <picture>
                                            <source srcSet={`./images/${this.props.quests[this.state.activeTabLocal].reward.type2 == "weapon" ? "weapons/all/" : this.props.quests[this.state.activeTabLocal].reward.type2 == "jewelry" ? "jew/all/" : "shop/"}${this.props.quests[this.state.activeTabLocal].reward.tmpl6}.webp`} type2="image/webp" />
                                            <source srcSet={`./images/${this.props.quests[this.state.activeTabLocal].reward.type2 == "weapon" ? "weapons/all/" : this.props.quests[this.state.activeTabLocal].reward.type2 == "jewelry" ? "jew/all/" : "shop/"}${this.props.quests[this.state.activeTabLocal].reward.tmpl6}.png`} type2="image/png" />
                                            <img src={`./images/${this.props.quests[this.state.activeTabLocal].reward.type2 == "weapon" ? "weapons/all/" : this.props.quests[this.state.activeTabLocal].reward.type2 == "jewelry" ? "jew/all/" : "shop/"}${this.props.quests[this.state.activeTabLocal].reward.tmpl6}.png`} alt="" />
                                        </picture>
                                        : null}
                                    {this.state.typeQuest == "barmenQuest" && this.state.rewardId && this.state.rewardId.length && this.state.rewardId[0] % 4 == 2 ?
                                        <picture>
                                            <source srcSet={`./images/${this.props.quests[this.state.activeTabLocal].reward.type2 == "weapon" ? "weapons/all/" : this.props.quests[this.state.activeTabLocal].reward.type2 == "jewelry" ? "jew/all/" : "shop/"}${this.props.quests[this.state.activeTabLocal].reward.tmpl7}.webp`} type2="image/webp" />
                                            <source srcSet={`./images/${this.props.quests[this.state.activeTabLocal].reward.type2 == "weapon" ? "weapons/all/" : this.props.quests[this.state.activeTabLocal].reward.type2 == "jewelry" ? "jew/all/" : "shop/"}${this.props.quests[this.state.activeTabLocal].reward.tmpl7}.png`} type2="image/png" />
                                            <img src={`./images/${this.props.quests[this.state.activeTabLocal].reward.type2 == "weapon" ? "weapons/all/" : this.props.quests[this.state.activeTabLocal].reward.type2 == "jewelry" ? "jew/all/" : "shop/"}${this.props.quests[this.state.activeTabLocal].reward.tmpl7}.png`} alt="" />
                                        </picture>
                                        : null}
                                    {this.state.typeQuest == "barmenQuest" && this.state.rewardId && this.state.rewardId.length && this.state.rewardId[0] % 4 == 3 ?
                                        <picture>
                                            <source srcSet={`./images/${this.props.quests[this.state.activeTabLocal].reward.type2 == "weapon" ? "weapons/all/" : this.props.quests[this.state.activeTabLocal].reward.type2 == "jewelry" ? "jew/all/" : "shop/"}${this.props.quests[this.state.activeTabLocal].reward.tmpl8}.webp`} type2="image/webp" />
                                            <source srcSet={`./images/${this.props.quests[this.state.activeTabLocal].reward.type2 == "weapon" ? "weapons/all/" : this.props.quests[this.state.activeTabLocal].reward.type2 == "jewelry" ? "jew/all/" : "shop/"}${this.props.quests[this.state.activeTabLocal].reward.tmpl8}.png`} type2="image/png" />
                                            <img src={`./images/${this.props.quests[this.state.activeTabLocal].reward.type2 == "weapon" ? "weapons/all/" : this.props.quests[this.state.activeTabLocal].reward.type2 == "jewelry" ? "jew/all/" : "shop/"}${this.props.quests[this.state.activeTabLocal].reward.tmpl8}.png`} alt="" />
                                        </picture>
                                        : null}
                                    {this.state.typeQuest == "art" ?
                                        <picture>
                                            <source srcSet={`./images/tavern/lootbox/${this.props.questsArt[this.state.activeTabLocal].reward.tmpl}.webp`} type2="image/webp" />
                                            <source srcSet={`./images/tavern/lootbox/${this.props.questsArt[this.state.activeTabLocal].reward.tmpl}.png`} type2="image/png" />
                                            <img src={`./images/tavern/lootbox/"}${this.props.questsArt[this.state.activeTabLocal].reward.tmpl}.png`} alt="" />
                                        </picture>
                                        : null}

                                </div> : null}

                            <div className="reward-modal-close">

                                <button className="def-bt mid" onClick={() => this.RewadrModal("close")}>
                                    <span className="button-in">
                                        <span className="button-name">
                                            <span className="button-name-in">
                                                <span className="button-name-value">
                                                    close
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            }

            <main className="tavern-page">
                <div className="loading-block"></div>
                <div className={`tavern-wrap ${this.state.openQuest || this.state.openModalChest ? "open" : "close"} ${this.state.barmenQuest.showAllQuest && "mob"}`}>
                    <div className="tavern-body">
                        <picture>
                            <source srcSet={bgw} type="image/webp" />
                            <source srcSet={bg} type="image/jpg" />
                            <img className="tavern-img" src={bgw} alt="" />
                        </picture>
                        <div className="dialog" onClick={() => this.openBarmen(true, "barmenQuest")}>
                            <div className="dialog-in">
                            </div>
                        </div>
                        <div className={`dialog new-quest ${this.props.allUserInfo && this.props.allUserInfo.maxrank < 2 ? "active" : "noactive"}`} onClick={() => this.openBarmen(true, "art")}>
                            <div className="dialog-in">
                            </div>
                        </div>
                        <div className="dialog chest" onClick={() => this.openChestModal(true)}>
                            <div className="dialog-in">
                            </div>
                        </div>
                        {this.state.idAuction !== "" &&
                            <NavLink className="dialog auction" to={`/auction/${this.state.idAuction}`}>
                                <div className="dialog-in">
                                </div>
                            </NavLink>}

                        <div className="tabs-quest">
                            <picture>
                                <source srcSet={tabw} type="image/webp" />
                                <source srcSet={tab} type="image/jpg" />
                                <img src={tabw} alt="" />
                            </picture>
                            <div className="tabs-quest-in">
                                <div className="tabs-quest-one men-5" onClick={() => this.openChestModal(true)}>
                                    <i></i>
                                </div>
                                <div className="tabs-quest-one men-6 have" onClick={() => this.openBarmen(true, "art")}>
                                    <i></i>
                                </div>
                                <NavLink className={`tabs-quest-one men-2 ${this.state.idAuction && "have"}`} to={`/auction/${this.state.idAuction}`}>
                                    <i></i>
                                </NavLink>
                                <div className={`tabs-quest-one men-1 have ${this.state.openQuest && "active"}`} onClick={() => this.openBarmen(true, "barmenQuest")}>
                                    <i></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`before-quest ${this.state.barmenQuest.show && !this.state.rewardModal && "open"} ${this.state.barmenQuest.anim && "anim"} ${this.state.barmenQuest.showAllQuest && "center-on-mob"}`}>
                        <div className="before-quest-in">

                            <div className={`before-quest-wrap ${this.state.barmenQuest.textShow && !this.state.rewardModal ? "open" : ""}`}>
                                <div className="before-quest-tit">
                                    {this.state.typeQuest == "art" ? "Nemos" : "Gutfried"}
                                </div>
                                <div className="before-quest-descr">
                                    {/* <div className="modal-bay-close"  onClick={() => this.openBarmen(false)}>
                                        <span></span>
                                    </div> */}
                                    <div className="before-quest-descr-bord-1">
                                        <div className="before-quest-descr-bord-2">
                                            <div className="before-quest-descr-bord-3">
                                                <div className="before-quest-body"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="next-quest">
                                        <button className="def-bt mid" onClick={() => this.openBarmenQuest(true)}>
                                            <span className="button-in">
                                                <span className="button-name">
                                                    <span className="button-name-in">
                                                        <span className="button-name-value">
                                                            Next
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className={`quest-modal ${this.state.barmenQuest.showAllQuest && !this.state.rewardModal ? "open" : ""}`}>
                                <div className="quest-modal-in">
                                    <div className="quest-modal-top">
                                        <picture>
                                            <source srcSet={modaltopw} type="image/webp" />
                                            <source srcSet={modaltop} type="image/jpg" />
                                            <img className="tav" src={modaltopw} alt="" />
                                        </picture>
                                        <div className="quest-modal-tit">
                                            <picture>
                                                <source srcSet={titw} type="image/webp" />
                                                <source srcSet={tit} type="image/jpg" />
                                                <img className="tav" src={titw} alt="" />
                                            </picture>
                                        </div>
                                        <div className="modal-bay-close" onClick={() => this.openBarmenQuest(false)}>
                                            <span></span>
                                        </div>
                                    </div>
                                    <div className="quest-modal-wrap">

                                        <div className="goblin"></div>
                                        <div className="quest-modal-aside">
                                            <div className="quest-modal-menu">

                                                {this.state.typeQuest == "art" ?
                                                    this.props.questsArt.map((item, index) => {
                                                        if (item.active) {
                                                            return (
                                                                <div className={`one-menu 
                                                                ${this.props.quest && this.state.activeTabLocal == index &&
                                                                    !this.props.quest["questdone" + this.state.activeTabLocal] &&
                                                                    "active"}`}

                                                                    onClick={() => this.changeQuest(index)}
                                                                    key={index}
                                                                >
                                                                    <span>
                                                                        {item.nameQuest}
                                                                    </span>
                                                                </div>
                                                            );
                                                        }
                                                    })
                                                    :
                                                    this.props.quests.map((item, index) => {
                                                        if (item.active) {
                                                            return (
                                                                <div className={`one-menu 
                                                                ${this.props.quest && this.state.activeTabLocal == index &&
                                                                    !this.props.quest["questdone" + this.state.activeTabLocal] &&
                                                                    "active"}`}

                                                                    onClick={() => this.changeQuest(index)}
                                                                    key={index}
                                                                >
                                                                    <span>
                                                                        {item.nameQuest}
                                                                    </span>
                                                                </div>
                                                            );
                                                        }
                                                    })
                                                }

                                            </div>
                                        </div>

                                        {this.state.typeQuest == "barmenQuest" && this.props.tavern && <OneTab {...this.state}  {...this.props} getQuest={this.getQuest} sendQuest={this.sendQuest} />}
                                        {this.state.typeQuest == "art" && <OneTabNewQuest {...this.state}  {...this.props} getNewQuest={this.getNewQuest} sendQuestNew={this.sendQuestNew} />}

                                    </div>
                                    <div className="quest-modal-bot">
                                        <picture>
                                            <source srcSet={modalbotw} type="image/webp" />
                                            <source srcSet={modalbot} type="image/jpg" />
                                            <img className="tav" src={modalbotw} alt="" />
                                        </picture>
                                    </div>
                                </div>
                            </div>

                            <picture>
                                <source srcSet={this.state.typeQuest == "art" ? artw : barmenw} type="image/webp" />
                                <source srcSet={this.state.typeQuest == "art" ? art : barmen} type="image/jpg" />
                                <img className={`barmen-img ${this.state.barmenQuest.showAllQuest && "hide-on-mob"}`} src={this.state.typeQuest == "art" ? art : barmen} alt="" />
                            </picture>

                        </div>
                    </div>

                    <div className={`before-quest ${this.state.openModalChest && "open anim"}`}>
                        <div className="before-quest-in loot">


                            <div className="your-chest">
                                <div className="bt-back">
                                    <span
                                        onClick={() => this.openChestModal(false)}
                                    ></span>
                                </div>

                                <picture>
                                    <source srcSet={lootDargW} type="image/webp" />
                                    <source srcSet={lootDarg} type="image/jpg" />
                                    <img src={lootDargW} alt="" />
                                </picture>

                                <div className="your-chest-top">
                                    <div className={`one-chest ch-1 ${this.state.activeChest == 0 ? "active" : "no"}`} onClick={() => this.navChest(0)}>
                                        <div className="one-chest-tit"></div>
                                    </div>
                                    <div className={`one-chest ch-4 ${this.state.activeChest == 1 ? "active" : "no"}`} onClick={() => this.navChest(1)}>
                                        <div className="one-chest-tit"></div>
                                    </div>
                                    <div className={`one-chest ch-3 ${this.state.activeChest == 2 ? "active" : "no"}`} >
                                        <div className="one-chest-tit">soon</div>
                                    </div>
                                </div>
                                <div className="your-chest-wrap">
                                    {this.state.activeChest == 0 ?
                                        <div className="your-chest-big">
                                            <picture>
                                                <source srcSet={chestMin1W} type="image/webp" />
                                                <source srcSet={chestMin1} type="image/jpg" />
                                                <img src={chestMin1W} alt="" />
                                            </picture>
                                            <div className="your-chest-big-tit">
                                                wooden lootbox
                                            </div>
                                            <div className="your-chest-big-num">
                                                {this.state.allUserChest && this.state.allUserChest.length && this.state.allUserChest.filter(item => item.template_id == 626793).length}X
                                            </div>
                                        </div> :
                                        <div className="your-chest-big">
                                            <picture>
                                                <source srcSet={chestw} type="image/webp" />
                                                <source srcSet={chest} type="image/png" />
                                                <img src={chestw} alt="" />
                                            </picture>
                                            <div className="your-chest-big-tit">
                                                Space <br />Chest
                                            </div>
                                            <div className="your-chest-big-num">
                                                {this.state.allUserChest && this.state.allUserChest.length && this.state.allUserChest.filter(item => item.template_id == 738753).length}X
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="open-chest">
                                <canvas id="canvas"></canvas>
                                <div className={`open-chest-in ${this.state.openChest && "open"} ${this.state.animChest && "anim"} ${this.state.activeChest == 0 ? "wood" : "alien"}`}>

                                    <div className="your-chest-big-tit-mob">
                                        {this.state.activeChest == 0 ? "wooden lootbox" : "Space Chest"}
                                    </div>
                                    {this.state.activeChest == 0 ?
                                        <div className="your-chest-big-num-mob">
                                            {this.state.allUserChest && this.state.allUserChest.length && this.state.allUserChest.filter(item => item.template_id == 626793).length}X
                                        </div>
                                        :
                                        <div className="your-chest-big-num-mob">
                                            {this.state.allUserChest && this.state.allUserChest.length && this.state.allUserChest.filter(item => item.template_id == 738753).length}X
                                        </div>
                                    }
                                    <div className="all-nft-chest">
                                        {this.state.openChest && this.state.newListNFTFromChest && this.state.newListNFTFromChest.length && this.state.newListNFTFromChest.map((nft, index) => {
                                            return (
                                                <div className={`nft-from-chest ${nft.show ? "show" : "hide"} ${nft.open ? "open" : "close"} nft-${index}`} key={index} >
                                                    <div className="front-nft-chest">
                                                        {this.state.activeChest == 0 ?
                                                            <picture>
                                                                <source srcSet={`./images/tavern/lootbox/${nft.id}.png`} type="image/webp" />
                                                                <source srcSet={`./images/tavern/lootbox/${nft.id}.png`} type="image/png" />
                                                                <img src={`./images/tavern/lootbox/${nft.id}.png`} alt="" />
                                                            </picture>
                                                            :
                                                            index == 0 ?

                                                                <picture>
                                                                    <source srcSet={`/images/alien/all/${nft.id}.gif`} type="image/gif" />
                                                                    <source srcSet={`/images/alien/all/${nft.id}.gif`} type="image/gif" />
                                                                    <img src={`/images/alien/all/${nft.id}.gif`} alt="" />
                                                                </picture>
                                                                :
                                                                <picture>
                                                                    <source srcSet={`./images/tavern/lootbox/${nft.id}.png`} type="image/webp" />
                                                                    <source srcSet={`./images/tavern/lootbox/${nft.id}.png`} type="image/png" />
                                                                    <img src={`./images/tavern/lootbox/${nft.id}.png`} alt="" />
                                                                </picture>
                                                        }
                                                        <div className="front-nft-chest-in">
                                                            {index + 1 === this.state.newListNFTFromChest.length ?
                                                                <button className="def-bt norm" onClick={() => this.openChest(false)}>
                                                                    <span className="button-in">
                                                                        <span className="button-name">
                                                                            <span className="button-name-in">
                                                                                <span className="button-name-value">
                                                                                    Close
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </button>

                                                                : <button className="def-bt norm" onClick={() => this.nextNft(index)}>
                                                                    <span className="button-in">
                                                                        <span className="button-name">
                                                                            <span className="button-name-in">
                                                                                <span className="button-name-value">
                                                                                    Next NFT
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </button>}
                                                        </div>
                                                    </div>
                                                    <div className="back-nft-chest">
                                                        <div className="back-nft-chest-in">
                                                            <button className="def-bt mid" onClick={() => this.openNft(index)}>
                                                                <span className="button-in">
                                                                    <span className="button-name">
                                                                        <span className="button-name-in">
                                                                            <span className="button-name-value">
                                                                                Show NFT
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })

                                        }
                                    </div>

                                </div>
                                <div className="open-chest-bt">

                                    {this.state.allUserChest && this.state.allUserChest.length ? <button className={`def-bt mid ${!this.state.openChest && "open"}`} onClick={() => this.openChest(true)}>
                                        <span className="button-in">
                                            <span className="button-name">
                                                <span className="button-name-in">
                                                    <span className="button-name-value">
                                                        Open
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </button> : null}

                                </div>
                            </div>

                            <div className={`witcher ${this.state.animChest && "anim"}`}>

                            </div>

                        </div>
                    </div>

                </div>
                {!this.state.barmenQuest.show &&
                    <footer>
                        <div className="footer-nav">
                            <nav>
                                <ul>
                                    <li>
                                        <a className="foot-logo-1" target="_blank" href="https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&sort=created&symbol=WAX">
                                            <span>

                                            </span>
                                            <i>
                                                Market
                                            </i>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="foot-logo-2" target="_blank" href="https://twitter.com/NftPanda/">
                                            <span>

                                            </span>
                                            <i>
                                                Twitter
                                            </i>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="foot-logo-3" target="_blank" href="https://www.instagram.com/nftpanda/">
                                            <span>

                                            </span>
                                            <i>
                                                Instagram
                                            </i>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="foot-logo-4" target="_blank" href="https://discord.gg/vCSgme4d5b">
                                            <span>

                                            </span>
                                            <i>
                                                Discord
                                            </i>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="foot-logo-5" target="_blank" href={`https://wax.alcor.exchange/swap?output=${process.env.REACT_APP_VAL}-nftpandabamb&input=WAX-eosio.token`}>
                                            <span>

                                            </span>
                                            <i>
                                                {process.env.REACT_APP_VAL} Trade
                                            </i>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="foot-logo-6" target="_blank" href="https://discord.gg/vCSgme4d5b">
                                            <span>

                                            </span>
                                            <i>
                                                Support
                                            </i>
                                        </a>
                                    </li>
                                </ul>

                            </nav>
                        </div>
                    </footer>
                }

            </main>
        </>);
    }
}

// let mapStateToProps = (state) => ({
//     allinvent: state.inventoryReduser.allinvent,
// });

let mapStateToProps = (state) => ({
    quests: state.questReduser.quests,
    questsArt: state.questReduser.questsArt,
    quest: state.questReduser.quest,
    questNew: state.questReduser.questNew,
    allresgroup: state.questReduser.allresgroup,
    activeTab: state.questReduser.activeTab,
    canComplate: state.questReduser.canComplate,
    tavern: state.questReduser.tavern,
    allTmpl: state.questReduser.allTmpl
});

export default connect(mapStateToProps, { questitems, setQuestInfo, setNewQuestInfo, changeTabs, canComplateQuest, getQuestDisp, getQuestDispNew, dis })(TavernComponent);