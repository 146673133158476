import React from 'react';

import { connect } from "react-redux";
import { JsonRpc } from 'eosjs'
import { useInView, InView, ScrollWrapper } from 'react-intersection-observer';

class PandaVideoComp extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            isLoadingVideo: false
        }

    }

    componentDidMount() {
        //this.setState({isLoading: true})
        //this.setState({isLoadingVideo: true})
    }

    render() {
        // const { isLoading } = this.state.isLoading;
        // const { isLoadingVideo } = this.state.isLoadingVideo;
        return (<InView triggeronce="true">

            {({ inView, ref, entry }) => (
                <div ref={ref} triggeronce="true" >
                    {localStorage.getItem('animation') == 'true' && !this.props.onlyThumb || localStorage.getItem('animation') == null && !this.props.onlyThumb ?
                        <>
                            {(!this.state.isLoading || !this.state.isLoadingVideo) && <div className="card-rar-wrap p-6">
                                <picture>
                                    <source srcSet={`/images/back-side.webp`} type="image/webp" />
                                    <source srcSet={`/images/back-side.png`} type="image/png" />
                                    <img src={`/images/back-side.png`} alt="" />
                                </picture>
                                <div className="loading-block"></div>
                            </div>}
                            {inView &&
                                <div className={`card-rar-wrap ${(this.state.isLoading && this.state.isLoadingVideo) ? "endloading" : "loading"}`}>
                                    {this.props.inslot ? <div className="inslot"></div> : null}
                                    <picture>
                                        <source srcSet={`/images/card-rar/${this.props.cardName}.webp`} type="image/webp" />
                                        <source srcSet={`/images/card-rar/${this.props.cardName}.png`} type="image/png" />
                                        <img src={`/images/card-rar/${this.props.cardName}.png`} alt="" onLoad={() => this.setState({ isLoading: true })} />
                                    </picture>
                                    <div className="element-rar">
                                        <picture>
                                            <source srcSet={`/images/card-rar/element-${this.props.element.toLowerCase()}.webp`} type="image/webp" />
                                            <source srcSet={`/images/card-rar/element-${this.props.element.toLowerCase()}.png`} type="image/png" />
                                            <img src={`/images/card-rar/element-${this.props.element.toLowerCase()}.png`} alt="" />
                                        </picture>
                                    </div>
                                </div>}
                            {/* <div
                                className={"video-panda"}
                                dangerouslySetInnerHTML={{ 
                                    __html: `
                                    <video width={136} height={170} autoPlay="true"  muted="true" loop playsInline="true" >
                                    <source 
                                    src="./images/card-1x/video/${this.props.pandaName}.mp4" type="video/mp4" />

                                </video>`
                                }}
                            /> */}

                            {inView && <video className={`video-panda ${(this.state.isLoading && this.state.isLoadingVideo) ? "endloading" : "loading"}`}
                                width={136}
                                height={170}
                                autoPlay="true"
                                muted="true"
                                loop
                                playsInline
                                onLoadedData={() => {
                                    this.setState({ isLoadingVideo: true })
                                }}
                            >

                                <source src={`/images/card-1x/video/${this.props.pandaName}.webm`} type="video/webm" />
                                <source src={`/images/card-1x/video/${this.props.pandaName}.mp4`} type="video/mp4" />

                            </video>}
                        </>
                        :
                        <picture>
                            <source srcSet={`/images/card-1x/${this.props.fullNameRare}.webp`} type="image/webp" />
                            <source srcSet={`/images/card-1x/${this.props.fullNameRare}.png`} type="image/png" />
                            <img src={`/images/card-1x/${this.props.fullNameRare}.png`} alt="" />
                        </picture>
                    }
                </div>

            )}
        </ InView>);
    }
}

let mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, {})(PandaVideoComp);