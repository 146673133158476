import React from 'react';

const ExchRes = (props) => {


    // needSteel: 50,
    // needTitawin: 10,
    // needKvessir: 3,
    // needGold: 2
    // }
    return (<>
        {props.exchRes.map((item, index) => {
            /*if (index < props.activePage * 12 && index >= (props.activePage - 1) * 12 ) {*/
            var wood;
            var steel;
            var titawin;
            var kvessir;
            var gold;
            var dwemer;
            var konst;
            var max;

            if (item.name == "steel") {
                if (props.activeItem.img == 431181) {
                    wood = Math.floor(props.activeItem.count / item.needWood)
                }
            }
            else if (item.name == "titawin") {
                if (props.activeItem.img == 431181) {
                    wood = Math.floor(props.activeItem.count / item.needWood)
                } else if (props.activeItem.img == 431182) {
                    steel = Math.floor(props.activeItem.count / item.needSteel)
                }
            } else if (item.name == "kvessir") {
                if (props.activeItem.img == 431181) {
                    wood = Math.floor(props.activeItem.count / item.needWood)
                } else if (props.activeItem.img == 431182) {
                    steel = Math.floor(props.activeItem.count / item.needSteel)
                } else if (props.activeItem.img == 431183) {
                    titawin = Math.floor(props.activeItem.count / item.needTitawin)
                }
            } else if (item.name == "gold") {
                if (props.activeItem.img == 431181) {
                    wood = Math.floor(props.activeItem.count / item.needWood)
                } else if (props.activeItem.img == 431182) {
                    steel = Math.floor(props.activeItem.count / item.needSteel)
                } else if (props.activeItem.img == 431183) {
                    titawin = Math.floor(props.activeItem.count / item.needTitawin)
                } else if (props.activeItem.img == 431184) {
                    kvessir = Math.floor(props.activeItem.count / item.needKvessir)
                }
            } else if (item.name == "dwemer") {
                if (props.activeItem.img == 431181) {
                    wood = Math.floor(props.activeItem.count / item.needWood)
                } else if (props.activeItem.img == 431182) {
                    steel = Math.floor(props.activeItem.count / item.needSteel)
                } else if (props.activeItem.img == 431183) {
                    titawin = Math.floor(props.activeItem.count / item.needTitawin)
                } else if (props.activeItem.img == 431184) {
                    kvessir = Math.floor(props.activeItem.count / item.needKvessir)
                } else if (props.activeItem.img == 431185) {
                    gold = Math.floor(props.activeItem.count / item.needGold)
                }
            }

            if (wood) {
                konst = item.needWood;
                max = wood * item.needWood;
            } else if (steel) {
                konst = item.needSteel;
                max = steel * item.needSteel;
            } else if (titawin) {
                konst = item.needTitawin;
                max = titawin * item.needTitawin;
            } else if (kvessir) {
                konst = item.needKvessir;
                max = kvessir * item.needKvessir;
            } else if (gold) {
                konst = item.needGold;
                max = gold * item.needGold;
            } else if (dwemer) {
                konst = item.needDwemer;
                max = dwemer * item.needDwemer;
            }

            return (

                <div key={index} className={`one-exch-item ${wood || steel || titawin || kvessir || gold ? 'show' : props.activeItem.img && item.name ? 'def' : null}  ${props.activeExchItem.activeNum === index ? 'active' : null} ${item.name ? '' : 'no'}`}>
                    <div className="one-exch-item-in tooltip">
                        <div className="one-exch-prod-thumb">
                            {wood || steel || titawin || kvessir || gold ?
                                <div className='linkexch' onClick={() => props.chooseExch(konst, item.img, max, index)} ></div> : null}

                            <div className="value-exch-eat">
                                {
                                    props.activeItem.img ?
                                        item.name == "steel" ?
                                            props.activeItem.img == 431181 ?
                                                Math.floor(props.activeItem.count / item.needWood)
                                                :
                                                0
                                            :
                                            item.name == "titawin" ?
                                                props.activeItem.img == 431181 ?
                                                    Math.floor(props.activeItem.count / item.needWood)
                                                    :
                                                    props.activeItem.img == 431182 ?
                                                        Math.floor(props.activeItem.count / item.needSteel)
                                                        :
                                                        0
                                                :
                                                item.name == "kvessir" ?
                                                    props.activeItem.img == 431181 ?
                                                        Math.floor(props.activeItem.count / item.needWood)
                                                        :
                                                        props.activeItem.img == 431182 ?
                                                            Math.floor(props.activeItem.count / item.needSteel)
                                                            :
                                                            props.activeItem.img == 431183 ?
                                                                Math.floor(props.activeItem.count / item.needTitawin)
                                                                :
                                                                0
                                                    :
                                                    item.name == "gold" ?
                                                        props.activeItem.img == 431181 ?
                                                            Math.floor(props.activeItem.count / item.needWood)
                                                            :
                                                            props.activeItem.img == 431182 ?
                                                                Math.floor(props.activeItem.count / item.needSteel)
                                                                :
                                                                props.activeItem.img == 431183 ?
                                                                    Math.floor(props.activeItem.count / item.needTitawin)
                                                                    :
                                                                    props.activeItem.img == 431184 ?
                                                                        Math.floor(props.activeItem.count / item.needKvessir)
                                                                        :
                                                                        0
                                                        :
                                                        item.name == "dwemer" ?
                                                            props.activeItem.img == 431181 ?
                                                                Math.floor(props.activeItem.count / item.needWood)
                                                                :
                                                                props.activeItem.img == 431182 ?
                                                                    Math.floor(props.activeItem.count / item.needSteel)
                                                                    :
                                                                    props.activeItem.img == 431183 ?
                                                                        Math.floor(props.activeItem.count / item.needTitawin)
                                                                        :
                                                                        props.activeItem.img == 431184 ?
                                                                            Math.floor(props.activeItem.count / item.needKvessir)
                                                                            :
                                                                            props.activeItem.img == 431185 ?
                                                                                Math.floor(props.activeItem.count / item.needGold)
                                                                                :
                                                                                0
                                                            :
                                                            null
                                        :
                                        null
                                }
                            </div>
                            <span></span>
                            <div className="one-exch-prod-thumb-in">
                                {item.name ?
                                    <picture>
                                        <source srcSet={`./images/jew/inventory/${item.img}.webp`} type="image/webp" />
                                        <source srcSet={`./images/jew/inventory/${item.img}.png`} type="image/png" />
                                        <img src={`./images/jew/inventory/${item.img}.png`} alt="" />
                                    </picture>
                                    : null}
                                <i></i>
                                <i className="b"></i>
                            </div>


                        </div>

                        <div className="cust-tooltip bottom">
                            <div className="tool-arrow"></div>
                            <div className="tool-wrap">
                                <div className="tool-wrap-1">
                                    <div className="tool-wrap-2">
                                        <div className="tool-body">
                                            <span>
                                                {item.name}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            );
            /*}*/
        })
        }




    </>);

}

export default ExchRes;