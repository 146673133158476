import React from "react";

import Menu from './Menu';
import ErrorComponent from "../Error/ErrorComponent";
import ErrorModal from "../ErrorModal/ErrorModalComponent";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import ModalOneWeapon from "./weapon/ModalOneWeapon";
import ModalOneJew from "./jew/ModalOneJew";
import Slots from './Slots';
import alienlogo from "../../images/alien/alien-panda-logo.png"; // relative path to image
import alienlogow from "../../images/alien/alien-panda-logo.webp"; // relative path to image

import {
  setSlotPandaOnGame,
  setPandaOnGame,
  toggleIsFetchingTop,
  doDelFromStates,
  addToSlotState,
  doDelFromSlot,
  setUsersRow,
  addSlot /*setTimerToState, */,
  setTimerEnd,
  removePandaAdv,
  removePandaAdvEnergy,
  changeStakeSum,
  changeStakeSumMinus,
  lvlUp,
  eat /*eatlist,*/,
  eatlistclear,
  removeWeaponSlot,
  addWeaponToSlot,
  addWeaponEnergy,
  removeJewSlot,
  addJewToSlot,
  addJewEnergy,
  removeArmorSlot,
  addArmorToSlot,
  addArmorEnergy,
  setPandaOnGameWax,
  setWeaponOnGameWax,
  setJewOnGameWax,
  setArmorOnGameWax,
  setWasLoadPandas,
  setWasLoadWeapon,
  setWasLoadJew,
  setWasLoadArmor,
  choosePanda,
  setUserSlots,
} from "./../../GlobalState/pandaonslot-reducer";

import {
  eatremove,
  eatlist,
  wasLoadinEat,
  wasBuyingEat,
} from "../../GlobalState/shop-reducer";
import {
  weaponlist,
  statusLoadinWeapon,
  addWeaponWhenRemove,
  addWeaponToPanda,
  setPandaWeapon,
} from "../../GlobalState/weapon-reducer";
import {
  jewlist,
  statusLoadinJew,
  addJewWhenRemove,
  addJewToPanda,
  setPandaJew,
} from "../../GlobalState/jew-reducer";
import {
  armorlist,
  statusLoadinArmor,
  addArmorWhenRemove,
  addArmorToPanda,
  setPandaArmor,
} from "../../GlobalState/armor-reducer";

import { ExplorerApi } from "atomicassets"; // атомик для списка панд
import { JsonRpc } from "eosjs";

import musicError from "./../../music/error.mp3";
import musicTimer from "./../../music/timer.mp3";

const fetch = require("node-fetch");
const api = new ExplorerApi(
  "https://" + localStorage.getItem('rpcA'),
  "atomicassets",
  { fetch, rateLimit: 4 }
);
var canSendsendAll = true; // ЭТО для отправки всех панд
var initial; // timer console

let userConst = null;

var arrHub = [
  "aa-wax-public1.neftyblocks.com",
  localStorage.getItem('rpcA'),
  localStorage.getItem('rpcA'),
  localStorage.getItem('rpcA'),
  "api.atomic.greeneosio.com",
  "atomic.wax.eosrio.io",
  "wax-aa.eu.eosamsterdam.net",
  "atomic-wax-mainnet.wecan.dev",
  "atomic.sentnl.io",
  // "atomic.tokengamer.io",
  // "atomic.ledgerwise.io",
  // "api-wax-aa.eosarabia.net",
  // "wax.hkeos.com/aa",
  // "wax-atomic.wizardsguild.one",
  // "wax-atomic.eosiomadrid.io",
  // "aa.wax.blacklusion.io",
  // "wax-atomic-api.eosphere.io",
  // "wax-aa.eosdublin.io"
]

var numRpc = 0;
var arrRpc = ['wax.pink.gg', 'wax.cryptolions.io', 'wax.greymass.com', 'api.wax.alohaeos.com', 'api.waxsweden.org', 'wax.eosphere.io', 'api.wax.greeneosio.com', 'wax.eu.eosamsterdam.net', 'api.wax.bountyblok.io']

const caseAlienTmpl = (tmpl) => {
  console.log("tmpl", tmpl);
  //var sum = false;
  const validTemplateIds = new Set([
    141561, 141555, 141554, 141553, 141551, 141545, 141544, 141543, 141540, 141533, 141532, 141531, 141528, 141520, 141519, 141518, 19647, 19640, 19639, 19638, 19635, 19628, 19627, 19626, 141484, 141483, 141480, 141477, 141470, 141460, 141457, 141454, 141452, 141451, 141450, 141445, 141444, 141440, 141437, 141428, 141418, 141415, 141412, 141410, 141409, 141408, 19621, 19620, 19616, 19613, 19604, 19594, 19591, 19588, 19586, 19585, 19584, 254356, 254354, 254352, 254350, 235646, 235644, 235642, 235640, 141574, 141572, 141570, 141568, 141566, 141564, 141560, 141558, 141556, 141550, 141548, 141546, 141539, 141536, 141534, 141527, 141524, 141522, 20993, 20992, 20991, 20990, 20989, 20988, 19646, 19643, 19641, 19634, 19631, 19629, 141511, 141505, 141504, 141503, 141502, 141501, 141496, 141492, 141491, 141490, 141489, 141481, 141473, 141472, 141471, 141469, 141468, 141463, 141458, 141456, 141455, 141453, 141441, 141431, 141430, 141429, 141427, 141426, 141421, 141416, 141414, 141413, 141411, 20983, 20982, 20981, 20980, 20979, 20978, 20977, 20976, 20975, 20974, 20973, 19617, 19607, 19606, 19605, 19603, 19602, 19597, 19592, 19590, 19589, 19587, 254357, 254355, 254353, 254351, 235647, 235645, 235643, 235641, 141573, 141569, 141567, 141563, 141562, 141559, 141552, 141549, 141542, 141541, 141538, 141529, 141526, 141516, 44933, 28422, 19645, 19636, 19633, 19624,
    141513, 141510, 141508, 141500, 141498, 141495, 141494, 141493, 141485, 141479, 141476, 141467, 141465, 141462, 141461, 141459, 141446, 141439, 141436, 141425, 141423, 141420, 141419, 141417, 19622, 19615, 19612, 19601, 19599, 19596, 19595, 19593, 141571, 141565, 141557, 141547, 141535, 141523, 28424, 28423, 19642, 19630,
    141515, 141512, 141507, 141506, 141499, 141497, 141487, 141482, 141475, 141474, 141466, 141464, 141448, 141442, 141435, 141432, 141424, 141422, 56042, 19618, 19611, 19608, 19600, 19598, 141514, 141509, 141488, 141486, 141478, 141449, 141447, 141438, 141406, 19623, 19614, 19582
  ]);

  const sum = validTemplateIds.has(tmpl);
  //console.log("Sum", sum);
  return sum;
}


class LKAlien extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newListPandaSlot: false,
      pandaInSlot: "",
      errorOnGame: false, // ошибка для вывода карточек в игре
      isLoadedOnGame: false, // лоадинг для вывода карточек в игре
      cardOnGame: [], // сами карточки для вывода карточек в игре
      slotNFT: [],
      openedModal: false,
      numberSlot: "",
      userRow: [],
      rewardModalOpen: false,
      buyModalSlot: false,
      rewardModalText: {
        title: "",
        text: "",
        finalNumber: "",
        weaponPay: "",
        jewPay: "",
        animationNumber: "",
        resourceRew: [],
      },
      resultRew: {
        // данные про награду
        rarNft: [0, 1, 2, 3, 4, 5, 6],
        numberNft: [6600, 4000, 3240, 1200, 720, 180, 60],
        numberNew: [0.1, 0.1, 0.18, 0.5, 0.85, 3.4, 10.0],
        allCardCount: 16000, // общее число панд
        baz: 0.05, // ставка которая влияет на награду
        rang: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // ставка которая влияет на награду
        steps: [0, 8, 13, 21, 34, 55, 89, 144, 233, 377, 610, 987, 1597], // ставка которая влияет на награду
        percStake: 1, // Какой процент от заработанных токенов нужно стейкать
        rangPercProfit: [
          0, 0.02, 0.03, 0.05, 0.08, 0.13, 0.21, 0.34, 0.55, 0.89, 1.44, 2.33,
          3.77,
        ],
      },
      hasError: false, // ошибка транзакции
      errorText: "", // ошибка транзакции
      chooseHeroLoading: false, // loading slot
      openMultiSt: false,
      openChooseAlienOrPanda: false,
      typeOfNft: "",
      pandasInSlot: [],
      allPandasSlot: [],
      wasLoadPandas: false,
      userSlotsAlien: [],
      userSlotsPanda: [],
      alienRegister: false,
      wasLoadAlienOnGame: false,
      alienOnGame: null,
      lastAdv: {
        panda: 0,
        numberslot: "",
        type: ""
      }
    };


    this.audioMusicError = new Audio(musicError);
    this.audioTimer = new Audio(musicTimer);

    this.getArreySlots = this.getArreySlots.bind(this);
    this.GetPandaListOnGame = this.GetPandaListOnGame.bind(this);
    this.GetAlienListOnGame = this.GetAlienListOnGame.bind(this);
    this.getArreySlots = this.getArreySlots.bind(this);
    this.openModalBySlot = this.openModalBySlot.bind(this);
    this.openCloseModalBody = this.openCloseModalBody.bind(this);
    this.getAlienOnWallet = this.getAlienOnWallet.bind(this);
    this.openModalResult = this.openModalResult.bind(this);
    this.clearError = this.clearError.bind(this);
    this.openCloseLoading = this.openCloseLoading.bind(this);

  }

  componentDidMount() {
    //console.log("componentDidMount")
    const activeUser = this.props.ual.activeUser;

    //this.getAllTMPL();
    if (activeUser !== this.state.user) {
      (async () => {
        try {
          const accountName = await activeUser.getAccountName();
          this.setState({
            user: this.props.id
          });
          this.getArreySlots();

          //this.getAllTMPL();
        } catch (error) {
          console.error('Error fetching account name:', error);
        }
      })();

    }
  }

  componentWillUnmount() { }

  openCloseModalBody(event) {
    if (event) {
      document.getElementsByTagName("body")[0].classList.add("open-modal");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("open-modal");
    }
  }

  openCloseLoading(event) {
    if (event) {
      document.getElementsByTagName("body")[0].classList.add("loading");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("loading");
    }
  }

  openModalBySlot(event, number) {
    this.setState({
      numberSlot: number,
      buyModalSlot: event
    });
    this.openCloseModalBody(event);
  }

  openModalResult(event) {
    this.setState({
      openModalResult: event
    });
  }



  async GetPandaListOnGame() {
    console.log("GetPandaListOnGame");
    this.props.toggleIsFetchingTop(true);
    try {
      var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);
      await rpcc
        ?.get_table_rows({
          json: true,
          code: process.env.REACT_APP_CONTRACT, // contract who owns the table
          scope: process.env.REACT_APP_CONTRACT, // scope of the table
          table: "nftsongamec", // name of the table as specified by the contract abi
          key_type: "i64",
          lower_bound: this.state.user,
          upper_bound: this.state.user,
          limit: -1,
          reverse: !0,
          show_payer: false,
          index_position: 2,
        })
        .then((results) => {
          const resultRow = results.rows;
          const resultRowFilter = resultRow.filter(
            (row) => row.storer === this.state.user && row.is_in_slot === 0
          );
          var pandaObSlot = [];
          if (resultRow && resultRow.length) {
            resultRow.map((panda) => {
              if (panda.is_in_slot) {
                panda["activePanda"] = false;
                pandaObSlot.push(panda);
              }
            });
          }

          this.setState({
            wasLoadPandas: true,
            cardOnGame: resultRow,
            pandasInSlot: pandaObSlot,
            allPandasSlot: resultRowFilter
          });

          this.getAlienOnWallet();
        });
    } catch (e) {
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      setTimeout(() => {
        this.GetPandaListOnGame();
        if (numRpc == 8) {
          numRpc = 0;
        } else {
          numRpc++;
        }
      }, 1000);
      console.log("\nCaught exception: " + e);
    }
  }

  async GetAlienListOnGame() {
    this.props.toggleIsFetchingTop(true);
    try {
      var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);
      await rpcc
        ?.get_table_rows({
          json: true,
          code: process.env.REACT_APP_CONTRACT, // contract who owns the table
          scope: process.env.REACT_APP_CONTRACT, // scope of the table
          table: "aliensnft", // name of the table as specified by the contract abi
          key_type: "i64",
          lower_bound: this.state.user,
          upper_bound: this.state.user,
          limit: -1,
          reverse: !0,
          show_payer: false,
          index_position: 2,
        })
        .then((results) => {
          var resultRow = [];

          if (results && results.rows && results.rows.length) {
            resultRow = results.rows;
          }

          this.setState({
            wasLoadAlienOnGame: true,
            alienOnGame: resultRow
          });



          // this.getAlienOnWallet();
        });
    } catch (e) {
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      setTimeout(() => {
        this.GetAlienListOnGame();
        if (numRpc == 8) {
          numRpc = 0;
        } else {
          numRpc++;
        }
      }, 1000);
      console.log("\nCaught exception: " + e);
    }
  }

  // ФУНКЦИЯ которая берет значения с таблицы слотов ИМЕННО С МАСИВА СЛОТОВ [0,0,0,0,0,0,0,0,0,0,0,...20...0]
  async getArreySlots() {
    //console.log("getArreySlots");
    try {
      //const activeUser = this.props.ual.activeUser;
      //const accountName = await activeUser.getAccountName();
      var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);
      //console.log("this.state.user", this.state.user);
      //console.log("numRpc", numRpc);
      //console.log("rrRpc[numRpc]", arrRpc[numRpc]);
      const results = await rpcc.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "usersalien", // name of the table as specified by the contract abi
        limit: 1,
        lower_bound: this.state.user,
        upper_bound: this.state.user,
        index_position: 1,
        reverse: false,
        show_payer: false,
      });
      const originRowUser = results;

      //console.log("results", results);
      if (results && results.rows && results.rows.length) {
        const resultRowAlien = results.rows[0].slots_alien;
        const resultRowPanda = results.rows[0].slots_panda;

        //console.log("resultRowAlien", resultRowAlien);
        this.setState({
          ...this.state,
          userSlotsAlien: resultRowAlien,
          userSlotsPanda: resultRowPanda,
          userRow: originRowUser.rows[0].max_slots,
          alienRegister: true
        })
        this.GetPandaListOnGame();
      } else {

      }
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      setTimeout(() => {
        this.getArreySlots();
        if (numRpc == 8) {
          numRpc = 0;
        } else {
          numRpc++;
        }
      }, 1000);
      console.log("\nCaught exception: " + e);
      // if (localStorage.getItem("sound") === "true") {
      //   this.audioMusicError.currentTime = 0;
      //   this.audioMusicError.volume = 0.1;
      //   this.audioMusicError.play();
      // }
    }
  }
  // КОНЕЦ ФУНКЦИЯ которая берет значения с таблицы слотов

  // диспаччит таймер слот
  async clearError() {
    this.setState({
      errorText: "",
      hasError: false,
    });
  }

  // aliens on wallet
  async getAlienOnWallet() {
    try {

      console.log("api", api);
      const asset = await api
        .getAssets({
          limit: 100,
          owner: this.state.user,
          collection_name: "alien.worlds",
          schema_name: ["arms.worlds", "crew.worlds"],
          //ids: newArr,
        })
        .then((assets) => {

          //console.log("assets", assets);

          var filterAssets = []

          if (assets && assets.length) {
            console.log("assets", assets)
            var filterAssets = assets.filter((item) => {
              return caseAlienTmpl(Number(item.template.template_id));
            }).map((item) => {
              return {
                asset_id: item.asset_id,
                template: {
                  template_id: item.template.template_id
                },
                data: {
                  rarity: item.data.rarity
                }
              }
            });
          }

          this.setState({
            allAlien: filterAssets,
          });
          this.GetAlienListOnGame();
          //console.log('assets', assets );
        });
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  } // End aliens on wallet

  render() {
    return (
      <>
        <Menu id={this.props.id}  {...this.props.ual} wax={this.props.balanceWax} bam={this.props.balanceBam} game={this.props.balanceGame} updateBalance={this.props.updateBalance} />


        <>
          {this.state.hasError ? (
            <ErrorComponent
              errorMessage={this.state.errorText}
              clearError={this.clearError}
            />
          ) : null}


          <main className="slot-page alien-slots alien-festivas">
            <div className="loading-block"></div>

            <section id="all-slots">
              <div className="container">
                <div className="alien-panda-logo">
                  <picture>
                    <source srcSet={alienlogow} type="image/webp" />
                    <source srcSet={alienlogo} type="image/png" />
                    <img src={alienlogow} alt="" />
                  </picture>
                </div>



                <div className="all-slots-in">
                  {
                    this.state.wasLoadPandas &&
                      this.state.wasLoadAlienOnGame &&
                      this.state.alienRegister
                      //&& this.state.pandasInSlot && this.state.pandasInSlot.length 
                      ? (
                        <Slots
                          {...this.props}
                          userSlotsAlien={this.state.userSlotsAlien}
                          alienOnGame={this.state.alienOnGame}
                          userSlotsPanda={this.state.userSlotsPanda}
                          userRow={this.state.userRow}
                          //onFormSubmit={this.onFormSubmit}
                          //slotNFT={this.props.pandaSlotOnGame}
                          //pandaListOnGame={this.props.pandaListOnGame}
                          //showCloseModalJew={this.showCloseModalJew}
                          showCloseModal={this.showCloseModal}
                          //resultRew={this.state.resultRew}
                          chooseHeroLoading={this.state.chooseHeroLoading}
                          //pandaInSlot={this.state.pandaInSlot}
                          pandasInSlot={this.state.pandasInSlot}
                          userSlots={this.state.userSlots}
                          allUserInfo={this.props.allUserInfo}
                          transfer={this.transfer}

                          openModalBySlot={this.openModalBySlot}
                          chooseAlienOrPanda={this.chooseAlienOrPanda}
                          removeAlienOrPanda={this.removeAlienOrPanda}
                          handleUpdate={this.handleUpdate}

                          sendToAdv={this.sendToAdv}
                        />
                      ) : null}
                </div>
              </div>
            </section>

            <footer>
              <div className="footer-nav">
                <nav>
                  <ul>
                    <li>
                      <a className="foot-logo-1" target="_blank" href="https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&sort=created&symbol=WAX">
                        <span>

                        </span>
                        <i>
                          Market
                        </i>
                      </a>
                    </li>
                    <li>
                      <a className="foot-logo-2" target="_blank" href="https://twitter.com/NftPanda/">
                        <span>

                        </span>
                        <i>
                          Twitter
                        </i>
                      </a>
                    </li>
                    <li>
                      <a className="foot-logo-3" target="_blank" href="https://www.instagram.com/nftpanda/">
                        <span>

                        </span>
                        <i>
                          Instagram
                        </i>
                      </a>
                    </li>
                    <li>
                      <a className="foot-logo-4" target="_blank" href="https://discord.gg/vCSgme4d5b">
                        <span>

                        </span>
                        <i>
                          Discord
                        </i>
                      </a>
                    </li>
                    <li>
                      <a className="foot-logo-5" target="_blank" href={`https://wax.alcor.exchange/swap?output=${process.env.REACT_APP_VAL}-nftpandabamb&input=WAX-eosio.token`}>
                        <span>

                        </span>
                        <i>
                          {process.env.REACT_APP_VAL} Trade
                        </i>
                      </a>
                    </li>
                    <li>
                      <a className="foot-logo-6" target="_blank" href="https://discord.gg/vCSgme4d5b">
                        <span>

                        </span>
                        <i>
                          Support
                        </i>
                      </a>
                    </li>
                  </ul>

                </nav>
              </div>
            </footer>

          </main>

        </>

      </>
    );
  }
}

let mapStateToProps = (state) => ({
  pandaSlotOnGame: state.slotReducer.pandaSlotOnGame,
  pandaListOnGame: state.slotReducer.pandaListOnGame,
  usersRow: state.slotReducer.usersRow,
  //alleat: state.slotReducer.alleat,
  statuseat: state.showReduser.statuseat,
  alleat: state.showReduser.alleat,
  wasbay: state.showReduser.wasbay,
  allWeapon: state.weaponReduser.allWeapon,
  statusWeapon: state.weaponReduser.statusWeapon,
  pandaWeapon: state.weaponReduser.pandaWeapon,
  allJew: state.jewReduser.allJew,
  allArmor: state.armorReduser.allArmor,
  statusJew: state.jewReduser.statusJew,
  pandaJew: state.jewReduser.pandaJew,
  statusArmor: state.armorReduser.statusArmor,
  pandaArmor: state.armorReduser.pandaArmor,
  pandaSlotOnGameWax: state.slotReducer.pandaSlotOnGameWax,
  //pandaSlotWeaponWax: state.slotReducer.pandaSlotWeaponWax,
  //pandaSlotJewWax: state.slotReducer.pandaSlotJewWax,
  wasLoadPandas: state.slotReducer.wasLoadPandas,
  wasLoadWeapon: state.slotReducer.wasLoadWeapon,
  wasLoadJew: state.slotReducer.wasLoadJew,
  wasLoadArmor: state.slotReducer.wasLoadArmor,
  userSlots: state.slotReducer.userSlots,
});

export default connect(mapStateToProps, {
  setSlotPandaOnGame,
  setPandaOnGame,
  toggleIsFetchingTop,
  doDelFromStates,
  addToSlotState,
  doDelFromSlot,
  setUsersRow,
  addSlot /*setTimerToState,*/,
  setTimerEnd,
  removePandaAdv,
  removePandaAdvEnergy,
  changeStakeSum,
  changeStakeSumMinus,
  lvlUp,
  eat,
  eatremove,
  eatlist,
  eatlistclear,
  wasLoadinEat,
  wasBuyingEat,
  weaponlist,
  statusLoadinWeapon,
  removeWeaponSlot,
  addWeaponWhenRemove,
  addWeaponToPanda,
  addWeaponToSlot,
  setPandaWeapon,
  addWeaponEnergy,
  jewlist,
  statusLoadinJew,
  removeJewSlot,
  addJewWhenRemove,
  addJewToPanda,
  addJewToSlot,
  setPandaJew,
  addJewEnergy,
  setPandaOnGameWax,
  setWeaponOnGameWax,
  setJewOnGameWax,
  setArmorOnGameWax,
  setWasLoadPandas,
  setWasLoadWeapon,
  setWasLoadJew,
  setWasLoadArmor,
  choosePanda,
  setUserSlots,
  armorlist,
  statusLoadinArmor,
  removeArmorSlot,
  addArmorWhenRemove,
  addArmorToPanda,
  addArmorToSlot,
  setPandaArmor,
  addArmorEnergy,
})(LKAlien);
