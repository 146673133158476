import React from "react";
import { JsonRpc } from "eosjs";
import {
  setWasLogin,
  setModalAfterLogin,
} from "../GlobalState/waslogin-reducer";
import { connect } from "react-redux";
import ErrorComponent from "../pages/Error/ErrorComponent";
import axios from "axios";

//import { UserService } from './../UserService';

var numRpc = 0;
var arrRpc = ['wax.pink.gg', 'wax.cryptolions.io', 'wax.greymass.com', 'api.wax.alohaeos.com', 'api.waxsweden.org', 'wax.eosphere.io', 'api.wax.greeneosio.com', 'wax.eu.eosamsterdam.net', 'api.wax.bountyblok.io']

const loginTransaction = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "login",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        username: "",
        partner: process.env.REACT_APP_CONTRACT,
        signing_value: "",
      },
    },
  ],
};

class FirstModalLogin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      wasLogin: "",
      errorText: "",
      hasError: false,
    };

    this.transfer = this.transfer.bind(this);
    this.updateLoginTable = this.updateLoginTable.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.clearError = this.clearError.bind(this);
  }

  handleClose() {
    //console.log(6666);
    //console.log(this.props);
    this.props.setModalAfterLogin(true);
    this.props.handleCloseModalFirst();
    localStorage.setItem("wasLogin", true);
  }

  componentDidMount() {
    this.updateLoginTable();
  }

  componentDidUpdate() { }

  async updateLoginTable() {
    try {
      //const accountName = this.props.ual.activeUser.accountName;
      const activeUser = this.props.ual.activeUser;
      const accountName = await activeUser.getAccountName();
      var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);

      //const rpc = this.props.ual.activeUser.rpc
      const result = await rpcc.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, //this.state.accountName,   // scope of the table
        table: "usersnew", // name of the table as specified by the contract abi
        limit: 1,
        index_position: 1,
        lower_bound: accountName,
        upper_bound: accountName,
        reverse: false,
        show_payer: false,
      });

      const wasLogin = result.rows[0];
      if (wasLogin) {
        this.setState({ wasLogin: wasLogin.name });
        localStorage.setItem("wasLogin", true);
      } else if (!wasLogin /*&& !openedTrans*/) {
        this.props.setWasLogin(false);
        localStorage.setItem("wasLogin", false);
        this.transfer();
      }
    } catch (e) {
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
    }
  }

  async sendWombat() {

    const activeUser = this.props.ual.activeUser;
    const accountName = await activeUser.getAccountName();
    var dateNow = parseInt(Date.now() / 1000);
    var datas = {

      "username": accountName,
      "event": "registration",
      "eventtime": dateNow,
    }



  }

  async transfer() {
    if (window.innerWidth > 1024) {
      // `${window.location.origin}#${localStorage.getItem('ref')}`

      let ref = "";
      if (localStorage.getItem("ref") !== null) {
        ref = localStorage.getItem("ref");
      } else {
        ref = process.env.REACT_APP_CONTRACT;
      }
      loginTransaction.actions[0].authorization[0].actor = this.props.ual.activeUser.accountName;
      loginTransaction.actions[0].data.username = this.props.ual.activeUser.accountName;
      loginTransaction.actions[0].data.partner = ref;
      loginTransaction.actions[0].data.signing_value = localStorage.getItem("signing_value");

      try {
        //console.log(loginTransaction);
        //this.setState({ openedTrans: true });
        await this.props.ual.activeUser.signTransaction(loginTransaction, {
          blocksBehind: 3,
          expireSeconds: 30,
        }).then((response) => {
          if (response.status === "executed") {
            this.sendWombat();
            this.props.setWasLogin(true);
          }
        });



      } catch (error) {
        console.warn(error);
        this.setState({
          hasError: true,
          errorText: error.message,
        });
        console.log("\nCaught exception: " + error);
      }
    }
  }

  async clearError() {
    this.setState({
      errorText: "",
      hasError: false,
    });
  }

  render() {
    return (
      <>
        {this.state.hasError ? (
          <ErrorComponent
            errorMessage={this.state.errorText}
            clearError={this.clearError}
          />
        ) : null}
        <LoginModal {...this.props} handleClose={this.handleClose} />
      </>
    );
  }
}

const LoginModal = (props) => {
  return (
    <>
      {(props.wasLogin && !props.showModalAfterLogin) ||
        props.showFisrsModal ? (
        <>
          <div className="bamb-modal-body">
            <div className="bamb-modal-body-in">
              <div className="bamb-modal first-mod">
                <div className="bamb-modal-in">
                  <div
                    className="modal-close"
                    onClick={props.handleClose}
                  ></div>
                  <div className="bamb-modal-top"></div>
                  <div className="bamb-modal-bot"></div>
                  <div className="bamb-modal-wrap">
                    <div className="bamb-modal-tit">Hello Legend</div>
                    <div className="bamb-modal-descr">
                      Now it’s your time to save the Elgard land. Please choose
                      your heroes and send them to adventure!
                    </div>
                    <div className="bamb-modal-bt">
                      <button
                        className="def-bt mid"
                        onClick={props.handleClose}
                      >
                        <span className="button-in">
                          <span className="button-name">
                            <span className="button-name-in">
                              <span className="button-name-value">
                                Let’s begin
                              </span>
                            </span>
                          </span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-modal"></div>
        </>
      ) : null}
    </>
  );
};

let mapStateToProps = (state) => ({
  wasLogin: state.wasLogin.wasLogin,
  showModalAfterLogin: state.wasLogin.showModalAfterLogin,
});

export default connect(mapStateToProps, { setWasLogin, setModalAfterLogin })(
  FirstModalLogin
);
