import React from 'react';
import PandaVideoComp from './../PandaVideoComp';
import musicHit from './../../music/hit1.mp3'
var audioMusicHit = new Audio(musicHit);

function backside(num) {
    console.log(num);
    var items = document.getElementsByClassName('choose-hero-bl');
    items[num].classList.toggle("active");
    console.log("items", items);
    if (localStorage.getItem('sound') === 'true') {
        audioMusicHit.currentTime = 0;
        audioMusicHit.volume = 0.1;
        audioMusicHit.play();
    }
}

var nameRarePanda;
var cardRare;

const chooseNameRar = function(item) {
    switch (item) {
        case 0:
            return nameRarePanda = "Common"
            break;
        case 1:
            return nameRarePanda = "Promo"
            break;
        case 2:
            return nameRarePanda = "Uncommon"
            break;
        case 3:
            return nameRarePanda = "Rare"
            break;
        case 4:
            return nameRarePanda = "Epic"
            break;
        case 5:
            return nameRarePanda = "Legendary"
            break;
        case 6:
            return nameRarePanda = "Mythic"
            break;
        default:
            return nameRarePanda = "NOBADY"
            break;
    }
}


const Heromodal = (props) => {
    var rarName = ["Rookie", "Officer-cadet", "Second-Lieutenant", "Lieutenant", "Captain", "Major", "Lieutenant-Colonel", "Colonel", "Brigadier", "Major-General", "Lieutenant-General", "General", "legend"]

    return (<>
        {props.pandaListOnGame ? props.pandaListOnGame.map((item, index) => (

            <div className="one-card-list mod-list choose-hero-bl" key={item.asset_id} data-rar={item.rarity === 1 ? 'Promo' : item.name_pa.substr(0, item.name_pa.indexOf('-'))}>

                <div className="one-card-list-in">
                    <div className="img-wrap">
                        <div className="open-info" onClick={(e) => {
                            backside(index);
                        }}></div>
                        {/* <picture>
                            <source srcSet={`./images/card-1x/${item.name_pa}.webp`} type="image/webp" />
                            <source srcSet={`./images/card-1x/${item.name_pa}.png`} type="image/png" /> 
                            <img src={`./images/card-1x/${item.name_pa}.png`} alt="" />
                        </picture> */}
                        {/* <PandaVideoComp fullNameRare={item.name_pa} pandaName={item.name_pa.substring(item.name_pa.indexOf("-") + 1)} cardName={item.name_pa} element={item.element} /> */}
                        <div className="card-rar-wrap">
                            <picture>
                                <source srcSet={`./images/card-rar/${item.name_pa.split('-')[1].substring(0, 1) == "A" ? "serial-1-" : item.name_pa.split('-')[1].substring(0, 1) == "N" ? "serial-2-" : item.name_pa.split('-')[1].substring(0, 1) == "P" ? "serial-3-" : null}${item.name_pa.toLowerCase().substr(0, item.name_pa.indexOf('-'))}.webp`} type="image/webp" />
                                <source srcSet={`./images/card-rar/${item.name_pa.split('-')[1].substring(0, 1) == "A" ? "serial-1-" : item.name_pa.split('-')[1].substring(0, 1) == "N" ? "serial-2-" : item.name_pa.split('-')[1].substring(0, 1) == "P" ? "serial-3-" : null}${item.name_pa.toLowerCase().substr(0, item.name_pa.indexOf('-'))}.png`} type="image/png" />
                                <img src={`./images/card-rar/${item.name_pa.split('-')[1].substring(0, 1) == "A" ? "serial-1-" : item.name_pa.split('-')[1].substring(0, 1) == "N" ? "serial-2-" : item.name_pa.split('-')[1].substring(0, 1) == "P" ? "serial-3-" : null}${item.name_pa.toLowerCase().substr(0, item.name_pa.indexOf('-'))}.webp`} alt="" />
                            </picture>
                            <div className="element-rar">
                                <picture>
                                    <source srcSet={`./images/card-rar/element-${item.element}.webp`} type="image/webp" />
                                    <source srcSet={`./images/card-rar/element-${item.element}.png`} type="image/png" />
                                    <img src={`./images/card-rar/element-${item.element}.webp`} alt="" />
                                </picture>
                            </div>
                        </div>
                        <div
                            className={"video-panda"}
                            dangerouslySetInnerHTML={{
                                __html: `
                                <video width={136} height={170} autoPlay="true"  muted="true" loop playsInline="true" >
                                <source 
                                src="./images/card-1x/video/${item.name_pa.split('-')[1]}.mp4" type="video/mp4" />

                            </video>`
                            }}
                        />
                        <div className="one-card-bt">
                            <button className="def-bt min append-card" onClick={(e) => {
                                props.onHandleSendPandaToSlot(item, index);
                            }} href="/" >
                                <span className="button-in">
                                    <span className="button-name">
                                        <span className="button-name-in">
                                            <span className="button-name-value">
                                                CHOOSE
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="card-back-side">

                    <div className="close-back-side" onClick={(e) => {
                        backside(index);
                    }}></div>
                    <div className="card-back-side-in">

                        <div className="card-top-avat-descr">
                            <div className="card-top-avat">
                            </div>
                            <div className="card-top-avat-descr-info">
                                Panda info
                            </div>
                            <div className="card-top-avat-descr-name">
                                {item.name_pa.split('-')[1]}
                            </div>
                            <div className="card-top-avat-descr-list">
                                <ul>
                                    <li>
                                        ID: {item.asset_id}
                                    </li>
                                    <li>
                                        RARITY: {chooseNameRar(item.rarity)}
                                    </li>
                                    <li>
                                        stake: {item.stake_count / 10000} {process.env.REACT_APP_VAL}
                                    </li>
                                    <li>
                                        rank: {rarName[item.lvl]}
                                    </li>
                                </ul>
                            </div>
                            <div className="card-top-avat-descr-copy">
                                NFT Panda
                                <span>
                                    World of fantasy
                                </span>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        )) : <>oops</>}
    </>);
}

export default Heromodal;