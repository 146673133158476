import React from "react";

import Menu from './../../components/Menu';
import Slots from './Slots';
import ErrorComponent from "../Error/ErrorComponent";
import ErrorModal from "../ErrorModal/ErrorModalComponent";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import BaySlot from "./BaySlot";
import ChooseAlienOrPanda from "./ChooseAlienOrPanda";
import Result from "./Result";
import GoToAdvModal from "./GoToAdvModal";
import SharpModal from "./SharpModal";
import BuyChestModal from "./BuyChestModal";
import getAllAlienRes from '../Get/getAllAlienRes';
import getAllLvlAssets from '../Get/getAllLvlAssets';
import getAllTopAlienUsers from '../Get/getAllTopAlienUsers';
import TotalInfo from './TotalInfo';

import ModalInfo from "../ModalInfo/ModalInfo";

import Countdown from "react-countdown";

import alienlogo from "../../images/alien/alien-panda-logo-3.png"; // relative path to image
import alienlogow from "../../images/alien/alien-panda-logo-3.webp"; // relative path to image

import {
  toggleIsFetchingTop,
} from "./../../GlobalState/alien-reducer";

import { ExplorerApi } from "atomicassets"; // атомик для списка панд
import { JsonRpc } from "eosjs";

import musicError from "./../../music/error.mp3";
import musicTimer from "./../../music/timer.mp3";
import musicRemove from "./../../music/remove.mp3";

const fetch = require("node-fetch");
const api = new ExplorerApi(
  "https://" + localStorage.getItem('rpcA'),
  "atomicassets",
  { fetch, rateLimit: 4 }
);
var canSendsendAll = true; // ЭТО для отправки всех панд
var initial; // timer console

//let userConst = null;

const timerStart = 1711972800; // real start
//const timerStart = 1719144000; // start for test
const timerEnd = 1717243200;
//const timerEnd = 1707931558; // test

var arrHub = [
  "aa-wax-public1.neftyblocks.com",
  localStorage.getItem('rpcA'),
  localStorage.getItem('rpcA'),
  localStorage.getItem('rpcA'),
  "api.atomic.greeneosio.com",
  "atomic.wax.eosrio.io",
  "wax-aa.eu.eosamsterdam.net",
  "atomic-wax-mainnet.wecan.dev",
  "atomic.sentnl.io",
  // "atomic.tokengamer.io",
  // "atomic.ledgerwise.io",
  // "api-wax-aa.eosarabia.net",
  // "wax.hkeos.com/aa",
  // "wax-atomic.wizardsguild.one",
  // "wax-atomic.eosiomadrid.io",
  // "aa.wax.blacklusion.io",
  // "wax-atomic-api.eosphere.io",
  // "wax-aa.eosdublin.io"
]

let allAlians = []

var numRpc = 0;
var arrRpc = ['wax.pink.gg', 'wax.cryptolions.io', 'wax.greymass.com', 'api.wax.alohaeos.com', 'api.waxsweden.org', 'wax.eosphere.io', 'api.wax.greeneosio.com', 'wax.eu.eosamsterdam.net', 'api.wax.bountyblok.io']

// это транзакция отправки ИЗ
const cardTransferSlot = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "addslotalien",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        username: "",
        asset_id: "",
        number_slot: "",
        type: ""
      },
    },
  ],
};

// это транзакция отправки ИЗ
const goTransAdv = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "gotoalienadv",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        storer: "",
        numberslot: "",
        typeadv: "",
        duration: "4"
      },
    },
  ],
};

const sharpTrans = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "aliensharp",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        asset_id: "",
        username: "",
        slotNumber: ""
      },
    },
  ],
};

// это транзакция отправки ИЗ Слота
const cardTransferOutFromSlot = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "alienout",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: { username: "", asset_id: "", type: "" },
    },
  ],
};

const loginTransaction = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "regalien",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        username: "",
      },
    },
  ],
};

// это транзакция покупки Слота
const slotTransBay = {
  actions: [
    {
      account: "alien.worlds",
      name: "transfer",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        from: "",
        to: process.env.REACT_APP_CONTRACT,
        quantity: "",
        memo: "buyslot",
      },
    },
  ],
};
// это транзакция покупки Слота
const bayChestTrans = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "bayalich",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        username: "",
        count: "",
      },
    },
  ],
};

const caseAlienTmpl = (tmpl) => {
  //console.log("tmpl", tmpl);
  //var sum = false;
  const validTemplateIds = new Set([
    141561, 141555, 141554, 141553, 141551, 141545, 141544, 141543, 141540, 141533, 141532, 141531, 141528, 141520, 141519, 141518, 19647, 19640, 19639, 19638, 19635, 19628, 19627, 19626, 141484, 141483, 141480, 141477, 141470, 141460, 141457, 141454, 141452, 141451, 141450, 141445, 141444, 141440, 141437, 141428, 141418, 141415, 141412, 141410, 141409, 141408, 19621, 19620, 19616, 19613, 19604, 19594, 19591, 19588, 19586, 19585, 19584, 254356, 254354, 254352, 254350, 235646, 235644, 235642, 235640, 141574, 141572, 141570, 141568, 141566, 141564, 141560, 141558, 141556, 141550, 141548, 141546, 141539, 141536, 141534, 141527, 141524, 141522, 20993, 20992, 20991, 20990, 20989, 20988, 19646, 19643, 19641, 19634, 19631, 19629, 141511, 141505, 141504, 141503, 141502, 141501, 141496, 141492, 141491, 141490, 141489, 141481, 141473, 141472, 141471, 141469, 141468, 141463, 141458, 141456, 141455, 141453, 141441, 141431, 141430, 141429, 141427, 141426, 141421, 141416, 141414, 141413, 141411, 20983, 20982, 20981, 20980, 20979, 20978, 20977, 20976, 20975, 20974, 20973, 19617, 19607, 19606, 19605, 19603, 19602, 19597, 19592, 19590, 19589, 19587, 254357, 254355, 254353, 254351, 235647, 235645, 235643, 235641, 141573, 141569, 141567, 141563, 141562, 141559, 141552, 141549, 141542, 141541, 141538, 141529, 141526, 141516, 44933, 28422, 19645, 19636, 19633, 19624,
    141513, 141510, 141508, 141500, 141498, 141495, 141494, 141493, 141485, 141479, 141476, 141467, 141465, 141462, 141461, 141459, 141446, 141439, 141436, 141425, 141423, 141420, 141419, 141417, 19622, 19615, 19612, 19601, 19599, 19596, 19595, 19593, 141571, 141565, 141557, 141547, 141535, 141523, 28424, 28423, 19642, 19630,
    141515, 141512, 141507, 141506, 141499, 141497, 141487, 141482, 141475, 141474, 141466, 141464, 141448, 141442, 141435, 141432, 141424, 141422, 56042, 19618, 19611, 19608, 19600, 19598, 141514, 141509, 141488, 141486, 141478, 141449, 141447, 141438, 141406, 19623, 19614, 19582,

    652725, 28425, 19659, 48129, 48142, 48151, 19658, 19660, 44934, 48116, 48130, 48131, 48143, 48144, 48152, 13728, 17453, 19652, 19653, 19654, 19655, 48132, 48146, 20984, 20985, 20986, 20987, 48117, 48118, 48123, 48124, 48125, 48126, 48133, 48136, 48137, 48138, 48139, 48145, 48147, 48148, 48149, 48150, 19650, 19651, 19656, 19657, 48121, 48122, 48127, 48128, 48134, 48135, 48140, 48141
  ]);

  const sum = validTemplateIds.has(tmpl);
  //console.log("Sum", sum);
  return sum;
}


class AlienComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newListPandaSlot: false,
      pandaInSlot: "",
      errorOnGame: false, // ошибка для вывода карточек в игре
      isLoadedOnGame: false, // лоадинг для вывода карточек в игре
      cardOnGame: [], // сами карточки для вывода карточек в игре
      slotNFT: [],
      openedModal: false,
      numberSlot: "",
      userRow: [],
      rewardModalOpen: false,
      buyModalSlot: false,
      rewardModalText: {
        title: "",
        text: "",
        finalNumber: "",
        weaponPay: "",
        jewPay: "",
        animationNumber: "",
        resourceRew: [],
      },
      resultRew: {
        // данные про награду
        rarNft: [0, 1, 2, 3, 4, 5, 6],
        numberNft: [6600, 4000, 3240, 1200, 720, 180, 60],
        numberNew: [0.1, 0.1, 0.18, 0.5, 0.85, 3.4, 10.0],
        allCardCount: 16000, // общее число панд
        baz: 0.05, // ставка которая влияет на награду
        rang: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // ставка которая влияет на награду
        steps: [0, 8, 13, 21, 34, 55, 89, 144, 233, 377, 610, 987, 1597], // ставка которая влияет на награду
        percStake: 1, // Какой процент от заработанных токенов нужно стейкать
        rangPercProfit: [
          0, 0.02, 0.03, 0.05, 0.08, 0.13, 0.21, 0.34, 0.55, 0.89, 1.44, 2.33,
          3.77,
        ],
      },
      hasError: false, // ошибка транзакции
      errorText: "", // ошибка транзакции
      chooseHeroLoading: false, // loading slot
      openMultiSt: false,
      openChooseAlienOrPanda: false,
      typeOfNft: "",
      pandasInSlot: [],
      allPandasSlot: [],
      wasLoadPandas: false,
      userSlotsAlien: [],
      userSlotsAlienAvatar: [],
      userSlotsPanda: [],
      alienRegister: false,
      wasLoadAlienOnGame: false,
      alienOnGame: null,
      lastAdv: {
        panda: 0,
        numberslot: null,
        type: "",
        duration: ""
      },
      senToAdvModalOpen: false,
      openSharpModal: false,
      openBuyChestModal: false,
      thankBuyChestModal: false,
      sharpModalInfo: {
        type: "",
        hero: null,
        index: "",
        lvlNumber: 0,
        loadingResult: false,
        prevTimer: 0,
        resultOpen: false,
        result: "good"
      },
      allAlienRes: { res: [0, 0, 0, 0, 0] },
      allLvlAssets: [],
      allTopAlienUsers: null,
      showInfo: false,
      textInfo: `
      Read details about festival on <a class="green" href="https://nftpanda.medium.com/crossing-universes-season-3-nft-panda-world-of-fantasy-alien-worlds-planet-magor-syndicate-2ce56482df25">medium</a>.
      <br /><br />
      <h2>Potions</h2>
      <br />
      <div style="text-align: left">Amplify your gameplay with Small Space Power Potions and Space Power Potions. Find them in Alien Worlds Festival adventures or buy them in the shop for an instant boost to your slot send-offs!</div>
      <br />
      <div style="text-align: left">Small Space Power Potion — by using this potion (it is activated immediately for all slots), you will be able to choose to send slots immediately for 8 hours, you can find it in adventure with a 1% chance or buy it in the <a class="green" href="/shop">shop</a> for 4 TLM + 8 Gems.</div>
      <br />
      <div style="text-align: left">Space Power Potion — by using this potion (it is activated immediately for all slots), you will be able to choose to send slots immediately for 12 hours, you can find it in adventure with a 0.5% chance or buy it in the <a class="green" href="/shop">shop</a> for 10 TLM + 20 Gems.</div>
      <br />
      <h2>Sharpening</h2>
      <br />
      <div style="text-align: left">Power up your NFTs and boost your Space Crystal collection rate with Sharpening mechanic. Unsuccessful sharpenings are decreased by a -1 level of sharpening NFT, raising the stakes in this thrilling game of risk and reward!</div>
      <br />      
      <div style="text-align: left">The sharpening principle is as follows:<br />
      1st level, the chance of successful sharpening is 80% increase in crystal production <span class="green">+ 3%</span><br />
      2nd level, the chance of successful sharpening is 75% increase in crystal production <span class="green">+ 7%</span><br />
      3rd level, the chance of successful sharpening is 70% increase in crystal production <span class="green">+ 12%</span><br />
      4th level, the chance of successful sharpening is 65% increase in crystal production <span class="green">+ 19%</span><br />
      level 5, the chance of successful sharpening is 60% increase in crystal production <span class="green">+ 28%</span><br />
      level 6, the chance of successful enchanting is 55% increase in crystal production <span class="green">+ 39%</span><br />
      level 7, the chance of successful sharpening is 50% increase in crystal production <span class="green">+ 52%</span><br />
      level 8, the chance of successful sharpening is 45% increase in crystal production <span class="green">+ 66%</span><br />
      level 9, the chance of successful enchanting is 40% increase in crystal production <span class="green">+ 82%</span><br />
      level 10, the chance of successful sharpening is 35% increase in crystal production <span class="green">+ 100%</span><br />
      To get sharpening, the user will need to buy it by paying 2 TLM + 8 wood in the <a class="green" href="/shop">shop</a>.</div>
      `,
      allUserChest: [],
      allChestInfo: []
    };


    this.audioMusicError = new Audio(musicError);
    this.audioTimer = new Audio(musicTimer);
    this.audioMusicRemove = new Audio(musicRemove);

    //this.getArreySlots = this.getArreySlots.bind(this);
    //this.userCheck = this.userCheck.bind(this);
    this.GetPandaListOnGame = this.GetPandaListOnGame.bind(this);
    this.GetAlienListOnGame = this.GetAlienListOnGame.bind(this);
    this.getArreySlots = this.getArreySlots.bind(this);

    this.openModalBySlot = this.openModalBySlot.bind(this);
    this.chooseAlienOrPanda = this.chooseAlienOrPanda.bind(this);
    this.removeAlienOrPanda = this.removeAlienOrPanda.bind(this);
    this.openCloseModalBody = this.openCloseModalBody.bind(this);
    this.getAlienOnWallet = this.getAlienOnWallet.bind(this);
    this.onHandleSendPandaToSlot = this.onHandleSendPandaToSlot.bind(this);
    //this.onHandleSendPandaFromSlot = this.onHandleSendPandaFromSlot.bind(this);
    this.transfer = this.transfer.bind(this);
    this.sendToAdv = this.sendToAdv.bind(this);
    this.openModalResult = this.openModalResult.bind(this);

    this.clearError = this.clearError.bind(this);
    this.baySlot = this.baySlot.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.openSendToAdvModal = this.openSendToAdvModal.bind(this);
    this.getModalRewardInfoMult = this.getModalRewardInfoMult.bind(this);
    this.openCloseLoading = this.openCloseLoading.bind(this);
    this.openSharpModal = this.openSharpModal.bind(this);
    this.openBuyChestModal = this.openBuyChestModal.bind(this);
    this.sharpTrans = this.sharpTrans.bind(this);
    this.getSharpResult = this.getSharpResult.bind(this);
    this.openCloseResulSharp = this.openCloseResulSharp.bind(this);
    this.closeModalInfo = this.closeModalInfo.bind(this);
    this.bayChest = this.bayChest.bind(this);
    this.getUserChest = this.getUserChest.bind(this);
    this.GetChestInfo = this.GetChestInfo.bind(this);
    //this.getAllTMPL = this.getAllTMPL.bind(this);

  }

  componentDidMount() {
    //console.log("componentDidMount")
    const activeUser = this.props.ual.activeUser;

    //this.getAllTMPL();
    if (activeUser !== this.state.user) {
      (async () => {
        try {
          const accountName = await activeUser.getAccountName();
          this.setState({
            user: accountName
          });
          this.getArreySlots(accountName);

          try {
            const allAlienRes = await getAllAlienRes(accountName);
            if (allAlienRes && allAlienRes.res !== undefined) {
              this.setState({ allAlienRes: allAlienRes });
            }

          } catch (e) {
            console.error('Error fetching NFTs:', e);
            this.setState({
              hasError: true,
              errorText: e.message,
            });
          }

          try {
            const allLvlAssets = await getAllLvlAssets(accountName);
            if (allLvlAssets && allLvlAssets.length) {
              this.setState({ allLvlAssets: allLvlAssets });
            }

          } catch (e) {
            console.error('Error fetching NFTs:', e);
            this.setState({
              hasError: true,
              errorText: e.message,
            });
          }

          try {
            const allTopAlienUsers = await getAllTopAlienUsers(accountName);

            if (allTopAlienUsers && allTopAlienUsers.length) {
              this.setState({ allTopAlienUsers: allTopAlienUsers });
            }

          } catch (e) {
            console.error('Error fetching NFTs:', e);
            this.setState({
              hasError: true,
              errorText: e.message,
            });
          }

          //this.getAllTMPL();
        } catch (error) {
          console.error('Error fetching account name:', error);
        }
      })();

      (async () => {


      })();
    }


  }

  componentWillUnmount() { }

  openCloseModalBody(event) {
    if (event) {
      document.getElementsByTagName("body")[0].classList.add("open-modal");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("open-modal");
    }
  }

  openCloseLoading(event) {
    if (event) {
      document.getElementsByTagName("body")[0].classList.add("loading");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("loading");
    }
  }

  openModalBySlot(event, number) {
    this.setState({
      numberSlot: number,
      buyModalSlot: event
    });
    this.openCloseModalBody(event);
  }

  openModalResult(event, crystal) {
    console.log("crystal", crystal);
    if (event) {
      this.setState({
        ...this.state,
        openModalResult: event,
      });
    }
    else {
      var stateCrystal = { ...this.state.allAlienRes }

      //console.log("stateCrystal", stateCrystal);
      //console.log("crystal2", parseFloat(crystal));

      console.log("stateCrystal.res[0]", stateCrystal.res[0])
      console.log("parseFloat(crystal)", parseFloat(crystal))

      var wasPoints = parseInt(stateCrystal.res[0]);
      stateCrystal.res[0] = wasPoints + parseFloat(crystal * 10000);

      this.setState({
        ...this.state,
        openModalResult: event,
        allAlienRes: stateCrystal//14357415
      });
    }
  }

  async transfer() {
    let ref = "";
    if (localStorage.getItem("ref") !== null) {
      ref = localStorage.getItem("ref");
    } else {
      ref = process.env.REACT_APP_CONTRACT;
    }

    var username = this.props.ual.activeUser.accountName;
    loginTransaction.actions[0].authorization[0].actor = username;
    loginTransaction.actions[0].data.username = username;

    try {
      //console.log(loginTransaction);
      //this.setState({ openedTrans: true });
      await this.props.ual.activeUser.signTransaction(loginTransaction, {
        blocksBehind: 3,
        expireSeconds: 30,
      }).then((response) => {
        if (response.status === "executed") {
          console.log("was login");
          this.setState({
            ...this.state,
            alienRegister: true,
            userSlotsAlien: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            userSlotsAlienAvatar: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            userSlotsPanda: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            userRow: 3,
          })
          this.GetPandaListOnGame();
        }
      });

    } catch (error) {
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
      });
      console.log("\nCaught exception: " + error);
    }

  }

  chooseAlienOrPanda(event, number, type) {
    this.setState({
      typeOfNft: type,
      numberSlot: number,
      openChooseAlienOrPanda: event
    });

    this.openCloseModalBody(event);
  }

  async GetPandaListOnGame() {
    this.props.toggleIsFetchingTop(true);
    try {
      var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);
      await rpcc
        ?.get_table_rows({
          json: true,
          code: process.env.REACT_APP_CONTRACT, // contract who owns the table
          scope: process.env.REACT_APP_CONTRACT, // scope of the table
          table: "nftsongamec", // name of the table as specified by the contract abi
          key_type: "i64",
          lower_bound: this.state.user,
          upper_bound: this.state.user,
          limit: -1,
          reverse: !0,
          show_payer: false,
          index_position: 2,
        })
        .then((results) => {
          const resultRow = results.rows;
          const resultRowFilter = resultRow.filter(
            (row) => row.storer === this.state.user //&& row.is_in_slot === 0
          );
          var pandaObSlot = [];
          if (resultRow && resultRow.length) {
            resultRow.map((panda) => {
              if (panda.is_in_slot) {
                panda["activePanda"] = false;
                pandaObSlot.push(panda);
              }
            });
          }

          this.setState({
            wasLoadPandas: true,
            cardOnGame: resultRow,
            pandasInSlot: pandaObSlot,
            allPandasSlot: resultRowFilter
          });

          this.getAlienOnWallet();
        });
    } catch (e) {
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      setTimeout(() => {
        this.GetPandaListOnGame();
        if (numRpc == 8) {
          numRpc = 0;
        } else {
          numRpc++;
        }
      }, 1000);
      console.log("\nCaught exception: " + e);
    }
  }

  async GetAlienListOnGame() {
    this.props.toggleIsFetchingTop(true);
    try {
      var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);
      await rpcc
        ?.get_table_rows({
          json: true,
          code: process.env.REACT_APP_CONTRACT, // contract who owns the table
          scope: process.env.REACT_APP_CONTRACT, // scope of the table
          table: "aliensnft", // name of the table as specified by the contract abi
          key_type: "i64",
          lower_bound: this.state.user,
          upper_bound: this.state.user,
          limit: -1,
          reverse: !0,
          show_payer: false,
          index_position: 2,
        })
        .then((results) => {
          var resultRow = [];

          if (results && results.rows && results.rows.length) {
            resultRow = results.rows;
          }


          console.log("resultRow", resultRow)

          this.setState({
            wasLoadAlienOnGame: true,
            alienOnGame: resultRow
          });



          // this.getAlienOnWallet();
        });
    } catch (e) {
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      setTimeout(() => {
        this.GetAlienListOnGame();
        if (numRpc == 8) {
          numRpc = 0;
        } else {
          numRpc++;
        }
      }, 1000);
      console.log("\nCaught exception: " + e);
    }
  }

  // ФУНКЦИЯ которая берет значения с таблицы слотов ИМЕННО С МАСИВА СЛОТОВ [0,0,0,0,0,0,0,0,0,0,0,...20...0]
  async getArreySlots(user) {
    try {
      //const activeUser = this.props.ual.activeUser;
      //const accountName = await activeUser.getAccountName();
      var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);
      //console.log("this.state.user", this.state.user);
      //console.log("numRpc", numRpc);
      //console.log("rrRpc[numRpc]", arrRpc[numRpc]);
      const results = await rpcc.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "usersalien", // name of the table as specified by the contract abi
        limit: 1,
        lower_bound: user,
        upper_bound: user,
        index_position: 1,
        reverse: false,
        show_payer: false,
      });
      const originRowUser = results;

      //console.log("results 43", results)
      //console.log("user 43", user)
      if (results && results.rows && results.rows.length && results.rows[0].username === user) {
        const resultRowAlien = results.rows[0].slots_alien;
        const resultRowAlienAvatar = results.rows[0].slots_avatar;
        const resultRowPanda = results.rows[0].slots_panda;

        //console.log("resultRowAlien", resultRowAlien);
        this.setState({
          ...this.state,
          userSlotsAlien: resultRowAlien,
          userSlotsAlienAvatar: resultRowAlienAvatar,
          userSlotsPanda: resultRowPanda,
          userRow: originRowUser.rows[0].max_slots,
          alienRegister: true
        })
        this.GetPandaListOnGame();
      } else {

      }
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      setTimeout(() => {
        this.getArreySlots();
        if (numRpc == 8) {
          numRpc = 0;
        } else {
          numRpc++;
        }
      }, 1000);
      console.log("\nCaught exception: " + e);
      // if (localStorage.getItem("sound") === "true") {
      //   this.audioMusicError.currentTime = 0;
      //   this.audioMusicError.volume = 0.1;
      //   this.audioMusicError.play();
      // }
    }
  }
  // КОНЕЦ ФУНКЦИЯ которая берет значения с таблицы слотов

  // диспаччит таймер слот
  async clearError() {
    this.setState({
      errorText: "",
      hasError: false,
    });
  }

  // // aliens on wallet
  // async getAlienOnWallet() {
  //   try {

  //     //console.log("api", api);
  //       const asset = await api
  //         .getAssets({
  //           limit: 1000,
  //           owner: this.state.user,
  //           collection_name: "alien.worlds",
  //           schema_name: ["arms.worlds", "crew.worlds"],
  //           //ids: newArr,
  //         })
  //         .then((assets) => {
  //           var filterAssets = []

  //           if (assets && assets.length) {
  //             //console.log("assets", assets)
  //             var filterAssets = assets.filter((item) => {
  //               return caseAlienTmpl(Number(item.template.template_id));
  //             }).map((item) => {
  //               return {
  //                 asset_id: item.asset_id,
  //                 template: {
  //                   template_id: item.template.template_id
  //                 },
  //                 data: {
  //                   rarity: item.data.rarity
  //                 }
  //               }
  //             });
  //           }

  //           this.setState({
  //             allAlien: filterAssets,
  //           });
  //           this.GetAlienListOnGame();
  //         });
  //   } catch (e) {
  //     //console.log(e.message)
  //     console.warn(e);
  //     this.setState({
  //       hasError: true,
  //       errorText: e.message,
  //     });
  //     console.log("\nCaught exception: " + e);
  //     if (localStorage.getItem("sound") === "true") {
  //       this.audioMusicError.currentTime = 0;
  //       this.audioMusicError.volume = 0.1;
  //       this.audioMusicError.play();
  //     }
  //   }
  // } // End aliens on wallet

  async getAlienOnWallet(page = 1, limit = 1000) {
    try {
      const fetchAssets = async (page) => {
        const params = {
          limit: limit,
          page: page,
          owner: this.state.user,
          collection_name: "alien.worlds",
          schema_name: ["arms.worlds", "crew.worlds", "faces.worlds"],
        };

        const assets = await api.getAssets(params);

        console.log("assets", assets);

        if (assets && assets.length) {
          const filteredAssets = assets
            .filter((item) => {
              return caseAlienTmpl(Number(item.template.template_id));
            })
            .map((item) => {
              return {
                asset_id: item.asset_id,
                template: {
                  template_id: item.template.template_id,
                },
                data: {
                  rarity: item.data.rarity,
                },
                schema: item.schema.schema_name
              };
            });

          return filteredAssets;
        }

        return [];
      };

      const allAssets = [];
      let currentPage = page;
      let hasMorePages = true;

      while (hasMorePages) {
        const assets = await fetchAssets(currentPage);

        if (assets.length === 0) {
          hasMorePages = false;
        } else {
          allAssets.push(...assets);
          if (assets.length === limit) {
            currentPage += 1;
          } else {
            hasMorePages = false;
          }
        }
      }

      this.setState({
        allAlien: allAssets,
      });
      this.GetAlienListOnGame();
    } catch (e) {
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  // отправка панды в слот
  async onHandleSendPandaToSlot(panda, indexPanda, type) {
    // console.log("onHandleSendPandaToSlot")
    // console.log("panda", panda,)
    // console.log("indexPanda", indexPanda)
    // console.log("type", type)

    this.setState({
      ...this.state,
      chooseHeroLoading: true,
    });

    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    cardTransferSlot.actions[0].authorization[0].actor = userName;
    cardTransferSlot.actions[0].data.username = userName;
    cardTransferSlot.actions[0].data.asset_id = panda.asset_id;
    cardTransferSlot.actions[0].data.number_slot = this.state.numberSlot;
    cardTransferSlot.actions[0].data.type = type == "avatar" ? "alien" : type;
    console.log(cardTransferSlot);

    try {
      await activeUser
        .signTransaction(cardTransferSlot, {
          blocksBehind: 3,
          expireSeconds: 30,
        })
        .then((response) => {
          if (response.status === "executed") {

            var state_panda = [...this.state.userSlotsPanda];
            var state_alien = [...this.state.userSlotsAlien];
            var state_alien_avatar = [...this.state.userSlotsAlienAvatar];
            var state_alienOnGame = [...this.state.alienOnGame];
            var state_pandasInSlot = [...this.state.pandasInSlot];
            var state_allPandasSlot = [...this.state.allPandasSlot];

            //console.log("state_pandasInSlot", state_pandasInSlot);

            /*
            userSlotsAlien: [],
            userSlotsPanda: [],
            */

            // console.log("type", type);
            // console.log("state_allPandasSlot1", state_allPandasSlot);
            // console.log("panda.asset_id", panda.asset_id);

            if (type == "panda") {
              state_panda[this.state.numberSlot] = panda.asset_id;
              state_pandasInSlot.push(panda);
              state_allPandasSlot = state_allPandasSlot.filter(n => n.asset_id !== panda.asset_id).map(u => { return u; })

            } else {
              var newObj = {
                asset_id: panda.asset_id,
                storer: this.state.user,
                timer: 0,
                tmpl: panda.template.template_id
              }

              //console.log("newObj", newObj);

              if (type == "avatar") {
                state_alien_avatar[this.state.numberSlot] = panda.asset_id;
                state_alienOnGame.push(newObj);
              } else {
                state_alien[this.state.numberSlot] = panda.asset_id;
                state_alienOnGame.push(newObj);
              }
            }

            this.setState({
              chooseHeroLoading: false,
              openChooseAlienOrPanda: false,
              userSlotsPanda: state_panda,
              userSlotsAlien: state_alien,
              userSlotsAlienAvatar: state_alien_avatar,
              alienOnGame: state_alienOnGame,
              pandasInSlot: state_pandasInSlot,
              allPandasSlot: state_allPandasSlot
            });

            this.openCloseModalBody(false);


            if (localStorage.getItem("sound") === "true") {
              this.audioMusicRemove.currentTime = 0;
              this.audioMusicRemove.volume = 0.1;
              this.audioMusicRemove.play();
            }
          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
        chooseHeroLoading: false,
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }

  }

  // отправка панды в слот
  async sendToAdv(panda, numberslot, type, duration) {
    //console.log("numberslot", numberslot)
    //console.log("panda", panda)
    this.setState({
      ...this.state,
      chooseHeroLoading: true,
    });

    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    goTransAdv.actions[0].authorization[0].actor = userName;
    goTransAdv.actions[0].data.storer = userName;
    goTransAdv.actions[0].data.numberslot = numberslot;
    goTransAdv.actions[0].data.typeadv = type;
    goTransAdv.actions[0].data.duration = duration;
    //console.log(goTransAdv);
    this.openCloseLoading(true);

    try {
      await activeUser
        .signTransaction(goTransAdv, {
          blocksBehind: 3,
          expireSeconds: 30,
        })
        .then((response) => {
          if (response.status === "executed") {
            this.openCloseModalBody(true);
            this.getModalRewardInfoMult(panda, numberslot, type, duration);
          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      this.openCloseLoading(false);
      this.setState({
        hasError: true,
        errorText: error.message,
        chooseHeroLoading: false,
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }

  }

  // заточка
  async sharpTrans(panda, numberslot, prevTimer, prevLvl) {
    //console.log("numberslot", numberslot)
    //console.log("panda", panda)
    this.openCloseLoading(true);
    this.setState({
      ...this.state,
      sharpModalInfo: {
        ...this.state.sharpModalInfo,
        loadingResult: true
      }
    });

    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    sharpTrans.actions[0].authorization[0].actor = userName;
    sharpTrans.actions[0].data.username = userName;
    sharpTrans.actions[0].data.slotNumber = numberslot;
    sharpTrans.actions[0].data.asset_id = panda;
    //console.log(sharpTrans);
    //this.openCloseLoading(true);

    try {
      await activeUser
        .signTransaction(sharpTrans, {
          blocksBehind: 3,
          expireSeconds: 30,
        })
        .then((response) => {
          if (response.status === "executed") {
            // this.openCloseModalBody(true);
            this.getSharpResult(panda, prevTimer, prevLvl);
          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      this.openCloseLoading(false);
      this.setState({
        hasError: true,
        errorText: error.message,
        chooseHeroLoading: false,
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }

  }

  async getModalRewardInfoMult(panda, numberslot, type, duration) {
    //console.log("getModalRewardInfoMult");
    await this.GetAlienListOnGame();
    await this.GetPandaListOnGame();

    try {
      //var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);
      var allTimers = false;
      var nowTime = Math.floor(Date.now() / 1000);

      // console.log("panda", panda)
      // console.log("numberslot", numberslot)
      // console.log("type", type)

      if (type === "all") {
        console.log("all");

        this.state.alienOnGame.map((item) => {
          if (item.timer > nowTime && !allTimers) {
            allTimers = true;
          }
        });
        this.state.allPandasSlot.map((item) => {
          if (item.timer > nowTime && !allTimers) {
            allTimers = true;
          }
        });
      } else {
        console.log("other");
        const getAlienHero = this.state.userSlotsAlien[numberslot];
        const getAlienAvatar = this.state.userSlotsAlienAvatar[numberslot];
        const getPandaHero = this.state.userSlotsPanda[numberslot];

        if (getAlienHero) {
          this.state.alienOnGame.map((item) => {
            if (item.asset_id == getAlienHero && item.timer > nowTime && !allTimers) {
              allTimers = true;
            }
          });
        } else if (getAlienAvatar) {
          this.state.alienOnGame.map((item) => {
            if (item.asset_id == getAlienAvatar && item.timer > nowTime && !allTimers) {
              allTimers = true;
            }
          });
        } else if (getPandaHero) {
          console.log("getPandaHero");
          console.log("this.state.allPandasSlot", this.state.allPandasSlot);
          this.state.allPandasSlot.map((item) => {

            console.log("item", item);
            if (item.timer > nowTime && !allTimers) {
              allTimers = true;
            }
          });
        }

      }

      //console.log("allTimers", allTimers);

      if (!allTimers) {
        //console.log("setInterval");
        this.interval = setTimeout(() => {

          this.getModalRewardInfoMult(panda, numberslot, type, duration);

          //console.log("this.getModalRewardInfoMult()");
        }, 10000);
      } else {
        //console.log("clearInterval");

        var xfactor = 1;
        if (duration == 8) {
          xfactor = 2;
        } else if (duration == 12) {
          xfactor = 3;
        }
        this.openCloseLoading(false);
        this.openCloseModalBody(false);
        var state_alienOnGame = [...this.state.alienOnGame];
        var futureDate = Date.now() / 1000 + (14400 * xfactor);

        //console.log(1, Date.now());
        if (type === "one") {
          state_alienOnGame = state_alienOnGame.map((item) => {
            if (item.asset_id == panda) {
              item.timer = futureDate;
            }
            return item;
          })
        } else {
          state_alienOnGame.map((item) => {
            item.timer = futureDate;
          })
        }
        //console.log(2, Date.now());

        this.setState({
          alienOnGame: state_alienOnGame,
          lastAdv: {
            panda: panda,
            numberslot: numberslot,
            type: type,
            duration: duration
          },
          senToAdvModalOpen: false
        });
        console.log(3, Date.now());

        //this.openCloseModalBody(false);
        this.openModalResult(true);
        if (localStorage.getItem("sound") === "true") {
          this.audioMusicRemove.currentTime = 0;
          this.audioMusicRemove.volume = 0.1;
          this.audioMusicRemove.play();
        }

        clearInterval(this.interval);
      }


    } catch (e) {
      this.openCloseLoading(false);
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  async getSharpResult(asset, prevTimer, prevLvl) {

    const allLvlAssets = await getAllLvlAssets(this.state.user);
    if (allLvlAssets && allLvlAssets.length) {
      this.setState({ allLvlAssets: allLvlAssets });
    }

    try {

      var allTimers = false;
      var nowTime = Math.floor(Date.now() / 1000);
      var nowLvl;
      var resultSharp = false;

      //console.log("asset", asset)
      //console.log("prevTimer", prevTimer)
      //console.log("prevLvl", prevLvl)
      this.state.allLvlAssets.map((item) => {
        //console.log("item", item);
        if (item.asset_id === asset && item.timer !== prevTimer && !allTimers) {
          // console.log("item", item);
          // console.log("item.timer", item.timer);
          // console.log("nowTime", nowTime);
          // console.log("item.lvl", item.lvl);
          allTimers = true;
          nowLvl = item.lvl;
        }
      });

      console.log("allTimers", allTimers);
      if (!allTimers) {
        this.interval = setTimeout(() => {
          this.getSharpResult(asset, prevTimer, prevLvl);
          console.log("this.getSharpResult()");
        }, 10000);
      } else {
        //console.log("clearInterval");
        if (nowLvl > prevLvl) {
          resultSharp = "good";
        } else {
          resultSharp = "bad";
        }

        this.setState({
          ...this.state,
          sharpModalInfo: {
            ...this.state.sharpModalInfo,
            loadingResult: false,
            result: resultSharp,
            resultOpen: true,
          }
        });

        //this.openCloseModalBody(false);
        this.openCloseLoading(false);
        //this.openModalResult(true);
        if (localStorage.getItem("sound") === "true") {
          this.audioMusicRemove.currentTime = 0;
          this.audioMusicRemove.volume = 0.1;
          this.audioMusicRemove.play();
        }

        clearInterval(this.interval);
      }


    } catch (e) {
      this.openCloseLoading(false);
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  handleUpdate(e) {
    var state_alienOnGame = [...this.state.alienOnGame];

    state_alienOnGame = state_alienOnGame.map((item) => {
      if (item.asset_id == e) {
        item.timer = 0;
      }
      return item;
    })

    this.setState({
      alienOnGame: state_alienOnGame,
    });
  }

  openSendToAdvModal(e) {

    this.setState({
      ...this.state,
      senToAdvModalOpen: e
    });

  }

  openSharpModal(e, type, info, index, lvlNumber, prevTimer) {
    //console.log("lvlNumber", lvlNumber);
    //console.log("prevTimer", prevTimer);
    console.log("e, type, info, index, lvlNumber, prevTimer", e, type, info, index, lvlNumber, prevTimer);
    if (e) {
      this.setState({
        ...this.state,
        openSharpModal: e,
        sharpModalInfo: {
          ...this.state.sharpModalInfo,
          type: type,
          hero: info,
          index: index,
          lvlNumber: lvlNumber,
          prevTimer: prevTimer,
          result: "",
          resultOpen: false,
          //loadingResult: true
        }
      });
    } else {
      this.setState({
        ...this.state,
        openSharpModal: e,
        sharpModalInfo: {
          ...this.state.sharpModalInfo,
          type: "",
          hero: "",
          index: "",
          lvlNumber: "",
          prevTimer: 0,
          result: "",
          resultOpen: false,
        }
      });
    }

  }

  openBuyChestModal(e) {
    //console.log("lvlNumber", lvlNumber);
    //console.log("prevTimer", prevTimer);
    this.getUserChest();
    this.GetChestInfo();
    if (e) {
      this.setState({
        ...this.state,
        openBuyChestModal: e,
      });
    } else {
      this.setState({
        ...this.state,
        openBuyChestModal: e,
        thankBuyChestModal: false
      });
    }

  }


  openCloseResulSharp(e, result) {

    console.log("e", e);
    console.log("result", result);
    if (e) {

    } else {
      // var actibeModalInfo = { ...this.state.sharpModalInfo }
      // console.log("actibeModalInfo", actibeModalInfo);
      // var finalResNumb = actibeModalInfo.lvlNumber;
      // if (result === "good" && finalResNumb > 0) {
      //   finalResNumb += 1;
      // } else {
      //   finalResNumb -= 1;
      // }
      // actibeModalInfo.lvlNumber = finalResNumb;
      // actibeModalInfo.resultOpen = false;

      // console.log("actibeModalInfo", actibeModalInfo);
      // this.setState({
      //   ...this.state,
      //   sharpModalInfo: {
      //     ...this.state.sharpModalInfo,
      //     ...actibeModalInfo
      //   }
      // });
      this.setState({
        ...this.state,
        openSharpModal: e,
        sharpModalInfo: {
          ...this.state.sharpModalInfo,
          type: "",
          hero: "",
          index: "",
          lvlNumber: "",
          prevTimer: 0,
          result: "",
          resultOpen: false,
        }
      });
    }

  }

  // отправка панды из слота
  async removeAlienOrPanda(panda, type) {
    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    cardTransferOutFromSlot.actions[0].authorization[0].actor = userName;
    cardTransferOutFromSlot.actions[0].data.username = userName;
    cardTransferOutFromSlot.actions[0].data.asset_id = panda.asset_id;
    cardTransferOutFromSlot.actions[0].data.type = type;
    //console.log(cardTransferOutFromSlot);

    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");

    try {
      await activeUser
        .signTransaction(cardTransferOutFromSlot, {
          blocksBehind: 3,
          expireSeconds: 30,
        })
        .then((response) => {
          if (response.status === "executed") {

            loadingSlot.classList.remove("loading");

            var state_panda = [...this.state.userSlotsPanda];
            var state_alien = [...this.state.userSlotsAlien];
            var state_alien_avatar = [...this.state.userSlotsAlienAvatar];
            var state_alienOnGame = [...this.state.alienOnGame];
            var state_pandasInSlot = [...this.state.pandasInSlot];
            var state_allPandasSlot = [...this.state.allPandasSlot];

            console.log("state_alien1", state_alien);
            console.log("state_alienOnGame1", state_alienOnGame);

            if (type == "panda") {
              // state_panda[indexPanda] = panda.asset_id;
              // state_alienOnGame[indexPanda] = panda;
              // state_pandasInSlot.push(panda);
              //state_panda = state_panda.filter(n => n !== panda.asset_id).map(u => { return u; })
              state_panda = state_panda.map(n => {
                if (n === panda.asset_id) {
                  return 0;
                } else {
                  return n;
                }
              });

              //state_pandasInSlot = state_pandasInSlot.filter(n => n.asset_id !== panda.asset_id).map(u => { return u; })

              state_allPandasSlot.push(panda);
            } else if (type == "avatar") {
              state_alien_avatar = state_alien_avatar.map(n => {
                if (n === panda.asset_id) {
                  return 0;
                } else {
                  return n;
                }
              });
            } else {
              //state_alien = state_alien.filter(n => n !== panda.asset_id).map(u => { return u; })
              state_alien = state_alien.map(n => {
                if (n === panda.asset_id) {
                  return 0;
                } else {
                  return n;
                }
              });
              //state_alienOnGame = state_alienOnGame.filter(n => n.asset_id !== panda.asset_id).map(u => { return u; })
            }

            console.log("state_alien2", state_alien);
            console.log("state_alienOnGame2", state_alienOnGame);

            this.setState({
              chooseHeroLoading: false,
              openChooseAlienOrPanda: false,
              userSlotsPanda: state_panda,
              userSlotsAlien: state_alien,
              userSlotsAlienAvatar: state_alien_avatar,
              alienOnGame: state_alienOnGame,
              pandasInSlot: state_pandasInSlot,
              allPandasSlot: state_allPandasSlot
            });

            this.openCloseModalBody(false);

            if (localStorage.getItem("sound") === "true") {
              this.audioMusicRemove.currentTime = 0;
              this.audioMusicRemove.volume = 0.1;
              this.audioMusicRemove.play();
            }
          }
        });
    } catch (error) {
      //console.log(error.message)
      loadingSlot.classList.remove("loading");
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  // покупка слота
  async baySlot(moneysum) {

    /*from: "",
        to: process.env.REACT_APP_CONTRACT,
        quantity: "",
        memo: "buyslot"*/
    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    slotTransBay.actions[0].authorization[0].actor = userName;
    slotTransBay.actions[0].data.from = userName;
    slotTransBay.actions[0].data.quantity = parseFloat(moneysum).toFixed(4) + " TLM";
    console.log(slotTransBay);

    try {
      await activeUser
        .signTransaction(slotTransBay, { blocksBehind: 3, expireSeconds: 30 })
        .then((response) => {
          if (response.status === "executed") {
            document.getElementsByClassName("modal-wrap-by-slote")[0].classList.remove("open");

            var newSlot = this.state.userRow + 1;
            this.setState({
              ...this.state,
              userRow: newSlot
            })

            this.openCloseModalBody(false);

          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  // покупка слота
  async bayChest(count) {

    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;

    bayChestTrans.actions[0].authorization[0].actor = userName;
    bayChestTrans.actions[0].data.username = userName;
    bayChestTrans.actions[0].data.count = count;
    console.log(bayChestTrans);

    try {
      await activeUser
        .signTransaction(bayChestTrans, { blocksBehind: 3, expireSeconds: 30 })
        .then((response) => {
          if (response.status === "executed") {

            // var newSlot = this.state.userRow + 1;

            var stateCrystal = { ...this.state.allAlienRes }

            stateCrystal.res[0] -= parseFloat(count) * 100000000000;

            this.setState({
              ...this.state,
              thankBuyChestModal: true,
              allAlienRes: stateCrystal
            })

            // this.openCloseModalBody(false);

          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  closeModalInfo(e) {
    if (e) {
      this.setState({
        ...this.state,
        showInfo: true
      });
      this.openCloseLoading(true);
    } else {
      this.setState({
        ...this.state,
        showInfo: false
      });
      this.openCloseLoading(false);
    }
  }

  async getUserChest() {
    (async () => {
      try {
        const userName = this.props.ual.activeUser.accountName;
        await api
          .getAssets({
            limit: 500,
            owner: userName,
            collection_name: "nftpandawaxp",
            schema_name: ["chests" /*, "promo"*/],
            //template_id: 437403,
          })
          .then((assets) => {

            var filterAssets = assets.map(item => {
              return {
                asset_id: item.asset_id,
                template_id: item.template.template_id,
                shema: item.schema.schema_name
              }
            })

            this.setState({
              ...this.state,
              allUserChest: filterAssets
            });
          });
      } catch (e) {
        setTimeout(() => {
          this.getUserChest();
          if (numRpc == 8) {
            numRpc = 0;
          } else {
            numRpc++;
          }
        }, 1000);
        console.log("\nCaught exception: " + e);
      }
    })();

  }



  async GetChestInfo() {
    this.props.toggleIsFetchingTop(true);
    try {
      var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);
      await rpcc
        ?.get_table_rows({
          json: true,
          code: process.env.REACT_APP_CONTRACT, // contract who owns the table
          scope: process.env.REACT_APP_CONTRACT, // scope of the table
          table: "alchestinf", // name of the table as specified by the contract abi
          key_type: "i64",
          //lower_bound: this.state.user,
          //upper_bound: this.state.user,
          limit: -1,
          reverse: !0,
          show_payer: false,
          //index_position: 2,
        })
        .then((results) => {
          const resultRow = results.rows;

          if (resultRow && resultRow.length) {
            this.setState({
              ...this.state,
              allChestInfo: resultRow
            });
          }
        });
    } catch (e) {
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      setTimeout(() => {
        this.GetPandaListOnGame();
        if (numRpc == 8) {
          numRpc = 0;
        } else {
          numRpc++;
        }
      }, 1000);
      console.log("\nCaught exception: " + e);
    }
  }

  render() {

    //console.log("this.state.buyModalSlot", this.state.buyModalSlot)
    return (
      <>
        <div className="open-modal-chest">
          <div className="bt-alien first alien-ch" onClick={() => this.openBuyChestModal(true)}>
            alien
            chest
          </div>
        </div>


        {this.state.showInfo && (
          <ModalInfo closeModalInfo={this.closeModalInfo} textInfo={this.state.textInfo} />
        )}
        {this.state.openModalResult ? <Result
          openModalResult={this.openModalResult}
          userSlotsAlien={this.state.userSlotsAlien}
          userSlotsAlienAvatar={this.state.userSlotsAlienAvatar}
          alienOnGame={this.state.alienOnGame}
          userSlotsPanda={this.state.userSlotsPanda}
          userRow={this.state.userRow}

          pandasInSlot={this.state.pandasInSlot}
          userSlots={this.state.userSlots}
          allUserInfo={this.props.allUserInfo}
          transfer={this.transfer}
          allLvlAssets={this.state.allLvlAssets}
          lastAdv={this.state.lastAdv} /> : null}

        {this.state.senToAdvModalOpen ? <GoToAdvModal
          openSendToAdvModal={this.openSendToAdvModal}
          sendToAdv={this.sendToAdv}
          allAlienRes={this.state.allAlienRes}

        /> : null}

        {this.state.openSharpModal ? <SharpModal
          openSharpModal={this.openSharpModal}
          sharpModalInfo={this.state.sharpModalInfo}
          sharpTrans={this.sharpTrans}
          allAlienRes={this.state.allAlienRes}
          openCloseResulSharp={this.openCloseResulSharp
          }

        /> : null}

        {this.state.openBuyChestModal ? <BuyChestModal
          openBuyChestModal={this.openBuyChestModal}
          thankBuyChestModal={this.state.thankBuyChestModal}
          allUserChest={this.state.allUserChest}
          allChestInfo={this.state.allChestInfo}
          bayChest={this.bayChest}


        /> : null}

        <TotalInfo
          allAlienRes={this.state.allAlienRes}
          allTopAlienUsers={this.state.allTopAlienUsers}
          user={this.state.user}
          closeModalInfo={this.closeModalInfo}
        />

        <Menu {...this.props.ual} wax={this.props.balanceWax} bam={this.props.balanceBam} game={this.props.balanceGame} updateBalance={this.removeAddBam} />

        {this.state.buyModalSlot ?
          <BaySlot openModalBySlot={this.openModalBySlot} userRow={this.state.userRow} baySlot={this.baySlot} />

          : null
        }

        {this.state.openChooseAlienOrPanda && this.state.typeOfNft ?
          <ChooseAlienOrPanda
            chooseAlienOrPanda={this.chooseAlienOrPanda}
            typeOfNft={this.state.typeOfNft}
            allPandasSlot={this.state.allPandasSlot}
            allAlien={this.state.allAlien}
            allAlienSlot={this.state.allAlienSlot}
            onHandleSendPandaToSlot={this.onHandleSendPandaToSlot}
            userSlotsAlien={this.state.userSlotsAlien}
            userSlotsAlienAvatar={this.state.userSlotsAlienAvatar}
          />

          : null
        }

        <>
          {this.state.hasError ? (
            <ErrorComponent
              errorMessage={this.state.errorText}
              clearError={this.clearError}
            />
          ) : null}




          <main className="slot-page alien-slots alien-festivas">
            <div className="loading-block"></div>

            <section id="all-slots">
              <div className="container">
                <div className="alien-panda-logo">
                  <picture>
                    <source srcSet={alienlogow} type="image/webp" />
                    <source srcSet={alienlogo} type="image/png" />
                    <img src={alienlogow} alt="" />
                  </picture>
                </div>

                {//Date.now() >= 1688126400000 && Date.now() <= 1690891200000 ? это для официального старта ивента
                  // 1690891200 - это дата начала ивента Tue, 01 Aug 2023 12:00:00 GMT
                  // 1696161600 - это дата конца ивента Sat, 30 Sep 2023 12:00:00 GMT
                  Date.now() > timerEnd * 1000 ? // это для теста, чтобы убрать сообщение которое говорит когда начало старта, тоесть у нас когбудто уже старт
                    <>

                      <div className="info-wrap-block">
                        <br />
                        <br /><br />
                        <div className="info-before-start">
                          <div className="red-line">
                            <div className="leader-alien-timer">
                              <Countdown date={timerEnd * 1000} />
                            </div>
                            <div className="leader-date">
                              We are pleased to announce that the alien worlds festival has been completed. < br />
                              Congratulations to all the winners!< br />
                              The start of the next season will be announced soon, stay tuned.
                            </div>
                          </div>
                        </div>

                        {/* <div className="leader-bt">
                        <div className="one-leader-bt">
                          <a className="bt-alien first" target="_blank" href="https://nftpanda.medium.com/crossing-universes-season-3-nft-panda-world-of-fantasy-alien-worlds-planet-magor-syndicate-2ce56482df25">
                            more information
                          </a>
                        </div>
                      </div> */}
                      </div>
                    </>
                    :
                    //Date.now() <= 1690891200000 ? // это для официального старта ивента
                    Date.now() <= timerStart * 1000 ? // тут нужно указать что ивен начинается с первого августа и длится до 1 октября
                      <div className="info-wrap-block">
                        <br />
                        <br /><br />
                        <div className="info-before-start">
                          <div className="red-line">
                            <div className="leader-date">
                              The fourth season of Alien Worlds Festival will run From 1 April 12 UTC to 1 June 12 UTC! <br />Time left before the start
                            </div>
                            <div className="leader-alien-timer">
                              <Countdown date={timerStart * 1000} />
                            </div>
                          </div>
                        </div>
                        <div className="leader-bt">
                          <div className="one-leader-bt">
                            <a className="bt-alien first" target="_blank" href="https://discord.com/invite/vCSgme4d5b">
                              more information
                            </a>
                          </div>
                        </div>
                      </div>
                      :

                      !this.state.alienRegister ?
                        <div className="register-alien">
                          <div className="bt-alien first" onClick={() => this.transfer()}>
                            registration
                          </div>
                        </div>
                        :
                        <div className="register-alien">
                          {this.state.userSlotsAlien.some(value => value !== 0) ?
                            <div className="bt-alien first" onClick={() => this.openSendToAdvModal(true)}>
                              send all
                            </div>
                            :
                            <div className="bt-alien first disabled">
                              send all
                            </div>
                          }
                          <NavLink className="bt-alien second" to="/alien-leaderboard">
                            leaderboard
                          </NavLink>
                        </div>
                }

                {/* <div className="register-alien">
                            <div className="bt-alien first" onClick={() => this.transfer()}>
                                registration
                            </div>
                        </div>

                        <div className="register-alien">
                            {this.state.userSlotsAlien.some(value => value !== 0) ?
                            <div className="bt-alien first" onClick={() => this.sendToAd(0,0,"all")}>
                                send all
                            </div>
                            :
                            <div className="bt-alien first disabled">
                                send all
                            </div>
                            }
                            <NavLink className="bt-alien second" to="/alien-leaderboard">
                                leaderboard
                            </NavLink>
                        </div> */}




                <div className="all-slots-in">
                  {
                    this.state.wasLoadPandas &&
                      this.state.wasLoadAlienOnGame &&
                      this.state.alienRegister
                      //&& this.state.pandasInSlot && this.state.pandasInSlot.length 
                      ? (
                        <Slots
                          {...this.props}
                          userSlotsAlien={this.state.userSlotsAlien}
                          userSlotsAlienAvatar={this.state.userSlotsAlienAvatar}
                          alienOnGame={this.state.alienOnGame}
                          userSlotsPanda={this.state.userSlotsPanda}
                          userRow={this.state.userRow}
                          //onFormSubmit={this.onFormSubmit}
                          //slotNFT={this.props.pandaSlotOnGame}
                          //pandaListOnGame={this.props.pandaListOnGame}
                          //showCloseModalJew={this.showCloseModalJew}
                          showCloseModal={this.showCloseModal}
                          //resultRew={this.state.resultRew}
                          chooseHeroLoading={this.state.chooseHeroLoading}
                          //pandaInSlot={this.state.pandaInSlot}
                          pandasInSlot={this.state.pandasInSlot}
                          userSlots={this.state.userSlots}
                          allUserInfo={this.props.allUserInfo}
                          transfer={this.transfer}

                          openModalBySlot={this.openModalBySlot}
                          chooseAlienOrPanda={this.chooseAlienOrPanda}
                          removeAlienOrPanda={this.removeAlienOrPanda}
                          handleUpdate={this.handleUpdate}

                          sendToAdv={this.sendToAdv}
                          openSharpModal={this.openSharpModal}
                          allLvlAssets={this.state.allLvlAssets}
                        />
                      ) : null}
                </div>
              </div>
            </section>

            <footer>
              <div className="footer-nav">
                <nav>
                  <ul>
                    <li>
                      <a className="foot-logo-1" target="_blank" href="https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&sort=created&symbol=WAX">
                        <span>

                        </span>
                        <i>
                          Market
                        </i>
                      </a>
                    </li>
                    <li>
                      <a className="foot-logo-2" target="_blank" href="https://twitter.com/NftPanda/">
                        <span>

                        </span>
                        <i>
                          Twitter
                        </i>
                      </a>
                    </li>
                    <li>
                      <a className="foot-logo-3" target="_blank" href="https://www.instagram.com/nftpanda/">
                        <span>

                        </span>
                        <i>
                          Instagram
                        </i>
                      </a>
                    </li>
                    <li>
                      <a className="foot-logo-4" target="_blank" href="https://discord.gg/vCSgme4d5b">
                        <span>

                        </span>
                        <i>
                          Discord
                        </i>
                      </a>
                    </li>
                    <li>
                      <a className="foot-logo-5" target="_blank" href={`https://wax.alcor.exchange/swap?output=${process.env.REACT_APP_VAL}-nftpandabamb&input=WAX-eosio.token`}>
                        <span>

                        </span>
                        <i>
                          {process.env.REACT_APP_VAL} Trade
                        </i>
                      </a>
                    </li>
                    <li>
                      <a className="foot-logo-6" target="_blank" href="https://discord.gg/vCSgme4d5b">
                        <span>

                        </span>
                        <i>
                          Support
                        </i>
                      </a>
                    </li>
                  </ul>

                </nav>
              </div>
            </footer>

          </main>

        </>

      </>
    );
  }
}

let mapStateToProps = (state) => ({
  // pandaSlotOnGame: state.slotReducer.pandaSlotOnGame,
  // pandaListOnGame: state.slotReducer.pandaListOnGame,
  // userRow: state.slotReducer.userRow,
  // wasbay: state.showReduser.wasbay,
  // pandaSlotOnGameWax: state.slotReducer.pandaSlotOnGameWax,
  // wasLoadPandas: state.slotReducer.wasLoadPandas,
  // userSlots: state.slotReducer.userSlots,
});

export default connect(mapStateToProps, {
  // setSlotPandaOnGame,
  // setPandaOnGame,
  toggleIsFetchingTop,
  // doDelFromState,
  // addToSlotState,
  // doDelFromSlot,
  // setUsersRow,
  // addSlot /*setTimerToState,*/,
  // setTimerEnd,
  // removePandaAdv,
  // setPandaOnGameWax,
  // setWasLoadPandas,
  // choosePanda,
  // setUserSlots,
})(AlienComponent);
