import React from 'react';

const BaySlot = (props) => {

    return ( 
    <div className="modal-wrap-by-slote open">
        <div className="bamb-modal-body">
            <div className="bamb-modal-body-in">
                <div className="bamb-modal">
                    <div className="bamb-modal-in">
                        <div className="modal-close" onClick={() => props.openModalBySlot(false, 0)}></div>
                        <div className="bamb-modal-top"></div>
                        <div className="bamb-modal-bot"></div>
                        <div className="bamb-modal-wrap">
                            <div className="bamb-modal-tit">
                                <div className="bamb-modal-tit-slot">
                                    ADD SLOT {props.userRow + 1}
                                    {/* <span></span> */}
                                </div>
                            </div>
                            <div className="bamb-modal-descr">
                                It’s time to grow your army. Confirm adding a new slot. <br /> It will cost <span>{(props.userRow) * 3} TLM</span> tokens.
                            </div>
                            <div className="bamb-modal-bt">
                                <button className="def-bt mid" onClick={(e) => {
                                    props.baySlot((props.userRow) * 3);
                                }}>
                                    <span className="button-in">
                                        <span className="button-name">
                                            <span className="button-name-in">
                                                <span className="button-name-value">
                                                    Buy Slot
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </button>
                            </div>
                            <div className="bamb-modal-bt bamb-modal-bam-buy">
                                <a href={`https://wax.alcor.exchange/swap?output=TLM-alien.worlds&input=WAX-eosio.token`} target="_blank" className="def-bt min">
                                    <span className="button-in">
                                        <span className="button-name">
                                            <span className="button-name-in">
                                                <span className="button-name-value">
                                                    Buy TLM
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg-modal"></div>
    </div>
);

}

export default BaySlot;