import React, { useState, useEffect } from 'react';
import ResultDetal from "./ResultDetal";
import "./alienAdvStyle.scss";
import PandaVideoComp from '../PandaVideoComp';
import {
    /*BrowserRouter, Redirect, Route, Switch,*/ Link,
    NavLink,
} from "react-router-dom";

import rewtop from '../../images/alien/result-top.png';
import rewtopw from '../../images/alien/result-top.webp';
import rewbot from '../../images/new-adv/multi-reward-bot.png';
import rewbotw from '../../images/new-adv/multi-reward-bot.webp';

import chest from '../../images/alien/alien-chest.png';
import chestw from '../../images/alien/alien-chest.webp';
import crystalsmodal from '../../images/alien/crystals-modal.png';
import crystalsmodalw from '../../images/alien/crystals-modal.webp';

const BuyChestModal = (props) => {

    const [value, setValue] = useState(1);

    const handleChange = (e) => {
        // Проверка, является ли введенное значение числом или пустой строкой
        let newValue = e.target.value;
        if (!isNaN(newValue) || newValue === "") {
            if (newValue >= 2) {
                newValue = 2;
            } else {
                newValue = 1;
            }
            setValue(newValue);
        }
    };

    const increment = () => {
        setValue((prevValue) => {
            if (prevValue === "" || prevValue > 1) return prevValue; // Если поле было пустым, начнем с 1
            return (parseInt(prevValue, 10) + 1).toString(); // Иначе прибавим 1
        });
    };

    const decrement = () => {
        setValue((prevValue) => {
            if (prevValue === "" || prevValue <= 1) return "1"; // Если поле было пустым, начнем с -1
            return (parseInt(prevValue, 10) - 1).toString(); // Иначе уменьшим на 1
        });
    };

    return (<>
        <div className={`modal-to-adv reward-window ${false ? "" : "open"} anim alien-rew`}>
            <div className="modal-to-adv-body">

                <div className={`modal-to-adv-body-in sharp-modal-body`}>
                    <div className={`modal-go-to-adv`}>
                        <div className="mdl-gtav-top">
                            <picture>
                                <source srcSet={rewtopw} type="image/webp" />
                                <source srcSet={rewtop} type="image/png" />
                                <img src={rewtop} alt="" />
                            </picture>
                            <div className="close-mult-rew" onClick={() => props.openBuyChestModal(false)}>
                                <span></span>
                            </div>
                            <div className="sharp-modal-tit tit-chest">
                                {!props.thankBuyChestModal ?
                                    "Space chest" :
                                    "thank you"}
                            </div>
                        </div>

                        <div className="mdl-gtav-mid">
                            <div className="mdl-gtav-mid-grey">
                                <div className="sharp-modal-in">
                                    {!props.thankBuyChestModal ?
                                        <div className="chest-alien">
                                            <div className="chest-alien-ava">
                                                available:
                                                <span>
                                                    {props.allChestInfo && props.allChestInfo.length ? props.allChestInfo[0].num : 100}
                                                </span>
                                            </div>
                                            <div className="chest-alien-thumb">
                                                <picture>
                                                    <source srcSet={chestw} type="image/webp" />
                                                    <source srcSet={chest} type="image/png" />
                                                    <img src={chestw} alt="" />
                                                </picture>
                                            </div>
                                            <div className="chest-alien-price">
                                                <div className="chest-alien-price-name">
                                                    total price
                                                </div>
                                                <div className="chest-alien-sum">
                                                    <span>
                                                        {15000 * value}
                                                    </span>
                                                    <i>
                                                        <picture>
                                                            <source srcSet={crystalsmodalw} type="image/webp" />
                                                            <source srcSet={crystalsmodal} type="image/png" />
                                                            <img src={crystalsmodalw} alt="" />
                                                        </picture>
                                                    </i>
                                                </div>
                                            </div>
                                            <div className="chest-alien-num">
                                                <div className="chest-alien-num-in">
                                                    <div className="bt-inp mn" onClick={decrement}>
                                                        -
                                                    </div>
                                                    <div className="chest-alien-input">
                                                        <input type="number" value={value} onChange={handleChange} />
                                                    </div>
                                                    <div className="bt-inp pl" onClick={increment}>
                                                        +
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="thank-chest-alien">
                                            You have successfully purchased the Space Chest
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="mdl-gtav-bot">
                            <picture>
                                <source srcSet={rewbotw} type="image/webp" />
                                <source srcSet={rewbot} type="image/png" />
                                <img src={rewbot} alt="" />
                            </picture>
                            <div className="sharp-bt">
                                {!props.thankBuyChestModal ?
                                    <button className="def-bt norm" onClick={(e) => {
                                        props.bayChest(value);
                                    }}>
                                        <span className="button-in">
                                            <span className="button-name">
                                                <span className="button-name-in">
                                                    <span className="button-name-value">
                                                        buy
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </button>
                                    :
                                    <button className="def-bt norm" onClick={(e) => {
                                        props.openBuyChestModal(false);
                                    }}>
                                        <span className="button-in">
                                            <span className="button-name">
                                                <span className="button-name-in">
                                                    <span className="button-name-value">
                                                        close
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </button>
                                }
                                {props.allUserChest && props.allUserChest.length && props.allUserChest.filter(item => item.template_id == 738753).length ?
                                    <NavLink className="def-bt norm" to={`/tavern`}>
                                        <span className="button-in">
                                            <span className="button-name">
                                                <span className="button-name-in">
                                                    <span className="button-name-value">
                                                        open x{props.allUserChest.filter(item => item.template_id == 738753).length}
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </NavLink>
                                    : null}


                            </div>
                        </div>


                    </div>


                </div>

                <div className="modal-weapon-bg-trans"></div>
            </div>

            <div className="modal-weapon-bg" ></div>
        </div>
    </>);

}

export default BuyChestModal;