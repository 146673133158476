import React from 'react';


import Menu from '../../components/Menu';

import { connect } from "react-redux";
import Auction from './Auction';
//import { JsonRpc } from 'eosjs';

import ErrorComponent from "../Error/ErrorComponent";
import { ExplorerApi } from "atomicassets"


import './../../css/auction.scss';

import panda from '../../images/auction/panda.png';
import pandaw from '../../images/auction/panda.png';

import musicError from './../../music/error.mp3'

const api = new ExplorerApi("https://" + localStorage.getItem('rpcA'), "atomicassets", { fetch, rateLimit: 4 });
class AuctionComponent extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            formInfo: undefined,
            formInfoChit: [],
            lotInfoDetal: undefined
            //betnum: 0,
        }

        //this.dobet = this.dobet.bind(this);
        //this.handleChangeInputUn = this.handleChangeInputUn.bind(this);
        this.clearError = this.clearError.bind(this);
        this.hasError = this.hasError.bind(this);
        //this.getInfoFromAtomic = this.getInfoFromAtomic.bind(this);


        this.audioMusicError = new Audio(musicError);

    }

    componentDidMount() {
        //this.userCheck()
    }

    componentWillUnmount() {
        clearInterval(this.updateTimer);
        //this.timerReload.current.innerText = 30;
    }

    async clearError() {
        this.setState({
            errorText: '',
            hasError: false
        });
    }

    async hasError(a, b) {
        // console.log("a", a);
        // console.log("a", b);
        this.setState({
            errorText: b,
            hasError: a
        });
    }



    render() {
        return (<>
            <Menu  {...this.props.ual} wax={this.props.balanceWax} bam={this.props.balanceBam} game={this.props.balanceGame} updateBalance={this.props.updateBalance} />
            {this.state.hasError ? <ErrorComponent
                errorMessage={this.state.errorText}
                clearError={this.clearError}
            /> : null}

            <main className="auction-page">
                <div className="loading-block"></div>
                <div className="auction">

                    <div className="container">
                        <Auction {...this.props} formInfo={this.state.formInfo}
                            lotInfoDetal={this.state.lotInfoDetal}
                            timerReload={this.timerReload}
                            //betnum={this.state.betnum} 
                            handleChangeInputUn={this.handleChangeInputUn}
                            hasError={this.hasError}
                            dobet={this.dobet}
                        />
                    </div>

                    <div className="panda-auction">
                        <picture>
                            <source srcSet={pandaw} type="image/webp" />
                            <source srcSet={panda} type="image/jpg" />
                            <img className="lot-img" src={pandaw} alt="" />
                        </picture>
                    </div>

                </div>
            </main>
        </>);
    }
}

let mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, {})(AuctionComponent);