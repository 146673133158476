import React from "react";
import Menu from '../../components/Menu';

import { connect } from "react-redux";
import Referal from "./Referal";
import { JsonRpc } from "eosjs";
import ErrorComponent from "../Error/ErrorComponent";

import musicInOut from "./../../music/in-out.mp3";
import musicError from "./../../music/error.mp3";

const getref = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "getrefer",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: { username: "" },
    },
  ],
};
const changeref = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "changeref",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        username: "",
        change: "",
      },
    },
  ],
};

class ReferalComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      base_url: `${window.location.origin}?${this.props.ual.activeUser.accountName}`,
      copySuccess: "",
      formInfo: null,
      userInfo: null,
      hasError: false, // ошибка транзакции
      errorText: "",
      refName: "123",
    };

    this.audioMusicError = new Audio(musicError);

    this.audioMusicInOut = new Audio(musicInOut);

    this.clearError = this.clearError.bind(this);
    this.userCheck = this.userCheck.bind(this);
    this.refname = this.refname.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
  }

  componentDidMount() {
    this.userCheck();
    this.getTotalSum();
    this.refname();
  }

  async userCheck() {
    try {
      const activeUser = this.props.ual.activeUser;
      const accountName = await activeUser.getAccountName();
      let rpcc;
      if (this.props.ual.activeUser.rpc !== undefined) {
        rpcc = this.props.ual.activeUser.rpc;
      } else if (
        this.props.ual.activeUser.rpc === undefined &&
        this.props.ual.activeUser.wax
      ) {
        rpcc = this.props.ual.activeUser.wax.rpc;
      } else {
        rpcc = new JsonRpc(localStorage.getItem("rpc"));
      }
      const results = await rpcc.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "referrals", // name of the table as specified by the contract abi
        limit: 100,
        lower_bound: accountName,
        upper_bound: accountName,
        //lower_bound: 0,
        key_type: "i64",
        //"index_position": 2,
        limit: -1,
        reverse: 0,
        show_payer: false,
        index_position: 3,
      });
      const originRowUser = results.rows;

      function dynamicSort(property) {
        var sortOrder = -1;
        if (property[0] === "-") {
          sortOrder = 1;
          property = property.substr(1);
        }
        return function (a, b) {
          var result =
            a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
          return result * sortOrder;
        };
      }

      originRowUser.sort(dynamicSort("total"));

      this.setState({
        ...this.state,
        formInfo: originRowUser,
      });
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
    }
  }

  async refname() {
    try {
      const activeUser = this.props.ual.activeUser;
      const accountName = await activeUser.getAccountName();
      let rpcc;
      if (this.props.ual.activeUser.rpc !== undefined) {
        rpcc = this.props.ual.activeUser.rpc;
      } else if (
        this.props.ual.activeUser.rpc === undefined &&
        this.props.ual.activeUser.wax
      ) {
        rpcc = this.props.ual.activeUser.wax.rpc;
      } else {
        rpcc = new JsonRpc(localStorage.getItem("rpc"));
      }
      const results = await rpcc.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "referrals", // name of the table as specified by the contract abi
        limit: 100,
        lower_bound: accountName,
        upper_bound: accountName,
        //lower_bound: 0,
        key_type: "i64",
        //"index_position": 2,
        limit: -1,
        reverse: 0,
        show_payer: false,
        index_position: 1,
      });

      let originRowUser;
      if (results.rows[0]) {
        originRowUser = results.rows[0].partner;
      }

      this.setState({
        ...this.state,
        refName: originRowUser,
      });
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
    }
  }

  async getTotalSum() {
    try {
      const activeUser = this.props.ual.activeUser;
      const accountName = await activeUser.getAccountName();
      let rpcc;
      if (this.props.ual.activeUser.rpc !== undefined) {
        rpcc = this.props.ual.activeUser.rpc;
      } else if (
        this.props.ual.activeUser.rpc === undefined &&
        this.props.ual.activeUser.wax
      ) {
        rpcc = this.props.ual.activeUser.wax.rpc;
      } else {
        rpcc = new JsonRpc(localStorage.getItem("rpc"));
      }
      const results = await rpcc.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, //this.state.accountName,   // scope of the table
        table: "usersnew", // name of the table as specified by the contract abi
        limit: 1,
        index_position: 1,
        lower_bound: accountName,
        upper_bound: accountName,
        reverse: false,
        show_payer: false,
      });
      const userInfo = results.rows;
      console.log(userInfo);

      this.setState({
        userInfo: userInfo[0].balance,
      });
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
    }
  }

  async getrefbam() {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");

    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    getref.actions[0].authorization[0].actor = userName;
    getref.actions[0].data.username = userName;
    console.log(getref);

    try {
      await activeUser
        .signTransaction(getref, { blocksBehind: 3, expireSeconds: 30 })
        .then((response) => {
          if (response.status === "executed") {
            loadingSlot.classList.remove("loading");
            this.setState({
              userInfo: 0,
            });

            console.log(response.status);
            if (localStorage.getItem("sound") === "true") {
              this.audioMusicInOut.currentTime = 0;
              this.audioMusicInOut.volume = 0.1;
              this.audioMusicInOut.play();
            }
          } else {
            //console.log(77777777777777777777)
            console.log(response);
          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      loadingSlot.classList.remove("loading");
      this.setState({
        hasError: true,
        errorText: error.message,
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  handleChangeInput(event) {
    //if(event.target.value >= 0) {
    this.setState({
      ...this.state,
      refName: event.target.value,
    });
    //}
  }

  async change() {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");

    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    changeref.actions[0].authorization[0].actor = userName;
    changeref.actions[0].data.username = userName;
    changeref.actions[0].data.change = this.state.refName;
    console.log(changeref);

    try {
      await activeUser
        .signTransaction(changeref, { blocksBehind: 3, expireSeconds: 30 })
        .then((response) => {
          if (response.status === "executed") {
            loadingSlot.classList.remove("loading");
          } else {
            //console.log(77777777777777777777)
            console.log(response);
          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      loadingSlot.classList.remove("loading");
      this.setState({
        hasError: true,
        errorText: error.message,
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  copy() {
    navigator.clipboard.writeText(this.state.base_url);
    this.setState({
      copySuccess: "your link is copied",
    });

    setTimeout(() => {
      this.setState({
        copySuccess: "",
      });
    }, 5000);
  }

  async clearError() {
    this.setState({
      errorText: "",
      hasError: false,
    });
  }

  render() {
    return (
      <>
        <Menu  {...this.props.ual} wax={this.props.balanceWax} bam={this.props.balanceBam} game={this.props.balanceGame}  updateBalance={this.props.updateBalance} />
        {this.state.hasError ? (
          <ErrorComponent
            errorMessage={this.state.errorText}
            clearError={this.clearError}
          />
        ) : null}
        <main className="slot-page classic">
          <div className="loading-block"></div>
          <section id="staking-page">
            <div className="staking-page-wrap no-top">
              <div className="staking-in table-top1">
                <div className="st-bg-top"></div>

                <div className="st-bg-mid">
                  <div className="ref-wr-perg">
                    <div className="st-bg-mid-top">
                      <div className="ref-title">Referral system</div>

                      <div className="ref-info">
                        Each referral you refer brings you 1% of his earnings.{" "}
                        <br />
                        If the number of invited users is more than 50 players,
                        then up to 2%
                      </div>

                      <div className="ref-bl-wrap">
                        <div className="ref-bl">
                          <div className="wrap-ref">
                            <div className="ref-title-sec">
                              Your referral link
                            </div>

                            <div className="wrap-ref-link">
                              <div className="link-field">
                                {this.state.base_url}
                              </div>
                              <button
                                className="def-bt min bt-copy"
                                onClick={(e) => {
                                  this.copy();
                                }}
                              >
                                <span className="button-in">
                                  <span className="button-name">
                                    <span className="button-name-in">
                                      <span className="button-name-value">
                                        copy link
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </button>
                            </div>
                            <div className="was-copy">
                              {" "}
                              {this.state.copySuccess}{" "}
                            </div>
                          </div>
                        </div>

                        <div className="ref-bl">
                          <div className="wrap-ref">
                            <div className="ref-title-sec">
                              Your current referral reward
                            </div>

                            <div className="wrap-ref-link">
                              <div className="link-field">
                                {this.state.userInfo / 10000} {process.env.REACT_APP_VAL}
                              </div>
                              <button
                                className="def-bt min bt-copy"
                                onClick={(e) => {
                                  this.getrefbam();
                                }}
                              >
                                <span className="button-in">
                                  <span className="button-name">
                                    <span className="button-name-in">
                                      <span className="button-name-value">
                                        GET {process.env.REACT_APP_VAL}
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </button>
                            </div>
                            <div className="min-with">
                              Minimum withdrawal 10 {process.env.REACT_APP_VAL}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="change-ref">
                                            <div className="wrap-ref">
                                                <div className="ref-title-sec">
                                                    Change your referral
                                                </div>
                                                

                                                <div className="wrap-ref-link" >
                                                    <div className="link-field">
                                                        <input type="text" value={this.state.refName}  onChange={this.handleChangeInput} />
                                                    </div>
                                                    <button className="def-bt min bt-copy" onClick={(e) => {
                                                        this.change();
                                                    }}>
                                                        <span className="button-in">
                                                            <span className="button-name">
                                                                <span className="button-name-in">
                                                                    <span className="button-name-value">
                                                                        Change
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div> */}

                      <div className="staking-title">REFERRALS</div>
                    </div>
                    <div className="st-bg-mid-bot">
                      <div className="staking-table">
                        <div className="staking-table-in">
                          {this.state.formInfo ? (
                            <Referal tableInfo={this.state.formInfo} />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="st-bg-bot"></div>
              </div>
            </div>
          </section>

          <footer>
            <div className="footer-nav">
              <nav>
                <ul>
                  <li>
                    <a
                      className="foot-logo-1"
                      target="_blank"
                      href="https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&sort=created&symbol=WAX"
                    >
                      <span></span>
                      <i>Market</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-2"
                      target="_blank"
                      href="https://twitter.com/NftPanda/"
                    >
                      <span></span>
                      <i>Twitter</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-3"
                      target="_blank"
                      href="https://www.instagram.com/nftpanda/"
                    >
                      <span></span>
                      <i>Instagram</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-4"
                      target="_blank"
                      href="https://discord.gg/vCSgme4d5b"
                    >
                      <span></span>
                      <i>Discord</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-5"
                      target="_blank"
                      href={`https://wax.alcor.exchange/swap?output=${process.env.REACT_APP_VAL}-nftpandabamb&input=WAX-eosio.token`}
                    >
                      <span></span>
                      <i>{process.env.REACT_APP_VAL} Trade</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-6"
                      target="_blank"
                      href="https://discord.gg/vCSgme4d5b"
                    >
                      <span></span>
                      <i>Support</i>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </footer>
        </main>
      </>
    );
  }
}

let mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(ReferalComponent);
