import React from 'react';
import modaltop from '../../../images/shop/modal-top.png';
import modalbot from '../../../images/shop/modal-bot.png';

const Modal = (props) => {
    var activeHaveInvent = false;
    var countHaveItem;
    if (props.modal.item.rare === 'common' && props.inventareat.style.common > 0) {
        activeHaveInvent = true;
        countHaveItem = props.inventareat.style.common;
    } else if (props.modal.item.rare === 'uncommon' && props.inventareat.style.uncommon > 0) {
        activeHaveInvent = true;
        countHaveItem = props.inventareat.style.uncommon;
    } else if (props.modal.item.rare === 'rare' && props.inventareat.style.rare > 0) {
        activeHaveInvent = true;
        countHaveItem = props.inventareat.style.rare;
    } else if (props.modal.item.rare === 'epic' && props.inventareat.style.epic > 0) {
        activeHaveInvent = true;
        countHaveItem = props.inventareat.style.epic;
    } else if (props.modal.item.rare === 'legendary' && props.inventareat.style.legendary > 0) {
        activeHaveInvent = true;
        countHaveItem = props.inventareat.style.legendary;
    } else if (props.modal.item.rare === 'mythic' && props.inventareat.style.mythic > 0) {
        activeHaveInvent = true;
        countHaveItem = props.inventareat.style.mythic;
    }

    //console.log("props.modalItemInfo", props.modalItemInfo)

    var dataInfo;
    if (props.modalItemInfo && props.modalItemInfo[0]) {
        dataInfo = props.modalItemInfo[0];
    } else {
        dataInfo = props.modalItemInfo;
    }

    var imgFood = "";

    if (dataInfo && dataInfo.schema.schema_name === "food") {
        switch (dataInfo.template.template_id) {
            case "350909":
                imgFood = "eat-Common-nft";
                break;

            case "350910":
                imgFood = "eat-Uncommon-nft";
                break;

            case "350911":
                imgFood = "eat-Rare-nft";
                break;

            case "350912":
                imgFood = "eat-Epic-nft";
                break;

            case "350913":
                imgFood = "eat-Mythic-nft";
                break;

            case "350914":
                imgFood = "eat-Voucher-nft";
                break;

            default:

        }
    }

    return (



        <>
            {dataInfo ?
                <div className={`modal-bay modal-invent detal-invent-modal ${props.modal.showModalStatus ? "open" : null} ${props.modal.showAnimModal ? " anim" : null}`}>
                    <div className="modal-bay-body">
                        <div className="modal-bay-body-in">

                            <div className="modal-bay-head">

                                <div className="modal-bay-top">
                                    <b className="modal-bay-close" onClick={() => props.showCloseModal('close')}><span></span></b>
                                    <img src={modaltop} alt="" />
                                </div>
                                <div className="modal-bay-wrap">

                                    <div className="modal-bay-tit">
                                        {dataInfo.data.name}
                                    </div>
                                    <div className="modal-bay-part">

                                    </div>
                                    <div className="modal-bay-thumb">
                                        <div className="modal-bay-thumb-in">
                                            {dataInfo.schema.schema_name === "food" ?
                                                <picture>
                                                    <source srcSet={`./images/shop/${imgFood}.webp`} type="image/webp" />
                                                    <source srcSet={`./images/shop/${imgFood}.png`} type="image/png" />
                                                    <img src={`./images/shop/${imgFood}.png`} alt="" />
                                                </picture>
                                                : dataInfo.schema.schema_name === "resources" || dataInfo.schema.schema_name === "gems" || dataInfo.schema.schema_name === "jewelry" ?
                                                    <picture>
                                                        <source srcSet={`./images/jew/all/${dataInfo.template.template_id}.webp`} type="image/webp" />
                                                        <source srcSet={`./images/jew/all/${dataInfo.template.template_id}.png`} type="image/png" />
                                                        <img src={`./images/jew/all/${dataInfo.template.template_id}.png`} alt="" />
                                                    </picture>
                                                    :
                                                    <picture>
                                                        <source srcSet={`./images/shop/voucher/${dataInfo.template.template_id}.webp`} type="image/webp" />
                                                        <source srcSet={`./images/shop/voucher/${dataInfo.template.template_id}.png`} type="image/png" />
                                                        <img src={`./images/shop/voucher/${dataInfo.template.template_id}.png`} alt="" />
                                                    </picture>
                                            }
                                            <div className="allItems">
                                                available quantity<br /><span>{dataInfo.allItems}</span>
                                            </div>

                                        </div>
                                    </div>

                                    {dataInfo.schema.schema_name !== "gems" ?
                                        <div className="total-sum">
                                            <div className="total-sum-top">
                                                rarity
                                            </div>
                                            <div className="total-sum-body">
                                                {dataInfo.data.rarity}
                                            </div>
                                        </div>
                                        : null}

                                    <div className="modal-bay-number">
                                        <div className="modal-bay-number-in">

                                            <div className="nav-number">
                                                <button onClick={() => props.changeCountBt('minus')} className="nav-number prev-n"><span>-</span></button>
                                            </div>
                                            <div className="modal-bay-number-inp">
                                                <input type="number" value={props.countModal} onChange={props.inputCount} />
                                            </div>
                                            <div className="nav-number">
                                                <button onClick={() => props.changeCountBt('plus')} className="nav-number next-n"><span>+</span></button>
                                            </div>
                                        </div>
                                    </div>

                                    {

                                    }


                                    {/* {props.modalItemInfo.activeCount != 0 ?
                                        50 - (props.manyItems - props.modalItemInfo.activeCount + props.countModal)
                                        :
                                        50 - (props.manyItems + props.countModal)
                                    } */}
                                    {props.canAddMoreItems ? "" : <div className="error-modal-message">Already chosen 50 NFTs</div>}

                                    <div className="modal-bay-bt">
                                        {!props.canAddMoreItems ?
                                            <button className="def-bt mid disabled">
                                                <span className="button-in">
                                                    <span className="button-name">
                                                        <span className="button-name-in">
                                                            <span className="button-name-value">
                                                                choose
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </button>
                                            :
                                            <button className="def-bt mid " onClick={() => props.acceptChanges()}>
                                                <span className="button-in">
                                                    <span className="button-name">
                                                        <span className="button-name-in">
                                                            <span className="button-name-value">
                                                                choose
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </button>
                                        }

                                    </div>

                                </div>
                                <div className="modal-bay-bot">
                                    <img src={modalbot} alt="" />
                                </div>
                            </div>
                        </div>

                        <div className="modal-bay-bg-trans" onClick={() => props.closeThenk()}></div>
                    </div>
                    <div className="modal-bay-bg" ></div>
                </div>

                : null}

        </>);
}

export default Modal;