import React from 'react';

import { connect } from "react-redux";
import Slots from './Slots';
import { NavLink } from 'react-router-dom';
import papper from '../../images/reward-modal-bg.png';
import CountUp from 'react-countup';
import chest from '../../images/box-2x.png';
import chest1 from '../../images/box-1-2x.png';
import chest2 from '../../images/box-2-2x.png';
import chest3 from '../../images/box-3-2x.png';
import chest4 from '../../images/box-4-2x.png';
import chest5 from '../../images/box-5-2x.png';
import chest6 from '../../images/box-6-2x.png';
import chest7 from '../../images/box-7-2x.png';
import chest8 from '../../images/box-8-2x.png';
import chest9 from '../../images/box-9-2x.png';

//import anonce from '../../images/auction/anonce-3.jpg';
//import anoncew from '../../images/auction/anonce-3.webp';

// import anonce from '../../images/auction/anonce-6.png';
// import anoncew from '../../images/auction/anonce-6.webp';
import { set } from 'lodash';

function backside(num) {
    //console.log(4);
    var items = document.getElementsByClassName('one-slot');
    items[num].classList.toggle("active");
}



class AdventuresNew extends React.Component {

    constructor(props) {
        super(props)

    }

    componentDidMount() { }

    // onSliderChange = value => {

    //     console.log(value);


    // };

    render() {
        var summ = 0;
        var allPandas = 0;
        //console.log("pandaSlots", this.props.pandaSlots);
        this.props.pandaSlots.find((x) => {
            if (x.activePanda === true) {
                summ += 1
            }
            if (x.is_in_slot === 1) {
                allPandas += 1
            }
        }
        )
        return <main className="slot-page top-user classic">
            <div className="loading-block"></div>

            <section id="all-slots">
                <div className="container">
                    <div className="all-slots-in">
                        <Slots
                            {...this.props}
                        />
                    </div>
                </div>
            </section>

            <footer>
                <div className="footer-nav">
                    <nav>
                        <ul>
                            <li>
                                <a className="foot-logo-1" target="_blank" href="https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&sort=created&symbol=WAX">
                                    <span>

                                    </span>
                                    <i>
                                        Market
                                    </i>
                                </a>
                            </li>
                            <li>
                                <a className="foot-logo-2" target="_blank" href="https://twitter.com/NftPanda/">
                                    <span>

                                    </span>
                                    <i>
                                        Twitter
                                    </i>
                                </a>
                            </li>
                            <li>
                                <a className="foot-logo-3" target="_blank" href="https://www.instagram.com/nftpanda/">
                                    <span>

                                    </span>
                                    <i>
                                        Instagram
                                    </i>
                                </a>
                            </li>
                            <li>
                                <a className="foot-logo-4" target="_blank" href="https://discord.gg/vCSgme4d5b">
                                    <span>

                                    </span>
                                    <i>
                                        Discord
                                    </i>
                                </a>
                            </li>
                            <li>
                                <a className="foot-logo-5" target="_blank" href={`https://wax.alcor.exchange/swap?output=${process.env.REACT_APP_VAL}-nftpandabamb&input=WAX-eosio.token`}>
                                    <span>

                                    </span>
                                    <i>
                                        {process.env.REACT_APP_VAL} Trade
                                    </i>
                                </a>
                            </li>
                            <li>
                                <a className="foot-logo-6" target="_blank" href="https://discord.gg/vCSgme4d5b">
                                    <span>

                                    </span>
                                    <i>
                                        Support
                                    </i>
                                </a>
                            </li>
                        </ul>

                    </nav>
                </div>
            </footer>

        </main>
    }
}

let mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, {})(AdventuresNew);