import React from 'react';

const ErrorModal = (props) => {
   
    return (<>
        <div className={`modal-stake-more ${ props.hasModalErrorInfo.hasModalError || props.hasModalErrorInfo2.hasModalError || props.hasModalErrorInfo3.hasModalError ? 'open' : 'close'}`}>
            <div className="bamb-modal-body">
                <div className="bamb-modal-body-in">
                    <div className="bamb-modal">
                        <div className="bamb-modal-in">
                            <div className="modal-close" onClick={props.handleCloseModalError}></div>
                            <div className="bamb-modal-top"></div>
                            <div className="bamb-modal-bot"></div>
                            <div className="bamb-modal-wrap">
                                <div className="unstake-wrap">

                                    <div className="unstake-top">
                                        <div className="unstake-tit">
                                            {props.hasModalErrorInfo.title}
                                        </div>
                                    </div>
                                    <div className="unstake-bot">
                                        <div dangerouslySetInnerHTML={{__html: props.hasModalErrorInfo.description}} />
                                    </div>

                                    <div className="unstake-top">
                                        <div className="unstake-tit">
                                            {props.hasModalErrorInfo2.title}
                                        </div>
                                    </div>
                                    <div className="unstake-bot">
                                        <div dangerouslySetInnerHTML={{__html: props.hasModalErrorInfo2.description}} />
                                    </div>

                                    <div className="unstake-top">
                                        <div className="unstake-tit">
                                            {props.hasModalErrorInfo3.title}
                                        </div>
                                    </div>
                                    <div className="unstake-bot">
                                        <div dangerouslySetInnerHTML={{__html: props.hasModalErrorInfo3.description}} />
                                    </div>
                                    
                                    <div className="unstake-form-in">
                                        
                                        <div className="stake-btns">
                                            <div className="one-stake-bam">
                                                <button className="def-bt min" onClick={props.handleCloseModalError}>
                                                    <span className="button-in">
                                                        <span className="button-name">
                                                            <span className="button-name-in">
                                                                <span className="button-name-value">
                                                                    Okay
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </button>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-modal-transp" onClick={props.handleCloseModalError}></div>
                </div>
                
            </div> 
            <div className="bg-modal"></div>
        </div>

            {/* <div className={`modal-error ${props.showModal ? 'open' : 'close'} `}>
                <div className="error-modal-table">
                    <div className="error-modal-table-top">
                        <span>
                            ERROR
                        </span>
                    </div>
                    <div className="error-modal-table-mid">
                        <div className="error-modal-table-mid-in">
                            {props.errorMessage}
                        </div>
                    </div>
                    <div className="error-modal-table-bot">
                        
                    </div>
                </div>
                <div className={`bg-wrror-modal ${props.showModalBg ? 'open' : 'close'} `}></div>
            </div>  */}
        </>);
}

export default ErrorModal;