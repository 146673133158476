const SET_PANDA_ON_USER = 'SET_PANDA_ON_USER';
const TOGGLE_IS_FETCHING_BOT = 'TOGGLE_IS_FETCHING_BOT';
const DO_TRANSFER_TO_GAME = 'DO_TRANSFER_TO_GAME';
const ADD_TO_USER_STATE_HEROES = 'ADD_TO_USER_STATE_HEROES';
const REMOVE_FROM_STATE_USER_HEROES = 'REMOVE_FROM_STATE_USER_HEROES';

let initialState = {
    pandaListOnUser: null,
    isFetchingBot: true
};

const pandaUserReducer = (state = initialState, action) => {

    switch(action.type) {
        case SET_PANDA_ON_USER: {
            return {...state, pandaListOnUser: action.pandaListOnUser}
        }
        case TOGGLE_IS_FETCHING_BOT: {
            return { ...state, isFetchingBot: action.isFetching}
        }
        case DO_TRANSFER_TO_GAME: {
            return { ...state, isFetchingBot: action.transferToGame}
        }
        case ADD_TO_USER_STATE_HEROES: {
            console.log(action.addNft);
            return { ...state, pandaListOnUser: [action.addNft].concat(state.pandaListOnUser)
            }
        }
        case REMOVE_FROM_STATE_USER_HEROES: {
            return { ...state, pandaListOnUser: state.pandaListOnUser.filter(n => n.asset_id !== action.removePandaFromStateUser).map( u =>  {
                return u;
                })
            }
        }
        default:
            return state;
    }
}


export const setPandaOnUser = (pandaListOnUser) => ({type: SET_PANDA_ON_USER, pandaListOnUser})
export const toggleIsFetchingBot = (isFetching) => ({type: TOGGLE_IS_FETCHING_BOT, isFetching })
export const doTransferToGame = (transferToGame) => ({type: DO_TRANSFER_TO_GAME, transferToGame })
export const addToUserStateHerose = (addNft) => ({type: ADD_TO_USER_STATE_HEROES, addNft })
export const doDelFromStateUserHerose = (removePandaFromStateUser) => ({type: REMOVE_FROM_STATE_USER_HEROES, removePandaFromStateUser })

export default pandaUserReducer;
