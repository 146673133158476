import React from "react";
import Menu from '../../components/Menu';
import Slider from "rc-slider";

import "rc-slider/assets/index.css";
import { connect } from "react-redux";
import {
  eatlist,
  wasLoadinEat,
  wasBuyingEat,
} from "../../GlobalState/shop-reducer";
import {
  weaponlist,
  statusLoadinWeapon,
  addWeaponWhenRemove,
  addWeaponToPanda,
} from "../../GlobalState/weapon-reducer";

import ErrorComponent from "../Error/ErrorComponent";

import { JsonRpc } from "eosjs";
import "./../../css/exchange.scss";

import nameInvent from "../../images/exchange/invent-top.png";
import nameInventw from "../../images/exchange/invent-top.webp";
import nameExch from "../../images/exchange/exch-top.png";
import nameExchw from "../../images/exchange/exch-top.webp";
import blocktop from "../../images/exchange/block-top.png";
import blocktopw from "../../images/exchange/block-top.webp";
import blockbot from "../../images/exchange/block-bot.png";
import blockbotw from "../../images/exchange/block-bot.webp";

import musicOpen from "./../../music/open.mp3";
import musicClose from "./../../music/close.mp3";

import musicError from "./../../music/error.mp3";

import { ExplorerApi } from "atomicassets"; // атомик для списка панд

import InventoryRes from "./Exchanger/InventoryRes";
import ExchRes from "./Exchanger/ExchRes";
import InventoryGem from "./Exchanger/InventoryGem";
import ExchGem from "./Exchanger/ExchGem";
import InventoryEat from "./Exchanger/InventoryEat";
import ExchEat from "./Exchanger/ExchEat";

import Modal from "./Modal";

const fetch = require("node-fetch");
const api = new ExplorerApi(
  "https://" + localStorage.getItem('rpcA'),
  "atomicassets",
  { fetch, rateLimit: 4 }
);

// это транзакция отправки ИЗ
const cardTransferOut = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "transferout",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        from: "",
        to: "",
        asset_id: [],
      },
    },
  ],
};

class ExchangerComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shop: {
        // EAT
        res: [
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
        ],
        // END EAT
        // EAT
        gem: [
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
        ],
        // END EAT
      },
      value: 0,
      shopitem: null,
      modalItemInfo: null,
      activeTab: "res",
      activePage: 1,
      countPage: "",
      activePageExch: 1,
      countPageExch: "",
      allresgroup: {},
      allgemgroup: {},
      alleatgroup: {},
      activeItem: {
        active: false,
        activeNum: "",
        count: "",
        img: "",
      },
      exchRes: {
        res: [
          // {
          //     name: "wood",
          //     img: 431181,
          // },
          {
            name: "steel",
            img: 431182,
            needWood: 2,
          },
          {
            name: "titawin",
            img: 431183,
            needWood: 10,
            needSteel: 5,
          },
          {
            name: "kvessir",
            img: 431184,
            needWood: 35,
            needSteel: 17,
            needTitawin: 3,
          },
          {
            name: "gold",
            img: 431185,
            needWood: 50,
            needSteel: 25,
            needTitawin: 5,
            needKvessir: 2,
          },
          {
            name: "dwemer",
            img: 431186,
            needWood: 100,
            needSteel: 50,
            needTitawin: 10,
            needKvessir: 3,
            needGold: 2,
          },
          {
            name: "",
          },
          {
            name: "",
          },
          {
            name: "",
          },
          {
            name: "",
          },
          {
            name: "",
          },
          {
            name: "",
          },
          {
            name: "",
          },
          {
            name: "",
          },
          {
            name: "",
          },
          {
            name: "",
          },
          {
            name: "",
          },
        ],
      },
      exchEat: {
        res: [
          // {
          //     name: "wood",
          //     img: 431181,
          // },
          {
            name: "common food",
            img: 350909,
          },
          {
            name: "uncommon food",
            img: 350910,
          },
          {
            name: "rare food",
            img: 350911,
          },
          {
            name: "epic food",
            img: 350912,
          },
          {
            name: "legendary food",
            img: 350913,
          },
          {
            name: "Mythic food",
            img: 350914,
          },
          {
            name: "voucher",
            img: 437402,
          },
          {
            name: "",
          },
          {
            name: "",
          },
          {
            name: "",
          },
          {
            name: "",
          },
          {
            name: "",
          },
          {
            name: "",
          },
          {
            name: "",
          },
          {
            name: "",
          },
          {
            name: "",
          },
          {
            name: "",
          },
        ],
      },
      exchGem: {
        res: [
          // {
          //     name: "wood",
          //     img: 431181,
          // },

          {
            name: "apatite water",
            img: 430131,
            needGem: 2,
          },
          {
            name: "indicolite water",
            img: 430136,
            needGem: 2,
          },
          {
            name: "kyanite water",
            img: 430138,
            needGem: 2,
          },
          {
            name: "pliacrin water",
            img: 430139,
            needGem: 2,
          },
          {
            name: "tihash water",
            img: 430141,
            needGem: 2,
          },
          {
            name: "apatite fire",
            img: 430143,
            needGem: 2,
          },
          {
            name: "indicolite fire",
            img: 430145,
            needGem: 2,
          },
          {
            name: "kyanite fire",
            img: 430148,
            needGem: 2,
          },
          {
            name: "pliacrin fire",
            img: 430151,
            needGem: 2,
          },
          {
            name: "tihash fire",
            img: 430152,
            needGem: 2,
          },
          {
            name: "apatite wind",
            img: 430155,
            needGem: 2,
          },
          {
            name: "indicolite wind",
            img: 430156,
            needGem: 2,
          },
          {
            name: "kyanite wind",
            img: 430158,
            needGem: 2,
          },
          {
            name: "pliacrin wind",
            img: 430161,
            needGem: 2,
          },
          {
            name: "tihash wind",
            img: 430162,
            needGem: 2,
          },
          {
            name: "apatite earth",
            img: 430164,
            needGem: 2,
          },
          {
            name: "indicolite earth",
            img: 430309,
            needGem: 2,
          },
          {
            name: "kyanite earth",
            img: 430310,
            needGem: 2,
          },
          {
            name: "pliacrin earth",
            img: 430311,
            needGem: 2,
          },
          {
            name: "tihash earth",
            img: 430312,
            needGem: 2,
          },
        ],
      },
      activeExchItem: {
        active: false,
        konst: "",
        img: "",
        activeNum: "",
      },
      sliderOptions: {
        step: 1,
        min: 0,
        max: 0,
      },
      receive: 0,
      made: false,
      showModalThank: false,
      activeArrTrans: [],
    };

    this.clearError = this.clearError.bind(this);
    this.chooseRes = this.chooseRes.bind(this);
    this.chooseExch = this.chooseExch.bind(this);
    this.craft = this.craft.bind(this);
    this.closeThenk = this.closeThenk.bind(this);

    this.audioOpen = new Audio(musicOpen);
    this.audioClose = new Audio(musicClose);
    this.audioMusicError = new Audio(musicError);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.userCheck();
    this.GetPandaResList();
    this.GetPandaGemList();
    this.GetPandaEatList();
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.remove("open-modal");
  }
  async GetPandaResList() {
    (async () => {
      try {
        const userName = this.props.ual.activeUser.accountName;
        const activeUser = this.props.ual.activeUser;
        const asset = await api
          .getAssets({
            limit: 1000,
            owner: userName,
            collection_name: "nftpandawaxp",
            schema_name: ["resources" /*, "promo"*/],
          })
          .then((assets) => {
            //console.log("assets", assets)
            let newData = {};
            Object.entries(assets).forEach((pair) => {
              let [key, value] = pair;
              //console.log(value.template.template_id);
              if (!newData[value.template.template_id])
                newData[value.template.template_id] = [];
              newData[value.template.template_id].push(value);
            });
            var arreyObj = Object.values(newData);

            if (arreyObj.length < 16) {
              for (let i = arreyObj.length; i < 16; i++) {
                // выведет 0, затем 1, затем 2
                var arraynew = [];
                arreyObj.push(arraynew);
              }
            }

            this.setState({
              ...this.state,
              allresgroup: arreyObj,
              countPage: arreyObj.length / 12,
            });

            // this.props.weaponlist(assets);
            // this.props.statusLoadinWeapon(true);
          });
      } catch (e) {
        //console.log(e.message)
        //console.warn(e)
        this.setState({
          ...this.state,
          modalAllWeapon: {
            ...this.state.modalAllWeapon,
            loadingWeapon: false,
          },
        });
        this.setState({
          hasError: true,
          errorText: e.message,
        });
        console.log("\nCaught exception: " + e);
        if (localStorage.getItem("sound") == "true") {
          this.audioMusicError.currentTime = 0;
          this.audioMusicError.volume = 0.1;
          this.audioMusicError.play();
        }
      }
    })();
  }

  async GetPandaGemList() {
    (async () => {
      try {
        const userName = this.props.ual.activeUser.accountName;
        const activeUser = this.props.ual.activeUser;
        const asset = await api
          .getAssets({
            limit: 1000,
            owner: userName,
            collection_name: "nftpandawaxp",
            schema_name: ["gems" /*, "promo"*/],
          })
          .then((assets) => {
            let newData = {};
            Object.entries(assets).forEach((pair) => {
              let [key, value] = pair;
              //console.log(value.template.template_id);
              if (!newData[value.template.template_id])
                newData[value.template.template_id] = [];
              newData[value.template.template_id].push(value);
            });
            var arreyObj = Object.values(newData);

            if (arreyObj.length < 16) {
              for (let i = arreyObj.length; i < 16; i++) {
                // выведет 0, затем 1, затем 2
                var arraynew = [];
                arreyObj.push(arraynew);
              }
            }

            this.setState({
              ...this.state,
              allgemgroup: arreyObj,
              countPage: arreyObj.length / 12,
            });

            // this.props.weaponlist(assets);
            // this.props.statusLoadinWeapon(true);
          });
      } catch (e) {
        //console.log(e.message)
        //console.warn(e)
        this.setState({
          ...this.state,
          modalAllWeapon: {
            ...this.state.modalAllWeapon,
            loadingWeapon: false,
          },
        });
        this.setState({
          hasError: true,
          errorText: e.message,
        });
        this.props.statusLoadinWeapon(false);
        console.log("\nCaught exception: " + e);
        if (localStorage.getItem("sound") == "true") {
          this.audioMusicError.currentTime = 0;
          this.audioMusicError.volume = 0.1;
          this.audioMusicError.play();
        }
      }
    })();
  }

  async GetPandaEatList() {
    (async () => {
      try {
        const userName = this.props.ual.activeUser.accountName;
        const activeUser = this.props.ual.activeUser;
        var arr = [350909, 350910, 350911, 350912, 350913, 350914, 437402];
        const asset = await api
          .getAssets({
            limit: 1000,
            owner: userName,
            collection_name: "nftpandawaxp",
            schema_name: ["food", "voucher"],
            template_id: arr,
          })
          .then((assets) => {
            let newData = {};
            Object.entries(assets).forEach((pair) => {
              let [key, value] = pair;
              //console.log(value.template.template_id);
              if (!newData[value.template.template_id])
                newData[value.template.template_id] = [];
              newData[value.template.template_id].push(value);
            });
            var arreyObj = Object.values(newData);

            if (arreyObj.length < 16) {
              for (let i = arreyObj.length; i < 16; i++) {
                // выведет 0, затем 1, затем 2
                var arraynew = [];
                arreyObj.push(arraynew);
              }
            }

            this.setState({
              ...this.state,
              alleatgroup: arreyObj,
              countPage: arreyObj.length / 12,
            });

            // this.props.weaponlist(assets);
            // this.props.statusLoadinWeapon(true);
          });
      } catch (e) {
        //console.log(e.message)
        console.warn(e);
        this.setState({
          ...this.state,
          modalEat: {
            ...this.state.modalEat,
            loadingChooseEat: false,
          },
        });
        this.setState({
          hasError: true,
          errorText: e.message,
        });
        console.log("\nCaught exception: " + e);
        if (localStorage.getItem("sound") == "true") {
          this.audioMusicError.currentTime = 0;
          this.audioMusicError.volume = 0.1;
          this.audioMusicError.play();
        }
      }
    })();
  }

  async userCheck() {
    try {
      const activeUser = this.props.ual.activeUser;
      const accountName = await activeUser.getAccountName();
      let rpcc;
      if (this.props.ual.activeUser.rpc != undefined) {
        rpcc = this.props.ual.activeUser.rpc;
      } else if (
        this.props.ual.activeUser.rpc == undefined &&
        this.props.ual.activeUser.wax
      ) {
        rpcc = this.props.ual.activeUser.wax.rpc;
      } else {
        rpcc = new JsonRpc(localStorage.getItem("rpc"));
      }
      const results = await rpcc.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "usersnew", // name of the table as specified by the contract abi
        limit: 1,
        lower_bound: accountName,
        upper_bound: accountName,
        index_position: 1,
        reverse: false,
        show_payer: false,
      });
      const originRowUser = results;
      //console.log(originRowUser);
      let resultRow;
      if (originRowUser.rows.length) {
        resultRow = results.rows[0].inrent;
      } else {
        resultRow = 0;
      }

      if (resultRow == 1) {
        const resultRowName = results.rows[0].rent;
        /*this.GetPandaListOnGame(resultRowName);
                this.GetPandaListOnUser(resultRowName);*/
      } else {
        /*this.GetPandaListOnGame(accountName);
                this.GetPandaListOnUser(accountName);*/
      }
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
      if (localStorage.getItem("sound") == "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  async clearError() {
    this.setState({
      errorText: "",
      hasError: false,
    });
  }

  tabsnav(tab) {
    if (tab == 1) {
      this.setState({
        shopitem: this.state.shop.gem,
        activeTab: "res",
        activePage: 1,
        activePageExch: 1,
        countPage: this.state.allresgroup.length / 12,
        countPageExch: this.state.allgemgroup.length / 12,
        activeItem: {
          active: false,
          activeNum: "",
          count: "",
          img: "",
        },
        activeExchItem: {
          active: false,
          konst: "",
          img: "",
          activeNum: "",
        },
        sliderOptions: {
          step: 1,
          min: 0,
          max: 0,
        },
        value: 0,
        receive: 0,
      });
    } else if (tab == 2) {
      this.setState({
        shopitem: this.state.shop.jew,
        activeTab: "gem",
        activePage: 1,
        activePageExch: 1,
        countPage: this.state.allgemgroup.length / 12,
        countPageExch: this.state.allgemgroup.length / 12,
        activeItem: {
          active: false,
          activeNum: "",
          count: "",
          img: "",
        },
        activeExchItem: {
          active: false,
          konst: "",
          img: "",
          activeNum: "",
        },
        sliderOptions: {
          step: 1,
          min: 0,
          max: 0,
        },
        value: 0,
        receive: 0,
      });
    } else if (tab == 3) {
      this.setState({
        shopitem: this.state.shop.jew,
        activeTab: "eat",
        activePage: 1,
        activePageExch: 1,
        countPage: this.state.allgemgroup.length / 12,
        countPageExch: this.state.allgemgroup.length / 12,
        activeItem: {
          active: false,
          activeNum: "",
          count: "",
          img: "",
        },
        activeExchItem: {
          active: false,
          konst: "",
          img: "",
          activeNum: "",
        },
        sliderOptions: {
          step: 1,
          min: 0,
          max: 0,
        },
        value: 0,
        receive: 0,
      });
    }
  }

  navpage(goto) {
    if (goto == "next") {
      this.setState({
        activePage: this.state.activePage + 1,
      });
    } else {
      this.setState({
        activePage: this.state.activePage - 1,
      });
    }
  }

  navpageExch(goto) {
    if (goto == "next") {
      this.setState({
        activePageExch: this.state.activePageExch + 1,
      });
    } else {
      this.setState({
        activePageExch: this.state.activePageExch - 1,
      });
    }
  }

  onSliderChange = (value) => {
    var slicedArray;
    var actionVar = 0;

    if (this.state.activeTab == "res") {
      slicedArray = this.state.allresgroup;
    } else if (this.state.activeTab == "gem") {
      slicedArray = this.state.allgemgroup;
    } else {
      slicedArray = this.state.alleatgroup;
      if (value % 10 == 0) {
        actionVar = value / 10;
      } else {
        actionVar = parseInt(value / 10);
      }
    }

    console.log("actionVar", actionVar);

    var arraynew = [];
    for (let i = 0; i < value; i++) {
      // выведет 0, затем 1, затем 2
      arraynew.push(slicedArray[this.state.activeItem.activeNum][i].asset_id);
    }

    this.setState({
      value: value,
      receive: value / this.state.activeExchItem.konst + actionVar,
      activeArrTrans: arraynew,
    });
  };

  chooseRes(count, img, index) {
    /*if(this.state.activeTab == "eat") {

            var slicedArray;
            slicedArray = this.state.alleatgroup;
            

            var arraynew = [];               
            for (let i = 0; i < 1; i++) { // выведет 0, затем 1, затем 2
                arraynew.push(slicedArray[0][i].asset_id); 
            }

            this.setState({
                activeItem: {
                    active: true,
                    activeNum: index,
                    count: count,
                    img: img
                },
                activeExchItem: {
                    active: true,
                    konst: "1",
                    img: img,
                    activeNum: index
                },
                sliderOptions: {
                    step: 1,
                    min: 1,
                    max: count
                },
                value: 1,
                receive: 1,
                activeArrTrans: arraynew
            });
        } else {*/
    this.setState({
      activeItem: {
        active: true,
        activeNum: index,
        count: count,
        img: img,
      },
      activeExchItem: {
        active: false,
        konst: "",
        img: "",
        activeNum: "",
      },
      sliderOptions: {
        step: 1,
        min: 0,
        max: 1,
      },
      value: 0,
      receive: 0,
    });
    /*}
     */
  }

  chooseExch(konst, img, max, index) {
    var slicedArray;

    if (this.state.activeTab == "res") {
      slicedArray = this.state.allresgroup;
    } else if (this.state.activeTab == "gem") {
      slicedArray = this.state.allgemgroup;
    } else {
      slicedArray = this.state.alleatgroup;
    }

    var arraynew = [];
    for (let i = 0; i < konst; i++) {
      // выведет 0, затем 1, затем 2
      arraynew.push(slicedArray[this.state.activeItem.activeNum][i].asset_id);
    }

    this.setState({
      activeExchItem: {
        active: true,
        konst: konst,
        img: img,
        activeNum: index,
      },
      sliderOptions: {
        step: konst,
        min: 0,
        max: max,
      },
      value: konst,
      receive: 1,
      activeArrTrans: arraynew,
    });
  }

  async craft() {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");
    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;

    try {
      await activeUser
        .signTransaction(
          {
            actions: [
              {
                account: "atomicassets",
                name: "transfer",
                authorization: [
                  {
                    actor: userName, // use account that was logged in
                    permission: "active",
                  },
                ],
                data: {
                  from: userName,
                  to: process.env.REACT_APP_CONTRACT,
                  asset_ids: this.state.activeArrTrans,
                  memo:
                    "exchange " +
                    this.state.activeExchItem.img +
                    " " +
                    this.state.receive +
                    " for player",
                },
              },
            ],
          },
          { blocksBehind: 3, expireSeconds: 30 }
        )
        .then((response) => {
          if (response.status === "executed") {
            var timeNum = 30000;
            if (this.state.activeTab == "eat") {
              timeNum = 30000;
            }

            loadingSlot.classList.remove("loading");
            this.setState({
              ...this.setState,
              made: true,
            });
            setTimeout(() => {
              this.setState({
                ...this.setState,
                made: false,
                showModalThank: true,
              });
            }, timeNum);
          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
        chooseHeroLoading: false,
      });
      if (localStorage.getItem("sound") == "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
      loadingSlot.classList.remove("loading");
    }
  }

  async closeThenk() {
    document.getElementsByTagName("body")[0].classList.remove("open-modal");
    this.setState({
      ...this.state,
      showModalThank: false,
      activeItem: {
        active: false,
        activeNum: "",
        count: "",
        img: "",
      },
      activeExchItem: {
        active: false,
        konst: "",
        img: "",
        activeNum: "",
      },
      sliderOptions: {
        step: 1,
        min: 0,
        max: 1,
      },
      value: 0,
      receive: 0,
    });
    if (localStorage.getItem("sound") == "true") {
      this.audioClose.currentTime = 0;
      this.audioClose.volume = 0.1;
      this.audioClose.play();
    }

    this.GetPandaResList();
    this.GetPandaGemList();
    this.GetPandaEatList();
  }

  render() {
    return (
      <>
        <Menu  {...this.props.ual} wax={this.props.balanceWax} bam={this.props.balanceBam} game={this.props.balanceGame} updateBalance={this.props.updateBalance} />
        {this.state.hasError ? (
          <ErrorComponent
            errorMessage={this.state.errorText}
            clearError={this.clearError}
          />
        ) : null}

        <Modal
          modal={this.state.modal}
          showModalThank={this.state.showModalThank}
          closeThenk={this.closeThenk}
          made={this.state.made}
        />

        <main className="exch-page">
          <div className="loading-block"></div>
          <div className="exch-wrap">
            <div className="exch-body">
              <div className="top-exch-wrap">
                <div className="exch-block-top">
                  <div className="exch-tabs page-exch">
                    <picture>
                      <source srcSet={blocktopw} type="image/webp" />
                      <source srcSet={blockbot} type="image/png" />
                      <img className="hidemob" src={blockbot} alt="" />
                    </picture>
                    <ul>
                      <li
                        className={`${this.state.activeTab == "res" ? "res active" : "res"
                          }`}
                      >
                        <span
                          className={`${this.state.activeTab == "res" ? "active" : ""
                            }`}
                          onClick={() => this.tabsnav(1)}
                        ></span>
                      </li>
                      <li
                        className={`${this.state.activeTab == "gem" ? "gem active" : "gem"
                          }`}
                      >
                        <span
                          className={`${this.state.activeTab == "gem" ? "active" : ""
                            }`}
                          onClick={() => this.tabsnav(2)}
                        ></span>
                      </li>
                      <li
                        className={`${this.state.activeTab == "eat" ? "eat active" : "eat"
                          }`}
                      >
                        <span
                          className={`${this.state.activeTab == "eat" ? "active" : ""
                            }`}
                          onClick={() => this.tabsnav(3)}
                        ></span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="exch-mob-wrap">
                <div
                  className={`ico-exch ${this.state.activeItem.active &&
                    this.state.activeExchItem.active
                    ? "active"
                    : ""
                    }`}
                ></div>
                <div className="exch-block left">
                  <div className="exch-block-top">
                    <div className="exch-name">
                      <picture>
                        <source srcSet={nameInventw} type="image/webp" />
                        <source srcSet={nameInvent} type="image/png" />
                        <img className="hidemob" src={nameInvent} alt="" />
                      </picture>
                    </div>
                    <div className="exch-tabs page-exch">
                      <picture>
                        <source srcSet={blocktopw} type="image/webp" />
                        <source srcSet={blockbot} type="image/png" />
                        <img className="hidemob" src={blockbot} alt="" />
                      </picture>
                      <ul>
                        <li
                          className={`${this.state.activeTab == "res" ? "res active" : "res"
                            }`}
                        >
                          <span
                            className={`${this.state.activeTab == "res" ? "active" : ""
                              }`}
                            onClick={() => this.tabsnav(1)}
                          ></span>
                        </li>
                        <li
                          className={`${this.state.activeTab == "gem" ? "gem active" : "gem"
                            }`}
                        >
                          <span
                            className={`${this.state.activeTab == "gem" ? "active" : ""
                              }`}
                            onClick={() => this.tabsnav(2)}
                          ></span>
                        </li>
                        <li
                          className={`${this.state.activeTab == "eat" ? "eat active" : "eat"
                            }`}
                        >
                          <span
                            className={`${this.state.activeTab == "eat" ? "active" : ""
                              }`}
                            onClick={() => this.tabsnav(3)}
                          ></span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="exch-block-wrap">
                    <div className="exch-block-wrap-in">
                      {this.state.activeTab == "res" ? (
                        <InventoryRes
                          {...this.props}
                          {...this.state}
                          allinvent={this.state.allresgroup}
                          chooseRes={this.chooseRes}
                          activeItem={this.state.activeItem}
                        />
                      ) : this.state.activeTab == "gem" ? (
                        <InventoryGem
                          {...this.props}
                          {...this.state}
                          allinvent={this.state.allgemgroup}
                          chooseRes={this.chooseRes}
                          activeItem={this.state.activeItem}
                        />
                      ) : this.state.activeTab == "eat" ? (
                        <InventoryEat
                          {...this.props}
                          {...this.state}
                          allinvent={this.state.alleatgroup}
                          chooseRes={this.chooseRes}
                          activeItem={this.state.activeItem}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="exch-block-bot">
                    <div className="exch-name1">
                      <picture>
                        <source srcSet={blockbotw} type="image/webp" />
                        <source srcSet={blockbot} type="image/png" />
                        <img className="hidemob" src={blockbot} alt="" />
                      </picture>
                    </div>
                    <div className="shafl-nav">
                      <div className="shafl-nav-in">
                        {this.state.countPage > 1 &&
                          this.state.activePage > 1 &&
                          this.state.activeTab == "gem" ? (
                          <button
                            className="nav-link nav-prev"
                            onClick={() => this.navpage("prev")}
                          >
                            <span></span>
                          </button>
                        ) : null}
                        {this.state.activeTab == "gem" ? (
                          <div className="nav-num">{this.state.activePage}</div>
                        ) : null}
                        {this.state.countPage > 1 &&
                          this.state.countPage >= this.state.activePage &&
                          this.state.activeTab == "gem" ? (
                          <button
                            className="nav-link nav-next"
                            onClick={() => this.navpage("next")}
                          >
                            <span></span>
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="block-chooser">
                  {this.state.activeTab == "eat" ? (
                    <div className="action-text">
                      <div className="action-text-tit">Promotion!</div>
                      <div className="action-text-descr">
                        <span>
                          When <br />
                        </span>
                        exchanging 10 nft, <br />
                        get 11 in-game <span>meals</span>!
                      </div>
                    </div>
                  ) : null}
                  <div className="block-chooser-in">
                    <div className="choose-from">
                      <div
                        className={`choose-from-in ${this.state.activeTab == "eat" ? "nft" : ""
                          }`}
                      >
                        {this.state.activeItem.active ? (
                          <picture>
                            <source
                              srcSet={`./images/${this.state.activeTab == "gem" ||
                                this.state.activeTab == "res"
                                ? "jew/inventory/"
                                : "shop/"
                                }${this.state.activeItem.img}.webp`}
                              type="image/webp"
                            />
                            <source
                              srcSet={`./images/${this.state.activeTab == "gem" ||
                                this.state.activeTab == "res"
                                ? "jew/inventory/"
                                : "shop/"
                                }${this.state.activeItem.img}.png`}
                              type="image/png"
                            />
                            <img
                              src={`./images/${this.state.activeTab == "gem" ||
                                this.state.activeTab == "res"
                                ? "jew/inventory/"
                                : "shop/"
                                }${this.state.activeItem.img}.png`}
                              alt=""
                            />
                          </picture>
                        ) : null}
                      </div>
                    </div>

                    <div className="choose-num-from">
                      <div className="choose-num-from-name">give away</div>
                      <div className="choose-num-from-count">
                        {this.state.value}
                      </div>
                      <div className="choose-num-from-slider">
                        {this.state.activeExchItem.active ? (
                          <Slider
                            step={this.state.sliderOptions.step}
                            min={this.state.sliderOptions.min}
                            max={this.state.sliderOptions.max}
                            defaultValue={this.state.sliderOptions.min}
                            value={this.state.value}
                            onChange={this.onSliderChange}
                          />
                        ) : (
                          <Slider
                            step={this.state.sliderOptions.step}
                            min={this.state.sliderOptions.min}
                            max={this.state.sliderOptions.max}
                            defaultValue={this.state.sliderOptions.min}
                            value={this.state.value}
                            onChange={this.onSliderChange}
                            disabled
                          />
                        )}

                        {/* <Range /> */}
                      </div>
                    </div>

                    <div className="choose-to">
                      <div
                        className={`choose-to-in ${this.state.activeTab == "eat" ? "panda" : ""
                          }`}
                      >
                        {this.state.activeExchItem.active ? (
                          <picture>
                            <source
                              srcSet={`./images/${this.state.activeTab == "gem" ||
                                this.state.activeTab == "res"
                                ? "jew/inventory/"
                                : "shop/"
                                }${this.state.activeExchItem.img}.webp`}
                              type="image/webp"
                            />
                            <source
                              srcSet={`./images/${this.state.activeTab == "gem" ||
                                this.state.activeTab == "res"
                                ? "jew/inventory/"
                                : "shop/"
                                }${this.state.activeExchItem.img}.png`}
                              type="image/png"
                            />
                            <img
                              src={`./images/${this.state.activeTab == "gem" ||
                                this.state.activeTab == "res"
                                ? "jew/inventory/"
                                : "shop/"
                                }${this.state.activeExchItem.img}.png`}
                              alt=""
                            />
                          </picture>
                        ) : null}
                      </div>
                    </div>

                    <div className="choose-rec">
                      <span>
                        receive<span>:</span>
                      </span>
                      <i>{this.state.receive}</i>
                    </div>

                    <div className="choose-bt">
                      {this.state.activeItem.active &&
                        this.state.activeExchItem.active ? (
                        <span onClick={() => this.craft("prev")}></span>
                      ) : (
                        <span className="disabled"></span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="exch-block right">
                  <div className="exch-block-top">
                    <div className="exch-name">
                      <picture>
                        <source srcSet={nameExchw} type="image/webp" />
                        <source srcSet={nameExch} type="image/png" />
                        <img className="hidemob" src={nameExch} alt="" />
                      </picture>
                    </div>
                    <div className="exch-tabs page-exch">
                      <picture>
                        <source srcSet={blocktopw} type="image/webp" />
                        <source srcSet={blockbot} type="image/png" />
                        <img className="hidemob" src={blockbot} alt="" />
                      </picture>
                    </div>
                  </div>
                  <div className="exch-block-wrap">
                    <div className="exch-block-wrap-in">
                      {this.state.activeTab == "res" ? (
                        <ExchRes
                          {...this.props}
                          {...this.state}
                          exchRes={this.state.exchRes.res}
                          chooseExch={this.chooseExch}
                        />
                      ) : this.state.activeTab == "gem" ? (
                        <ExchGem
                          {...this.props}
                          {...this.state}
                          exchRes={this.state.exchGem.res}
                          chooseExch={this.chooseExch}
                        />
                      ) : this.state.activeTab == "eat" ? (
                        <ExchEat
                          {...this.props}
                          {...this.state}
                          exchRes={this.state.exchEat.res}
                          chooseExch={this.chooseExch}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="exch-block-bot">
                    <div className="exch-name1">
                      <picture>
                        <source srcSet={blockbotw} type="image/webp" />
                        <source srcSet={blockbot} type="image/png" />
                        <img className="hidemob" src={blockbot} alt="" />
                      </picture>
                    </div>
                    <div className="shafl-nav">
                      <div className="shafl-nav-in">
                        {this.state.countPageExch > 1 &&
                          this.state.activePageExch > 1 &&
                          this.state.activeTab == "gem" ? (
                          <button
                            className="nav-link nav-prev"
                            onClick={() => this.navpageExch("prev")}
                          >
                            <span></span>
                          </button>
                        ) : null}
                        {this.state.activeTab == "gem" ? (
                          <div className="nav-num">
                            {this.state.activePageExch}
                          </div>
                        ) : null}
                        {this.state.countPageExch > 1 &&
                          this.state.countPageExch >= this.state.activePageExch &&
                          this.state.activeTab == "gem" ? (
                          <button
                            className="nav-link nav-next"
                            onClick={() => this.navpageExch("next")}
                          >
                            <span></span>
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bot-exch-wrap">
                <div className="exch-block-bot">
                  <div className="exch-name1">
                    <picture>
                      <source srcSet={blockbotw} type="image/webp" />
                      <source srcSet={blockbot} type="image/png" />
                      <img className="hidemob" src={blockbot} alt="" />
                    </picture>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer>
            <div className="footer-nav">
              <nav>
                <ul>
                  <li>
                    <a
                      className="foot-logo-1"
                      target="_blank"
                      href="https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&sort=created&symbol=WAX"
                    >
                      <span></span>
                      <i>Market</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-2"
                      target="_blank"
                      href="https://twitter.com/NftPanda/"
                    >
                      <span></span>
                      <i>Twitter</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-3"
                      target="_blank"
                      href="https://www.instagram.com/nftpanda/"
                    >
                      <span></span>
                      <i>Instagram</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-4"
                      target="_blank"
                      href="https://discord.gg/vCSgme4d5b"
                    >
                      <span></span>
                      <i>Discord</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-5"
                      target="_blank"
                      href={`https://wax.alcor.exchange/swap?output=${process.env.REACT_APP_VAL}-nftpandabamb&input=WAX-eosio.token`}
                    >
                      <span></span>
                      <i>{process.env.REACT_APP_VAL} Trade</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-6"
                      target="_blank"
                      href="https://discord.gg/vCSgme4d5b"
                    >
                      <span></span>
                      <i>Support</i>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </footer>
        </main>
      </>
    );
  }
}

// let mapStateToProps = (state) => ({
//     allinvent: state.inventoryReduser.allinvent,
// });

let mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(ExchangerComponent);
