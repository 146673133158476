import React from 'react';

import { connect } from "react-redux";



import shafltop from '../../../images/shop/shelf-top.png';
import shaflbot from '../../../images/shop/shelf-bot.png';
import shafltopw from '../../../images/shop/shelf-top.webp';
import shaflbotw from '../../../images/shop/shelf-bot.webp';

import musicInOut from './../../../music/in-out.mp3'

import sweepLong from './../../../music/sweep-long.mp3'
import sweepShort from './../../../music/sweep-short.mp3'
import activeTab from './../../../music/active-tab.mp3'
import musicOpen from './../../../music/open.mp3'
import musicClose from './../../../music/close.mp3'

import addEat from './../../../music/buy-eat.mp3'

import Store from './Store';
import Modal from './Modal';

import { ExplorerApi } from "atomicassets" // атомик для списка панд
const fetch = require('node-fetch');
const api = new ExplorerApi("https://" + localStorage.getItem('rpcA'), "atomicassets", { fetch, rateLimit: 4 });

class StoreComponent extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            //errorOnGame: false, // ошибка для вывода карточек в игре
            shop: {
                // EAT
                weapon: [
                    // sword water
                    {
                        nametype: "sword",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "376550",
                        imgBig: "376550",
                        name: "Sword blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "sword",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'Sword Part 1',
                        img: "376551",
                        imgBig: "376551",
                        name: "Sword Part",
                        subtit: "Sword Part 1",
                        descr: "Sword Part 1",
                        price: "5",
                    },
                    {
                        nametype: "sword",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'Sword Part 2',
                        img: "376554",
                        imgBig: "376554",
                        name: "Sword Part",
                        subtit: "Sword Part 2",
                        descr: "Sword Part 2",
                        price: "5",
                    },
                    {
                        nametype: "sword",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'Sword Part 3',
                        img: "376555",
                        imgBig: "376555",
                        name: "Sword Part",
                        subtit: "Sword Part 3",
                        descr: "Sword Part 3",
                        price: "5",
                    },
                    // end sword water
                    // sword fire 
                    {
                        nametype: "sword",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "376561",
                        imgBig: "376561",
                        name: "Sword blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "sword",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'Sword Part 1',
                        img: "376562",
                        imgBig: "376562",
                        name: "Sword Part",
                        subtit: "Sword Part 1",
                        descr: "Sword Part 1",
                        price: "5",
                    },
                    {
                        nametype: "sword",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'Sword Part 2',
                        img: "376563",
                        imgBig: "376563",
                        name: "Sword Part",
                        subtit: "Sword Part 2",
                        descr: "Sword Part 2",
                        price: "5",
                    },
                    {
                        nametype: "sword",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'Sword Part 3',
                        img: "376568",
                        imgBig: "376568",
                        name: "Sword Part",
                        subtit: "Sword Part 3",
                        descr: "Sword Part 3",
                        price: "5",
                    },
                    // end sword fire
                    // sword wind 
                    {
                        nametype: "sword",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "376573",
                        imgBig: "376573",
                        name: "Sword blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "sword",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'Sword Part 1',
                        img: "376574",
                        imgBig: "376574",
                        name: "Sword Part",
                        subtit: "Sword Part 1",
                        descr: "Sword Part 1",
                        price: "5",
                    },
                    {
                        nametype: "sword",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'Sword Part 2',
                        img: "376575",
                        imgBig: "376575",
                        name: "Sword Part",
                        subtit: "Sword Part 2",
                        descr: "Sword Part 2",
                        price: "5",
                    },
                    {
                        nametype: "sword",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'Sword Part 3',
                        img: "376576",
                        imgBig: "376576",
                        name: "Sword Part",
                        subtit: "Sword Part 3",
                        descr: "Sword Part 3",
                        price: "5",
                    },
                    // end sword wind
                    // sword earth 
                    {
                        nametype: "sword",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "376578",
                        imgBig: "376578",
                        name: "Sword blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "sword",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'Sword Part 1',
                        img: "376579",
                        imgBig: "376579",
                        name: "Sword Part",
                        subtit: "Sword Part 1",
                        descr: "Sword Part 1",
                        price: "5",
                    },
                    {
                        nametype: "sword",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'Sword Part 2',
                        img: "376580",
                        imgBig: "376580",
                        name: "Sword Part",
                        subtit: "Sword Part 2",
                        descr: "Sword Part 2",
                        price: "5",
                    },
                    {
                        nametype: "sword",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'Sword Part 3',
                        img: "376581",
                        imgBig: "376581",
                        name: "Sword Part",
                        subtit: "Sword Part 3",
                        descr: "Sword Part 3",
                        price: "5",
                    },
                    // end sword earth
                    // axe water
                    {
                        nametype: "axe",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "373376",
                        imgBig: "373376",
                        name: "axe blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "axe",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'axe Part 1',
                        img: "373380",
                        imgBig: "373380",
                        name: "axe Part",
                        subtit: "axe Part 1",
                        descr: "axe Part 1",
                        price: "5",
                    },
                    {
                        nametype: "axe",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'axe Part 2',
                        img: "373384",
                        imgBig: "373384",
                        name: "axe Part",
                        subtit: "axe Part 2",
                        descr: "axe Part 2",
                        price: "5",
                    },
                    {
                        nametype: "axe",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'axe Part 3',
                        img: "373387",
                        imgBig: "373387",
                        name: "axe Part",
                        subtit: "axe Part 3",
                        descr: "axe Part 3",
                        price: "5",
                    },
                    // end axe water
                    // axe fire 
                    {
                        nametype: "axe",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "373394",
                        imgBig: "373394",
                        name: "axe blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "axe",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'axe Part 1',
                        img: "373406",
                        imgBig: "373406",
                        name: "axe Part",
                        subtit: "axe Part 1",
                        descr: "axe Part 1",
                        price: "5",
                    },
                    {
                        nametype: "axe",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'axe Part 2',
                        img: "373427",
                        imgBig: "373427",
                        name: "axe Part",
                        subtit: "axe Part 2",
                        descr: "axe Part 2",
                        price: "5",
                    },
                    {
                        nametype: "axe",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'axe Part 3',
                        img: "373431",
                        imgBig: "373431",
                        name: "axe Part",
                        subtit: "axe Part 3",
                        descr: "axe Part 3",
                        price: "5",
                    },
                    // end axe fire
                    // axe wind 
                    {
                        nametype: "axe",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "373439",
                        imgBig: "373439",
                        name: "axe blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "axe",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'axe Part 1',
                        img: "373442",
                        imgBig: "373442",
                        name: "axe Part",
                        subtit: "axe Part 1",
                        descr: "axe Part 1",
                        price: "5",
                    },
                    {
                        nametype: "axe",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'axe Part 2',
                        img: "373444",
                        imgBig: "373444",
                        name: "axe Part",
                        subtit: "axe Part 2",
                        descr: "axe Part 2",
                        price: "5",
                    },
                    {
                        nametype: "axe",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'axe Part 3',
                        img: "373447",
                        imgBig: "373447",
                        name: "axe Part",
                        subtit: "axe Part 3",
                        descr: "axe Part 3",
                        price: "5",
                    },
                    // end axe wind
                    // axe earth 
                    {
                        nametype: "axe",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "373452",
                        imgBig: "373452",
                        name: "axe blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "axe",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'axe Part 1',
                        img: "373453",
                        imgBig: "373453",
                        name: "axe Part",
                        subtit: "axe Part 1",
                        descr: "axe Part 1",
                        price: "5",
                    },
                    {
                        nametype: "axe",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'axe Part 2',
                        img: "373454",
                        imgBig: "373454",
                        name: "axe Part",
                        subtit: "axe Part 2",
                        descr: "axe Part 2",
                        price: "5",
                    },
                    {
                        nametype: "axe",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'axe Part 3',
                        img: "373456",
                        imgBig: "373456",
                        name: "axe Part",
                        subtit: "axe Part 3",
                        descr: "axe Part 3",
                        price: "5",
                    },
                    // end axe earth
                    // bow water
                    {
                        nametype: "bow",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "373647",
                        imgBig: "373647",
                        name: "bow blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "bow",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'bow Part 1',
                        img: "373649",
                        imgBig: "373649",
                        name: "bow Part",
                        subtit: "bow Part 1",
                        descr: "bow Part 1",
                        price: "5",
                    },
                    {
                        nametype: "bow",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'bow Part 2',
                        img: "373650",
                        imgBig: "373650",
                        name: "bow Part",
                        subtit: "bow Part 2",
                        descr: "bow Part 2",
                        price: "5",
                    },
                    {
                        nametype: "bow",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'bow Part 3',
                        img: "373653",
                        imgBig: "373653",
                        name: "bow Part",
                        subtit: "bow Part 3",
                        descr: "bow Part 3",
                        price: "5",
                    },
                    // end bow water
                    // bow fire 
                    {
                        nametype: "bow",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "373661",
                        imgBig: "373661",
                        name: "bow blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "bow",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'bow Part 1',
                        img: "373665",
                        imgBig: "373665",
                        name: "bow Part",
                        subtit: "bow Part 1",
                        descr: "bow Part 1",
                        price: "5",
                    },
                    {
                        nametype: "bow",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'bow Part 2',
                        img: "373666",
                        imgBig: "373666",
                        name: "bow Part",
                        subtit: "bow Part 2",
                        descr: "bow Part 2",
                        price: "5",
                    },
                    {
                        nametype: "bow",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'bow Part 3',
                        img: "373667",
                        imgBig: "373667",
                        name: "bow Part",
                        subtit: "bow Part 3",
                        descr: "bow Part 3",
                        price: "5",
                    },
                    // end bow fire
                    // bow wind 
                    {
                        nametype: "bow",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "373671",
                        imgBig: "373671",
                        name: "bow blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "bow",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'bow Part 1',
                        img: "373677",
                        imgBig: "373677",
                        name: "bow Part",
                        subtit: "bow Part 1",
                        descr: "bow Part 1",
                        price: "5",
                    },
                    {
                        nametype: "bow",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'bow Part 2',
                        img: "373678",
                        imgBig: "373678",
                        name: "bow Part",
                        subtit: "bow Part 2",
                        descr: "bow Part 2",
                        price: "5",
                    },
                    {
                        nametype: "bow",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'bow Part 3',
                        img: "373679",
                        imgBig: "373679",
                        name: "bow Part",
                        subtit: "bow Part 3",
                        descr: "bow Part 3",
                        price: "5",
                    },
                    // end bow wind
                    // bow earth 
                    {
                        nametype: "bow",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "373683",
                        imgBig: "373683",
                        name: "bow blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "bow",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'bow Part 1',
                        img: "373732",
                        imgBig: "373732",
                        name: "bow Part",
                        subtit: "bow Part 1",
                        descr: "bow Part 1",
                        price: "5",
                    },
                    {
                        nametype: "bow",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'bow Part 2',
                        img: "373734",
                        imgBig: "373734",
                        name: "bow Part",
                        subtit: "bow Part 2",
                        descr: "bow Part 2",
                        price: "5",
                    },
                    {
                        nametype: "bow",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'bow Part 3',
                        img: "373735",
                        imgBig: "373735",
                        name: "bow Part",
                        subtit: "bow Part 3",
                        descr: "bow Part 3",
                        price: "5",
                    },
                    // end bow earth
                    // claws water
                    {
                        nametype: "claws",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "373737",
                        imgBig: "373737",
                        name: "claws blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "claws",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'claws Part 1',
                        img: "373741",
                        imgBig: "373741",
                        name: "claws Part",
                        subtit: "claws Part 1",
                        descr: "claws Part 1",
                        price: "5",
                    },
                    {
                        nametype: "claws",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'claws Part 2',
                        img: "373742",
                        imgBig: "373742",
                        name: "claws Part",
                        subtit: "claws Part 2",
                        descr: "claws Part 2",
                        price: "5",
                    },
                    {
                        nametype: "claws",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'claws Part 3',
                        img: "373743",
                        imgBig: "373743",
                        name: "claws Part",
                        subtit: "claws Part 3",
                        descr: "claws Part 3",
                        price: "5",
                    },
                    // end claws water
                    // claws fire 
                    {
                        nametype: "claws",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "374508",
                        imgBig: "374508",
                        name: "claws blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "claws",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'claws Part 1',
                        img: "374510",
                        imgBig: "374510",
                        name: "claws Part",
                        subtit: "claws Part 1",
                        descr: "claws Part 1",
                        price: "5",
                    },
                    {
                        nametype: "claws",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'claws Part 2',
                        img: "374511",
                        imgBig: "374511",
                        name: "claws Part",
                        subtit: "claws Part 2",
                        descr: "claws Part 2",
                        price: "5",
                    },
                    {
                        nametype: "claws",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'claws Part 3',
                        img: "374512",
                        imgBig: "374512",
                        name: "claws Part",
                        subtit: "claws Part 3",
                        descr: "claws Part 3",
                        price: "5",
                    },
                    // end claws fire
                    // claws wind 
                    {
                        nametype: "claws",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "374519",
                        imgBig: "374519",
                        name: "claws blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "claws",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'claws Part 1',
                        img: "374521",
                        imgBig: "374521",
                        name: "claws Part",
                        subtit: "claws Part 1",
                        descr: "claws Part 1",
                        price: "5",
                    },
                    {
                        nametype: "claws",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'claws Part 2',
                        img: "374522",
                        imgBig: "374522",
                        name: "claws Part",
                        subtit: "claws Part 2",
                        descr: "claws Part 2",
                        price: "5",
                    },
                    {
                        nametype: "claws",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'claws Part 3',
                        img: "374523",
                        imgBig: "374523",
                        name: "claws Part",
                        subtit: "claws Part 3",
                        descr: "claws Part 3",
                        price: "5",
                    },
                    // end claws wind
                    // claws earth 
                    {
                        nametype: "claws",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "374526",
                        imgBig: "374526",
                        name: "claws blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "claws",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'claws Part 1',
                        img: "374528",
                        imgBig: "374528",
                        name: "claws Part",
                        subtit: "claws Part 1",
                        descr: "claws Part 1",
                        price: "5",
                    },
                    {
                        nametype: "claws",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'claws Part 2',
                        img: "374531",
                        imgBig: "374531",
                        name: "claws Part",
                        subtit: "claws Part 2",
                        descr: "claws Part 2",
                        price: "5",
                    },
                    {
                        nametype: "claws",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'claws Part 3',
                        img: "374532",
                        imgBig: "374532",
                        name: "claws Part",
                        subtit: "claws Part 3",
                        descr: "claws Part 3",
                        price: "5",
                    },
                    // end claws earth
                    // dagger water
                    {
                        nametype: "dagger",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "374537",
                        imgBig: "374537",
                        name: "dagger blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "dagger",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'dagger Part 1',
                        img: "374538",
                        imgBig: "374538",
                        name: "dagger Part",
                        subtit: "dagger Part 1",
                        descr: "dagger Part 1",
                        price: "5",
                    },
                    {
                        nametype: "dagger",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'dagger Part 2',
                        img: "374539",
                        imgBig: "374539",
                        name: "dagger Part",
                        subtit: "dagger Part 2",
                        descr: "dagger Part 2",
                        price: "5",
                    },
                    {
                        nametype: "dagger",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'dagger Part 3',
                        img: "374542",
                        imgBig: "374542",
                        name: "dagger Part",
                        subtit: "dagger Part 3",
                        descr: "dagger Part 3",
                        price: "5",
                    },
                    // end dagger water
                    // dagger fire 
                    {
                        nametype: "dagger",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "374545",
                        imgBig: "374545",
                        name: "dagger blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "dagger",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'dagger Part 1',
                        img: "374558",
                        imgBig: "374558",
                        name: "dagger Part",
                        subtit: "dagger Part 1",
                        descr: "dagger Part 1",
                        price: "5",
                    },
                    {
                        nametype: "dagger",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'dagger Part 2',
                        img: "374561",
                        imgBig: "374561",
                        name: "dagger Part",
                        subtit: "dagger Part 2",
                        descr: "dagger Part 2",
                        price: "5",
                    },
                    {
                        nametype: "dagger",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'dagger Part 3',
                        img: "374565",
                        imgBig: "374565",
                        name: "dagger Part",
                        subtit: "dagger Part 3",
                        descr: "dagger Part 3",
                        price: "5",
                    },
                    // end dagger fire
                    // dagger wind 
                    {
                        nametype: "dagger",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "374585",
                        imgBig: "374585",
                        name: "dagger blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "dagger",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'dagger Part 1',
                        img: "374587",
                        imgBig: "374587",
                        name: "dagger Part",
                        subtit: "dagger Part 1",
                        descr: "dagger Part 1",
                        price: "5",
                    },
                    {
                        nametype: "dagger",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'dagger Part 2',
                        img: "374588",
                        imgBig: "374588",
                        name: "dagger Part",
                        subtit: "dagger Part 2",
                        descr: "dagger Part 2",
                        price: "5",
                    },
                    {
                        nametype: "dagger",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'dagger Part 3',
                        img: "374589",
                        imgBig: "374589",
                        name: "dagger Part",
                        subtit: "dagger Part 3",
                        descr: "dagger Part 3",
                        price: "5",
                    },
                    // end dagger wind
                    // dagger earth 
                    {
                        nametype: "dagger",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "374591",
                        imgBig: "374591",
                        name: "dagger blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "dagger",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'dagger Part 1',
                        img: "374592",
                        imgBig: "374592",
                        name: "dagger Part",
                        subtit: "dagger Part 1",
                        descr: "dagger Part 1",
                        price: "5",
                    },
                    {
                        nametype: "dagger",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'dagger Part 2',
                        img: "374593",
                        imgBig: "374593",
                        name: "dagger Part",
                        subtit: "dagger Part 2",
                        descr: "dagger Part 2",
                        price: "5",
                    },
                    {
                        nametype: "dagger",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'dagger Part 3',
                        img: "374594",
                        imgBig: "374594",
                        name: "dagger Part",
                        subtit: "dagger Part 3",
                        descr: "dagger Part 3",
                        price: "5",
                    },
                    // end dagger earth

                    // gun water
                    {
                        nametype: "gun",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "374597",
                        imgBig: "374597",
                        name: "gun blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "gun",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'gun Part 1',
                        img: "374598",
                        imgBig: "374598",
                        name: "gun Part",
                        subtit: "gun Part 1",
                        descr: "gun Part 1",
                        price: "5",
                    },
                    {
                        nametype: "gun",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'gun Part 2',
                        img: "374601",
                        imgBig: "374601",
                        name: "gun Part",
                        subtit: "gun Part 2",
                        descr: "gun Part 2",
                        price: "5",
                    },
                    {
                        nametype: "gun",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'gun Part 3',
                        img: "374604",
                        imgBig: "374604",
                        name: "gun Part",
                        subtit: "gun Part 3",
                        descr: "gun Part 3",
                        price: "5",
                    },
                    // end gun water
                    // gun fire 
                    {
                        nametype: "gun",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "374608",
                        imgBig: "374608",
                        name: "gun blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "gun",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'gun Part 1',
                        img: "374611",
                        imgBig: "374611",
                        name: "gun Part",
                        subtit: "gun Part 1",
                        descr: "gun Part 1",
                        price: "5",
                    },
                    {
                        nametype: "gun",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'gun Part 2',
                        img: "374612",
                        imgBig: "374612",
                        name: "gun Part",
                        subtit: "gun Part 2",
                        descr: "gun Part 2",
                        price: "5",
                    },
                    {
                        nametype: "gun",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'gun Part 3',
                        img: "374614",
                        imgBig: "374614",
                        name: "gun Part",
                        subtit: "gun Part 3",
                        descr: "gun Part 3",
                        price: "5",
                    },
                    // end gun fire
                    // gun wind 
                    {
                        nametype: "gun",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "374629",
                        imgBig: "374629",
                        name: "gun blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "gun",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'gun Part 1',
                        img: "374632",
                        imgBig: "374632",
                        name: "gun Part",
                        subtit: "gun Part 1",
                        descr: "gun Part 1",
                        price: "5",
                    },
                    {
                        nametype: "gun",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'gun Part 2',
                        img: "374637",
                        imgBig: "374637",
                        name: "gun Part",
                        subtit: "gun Part 2",
                        descr: "gun Part 2",
                        price: "5",
                    },
                    {
                        nametype: "gun",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'gun Part 3',
                        img: "374639",
                        imgBig: "374639",
                        name: "gun Part",
                        subtit: "gun Part 3",
                        descr: "gun Part 3",
                        price: "5",
                    },
                    // end gun wind
                    // gun earth 
                    {
                        nametype: "gun",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "374641",
                        imgBig: "374641",
                        name: "gun blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "gun",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'gun Part 1',
                        img: "374643",
                        imgBig: "374643",
                        name: "gun Part",
                        subtit: "gun Part 1",
                        descr: "gun Part 1",
                        price: "5",
                    },
                    {
                        nametype: "gun",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'gun Part 2',
                        img: "374648",
                        imgBig: "374648",
                        name: "gun Part",
                        subtit: "gun Part 2",
                        descr: "gun Part 2",
                        price: "5",
                    },
                    {
                        nametype: "gun",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'gun Part 3',
                        img: "374651",
                        imgBig: "374651",
                        name: "gun Part",
                        subtit: "gun Part 3",
                        descr: "gun Part 3",
                        price: "5",
                    },
                    // end gun earth

                    // hammer water
                    {
                        nametype: "hammer",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "375229",
                        imgBig: "375229",
                        name: "hammer blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "hammer",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'hammer Part 1',
                        img: "375231",
                        imgBig: "375231",
                        name: "hammer Part",
                        subtit: "hammer Part 1",
                        descr: "hammer Part 1",
                        price: "5",
                    },
                    {
                        nametype: "hammer",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'hammer Part 2',
                        img: "375232",
                        imgBig: "375232",
                        name: "hammer Part",
                        subtit: "hammer Part 2",
                        descr: "hammer Part 2",
                        price: "5",
                    },
                    {
                        nametype: "hammer",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'hammer Part 3',
                        img: "375233",
                        imgBig: "375233",
                        name: "hammer Part",
                        subtit: "hammer Part 3",
                        descr: "hammer Part 3",
                        price: "5",
                    },
                    // end hammer water
                    // hammer fire 
                    {
                        nametype: "hammer",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "375236",
                        imgBig: "375236",
                        name: "hammer blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "hammer",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'hammer Part 1',
                        img: "375239",
                        imgBig: "375239",
                        name: "hammer Part",
                        subtit: "hammer Part 1",
                        descr: "hammer Part 1",
                        price: "5",
                    },
                    {
                        nametype: "hammer",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'hammer Part 2',
                        img: "375240",
                        imgBig: "375240",
                        name: "hammer Part",
                        subtit: "hammer Part 2",
                        descr: "hammer Part 2",
                        price: "5",
                    },
                    {
                        nametype: "hammer",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'hammer Part 3',
                        img: "375241",
                        imgBig: "375241",
                        name: "hammer Part",
                        subtit: "hammer Part 3",
                        descr: "hammer Part 3",
                        price: "5",
                    },
                    // end hammer fire
                    // hammer wind 
                    {
                        nametype: "hammer",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "376141",
                        imgBig: "376141",
                        name: "hammer blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "hammer",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'hammer Part 1',
                        img: "376142",
                        imgBig: "376142",
                        name: "hammer Part",
                        subtit: "hammer Part 1",
                        descr: "hammer Part 1",
                        price: "5",
                    },
                    {
                        nametype: "hammer",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'hammer Part 2',
                        img: "376143",
                        imgBig: "376143",
                        name: "hammer Part",
                        subtit: "hammer Part 2",
                        descr: "hammer Part 2",
                        price: "5",
                    },
                    {
                        nametype: "hammer",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'hammer Part 3',
                        img: "376144",
                        imgBig: "376144",
                        name: "hammer Part",
                        subtit: "hammer Part 3",
                        descr: "hammer Part 3",
                        price: "5",
                    },
                    // end hammer wind
                    // hammer earth 
                    {
                        nametype: "hammer",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "376146",
                        imgBig: "376146",
                        name: "hammer blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "hammer",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'hammer Part 1',
                        img: "376147",
                        imgBig: "376147",
                        name: "hammer Part",
                        subtit: "hammer Part 1",
                        descr: "hammer Part 1",
                        price: "5",
                    },
                    {
                        nametype: "hammer",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'hammer Part 2',
                        img: "376149",
                        imgBig: "376149",
                        name: "hammer Part",
                        subtit: "hammer Part 2",
                        descr: "hammer Part 2",
                        price: "5",
                    },
                    {
                        nametype: "hammer",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'hammer Part 3',
                        img: "376150",
                        imgBig: "376150",
                        name: "hammer Part",
                        subtit: "hammer Part 3",
                        descr: "hammer Part 3",
                        price: "5",
                    },
                    // end hammer earth

                    // mechanical arm water
                    {
                        nametype: "mechanicalarm",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "376152",
                        imgBig: "376152",
                        name: "mechanical arm blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "mechanicalarm",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'mechanical arm Part 1',
                        img: "376153",
                        imgBig: "376153",
                        name: "mechanical arm Part",
                        subtit: "mechanical arm Part 1",
                        descr: "mechanical arm Part 1",
                        price: "5",
                    },
                    {
                        nametype: "mechanicalarm",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'mechanical arm Part 2',
                        img: "376154",
                        imgBig: "376154",
                        name: "mechanical arm Part",
                        subtit: "mechanical arm Part 2",
                        descr: "mechanical arm Part 2",
                        price: "5",
                    },
                    {
                        nametype: "mechanicalarm",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'mechanical arm Part 3',
                        img: "376155",
                        imgBig: "376155",
                        name: "mechanical arm Part",
                        subtit: "mechanical arm Part 3",
                        descr: "mechanical arm Part 3",
                        price: "5",
                    },
                    // end mechanical arm water
                    // mechanical arm fire 
                    {
                        nametype: "mechanicalarm",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "376157",
                        imgBig: "376157",
                        name: "mechanical arm blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "mechanicalarm",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'mechanical arm Part 1',
                        img: "376158",
                        imgBig: "376158",
                        name: "mechanical arm Part",
                        subtit: "mechanical arm Part 1",
                        descr: "mechanical arm Part 1",
                        price: "5",
                    },
                    {
                        nametype: "mechanicalarm",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'mechanical arm Part 2',
                        img: "376160",
                        imgBig: "376160",
                        name: "mechanical arm Part",
                        subtit: "mechanical arm Part 2",
                        descr: "mechanical arm Part 2",
                        price: "5",
                    },
                    {
                        nametype: "mechanicalarm",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'mechanical arm Part 3',
                        img: "376161",
                        imgBig: "376161",
                        name: "mechanical arm Part",
                        subtit: "mechanical arm Part 3",
                        descr: "mechanical arm Part 3",
                        price: "5",
                    },
                    // end mechanical arm fire
                    // mechanical arm wind 
                    {
                        nametype: "mechanicalarm",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "376165",
                        imgBig: "376165",
                        name: "mechanical arm blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "mechanicalarm",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'mechanical arm Part 1',
                        img: "376166",
                        imgBig: "376166",
                        name: "mechanical arm Part",
                        subtit: "mechanical arm Part 1",
                        descr: "mechanical arm Part 1",
                        price: "5",
                    },
                    {
                        nametype: "mechanicalarm",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'mechanical arm Part 2',
                        img: "376167",
                        imgBig: "376167",
                        name: "mechanical arm Part",
                        subtit: "mechanical arm Part 2",
                        descr: "mechanical arm Part 2",
                        price: "5",
                    },
                    {
                        nametype: "mechanicalarm",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'mechanical arm Part 3',
                        img: "376170",
                        imgBig: "376170",
                        name: "mechanical arm Part",
                        subtit: "mechanical arm Part 3",
                        descr: "mechanical arm Part 3",
                        price: "5",
                    },
                    // end mechanical arm wind
                    // mechanical arm earth 
                    {
                        nametype: "mechanicalarm",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "376173",
                        imgBig: "376173",
                        name: "mechanical arm blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "mechanicalarm",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'mechanical arm Part 1',
                        img: "376174",
                        imgBig: "376174",
                        name: "mechanical arm Part",
                        subtit: "mechanical arm Part 1",
                        descr: "mechanical arm Part 1",
                        price: "5",
                    },
                    {
                        nametype: "mechanicalarm",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'mechanical arm Part 2',
                        img: "376175",
                        imgBig: "376175",
                        name: "mechanical arm Part",
                        subtit: "mechanical arm Part 2",
                        descr: "mechanical arm Part 2",
                        price: "5",
                    },
                    {
                        nametype: "mechanicalarm",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'mechanical arm Part 3',
                        img: "376176",
                        imgBig: "376176",
                        name: "mechanical arm Part",
                        subtit: "mechanical arm Part 3",
                        descr: "mechanical arm Part 3",
                        price: "5",
                    },
                    // end mechanical arm earth
                    // slingshot water
                    {
                        nametype: "slingshot",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "376193",
                        imgBig: "376193",
                        name: "slingshot blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "slingshot",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'slingshot Part 1',
                        img: "376194",
                        imgBig: "376194",
                        name: "slingshot Part",
                        subtit: "slingshot Part 1",
                        descr: "slingshot Part 1",
                        price: "5",
                    },
                    {
                        nametype: "slingshot",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'slingshot Part 2',
                        img: "376195",
                        imgBig: "376195",
                        name: "slingshot Part",
                        subtit: "slingshot Part 2",
                        descr: "slingshot Part 2",
                        price: "5",
                    },
                    {
                        nametype: "slingshot",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'slingshot Part 3',
                        img: "376196",
                        imgBig: "376196",
                        name: "slingshot Part",
                        subtit: "slingshot Part 3",
                        descr: "slingshot Part 3",
                        price: "5",
                    },
                    // end slingshot water
                    // slingshot fire 
                    {
                        nametype: "slingshot",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "376200",
                        imgBig: "376200",
                        name: "slingshot blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "slingshot",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'slingshot Part 1',
                        img: "376201",
                        imgBig: "376201",
                        name: "slingshot Part",
                        subtit: "slingshot Part 1",
                        descr: "slingshot Part 1",
                        price: "5",
                    },
                    {
                        nametype: "slingshot",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'slingshot Part 2',
                        img: "376202",
                        imgBig: "376202",
                        name: "slingshot Part",
                        subtit: "slingshot Part 2",
                        descr: "slingshot Part 2",
                        price: "5",
                    },
                    {
                        nametype: "slingshot",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'slingshot Part 3',
                        img: "376203",
                        imgBig: "376203",
                        name: "slingshot Part",
                        subtit: "slingshot Part 3",
                        descr: "slingshot Part 3",
                        price: "5",
                    },
                    // end slingshot fire
                    // slingshot wind 
                    {
                        nametype: "slingshot",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "376295",
                        imgBig: "376295",
                        name: "slingshot blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "slingshot",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'slingshot Part 1',
                        img: "376296",
                        imgBig: "376296",
                        name: "slingshot Part",
                        subtit: "slingshot Part 1",
                        descr: "slingshot Part 1",
                        price: "5",
                    },
                    {
                        nametype: "slingshot",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'slingshot Part 2',
                        img: "376297",
                        imgBig: "376297",
                        name: "slingshot Part",
                        subtit: "slingshot Part 2",
                        descr: "slingshot Part 2",
                        price: "5",
                    },
                    {
                        nametype: "slingshot",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'slingshot Part 3',
                        img: "376299",
                        imgBig: "376299",
                        name: "slingshot Part",
                        subtit: "slingshot Part 3",
                        descr: "slingshot Part 3",
                        price: "5",
                    },
                    // end slingshot wind
                    // slingshot earth 
                    {
                        nametype: "slingshot",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "376300",
                        imgBig: "376300",
                        name: "slingshot blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "slingshot",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'slingshot Part 1',
                        img: "376304",
                        imgBig: "376304",
                        name: "slingshot Part",
                        subtit: "slingshot Part 1",
                        descr: "slingshot Part 1",
                        price: "5",
                    },
                    {
                        nametype: "slingshot",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'slingshot Part 2',
                        img: "376306",
                        imgBig: "376306",
                        name: "slingshot Part",
                        subtit: "slingshot Part 2",
                        descr: "slingshot Part 2",
                        price: "5",
                    },
                    {
                        nametype: "slingshot",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'slingshot Part 3',
                        img: "376308",
                        imgBig: "376308",
                        name: "slingshot Part",
                        subtit: "slingshot Part 3",
                        descr: "slingshot Part 3",
                        price: "5",
                    },
                    // end slingshot earth
                    // stick water
                    {
                        nametype: "stick",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "376312",
                        imgBig: "376312",
                        name: "stick blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "stick",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'stick Part 1',
                        img: "376313",
                        imgBig: "376313",
                        name: "stick Part",
                        subtit: "stick Part 1",
                        descr: "stick Part 1",
                        price: "5",
                    },
                    {
                        nametype: "stick",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'stick Part 2',
                        img: "376315",
                        imgBig: "376315",
                        name: "stick Part",
                        subtit: "stick Part 2",
                        descr: "stick Part 2",
                        price: "5",
                    },
                    {
                        nametype: "stick",
                        type: "weapon",
                        element: "water",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'stick Part 3',
                        img: "376319",
                        imgBig: "376319",
                        name: "stick Part",
                        subtit: "stick Part 3",
                        descr: "stick Part 3",
                        price: "5",
                    },
                    // end stick water
                    // stick fire 
                    {
                        nametype: "stick",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "376322",
                        imgBig: "376322",
                        name: "stick blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "stick",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'stick Part 1',
                        img: "376325",
                        imgBig: "376325",
                        name: "stick Part",
                        subtit: "stick Part 1",
                        descr: "stick Part 1",
                        price: "5",
                    },
                    {
                        nametype: "stick",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'stick Part 2',
                        img: "376327",
                        imgBig: "376327",
                        name: "stick Part",
                        subtit: "stick Part 2",
                        descr: "stick Part 2",
                        price: "5",
                    },
                    {
                        nametype: "stick",
                        type: "weapon",
                        element: "fire",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'stick Part 3',
                        img: "376328",
                        imgBig: "376328",
                        name: "stick Part",
                        subtit: "stick Part 3",
                        descr: "stick Part 3",
                        price: "5",
                    },
                    // end stick fire
                    // stick wind 
                    {
                        nametype: "stick",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "376330",
                        imgBig: "376330",
                        name: "stick blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "stick",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'stick Part 1',
                        img: "376334",
                        imgBig: "376334",
                        name: "stick Part",
                        subtit: "stick Part 1",
                        descr: "stick Part 1",
                        price: "5",
                    },
                    {
                        nametype: "stick",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'stick Part 2',
                        img: "376337",
                        imgBig: "376337",
                        name: "stick Part",
                        subtit: "stick Part 2",
                        descr: "stick Part 2",
                        price: "5",
                    },
                    {
                        nametype: "stick",
                        type: "weapon",
                        element: "wind",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'stick Part 3',
                        img: "376342",
                        imgBig: "376342",
                        name: "stick Part",
                        subtit: "stick Part 3",
                        descr: "stick Part 3",
                        price: "5",
                    },
                    // end stick wind
                    // stick earth 
                    {
                        nametype: "stick",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'blueprint',
                        img: "376397",
                        imgBig: "376397",
                        name: "stick blueprint",
                        subtit: "blueprint",
                        descr: "blueprint",
                        price: "5",
                    },
                    {
                        nametype: "stick",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'stick Part 1',
                        img: "376398",
                        imgBig: "376398",
                        name: "stick Part",
                        subtit: "stick Part 1",
                        descr: "stick Part 1",
                        price: "5",
                    },
                    {
                        nametype: "stick",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'stick Part 2',
                        img: "376399",
                        imgBig: "376399",
                        name: "stick Part",
                        subtit: "stick Part 2",
                        descr: "stick Part 2",
                        price: "5",
                    },
                    {
                        nametype: "stick",
                        type: "weapon",
                        element: "earth",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'stick Part 3',
                        img: "376400",
                        imgBig: "376400",
                        name: "stick Part",
                        subtit: "stick Part 3",
                        descr: "stick Part 3",
                        price: "5",
                    },
                    // end stick earth



                ],
                // END EAT
                // EAT
                eat: [
                    {
                        type: "eat",
                        rare: "common",
                        rareNumber: 0,
                        tooltip: 'common food',
                        img: "eat-Common",
                        imgBig: "eat-Common-nft",
                        name: "pickled bamboo",
                        subtit: "sub title",
                        descr: "descr about this eat",
                        price: 0.28
                    },
                    {
                        type: "eat",
                        rare: "uncommon",
                        rareNumber: 1,
                        tooltip: 'uncommon food',
                        img: "eat-Uncommon",
                        imgBig: "eat-Uncommon-nft",
                        name: "bamboo with mushrooms",
                        subtit: "sub title",
                        descr: "descr about this eat",
                        price: 0.56
                    },
                    {
                        type: "eat",
                        rare: "rare",
                        rareNumber: 2,
                        tooltip: 'rare food',
                        img: "eat-Rare",
                        imgBig: "eat-Rare-nft",
                        name: "ramen",
                        subtit: "sub title",
                        descr: "descr about this eat",
                        price: 1.5000
                    },
                    {
                        type: "eat",
                        rare: "epic",
                        rareNumber: 3,
                        tooltip: 'epic food',
                        img: "eat-Epic",
                        imgBig: "eat-Epic-nft",
                        name: "rice with bamboo",
                        subtit: "sub title",
                        descr: "descr about this eat",
                        price: 2.5000
                    },
                    {
                        type: "eat",
                        rare: "legendary",
                        rareNumber: 4,
                        tooltip: 'legendary food',
                        img: "eat-Legendary",
                        imgBig: "eat-Legendary-nft",
                        name: "dumplings with bamboo",
                        subtit: "sub title",
                        descr: "descr about this eat",
                        price: 10.0000
                    },
                    {
                        type: "eat",
                        rare: "mythic",
                        rareNumber: 5,
                        tooltip: 'mythic food',
                        img: "eat-Mythic",
                        imgBig: "eat-Mythic-nft",
                        name: "stake with bamboo",
                        subtit: "sub title",
                        descr: "descr about this eat",
                        price: 30.0000
                    },
                    {
                        type: "",
                        rare: "",
                        rareNumber: "",
                        tooltip: '',
                        img: "",
                        imgBig: "",
                        name: "",
                        subtit: "",
                        descr: "",
                        price: "",
                    },
                    {
                        type: "",
                        rare: "",
                        rareNumber: "",
                        tooltip: '',
                        img: "",
                        imgBig: "",
                        name: "",
                        subtit: "",
                        descr: "",
                        price: "",
                    },
                    {
                        type: "",
                        rare: "",
                        rareNumber: "",
                        tooltip: '',
                        img: "",
                        imgBig: "",
                        name: "",
                        subtit: "",
                        descr: "",
                        price: "",
                    },
                    {
                        type: "",
                        rare: "",
                        rareNumber: "",
                        tooltip: '',
                        img: "",
                        imgBig: "",
                        name: "",
                        subtit: "",
                        descr: "",
                        price: "",
                    },
                    {
                        type: "",
                        rare: "",
                        rareNumber: "",
                        tooltip: '',
                        img: "",
                        imgBig: "",
                        name: "",
                        subtit: "",
                        descr: "",
                        price: "",
                    },
                    {
                        type: "",
                        rare: "",
                        rareNumber: "",
                        tooltip: '',
                        img: "",
                        imgBig: "",
                        name: "",
                        subtit: "",
                        descr: "",
                        price: "",
                    }
                ],
                // END EAT
                // EAT
                zel: [
                    {
                        type: "potion",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'tooltip potion 1',
                        img: "small-space-power-potion",
                        imgBig: "small-space-power-potion",
                        name: "small space power potion",
                        subtit: "sub title",
                        price: 4.00,
                        token: "TLM",
                        res: "gems",
                        resTmpl: [],
                        resNum: "8"
                    },
                    {
                        type: "potion",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'tooltip potion 1',
                        img: "space-power-potion",
                        imgBig: "space-power-potion",
                        name: "space power potion",
                        subtit: "sub title",
                        price: 10.00,
                        token: "TLM",
                        res: "gems",
                        resNum: "20"
                    },
                    {
                        type: "potion",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'tooltip potion 1',
                        img: "space-sharpening-potion",
                        imgBig: "space-sharpening-potion",
                        name: "space sharpening potion",
                        subtit: "sub title",
                        price: 2.00,
                        token: "TLM",
                        res: "resources",
                        resTmpl: [431181],
                        resNum: "8"
                    },
                    {
                        type: "",
                        rare: "",
                        rareNumber: "",
                        tooltip: '',
                        img: "",
                        imgBig: "",
                        name: "",
                        subtit: "",
                        descr: "",
                        price: "",
                    },
                    {
                        type: "",
                        rare: "",
                        rareNumber: "",
                        tooltip: '',
                        img: "",
                        imgBig: "",
                        name: "",
                        subtit: "",
                        descr: "",
                        price: "",
                    },
                    {
                        type: "",
                        rare: "",
                        rareNumber: "",
                        tooltip: '',
                        img: "",
                        imgBig: "",
                        name: "",
                        subtit: "",
                        descr: "",
                        price: "",
                    },
                    {
                        type: "",
                        rare: "",
                        rareNumber: "",
                        tooltip: '',
                        img: "",
                        imgBig: "",
                        name: "",
                        subtit: "",
                        descr: "",
                        price: "",
                    },
                    {
                        type: "",
                        rare: "",
                        rareNumber: "",
                        tooltip: '',
                        img: "",
                        imgBig: "",
                        name: "",
                        subtit: "",
                        descr: "",
                        price: "",
                    },
                    {
                        type: "",
                        rare: "",
                        rareNumber: "",
                        tooltip: '',
                        img: "",
                        imgBig: "",
                        name: "",
                        subtit: "",
                        descr: "",
                        price: "",
                    },
                    {
                        type: "",
                        rare: "",
                        rareNumber: "",
                        tooltip: '',
                        img: "",
                        imgBig: "",
                        name: "",
                        subtit: "",
                        descr: "",
                        price: "",
                    },
                    {
                        type: "",
                        rare: "",
                        rareNumber: "",
                        tooltip: '',
                        img: "",
                        imgBig: "",
                        name: "",
                        subtit: "",
                        descr: "",
                        price: "",
                    },
                    {
                        type: "",
                        rare: "",
                        rareNumber: "",
                        tooltip: '',
                        img: "",
                        imgBig: "",
                        name: "",
                        subtit: "",
                        descr: "",
                        price: "",
                    },
                ],
                // END EAT
                // EAT

                // type: "eat",
                //         rare: "common",
                //         rareNumber: 0,
                //         tooltip: 'common food',
                //         img: "eat-Common",
                //         imgBig: "eat-Common-nft",
                //         name: "pickled bamboo",
                //         subtit: "sub title",
                //         descr: "descr about this eat",
                //         price: 0.28

                book: [
                    {
                        type: "book",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'for learning skills',
                        img: "elder",
                        imgBig: "elder",
                        name: "scroll for elder",
                        subtit: "sub title",
                        price: 100.00
                    },
                    {
                        type: "book",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'for learning skills',
                        img: "healer",
                        imgBig: "healer",
                        name: "scroll for healer",
                        subtit: "sub title",
                        price: 100.00
                    },
                    {
                        type: "book",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'for learning skills',
                        img: "dreneya",
                        imgBig: "dreneya",
                        name: "scroll for dreneya",
                        subtit: "sub title",
                        price: 100.00
                    },
                    {
                        type: "book",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'for learning skills',
                        img: "priest",
                        imgBig: "priest",
                        name: "scroll for priest",
                        subtit: "sub title",
                        price: 100.00
                    },
                    {
                        type: "book",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'for learning skills',
                        img: "druid",
                        imgBig: "druid",
                        name: "scroll for druid",
                        subtit: "sub title",
                        price: 100.00
                    },
                    {
                        type: "book",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'for learning skills',
                        img: "mage",
                        imgBig: "mage",
                        name: "scroll for mage",
                        subtit: "sub title",
                        price: 100.00
                    },
                    {
                        type: "book",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'for learning skills',
                        img: "tauren",
                        imgBig: "tauren",
                        name: "book for tauren",
                        subtit: "sub title",
                        price: 100.00
                    },
                    {
                        type: "book",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'for learning skills',
                        img: "smith",
                        imgBig: "smith",
                        name: "book for smith",
                        subtit: "sub title",
                        price: 100.00
                    },
                    {
                        type: "book",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'for learning skills',
                        img: "engineer",
                        imgBig: "engineer",
                        name: "book for engineer",
                        subtit: "sub title",
                        price: 100.00
                    },
                    {
                        type: "book",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'for learning skills',
                        img: "berserker",
                        imgBig: "berserker",
                        name: "book for berserker",
                        subtit: "sub title",
                        price: 100.00
                    },
                    {
                        type: "book",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'for learning skills',
                        img: "bard",
                        imgBig: "bard",
                        name: "book for bard",
                        subtit: "sub title",
                        price: 100.00
                    },
                    {
                        type: "book",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'for learning skills',
                        img: "shooter",
                        imgBig: "shooter",
                        name: "book for shooter",
                        subtit: "sub title",
                        price: 100.00
                    },
                    {
                        type: "book",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'for learning skills',
                        img: "archer",
                        imgBig: "archer",
                        name: "book for archer",
                        subtit: "sub title",
                        price: 100.00
                    },
                    {
                        type: "book",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'for learning skills',
                        img: "warrior",
                        imgBig: "warrior",
                        name: "book for warrior",
                        subtit: "sub title",
                        price: 100.00
                    },
                    {
                        type: "book",
                        rare: "common",
                        rareNumber: "0",
                        tooltip: 'for learning skills',
                        img: "paladin",
                        imgBig: "paladin",
                        name: "book for paladin",
                        subtit: "sub title",
                        price: 100.00
                    }

                ]
                // END EAT
            },
            shopitem: null,
            activeTab: '',
            activePage: 1,
            countPage: '',
            modal: {
                showModalStatus: false,
                showAnimModal: false,
                item: {},
                countModal: 1,
            },
            weaponCount: ''

        }

        this.audioMusicInOut = new Audio(musicInOut);

        this.showCloseModal = this.showCloseModal.bind(this);
        this.inputCount = this.inputCount.bind(this);
        this.changeCountBt = this.changeCountBt.bind(this);
        this.GetUserWeaponList = this.GetUserWeaponList.bind(this);
        //this.bayeat = this.bayeat.bind(this);

        this.audioSweeplong = new Audio(sweepLong);
        this.audioSweepShort = new Audio(sweepShort);
        this.audioActiveTab = new Audio(activeTab);
        this.audioOpen = new Audio(musicOpen);
        this.audioClose = new Audio(musicClose);
        this.audioAddEat = new Audio(addEat);

    }

    componentDidMount() {
        //console.log(this.props);
        //console.log(123123123123);
        this.setState({
            shopitem: this.state.shop.weapon,
            activeTab: 'weapon',
            countPage: 13,
        });
    }

    componentDidUpdate() {
        if (this.props.cooking && this.state.modal.showModalStatus) {
            this.setState({
                ...this.state,
                modal: {
                    ...this.state.modal,
                    showModalStatus: false,
                    countModal: 1,
                }

            });
        }
    }

    tabsnav(tab) {
        if (tab == 1) {
            this.setState({
                shopitem: this.state.shop.weapon,
                activeTab: 'weapon',
                activePage: 1,
                countPage: this.state.shop.weapon.length / 12
            });
        } else if (tab == 2) {
            this.setState({
                shopitem: this.state.shop.eat,
                activeTab: 'eat',
                activePage: 1,
                countPage: this.state.shop.eat.length / 12
            });
        } else if (tab == 3) {
            this.setState({
                shopitem: this.state.shop.zel,
                activeTab: 'zel',
                activePage: 1,
                countPage: this.state.shop.zel.length / 12
            });
            this.props.getAlienBalance();
        } else if (tab == 4) {
            this.setState({
                shopitem: this.state.shop.book,
                activeTab: 'book',
                activePage: 1,
                countPage: this.state.shop.book.length / 12
            });
        }

        if (localStorage.getItem('sound') == 'true') {
            this.audioActiveTab.currentTime = 0;
            this.audioActiveTab.volume = 0.1;
            this.audioActiveTab.play();
        }

    }

    navpage(goto) {
        if (goto == 'next') {
            this.setState({
                activePage: this.state.activePage + 1,
            });
        } else {
            this.setState({
                activePage: this.state.activePage - 1,
            });
        }
    }

    showCloseModal(method, item) {
        document.getElementsByTagName('body')[0].classList.add("open-modal");
        if (method == 'open') {
            this.setState({
                modal: {
                    ...this.state.modal,
                    showModalStatus: true,
                    item: item,
                }
            });
            setTimeout(() => {
                this.setState({
                    modal: {
                        ...this.state.modal,
                        showAnimModal: true,
                    }
                });
            }, 300);
            if (localStorage.getItem('sound') == 'true') {
                this.audioOpen.currentTime = 0;
                this.audioOpen.volume = 0.1;
                this.audioOpen.play();
            }

            if (item.type == "weapon") {
                this.GetUserWeaponList(item.img);
            }

        } else {
            document.getElementsByTagName('body')[0].classList.remove("open-modal");
            this.setState({
                modal: {
                    ...this.state.modal,
                    showModalStatus: false,
                    item: {},
                    showAnimModal: false,
                    countModal: 1,
                }
            });
            if (localStorage.getItem('sound') == 'true') {
                this.audioClose.currentTime = 0;
                this.audioClose.volume = 0.1;
                this.audioClose.play();
            }
        }

    }

    inputCount(event) {
        if (event.target.value > 0) {
            this.setState({
                modal: {
                    ...this.state.modal,
                    countModal: event.target.value
                }
            })
        }
    }

    changeCountBt(symb) {
        if (symb == 'plus') {
            var coutVal = parseInt(this.state.modal.countModal) + 1;
            //if(coutVal <= 20) {
            this.setState({
                modal: {
                    ...this.state.modal,
                    countModal: coutVal
                }
            })
            //}
        } else {
            var coutVal = parseInt(this.state.modal.countModal) - 1;
            if (coutVal > 0) {
                this.setState({
                    modal: {
                        ...this.state.modal,
                        countModal: coutVal
                    }
                })
            }
        }
        if (localStorage.getItem('sound') == 'true') {
            this.audioAddEat.currentTime = 0;
            this.audioAddEat.volume = 0.4;
            this.audioAddEat.play();
        }
    }

    opensound(act) {
        if (localStorage.getItem('sound') == 'true' && act == false) {
            this.audioSweepShort.currentTime = 0;
            this.audioSweepShort.volume = 0.1;
            this.audioSweepShort.play();
        }
    }


    closesound(act) {
        if (localStorage.getItem('sound') == 'true' && act == false) {
            this.audioSweepShort.currentTime = 0;
            this.audioSweepShort.volume = 0.1;
            this.audioSweepShort.play();
        }
    }

    async GetUserWeaponList(parts) {
        (async () => {
            try {
                const userName = this.props.ual.activeUser.accountName;
                const activeUser = this.props.ual.activeUser;
                var arr = [
                    parts
                ];
                //arr.push();
                console.log("parts", parts);
                const asset = await api.getAssets({
                    limit: 500,
                    owner: userName,
                    collection_name: 'nftpandawaxp',
                    schema_name: ["weapon"/*, "promo"*/],
                    template_id: parts,
                }).then(assets => {

                    //console.log('assets', assets.length)

                    this.setState({
                        ...this.state,
                        weaponCount: assets.length
                    });
                });
            } catch (e) {
                this.setState({
                    hasError: true,
                    errorText: e.message,
                });
                console.log('\nCaught exception: ' + e);
                // if(localStorage.getItem('sound') == 'true') {
                //     this.audioMusicError.currentTime = 0;
                //     this.audioMusicError.volume = 0.1;
                //     this.audioMusicError.play();
                // }
            }
        })();
    }

    filter(filter, type, val) {
        var filterlist;
        if (filter == "element") {
            if (type == "weapon") {
                filterlist = this.state.shop.weapon.filter(item => item.element === val);
                console.log(filterlist);
                this.setState({
                    ...this.state,
                    shopitem: filterlist,
                    activePage: 1,
                    countPage: filterlist.length / 12
                })
            }
        } else if (filter == "type") {
            if (type == "weapon") {
                filterlist = this.state.shop.weapon.filter(item => item.nametype === val);
                console.log(filterlist);
                this.setState({
                    ...this.state,
                    shopitem: filterlist,
                    activePage: 1,
                    countPage: filterlist.length / 12
                })
            }
        }
    }


    render() {
        return (<>

            {<Modal
                modal={this.state.modal}
                showCloseModal={this.showCloseModal}
                inputCount={this.inputCount}
                changeCountBt={this.changeCountBt}
                bayeat={this.props.bayeat}
                bayweapon={this.props.bayweapon}
                inventareat={this.props.modalEat}
                showModalThank={this.props.showModalThank}
                closeThenk={this.props.closeThenk}
                countModalThank={this.props.countModalThank}
                cooking={this.props.cooking}
                weaponCount={this.state.weaponCount}
                resInGame={this.props.resInGame}
                allNFT={this.props.allNFT}
                tlmBalance={this.props.tlmBalance}
                baypotion={this.props.baypotion}
                allAlienRes={this.props.allAlienRes}
            />}

            <div className="shafl-tab">
                <ul>
                    <li className={`${this.state.activeTab == 'weapon' ? 'weapon active' : 'weapon'}`}>
                        <button onMouseEnter={() => this.opensound(this.state.activeTab == 'weapon' ? true : false)} onMouseLeave={() => this.closesound(this.state.activeTab == 'weapon' ? true : false)} onClick={() => this.tabsnav(1)}>
                            <i></i>
                            <span></span>
                        </button>
                    </li>
                    <li className={`${this.state.activeTab == 'eat' ? 'eat active' : 'eat'}`}>
                        <button onMouseEnter={() => this.opensound(this.state.activeTab == 'eat' ? true : false)} onMouseLeave={() => this.closesound(this.state.activeTab == 'eat' ? true : false)} onClick={() => this.tabsnav(2)}>
                            <i></i>
                            <span></span>
                        </button>
                    </li>
                    <li className={`${this.state.activeTab == 'zel' ? 'zel active' : 'zel'}`}>
                        <button onMouseEnter={() => this.opensound(this.state.activeTab == 'zel' ? true : false)} onMouseLeave={() => this.closesound(this.state.activeTab == 'zel' ? true : false)} onClick={() => this.tabsnav(3)}>
                            <i></i>
                            <span></span>
                        </button>
                    </li>
                    <li className={`${this.state.activeTab == 'book' ? 'book active' : 'book'}`}>
                        <button onMouseEnter={() => this.opensound(this.state.activeTab == 'book' ? true : false)} onMouseLeave={() => this.closesound(this.state.activeTab == 'book' ? true : false)} onClick={() => this.tabsnav(4)}>
                            <i></i>
                            <span></span>
                        </button>
                    </li>
                </ul>
            </div>
            <div className="shafl-body">
                <div className="shafl-top">
                    <div className="eyes"></div>
                    <picture>
                        <source srcSet={shafltopw} type="image/webp" />
                        <source srcSet={shafltop} type="image/png" />
                        <img className="hidedesc" src={shafltop} alt="" />
                    </picture>

                    {this.state.activeTab == 'weapon' ?
                        <div className="one-pack-filter">
                            <div className="filter-shop element-filt">
                                <div className="filter-shop-bt">
                                    <button>
                                        element
                                    </button>
                                </div>
                                <div className="filter-shop-drop">
                                    <div className="filter-shop-wrap">
                                        <div className="filter-shop-drop-top"></div>
                                        <div className="filter-shop-drop-body">
                                            <div className="shop-drop-menu">
                                                <ul>
                                                    <li className="hasico">
                                                        <button className="el-water" onClick={() => this.filter('element', 'weapon', 'water')}>
                                                            <i></i>
                                                            water
                                                        </button>
                                                    </li>
                                                    <li className="hasico">
                                                        <button className="el-fire" onClick={() => this.filter('element', 'weapon', 'fire')}>
                                                            <i></i>
                                                            fire
                                                        </button>
                                                    </li>
                                                    <li className="hasico">
                                                        <button className="el-air" onClick={() => this.filter('element', 'weapon', 'wind')}>
                                                            <i></i>
                                                            wind
                                                        </button>
                                                    </li>
                                                    <li className="hasico">
                                                        <button className="el-earth" onClick={() => this.filter('element', 'weapon', 'earth')}>
                                                            <i></i>
                                                            earth
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="filter-shop-drop-bot"></div>
                                    </div>
                                </div>
                            </div>

                            <div className="filter-shop type-filt">
                                <div className="filter-shop-bt">
                                    <button>
                                        type
                                    </button>
                                </div>
                                <div className="filter-shop-drop">
                                    <div className="filter-shop-wrap">
                                        <div className="filter-shop-drop-top"></div>
                                        <div className="filter-shop-drop-body">
                                            <div className="shop-drop-menu">
                                                <ul>
                                                    <li><button className="el-fire" onClick={() => this.filter('type', 'weapon', 'sword')}>sword</button></li>
                                                    <li><button className="el-fire" onClick={() => this.filter('type', 'weapon', 'axe')}>axe </button></li>
                                                    <li><button className="el-fire" onClick={() => this.filter('type', 'weapon', 'bow')}>bow</button></li>
                                                    <li><button className="el-fire" onClick={() => this.filter('type', 'weapon', 'claws')}>claws </button></li>
                                                    <li><button className="el-fire" onClick={() => this.filter('type', 'weapon', 'dagger')}>dagger </button></li>
                                                    <li><button className="el-fire" onClick={() => this.filter('type', 'weapon', 'gun')}>gun</button></li>
                                                    <li><button className="el-fire" onClick={() => this.filter('type', 'weapon', 'hammer')}>hammer</button></li>
                                                    <li><button className="el-fire" onClick={() => this.filter('type', 'weapon', 'mechanicalarm')}>mechanical arm</button></li>
                                                    <li><button className="el-fire" onClick={() => this.filter('type', 'weapon', 'slingshot')}>slingshot</button></li>
                                                    <li><button className="el-fire" onClick={() => this.filter('type', 'weapon', 'stick')}>stick </button></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="filter-shop-drop-bot"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null}

                </div>
                <div className="shafl-wrap">
                    <div className="shafl-wrap-in">
                        <Store  {...this.props} {...this.state} shopitem={this.state.shopitem} showCloseModal={this.showCloseModal} />
                    </div>
                </div>
                <div className="shafl-bot">

                    <picture>
                        <source srcSet={shaflbotw} type="image/webp" />
                        <source srcSet={shaflbot} type="image/png" />
                        <img className="hidedesc" src={shaflbot} alt="" />
                    </picture>
                    <div className="shafl-nav">
                        <div className="shafl-nav-in">
                            {
                                this.state.countPage > 1 && this.state.activePage > 1 ?
                                    <button className="nav-link nav-prev" onClick={() => this.navpage('prev')}>
                                        <span></span>
                                    </button>
                                    : null
                            }

                            <div className="nav-num">
                                {this.state.activePage}
                            </div>

                            {
                                this.state.countPage > 1 && this.state.countPage >= this.state.activePage ?
                                    <button className="nav-link nav-next" onClick={() => this.navpage('next')}>
                                        <span></span>
                                    </button>
                                    : null
                            }


                        </div>
                    </div>
                </div>
            </div>

        </>);
    }
}

let mapStateToProps = (state) => ({
    //error: state.errorRed.Error,
});

export default connect(mapStateToProps, {})(StoreComponent);