import React, { useEffect, useRef, useState } from 'react';
import p5 from 'p5';
import Menu from '../../components/Menu';
import InventModal from './InventModal';
import Modal from "./Inventory/Modal";
import ModalThank from "./Inventory/ModalThank";
import { ExplorerApi } from "atomicassets";
import { useHistory } from 'react-router-dom';
import { JsonRpc } from "eosjs";
import ErrorComponent from "../Error/ErrorComponent";

import "./../../css/burn.scss";
import font from './../../fonts/troika.otf'

import bg1 from './../../images/fire/bg-1.webp';
import bg2 from './../../images/fire/bg-2.webp';
import bg3 from './../../images/fire/bg-3.webp';
import btBurn from './../../images/fire/burn-bt.png';
import btLeader from './../../images/fire/leader-bt.png';

const fetch = require("node-fetch");
const api = new ExplorerApi(
    "https://" + localStorage.getItem('rpcA'),
    "atomicassets",
    { fetch, rateLimit: 4 }
);

const cardTransfer = {
    actions: [
        {
            account: "atomicassets",
            name: "transfer",
            authorization: [
                {
                    actor: "", // use account that was logged in
                    permission: "active",
                },
            ],
            data: {
                from: "",
                to: process.env.REACT_APP_CONTRACT,
                asset_ids: [],
                memo: "",
            },
        },
    ],
};



const parts = [
    "373380", "373384", "373387", "373376", "373406", "373427", "373431", "373394", "373442", "373444", "373447", "373439", "373453", "373454", "373456", "373452",
    "373649", "373650", "373653", "373647", "373665", "373666", "373667", "373661", "373677", "373678", "373679", "373671", "373732", "373734", "373735", "373683",
    "373741", "373742", "373743", "373737", "374510", "374511", "374512", "374508", "374521", "374522", "374523", "374519", "374528", "374531", "374532", "374526",
    "374538", "374539", "374542", "374537", "374558", "374561", "374565", "374545", "374587", "374588", "374589", "374585", "374592", "374593", "374594", "374591",
    "374598", "374601", "374604", "374597", "374611", "374612", "374614", "374608", "374632", "374637", "374639", "374629", "374643", "374648", "374651", "374641",
    "375231", "375232", "375233", "375229", "375239", "375240", "375241", "375236", "376142", "376143", "376144", "376141", "376147", "376149", "376150", "376146",
    "376153", "376154", "376155", "376152", "376158", "376160", "376161", "376157", "376166", "376167", "376170", "376165", "376174", "376175", "376176", "376173",
    "376194", "376195", "376196", "376193", "376201", "376202", "376203", "376200", "376296", "376297", "376299", "376295", "376304", "376306", "376308", "376300",
    "376313", "376315", "376319", "376312", "376325", "376327", "376328", "376322", "376334", "376337", "376342", "376330", "376398", "376399", "376400", "376397",
    "376551", "376554", "376555", "376550", "376562", "376563", "376568", "376561", "376574", "376575", "376576", "376573", "376579", "376580", "376581", "376578"
]

var numRpc = 0;
var arrRpc = ['wax.pink.gg', 'wax.cryptolions.io', 'wax.greymass.com', 'api.wax.alohaeos.com', 'api.waxsweden.org', 'wax.eosphere.io', 'api.wax.greeneosio.com', 'wax.eu.eosamsterdam.net', 'api.wax.bountyblok.io']


const Burn = (props) => {
    const history = useHistory();
    const canvasRef = useRef(null);
    const p5InstanceRef = useRef(null);
    let p5Instance = null;
    const [modalParam, setModalParam] = useState({
        showModalStatus: false,
        showAnimModal: false,
        item: {},
        countModal: 0
    });
    const [modalItemInfo, setModalItemInfo] = useState(null);

    const [allItems, setAllItems] = useState([]);
    const [user, setUser] = useState(null); // не забудьте установить начальное значение для пользователя

    const [countModal, setCountModal] = useState(0);
    const [manyItems, setManyItems] = useState(0);
    const [manyBalls, setManyBalls] = useState(0);
    const [openInventModal, setOpenInventModal] = useState(false);
    const [canAddMoreItems, setCanAddMoreItems] = useState(true);
    const [openThankModal, setOpenThankModal] = useState(false);
    const [dynamicTextGlobal, setDynamicText] = useState(0);
    const [hasError, setHasError] = useState(false);
    const [errorText, setErrorText] = useState('');


    const createSketch = () => (p) => {
        let myFont;
        let bgImages = [];
        let buttonSprite1, buttonSprite2;
        const button1SpriteWidth = 419;
        const button1SpriteHeight = 390;
        const button1Height = 195;
        const button2SpriteWidth = 368;
        const button2SpriteHeight = 378;
        const button2Height = 189;
        const bgImageWidth = 2500;
        const bgImageHeight = 1563;

        const button1OriginalPosX = 1230;
        const button1OriginalPosY = 1233;
        const button2OriginalPosX = 668;
        const button2OriginalPosY = 1150;



        let buttonScale, button1PosX, button1PosY, button2PosX, button2PosY;

        let transitionStartTime = 0;
        const transitionDuration = 2000; // 2 seconds
        let currentBackground;
        let previousBackground = null;
        let currentBackgroundOpacity = 255;


        const textBlockOriginalPosY = 760; // Adjust these values
        let textBlockWidth = 398;
        const textBlockOriginalPosX = bgImageWidth / 2 - textBlockWidth / 2; // Adjust these values
        let textBlockHeight = 90;
        let textBlockSize = 43;
        let textBlockPosX, textBlockPosY;
        let textPart1 = "your points: ";
        let dynamicText = '';

        const drawTextBlock = () => {
            textBlockPosX = (p.windowWidth / 2) - (bgImageWidth * buttonScale / 2) + textBlockOriginalPosX * buttonScale;
            textBlockPosY = (p.windowHeight / 2) - (bgImageHeight * buttonScale / 2) + textBlockOriginalPosY * buttonScale;

            p.push();
            p.textFont(myFont);
            p.fill(0, 0, 0, 125); // RGBA - Black color with 50% transparency
            p.rect(textBlockPosX, textBlockPosY, textBlockWidth * buttonScale, textBlockHeight * buttonScale);

            p.textAlign(p.LEFT, p.CENTER);
            p.textSize(textBlockSize * buttonScale); // text size scales with button scale
            let textPart1Width = p.textWidth(textPart1);
            let dynamicTextWidth = p.textWidth(p5InstanceRef.current.dynamicTextGlobal);
            let totalTextWidth = textPart1Width + dynamicTextWidth;

            let offsetX = (textBlockWidth * buttonScale - totalTextWidth) / 2;

            p.fill(255, 238, 190); // white color
            p.text(textPart1, textBlockPosX + offsetX, textBlockPosY + (textBlockHeight * buttonScale) / 2);

            p.fill(255); // red color for the dynamic part
            p.text(p5InstanceRef.current.dynamicTextGlobal, textBlockPosX + offsetX + textPart1Width, textBlockPosY + (textBlockHeight * buttonScale) / 2);
            p.pop();
        };

        const storedBackground = window.localStorage.getItem('currentBackground');

        const getCurrentBgIndex = () => {
            let date = new Date();
            let hours = date.getHours();
            if (hours >= 8 && hours < 16) {
                return 0;
            } else if (hours >= 16 && hours < 20) {
                return 1;
            } else {
                return 2;
            }
        };

        let targetDate = new Date(); // you can set a specific date here
        targetDate.setDate(targetDate.getDate() + 2); // For instance, we set the target date 2 days from now

        let timerPosX; // Adjust these values
        let timerPosY;
        let timerPosYorigin = 405;

        const drawTimer = () => {
            timerPosX = p.windowWidth / 2; // Расположение по центру горизонтали
            timerPosY = (p.windowHeight / 2) - (bgImageHeight * buttonScale / 2) + timerPosYorigin * buttonScale; // Учитываем масштабирование

            const now = new Date();
            const distance = 1696118400000 - now;

            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            const timerText = now <= 1696118400000 ? `${days.toString().padStart(2, '0')}:${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}` : "coming soon";
            p.textFont(myFont);
            p.textAlign(p.CENTER, p.CENTER);
            p.textSize(60 * buttonScale); // Adjust the text size
            p.fill(255); // Adjust the text color
            p.text(timerText, timerPosX, timerPosY);
        };

        p.preload = () => {
            bgImages = [p.loadImage(bg1), p.loadImage(bg2), p.loadImage(bg3)];
            buttonSprite1 = p.loadImage(btBurn);
            buttonSprite2 = p.loadImage(btLeader);
            myFont = p.loadFont(font);

            let date = new Date();
            let hours = date.getHours();
            if (hours >= 8 && hours < 16) {
                currentBackground = 0;
            } else if (hours >= 16 && hours < 20) {
                currentBackground = 1;
            } else {
                currentBackground = 2;
            }

            if (storedBackground && storedBackground !== currentBackground.toString()) {
                // Start transition to the new background
                transitionStartTime = p.millis();
                previousBackground = bgImages[parseInt(storedBackground)];
            }

            const storedBackgroundIndex = window.localStorage.getItem('currentBackground');
            currentBackground = storedBackgroundIndex !== null ? Number(storedBackgroundIndex) : getCurrentBgIndex();
        };

        p.setup = () => {
            p.createCanvas(p.windowWidth, p.windowHeight);
            p.imageMode(p.CENTER);

            const currentBgIndex = getCurrentBgIndex();
            if (currentBgIndex !== currentBackground) {
                previousBackground = currentBackground;
                currentBackground = currentBgIndex;
                transitionStartTime = p.millis();
            }
        };

        p.draw = () => {
            p.background(255);
            const scale = Math.max(p.windowWidth / bgImageWidth, p.windowHeight / bgImageHeight);
            const bgImageCenterX = p.windowWidth / 2;
            const bgImageCenterY = p.windowHeight / 2;

            if (transitionStartTime !== 0 && p.millis() - transitionStartTime < transitionDuration) {
                const progress = (p.millis() - transitionStartTime) / transitionDuration;
                const fadeInOpacity = p.map(progress, 0, 1, 0, 255);

                if (previousBackground !== null) {
                    p.push();
                    p.tint(255, 255);
                    p.image(bgImages[previousBackground], bgImageCenterX, bgImageCenterY, bgImageWidth * scale, bgImageHeight * scale);
                    p.pop();
                }

                p.push();
                p.tint(255, fadeInOpacity);
                p.image(bgImages[currentBackground], bgImageCenterX, bgImageCenterY, bgImageWidth * scale, bgImageHeight * scale);
                currentBackgroundOpacity = fadeInOpacity;
                p.pop();
            } else {
                p.push();
                p.tint(255, 255);
                p.image(bgImages[currentBackground], bgImageCenterX, bgImageCenterY, bgImageWidth * scale, bgImageHeight * scale);
                p.pop();

                if (transitionStartTime !== 0) {
                    window.localStorage.setItem('currentBackground', currentBackground.toString());
                    transitionStartTime = 0;
                }
            }

            buttonScale = scale;
            button1PosX = bgImageCenterX - (bgImageWidth * scale / 2) + button1OriginalPosX * buttonScale;
            button1PosY = bgImageCenterY - (bgImageHeight * scale / 2) + button1OriginalPosY * buttonScale;
            button2PosX = bgImageCenterX - (bgImageWidth * scale / 2) + button2OriginalPosX * buttonScale;
            button2PosY = bgImageCenterY - (bgImageHeight * scale / 2) + button2OriginalPosY * buttonScale;

            p.push();

            const now = new Date();
            if (now <= 1696118400000) {
                drawButton(buttonSprite2, button2PosX, button2PosY, button2SpriteWidth, button2Height, button2SpriteHeight);
                drawButton(buttonSprite1, button1PosX, button1PosY, button1SpriteWidth, button1Height, button1SpriteHeight);
                drawTextBlock()
            }
            drawTimer();
            p.pop();
        };

        const drawButton = (sprite, posX, posY, spriteWidth, height, spriteHeight) => {
            const isMouseOverButton = p.mouseX >= posX - spriteWidth / 2 * buttonScale
                && p.mouseX <= posX + spriteWidth / 2 * buttonScale
                && p.mouseY >= posY - height / 2 * buttonScale
                && p.mouseY <= posY + height / 2 * buttonScale;

            const spriteY = isMouseOverButton ? spriteHeight - height : 0;

            p.image(
                sprite,
                posX,
                posY,
                spriteWidth * buttonScale,
                height * buttonScale,
                0,
                spriteY,
                spriteWidth,
                spriteHeight - height
            );
        };

        p.mouseClicked = () => {
            checkButtonClicked(button1PosX, button1PosY, button1SpriteWidth, button1Height, "bt-open-invent");
            checkButtonClicked(button2PosX, button2PosY, button2SpriteWidth, button2Height, "leaderboard");
        };

        const checkButtonClicked = (posX, posY, width, height, type) => {
            const isMouseOverButton = p.mouseX >= posX - width / 2 * buttonScale
                && p.mouseX <= posX + width / 2 * buttonScale
                && p.mouseY >= posY - height / 2 * buttonScale
                && p.mouseY <= posY + height / 2 * buttonScale;

            console.log("openInventModal", p5InstanceRef.current.openInventModal);
            const now = new Date();
            if (isMouseOverButton && p5InstanceRef.current.openInventModal === false) {
                console.log("Button clicked");
                if (type === "bt-open-invent" && now <= 1696118400000) {

                    setOpenInventModal(true);
                    document.getElementsByTagName("body")[0].classList.add("open-modal");
                } else if (type === "leaderboard") {
                    history.push('/leaderboard');
                }
            }
        };

        p.windowResized = () => {
            p.resizeCanvas(window.innerWidth, window.innerHeight);
            // setTimeout(() => {
            //     const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            //     const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            //     p.resizeCanvas(width, height);
            // }, 2000);
        };

    };

    useEffect(() => {
        if (!p5InstanceRef.current) {
            p5InstanceRef.current = new p5(createSketch(), canvasRef.current);
        }
        p5InstanceRef.current.dynamicTextGlobal = dynamicTextGlobal;
        p5InstanceRef.current.openInventModal = openInventModal;

        return () => {
            p5InstanceRef.current.remove();
        };
    }, []);

    useEffect(() => {
        if (p5InstanceRef.current) {
            p5InstanceRef.current.dynamicTextGlobal = dynamicTextGlobal;
            p5InstanceRef.current.openInventModal = openInventModal;
        }
    }, [dynamicTextGlobal, openInventModal]);

    useEffect(() => {
        const handleOrientationChange = () => {
            if (p5InstanceRef.current) {
                p5InstanceRef.current.windowResized();
            }
        };

        window.addEventListener('orientationchange', handleOrientationChange);

        return () => {
            window.removeEventListener('orientationchange', handleOrientationChange);
        };
    }, []);

    const getAllPandaItem = async (username, page = 1, limit = 1000) => {
        try {
            const fetchAssets = async (page) => {
                const params = {
                    limit: limit,
                    page: page,
                    owner: username,
                    collection_name: "nftpandawaxp",
                    schema_name: ["gems", "voucher", "food", "resources", "heroes", "promo", "weapon", "armor", "jewelry"],
                };

                const assets = await api.getAssets(params);

                if (assets && assets.length) {
                    const filteredAssets = assets.map((item) => {
                        //console.log("item.template.template_id", item.template.template_id);
                        if (!parts.includes(item.template.template_id)) {
                            return {
                                asset_id: item.asset_id,
                                name: item.name,
                                template: {
                                    template_id: item.template.template_id,
                                },
                                schema: {
                                    schema_name: item.schema.schema_name
                                },
                                data: {
                                    rarity: item.data.rarity,
                                },
                            };
                        }
                        else {
                            return {
                                asset_id: item.asset_id,
                                name: item.name,
                                template: {
                                    template_id: item.template.template_id,
                                },
                                schema: {
                                    schema_name: "parts"
                                },
                                data: {
                                    rarity: item.data.rarity,
                                },
                            };
                        }

                    }).filter(item => item !== null);

                    return filteredAssets;
                }

                return [];
            };

            const allAssets = [];
            let currentPage = page;
            let hasMorePages = true;

            while (hasMorePages) {
                const assets = await fetchAssets(currentPage);

                if (assets.length === 0) {
                    hasMorePages = false;
                } else {
                    allAssets.push(...assets);
                    if (assets.length === limit) {
                        currentPage += 1;
                    } else {
                        hasMorePages = false;
                    }
                }
            }

            const groupedAssets = allAssets.reduce((grouped, asset) => {
                let schemaKey = asset.schema.schema_name;
                const templateKey = asset.template.template_id;

                //console.log("schemaKey", schemaKey);

                if (schemaKey == "promo") {
                    schemaKey = "heroes"
                }

                if (!grouped[schemaKey]) {
                    grouped[schemaKey] = {};
                }
                if (!grouped[schemaKey][templateKey]) {
                    grouped[schemaKey][templateKey] = {
                        assets: [],
                        count: 0,
                    };;
                }
                grouped[schemaKey][templateKey].assets.push(asset);
                grouped[schemaKey][templateKey].count = 0; // Установите поле count в значение по умолчанию
                return grouped;
            }, {});

            //console.log("groupedAssets", groupedAssets);
            setAllItems(groupedAssets);

        } catch (e) {
            handleError(e.message);
            console.log("\nCaught exception: " + e);
        }
    };

    const gettop = async (username) => {
        try {
            var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);
            const results = await rpcc.get_table_rows({
                json: true,
                code: process.env.REACT_APP_CONTRACT,
                scope: process.env.REACT_APP_CONTRACT,
                lower_bound: username,
                upper_bound: username,
                table: "usertopev",
                limit: 1,
                key_type: "i64",
                reverse: !0,
                show_payer: false,
                index_position: 1,
            });
            const originRowUser = results.rows;

            console.log("originRowUser", originRowUser);

            if (originRowUser && originRowUser.length > 0) {
                setDynamicText(originRowUser[0].allsum / 100);
            }
        } catch (e) {
            handleError(e.message);
            console.log("\nCaught exception: " + e);
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            const accountName = props.activeUser.accountName;
            console.log("accountName", accountName);
            setUser(accountName);
            await getAllPandaItem(accountName, 1, 1000);
            await gettop(accountName);
        };

        fetchData();
    }, [props.activeUser.accountName]);

    const balls = (tmpl) => {
        //console.log("tmpl", tmpl);
        var sum;
        switch (tmpl) {
            case "350909":
                sum = 20;
                break;
            case "350910":
                sum = 30;
                break;

            case "350911":
                sum = 60;
                break;

            case "350912":
                sum = 100;
                break;

            case "350913":
                sum = 200;
                break;

            case "350914":
                sum = 400;
                break;

            case "431181":
                sum = 300;
                break;
            case "431182":
                sum = 600;
                break;

            case "431183":
                sum = 900;
                break;

            case "431184":
                sum = 1200;
                break;

            case "431185":
                sum = 1500;
                break;

            case "431186":
                sum = 1800;
                break;

            case "gems":
                sum = 300;
                break;

            case "437403": case "437402":
                sum = 100;
                break;

                // case "373380": case "373384": case "373387": case "373376": case "373406": case "373427": case "373431": case "373394":
                // case "373442": case "373444": case "373447": case "373439": case "373453": case "373454": case "373456": case "373452":
                // case "373649": case "373650": case "373653": case "373647": case "373665": case "373666": case "373667": case "373661":
                // case "373677": case "373678": case "373679": case "373671": case "373732": case "373734": case "373735": case "373683":
                // case "373741": case "373742": case "373743": case "373737": case "374510": case "374511": case "374512": case "374508":
                // case "374521": case "374522": case "374523": case "374519": case "374528": case "374531": case "374532": case "374526":
                // case "374538": case "374539": case "374542": case "374537": case "374558": case "374561": case "374565": case "374545":
                // case "374587": case "374588": case "374589": case "374585": case "374592": case "374593": case "374594": case "374591":
                // case "374598": case "374601": case "374604": case "374597": case "374611": case "374612": case "374614": case "374608":
                // case "374632": case "374637": case "374639": case "374629": case "374643": case "374648": case "374651": case "374641":
                // case "375231": case "375232": case "375233": case "375229": case "375239": case "375240": case "375241": case "375236":
                // case "376142": case "376143": case "376144": case "376141": case "376147": case "376149": case "376150": case "376146":
                // case "376153": case "376154": case "376155": case "376152": case "376158": case "376160": case "376161": case "376157":
                // case "376166": case "376167": case "376170": case "376165": case "376174": case "376175": case "376176": case "376173":
                // case "376194": case "376195": case "376196": case "376193": case "376201": case "376202": case "376203": case "376200":
                // case "376296": case "376297": case "376299": case "376295": case "376304": case "376306": case "376308": case "376300":
                // case "376313": case "376315": case "376319": case "376312": case "376325": case "376327": case "376328": case "376322":
                // case "376334": case "376337": case "376342": case "376330": case "376398": case "376399": case "376400": case "376397":
                // case "376551": case "376554": case "376555": case "376550": case "376562": case "376563": case "376568": case "376561":
                // case "376574": case "376575": case "376576": case "376573": case "376579": case "376580": case "376581": case "376578":
                sum = 1000;
                break;
            //weapon
            case "373391": case "373433": case "373448": case "373457": case "373659": case "373669": case "373682": case "373736": case "374507": case "374515": case "374525":
            case "374535": case "374544": case "374570": case "374590": case "374596": case "374606": case "374615": case "374640": case "374653": case "375234": case "375243":
            case "376145": case "376151": case "376156": case "376162": case "376172": case "376178": case "376198": case "376204": case "376302": case "376310": case "376320":
            case "376329": case "376374": case "376407": case "376557": case "376570": case "376577": case "376582":
            // end weapon
            // jew
            case "412726": case "412736": case "412750": case "413107": case "413131": case "413857": case "413867": case "414774": case "414785": case "415378": case "415402": case "415409": case "416477": case "416488": case "417238": case "417251": case "428200": case "428220": case "428540": case "429272":
            // end jew
            // armor
            case "549570": case "549569": case "549572": case "549568": case "549575": case "549574": case "549576": case "549573": case "549582":
            case "549580": case "549583": case "549579": case "549587": case "549586": case "549588": case "549585": case "549592":
            case "549590": case "549593": case "549589": case "549601": case "549599": case "549603": case "549598": case "549845": case "549844":
            case "549846": case "549843": case "549850": case "549849": case "549851": case "549848": case "549854": case "549853": case "549855":
            case "549852": case "549860": case "549859": case "549861": case "549857": case "549866": case "549864": case "549867": case "549863":
            case "549870": case "549869": case "549871": case "549868": case "549877": case "549876": case "549878": case "549874": case "549882":
            case "549880": case "549884": case "549879": case "549890": case "549889": case "549892": case "549887":
                // end ermor
                sum = 3000;
                break;
            //weapon
            case "376750": case "376762": case "376770": case "376776": case "376991": case "376997": case "377002": case "377019": case "378777": case "378783": case "378790":
            case "378799": case "378165": case "378290": case "378302": case "378316": case "382639": case "382672": case "382685": case "382702": case "377469": case "377476":
            case "377486": case "377492": case "382574": case "382580": case "382604": case "382621": case "379070": case "380271": case "380277": case "382044": case "377302":
            case "377347": case "377365": case "377387": case "378029": case "378037": case "378080": case "378087":
            // end weapon
            // jew
            case "412728": case "412737": case "412751": case "413108": case "413133": case "413859": case "413868": case "414776": case "414786": case "415381": case "415403": case "415575": case "416478": case "416489": case "417239": case "417252": case "428202": case "428222": case "428543": case "429274":
            // end jew
            // armor
            case "550246": case "550244": case "550247": case "550243": case "550251": case "550250": case "550252": case "550249": case "550256":
            case "550255": case "550257": case "550253": case "550269": case "550268": case "550270": case "550266": case "550439": case "550438":
            case "550440": case "550437": case "550609": case "550608": case "550610": case "550607": case "550640": case "550639": case "550642":
            case "550638": case "550652": case "550651": case "550653": case "550649": case "550662": case "550661": case "550663": case "550660":
            case "550667": case "550666": case "550668": case "550665": case "550674": case "550672": case "550675": case "550671": case "551011":
            case "551010": case "551013": case "551008": case "551028": case "551027": case "551029": case "551026": case "551037": case "551035":
            case "551039": case "551034": case "551222": case "551221": case "551223": case "551220":
                // end ermor
                sum = 9000;
                break;
            //weapon
            case "376753": case "376763": case "376772": case "376777": case "376993": case "376998": case "377009": case "377283": case "378778": case "378784": case "378791":
            case "378801": case "378171": case "378292": case "378305": case "378317": case "382643": case "382675": case "382688": case "382709": case "377472": case "377477":
            case "377487": case "378015": case "382575": case "382581": case "382609": case "382623": case "379072": case "380272": case "380280": case "382046": case "377303":
            case "377352": case "377368": case "377392": case "378030": case "378038": case "378081": case "378088":
            // end weapon
            // jew

            case "412730": case "412738": case "412753": case "413111": case "413135": case "413860": case "413869": case "414778": case "414798": case "415385": case "415404": case "415576": case "416479": case "416490": case "417240": case "417253": case "428204": case "428225": case "428546": case "429275":
            // end jew
            // armor
            case "551825": case "551824": case "551826": case "551823": case "551862": case "551861": case "551863": case "551860": case "551946":
            case "551944": case "551947": case "551943": case "552241": case "552240": case "552244": case "552239": case "552257": case "552255":
            case "552260": case "552251": case "552269": case "552268": case "552270": case "552267": case "552276": case "552275": case "552278":
            case "552274": case "552511": case "552509": case "552513": case "552507": case "552533": case "552532": case "552534": case "552530":
            case "552538": case "552537": case "552539": case "552536": case "552555": case "552554": case "552556": case "552551": case "552563":
            case "552562": case "552564": case "552561": case "552567": case "552566": case "552568": case "552565": case "552572": case "552571":
            case "552574": case "552570": case "552578": case "552577": case "552580": case "552576":
            case "552253":
                // end ermor
                sum = 18000;
                break;
            //weapon
            case "376755": case "376767": case "376773": case "376787": case "376994": case "376999": case "377011": case "377285": case "378779": case "378785": case "378794":
            case "378810": case "378174": case "378295": case "378307": case "378320": case "382647": case "382678": case "382691": case "382714": case "377473": case "377481":
            case "377488": case "378017": case "382577": case "382588": case "382613": case "382627": case "380265": case "380273": case "380281": case "382050": case "377304":
            case "377355": case "377373": case "377397": case "378031": case "378040": case "378083": case "378089":
            // end weapon
            // jew
            case "412732": case "412739": case "412754": case "413112": case "413136": case "413862": case "413872": case "414782": case "414800": case "415388": case "415406": case "415577": case "416482": case "416491": case "417241": case "417254": case "428207": case "428226": case "428548": case "429276":
            // end jew
            // armor
            case "552775": case "552774": case "552776": case "552773": case "552781": case "552780": case "552782": case "552779": case "552820":
            case "552819": case "552821": case "552818": case "552825": case "552824": case "552826": case "552823": case "552853": case "552852":
            case "552855": case "552851": case "556260": case "556259": case "556264": case "556258": case "556372": case "556371": case "556373":
            case "556370": case "556377": case "556376": case "556378": case "556375": case "556414": case "556412": case "556418": case "556410":
            case "556470": case "556467": case "556471": case "556466": case "556477": case "556476": case "556478": case "556475": case "556611":
            case "556609": case "556612": case "556608": case "556623": case "556622": case "556624": case "556621": case "556628": case "556627":
            case "556629": case "556626": case "556632": case "556631": case "556633": case "556630":
                // end ermor
                sum = 50000;
                break;
            //weapon
            case "376756": case "376768": case "376774": case "376794": case "376995": case "377000": case "377012": case "377286": case "378780": case "378787": case "378795":
            case "378815": case "378176": case "378296": case "378311": case "378322": case "382670": case "382680": case "382693": case "382724": case "377474": case "377483":
            case "377489": case "378023": case "382578": case "382591": case "382616": case "382628": case "380268": case "380275": case "380284": case "382052": case "377306":
            case "377359": case "377377": case "377401": case "378034": case "378075": case "378084": case "378090":
            // end weapon
            // jew
            case "412733": case "412740": case "412755": case "413114": case "413139": case "413863": case "413874": case "414783": case "414801": case "415389": case "415407": case "415578": case "416483": case "416492": case "417242": case "417257": case "428213": case "428527": case "428550": case "429277":
            // end jew
            // armor
            case "582961": case "582960": case "582963": case "582959": case "582966": case "582965": case "582967": case "582964": case "583000":
            case "582999": case "583001": case "582998": case "583004": case "583003": case "583005": case "583002": case "583249": case "583248":
            case "583251": case "583247": case "583254": case "583253": case "583255": case "583252": case "583258": case "583257": case "583259":
            case "583256": case "583262": case "583261": case "583264": case "583260": case "583267": case "583266": case "583269": case "583265":

            case "583558": case "583557": case "583560": case "583556": case "583563": case "583562": case "583564": case "583561": case "583567":
            case "583566": case "583568": case "583565": case "583607": case "583603": case "583608": case "583600": case "583856": case "583855":
            case "583857": case "583854": case "583864": case "583863": case "583865": case "583862":
                // end ermor
                sum = 150000;
                break;
            //weapon
            case "376760": case "376769": case "376775": case "376795": case "376996": case "377001": case "377015": case "377287": case "378781": case "378789": case "378796":
            case "392191": case "378178": case "378299": case "378313": case "378323": case "382671": case "382684": case "382698": case "382726": case "377475": case "377484":
            case "377490": case "378025": case "382579": case "382594": case "382620": case "382629": case "380270": case "380276": case "380286": case "382054": case "377307":
            case "377362": case "377381": case "377404": case "378036": case "378077": case "378085": case "378091":
            // end weapon
            // jew
            case "412735": case "412741": case "412757": case "413115": case "413141": case "413865": case "413877": case "414784": case "414804": case "415392": case "415408": case "415579": case "416486": case "416493": case "417244": case "417259": case "428215": case "428529": case "428553": case "429295":
            // end jew
            // armor
            case "595073": case "595072": case "595074": case "595066": case "595077": case "595076": case "595078": case "595075": case "595081":
            case "595080": case "595082": case "595079": case "595085": case "595084": case "595086": case "595083": case "595092": case "595091":
            case "595093": case "595090": case "595097": case "595096": case "595098": case "595095": case "595101": case "595100": case "595102":
            case "595099": case "595105": case "595104": case "595106": case "595103": case "595110": case "595109": case "595111": case "595108":
            case "595114": case "595113": case "595115": case "595112": case "595120": case "595117": case "595122": case "595116": case "595126":
            case "595125": case "595127": case "595124": case "595176": case "595175": case "595177": case "595174": case "595181": case "595180":
            case "595182": case "595179": case "595187": case "595184": case "595188": case "595183":
                // end ermor
                sum = 500000;
                break;

            case "153429": case "152522": case "152929": case "153254": case "152790": case "152501": case "152536": case "152512": case "152422": case "151602": case "153086":
            case "152387": case "295827": case "295844": case "295996": case "296010": case "296020": case "296035": case "296081": case "296097": case "296104": case "296629":
            case "296781": case "296787":
            // new
            case "403458": case "402745": case "402979": case "403425": case "402479": case "402524": case "401715": case "401754": case "401883": case "401819": case "401834":
            case "401847":
            //promo
            case "174053": case "162161": case "157195": case "287222": case "314592": case "341179":
                sum = 12000;
                break;
            case "153430": case "152525": case "152953": case "153266": case "152792": case "152503": case "152537": case "152514": case "152423": case "151638": case "153105":
            case "152389": case "295831": case "295846": case "295997": case "296011": case "296023": case "296049": case "296083": case "296098": case "296106": case "296630":
            case "296782": case "296788":
            // new
            case "403543": case "402749": case "402982": case "403434": case "402495": case "402525": case "401717": case "401755": case "401890": case "401820": case "401836":
            case "401854":
                sum = 36000;
                break;
            case "153432": case "152527": case "152974": case "153273": case "152829": case "152504": case "152538": case "152515": case "152424": case "151659": case "153240":
            case "152392": case "295833": case "295848": case "295998": case "296013": case "296025": case "296073": case "296085": case "296099": case "296108": case "296633":
            case "296783": case "296789":
            // new
            case "403551": case "402751": case "403404": case "403435": case "402496": case "402526": case "401718": case "401756": case "402198": case "401825": case "401840":
            case "401857":
                sum = 72000;
                break;
            case "153433": case "152528": case "152987": case "153444": case "152844": case "152505": case "152541": case "152516": case "152426": case "151661": case "153243":
            case "152393": case "295836": case "295852": case "296005": case "296014": case "296029": case "296076": case "296086": case "296100": case "296625": case "296778":
            case "296784": case "296790":
            // new
            case "403565": case "402754": case "403405": case "403438": case "402499": case "402527": case "401719": case "401758": case "402202": case "401827": case "401841":
            case "401862":
                sum = 200000;
                break;
            case "153436": case "152529": case "153445": case "152860": case "152507": case "152542": case "152517": case "152427": case "151662": case "153245": case "152395":
            case "153002": case "295838": case "295854": case "296007": case "296016": case "296032": case "296077": case "296088": case "296101": case "296626": case "296779":
            case "296785": case "296791":
            // new
            case "403585": case "402766": case "403407": case "403439": case "402500": case "402528": case "401720": case "401759": case "402203": case "401830": case "401842":
            case "401863":
                sum = 600000;
                break;
            case "153438": case "153446": case "152886": case "152519": case "152428": case "153246": case "152398": case "152532": case "153021": case "152509": case "152678":
            case "152362": case "295840": case "295855": case "296008": case "296018": case "296033": case "296079": case "296091": case "296102": case "296627": case "296780":
            case "296786": case "296793":
            // new
            case "403590": case "402769": case "403409": case "403442": case "402502": case "402530": case "401721": case "401807": case "402205": case "401831": case "401843":
            case "401866":
                sum = 2000000;
                break;

            default:
                sum = 0;
                break;
        }

        return sum
    }

    const showCloseModal = (method, item) => {
        document.getElementsByTagName("body")[0].classList.add("open-modal");
        if (method === "open") {
            setModalParam(prevModalParam => ({
                ...prevModalParam,
                showModalStatus: true
            }));
            setModalItemInfo(item);
            setCountModal(item.activeCount != 0 ? item.activeCount : 0)
            setTimeout(() => {
                setModalParam(prevModalParam => ({
                    ...prevModalParam,
                    showAnimModal: true
                }));
            }, 300);

        } else {
            document.getElementsByTagName("body")[0].classList.remove("open-modal");

            setModalParam({
                showModalStatus: false,
                item: {},
                showAnimModal: false,
                countModal: 0
            });
            setModalItemInfo(null);
        }
    }

    const changeCountBt = (operation) => {
        let newCount = countModal;
        // console.log("operation", operation);
        // console.log("modalItemInfo.schema.schema_name", modalItemInfo.schema.schema_name);
        // console.log("modalItemInfo.template.template_id", modalItemInfo.template.template_id);
        // console.log("newCount", newCount);
        var schema = modalItemInfo.schema.schema_name;
        var template_id = modalItemInfo.template.template_id;
        if (schema == "promo") {
            schema = "heroes"
        }
        if (operation === "plus" && newCount < allItems[schema][template_id].assets.length) {
            newCount++;
        } else if (operation === "minus" && newCount > 0) { // prevent countModal going below 1
            newCount--;
        }

        setCountModal(newCount);
        canChooseMoreItems(newCount);
    };

    const inputCount = (event) => {
        let newCount = parseInt(event.target.value);
        if (!isNaN(newCount) && newCount >= 0 && newCount <= allItems[modalItemInfo.schema.schema_name][modalItemInfo.template.template_id].assets.length) { // ensure that countModal is a number and it's at least 1
            setCountModal(newCount);
            canChooseMoreItems(newCount);
        }
    };

    const canChooseMoreItems = (countModal) => {
        var can;

        if (modalItemInfo.activeCount == countModal || modalItemInfo.activeCount < countModal) {
            can = 50 - (manyItems + countModal) + modalItemInfo.activeCount;
        } else if (modalItemInfo.activeCount > countModal) {
            can = 50 - (manyItems + countModal) + (modalItemInfo.activeCount)
        } else {
            can = 50 - (manyItems + countModal)
        }

        setCanAddMoreItems(can >= 0 ? true : false);
        //console.log("can != 0 ? true : false", can >= 0 ? true : false);
    }

    const acceptChanges = () => {

        var schema = modalItemInfo.schema.schema_name;
        var template_id = modalItemInfo.template.template_id;
        if (schema == "promo") {
            schema = "heroes"
        }
        setAllItems(prevItems => {
            const newItems = { ...prevItems };
            newItems[schema][template_id].count = countModal;
            return newItems;
        });

        setModalItemInfo(prevItems2 => {
            const newItems2 = { ...prevItems2 };
            newItems2.activeCount = countModal;
            return newItems2;
        });
        showCloseModal("close");
    };

    useEffect(() => {
        let totalCount = 0;
        let totalBall = 0;

        for (let schema in allItems) {
            for (let template in allItems[schema]) {
                totalCount += allItems[schema][template].count;
                if (schema !== "gems") {
                    totalBall += allItems[schema][template].count * balls(template);
                } else {
                    totalBall += allItems[schema][template].count * balls(schema);
                }
            }
        }

        setManyItems(totalCount);
        setManyBalls(totalBall);
    }, [allItems]);

    const addpoints = async () => {
        var loadingSlot = document.getElementsByTagName("body")[0];
        loadingSlot.classList.add("loading");

        let asset_id = [];

        for (let schema in allItems) {
            for (let template in allItems[schema]) {
                const assetSlice = allItems[schema][template].assets
                    .slice(0, allItems[schema][template].count)
                    .map(asset => asset.asset_id);
                asset_id.push(...assetSlice);
            }
        }

        cardTransfer.actions[0].authorization[0].actor = user;
        cardTransfer.actions[0].data.from = user;
        cardTransfer.actions[0].data.asset_ids = asset_id;
        cardTransfer.actions[0].data.memo = "burning festival";
        //console.log(cardTransfer);
        try {
            await props.ual.activeUser
                .signTransaction(cardTransfer, {
                    blocksBehind: 3,
                    expireSeconds: 30,
                })
                .then((response) => {
                    if (response.status === "executed") {
                        loadingSlot.classList.remove("loading");

                        for (let schema in allItems) {
                            for (let template in allItems[schema]) {
                                const count = allItems[schema][template].count;
                                allItems[schema][template].assets.splice(0, count);
                                allItems[schema][template].count = 0;
                            }
                        }

                        setManyItems(0);
                        setOpenThankModal(true);
                        setDynamicText(dynamicTextGlobal + (manyBalls / 100));
                    }
                });
        } catch (error) {
            handleError(error.message);
            loadingSlot.classList.remove("loading");
        }
    }

    const closeInvent = () => {
        setOpenInventModal(false);
        document.getElementsByTagName("body")[0].classList.remove("open-modal");
    }

    const closeThank = () => {
        setOpenThankModal(false);
        setManyBalls(0);
        //setOpenInventModal(false);
    }

    const handleError = (e) => {

        console.warn(e);
        setHasError(true);
        setErrorText(e);
    }

    const clearError = () => {
        setHasError(false);
        setErrorText("");
    }


    return (<div className='burning-page'>
        {hasError && errorText ? (
            <ErrorComponent
                errorMessage={errorText}
                clearError={clearError}
            />
        ) : null}

        <div className="rotate-screen"></div>
        {openThankModal &&
            <ModalThank manyBalls={manyBalls} closeThank={closeThank} />
        }
        <Modal
            modal={modalParam}
            showCloseModal={showCloseModal}
            modalItemInfo={modalItemInfo}
            countModal={countModal}
            changeCountBt={changeCountBt}
            inputCount={inputCount}
            acceptChanges={acceptChanges}
            manyItems={manyItems}
            canAddMoreItems={canAddMoreItems}
        />
        <Menu  {...props.ual} wax={props.balanceWax} bam={props.balanceBam} game={props.balanceGame} updateBalance={props.updateBalance} />
        {openInventModal &&
            <InventModal
                showCloseModal={showCloseModal}
                allItems={allItems}
                manyItems={manyItems}
                manyBalls={manyBalls}
                addpoints={addpoints}
                closeInvent={closeInvent} />
        }
        <div ref={canvasRef}></div>
    </div>
    );
};

export default Burn;
