import React, { useState }  from 'react';
import CountUp from 'react-countup';
import DetalLoop from '../NewAdv/DetalLoop';

import rewtop from '../../../images/new-adv/mult-res-top.png';
import rewtopw from '../../../images/new-adv/mult-res-top.webp';
import rewbot from '../../../images/new-adv/mult-res-bot.png';
import rewbotw from '../../../images/new-adv/mult-res-bot.webp';
import back from '../../../images/new-adv/modal-back.svg';
import titleOne from '../../../images/new-adv/modal-tit-1.svg';


const DetalMultInfo = (props) => {

    return (
    <>
        <div className={`detal-mult-modal ${props.modalDetalRewOpen && "open"} ${props.modalDetalRewOpenAnim && "anim"}`}>
            <div className="detal-mult-body">
                <div className="detal-mult-body-in">
                    <div className="detal-mult-res">
                        <div className="detal-mult-top">
                            <picture>
                                <source srcSet={rewtopw} type="image/webp" />
                                <source srcSet={rewtop} type="image/png" /> 
                                <img src={rewtop} alt="" />
                            </picture>
                        </div>
                        <div className="detal-mult-list">
                            <DetalLoop {...props} />
                            
                        </div>
                        <div className="detal-mult-bot">
                            <picture>
                                <source srcSet={rewbotw} type="image/webp" />
                                <source srcSet={rewbot} type="image/png" /> 
                                <img src={rewbot} alt="" />
                            </picture>

                            <div className="detal-close">
                                <button className="def-bt norm"  onClick={() => props.openNewAdvMap('close', props.duration, props.typeReward)}>
                                    <span className="button-in">
                                        <span className="button-name">
                                            <span className="button-name-in">
                                                <span className="button-name-value">
                                                    Close
                                                </span> 
                                            </span>
                                        </span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </>);
}

export default DetalMultInfo;