import './App.scss';
import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Login from './pages/Login';
import AdventuresComponent from './pages/Adventures/AdventuresComponent';
import LKComponent from './pages/LK/LKComponent';
import LKAlienComponent from './pages/LKAlien/LKAlienComponent';
import AlienComponent from './pages/Alien/AlienComponent';
import HeropageComponent from './pages/Heropage/HeropageComponent';
import RentComponent from './pages/Rent/RentComponent';
import CraftComponentFirst from './pages/Craft/CraftComponentFirst';
import CraftComponentWeapon from './pages/Craft/CraftComponentWeapon';
import CraftComponentJewelry from './pages/Craft/CraftComponentJewelry';
import CraftPageComponentWeapon from './pages/Craft/CraftPageComponentWeapon';
import UpgradePageComponentWeapon from './pages/Craft/UpgradePageComponentWeapon';
import CraftPageComponentJewelry from './pages/Craft/CraftPageComponentJewelry';
import UpgradePageComponentJewelry from './pages/Craft/UpgradePageComponentJewelry';
import UpgradePageComponentArmor from './pages/Craft/UpgradePageComponentArmor';
import CraftPageComponentArmor from './pages/Craft/CraftPageComponentArmor';
//import StakingComponent from './pages/Staking/StakingComponent';
import ReferalComponent from './pages/Referal/ReferalComponent';
//import GuardComponent from './pages/Guard/GuardComponent';
import ShopComponent from './pages/Shop/ShopComponent';
import InventoryComponent from './pages/Inventory/InventoryComponent';
import ArtefactsComponent from './pages/Artefacts/ArtefactsComponent';
//import AchivComponent from './pages/Achiv/AchivComponent';
//import StatisticsComponent from './pages/Statistics/StatisticsComponent';
//import StatisticsResComponent from './pages/StatisticsRes/StatisticsResComponent';
import StatisticsEventComponent from './pages/StatisticsEvent/StatisticsEventComponent';
import AlienLeaderboardComponent from './pages/AlienLeaderboard/AlienLeaderboardComponent';
import CalculatorComponent from './pages/Calculator/CalculatorComponent';
import cryptoRandomString from 'crypto-random-string';
import ExchangerComponent from './pages/Exchanger/ExchangerComponent';
import TavernComponent from './pages/Tavern/TavernComponent';
import Boss from './pages/Boss/Boss';
import Burn from './pages/Burn/Burn';
import Skills from './pages/Skills/Skills';
//import TavernModal from './pages/TavernModal/TavernModalComponent';
import AuctionComponent from './pages/Auction/AuctionComponent';

//import { useSelector } from 'react-redux';
//import FirstModalLogin from './components/FirstModalLogin';
import { JsonRpc } from 'eosjs'

import { connect } from "react-redux";
import { setBalance, setBalanceBam, changeBalance } from "./GlobalState/head-reducer"
import { questitems, setQuestInfo, changeTabs, canComplateQuest, setTavernInfo } from "./GlobalState/quest-reducer"


var arrHub = [
  "aa-wax-public1.neftyblocks.com",
  "wax.api.atomicassets.io",
  "api.wax-aa.bountyblok.io",
  "aa.dapplica.io",
  "api.atomic.greeneosio.com",
  "atomic.wax.eosrio.io",
  "wax-aa.eu.eosamsterdam.net",
  "atomic-wax-mainnet.wecan.dev",
  "atomic.sentnl.io",
  // "atomic.tokengamer.io",
  // "atomic.ledgerwise.io",
  // "api-wax-aa.eosarabia.net",
  // "wax.hkeos.com/aa",
  // "wax-atomic.wizardsguild.one",
  // "wax-atomic.eosiomadrid.io",
  // "aa.wax.blacklusion.io",
  // "wax-atomic-api.eosphere.io",
  // "wax-aa.eosdublin.io"
]


var numRpc = 0;
var arrRpc = ['wax.pink.gg', 'wax.cryptolions.io', 'wax.greymass.com', 'api.wax.alohaeos.com', 'api.waxsweden.org', 'wax.eosphere.io', 'api.wax.greeneosio.com', 'wax.eu.eosamsterdam.net', 'api.wax.bountyblok.io']


//const api2 = new ExplorerApi("https://" + arrHub[numRpc], "atomicassets", { fetch, rateLimit: 4 });


class App extends React.Component {
  //const UserState = useSelector((store) => store.user);

  constructor(props) {
    super(props);
    this.state = {
      activeUser: null,
      accountName: '',
      accountBalance: null,
      accountBalancenftpandabamb: null,
      checkedTerm: false,
      checkStatus: false,
      openQuest: false,
      hideQuestBt: true,
      loadingUpdate: true,
      loadingLogin: true,
      activeNoda: "wax.pink.gg",
      activeNodaAtomic: "wax.api.atomicassets.io"


    };

    this.updateAccountName = this.updateAccountName.bind(this);
    this.updateAccountBalance = this.updateAccountBalance.bind(this);
    this.getGameBalance = this.getGameBalance.bind(this);
    this.removeAddBam = this.removeAddBam.bind(this);
    this.checkError = this.checkError.bind(this);
    this.addCheckBox = this.addCheckBox.bind(this);
    this.openQuest = this.openQuest.bind(this);
    this.getTavernInfo = this.getTavernInfo.bind(this);
    this.hideQuestBt = this.hideQuestBt.bind(this);

  }



  componentDidUpdate() {
    const { ual: { activeUser } } = this.props;
    //const accountName = activeUser.getAccountName();
    //console.log(11111);
    if (activeUser && !this.state.activeUser) {
      this.setState(
        {
          activeUser: activeUser,
          loadingLogin: false
        },
        this.updateAccountName);
      this.getGameBalance();
      this.getTavernInfo();
      localStorage.setItem('activeUser', true);
      this.setState({
        checkedTerm: false,
        checkStatus: false
      })

      if (localStorage.getItem('signing_value') == null) {
        const number = cryptoRandomString({ length: 14, type: 'numeric' });
        localStorage.setItem('signing_value', number);
      }

    } else if (!activeUser && this.state.activeUser) {
      //console.log('Reset State');
      this.setState({
        activeUser: null,
        loadingLogin: true
      })
      localStorage.setItem('activeUser', false);
      localStorage.setItem('wasReload', false);
      localStorage.setItem('wasReloadJew', false);
      localStorage.setItem('wasReloadArmor', false);
    }

    /* if (accountName !== !this.state.accountName) {
       this.setState({ accountName: accountName }, this.updateAccountName);
     }
 */
    if (activeUser && !localStorage.getItem('activeUser')) {
      localStorage.setItem('activeUser', true);
    }


  }

  componentDidMount() {
    //this.updateAccountName();
    localStorage.setItem('wasReload', true);
    localStorage.setItem('wasReloadJew', true);
    localStorage.setItem('wasReloadArmor', true);
    //console.log(22222);
    //this.getGameBalance();


    if (!localStorage.getItem('rpc')) {
      localStorage.setItem('rpc', 'wax.pink.gg');
    } else {
      this.setState({
        activeNoda: localStorage.getItem('rpc')
      });
    }

    if (!localStorage.getItem('rpcA')) {
      localStorage.setItem('rpcA', 'wax.api.atomicassets.io');
    } else {
      this.setState({
        activeNodaAtomic: localStorage.getItem('rpcA')
      });
    }

  }

  checkError() {
    this.setState({
      checkStatus: true
    });
  }

  addCheckBox(e) {
    if (e.target.checked) {
      this.setState({
        checkedTerm: true,
        checkStatus: false
      })
    }
    else {
      this.setState({
        checkedTerm: false
      })
    }

  }

  async updateAccountName() {
    try {
      const accountName = this.props.ual?.activeUser?.accountName;

      if (accountName == undefined) {
        this.setState({ accountName: accountName, loadingUpdate: false }, this.updateAccountBalance);
      } {
        this.setState({ accountName: accountName, loadingUpdate: true }, this.updateAccountBalance);
      }

    } catch (e) {
      console.warn(e);
    }
  }

  async updateAccountBalance() {
    try {
      const { ual: { activeUser } } = this.props;
      var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);

      const account = this.props?.ual?.activeUser?.accountName;
      var accountBalance = account?.core_liquid_balance;
      accountBalance = (parseFloat(accountBalance).toFixed(4));

      if (accountBalance == "NaN") {
        accountBalance = 0;
      }

      var accountBalanceBAM = await rpcc.get_currency_balance(process.env.REACT_APP_TOKEN, activeUser.accountName, process.env.REACT_APP_VAL);
      accountBalanceBAM = (parseFloat(accountBalanceBAM).toFixed(4));
      if (accountBalanceBAM == "NaN") {
        accountBalanceBAM = 0;
      }
      // this.setState({ accountBalance : accountBalance })
      // this.setState({ accountBalanceBAM : accountBalanceBAM })
      this.props.setBalance(accountBalance, accountBalanceBAM);
    } catch (e) {
      console.warn(e)
      setTimeout(() => {
        this.updateAccountBalance();
        if (numRpc == 8) {
          numRpc = 0;
        } else {
          numRpc++;
        }
      }, 1000);
    }
  }

  async getGameBalance() {
    try {
      const accountName = this.props.ual.activeUser.accountName;
      // let rpcc;
      // if (this.props.ual.activeUser.rpc != undefined) {
      //   rpcc = this.props.ual.activeUser.rpc
      // } else if (this.props.ual.activeUser.rpc == undefined && this.props.ual.activeUser.wax) {
      //   rpcc = this.props.ual.activeUser.wax.rpc
      // } else {
      //   rpcc = new JsonRpc(localStorage.getItem('rpc'));
      // }

      var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);

      const results = await rpcc.get_table_rows({
        "json": true,
        "code": process.env.REACT_APP_CONTRACT,    // contract who owns the table
        "scope": process.env.REACT_APP_CONTRACT,   // scope of the table
        "table": "usersnew",    // name of the table as specified by the contract abi
        "limit": 1,
        lower_bound: accountName,
        upper_bound: accountName,
        "index_position": 1,
        reverse: false,
        show_payer: false
      }).then(result => {
        const originRowUser = result;
        const resultRow = (result.rows[0].allsum / 10000).toFixed(4);

        if (resultRow) {
          //console.log('nooooooooooooooooooo');
          this.props.setBalanceBam(resultRow);

          this.setState({
            ...this.state,
            allUserInfo: result.rows[0]
          });
        }


      });



    } catch (e) {
      //console.log(e.message)
      console.warn(e)
      // this.setState({
      //   hasError: true,
      //   errorText: e.message,
      // });
      console.log('\nCaught exception: ' + e);
      setTimeout(() => {
        this.getGameBalance();
        if (numRpc == 8) {
          numRpc = 0;
        } else {
          numRpc++;
        }
      }, 1000);
    }

  }



  async getTavernInfo() {
    try {
      // let rpcc;
      // if (this.props.ual.activeUser.rpc != undefined) {
      //   rpcc = this.props.ual.activeUser.rpc
      // } else if (this.props.ual.activeUser.rpc == undefined && this.props.ual.activeUser.wax) {
      //   rpcc = this.props.ual.activeUser.wax.rpc
      // } else {
      //   rpcc = new JsonRpc(localStorage.getItem('rpc'));
      // }
      var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);

      //console.log("rpcc app tavern", rpcc);
      await rpcc?.get_table_rows({
        "json": true,
        "code": process.env.REACT_APP_CONTRACT,    // contract who owns the table
        "scope": process.env.REACT_APP_CONTRACT,   // scope of the table
        "table": "tavern",    // name of the table as specified by the contract abi
        "limit": 1,
        lower_bound: 1,
        upper_bound: 1,
        /*"index_position": 1,
        reverse: false, 
        show_payer: false*/
      }).then(tavern => {

        //console.log('tavern', tavern);

        if (tavern.rows[0]) {

          this.props.setTavernInfo(tavern.rows[0]);
        }
      });


    } catch (e) {
      //console.log(e.message)
      console.warn(e)
      // this.setState({
      //   hasError: true,
      //   errorText: e.message,
      // });
      console.log('\nCaught exception: ' + e);
      setTimeout(() => {
        this.getTavernInfo();
        if (numRpc == 8) {
          numRpc = 0;
        } else {
          numRpc++;
        }
      }, 1000);
    }
  }

  removeAddBam(addOrRem, sum, from) {
    this.props.changeBalance(addOrRem, sum, from);
  }



  openQuest() {

    this.setState({
      ...this.state,
      openQuest: !this.state.openQuest,
    });
    if (this.props.quest.quest1) {
      this.props.changeTabs(0);
    } else if (this.props.quest.quest2) {
      this.props.changeTabs(1);
    }
  };

  hideQuestBt(event) {


    if (event) {
      this.setState({
        ...this.state,
        hideQuestBt: true,
      });
    } else {
      this.setState({
        ...this.state,
        hideQuestBt: false,
      });
    }
  };



  render() {
    const isLoginUserLocal = localStorage.getItem('activeUser');
    return (

      <div className="App new-years1">
        {/* {
        this.state.hideQuestBt && this.props.quest && !this.props.quest.questdone1 && this.props.quest.quest1 && this.props.tavern.quest1 != 0 || 
        this.state.hideQuestBt && this.props.quest && !this.props.quest.questdone2 && this.props.quest.quest2 && this.props.tavern.quest2 != 0  || 
        this.state.hideQuestBt && this.props.quest && !this.props.quest.questdone3 && this.props.quest.quest3 && this.props.tavern.quest3 != 0  || 
        this.state.hideQuestBt && this.props.quest && !this.props.quest.questdone4 && this.props.quest.quest4 && this.props.tavern.quest4 != 0  || 
        this.state.hideQuestBt && this.props.quest && !this.props.quest.questdone5 && this.props.quest.quest5 && this.props.tavern.quest5 != 0  || 
        this.state.hideQuestBt && this.props.quest && !this.props.quest.questdone6 && this.props.quest.quest6 && this.props.tavern.quest6 != 0  || 
        this.state.hideQuestBt && this.props.quest && !this.props.quest.questdone7 && this.props.quest.quest7 && this.props.tavern.quest7 != 0  || 
        this.state.hideQuestBt && this.props.quest && !this.props.quest.questdone8 && this.props.quest.quest8 && this.props.tavern.quest8 != 0  || 
        this.state.hideQuestBt && this.props.quest && !this.props.quest.questdone9 && this.props.quest.quest9 && this.props.tavern.quest9 != 0  || 
        this.state.hideQuestBt && this.props.quest && !this.props.quest.questdone10 && this.props.quest.quest10 && this.props.tavern.quest10 != 0  || 
        this.state.hideQuestBt && this.props.quest && !this.props.quest.questdone11 && this.props.quest.quest11 && this.props.tavern.quest11 != 0  || 
        this.state.hideQuestBt && this.props.quest && !this.props.quest.questdone12 && this.props.quest.quest12 && this.props.tavern.quest12 != 0 
        ?
        <div className={`show-quest-bt ${this.state.openQuest ? "active" : ""}`} onClick={() => this.openQuest()}>
          <span></span>
        </div>
        : null } */}

        {/* <div className="snowflakes"><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i>
          <i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i>
          <i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i>
          <i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i>
          <i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i>
          <i></i><i></i><i></i><i></i><i></i><i></i><i></i></div> */}
        {this.state.activeUser && isLoginUserLocal ?

          <BrowserRouter>

            {/* {this.state.hideQuestBt && this.props.quest && this.state.openQuest && <TavernModal {...this.props} /> } */}
            <Switch>
              <Route exact path="/" >
                <Redirect to="/my-heroes" />

              </Route>
              <Route exact path="/adventures">
                <AdventuresComponent {...this.props} updateBalance={this.removeAddBam} allUserInfo={this.state.allUserInfo} />
              </Route>
              <Route exact path="/alien-adventures">
                <AlienComponent {...this.props} updateBalance={this.removeAddBam} allUserInfo={this.state.allUserInfo} />
              </Route>
              <Route path="/lk/:id">
                <LKComponent {...this.props} updateBalance={this.removeAddBam} allUserInfo={this.state.allUserInfo} />
              </Route>
              <Route path="/lk-alien/:id">
                <LKAlienComponent {...this.props} updateBalance={this.removeAddBam} allUserInfo={this.state.allUserInfo} />
              </Route>
              <Route exact path="/my-heroes" >
                <HeropageComponent {...this.props} updateBalance={this.removeAddBam} />
              </Route>
              {/* <Route exact path="/info" >
                <StakingComponent {...this.props} />
              </Route> */}
              {/* <Route exact path="/leaderboard-bam" >
                <StatisticsComponent {...this.props} />
              </Route>
              <Route exact path="/leaderboard-items" >
                <StatisticsResComponent {...this.props} />
              </Route> */}
              <Route exact path="/leaderboard" >
                <StatisticsEventComponent {...this.props} updateBalance={this.removeAddBam} />
              </Route>
              <Route exact path="/alien-leaderboard" >
                <AlienLeaderboardComponent {...this.props} updateBalance={this.removeAddBam} />
              </Route>
              <Route exact path="/rent" >
                <RentComponent {...this.props} updateBalance={this.removeAddBam} />
              </Route>
              <Route exact path="/shop" >
                <ShopComponent {...this.props} updateBalance={this.removeAddBam} activeUser={this.state.activeUser} />
              </Route>
              <Route exact path="/craft" >
                <CraftComponentFirst {...this.props} updateBalance={this.removeAddBam} />
              </Route>
              <Route exact path="/craft-weapon" >
                <CraftComponentWeapon {...this.props} updateBalance={this.removeAddBam} />
              </Route>
              <Route exact path="/craft-jewelry" >
                <CraftComponentJewelry {...this.props} updateBalance={this.removeAddBam} />
              </Route>
              <Route exact path="/craft-weapon-page" >
                <CraftPageComponentWeapon {...this.props} updateBalance={this.removeAddBam} />
              </Route>
              <Route exact path="/craft-weapon-page/:id" >
                <CraftPageComponentWeapon {...this.props} updateBalance={this.removeAddBam} />
              </Route>
              <Route exact path="/craft-armor-page/:id" >
                <CraftPageComponentArmor {...this.props} updateBalance={this.removeAddBam} />
              </Route>
              <Route exact path="/craft-armor-page" >
                <CraftPageComponentArmor {...this.props} updateBalance={this.removeAddBam} />
              </Route>
              <Route exact path="/upgrade-weapon-page" >
                <UpgradePageComponentWeapon {...this.props} updateBalance={this.removeAddBam} />
              </Route>
              <Route exact path="/craft-jewelry-page" >
                <CraftPageComponentJewelry {...this.props} updateBalance={this.removeAddBam} />
              </Route>
              <Route exact path="/craft-jewelry-page/:id" >
                <CraftPageComponentJewelry {...this.props} updateBalance={this.removeAddBam} />
              </Route>
              <Route exact path="/upgrade-jewelry-page" >
                <UpgradePageComponentJewelry {...this.props} updateBalance={this.removeAddBam} />
              </Route>
              <Route exact path="/upgrade-armor-page" >
                <UpgradePageComponentArmor {...this.props} updateBalance={this.removeAddBam} />
              </Route>
              {/* <Route exact path="/calc" >
                <CalculatorComponent {...this.props} />
              </Route> */}
              <Route exact path="/referrals" >
                <ReferalComponent {...this.props} updateBalance={this.removeAddBam} />
              </Route>
              {/* <Route exact path="/achiv" >
                <AchivComponent {...this.props} />
              </Route> */}
              {/* <Route exact path="/guard" >
                <GuardComponent {...this.props} />
              </Route> */}
              <Route exact path="/inventory" >
                <InventoryComponent {...this.props} updateBalance={this.removeAddBam} activeUser={this.state.activeUser} />
              </Route>
              <Route exact path="/exchange" >
                <ExchangerComponent {...this.props} updateBalance={this.removeAddBam} />
              </Route>
              <Route exact path="/artifacts" >
                <ArtefactsComponent {...this.props} updateBalance={this.removeAddBam} />
              </Route>
              <Route exact path="/tavern" >
                <TavernComponent {...this.props}  {...this.state} getQwestInfo={this.getQwestInfo} hideQuestBt={this.hideQuestBt} updateBalance={this.removeAddBam} />
              </Route>
              <Route exact path="/auction/:id" >
                <AuctionComponent {...this.props} updateBalance={this.removeAddBam} />
              </Route>
              <Route exact path="/boss" >
                <Boss {...this.props}  {...this.state} getQwestInfo={this.getQwestInfo} hideQuestBt={this.hideQuestBt} updateBalance={this.removeAddBam} activeUser={this.state.activeUser} />
              </Route>
              <Route exact path="/burning-festival" >
                <Burn {...this.props}  {...this.state} getQwestInfo={this.getQwestInfo} hideQuestBt={this.hideQuestBt} updateBalance={this.removeAddBam} activeUser={this.state.activeUser} />
              </Route>
              <Route exact path="/skills" >
                <Skills {...this.props}  {...this.state} getQwestInfo={this.getQwestInfo} hideQuestBt={this.hideQuestBt} updateBalance={this.removeAddBam} activeUser={this.state.activeUser} />
              </Route>
            </Switch>
          </BrowserRouter>
          :
          <Login {...this.props}
            checkedTerm={this.state.checkedTerm}
            checkStatus={this.state.checkStatus}
            checkError={this.checkError}
            addCheckBox={this.addCheckBox}
          />}
      </div>

    );
  }
}


let mapStateToProps = (state) => ({
  balanceBam: state.headReducer.balanceBam,
  balanceWax: state.headReducer.balanceWax,
  balanceGame: state.headReducer.balanceGame,
  //quests: state.questReduser.quests,
  allresgroup: state.questReduser.allresgroup,
  activeTab: state.questReduser.activeTab,
  canComplate: state.questReduser.canComplate,
  tavern: state.questReduser.tavern,
  allTmpl: state.questReduser.allTmpl
});

export default connect(mapStateToProps, {
  setBalance, setBalanceBam, changeBalance, setQuestInfo, questitems, changeTabs, canComplateQuest, setTavernInfo
})(App);
