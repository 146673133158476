import React from 'react';

const NeedForQuestNew = (props) => {
   
    var bam;
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
      ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
        return props.balance >= item.value;
    });
    bam = item ? (props.balance / item.value).toFixed(1).replace(rx, "$1") + item.symbol : "0";
    
    return (<>
        {props.activeQuest.map((item, index) => {

            var done = false;
            if(
            item.type == "repareweapon" && item.nameTable == "repare4" && props.infoquest[item.nameTable] != undefined &&  props.infoquest[item.nameTable][0] >= item.num || 
            item.type == "reparejew" && item.nameTable == "repare4" && props.infoquest[item.nameTable] != undefined && props.infoquest[item.nameTable][1] >= item.num || 
            item.type == "reparearmor" && item.nameTable == "repare4" && props.infoquest[item.nameTable] != undefined && props.infoquest[item.nameTable][2] >= item.num ||
            item.type == "getrank" && props.alluserinfo.maxrank != undefined && props.alluserinfo.maxrank >= item.num && props.infoquest["quest" + (parseInt(props.activeTab) + 1)] == 1 ||
            item.type == "buyoneslot" && props.alluserinfo.max_slots != undefined && props.alluserinfo.max_slots == 20 && props.infoquest["quest" + (parseInt(props.activeTab) + 1)]== 1 || props.infoquest[item.nameTable] >= item.num
            
            ) {
                done = true
            }
            
            return (
            
                <div key={index} className="one-bring">
                <div className={`one-bring-thumb ${done ? "done" : "notdone"}`}>
                    <div className="one-bring-thumb-in">
                        {item.type == "lvlup" ?
                            <picture>
                                <source srcSet={`./images/questlvl.png`} type="image/webp" />
                                <source srcSet={`./images/questlvl.png`} type="image/png" /> 
                                <img src={`./images/questlvl.png`} alt="" />
                            </picture>
                        :
                        item.type == "eat" ?
                            <picture>
                                <source srcSet={`./images/questeat.png`} type="image/webp" />
                                <source srcSet={`./images/questeat.png`} type="image/png" /> 
                                <img src={`./images/questeat.png`} alt="" />
                            </picture>
                        :
                        item.type == "advbest" ?
                            <picture>
                                <source srcSet={`./images/quest100.png`} type="image/webp" />
                                <source srcSet={`./images/quest100.png`} type="image/png" /> 
                                <img src={`./images/quest100.png`} alt="" />
                            </picture>
                        :
                        item.type == "advbad" ?
                            <picture>
                                <source srcSet={`./images/questbad.png`} type="image/webp" />
                                <source srcSet={`./images/questbad.png`} type="image/png" /> 
                                <img src={`./images/questbad.png`} alt="" />
                            </picture>
                        :
                        item.type == "craftweapon" ?
                            <picture>
                                <source srcSet={`./images/questcraftweapon.png`} type="image/webp" />
                                <source srcSet={`./images/questcraftweapon.png`} type="image/png" /> 
                                <img src={`./images/questcraftweapon.png`} alt="" />
                            </picture>
                        :
                        item.type == "advpandabam" ?
                            <picture>
                                <source srcSet={`./images/questbamweapon.png`} type="image/webp" />
                                <source srcSet={`./images/questbamweapon.png`} type="image/png" /> 
                                <img src={`./images/questbamweapon.png`} alt="" />
                            </picture>
                        :
                        item.type == "advpandares" ?
                            <picture>
                                <source srcSet={`./images/questadvresweapon.png`} type="image/webp" />
                                <source srcSet={`./images/questadvresweapon.png`} type="image/png" /> 
                                <img src={`./images/questadvresweapon.png`} alt="" />
                            </picture>
                        :
                        item.type == "advpandagem" ?
                            <picture>
                                <source srcSet={`./images/questadvgemsweapon.png`} type="image/webp" />
                                <source srcSet={`./images/questadvgemsweapon.png`} type="image/png" /> 
                                <img src={`./images/questadvgemsweapon.png`} alt="" />
                            </picture>
                        :
                        item.type == "getwood" ?
                            <picture>
                                <source srcSet={`./images/questadvwood.png`} type="image/webp" />
                                <source srcSet={`./images/questadvwood.png`} type="image/png" /> 
                                <img src={`./images/questadvwood.png`} alt="" />
                            </picture>
                        :
                        item.type == "getgems" ?
                            <picture>
                                <source srcSet={`./images/questadvgemsweapon.png`} type="image/webp" />
                                <source srcSet={`./images/questadvgemsweapon.png`} type="image/png" /> 
                                <img src={`./images/questadvgemsweapon.png`} alt="" />
                            </picture>
                        :
                        item.type == "buyoneslot" ?
                            <picture>
                                <source srcSet={`./images/questbuyslot.png`} type="image/webp" />
                                <source srcSet={`./images/questbuyslot.png`} type="image/png" /> 
                                <img src={`./images/questbuyslot.png`} alt="" />
                            </picture>
                        :
                        item.type == "getrank" ?
                            <picture>
                                <source srcSet={`./images/questrank.png`} type="image/webp" />
                                <source srcSet={`./images/questrank.png`} type="image/png" /> 
                                <img src={`./images/questrank.png`} alt="" />
                            </picture>
                        :
                        item.type == "questchange" ?
                            <picture>
                                <source srcSet={`./images/questchangetit.png`} type="image/webp" />
                                <source srcSet={`./images/questchangetit.png`} type="image/png" /> 
                                <img src={`./images/questchangetit.png`} alt="" />
                            </picture>
                        :
                        item.type == "getset" ?
                            <picture>
                                <source srcSet={`./images/questset.png`} type="image/webp" />
                                <source srcSet={`./images/questset.png`} type="image/png" /> 
                                <img src={`./images/questset.png`} alt="" />
                            </picture>
                        :
                        item.type == "repareweapon" ?
                            <picture>
                                <source srcSet={`./images/questrepareweapon.png`} type="image/webp" />
                                <source srcSet={`./images/questrepareweapon.png`} type="image/png" /> 
                                <img src={`./images/questrepareweapon.png`} alt="" />
                            </picture>
                        :
                        item.type == "reparejew" ?
                            <picture>
                                <source srcSet={`./images/questreparejew.png`} type="image/webp" />
                                <source srcSet={`./images/questreparejew.png`} type="image/png" /> 
                                <img src={`./images/questreparejew.png`} alt="" />
                            </picture>
                        :
                        item.type == "reparearmor" ?
                            <picture>
                                <source srcSet={`./images/questreparearm.png`} type="image/webp" />
                                <source srcSet={`./images/questreparearm.png`} type="image/png" /> 
                                <img src={`./images/questreparearm.png`} alt="" />
                            </picture>
                        :

                            null
                        }
                    </div>
                    
                    <div className="one-bring-num">
                        
                        {//props.infoquest && props.infoquest != undefined && item.nameTable
                        }
                        {props.infoquest && props.infoquest[item.nameTable] != undefined ? 
                        item.type == "repareweapon" && item.nameTable == "repare4"  ? props.infoquest[item.nameTable][0] 
                        : item.type == "reparejew" && item.nameTable == "repare4" ? props.infoquest[item.nameTable][1]
                        : item.type == "reparearmor" && item.nameTable == "repare4" ? props.infoquest[item.nameTable][2]
                        : props.infoquest[item.nameTable] > item.num ? item.num : props.infoquest[item.nameTable] : "0"}/{item.num}

                    </div>
                    <div className="one-shafl-prod-info tooltip">
                        <div className="cust-tooltip right">
                            <div className="tool-arrow"></div>
                            <div className="tool-wrap">
                                <div className="tool-wrap-1">
                                    <div className="tool-wrap-2">
                                        <div className="tool-body" dangerouslySetInnerHTML={{__html: item.tit}}>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            );
            /*}*/
        })
        }
        


        
    </>);

}

export default NeedForQuestNew;