import React from 'react';

const InventoryGem = (props) => {
    const ITEMS_PER_PAGE = 20;
    const startIdx = (props.activePage - 1) * ITEMS_PER_PAGE;
    const endIdx = startIdx + ITEMS_PER_PAGE;

    const inventArray = props.allinvent ? Object.values(props.allinvent) : [];

    return (
        <div className="burn-wrap">
            <div className="burn-wrap-in">
                {inventArray.length
                    ? inventArray.slice(startIdx, endIdx).map((group, index) => {
                        const item = group.assets[0]; // Берем первый актив из группы
                        const count = group.count; // Получаем количество из поля count в каждой группе
                        item.activeCount = count;
                        item.allItems = group.assets.length;

                        return (
                            <div key={index} className={`one-invent-prod Common active ${props.manyItems >= 50 && group.count == 0 && "disabled"}`}>
                                <div className="one-invent-prod-info tooltip">
                                    <div className="cust-tooltip right">
                                        <div className="tool-arrow"></div>
                                        <div className="tool-wrap">
                                            <div className="tool-wrap-1">
                                                <div className="tool-wrap-2">
                                                    <div className="tool-body">
                                                        <span>{item.name}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="one-invent-prod-thumb">
                                    {props.manyItems < 50 || group.count != 0 ? <div className='linkinv' onClick={() => props.showCloseModal('open', item)} ></div> : null}
                                    <div className="value-inv-eat">
                                        {group.count}/{group.assets.length}
                                    </div>
                                    <span></span>
                                    <div className="one-invent-prod-thumb-in">
                                        <picture>
                                            <source srcSet={`./images/jew/inventory/${item.template.template_id}.webp`} type="image/webp" />
                                            <source srcSet={`./images/jew/inventory/${item.template.template_id}.png`} type="image/png" />
                                            <img src={`./images/jew/inventory/${item.template.template_id}.png`} alt="" />
                                        </picture>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                    : <></>}
            </ div>
        </div>
    );
}

export default InventoryGem;
