import React from "react";
import Menu from '../../components/Menu';

import { connect } from "react-redux";
import {
  eatlist,
  wasLoadinEat,
  wasBuyingEat,
} from "../../GlobalState/shop-reducer";
import {
  weaponlist,
  statusLoadinWeapon,
  addWeaponWhenRemove,
  addWeaponToPanda,
} from "../../GlobalState/weapon-reducer";

import ErrorComponent from "../Error/ErrorComponent";

import { JsonRpc } from "eosjs";
import "./../../css/invent.scss";

import inventtop from "../../images/invent/invent-top.png";
import shafltopmob from "../../images/invent/repeat-bg.png";
import inventbot from "../../images/invent/invent-bot.png";
import inventtopw from "../../images/invent/invent-top.webp";
import shafltopmobw from "../../images/invent/repeat-bg.webp";
import inventbotw from "../../images/invent/invent-bot.webp";

import musicOpen from "./../../music/open.mp3";
import musicClose from "./../../music/close.mp3";

import musicError from "./../../music/error.mp3";

import { ExplorerApi } from "atomicassets"; // атомик для списка панд

import InventoryEat from "./Inventory/InventoryEat";
import InventoryRes from "./Inventory/InventoryRes";
import InventoryGem from "./Inventory/InventoryGem";
import InventoryJew from "./Inventory/InventoryJew";
import InventoryWeapon from "./Inventory/InventoryWeapon";
import InventoryArmor from "./Inventory/InventoryArmor";
import InventoryBook from "./Inventory/InventoryBook";
import InventoryZel from "./Inventory/InventoryZel";
import Modal from "./Inventory/Modal";

import getAllSkillBooksClass from '../Get/getAllSkillBooksClass';

const fetch = require("node-fetch");
const api = new ExplorerApi(
  "https://" + localStorage.getItem('rpcA'),
  "atomicassets",
  { fetch, rateLimit: 4 }
);

// это транзакция отправки ИЗ
const cardTransferOut = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "transferout",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        from: "",
        to: "",
        asset_id: [],
      },
    },
  ],
};

class InventoryComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shop: {
        // EAT
        weapon: [
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          // {
          //     rare: "common",
          //     element: "fire",
          //     img: "war-1-1",
          //     imgBig: "",
          //     name: "pickled bamboo",
          //     subtit: "Part 1 of 3",
          //     descr: "descr about this eat",
          //     price: 2.99
          // },
          // {
          //     rare: "uncommon",
          //     element: "fire",
          //     img: "war-1-2",
          //     imgBig: "",
          //     name: "bamboo with mushrooms",
          //     subtit: "Part 1 of 3",
          //     descr: "descr about this eat",
          //     price: 4.9
          // },
          // {
          //     rare: "rare",
          //     element: "fire",
          //     img: "war-1-3",
          //     imgBig: "",
          //     name: "ramen",
          //     descr: "descr about this eat",
          //     price: 4
          // },
          // {
          //     rare: "epic",
          //     element: "fire",
          //     img: "war-1-4",
          //     imgBig: "",
          //     name: "rice with bamboo",
          //     subtit: "Part 1 of 3",
          //     descr: "descr about this eat",
          //     price: 4.999
          // },
          // {
          //     rare: "legendary",
          //     element: "wind",
          //     img: "war-2-1",
          //     imgBig: "",
          //     name: "dumplings with bamboo",
          //     subtit: "Part 1 of 3",
          //     descr: "descr about this eat",
          //     price: 44.999
          // },
          // {
          //     rare: "mythic",
          //     element: "wind",
          //     img: "war-2-2",
          //     imgBig: "",
          //     name: "stake with bamboo",
          //     subtit: "Part 1 of 3",
          //     descr: "descr about this eat",
          //     price: 500
          // } ,
          // {
          //     rare: "mythic",
          //     element: "wind",
          //     img: "war-2-3",
          //     imgBig: "",
          //     name: "stake with bamboo",
          //     subtit: "Part 1 of 3",
          //     descr: "descr about this eat",
          //     price: 4.999
          // } ,
          // {
          //     rare: "mythic",
          //     element: "wind",
          //     img: "war-2-4",
          //     imgBig: "",
          //     name: "stake with bamboo",
          //     subtit: "Part 1 of 3",
          //     descr: "descr about this eat",
          //     price: 4.999
          // },
          // {
          //     rare: "legendary",
          //     element: "whater",
          //     img: "war-3-1",
          //     imgBig: "",
          //     name: "dumplings with bamboo",
          //     subtit: "Part 1 of 3",
          //     descr: "descr about this eat",
          //     price: 4.999
          // },
          // {
          //     rare: "mythic",
          //     element: "whater",
          //     img: "war-3-2",
          //     imgBig: "",
          //     name: "stake with bamboo",
          //     subtit: "Part 1 of 3",
          //     descr: "descr about this eat",
          //     price: 4.999
          // },
          // {
          //     rare: "mythic",
          //     element: "whater",
          //     img: "war-3-3",
          //     imgBig: "",
          //     name: "stake with bamboo",
          //     subtit: "Part 1 of 3",
          //     descr: "descr about this eat",
          //     price: 4.999
          // } ,
          // {
          //     rare: "mythic",
          //     element: "whater",
          //     img: "war-3-4",
          //     imgBig: "",
          //     name: "stake with bamboo",
          //     subtit: "Part 1 of 3",
          //     descr: "descr about this eat",
          //     price: 4.999
          // },
          // {
          //     rare: "mythic",
          //     element: "whater",
          //     img: "war-3-3",
          //     imgBig: "",
          //     name: "stake with bamboo",
          //     subtit: "Part 1 of 3",
          //     descr: "descr about this eat",
          //     price: 4.999
          // },
        ],
        // END EAT
        // EAT
        eat: [
          {
            type: "eat",
            rare: "common",
            rareNumber: 0,
            tooltip: "common food",
            img: "eat-common",
            imgBig: "eat-common-nft",
            name: "pickled bamboo",
            subtit: "sub title",
            descr: "descr about this eat",
            price: 0.11,
          },
          {
            type: "eat",
            rare: "common",
            rareNumber: 0,
            tooltip: "common food",
            img: "eat-common",
            imgBig: "eat-common-nft",
            name: "pickled bamboo",
            subtit: "sub title",
            descr: "descr about this eat",
            price: 0.11,
          },
          {
            type: "eat",
            rare: "uncommon",
            rareNumber: 1,
            tooltip: "uncommon food",
            img: "eat-uncommon",
            imgBig: "eat-uncommon-nft",
            name: "bamboo with mushrooms",
            subtit: "sub title",
            descr: "descr about this eat",
            price: 0.22,
          },
          {
            type: "eat",
            rare: "rare",
            rareNumber: 2,
            tooltip: "rare food",
            img: "eat-rare",
            imgBig: "eat-rare-nft",
            name: "ramen",
            subtit: "sub title",
            descr: "descr about this eat",
            price: 0.6,
          },
          {
            type: "eat",
            rare: "epic",
            rareNumber: 3,
            tooltip: "epic food",
            img: "eat-epic",
            imgBig: "eat-epic-nft",
            name: "rice with bamboo",
            subtit: "sub title",
            descr: "descr about this eat",
            price: 1.0,
          },
          {
            type: "eat",
            rare: "legendary",
            rareNumber: 4,
            tooltip: "legendary food",
            img: "eat-legend",
            imgBig: "eat-legend-nft",
            name: "dumplings with bamboo",
            subtit: "sub title",
            descr: "descr about this eat",
            price: 4.0,
          },
          {
            type: "eat",
            rare: "mythic",
            rareNumber: 5,
            tooltip: "mythic food",
            img: "eat-mythic",
            imgBig: "eat-mythic-nft",
            name: "stake with bamboo",
            subtit: "sub title",
            descr: "descr about this eat",
            price: 12.0,
          },
        ],
        // END EAT
        // EAT
        zel: [
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
        ],
        // END EAT
        // EAT
        res: [
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
        ],
        // END EAT
        // EAT
        gem: [
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
        ],
        // END EAT
        // EAT
        jew: [
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
        ],
        // END EAT
        // EAT
        armor: [
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
          {
            type: "",
            rare: "",
            rareNumber: "",
            tooltip: "",
            img: "",
            imgBig: "",
            name: "",
            subtit: "",
            descr: "",
            price: "",
          },
        ],
        // END EAT
      },
      shopitem: null,
      modalItemInfo: null,
      activeTab: "weapon",
      activePage: 1,
      countPage: "",
      modal: {
        showModalStatus: false,
        showAnimModal: false,
        item: {},
        countModal: 1,
      },
      modalEat: {
        openModal: false,
        slotIndex: "",
        assetid: "",
        eatid: "",
        loadingChooseEat: false,
        //alleat: {},
        style: {
          common: "",
          uncommon: "",
          rare: "",
          epic: "",
          legendary: "",
          mythic: "",
        },
      },
      modalRes: {
        openModal: false,
        slotIndex: "",
        assetid: "",
        eatid: "",
        loadingChooseEat: false,
        //alleat: {},
        style: {
          common: "",
          uncommon: "",
          rare: "",
          epic: "",
          legendary: "",
          mythic: "",
        },
      },
      allweapongroup: {},
      allresgroup: {},
      allgemgroup: {},
      alljewgroup: {},
      allarmorgroup: {},
      resInGame: null
    };

    this.clearError = this.clearError.bind(this);
    this.showCloseModal = this.showCloseModal.bind(this);

    this.audioOpen = new Audio(musicOpen);
    this.audioClose = new Audio(musicClose);

    this.audioMusicError = new Audio(musicError);
  }

  componentDidMount() {
    this.userCheck();
    if (!this.props.wasbay && !this.props.statuseat) {
      this.GetPandaEatList();
    } else {
      this.GetPandaEatList();
    }
    this.GetPandaWeaponList();
    this.GetPandaResList();
    this.GetPandaJewList();
    this.GetPandaGemList();
    this.GetPandaArmorList();

    (async () => {

      let resInGame;
      try {
        resInGame = await getAllSkillBooksClass(this.props.activeUser.accountName);
      } catch (error) {
        console.error('Error fetching medals:', error);
        resInGame = []; // return an empty array if there is an error
      }
      this.setState({
        resInGame: resInGame
      });

    })();

  }



  async GetPandaWeaponList() {
    (async () => {
      try {
        const userName = this.props.ual.activeUser.accountName;
        const activeUser = this.props.ual.activeUser;
        const asset = await api
          .getAssets({
            limit: 1000,
            owner: userName,
            collection_name: "nftpandawaxp",
            schema_name: ["weapon" /*, "promo"*/],
          })
          .then((assets) => {
            let newData = {};
            Object.entries(assets).forEach((pair) => {
              let [key, value] = pair;
              //console.log(value.template.template_id);
              if (!newData[value.template.template_id])
                newData[value.template.template_id] = [];
              newData[value.template.template_id].push(value);
            });
            //console.log(newData);
            var arreyObj = Object.values(newData);

            //console.log("arreyObj.length", arreyObj.length);

            this.setState({
              ...this.state,
              allweapongroup: arreyObj,
              countPage: arreyObj.length / 12,
            });

            this.props.weaponlist(assets);
            this.props.statusLoadinWeapon(true);
          });
      } catch (e) {
        //console.log(e.message)
        //console.warn(e)
        this.setState({
          ...this.state,
          modalAllWeapon: {
            ...this.state.modalAllWeapon,
            loadingWeapon: false,
          },
        });
        this.setState({
          hasError: true,
          errorText: e.message,
        });
        this.props.statusLoadinWeapon(false);
        console.log("\nCaught exception: " + e);
        if (localStorage.getItem("sound") === "true") {
          this.audioMusicError.currentTime = 0;
          this.audioMusicError.volume = 0.1;
          this.audioMusicError.play();
        }
      }
    })();
  }

  async GetPandaResList() {
    (async () => {
      try {
        const userName = this.props.ual.activeUser.accountName;
        const activeUser = this.props.ual.activeUser;
        const asset = await api
          .getAssets({
            limit: 1000,
            owner: userName,
            collection_name: "nftpandawaxp",
            schema_name: ["resources" /*, "promo"*/],
          })
          .then((assets) => {
            let newData = {};
            Object.entries(assets).forEach((pair) => {
              let [key, value] = pair;
              //console.log(value.template.template_id);
              if (!newData[value.template.template_id])
                newData[value.template.template_id] = [];
              newData[value.template.template_id].push(value);
            });
            //console.log(newData);
            var arreyObj = Object.values(newData);

            //console.log("arreyObj.length", arreyObj.length);

            this.setState({
              ...this.state,
              allresgroup: arreyObj,
              countPage: arreyObj.length / 12,
            });

            // this.props.weaponlist(assets);
            // this.props.statusLoadinWeapon(true);
          });
      } catch (e) {
        //console.log(e.message)
        //console.warn(e)
        this.setState({
          ...this.state,
          modalAllWeapon: {
            ...this.state.modalAllWeapon,
            loadingWeapon: false,
          },
        });
        this.setState({
          hasError: true,
          errorText: e.message,
        });
        this.props.statusLoadinWeapon(false);
        console.log("\nCaught exception: " + e);
        if (localStorage.getItem("sound") === "true") {
          this.audioMusicError.currentTime = 0;
          this.audioMusicError.volume = 0.1;
          this.audioMusicError.play();
        }
      }
    })();
  }

  async GetPandaJewList() {
    (async () => {
      try {
        const userName = this.props.ual.activeUser.accountName;
        const activeUser = this.props.ual.activeUser;
        const asset = await api
          .getAssets({
            limit: 1000,
            owner: userName,
            collection_name: "nftpandawaxp",
            schema_name: ["jewelry" /*, "promo"*/],
          })
          .then((assets) => {
            let newData = {};
            Object.entries(assets).forEach((pair) => {
              let [key, value] = pair;
              //console.log(value.template.template_id);
              if (!newData[value.template.template_id])
                newData[value.template.template_id] = [];
              newData[value.template.template_id].push(value);
            });
            //console.log(newData);
            var arreyObj = Object.values(newData);

            //console.log("arreyObj.length", arreyObj.length);

            this.setState({
              ...this.state,
              alljewgroup: arreyObj,
              countPage: arreyObj.length / 12,
            });

            // this.props.weaponlist(assets);
            // this.props.statusLoadinWeapon(true);
          });
      } catch (e) {
        //console.log(e.message)
        //console.warn(e)
        this.setState({
          ...this.state,
          modalAllWeapon: {
            ...this.state.modalAllWeapon,
            loadingWeapon: false,
          },
        });
        this.setState({
          hasError: true,
          errorText: e.message,
        });
        this.props.statusLoadinWeapon(false);
        console.log("\nCaught exception: " + e);
        if (localStorage.getItem("sound") === "true") {
          this.audioMusicError.currentTime = 0;
          this.audioMusicError.volume = 0.1;
          this.audioMusicError.play();
        }
      }
    })();
  }

  async GetPandaGemList() {
    (async () => {
      try {
        const userName = this.props.ual.activeUser.accountName;
        const activeUser = this.props.ual.activeUser;
        const asset = await api
          .getAssets({
            limit: 1000,
            owner: userName,
            collection_name: "nftpandawaxp",
            schema_name: ["gems" /*, "promo"*/],
          })
          .then((assets) => {
            let newData = {};
            Object.entries(assets).forEach((pair) => {
              let [key, value] = pair;
              //console.log(value.template.template_id);
              if (!newData[value.template.template_id])
                newData[value.template.template_id] = [];
              newData[value.template.template_id].push(value);
            });
            //console.log(newData);
            var arreyObj = Object.values(newData);

            //console.log("arreyObj.length", arreyObj.length);

            this.setState({
              ...this.state,
              allgemgroup: arreyObj,
              countPage: arreyObj.length / 12,
            });

            // this.props.weaponlist(assets);
            // this.props.statusLoadinWeapon(true);
          });
      } catch (e) {
        //console.log(e.message)
        //console.warn(e)
        this.setState({
          ...this.state,
          modalAllWeapon: {
            ...this.state.modalAllWeapon,
            loadingWeapon: false,
          },
        });
        this.setState({
          hasError: true,
          errorText: e.message,
        });
        this.props.statusLoadinWeapon(false);
        console.log("\nCaught exception: " + e);
        if (localStorage.getItem("sound") === "true") {
          this.audioMusicError.currentTime = 0;
          this.audioMusicError.volume = 0.1;
          this.audioMusicError.play();
        }
      }
    })();
  }

  async GetPandaArmorList() {
    (async () => {
      try {
        const userName = this.props.ual.activeUser.accountName;
        const activeUser = this.props.ual.activeUser;
        const asset = await api
          .getAssets({
            limit: 1000,
            owner: userName,
            collection_name: "nftpandawaxp",
            schema_name: ["armor" /*, "promo"*/],
          })
          .then((assets) => {
            let newData = {};
            Object.entries(assets).forEach((pair) => {
              let [key, value] = pair;
              //console.log(value.template.template_id);
              if (!newData[value.template.template_id])
                newData[value.template.template_id] = [];
              newData[value.template.template_id].push(value);
            });
            //console.log(newData);
            var arreyObj = Object.values(newData);

            //console.log("arreyObj.length", arreyObj.length);

            this.setState({
              ...this.state,
              allarmorgroup: arreyObj,
              countPage: arreyObj.length / 12,
            });
          });
      } catch (e) {
        //console.log(e.message)
        //console.warn(e)
        this.setState({
          ...this.state,
          modalAllWeapon: {
            ...this.state.modalAllWeapon,
            loadingWeapon: false,
          },
        });
        this.setState({
          hasError: true,
          errorText: e.message,
        });
        this.props.statusLoadinWeapon(false);
        console.log("\nCaught exception: " + e);
        if (localStorage.getItem("sound") === "true") {
          this.audioMusicError.currentTime = 0;
          this.audioMusicError.volume = 0.1;
          this.audioMusicError.play();
        }
      }
    })();
  }

  async GetPandaEatList() {
    (async () => {
      try {
        const activeUser = this.props.ual.activeUser;
        const accountName = await activeUser.getAccountName();
        let rpcc;
        if (this.props.ual.activeUser.rpc !== undefined) {
          rpcc = this.props.ual.activeUser.rpc;
        } else if (
          this.props.ual.activeUser.rpc === undefined &&
          this.props.ual.activeUser.wax
        ) {
          rpcc = this.props.ual.activeUser.wax.rpc;
        } else {
          rpcc = new JsonRpc(localStorage.getItem("rpc"));
        }
        const results = await rpcc.get_table_rows({
          json: true,
          code: process.env.REACT_APP_CONTRACT, // contract who owns the table
          scope: process.env.REACT_APP_CONTRACT, // scope of the table
          table: "eatable", // name of the table as specified by the contract abi
          //"key_type": "i64",
          lower_bound: accountName,
          upper_bound: accountName,
          limit: 1,
          reverse: false,
          show_payer: false,
          index_position: 1,
        });
        if (results.rows.length) {
          const resultRow = results.rows[0].eat_count;

          this.props.eatlist(resultRow);

          this.props.wasLoadinEat(true);
        } else {
          this.props.eatlist([0, 0, 0, 0, 0, 0]);
        }
      } catch (e) {
        //console.log(e.message)
        //console.warn(e)
        this.setState({
          ...this.state,
          modalEat: {
            ...this.state.modalEat,
            /*openModal: false,
                        slotIndex: '',
                        assetid: '',
                        eatid: '',*/
            loadingChooseEat: false,
            /*alleat: assets,*/
          },
        });
        this.setState({
          hasError: true,
          errorText: e.message,
        });
        console.log("\nCaught exception: " + e);
        if (localStorage.getItem("sound") === "true") {
          this.audioMusicError.currentTime = 0;
          this.audioMusicError.volume = 0.1;
          this.audioMusicError.play();
        }
      }
    })();
  }

  async userCheck() {
    try {
      const activeUser = this.props.ual.activeUser;
      const accountName = await activeUser.getAccountName();
      let rpcc;
      if (this.props.ual.activeUser.rpc !== undefined) {
        rpcc = this.props.ual.activeUser.rpc;
      } else if (
        this.props.ual.activeUser.rpc === undefined &&
        this.props.ual.activeUser.wax
      ) {
        rpcc = this.props.ual.activeUser.wax.rpc;
      } else {
        rpcc = new JsonRpc(localStorage.getItem("rpc"));
      }
      const results = await rpcc.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "usersnew", // name of the table as specified by the contract abi
        limit: 1,
        lower_bound: accountName,
        upper_bound: accountName,
        index_position: 1,
        reverse: false,
        show_payer: false,
      });
      const originRowUser = results;
      //console.log(originRowUser);
      let resultRow;
      if (originRowUser.rows.length) {
        resultRow = results.rows[0].inrent;
      } else {
        resultRow = 0;
      }

      if (resultRow === 1) {
        const resultRowName = results.rows[0].rent;
        /*this.GetPandaListOnGame(resultRowName);
                this.GetPandaListOnUser(resultRowName);*/
      } else {
        /*this.GetPandaListOnGame(accountName);
                this.GetPandaListOnUser(accountName);*/
      }
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  async clearError() {
    this.setState({
      errorText: "",
      hasError: false,
    });
  }

  tabsnav(tab) {
    if (tab === 1) {
      this.setState({
        shopitem: this.state.shop.weapon,
        activeTab: "weapon",
        activePage: 1,
        countPage: this.state.allweapongroup.length / 12,
      });
    } else if (tab === 2) {
      this.setState({
        shopitem: this.state.shop.eat,
        activeTab: "eat",
        activePage: 1,
        countPage: this.state.shop.eat.length / 12,
      });
    } else if (tab === 3) {
      this.setState({
        shopitem: this.state.shop.zel,
        activeTab: "zel",
        activePage: 1,
        countPage: this.state.shop.zel.length / 12,
      });
    } else if (tab === 4) {
      this.setState({
        shopitem: this.state.shop.res,
        activeTab: "res",
        activePage: 1,
        countPage: this.state.allresgroup.length / 12,
      });
    } else if (tab === 5) {
      this.setState({
        shopitem: this.state.shop.gem,
        activeTab: "gem",
        activePage: 1,
        countPage: this.state.allgemgroup.length / 12,
      });
    } else if (tab === 6) {
      this.setState({
        shopitem: this.state.shop.jew,
        activeTab: "jew",
        activePage: 1,
        countPage: this.state.alljewgroup.length / 12,
      });
    } else if (tab === 7) {
      this.setState({
        shopitem: this.state.shop.jew,
        activeTab: "armor",
        activePage: 1,
        countPage: this.state.alljewgroup.length / 12,
      });
    } else if (tab === 8) {
      const nonZeroNumbers = this.state.resInGame && this.state.resInGame.books && this.state.resInGame.books.length ? this.state.resInGame.books.filter(number => number !== 0) : [];
      console.log("nonZeroNumbers", nonZeroNumbers);
      this.setState({
        shopitem: this.state.shop.jew,
        activeTab: "book",
        activePage: 1,
        countPage: nonZeroNumbers.length / 12,
      });
    }
  }

  navpage(goto) {
    if (goto === "next") {
      this.setState({
        activePage: this.state.activePage + 1,
      });
    } else {
      this.setState({
        activePage: this.state.activePage - 1,
      });
    }
  }

  showCloseModal(method, item) {
    document.getElementsByTagName("body")[0].classList.add("open-modal");
    if (method === "open") {
      this.setState({
        modal: {
          ...this.state.modal,
          showModalStatus: true,
        },
        modalItemInfo: item,
      });
      setTimeout(() => {
        this.setState({
          modal: {
            ...this.state.modal,
            showAnimModal: true,
          },
        });
      }, 300);
      if (localStorage.getItem("sound") === "true") {
        this.audioOpen.currentTime = 0;
        this.audioOpen.volume = 0.1;
        this.audioOpen.play();
      }
    } else {
      document.getElementsByTagName("body")[0].classList.remove("open-modal");
      this.setState({
        modal: {
          ...this.state.modal,
          showModalStatus: false,
          item: {},
          showAnimModal: false,
          countModal: 1,
        },
        modalItemInfo: null,
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioClose.currentTime = 0;
        this.audioClose.volume = 0.1;
        this.audioClose.play();
      }
    }
  }

  render() {
    return (
      <>
        <Menu  {...this.props.ual} wax={this.props.balanceWax} bam={this.props.balanceBam} game={this.props.balanceGame} updateBalance={this.props.updateBalance} />
        {this.state.hasError ? (
          <ErrorComponent
            errorMessage={this.state.errorText}
            clearError={this.clearError}
          />
        ) : null}

        <main className="invent-page">
          <div className="loading-block"></div>

          <div className="invent">
            <Modal
              modal={this.state.modal}
              showCloseModal={this.showCloseModal}
              modalItemInfo={this.state.modalItemInfo}
            />

            <div className="invent-body">
              <div className="invent-top">
                <picture>
                  <source srcSet={shafltopmobw} type="image/webp" />
                  <source srcSet={shafltopmob} type="image/png" />
                  <img className="hidedesc" src={shafltopmob} alt="" />
                </picture>
                <picture>
                  <source srcSet={inventtopw} type="image/webp" />
                  <source srcSet={inventtop} type="image/png" />
                  <img className="hidemob" src={inventtop} alt="" />
                </picture>

                <div className="invent-tab">
                  <ul>
                    <li
                      className={`${this.state.activeTab === "weapon"
                        ? "weapon active"
                        : "weapon"
                        }`}
                    >
                      <button
                        className={`${this.state.activeTab === "weapon" ? "active" : ""
                          }`}
                        onClick={() => this.tabsnav(1)}
                      ></button>
                    </li>
                    <li
                      className={`${this.state.activeTab === "eat" ? "eat active" : "eat"
                        }`}
                    >
                      <button
                        className={`${this.state.activeTab === "eat" ? "active" : ""
                          }`}
                        onClick={() => this.tabsnav(2)}
                      ></button>
                    </li>
                    <li
                      className={`${this.state.activeTab === "zel" ? "zel active" : "zel"
                        }`}
                    >
                      <button
                        className={`${this.state.activeTab === "zel" ? "active" : ""
                          }`}
                        onClick={() => this.tabsnav(3)}
                      ></button>
                    </li>
                    <li
                      className={`${this.state.activeTab === "res" ? "res active" : "res"
                        }`}
                    >
                      <button
                        className={`${this.state.activeTab === "res" ? "active" : ""
                          }`}
                        onClick={() => this.tabsnav(4)}
                      ></button>
                    </li>
                    <li
                      className={`${this.state.activeTab === "gem" ? "gem active" : "gem"
                        }`}
                    >
                      <button
                        className={`${this.state.activeTab === "gem" ? "active" : ""
                          }`}
                        onClick={() => this.tabsnav(5)}
                      ></button>
                    </li>
                    <li
                      className={`${this.state.activeTab === "jew" ? "jew active" : "jew"
                        }`}
                    >
                      <button
                        className={`${this.state.activeTab === "jew" ? "active" : ""
                          }`}
                        onClick={() => this.tabsnav(6)}
                      ></button>
                    </li>
                    <li
                      className={`${this.state.activeTab === "armor"
                        ? "armor active"
                        : "armor"
                        }`}
                    >
                      <button
                        className={`${this.state.activeTab === "armor" ? "active" : ""
                          }`}
                        onClick={() => this.tabsnav(7)}
                      ></button>
                    </li>
                    <li
                      className={`${this.state.activeTab === "book"
                        ? "book active"
                        : "book"
                        }`}
                    >
                      <button
                        className={`${this.state.activeTab === "book" ? "active" : ""
                          }`}
                        onClick={() => this.tabsnav(8)}
                      ></button>
                    </li>
                  </ul>
                </div>

                {this.state.activeTab === "weapon" && false ? (
                  <>
                    <div className="filter-invent element-filt">
                      <div className="filter-invent-bt">
                        <button>element</button>
                      </div>
                      <div className="filter-invent-drop">
                        <div className="filter-invent-wrap">
                          <div className="filter-invent-drop-body">
                            <div className="invent-drop-menu">
                              <ul>
                                <li className="hasico">
                                  <button className="el-fire">
                                    <i></i>
                                    fire
                                  </button>
                                </li>
                                <li className="hasico">
                                  <button className="el-water">
                                    <i></i>
                                    water
                                  </button>
                                </li>
                                <li className="hasico">
                                  <button className="el-air">
                                    <i></i>
                                    air
                                  </button>
                                </li>
                                <li className="hasico">
                                  <button className="el-earth">
                                    <i></i>
                                    earth
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="filter-invent type-filt">
                      <div className="filter-invent-bt">
                        <button>type</button>
                      </div>
                      <div className="filter-invent-drop">
                        <div className="filter-invent-wrap">
                          <div className="filter-invent-drop-body">
                            <div className="invent-drop-menu">
                              <ul>
                                <li>
                                  <button className="el-fire">Berserker</button>
                                </li>
                                <li>
                                  <button className="el-fire">Warrior </button>
                                </li>
                                <li>
                                  <button className="el-fire">Mage</button>
                                </li>
                                <li>
                                  <button className="el-fire">Archer </button>
                                </li>
                                <li>
                                  <button className="el-fire">Paladin </button>
                                </li>
                                <li>
                                  <button className="el-fire">Engineer</button>
                                </li>
                                <li>
                                  <button className="el-fire">Druid</button>
                                </li>
                                <li>
                                  <button className="el-fire">Dreneya</button>
                                </li>
                                <li>
                                  <button className="el-fire">Bard</button>
                                </li>
                                <li>
                                  <button className="el-fire">Shooter </button>
                                </li>
                                <li>
                                  <button className="el-fire">Smith </button>
                                </li>
                                <li>
                                  <button className="el-fire">Tauren </button>
                                </li>
                                <li>
                                  <button className="el-fire">Priest</button>
                                </li>
                                <li>
                                  <button className="el-fire">Healer</button>
                                </li>
                                <li>
                                  <button className="el-fire">Elder</button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
              <div className="invent-wrap">
                <div className="invent-wrap-in">
                  {this.state.activeTab === "eat" ? (
                    <InventoryEat
                      {...this.props}
                      {...this.state}
                      allinvent={this.props.alleat}
                      styleEat={this.state.modalEat.style}
                      showCloseModal={this.showCloseModal}
                    />
                  ) : this.state.activeTab === "book" ? (
                    <InventoryBook
                      {...this.props}
                      {...this.state}
                      allinvent={this.props.resInGame}
                      styleEat={this.state.modalEat.style}
                      showCloseModal={this.showCloseModal}
                    />
                  ) : this.state.activeTab === "weapon" ? (
                    // <InventoryWeapon  {...this.props} {...this.state} allinvent={this.props.allWeapon} />
                    <InventoryWeapon
                      {...this.props}
                      {...this.state}
                      allinvent={this.state.allweapongroup}
                      showCloseModal={this.showCloseModal}
                    />
                  ) : this.state.activeTab === "res" ? (
                    <InventoryRes
                      {...this.props}
                      {...this.state}
                      allinvent={this.state.allresgroup}
                      styleEat={this.state.modalEat.style}
                      showCloseModal={this.showCloseModal}
                    />
                  ) : this.state.activeTab === "gem" ? (
                    <InventoryGem
                      {...this.props}
                      {...this.state}
                      allinvent={this.state.allgemgroup}
                      styleEat={this.state.modalEat.style}
                      showCloseModal={this.showCloseModal}
                    />
                  ) : this.state.activeTab === "jew" ? (
                    <InventoryJew
                      {...this.props}
                      {...this.state}
                      allinvent={this.state.alljewgroup}
                      styleEat={this.state.modalEat.style}
                      showCloseModal={this.showCloseModal}
                    />
                  ) : this.state.activeTab === "armor" ? (
                    <InventoryArmor
                      {...this.props}
                      {...this.state}
                      allinvent={this.state.allarmorgroup}
                      styleEat={this.state.modalEat.style}
                      showCloseModal={this.showCloseModal}
                    />
                  ) : null}
                </div>
              </div>
              <div className="invent-bot">
                <picture>
                  <source srcSet={shafltopmobw} type="image/webp" />
                  <source srcSet={shafltopmob} type="image/png" />
                  <img className="hidedesc" src={shafltopmob} alt="" />
                </picture>
                <picture>
                  <source srcSet={inventbotw} type="image/webp" />
                  <source srcSet={inventbot} type="image/png" />
                  <img className="bot-inv-nav" src={inventbot} alt="" />
                </picture>

                <div className="invent-nav">
                  <div className="invent-nav-in">
                    {this.state.countPage > 1 && this.state.activePage > 1 ? (
                      <button
                        className="nav-link nav-prev"
                        onClick={() => this.navpage("prev")}
                      >
                        <span></span>
                      </button>
                    ) : null}

                    <div className="nav-num">{this.state.activePage}</div>

                    {this.state.countPage > 1 &&
                      this.state.countPage >= this.state.activePage ? (
                      <button
                        className="nav-link nav-next"
                        onClick={() => this.navpage("next")}
                      >
                        <span></span>
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer>
            <div className="footer-nav">
              <nav>
                <ul>
                  <li>
                    <a
                      className="foot-logo-1"
                      target="_blank"
                      href="https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&sort=created&symbol=WAX"
                    >
                      <span></span>
                      <i>Market</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-2"
                      target="_blank"
                      href="https://twitter.com/NftPanda/"
                    >
                      <span></span>
                      <i>Twitter</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-3"
                      target="_blank"
                      href="https://www.instagram.com/nftpanda/"
                    >
                      <span></span>
                      <i>Instagram</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-4"
                      target="_blank"
                      href="https://discord.gg/vCSgme4d5b"
                    >
                      <span></span>
                      <i>Discord</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-5"
                      target="_blank"
                      href={`https://wax.alcor.exchange/swap?output=${process.env.REACT_APP_VAL}-nftpandabamb&input=WAX-eosio.token`}
                    >
                      <span></span>
                      <i>{process.env.REACT_APP_VAL} Trade</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-6"
                      target="_blank"
                      href="https://discord.gg/vCSgme4d5b"
                    >
                      <span></span>
                      <i>Support</i>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </footer>
        </main>
      </>
    );
  }
}

// let mapStateToProps = (state) => ({
//     allinvent: state.inventoryReduser.allinvent,
// });

let mapStateToProps = (state) => ({
  statuseat: state.showReduser.statuseat,
  alleat: state.showReduser.alleat,
  wasbay: state.showReduser.wasbay,
  allWeapon: state.weaponReduser.allWeapon,
  statusWeapon: state.weaponReduser.statusWeapon,
});

export default connect(mapStateToProps, {
  eatlist,
  wasLoadinEat,
  wasBuyingEat,
  weaponlist,
  statusLoadinWeapon,
  addWeaponWhenRemove,
  addWeaponToPanda,
})(InventoryComponent);
