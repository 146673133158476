import React from 'react';
import needtorepair from '../../../images/need-to-repair.png';
import modaltop from '../../../images/shop/modal-top.png';
import modalbot from '../../../images/shop/modal-bot.png';
import modaltopwebp from '../../../images/shop/modal-top.webp';
import modalbotwebp from '../../../images/shop/modal-bot.webp';

const ModalOneWeapon = (props) => {
    var nameWeapon;

    var arr = [
        {
            "tmpl": "392191",
            "name": "Dwemer God of Bloody Justice"
        },
        {
            "tmpl": "382726",
            "name": "Dwemer Slayer of Evil"
        },
        {
            "tmpl": "382724",
            "name": "Golden Judge of Elgard"
        },
        {
            "tmpl": "382714",
            "name": "Fallen Angel"
        },
        {
            "tmpl": "382709",
            "name": "Destroyer of Barriers"
        },
        {
            "tmpl": "382702",
            "name": "Steel Git"
        },
        {
            "tmpl": "382698",
            "name": "Dwemer Slayer of Evil"
        },
        {
            "tmpl": "382693",
            "name": "Golden Judge of Elgard"
        },
        {
            "tmpl": "382691",
            "name": "Fallen Angel"
        },
        {
            "tmpl": "382688",
            "name": "Destroyer of Barriers"
        },
        {
            "tmpl": "382685",
            "name": "Steel Git"
        },
        {
            "tmpl": "382684",
            "name": "Dwemer Slayer of Evil"
        },
        {
            "tmpl": "382680",
            "name": "Golden Judge of Elgard"
        },
        {
            "tmpl": "382678",
            "name": "Fallen Angel"
        },
        {
            "tmpl": "382675",
            "name": "Destroyer of Barriers"
        },
        {
            "tmpl": "382672",
            "name": "Steel Git"
        },
        {
            "tmpl": "382671",
            "name": "Dwemer Slayer of Evil"
        },
        {
            "tmpl": "382670",
            "name": "Golden Judge of Elgard"
        },
        {
            "tmpl": "382647",
            "name": "Fallen Angel"
        },
        {
            "tmpl": "382643",
            "name": "Destroyer of Barriers"
        },
        {
            "tmpl": "382639",
            "name": "Steel Git"
        },
        {
            "tmpl": "382629",
            "name": "Dwemer Space Warp"
        },
        {
            "tmpl": "382628",
            "name": "Golden Hand of Elgard"
        },
        {
            "tmpl": "382627",
            "name": "Mechanical Killer"
        },
        {
            "tmpl": "382623",
            "name": "Deadly Scythe"
        },
        {
            "tmpl": "382621",
            "name": "Steel Death Сlaw"
        },
        {
            "tmpl": "382620",
            "name": "Dwemer Space Warp"
        },
        {
            "tmpl": "382616",
            "name": "Golden Hand of Elgard"
        },
        {
            "tmpl": "382613",
            "name": "Mechanical Killer"
        },
        {
            "tmpl": "382609",
            "name": "Deadly Scythe"
        },
        {
            "tmpl": "382604",
            "name": "Steel Death Сlaw"
        },
        {
            "tmpl": "382594",
            "name": "Dwemer Space Warp"
        },
        {
            "tmpl": "382591",
            "name": "Golden Hand of Elgard"
        },
        {
            "tmpl": "382588",
            "name": "Mechanical Killer"
        },
        {
            "tmpl": "382581",
            "name": "Deadly Scythe"
        },
        {
            "tmpl": "382580",
            "name": "Steel Death Сlaw"
        },
        {
            "tmpl": "382579",
            "name": "Dwemer Space Warp"
        },
        {
            "tmpl": "382578",
            "name": "Golden Hand of Elgard"
        },
        {
            "tmpl": "382577",
            "name": "Mechanical Killer"
        },
        {
            "tmpl": "382575",
            "name": "Deadly Scythe"
        },
        {
            "tmpl": "382574",
            "name": "Steel Death Сlaw"
        },
        {
            "tmpl": "382054",
            "name": "Dwemer Destroyer of Legions"
        },
        {
            "tmpl": "382052",
            "name": "Golden Friend of Elgard"
        },
        {
            "tmpl": "382050",
            "name": "Horns of Terror"
        },
        {
            "tmpl": "382046",
            "name": "Rampageous Bull"
        },
        {
            "tmpl": "382044",
            "name": "Furious Beast"
        },
        {
            "tmpl": "380286",
            "name": "Dwemer Destroyer of Legions"
        },
        {
            "tmpl": "380284",
            "name": "Golden Friend of Elgard"
        },
        {
            "tmpl": "380281",
            "name": "Horns of Terror"
        },
        {
            "tmpl": "380280",
            "name": "Rampageous Bull"
        },
        {
            "tmpl": "380279",
            "name": "Rampageous Bull"
        },
        {
            "tmpl": "380277",
            "name": "Furious Beast"
        },
        {
            "tmpl": "380276",
            "name": "Dwemer Destroyer of Legions"
        },
        {
            "tmpl": "380275",
            "name": "Golden Friend of Elgard"
        },
        {
            "tmpl": "380273",
            "name": "Horns of Terror"
        },
        {
            "tmpl": "380272",
            "name": "Rampageous Bull"
        },
        {
            "tmpl": "380271",
            "name": "Furious Beast"
        },
        {
            "tmpl": "380270",
            "name": "Dwemer Destroyer of Legions"
        },
        {
            "tmpl": "380268",
            "name": "Golden Friend of Elgard"
        },
        {
            "tmpl": "380265",
            "name": "Horns of Terror"
        },
        {
            "tmpl": "379072",
            "name": "Rampageous Bull"
        },
        {
            "tmpl": "379070",
            "name": "Furious Beast"
        },
        {
            "tmpl": "378821",
            "name": "Dwemer God of Bloody Justice"
        },
        {
            "tmpl": "378815",
            "name": "Golden Griffin of Elgard"
        },
        {
            "tmpl": "378810",
            "name": "Horror Maker"
        },
        {
            "tmpl": "378801",
            "name": "Fangs of Madness"
        },
        {
            "tmpl": "378799",
            "name": "Pain Teacher"
        },
        {
            "tmpl": "378796",
            "name": "Dwemer God of Bloody Justice"
        },
        {
            "tmpl": "378795",
            "name": "Golden Griffin of Elgard"
        },
        {
            "tmpl": "378794",
            "name": "Horror Maker"
        },
        {
            "tmpl": "378791",
            "name": "Fangs of Madness"
        },
        {
            "tmpl": "378790",
            "name": "Pain Teacher"
        },
        {
            "tmpl": "378789",
            "name": "Dwemer God of Bloody Justice"
        },
        {
            "tmpl": "378787",
            "name": "Golden Griffin of Elgard"
        },
        {
            "tmpl": "378785",
            "name": "Horror Maker"
        },
        {
            "tmpl": "378784",
            "name": "Fangs of Madness"
        },
        {
            "tmpl": "378783",
            "name": "Pain Teacher"
        },
        {
            "tmpl": "378781",
            "name": "Dwemer God of Bloody Justice"
        },
        {
            "tmpl": "378780",
            "name": "Golden Griffin of Elgard"
        },
        {
            "tmpl": "378779",
            "name": "Horror Maker"
        },
        {
            "tmpl": "378778",
            "name": "Fangs of Madness"
        },
        {
            "tmpl": "378777",
            "name": "Pain Teacher"
        },
        {
            "tmpl": "378323",
            "name": "Dwemer Nightmare of Centuries"
        },
        {
            "tmpl": "378322",
            "name": "Relic of Kings"
        },
        {
            "tmpl": "378320",
            "name": "Hurricane Claw"
        },
        {
            "tmpl": "378317",
            "name": "Whisper of Death"
        },
        {
            "tmpl": "378316",
            "name": "Edge of Dusk"
        },
        {
            "tmpl": "378313",
            "name": "Dwemer Nightmare of Centuries"
        },
        {
            "tmpl": "378311",
            "name": "Relic of Kings"
        },
        {
            "tmpl": "378307",
            "name": "Hurricane Claw"
        },
        {
            "tmpl": "378305",
            "name": "Whisper of Death"
        },
        {
            "tmpl": "378302",
            "name": "Edge of Dusk"
        },
        {
            "tmpl": "378299",
            "name": "Dwemer Nightmare of Centuries"
        },
        {
            "tmpl": "378296",
            "name": "Relic of Kings"
        },
        {
            "tmpl": "378295",
            "name": "Hurricane Claw"
        },
        {
            "tmpl": "378292",
            "name": "Whisper of Death"
        },
        {
            "tmpl": "378290",
            "name": "Edge of Dusk"
        },
        {
            "tmpl": "378178",
            "name": "Dwemer Nightmare of Centuries"
        },
        {
            "tmpl": "378176",
            "name": "Relic of Kings"
        },
        {
            "tmpl": "378174",
            "name": "Hurricane Claw"
        },
        {
            "tmpl": "378171",
            "name": "Whisper of Death"
        },
        {
            "tmpl": "378165",
            "name": "Edge of Dusk"
        },
        {
            "tmpl": "378091",
            "name": "Dwemer Lord of Darkness"
        },
        {
            "tmpl": "378090",
            "name": "Golden Liberator of Elgard"
        },
        {
            "tmpl": "378089",
            "name": "Dragon Fang"
        },
        {
            "tmpl": "378088",
            "name": "Green Fury"
        },
        {
            "tmpl": "378087",
            "name": "Thunder Steel"
        },
        {
            "tmpl": "378085",
            "name": "Dwemer Lord of Darkness"
        },
        {
            "tmpl": "378084",
            "name": "Golden Liberator of Elgard"
        },
        {
            "tmpl": "378083",
            "name": "Dragon Fang"
        },
        {
            "tmpl": "378081",
            "name": "Green Fury"
        },
        {
            "tmpl": "378080",
            "name": "Thunder Steel"
        },
        {
            "tmpl": "378077",
            "name": "Dwemer Lord of Darkness"
        },
        {
            "tmpl": "378075",
            "name": "Golden Liberator of Elgard"
        },
        {
            "tmpl": "378040",
            "name": "Dragon Fang"
        },
        {
            "tmpl": "378038",
            "name": "Green Fury"
        },
        {
            "tmpl": "378037",
            "name": "Thunder Steel"
        },
        {
            "tmpl": "378036",
            "name": "Dwemer Lord of Darkness"
        },
        {
            "tmpl": "378034",
            "name": "Golden Liberator of Elgard"
        },
        {
            "tmpl": "378031",
            "name": "Dragon Fang"
        },
        {
            "tmpl": "378030",
            "name": "Green Fury"
        },
        {
            "tmpl": "378029",
            "name": "Thunder Steel"
        },
        {
            "tmpl": "378025",
            "name": "Dwemer Destroyer of Worlds"
        },
        {
            "tmpl": "378023",
            "name": "Heart of Elgard"
        },
        {
            "tmpl": "378017",
            "name": "Mother in Law"
        },
        {
            "tmpl": "378015",
            "name": "Millennial Pain"
        },
        {
            "tmpl": "377492",
            "name": "Crusher"
        },
        {
            "tmpl": "377490",
            "name": "Dwemer Destroyer of Worlds"
        },
        {
            "tmpl": "377489",
            "name": "Heart of Elgard"
        },
        {
            "tmpl": "377488",
            "name": "Mother in Law"
        },
        {
            "tmpl": "377487",
            "name": "Millennial Pain"
        },
        {
            "tmpl": "377486",
            "name": "Crusher"
        },
        {
            "tmpl": "377484",
            "name": "Dwemer Destroyer of Worlds"
        },
        {
            "tmpl": "377483",
            "name": "Heart of Elgard"
        },
        {
            "tmpl": "377481",
            "name": "Mother in Law"
        },
        {
            "tmpl": "377477",
            "name": "Millennial Pain"
        },
        {
            "tmpl": "377476",
            "name": "Crusher"
        },
        {
            "tmpl": "377475",
            "name": "Dwemer Destroyer of Worlds"
        },
        {
            "tmpl": "377474",
            "name": "Heart of Elgard"
        },
        {
            "tmpl": "377473",
            "name": "Mother in Law"
        },
        {
            "tmpl": "377472",
            "name": "Millennial Pain"
        },
        {
            "tmpl": "377469",
            "name": "Crusher"
        },
        {
            "tmpl": "377404",
            "name": "Dwemer Devourer of Souls"
        },
        {
            "tmpl": "377401",
            "name": "Heavenly Needle"
        },
        {
            "tmpl": "377397",
            "name": "Voice of Valkyries"
        },
        {
            "tmpl": "377392",
            "name": "Dream catcher"
        },
        {
            "tmpl": "377387",
            "name": "Lore Keeper"
        },
        {
            "tmpl": "377381",
            "name": "Dwemer Devourer of Souls"
        },
        {
            "tmpl": "377377",
            "name": "Heavenly Needle"
        },
        {
            "tmpl": "377373",
            "name": "Voice of Valkyries"
        },
        {
            "tmpl": "377368",
            "name": "Dream catcher"
        },
        {
            "tmpl": "377365",
            "name": "Lore Keeper"
        },
        {
            "tmpl": "377362",
            "name": "Dwemer Devourer of Souls"
        },
        {
            "tmpl": "377359",
            "name": "Heavenly Needle"
        },
        {
            "tmpl": "377355",
            "name": "Voice of Valkyries"
        },
        {
            "tmpl": "377352",
            "name": "Dream catcher"
        },
        {
            "tmpl": "377347",
            "name": "Lore Keeper"
        },
        {
            "tmpl": "377307",
            "name": "Dwemer Devourer of Souls"
        },
        {
            "tmpl": "377306",
            "name": "Heavenly Needle"
        },
        {
            "tmpl": "377304",
            "name": "Voice of Valkyries"
        },
        {
            "tmpl": "377303",
            "name": "Dream catcher"
        },
        {
            "tmpl": "377302",
            "name": "Lore Keeper"
        },
        {
            "tmpl": "377287",
            "name": "Dwemer Soul of Elgard"
        },
        {
            "tmpl": "377286",
            "name": "Golden Heartbreaker"
        },
        {
            "tmpl": "377285",
            "name": "Dragonsbane"
        },
        {
            "tmpl": "377283",
            "name": "Whisper of Night"
        },
        {
            "tmpl": "377019",
            "name": "Cleaving Wind"
        },
        {
            "tmpl": "377015",
            "name": "Dwemer Soul of Elgard"
        },
        {
            "tmpl": "377012",
            "name": "Golden Heartbreaker"
        },
        {
            "tmpl": "377011",
            "name": "Dragonsbane"
        },
        {
            "tmpl": "377009",
            "name": "Whisper of Night"
        },
        {
            "tmpl": "377002",
            "name": "Cleaving Wind"
        },
        {
            "tmpl": "377001",
            "name": "Dwemer Soul of Elgard"
        },
        {
            "tmpl": "377000",
            "name": "Golden Heartbreaker"
        },
        {
            "tmpl": "376999",
            "name": "Dragonsbane"
        },
        {
            "tmpl": "376998",
            "name": "Whisper of Night"
        },
        {
            "tmpl": "376997",
            "name": "Cleaving Wind"
        },
        {
            "tmpl": "376996",
            "name": "Dwemer Soul of Elgard"
        },
        {
            "tmpl": "376995",
            "name": "Golden Heartbreaker"
        },
        {
            "tmpl": "376994",
            "name": "Dragonsbane"
        },
        {
            "tmpl": "376993",
            "name": "Whisper of Night"
        },
        {
            "tmpl": "376991",
            "name": "Cleaving Wind"
        },
        {
            "tmpl": "376795",
            "name": "Dwemer King of the Night"
        },
        {
            "tmpl": "376794",
            "name": "Legacy of Elgard"
        },
        {
            "tmpl": "376787",
            "name": "Toothed Girlfriend"
        },
        {
            "tmpl": "376777",
            "name": "Bestial Rage"
        },
        {
            "tmpl": "376776",
            "name": "Son of Steel"
        },
        {
            "tmpl": "376775",
            "name": "Dwemer King of the Night"
        },
        {
            "tmpl": "376774",
            "name": "Legacy of Elgard"
        },
        {
            "tmpl": "376773",
            "name": "Toothed Girlfriend"
        },
        {
            "tmpl": "376772",
            "name": "Bestial Rage"
        },
        {
            "tmpl": "376770",
            "name": "Son of Steel"
        },
        {
            "tmpl": "376769",
            "name": "Dwemer King of the Night"
        },
        {
            "tmpl": "376768",
            "name": "Legacy of Elgard"
        },
        {
            "tmpl": "376767",
            "name": "Toothed Girlfriend"
        },
        {
            "tmpl": "376763",
            "name": "Bestial Rage"
        },
        {
            "tmpl": "376762",
            "name": "Son of Steel"
        },
        {
            "tmpl": "376760",
            "name": "Dwemer King of the Night"
        },
        {
            "tmpl": "376756",
            "name": "Legacy of Elgard"
        },
        {
            "tmpl": "376755",
            "name": "Toothed Girlfriend"
        },
        {
            "tmpl": "376753",
            "name": "Bestial Rage"
        },
        {
            "tmpl": "376750",
            "name": "Son of Steel"
        },
        {
            "tmpl": "376582",
            "name": "Sword"
        },
        {
            "tmpl": "376577",
            "name": "Sword"
        },
        {
            "tmpl": "376570",
            "name": "Sword"
        },
        {
            "tmpl": "376557",
            "name": "Sword"
        },
        {
            "tmpl": "376407",
            "name": "Stick"
        },
        {
            "tmpl": "376374",
            "name": "Stick"
        },
        {
            "tmpl": "376329",
            "name": "Stick"
        },
        {
            "tmpl": "376320",
            "name": "Stick"
        },
        {
            "tmpl": "376310",
            "name": "Slingshot"
        },
        {
            "tmpl": "376302",
            "name": "Slingshot"
        },
        {
            "tmpl": "376204",
            "name": "Slingshot"
        },
        {
            "tmpl": "376198",
            "name": "Slingshot"
        },
        {
            "tmpl": "376178",
            "name": "Mechanical Arm"
        },
        {
            "tmpl": "376172",
            "name": "Mechanical Arm"
        },
        {
            "tmpl": "376162",
            "name": "Mechanical Arm"
        },
        {
            "tmpl": "376156",
            "name": "Mechanical Arm"
        },
        {
            "tmpl": "376151",
            "name": "Hammer"
        },
        {
            "tmpl": "376145",
            "name": "Hammer"
        },
        {
            "tmpl": "375243",
            "name": "Hammer"
        },
        {
            "tmpl": "375234",
            "name": "Hammer"
        },
        {
            "tmpl": "374653",
            "name": "Gun"
        },
        {
            "tmpl": "374640",
            "name": "Gun"
        },
        {
            "tmpl": "374615",
            "name": "Gun"
        },
        {
            "tmpl": "374606",
            "name": "Gun"
        },
        {
            "tmpl": "374596",
            "name": "Dagger"
        },
        {
            "tmpl": "374590",
            "name": "Dagger"
        },
        {
            "tmpl": "374570",
            "name": "Dagger"
        },
        {
            "tmpl": "374544",
            "name": "Dagger"
        },
        {
            "tmpl": "374535",
            "name": "Claws"
        },
        {
            "tmpl": "374525",
            "name": "Claws"
        },
        {
            "tmpl": "374515",
            "name": "Claws"
        },
        {
            "tmpl": "374507",
            "name": "Claws"
        },
        {
            "tmpl": "373736",
            "name": "Bow"
        },
        {
            "tmpl": "373682",
            "name": "Bow"
        },
        {
            "tmpl": "373669",
            "name": "Bow"
        },
        {
            "tmpl": "373659",
            "name": "Bow"
        },
        {
            "tmpl": "373457",
            "name": "Axe"
        },
        {
            "tmpl": "373448",
            "name": "Axe"
        },
        {
            "tmpl": "373433",
            "name": "Axe"
        },
        {
            "tmpl": "373391",
            "name": "Axe"
        }
    ]

    arr.filter((l) => l.tmpl == props.modalWeapon.weapon.tmpl ? nameWeapon = l.name : null);

    return (
        <>
            {props.modalWeapon.weapon !== null ?
                <div className={`modal-weapon ${props.modalWeapon.openModal ? "open" : null} ${props.modalWeapon.showAnimModal ? " anim" : null}`}>
                    <div className="modal-weapon-body">
                        <div className="modal-weapon-body-in">

                            <div className="modal-weapon-head">

                                <div className="modal-weapon-top">
                                    <button className="modal-weapon-close" onClick={() => props.showCloseModal('close')}><span></span></button>
                                    <picture>
                                        <source srcSet={`${modaltop}`} type="image/webp" />
                                        <source srcSet={`${modaltopwebp}`} type="image/png" />
                                        <img src={`${modaltopwebp}`} alt="" />
                                    </picture>
                                </div>
                                <div className="modal-weapon-wrap weap-mod-part">
                                    <div className={`can-fix tooltip ${props.modalWeapon.weaponEnergy === 0 ? 'active' : ''}`} onClick={() => props.addEnergyWeapon(props.modalWeapon.weapon.asset_id, props.modalWeapon.weapon.panda_id)}>
                                        <div className="cust-tooltip bottom">
                                            <div className="tool-arrow"></div>
                                            <div className="tool-wrap">
                                                <div className="tool-wrap-1">
                                                    <div className="tool-wrap-2">
                                                        <div className="tool-body">

                                                            <>repair weapon <br />{props.modalWeapon.cost} {process.env.REACT_APP_VAL}</>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {props.modalWeapon.voucherCount > 0 ?
                                        <div className={`can-fix-w tooltip ${props.modalWeapon.weaponEnergy === 0 ? 'active' : ''}`} onClick={() => props.addEnergyWeaponByVoucher(props.modalWeapon.voucherList[0].asset_id, props.modalWeapon.weapon.asset_id, props.modalWeapon.index)}>
                                            <div className="cust-tooltip bottom">
                                                <div className="tool-arrow"></div>
                                                <div className="tool-wrap">
                                                    <div className="tool-wrap-1">
                                                        <div className="tool-wrap-2">
                                                            <div className="tool-body">

                                                                <>repair weapon <br />1 voucher</>
                                                                <br /> (you own {props.modalWeapon.voucherCount})
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null}




                                    <div className="modal-weapon-tit">
                                        {nameWeapon}
                                    </div>

                                    <div className="modal-weapon-thumb">
                                        <div className={`modal-weapon-thumb-in ${props.modalWeapon.weaponEnergy === 0 ? "dis" : null}`}>
                                            <picture>
                                                <source srcSet={`./images/weapons/all/${props.modalWeapon.weapon.tmpl}.webp`} type="image/webp" />
                                                <source srcSet={`./images/weapons/all/${props.modalWeapon.weapon.tmpl}.png`} type="image/png" />
                                                <img src={`./images/weapons/all/${props.modalWeapon.weapon.tmpl}.png`} alt="" />
                                            </picture>
                                            {/* <div className="modal-weapon-thumb-text">
                                        common
                                    </div>  */}
                                        </div>
                                        {props.modalWeapon.weaponEnergy === 0 ?
                                            <div className="broken">
                                                <picture>
                                                    {/* <source srcSet={`./images/weapons/all/${props.modalWeapon.weapon.tmpl}.webp`} type="image/webp" /> */}
                                                    <source srcSet={needtorepair} type="image/png" />
                                                    <img src={needtorepair} alt="" />
                                                </picture>
                                            </div>
                                            : null}
                                    </div>

                                    <div className="strength">
                                        <div className="strength-name">
                                            durability
                                        </div>
                                        <div className="strength-perc">
                                            <div className="strength-perc-wrap">
                                                <div className="strength-perc-in" >
                                                    <div className="line-prog" style={{ "width": "" + `${props.modalWeapon.weaponEnergy / 100}` + "%" }}></div>
                                                </div>
                                                <div className="strength-perc-number">
                                                    <span>
                                                        {props.modalWeapon.weaponEnergy / 100}
                                                    </span>
                                                    %
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal-weapon-bt">
                                        {/* <button className="def-bt min" >
                                    <span className="button-in">
                                        <span className="button-name">
                                            <span className="button-name-in">
                                                <span className="button-name-value">
                                                    change
                                                </span> 
                                            </span>
                                        </span>
                                    </span>
                                </button> */}
                                        <button className="def-bt min" onClick={(e) => { props.sendOutWeapon(props.modalWeapon.weapon); }}>
                                            <span className="button-in">
                                                <span className="button-name">
                                                    <span className="button-name-in">
                                                        <span className="button-name-value">
                                                            unequip
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </button>
                                    </div>

                                    <div className="bot-ico-modal">
                                        <div className="left-weapon"></div>
                                        <div className="right-weapon"></div>
                                    </div>
                                </div>
                                <div className="modal-weapon-bot">
                                    <picture>
                                        <source srcSet={`${modalbotwebp}`} type="image/webp" />
                                        <source srcSet={`${modalbot}`} type="image/png" />
                                        <img src={`${modalbot}`} alt="" />
                                    </picture>
                                </div>
                            </div>
                        </div>

                        <div className="modal-weapon-bg-trans" onClick={() => props.showCloseModal()}></div>
                    </div>

                    <div className="modal-weapon-bg" ></div>
                </div>
                :
                null}






        </>);
}

export default ModalOneWeapon;