import React from 'react';
import needtorepair from '../../../images/need-to-repair.png';
import modaltop from '../../../images/shop/modal-top.png';
import modalbot from '../../../images/shop/modal-bot.png';
import modaltopwebp from '../../../images/shop/modal-top.webp';
import modalbotwebp from '../../../images/shop/modal-bot.webp';

const ModalOneJew = (props) => {

    var armorArrey = [
        {
            template_id: 595188,
            name: "Dwemer Armor of Intrepid Legions Leader"
        },
        {
            template_id: 595187,
            name: "Dwemer Armor of Intrepid Legions Leader"
        },
        {
            template_id: 595184,
            name: "Dwemer Armor of Intrepid Legions Leader"
        },
        {
            template_id: 595183,
            name: "Dwemer Armor of Intrepid Legions Leader"
        },
        {
            template_id: 595182,
            name: "Dwemer Armor of Great Ancestral Spirit"
        },
        {
            template_id: 595181,
            name: "Dwemer Armor of Great Ancestral Spirit"
        },
        {
            template_id: 595180,
            name: "Dwemer Armor of Great Ancestral Spirit"
        },
        {
            template_id: 595179,
            name: "Dwemer Armor of Great Ancestral Spirit"
        },
        {
            template_id: 595177,
            name: "Dwemer Apron of Divine Mastery"
        },
        {
            template_id: 595176,
            name: "Dwemer Apron of Divine Mastery"
        },
        {
            template_id: 595175,
            name: "Dwemer Apron of Divine Mastery"
        },
        {
            template_id: 595174,
            name: "Dwemer Apron of Divine Mastery"
        },
        {
            template_id: 595127,
            name: "Dwemer Armor of Invisible Dread"
        },
        {
            template_id: 595126,
            name: "Dwemer Armor of Invisible Dread"
        },
        {
            template_id: 595125,
            name: "Dwemer Armor of Invisible Dread"
        },
        {
            template_id: 595124,
            name: "Dwemer Armor of Invisible Dread"
        },
        {
            template_id: 595122,
            name: "Dwemer Mantle of Ancient God"
        },
        {
            template_id: 595120,
            name: "Dwemer Mantle of Ancient God"
        },
        {
            template_id: 595117,
            name: "Dwemer Mantle of Ancient God"
        },
        {
            template_id: 595116,
            name: "Dwemer Mantle of Ancient God"
        },
        {
            template_id: 595115,
            name: "Dwemer Armor of Horde Tamer"
        },
        {
            template_id: 595114,
            name: "Dwemer Armor of Horde Tamer"
        },
        {
            template_id: 595113,
            name: "Dwemer Armor of Horde Tamer"
        },
        {
            template_id: 595112,
            name: "Dwemer Armor of Horde Tamer"
        },
        {
            template_id: 595111,
            name: "Dwemer Mantle of Soul Breaker"
        },
        {
            template_id: 595110,
            name: "Dwemer Mantle of Soul Breaker"
        },
        {
            template_id: 595109,
            name: "Dwemer Mantle of Soul Breaker"
        },
        {
            template_id: 595108,
            name: "Dwemer Mantle of Soul Breaker"
        },
        {
            template_id: 595106,
            name: "Dwemer Mantle of Universes Healing"
        },
        {
            template_id: 595105,
            name: "Dwemer Mantle of Universes Healing"
        },
        {
            template_id: 595104,
            name: "Dwemer Mantle of Universes Healing"
        },
        {
            template_id: 595103,
            name: "Dwemer Mantle of Universes Healing"
        },
        {
            template_id: 595102,
            name: "Dwemer Armor of Darkness Absorption"
        },
        {
            template_id: 595101,
            name: "Dwemer Armor of Darkness Absorption"
        },
        {
            template_id: 595100,
            name: "Dwemer Armor of Darkness Absorption"
        },
        {
            template_id: 595099,
            name: "Dwemer Armor of Darkness Absorption"
        },
        {
            template_id: 595098,
            name: "Dwemer Keeper Mantle of Fallen World"
        },
        {
            template_id: 595097,
            name: "Dwemer Keeper Mantle of Fallen World"
        },
        {
            template_id: 595096,
            name: "Dwemer Keeper Mantle of Fallen World"
        },
        {
            template_id: 595095,
            name: "Dwemer Keeper Mantle of Fallen World"
        },
        {
            template_id: 595093,
            name: "Dwemer Armor of Forbidden Alchemy"
        },
        {
            template_id: 595092,
            name: "Dwemer Armor of Forbidden Alchemy"
        },
        {
            template_id: 595091,
            name: "Dwemer Armor of Forbidden Alchemy"
        },
        {
            template_id: 595090,
            name: "Dwemer Armor of Forbidden Alchemy"
        },
        {
            template_id: 595086,
            name: "Dwemer Armor of Infinite Space"
        },
        {
            template_id: 595085,
            name: "Dwemer Armor of Infinite Space"
        },
        {
            template_id: 595084,
            name: "Dwemer Armor of Infinite Space"
        },
        {
            template_id: 595083,
            name: "Dwemer Armor of Infinite Space"
        },
        {
            template_id: 595082,
            name: "Dwemer Chaos Absorption Armor"
        },
        {
            template_id: 595081,
            name: "Dwemer Chaos Absorption Armor"
        },
        {
            template_id: 595080,
            name: "Dwemer Chaos Absorption Armor"
        },
        {
            template_id: 595079,
            name: "Dwemer Chaos Absorption Armor"
        },
        {
            template_id: 595078,
            name: "Dwemer Armor of Twilight Emperor"
        },
        {
            template_id: 595077,
            name: "Dwemer Armor of Twilight Emperor"
        },
        {
            template_id: 595076,
            name: "Dwemer Armor of Twilight Emperor"
        },
        {
            template_id: 595075,
            name: "Dwemer Armor of Twilight Emperor"
        },
        {
            template_id: 595074,
            name: "Dwemer Almighty Gaze of Abyss"
        },
        {
            template_id: 595073,
            name: "Dwemer Almighty Gaze of Abyss"
        },
        {
            template_id: 595072,
            name: "Dwemer Almighty Gaze of Abyss"
        },
        {
            template_id: 595066,
            name: "Dwemer Almighty Gaze of Abyss"
        },
        {
            template_id: 583865,
            name: "Armor In Name of Elgard"
        },
        {
            template_id: 583864,
            name: "Armor In Name of Elgard"
        },
        {
            template_id: 583863,
            name: "Armor In Name of Elgard"
        },
        {
            template_id: 583862,
            name: "Armor In Name of Elgard"
        },
        {
            template_id: 583857,
            name: "Golden Spirit of Elgard"
        },
        {
            template_id: 583856,
            name: "Golden Spirit of Elgard"
        },
        {
            template_id: 583855,
            name: "Golden Spirit of Elgard"
        },
        {
            template_id: 583854,
            name: "Golden Spirit of Elgard"
        },
        {
            template_id: 583608,
            name: "Golden Apron of Celestial Mastery"
        },
        {
            template_id: 583607,
            name: "Golden Apron of Celestial Mastery"
        },
        {
            template_id: 583603,
            name: "Golden Apron of Celestial Mastery"
        },
        {
            template_id: 583600,
            name: "Golden Apron of Celestial Mastery"
        },
        {
            template_id: 583568,
            name: "Gold Liberator Armor of Elgard"
        },
        {
            template_id: 583567,
            name: "Gold Liberator Armor of Elgard"
        },
        {
            template_id: 583566,
            name: "Gold Liberator Armor of Elgard"
        },
        {
            template_id: 583565,
            name: "Gold Liberator Armor of Elgard"
        },
        {
            template_id: 583564,
            name: "High Priest Robe of Elgard"
        },
        {
            template_id: 583563,
            name: "High Priest Robe of Elgard"
        },
        {
            template_id: 583562,
            name: "High Priest Robe of Elgard"
        },
        {
            template_id: 583561,
            name: "High Priest Robe of Elgard"
        },
        {
            template_id: 583560,
            name: "Golden Glow of Elgard's Honor"
        },
        {
            template_id: 583558,
            name: "Golden Glow of Elgard's Honor"
        },
        {
            template_id: 583557,
            name: "Golden Glow of Elgard's Honor"
        },
        {
            template_id: 583556,
            name: "Golden Glow of Elgard's Honor"
        },
        {
            template_id: 583269,
            name: "High Arcanist Robe of Elgard"
        },
        {
            template_id: 583268,
            name: "High Arcanist Robe of Elgard"
        },
        {
            template_id: 583267,
            name: "High Arcanist Robe of Elgard"
        },
        {
            template_id: 583266,
            name: "High Arcanist Robe of Elgard"
        },
        {
            template_id: 583265,
            name: "High Arcanist Robe of Elgard"
        },
        {
            template_id: 583264,
            name: "Golden Robe of Omnipotent Healing"
        },
        {
            template_id: 583262,
            name: "Golden Robe of Omnipotent Healing"
        },
        {
            template_id: 583261,
            name: "Golden Robe of Omnipotent Healing"
        },
        {
            template_id: 583260,
            name: "Golden Robe of Omnipotent Healing"
        },
        {
            template_id: 583259,
            name: "Golden Mechanical Shield of Elgard"
        },
        {
            template_id: 583258,
            name: "Golden Mechanical Shield of Elgard"
        },
        {
            template_id: 583257,
            name: "Golden Mechanical Shield of Elgard"
        },
        {
            template_id: 583256,
            name: "Golden Mechanical Shield of Elgard"
        },
        {
            template_id: 583255,
            name: "Arcane Loremaster of Elgard"
        },
        {
            template_id: 583254,
            name: "Arcane Loremaster of Elgard"
        },
        {
            template_id: 583253,
            name: "Arcane Loremaster of Elgard"
        },
        {
            template_id: 583252,
            name: "Arcane Loremaster of Elgard"
        },
        {
            template_id: 583251,
            name: "Golden Antimatter Armor"
        },
        {
            template_id: 583249,
            name: "Golden Antimatter Armor"
        },
        {
            template_id: 583248,
            name: "Golden Antimatter Armor"
        },
        {
            template_id: 583247,
            name: "Golden Antimatter Armor"
        },
        {
            template_id: 583005,
            name: "Golden Armor of Multiverse Mystery"
        },
        {
            template_id: 583004,
            name: "Golden Armor of Multiverse Mystery"
        },
        {
            template_id: 583003,
            name: "Golden Armor of Multiverse Mystery"
        },
        {
            template_id: 583002,
            name: "Golden Armor of Multiverse Mystery"
        },
        {
            template_id: 583001,
            name: "Golden Pain Keeper of Elgard"
        },
        {
            template_id: 583000,
            name: "Golden Pain Keeper of Elgard"
        },
        {
            template_id: 582999,
            name: "Golden Pain Keeper of Elgard"
        },
        {
            template_id: 582998,
            name: "Golden Pain Keeper of Elgard"
        },
        {
            template_id: 582967,
            name: "Armor of Golden King"
        },
        {
            template_id: 582966,
            name: "Armor of Golden King"
        },
        {
            template_id: 582965,
            name: "Armor of Golden King"
        },
        {
            template_id: 582964,
            name: "Armor of Golden King"
        },
        {
            template_id: 582963,
            name: "Golden Gaze of Elgard"
        },
        {
            template_id: 582961,
            name: "Golden Gaze of Elgard"
        },
        {
            template_id: 582960,
            name: "Golden Gaze of Elgard"
        },
        {
            template_id: 582959,
            name: "Golden Gaze of Elgard"
        },
        {
            template_id: 556633,
            name: "Armor of Night Ronin"
        },
        {
            template_id: 556632,
            name: "Armor of Night Ronin"
        },
        {
            template_id: 556631,
            name: "Armor of Night Ronin"
        },
        {
            template_id: 556630,
            name: "Armor of Night Ronin"
        },
        {
            template_id: 556629,
            name: "Dark Spirit Armor"
        },
        {
            template_id: 556628,
            name: "Dark Spirit Armor"
        },
        {
            template_id: 556627,
            name: "Dark Spirit Armor"
        },
        {
            template_id: 556626,
            name: "Dark Spirit Armor"
        },
        {
            template_id: 556624,
            name: "Almighty Apron"
        },
        {
            template_id: 556623,
            name: "Almighty Apron"
        },
        {
            template_id: 556622,
            name: "Almighty Apron"
        },
        {
            template_id: 556621,
            name: "Almighty Apron"
        },
        {
            template_id: 556612,
            name: "Dark Burst Armor"
        },
        {
            template_id: 556611,
            name: "Dark Burst Armor"
        },
        {
            template_id: 556609,
            name: "Dark Burst Armor"
        },
        {
            template_id: 556608,
            name: "Dark Burst Armor"
        },
        {
            template_id: 556478,
            name: "Armor of Unwavering Priest"
        },
        {
            template_id: 556477,
            name: "Armor of Unwavering Priest"
        },
        {
            template_id: 556476,
            name: "Armor of Unwavering Priest"
        },
        {
            template_id: 556475,
            name: "Armor of Unwavering Priest"
        },
        {
            template_id: 556471,
            name: "Armor of Desperate Nobility"
        },
        {
            template_id: 556470,
            name: "Armor of Desperate Nobility"
        },
        {
            template_id: 556467,
            name: "Armor of Desperate Nobility"
        },
        {
            template_id: 556466,
            name: "Armor of Desperate Nobility"
        },
        {
            template_id: 556418,
            name: "Robe of Dark Archmage"
        },
        {
            template_id: 556414,
            name: "Robe of Dark Archmage"
        },
        {
            template_id: 556412,
            name: "Robe of Dark Archmage"
        },
        {
            template_id: 556410,
            name: "Robe of Dark Archmage"
        },
        {
            template_id: 556378,
            name: "Mantle of Serpent Curse"
        },
        {
            template_id: 556377,
            name: "Mantle of Serpent Curse"
        },
        {
            template_id: 556376,
            name: "Mantle of Serpent Curse"
        },
        {
            template_id: 556375,
            name: "Mantle of Serpent Curse"
        },
        {
            template_id: 556373,
            name: "Armor of Devourer Light"
        },
        {
            template_id: 556372,
            name: "Armor of Devourer Light"
        },
        {
            template_id: 556371,
            name: "Armor of Devourer Light"
        },
        {
            template_id: 556370,
            name: "Armor of Devourer Light"
        },
        {
            template_id: 556264,
            name: "Armor of Forbidden Truths"
        },
        {
            template_id: 556260,
            name: "Armor of Forbidden Truths"
        },
        {
            template_id: 556259,
            name: "Armor of Forbidden Truths"
        },
        {
            template_id: 556258,
            name: "Armor of Forbidden Truths"
        },
        {
            template_id: 552855,
            name: "Dark Alchemist Armor"
        },
        {
            template_id: 552853,
            name: "Dark Alchemist Armor"
        },
        {
            template_id: 552852,
            name: "Dark Alchemist Armor"
        },
        {
            template_id: 552851,
            name: "Dark Alchemist Armor"
        },
        {
            template_id: 552826,
            name: "Armor of Black Hole"
        },
        {
            template_id: 552825,
            name: "Armor of Black Hole"
        },
        {
            template_id: 552824,
            name: "Armor of Black Hole"
        },
        {
            template_id: 552823,
            name: "Armor of Black Hole"
        },
        {
            template_id: 552821,
            name: "Armor of Demonic Pain"
        },
        {
            template_id: 552820,
            name: "Armor of Demonic Pain"
        },
        {
            template_id: 552819,
            name: "Armor of Demonic Pain"
        },
        {
            template_id: 552818,
            name: "Armor of Demonic Pain"
        },
        {
            template_id: 552782,
            name: "King Style Armor"
        },
        {
            template_id: 552781,
            name: "King Style Armor"
        },
        {
            template_id: 552780,
            name: "King Style Armor"
        },
        {
            template_id: 552779,
            name: "King Style Armor"
        },
        {
            template_id: 552776,
            name: "Armor of All-Seeing Eye"
        },
        {
            template_id: 552775,
            name: "Armor of All-Seeing Eye"
        },
        {
            template_id: 552774,
            name: "Armor of All-Seeing Eye"
        },
        {
            template_id: 552773,
            name: "Armor of All-Seeing Eye"
        },
        {
            template_id: 552580,
            name: "Ferocious Warrior Armor"
        },
        {
            template_id: 552578,
            name: "Ferocious Warrior Armor"
        },
        {
            template_id: 552577,
            name: "Ferocious Warrior Armor"
        },
        {
            template_id: 552576,
            name: "Ferocious Warrior Armor"
        },
        {
            template_id: 552574,
            name: "Rock Spirit Armor"
        },
        {
            template_id: 552572,
            name: "Rock Spirit Armor"
        },
        {
            template_id: 552571,
            name: "Rock Spirit Armor"
        },
        {
            template_id: 552570,
            name: "Rock Spirit Armor"
        },
        {
            template_id: 552568,
            name: "Impenetrable Apron"
        },
        {
            template_id: 552567,
            name: "Impenetrable Apron"
        },
        {
            template_id: 552566,
            name: "Impenetrable Apron"
        },
        {
            template_id: 552565,
            name: "Impenetrable Apron"
        },
        {
            template_id: 552564,
            name: "Piercing Shot Armor"
        },
        {
            template_id: 552563,
            name: "Piercing Shot Armor"
        },
        {
            template_id: 552562,
            name: "Piercing Shot Armor"
        },
        {
            template_id: 552561,
            name: "Piercing Shot Armor"
        },
        {
            template_id: 552556,
            name: "Flawless Robe"
        },
        {
            template_id: 552555,
            name: "Flawless Robe"
        },
        {
            template_id: 552554,
            name: "Flawless Robe"
        },
        {
            template_id: 552551,
            name: "Flawless Robe"
        },
        {
            template_id: 552539,
            name: "Armor of Courage"
        },
        {
            template_id: 552538,
            name: "Armor of Courage"
        },
        {
            template_id: 552537,
            name: "Armor of Courage"
        },
        {
            template_id: 552536,
            name: "Armor of Courage"
        },
        {
            template_id: 552534,
            name: "Robe of Archmage"
        },
        {
            template_id: 552533,
            name: "Robe of Archmage"
        },
        {
            template_id: 552532,
            name: "Robe of Archmage"
        },
        {
            template_id: 552530,
            name: "Robe of Archmage"
        },
        {
            template_id: 552513,
            name: "Mantle of Healing Aura"
        },
        {
            template_id: 552511,
            name: "Mantle of Healing Aura"
        },
        {
            template_id: 552509,
            name: "Mantle of Healing Aura"
        },
        {
            template_id: 552507,
            name: "Mantle of Healing Aura"
        },
        {
            template_id: 552278,
            name: "Tactical Engineering Armor"
        },
        {
            template_id: 552276,
            name: "Tactical Engineering Armor"
        },
        {
            template_id: 552275,
            name: "Tactical Engineering Armor"
        },
        {
            template_id: 552274,
            name: "Tactical Engineering Armor"
        },
        {
            template_id: 552270,
            name: "Armor of the Vast Mind"
        },
        {
            template_id: 552269,
            name: "Armor of the Vast Mind"
        },
        {
            template_id: 552268,
            name: "Armor of the Vast Mind"
        },
        {
            template_id: 552267,
            name: "Armor of the Vast Mind"
        },
        {
            template_id: 552260,
            name: "Incredible Alchemy Armor"
        },
        {
            template_id: 552257,
            name: "Incredible Alchemy Armor"
        },
        {
            template_id: 552255,
            name: "Incredible Alchemy Armor"
        },
        {
            template_id: 552253,
            name: "Incredible Alchemy Armor"
        },
        {
            template_id: 552252,
            name: "Incredible Alchemy Armor"
        },
        {
            template_id: 552251,
            name: "Incredible Alchemy Armor"
        },
        {
            template_id: 552244,
            name: "Armor of Multiverse"
        },
        {
            template_id: 552241,
            name: "Armor of Multiverse"
        },
        {
            template_id: 552240,
            name: "Armor of Multiverse"
        },
        {
            template_id: 552239,
            name: "Armor of Multiverse"
        },
        {
            template_id: 551947,
            name: "Armor of Insane Suffering"
        },
        {
            template_id: 551946,
            name: "Armor of Insane Suffering"
        },
        {
            template_id: 551944,
            name: "Armor of Insane Suffering"
        },
        {
            template_id: 551943,
            name: "Armor of Insane Suffering"
        },
        {
            template_id: 551863,
            name: "Audience Favorite Armor"
        },
        {
            template_id: 551862,
            name: "Audience Favorite Armor"
        },
        {
            template_id: 551861,
            name: "Audience Favorite Armor"
        },
        {
            template_id: 551860,
            name: "Audience Favorite Armor"
        },
        {
            template_id: 551826,
            name: "Night Hunter Armor"
        },
        {
            template_id: 551825,
            name: "Night Hunter Armor"
        },
        {
            template_id: 551824,
            name: "Night Hunter Armor"
        },
        {
            template_id: 551823,
            name: "Night Hunter Armor"
        },
        {
            template_id: 551223,
            name: "Steel Soul Armor"
        },
        {
            template_id: 551222,
            name: "Steel Soul Armor"
        },
        {
            template_id: 551221,
            name: "Steel Soul Armor"
        },
        {
            template_id: 551220,
            name: "Steel Soul Armor"
        },
        {
            template_id: 551039,
            name: "Improved Tree Spirit Armor"
        },
        {
            template_id: 551037,
            name: "Improved Tree Spirit Armor"
        },
        {
            template_id: 551035,
            name: "Improved Tree Spirit Armor"
        },
        {
            template_id: 551034,
            name: "Improved Tree Spirit Armor"
        },
        {
            template_id: 551029,
            name: "Thoughtful Apron"
        },
        {
            template_id: 551028,
            name: "Thoughtful Apron"
        },
        {
            template_id: 551027,
            name: "Thoughtful Apron"
        },
        {
            template_id: 551026,
            name: "Thoughtful Apron"
        },
        {
            template_id: 551013,
            name: "Armor of Accurate Shooter"
        },
        {
            template_id: 551011,
            name: "Armor of Accurate Shooter"
        },
        {
            template_id: 551010,
            name: "Armor of Accurate Shooter"
        },
        {
            template_id: 551008,
            name: "Armor of Accurate Shooter"
        },
        {
            template_id: 550675,
            name: "Stylish Robe"
        },
        {
            template_id: 550674,
            name: "Stylish Robe"
        },
        {
            template_id: 550672,
            name: "Stylish Robe"
        },
        {
            template_id: 550671,
            name: "Stylish Robe"
        },
        {
            template_id: 550668,
            name: "Armor of Valor"
        },
        {
            template_id: 550667,
            name: "Armor of Valor"
        },
        {
            template_id: 550666,
            name: "Armor of Valor"
        },
        {
            template_id: 550665,
            name: "Armor of Valor"
        },
        {
            template_id: 550663,
            name: "Robe of Guardian of Magic"
        },
        {
            template_id: 550662,
            name: "Robe of Guardian of Magic"
        },
        {
            template_id: 550661,
            name: "Robe of Guardian of Magic"
        },
        {
            template_id: 550660,
            name: "Robe of Guardian of Magic"
        },
        {
            template_id: 550653,
            name: "Cape of Healing"
        },
        {
            template_id: 550652,
            name: "Cape of Healing"
        },
        {
            template_id: 550651,
            name: "Cape of Healing"
        },
        {
            template_id: 550649,
            name: "Cape of Healing"
        },
        {
            template_id: 550642,
            name: "Convenient Tool Holder"
        },
        {
            template_id: 550640,
            name: "Convenient Tool Holder"
        },
        {
            template_id: 550639,
            name: "Convenient Tool Holder"
        },
        {
            template_id: 550638,
            name: "Convenient Tool Holder"
        },
        {
            template_id: 550610,
            name: "Robes of Loremaster"
        },
        {
            template_id: 550609,
            name: "Robes of Loremaster"
        },
        {
            template_id: 550608,
            name: "Robes of Loremaster"
        },
        {
            template_id: 550607,
            name: "Robes of Loremaster"
        },
        {
            template_id: 550440,
            name: "Simple Alchemy Armor"
        },
        {
            template_id: 550439,
            name: "Simple Alchemy Armor"
        },
        {
            template_id: 550438,
            name: "Simple Alchemy Armor"
        },
        {
            template_id: 550437,
            name: "Simple Alchemy Armor"
        },
        {
            template_id: 550270,
            name: "Armor of Two Worlds"
        },
        {
            template_id: 550269,
            name: "Armor of Two Worlds"
        },
        {
            template_id: 550268,
            name: "Armor of Two Worlds"
        },
        {
            template_id: 550266,
            name: "Armor of Two Worlds"
        },
        {
            template_id: 550257,
            name: "Pain Keeper Armor"
        },
        {
            template_id: 550256,
            name: "Pain Keeper Armor"
        },
        {
            template_id: 550255,
            name: "Pain Keeper Armor"
        },
        {
            template_id: 550253,
            name: "Pain Keeper Armor"
        },
        {
            template_id: 550252,
            name: "Fashionable Leather Jacket"
        },
        {
            template_id: 550251,
            name: "Fashionable Leather Jacket"
        },
        {
            template_id: 550250,
            name: "Fashionable Leather Jacket"
        },
        {
            template_id: 550249,
            name: "Fashionable Leather Jacket"
        },
        {
            template_id: 550247,
            name: "Cape of Stealth"
        },
        {
            template_id: 550246,
            name: "Cape of Stealth"
        },
        {
            template_id: 550244,
            name: "Cape of Stealth"
        },
        {
            template_id: 550243,
            name: "Cape of Stealth"
        },
        {
            template_id: 549892,
            name: "Armor of Brave"
        },
        {
            template_id: 549890,
            name: "Armor of Brave"
        },
        {
            template_id: 549889,
            name: "Armor of Brave"
        },
        {
            template_id: 549887,
            name: "Armor of Brave"
        },
        {
            template_id: 549884,
            name: "Tree Spirit Armor"
        },
        {
            template_id: 549882,
            name: "Tree Spirit Armor"
        },
        {
            template_id: 549880,
            name: "Tree Spirit Armor"
        },
        {
            template_id: 549879,
            name: "Tree Spirit Armor"
        },
        {
            template_id: 549878,
            name: "Light Apron"
        },
        {
            template_id: 549877,
            name: "Light Apron"
        },
        {
            template_id: 549876,
            name: "Light Apron"
        },
        {
            template_id: 549874,
            name: "Light Apron"
        },
        {
            template_id: 549871,
            name: "True Shot Armor"
        },
        {
            template_id: 549870,
            name: "True Shot Armor"
        },
        {
            template_id: 549869,
            name: "True Shot Armor"
        },
        {
            template_id: 549868,
            name: "True Shot Armor"
        },
        {
            template_id: 549867,
            name: "Light Robe"
        },
        {
            template_id: 549866,
            name: "Light Robe"
        },
        {
            template_id: 549864,
            name: "Light Robe"
        },
        {
            template_id: 549863,
            name: "Light Robe"
        },
        {
            template_id: 549861,
            name: "Armor of Honor"
        },
        {
            template_id: 549860,
            name: "Armor of Honor"
        },
        {
            template_id: 549859,
            name: "Armor of Honor"
        },
        {
            template_id: 549857,
            name: "Armor of Honor"
        },
        {
            template_id: 549855,
            name: "Plain Mantle"
        },
        {
            template_id: 549854,
            name: "Plain Mantle"
        },
        {
            template_id: 549853,
            name: "Plain Mantle"
        },
        {
            template_id: 549852,
            name: "Plain Mantle"
        },
        {
            template_id: 549851,
            name: "Light Cape"
        },
        {
            template_id: 549850,
            name: "Light Cape"
        },
        {
            template_id: 549849,
            name: "Light Cape"
        },
        {
            template_id: 549848,
            name: "Light Cape"
        },
        {
            template_id: 549846,
            name: "Lightweight Tool Holder"
        },
        {
            template_id: 549845,
            name: "Lightweight Tool Holder"
        },
        {
            template_id: 549844,
            name: "Lightweight Tool Holder"
        },
        {
            template_id: 549843,
            name: "Lightweight Tool Holder"
        },
        {
            template_id: 549603,
            name: "Robe of Sage"
        },
        {
            template_id: 549601,
            name: "Robe of Sage"
        },
        {
            template_id: 549599,
            name: "Robe of Sage"
        },
        {
            template_id: 549598,
            name: "Robe of Sage"
        },
        {
            template_id: 549593,
            name: "Armor of Forest"
        },
        {
            template_id: 549592,
            name: "Armor of Forest"
        },
        {
            template_id: 549590,
            name: "Armor of Forest"
        },
        {
            template_id: 549589,
            name: "Armor of Forest"
        },
        {
            template_id: 549588,
            name: "Protective Hug"
        },
        {
            template_id: 549587,
            name: "Protective Hug"
        },
        {
            template_id: 549586,
            name: "Protective Hug"
        },
        {
            template_id: 549585,
            name: "Protective Hug"
        },
        {
            template_id: 549583,
            name: "Fearless Light Armor"
        },
        {
            template_id: 549582,
            name: "Fearless Light Armor"
        },
        {
            template_id: 549580,
            name: "Fearless Light Armor"
        },
        {
            template_id: 549579,
            name: "Fearless Light Armor"
        },
        {
            template_id: 549576,
            name: "Light Leather Jacket"
        },
        {
            template_id: 549575,
            name: "Light Leather Jacket"
        },
        {
            template_id: 549574,
            name: "Light Leather Jacket"
        },
        {
            template_id: 549573,
            name: "Light Leather Jacket"
        },
        {
            template_id: 549572,
            name: "Plain Cape"
        },
        {
            template_id: 549570,
            name: "Plain Cape"
        },
        {
            template_id: 549569,
            name: "Plain Cape"
        },
        {
            template_id: 549568,
            name: "Plain Cape"
        }
    ]

    var jewtit;
    switch (props.modalJew.jew.tmpl) {
        case 412726: case 412728: case 412730: case 412732: case 412733: case 412735:
        case 412736: case 412737: case 412738: case 412739: case 412740: case 412741:
        case 412750: case 412751: case 412753: case 412754: case 412755: case 412757:
        case 413107: case 413108: case 413111: case 413112: case 413114: case 413115:
            jewtit = 'ring';
            break;
        case 413131: case 413133: case 413135: case 413136: case 413139: case 413141:
        case 413857: case 413859: case 413860: case 413862: case 413863: case 413865:
        case 413867: case 413868: case 413869: case 413872: case 413874: case 413877:
        case 414774: case 414776: case 414778: case 414782: case 414783: case 414784:
            jewtit = 'Necklace';
            break;
        case 414785: case 414786: case 414798: case 414800: case 414801: case 414804:
        case 415378: case 415381: case 415385: case 415388: case 415389: case 415392:
        case 415402: case 415403: case 415404: case 415406: case 415407: case 415408:
        case 415409: case 415575: case 415576: case 415577: case 415578: case 415579:
            jewtit = 'Bracelet';
            break;
        case 416477: case 416478: case 416479: case 416482: case 416483: case 416486:
        case 416488: case 416489: case 416490: case 416491: case 416492: case 416493:
        case 417238: case 417239: case 417240: case 417241: case 417242: case 417244:
        case 417251: case 417252: case 417253: case 417254: case 417257: case 417259:
            jewtit = 'Brooch';
            break;
        case 428200: case 428202: case 428204: case 428207: case 428213: case 428215:
        case 428220: case 428222: case 428225: case 428226: case 428527: case 428529:
        case 428540: case 428543: case 428546: case 428548: case 428550: case 428553:
        case 429272: case 429274: case 429275: case 429276: case 429277: case 429295:
            jewtit = 'Earring';
            break;
    }

    //var armortit;
    let armortit = armorArrey.find(o => o.template_id === props.modalJew.jew.tmpl);

    //console.log("props.modalJew.jew.tmpl", obj)


    return (
        <>
            {props.modalJew.jew !== null ?
                <div className={`modal-weapon ${props.modalJew.openModal ? "open" : null} ${props.modalJew.showAnimModal ? " anim" : null}`}>
                    <div className="modal-weapon-body">
                        <div className="modal-weapon-body-in">

                            <div className="modal-weapon-head">

                                <div className="modal-weapon-top">
                                    <button className="modal-weapon-close" onClick={() => props.showCloseModalJew('close')}><span></span></button>
                                    <picture>
                                        <source srcSet={`${modaltop}`} type="image/webp" />
                                        <source srcSet={`${modaltopwebp}`} type="image/png" />
                                        <img src={`${modaltopwebp}`} alt="" />
                                    </picture>
                                </div>
                                <div className={`modal-weapon-wrap ${jewtit ? "jew-mod-part" : "armor-mod-part"}`}>
                                    <div className={`can-fix tooltip ${props.modalJew.jewEnergy === 0 ? 'active' : ''}`} onClick={() => props.addEnergyJew(props.modalJew.jew.asset_id, props.modalJew.jew.panda_id, jewtit)}>
                                        <div className="cust-tooltip bottom">
                                            <div className="tool-arrow"></div>
                                            <div className="tool-wrap">
                                                <div className="tool-wrap-1">
                                                    <div className="tool-wrap-2">
                                                        <div className="tool-body">

                                                            <>repair {jewtit ? "jewelry" : "armor"} <br />{props.modalJew.cost} {process.env.REACT_APP_VAL}</>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="modal-weapon-tit">
                                        {jewtit}
                                        {armortit != undefined && armortit.name}
                                    </div>

                                    <div className="modal-weapon-thumb">
                                        <div className={`modal-weapon-thumb-in ${props.modalJew.jewEnergy === 0 ? "dis" : null}`}>
                                            <picture>
                                                {/* <source srcSet={`./images/${jewtit ? "jew" : "armor"}/all/${props.modalJew.jew.tmpl}.webp`} type="image/webp" />
                                        <source srcSet={`./images/${jewtit ? "jew" : "armor"}/all/${props.modalJew.jew.tmpl}.png`} type="image/png" />  */}
                                                <img src={`./images/${jewtit ? "jew" : "armor"}/all/${props.modalJew.jew.tmpl}.png`} alt="" />
                                            </picture>
                                            {/* <div className="modal-weapon-thumb-text">
                                        common
                                    </div>  */}
                                        </div>
                                        {props.modalJew.jewEnergy === 0 ?
                                            <div className="broken">
                                                <picture>
                                                    {/* <source srcSet={`./images/weapons/all/${props.modalJew.weapon.template.template_id}.webp`} type="image/webp" /> */}
                                                    <source srcSet={needtorepair} type="image/png" />
                                                    <img src={needtorepair} alt="" />
                                                </picture>
                                            </div>
                                            : null}
                                    </div>

                                    <div className="strength">
                                        <div className="strength-name">
                                            durability
                                        </div>
                                        <div className="strength-perc">
                                            <div className="strength-perc-wrap">
                                                <div className="strength-perc-in" >
                                                    <div className="line-prog" style={{ "width": "" + `${props.modalJew.jewEnergy / 100}` + "%" }}></div>
                                                </div>
                                                <div className="strength-perc-number">
                                                    <span>
                                                        {props.modalJew.jewEnergy / 100}
                                                    </span>
                                                    %
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal-weapon-bt">
                                        {/* <button className="def-bt min" >
                                    <span className="button-in">
                                        <span className="button-name">
                                            <span className="button-name-in">
                                                <span className="button-name-value">
                                                    change
                                                </span> 
                                            </span>
                                        </span>
                                    </span>
                                </button> */}
                                        <button className="def-bt min" onClick={(e) => { props.sendOutJew(props.modalJew.jew, jewtit ? "jew" : "armor"); }}>
                                            <span className="button-in">
                                                <span className="button-name">
                                                    <span className="button-name-in">
                                                        <span className="button-name-value">
                                                            unequip
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </button>
                                    </div>

                                    <div className="bot-ico-modal">

                                    </div>
                                </div>
                                <div className="modal-weapon-bot">
                                    <picture>
                                        <source srcSet={`${modalbotwebp}`} type="image/webp" />
                                        <source srcSet={`${modalbot}`} type="image/png" />
                                        <img src={`${modalbot}`} alt="" />
                                    </picture>
                                </div>
                            </div>
                        </div>

                        <div className="modal-weapon-bg-trans" onClick={() => props.showCloseModalJew()}></div>
                    </div>

                    <div className="modal-weapon-bg" ></div>
                </div>
                :
                null}






        </>);
}

export default ModalOneJew;