import React from 'react';
import useGetAllMedalInGame from './../../Get/useGetAllMedalInGame'

import chest from '../../../images/box-2x.png';
import chest1 from '../../../images/box-1-2x.png';
import chest2 from '../../../images/box-2-2x.png';
import chest3 from '../../../images/box-3-2x.png';
import chest4 from '../../../images/box-4-2x.png';
import chest5 from '../../../images/box-5-2x.png';
import chest6 from '../../../images/box-6-2x.png';
import chest7 from '../../../images/box-7-2x.png';
import chest8 from '../../../images/box-8-2x.png';
import chest9 from '../../../images/box-9-2x.png';
import rewbot from '../../../images/new-adv/mult-res-bot.png';
import rewbotw from '../../../images/new-adv/mult-res-bot.webp';

import pandaReww from '../../../images/adv-info-panda.webp';
import pandaRew from '../../../images/adv-info-panda.png';

import pandaWeaponw from '../../../images/adv-info-weapon.webp';
import pandaWeapon from '../../../images/adv-info-weapon.png';

import pandaJeww from '../../../images/adv-info-jew.webp';
import pandaJew from '../../../images/adv-info-jew.png';

import pandaArmorw from '../../../images/adv-info-armor.webp';
import pandaArmor from '../../../images/adv-info-armor.png';

import pandaExpw from '../../../images/adv-info-exp.webp';
import pandaExp from '../../../images/adv-info-exp.png';


function backside(num) {
    //console.log(4);
    var items = document.getElementsByClassName('one-slot');
    items[num].classList.toggle("active");
}

const DetalLoop = (props) => {

    const { allMedalInGame } = useGetAllMedalInGame(props.user);

    var skillsName = [
        {
            name: "elder"
        },
        {
            name: "healer"
        },
        {
            name: "priest"
        },
        {
            name: "dreneya"
        },
        {
            name: "druid"
        },
        {
            name: "mage"
        },
        {
            name: "tauren"
        },
        {
            name: "smith"
        },
        {
            name: "engineer"
        },
        {
            name: "berserker"
        },
        {
            name: "bard"
        },
        {
            name: "shooter"
        },
        {
            name: "archer"
        },
        {
            name: "warrior"
        },
        {
            name: "paladin"
        }
    ]

    return (<>
        {console.log("props.multiResult", props.multiResult)}
        {props.multiResult.map((item, index) => {
            var panda;



            for (let a = 0; a < props.pandaSlots.length; a++) {
                if (item.asset_id === props.pandaSlots[a].asset_id) {

                    panda = props.pandaSlots[a];

                    var num1 = item.finalnumber;
                    var pandaRar = panda.rarity;  // (NFT) rare
                    var pandaLvl = panda.lvl; // (РАНГ) 8.67 далдно быть застейкано
                    var pandaSt = panda.stake_count; // берес количество застейканого бамам на панде
                    var pandaEnergy = panda.energy;
                    var pandaWeapon = panda.weapon;
                    var pandaJew = panda.jew;
                    var pandaArmor = panda.armor;
                    var energy = 500;
                    if (props.duration == 8 && props.typeReward == 'bamboo') {
                        energy = 2000;
                    } else if (props.duration == 12 && props.typeReward == 'bamboo') {
                        energy = 4000;
                    }



                    var pandaWeaponEnergy;
                    var pandaJewEnergy;
                    var pandaArmorEnergy;
                    if (pandaWeapon) {
                        pandaWeaponEnergy = pandaWeapon.energy;
                    }
                    if (pandaJew) {
                        pandaJewEnergy = pandaJew.energy;
                    }
                    if (pandaArmor) {
                        pandaArmorEnergy = pandaArmor.energy;
                    }
                    var percStake = 1;
                    var numberNew = props.resultRew.numberNew;
                    //var pandaRarity = props.resultRew.numberNft[pandaRar] / props.resultRew.allCardCount; // редкость
                    //var pandaBamsum = props.resultRew.baz * (1 / pandaRarity); // награда за поход

                    var pandaBamsum = numberNew[pandaRar];


                    // console.log("panda", panda);
                    // console.log("pandaRar", pandaRar);
                    // console.log("pandaBamsum", pandaBamsum);
                    // console.log("numberNew", numberNew);


                    var howmcount = pandaBamsum * (props.resultRew.steps[pandaLvl]) * percStake;
                    var showBam = howmcount.toFixed(2);
                    var showBamBig = showBam * 10000;

                    var lvlLoop = pandaLvl;
                    var pandaStake = {
                        "0": [0, 8700, 22900, 45800, 82900, 142900, 240000, 397100, 651300, 1062600, 1728100, 2804900, 4547100],
                        "1": [0, 14400, 37800, 75600, 136800, 235800, 395800, 655000, 1074400, 1753000, 2851000, 4627600, 7502200],
                        "2": [0, 17700, 46600, 93300, 168900, 291100, 488900, 808900, 1326700, 2164500, 3520100, 5713500, 9262400],
                        "3": [0, 48000, 126000, 252000, 456000, 786000, 1320000, 2184000, 3582000, 5844000, 9504000, 15426000, 25008000],
                        "4": [0, 80000, 210000, 420000, 760000, 1310000, 2200000, 3640000, 5970000, 9740000, 15840000, 25710000, 41680000],
                        "5": [0, 320000, 840000, 1680000, 3040000, 5240000, 8800000, 14560000, 23880000, 38960000, 63360000, 102840000, 166720000],
                        "6": [0, 960000, 2520000, 5040000, 9120000, 15720000, 26400000, 43680000, 71640000, 116880000, 190080000, 308520000, 500160000]
                    }

                    var howmcount = pandaStake[pandaRar][pandaLvl];
                    var youmastadd = (howmcount - pandaSt) / 10000; // вы должны добавитть бам
                    var lvlLoop = pandaLvl; // левел для цыкла
                    var weaponPay = 0;
                    var armorPay = 0;
                    var jewPay = 0;
                    var pandaProfit = (pandaBamsum + (pandaBamsum * props.resultRew.rangPercProfit[lvlLoop]));
                    var pandaProfitWithWeapon = (pandaBamsum + (pandaBamsum * props.resultRew.rangPercProfit[lvlLoop])) + weaponPay;
                    var pandaProfitWithJew = (pandaBamsum + (pandaBamsum * props.resultRew.rangPercProfit[lvlLoop])) + jewPay;
                    var pandaProfitWithArmor = (pandaBamsum + (pandaBamsum * props.resultRew.rangPercProfit[lvlLoop])) + armorPay;
                    var payout_amount;
                    var payout_amount_all;
                    var payout_amountWithWeapon;
                    var payout_amountWithJew;
                    var payout_amountWithArmor;
                    var animNumber;
                    var finelNumbNew;
                    var pandaProfitAll = 0;

                    // res

                    var resWood = 0,
                        resSteel = 0,
                        resTitawin = 0,
                        resKvessir = 0,
                        resGold = 0,
                        resDwemer = 0;
                    var pandaPercRes = 20,
                        weaponPercRes = 20,
                        armorPercRes = 40;
                    var endStory = 0;

                    // stones
                    var stonePerc = 0;
                    var pandaPercStone = 25,
                        weaponPercStone = 25,
                        armorPercStone = 30;
                    var pandaWeaponRar = pandaWeapon.rarity;
                    var pandaArmorRar = pandaArmor.rarity;

                    if (pandaWeapon && pandaJew && pandaArmor) {
                        if (pandaRar != 0) {
                            pandaRar = pandaRar - 1;
                        }

                        if (pandaWeapon.rarity >= pandaRar && pandaJew.rarity >= pandaRar && pandaArmor.rarity >= pandaRar) {
                            energy = 400;
                            if (props.duration == 12) {
                                energy = 1800;
                            }
                        }
                    }

                    for (var i = pandaLvl; i >= 0; i--) {
                        //howmcount = pandaBamsum * (props.resultRew.steps[lvlLoop]) * percStake; 
                        howmcount = pandaStake[pandaRar][pandaLvl]

                        if (pandaSt < howmcount) {
                            lvlLoop = i;
                        }
                    }

                    var weaponPay = 0;
                    var jewPay = 0;
                    var armorPay = 0;
                    var finelNumbNew = 0;
                    var pandaProfit;
                    var pandaProfitWithWeapon;
                    var pandaProfitWithJew;
                    var pandaProfitWithArmor;
                    var payout_amount;
                    var payout_amountWithWeapon;
                    var payout_amountWithJew;
                    var payout_amountWithArmor;
                    var animNumber = 0;
                    var countExp = 0;
                    var rewardX = 1;

                    // res
                    var yourResRew;
                    var resWood = 0,
                        resSteel = 0,
                        resTitawin = 0,
                        resKvessir = 0,
                        resGold = 0,
                        resDwemer = 0;
                    var pandaPercRes = 15,
                        weaponPercRes = 15,
                        armorPercRes = 15;
                    var endStory = 0;

                    var yourResRewStone = [];
                    var stonePerc = 0;
                    var pandaPercStone = 20,
                        weaponPercStone = 20,
                        armorPercStone = 24;
                    var pandaWeaponRar = pandaWeapon.rarity;
                    var typebook;






                    if (props.typeReward === "bamboo") {

                        if (props.duration == 8) {
                            rewardX = 2;
                            energy = 2000;
                        } else if (props.duration == 12) {
                            rewardX = 3;
                            energy = 4000;
                        }

                        if (pandaWeapon && pandaWeaponEnergy !== 0) {
                            //console.log('this.props.pandaSlotOnGame[index][8].data.rarity', this.props.pandaSlotOnGame[index][8].data.rarity);

                            if (pandaWeaponRar === 0) {
                                weaponPay = 0.2;
                            } else if (pandaWeaponRar === 1) {
                                weaponPay = 0.33;
                            } else if (pandaWeaponRar === 2) {
                                weaponPay = 0.56;
                            } else if (pandaWeaponRar === 3) {
                                weaponPay = 1.00;
                            } else if (pandaWeaponRar === 4) {
                                weaponPay = 1.6;
                            } else if (pandaWeaponRar === 5) {
                                weaponPay = 2.8;
                            }
                        }

                        if (pandaJew !== undefined && pandaJewEnergy !== 0) {
                            //console.log('this.props.pandaSlotOnGame[index][10].data.rarity', this.props.pandaSlotOnGame[index][10].rarity);
                            var pandaJewRar = pandaJew.rarity;
                            if (pandaJewRar === 0) {
                                jewPay = 0.07;
                            } else if (pandaJewRar === 1) {
                                jewPay = 0.15;
                            } else if (pandaJewRar === 2) {
                                jewPay = 0.30;
                            } else if (pandaJewRar === 3) {
                                jewPay = 0.59;
                            } else if (pandaJewRar === 4) {
                                jewPay = 1.17;
                            } else if (pandaJewRar === 5) {
                                jewPay = 2.35;
                            }
                        }

                        if (pandaArmor && pandaArmorEnergy !== 0) {

                            var pandaArmorRar = pandaArmor.rarity;
                            if (pandaArmorRar === 0) {
                                armorPay = 0.11;
                            } else if (pandaArmorRar === 1) {
                                armorPay = 0.20;
                            } else if (pandaArmorRar === 2) {
                                armorPay = 0.33;
                            } else if (pandaArmorRar === 3) {
                                armorPay = 0.60;
                            } else if (pandaArmorRar === 4) {
                                armorPay = 1.2;
                            } else if (pandaArmorRar === 5) {
                                armorPay = 2.56;
                            }
                        }

                        pandaProfit = (pandaBamsum + (pandaBamsum * props.resultRew.rangPercProfit[lvlLoop]));
                        pandaProfitWithWeapon = (pandaBamsum + (pandaBamsum * props.resultRew.rangPercProfit[lvlLoop])) + weaponPay;
                        pandaProfitWithJew = (pandaBamsum + (pandaBamsum * props.resultRew.rangPercProfit[lvlLoop])) + jewPay;
                        pandaProfitWithArmor = (pandaBamsum + (pandaBamsum * props.resultRew.rangPercProfit[lvlLoop])) + armorPay;

                        // console.log("pandaProfit", pandaProfit);
                        // console.log("pandaProfitWithWeapon", pandaProfitWithWeapon);
                        // console.log("pandaProfitWithJew", pandaProfitWithJew);
                        // console.log("pandaProfitWithArmor", pandaProfitWithArmor);
                        var exp;
                        if (lvlLoop !== pandaLvl) {
                            exp = false;
                        } else {
                            exp = true;
                        }

                        switch (num1) {
                            case 1: case 2: case 3: case 4: case 5:
                                payout_amount = pandaProfit;
                                payout_amountWithJew = pandaProfitWithJew;
                                payout_amountWithArmor = pandaProfitWithArmor;
                                payout_amountWithWeapon = pandaProfitWithWeapon;
                                animNumber = 5;
                                if (exp) {
                                    countExp += 2 * rewardX;
                                }
                                break;
                            case 6: case 7: case 8: case 9: case 10:
                                payout_amount = pandaProfit - (pandaProfit * 0.25);
                                payout_amountWithWeapon = pandaProfitWithWeapon - (pandaProfitWithWeapon * 0.25);
                                payout_amountWithJew = pandaProfitWithJew - (pandaProfitWithJew * 0.25);
                                payout_amountWithArmor = pandaProfitWithArmor - (pandaProfitWithArmor * 0.25);
                                animNumber = 4;
                                if (exp) {
                                    countExp += 1 * rewardX;
                                }
                                break;
                            case 11: case 12: case 13: case 14: case 15:
                                payout_amount = pandaProfit - (pandaProfit * 0.50);
                                payout_amountWithWeapon = pandaProfitWithWeapon - (pandaProfitWithWeapon * 0.50);
                                payout_amountWithJew = pandaProfitWithJew - (pandaProfitWithJew * 0.50);
                                payout_amountWithArmor = pandaProfitWithArmor - (pandaProfitWithArmor * 0.50);
                                animNumber = 3;
                                if (exp) {
                                    countExp += 1 * rewardX;
                                }
                                break;
                            case 16: case 17: case 18: case 19: case 20:
                                payout_amount = pandaProfit - (pandaProfit * 0.75);
                                payout_amountWithWeapon = pandaProfitWithWeapon - (pandaProfitWithWeapon * 0.75);
                                payout_amountWithJew = pandaProfitWithJew - (pandaProfitWithJew * 0.75);
                                payout_amountWithArmor = pandaProfitWithArmor - (pandaProfitWithArmor * 0.75);
                                animNumber = 2;
                                if (exp) {
                                    countExp += 1 * rewardX;
                                }
                                break;
                            case 21: case 22: case 23: case 24: case 25:
                                payout_amount = 0;
                                payout_amountWithWeapon = 0;
                                animNumber = 1;
                                weaponPay = 0;
                                payout_amountWithJew = 0;
                                payout_amountWithArmor = 0;
                                jewPay = 0;
                                break;
                            case 26: case 27: case 28: case 29: case 30:
                                payout_amount = pandaProfit;
                                payout_amountWithWeapon = pandaProfitWithWeapon;
                                payout_amountWithJew = pandaProfitWithJew;
                                payout_amountWithArmor = pandaProfitWithArmor;
                                animNumber = 5;
                                if (exp) {
                                    countExp += 2 * rewardX;
                                }
                                break;
                            case 31: case 32: case 33: case 34: case 35:
                                payout_amount = pandaProfit + (pandaProfit * 0.25);
                                payout_amountWithWeapon = pandaProfitWithWeapon + (pandaProfitWithWeapon * 0.25);
                                payout_amountWithJew = pandaProfitWithJew + (pandaProfitWithJew * 0.25);
                                payout_amountWithArmor = pandaProfitWithArmor + (pandaProfitWithArmor * 0.25);
                                animNumber = 6;
                                if (exp) {
                                    countExp += 2 * rewardX;
                                }
                                break;
                            case 36: case 37: case 38: case 39: case 40:
                                payout_amount = pandaProfit + (pandaProfit * 0.50);
                                payout_amountWithWeapon = pandaProfitWithWeapon + (pandaProfitWithWeapon * 0.50);
                                payout_amountWithJew = pandaProfitWithJew + (pandaProfitWithJew * 0.50);
                                payout_amountWithArmor = pandaProfitWithArmor + (pandaProfitWithArmor * 0.50);
                                animNumber = 7;
                                if (exp) {
                                    countExp += 2 * rewardX;
                                }
                                break;
                            case 41: case 42: case 43: case 44: case 45:
                                payout_amount = pandaProfit + (pandaProfit * 0.75);
                                payout_amountWithWeapon = pandaProfitWithWeapon + (pandaProfitWithWeapon * 0.75);
                                payout_amountWithJew = pandaProfitWithJew + (pandaProfitWithJew * 0.75);
                                payout_amountWithArmor = pandaProfitWithArmor + (pandaProfitWithArmor * 0.75);
                                animNumber = 8;
                                if (exp) {
                                    countExp += 2 * rewardX;
                                }
                                break;
                            case 46: case 47: case 48: case 49: case 50:
                                payout_amount = pandaProfit * 2;
                                payout_amountWithWeapon = pandaProfitWithWeapon * 2;
                                payout_amountWithJew = pandaProfitWithJew * 2;
                                payout_amountWithArmor = pandaProfitWithArmor * 2;
                                animNumber = 9;
                                if (exp) {
                                    countExp += 3 * rewardX;
                                }
                                break;
                            default:
                                payout_amount = 0;
                                payout_amountWithWeapon = 0;
                                payout_amountWithJew = 0;
                                payout_amountWithArmor = 0;
                                animNumber = 0;
                                break;
                        }

                        // console.log("pandaWeapon", pandaWeapon);
                        // console.log("pandaWeaponEnergy", pandaWeaponEnergy);
                        // console.log("payout_amountWithWeapon", payout_amountWithWeapon);
                        // console.log("payout_amount", payout_amount);


                        if (pandaEnergy >= energy) {
                            finelNumbNew = parseInt(payout_amount * rewardX * 10000) / 10000;
                            weaponPay = parseInt(((payout_amountWithWeapon * rewardX) - (payout_amount * rewardX)) * 10000) / 10000;
                            jewPay = parseInt(((payout_amountWithJew * rewardX) - (payout_amount * rewardX)) * 10000) / 10000;
                            armorPay = parseInt(((payout_amountWithArmor * rewardX) - (payout_amount * rewardX)) * 10000) / 10000;
                        } else {
                            finelNumbNew = parseInt(payout_amount * rewardX * 10000 * 0.2) / 10000;
                            weaponPay = parseInt(((payout_amountWithWeapon * rewardX) - (payout_amount * rewardX)) * 10000 * 0.2) / 10000;
                            jewPay = parseInt(((payout_amountWithJew * rewardX) - (payout_amount * rewardX)) * 10000 * 0.2) / 10000;
                            armorPay = parseInt(((payout_amountWithArmor * rewardX) - (payout_amount * rewardX)) * 10000 * 0.2) / 10000;

                            /*weaponPay = weaponPay * rewardX * 0.2;
                            jewPay = jewPay * rewardX * 0.2;
                            armorPay = armorPay * rewardX * 0.2;*/
                        }

                        console.log("weaponPay", weaponPay);


                        if (pandaJew && pandaJewEnergy === 0 && rewardX == 1 ||
                            pandaJew && pandaJewEnergy < energy && rewardX == 2 ||
                            pandaJew && pandaJewEnergy < energy && rewardX == 3
                        ) {
                            jewPay = 0;
                        }

                        if (pandaArmor && pandaArmorEnergy === 0 && rewardX == 1 ||
                            pandaArmor && pandaArmorEnergy < energy && rewardX == 2 ||
                            pandaArmor && pandaArmorEnergy < energy && rewardX == 3
                        ) {
                            armorPay = 0;
                        }

                        if (pandaWeapon && pandaWeaponEnergy === 0 && rewardX == 1 ||
                            pandaWeapon && pandaWeaponEnergy < energy && rewardX == 2 ||
                            pandaWeapon && pandaWeaponEnergy < energy && rewardX == 3
                        ) {
                            weaponPay = 0;
                        }

                    } else if (props.typeReward === 'resources') {
                        console.log("pandaRar", pandaRar)
                        switch (pandaRar) {
                            case 0: case 1:
                                pandaPercRes = 20;
                                break;
                            case 2:
                                pandaPercRes = 15;
                                break;
                            case 3:
                                pandaPercRes = 10;
                                break;
                            case 4:
                                pandaPercRes = 5;
                                break;
                            case 5:
                                pandaPercRes = 2;
                                break;
                            case 6:
                                pandaPercRes = 0;
                                break;
                            default:
                                pandaPercRes = 20;
                                break;
                        }
                        // end for panda
                        console.log("pandaPercRes", pandaPercRes)

                        console.log("pandaWeaponRar", pandaWeaponRar)
                        // for weapon
                        switch (pandaWeaponRar) {
                            case 0:
                                weaponPercRes = 20;
                                break;
                            case 1:
                                weaponPercRes = 15;
                                break;
                            case 2:
                                weaponPercRes = 10;
                                break;
                            case 3:
                                weaponPercRes = 5;
                                break;
                            case 4:
                                weaponPercRes = 2;
                                break;
                            case 5:
                                weaponPercRes = 0;
                                break;
                            default:
                                weaponPercRes = 20;
                                break;
                        }
                        console.log("weaponPercRes", weaponPercRes)
                        //end for weapon

                        // armor
                        console.log("pandaArmorRar", pandaArmorRar)
                        if (pandaArmor.energy >= energy) {
                            switch (pandaArmorRar) {
                                case 0:
                                    armorPercRes = 20;
                                    break;
                                case 1:
                                    armorPercRes = 15;
                                    break;
                                case 2:
                                    armorPercRes = 10;
                                    break;
                                case 3:
                                    armorPercRes = 5;
                                    break;
                                case 4:
                                    armorPercRes = 2;
                                    break;
                                case 5:
                                    armorPercRes = 0;
                                    break;
                                default:
                                    armorPercRes = 20;
                                    break;
                            }
                        }
                        console.log("armorPercRes", armorPercRes)
                        // end armor

                        console.log("weaponPercRes + pandaPercRes + armorPercRes", weaponPercRes + pandaPercRes + armorPercRes);

                        console.log("num1", num1)

                        switch (weaponPercRes + pandaPercRes + armorPercRes) {

                            case 80:
                                resWood = 150;
                                resSteel = 40;
                                resTitawin = 8;
                                resKvessir = 2;
                                resGold = 0;
                                resDwemer = 0;
                                break;
                            case 75:
                                resWood = 185;
                                resSteel = 50;
                                resTitawin = 10;
                                resKvessir = 4;
                                resGold = 1;
                                resDwemer = 0;
                                break;
                            case 70:
                                resWood = 210;
                                resSteel = 62;
                                resTitawin = 15;
                                resKvessir = 10;
                                resGold = 2;
                                resDwemer = 1;
                                break;
                            case 65:
                                resWood = 240;
                                resSteel = 70;
                                resTitawin = 20;
                                resKvessir = 15;
                                resGold = 3;
                                resDwemer = 2;
                                break;
                            case 62:
                                resWood = 260;
                                resSteel = 80;
                                resTitawin = 20;
                                resKvessir = 15;
                                resGold = 3;
                                resDwemer = 2;
                                break;
                            case 60:
                                resWood = 270;
                                resSteel = 85;
                                resTitawin = 22;
                                resKvessir = 18;
                                resGold = 3;
                                resDwemer = 2;
                                break;
                            case 57:
                                resWood = 300;
                                resSteel = 85;
                                resTitawin = 22;
                                resKvessir = 18;
                                resGold = 3;
                                resDwemer = 2;
                                break;
                            case 55:
                                resWood = 310;
                                resSteel = 90;
                                resTitawin = 25;
                                resKvessir = 18;
                                resGold = 4;
                                resDwemer = 3;
                                break;
                            case 52:
                                resWood = 330;
                                resSteel = 95;
                                resTitawin = 28;
                                resKvessir = 19;
                                resGold = 5;
                                resDwemer = 3;
                                break;
                            case 50:
                                resWood = 340;
                                resSteel = 100;
                                resTitawin = 30;
                                resKvessir = 20;
                                resGold = 6;
                                resDwemer = 4;
                                break;
                            case 47:
                                resWood = 350;
                                resSteel = 110;
                                resTitawin = 35;
                                resKvessir = 23;
                                resGold = 7;
                                resDwemer = 5;
                                break;
                            case 45:
                                resWood = 360;
                                resSteel = 120;
                                resTitawin = 35;
                                resKvessir = 23;
                                resGold = 7;
                                resDwemer = 5;
                                break;
                            case 44:
                                resWood = 360;
                                resSteel = 123;
                                resTitawin = 38;
                                resKvessir = 25;
                                resGold = 8;
                                resDwemer = 6;
                                break;
                            case 42:
                                resWood = 370;
                                resSteel = 125;
                                resTitawin = 40;
                                resKvessir = 28;
                                resGold = 10;
                                resDwemer = 7;
                                break;
                            case 40:
                                resWood = 380;
                                resSteel = 135;
                                resTitawin = 40;
                                resKvessir = 28;
                                resGold = 10;
                                resDwemer = 7;
                                break;
                            case 37:
                                resWood = 390;
                                resSteel = 140;
                                resTitawin = 45;
                                resKvessir = 30;
                                resGold = 15;
                                resDwemer = 10;
                                break;
                            case 35:
                                resWood = 400;
                                resSteel = 150;
                                resTitawin = 45;
                                resKvessir = 30;
                                resGold = 15;
                                resDwemer = 10;
                                break;
                            case 32:
                                resWood = 400;
                                resSteel = 170;
                                resTitawin = 50;
                                resKvessir = 32;
                                resGold = 17;
                                resDwemer = 11;
                                break;
                            case 30:
                                resWood = 400;
                                resSteel = 180;
                                resTitawin = 55;
                                resKvessir = 35;
                                resGold = 18;
                                resDwemer = 12;
                                break;
                            case 27:
                                resWood = 400;
                                resSteel = 196;
                                resTitawin = 60;
                                resKvessir = 40;
                                resGold = 20;
                                resDwemer = 14;
                                break;
                            case 25:
                                resWood = 400;
                                resSteel = 200;
                                resTitawin = 70;
                                resKvessir = 44;
                                resGold = 21;
                                resDwemer = 15;
                                break;
                            case 24:
                                resWood = 410;
                                resSteel = 200;
                                resTitawin = 70;
                                resKvessir = 44;
                                resGold = 21;
                                resDwemer = 15;
                                break;
                            case 22:
                                resWood = 400;
                                resSteel = 205;
                                resTitawin = 80;
                                resKvessir = 50;
                                resGold = 25;
                                resDwemer = 20;
                                break;
                            case 20:
                                resWood = 400;
                                resSteel = 210;
                                resTitawin = 90;
                                resKvessir = 55;
                                resGold = 25;
                                resDwemer = 20;
                                break;
                            case 19:
                                resWood = 400;
                                resSteel = 210;
                                resTitawin = 90;
                                resKvessir = 60;
                                resGold = 30;
                                resDwemer = 20;
                                break;
                            case 17:
                                resWood = 390;
                                resSteel = 200;
                                resTitawin = 110;
                                resKvessir = 80;
                                resGold = 30;
                                resDwemer = 20;
                                break;
                            case 15:
                                resWood = 380;
                                resSteel = 190;
                                resTitawin = 130;
                                resKvessir = 90;
                                resGold = 40;
                                resDwemer = 20;
                                break;
                            case 14:
                                resWood = 380;
                                resSteel = 190;
                                resTitawin = 130;
                                resKvessir = 90;
                                resGold = 40;
                                resDwemer = 30;
                                break;
                            case 12:
                                resWood = 380;
                                resSteel = 190;
                                resTitawin = 130;
                                resKvessir = 100;
                                resGold = 50;
                                resDwemer = 30;
                                break;
                            case 10:
                                resWood = 370;
                                resSteel = 180;
                                resTitawin = 140;
                                resKvessir = 110;
                                resGold = 65;
                                resDwemer = 35;
                                break;
                            case 9:
                                resWood = 370;
                                resSteel = 180;
                                resTitawin = 140;
                                resKvessir = 110;
                                resGold = 70;
                                resDwemer = 40;
                                break;
                            case 7:
                                resWood = 360;
                                resSteel = 180;
                                resTitawin = 140;
                                resKvessir = 120;
                                resGold = 80;
                                resDwemer = 50;
                                break;
                            case 6:
                                resWood = 360;
                                resSteel = 180;
                                resTitawin = 140;
                                resKvessir = 120;
                                resGold = 85;
                                resDwemer = 55;
                                break;
                            case 5:
                                resWood = 350;
                                resSteel = 180;
                                resTitawin = 150;
                                resKvessir = 120;
                                resGold = 90;
                                resDwemer = 60;
                                break;
                            case 4:
                                resWood = 340;
                                resSteel = 170;
                                resTitawin = 160;
                                resKvessir = 120;
                                resGold = 100;
                                resDwemer = 70;
                                break;
                            case 2:
                                resWood = 340;
                                resSteel = 170;
                                resTitawin = 150;
                                resKvessir = 130;
                                resGold = 110;
                                resDwemer = 80;
                                break;
                            case 0:
                                resWood = 330;
                                resSteel = 160;
                                resTitawin = 150;
                                resKvessir = 140;
                                resGold = 120;
                                resDwemer = 100;
                            default:
                                break;
                        }



                        if (num1 <= resWood) {
                            yourResRew = 'wood'
                            countExp += 2;
                        } else if (num1 > resWood && num1 <= resSteel + resWood) {
                            yourResRew = 'steel'
                            countExp += 2;
                        } else if (num1 > resSteel + resWood && num1 <= resTitawin + resSteel + resWood) {
                            yourResRew = 'titawin'
                            countExp += 2;
                        } else if (num1 > resTitawin + resSteel + resWood && num1 <= resKvessir + resTitawin + resSteel + resWood) {
                            yourResRew = 'kvessir'
                            countExp += 2;
                        } else if (num1 > resKvessir + resTitawin + resSteel + resWood && num1 <= resGold + resKvessir + resTitawin + resSteel + resWood) {
                            yourResRew = 'gold'
                            countExp += 2;
                        } else if (num1 > resGold + resKvessir + resTitawin + resSteel + resWood && num1 <= resDwemer + resGold + resKvessir + resTitawin + resSteel + resWood) {
                            yourResRew = 'dwemer'
                            countExp += 2;
                        }
                        // else if (num1 > resDwemer) {
                        //     yourResRew = 'noooooooooooooo'
                        //     animNumber = 1;
                        // }

                        if (lvlLoop < 2 && animNumber == 1) {
                            countExp -= 2;
                        }

                        if (props.duration == 12) {
                            rewardX = 2
                        }


                        //return objRes;

                    } else if (props.typeReward === 'stones') {

                        switch (pandaRar) {
                            case 0: case 1:
                                pandaPercStone = 25;
                                break;
                            case 2:
                                pandaPercStone = 21;
                                break;
                            case 3:
                                pandaPercStone = 15;
                                break;
                            case 4:
                                pandaPercStone = 10;
                                break;
                            case 5:
                                pandaPercStone = 5;
                                break;
                            case 6:
                                pandaPercStone = 0;
                                break;
                            default:
                                pandaPercStone = 25;
                                break;
                        }
                        // end for panda

                        // for weapon
                        switch (pandaWeaponRar) {
                            case 0:
                                weaponPercStone = 25;
                                break;
                            case 1:
                                weaponPercStone = 21;
                                break;
                            case 2:
                                weaponPercStone = 15;
                                break;
                            case 3:
                                weaponPercStone = 10;
                                break;
                            case 4:
                                weaponPercStone = 5;
                                break;
                            case 5:
                                weaponPercStone = 0;
                                break;
                            default:
                                weaponPercStone = 25;
                                break;
                        }

                        if (pandaArmor && pandaArmorEnergy !== 0) {
                            var pandaArmorRar = pandaArmor.rarity;
                            //console.log("gems pandaArmorRar", pandaArmorRar);
                            switch (pandaArmorRar) {
                                case 0:
                                    armorPercStone = 25;
                                    break;
                                case 1:
                                    armorPercStone = 21;
                                    break;
                                case 2:
                                    armorPercStone = 15;
                                    break;
                                case 3:
                                    armorPercStone = 10;
                                    break;
                                case 4:
                                    armorPercStone = 5;
                                    break;
                                case 5:
                                    armorPercStone = 0;
                                    break;
                                default:
                                    armorPercStone = 25;
                                    break;
                            }
                        }


                        stonePerc = parseInt(((100 - weaponPercStone - pandaPercStone - armorPercStone) * 10) / 20)

                        if (num1 <= stonePerc) { // 400
                            yourResRewStone.push({ name: 'tihash', element: 'earth' });
                            countExp += 2;
                        } else if (num1 > stonePerc && num1 <= stonePerc * 2) {
                            yourResRewStone.push({ name: 'tihash', element: 'wind' });
                            countExp += 2;
                        } else if (num1 > stonePerc * 2 && num1 <= stonePerc * 3) {
                            yourResRewStone.push({ name: 'tihash', element: 'fire' });
                            countExp += 2;
                        } else if (num1 > stonePerc * 3 && num1 <= stonePerc * 4) {
                            yourResRewStone.push({ name: 'tihash', element: 'water' });
                            countExp += 2;
                        }

                        else if (num1 > stonePerc * 4 && num1 <= stonePerc * 5) {
                            yourResRewStone.push({ name: 'pliacrin', element: 'earth' });
                            countExp += 2;
                        }
                        else if (num1 > stonePerc * 5 && num1 <= stonePerc * 6) {
                            yourResRewStone.push({ name: 'pliacrin', element: 'wind' });
                            countExp += 2;
                        }
                        else if (num1 > stonePerc * 6 && num1 <= stonePerc * 7) {
                            yourResRewStone.push({ name: 'pliacrin', element: 'fire' });
                            countExp += 2;
                        }
                        else if (num1 > stonePerc * 7 && num1 <= stonePerc * 8) {
                            yourResRewStone.push({ name: 'pliacrin', element: 'water' });
                            countExp += 2;
                        }

                        else if (num1 > stonePerc * 8 && num1 <= stonePerc * 9) {
                            yourResRewStone.push({ name: 'kyanite', element: 'earth' });
                            countExp += 2;
                        }
                        else if (num1 > stonePerc * 9 && num1 <= stonePerc * 10) {
                            yourResRewStone.push({ name: 'kyanite', element: 'wind' });
                            countExp += 2;
                        }
                        else if (num1 > stonePerc * 10 && num1 <= stonePerc * 11) {
                            yourResRewStone.push({ name: 'kyanite', element: 'fire' });
                            countExp += 2;
                        }
                        else if (num1 > stonePerc * 11 && num1 <= stonePerc * 12) {
                            yourResRewStone.push({ name: 'kyanite', element: 'water' });
                            countExp += 2;
                        }

                        else if (num1 > stonePerc * 12 && num1 <= stonePerc * 13) {
                            yourResRewStone.push({ name: 'indicolite', element: 'earth' });
                            countExp += 2;
                        }
                        else if (num1 > stonePerc * 13 && num1 <= stonePerc * 14) {
                            yourResRewStone.push({ name: 'indicolite', element: 'wind' });
                            countExp += 2;
                        }
                        else if (num1 > stonePerc * 14 && num1 <= stonePerc * 15) {
                            yourResRewStone.push({ name: 'indicolite', element: 'fire' });
                            countExp += 2;
                        }
                        else if (num1 > stonePerc * 15 && num1 <= stonePerc * 16) {
                            yourResRewStone.push({ name: 'indicolite', element: 'water' });
                            countExp += 2;
                        }

                        else if (num1 > stonePerc * 16 && num1 <= stonePerc * 17) {
                            yourResRewStone.push({ name: 'apatite', element: 'earth' });
                            countExp += 2;
                        }
                        else if (num1 > stonePerc * 17 && num1 <= stonePerc * 18) {
                            yourResRewStone.push({ name: 'apatite', element: 'wind' });
                            countExp += 2;
                        }
                        else if (num1 > stonePerc * 18 && num1 <= stonePerc * 19) {
                            yourResRewStone.push({ name: 'apatite', element: 'fire' });
                            countExp += 2;
                        }
                        else if (num1 > stonePerc * 19 && num1 <= stonePerc * 20) {
                            yourResRewStone.push({ name: 'apatite', element: 'water' });
                            countExp += 2;
                        } else if (num1 > stonePerc * 20) {
                            //yourResRewStone.push({name: 'noooooooooooooo'});
                            animNumber = 1;
                        }
                        console.log("yourResRewStone", yourResRewStone);

                        if (lvlLoop < 2 && animNumber == 1) {
                            countExp -= 2;
                        }

                        if (props.duration == 12) {
                            rewardX = 2
                        }

                    } else if (props.typeReward === 'books') {

                        var totalLuck = 20;

                        if (allMedalInGame && allMedalInGame.medal10 != undefined && allMedalInGame.medal10) {
                            totalLuck = 35;
                        } else if (allMedalInGame && allMedalInGame.medal100 != undefined && allMedalInGame.medal100) {
                            totalLuck = 30;
                        } else if (allMedalInGame && allMedalInGame.medal300 != undefined && allMedalInGame.medal300) {
                            totalLuck = 25;
                        }


                        if (num1 < totalLuck) {
                            typebook = 0;
                        } else if (num1 >= totalLuck * 15) {
                            typebook = 999;
                            animNumber = 1;
                        } else {
                            typebook = Math.floor(num1 / totalLuck) - 1;
                        }

                        console.log("typebook", typebook);

                        var manyaAdd = props.duration == 12 ? 2 : props.duration == 6 ? 1 : 0;

                        // if (typebook < 15) {
                        //     skillsName[typebook].count += manyaAdd;
                        // }

                        if (animNumber == 0) {
                            countExp = 1;
                        }


                        if (props.duration == 12) {
                            rewardX = 2
                        }

                    }

                    return (
                        <div className="one-loop-res">
                            <div className="one-loop-res-wrap">
                                <div className="one-loop-res-in">
                                    <div className="one-loop-res-num">
                                        #{index + 1}
                                        {/* <br /> 
                                        id{panda.asset_id} 
                                        <br /> 
                                        id{num1}  */}
                                    </div>
                                    <div className="one-loop-res-thumb">
                                        <div className="one-loop-res-thumb-in">
                                            <picture>
                                                <source srcSet={`./images/card-1x/${panda.name_pa}.webp`} type="image/webp" />
                                                <source srcSet={`./images/card-1x/${panda.name_pa}.png`} type="image/png" />
                                                <img src={`./images/card-1x/${panda.name_pa}.png`} alt="" />
                                            </picture>
                                        </div>
                                    </div>
                                    <div className="one-loop-res-descr">
                                        <div className="one-loop-res-nums">
                                            {props.typeReward === "bamboo" &&
                                                <div className="one-loop-res-bam">
                                                    <div className="one-loop-res-chest">
                                                        <div className={`chest-wrap-in act${animNumber}`}>
                                                            <img className="chest" src={chest} alt="" />
                                                            {animNumber === 1 ? <img className={`chest1 active`} src={chest1} alt="" /> : null}
                                                            {animNumber === 2 ? <img className={`chest2 active`} src={chest2} alt="" /> : null}
                                                            {animNumber === 3 ? <img className={`chest3 active`} src={chest3} alt="" /> : null}
                                                            {animNumber === 4 ? <img className={`chest4 active`} src={chest4} alt="" /> : null}
                                                            {animNumber === 5 ? <img className={`chest5 active`} src={chest5} alt="" /> : null}
                                                            {animNumber === 6 ? <img className={`chest6 active`} src={chest6} alt="" /> : null}
                                                            {animNumber === 7 ? <img className={`chest7 active`} src={chest7} alt="" /> : null}
                                                            {animNumber === 8 ? <img className={`chest8 active`} src={chest8} alt="" /> : null}
                                                            {animNumber === 9 ? <img className={`chest9 active`} src={chest9} alt="" /> : null}
                                                        </div>
                                                    </div>
                                                    <div className="one-loop-res-detal-bam">
                                                        <div className="ico-list">
                                                            <div className="one-ico-item">
                                                                <div className="one-ico-item-thumb tooltip">
                                                                    <picture>
                                                                        <source srcSet={pandaReww} type="image/webp" />
                                                                        <source srcSet={pandaRew} type="image/png" />
                                                                        <img src={pandaRew} alt="" />
                                                                    </picture>
                                                                    <div className="cust-tooltip bottom">
                                                                        <div className="tool-arrow"></div>
                                                                        <div className="tool-wrap">
                                                                            <div className="tool-wrap-1">
                                                                                <div className="tool-wrap-2">
                                                                                    <div className="tool-body">
                                                                                        hero reward
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="one-ico-item-text">
                                                                    {finelNumbNew}
                                                                </div>
                                                            </div>

                                                            <div className="one-ico-item">
                                                                <div className="one-ico-item-thumb tooltip">
                                                                    <picture>
                                                                        <source srcSet={pandaWeaponw} type="image/webp" />
                                                                        <source srcSet={pandaWeapon} type="image/png" />
                                                                        <img src={pandaWeapon} alt="" />
                                                                    </picture>
                                                                    <div className="cust-tooltip bottom">
                                                                        <div className="tool-arrow"></div>
                                                                        <div className="tool-wrap">
                                                                            <div className="tool-wrap-1">
                                                                                <div className="tool-wrap-2">
                                                                                    <div className="tool-body">
                                                                                        weapon bonus
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="one-ico-item-text">
                                                                    {weaponPay}
                                                                </div>
                                                            </div>

                                                            <div className="one-ico-item">
                                                                <div className="one-ico-item-thumb tooltip">
                                                                    <picture>
                                                                        <source srcSet={pandaJeww} type="image/webp" />
                                                                        <source srcSet={pandaJew} type="image/png" />
                                                                        <img src={pandaJeww} alt="" />
                                                                    </picture>
                                                                    <div className="cust-tooltip bottom">
                                                                        <div className="tool-arrow"></div>
                                                                        <div className="tool-wrap">
                                                                            <div className="tool-wrap-1">
                                                                                <div className="tool-wrap-2">
                                                                                    <div className="tool-body">
                                                                                        jewerly bonus
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="one-ico-item-text">
                                                                    {jewPay}
                                                                </div>
                                                            </div>


                                                            <div className="one-ico-item">
                                                                <div className="one-ico-item-thumb tooltip">
                                                                    <picture>
                                                                        <source srcSet={pandaArmorw} type="image/webp" />
                                                                        <source srcSet={pandaArmor} type="image/png" />
                                                                        <img src={pandaArmorw} alt="" />
                                                                    </picture>
                                                                    <div className="cust-tooltip bottom">
                                                                        <div className="tool-arrow"></div>
                                                                        <div className="tool-wrap">
                                                                            <div className="tool-wrap-1">
                                                                                <div className="tool-wrap-2">
                                                                                    <div className="tool-body">
                                                                                        armor bonus
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="one-ico-item-text">
                                                                    {armorPay}
                                                                </div>
                                                            </div>

                                                            <div className="one-ico-item">
                                                                <div className="one-ico-item-thumb tooltip">
                                                                    <picture>
                                                                        <source srcSet={pandaExpw} type="image/webp" />
                                                                        <source srcSet={pandaExp} type="image/png" />
                                                                        <img src={pandaExp} alt="" />
                                                                    </picture>
                                                                    <div className="cust-tooltip bottom">
                                                                        <div className="tool-arrow"></div>
                                                                        <div className="tool-wrap">
                                                                            <div className="tool-wrap-1">
                                                                                <div className="tool-wrap-2">
                                                                                    <div className="tool-body">
                                                                                        gain Experience
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="one-ico-item-text">
                                                                    {countExp ? "+" + countExp : "0"}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <table>
                                                        <tr>
                                                            <td>
                                                                hero reward:
                                                            </td>
                                                            <td>
                                                                {finelNumbNew}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Weapon bonus:
                                                            </td>
                                                            <td>
                                                                {weaponPay}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                jewerly bonus:
                                                            </td>
                                                            <td>
                                                                {jewPay}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                gain Experience:
                                                            </td>
                                                            <td>
                                                                +1
                                                            </td>
                                                        </tr>
                                                    </table> */}
                                                    </div>
                                                </div>}
                                            {props.typeReward === "resources" &&
                                                <div className="one-loop-res-res">
                                                    <div className="one-loop-res-type">
                                                        {yourResRew === 'wood' &&
                                                            <div className="one-drop" >
                                                                <div className="one-drop-thumb">
                                                                    <span>
                                                                        <picture>
                                                                            <source srcSet={`./images/new-adv/res/res-common.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/new-adv/res/res-common.png`} type="image/png" />
                                                                            <img src={`./images/new-adv/res/res-common.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                </div>
                                                                <div className="one-drop-name">
                                                                    <i> +{1 * rewardX} </i> wood
                                                                </div>
                                                            </div>
                                                        }
                                                        {yourResRew === 'steel' &&
                                                            <div className="one-drop" >
                                                                <div className="one-drop-thumb">
                                                                    <span>
                                                                        <picture>
                                                                            <source srcSet={`./images/new-adv/res/res-uncommon.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/new-adv/res/res-uncommon.png`} type="image/png" />
                                                                            <img src={`./images/new-adv/res/res-uncommon.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                </div>
                                                                <div className="one-drop-name">
                                                                    <i> +{1 * rewardX} </i> steel
                                                                </div>
                                                            </div>
                                                        }
                                                        {yourResRew === 'titawin' &&
                                                            <div className="one-drop" >
                                                                <div className="one-drop-thumb">
                                                                    <span>
                                                                        <picture>
                                                                            <source srcSet={`./images/new-adv/res/res-rare.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/new-adv/res/res-rare.png`} type="image/png" />
                                                                            <img src={`./images/new-adv/res/res-rare.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                </div>
                                                                <div className="one-drop-name">
                                                                    <i> +{1 * rewardX} </i> titawin
                                                                </div>
                                                            </div>
                                                        }
                                                        {yourResRew === 'kvessir' &&
                                                            <div className="one-drop" >
                                                                <div className="one-drop-thumb">
                                                                    <span>
                                                                        <picture>
                                                                            <source srcSet={`./images/new-adv/res/res-epic.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/new-adv/res/res-epic.png`} type="image/png" />
                                                                            <img src={`./images/new-adv/res/res-epic.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                </div>
                                                                <div className="one-drop-name">
                                                                    <i> +{1 * rewardX} </i> kvessir
                                                                </div>
                                                            </div>
                                                        }
                                                        {yourResRew === 'gold' &&
                                                            <div className="one-drop" >
                                                                <div className="one-drop-thumb">
                                                                    <span>
                                                                        <picture>
                                                                            <source srcSet={`./images/new-adv/res/res-legendary.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/new-adv/res/res-legendary.png`} type="image/png" />
                                                                            <img src={`./images/new-adv/res/res-legendary.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                </div>
                                                                <div className="one-drop-name">
                                                                    <i> +{1 * rewardX} </i> gold
                                                                </div>
                                                            </div>
                                                        }
                                                        {yourResRew === 'dwemer' &&
                                                            <div className="one-drop" >
                                                                <div className="one-drop-thumb">
                                                                    <span>
                                                                        <picture>
                                                                            <source srcSet={`./images/new-adv/res/res-mythic.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/new-adv/res/res-mythic.png`} type="image/png" />
                                                                            <img src={`./images/new-adv/res/res-mythic.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                </div>
                                                                <div className="one-drop-name">
                                                                    <i> +{1 * rewardX} </i> dwemer
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="one-drop no-shadow" >
                                                            <div className="one-drop-thumb">
                                                                <span>
                                                                    <picture>
                                                                        <source srcSet={`./images/new-adv/exp.webp`} type="image/webp" />
                                                                        <source srcSet={`./images/new-adv/exp.png`} type="image/png" />
                                                                        <img src={`./images/new-adv/exp.png`} alt="" />
                                                                    </picture>
                                                                </span>
                                                            </div>
                                                            <div className="one-drop-name">
                                                                <i>+{countExp * rewardX}</i> exp
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="one-loop-res-detal-bam">
                                                    <table>
                                                        <tr>
                                                            <td>
                                                                gain Experience:
                                                            </td>
                                                            <td>
                                                                +1
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div> */}
                                                </div>
                                            }
                                            {props.typeReward === "books" &&
                                                <div className="one-loop-res-res">
                                                    <div className="one-loop-res-type">
                                                        {typebook < 15 &&
                                                            <div className="one-drop">
                                                                <div className="one-drop-thumb">
                                                                    <span>
                                                                        <picture>
                                                                            <source srcSet={`./images/skills/needres/${skillsName[typebook].name}.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/skills/needres/${skillsName[typebook].name}.png`} type="image/png" />
                                                                            <img src={`./images/skills/needres/${skillsName[typebook].name}.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                </div>
                                                                <div className="one-drop-name">
                                                                    <i>+{1 * rewardX}</i> {skillsName[typebook].name}
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="one-drop no-shadow" >
                                                            <div className="one-drop-thumb">
                                                                <span>
                                                                    <picture>
                                                                        <source srcSet={`./images/new-adv/exp.webp`} type="image/webp" />
                                                                        <source srcSet={`./images/new-adv/exp.png`} type="image/png" />
                                                                        <img src={`./images/new-adv/exp.png`} alt="" />
                                                                    </picture>
                                                                </span>
                                                            </div>
                                                            <div className="one-drop-name">
                                                                <i>+{countExp * rewardX}</i> exp
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            {props.typeReward === "stones" &&
                                                <div className="one-loop-res-res">
                                                    <div className="one-loop-res-type">
                                                        {yourResRewStone && yourResRewStone.length > 0 &&
                                                            <div className="one-drop">
                                                                <div className="one-drop-thumb">
                                                                    <span>
                                                                        <picture>
                                                                            <source srcSet={`./images/new-adv/res/stone-${yourResRewStone[0].name}-${yourResRewStone[0].element}.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/new-adv/res/stone-${yourResRewStone[0].name}-${yourResRewStone[0].element}.png`} type="image/png" />
                                                                            <img src={`./images/new-adv/res/stone-${yourResRewStone[0].name}-${yourResRewStone[0].element}.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                </div>
                                                                <div className="one-drop-name">
                                                                    <i>+{1 * rewardX}</i> {yourResRewStone[0].name}
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="one-drop no-shadow" >
                                                            <div className="one-drop-thumb">
                                                                <span>
                                                                    <picture>
                                                                        <source srcSet={`./images/new-adv/exp.webp`} type="image/webp" />
                                                                        <source srcSet={`./images/new-adv/exp.png`} type="image/png" />
                                                                        <img src={`./images/new-adv/exp.png`} alt="" />
                                                                    </picture>
                                                                </span>
                                                            </div>
                                                            <div className="one-drop-name">
                                                                <i>+{countExp * rewardX}</i> exp
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className={`one-loop-res-img 
                                        ${props.typeReward === "bamboo" && "bamboo-rew"} 
                                        ${props.typeReward === "resources" && "res-rew"}
                                        ${props.typeReward === "stones" && "stone-rew"}
                                        ${props.typeReward === "books" && "book-rew"}
                                        `}>
                                            <div className={`rew-story story-${animNumber}`}>
                                                <div className="rew-story-in">
                                                    <div className="story-tit">
                                                        {props.typeReward === "bamboo" ? props.story[num1 - 1].title : props.storyRes[animNumber].title}
                                                    </div>
                                                    <div className="story-text">
                                                        {props.typeReward === "bamboo" ? props.story[num1 - 1].text : props.storyRes[animNumber].text}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="detal-mult-bot">
                                <picture>
                                    <source srcSet={rewbotw} type="image/webp" />
                                    <source srcSet={rewbot} type="image/png" />
                                    <img src={rewbot} alt="" />
                                </picture>
                            </div>

                        </div>
                    )

                }
            }

        })
        }
    </>);

}

export default DetalLoop;