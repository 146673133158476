import React from 'react';
import modaltop from '../../../images/shop/modal-top.png';
import modalbot from '../../../images/shop/modal-bot.png';

const Modal = (props) => {
    var activeHaveInvent = false;
    var countHaveItem;
    if (props.modal.item.rare === 'common' && props.inventareat.style.common > 0) {
        activeHaveInvent = true;
        countHaveItem = props.inventareat.style.common;
    } else if (props.modal.item.rare === 'uncommon' && props.inventareat.style.uncommon > 0) {
        activeHaveInvent = true;
        countHaveItem = props.inventareat.style.uncommon;
    } else if (props.modal.item.rare === 'rare' && props.inventareat.style.rare > 0) {
        activeHaveInvent = true;
        countHaveItem = props.inventareat.style.rare;
    } else if (props.modal.item.rare === 'epic' && props.inventareat.style.epic > 0) {
        activeHaveInvent = true;
        countHaveItem = props.inventareat.style.epic;
    } else if (props.modal.item.rare === 'legendary' && props.inventareat.style.legendary > 0) {
        activeHaveInvent = true;
        countHaveItem = props.inventareat.style.legendary;
    } else if (props.modal.item.rare === 'mythic' && props.inventareat.style.mythic > 0) {
        activeHaveInvent = true;
        countHaveItem = props.inventareat.style.mythic;
    }

    console.log('props.modalItemInfo', props.modalItemInfo);

    var dataInfo;
    if (props.modalItemInfo && props.modalItemInfo[0]) {
        dataInfo = props.modalItemInfo[0];
    } else {
        dataInfo = props.modalItemInfo;
    }

    console.log('dataInfo', dataInfo);

    return (



        <>
            {dataInfo ?
                <div className={`modal-bay modal-invent ${props.modal.showModalStatus ? "open" : null} ${props.modal.showAnimModal ? " anim" : null}`}>
                    <div className="modal-bay-body">
                        <div className="modal-bay-body-in">

                            <div className="modal-bay-head">

                                <div className="modal-bay-top">
                                    <button className="modal-bay-close" onClick={() => props.showCloseModal('close')}><span></span></button>
                                    <img src={modaltop} alt="" />
                                </div>
                                <div className="modal-bay-wrap">

                                    <div className="modal-bay-tit">
                                        {dataInfo.data.name}
                                    </div>
                                    <div className="modal-bay-part">

                                    </div>
                                    <div className="modal-bay-thumb">
                                        <div className="modal-bay-thumb-in">
                                            {dataInfo.schema.schema_name === "food" ?
                                                <picture>
                                                    <source srcSet={`./images/shop/${dataInfo.template.template_id}.webp`} type="image/webp" />
                                                    <source srcSet={`./images/shop/${dataInfo.template.template_id}.png`} type="image/png" />
                                                    <img src={`./images/shop/${dataInfo.template.template_id}.png`} alt="" />
                                                </picture>
                                                : dataInfo.schema.schema_name === "resources" || dataInfo.schema.schema_name === "gems" || dataInfo.schema.schema_name === "jewelry" ?
                                                    <picture>
                                                        <source srcSet={`./images/jew/all/${dataInfo.template.template_id}.webp`} type="image/webp" />
                                                        <source srcSet={`./images/jew/all/${dataInfo.template.template_id}.png`} type="image/png" />
                                                        <img src={`./images/jew/all/${dataInfo.template.template_id}.png`} alt="" />
                                                    </picture>
                                                    :
                                                    <picture>
                                                        <source srcSet={`./images/weapons/all/${dataInfo.template.template_id}.webp`} type="image/webp" />
                                                        <source srcSet={`./images/weapons/all/${dataInfo.template.template_id}.png`} type="image/png" />
                                                        <img src={`./images/weapons/all/${dataInfo.template.template_id}.png`} alt="" />
                                                    </picture>
                                            }
                                            {/* <div className="modal-bay-thumb-text">
                                        sword part 1 of 3
                                    </div> */}
                                        </div>
                                    </div>

                                    {dataInfo.schema.schema_name !== "gems" ?
                                        <div className="total-sum">
                                            <div className="total-sum-top">
                                                rarity
                                            </div>
                                            <div className="total-sum-body">
                                                {dataInfo.data.rarity}
                                            </div>
                                        </div>
                                        : null}


                                </div>
                                <div className="modal-bay-bot">
                                    <img src={modalbot} alt="" />
                                </div>
                            </div>
                        </div>

                        <div className="modal-bay-bg-trans" onClick={() => props.closeThenk()}></div>
                    </div>
                    <div className="modal-bay-bg" ></div>
                </div>

                : null}

        </>);
}

export default Modal;