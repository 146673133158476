const SET_BALANSE = 'SET_BALANSE_WAX';
const SET_BALANSE_BAM = 'SET_BALANSE_BAM';
const CHANGE_BALANSE = 'CHANGE_BALANSE';

let initialState = {
    balanceWax: null,
    balanceBam: null,
    balanceGame: null
};

const headReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_BALANSE: {
            return {
                ...state,
                balanceWax: action.data.wax,
                balanceBam: action.data.bam,
            }
        }
        case SET_BALANSE_BAM: {
            return {
                ...state,
                balanceGame: action.data.bam,
            }
        }
        case CHANGE_BALANSE: {

            var num1 = parseFloat(action.data.sum);
            var num2 = parseFloat(state.balanceGame);
            var numBal = parseFloat(state.balanceBam);

            /*console.log('num1', num1);
            console.log('num2', num2);
            console.log('numBal', numBal);*/

            //console.log(sum.toFixed(4));
            if (action.data.addOrRem) {
                if (action.data.from) {
                    var sum = Number(Math.abs((num1 + num2))).toFixed(4);
                    var sumgame = (Math.abs((numBal - num1))).toFixed(4);
                    /*console.log('sum - 1', sum);*/
                    return {
                        ...state,
                        balanceGame: sum,
                        balanceBam: sumgame
                    }
                } else {
                    var sum = Math.abs((num1 + num2).toFixed(4));
                    //console.log('sum - 2', sum);
                    return {
                        ...state,
                        balanceGame: sum,
                    }
                }

            }
            else {
                if (action.data.from) {
                    var sum = Number(Math.abs((num2 - num1))).toFixed(4);
                    var sumgame = Number(Math.abs((numBal + num1))).toFixed(4);
                    /*console.log('sum - 3', sum);*/
                    return {
                        ...state,
                        balanceGame: sum,
                        balanceBam: sumgame
                    }
                } else {
                    var sum = Math.abs((num2 - num1).toFixed(4));
                    /*console.log('sum - 4', sum);*/
                    return {
                        ...state,
                        balanceGame: sum,
                    }
                }

            }

        }

        default:
            return state;
    }
}


export const setBalance = (wax, bam) => ({ type: SET_BALANSE, data: { wax, bam } })
export const setBalanceBam = (bam) => ({ type: SET_BALANSE_BAM, data: { bam } })
export const changeBalance = (addOrRem, sum, from) => ({ type: CHANGE_BALANSE, data: { addOrRem, sum, from } })

export default headReducer;
