import React from 'react';


import Menu from '../../components/Menu';

import { Link, NavLink } from 'react-router-dom';

import { connect } from "react-redux";
import { } from "../../GlobalState/weapon-reducer"

import ErrorComponent from "../Error/ErrorComponent";

//import { JsonRpc } from 'eosjs'
import './../../css/craft.scss';

//import inventbotw from '../../images/craft/bg-craft-first.jpg';
import bgnew from '../../images/craft/new-bg.jpg';
import bgnewW from '../../images/craft/new-bg.webp';

import craftManWeapon from '../../images/craft/craft-weapon-1.png';
import craftManWeapon2 from '../../images/craft/craft-weapon-2.png';
import craftManJew from '../../images/craft/craft-jewelry-1.png';
import craftManJew2 from '../../images/craft/craft-jewelry-2.png';
import craftManArmor from '../../images/craft/craft-armor-1.png';
import craftManArmor2 from '../../images/craft/craft-armor-2.png';
import manWeapon from '../../images/craft/modal-weapon.png';
import manJew from '../../images/craft/modal-jew.png';
import manArmor from '../../images/craft/modal-armor.png';
import mobcraft from '../../images/craft/craft-mob-part.png';

import craftManWeaponW from '../../images/craft/craft-weapon-1.webp';
import craftManWeapon2W from '../../images/craft/craft-weapon-2.webp';
import craftManJewW from '../../images/craft/craft-jewelry-1.webp';
import craftManJew2W from '../../images/craft/craft-jewelry-2.webp';
import craftManArmorW from '../../images/craft/craft-armor-1.webp';
import craftManArmor2W from '../../images/craft/craft-armor-2.webp';
import manWeaponW from '../../images/craft/modal-weapon.webp';
import manJewW from '../../images/craft/modal-jew.webp';
import manArmorW from '../../images/craft/modal-armor.webp';
import mobcraftW from '../../images/craft/craft-mob-part.webp';

import musicError from './../../music/error.mp3'

import { ExplorerApi } from "atomicassets" // атомик для списка панд

const fetch = require('node-fetch');
const api = new ExplorerApi("https://" + localStorage.getItem('rpcA'), "atomicassets", { fetch, rateLimit: 4 });


var barmenText;

class CraftComponentFirst extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            shopitem: null,
            modalChoose: false,
            modalType: "",
            nameMan: "",
            showCloseBt: false
        }

        this.clearError = this.clearError.bind(this);
        this.openModalChoose = this.openModalChoose.bind(this);
        this.audioMusicError = new Audio(musicError);
        this.wordflick = this.wordflick.bind(this);
        this.showBt = this.showBt.bind(this);

    }

    componentDidMount() {
        barmenText = document.getElementsByClassName("before-quest-body")[0];

        console.log("barmenText", barmenText);
    }

    async clearError() {
        this.setState({
            errorText: '',
            hasError: false
        });
    }

    async openModalChoose(targ, who, name, text) {
        if (targ == "open") {
            this.setState({
                modalChoose: true,
                modalType: who,
                nameMan: name
            });
            this.wordflick(text, this.showBt);
        } else {
            this.setState({
                modalChoose: false,
                modalType: "",
                nameMan: "",
                showCloseBt: false,
            });
        }
        //console.log(barmenText);
        barmenText.innerHTML = '';

    }

    showBt(event) {
        this.setState({
            showCloseBt: true,
        });
    }

    wordflick(event, showBt) {
        var words = [event];
        var part,
            i = 0,
            offset = 0,
            len = words.length,
            forwards = true,
            skip_count = 0,
            skip_delay = 15,
            speed = 50;

        var ss = setInterval(function () {
            if (forwards) {
                if (offset >= words[i].length) {
                    ++skip_count;
                    if (skip_count == skip_delay) {
                        forwards = false;
                        skip_count = 0;
                    }
                }
            }
            else {
                if (offset == 0) {
                    forwards = true;
                    i++;
                    offset = 0;
                    if (i >= len) {
                        i = 0;
                    }
                }
            }
            part = words[i].substr(0, offset);
            if (skip_count == 0) {
                if (forwards && offset <= words[i].length) {
                    offset++;
                    //console.log(444);
                }
                else {
                    clearInterval(ss);
                    console.log('test');
                    showBt();
                }
            }


            barmenText.innerHTML = part;
        }, speed);

    };

    render() {
        return (<>
            <Menu  {...this.props.ual} wax={this.props.balanceWax} bam={this.props.balanceBam} game={this.props.balanceGame} updateBalance={this.props.updateBalance} />
            {this.state.hasError ? <ErrorComponent
                errorMessage={this.state.errorText}
                clearError={this.clearError}
            /> : null}
            <main className="craft-page main-page-craft">
                <div className="loading-block"></div>

                <div className="craft-wrap">

                    <div className={`modal-choose-craft ${this.state.modalChoose && "open"}`}>
                        {/* <div className="close-craft-bg" onClick={() => this.openModalChoose("close")}></div> */}
                        <div className="modal-choose-craft-in">
                            <div className="modal-choose-craft-body">
                                <div className="modal-choose-craft-tit">
                                    {this.state.nameMan}
                                </div>
                                <div className="man-modal">
                                    <picture>
                                        <source srcSet={this.state.modalType == "weapon" ? manWeaponW : this.state.modalType == "jew" ? manJewW : manArmorW} type="image/webp" />
                                        <source srcSet={this.state.modalType == "weapon" ? manWeapon : this.state.modalType == "jew" ? manJew : manArmor} type="image/png" />
                                        <img src={this.state.modalType == "weapon" ? manWeaponW : this.state.modalType == "jew" ? manJewW : manArmorW} alt="" />
                                    </picture>

                                </div>
                                <div className="modal-choose-craft-deecr">
                                    <div class={`modal-bay-close ${this.state.showCloseBt && "show"}`} onClick={() => this.openModalChoose("close")}><span></span></div>
                                    <div class="before-quest-descr">
                                        <div class="before-quest-descr-bord-1">
                                            <div class="before-quest-descr-bord-2">
                                                <div class="before-quest-descr-bord-3">
                                                    <div class="before-quest-body">
                                                        Greetings Hero!
                                                        Would you like to get the best
                                                        ARMOR in the Lands of Elgard?
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="bt-crafts">
                                            <NavLink class="def-bt mid for-desc" to={this.state.modalType == "weapon" ? "/craft-weapon-page" : this.state.modalType == "jew" ? "/craft-jewelry-page" : "craft-armor-page"}>
                                                <span class="button-in">
                                                    <span class="button-name">
                                                        <span class="button-name-in">
                                                            <span class="button-name-value">
                                                                craft
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </NavLink>
                                            <NavLink class="def-bt mid for-desc" to={this.state.modalType == "weapon" ? "/upgrade-weapon-page" : this.state.modalType == "jew" ? "/upgrade-jewelry-page" : "upgrade-armor-page"}>
                                                <span class="button-in">
                                                    <span class="button-name">
                                                        <span class="button-name-in">
                                                            <span class="button-name-value">
                                                                upgrade
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </NavLink>

                                            <NavLink class="def-bt min for-mob" to={this.state.modalType == "weapon" ? "/craft-weapon-page" : this.state.modalType == "jew" ? "/craft-jewelry-page" : "craft-armor-page"}>
                                                <span class="button-in">
                                                    <span class="button-name">
                                                        <span class="button-name-in">
                                                            <span class="button-name-value">
                                                                craft
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </NavLink>
                                            <NavLink class="def-bt min for-mob" to={this.state.modalType == "weapon" ? "/upgrade-weapon-page" : this.state.modalType == "jew" ? "/upgrade-jewelry-page" : "upgrade-armor-page"}>
                                                <span class="button-in">
                                                    <span class="button-name">
                                                        <span class="button-name-in">
                                                            <span class="button-name-value">
                                                                upgrade
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </NavLink>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="img-mob">
                        <img src={inventbotw} alt="" />
                    </div>
                    <div className="img-mob">
                        <img className='mob' src={inventbotw} alt="" />
                    </div>
                    <div className="craft-body">
                        <NavLink className="craft-part head-part" to="/craft-weapon">
                        </NavLink>
                        <NavLink className="upgrade-part head-part" to="/craft-jewelry">
                        </NavLink>
                    </div> */}
                    {/* <img src={bgnew} alt="" /> */}
                    <picture>
                        <source srcSet={bgnewW} type="image/webp" />
                        <source srcSet={bgnew} type="image/png" />
                        <img src={bgnewW} alt="" />
                    </picture>

                    <div className="craft-man craft-jew-man" onClick={() => this.openModalChoose("open", "jew", "Endon", "Greetings Hero! Would you like to get the best jewelry in the Lands of Elgard?")}>
                        <div className="man-bg">
                            <picture>
                                <source srcSet={craftManJewW} type="image/webp" />
                                <source srcSet={craftManJew} type="image/png" />
                                <img src={craftManJewW} alt="" />
                            </picture>
                        </div>
                        <div className="man-hover">
                            <picture>
                                <source srcSet={craftManJew2W} type="image/webp" />
                                <source srcSet={craftManJew2} type="image/png" />
                                <img src={craftManJew2W} alt="" />
                            </picture>
                        </div>
                    </div>

                    <div className="craft-man craft-weap-man" onClick={() => this.openModalChoose("open", "weapon", "Pax", "Greetings Hero! Would you like to get the best WEAPON in the Lands of Elgard?")}>
                        <div className="man-bg">
                            <picture>
                                <source srcSet={craftManWeaponW} type="image/webp" />
                                <source srcSet={craftManWeapon} type="image/png" />
                                <img src={craftManWeaponW} alt="" />
                            </picture>
                        </div>
                        <div className="man-hover">
                            <picture>
                                <source srcSet={craftManWeapon2W} type="image/webp" />
                                <source srcSet={craftManWeapon2} type="image/png" />
                                <img src={craftManWeapon2W} alt="" />
                            </picture>
                        </div>
                    </div>

                    <div className="craft-man craft-armor-man" onClick={() => this.openModalChoose("open", "armor", "Nuruddin", "Greetings Hero! Would you like to get the best ARMOR in the Lands of Elgard?")}>
                        <div className="man-bg">
                            <picture>
                                <source srcSet={craftManArmorW} type="image/webp" />
                                <source srcSet={craftManArmor} type="image/png" />
                                <img src={craftManArmorW} alt="" />
                            </picture>
                        </div>
                        <div className="man-hover">
                            <picture>
                                <source srcSet={craftManArmor2W} type="image/webp" />
                                <source srcSet={craftManArmor2} type="image/png" />
                                <img src={craftManArmor2W} alt="" />
                            </picture>
                        </div>
                    </div>

                    <div className="mob-carf-wrap">
                        <picture>
                            <source srcSet={mobcraftW} type="image/webp" />
                            <source srcSet={mobcraft} type="image/png" />
                            <img src={mobcraftW} alt="" />
                        </picture>

                        <div className="mob-man">
                            <div className="one-mob-man" onClick={() => this.openModalChoose("open", "weapon", "Pax", "Greetings Hero! Would you like to get the best WEAPON in the Lands of Elgard?")}></div>
                            <div className="one-mob-man" onClick={() => this.openModalChoose("open", "jew", "Endon", "Greetings Hero! Would you like to get the best jewelry in the Lands of Elgard?")}></div>
                            <div className="one-mob-man" onClick={() => this.openModalChoose("open", "armor", "Nuruddin", "Greetings Hero! Would you like to get the best ARMOR in the Lands of Elgard?")}></div>
                        </div>
                    </div>
                </div>

                <footer>
                    <div className="footer-nav">
                        <nav>
                            <ul>
                                <li>
                                    <a className="foot-logo-1" target="_blank" href="https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&sort=created&symbol=WAX">
                                        <span>

                                        </span>
                                        <i>
                                            Market
                                        </i>
                                    </a>
                                </li>
                                <li>
                                    <a className="foot-logo-2" target="_blank" href="https://twitter.com/NftPanda/">
                                        <span>

                                        </span>
                                        <i>
                                            Twitter
                                        </i>
                                    </a>
                                </li>
                                <li>
                                    <a className="foot-logo-3" target="_blank" href="https://www.instagram.com/nftpanda/">
                                        <span>

                                        </span>
                                        <i>
                                            Instagram
                                        </i>
                                    </a>
                                </li>
                                <li>
                                    <a className="foot-logo-4" target="_blank" href="https://discord.gg/vCSgme4d5b">
                                        <span>

                                        </span>
                                        <i>
                                            Discord
                                        </i>
                                    </a>
                                </li>
                                <li>
                                    <a className="foot-logo-5" target="_blank" href={`https://wax.alcor.exchange/swap?output=${process.env.REACT_APP_VAL}-nftpandabamb&input=WAX-eosio.token`}>
                                        <span>

                                        </span>
                                        <i>
                                            {process.env.REACT_APP_VAL} Trade
                                        </i>
                                    </a>
                                </li>
                                <li>
                                    <a className="foot-logo-6" target="_blank" href="https://discord.gg/vCSgme4d5b">
                                        <span>

                                        </span>
                                        <i>
                                            Support
                                        </i>
                                    </a>
                                </li>
                            </ul>

                        </nav>
                    </div>
                </footer>

            </main>
        </>);
    }
}

// let mapStateToProps = (state) => ({
//     allinvent: state.inventoryReduser.allinvent,
// });

let mapStateToProps = (state) => ({

});


export default connect(mapStateToProps, {})(CraftComponentFirst);