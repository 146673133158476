export const learnSkills = (accountName, id, numberSkill, allResNft) => {
    return {
        actions: [{
            account: "atomicassets",
            name: "transfer",
            authorization: [
                {
                    actor: accountName, // use account that was logged in
                    permission: "active",
                },
            ],
            data: {
                from: accountName,
                to: process.env.REACT_APP_CONTRACT,
                asset_ids: allResNft,
                memo: `learnskills ${id} ${numberSkill} `,
            },
        }]
    };
};

export const withdrawNftFromGame = (accountName, ids) => {
    return {
        actions: [{
            account: process.env.REACT_APP_CONTRACT,
            name: "withdrawnft",
            authorization: [
                {
                    actor: accountName,
                    permission: "active",
                }
            ],
            data: {
                to: accountName,
                asset_ids: ids
            }
        }]
    };
};

export const transferToTheGame = (accountName, ids) => {
    return {
        actions: [
            {
                account: "atomicassets",
                name: "transfer",
                authorization: [
                    {
                        actor: accountName, // use account that was logged in
                        permission: "active",
                    },
                ],
                data: {
                    from: accountName,
                    to: process.env.REACT_APP_CONTRACT,
                    asset_ids: ids,
                    memo: "",
                },
            },
        ],
    };
};

export const buySlot = (accountName, sum) => {
    return {
        actions: [
            {
                account: "eosio.token",
                name: "transfer",
                authorization: [
                    {
                        actor: accountName, // use account that was logged in
                        permission: "active",
                    },
                ],
                data: {
                    from: accountName,
                    to: process.env.REACT_APP_CONTRACT,
                    quantity: sum,
                    memo: "buyslots ",
                },
            },
        ],
    };
};


