import React from 'react';
import musicHit from './../../music/hit1.mp3'
var audioMusicHit = new Audio(musicHit);

function backside(num) {
    var items = document.getElementsByClassName('choose-hero-bl');
    items[num].classList.toggle("active");
    if (localStorage.getItem('sound') === 'true') {
        audioMusicHit.currentTime = 0;
        audioMusicHit.volume = 0.1;
        audioMusicHit.play();
    }
}

//var nameRarePanda;
//var cardRare;

const rarName = ["Rookie", "Officer-cadet", "Second-Lieutenant", "Lieutenant", "Captain", "Major", "Lieutenant-Colonel", "Colonel", "Brigadier", "Major-General", "Lieutenant-General", "General", "legend"]


const CardContentPanda = ({ item, index, onChoose }) => {

    //console.log("aaaaaaaaaaaaaaaaaaaaaaaaaa");
    const cardRare = item.rarity === 1 ? 'Promo' : item.name_pa.substr(0, item.name_pa.indexOf('-'));
    const serialNum = item.name_pa.split('-')[1].substring(0, 1);
    const serialPrefix = serialNum === "A" ? "serial-1-" : serialNum === "N" ? "serial-2-" : serialNum === "P" ? "serial-3-" : null;
    const cardImage = `${serialPrefix}${item.name_pa.toLowerCase().substr(0, item.name_pa.indexOf('-'))}`;

    return (
        <div className="one-card-list mod-list choose-hero-bl" data-rar={item.rarity === 1 ? 'Promo' : item.name_pa.substr(0, item.name_pa.indexOf('-'))}>

            <div className="one-card-list-in">
                <div className="img-wrap">
                    <div className="open-info" onClick={(e) => {
                        backside(index);
                    }}></div>
                    <div className="card-rar-wrap">
                        <picture>
                            <source srcSet={`./images/card-rar/${cardImage}.webp`} type="image/webp" />
                            <source srcSet={`./images/card-rar/${cardImage}.png`} type="image/png" />
                            <img src={`./images/card-rar/${cardImage}.webp`} alt="" />
                        </picture>
                        <div className="element-rar">
                            <picture>
                                <source srcSet={`./images/card-rar/element-${item.element}.webp`} type="image/webp" />
                                <source srcSet={`./images/card-rar/element-${item.element}.png`} type="image/png" />
                                <img src={`./images/card-rar/element-${item.element}.webp`} alt="" />
                            </picture>
                        </div>
                    </div>
                    <div
                        className={"video-panda"}
                        dangerouslySetInnerHTML={{
                            __html: `
                        <video width={136} height={170} autoPlay="true"  muted="true" loop playsInline="true" >
                        <source 
                        src="./images/card-1x/video/${item.name_pa.split('-')[1]}.mp4" type="video/mp4" />

                    </video>`
                        }}
                    />
                    <div className="one-card-bt">
                        <button className="def-bt min append-card" onClick={(e) => {
                            onChoose(item, index, "panda");
                        }} href="/" >
                            <span className="button-in">
                                <span className="button-name">
                                    <span className="button-name-in">
                                        <span className="button-name-value">
                                            CHOOSE
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="card-back-side">

                <div className="close-back-side" onClick={(e) => {
                    backside(index);
                }}></div>
                <div className="card-back-side-in">

                    <div className="card-top-avat-descr">
                        <div className="card-top-avat">
                        </div>
                        <div className="card-top-avat-descr-info">
                            Panda info
                        </div>
                        <div className="card-top-avat-descr-name">
                            {item.name_pa.split('-')[1]}
                        </div>
                        <div className="card-top-avat-descr-list">
                            <ul>
                                <li>
                                    ID: {item.asset_id}
                                </li>
                                <li>
                                    RARITY: {cardRare}
                                </li>
                                <li>
                                    stake: {item.stake_count / 10000} {process.env.REACT_APP_VAL}
                                </li>
                                <li>
                                    rank: {rarName[item.lvl]}
                                </li>
                                <li>
                                    index: {index}
                                </li>
                            </ul>
                        </div>
                        <div className="card-top-avat-descr-copy">
                            NFT Panda
                            <span>
                                World of fantasy
                            </span>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
};

const CardContentAlien = ({ item, index, onChoose, userSlotsAlien, userSlotsAlienAvatar, typeOfNft }) => {
    console.log("item.data.rarity", item.data.rarity);

    let list = [];
    if (typeOfNft == "alien") {
        list = userSlotsAlien;
    } else {
        list = userSlotsAlienAvatar;
    }

    return (
        //item.data.rarity != "Abundant" && 
        !list.includes(item.asset_id) && typeOfNft == "alien" && item.schema !== "faces.worlds" ||
            !list.includes(item.asset_id) && typeOfNft == "avatar" && item.schema === "faces.worlds"
            ?
            <div className="one-card-list mod-list choose-hero-bl" data-rar={item.data.rarity}>

                <div className="one-card-list-in">
                    <div className="img-wrap">
                        {/* <div className="open-info" onClick={(e) => {
                        backside(index);
                    }}></div> */}
                        <div className="card-rar-wrap">

                            <picture>
                                {/* <source srcSet={`./images/card-rar/${item.template.template_id}.webp`} type="image/webp" /> */}
                                <source srcSet={`./images/alien/all/${item.template.template_id}.gif`} type="image/gif" />
                                <source srcSet={`./images/alien/all/${item.template.template_id}.gif`} type="image/gif" />
                                <img src={`./images/alien/all/${item.template.template_id}.gif`} alt="" />
                            </picture>

                            {/* <div className="element-rar">
                            <picture>
                                <source srcSet={`./images/card-rar/element-${item.element}.webp`} type="image/webp" />
                                <source srcSet={`./images/card-rar/element-${item.element}.png`} type="image/png" />
                                <img src={`./images/card-rar/element-${item.element}.webp`} alt="" />
                            </picture>
                        </div> */}
                        </div>

                        <div className="one-card-bt">
                            <button className="def-bt min append-card" onClick={(e) => {
                                onChoose(item, index, typeOfNft);
                            }} href="/" >
                                <span className="button-in">
                                    <span className="button-name">
                                        <span className="button-name-in">
                                            <span className="button-name-value">
                                                CHOOSE
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="card-back-side">

                    <div className="close-back-side" onClick={(e) => {
                        backside(index);
                    }}></div>
                    <div className="card-back-side-in">

                        <div className="card-top-avat-descr">
                            <div className="card-top-avat">
                            </div>
                            <div className="card-top-avat-descr-info">
                                Panda info
                            </div>
                            <div className="card-top-avat-descr-name">

                                <li>
                                    index: {index}
                                </li>
                            </div>
                            <div className="card-top-avat-descr-list">

                            </div>
                            <div className="card-top-avat-descr-copy">
                                NFT Panda
                                <span>
                                    World of fantasy
                                </span>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            : null
    );

};

const Heromodal = ({ allPandasSlot, allAlien, typeOfNft, onHandleSendPandaToSlot, userSlotsAlien, userSlotsAlienAvatar }) => {

    const contentList = typeOfNft === "panda" ? allPandasSlot.filter((row) => row.is_in_slot === 0) : typeOfNft === "alien" || typeOfNft === "avatar" ? allAlien : [];

    // console.log("typeOfNft", typeOfNft);
    console.log("contentList", contentList);
    // console.log("allPandasSlot", allPandasSlot);
    // console.log("allAlien", allAlien);

    return (
        <>
            {contentList.map((item, index) => (
                typeOfNft === "panda" ?
                    <CardContentPanda key={index} item={item} index={index} onChoose={onHandleSendPandaToSlot} />
                    : typeOfNft === "alien" ?
                        <CardContentAlien key={index} item={item} index={index} onChoose={onHandleSendPandaToSlot} userSlotsAlien={userSlotsAlien} userSlotsAlienAvatar={userSlotsAlienAvatar} typeOfNft={typeOfNft} />
                        :
                        <CardContentAlien key={index} item={item} index={index} onChoose={onHandleSendPandaToSlot} userSlotsAlien={userSlotsAlien} userSlotsAlienAvatar={userSlotsAlienAvatar} typeOfNft={typeOfNft} />
            ))}
        </>
    );
}

export default Heromodal;