import React, { Component } from 'react'
import logo from '../images/logo.png' // relative path to image
import { MouseParallaxContainer, MouseParallaxChild } from "react-parallax-mouse";

var imgSize = 3840 / 2160;


//export const Login = (props) => {
export default class Login extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            opendrop: false,
            activeNoda: 'wax.pink.gg'
        }

        this.chooseNode = this.chooseNode.bind(this);
    }

    componentDidMount() {
        
        if (!localStorage.getItem('rpc')) {
            localStorage.setItem('rpc', 'wax.pink.gg');
        } else {
            this.setState({
                activeNoda: localStorage.getItem('rpc')
            });
        }
        
    }

    async openDrop(event) {

        if(event === "open") {
            this.setState({
                ...this.state,
                opendrop: true,
            })
        } else {
            this.setState({
                ...this.state,
                opendrop: false,
            })
        }
    }

    async chooseNode(noda) {
        this.setState({
            ...this.state,
            activeNoda: noda,
            opendrop: false
        })
        localStorage.setItem('rpc', noda);
    }
    
    render () {
        return (<>
        {
        //!this.props.ual.activeUser ? 
        
        <div className="first-panda-screen-wrap">
            <div className="first-panda-screen" id="firstScr">
            <div className="home-img-anim">
                <div className={ `home-img-anim-in ${imgSize > window.innerWidth / window.innerHeight ? "width-more" : "width-min"} `}>
                    <div className="div-test">
                        {/* <img src="https://dapp.nftpanda.space/bg-clear.jpg" alt="" / >
                        <div className="svg-smoke smoke-one" id="smoke">
                            <div className="sm sm-1"></div>
                            <div className="sm sm-2"></div>
                            <div className="sm sm-3"></div>
                            <div className="sm sm-4"></div>
                            <div className="sm sm-5"></div>
                        </div>
                        <div className="svg-smoke smoke-two">
                            <div className="sm sm-6"></div>
                            <div className="sm sm-7"></div>
                        </div>
                        <div className="all-cloud">
                            <div className="cloud large cloud-1"></div>
                            <div className="cloud large cloud-2"></div>
                            <div className="cloud large cloud-3"></div>
                            <div className="cloud large cloud-4"></div>
                            <div className="cloud large cloud-5"></div>
                            <div className="cloud large cloud-6"></div>

                            <div className="cloud-other large cloud-7"></div>
                            <div className="cloud-other large cloud-8"></div>
                            <div className="cloud-other large cloud-9"></div>
                            <div className="cloud-other large cloud-10"></div>
                            <div className="cloud-other large cloud-11"></div>
                            <div className="cloud-other large cloud-12"></div>
                        </div> */}

                        

                        <MouseParallaxContainer className="parallax" resetOnLeave>
                            <MouseParallaxChild factorX={0.013} factorY={0.01} className="scene-moutn">
                                <div className="scene-moutn-in">
                                    <picture >
                                        <source srcSet="https://game.nftpanda.space/images/mount.webp" type="image/webp" />
                                        <source srcSet="https://game.nftpanda.space/images/mount.jpg" type="image/jpg" /> 
                                        <img src="https://game.nftpanda.space/images/mount.jpg" alt="" />
                                    </picture>
                                </div>
                            </MouseParallaxChild>

                            <MouseParallaxChild factorX={0.01} factorY={0.01} className="scene-bg">
                                <picture >
                                    <source srcSet="https://game.nftpanda.space/images/bg-hero.webp" type="image/webp" />
                                    <source srcSet="https://game.nftpanda.space/images/bg-hero.png" type="image/png" /> 
                                    <img src="https://game.nftpanda.space/images/bg-hero.webp" alt="" />
                                </picture>

                                

                                <div className="light">
                                    <picture>
                                        <source srcSet="https://game.nftpanda.space/images/sv-hero-tree.webp" type="image/webp" />
                                        <source srcSet="https://game.nftpanda.space/images/sv-hero-tree.png" type="image/png" /> 
                                        <img src="https://game.nftpanda.space/images/sv-hero-tree.webp" alt="" />
                                    </picture>

                                    <div className="light-one l-1"></div>
                                    <div className="light-one l-2"></div>
                                    <div className="light-one l-3"></div>
                                    <div className="light-one l-4"></div>
                                    <div className="light-one l-5"></div>
                                    <div className="light-one l-6"></div>
                                    <div className="light-one l-7"></div>
                                    <div className="light-one l-8"></div>
                                    <div className="light-one l-9"></div>
                                    <div className="light-one l-10"></div>
                                    <div className="light-one l-11"></div>
                                    <div className="light-one l-12"></div>
                                </div>

                            </MouseParallaxChild>

                            <MouseParallaxChild factorX={0.01} factorY={0.01} className="drag" data-depth="0.07">
                                <picture>
                                    <source srcSet="https://game.nftpanda.space/images/drag.webp" type="image/webp" />
                                    <source srcSet="https://game.nftpanda.space/images/drag.png" type="image/png" /> 
                                    <img src="https://game.nftpanda.space/images/drag.webp" alt="" />
                                </picture>
                            </MouseParallaxChild>

                            <MouseParallaxChild factorX={0.01} factorY={0.01} className="land" data-depth="0.07">
                                <picture>
                                    <source srcSet="https://game.nftpanda.space/images/land.webp" type="image/webp" />
                                    <source srcSet="https://game.nftpanda.space/images/land.png" type="image/png" /> 
                                    <img src="https://game.nftpanda.space/images/land.webp" alt="" />
                                </picture>
                            </MouseParallaxChild>
                            
                            
                            <div className="heroes-bg-wrap">
                                <div className="heroes-bg">
                                    <picture>
                                        <source srcSet="https://game.nftpanda.space/images/bg-heroes.webp" type="image/webp" />
                                        <source srcSet="https://game.nftpanda.space/images/bg-heroes.png" type="image/png" /> 
                                        <img src="https://game.nftpanda.space/images/bg-heroes.webp" alt="" />
                                    </picture>
                                    <div className="heroes">
                                        <picture>
                                            <source srcSet="https://game.nftpanda.space/images/heroes.webp" type="image/webp" />
                                            <source srcSet="https://game.nftpanda.space/images/heroes.png" type="image/png" /> 
                                            <img src="https://game.nftpanda.space/images/heroes.webp" alt="" />
                                        </picture>
                                    </div>
                                </div>
                            </div>


                            <div className="first-panda-screen-in">
                                <div className="logo">
                                    <img width="536" src={logo} alt={"logo"}/> 
                                </div>
                                <div className="bg-panda-login">
                                <button
                                    role='button'
                                    onClick={() => this.props.checkedTerm ? this.props.ual.showModal() : this.props.checkError()}
                                    className='def-bt big'>
                                    <span className="button-in">
                                        <span className="button-name">
                                        <span className="button-name-in">
                                            <span className="button-name-value">
                                            LOGIN
                                            </span>
                                        </span>
                                        </span>
                                    </span>
                                </button>
                                </div>
                                <div className="table-home">
                                <div className="table-home-in" >

                                    <div className="choose-noda">
                                        <div className="choose-noda-tit">
                                            RPC ENDPOINT AVAILABLE
                                        </div>
                                        <div className="choose-sel">
                                            <div className="choose-sel-in">
                                                {this.state.opendrop && <div className="bg-close-drop" onClick={() => this.openDrop('close')}></div>}
                                                <div className="choose-sel-act" tabIndex="0" onClick={() => this.openDrop('open')}>
                                                    {this.state.activeNoda}
                                                    <i>
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59 49.5">
                                                            <g id="Слой_2" data-name="Слой 2">.
                                                                <g id="Слой_3_копия_" data-name="Слой 3 (копия)">
                                                                    <g id="Слой_5" data-name="Слой 5">
                                                                        <rect className="drop-cl-1" width="59" height="49.5" rx="5"/>
                                                                        <rect className="drop-cl-2" x="4.67" y="3" width="49.62" height="40"/>
                                                                        <polygon className="drop-cl-3" points="12.5 16.83 30 34.33 46.5 16.83 12.5 16.83"/>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </i>
                                                </div>
                                                <div className={`choose-sel-drop ${this.state.opendrop ? 'open' : 'close'}`} tabIndex="0">
                                                    <ul>
                                                        {/* <li onClick={() => this.chooseNode('chain.wax.io')}>chain.wax.io</li> */}
                                                        <li onClick={() => this.chooseNode('wax.pink.gg')}>wax.pink.gg</li>
                                                        <li onClick={() => this.chooseNode('wax.cryptolions.io')}>wax.cryptolions.io</li>
                                                        <li onClick={() => this.chooseNode('wax.greymass.com')}>wax.greymass.com</li>
                                                        <li onClick={() => this.chooseNode('api.wax.alohaeos.com')}>api.wax.alohaeos.com</li>
                                                        <li onClick={() => this.chooseNode('api.waxsweden.org')}>api.waxsweden.org</li>
                                                        <li onClick={() => this.chooseNode('wax.eosphere.io')}>wax.eosphere.io</li>
                                                        <li onClick={() => this.chooseNode('api.wax.greeneosio.com')}>api.wax.greeneosio.com</li>
                                                        <li onClick={() => this.chooseNode('wax.eu.eosamsterdam.net')}>wax.eu.eosamsterdam.net</li>
                                                        <li onClick={() => this.chooseNode('api.wax.bountyblok.io')}>api.wax.bountyblok.io</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    Accept <a href="https://nftpanda.space/termsofuse.pdf">Terms of Use</a> <br />and <a href="https://nftpanda.space/privacypolicy.pdf">Privacy Policy</a> 
                                    <div className={`check-term ${this.props.checkStatus ? 'error' : null}`}>
                                        <label>
                                            <input type="checkbox" onChange={(e) => {
                                                        this.props.addCheckBox(e);
                                                    }} />
                                            
                                            <i></i>
                                            <span>
                                                I read and accept
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                </div>
                            </div>

                            
                        </MouseParallaxContainer>
                    </div>
                </div>
            </div>

            
        </div>

            
        
      </div> 
      //: 
    //   <>
      
    //   <div className="errror-wrapp">
    //     <div className="errror-wrapp-top">
    //         Oops apparently someone decided to share a link to our closed test. It's okay, just a little more and you will be able to fully play as soon as the test is over.
    //     </div>
        
        
    //     <div className='ual-btn-wrapper'>
    //             <button role='button' className="def-bt min" onClick={() => this.props.ual.logout()}>
    //                 <span className="button-in">
    //                     <span className="button-name">
    //                         <span className="button-name-in">
    //                             <span className="button-name-value">
    //                                 Log out
    //                             </span>
    //                         </span>
    //                     </span>
    //                 </span>
    //             </button>
    //         </div>
    //     </div>
    //   </> 
      
      }
    
    </>)
    }
}

