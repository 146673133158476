import React from 'react';
import modaltop from '../../images/shop/modal-top.png';
import modalbot from '../../images/shop/modal-bot.png';

const Modal = (props) => {
    
    return (
    <>
       
        <div id="thank-modal-shop" className={`modal-bay ${props.showModalThank ? "open" : null}`}>
            <div className="modal-bay-body">
                <div className="modal-bay-body-in">

                    <div className="modal-bay-head">
                        
                        <div className="modal-bay-top">
                            <button className="modal-bay-close" onClick={() => props.closeThenk()}><span></span></button>
                            <img src={modaltop} alt="" />
                        </div>
                        <div className="modal-bay-wrap">
                            
                            <div className="modal-bay-tit">
                                Thank you
                            </div>
                            <div className="modal-bay-part">
                                the purchase was successful
                                {/* <div className="action-text">
                                    {props.countModalThank == 10 ? "" : "" }
                                </div> */}
                            </div>
                            {/* <div className="modal-thank-info">
                                Purchase details
                            </div> */}

                            {/* <div className="total-sum">
                                
                                <div className="total-sum-body">
                                    <span>
                                        {parseInt(props.modal.item.price * props.countModalThank * 10000) / 10000}
                                    </span>
                                </div>
                            </div> */}

                            <div className="modal-bay-bt">
                                <button className="def-bt mid" onClick={() => props.closeThenk()}>
                                    <span className="button-in">
                                        <span className="button-name">
                                            <span className="button-name-in">
                                                <span className="button-name-value">
                                                    Close
                                                </span> 
                                            </span>
                                        </span>
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="modal-bay-bot">
                            <img src={modalbot} alt="" />
                        </div>
                    </div>
                </div>
                <div className="modal-bay-bg-trans" onClick={() => props.closeThenk()}></div>
                
            </div>
            <div className="modal-bay-bg" ></div>
            
        </div>

        <div id="cooking-modal-shop" className={`modal-bay modal-made ${props.made ? "open" : null}`}>
            <div className="modal-bay-body">
                <div className="modal-bay-body-in">

                    <div className="modal-bay-head">
                        
                        <div className="modal-bay-top">
                            <img src={modaltop} alt="" />
                        </div>
                        <div className="modal-bay-wrap">
                           
                            <div className="modal-bay-tit">
                                We are working on your order
                            </div>
                            <div className="loading-modal"></div>
                            <div className="modal-thank-info">
                                It can take a few minutes, please wait
                            </div>
                           
                        </div>
                        <div className="modal-bay-bot">
                            <img src={modalbot} alt="" />
                        </div>
                    </div>
                </div>
                <div className="modal-bay-bg-trans" onClick={() => props.closeThenk()}></div>
                
            </div>
            <div className="modal-bay-bg" ></div>
            
        </div>

    </>);
}

export default Modal;