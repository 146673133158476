import React from "react";
import Menu from '../../components/Menu';
import axios from "axios";

import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import {
  weaponlist,
  statusLoadinWeapon,
  addWeaponWhenRemove,
  addWeaponToPanda,
} from "../../GlobalState/weapon-reducer";

import ErrorComponent from "../Error/ErrorComponent";
import UpgradeHomeLoop from "./UpgradeHomeLoop";
import cryptoRandomString from "crypto-random-string";

import { JsonRpc } from "eosjs";
import "./../../css/craft.scss";

import musicError from "./../../music/error.mp3";
import musicOpenCraft from "./../../music/bt-craft.mp3";
import craftSuccess from "./../../music/craft-success.mp3";
import craftFail from "./../../music/craft-fail.mp3";
import newClick from "./../../music/newclick.mp3";

import { ExplorerApi } from "atomicassets"; // атомик для списка панд
import { compose } from "glamor";

const fetch = require("node-fetch");
const api = new ExplorerApi(
  "https://" + localStorage.getItem('rpcA'),
  "atomicassets",
  { fetch, rateLimit: 4 }
);

// это транзакция отправки ИЗ
const cardTransferOut = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "transferout",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        from: "",
        to: "",
        asset_id: [],
      },
    },
  ],
};

class UpgradePageComponentJewelry extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      craftweapon: [
        {
          headWeapon: "sword",
          allelements: [
            {
              weapon: 376557,
              element: "water",
            },
            {
              weapon: 376582,
              element: "earth",
            },
            {
              weapon: 376577,
              element: "wind",
            },
            {
              weapon: 376570,
              element: "fire",
            },
          ],
        },
        {
          headWeapon: "bow",
          allelements: [
            {
              weapon: 373659,
              element: "water",
              bluePrint: 373647,
              partOne: 373649,
              partTwo: 373650,
              partThree: 373653,
            },
            {
              weapon: 373736,
              element: "earth",
              bluePrint: 373683,
              partOne: 373732,
              partTwo: 373734,
              partThree: 373735,
            },
            {
              weapon: 373682,
              element: "wind",
              bluePrint: 373671,
              partOne: 373677,
              partTwo: 373678,
              partThree: 373679,
            },
            {
              weapon: 373669,
              element: "fire",
              bluePrint: 373661,
              partOne: 373665,
              partTwo: 373666,
              partThree: 373667,
            },
          ],
        },
        {
          headWeapon: "hammer",
          allelements: [
            {
              weapon: 375234,
              element: "water",
              bluePrint: 375229,
              partOne: 375231,
              partTwo: 375232,
              partThree: 375233,
            },
            {
              weapon: 376151,
              element: "earth",
              bluePrint: 376146,
              partOne: 376147,
              partTwo: 376149,
              partThree: 376150,
            },
            {
              weapon: 376145,
              element: "wind",
              bluePrint: 376141,
              partOne: 376142,
              partTwo: 376143,
              partThree: 376144,
            },
            {
              weapon: 375243,
              element: "fire",
              bluePrint: 375236,
              partOne: 375239,
              partTwo: 375240,
              partThree: 375241,
            },
          ],
        },
        {
          headWeapon: "axe",
          allelements: [
            {
              weapon: 373391,
              element: "water",
              bluePrint: 373376,
              partOne: 373380,
              partTwo: 373384,
              partThree: 373387,
            },
            {
              weapon: 373457,
              element: "earth",
              bluePrint: 373452,
              partOne: 373453,
              partTwo: 373454,
              partThree: 373456,
            },
            {
              weapon: 373448,
              element: "wind",
              bluePrint: 373439,
              partOne: 373442,
              partTwo: 373444,
              partThree: 373447,
            },
            {
              weapon: 373394,
              element: "fire",
              bluePrint: 373661,
              partOne: 373406,
              partTwo: 373427,
              partThree: 373431,
            },
          ],
        },
        {
          headWeapon: "stick",
          allelements: [
            {
              weapon: 376320,
              element: "water",
              bluePrint: 376312,
              partOne: 376313,
              partTwo: 376315,
              partThree: 376319,
            },
            {
              weapon: 376407,
              element: "earth",
              bluePrint: 376397,
              partOne: 376398,
              partTwo: 376399,
              partThree: 376400,
            },
            {
              weapon: 376374,
              element: "wind",
              bluePrint: 376330,
              partOne: 376334,
              partTwo: 376337,
              partThree: 376342,
            },
            {
              weapon: 376329,
              element: "fire",
              bluePrint: 376322,
              partOne: 376325,
              partTwo: 376327,
              partThree: 376328,
            },
          ],
        },
        {
          headWeapon: "mech",
          allelements: [
            {
              weapon: 376156,
              element: "water",
              bluePrint: 376152,
              partOne: 376153,
              partTwo: 376154,
              partThree: 376155,
            },
            {
              weapon: 376178,
              element: "earth",
              bluePrint: 376173,
              partOne: 376174,
              partTwo: 376175,
              partThree: 376176,
            },
            {
              weapon: 376172,
              element: "wind",
              bluePrint: 376165,
              partOne: 376166,
              partTwo: 376167,
              partThree: 376170,
            },
            {
              weapon: 376162,
              element: "fire",
              bluePrint: 376157,
              partOne: 376158,
              partTwo: 376160,
              partThree: 376161,
            },
          ],
        },
        {
          headWeapon: "claws",
          allelements: [
            {
              weapon: 374507,
              element: "water",
              bluePrint: 373737,
              partOne: 373741,
              partTwo: 373742,
              partThree: 373743,
            },
            {
              weapon: 374535,
              element: "earth",
              bluePrint: 374526,
              partOne: 374528,
              partTwo: 374531,
              partThree: 374532,
            },
            {
              weapon: 374525,
              element: "wind",
              bluePrint: 374519,
              partOne: 374521,
              partTwo: 374522,
              partThree: 374523,
            },
            {
              weapon: 374515,
              element: "fire",
              bluePrint: 374508,
              partOne: 374510,
              partTwo: 374511,
              partThree: 374512,
            },
          ],
        },
        {
          headWeapon: "slingshot",
          allelements: [
            {
              weapon: 376198,
              element: "water",
              bluePrint: 376194,
              partOne: 376194,
              partTwo: 376195,
              partThree: 376196,
            },
            {
              weapon: 376310,
              element: "earth",
              bluePrint: 376300,
              partOne: 376304,
              partTwo: 376306,
              partThree: 376308,
            },
            {
              weapon: 376302,
              element: "wind",
              bluePrint: 376295,
              partOne: 376296,
              partTwo: 376297,
              partThree: 376299,
            },
            {
              weapon: 376204,
              element: "fire",
              bluePrint: 376200,
              partOne: 376201,
              partTwo: 376202,
              partThree: 376203,
            },
          ],
        },
        {
          headWeapon: "gun",
          allelements: [
            {
              weapon: 374606,
              element: "water",
              bluePrint: 374597,
              partOne: 374598,
              partTwo: 374601,
              partThree: 374604,
            },
            {
              weapon: 374653,
              element: "earth",
              bluePrint: 374641,
              partOne: 374643,
              partTwo: 374648,
              partThree: 374651,
            },
            {
              weapon: 374640,
              element: "wind",
              bluePrint: 374629,
              partOne: 374632,
              partTwo: 374637,
              partThree: 374639,
            },
            {
              weapon: 374615,
              element: "fire",
              bluePrint: 374608,
              partOne: 374611,
              partTwo: 374612,
              partThree: 374614,
            },
          ],
        },
        {
          headWeapon: "dagger",
          allelements: [
            {
              weapon: 374544,
              element: "water",
              bluePrint: 374537,
              partOne: 374538,
              partTwo: 374539,
              partThree: 374542,
            },
            {
              weapon: 374596,
              element: "earth",
              bluePrint: 374591,
              partOne: 374592,
              partTwo: 374593,
              partThree: 374594,
            },
            {
              weapon: 374590,
              element: "wind",
              bluePrint: 374585,
              partOne: 374587,
              partTwo: 374588,
              partThree: 374589,
            },
            {
              weapon: 374570,
              element: "fire",
              bluePrint: 374545,
              partOne: 374558,
              partTwo: 374561,
              partThree: 374565,
            },
          ],
        },
      ],
      activeWeaponName: "",
      activeRecipe: {
        arreyIndex: "",
        weapon: "",
        tempaleID: "",
        res: "",
        resNum: "",
        resIndex: "",
      },
      openRecipe: false,
      allUserWeapon: {}, // все оружия
      allUserRes: {}, // все оружия
      modalResult: {
        open: false, // показать модалку результата
        loading: true, // ожидание результата
      },
      craftpay: false, //
      modalcraft: {
        top: "Upgrade jewelry",
        title: "The blacksmith is ready to start work",
        button: "first",
        descr:
          `The blacksmith always takes money <span>(10 ${process.env.REACT_APP_VAL})<i></i></span> for the front, since nothing motivates like a salary. `,
      },
      craftfail: "",
      craftResult: "",
      activePage: 1,
      countPage: "2",
      loadingNft: true,
    };

    this.clearError = this.clearError.bind(this);
    this.GetUserWeaponList = this.GetUserWeaponList.bind(this);
    this.openRecipe = this.openRecipe.bind(this);
    this.backFromRecipe = this.backFromRecipe.bind(this);

    this.craftpay = this.craftpay.bind(this);
    this.opencraftmodal = this.opencraftmodal.bind(this);
    this.craft = this.craft.bind(this);
    //this.GetPandaPartList = this.GetPandaPartList.bind(this);
    //this.GetPandaWeaponList = this.GetPandaWeaponList.bind(this);
    this.closeModalResult = this.closeModalResult.bind(this);
    this.closeModalSteps = this.closeModalSteps.bind(this);
    this.medalCheck = this.medalCheck.bind(this);

    this.audioMusicError = new Audio(musicError);
    this.audioMusicOpenCraft = new Audio(musicOpenCraft);
    this.audioMusicCraftSuccess = new Audio(craftSuccess);
    this.audioMusicCraftfail = new Audio(craftFail);
    this.audioMusicNewClick = new Audio(newClick);
  }

  componentDidMount() {
    //this.GetUserWeaponList();
    this.medalCheck();
    this.getAllNft();
  }

  async getAllNft() {
    try {
      let promResWood = [];
      let promResSteel = [];
      let promResTit = [];
      let promResKves = [];
      let promResGold = [];
      let promResDwem = [];
      let promStone = [];

      this.setState({
        ...this.state,
        loadingNft: true,
      });

      //promResWood.push(this.GetUserWeaponList(431181, 'res'));
      promResSteel.push(this.GetUserWeaponList(431182, "res"));
      promResTit.push(this.GetUserWeaponList(431183, "res"));
      promResKves.push(this.GetUserWeaponList(431184, "res"));
      promResGold.push(this.GetUserWeaponList(431185, "res"));
      promResDwem.push(this.GetUserWeaponList(431186, "res"));
      promStone.push(this.GetUserWeaponList("", "armor"));

      let resultW = await Promise.all(promResWood);
      let resultSt = await Promise.all(promResSteel);
      let resultT = await Promise.all(promResTit);
      let resultK = await Promise.all(promResKves);
      let resultG = await Promise.all(promResGold);
      let resultD = await Promise.all(promResDwem);
      let resultS = await Promise.all(promStone);

      var resultNew = [];
      var resultNewRes = [];
      resultNewRes.push(
        ...resultSt[0],
        ...resultT[0],
        ...resultK[0],
        ...resultG[0],
        ...resultD[0]
      );
      resultNew.push(...resultS[0]);

      //console.log("resultNew", resultNew);
      let newData = {};
      Object.entries(resultNew).forEach((pair) => {
        let [key, value] = pair;
        //console.log(value);

        if (!newData[value.template.template_id])
          newData[value.template.template_id] = [];
        newData[value.template.template_id].push(value);
      });

      let newDataRes = {};
      Object.entries(resultNewRes).forEach((pair) => {
        let [key, value] = pair;
        //console.log(value);

        if (!newDataRes[value.template.template_id])
          newDataRes[value.template.template_id] = [];
        newDataRes[value.template.template_id].push(value);
      });
      //console.log("newDataRes", newDataRes)
      var arreyObj = Object.values(newData);
      var arreyObjRes = Object.values(newDataRes);

      //console.log('arreyObj.length', arreyObj.length)

      this.setState({
        ...this.state,
        allUserWeapon: arreyObj,
        allUserRes: arreyObjRes,
        countPage: arreyObj.length / 8,
        loadingNft: false,
      });

      // result = result[0].map( (item) => {
      //     return {
      //         asset_id: item.asset_id,
      //         template_id: item.template.template_id,
      //         schema_name: item.schema.schema_name
      //     }
      // })

      // resultS = resultS[0].map( (item) => {
      //     return {
      //         asset_id: item.asset_id,
      //         template_id: item.template.template_id,
      //         schema_name: item.schema.schema_name
      //     }
      // })

      // var resultNew = [];
      // resultNew.push(...result, ...resultS);

      // this.setState({
      //     ...this.state,
      //     allparts: resultNew,
      //     counPartWeapon: {
      //         resource: result.length,
      //         stone: resultS.length
      //     },
      //     loadingNft: false
      // });
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
    }
  }

  async medalCheck() {
    try {
      //console.log(555555555555555555555555555555555555555555555);
      const activeUser = this.props.ual.activeUser;
      const accountName = await activeUser.getAccountName();
      let rpcc;
      if (this.props.ual.activeUser.rpc != undefined) {
        rpcc = this.props.ual.activeUser.rpc;
      } else if (
        this.props.ual.activeUser.rpc == undefined &&
        this.props.ual.activeUser.wax
      ) {
        rpcc = this.props.ual.activeUser.wax.rpc;
      } else {
        rpcc = new JsonRpc(localStorage.getItem("rpc"));
      }
      const results = await rpcc.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "medaltop", // name of the table as specified by the contract abi
        //"table": "usersnew",    // name of the table as specified by the contract abi
        limit: 1,
        lower_bound: accountName,
        upper_bound: accountName,
        //lower_bound: 0,
        key_type: "i64",
        //"index_position": 2,
        //"limit": -1,
        reverse: !0,
        show_payer: false,
        index_position: 1,
      });
      const originRowUser = results.rows[0];
      console.log("originRowUser", originRowUser);

      var totalLuck = 0;
      if (results.rows[0]) {
        if (originRowUser.medal100) {
          totalLuck = 5;
          if (originRowUser.medal10) {
            totalLuck += 2.5;
            if (originRowUser.medal300) {
              totalLuck += 1.5;
            }
          } else if (originRowUser.medal300) {
            totalLuck += 1.5;
          }
        } else if (originRowUser.medal10) {
          totalLuck = 5;
          if (originRowUser.medal100) {
            totalLuck += 2.5;
            if (originRowUser.medal300) {
              totalLuck += 1.5;
            }
          } else if (originRowUser.medal300) {
            totalLuck += 1.5;
          }
        } else if (originRowUser.medal300) {
          totalLuck = 3;
          if (originRowUser.medal10) {
            totalLuck += 2.5;
            if (originRowUser.medal100) {
              totalLuck += 2.5;
            }
          } else if (originRowUser.medal100) {
            totalLuck += 2.5;
          }
        }
      }

      if (originRowUser != undefined) {
        this.setState({
          contractMedal: totalLuck,
        });
      }
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
    }
  }

  async clearError() {
    this.setState({
      errorText: "",
      hasError: false,
    });
  }

  async GetQuestList(shema) {
    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;

    /*console.log("tmpl", tmpl);
        console.log("shemaname", shemaname);*/
    return await api
      .getAssets({
        limit: 1000,
        owner: userName,
        collection_name: "nftpandawaxp",
        schema_name: shema,
        //template_id: tmpl,
      })
      .then((assets) =>
        //console.log(assets);

        assets.map((item) => {
          return {
            asset_id: item.asset_id,
            template: {
              template_id: item.template.template_id,
            },
            schema: {
              schema_name: item.schema.schema_name,
            },
            data: {
              rarity: item.data.rarity,
              name: item.data.name,
            },
          };
        })
      );
  }

  async GetUserWeaponList(parts, type) {
    var schema;
    var many = 1000;
    if (type == "res") {
      schema = "resources";
      many = 100;
    } else {
      schema = "jewelry";
    }
    // console.log('parts', parts);
    // console.log('type', type);
    // console.log('schema', schema);

    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    //arr.push();
    // console.log("arr", arr);
    // console.log("this.state.activeRecipe.bluePrint", this.state.activeRecipe.bluePrint);
    return await api
      .getAssets({
        limit: many,
        owner: userName,
        collection_name: "nftpandawaxp",
        schema_name: schema,
        //schema_name: ["armor"/*, "promo"*/],
        template_id: parts,
      })
      .then((assets) => {
        return assets;
      });
  }

  async openRecipe(index, templateId) {
    //console.log(this.state.allUserWeapon);
    var jewUpgr = 0;
    var resUpgr = 0;

    //console.log("templateId", templateId)

    switch (parseInt(templateId)) {
      // Ring water
      case 412726:
        jewUpgr = 412728;
        resUpgr = 431182;
        break;
      case 412728:
        jewUpgr = 412730;
        resUpgr = 431183;
        break;
      case 412730:
        jewUpgr = 412732;
        resUpgr = 431184;
        break;
      case 412732:
        jewUpgr = 412733;
        resUpgr = 431185;
        break;
      case 412733:
        jewUpgr = 412735;
        resUpgr = 431186;
        break;
      // end Ring water
      // Ring fire
      case 412736:
        jewUpgr = 412737;
        resUpgr = 431182;
        break;
      case 412737:
        jewUpgr = 412738;
        resUpgr = 431183;
        break;
      case 412738:
        jewUpgr = 412739;
        resUpgr = 431184;
        break;
      case 412739:
        jewUpgr = 412740;
        resUpgr = 431185;
        break;
      case 412740:
        jewUpgr = 412741;
        resUpgr = 431186;
        break;
      // end Ring fire
      // Ring wind
      case 412750:
        jewUpgr = 412751;
        resUpgr = 431182;
        break;
      case 412751:
        jewUpgr = 412753;
        resUpgr = 431183;
        break;
      case 412753:
        jewUpgr = 412754;
        resUpgr = 431184;
        break;
      case 412754:
        jewUpgr = 412755;
        resUpgr = 431185;
        break;
      case 412755:
        jewUpgr = 412757;
        resUpgr = 431186;
        break;
      // end Ring wind
      // Ring earth
      case 413107:
        jewUpgr = 413108;
        resUpgr = 431182;
        break;
      case 413108:
        jewUpgr = 413111;
        resUpgr = 431183;
        break;
      case 413111:
        jewUpgr = 413112;
        resUpgr = 431184;
        break;
      case 413112:
        jewUpgr = 413114;
        resUpgr = 431185;
        break;
      case 413114:
        jewUpgr = 413115;
        resUpgr = 431186;
        break;
      // end Ring earth

      // Necklace water
      case 413131:
        jewUpgr = 413133;
        resUpgr = 431182;
        break;
      case 413133:
        jewUpgr = 413135;
        resUpgr = 431183;
        break;
      case 413135:
        jewUpgr = 413136;
        resUpgr = 431184;
        break;
      case 413136:
        jewUpgr = 413139;
        resUpgr = 431185;
        break;
      case 413139:
        jewUpgr = 413141;
        resUpgr = 431186;
        break;
      // end Necklace water
      // Necklace fire
      case 413857:
        jewUpgr = 413859;
        resUpgr = 431182;
        break;
      case 413859:
        jewUpgr = 413860;
        resUpgr = 431183;
        break;
      case 413860:
        jewUpgr = 413862;
        resUpgr = 431184;
        break;
      case 413862:
        jewUpgr = 413863;
        resUpgr = 431185;
        break;
      case 413863:
        jewUpgr = 413865;
        resUpgr = 431186;
        break;
      // end Necklace fire
      // Necklace wind
      case 413867:
        jewUpgr = 413868;
        resUpgr = 431182;
        break;
      case 413868:
        jewUpgr = 413869;
        resUpgr = 431183;
        break;
      case 413869:
        jewUpgr = 413872;
        resUpgr = 431184;
        break;
      case 413872:
        jewUpgr = 413874;
        resUpgr = 431185;
        break;
      case 413874:
        jewUpgr = 413877;
        resUpgr = 431186;
        break;
      // end Ring wind
      // Necklace earth
      case 414774:
        jewUpgr = 414776;
        resUpgr = 431182;
        break;
      case 414776:
        jewUpgr = 414778;
        resUpgr = 431183;
        break;
      case 414778:
        jewUpgr = 414782;
        resUpgr = 431184;
        break;
      case 414782:
        jewUpgr = 414783;
        resUpgr = 431185;
        break;
      case 414783:
        jewUpgr = 414784;
        resUpgr = 431186;
        break;
      // end Necklace earth

      // Bracelet water
      case 414785:
        jewUpgr = 414786;
        resUpgr = 431182;
        break;
      case 414786:
        jewUpgr = 414798;
        resUpgr = 431183;
        break;
      case 414798:
        jewUpgr = 414800;
        resUpgr = 431184;
        break;
      case 414800:
        jewUpgr = 414801;
        resUpgr = 431185;
        break;
      case 414801:
        jewUpgr = 414804;
        resUpgr = 431186;
        break;
      // end Bracelet water
      // Bracelet fire
      case 415378:
        jewUpgr = 415381;
        resUpgr = 431182;
        break;
      case 415381:
        jewUpgr = 415385;
        resUpgr = 431183;
        break;
      case 415385:
        jewUpgr = 415388;
        resUpgr = 431184;
        break;
      case 415388:
        jewUpgr = 415389;
        resUpgr = 431185;
        break;
      case 415389:
        jewUpgr = 415392;
        resUpgr = 431186;
        break;
      // end Bracelet fire
      // Bracelet wind
      case 415402:
        jewUpgr = 415403;
        resUpgr = 431182;
        break;
      case 415403:
        jewUpgr = 415404;
        resUpgr = 431183;
        break;
      case 415404:
        jewUpgr = 415406;
        resUpgr = 431184;
        break;
      case 415406:
        jewUpgr = 415407;
        resUpgr = 431185;
        break;
      case 415407:
        jewUpgr = 415408;
        resUpgr = 431186;
        break;
      // end Bracelet wind
      // Bracelet earth
      case 415409:
        jewUpgr = 415575;
        resUpgr = 431182;
        break;
      case 415575:
        jewUpgr = 415576;
        resUpgr = 431183;
        break;
      case 415576:
        jewUpgr = 415577;
        resUpgr = 431184;
        break;
      case 415577:
        jewUpgr = 415578;
        resUpgr = 431185;
        break;
      case 415578:
        jewUpgr = 415579;
        resUpgr = 431186;
        break;
      // end Bracelet earth

      // Brooch water
      case 416477:
        jewUpgr = 416478;
        resUpgr = 431182;
        break;
      case 416478:
        jewUpgr = 416479;
        resUpgr = 431183;
        break;
      case 416479:
        jewUpgr = 416482;
        resUpgr = 431184;
        break;
      case 416482:
        jewUpgr = 416483;
        resUpgr = 431185;
        break;
      case 416483:
        jewUpgr = 416486;
        resUpgr = 431186;
        break;
      // end Brooch water
      // Brooch fire
      case 416488:
        jewUpgr = 416489;
        resUpgr = 431182;
        break;
      case 416489:
        jewUpgr = 416490;
        resUpgr = 431183;
        break;
      case 416490:
        jewUpgr = 416491;
        resUpgr = 431184;
        break;
      case 416491:
        jewUpgr = 416492;
        resUpgr = 431185;
        break;
      case 416492:
        jewUpgr = 416493;
        resUpgr = 431186;
        break;
      // end Brooch fire
      // Brooch wind
      case 417238:
        jewUpgr = 417239;
        resUpgr = 431182;
        break;
      case 417239:
        jewUpgr = 417240;
        resUpgr = 431183;
        break;
      case 417240:
        jewUpgr = 417241;
        resUpgr = 431184;
        break;
      case 417241:
        jewUpgr = 417242;
        resUpgr = 431185;
        break;
      case 417242:
        jewUpgr = 417244;
        resUpgr = 431186;
        break;
      // end Brooch wind
      // Brooch earth
      case 417251:
        jewUpgr = 417252;
        resUpgr = 431182;
        break;
      case 417252:
        jewUpgr = 417253;
        resUpgr = 431183;
        break;
      case 417253:
        jewUpgr = 417254;
        resUpgr = 431184;
        break;
      case 417254:
        jewUpgr = 417257;
        resUpgr = 431185;
        break;
      case 417257:
        jewUpgr = 417259;
        resUpgr = 431186;
        break;
      // end Brooch earth

      // Earring  water
      case 428200:
        jewUpgr = 428202;
        resUpgr = 431182;
        break;
      case 428202:
        jewUpgr = 428204;
        resUpgr = 431183;
        break;
      case 428204:
        jewUpgr = 428207;
        resUpgr = 431184;
        break;
      case 428207:
        jewUpgr = 428213;
        resUpgr = 431185;
        break;
      case 428213:
        jewUpgr = 428215;
        resUpgr = 431186;
        break;
      // end Earring  water
      // Earring  fire
      case 428220:
        jewUpgr = 428222;
        resUpgr = 431182;
        break;
      case 428222:
        jewUpgr = 428225;
        resUpgr = 431183;
        break;
      case 428225:
        jewUpgr = 428226;
        resUpgr = 431184;
        break;
      case 428226:
        jewUpgr = 428527;
        resUpgr = 431185;
        break;
      case 428527:
        jewUpgr = 428529;
        resUpgr = 431186;
        break;
      // end Earring  fire
      // Earring  wind
      case 428540:
        jewUpgr = 428543;
        resUpgr = 431182;
        break;
      case 428543:
        jewUpgr = 428546;
        resUpgr = 431183;
        break;
      case 428546:
        jewUpgr = 428548;
        resUpgr = 431184;
        break;
      case 428548:
        jewUpgr = 428550;
        resUpgr = 431185;
        break;
      case 428550:
        jewUpgr = 428553;
        resUpgr = 431186;
        break;
      // end Earring  wind
      // Earring  earth
      case 429272:
        jewUpgr = 429274;
        resUpgr = 431182;
        break;
      case 429274:
        jewUpgr = 429275;
        resUpgr = 431183;
        break;
      case 429275:
        jewUpgr = 429276;
        resUpgr = 431184;
        break;
      case 429276:
        jewUpgr = 429277;
        resUpgr = 431185;
        break;
      case 429277:
        jewUpgr = 429295;
        resUpgr = 431186;
        break;
      // end Earring  earth

      default:
        console.log("FTF");
        jewUpgr = 0;
        resUpgr = 0;
        break;
    }

    var needRes =
      this.state.allUserWeapon[index][0].data.rarity == "Common"
        ? 431182
        : this.state.allUserWeapon[index][0].data.rarity == "Uncommon"
          ? 431183
          : this.state.allUserWeapon[index][0].data.rarity == "Rare"
            ? 431184
            : this.state.allUserWeapon[index][0].data.rarity == "Epic"
              ? 431185
              : 431186;
    //console.log("needRes", needRes);
    var indexRes;
    var needNum;
    for (let i = 0; i < this.state.allUserRes.length; i++) {
      if (this.state.allUserRes[i][0].template.template_id == needRes) {
        indexRes = i;
        needNum = this.state.allUserRes[i].length;
        //console.log("this.state.allUserRes[i]", this.state.allUserRes[i]);
      }
    }

    this.setState({
      ...this.state,
      openRecipe: true,
      activeWeaponName: this.state.allUserWeapon[index][0].data.name,
      activeRecipe: {
        arreyIndex: index,
        weapon: jewUpgr,
        tempaleID: templateId,
        res: needRes,
        resNum: needNum,
        resIndex: indexRes,
      },
    });

    if (localStorage.getItem("sound") == "true") {
      this.audioMusicNewClick.currentTime = 0;
      this.audioMusicNewClick.volume = 0.4;
      this.audioMusicNewClick.play();
    }

    // this.GetPandaPartList(this.state.craftweapon[index].allelements[element]);
    // this.GetPandaWeaponList(this.state.craftweapon[index].allelements[element]);
  }

  async backFromRecipe(index, element) {
    this.setState({
      ...this.state,
      openRecipe: false,
      activeWeaponName: "",
      activeRecipe: {
        arreyIndex: "",
        arreyResIndex: "",
        weapon: "",
        res: "",
        tempaleID: "",
      },
    });

    if (localStorage.getItem("sound") == "true") {
      this.audioMusicNewClick.currentTime = 0;
      this.audioMusicNewClick.volume = 0.4;
      this.audioMusicNewClick.play();
    }
  }

  async opencraftmodal() {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("open-modal");
    const number = cryptoRandomString({ length: 14, type: "numeric" });
    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;

    // try {
    //   let rpcc;
    //   if (this.props.ual.activeUser.rpc != undefined) {
    //     rpcc = this.props.ual.activeUser.rpc;
    //   } else if (
    //     this.props.ual.activeUser.rpc == undefined &&
    //     this.props.ual.activeUser.wax
    //   ) {
    //     rpcc = this.props.ual.activeUser.wax.rpc;
    //   } else {
    //     rpcc = new JsonRpc(localStorage.getItem("rpc"));
    //   }
    //   //const userName = this.props.ual.activeUser.accountName;
    //   const results = await rpcc.get_table_rows({
    //     json: true,
    //     code: process.env.REACT_APP_CONTRACT, // contract who owns the table
    //     scope: process.env.REACT_APP_CONTRACT, // scope of the table
    //     table: "oracletable", // name of the table as specified by the contract abi
    //     //"key_type": "i64",
    //     lower_bound: userName,
    //     upper_bound: userName,
    //     //"limit": -1,
    //     //reverse: !0,
    //     show_payer: false,
    //     //index_position: 2,
    //   });
    //   const resultRow = results.rows;
    //   //console.log('resultRow', resultRow[0]);
    //   if (resultRow[0]) {
    //     if (resultRow[0].storer == userName) {
    //       this.setState({
    //         ...this.setState,
    //         modalcraft: {
    //           top: "upgrade",
    //           title: "Now the blacksmith is ready to start work",
    //           button: "second",
    //           descr:
    //             "hand over all parts of the weapon so that he can start working on his craft. It can take a few minutes, please wait...",
    //         },
    //       });
    //     }
    //   }
    // } catch (e) {
    //   // console.log(e.message)
    //   console.warn(e);
    //   this.setState({
    //     hasError: true,
    //     errorText: e.message,
    //   });
    //   console.log("\nCaught exception: " + e);
    //   if (localStorage.getItem("sound") == "true") {
    //     this.audioMusicError.currentTime = 0;
    //     this.audioMusicError.volume = 0.1;
    //     this.audioMusicError.play();
    //   }
    // }

    this.setState({
      ...this.setState,
      craftpay: true,
    });

    if (localStorage.getItem("sound") == "true") {
      this.audioMusicOpenCraft.currentTime = 0;
      this.audioMusicOpenCraft.volume = 0.3;
      this.audioMusicOpenCraft.play();
    }
  }

  async craftpay() {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");
    const number = cryptoRandomString({ length: 14, type: "numeric" });
    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;

    try {
      await activeUser
        .signTransaction(
          {
            actions: [
              {
                account: process.env.REACT_APP_TOKEN,
                name: "transfer",
                authorization: [
                  {
                    actor: userName, // use account that was logged in
                    permission: "active",
                  },
                ],
                data: {
                  from: userName,
                  to: process.env.REACT_APP_CONTRACT,
                  quantity: "15.0000 " + process.env.REACT_APP_VAL,
                  memo: "weaponpay " + this.state.activeRecipe.weapon + " work",
                },
              },
            ],
          },
          { blocksBehind: 3, expireSeconds: 30 }
        )
        .then((response) => {
          if (response.status === "executed") {
            loadingSlot.classList.remove("loading");
            this.setState({
              ...this.setState,
              modalcraft: {
                top: "upgrade",
                title: "Now the blacksmith is ready to start work",
                button: "second",
                descr:
                  "hand over all parts of the weapon so that he can start working on his craft. It can take a few minutes, please wait...",
              },
            });
            this.props.updateBalance(false, parseFloat(20.0).toFixed(4));
          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
        chooseHeroLoading: false,
      });

      if (localStorage.getItem("sound") == "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
      loadingSlot.classList.remove("loading");
    }
  }

  async sendWombat(event) {
    const activeUser = this.props.ual.activeUser;
    const accountName = await activeUser.getAccountName();
    var dateNow = parseInt(Date.now() / 1000);
    var datas = {

      "username": accountName,
      "event": event,
      "eventtime": dateNow,
    }


  }

  async craft() {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");
    const number = cryptoRandomString({ length: 14, type: "numeric" });
    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    var firstWeapon =
      this.state.allUserWeapon[this.state.activeRecipe.arreyIndex][0].asset_id;
    var secondWeapon =
      this.state.allUserWeapon[this.state.activeRecipe.arreyIndex][1].asset_id;

    var resource = [];

    this.state.allUserRes[this.state.activeRecipe.resIndex].map((u, index) => {
      //console.log("INDEX", index);
      if (index < 5) {
        resource.push(u.asset_id);
      }
      //console.log("u[0]", u.template.template_id)
    });

    // console.log('first weapon', resource);
    // console.log('secon weapon', secondWeapon);

    // console.log("this.state.activeRecipe.weapon", this.state.activeRecipe.weapon);
    // console.log("number", number);

    try {
      await activeUser
        .signTransaction(
          {
            actions: [
              {
                account: "atomicassets",
                name: "transfer",
                authorization: [
                  {
                    actor: userName, // use account that was logged in
                    permission: "active",
                  },
                ],
                data: {
                  from: userName,
                  to: process.env.REACT_APP_CONTRACT,
                  asset_ids: [firstWeapon, secondWeapon, ...resource],
                  memo:
                    "upgrade " +
                    this.state.activeRecipe.weapon +
                    " " +
                    number +
                    " jewelry for panda",
                },
              },
            ],
          },
          { blocksBehind: 3, expireSeconds: 30 }
        )
        .then((response) => {
          if (response.status === "executed") {
            loadingSlot.classList.remove("loading");
            this.props.updateBalance(false, parseFloat(10.0).toFixed(4));
            this.setState({
              ...this.setState,
              craftpay: false,
              modalResult: {
                open: true,
                loading: true,
              },
            });
            this.getresult();
            switch (this.state.activeRecipe.weapon) {
              case 412733: case 412740: case 412755: case 413114: case 413139: case 413863: case 413874: case 414783: case 414801: case 415389: case 415407:
              case 415578: case 416483: case 416492: case 417242: case 417257: case 428213: case 428527: case 428550: case 429277:


                this.sendWombat("craft");
                break;
              default:

                break;
            }
          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
        chooseHeroLoading: false,
      });
      if (localStorage.getItem("sound") == "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
      loadingSlot.classList.remove("loading");
    }
  }

  async getresult() {
    (async () => {
      try {
        //console.log('try');
        const userName = this.props.ual.activeUser.accountName;
        const activeUser = this.props.ual.activeUser;
        const weaponsID = this.state.activeRecipe.weapon;
        var perc = 55;
        if (this.state.contractMedal) {
          var perc = 55 + this.state.contractMedal;
        }

        this.interval = setInterval(() => {
          //console.log('setTimeout');
          if (this.state.craftfail <= perc && this.state.craftfail != 0) {
            clearInterval(this.interval);

            console.log("YES IT DONE");
            this.setState({
              ...this.state,
              craftResult: "success",
              modalResult: {
                ...this.state.modalResult,
                loading: false,
              },
            });
            if (localStorage.getItem("sound") == "true") {
              this.audioMusicCraftSuccess.currentTime = 0;
              this.audioMusicCraftSuccess.volume = 0.4;
              this.audioMusicCraftSuccess.play();
            }
          } else if (this.state.craftfail > perc) {
            console.log("NOOOOOOOOOOOOOOOOO");

            clearInterval(this.interval);
            this.setState({
              ...this.state,
              craftResult: "fail",
              modalResult: {
                ...this.state.modalResult,
                loading: false,
              },
            });

            if (localStorage.getItem("sound") == "true") {
              this.audioMusicCraftfail.currentTime = 0;
              this.audioMusicCraftfail.volume = 0.3;
              this.audioMusicCraftfail.play();
            }
          }

          // console.log("timer");
          // console.log("this.state.allThisWeapon", this.state.allThisWeapon);
          // console.log("assets", assets);
          // console.log("this.state.craftfail", this.state.craftfail);

          //this.GetPandaWeaponList(this.state.activeRecipe);
          this.getUserNoRes();
        }, 10000);
      } catch (e) {
        //console.log(e.message)
        console.warn(e);
        this.setState({
          ...this.state,
          hasError: true,
          errorText: e.message,
          modalEat: {
            ...this.state.modalEat,
            loadingChooseEat: false,
          },
        });
        console.log("\nCaught exception: " + e);
        if (localStorage.getItem("sound") == "true") {
          this.audioMusicError.currentTime = 0;
          this.audioMusicError.volume = 0.1;
          this.audioMusicError.play();
        }
      }
    })();
  }

  async getUserNoRes() {
    const number = cryptoRandomString({ length: 14, type: "numeric" });
    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;

    try {
      let rpcc;
      if (this.props.ual.activeUser.rpc != undefined) {
        rpcc = this.props.ual.activeUser.rpc;
      } else if (
        this.props.ual.activeUser.rpc == undefined &&
        this.props.ual.activeUser.wax
      ) {
        rpcc = this.props.ual.activeUser.wax.rpc;
      } else {
        rpcc = new JsonRpc(localStorage.getItem("rpc"));
      }
      //const userName = this.props.ual.activeUser.accountName;
      const results = await rpcc.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "usersnew", // name of the table as specified by the contract abi
        //"key_type": "i64",
        lower_bound: userName,
        upper_bound: userName,
        //"limit": -1,
        //reverse: !0,
        show_payer: false,
        //index_position: 2,
      });
      const resultRow = results.rows;
      //console.log('resultRow getUserNoRes', resultRow[0]);
      if (resultRow[0]) {
        this.setState({
          ...this.setState,
          craftfail: resultRow[0].random,
        });
      }
    } catch (e) {
      // console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
      if (localStorage.getItem("sound") == "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  async closeModalResult() {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.remove("open-modal");
    this.setState({
      ...this.state,
      allparts: {}, // все части этого оружия который хотят скрафтить
      allThisWeapon: {}, // все оружия
      modalResult: {
        open: false, // показать модалку результата
        loading: true, // ожидание результата
      },
      craftpay: false, //
      modalcraft: {
        top: "Upgrade jewelry",
        title: "The blacksmith is ready to start work",
        button: "first",
        descr:
          `The blacksmith always takes money <span>(10 ${process.env.REACT_APP_VAL})<i></i></span> for the front, since nothing motivates like a salary. `,
      },
      craftfail: "",
      craftResult: "",
      openRecipe: false,
    });

    setTimeout(() => {
      this.getAllNft();
    }, 300);
  }

  async closeModalSteps() {
    this.setState({
      ...this.setState,
      craftpay: false,
    });
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.remove("open-modal");
    //console.log('closeModalSteps');
    this.getAllNft();
  }

  navpage(goto) {
    if (goto == "next") {
      this.setState({
        activePage: this.state.activePage + 1,
      });
    } else {
      this.setState({
        activePage: this.state.activePage - 1,
      });
    }
  }

  render() {
    return (
      <>
        <Menu  {...this.props.ual} wax={this.props.balanceWax} bam={this.props.balanceBam} game={this.props.balanceGame} updateBalance={this.props.updateBalance} />
        {this.state.hasError ? (
          <ErrorComponent
            errorMessage={this.state.errorText}
            clearError={this.clearError}
          />
        ) : null}
        <main className="craft-page">
          <div className="loading-block"></div>

          <div className="craft-wrap upgrade-page-bg upgr-jew">
            {this.state.openRecipe ? null : (
              <div className="craft-body-choose">
                <div className="choose-weapon-upgade">
                  <div
                    className={`choose-weapon-upgade-in ${this.state.loadingNft ? "loading" : "ok"
                      }`}
                  >
                    <Link className="bt-back" to="/craft">
                      <span></span>
                    </Link>

                    <div
                      className="bt-reload"
                      onClick={(e) => {
                        this.getAllNft();
                      }}
                    >
                      <span></span>
                    </div>

                    <UpgradeHomeLoop
                      {...this.props}
                      {...this.state}
                      openRecipe={this.openRecipe}
                    />

                    {this.state.loadingNft &&
                      this.state.allUserWeapon &&
                      !this.state.allUserWeapon.length && (
                        <div className="loading-block"></div>
                      )}
                  </div>

                  <div className="upgr-nav">
                    <div className="upgr-nav-in">
                      {this.state.countPage > 1 && this.state.activePage > 1 ? (
                        <button
                          className="nav-link nav-prev"
                          onClick={() => this.navpage("prev")}
                        >
                          <span></span>
                        </button>
                      ) : null}

                      <div className="nav-num">{this.state.activePage}</div>

                      {this.state.countPage > 1 &&
                        this.state.countPage >= this.state.activePage &&
                        this.state.countPage != this.state.activePage ? (
                        <button
                          className="nav-link nav-next"
                          onClick={() => this.navpage("next")}
                        >
                          <span></span>
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {this.state.openRecipe ? (
              <div className="upgr-recipe">
                <div className="upgr-recipe-in">
                  <div className="upgr-recipe-top">
                    <div className="bt-back">
                      <span
                        onClick={(e) => {
                          this.backFromRecipe();
                        }}
                      ></span>
                    </div>
                  </div>
                  <div className="upgr-recipe-wrap">
                    <div
                      className={`runa ${this.state.allUserWeapon[
                        this.state.activeRecipe.arreyIndex
                      ][0] &&
                        this.state.allUserWeapon[
                        this.state.activeRecipe.arreyIndex
                        ][1] &&
                        this.state.activeRecipe.resNum >= 5
                        ? "active"
                        : null
                        }`}
                    ></div>

                    <div className="upgr-recipe-wrap-in">
                      {/* one */}
                      <div
                        className={`one-card-upgr ${this.state.allUserWeapon[
                          this.state.activeRecipe.arreyIndex
                        ][0]
                          ? "active"
                          : "disable"
                          }`}
                      >
                        <div className="one-card-upgr-in">
                          <div className="one-card-upgr-thumb">
                            <picture>
                              <source
                                srcSet={`./images/jew/all/${this.state.activeRecipe.res}.webp`}
                                type="image/webp"
                              />
                              <source
                                srcSet={`./images/jew/all/${this.state.activeRecipe.res}.png`}
                                type="image/png"
                              />
                              <img
                                src={`./images/jew/all/${this.state.activeRecipe.res}.png`}
                                alt=""
                              />
                            </picture>
                          </div>

                          {!this.state.allUserWeapon[
                            this.state.activeRecipe.arreyIndex
                          ][0] ? (
                            <div className="btns-bay-craft">
                              <div className="one-bt-bc">
                                <a
                                  className="def-bt mid"
                                  href="https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&schema_name=weapon&sort=created&symbol=WAX"
                                >
                                  <span className="button-in">
                                    <span className="button-name">
                                      <span className="button-name-in">
                                        <span className="button-name-value">
                                          BUY
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </a>
                              </div>
                            </div>
                          ) : null}

                          <div className="part-num">
                            {this.state.activeRecipe.resNum}
                          </div>

                          <div className="need-parts">
                            {this.state.activeRecipe.resNum <= 5
                              ? this.state.activeRecipe.resNum
                              : "5"}
                            /5
                          </div>
                        </div>

                        <div
                          className={`dots-parts ${this.state.activeRecipe.resNum >= 5
                            ? "act-5"
                            : this.state.activeRecipe.resNum == 4
                              ? "act-4"
                              : this.state.activeRecipe.resNum == 3
                                ? "act-3"
                                : this.state.activeRecipe.resNum == 2
                                  ? "act-2"
                                  : this.state.activeRecipe.resNum == 1
                                    ? "act-1"
                                    : "act-0"
                            }`}
                        >
                          <div className="dots-parts-in">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                          </div>
                        </div>
                      </div>
                      {/* / one */}
                      {/* one */}
                      <div
                        className={`one-card-upgr ${this.state.allUserWeapon[
                          this.state.activeRecipe.arreyIndex
                        ][0]
                          ? "active"
                          : "disable"
                          }`}
                      >
                        <div className="one-card-upgr-in">
                          <div className="one-card-upgr-thumb">
                            <picture>
                              <source
                                srcSet={`./images/jew/all/${this.state.activeRecipe.tempaleID}.webp`}
                                type="image/webp"
                              />
                              <source
                                srcSet={`./images/jew/all/${this.state.activeRecipe.tempaleID}.png`}
                                type="image/png"
                              />
                              <img
                                src={`./images/jew/all/${this.state.activeRecipe.tempaleID}.png`}
                                alt=""
                              />
                            </picture>
                          </div>

                          {!this.state.allUserWeapon[
                            this.state.activeRecipe.arreyIndex
                          ][0] ? (
                            <div className="btns-bay-craft">
                              <div className="one-bt-bc">
                                <NavLink
                                  className="def-bt mid"
                                  to="/craft-weapon-page"
                                >
                                  <span className="button-in">
                                    <span className="button-name">
                                      <span className="button-name-in">
                                        <span className="button-name-value">
                                          craft
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </NavLink>
                              </div>
                              <div className="one-bt-bc">
                                <a
                                  className="def-bt mid"
                                  target="_blank"
                                  href={`https://wax.atomichub.io/market?collection_name=nftpandawaxp&data:text.rarity=Rare&match=${this.state.activeWeaponName.replace(
                                    / /g,
                                    "%20"
                                  )}&order=desc&schema_name=weapon&sort=created&symbol=WAX`}
                                >
                                  <span className="button-in">
                                    <span className="button-name">
                                      <span className="button-name-in">
                                        <span className="button-name-value">
                                          BUY
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </a>
                              </div>
                            </div>
                          ) : null}

                          <div className="part-num">
                            {
                              this.state.allUserWeapon[
                                this.state.activeRecipe.arreyIndex
                              ].length
                            }
                          </div>

                          <div className="need-parts">
                            {this.state.allUserWeapon[
                              this.state.activeRecipe.arreyIndex
                            ].length <= 2
                              ? this.state.allUserWeapon[
                                this.state.activeRecipe.arreyIndex
                              ].length
                              : "2"}
                            /2
                          </div>
                        </div>

                        <div
                          className={`dots-parts ${this.state.allUserWeapon[
                            this.state.activeRecipe.arreyIndex
                          ].length >= 2
                            ? "act-2"
                            : this.state.allUserWeapon[
                              this.state.activeRecipe.arreyIndex
                            ].length == 1
                              ? "act-1"
                              : "act-0"
                            }`}
                        >
                          <div className="dots-parts-in">
                            <span></span>
                            <span></span>
                          </div>
                        </div>
                      </div>
                      {/* / one */}
                    </div>
                  </div>

                  <div className="upgr-bt-do-upg">
                    <div className="upgr-bt">
                      {this.state.allUserWeapon[
                        this.state.activeRecipe.arreyIndex
                      ][0] &&
                        this.state.allUserWeapon[
                        this.state.activeRecipe.arreyIndex
                        ][1] &&
                        this.state.activeRecipe.resNum >= 5 ? (
                        <span
                          onClick={(e) => {
                            this.opencraftmodal();
                          }}
                        ></span>
                      ) : (
                        <span className="disabled"></span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <div
              className={`modal-result ${this.state.modalResult.open ? "open" : null
                } ${this.state.craftResult == "success" ? "success" : null} ${this.state.craftResult == "fail" ? "fail" : null
                }`}
            >
              <div className="modal-result-body">
                <div className="modal-result-body-in">
                  <div className="modal-result-head">
                    {!this.state.modalResult.loading ? (
                      <div
                        className="close-result"
                        onClick={(e) => {
                          this.closeModalResult();
                        }}
                      >
                        <span></span>
                      </div>
                    ) : null}
                    <div className="weapon-modal-name">
                      {this.state.activeWeaponName}
                    </div>
                    <div className="weapon-modal-wrap">
                      <div className="fail-res"></div>
                      <div className="success-res"></div>
                      {this.state.modalResult.loading ? (
                        <div className="loading-res"></div>
                      ) : null}
                      <div className="weapon-modal-img">
                        {this.state.activeRecipe.weapon ? (
                          <picture>
                            <source
                              srcSet={`./images/jew/all/${this.state.activeRecipe.weapon}.webp`}
                              type="image/webp"
                            />
                            <source
                              srcSet={`./images/jew/all/${this.state.activeRecipe.weapon}.png`}
                              type="image/png"
                            />
                            <img
                              src={`./images/jew/all/${this.state.activeRecipe.weapon}.png`}
                              alt=""
                            />
                          </picture>
                        ) : null}
                      </div>
                      {!this.state.modalResult.loading ? (
                        <div className="close-bt">
                          <button
                            className="def-bt min"
                            onClick={(e) => {
                              this.closeModalResult();
                            }}
                          >
                            <span className="button-in">
                              <span className="button-name">
                                <span className="button-name-in">
                                  <span className="button-name-value">
                                    close
                                  </span>
                                </span>
                              </span>
                            </span>
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="modal-result-close-trans"></div>
              </div>
              <div className="modal-weapon-bg"></div>
            </div>

            <div
              className={`modal-result craftpay ${this.state.craftpay ? "open" : null
                }`}
            >
              <div className="modal-result-body">
                <div className="modal-result-body-in">
                  <div className="modal-result-head">
                    <div
                      className="close-result"
                      onClick={(e) => {
                        this.closeModalResult();
                      }}
                    >
                      <span></span>
                    </div>

                    <div className="weapon-modal-name">
                      {this.state.modalcraft.top}
                    </div>
                    <div className="weapon-modal-wrap">
                      <div className="weapon-modal-img">
                        <div className="step-wrap">
                          <div className="step-title">
                            {this.state.modalcraft.title}
                          </div>
                          <div className="step-loader">
                            <div className="loading-res"></div>
                            <div className="step-bt">
                              {/* {this.state.modalcraft.button !== "first" ?  */}
                              <button
                                className="def-bt min"
                                onClick={(e) => {
                                  this.craft();
                                }}
                              >
                                <span className="button-in">
                                  <span className="button-name">
                                    <span className="button-name-in">
                                      <span className="button-name-value">
                                        upgrade
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </button>
                              {/* :
                                                            <button className="def-bt min" onClick={(e) => {this.craft()}}>
                                                                <span className="button-in">
                                                                    <span className="button-name">
                                                                        <span className="button-name-in">
                                                                            <span className="button-name-value">
                                                                                upgarde
                                                                            </span> 
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </button>
                                                        } */}
                            </div>
                          </div>
                          {this.state.modalcraft.button == "first" ? (
                            <div className="info-55">
                              <span>Attention:</span> the chance of successful{" "}
                              <br /> crafting is{" "}
                              <i>
                                {this.state.contractMedal
                                  ? 55 + this.state.contractMedal
                                  : "55"}
                                %
                              </i>
                            </div>
                          ) : null}
                          <div
                            className="step-descr"
                            dangerouslySetInnerHTML={{
                              __html: this.state.modalcraft.descr,
                            }}
                          >
                            {/* {this.state.modalcraft.descr} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-result-close-trans"></div>
              </div>
              <div className="modal-weapon-bg"></div>
            </div>
          </div>

          <footer>
            <div className="footer-nav">
              <nav>
                <ul>
                  <li>
                    <a
                      className="foot-logo-1"
                      target="_blank"
                      href="https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&sort=created&symbol=WAX"
                    >
                      <span></span>
                      <i>Market</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-2"
                      target="_blank"
                      href="https://twitter.com/NftPanda/"
                    >
                      <span></span>
                      <i>Twitter</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-3"
                      target="_blank"
                      href="https://www.instagram.com/nftpanda/"
                    >
                      <span></span>
                      <i>Instagram</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-4"
                      target="_blank"
                      href="https://discord.gg/vCSgme4d5b"
                    >
                      <span></span>
                      <i>Discord</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-5"
                      target="_blank"
                      href={`https://wax.alcor.exchange/swap?output=${process.env.REACT_APP_VAL}-nftpandabamb&input=WAX-eosio.token`}
                    >
                      <span></span>
                      <i>{process.env.REACT_APP_VAL} Trade</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-6"
                      target="_blank"
                      href="https://discord.gg/vCSgme4d5b"
                    >
                      <span></span>
                      <i>Support</i>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </footer>
        </main>
      </>
    );
  }
}

// let mapStateToProps = (state) => ({
//     allinvent: state.inventoryReduser.allinvent,
// });

let mapStateToProps = (state) => ({
  /*allWeapon: state.weaponReduser.allWeapon,
    statusWeapon: state.weaponReduser.statusWeapon,*/
});

export default connect(mapStateToProps, {
  /*weaponlist,statusLoadinWeapon, addWeaponWhenRemove, addWeaponToPanda,*/
})(UpgradePageComponentJewelry);
