import { useEffect, useState } from 'react';
import { JsonRpc } from "eosjs";

var numRpc = 0;
//var arrRpc = ['waxtest.eu.eosamsterdam.net']
var arrRpc = ['wax.pink.gg', 'wax.cryptolions.io', 'wax.greymass.com', 'api.wax.alohaeos.com', 'api.waxsweden.org', 'wax.eosphere.io', 'api.wax.greeneosio.com', 'wax.eu.eosamsterdam.net', 'api.wax.bountyblok.io']

const useGetAllSkillBooks = (userName) => {
    const [allSkillBooksInGame, setallSkillBooksInGame] = useState({});
    const [hasError, setHasError] = useState(false);
    const [errorText, setErrorText] = useState('');

    //console.log("111111111111111")
    //console.log("userName", userName)


    const GetSkillBooksListOnGame = async () => {

        //console.log("ssssssssssssssssssssssssss")
        if (allSkillBooksInGame && allSkillBooksInGame.username !== undefined && allSkillBooksInGame.username == userName) {

            console.log("iffffffff")
        }
        else {
            try {
                var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);
                await rpcc
                    ?.get_table_rows({
                        json: true,
                        code: process.env.REACT_APP_CONTRACT,
                        scope: process.env.REACT_APP_CONTRACT,
                        //code: "nftpandawofg",
                        //scope: "nftpandawofg",
                        table: "resgame",
                        limit: 1,
                        lower_bound: userName,
                        upper_bound: userName,
                        key_type: "i64",
                        reverse: !0,
                        show_payer: false,
                        index_position: 1,
                    })
                    .then((results) => {
                        var resultRow = [];

                        console.log("results", results)

                        if (results && results.rows && results.rows.length) {
                            resultRow = results.rows[0];
                        }

                        // console.log("userName", userName);
                        // console.log("resultRow", resultRow);
                        // console.log("results", results);

                        setallSkillBooksInGame(resultRow);
                    });
            } catch (e) {
                console.warn(e);
                setHasError(true);
                setErrorText(e.message);
                setTimeout(() => {

                    if (numRpc == 8) {
                        numRpc = 0;
                    } else {
                        GetSkillBooksListOnGame();
                        numRpc++;
                    }
                }, 1000);
                console.log("\nCaught exception: " + e);
            }
        }
    }

    useEffect(() => {
        if (userName) {
            GetSkillBooksListOnGame();
        }
    }, [userName]);

    // Верните JSX или null, если вы не хотите отображать ничего в компоненте
    return { allSkillBooksInGame };

}

export default useGetAllSkillBooks;
