import React from 'react';
import { NavLink } from 'react-router-dom';
import musicHit from './../../music/hit1.mp3'
var audioMusicHit = new Audio(musicHit);

const Eatmodal = (props) => {
    var imgeat;
    var rareat;
    var name;
    
    // if (item.) {

    // }
    return (<>
        {props.alleat.length ? props.alleat.map((item, index) => {
            {
                if (index == 0) {
                    imgeat = 'eat-Common-nft';
                    rareat = 0;
                    name = "Common"
                } else if (index == 1) {
                    imgeat = 'eat-Uncommon-nft';
                    rareat = 1;
                    name = "Uncommon"
                } else if (index == 2) {
                    imgeat = 'eat-Rare-nft';
                    rareat = 2;
                    name = "Rare"
                } else if (index == 3) {
                    imgeat = 'eat-Epic-nft';
                    rareat = 3;
                    name = "Epic"
                } else if (index == 4) {
                    imgeat = 'eat-Legendary-nft';
                    rareat = 4;
                    name = "Legendary"
                } else if (index == 5) {
                    imgeat = 'eat-Mythic-nft';
                    rareat = 5;
                    name = "Mythic"
                } else if (index == 6) {
                    imgeat = 'eat-voucher-eat-nft';
                    rareat = 6;
                    name = "Voucher"
                }
            } 
            return (   
              
            item ? 
            <div className={`one-eat-list active`} key={index} >
                
                <div className="one-eat-list-in">
                    

                    <div className="img-wrap">
                        <div className="only-thumb">

                            <div className="food-rar">
                                {name} food
                            </div>
                            
                            <div className="value-eat">
                                {item}
                            </div>
                            <picture>
                                <source srcSet={`./images/shop/${imgeat}.webp`} type="image/webp" />
                                <source srcSet={`./images/shop/${imgeat}.png`} type="image/png" /> 
                                <img src={`./images/shop/${imgeat}.png`} alt="" />
                            </picture>

                        </div>
                        <div className="one-eat-bt">
                            <button className="def-bt min append-card" onClick={(e) => {
                                    props.addEnergy(index, props.pandaId); // id еды, индекс слота, 
                                }}>
                                <span className="button-in">
                                    <span className="button-name">
                                        <span className="button-name-in">
                                            <span className="button-name-value">
                                                Feed the hero
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
            : null 
            );
            
            
        })
        : <>oops</>}
        


        <div className="modal-buy-bt eat-buy-now">
            <div className="modal-buy-text">
                
            </div>
            <NavLink className="def-bt mid shop-link-bt" to="/exchange">
            
                <span className="button-in">
                    <span className="button-name">
                        <span className="button-name-in">
                            <span className="button-name-value">
                                exchange old food
                            </span>
                        </span>
                    </span>
                </span>
            </NavLink>
            <NavLink className="def-bt mid shop-link-bt" to="/shop">
            
                <span className="button-in">
                    <span className="button-name">
                        <span className="button-name-in">
                            <span className="button-name-value">
                                SHOP
                            </span>
                        </span>
                    </span>
                </span>
            </NavLink>
        </div>
    </>);
}

export default Eatmodal;