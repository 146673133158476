import React from 'react';
import { Link, NavLink, useParams, Route } from 'react-router-dom';

const CraftHomeLoop = (props) => {

    return (<>
        {props.craftweapon ? props.craftweapon.map((item, index) => {
            //console.log('item', item);
            return (
                <div key={index} className="one-choose-craft-weapon">
                    <div className="one-choose-craft-weapon-in tooltip">
                        {item.allelements[0].template_id && 
                        <div className="cust-tooltip top">
                            <div className="tool-arrow"></div>
                            <div className="tool-wrap">
                                <div className="tool-wrap-1">
                                    <div className="tool-wrap-2">
                                        <div className="tool-body" dangerouslySetInnerHTMLa={{__html: item.tit}}>
                                            {item.allelements[0].name} <br/>{item.allelements[0].type}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }

                        <div className="one-choose-craft-thumb">
                    
                            {!item.allelements[0].template_id ?
                                <picture>
                                    <source srcSet={`/images/craft/${item.allelements[0].bluePrint ? 'weapons/weapon' : 'jew/jewelry'}-${item.headWeapon}.webp`} type="image/webp" />
                                    <source srcSet={`/images/craft/${item.allelements[0].bluePrint ? 'weapons/weapon' : 'jew/jewelry'}-${item.headWeapon}.png`} type="image/png" />
                                    <img src={`/images/craft/${item.allelements[0].bluePrint ? 'weapons/weapon' : 'jew/jewelry'}-${item.headWeapon}.png`} alt="" />
                                </picture>
                                :
                                <picture>
                                    <source srcSet={`/images/craft/armor/armor-${item.allelements[0].type}.webp`} type="image/webp" />
                                    <source srcSet={`/images/craft/armor/armor-${item.allelements[0].type}.png`} type="image/png" />
                                    <img src={`/images/craft/armor/armor-${item.allelements[0].type}.png`} alt="" />
                                </picture>
                            }
                            {!item.allelements[0].template_id &&
                                <div className="one-choose-craft-thumb-hover">
                                    <picture>
                                        <source srcSet={`/images/craft/${item.allelements[0].bluePrint ? 'weapons/weapon' : 'jew/jewelry'}-${item.headWeapon}-active.webp`} type="image/webp" />
                                        <source srcSet={`/images/craft/${item.allelements[0].bluePrint ? 'weapons/weapon' : 'jew/jewelry'}-${item.headWeapon}-active.png`} type="image/png" />
                                        <img src={`/images/craft/${item.allelements[0].bluePrint ? 'weapons/weapon' : 'jew/jewelry'}-${item.headWeapon}-active.png`} alt="" />
                                    </picture>
                                </div>
                            }
                        </div>

                        {/* {item.allelements[0].template_id && props.activeId != undefined && props.activeId >= 0 && props.activeEl != undefined && props.activeEl >= 0 ? */}
                        <div className="elements-hover">
                            <div className="elements-hover-in">
                                <Link to={`./${item.allelements[0].template_id ? "craft-armor-page" : item.allelements[0].bluePrint ? "craft-weapon-page" : "craft-jewelry-page"}/?id=${index}&el=0`} className={`one-el ${item.allelements[0].template_id && item.allelements[0].element}`} onClick={(e) => { props.openRecipe(index, 0) }}>
                                    <picture>
                                        <source srcSet={`/images/craft/water.webp`} type="image/webp" />
                                        <source srcSet={`/images/craft/water.png`} type="image/png" />
                                        <img src={`/images/craft/water.png`} alt="" />
                                    </picture>
                                </Link>
                                <Link to={`./${item.allelements[0].template_id ? "craft-armor-page" : item.allelements[0].bluePrint ? "craft-weapon-page" : "craft-jewelry-page"}/?id=${index}&el=1`} className={`one-el ${item.allelements[0].template_id && item.allelements[1].element}`} onClick={(e) => { props.openRecipe(index, 1) }}>
                                    <picture>
                                        <source srcSet={`/images/craft/fire.webp`} type="image/webp" />
                                        <source srcSet={`/images/craft/fire.png`} type="image/png" />
                                        <img src={`/images/craft/fire.png`} alt="" />
                                    </picture>
                                </Link>
                                <Link to={`./${item.allelements[0].template_id ? "craft-armor-page" : item.allelements[0].bluePrint ? "craft-weapon-page" : "craft-jewelry-page"}/?id=${index}&el=2`} className={`one-el ${item.allelements[0].template_id && item.allelements[2].element}`} onClick={(e) => { props.openRecipe(index, 2) }}>
                                    <picture>
                                        <source srcSet={`/images/craft/wind.webp`} type="image/webp" />
                                        <source srcSet={`/images/craft/wind.png`} type="image/png" />
                                        <img src={`/images/craft/wind.png`} alt="" />
                                    </picture>
                                </Link>
                                <Link to={`./${item.allelements[0].template_id ? "craft-armor-page" : item.allelements[0].bluePrint ? "craft-weapon-page" : "craft-jewelry-page"}/?id=${index}&el=3`} className={`one-el ${item.allelements[0].template_id && item.allelements[3].element}`} onClick={(e) => { props.openRecipe(index, 3) }}>
                                    <picture>
                                        <source srcSet={`/images/craft/earth.webp`} type="image/webp" />
                                        <source srcSet={`/images/craft/earth.png`} type="image/png" />
                                        <img src={`/images/craft/earth.png`} alt="" />
                                    </picture>
                                </Link>
                            </div>
                        </div>
                        {/* // :
                                // <div className="elements-hover">
                                //     <div className="elements-hover-in">
                                //         <div className="one-el" onClick={(e) => {props.openRecipe(index, 0)}}>
                                //             <picture>
                                //                 <source srcSet={`/images/craft/water.webp`} type="image/webp" />
                                //                 <source srcSet={`/images/craft/water.png`} type="image/png" /> 
                                //                 <img src={`/images/craft/water.png`} alt="" />
                                //             </picture>
                                //         </div>
                                //         <div className="one-el" onClick={(e) => {props.openRecipe(index, 3)}}>
                                //             <picture>
                                //                 <source srcSet={`/images/craft/fire.webp`} type="image/webp" />
                                //                 <source srcSet={`/images/craft/fire.png`} type="image/png" /> 
                                //                 <img src={`/images/craft/fire.png`} alt="" />
                                //             </picture>
                                //         </div>
                                //         <div className="one-el" onClick={(e) => {props.openRecipe(index, 2)}}>
                                //             <picture>
                                //                 <source srcSet={`/images/craft/wind.webp`} type="image/webp" />
                                //                 <source srcSet={`/images/craft/wind.png`} type="image/png" /> 
                                //                 <img src={`/images/craft/wind.png`} alt="" />
                                //             </picture>
                                //         </div>
                                //         <div className="one-el" onClick={(e) => {props.openRecipe(index, 1)}}>
                                //             <picture>
                                //                 <source srcSet={`/images/craft/earth.webp`} type="image/webp" />
                                //                 <source srcSet={`/images/craft/earth.png`} type="image/png" /> 
                                //                 <img src={`/images/craft/earth.png`} alt="" />
                                //             </picture>
                                //         </div>
                                //     </div>
                                // </div>
                                // } */}
                    </div>
                </div>
            );


        }) : null}

    </>);
}

export default CraftHomeLoop;