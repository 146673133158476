const SET_SLOT_PANDA_ON_GAME = 'SET_SLOT_PANDA_ON_GAME';
const SET_PANDA_ON_GAME = 'SET_PANDA_ON_GAME';
const TOGGLE_IS_FETCHING_TOP = 'TOGGLE_IS_FETCHING_TOP';
const REMOVE_FROM_STATE = 'REMOVE_FROM_STATE';
const ADD_TO_SLOT_STATE = 'ADD_TO_SLOT_STATE';
const REMOVE_FROM_SLOT = 'REMOVE_FROM_SLOT';
const SET_USERS_ROW = 'SET_USERS_ROW';
const ADD_SLOT = 'ADD_SLOT';

const REMOVE_SLOT = 'REMOVE_SLOT';
const REMOVE_PANDA_ADV = 'REMOVE_PANDA_ADV';


const SET_PANDA_ON_GAME_WAX = 'SET_PANDA_ON_GAME_WAX';

const SET_WAS_LOAD_PANDAS = 'SET_WAS_LOAD_PANDAS';

const CHOOSE_PANDA = 'CHOOSE_PANDA';
const SET_USER_SLOTS = 'SET_USER_SLOTS';

let initialState = {
    pandaSlotOnGame: [],
    pandaListOnGame: [],
    isFetching: true,
    usersRow: null,
    pandaSlotOnGameWax: [],
    wasLoadPandas: false,
    userSlots: []
};

const slotReducer = (state = initialState, action) => {

    switch (action.type) {
        // remove
        case SET_USER_SLOTS: {
            return { ...state, userSlots: action.pandas }
        }
        case SET_SLOT_PANDA_ON_GAME: {
            return { ...state, pandaSlotOnGame: action.pandaSlotOnGame }
        }
        // remove
        case SET_PANDA_ON_GAME: {
            return { ...state, pandaListOnGame: action.pandaListOnGame }
        }
        case TOGGLE_IS_FETCHING_TOP: {
            return { ...state, isFetching: action.isFetching }
        }
        case REMOVE_FROM_STATE: {
            console.log("action.removePandaFromState", action.removePandaFromState);
            //var copyUserSlots = [...state.userSlots];



            var slotPanda = state.userSlots[action.data.indexPanda];
            var copyUserSlots = [...state.userSlots];
            console.log("first copyUserSlots", state.userSlots);
            //copyUserSlots[action.data.numberSlot] = action.data.removePandaFromState.asset_id;

            if (copyUserSlots[action.data.indexPanda]) {

                //console.log("slotPanda", slotPanda);
                //console.log("action.data.removePandaFromState.asset_id", action.data.removePandaFromState.asset_id);
                copyUserSlots[action.data.indexPanda] = action.data.removePandaFromState.asset_id;

                //console.log("second copyUserSlots", copyUserSlots);

                var copyWaxState = [...state.pandaSlotOnGameWax];
                var stateListCopy = [...state.pandaListOnGame]
                var GoToState;
                var GoToSlot;

                state.pandaSlotOnGameWax.map((item) => {
                    if (item.asset_id === slotPanda) {
                        GoToState = item;
                    }
                })
                var copyPanda = Object.assign({}, GoToState);
                copyPanda.is_in_slot = 0;

                stateListCopy = stateListCopy.filter(n => n.asset_id !== action.data.removePandaFromState.asset_id).map(u => { return u; })

                //console.log("copyWaxState", copyWaxState)

                state.pandaListOnGame.map((item) => {
                    if (item.asset_id === action.data.removePandaFromState.asset_id) {
                        GoToSlot = item;
                    }
                })
                var copyGoToSlot = Object.assign({}, GoToSlot);
                copyGoToSlot.is_in_slot = 1;

                copyWaxState = copyWaxState.filter(n => n.asset_id !== slotPanda).map(u => {
                    return u;
                })

                console.log("copyWaxState filter", copyWaxState);


                return {
                    ...state,
                    pandaSlotOnGameWax: [copyGoToSlot].concat(copyWaxState),
                    //userSlots: copyUserSlots,
                    pandaListOnGame: [copyPanda].concat(stateListCopy),
                    userSlots: copyUserSlots,
                }

            } else {
                var copyWaxState = [...state.pandaSlotOnGameWax];
                var GoToSlot;

                state.pandaListOnGame.map((item) => {
                    if (item.asset_id === action.data.removePandaFromState.asset_id) {
                        GoToSlot = item;
                    }
                })
                var copyGoToSlot = Object.assign({}, GoToSlot);
                copyGoToSlot.is_in_slot = 1;
                copyUserSlots[action.data.indexPanda] = action.data.removePandaFromState.asset_id;

                return {
                    ...state, pandaListOnGame: state.pandaListOnGame.filter(n => n.asset_id !== action.data.removePandaFromState.asset_id).map(u => {
                        return u;
                    }),
                    pandaSlotOnGameWax: [copyGoToSlot].concat(copyWaxState),
                    userSlots: copyUserSlots,
                }
            }
        }

        case REMOVE_FROM_SLOT: {
            var copyUserSlots = [...state.userSlots];
            copyUserSlots[action.data.indexSlot] = 0;
            return {
                ...state,
                pandaListOnGame: [action.data.panda].concat(state.pandaListOnGame),
                userSlots: copyUserSlots,
                pandaSlotOnGameWax: state.pandaSlotOnGameWax.filter(n => n.asset_id !== action.data.panda.asset_id).map(u => {
                    return u;
                })
            }
        }
        case SET_USERS_ROW: {
            return { ...state, usersRow: action.usersRow }
        }
        case ADD_SLOT: {
            return {
                ...state, usersRow: action.slotNum
            }
        }
        case REMOVE_SLOT: {
            return {
                ...state, pandaSlotOnGameWax: state.pandaSlotOnGameWax.map((item) => {
                    if (item.asset_id === action.removeId) {
                        var copyPanda = Object.assign({}, item);
                        copyPanda.timer = 0;
                        return copyPanda
                    }
                    return item;
                })
            }
        }

        case REMOVE_PANDA_ADV: {
            console.log("action.data.multi", action.data.multi);
            if (action.data.multi) {
                return {
                    ...state,
                    pandaSlotOnGameWax: state.pandaSlotOnGameWax.map((item, index) => {
                        if (action.data.removeId.includes(item.asset_id)) {
                            var copyPanda = Object.assign({}, item);
                            //console.log("copyPanda 1", copyPanda);

                            copyPanda.timer = action.data.timer;


                            return copyPanda;

                        }
                        return item;
                    })
                }
            } else {
                return {
                    ...state,
                    pandaSlotOnGameWax: state.pandaSlotOnGameWax.map((item, index) => {
                        if (item.asset_id == action.data.removeId) {
                            var copyPanda = Object.assign({}, item);
                            //console.log("copyPanda 1", copyPanda);

                            copyPanda.timer = action.data.timer;
                            return copyPanda;

                        }
                        return item;
                    })
                }
            }

        }

        case SET_PANDA_ON_GAME_WAX: {
            return { ...state, pandaSlotOnGameWax: action.pandas }
        }


        case SET_WAS_LOAD_PANDAS: {
            return { ...state, wasLoadPandas: action.pandas }
        }


        case CHOOSE_PANDA: {
            //console.log("action.data.type", action.data.type)
            //console.log("action.data.duration", action.data.duration)
            // console.log("action.data.type", action.data.type)
            var timer = Date.now();
            var energy = 500;
            if (action.data.duration == 8 && action.data.type == "bamboo" ||
                action.data.duration == 12 && action.data.type != "bamboo") {
                energy = 2000;
            } else if (action.data.duration == 12 && action.data.type == "bamboo") {
                energy = 4000;
            }
            //console.log(timer);
            return {
                ...state, pandaSlotOnGameWax: state.pandaSlotOnGameWax.map((u) => {
                    if (action.data.mult === "one") {
                        if (u && u.asset_id === action.data.panda) {
                            var copy = Object.assign({}, u);
                            copy.activePanda = !copy.activePanda;

                            return copy
                        }
                    } else if (action.data.type === "bamboo" && action.data.mult !== "remove") {

                        if (timer > u.timer * 1000 && action.data.duration == 4 ||
                            timer > u.timer * 1000 && action.data.duration == 8 && u.energy > energy ||
                            timer > u.timer * 1000 && action.data.duration == 8 && u.energy > energy) {
                            var copy = Object.assign({}, u);
                            copy.activePanda = true;
                        } else {
                            var copy = Object.assign({}, u);
                            copy.activePanda = false;
                        }
                        return copy

                    } else if (action.data.mult === "all") {


                        var copy = Object.assign({}, u);
                        copy.activePanda = true;

                        return copy


                    } else if (action.data.mult === "onlybest"
                        && action.data.type !== "bamboo"
                        && timer > u.timer * 1000
                        && u.weapon
                        && u.weapon.energy >= energy) {
                        var copy = Object.assign({}, u);

                        var onePanda = copy;
                        var pandaRar = onePanda.rarity;  // (NFT) rare
                        var pandaLvl = onePanda.lvl; // (РАНГ) 8.67 далдно быть застейкано
                        var pandaSt = onePanda.stake_count; // берес количество застейканого бамам на панде

                        var pandaStake = {
                            "0": [0, 8700, 22900, 45800, 82900, 142900, 240000, 397100, 651300, 1062600, 1728100, 2804900, 4547100],
                            "1": [0, 14400, 37800, 75600, 136800, 235800, 395800, 655000, 1074400, 1753000, 2851000, 4627600, 7502200],
                            "2": [0, 17700, 46600, 93300, 168900, 291100, 488900, 808900, 1326700, 2164500, 3520100, 5713500, 9262400],
                            "3": [0, 48000, 126000, 252000, 456000, 786000, 1320000, 2184000, 3582000, 5844000, 9504000, 15426000, 25008000],
                            "4": [0, 80000, 210000, 420000, 760000, 1310000, 2200000, 3640000, 5970000, 9740000, 15840000, 25710000, 41680000],
                            "5": [0, 320000, 840000, 1680000, 3040000, 5240000, 8800000, 14560000, 23880000, 38960000, 63360000, 102840000, 166720000],
                            "6": [0, 960000, 2520000, 5040000, 9120000, 15720000, 26400000, 43680000, 71640000, 116880000, 190080000, 308520000, 500160000]
                        }

                        var howmcount = pandaStake[pandaRar][pandaLvl];
                        var youmastadd = (howmcount - pandaSt) / 10000; // вы должны добавитть бам
                        var lvlLoop = pandaLvl; // левел для цыкла

                        for (var i = pandaLvl; i >= 0; i--) {

                            if (pandaSt < howmcount) {
                                lvlLoop = i;
                            }
                        }

                        // var exp;
                        // if (lvlLoop !== pandaLvl) {
                        //     copy.activePanda = false;
                        // } else {
                        //     copy.activePanda = true;
                        // }

                        if (lvlLoop < 2) {
                            copy.activePanda = false;
                        } else {
                            copy.activePanda = true;
                        }

                        return copy;
                    } else if (action.data.type === "bamboo" && action.data.mult === "remove") {

                        //console.log("we are here");
                        if (u.activePanda) {
                            //console.log("u", u);
                            var copyPanda = Object.assign({}, u);
                            copyPanda.activePanda = false;


                            if (copyPanda.energy >= energy) {
                                copyPanda.energy = copyPanda.energy - energy;
                            }

                            if (copyPanda.weapon && copyPanda.weapon.energy >= energy) {
                                var copyPandaWeapon = Object.assign({}, copyPanda.weapon);
                                copyPandaWeapon.energy = copyPanda.weapon.energy - energy;
                                copyPanda.weapon = copyPandaWeapon;
                            }

                            /*if (copyPanda.armor && copyPanda.armor.energy >= energy) {
                                var copyPandaArmor = Object.assign({}, copyPanda.armor);
                                copyPandaArmor.energy = copyPanda.armor.energy - energy;
                                copyPanda.armor = copyPandaArmor;
                            }*/

                            if (action.data.type == "bamboo") {
                                if (copyPanda.jew && copyPanda.jew.energy >= energy) {
                                    var copyPandaJew = Object.assign({}, copyPanda.jew);
                                    copyPandaJew.energy = copyPanda.jew.energy - energy;
                                    copyPanda.jew = copyPandaJew;
                                }
                            }

                            return copyPanda;

                        }
                    } else {
                        var copy = Object.assign({}, u);
                        copy.activePanda = false;

                        return copy
                    }
                    return u;
                })
            }
        }

        default:
            return state;
    }
}

export const setSlotPandaOnGame = (pandaSlotOnGame) => ({ type: SET_SLOT_PANDA_ON_GAME, pandaSlotOnGame }) // панды в слотах
export const setPandaOnGame = (pandaListOnGame) => ({ type: SET_PANDA_ON_GAME, pandaListOnGame }) // панда в модалке
export const toggleIsFetchingTop = (isFetching) => ({ type: TOGGLE_IS_FETCHING_TOP, isFetching })
export const doDelFromState = (removePandaFromState, indexPanda) => ({ type: REMOVE_FROM_STATE, data: { removePandaFromState, indexPanda } })
export const addToSlotState = (numberSlot, panda) => ({ type: ADD_TO_SLOT_STATE, data: { numberSlot, panda }, })
export const doDelFromSlot = (panda, indexSlot) => ({ type: REMOVE_FROM_SLOT, data: { panda, indexSlot } })
export const setUsersRow = (usersRow) => ({ type: SET_USERS_ROW, usersRow })
export const addSlot = (slotNum) => ({ type: ADD_SLOT, slotNum })
//export const setTimerToState = (addId, addTime) => ({type: ADD_TIMER,  data: {addId, addTime} })
export const setTimerEnd = (removeId) => ({ type: REMOVE_SLOT, removeId })
export const removePandaAdv = (removeId, timer, energyPanda, energyWeapon, energyJew, types, multi, duration) => ({ type: REMOVE_PANDA_ADV, data: { removeId, timer, energyPanda, energyWeapon, energyJew, types, multi, duration } })

export const setPandaOnGameWax = (pandas) => ({ type: SET_PANDA_ON_GAME_WAX, pandas }) // панды в слотах

export const setWasLoadPandas = (pandas) => ({ type: SET_WAS_LOAD_PANDAS, pandas }) // панды в слотах

export const choosePanda = (panda, mult, type, duration) => ({ type: CHOOSE_PANDA, data: { panda, mult, type, duration } }) // панды в слотах

export const setUserSlots = (pandas) => ({ type: SET_USER_SLOTS, pandas }) // панды в слотах

export default slotReducer;
