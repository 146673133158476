import React from 'react';

import chest from '../../images/alien/chest.png';
import chestw from '../../images/alien/chest.webp';
import chest0 from '../../images/alien/box-1.png';
import chest1 from '../../images/alien/box-1.png';
import chest2 from '../../images/alien/box-2.png';
import chest3 from '../../images/alien/box-3.png';
import chest4 from '../../images/alien/box-4.png';
import chest5 from '../../images/alien/box-5.png';
import chest6 from '../../images/alien/box-6.png';
import chest7 from '../../images/alien/box-7.png';
import chest8 from '../../images/alien/box-8.png';
import chest9 from '../../images/alien/box-9.png';

import rewbot from '../../images/new-adv/mult-res-bot.png';
import rewbotw from '../../images/new-adv/mult-res-bot.webp';

import pandaReww from '../../images/adv-info-panda.webp';
import pandaRew from '../../images/adv-info-panda.png';


import res1 from '../../images/alien/res-ico-min-1.png';
import res1w from '../../images/alien/res-ico-min-1.webp';

import res2 from '../../images/alien/res-ico-min-2.png';
import res2w from '../../images/alien/res-ico-min-2.webp';

import res3 from '../../images/alien/res-ico-min-3.png';
import res3w from '../../images/alien/res-ico-min-3.webp';


function backside(num) {
    //console.log(4);
    var items = document.getElementsByClassName('one-slot');
    items[num].classList.toggle("active");
}

const DetalLoop = (props) => {

    // function getAlienInfoById(asset_id, alienOnGame) {
    //     return alienOnGame.find(alien => alien.asset_id === asset_id);
    // }
    // function getPandaById(asset_id, pandasInSlot) {
    //     return pandasInSlot.find(panda => panda.asset_id === asset_id);
    // }
    //const slotsToCheck = props.lastAdv.panda ? props.userSlotsAlien.filter(i => i === props.lastAdv.panda) : props.userSlotsAlien.slice(0, props.userRow);

    // const renderSlots = slotsToCheck.map((item) => {

    //     if (item !== 0) {

    //         var ball = 0;
    //         var ballali = 0;
    //         var ballpa = 0;
    //         var hasPrise = false;
    //         var prize = [0, 0, 0]
    //         const potionNames = ['small space power potion', 'space power potion', 'space sharpening potion'];
    //         const percentages = [0, 3, 7, 12, 19, 28, 39, 52, 66, 82, 100];

    //         var payout_amount;
    //         const index = props.userSlotsAlien.indexOf(item);
    //         const onePanda = props.userSlotsPanda[index];
    //         var animNumber = 0;

    //         const oneAlienInfo = getAlienInfoById(item, alienOnGame);
    //         ballali += props.caseAlienTmpl(oneAlienInfo.tmpl);

    //         var payout_amount;
    //         var sharpLvlAlien;
    //         var sharpLvlPanda;

    //         sharpLvlAlien = props.allLvlAssets.find(i => i.asset_id === item)?.lvl || 0;

    //         if (sharpLvlAlien > 0) {
    //             ballali = ballali + ballali * percentages[sharpLvlAlien] / 100
    //         }
    //         const onePandaObj = getPandaById(onePanda, pandasInSlot);
    //         const pandaRar = onePandaObj.rarity;
    //         ballpa = props.casePandaRar(pandaRar);
    //         sharpLvlPanda = props.allLvlAssets.find(i => i.asset_id === onePandaObj.asset_id)?.lvl || 0;
    //         if (sharpLvlPanda > 0) {
    //             ballpa = ballpa + ballpa * percentages[sharpLvlPanda] / 100
    //         }
    //         ball = ballali + ballpa;
    //         let randBumb = oneAlienInfo.random;
    //         let num1 = Math.floor(randBumb / 20);
    //         switch (num1) {
    //             case 0: case 1: case 2: case 3: case 4:
    //                 payout_amount = ball;
    //                 animNumber = 5;
    //                 break;
    //             case 5: case 6: case 7: case 8: case 9:
    //                 payout_amount = ball - (ball * 0.25);
    //                 animNumber = 4;
    //                 break;
    //             case 10: case 11: case 12: case 13: case 14:
    //                 payout_amount = ball - (ball * 0.50);
    //                 animNumber = 3;
    //                 break;
    //             case 15: case 16: case 17: case 18: case 19:
    //                 payout_amount = ball - (ball * 0.75);
    //                 animNumber = 2;
    //                 break;
    //             case 20: case 21: case 22: case 23: case 24:
    //                 animNumber = 1;
    //                 payout_amount = 0;
    //                 break;
    //             case 25: case 26: case 27: case 28: case 29:
    //                 animNumber = 5;
    //                 payout_amount = ball;
    //                 break;
    //             case 30: case 31: case 32: case 33: case 34:
    //                 animNumber = 6;
    //                 payout_amount = ball + (ball * 0.25);
    //                 break;
    //             case 35: case 36: case 37: case 38: case 39:
    //                 animNumber = 7;
    //                 payout_amount = ball + (ball * 0.50);
    //                 break;
    //             case 40: case 41: case 42: case 43: case 44:
    //                 animNumber = 8;
    //                 payout_amount = ball + (ball * 0.75);
    //                 break;
    //             case 45: case 46: case 47: case 48: case 49: case 50:
    //                 animNumber = 9;
    //                 payout_amount = ball * 2;
    //                 break;
    //             default:
    //                 payout_amount = 0;
    //                 break;
    //         }
    //         var xfactor = 1;
    //         if (props.lastAdv.duration == 8) {
    //             payout_amount = payout_amount * 2;
    //             xfactor = 2;
    //         } else if (props.lastAdv.duration == 12) {
    //             payout_amount = payout_amount * 3;
    //             xfactor = 3;
    //         }
    //         if (randBumb < 5) { // 0,5% шанс (5 из 1000)
    //             prize[1] += 1 * xfactor;
    //         } else if (randBumb < 15) { // 1% шанс (10 из 1000)
    //             prize[0] += 1 * xfactor;
    //         } else if (randBumb < 20) { // 0,5% шанс (5 из 1000)
    //             prize[2] += 1 * xfactor;
    //         }

    //         if (prize.some(el => el !== 0)) {
    //             hasPrise = true;
    //         }

    //         payout_amount = payout_amount;
    //         console.log("onePandaObj", onePandaObj);

    const { userSlotsAlien, userSlotsPanda, userRow, alienOnGame, pandasInSlot, lastAdv, userSlotsAlienAvatar } = props;

    function getAlienInfoById(asset_id, alienOnGame) {
        return alienOnGame.find(alien => alien.asset_id === asset_id);
    }
    function getPandaById(asset_id, pandasInSlot) {
        return pandasInSlot.find(panda => panda.asset_id === asset_id);
    }

    const renderSlots = () => {
        let slotsToCheck;
        let slotsAvatar;
        let slotsPanda;
        let startLoop = 0;
        let endLoop = 20;

        if (lastAdv.type == "all") {
            slotsToCheck = userSlotsAlien;
            slotsAvatar = userSlotsAlienAvatar;
            slotsPanda = userSlotsPanda;
        } else {
            startLoop = lastAdv.numberslot;
            endLoop = lastAdv.numberslot + 1;
        }

        let allBal = 0;
        const slots = [];

        for (let index = startLoop; index < endLoop; index++) {
            let onePandaObj;
            let oneAlienInfo;
            let oneAlienInfoAvat;
            let randBumb;

            var ball = 0;
            var ballali = 0;
            var ballaliavat = 0;
            var ballpa = 0;
            var animNumber = 0;
            var hasPrise = false;
            var prize = [0, 0, 0]
            const percentages = [0, 3, 7, 12, 19, 28, 39, 52, 66, 82, 100];

            let slotsToCheck = userSlotsAlien[index];
            let slotsAvatar = userSlotsAlienAvatar[index];
            let slotsPanda = userSlotsPanda[index];

            if (slotsToCheck !== 0 || slotsAvatar !== 0 || slotsPanda !== 0) {

                if (slotsToCheck) {
                    oneAlienInfo = getAlienInfoById(slotsToCheck, alienOnGame)
                    console.log("oneAlienInfo", oneAlienInfo)
                    ballali += props.caseAlienTmpl(oneAlienInfo.tmpl);

                    var sharpLvlAlien;
                    sharpLvlAlien = props.allLvlAssets.find(i => i.asset_id === slotsToCheck)?.lvl || 0;
                    if (sharpLvlAlien > 0) {
                        ballali = ballali + ballali * percentages[sharpLvlAlien] / 100
                    }
                    randBumb = oneAlienInfo.random;
                }

                if (slotsAvatar) {
                    oneAlienInfoAvat = getAlienInfoById(slotsAvatar, alienOnGame)
                    console.log("oneAlienInfoAvat", oneAlienInfoAvat)
                    ballaliavat += props.caseAlienTmpl(oneAlienInfoAvat.tmpl);

                    var sharpLvlAlienAvatar;
                    sharpLvlAlienAvatar = props.allLvlAssets.find(i => i.asset_id === slotsAvatar)?.lvl || 0;
                    if (sharpLvlAlienAvatar > 0) {
                        ballaliavat = ballaliavat + ballaliavat * percentages[sharpLvlAlienAvatar] / 100
                    }
                    randBumb = oneAlienInfoAvat.random;
                }

                if (slotsPanda) {
                    onePandaObj = getPandaById(slotsPanda, pandasInSlot);
                    console.log("onePandaObj", onePandaObj)

                    var sharpLvlPanda;
                    const pandaRar = onePandaObj.rarity;
                    ballpa = props.casePandaRar(pandaRar);
                    sharpLvlPanda = props.allLvlAssets.find(i => i.asset_id === onePandaObj.asset_id)?.lvl || 0;

                    if (sharpLvlPanda > 0) {
                        ballpa = ballpa + ballpa * percentages[sharpLvlPanda] / 100
                    }
                    randBumb = onePandaObj.finalnumber;
                }

                // console.log("slotsToCheck", slotsToCheck)
                // console.log("slotsAvatar", slotsAvatar)
                // console.log("slotsPanda", slotsPanda)

                // console.log("onePandaObj", onePandaObj)
                // console.log("oneAlienInfo", oneAlienInfo)
                // console.log("oneAlienInfoAvat", oneAlienInfoAvat)

                //const index = userSlotsAlien.indexOf(item);

                var payout_amount;

                console.log("sharpLvlAlien", sharpLvlAlien)
                console.log("sharpLvlAlienAvatar", sharpLvlAlienAvatar)
                console.log("ballali", ballali)
                console.log("ballaliavat", ballaliavat)
                console.log("ballpa", ballpa)
                console.log("randBumb", randBumb)

                ball = ballali + ballpa + ballaliavat;


                let num1 = Math.floor(randBumb / 20);
                console.log("num1", num1);
                switch (num1) {
                    case 0: case 1: case 2: case 3: case 4:
                        payout_amount = ball;
                        animNumber = 5;
                        break;
                    case 5: case 6: case 7: case 8: case 9:
                        payout_amount = ball - (ball * 0.25);
                        animNumber = 4;
                        break;
                    case 10: case 11: case 12: case 13: case 14:
                        payout_amount = ball - (ball * 0.50);
                        animNumber = 3;
                        break;
                    case 15: case 16: case 17: case 18: case 19:
                        payout_amount = ball - (ball * 0.75);
                        animNumber = 2;
                        break;
                    case 20: case 21: case 22: case 23: case 24:
                        payout_amount = 0;
                        animNumber = 1;
                        break;
                    case 25: case 26: case 27: case 28: case 29:
                        payout_amount = ball;
                        animNumber = 5;
                        break;
                    case 30: case 31: case 32: case 33: case 34:
                        payout_amount = ball + (ball * 0.25);
                        animNumber = 6;
                        break;
                    case 35: case 36: case 37: case 38: case 39:
                        payout_amount = ball + (ball * 0.50);
                        animNumber = 7;
                        break;
                    case 40: case 41: case 42: case 43: case 44:
                        payout_amount = ball + (ball * 0.75);
                        animNumber = 8;
                        break;
                    case 45: case 46: case 47: case 48: case 49: case 50:
                        payout_amount = ball * 2;
                        animNumber = 9;
                        break;
                    default:
                        payout_amount = 0;
                        break;
                }

                console.log("payout_amount", payout_amount);

                var xfactor = 1;
                if (props.lastAdv.duration == 8) {
                    payout_amount = payout_amount * 2;
                    xfactor = 2;
                } else if (props.lastAdv.duration == 12) {
                    payout_amount = payout_amount * 3;
                    xfactor = 3;
                }

                if (randBumb < 5) { // 0,5% шанс (5 из 1000)
                    prize[1] += 1 * xfactor;
                } else if (randBumb < 15) { // 1% шанс (10 из 1000)
                    prize[0] += 1 * xfactor;
                } else if (randBumb < 20) { // 0,5% шанс (5 из 1000)
                    prize[2] += 1 * xfactor;
                }
                console.log("payout_amount", payout_amount);

                allBal += payout_amount;

                if (randBumb < 5) { // 0,5% шанс (5 из 1000)
                    prize[1] += 1 * xfactor;
                } else if (randBumb < 15) { // 1% шанс (10 из 1000)
                    prize[0] += 1 * xfactor;
                } else if (randBumb < 20) { // 0,5% шанс (5 из 1000)
                    prize[2] += 1 * xfactor;
                }

                if (prize.some(el => el !== 0)) {
                    hasPrise = true;
                }

                slots.push(
                    <div className="one-loop-res">
                        <div className="one-loop-res-wrap">
                            <div className="one-loop-res-in alien">
                                <div className="one-loop-res-num">
                                    #{index + 1}
                                </div>
                                <div className="alien-detal-left">
                                    <div className={`one-loop-res-thumb ${oneAlienInfo && oneAlienInfo.tmpl ? "ok" : "not"}`}>
                                        <div className="one-loop-res-thumb-in">
                                            {oneAlienInfo && oneAlienInfo.tmpl &&
                                                <picture>
                                                    <source srcSet={`./images/alien/all/${oneAlienInfo.tmpl}.gif`} type="image/gif" />
                                                    <source srcSet={`./images/alien/all/${oneAlienInfo.tmpl}.gif`} type="image/gif" />
                                                    <img src={`./images/alien/all/${oneAlienInfo.tmpl}.gif`} alt="" />
                                                </picture>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="panda-detal-right">
                                    <div className={`one-loop-res-thumb ${onePandaObj && onePandaObj.name_pa ? "ok" : "not"}`}>
                                        <div className="one-loop-res-thumb-in">
                                            {onePandaObj && onePandaObj.name_pa &&
                                                <picture>
                                                    <source srcSet={`./images/card-1x/${onePandaObj.name_pa}.webp`} type="image/webp" />
                                                    <source srcSet={`./images/card-1x/${onePandaObj.name_pa}.png`} type="image/png" />
                                                    <img src={`./images/card-1x/${onePandaObj.name_pa}.png`} alt="" />
                                                </picture>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="alien-avatar">
                                    <div className="alien-avatar-in">
                                        {oneAlienInfoAvat ?
                                            <span>

                                                <picture>
                                                    <source srcSet={`/images/alien/all/${oneAlienInfoAvat.tmpl}.gif`} type="image/gif" />
                                                    <source srcSet={`/images/alien/all/${oneAlienInfoAvat.tmpl}.gif`} type="image/gif" />
                                                    <img src={`/images/alien/all/${oneAlienInfoAvat.tmpl}.gif`} alt="" />
                                                </picture>
                                            </span>
                                            : null}
                                    </div>
                                </div>

                                <div className="info-res-alien">
                                    <div className="info-res-alien-in">
                                        <div className={`info-res-alien-thumb act${animNumber}`}>
                                            {animNumber === 1 ? <img className={`chest1 active`} src={chest1} alt="" /> : null}
                                            {animNumber === 2 ? <img className={`chest2 active`} src={chest2} alt="" /> : null}
                                            {animNumber === 3 ? <img className={`chest3 active`} src={chest3} alt="" /> : null}
                                            {animNumber === 4 ? <img className={`chest4 active`} src={chest4} alt="" /> : null}
                                            {animNumber === 5 ? <img className={`chest5 active`} src={chest5} alt="" /> : null}
                                            {animNumber === 6 ? <img className={`chest6 active`} src={chest6} alt="" /> : null}
                                            {animNumber === 7 ? <img className={`chest7 active`} src={chest7} alt="" /> : null}
                                            {animNumber === 8 ? <img className={`chest8 active`} src={chest8} alt="" /> : null}
                                            {animNumber === 9 ? <img className={`chest9 active`} src={chest9} alt="" /> : null}
                                        </div>
                                        <div className="info-res-alien-descr">
                                            <span>+ {payout_amount / 1000}</span><br />
                                            <div>space crystals</div>
                                        </div>
                                        {hasPrise ?
                                            <div className="bonus-reward-detal">
                                                <div className="bonus-reward-tit">
                                                    you have bonus prize:
                                                </div>
                                                <div className="bonus-reward-count">
                                                    {prize.map((value, index) => (
                                                        value !== 0 ?
                                                            // <span key={index}>
                                                            //     {potionNames[index]}: +{value}
                                                            // </span>
                                                            index == 0 ?
                                                                <div class="one-drop">
                                                                    <div class="one-drop-thumb">
                                                                        <span>
                                                                            <picture>
                                                                                <source srcSet={res1w} type="image/webp" />
                                                                                <source srcSet={res1} type="image/png" />
                                                                                <img src={res1w} width={60} alt="" />
                                                                            </picture>
                                                                        </span>
                                                                    </div>
                                                                    <div class="one-drop-name">
                                                                        +1
                                                                    </div>
                                                                </div>
                                                                : index == 1 ?
                                                                    <div class="one-drop">
                                                                        <div class="one-drop-thumb">
                                                                            <span>
                                                                                <picture>
                                                                                    <source srcSet={res2w} type="image/webp" />
                                                                                    <source srcSet={res2} type="image/png" />
                                                                                    <img src={res2w} width={60} alt="" />
                                                                                </picture>
                                                                            </span>
                                                                        </div>
                                                                        <div class="one-drop-name">
                                                                            +1
                                                                        </div>
                                                                    </div>
                                                                    : index == 2 ?
                                                                        <div class="one-drop">
                                                                            <div class="one-drop-thumb">
                                                                                <span>
                                                                                    <picture>
                                                                                        <source srcSet={res3w} type="image/webp" />
                                                                                        <source srcSet={res3} type="image/png" />
                                                                                        <img src={res3w} width={60} alt="" />
                                                                                    </picture>
                                                                                </span>
                                                                            </div>
                                                                            <div class="one-drop-name">
                                                                                +1
                                                                            </div>
                                                                        </div>
                                                                        : null


                                                            : null
                                                    ))}

                                                </div>
                                            </div>
                                            : null
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="detal-mult-bot">
                            <picture>
                                <source srcSet={rewbotw} type="image/webp" />
                                <source srcSet={rewbot} type="image/png" />
                                <img src={rewbot} alt="" />
                            </picture>
                        </div>

                    </div>
                )
            }

        };

        return slots;
    }

    return (
        <div>
            {renderSlots()}
        </div>
    );

}

export default DetalLoop;