import React, { useEffect, useRef } from "react";

const OnePandaPanelHero = (props) => {

    const widthScale = props.widthScale;
    const windowWidth = props.windowWidth;

    const pandaInfo = props.pandaInfo;

    return (
        <div className={`one-skills-panda-wrap ${props.isActive} ${props.isOnFilter}`} >
            <div className={`one-skills-panda rar-${pandaInfo.onePanda.rarity}`} style={
                {
                    width: props.imageDimensions.height * 0.35,
                    height: props.imageDimensions.height * 0.35
                    //width: windowWidth > 2100 ? 120 : widthScale * 110,
                    //height: windowWidth > 2100 ? 120 : widthScale * 110,
                }
            } onClick={() => props.nowActivePanda(props.index)}>
                <div className="one-skills-panda-in has-p">

                    <div className="one-skills-panda-thumb">
                        <span>
                            <picture>
                                <source srcSet={`./images/card-1x/${pandaInfo.onePanda.name_pa}.webp`} type="image/webp" />
                                <source srcSet={`./images/card-1x/${pandaInfo.onePanda.name_pa}.png`} type="image/png" />
                                <img src={`./images/card-1x/${pandaInfo.onePanda.name_pa}.png`} alt="" />
                            </picture>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default OnePandaPanelHero;