import React from "react";

import Menu from '../../components/Menu';

//import Countdown from "react-countdown";
import { connect } from "react-redux";
import Artefacts from "./Artefacts";
import ModalMedal from "./ModalMedal";
import InfoModal from "./InfoModal";
import { JsonRpc } from "eosjs";
import "./../../css/artefacts.scss";

import ErrorComponent from "../Error/ErrorComponent";

import shafltop from "../../images/artefacts/title.png";
import shafltopw from "../../images/artefacts/title.webp";
import shaflbot from "../../images/artefacts/bottom.png";
import shaflbotw from "../../images/artefacts/bottom.webp";

import { ExplorerApi } from "atomicassets"; // атомик для списка панд

import musicError from "./../../music/error.mp3";
import musicClose from "./../../music/close.mp3";
import musicOpen from "./../../music/open.mp3";
import musicPandaGo from "./../../music/panda-go.mp3";
import musicInOut from "./../../music/in-out.mp3";
import musicRemove from "./../../music/remove.mp3";

const fetch = require("node-fetch");
const api = new ExplorerApi(
  "https://" + localStorage.getItem('rpcA'),
  "atomicassets",
  { fetch, rateLimit: 4 }
);

// это транзакция отправки ИЗ
const cardTransferOut = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "medalout",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        from: "",
        to: "",
        asset_id: [],
      },
    },
  ],
};

// это транзакция отправки В
const cardTransfer = {
  actions: [
    {
      account: "atomicassets",
      name: "transfer",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        from: "",
        to: process.env.REACT_APP_CONTRACT,
        asset_ids: [],
        memo: "",
      },
    },
  ],
};

class ArtefactsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formInfo: null,
      errorText: "",
      hasError: false,
      artitem: [
        {
          img: "art-1",
          noimg: "art-1-no",
          name: "poster",
          id: "217111",
          has: false,
          title: "Trial of Pragnar",
          descr:
            "Special Collectible Art, series #1 <br /> Lore of Art: <br /> The High Council of the Water Village decided to send the best paladin to one of the ancient Guardians of the Mountain. Pragnar the son of Paus from Anorigar was ordered to learn more information about the evil that threatens Elgard…",
          height: "166",
          urlAtomic: "https://wax.atomichub.io/market?collection_name=nftpandawaxp&match=trial%20of%20pragnar&order=desc&schema_name=art&sort=created&symbol=WAX",
          bigArtDis: "art-0",
          bigArtACT: "art-1"
        },
        {
          img: "art-2",
          noimg: "art-2-no",
          name: "art-2-no",
          id: "606134",
          has: false,
          title: "The secret of the Tavern \"Drunken Goblin\"",
          descr:
            "The favorite place of the best Heroes in the Lands of Elgard, the tavern \"Drunken Goblin\" has always kept a secret. This legend is already so old that not even all the Heroes are sure that it is true. Oh how often visitors tried to get at least some information from Gutfried, but he was always silent about it. Does it mean that even he doesn't know the truth? But one day a secret message appeared in the tavern…",
          height: "167",
          urlAtomic: "https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&schema_name=art&search=The%20secret%20of%20the%20Tavern%20%22Drunken%20Goblin%22&sort=created&state=1&symbol=WAX",
          bigArtDis: "art-4",
          bigArtACT: "art-5"

        },
        {
          img: "art-3",
          noimg: "art-3-no",
          name: "art-3-no",
          id: "664448",
          has: false,
          title: "Elgard's Badge of Valor",
          descr:
            "Elgard's Badge of Valor is a rare and coveted reward wielded only by the bravest Heroes of the legendary Lands of Elgard. Its radiance is emblematic of courage, skill, and unwavering determination. Only those who have proven themselves on the battlefield and demonstrated exceptional bravery in the face of terrible danger are considered worthy to wield the Mark of Valor. To wear it is to bear the weight of a legacy that dates back through countless generations of heroes, and to be considered one of the finest warriors to ever walk the Lands of Elgard.",
          height: "190",
          urlAtomic: "https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&schema_name=art&search=Elgard%27s%20Badge%20of%20Valor&sort=created&state=1&symbol=WAX",
          bigArtDis: "art-2",
          bigArtACT: "art-3"

        },
        {
          img: "art-4",
          noimg: "art-4-no",
          name: "art-4-no",
          id: "713462",
          has: false,
          title: "Alien Worlds Festival Cup",
          descr:
            "In the realm of WAX space, there exists the Alien Worlds Festival Cup — a precious relic held exclusively by the daring adventurers of the inaugural season of a remarkable festival. This extraordinary event came to life through a partnership between NFT Panda: World of Fantasy and the esteemed Alien Worlds Planet Magor Syndicate.",
          height: "190",
          urlAtomic: "https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&schema_name=art&search=Alien%20Worlds%20Festival%20Cup&sort=created&state=1&symbol=WAX",
          bigArtDis: "art-7",
          bigArtACT: "art-6"
        },
        {
          img: "art-5",
          noimg: "art-5-no",
          name: "art-5-no",
          id: "750482",
          has: false,
          title: "Alien Worlds Festival Cup for Season 2",
          descr:
            "In the realm of WAX space, the Alien Worlds Festival Cup for Season 2 emerges as a symbol of unparalleled achievement and bravery for its participants. Through the collaboration between NFT Panda: World of Fantasy and the honorable Alien Worlds Planet Magor Syndicate, the festival takes on a new dimension, and this cup stands as an emblem of new milestones and adventures.",
          height: "190",
          urlAtomic: "https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&schema_name=art&search=Alien%20Worlds%20Festival%20Cup&sort=created&state=1&symbol=WAX",
          bigArtDis: "art-9",
          bigArtACT: "art-8"
        },
        // {
        //   img: "x-1",
        //   name: "x-1",
        //   id: "",
        //   has: true,
        // },
        {
          img: "x-3",
          name: "x-3",
          id: "",
          has: true,
        },
        {
          img: "x-1",
          name: "x-1",
          id: "",
          has: true,
        },
        {
          img: "x-3",
          name: "x-3",
          id: "",
          has: true,
        },
        {
          img: "x-2",
          name: "x-2",
          id: "",
          has: true,
        },
      ],
      allparts: {},
      addMedal: {
        openModal: false,
        anim: false,
      },
      atomicMedal: {
        medal100: "",
        medal300: "",
        medal10: "",
      },
      contractMedal: {
        medal10: 0,
        medal100: 0,
        medal300: 0,
      },
      infoModal: {
        openModal: false,
        anim: false,
        index: "",
      },
      // END EAT
    };

    this.GetPandaPartListArt = this.GetPandaPartListArt.bind(this);
    this.GetMedals = this.GetMedals.bind(this);
    this.clearError = this.clearError.bind(this);
    this.showCloseMedal = this.showCloseMedal.bind(this);
    this.showCloseInfoModal = this.showCloseInfoModal.bind(this);
    this.sendMedal = this.sendMedal.bind(this);
    this.sendMedalOut = this.sendMedalOut.bind(this);
    this.medalCheck = this.medalCheck.bind(this);

    this.audioMusicError = new Audio(musicError);
    this.audioClose = new Audio(musicClose);
    this.audioOpen = new Audio(musicOpen);
    this.audioMusicPandaGo = new Audio(musicPandaGo);
    this.audioMusicInOut = new Audio(musicInOut);
    this.audioMusicRemove = new Audio(musicRemove);
  }

  componentDidMount() {
    this.userCheck();
    this.medalCheck();
    this.GetPandaPartListArt();
  }

  async userCheck() {
    try {
      //console.log(555555555555555555555555555555555555555555555);
      const activeUser = this.props.ual.activeUser;
      const accountName = await activeUser.getAccountName();
      //console.log(activeUser);
      //console.log(accountName);
      let rpcc;
      if (this.props.ual.activeUser.rpc != undefined) {
        rpcc = this.props.ual.activeUser.rpc;
      } else if (
        this.props.ual.activeUser.rpc == undefined &&
        this.props.ual.activeUser.wax
      ) {
        rpcc = this.props.ual.activeUser.wax.rpc;
      } else {
        rpcc = new JsonRpc(localStorage.getItem("rpc"));
      }
      const results = await rpcc.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "usertop", // name of the table as specified by the contract abi
        //"table": "usersnew",    // name of the table as specified by the contract abi
        limit: 300,
        /*lower_bound: 0,*/
        //upper_bound: 0,
        //lower_bound: 0,
        key_type: "i64",
        //"index_position": 2,
        //"limit": -1,
        reverse: !0,
        show_payer: false,
        index_position: 2,
      });
      const originRowUser = results.rows;
      //console.log(originRowUser);

      /*function dynamicSort(property) {
                var sortOrder = -1;
                if(property[0] === "-") {
                    sortOrder = 1;
                    property = property.substr(1);
                }
                return function (a,b) {
                    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                    return result * sortOrder;
                }
            }

            originRowUser.sort(dynamicSort("allsum"));*/

      this.setState({
        formInfo: originRowUser,
      });
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
    }
  }

  async medalCheck() {
    try {
      //console.log(555555555555555555555555555555555555555555555);
      const activeUser = this.props.ual.activeUser;
      const accountName = await activeUser.getAccountName();
      //console.log(activeUser);
      //console.log(accountName);
      let rpcc;
      if (this.props.ual.activeUser.rpc != undefined) {
        rpcc = this.props.ual.activeUser.rpc;
      } else if (
        this.props.ual.activeUser.rpc == undefined &&
        this.props.ual.activeUser.wax
      ) {
        rpcc = this.props.ual.activeUser.wax.rpc;
      } else {
        rpcc = new JsonRpc(localStorage.getItem("rpc"));
      }
      const results = await rpcc.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "medaltop", // name of the table as specified by the contract abi
        //"table": "usersnew",    // name of the table as specified by the contract abi
        limit: 1,
        lower_bound: accountName,
        upper_bound: accountName,
        //lower_bound: 0,
        key_type: "i64",
        //"index_position": 2,
        //"limit": -1,
        reverse: !0,
        show_payer: false,
        index_position: 1,
      });
      const originRowUser = results.rows[0];
      console.log("originRowUser", originRowUser);

      if (originRowUser != undefined) {
        this.setState({
          contractMedal: originRowUser,
        });
      }
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
    }
  }

  async GetPandaPartListArt(parts) {
    (async () => {
      try {
        const userName = this.props.ual.activeUser.accountName;
        const activeUser = this.props.ual.activeUser;
        //var arr = [parts.resource, parts.stone];
        //arr.push();
        // console.log("arr", arr);
        // console.log("this.state.activeRecipe.bluePrint", this.state.activeRecipe.bluePrint);
        const asset = await api
          .getAssets({
            limit: 1000,
            owner: userName,
            collection_name: "nftpandawaxp",
            schema_name: ["art"],
            //template_id: arr,
          })
          .then((assets) => {
            //console.log('GetPandaPartListRes', assets);
            let art1 = 0;
            let art2 = 0;
            let art3 = 0;
            let art4 = 0;
            let art5 = 0;
            for (var c in assets) {
              //console.log(byTemplate[c]);
              if (assets[c].template.template_id == 217111) {
                art1 = true;
              } else if (assets[c].template.template_id == 606134) {
                art2 = true;
              } else if (assets[c].template.template_id == 664448) {
                art3 = true;
              } else if (assets[c].template.template_id == 713462) {
                art4 = true;
              } else if (assets[c].template.template_id == 750482) {
                art5 = true;
              }
              //else if(assets[c].template.template_id == this.state.activeRecipe.stone) {
              //     stone += 1;
              // }
            }

            //console.log('this.state.artitem', this.state.artitem);

            var copyArt = Object.assign([], this.state.artitem);
            //console.log(copyArt);
            if (art1) {
              copyArt[0].has = true;
            }
            if (art2) {
              copyArt[1].has = true;
            }
            if (art3) {
              copyArt[2].has = true;
            }
            if (art4) {
              copyArt[3].has = true;
            }
            if (art5) {
              copyArt[4].has = true;
            }
            //console.log(copyArt[0]);

            this.setState({
              ...this.state,
              allparts: assets,
              artitem: copyArt,
            });

            //console.log('this.state.artitem', this.state.artitem);
          });
      } catch (e) {
        this.setState({
          hasError: true,
          errorText: e.message,
        });
        console.log("\nCaught exception: " + e);
        if (localStorage.getItem("sound") == "true") {
          this.audioMusicError.currentTime = 0;
          this.audioMusicError.volume = 0.1;
          this.audioMusicError.play();
        }
      }
    })();
  }

  async GetMedals(parts) {
    (async () => {
      try {
        const userName = this.props.ual.activeUser.accountName;
        const activeUser = this.props.ual.activeUser;
        //var arr = [parts.resource, parts.stone];
        //arr.push();
        // console.log("arr", arr);
        // console.log("this.state.activeRecipe.bluePrint", this.state.activeRecipe.bluePrint);
        const asset = await api
          .getAssets({
            limit: 1000,
            owner: userName,
            collection_name: "nftpandawaxp",
            schema_name: ["awards"],
            //template_id: arr,
          })
          .then((assets) => {
            console.log("awards", assets);
            let medal100 = 0;
            let medal300 = 0;
            let medal10 = 0;
            for (var c in assets) {
              //console.log(byTemplate[c]);
              if (assets[c].template.template_id == 366298) {
                medal100 = assets[c].asset_id;
              } else if (assets[c].template.template_id == 366297) {
                medal300 = assets[c].asset_id;
              } else if (assets[c].template.template_id == 437401) {
                medal10 = assets[c].asset_id;
              }
            }

            this.setState({
              ...this.state,
              atomicMedal: {
                medal100: medal100,
                medal300: medal300,
                medal10: medal10,
              },
            });
          });
      } catch (e) {
        this.setState({
          hasError: true,
          errorText: e.message,
        });
        console.log("\nCaught exception: " + e);
        if (localStorage.getItem("sound") == "true") {
          this.audioMusicError.currentTime = 0;
          this.audioMusicError.volume = 0.1;
          this.audioMusicError.play();
        }
      }
    })();
  }

  async clearError() {
    this.setState({
      errorText: "",
      hasError: false,
    });
  }

  showCloseMedal(method, type) {
    //document.getElementsByTagName('body')[0].classList.add("open-modal");
    if (method == "open") {
      document.getElementsByTagName("body")[0].classList.add("open-modal");
      this.GetMedals();

      this.setState({
        ...this.state,
        addMedal: {
          ...this.state.addMedal,
          modalOpen: true,
        },
      });

      setTimeout(() => {
        this.setState({
          ...this.state,
          addMedal: {
            ...this.state.addMedal,
            anim: true,
          },
        });
      }, 100);

      if (localStorage.getItem("sound") == "true") {
        this.audioOpen.currentTime = 0;
        this.audioOpen.volume = 0.1;
        this.audioOpen.play();
      }
    } else {
      document.getElementsByTagName("body")[0].classList.remove("open-modal");
      this.setState({
        ...this.state,
        addMedal: {
          ...this.state.addMedal,
          modalOpen: false,
        },
      });
      if (localStorage.getItem("sound") == "true") {
        this.audioClose.currentTime = 0;
        this.audioClose.volume = 0.1;
        this.audioClose.play();
      }
    }
  }

  showCloseInfoModal(method, index) {
    //document.getElementsByTagName('body')[0].classList.add("open-modal");
    if (method == "open") {
      this.setState({
        ...this.state,
        infoModal: {
          ...this.state.infoModal,
          modalOpen: true,
          index: index,
        },
      });

      setTimeout(() => {
        this.setState({
          ...this.state,
          infoModal: {
            ...this.state.infoModal,
            anim: true,
          },
        });
      }, 100);

      if (localStorage.getItem("sound") == "true") {
        this.audioOpen.currentTime = 0;
        this.audioOpen.volume = 0.1;
        this.audioOpen.play();
      }
    } else {
      //document.getElementsByTagName('body')[0].classList.remove("open-modal");
      this.setState({
        ...this.state,
        infoModal: {
          ...this.state.infoModal,
          modalOpen: false,
          index: "",
        },
      });
      if (localStorage.getItem("sound") == "true") {
        this.audioClose.currentTime = 0;
        this.audioClose.volume = 0.1;
        this.audioClose.play();
      }
    }
  }

  async sendMedalOut(dateBt, medalTypeNum) {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");

    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    cardTransferOut.actions[0].authorization[0].actor = userName;
    cardTransferOut.actions[0].data.to = userName;
    cardTransferOut.actions[0].data.asset_id = [dateBt];
    //console.log(cardTransferOut);
    try {
      await activeUser
        .signTransaction(cardTransferOut, {
          blocksBehind: 3,
          expireSeconds: 30,
        })
        .then((response) => {
          //console.log(response);
          if (response.status === "executed") {
            loadingSlot.classList.remove("loading");
            //this.removePandaFromStateGame(dateBt);
            //this.handleCloseModalWarning();
            //console.log(response.status);

            if (medalTypeNum == 300) {
              this.setState({
                ...this.state,
                atomicMedal: {
                  ...this.state.atomicMedal,
                  medal300: dateBt,
                },
                contractMedal: {
                  ...this.state.contractMedal,
                  medal300: 0,
                },
              });
            } else if (medalTypeNum == 100) {
              this.setState({
                ...this.state,
                atomicMedal: {
                  ...this.state.atomicMedal,
                  medal100: dateBt,
                },
                contractMedal: {
                  ...this.state.contractMedal,
                  medal100: 0,
                },
              });
            } else if (medalTypeNum == 10) {
              this.setState({
                ...this.state,
                atomicMedal: {
                  ...this.state.atomicMedal,
                  medal10: dateBt,
                },
                contractMedal: {
                  ...this.state.contractMedal,
                  medal10: 0,
                },
              });
            }

            if (localStorage.getItem("sound") == "true") {
              this.audioMusicRemove.currentTime = 0;
              this.audioMusicRemove.volume = 0.1;
              this.audioMusicRemove.play();
            }
          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      loadingSlot.classList.remove("loading");
      this.setState({
        hasError: true,
        errorText: error.message,
      });
      if (localStorage.getItem("sound") == "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  async sendMedal(dateBt, medalTypeNum) {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");

    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    cardTransfer.actions[0].authorization[0].actor = userName;
    cardTransfer.actions[0].data.from = userName;
    cardTransfer.actions[0].data.asset_ids = [dateBt];
    cardTransfer.actions[0].data.memo = "addmedal ";
    console.log(cardTransfer);
    try {
      await activeUser
        .signTransaction(cardTransfer, { blocksBehind: 3, expireSeconds: 30 })
        .then((response) => {
          if (response.status === "executed") {
            loadingSlot.classList.remove("loading");
            //this.removePandaFromStateUser(dateBt, namePa);
            //console.log(response.status);
            if (medalTypeNum == 300) {
              this.setState({
                ...this.state,
                atomicMedal: {
                  ...this.state.atomicMedal,
                  medal300: "",
                },
                contractMedal: {
                  ...this.state.contractMedal,
                  medal300: dateBt,
                },
              });
            } else if (medalTypeNum == 100) {
              this.setState({
                ...this.state,
                atomicMedal: {
                  ...this.state.atomicMedal,
                  medal100: "",
                },
                contractMedal: {
                  ...this.state.contractMedal,
                  medal100: dateBt,
                },
              });
            } else if (medalTypeNum == 10) {
              this.setState({
                ...this.state,
                atomicMedal: {
                  ...this.state.atomicMedal,
                  medal10: "",
                },
                contractMedal: {
                  ...this.state.contractMedal,
                  medal10: dateBt,
                },
              });
            }

            if (localStorage.getItem("sound") == "true") {
              this.audioMusicRemove.currentTime = 0;
              this.audioMusicRemove.volume = 0.1;
              this.audioMusicRemove.play();
            }
          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      loadingSlot.classList.remove("loading");
      this.setState({
        hasError: true,
        errorText: error.message,
      });
      if (localStorage.getItem("sound") == "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.play();
      }
    }
  }

  render() {
    return (
      <>

        <Menu  {...this.props.ual} wax={this.props.balanceWax} bam={this.props.balanceBam} game={this.props.balanceGame} updateBalance={this.props.updateBalance} />
        {this.state.hasError ? (
          <ErrorComponent
            errorMessage={this.state.errorText}
            clearError={this.clearError}
          />
        ) : null}

        {this.state.addMedal.modalOpen ? (
          <ModalMedal
            showCloseMedal={this.showCloseMedal}
            addMedal={this.state.addMedal}
            contractMedal={this.state.contractMedal}
            atomicMedal={this.state.atomicMedal}
            sendMedal={this.sendMedal}
            sendMedalOut={this.sendMedalOut}
          />
        ) : null}

        {this.state.infoModal.modalOpen ? (
          <InfoModal
            showCloseInfoModal={this.showCloseInfoModal}
            infoModal={this.state.infoModal}
            artitem={this.state.artitem}
          />
        ) : null}

        <main className="art-page">
          <section id="art-page">
            <div className="skill-bt">
              <span onClick={() => this.showCloseMedal("open")}></span>
            </div>

            <div className="art-page-wrap">
              <div className="art-in">
                <div className="art-top">
                  <picture>
                    <source srcSet={shafltopw} type="image/webp" />
                    <source srcSet={shafltop} type="image/png" />
                    <img className="hidedesc" src={shafltop} alt="" />
                  </picture>
                </div>

                <div className="art-mid">
                  <div className="art-mid-in">
                    {this.state.artitem && this.state.artitem.length ? (
                      <Artefacts
                        showCloseInfoModal={this.showCloseInfoModal}
                        artitem={this.state.artitem}
                      />
                    ) : null}
                    <div className="pol pol1"></div>
                    <div className="pol pol2"></div>
                    <div className="pol pol3"></div>
                  </div>
                </div>

                <div className="art-bot">
                  <picture>
                    <source srcSet={shaflbotw} type="image/webp" />
                    <source srcSet={shaflbot} type="image/png" />
                    <img className="hidedesc" src={shaflbot} alt="" />
                  </picture>
                </div>
              </div>
            </div>
          </section>

          <footer>
            <div className="footer-nav">
              <nav>
                <ul>
                  <li>
                    <a
                      className="foot-logo-1"
                      target="_blank"
                      href="https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&sort=created&symbol=WAX"
                    >
                      <span></span>
                      <i>Market</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-2"
                      target="_blank"
                      href="https://twitter.com/NftPanda/"
                    >
                      <span></span>
                      <i>Twitter</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-3"
                      target="_blank"
                      href="https://www.instagram.com/nftpanda/"
                    >
                      <span></span>
                      <i>Instagram</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-4"
                      target="_blank"
                      href="https://discord.gg/vCSgme4d5b"
                    >
                      <span></span>
                      <i>Discord</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-5"
                      target="_blank"
                      href={`https://wax.alcor.exchange/swap?output=${process.env.REACT_APP_VAL}-nftpandabamb&input=WAX-eosio.token`}
                    >
                      <span></span>
                      <i>{process.env.REACT_APP_VAL} Trade</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-6"
                      target="_blank"
                      href="https://discord.gg/vCSgme4d5b"
                    >
                      <span></span>
                      <i>Support</i>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </footer>
        </main>
      </>
    );
  }
}

let mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(ArtefactsComponent);
