import React from 'react';

const InventoryEat = (props) => {

    // return (<>


    //         { props.shopitem ? props.shopitem.map((item, index) => {
    //             if (index < props.activePage * 12 && index >= (props.activePage - 1) * 12 ) {
    //                 return (

    //                 );
    //             }

    //         }) : null}

    //     </>);




    var imgeat;
    var countCommon = 0;
    var countUncommon = 0;
    var countRare = 0;
    var countEpic = 0;
    var countLegendary = 0;
    var countMythic = 0;
    // if (item.) {

    // }
    return (<>
        {props.allinvent && props.allinvent.length ? props.allinvent.map((item, index) => {
            {
                //console.log(item.template.template_id);
                // img eat 

                var rareat;
                var nameRar;
                var nameFood;
                var tmpl;

                if (index === 0) {
                    imgeat = 'eat-common-nft';
                    rareat = 0;
                    nameRar = "Common";
                    nameFood = "pickled bamboo";
                    tmpl = "eat-Common-nft";
                } else if (index === 1) {
                    imgeat = 'eat-uncommon-nft';
                    rareat = 1;
                    nameRar = "Uncommon";
                    nameFood = "bamboo with mushrooms";
                    tmpl = "eat-Uncommon-nft";
                } else if (index === 2) {
                    imgeat = 'eat-rare-nft';
                    rareat = 2;
                    nameRar = "Rare";
                    nameFood = "ramen";
                    tmpl = "eat-Rare-nft";
                } else if (index === 3) {
                    imgeat = 'eat-epic-nft';
                    rareat = 3;
                    nameRar = "Epic";
                    nameFood = "rice with bamboo";
                    tmpl = "eat-Epic-nft";
                } else if (index === 4) {
                    imgeat = 'eat-legend-nft';
                    rareat = 4;
                    nameRar = "Legendary";
                    nameFood = "dumplings with bamboo";
                    tmpl = "eat-Legendary-nft";
                } else if (index === 5) {
                    imgeat = 'eat-mythic-nft';
                    rareat = 5;
                    nameRar = "Mythic"
                    nameFood = "stake with bamboo";
                    tmpl = "eat-Mythic-nft";
                } else if (index === 6) {
                    imgeat = 'eat-voucher-nft';
                    rareat = 6;
                    nameRar = "Voucher"
                    nameFood = "stake with bamboo";
                    tmpl = "eat-Voucher-nft";
                }
                // end img eat

                //console.log('rareatrareat', rareat)
            }
            return (
                item ?
                    <div className={`one-invent-prod active ${nameRar}`} key={index} >
                        <div className="one-invent-prod-info tooltip">
                            <div className="cust-tooltip right">
                                <div className="tool-arrow"></div>
                                <div className="tool-wrap">
                                    <div className="tool-wrap-1">
                                        <div className="tool-wrap-2">
                                            <div className="tool-body">
                                                <span>{nameRar} food</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="one-invent-prod-thumb">
                            <div className='linkinv' onClick={() => props.showCloseModal('open',
                                {
                                    data: {
                                        name: nameFood,
                                        rarity: nameRar
                                    },
                                    schema: {
                                        schema_name: "food",
                                    },
                                    template: {
                                        template_id: tmpl
                                    }
                                }
                            )} ></div>
                            <div className="value-inv-eat">
                                {item}
                            </div>
                            <span></span>
                            <div className="one-invent-prod-thumb-in">
                                <picture>
                                    <source srcSet={`./images/shop/eat-${nameRar}.webp`} type="image/webp" />
                                    <source srcSet={`./images/shop/eat-${nameRar}.png`} type="image/png" />
                                    <img src={`./images/shop/eat-${nameRar}.png`} alt="" />
                                </picture>
                                <i></i>
                                <i className="b"></i>
                            </div>
                        </div>


                    </div>
                    : null);
        })
            : <></>}




    </>);

}

export default InventoryEat;