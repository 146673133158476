import React from 'react';

const Artefacts = (props) => {

    
    
    return (<>
        { props.artitem ? props.artitem.map((item, index) => {
                // if (index < props.activePage * 12 && index >= (props.activePage - 1) * 12 ) {
                    return (
                    <div key={index} className={`one-art-item `}>
                        <div className="one-art-item-in">
                            <div className="one-art-item-thumb">
                                <picture>
                                    <source srcSet={`/images/artefacts/${item.has ? item.img : item.noimg}.webp`} type="image/webp" />
                                    <source srcSet={`/images/artefacts/${item.has ? item.img : item.noimg}.png`} type="image/png" /> 
                                    <img style={{maxHeight: item.height + "px"}} src={`/images/artefacts/${item.has ? item.img : item.noimg}.png`} alt="" />
                                </picture>
                            </div>
                        </div>
                        
                        <div className="open-modal-bt" onClick={() => props.showCloseInfoModal('open', index)}></div>
                        
                    </div>
                    );
                // }
                
            }) : null}       
    </>);
}

export default Artefacts;