import React from 'react';
let manyItems = 12;

const InventoryBook = (props) => {

    console.log("props", props)
    console.log("props.activePage - 1", props.activePage - 1)
    console.log("manyItems 1", manyItems);
    // const nonZeroNumbers = props.resInGame && props.resInGame.books && props.resInGame.books.length ? props.resInGame.books.filter(number => number !== 0) : [];
    // console.log("nonZeroNumbers", nonZeroNumbers);

    const numbers = props.resInGame && props.resInGame.books && props.resInGame.books.length ? props.resInGame.books.filter(number => number !== 0) : [];
    const skillArrayNumber = ["elder", "healer", "priest", "dreneya", "druid", "mage", "tauren", "smith", "engineer", "berserker", "bard", "shooter", "archer", "warrior", "paladin"];

    let nonZeroNumbers = [];
    let nonZeroSkills = [];

    for (let i = 0; i < numbers.length; i++) {
        if (numbers[i] !== 0) {
            nonZeroNumbers.push(numbers[i]);
            nonZeroSkills.push(skillArrayNumber[i]);
        }
    }

    // }
    return (<>
        {nonZeroNumbers ? nonZeroNumbers.map((item, index) => {
            if (index < props.activePage * manyItems && index >= (props.activePage - 1) * manyItems && item !== 0) {
                console.log("manyItems", manyItems);
                console.log("index", index);
                return (

                    <div key={index} className={`one-invent-prod  common active`}>
                        <div className="one-invent-prod-info tooltip">
                            <div className="cust-tooltip right">
                                <div className="tool-arrow"></div>
                                <div className="tool-wrap">
                                    <div className="tool-wrap-1">
                                        <div className="tool-wrap-2">
                                            <div className="tool-body">
                                                <span>for {nonZeroSkills[index]}</span> <br />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="one-invent-prod-thumb">
                            <div className='linkinv' onClick={() => props.showCloseModal('open', item)} ></div>
                            <div className="value-inv-eat">
                                {item}
                            </div>
                            <span></span>
                            <div className="one-invent-prod-thumb-in">
                                <picture>
                                    <source srcSet={`./images/shop/icons/books/${nonZeroSkills[index]}.webp`} type="image/webp" />
                                    <source srcSet={`./images/shop/icons/books/${nonZeroSkills[index]}.png`} type="image/png" />
                                    <img src={`./images/shop/icons/books/${nonZeroSkills[index]}.png`} alt="" />
                                </picture>
                                <i></i>
                                <i className="b"></i>
                            </div>


                        </div>

                    </div>

                );
            } else if (item === 0) {
                manyItems++
                console.log("index2", index);
            }
        })
            : <></>}




    </>);

}

export default InventoryBook;