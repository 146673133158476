import { combineReducers } from 'redux';
import user from './UserReducer';
import pandaReducer from './pandaongame-reducer';
import pandaUserReducer from './pandaonuser-reducer';
import wasLoginReducer from './waslogin-reducer';
import slotReducer from './pandaonslot-reducer';
import headReducer from './head-reducer';
import showReduser from './shop-reducer';
import inventoryReduser from './inventory-reducer';
import weaponReduser from './weapon-reducer';
import jewReduser from './jew-reducer';
import armorReduser from './armor-reducer';
import questReduser from './quest-reducer';
//import alienReducer from './alien-reducer';

export const rootReducer = combineReducers({
    user: user,
    heroPageTop: pandaReducer,
    heroPageBot: pandaUserReducer,
    wasLogin: wasLoginReducer,
    slotReducer: slotReducer,
    headReducer: headReducer,
    showReduser: showReduser,
    inventoryReduser: inventoryReduser,
    weaponReduser: weaponReduser,
    jewReduser: jewReduser,
    armorReduser: armorReduser,
    questReduser: questReduser,
    //alienReducer: alienReducer
});
