import React from 'react';
import Countdown from 'react-countdown';

function backside(num) {
    //console.log(4);
    var items = document.getElementsByClassName('one-slot');
    items[num].classList.toggle("active");
}

const MultPanda = (props) => {
    //console.log("userSlots", props.userSlots);
    //console.log("props.pandaSlots", props.pandaSlots);
    return (<>
        {props.userSlots.map((item, index) => {

            // console.log("item", item);
            // console.log("props.pandaSlots", props.pandaSlots);
            // console.log("one panda", onePanda);
            // console.log("one weapon", oneWeapon);
            // console.log("one jew", oneJew);

            if (item === 0) {
                return (
                    <div key={index} className={`one-mult-panda`}>
                        <div className="one-mult-panda-in">
                            <div className="one-mult-panda-thumb">
                            </div>
                            <i className='a'></i>
                            <i className='b'></i>
                        </div>
                    </div>
                )
            }
            if (item !== 0) {

                var onePanda = item;
                var onePandaName;
                var oneWeapon;
                var oneArmor;
                var nameWeapon;
                var oneJew;
                var status = false;

                if (props.pandaSlots && props.pandaSlots.length) {
                    for (let i = 0; i < props.pandaSlots.length; i++) {
                        if (item === props.pandaSlots[i].asset_id) {
                            onePanda = props.pandaSlots[i];
                            onePandaName = onePanda.name_pa;
                            onePandaName = onePandaName.substring(onePandaName.indexOf("-") + 1)
                        }
                    }
                }

                var pandaRar = onePanda.rarity;  // (NFT) rare
                var pandaLvl = onePanda.lvl; // (РАНГ) 8.67 далдно быть застейкано
                var pandaSt = onePanda.stake_count; // берес количество застейканого бамам на панде

                var pandaStake = {
                    "0": [0, 8700, 22900, 45800, 82900, 142900, 240000, 397100, 651300, 1062600, 1728100, 2804900, 4547100],
                    "1": [0, 14400, 37800, 75600, 136800, 235800, 395800, 655000, 1074400, 1753000, 2851000, 4627600, 7502200],
                    "2": [0, 17700, 46600, 93300, 168900, 291100, 488900, 808900, 1326700, 2164500, 3520100, 5713500, 9262400],
                    "3": [0, 48000, 126000, 252000, 456000, 786000, 1320000, 2184000, 3582000, 5844000, 9504000, 15426000, 25008000],
                    "4": [0, 80000, 210000, 420000, 760000, 1310000, 2200000, 3640000, 5970000, 9740000, 15840000, 25710000, 41680000],
                    "5": [0, 320000, 840000, 1680000, 3040000, 5240000, 8800000, 14560000, 23880000, 38960000, 63360000, 102840000, 166720000],
                    "6": [0, 960000, 2520000, 5040000, 9120000, 15720000, 26400000, 43680000, 71640000, 116880000, 190080000, 308520000, 500160000]
                }

                var howmcount = pandaStake[pandaRar][pandaLvl];
                var lvlLoop = pandaLvl; // левел для цыкла

                for (var i = pandaLvl; i >= 0; i--) {

                    if (pandaSt < howmcount) {
                        lvlLoop = i;
                    }
                }

                var errorBamToLvlUp;

                var nameRare = '';
                switch (lvlLoop) {
                    case 0:
                        nameRare = "Rookie"
                        break;
                    case 1:
                        nameRare = "Officer-cadet"
                        break;
                    case 2:
                        nameRare = "Second-Lieutenant"
                        break;
                    case 3:
                        nameRare = "Lieutenant"
                        break;
                    case 4:
                        nameRare = "Captain"
                        break;
                    case 5:
                        nameRare = "Major"
                        break;
                    case 6:
                        nameRare = "Lieutenant-Colonel"
                        break;
                    case 7:
                        nameRare = "Colonel"
                        break;
                    case 8:
                        nameRare = "Brigadier"
                        break;
                    case 9:
                        nameRare = "Major-General"
                        break;
                    case 10:
                        nameRare = "Lieutenant-General"
                        break;
                    case 11:
                        nameRare = "General"
                        break;
                    case 12:
                        nameRare = "legend"
                        break;
                    default:
                        nameRare = "NOBADY"
                        break;
                }

                var nameRarePanda = '';
                switch (pandaRar) {
                    case 0:
                        nameRarePanda = "Common"
                        break;
                    case 1:
                        nameRarePanda = "Promo"
                        break;
                    case 2:
                        nameRarePanda = "Uncommon"
                        break;
                    case 3:
                        nameRarePanda = "Rare"
                        break;
                    case 4:
                        nameRarePanda = "Epic"
                        break;
                    case 5:
                        nameRarePanda = "Legendary"
                        break;
                    case 6:
                        nameRarePanda = "Mythic"
                        break;
                    default:
                        nameRarePanda = "NOBADY"
                        break;
                }

                //var energy = onePanda.energy / 100;
                //var countBamb = 10;

                if (onePanda.weapon) {
                    oneWeapon = onePanda.weapon;
                }

                if (onePanda.armor) {
                    oneArmor = onePanda.armor;
                }

                if (onePanda.jew) {
                    oneJew = onePanda.jew;
                }

                //var weaponElement = oneWeapon && oneWeapon.element;
                //var jewElement = oneJew && oneJew.element;

                /*var exp;
                if (lvlLoop !== pandaLvl) {
                    exp = true;
                } else {
                    exp = false;
                }*/

                /*console.log("", );
                console.log("props.sendToAdv.type",props.sendToAdv.type );
                console.log("!oneWeapon",!oneWeapon );
                console.log("onePanda.lvl",onePanda.lvl);
                console.log("onePanda.energy",onePanda.energy);
                console.log("exp",exp); 
                console.log("oneWeapon", oneWeapon);*/
                var hasError = false;
                var pandaSleep = false;

                var energy = 500;
                if (props.duration == 8 && props.sendToAdv.type == 'bamboo' || props.duration == 12 && props.sendToAdv.type != 'bamboo') {
                    energy = 2000;
                } else if (props.duration == 12 && props.sendToAdv.type == 'bamboo') {
                    energy = 4000;
                }

                if (oneWeapon && oneJew && oneArmor) {
                    if (pandaRar != 0) {
                        pandaRar = pandaRar - 1;
                    }

                    if (oneWeapon.rarity >= pandaRar && oneJew.rarity >= pandaRar && oneArmor.rarity >= pandaRar) {
                        energy = 400;
                        if (props.duration == 12) {
                            energy = 1800;
                        }
                    }
                }

                //console.log("energy", energy);
                var orangeError = false;
                if (props.sendToAdv.type !== 'bamboo') {
                    if (!oneWeapon || lvlLoop < 2 || onePanda.energy < energy || /*exp ||*/ oneWeapon.energy < energy) {
                        hasError = true;
                    }
                } else if (props.sendToAdv.type === 'bamboo') {
                    if (onePanda.energy < energy) {
                        hasError = true;
                    }
                }

                if (oneArmor && oneArmor.energy < energy) {
                    orangeError = true;
                }

                if (Date.now() < onePanda.timer * 1000) {
                    pandaSleep = true;
                }

                return (

                    <div key={index} className={`one-mult-panda rar-${onePanda.rarity} ${onePanda.activePanda ? "active" : "dis"}
                    ${props.activeMob == onePanda.asset_id && "activeMob"}
                    `}>
                        <div className={`mobile-click `} onClick={(e) => {
                            props.activeMobFunc(onePanda);
                        }}></div>
                        <div className="one-mult-panda-in">
                            <div className="one-mult-panda-thumb">
                                <span>
                                    <picture>
                                        <source srcSet={`./images/card-1x/${onePanda.name_pa}.webp`} type="image/webp" />
                                        <source srcSet={`./images/card-1x/${onePanda.name_pa}.png`} type="image/png" />
                                        <img src={`./images/card-1x/${onePanda.name_pa}.png`} alt="" />
                                    </picture>
                                </span>

                                {pandaSleep &&
                                    <div className="panda-sleep"></div>
                                }
                            </div>
                            <i className='a'></i>
                            <i className='b'></i>

                        </div>

                        {(hasError && !pandaSleep) || (orangeError && !pandaSleep) ? <div className={`panda-err ${hasError && props.sendToAdv.type === 'bamboo' || orangeError ? "light" : "norm"}`}></div> : null}

                        <div className={`hover-mult-panda ${props.pandaInfo.asset_id != undefined && props.pandaInfo.asset_id == onePanda.asset_id && "active"}`}>

                            <div className="hover-mult-panda-top">
                                <div className="show-info" onClick={(e) => {
                                    props.getPandaInfo(onePanda, nameRare, lvlLoop);
                                }}>
                                    <span></span>
                                </div>
                            </div>
                            <div className="hover-mult-panda-bot">
                                {onePanda.activePanda ?
                                    <div className="rem-panda-mult" onClick={(e) => { props.activeSlot(onePanda.asset_id); }}>
                                        <span></span>
                                    </div>
                                    :
                                    !hasError && !pandaSleep || hasError && props.sendToAdv.type === 'bamboo' ?
                                        <div className="add-panda-mult" onClick={(e) => { props.activeSlot(onePanda.asset_id); }}>
                                            <span></span>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>

                    </div>
                )
            }


        })
        }
    </>);

}

export default MultPanda;