import React from 'react';

const Store = (props) => {

    return (<>


        {props.shopitem ? props.shopitem.map((item, index) => {
            if (index < props.activePage * 12 && index >= (props.activePage - 1) * 12) {
                return (
                    <div key={index} className={`one-shafl-prod ${item.img ? "has-img" : "noimg"} ${item.rare}`}>
                        {item.descr ? <div className="one-shafl-prod-info tooltip">
                            <div className="cust-tooltip right">
                                <div className="tool-arrow"></div>
                                <div className="tool-wrap">
                                    <div className="tool-wrap-1">
                                        <div className="tool-wrap-2">
                                            <div className="tool-body">
                                                <span>{item.tooltip}</span> <br />
                                                {item.name}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : null}
                        {item.element ? <div className={`one-shafl-prod-element tooltip ${item.element}`}>
                            <div className="cust-tooltip right">
                                <div className="tool-arrow"></div>
                                <div className="tool-wrap">
                                    <div className="tool-wrap-1">
                                        <div className="tool-wrap-2">
                                            <div className="tool-body">
                                                {item.element}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : null}
                        {item.type === "book" ? <div className="one-shafl-prod-info tooltip">
                            <div className="cust-tooltip right">
                                <div className="tool-arrow"></div>
                                <div className="tool-wrap">
                                    <div className="tool-wrap-1">
                                        <div className="tool-wrap-2">
                                            <div className="tool-body">
                                                <span>{item.name}</span> <br />
                                                {item.tooltip}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : null}
                        {item.type === "potion" ? <div className="one-shafl-prod-info tooltip">
                            <div className="cust-tooltip right">
                                <div className="tool-arrow"></div>
                                <div className="tool-wrap">
                                    <div className="tool-wrap-1">
                                        <div className="tool-wrap-2">
                                            <div className="tool-body">
                                                <span>{item.name}</span> <br />
                                                {item.tooltip}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : null}
                        <div className="one-shafl-prod-thumb">

                            {item.img && item.type == "eat" ?
                                <>
                                    <div className='storelink' onClick={() => props.showCloseModal('open', item)} ></div>
                                    <span></span>
                                    <div className="one-shafl-prod-thumb-in">
                                        <picture>
                                            <source srcSet={`./images/shop/${item.img}.webp`} type="image/webp" />
                                            <source srcSet={`./images/shop/${item.img}.png`} type="image/png" />
                                            <img src={`./images/shop/${item.img}.png`} alt="" />
                                        </picture>
                                        <i></i>
                                        <i className="b"></i>
                                    </div>
                                </>
                                : item.type == "weapon" ?
                                    <>
                                        <div className='storelink' onClick={() => props.showCloseModal('open', item)} ></div>
                                        <span></span>
                                        <div className="one-shafl-prod-thumb-in">
                                            <picture>
                                                <source srcSet={`./images/weapons/inventory/${item.img}.webp`} type="image/webp" />
                                                <source srcSet={`./images/weapons/inventory/${item.img}.png`} type="image/png" />
                                                <img src={`./images/weapons/inventory/${item.img}.png`} alt="" />
                                            </picture>
                                            <i></i>
                                            <i className="b"></i>
                                        </div>
                                    </>
                                    : item.type == "book" ?
                                        <>
                                            <div className='storelink' onClick={() => props.showCloseModal('open', item)} ></div>
                                            <span></span>
                                            <div className="one-shafl-prod-thumb-in">
                                                <picture>
                                                    <source srcSet={`./images/shop/icons/books/${item.img}.webp`} type="image/webp" />
                                                    <source srcSet={`./images/shop/icons/books/${item.img}.png`} type="image/png" />
                                                    <img src={`./images/shop/icons/books/${item.img}.png`} alt="" />
                                                </picture>
                                                <i></i>
                                                <i className="b"></i>
                                            </div>
                                        </>
                                        : item.type == "potion" ?
                                            <>
                                                <div className='storelink' onClick={() => props.showCloseModal('open', item)} ></div>
                                                <span></span>
                                                <div className="one-shafl-prod-thumb-in">
                                                    <picture>
                                                        <source srcSet={`./images/shop/potions/${item.img}.webp`} type="image/webp" />
                                                        <source srcSet={`./images/shop/potions/${item.img}.png`} type="image/png" />
                                                        <img src={`./images/shop/potions/${item.img}.png`} alt="" />
                                                    </picture>
                                                    <i></i>
                                                    <i className="b"></i>
                                                </div>
                                            </>
                                            : null}

                        </div>
                        {item.type == "potion" && item.token !== undefined && item.token === "TLM" ?
                            <div className="one-shafl-prod-descr">
                                <div className="one-shafl-prod-num" onClick={() => props.showCloseModal('open', item)}>
                                    {item.price ? <span className='tlmtoken'>{item.price}</span> : null}

                                    <span className={item.res}>+ {item.resNum}</span>
                                </div>
                            </div>
                            :
                            <div className="one-shafl-prod-descr">
                                <div className="one-shafl-prod-num" onClick={() => props.showCloseModal('open', item)}>
                                    {item.price ? <span>{item.price}</span> : null}
                                </div>
                            </div>}
                    </div>
                );
            }

        }) : null}

    </>);
}

export default Store;