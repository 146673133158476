import React, { useEffect, useRef } from "react";

const OnePandaPanelHero = (props) => {

    const widthScale = props.widthScale;
    const windowWidth = props.windowWidth;

    const pandaInfo = props.pandaInfo;

    return (
        <div className="one-panel-panda-wrap">
            <div className={`one-panel-panda rar-${pandaInfo.onePanda.rarity} ${props.isActive ? "active" : "dis"}`} style={
                {
                    width: windowWidth > 1924 ? 108 : widthScale * 134,
                    height: windowWidth > 1924 ? 109 : widthScale * 132,
                }
            } onClick={() => props.nowActivePanda(props.index)}>
                <div className="one-panel-panda-in has-p">
                    <div className="one-panel-panda-thumb">
                        <span>
                            <picture>
                                <source srcSet={`./images/card-1x/${pandaInfo.onePanda.name_pa}.webp`} type="image/webp" />
                                <source srcSet={`./images/card-1x/${pandaInfo.onePanda.name_pa}.png`} type="image/png" />
                                <img src={`./images/card-1x/${pandaInfo.onePanda.name_pa}.png`} alt="" />
                            </picture>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default OnePandaPanelHero;