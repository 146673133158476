import React from "react";
import Menu from '../../components/Menu';
import axios from "axios";

import { connect } from "react-redux";
import {
  setPandaOnGame,
  toggleIsFetchingTop,
  doTransferToUser,
  doDelFromState,
  addToGameState,
} from "./../../GlobalState/pandaongame-reducer";
import {
  setPandaOnUser,
  toggleIsFetchingBot,
  doTransferToGame,
  addToUserStateHerose,
  doDelFromStateUserHerose,
} from "./../../GlobalState/pandaonuser-reducer";

import FirstModalLogin from "./../../components/FirstModalLogin";
import ErrorComponent from "../Error/ErrorComponent";

import Heropagetop from "./Heropagetop/Heropagetop";
import Heropagebot from "./Heropagebot/Heropagebot";
import Preloader from "./../Preloader/Preloader";

import { JsonRpc } from "eosjs";

import musicPandaGo from "./../../music/panda-go.mp3";
import musicError from "./../../music/error.mp3";
import musicClose from "./../../music/close.mp3";
import musicOpen from "./../../music/open.mp3";

import { ExplorerApi } from "atomicassets"; // атомик для списка панд
import _ from "lodash";
import { async } from "q";
const fetch = require("node-fetch");
const api = new ExplorerApi(
  "https://" + localStorage.getItem('rpcA'),
  "atomicassets",
  { fetch, rateLimit: 4 }
);



var numRpc = 0;
var arrRpc = ['wax.pink.gg', 'wax.cryptolions.io', 'wax.greymass.com', 'api.wax.alohaeos.com', 'api.waxsweden.org', 'wax.eosphere.io', 'api.wax.greeneosio.com', 'wax.eu.eosamsterdam.net', 'api.wax.bountyblok.io']


// это транзакция отправки ИЗ
const cardTransferOut = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "transferout",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        from: "",
        to: "",
        asset_id: [],
      },
    },
  ],
};

// это транзакция отправки В
const cardTransfer = {
  actions: [
    {
      account: "atomicassets",
      name: "transfer",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        from: "",
        to: process.env.REACT_APP_CONTRACT,
        asset_ids: [],
        memo: "",
      },
    },
  ],
};

// регистрация в таблице
const loginTransaction = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "login",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        username: "",
        partner: process.env.REACT_APP_CONTRACT,
        signing_value: "",
      },
    },
  ],
};


class HeropageComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      warning: {
        hasError: false,
        information: false,
        title: "",
        message: "",
        idAssets: "",
      },
      errorText: "",
      hasError: false,
      showFisrsModal: false,
    };

    this.onHandleSendPandaToUser = this.onHandleSendPandaToUser.bind(this);
    this.onHandleSendPandaToGame = this.onHandleSendPandaToGame.bind(this);
    this.openWarningModal = this.openWarningModal.bind(this);
    this.handleCloseModalWarning = this.handleCloseModalWarning.bind(this);
    this.clearError = this.clearError.bind(this);
    this.handleCloseModalFirst = this.handleCloseModalFirst.bind(this);

    this.audioMusicPandaGo = new Audio(musicPandaGo);
    this.audioMusicError = new Audio(musicError);
    this.audioClose = new Audio(musicClose);
    this.audioOpen = new Audio(musicOpen);
  }

  componentDidMount() {
    this.userCheck();
  }

  async userCheck() {
    try {
      //console.log(555555555555555555555555555555555555555555555);
      const activeUser = this.props.ual.activeUser;
      const accountName = await activeUser.getAccountName();
      //console.log(activeUser);
      //console.log(accountName);
      var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);

      const results = await rpcc.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "usersnew", // name of the table as specified by the contract abi
        limit: 1,
        lower_bound: accountName,
        upper_bound: accountName,
        index_position: 1,
        reverse: false,
        show_payer: false,
      });
      const originRowUser = results;
      //console.log(originRowUser);
      let resultRow;
      if (originRowUser.rows.length) {
        //resultRow = results.rows[0].inrent;
        resultRow = 0;
      } else {
        resultRow = 0;
      }

      //console.log("originRowUser", originRowUser);
      //console.log("resultRow", resultRow);

      if (resultRow === 1) {
        const resultRowName = results.rows[0].rent;
        //console.log(111);
        //console.log(resultRowName);
        this.GetPandaListOnGame(resultRowName);
        this.GetPandaListOnUser(resultRowName);
      } else {
        //console.log(222);
        //console.log(accountName);
        this.GetPandaListOnGame(accountName);
        this.GetPandaListOnUser(accountName);
      }
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  // Показать панды в игре
  async GetPandaListOnGame(nameUser) {
    this.props.toggleIsFetchingTop(true);
    try {
      var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);
      //console.log("arrRpc", arrRpc);
      //console.log("arrRpc[numRpc]", arrRpc[numRpc]);
      //console.log("numRpc", numRpc);
      const results = await rpcc.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "nftsongamec", // name of the table as specified by the contract abi
        key_type: "i64",
        lower_bound: nameUser,
        upper_bound: nameUser,
        limit: -1,
        reverse: !0,
        show_payer: false,
        index_position: 2,
      });
      const resultRow = results.rows;
      this.props.setPandaOnGame(resultRow);
      this.props.toggleIsFetchingTop(false);
    } catch (e) {
      // console.log(e.message)
      console.warn(e);
      /*this.setState({
                hasError: true,
                errorText: e.message,
            });*/
      console.log("\nCaught exception: " + e);
      /*if(localStorage.getItem('sound') === 'true') {
                this.audioMusicError.currentTime = 0;
                this.audioMusicError.volume = 0.1;
                this.audioMusicError.play();
            }*/
    }
  }

  // Показать панды на акаунте у игрока
  async GetPandaListOnUser(nameUser) {
    this.props.toggleIsFetchingBot(true);
    (async () => {
      try {

        const userName = this.props.ual.activeUser.accountName;

        if (nameUser === userName) {

          const params = {
            limit: 1000,
            owner: userName,
            collection_name: "nftpandawaxp",
            schema_name: ["heroes", "promo"],
          };

          await api.getAssets(params, 1, 100).then(assets => {
            var filteredAssets = [];

            if (assets && assets.length) {
              filteredAssets = assets.map((item) => {
                return {
                  asset_id: item.asset_id,
                  name: item.name,
                  template: {
                    template_id: item.template.template_id,
                  },
                  data: {
                    rarity: item.data.rarity,
                    name: item.data.name,
                    type: item.data.type,
                    element: item.data.element
                  },
                };
              });
            }

            this.props.setPandaOnUser(filteredAssets);
            this.props.toggleIsFetchingBot(false);
          });
        }
      } catch (e) {
        //console.log(e.message)
        console.warn(e);
        this.setState({
          hasError: true,
          errorText: e.message,
        });
        console.log("\nCaught exception: " + e);
        if (localStorage.getItem("sound") === "true") {
          this.audioMusicError.currentTime = 0;
          this.audioMusicError.volume = 0.1;
          this.audioMusicError.play();
        }
      }
    })();
  }

  // Таймер после отправки панды туда сюда
  async updateLists() {
    var startTime = new Date().getTime();
    this.interval = setInterval(() => {
      if (new Date().getTime() - startTime > 5000) {
        clearInterval(this.interval);
        return;
      }
      this.GetPandaListOnGame();
      this.GetPandaListOnUser();
    }, 1000);
  }

  // диспаччит понд с игры к юзеру
  async removePandaFromStateGame(idPanda) {
    //console.log(idPanda);
    this.props.doDelFromState(idPanda, 0);

    (async () => {
      const b = await api.getAsset(idPanda);
      this.props.addToUserStateHerose(b);
    })();
  }

  // МОДАЛКА ПРИ ВЫВОДЕ
  async openWarningModal(item) {
    if (localStorage.getItem("sound") === "true") {
      this.audioOpen.currentTime = 0;
      this.audioOpen.volume = 0.1;
      this.audioOpen.play();
    }
    if (item.timer > Date.now() / 1000) {
      this.setState({
        ...this.state,
        warning: {
          hasError: true,
          information: true,
          title: "The hero needs a rest, take your time",
          message:
            "Your hero hasn't rested yet from the last adventure, and he still needs a little rest",
          idAssets: item.asset_id,
        },
      });
    } else if (item.energy < 10000) {
      this.setState({
        ...this.state,
        warning: {
          hasError: true,
          information: true,
          title: "Your hero is running out of energy",
          message:
            "You can withdraw your hero only with the maximum amount of energy",
          idAssets: item.asset_id,
        },
      });
    } else if (item.stake_count) {
      this.setState({
        ...this.state,
        warning: {
          hasError: true,
          information: true,
          title: `You must unstake all the ${process.env.REACT_APP_VAL} on your card.`,
          message:
            `In order for you to take the panda out of the game, you must unstake ${process.env.REACT_APP_VAL}. To do this, go to the adventure page and make an unstake ${process.env.REACT_APP_VAL} from this hero`,
          idAssets: item.asset_id,
        },
      });
    } else {
      this.setState({
        ...this.state,
        warning: {
          hasError: true,
          information: false,
          title: "Taking the hero out from game",
          message:
            "If you withdraw your hero, you will lose all game progress of this card",
          idAssets: item.asset_id,
        },
      });
    }
  }

  // МОДАЛКА ПРИ ВЫВОДЕ
  async handleCloseModalWarning() {
    if (localStorage.getItem("sound") === "true") {
      this.audioClose.currentTime = 0;
      this.audioClose.volume = 0.1;
      this.audioClose.play();
    }
    this.setState({
      ...this.state,
      warning: {
        hasError: false,
        information: false,
        title: "",
        message: "",
        idAssets: "",
      },
    });
  }

  // диспаччит понд от юзера в игру
  async removePandaFromStateUser(idPanda, namePa) {
    //console.log(idPanda);
    this.props.doDelFromStateUserHerose(idPanda);
    this.props.addToGameState({
      asset_id: idPanda,
      name_pa: namePa,
      stake_count: 0,
      lvl: 0,
      element: "water"
    });
  }

  async onHandleSendPandaToUser(dateBt) {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");

    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    cardTransferOut.actions[0].authorization[0].actor = userName;
    cardTransferOut.actions[0].data.to = userName;
    cardTransferOut.actions[0].data.asset_id = [dateBt];
    //console.log(cardTransferOut);
    try {
      await activeUser
        .signTransaction(cardTransferOut, {
          blocksBehind: 3,
          expireSeconds: 30,
        })
        .then((response) => {
          //console.log(response);
          if (response.status === "executed") {
            loadingSlot.classList.remove("loading");
            this.removePandaFromStateGame(dateBt);
            this.handleCloseModalWarning();
            //console.log(response.status);

            if (localStorage.getItem("sound") === "true") {
              this.audioMusicPandaGo.currentTime = 0;
              this.audioMusicPandaGo.volume = 0.1;
              this.audioMusicPandaGo.play();
            }
          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      loadingSlot.classList.remove("loading");
      this.setState({
        hasError: true,
        errorText: error.message,
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  async onHandleSendPandaToGame(dateBt, namePa) {

    console.log("dateBt", dateBt);
    console.log("namePa", namePa);
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");

    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    cardTransfer.actions[0].authorization[0].actor = userName;
    cardTransfer.actions[0].data.from = userName;
    cardTransfer.actions[0].data.asset_ids = [dateBt];
    cardTransfer.actions[0].data.memo = namePa;
    //console.log(cardTransfer);
    try {
      await activeUser
        .signTransaction(cardTransfer, { blocksBehind: 3, expireSeconds: 30 })
        .then((response) => {
          if (response.status === "executed") {
            loadingSlot.classList.remove("loading");
            this.removePandaFromStateUser(dateBt, namePa);
            //console.log(response.status);

            if (localStorage.getItem("sound") === "true") {
              this.audioMusicPandaGo.currentTime = 0;
              this.audioMusicPandaGo.volume = 0.1;
              this.audioMusicPandaGo.play();
            }
          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      loadingSlot.classList.remove("loading");
      this.setState({
        hasError: true,
        errorText: error.message,
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.play();
      }
    }
  }

  async clearError() {
    this.setState({
      errorText: "",
      hasError: false,
    });
  }

  async sendWombat() {

    const activeUser = this.props.ual.activeUser;
    const accountName = await activeUser.getAccountName();
    var dateNow = parseInt(Date.now() / 1000);
    var datas = {

      "username": accountName,
      "event": "registration",
      "eventtime": dateNow,
    }


  }

  /* ЛОгИН в контракте */
  async transfer() {
    let ref = "";
    if (localStorage.getItem("ref") !== null) {
      ref = localStorage.getItem("ref");
    } else {
      ref = process.env.REACT_APP_CONTRACT;
    }
    loginTransaction.actions[0].authorization[0].actor =
      this.props.ual.activeUser.accountName;
    loginTransaction.actions[0].data.username =
      this.props.ual.activeUser.accountName;
    loginTransaction.actions[0].data.partner = ref;
    loginTransaction.actions[0].data.signing_value =
      localStorage.getItem("signing_value");

    console.log("loginTransaction", loginTransaction);
    try {
      //this.setState({ openedTrans: true });
      await this.props.ual.activeUser.signTransaction(loginTransaction, {
        blocksBehind: 3,
        expireSeconds: 30,
      }).then((response) => {
        if (response.status === "executed") {
          this.sendWombat();
          localStorage.setItem("wasLogin", true);
          this.setState({ showFisrsModal: true });
        }
      });


    } catch (error) {
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
      });
      console.log("\nCaught exception: " + error);
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  //Закрыть модалку после логина

  async handleCloseModalFirst() {
    if (localStorage.getItem("sound") === "true") {
      this.audioClose.currentTime = 0;
      this.audioClose.volume = 0.1;
      this.audioClose.play();
    }
    this.setState({
      ...this.state,
      showFisrsModal: false,
    });
  }

  /* Конец ЛОгИН в контракте */

  render() {
    return (
      <>
        <Menu  {...this.props.ual} wax={this.props.balanceWax} bam={this.props.balanceBam} game={this.props.balanceGame} updateBalance={this.props.updateBalance} />
        {this.props.ual.activeUser ? (
          <FirstModalLogin
            {...this.props}
            showFisrsModal={this.state.showFisrsModal}
            handleCloseModalFirst={this.handleCloseModalFirst}
          />
        ) : null}
        {this.state.hasError ? (
          <ErrorComponent
            errorMessage={this.state.errorText}
            clearError={this.clearError}
          />
        ) : null}
        <main className="other-page">
          <div className="loading-block"></div>
          <section id="list-all-panda">
            <div className="bamb-tree">
              <div className="bamb-tree-in">
                <div className="bamb-tree-top"></div>
                <div className="bamb-tree-bot"></div>


                <div className="bamb-tree-wrap">
                  {localStorage.getItem("wasLogin") == "false" ||
                    localStorage.getItem("wasLogin") == undefined ? ( // если незалогинен
                    <div className="you-have-no-panda">
                      <div className="tit-list">
                        Welcome if you want to start your adventure you must
                        register first
                      </div>
                      <div className="begin-adv">
                        <button
                          className="def-bt mid"
                          onClick={(e) => {
                            this.transfer();
                          }}
                        >
                          <span className="button-in">
                            <span className="button-name">
                              <span className="button-name-in">
                                <span className="button-name-value">
                                  Register
                                </span>
                              </span>
                            </span>
                          </span>
                        </button>
                        <div id="btn-transfer"></div>
                      </div>
                    </div>
                  ) : (this.props.pandaListOnGame &&
                    this.props.pandaListOnGame.length > 0) ||
                    (this.props.pandaListOnUser &&
                      this.props.pandaListOnUser.length > 0) ? (
                    <>
                      <div className="top-card-list">
                        {!this.props.isFetching ? (
                          <Heropagetop
                            {...this.state}
                            pandaListOnGame={this.props.pandaListOnGame}
                            onHandleSendPandaToUser={
                              this.onHandleSendPandaToUser
                            }
                            openWarningModal={this.openWarningModal}
                            handleCloseModalWarning={
                              this.handleCloseModalWarning
                            }
                          />
                        ) : null}
                      </div>
                      {!this.props.isFetchingBot ? (
                        <Heropagebot
                          pandaListOnUser={this.props.pandaListOnUser}
                          onHandleSendPandaToGame={this.onHandleSendPandaToGame}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div className="you-have-no-panda">
                      <div className="tit-list">
                        Sorry, but you don’t have any heroes yet. <br /> Please
                        buy your first hero!
                      </div>
                      <div className="begin-adv">
                        <a
                          className="def-bt mid"
                          target="_blank"
                          href="https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&schema_name=heroes&sort=created&state=1&symbol=WAX"
                        >
                          <span className="button-in">
                            <span className="button-name">
                              <span className="button-name-in">
                                <span className="button-name-value">
                                  Buy hero
                                </span>
                              </span>
                            </span>
                          </span>
                        </a>
                        <div id="btn-transfer"></div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>

          <footer>
            <div className="footer-nav">
              <nav>
                <ul>
                  <li>
                    <a
                      className="foot-logo-1"
                      target="_blank"
                      href="https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&sort=created&symbol=WAX"
                    >
                      <span></span>
                      <i>Market</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-2"
                      target="_blank"
                      href="https://twitter.com/NftPanda/"
                    >
                      <span></span>
                      <i>Twitter</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-3"
                      target="_blank"
                      href="https://www.instagram.com/nftpanda/"
                    >
                      <span></span>
                      <i>Instagram</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-4"
                      target="_blank"
                      href="https://discord.gg/vCSgme4d5b"
                    >
                      <span></span>
                      <i>Discord</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-5"
                      target="_blank"
                      href={`https://wax.alcor.exchange/swap?output=${process.env.REACT_APP_VAL}-nftpandabamb&input=WAX-eosio.token`}
                    >
                      <span></span>
                      <i>{process.env.REACT_APP_VAL} Trade</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-6"
                      target="_blank"
                      href="https://discord.gg/vCSgme4d5b"
                    >
                      <span></span>
                      <i>Support</i>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </footer>
        </main>
      </>
    );
  }
}

let mapStateToProps = (state) => ({
  pandaListOnGame: state.heroPageTop.pandaListOnGame,
  isFetching: state.heroPageTop.isFetching,
  pandaListOnUser: state.heroPageBot.pandaListOnUser,
  isFetchingBot: state.heroPageBot.isFetchingBot,
});

export default connect(mapStateToProps, {
  setPandaOnGame,
  toggleIsFetchingTop,
  doTransferToUser,
  setPandaOnUser,
  toggleIsFetchingBot,
  doTransferToGame,
  doDelFromState,
  addToUserStateHerose,
  doDelFromStateUserHerose,
  addToGameState,
})(HeropageComponent);
