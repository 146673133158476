import React from 'react';

import { connect } from "react-redux";
import Slots from './Slots';
import { NavLink } from 'react-router-dom';
import Heromodal from './Heromodal';
import Eatmodal from './Eatmodal';
import Countdown from 'react-countdown';
import papper from '../../images/reward-modal-bg.png';
import CountUp from 'react-countup';
import chest from '../../images/box-2x.png';
import chest1 from '../../images/box-1-2x.png';
import chest2 from '../../images/box-2-2x.png';
import chest3 from '../../images/box-3-2x.png';
import chest4 from '../../images/box-4-2x.png';
import chest5 from '../../images/box-5-2x.png';
import chest6 from '../../images/box-6-2x.png';
import chest7 from '../../images/box-7-2x.png';
import chest8 from '../../images/box-8-2x.png';
import chest9 from '../../images/box-9-2x.png';

//import anonce from '../../images/alien/anonce-7.jpg';
//import anoncew from '../../images/alien/anonce-7.webp';

// import anonce from '../../images/auction/anonce-5.jpg';
// import anoncew from '../../images/auction/anonce-5.webp';

import anonce from '../../images/alien/anonce-8.png';
import anoncew from '../../images/alien/anonce-8.webp';
import { set } from 'lodash';

function backside(num) {
    //console.log(4);
    var items = document.getElementsByClassName('one-slot');
    items[num].classList.toggle("active");
}

function filter(rar) {
    //console.log(4);
    var showm = document.querySelector('.filter-mod');
    var allcard = document.querySelectorAll('.mod-list');
    if (rar === "all") {
        for (var i = 0; i < allcard.length; ++i) {
            allcard[i].classList.remove('hide');
        }
    }
    else {
        for (var i = 0; i < allcard.length; ++i) {
            allcard[i].classList.add('hide');
        }
        var showcard = document.querySelectorAll('[data-rar=' + rar + ']');
        for (var i = 0; i < showcard.length; ++i) {
            showcard[i].classList.remove('hide');
        }
    }
    showm.classList.remove("open");
}

function openfilter(rar) {
    //console.log(4);
    var showcard = document.querySelector('.filter-mod')
    var items = document.getElementsByClassName(rar);
    showcard.classList.toggle("open");
}

if (localStorage.getItem('wasLogin') === 'false') {
    window.addEventListener('click', function (e) {
        var showcard = document.querySelector('.filter-mod')
        if (!showcard.contains(e.target) /*&& !button.contains(e.target)*/) {
            // Ниже код, который нужно выполнить при срабатывании события.
            showcard.classList.remove('open');
        }
    });
}

class AdventuresNew extends React.Component {

    constructor(props) {
        super(props)

    }

    componentDidMount() { }

    onSliderChange = value => {

        console.log(value);


    };

    render() {
        var summ = 0;
        var allPandas = 0;
        //console.log("pandaSlots", this.props.pandaSlots);
        this.props.pandaSlots.find((x) => {
            if (x.activePanda === true) {
                summ += 1
            }
            if (x.is_in_slot === 1 && this.props.userSlots.includes(x.asset_id)) {
                allPandas += 1
            }
        }
        )
        return <main className="slot-page classic">
            <div className="loading-block"></div>
            <div className="haveQuests tooltip">
                <NavLink className="haveQuestsLink" to="/tavern"></NavLink>
                <div className="cust-tooltip right">
                    <div className="tool-arrow"></div>
                    <div className="tool-wrap">
                        <div className="tool-wrap-1">
                            <div className="tool-wrap-2">
                                <div className="tool-body">
                                    new quests in the tavern
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {this.props.allUserInfo && this.props.allUserInfo.maxrank < 2 ?
                <div className="haveQuests newquest tooltip">
                    <NavLink className="haveQuestsLinkNew" to="/tavern"></NavLink>
                    <div className="cust-tooltip right">
                        <div className="tool-arrow"></div>
                        <div className="tool-wrap">
                            <div className="tool-wrap-1">
                                <div className="tool-wrap-2">
                                    <div className="tool-body">
                                        Quests for Beginners
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null
            }

            <div className="send-all-wrap">
                <div className={`send-all-panda ${this.props.openMultiSt ? "active" : ""}`}>
                    <div className={`send-all-bt tooltip`}>
                        <span className={`${this.props.openMultiSt ? "active" : ""}`} onClick={(e) => {
                            this.props.openMulti();
                        }}></span>
                        <div className="cust-tooltip right">
                            <div className="tool-arrow"></div>
                            <div className="tool-wrap">
                                <div className="tool-wrap-1">
                                    <div className="tool-wrap-2">
                                        <div className="tool-body">
                                            mass action
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`send-wrap ${this.props.openMultiSt ? "active" : ""}`}>
                        <div className="send-all-top">
                            <div className="send-all-top-in">
                                <div className="selec-num-panda">
                                    selected:
                                    <span>
                                        {summ}/{allPandas}
                                    </span>
                                </div>
                                <div className="select-bt">
                                    <div className="select-all">
                                        <button className="def-bt mini" onClick={(e) => {
                                            this.props.selectAll('all');
                                        }}>
                                            <span className="button-in">
                                                <span className="button-name">
                                                    <span className="button-name-in">
                                                        <span className="button-name-value">
                                                            select all
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </button>
                                    </div>
                                    {summ ?
                                        <div className="clear-all">
                                            <button className="def-bt mini" onClick={(e) => {
                                                this.props.selectAll('no');
                                            }}>
                                                <span className="button-in">
                                                    <span className="button-name">
                                                        <span className="button-name-in">
                                                            <span className="button-name-value">
                                                                X
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </button>
                                        </div>
                                        : null}
                                </div>
                            </div>
                        </div>
                        <div className="send-all-bot">
                            <div className="send-all-bot-in">
                                <div className="one-bt-send tooltip">
                                    <span onClick={() => this.props.openNewAdvMap('open', 0, 0, false, true)}></span>
                                    <div className="cust-tooltip bottom">
                                        <div className="tool-arrow"></div>
                                        <div className="tool-wrap">
                                            <div className="tool-wrap-1">
                                                <div className="tool-wrap-2">
                                                    <div className="tool-body">
                                                        Send to adventure
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {summ ?
                                    <div className="one-bt-send tooltip">
                                        <span onClick={(e) => {
                                            this.props.addEnergyMulti();
                                        }}></span>
                                        <div className="cust-tooltip bottom">
                                            <div className="tool-arrow"></div>
                                            <div className="tool-wrap">
                                                <div className="tool-wrap-1">
                                                    <div className="tool-wrap-2">
                                                        <div className="tool-body">
                                                            feed everyone
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="one-bt-send tooltip">
                                        <span></span>
                                        <div className="cust-tooltip bottom">
                                            <div className="tool-arrow"></div>
                                            <div className="tool-wrap">
                                                <div className="tool-wrap-1">
                                                    <div className="tool-wrap-2">
                                                        <div className="tool-body">
                                                            feed everyone
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="one-bt-send tooltip">
                                    <span onClick={(e) => {
                                        this.props.addEnergyMultiJew();
                                    }}></span>
                                    <div className="cust-tooltip bottom">
                                        <div className="tool-arrow"></div>
                                        <div className="tool-wrap">
                                            <div className="tool-wrap-1">
                                                <div className="tool-wrap-2">
                                                    <div className="tool-body">
                                                        repair jewelry
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="one-bt-send tooltip">
                                    <span onClick={(e) => {
                                        this.props.addEnergyMultiWeapon("weapon");
                                    }}></span>
                                    <div className="cust-tooltip bottom">
                                        <div className="tool-arrow"></div>
                                        <div className="tool-wrap">
                                            <div className="tool-wrap-1">
                                                <div className="tool-wrap-2">
                                                    <div className="tool-body">
                                                        repair weapons
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="one-bt-send tooltip">
                                    <span onClick={(e) => {
                                        this.props.addEnergyMultiWeapon("armor");
                                    }}></span>
                                    <div className="cust-tooltip bottom">
                                        <div className="tool-arrow"></div>
                                        <div className="tool-wrap">
                                            <div className="tool-wrap-1">
                                                <div className="tool-wrap-2">
                                                    <div className="tool-body">
                                                        repair armors
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {//Date.now() <= 1664064000000 && 
                // <div className="anonce">
                //     <NavLink target='_blank' href="http://localhost:3000/alien-adventures">
                //         <picture>
                //             <source srcSet={anoncew} type="image/webp" />
                //             <source srcSet={anonce} type="image/png" />
                //             <img src={anoncew} alt="" />
                //         </picture>
                //     </NavLink>
                // </div>
                <div className="anonce">
                    <NavLink to="/alien-adventures">
                        <picture>
                            <source srcSet={anonce} type="image/webp" />
                            <source srcSet={anonce} type="image/png" />
                            <img src={anonce} alt="" />
                        </picture>
                    </NavLink>
                </div>
            }

            <div className="section-title">
                <div className="container">
                    <div className="section-title-in">
                        <h1>
                            Adventures
                        </h1>
                        <div className="section-subtitle">
                            it’s your time legend to begin the battle for Elgard land. Let's send your army to adventures and collect your reward.
                        </div>
                    </div>
                </div>
            </div>


            <section id="all-slots">
                <div className="container">
                    <div className="all-slots-in">
                        <Slots
                            {...this.props}
                        />
                    </div>
                </div>
            </section>

            {this.props.openModalChooseHero ? <div className="all-modal-wrap open">
                {this.props.chooseHeroLoading ? <div className="loader-choose-hero"></div> : null}
                <div className="bamb-modal-body">

                    <div className="bamb-modal-body-in">
                        <div className="bamb-modal">
                            <div className="bamb-modal-in">
                                <div className="modal-close" onClick={this.props.handleClose}></div>
                                <div className="bamb-modal-top"></div>
                                <div className="bamb-modal-bot"></div>
                                <div className="bamb-modal-wrap">
                                    <div className="bamb-modal-tit">
                                        Choose a hero
                                    </div>

                                    {this.props.pandaListOnGame.length ? <div className="filter">

                                        <div className="filter-in">
                                            <div className="filter-tit">
                                                filters:
                                            </div>
                                            <div className="filter-one filter-mod">
                                                <button className="def-bt min" onClick={(e) => {
                                                    openfilter();
                                                }}>
                                                    <span className="button-in">
                                                        <span className="button-name">
                                                            <span className="button-name-in">
                                                                <span className="button-name-value">
                                                                    All heroes
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </button>

                                                <div className="filter-drop min">
                                                    <div className="filter-drop-in">
                                                        <ul>
                                                            <li onClick={(e) => {
                                                                filter('all');
                                                            }}>
                                                                All heroes
                                                            </li>
                                                            <li onClick={(e) => {
                                                                filter('Common');
                                                            }}>
                                                                Common
                                                            </li>
                                                            <li onClick={(e) => {
                                                                filter('Promo');
                                                            }}>
                                                                Promo
                                                            </li>
                                                            <li onClick={(e) => {
                                                                filter('Uncommon');
                                                            }}>
                                                                Uncommon
                                                            </li>
                                                            <li onClick={(e) => {
                                                                filter('Rare');
                                                            }}>
                                                                Rare
                                                            </li>
                                                            <li onClick={(e) => {
                                                                filter('Epic');
                                                            }}>
                                                                Epic
                                                            </li>
                                                            <li onClick={(e) => {
                                                                filter('Legendary');
                                                            }}>
                                                                Legendary
                                                            </li>
                                                            <li onClick={(e) => {
                                                                filter('Mythic');
                                                            }}>
                                                                Mythic
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        : null}

                                    <div className="bamb-modal-cards">
                                        {this.props.pandaListOnGame && this.props.pandaListOnGame.length ? <Heromodal pandaListOnGame={this.props.pandaListOnGame} onHandleSendPandaToSlot={this.props.onHandleSendPandaToSlot} /> :
                                            <div className="modal-buy-bt">
                                                <div className="modal-buy-text">
                                                    SORRY, BUT YOU DON’T HAVE ANY HEROES YET. PLEASE BUY YOUR FIRST HERO!
                                                </div>
                                                <a className="def-bt mid" target="_blank" href="https://wax.atomichub.io/market?collection_name=nftpandawaxp">
                                                    <span className="button-in">
                                                        <span className="button-name">
                                                            <span className="button-name-in">
                                                                <span className="button-name-value">
                                                                    Buy hero
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </a>
                                            </div>

                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-modal"></div>
            </div> : null}

            {this.props.openBuySlot ? <div className="modal-wrap-by-slote open">
                <div className="bamb-modal-body">
                    <div className="bamb-modal-body-in">
                        <div className="bamb-modal">
                            <div className="bamb-modal-in">
                                <div className="modal-close" onClick={() => this.props.handleClose()}></div>
                                <div className="bamb-modal-top"></div>
                                <div className="bamb-modal-bot"></div>
                                <div className="bamb-modal-wrap">
                                    <div className="bamb-modal-tit">
                                        <div className="bamb-modal-tit-slot">
                                            ADD SLOT {this.props.usersRow + 1}
                                            {/* <span></span> */}
                                        </div>
                                    </div>
                                    <div className="bamb-modal-descr">
                                        It’s time to grow your army. Confirm adding a new slot. <br /> It will cost <span>{(this.props.usersRow) * 30} {process.env.REACT_APP_VAL}</span> tokens.
                                    </div>
                                    <div className="bamb-modal-bt">
                                        <button className="def-bt mid" onClick={(e) => {
                                            this.props.baySlot((this.props.usersRow) * 30);
                                        }}>
                                            <span className="button-in">
                                                <span className="button-name">
                                                    <span className="button-name-in">
                                                        <span className="button-name-value">
                                                            Buy Slot
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </button>
                                    </div>
                                    <div className="bamb-modal-bt bamb-modal-bam-buy">
                                        <a href={`https://wax.alcor.exchange/swap?output=${process.env.REACT_APP_VAL}-nftpandabamb&input=WAX-eosio.token`} target="_blank" className="def-bt min">
                                            <span className="button-in">
                                                <span className="button-name">
                                                    <span className="button-name-in">
                                                        <span className="button-name-value">
                                                            Buy {process.env.REACT_APP_VAL}
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-modal"></div>
            </div>
                : null}

            {this.props.rewardModalOpen ? <div className="modal-wrap-by-reward">
                <div className="papper-modal-body">

                    <div className="papper-modal-body-in">
                        <div className="papper-modal-bg-close" onClick={this.props.handleClose}></div>
                        {!this.props.rewardModalText.text ? <div className="loader-modal"></div> : null}

                        <div className="papper-modal">
                            <img src={papper} alt="" />
                            <div className="papper-modal-in">


                                {/* <div className="modal-close" onClick={this.props.handleClose}></div> */}

                                <div className="papper-modal-wrap">
                                    <div className="papper-modal-tit">
                                        {this.props.rewardModalText.title}
                                    </div>
                                    <div className="papper-modal-descr">
                                        {this.props.rewardModalText.text}
                                    </div>
                                    <div className="chest-wrap">
                                        <div className={`chest-wrap-in act${this.props.rewardModalText.animationNumber}`}>
                                            <img className="chest" src={chest} alt="" />
                                            {this.props.rewardModalText.animationNumber === 1 ? <img className={`chest1 active`} src={chest1} alt="" /> : null}
                                            {this.props.rewardModalText.animationNumber === 2 ? <img className={`chest2 active`} src={chest2} alt="" /> : null}
                                            {this.props.rewardModalText.animationNumber === 3 ? <img className={`chest3 active`} src={chest3} alt="" /> : null}
                                            {this.props.rewardModalText.animationNumber === 4 ? <img className={`chest4 active`} src={chest4} alt="" /> : null}
                                            {this.props.rewardModalText.animationNumber === 5 ? <img className={`chest5 active`} src={chest5} alt="" /> : null}
                                            {this.props.rewardModalText.animationNumber === 6 ? <img className={`chest6 active`} src={chest6} alt="" /> : null}
                                            {this.props.rewardModalText.animationNumber === 7 ? <img className={`chest7 active`} src={chest7} alt="" /> : null}
                                            {this.props.rewardModalText.animationNumber === 8 ? <img className={`chest8 active`} src={chest8} alt="" /> : null}
                                            {this.props.rewardModalText.animationNumber === 9 ? <img className={`chest9 active`} src={chest9} alt="" /> : null}
                                            <div className="count-up">
                                                <CountUp end={this.props.rewardModalText.finalNumber} decimals={4} duration={2} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="papper-modal-bt">
                                        <button className="def-bt mid" onClick={this.props.handleClose}>
                                            <span className="button-in">
                                                <span className="button-name">
                                                    <span className="button-name-in">
                                                        <span className="button-name-value">
                                                            close
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-modal"></div>
            </div>
                : null}

            {this.props.stakeModalOpen ? <div className="modal-wrap-stake open">
                <div className="bamb-modal-body">
                    <div className="bamb-modal-body-in">
                        <div className="bamb-modal">
                            <div className="bamb-modal-in">
                                <div className="modal-close" onClick={this.props.handleClose}></div>
                                <div className="bamb-modal-top"></div>
                                <div className="bamb-modal-bot"></div>
                                <div className="bamb-modal-wrap">
                                    <div className="stake-wrap">
                                        <div className="stake-top">
                                            <div className="stake-tit">
                                                Staking {this.props.stakeInfo.name} <span>id: {this.props.stakeInfo.id}</span>
                                            </div>
                                        </div>
                                        <div className="stake-body">
                                            <div className="stake-thumb">
                                                <img src={`./images/card-1x/${this.props.stakeInfo.thumb}.png`} alt="" />
                                            </div>
                                            <div className="stake-descr">
                                                <div className="stake-form">
                                                    <div className="stake-form-tit">
                                                        your current staking information
                                                    </div>
                                                    <div className="stake-form-info">
                                                        <div className="stake-bam">
                                                            in stake: <span>{this.props.stakeInfo.sum}</span> bam
                                                        </div>
                                                        <div className="unstake-bam">
                                                            <span onClick={(e) => {
                                                                this.props.unstakeOpenModal();
                                                            }}>
                                                                unstake
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="stake-form-in">
                                                        <div className="stake-inp">
                                                            <input type="number" min="0" value={this.props.stakeInfo.input} onChange={this.props.handleChangeInput} placeholder="enter token amount" />
                                                        </div>
                                                        <div className="stake-btns">
                                                            <div className="one-stake-bam">
                                                                <button className="def-bt min" onClick={(e) => {
                                                                    this.props.stakeBamNft(this.props.stakeInfo.id, this.props.stakeInfo.input, this.props.stakeInfo.allinfo);
                                                                }}>
                                                                    <span className="button-in">
                                                                        <span className="button-name">
                                                                            <span className="button-name-in">
                                                                                <span className="button-name-value">
                                                                                    add to stake
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </button>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    {

                                                        this.props.stakeInfo.lvlLoop !== this.props.stakeInfo.allinfo.lvl && this.props.stakeInfo.youmastadd > 0 ?
                                                            <>
                                                                <div className="modal-must-add">
                                                                    To get the maximum reward, you must stake <span onClick={(e) => {
                                                                        this.props.addNumToStake(this.props.stakeInfo.youmastadd.toFixed(4));
                                                                    }}>{this.props.stakeInfo.youmastadd} {process.env.REACT_APP_VAL}</span>


                                                                </div>
                                                            </>
                                                            :
                                                            this.props.stakeInfo.allinfo.stake_count < this.props.stakeInfo.errorBamToLvlUp ?
                                                                <div className="modal-must-add">
                                                                    To rank up you still need to stake <span onClick={(e) => {
                                                                        this.props.addNumToStake((parseInt((this.props.stakeInfo.errorBamToLvlUp - this.props.stakeInfo.allinfo.stake_count) * 10000) / 10000) / 10000);
                                                                    }}>{parseInt(((this.props.stakeInfo.errorBamToLvlUp - this.props.stakeInfo.allinfo.stake_count) * 10000) / 10000) / 10000} {process.env.REACT_APP_VAL}</span>
                                                                </div>
                                                                :
                                                                null}

                                                    <div className="bay-bam">
                                                        <div className="one-stake-bam">
                                                            <a target="_blank" href={`https://wax.alcor.exchange/swap?output=${process.env.REACT_APP_VAL}-nftpandabamb&input=WAX-eosio.token`} className="def-bt min">
                                                                <span className="button-in">
                                                                    <span className="button-name">
                                                                        <span className="button-name-in">
                                                                            <span className="button-name-value">
                                                                                BUY {process.env.REACT_APP_VAL}
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal-unstake">
                    <div className="bamb-modal-body">
                        <div className="bamb-modal-body-in">
                            <div className="bamb-modal">
                                <div className="bamb-modal-in">
                                    <div className="modal-close" onClick={this.props.handleCloseUnstake}></div>
                                    <div className="bamb-modal-top"></div>
                                    <div className="bamb-modal-bot"></div>
                                    <div className="bamb-modal-wrap">
                                        <div className="unstake-wrap">
                                            <div className="unstake-top">
                                                <div className="unstake-tit">
                                                    Your staking is {this.props.stakeInfo.sum} {process.env.REACT_APP_VAL}
                                                </div>
                                            </div>
                                            <div className="unstake-bot">
                                                If you do this, then you will <span>lose hero rank</span>.
                                            </div>

                                            <div className="unstake-form-in">
                                                <div className="stake-inp">
                                                    <input min="0" type="number" value={this.props.stakeInfo.inputUn} onChange={this.props.handleChangeInputUn} placeholder="enter token amount" />
                                                </div>
                                                <div className="stake-btns">
                                                    <div className="one-stake-bam">
                                                        <button className="def-bt min" onClick={(e) => {
                                                            this.props.unstakeBamNft(this.props.stakeInfo.id, this.props.stakeInfo.inputUn, this.props.stakeInfo.allinfo);
                                                        }}>
                                                            <span className="button-in">
                                                                <span className="button-name">
                                                                    <span className="button-name-in">
                                                                        <span className="button-name-value">
                                                                            unstake
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-modal"></div>
                </div>

                <div className="bg-modal"></div>
            </div>
                : null}

            {this.props.modalEat.openModal ? <div className="modal-wrap-eat-list">
                {this.props.modalEat.loadingChooseEat ? <div className="loader-choose-hero"></div> : null}
                <div className="bamb-modal-body">

                    <div className="bamb-modal-body-in">
                        <div className="bamb-modal">
                            <div className="bamb-modal-in">
                                <div className="modal-close" onClick={this.props.closeModalEat}></div>
                                <div className="bamb-modal-top"></div>
                                <div className="bamb-modal-bot"></div>
                                <div className="bamb-modal-wrap">
                                    <div className="bamb-modal-tit">
                                        Choose food for your Hero
                                    </div>

                                    <div className="bamb-modal-cards">
                                        {this.props.alleat && this.props.alleat.length ? <Eatmodal alleat={this.props.alleat} addEnergy={this.props.addEnergy} pandaId={this.props.modalEat.assetid} styleEat={this.props.modalEat.style} /> :
                                            <div className="modal-buy-bt">
                                                <div className="modal-buy-text">
                                                    it looks like you have completely run out of food. It's time to go to the shop
                                                </div>
                                                <NavLink className="def-bt mid shop-link-bt" to="/shop">

                                                    <span className="button-in">
                                                        <span className="button-name">
                                                            <span className="button-name-in">
                                                                <span className="button-name-value">
                                                                    SHOP
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </NavLink>
                                            </div>

                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-modal"></div>
            </div>
                : null
            }

            <footer>
                <div className="footer-nav">
                    <nav>
                        <ul>
                            <li>
                                <a className="foot-logo-1" target="_blank" href="https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&sort=created&symbol=WAX">
                                    <span>

                                    </span>
                                    <i>
                                        Market
                                    </i>
                                </a>
                            </li>
                            <li>
                                <a className="foot-logo-2" target="_blank" href="https://twitter.com/NftPanda/">
                                    <span>

                                    </span>
                                    <i>
                                        Twitter
                                    </i>
                                </a>
                            </li>
                            <li>
                                <a className="foot-logo-3" target="_blank" href="https://www.instagram.com/nftpanda/">
                                    <span>

                                    </span>
                                    <i>
                                        Instagram
                                    </i>
                                </a>
                            </li>
                            <li>
                                <a className="foot-logo-4" target="_blank" href="https://discord.gg/vCSgme4d5b">
                                    <span>

                                    </span>
                                    <i>
                                        Discord
                                    </i>
                                </a>
                            </li>
                            <li>
                                <a className="foot-logo-5" target="_blank" href={`https://wax.alcor.exchange/swap?output=${process.env.REACT_APP_VAL}-nftpandabamb&input=WAX-eosio.token`}>
                                    <span>

                                    </span>
                                    <i>
                                        {process.env.REACT_APP_VAL} Trade
                                    </i>
                                </a>
                            </li>
                            <li>
                                <a className="foot-logo-6" target="_blank" href="https://discord.gg/vCSgme4d5b">
                                    <span>

                                    </span>
                                    <i>
                                        Support
                                    </i>
                                </a>
                            </li>
                        </ul>

                    </nav>
                </div>
            </footer>

        </main>
    }
}

let mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, {})(AdventuresNew);