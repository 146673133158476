import React from "react";
import Menu from '../../components/Menu';

import Countdown from "react-countdown";

import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import AlienLeaderboard from "./AlienLeaderboard";
import { JsonRpc } from "eosjs";
import alienlogo from "../../images/alien/alien-panda-logo-3.png"; // relative path to image
import alienlogow from "../../images/alien/alien-panda-logo-3.webp"; // relative path to image

import tit from "../../images/alien/leader-tit.png"; // relative path to image
import titw from "../../images/alien/leader-tit.webp"; // relative path to image

var numRpc = 0;
var arrRpc = ['wax.pink.gg', 'wax.cryptolions.io', 'wax.greymass.com', 'api.wax.alohaeos.com', 'api.waxsweden.org', 'wax.eosphere.io', 'api.wax.greeneosio.com', 'wax.eu.eosamsterdam.net', 'api.wax.bountyblok.io']
const timerStart = 1711972800; // real start
//const timerStart = 1701946630; // start for test
const timerEnd = 1717243200;
//const timerEnd = 1707931558; // test

const LiderDescr = (props) => {
    const { classN } = props;

    return (<div className={`wrapline ${classN}`}><div className={`red-line`}>
        {Date.now() >= timerStart * 1000 && timerEnd * 1000 >= Date.now() ?
            <>
                <div className="leader-alien-timer">
                    <Countdown date={timerEnd * 1000} />
                </div>
                <div className="leader-alien-descr">
                    The fourth season will run From 1 April 12 UTC to 1 June 12 UTC!
                </div>
            </>
            :

            timerEnd * 1000 < Date.now() ?
                <div className="leader-alien-descr">
                    We are pleased to announce that the alien worlds festival has been completed. < br />
                    Congratulations to all the winners!< br />
                    The start of the next season will be announced soon, stay tuned.
                </div>
                :
                <>
                    <div className="leader-date">
                        {
                            Date.now() >= 1682942400000 ?
                                <>Here, you will find a list of the most legendary Space Crystal seekers in the Lands of Elgard. <br />The top 200 players will receive rewards. <br />The fourth season will run From 1 April 12 UTC to 1 June 12 UTC!</>
                                : <>The event will go From 1 April 12 UTC to 1 June 12 UTC! <br />Time left before the start</>
                        }

                    </div>
                    <div className="leader-alien-timer">
                        {Date.now() <= timerStart * 1000 ? (
                            <Countdown date={timerStart * 1000} />
                        ) : null
                        }
                    </div>
                    <div className="leader-alien-descr">
                        {/* Here, you will find a list of the most legendary Space Crystal seekers in the Lands of Elgard. <br />The top 100 players will receive rewards. */}
                    </div>
                </>
        }
    </div>


        <div className="leader-bt">
            <div className="one-leader-bt">
                {Date.now() >= 1688126400000 && timerEnd * 1000 > Date.now() ?
                    <a className="bt-alien first" target="_blank" href="https://nftpanda.medium.com/crossing-universes-season-3-nft-panda-world-of-fantasy-alien-worlds-planet-magor-syndicate-2ce56482df25">
                        more information
                    </a>
                    :
                    null
                }
            </div>
            <div className="one-leader-bt">
                <NavLink className="bt-alien first" to="/alien-adventures">
                    back to adventures
                </NavLink>
            </div>
        </div>
    </div >
    );
}

class AlienLeaderboardComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            formInfo: [],
            formInfoChit: [],
            count: 0,
            priseList: [
                { start: 1, end: 1, sum: 7000, voucher: 200, chests: 15, nft: 1 },
                { start: 2, end: 2, sum: 5300, voucher: 150, chests: 13, nft: 1 },
                { start: 3, end: 3, sum: 3000, voucher: 120, chests: 12, nft: 1 },
                { start: 4, end: 4, sum: 2100, voucher: 110, chests: 10, nft: 1 },
                { start: 5, end: 5, sum: 1800, voucher: 100, chests: 8, nft: 1 },
                { start: 6, end: 10, sum: 1400, voucher: 80, chests: 7, nft: 1 },
                { start: 6, end: 10, sum: 1400, voucher: 80, chests: 7, nft: 1 },
                { start: 6, end: 10, sum: 1400, voucher: 80, chests: 7, nft: 1 },
                { start: 6, end: 10, sum: 1400, voucher: 80, chests: 7, nft: 1 },
                { start: 6, end: 10, sum: 1400, voucher: 80, chests: 7, nft: 1 },
                { start: 11, end: 20, sum: 1200, voucher: 75, chests: 6, nft: 1 },
                { start: 11, end: 20, sum: 1200, voucher: 75, chests: 6, nft: 1 },
                { start: 11, end: 20, sum: 1200, voucher: 75, chests: 6, nft: 1 },
                { start: 11, end: 20, sum: 1200, voucher: 75, chests: 6, nft: 1 },
                { start: 11, end: 20, sum: 1200, voucher: 75, chests: 6, nft: 1 },
                { start: 11, end: 20, sum: 1200, voucher: 75, chests: 6, nft: 1 },
                { start: 11, end: 20, sum: 1200, voucher: 75, chests: 6, nft: 1 },
                { start: 11, end: 20, sum: 1200, voucher: 75, chests: 6, nft: 1 },
                { start: 11, end: 20, sum: 1200, voucher: 75, chests: 6, nft: 1 },
                { start: 11, end: 20, sum: 1200, voucher: 75, chests: 6, nft: 1 },
                { start: 21, end: 30, sum: 1000, voucher: 70, chests: 5, nft: 1 },
                { start: 21, end: 30, sum: 1000, voucher: 70, chests: 5, nft: 1 },
                { start: 21, end: 30, sum: 1000, voucher: 70, chests: 5, nft: 1 },
                { start: 21, end: 30, sum: 1000, voucher: 70, chests: 5, nft: 1 },
                { start: 21, end: 30, sum: 1000, voucher: 70, chests: 5, nft: 1 },
                { start: 21, end: 30, sum: 1000, voucher: 70, chests: 5, nft: 1 },
                { start: 21, end: 30, sum: 1000, voucher: 70, chests: 5, nft: 1 },
                { start: 21, end: 30, sum: 1000, voucher: 70, chests: 5, nft: 1 },
                { start: 21, end: 30, sum: 1000, voucher: 70, chests: 5, nft: 1 },
                { start: 21, end: 30, sum: 1000, voucher: 70, chests: 5, nft: 1 },
                { start: 31, end: 40, sum: 850, voucher: 65, chests: 4, nft: 1 },
                { start: 31, end: 40, sum: 850, voucher: 65, chests: 4, nft: 1 },
                { start: 31, end: 40, sum: 850, voucher: 65, chests: 4, nft: 1 },
                { start: 31, end: 40, sum: 850, voucher: 65, chests: 4, nft: 1 },
                { start: 31, end: 40, sum: 850, voucher: 65, chests: 4, nft: 1 },
                { start: 31, end: 40, sum: 850, voucher: 65, chests: 4, nft: 1 },
                { start: 31, end: 40, sum: 850, voucher: 65, chests: 4, nft: 1 },
                { start: 31, end: 40, sum: 850, voucher: 65, chests: 4, nft: 1 },
                { start: 31, end: 40, sum: 850, voucher: 65, chests: 4, nft: 1 },
                { start: 31, end: 40, sum: 850, voucher: 65, chests: 4, nft: 1 },
                { start: 41, end: 50, sum: 750, voucher: 60, chests: 3, nft: 1 },
                { start: 41, end: 50, sum: 750, voucher: 60, chests: 3, nft: 1 },
                { start: 41, end: 50, sum: 750, voucher: 60, chests: 3, nft: 1 },
                { start: 41, end: 50, sum: 750, voucher: 60, chests: 3, nft: 1 },
                { start: 41, end: 50, sum: 750, voucher: 60, chests: 3, nft: 1 },
                { start: 41, end: 50, sum: 750, voucher: 60, chests: 3, nft: 1 },
                { start: 41, end: 50, sum: 750, voucher: 60, chests: 3, nft: 1 },
                { start: 41, end: 50, sum: 750, voucher: 60, chests: 3, nft: 1 },
                { start: 41, end: 50, sum: 750, voucher: 60, chests: 3, nft: 1 },
                { start: 41, end: 50, sum: 750, voucher: 60, chests: 3, nft: 1 },
                { start: 51, end: 60, sum: 600, voucher: 55, chests: 2, nft: 1 },
                { start: 51, end: 60, sum: 600, voucher: 55, chests: 2, nft: 1 },
                { start: 51, end: 60, sum: 600, voucher: 55, chests: 2, nft: 1 },
                { start: 51, end: 60, sum: 600, voucher: 55, chests: 2, nft: 1 },
                { start: 51, end: 60, sum: 600, voucher: 55, chests: 2, nft: 1 },
                { start: 51, end: 60, sum: 600, voucher: 55, chests: 2, nft: 1 },
                { start: 51, end: 60, sum: 600, voucher: 55, chests: 2, nft: 1 },
                { start: 51, end: 60, sum: 600, voucher: 55, chests: 2, nft: 1 },
                { start: 51, end: 60, sum: 600, voucher: 55, chests: 2, nft: 1 },
                { start: 51, end: 60, sum: 600, voucher: 55, chests: 2, nft: 1 },
                { start: 61, end: 70, sum: 500, voucher: 50, chests: 2, nft: 1 },
                { start: 61, end: 70, sum: 500, voucher: 50, chests: 2, nft: 1 },
                { start: 61, end: 70, sum: 500, voucher: 50, chests: 2, nft: 1 },
                { start: 61, end: 70, sum: 500, voucher: 50, chests: 2, nft: 1 },
                { start: 61, end: 70, sum: 500, voucher: 50, chests: 2, nft: 1 },
                { start: 61, end: 70, sum: 500, voucher: 50, chests: 2, nft: 1 },
                { start: 61, end: 70, sum: 500, voucher: 50, chests: 2, nft: 1 },
                { start: 61, end: 70, sum: 500, voucher: 50, chests: 2, nft: 1 },
                { start: 61, end: 70, sum: 500, voucher: 50, chests: 2, nft: 1 },
                { start: 61, end: 70, sum: 500, voucher: 50, chests: 2, nft: 1 },
                { start: 71, end: 80, sum: 400, voucher: 45, chests: 1, nft: 1 },
                { start: 71, end: 80, sum: 400, voucher: 45, chests: 1, nft: 1 },
                { start: 71, end: 80, sum: 400, voucher: 45, chests: 1, nft: 1 },
                { start: 71, end: 80, sum: 400, voucher: 45, chests: 1, nft: 1 },
                { start: 71, end: 80, sum: 400, voucher: 45, chests: 1, nft: 1 },
                { start: 71, end: 80, sum: 400, voucher: 45, chests: 1, nft: 1 },
                { start: 71, end: 80, sum: 400, voucher: 45, chests: 1, nft: 1 },
                { start: 71, end: 80, sum: 400, voucher: 45, chests: 1, nft: 1 },
                { start: 71, end: 80, sum: 400, voucher: 45, chests: 1, nft: 1 },
                { start: 71, end: 80, sum: 400, voucher: 45, chests: 1, nft: 1 },
                { start: 81, end: 90, sum: 350, voucher: 40, chests: 1, nft: 1 },
                { start: 81, end: 90, sum: 350, voucher: 40, chests: 1, nft: 1 },
                { start: 81, end: 90, sum: 350, voucher: 40, chests: 1, nft: 1 },
                { start: 81, end: 90, sum: 350, voucher: 40, chests: 1, nft: 1 },
                { start: 81, end: 90, sum: 350, voucher: 40, chests: 1, nft: 1 },
                { start: 81, end: 90, sum: 350, voucher: 40, chests: 1, nft: 1 },
                { start: 81, end: 90, sum: 350, voucher: 40, chests: 1, nft: 1 },
                { start: 81, end: 90, sum: 350, voucher: 40, chests: 1, nft: 1 },
                { start: 81, end: 90, sum: 350, voucher: 40, chests: 1, nft: 1 },
                { start: 81, end: 90, sum: 350, voucher: 40, chests: 1, nft: 1 },
                { start: 91, end: 100, sum: 310, voucher: 35, chests: 1, nft: 1 },
                { start: 91, end: 100, sum: 310, voucher: 35, chests: 1, nft: 1 },
                { start: 91, end: 100, sum: 310, voucher: 35, chests: 1, nft: 1 },
                { start: 91, end: 100, sum: 310, voucher: 35, chests: 1, nft: 1 },
                { start: 91, end: 100, sum: 310, voucher: 35, chests: 1, nft: 1 },
                { start: 91, end: 100, sum: 310, voucher: 35, chests: 1, nft: 1 },
                { start: 91, end: 100, sum: 310, voucher: 35, chests: 1, nft: 1 },
                { start: 91, end: 100, sum: 310, voucher: 35, chests: 1, nft: 1 },
                { start: 91, end: 100, sum: 310, voucher: 35, chests: 1, nft: 1 },
                { start: 91, end: 100, sum: 310, voucher: 35, chests: 1, nft: 1 },
                { start: 101, end: 110, sum: 280, voucher: 30, chests: null, nft: 1 },
                { start: 101, end: 110, sum: 280, voucher: 30, chests: null, nft: 1 },
                { start: 101, end: 110, sum: 280, voucher: 30, chests: null, nft: 1 },
                { start: 101, end: 110, sum: 280, voucher: 30, chests: null, nft: 1 },
                { start: 101, end: 110, sum: 280, voucher: 30, chests: null, nft: 1 },
                { start: 101, end: 110, sum: 280, voucher: 30, chests: null, nft: 1 },
                { start: 101, end: 110, sum: 280, voucher: 30, chests: null, nft: 1 },
                { start: 101, end: 110, sum: 280, voucher: 30, chests: null, nft: 1 },
                { start: 101, end: 110, sum: 280, voucher: 30, chests: null, nft: 1 },
                { start: 101, end: 110, sum: 280, voucher: 30, chests: null, nft: 1 },
                { start: 111, end: 120, sum: 250, voucher: 25, chests: null, nft: 1 },
                { start: 111, end: 120, sum: 250, voucher: 25, chests: null, nft: 1 },
                { start: 111, end: 120, sum: 250, voucher: 25, chests: null, nft: 1 },
                { start: 111, end: 120, sum: 250, voucher: 25, chests: null, nft: 1 },
                { start: 111, end: 120, sum: 250, voucher: 25, chests: null, nft: 1 },
                { start: 111, end: 120, sum: 250, voucher: 25, chests: null, nft: 1 },
                { start: 111, end: 120, sum: 250, voucher: 25, chests: null, nft: 1 },
                { start: 111, end: 120, sum: 250, voucher: 25, chests: null, nft: 1 },
                { start: 111, end: 120, sum: 250, voucher: 25, chests: null, nft: 1 },
                { start: 111, end: 120, sum: 250, voucher: 25, chests: null, nft: 1 },
                { start: 121, end: 130, sum: 220, voucher: 20, chests: null, nft: 1 },
                { start: 121, end: 130, sum: 220, voucher: 20, chests: null, nft: 1 },
                { start: 121, end: 130, sum: 220, voucher: 20, chests: null, nft: 1 },
                { start: 121, end: 130, sum: 220, voucher: 20, chests: null, nft: 1 },
                { start: 121, end: 130, sum: 220, voucher: 20, chests: null, nft: 1 },
                { start: 121, end: 130, sum: 220, voucher: 20, chests: null, nft: 1 },
                { start: 121, end: 130, sum: 220, voucher: 20, chests: null, nft: 1 },
                { start: 121, end: 130, sum: 220, voucher: 20, chests: null, nft: 1 },
                { start: 121, end: 130, sum: 220, voucher: 20, chests: null, nft: 1 },
                { start: 121, end: 130, sum: 220, voucher: 20, chests: null, nft: 1 },
                { start: 131, end: 140, sum: 190, voucher: 20, chests: null, nft: 1 },
                { start: 131, end: 140, sum: 190, voucher: 20, chests: null, nft: 1 },
                { start: 131, end: 140, sum: 190, voucher: 20, chests: null, nft: 1 },
                { start: 131, end: 140, sum: 190, voucher: 20, chests: null, nft: 1 },
                { start: 131, end: 140, sum: 190, voucher: 20, chests: null, nft: 1 },
                { start: 131, end: 140, sum: 190, voucher: 20, chests: null, nft: 1 },
                { start: 131, end: 140, sum: 190, voucher: 20, chests: null, nft: 1 },
                { start: 131, end: 140, sum: 190, voucher: 20, chests: null, nft: 1 },
                { start: 131, end: 140, sum: 190, voucher: 20, chests: null, nft: 1 },
                { start: 131, end: 140, sum: 190, voucher: 20, chests: null, nft: 1 },
                { start: 141, end: 150, sum: 150, voucher: 20, chests: null, nft: 1 },
                { start: 141, end: 150, sum: 150, voucher: 20, chests: null, nft: 1 },
                { start: 141, end: 150, sum: 150, voucher: 20, chests: null, nft: 1 },
                { start: 141, end: 150, sum: 150, voucher: 20, chests: null, nft: 1 },
                { start: 141, end: 150, sum: 150, voucher: 20, chests: null, nft: 1 },
                { start: 141, end: 150, sum: 150, voucher: 20, chests: null, nft: 1 },
                { start: 141, end: 150, sum: 150, voucher: 20, chests: null, nft: 1 },
                { start: 141, end: 150, sum: 150, voucher: 20, chests: null, nft: 1 },
                { start: 141, end: 150, sum: 150, voucher: 20, chests: null, nft: 1 },
                { start: 141, end: 150, sum: 150, voucher: 20, chests: null, nft: 1 },
                { start: 151, end: 160, sum: 130, voucher: 20, chests: null, nft: 1 },
                { start: 151, end: 160, sum: 130, voucher: 20, chests: null, nft: 1 },
                { start: 151, end: 160, sum: 130, voucher: 20, chests: null, nft: 1 },
                { start: 151, end: 160, sum: 130, voucher: 20, chests: null, nft: 1 },
                { start: 151, end: 160, sum: 130, voucher: 20, chests: null, nft: 1 },
                { start: 151, end: 160, sum: 130, voucher: 20, chests: null, nft: 1 },
                { start: 151, end: 160, sum: 130, voucher: 20, chests: null, nft: 1 },
                { start: 151, end: 160, sum: 130, voucher: 20, chests: null, nft: 1 },
                { start: 151, end: 160, sum: 130, voucher: 20, chests: null, nft: 1 },
                { start: 151, end: 160, sum: 130, voucher: 20, chests: null, nft: 1 },
                { start: 161, end: 170, sum: 90, voucher: 20, chests: null, nft: 1 },
                { start: 161, end: 170, sum: 90, voucher: 20, chests: null, nft: 1 },
                { start: 161, end: 170, sum: 90, voucher: 20, chests: null, nft: 1 },
                { start: 161, end: 170, sum: 90, voucher: 20, chests: null, nft: 1 },
                { start: 161, end: 170, sum: 90, voucher: 20, chests: null, nft: 1 },
                { start: 161, end: 170, sum: 90, voucher: 20, chests: null, nft: 1 },
                { start: 161, end: 170, sum: 90, voucher: 20, chests: null, nft: 1 },
                { start: 161, end: 170, sum: 90, voucher: 20, chests: null, nft: 1 },
                { start: 161, end: 170, sum: 90, voucher: 20, chests: null, nft: 1 },
                { start: 161, end: 170, sum: 90, voucher: 20, chests: null, nft: 1 },
                { start: 171, end: 180, sum: 60, voucher: 20, chests: null, nft: 1 },
                { start: 171, end: 180, sum: 60, voucher: 20, chests: null, nft: 1 },
                { start: 171, end: 180, sum: 60, voucher: 20, chests: null, nft: 1 },
                { start: 171, end: 180, sum: 60, voucher: 20, chests: null, nft: 1 },
                { start: 171, end: 180, sum: 60, voucher: 20, chests: null, nft: 1 },
                { start: 171, end: 180, sum: 60, voucher: 20, chests: null, nft: 1 },
                { start: 171, end: 180, sum: 60, voucher: 20, chests: null, nft: 1 },
                { start: 171, end: 180, sum: 60, voucher: 20, chests: null, nft: 1 },
                { start: 171, end: 180, sum: 60, voucher: 20, chests: null, nft: 1 },
                { start: 171, end: 180, sum: 60, voucher: 20, chests: null, nft: 1 },
                { start: 181, end: 190, sum: 30, voucher: 20, chests: null, nft: 1 },
                { start: 181, end: 190, sum: 30, voucher: 20, chests: null, nft: 1 },
                { start: 181, end: 190, sum: 30, voucher: 20, chests: null, nft: 1 },
                { start: 181, end: 190, sum: 30, voucher: 20, chests: null, nft: 1 },
                { start: 181, end: 190, sum: 30, voucher: 20, chests: null, nft: 1 },
                { start: 181, end: 190, sum: 30, voucher: 20, chests: null, nft: 1 },
                { start: 181, end: 190, sum: 30, voucher: 20, chests: null, nft: 1 },
                { start: 181, end: 190, sum: 30, voucher: 20, chests: null, nft: 1 },
                { start: 181, end: 190, sum: 30, voucher: 20, chests: null, nft: 1 },
                { start: 181, end: 190, sum: 30, voucher: 20, chests: null, nft: 1 },
                { start: 191, end: 200, sum: 20, voucher: 20, chests: null, nft: 1 },
                { start: 191, end: 200, sum: 20, voucher: 20, chests: null, nft: 1 },
                { start: 191, end: 200, sum: 20, voucher: 20, chests: null, nft: 1 },
                { start: 191, end: 200, sum: 20, voucher: 20, chests: null, nft: 1 },
                { start: 191, end: 200, sum: 20, voucher: 20, chests: null, nft: 1 },
                { start: 191, end: 200, sum: 20, voucher: 20, chests: null, nft: 1 },
                { start: 191, end: 200, sum: 20, voucher: 20, chests: null, nft: 1 },
                { start: 191, end: 200, sum: 20, voucher: 20, chests: null, nft: 1 },
                { start: 191, end: 200, sum: 20, voucher: 20, chests: null, nft: 1 },
                { start: 191, end: 200, sum: 20, voucher: 20, chests: null, nft: 1 }

            ]
        };
    }

    componentDidMount() {
        this.userCheck();
    }

    async userCheck() {
        try {
            //console.log(555555555555555555555555555555555555555555555);
            //const activeUser = this.props.ual.activeUser;
            //const accountName = await activeUser.getAccountName();
            //console.log(activeUser);
            //console.log(accountName);
            var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);
            const results = await rpcc.get_table_rows({
                json: true,
                code: process.env.REACT_APP_CONTRACT, // contract who owns the table
                scope: process.env.REACT_APP_CONTRACT, // scope of the table
                // code: "nftpandawofg", // contract who owns the table
                // scope: "nftpandawofg", // scope of the table
                table: "alientop", // name of the table as specified by the contract abi
                limit: 300,
                key_type: "i64",
                reverse: !0,
                show_payer: false,
                index_position: 2,
            });
            const originRowUser = results.rows;

            this.setState({
                formInfo: originRowUser,
            });

            //this.userCheckChit();
        } catch (e) {
            //console.log(e.message)
            console.warn(e);
            this.setState({
                hasError: true,
                errorText: e.message,
            });
            console.log("\nCaught exception: " + e);
        }
    }

    render() {
        return (
            <>
                <Menu  {...this.props.ual} wax={this.props.balanceWax} bam={this.props.balanceBam} game={this.props.balanceGame} updateBalance={this.props.updateBalance} />
                <main className="slot-page alien-festivas">
                    <section id="leader-alien">
                        <div className="leader-alien">
                            <div className="leader-alien-top">
                                <div className="leader-alien-logo">
                                    <picture>
                                        <source srcSet={alienlogow} type="image/webp" />
                                        <source srcSet={alienlogo} type="image/png" />
                                        <img src={alienlogow} alt="" />
                                    </picture>
                                </div>
                                <div className="leader-alien-tit">
                                    <picture>
                                        <source srcSet={titw} type="image/webp" />
                                        <source srcSet={tit} type="image/png" />
                                        <img src={titw} alt="" />
                                    </picture>
                                </div>

                                <LiderDescr classN={"desc"} />
                            </div>
                            <div className="leader-alien-mid">
                                <LiderDescr classN={"mob"} />

                                <AlienLeaderboard
                                    name={this.props.ual.activeUser.accountName}
                                    tableInfo={this.state.formInfo}
                                    priseList={this.state.priseList}
                                />
                            </div>
                            <div className="leader-alien-bot"></div>

                        </div>
                    </section>

                    <footer>
                        <div className="footer-nav">
                            <nav>
                                <ul>
                                    <li>
                                        <a
                                            className="foot-logo-1"
                                            target="_blank"
                                            href="https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&sort=created&symbol=WAX"
                                        >
                                            <span></span>
                                            <i>Market</i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="foot-logo-2"
                                            target="_blank"
                                            href="https://twitter.com/NftPanda/"
                                        >
                                            <span></span>
                                            <i>Twitter</i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="foot-logo-3"
                                            target="_blank"
                                            href="https://www.instagram.com/nftpanda/"
                                        >
                                            <span></span>
                                            <i>Instagram</i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="foot-logo-4"
                                            target="_blank"
                                            href="https://discord.gg/vCSgme4d5b"
                                        >
                                            <span></span>
                                            <i>Discord</i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="foot-logo-5"
                                            target="_blank"
                                            href={`https://wax.alcor.exchange/swap?output=${process.env.REACT_APP_VAL}-nftpandabamb&input=WAX-eosio.token`}
                                        >
                                            <span></span>
                                            <i>{process.env.REACT_APP_VAL} Trade</i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="foot-logo-6"
                                            target="_blank"
                                            href="https://discord.gg/vCSgme4d5b"
                                        >
                                            <span></span>
                                            <i>Support</i>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </footer>
                </main>
            </>
        );
    }
}

let mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(AlienLeaderboardComponent);
