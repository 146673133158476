import React, { useState, useEffect } from 'react';
import ResultDetal from "./ResultDetal";
import "./alienAdvStyle.scss";
import PandaVideoComp from './../PandaVideoComp';
import {
    /*BrowserRouter, Redirect, Route, Switch,*/ Link,
    NavLink,
} from "react-router-dom";

import rewtop from '../../images/alien/result-top.png';
import rewtopw from '../../images/alien/result-top.webp';
import rewbot from '../../images/new-adv/multi-reward-bot.png';
import rewbotw from '../../images/new-adv/multi-reward-bot.webp';

import noPotion from '../../images/shop/potions/space-power-no-potion.jpg';
import noPotionw from '../../images/shop/potions/space-power-no-potion.webp';
import bigPotion from '../../images/shop/potions/space-power-potion.jpg';
import bigPotionw from '../../images/shop/potions/space-power-potion.webp';
import minPotion from '../../images/shop/potions/small-space-power-potion.jpg';
import minPotionw from '../../images/shop/potions/small-space-power-potion.webp';

import plus from '../../images/alien/lvlup-plus.png';
import plusw from '../../images/alien/lvlup-plus.webp';
import lvlup from '../../images/alien/lvlup-ico.png';
import lvlupw from '../../images/alien/lvlup-ico.webp';
import bigpotion from '../../images/alien/big-potion.png';
import bigpotionw from '../../images/alien/big-potion.webp';

import good from '../../images/alien/res-good.png';
import goodw from '../../images/alien/res-good.webp';
import bad from '../../images/alien/res-bad.png';
import badw from '../../images/alien/res-bad.webp';

const SharpModal = (props) => {

    var onePandaName;
    var cardRare;
    var imgRare;
    var nameRarePanda;
    if (props.sharpModalInfo.type === "panda") {
        onePandaName = props.sharpModalInfo.hero.name_pa;
        onePandaName = onePandaName.substring(onePandaName.indexOf("-") + 1)
        var pandaRar = props.sharpModalInfo.hero.rarity;

        nameRarePanda = '';
        switch (pandaRar) {
            case 0:
                nameRarePanda = "Common"
                break;
            case 1:
                nameRarePanda = "Promo"
                break;
            case 2:
                nameRarePanda = "Uncommon"
                break;
            case 3:
                nameRarePanda = "Rare"
                break;
            case 4:
                nameRarePanda = "Epic"
                break;
            case 5:
                nameRarePanda = "Legendary"
                break;
            case 6:
                nameRarePanda = "Mythic"
                break;
            default:
                nameRarePanda = "NOBADY"
                break;
        }

        if (onePandaName.substring(0, 1) == "A") {
            cardRare = "serial-1-"
        } else if (onePandaName.substring(0, 1) == "N") {
            cardRare = "serial-2-"
        } else if (onePandaName.substring(0, 1) == "P") {
            cardRare = "serial-3-"
        }

        imgRare = nameRarePanda.toLowerCase();
        if (imgRare == "promo") {
            imgRare = "common";
        }
    }
    var lvlStatus = [
        { success: "80%", bonus: "3%" },
        { success: "75%", bonus: "7%" },
        { success: "70%", bonus: "12%" },
        { success: "65%", bonus: "19%" },
        { success: "60%", bonus: "28%" },
        { success: "55%", bonus: "39%" },
        { success: "50%", bonus: "52%" },
        { success: "45%", bonus: "66%" },
        { success: "40%", bonus: "82%" },
        { success: "35%", bonus: "100%" },
        { success: "max", bonus: "max" }
    ]

    console.log("props", props)


    return (<>
        <div className={`modal-to-adv reward-window ${false ? "" : "open"} anim alien-rew`}>
            <div className="modal-to-adv-body">
                {props.sharpModalInfo.resultOpen ? null :
                    <div className={`modal-to-adv-body-in sharp-modal-body`}>
                        <div className={`modal-go-to-adv`}>
                            <div className="mdl-gtav-top">
                                <picture>
                                    <source srcSet={rewtopw} type="image/webp" />
                                    <source srcSet={rewtop} type="image/png" />
                                    <img src={rewtop} alt="" />
                                </picture>
                                <div className="close-mult-rew" onClick={() => props.openSharpModal(false)}>
                                    <span></span>
                                </div>
                                <div className="sharp-modal-tit">
                                    sharpening
                                </div>
                            </div>

                            <div className="mdl-gtav-mid">
                                <div className="mdl-gtav-mid-grey">
                                    <div className="sharp-modal-in">
                                        <div className="sharp-modal-cards">
                                            {props.sharpModalInfo.type === "alien" ?
                                                <div className="sharp-modal-left-card alien">
                                                    <div className={`alien-thumb`}>

                                                        <span className='hasnft'>
                                                            <div className="alien-info tooltip">
                                                                <div className="cust-tooltip right">
                                                                    <div className="tool-arrow"></div>
                                                                    <div className="tool-wrap">
                                                                        <div className="tool-wrap-1">
                                                                            <div className="tool-wrap-2">
                                                                                <div className="tool-body">
                                                                                    <span>ASSET ID: </span>

                                                                                    {props.sharpModalInfo.hero.asset_id} <br />
                                                                                    <span>Rarity: </span>
                                                                                    {/* {alienRarName} */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <picture>
                                                                <source srcSet={`/images/alien/all/${props.sharpModalInfo.hero.tmpl}.gif`} type="image/gif" />
                                                                <source srcSet={`/images/alien/all/${props.sharpModalInfo.hero.tmpl}.gif`} type="image/gif" />
                                                                <img src={`/images/alien/all/${props.sharpModalInfo.hero.tmpl}.gif`} alt="" />
                                                            </picture>

                                                            <div className="sharp-card tooltip">
                                                                <div className="sharp-plus">
                                                                    <picture>
                                                                        <source srcSet={plusw} type="image/webp" />
                                                                        <source srcSet={plus} type="image/png" />
                                                                        <img src={plusw} alt="" />
                                                                    </picture>
                                                                </div>
                                                                <div className="sharp-ico">
                                                                    <picture>
                                                                        <source srcSet={lvlupw} type="image/webp" />
                                                                        <source srcSet={lvlup} type="image/png" />
                                                                        <img src={lvlupw} alt="" />
                                                                    </picture>
                                                                </div>
                                                                <div className="sharp-text">
                                                                    <span>{props.sharpModalInfo.lvlNumber}</span>
                                                                    <i>lvl</i>
                                                                </div>
                                                                <div className="cust-tooltip bottom">
                                                                    <div className="tool-arrow"></div>
                                                                    <div className="tool-wrap">
                                                                        <div className="tool-wrap-1">
                                                                            <div className="tool-wrap-2">
                                                                                <div className="tool-body">

                                                                                    <span>lvl: {props.sharpModalInfo.lvlNumber}</span>
                                                                                    <br />
                                                                                    <span>Production: +12%</span>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                                :
                                                <div className="sharp-modal-left-card panda">
                                                    <div className={`alien-thumb`}>
                                                        <span className='hasnft'>
                                                            <div className="alien-info tooltip">
                                                                <div className="cust-tooltip right">
                                                                    <div className="tool-arrow"></div>
                                                                    <div className="tool-wrap">
                                                                        <div className="tool-wrap-1">
                                                                            <div className="tool-wrap-2">
                                                                                <div className="tool-body">
                                                                                    <span>ASSET ID: </span>
                                                                                    {props.sharpModalInfo.hero.asset_id} <br />
                                                                                    <span>Rarity: </span>
                                                                                    {/* {nameRarePanda} */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <picture>
                                                                <source srcSet={`./images/card-1x/${props.sharpModalInfo.hero.name_pa}.webp`} type="image/webp" />
                                                                <source srcSet={`./images/card-1x/${props.sharpModalInfo.hero.name_pa}.png`} type="image/png" />
                                                                <img src={`./images/card-1x/${props.sharpModalInfo.hero.name_pa}.png`} alt="" />
                                                            </picture>
                                                            <div className="sharp-card tooltip">
                                                                <div className="sharp-plus">
                                                                    <picture>
                                                                        <source srcSet={plusw} type="image/webp" />
                                                                        <source srcSet={plus} type="image/png" />
                                                                        <img src={plusw} alt="" />
                                                                    </picture>
                                                                </div>
                                                                <div className="sharp-ico">
                                                                    <picture>
                                                                        <source srcSet={lvlupw} type="image/webp" />
                                                                        <source srcSet={lvlup} type="image/png" />
                                                                        <img src={lvlupw} alt="" />
                                                                    </picture>
                                                                </div>
                                                                <div className="sharp-text">
                                                                    <span>{props.sharpModalInfo.lvlNumber}</span>
                                                                    <i>lvl</i>
                                                                </div>
                                                                <div className="cust-tooltip bottom">
                                                                    <div className="tool-arrow"></div>
                                                                    <div className="tool-wrap">
                                                                        <div className="tool-wrap-1">
                                                                            <div className="tool-wrap-2">
                                                                                <div className="tool-body">

                                                                                    <span>lvl: {props.sharpModalInfo.lvlNumber}</span>
                                                                                    <br />
                                                                                    <span>Production: +12%</span>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                            }

                                            <div className={`sharp-modal-plus ${props.allAlienRes.res[4] ? "active" : "def"}`}></div>

                                            <div className="sharp-modal-right-card">
                                                <div className={`big-potion ${props.allAlienRes.res[4] !== 0 ? "act" : "dis"}`}>
                                                    <picture>
                                                        <source srcSet={bigpotionw} type="image/webp" />
                                                        <source srcSet={bigpotion} type="image/png" />
                                                        <img src={bigpotionw} alt="" />
                                                    </picture>
                                                    <div className="alien-info tooltip">
                                                        <div className="cust-tooltip right">
                                                            <div className="tool-arrow"></div>
                                                            <div className="tool-wrap">
                                                                <div className="tool-wrap-1">
                                                                    <div className="tool-wrap-2">
                                                                        <div className="tool-body">
                                                                            <span>ASSET ID: </span>

                                                                            {props.sharpModalInfo.hero.asset_id} <br />
                                                                            <span>Rarity: </span>
                                                                            {/* {alienRarName} */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="num-potion">
                                                        {props.allAlienRes.res[4]}X
                                                    </div>

                                                    <NavLink
                                                        className="def-bt mini"
                                                        to="/shop"
                                                    >

                                                        <span className="button-in">
                                                            <span className="button-name">
                                                                <span className="button-name-in">
                                                                    <span className="button-name-value">
                                                                        buy
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="sharp-modal-descr">
                                            <div className="sharp-modal-next">
                                                <div className="sharp-modal-next-in">

                                                    Next level:
                                                    <span>
                                                        {props.sharpModalInfo.lvlNumber + 1} (Power: <i>+{lvlStatus[props.sharpModalInfo.lvlNumber].bonus}</i>)
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="sharp-modal-chance">
                                                {props.sharpModalInfo.lvlNumber < 10 ?
                                                    <div className="sharp-modal-chance-top">
                                                        <span>
                                                            {lvlStatus[props.sharpModalInfo.lvlNumber].success}
                                                        </span>
                                                        chance of success
                                                    </div>
                                                    : null}
                                                <div className="sharp-modal-chance-bot">
                                                    if you fail, you will go down to <span>lvl {props.sharpModalInfo.lvlNumber !== 0 ? props.sharpModalInfo.lvlNumber - 1 : 0}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mdl-gtav-bot">
                                <picture>
                                    <source srcSet={rewbotw} type="image/webp" />
                                    <source srcSet={rewbot} type="image/png" />
                                    <img src={rewbot} alt="" />
                                </picture>
                                <div className="sharp-bt">
                                    {props.allAlienRes.res[4] && props.sharpModalInfo.lvlNumber < 10 ?
                                        <button className="def-bt norm" onClick={(e) => {
                                            props.sharpTrans(props.sharpModalInfo.hero.asset_id, props.sharpModalInfo.index, props.sharpModalInfo.prevTimer, props.sharpModalInfo.lvlNumber);
                                        }}>
                                            <span className="button-in">
                                                <span className="button-name">
                                                    <span className="button-name-in">
                                                        <span className="button-name-value">
                                                            Sharp
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </button>
                                        :
                                        <button className="def-bt norm disabled" >
                                            <span className="button-in">
                                                <span className="button-name">
                                                    <span className="button-name-in">
                                                        <span className="button-name-value">
                                                            Sharp
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </button>
                                    }
                                </div>
                            </div>


                        </div>


                    </div>
                }
                {props.sharpModalInfo.resultOpen ?
                    <div className="sharp-res" onClick={() => props.openCloseResulSharp(false, props.sharpModalInfo.result)}>
                        <div className="sharp-res-in">
                            <div className="sharp-res-thumb">
                                {props.sharpModalInfo.result === "good" ?
                                    <picture>
                                        <source srcSet={goodw} type="image/webp" />
                                        <source srcSet={good} type="image/png" />
                                        <img src={goodw} alt="" />
                                    </picture>
                                    :
                                    <picture>
                                        <source srcSet={badw} type="image/webp" />
                                        <source srcSet={bad} type="image/png" />
                                        <img src={badw} alt="" />
                                    </picture>
                                }
                            </div>

                        </div>

                    </div>
                    : null
                }

                <div className="modal-weapon-bg-trans"></div>
            </div>

            <div className="modal-weapon-bg" ></div>
        </div>
    </>);

}

export default SharpModal;