const WAS_LOGIN = 'WAS_LOGIN';
const MODAL_AFTER_LOGIN = 'MODAL_AFTER_LOGIN';

let initialState = {
    wasLogin: false,
    showModalAfterLogin: false
};

const wasLoginReducer = (state = initialState, action) => {

    switch(action.type) {
        case WAS_LOGIN: {
            return {...state, wasLogin: action.wasLogin}
        }
        case MODAL_AFTER_LOGIN: {
            return {...state, showModalAfterLogin: action.showModalAfterLogin}
        }
        default:
            return state;
    }
}


export const setWasLogin = (wasLogin) => ({type: WAS_LOGIN, wasLogin})
export const setModalAfterLogin = (showModalAfterLogin) => ({type: MODAL_AFTER_LOGIN, showModalAfterLogin})

export default wasLoginReducer;
