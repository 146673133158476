import React, { useEffect, useRef, useState } from 'react';
import p5 from 'p5';
import Menu from '../../components/Menu';
import ModalThank from "./ModalThank";
import { ExplorerApi } from "atomicassets";
import { useHistory } from 'react-router-dom';
import { JsonRpc } from "eosjs";
import ErrorComponent from "../Error/ErrorComponent";
import HeroList from './HeroList';
import { detalPanda } from '../PandaNameInfo';
import useGetAllSkillBooks from './../Get/useGetAllSkillBooks'
import { learnSkills } from './../Get/Transactions';
import useGetAllNftOnWallet from './../Get/useGetAllNftOnWallet';

import "./skills.scss";
import font from './../../fonts/troika.otf'

import importBgimg from './../../images/skills/bg.jpg';
import importBookimg from './../../images/skills/book.webp';
import importBtbgskill1 from './../../images/skills/skill-bg-1.png';
import importBtbgskill2 from './../../images/skills/skill-bg-2.png';
import importBtbgskill3 from './../../images/skills/skill-bg-3.png';

import importBookTit from './../../images/skills/book-name.png';
import importBookBamb from './../../images/skills/bamboo.png';
import importBookSkillOrn from './../../images/skills/skill-ornament.png';

import importAboutSkillOrn from './../../images/skills/about-skill.png';

import importBookIco from './../../images/skills/needres/book.png';
import importResWood from './../../images/skills/needres/wood.png';
import importSecResSteel from './../../images/skills/needres/steel.png';
import importSecResKvessir from './../../images/skills/needres/kvessir.png';
import importSecResTitawin from './../../images/skills/needres/titawin.png';
import importSecResGold from './../../images/skills/needres/gold.png';
import importSecResDwemer from './../../images/skills/needres/dwemer.png';
import importResGems from './../../images/skills/needres/gem.png';
import importResBam from './../../images/skills/bam.png';
import importCheck from './../../images/skills/check.png';
import importSkillLearn from './../../images/skills/skill-learn.png';

import importLearnBt from './../../images/skills/bt-learn.png'

const fetch = require("node-fetch");
const api = new ExplorerApi(
    "https://" + localStorage.getItem('rpcA'),
    "atomicassets",
    { fetch, rateLimit: 4 }
);

const cardTransfer = {
    actions: [
        {
            account: "atomicassets",
            name: "transfer",
            authorization: [
                {
                    actor: "", // use account that was logged in
                    permission: "active",
                },
            ],
            data: {
                from: "",
                to: process.env.REACT_APP_CONTRACT,
                asset_ids: [],
                memo: "",
            },
        },
    ],
};

var numRpc = 0;
var arrRpc = ['wax.pink.gg', 'wax.cryptolions.io', 'wax.greymass.com', 'api.wax.alohaeos.com', 'api.waxsweden.org', 'wax.eosphere.io', 'api.wax.greeneosio.com', 'wax.eu.eosamsterdam.net', 'api.wax.bountyblok.io']

const caseClasInfo = (type) => {
    var pandaSkillsTitles = [];
    var pandaSkillsDescrs = [];
    switch (type) {
        case "warrior":
            pandaSkillsTitles = ["Power Blow", "Heroic Leap", "Сry of Insanity"]
            pandaSkillsDescrs = [
                "Basic Attack",
                ["3% damage melee attack", "3% damage melee attack", "5% damage melee attack", "7% damage melee attack", "9% damage melee attack", "12% damage melee attack", "15% damage melee attack"],
                ["5% damage melee attack", "5% damage melee attack", "10% damage melee attack", "15% damage melee attack", "20% damage melee attack", "25% damage melee attack", "30% damage melee attack"]
            ]
            break;
        case "berserker":
            pandaSkillsTitles = ["Beast Strike", "Сall to Fury", "In Name of Elgard"];
            pandaSkillsDescrs = [
                "Basic Attack",
                ["3% damage melee attack", "3% damage melee attack", "5% damage melee attack", "7% damage melee attack", "9% damage melee attack", "12% damage melee attack", "15% damage melee attack"],
                ["3% to attack to all heroes", "3% to attack to all heroes", "6% to attack to all heroes", "9% to attack to all heroes", "12% to attack to all heroes", "16% to attack to all heroes", "20% to attack to all heroes"]
            ]
            break;
        case "mage":
            pandaSkillsTitles = ["Elemental Strike", "Arcane Explosion", "Ball Lightning"];
            pandaSkillsDescrs = [
                "Basic Attack",
                ["3% damage magic attack", "3% damage magic attack", "5% damage magic attack", "7% damage magic attack", "9% damage magic attack", "12% damage magic attack", "15% damage magic attack"],
                ["20% damage magic attack", "20% damage magic attack", "30% damage magic attack", "40% damage magic attack", "50% damage magic attack", "60% damage magic attack", "70% damage magic attack"]
            ]
            break;
        case "archer":
            pandaSkillsTitles = ["Power Shot", "Dual Shot", "Piercing Shot"];
            pandaSkillsDescrs = [
                "Basic Attack",
                ["3% damage ranged attack", "3% damage ranged attack", "5% damage ranged attack", "7% damage ranged attack", "9% damage ranged attack", "12% damage ranged attack", "15% damage ranged attack"],
                ["20% damage ranged attack", "20% damage ranged attack", "30% damage ranged attack", "40% damage ranged attack", "50% damage ranged attack", "60% damage ranged attack", "70% damage ranged attack"]
            ]
            break;
        case "paladin":
            pandaSkillsTitles = ["Noble Strike", "Shield of Valor", "Shield of Elgard"];
            pandaSkillsDescrs = [
                "Basic Attack",
                ["Adds 5% armor to all heroes", "Adds 5% armor to all heroes", "Adds 8% armor to all heroes", "Adds 11% armor to all heroes", "Adds 14% armor to all heroes", "Adds 17% armor to all heroes", "Adds 20% armor to all heroes"],
                ["20% damage melee attack", "20% damage melee attack", "30% damage melee attack", "40% damage melee attack", "50% damage melee attack", "60% damage melee attack", "70% damage melee attack"]
            ]
            break;
        case "engineer":
            pandaSkillsTitles = ["Focus Fire", "Technical Upgrade", "Big Boom"];
            pandaSkillsDescrs = [
                "Basic Attack",
                ["Adds 1% to attack to all heroes", "Adds 1% to attack to all heroes", "Adds 2% to attack to all heroes", "Add 4% to attack to all heroes", "Add 6% to attack to all heroes", "Add 8% to attack to all heroes", "Add 10% to attack to all heroes"],
                ["20% damage ranged attack", "20% damage ranged attack", "30% damage ranged attack", "40% damage ranged attack", "50% damage ranged attack", "60% damage ranged attack", "70% damage ranged attack"]
            ]
            break;
        case "druid":
            pandaSkillsTitles = ["Shock Wave", "Nature Power", "Poison Splash"];
            pandaSkillsDescrs = [
                "Basic Attack",
                ["Adds 5% health to all heroes", "Adds 5% health to all heroes", "Adds 8% health to all heroes", "Adds 11% health to all heroes", "Adds 14% health to all heroes", "Adds 17% health to all heroes", "Adds 20% health to all heroes"],
                ["20% damage magic attack", "20% damage magic attack", "30% damage magic attack", "40% damage magic attack", "50% damage magic attack", "60% damage magic attack", "70% damage magic attack"]
            ]
            break;
        case "dreneya":
            pandaSkillsTitles = ["Ghost Strike", "Distortion Shield", "Space Distortion"];
            pandaSkillsDescrs = [
                "Basic Attack",
                ["Adds 10% magic defence to all heroes", "Adds 10% magic defence to all heroes", "Adds 16% magic defence to all heroes", "Adds 22% magic defence to all heroes", "Adds 28% magic defence to all heroes", "Adds 34% magic defence to all heroes", "Adds 40% magic defence to all heroes"],
                ["20% damage magic defense to all heroes", "20% damage magic defense to all heroes", "30% damage magic defense to all heroes", "40% damage magic defense to all heroes", "50% damage magic defense to all heroes", "60% damage magic defense to all heroes", "70% damage magic defense to all heroes"]
            ]
            break;
        case "bard":
            pandaSkillsTitles = ["True Strike", "Song of Encouragement", "Song of Origins"];
            pandaSkillsDescrs = [
                "Basic Attack",
                ["3% chance to double-attack", "3% chance to double-attack", "5% chance to double-attack", "7% chance to double-attack", "9% chance to double-attack", "12% chance to double-attack", "15% chance to double-attack"],
                ["5% chance to double-attack", "5% chance to double-attack", "10% chance to double-attack", "15% chance to double-attack", "20% chance to double-attack", "25% chance to double-attack", "30% chance to double-attack"]
            ]
            break;
        case "priest":
            pandaSkillsTitles = ["Spiritual Kick", "Rebirth", "Explosion of Harmony"];
            pandaSkillsDescrs = [
                "Basic Attack",
                ["Adds 5% armor to all heroes", "Adds 5% armor to all heroes", "Adds 8% armor to all heroes", "Adds 11% armor to all heroes", "Adds 14% armor to all heroes", "Adds 17% armor to all heroes", "Adds 20% armor to all heroes"],
                ["10% damage magic attack to all heroes", "10% damage magic attack to all heroes", "15% damage magic attack to all heroes", "20% damage magic attack to all heroes", "25% damage magic attack to all heroes", "32% damage magic attack to all heroes", "40% damage magic attack to all heroes"]
            ]
            break;
        case "smith":
            pandaSkillsTitles = ["Iron Strike", "Steel Shield", "Golden Shield"];
            pandaSkillsDescrs = [
                "Basic Attack",
                ["Adds 5% armor to all heroes", "Adds 5% armor to all heroes", "Adds 8% armor to all heroes", "Adds 11% armor to all heroes", "Adds 14% armor to all heroes", "Adds 17% armor to all heroes", "Adds 20% armor to all heroes"],
                ["Adding 10% armor to all heroes", "Adding 10% armor to all heroes", "Adding 15% armor to all heroes", "Adding 20% armor to all heroes", "Adding 25% armor to all heroes", "Adding 32% armor to all heroes", "Adding 40% armor to all heroes"]
            ]
            break;
        case "healer":
            pandaSkillsTitles = ["Fever", "Mass Healing", "Phoenix Feather"];
            pandaSkillsDescrs = [
                "Basic Attack",
                ["Restores 5% health of all heroes", "Restores 5% health of all heroes", "Restores 10% health of all heroes", "Restores 15% health of all heroes", "Restores 20% health of all heroes", "Restores 25% health of all heroes", "Restores 30% health of all heroes"],
                ["Resurrects one common hero", "Resurrects one common hero", "Resurrects one uncommon hero or less", "Resurrects one rare hero or less", "Resurrects one epichero or less", "Resurrects one legendary hero or less", "Resurrects one mythic hero or less"]
            ]
            break;
        case "shooter":
            pandaSkillsTitles = ["Aimed Shot", "Triple Shot", "Critical Shot"];
            pandaSkillsDescrs = [
                "Basic Attack",
                ["10% damage ranged attack", "10% damage ranged attack", "15% damage ranged attack", "20% damage ranged attack", "25% damage ranged attack", "32% damage ranged attack", "40% damage ranged attack"],
                ["10% chance for x3 crit", "10% chance for x3 crit", "15% chance for x3 crit", "20% chance for x3 crit", "25% chance for x3 crit", "32% chance for x3 crit", "40% chance for x3 crit"]
            ]
            break;
        case "tauren":
            pandaSkillsTitles = ["Wrath Strike", "Triple Chop", "Storm of Destruction"];
            pandaSkillsDescrs = [
                "Basic Attack",
                ["10% damage melee attack", "10% damage melee attack", "15% damage melee attack", "20% damage melee attack", "25% damage melee attack", "32% damage melee attack", "40% damage melee attack"],
                ["20% damage melee attack", "20% damage melee attack", "30% damage melee attack", "40% damage melee attack", "50% damage melee attack", "60% damage melee attack", "70% damage melee attack"]
            ]
            break;
        case "elder":
            pandaSkillsTitles = ["Spiritual Shot", "Сall of Sage", "Touch of Ancients"];
            pandaSkillsDescrs = [
                "Basic Attack",
                ["1% to attack to all heroes", "1% to attack to all heroes", "2% to attack to all heroes", "4% to attack to all heroes", "6% to attack to all heroes", "8% to attack to all heroes", "10% to attack to all heroes"],
                ["Adding 2% to armor, health and attack", "Adding 2% to armor, health and attack", "Adding 4% to armor, health and attack", "Adding 6% to armor, health and attack", "Adding 8% to armor, health and attack", "Adding 11% to armor, health and attack", "Adding 15% to armor, health and attack"]
            ]
            break;

        default:
            break;
    }

    return {
        titleSkills: pandaSkillsTitles,
        skillsDescr: pandaSkillsDescrs
    }
}

const caseNeedRes = (rar) => {
    var res = 0;
    var resTmpl = 431181;
    var res2 = 0;
    var res2Tmpl = 0;
    var res2Name = '';
    var gems = 0;
    var bam = 0;
    var books1 = 0;
    var books2 = 0;
    switch (rar) {
        case 0:
            res = 5;
            res2 = 1;
            res2Tmpl = 431182;
            res2Name = "steel";
            gems = 5;
            bam = 20;
            books1 = 15;
            books2 = 30;
            break;
        case 1:
            res = 10;
            res2 = 1;
            resTmpl = 431182;
            res2Name = "steel";
            gems = 5;
            bam = 20;
            books1 = 15;
            books2 = 30;
            break;
        case 2:
            res = 10;
            res2 = 3;
            res2Tmpl = 431182;
            res2Name = "steel";
            gems = 7;
            bam = 30;
            books1 = 18;
            books2 = 36;
            break;
        case 3:
            res = 10;
            res2 = 5;
            res2Tmpl = 431183;
            res2Name = "titawin";
            gems = 10;
            bam = 50;
            books1 = 21;
            books2 = 42;
            break;
        case 4:
            res = 10;
            res2 = 8;
            res2Tmpl = 431184;
            res2Name = "kvessir";
            gems = 15;
            bam = 70;
            books1 = 24;
            books2 = 48;
            break;
        case 5:
            res = 10;
            res2 = 11;
            res2Tmpl = 431185;
            res2Name = "gold";
            gems = 20;
            bam = 100;
            books1 = 27;
            books2 = 54;
            break;
        case 6:
            res = 10;
            res2 = 15;
            res2Tmpl = 431186;
            res2Name = "dwemer";
            gems = 25;
            bam = 150;
            books1 = 30;
            books2 = 60;
            break;

        default:
            break;
    }

    return {
        res: res,
        resTmpl: resTmpl,
        res2: res2,
        res2Tmpl: res2Tmpl,
        res2Name: res2Name,
        gems: gems,
        bam: bam,
        books1: books1,
        books2: books2
    }
}

const skillArreyNumer = [
    "elder",
    "healer",
    "priest",
    "dreneya",
    "druid",
    "mage",
    "tauren",
    "smith",
    "engineer",
    "berserker",
    "bard",
    "shooter",
    "archer",
    "warrior",
    "paladin"
]


const createSketch = (p, setWidthScale, setWindowWidth, setWindowHeight) => {
    console.log("p", p);
    let myFont;
    let bgImages;
    let book;
    let buttonSprite1 = [], buttonSprite2 = [], buttonSprite3 = [];
    var importBookTitle,
        bamboo,
        skillOrnament,
        hero,
        heroList = {},
        classList = {},
        bigSkillOrnament,
        bigSkillOrnamentList = [],
        bigSkillIco,
        aboutOrn,
        bookico,
        secRes,
        secResSteel,
        secResKves,
        secResTit,
        secResGold,
        secResDwem,
        wood,
        gems,
        bam,
        learnBtSprite = [],
        check,
        learned
        ;

    const bookWidth = 1680;
    const bookHeight = 942;
    const bookPosX = 416;
    const bookPosY = 180;

    // width, fullheight, x, y
    const btSkill1 = [103, 208, 248, 660];
    const btSkill2 = [104, 210, 400, 660];
    const btSkill3 = [104, 208, 558, 660];

    const btLearn = [149, 120, 1166, 795];

    const checkPos1 = [47, 48, 332, 633];
    const checkPos2 = [47, 48, 483, 633];
    const checkPos3 = [47, 48, 640, 633];

    const learnParam = [317, 99, 1075, 625]

    const importBookTitleParam = [526, 145, 198, 61];
    const bambooParam = [575, 184, 167, 333];
    const skillOrnamentParam = [608, 204, 150, 590];
    let heroParam = []; // потому что он динамичный
    const activeBigSkillOrnament = [583, 251, 940, 70];
    let activeBigSkill = []; // потому что он динамичный
    const skillBigOrnamentParam = [624, 231, 920, 348];

    const importResBookParam = [114, 114, 929, 650];
    const importResWoodParam = [114, 114, 1046, 650];
    const importResSecResParam = [114, 114, 1168, 650];
    const importResGemsParam = [114, 114, 1283, 650];
    const importResBamParam = [107, 64, 1407, 675];

    const textNameBook = {
        position: 'center',
        fontSize: 80,
        left: 460,
        top: 125,
        text: "skill book",
        border: null,
        borderWidth: 0,
        color: [65, 44, 7],
        width: 0
    }

    let textNameHero = {
        position: 'center',
        fontSize: 41,
        left: 460,
        top: 221,
        text: "Nifuriel",
        border: [65, 44, 7],
        borderWidth: 6,
        color: [239, 231, 195],
        width: 0
    }

    let textNameType = {
        position: 'center',
        fontSize: 32,
        left: 460,
        top: 540,
        text: "warrior",
        border: null,
        borderWidth: 0,
        color: [65, 44, 7],
        width: 0
    }

    const skillTitle = {
        position: 'center',
        fontSize: 55,
        left: 460,
        top: 600,
        text: "hero skills",
        border: null,
        borderWidth: 0,
        color: [65, 44, 7],
        width: 0
    }

    let btdescr1 = {
        position: 'center',
        fontSize: 25,
        left: 304,
        top: 780,
        text: "Power Blow",
        border: null,
        borderWidth: 0,
        color: [65, 44, 7],
        width: 130
    }

    let btdescr2 = {
        position: 'center',
        fontSize: 25,
        left: 456,
        top: 780,
        text: "Heroic Leap",
        border: null,
        borderWidth: 0,
        color: [65, 44, 7],
        width: 130
    }

    let btdescr3 = {
        position: 'center',
        fontSize: 25,
        left: 614,
        top: 780,
        text: "In Name of Elgard",
        border: null,
        borderWidth: 0,
        color: [65, 44, 7],
        width: 130
    }

    let titlePaga2NameSkill = {
        position: 'center',
        fontSize: 34,
        left: 1232,
        top: 350,
        text: "block block block block block block block",
        border: null,
        borderWidth: 0,
        color: [65, 44, 7],
        width: 250
    }

    let textPaga2Descr = {
        position: 'center',
        fontSize: 28,
        left: 1232,
        top: 400,
        text: "The hero completely reflects the enemy's attack with a small chance or reduces its damage",
        border: null,
        borderWidth: 0,
        color: [65, 44, 7],
        width: 395
    }

    const textPaga2priceTit = {
        position: 'center',
        fontSize: 31,
        left: 1232,
        top: 550,
        text: "skill learning price",
        border: [65, 44, 7],
        borderWidth: 6,
        color: [255, 255, 255],
        width: 395
    }

    let textPaga2reg = {
        position: 'center',
        fontSize: 28,
        left: 1232,
        top: 600,
        text: "required rank: legendary",
        border: null,
        borderWidth: 0,
        color: [65, 44, 7],
        width: 395
    }

    let importResBooknum = {
        position: 'center',
        fontSize: 35,
        left: 990,
        top: 750,
        text: "1/1",
        border: [65, 44, 7],
        borderWidth: 6,
        color: [239, 231, 195],
        width: 114
    }

    let importResWoodnum = {
        position: 'center',
        fontSize: 35,
        left: 1110,
        top: 750,
        text: "1/1",
        border: [65, 44, 7],
        borderWidth: 6,
        color: [239, 231, 195],
        width: 114
    }

    let importSecResnum = {
        position: 'center',
        fontSize: 35,
        left: 1230,
        top: 750,
        text: "10/10",
        border: [65, 44, 7],
        borderWidth: 6,
        color: [239, 231, 195],
        width: 114
    }

    let importResGemsnum = {
        position: 'center',
        fontSize: 35,
        left: 1350,
        top: 750,
        text: "10/10",
        border: [65, 44, 7],
        borderWidth: 6,
        color: [239, 231, 195],
        width: 114
    }

    let importResBamnum = {
        position: 'center',
        fontSize: 35,
        left: 1465,
        top: 750,
        text: "10 bam",
        border: [65, 44, 7],
        borderWidth: 6,
        color: [239, 231, 195],
        width: 130
    }

    //let img;
    let bgImageWidth = 2500;
    let bgImageHeight = 1563;

    let buttonScale;
    let heroName = '';

    let shouldRedraw = false;
    let activeSkill = 1; // активный скилл для того чтобы понимать какой тайтл и описание показывать
    let heightTitle = 0; // высота тайтла чтобы понимать насколько ниже показывать дескрипшн


    p.preload = () => {

        console.log("p2", p);

        bgImages = p.loadImage(importBgimg);
        book = p.loadImage(importBookimg);
        check = p.loadImage(importCheck);
        learned = p.loadImage(importSkillLearn);


        buttonSprite1.push(p.loadImage(importBtbgskill1), p.loadImage('/images/skills/skill-ico-1.png'));
        buttonSprite2.push(p.loadImage(importBtbgskill2), p.loadImage('/images/skills/skill-ico-2.png'));
        buttonSprite3.push(p.loadImage(importBtbgskill3), p.loadImage('/images/skills/skill-ico-3.png'));

        learnBtSprite.push(p.loadImage(importLearnBt));

        importBookTitle = p.loadImage(importBookTit);
        bamboo = p.loadImage(importBookBamb);
        skillOrnament = p.loadImage(importBookSkillOrn);

        hero = p.loadImage('/images/skills/png.png')

        // console.log("allPandaInfo", p.allPandaInfo);
        // console.log("heroList", heroList);

        bigSkillIco = p.loadImage('/images/skills/big-skill-ico-2.png')
        bigSkillOrnament = p.loadImage('/images/skills/big-skill-bg-1.png')

        bigSkillOrnamentList.push(p.loadImage('/images/skills/big-skill-bg-2.png'), p.loadImage('/images/skills/big-skill-bg-1.png'), p.loadImage('/images/skills/big-skill-bg-3.png'));


        aboutOrn = p.loadImage(importAboutSkillOrn);

        bookico = p.loadImage(importBookIco);
        wood = p.loadImage(importResWood);
        gems = p.loadImage(importResGems);
        bam = p.loadImage(importResBam);


        secRes = p.loadImage(importSecResSteel);
        secResSteel = p.loadImage(importSecResSteel);
        secResKves = p.loadImage(importSecResKvessir);
        secResTit = p.loadImage(importSecResTitawin);
        secResGold = p.loadImage(importSecResGold);
        secResDwem = p.loadImage(importSecResDwemer);

        myFont = p.loadFont(font);
    };

    p.setup = () => {
        p.createCanvas(p.windowWidth, p.windowHeight);
        bgImageWidth = bgImages.width;
        bgImageHeight = bgImages.height;

        heroParam = [hero.width, hero.height, 450, 400] // width, fullheight, x, y
        activeBigSkill = [bigSkillIco.width, bigSkillIco.height, 1232, 215] // width, fullheight, x, y

        p.imageMode(p.CENTER);
    };

    p.draw = () => {
        //p.background(255);
        let pandaDynamic = p.dynamicTextGlobal;

        if (pandaDynamic && pandaDynamic.onePandaName && pandaDynamic.onePandaName !== heroName || pandaDynamic && pandaDynamic.onePandaName && !shouldRedraw) {
            heroName = pandaDynamic.onePandaName;

            if (heroList[heroName] == undefined) {
                p.loadImage('/images/skills/hero/' + heroName + '.png', img => {
                    hero = img;
                    heroParam = [hero.width, hero.height, 458, 380];

                    heroList[heroName] = {
                        img: img,
                        heroParam: [hero.width, hero.height, 458, 380]
                    }
                });
            } else {
                hero = heroList[heroName].img;
                heroParam = heroList[heroName].heroParam;
            }

            // console.log("heroList", heroList);
            // console.log("pandaDynamic", pandaDynamic);
            // console.log("pandaDynamic.onePandaName", pandaDynamic.onePandaName);
            // console.log("heroName", heroName);
            // console.log("pandaDynamic.onePandaName !== heroName", pandaDynamic.onePandaName !== heroName);
            // console.log("!shouldRedraw", !shouldRedraw);

            shouldRedraw = true;
            /////////////////////////////////////////////////////////////////////////////// это для кнопок скилов
            const basePath = '/images/skills/skillico/';
            const basePathBig = '/images/skills/bigico/';
            const basePathBookico = '/images/skills/needres/';

            let buttons = [buttonSprite1, buttonSprite2, buttonSprite3];
            // определение списка навыков
            let skills = ['skill1img', 'skill2img', 'skill3img', 'bigSkillIco1', 'bigSkillIco2', 'bigSkillIco3', 'bookico'];

            // убедимся, что объект класса существует
            if (!classList[pandaDynamic.onePanda.clas]) {
                classList[pandaDynamic.onePanda.clas] = {};
            }

            // перебираем навыки
            for (let i = 0; i < skills.length; i++) {
                let skill = skills[i];
                // если навык не определен, загрузим изображение
                if (i < 3) {
                    if (!classList[pandaDynamic.onePanda.clas][skill]) {
                        let path = basePath + pandaDynamic.onePanda.clas + `_skill${i + 1}.png`;
                        p.loadImage(path, img => {
                            buttons[i][1] = img;
                            classList[pandaDynamic.onePanda.clas][skill] = img;
                        });
                    } else {
                        buttons[i][1] = classList[pandaDynamic.onePanda.clas][skill];
                    }
                } else {
                    if (!classList[pandaDynamic.onePanda.clas][skill]) {
                        if (skill == `bigSkillIco${activeSkill + 1}`) {
                            let path = basePathBig + pandaDynamic.onePanda.clas + `_skill${(activeSkill + 1)}.png`;
                            p.loadImage(path, img => {
                                bigSkillIco = img;
                                classList[pandaDynamic.onePanda.clas][skill] = img;
                            });
                        }

                        else if (skill == `bookico`) {
                            let path = basePathBookico + pandaDynamic.onePanda.clas + `.png`;
                            p.loadImage(path, img => {
                                bookico = img;
                                classList[pandaDynamic.onePanda.clas][skill] = img;
                            });
                        }
                    } else {
                        if (skill == `bigSkillIco${activeSkill + 1}`) {
                            bigSkillIco = classList[pandaDynamic.onePanda.clas][skill]
                        }
                        else if (skill == "bookico") {
                            bookico = classList[pandaDynamic.onePanda.clas][skill]
                        }
                    }
                }
            }

            bigSkillOrnament = bigSkillOrnamentList[activeSkill];

            textNameHero.text = pandaDynamic.onePandaName;
            textNameType.text = pandaDynamic.onePanda.clas;

            var activeType = caseClasInfo(pandaDynamic.onePanda.clas);
            var actyveRes = caseNeedRes(pandaDynamic.onePanda.rarity);

            btdescr1.text = activeType.titleSkills[0];
            btdescr2.text = activeType.titleSkills[1];
            btdescr3.text = activeType.titleSkills[2];
            titlePaga2NameSkill.text = activeType.titleSkills[activeSkill];
            textPaga2Descr.text = activeSkill == 0 ? "Basic Attack" : activeType.skillsDescr[activeSkill][pandaDynamic.onePanda.rarity];

            var getIndexClass = skillArreyNumer.indexOf(pandaDynamic.onePanda.clas);

            const bookCount = p.allSkillBooksInGameState && p.allSkillBooksInGameState.books !== undefined && p.allSkillBooksInGameState.books[getIndexClass];
            const bookLimit = activeSkill == 1 ? actyveRes.books1 : actyveRes.books2;
            importResBooknum.text = `${Math.min(bookCount, bookLimit)}/${bookLimit}`;

            const resCount = p.allNFTCount[actyveRes.resTmpl] || 0;
            importResWoodnum.text = `${Math.min(resCount, actyveRes.res)}/${actyveRes.res}`;

            const secResCount = p.allNFTCount[actyveRes.res2Tmpl] || 0;
            importSecResnum.text = `${Math.min(secResCount, actyveRes.res2)}/${actyveRes.res2}`;

            const gemCount = p.allNFTCount["gems"] || 0;
            importResGemsnum.text = `${Math.min(gemCount, actyveRes.gems)}/${actyveRes.gems}`;

            const bamCount = parseInt(p.balance);
            importResBamnum.text = `${Math.min(bamCount, actyveRes.bam)}/${actyveRes.bam}`;

            if (pandaDynamic.onePanda.rarity === 0 || pandaDynamic.onePanda.rarity === 1 || pandaDynamic.onePanda.rarity === 2) {
                secRes = secResSteel;
            } else if (pandaDynamic.onePanda.rarity === 3) {
                secRes = secResTit
            } else if (pandaDynamic.onePanda.rarity === 4) {
                secRes = secResKves
            } else if (pandaDynamic.onePanda.rarity === 5) {
                secRes = secResGold
            } else if (pandaDynamic.onePanda.rarity === 6) {
                secRes = secResDwem
            }
        }

        // if (hero) {
        //     p.image(hero, p.width / 2, p.height / 2);
        // }

        let windowRatio = p.windowWidth / p.windowHeight;
        let imgRatio = bgImageWidth / bgImageHeight;

        let drawWidth, drawHeight;

        // Если соотношение сторон окна больше, чем соотношение сторон изображения
        if (windowRatio > imgRatio) {
            drawWidth = p.windowWidth;
            drawHeight = p.windowWidth / imgRatio;
        } else {
            drawHeight = p.windowHeight;
            drawWidth = p.windowHeight * imgRatio;
        }

        let x = (p.windowWidth - drawWidth) / 2;
        let y = p.windowHeight - drawHeight;
        p.imageMode(p.CORNER); // Устанавливаем режим отображения на CORNER
        //p.image(bgImages, x, y, drawWidth, drawHeight);
        p.imageMode(p.CENTER);

        const scale = Math.max(p.windowWidth / bgImageWidth, p.windowHeight / bgImageHeight);
        buttonScale = scale;
        p.push();
        drawImg(book, bookPosX, bookPosY, bookWidth, bookHeight, buttonScale);
        p.pop();
    };

    let newWidth, newHeight, newY, newScale, newX;

    const drawImg = (sprite, posX, posY, spriteWidth, spriteHeight, buttonScale) => {
        const canvasWidth = p.width;
        const canvasHeight = p.height;
        const bookAspectRatio = spriteWidth / spriteHeight;
        const maxHeight = canvasHeight * 0.9;
        const maxWidth = canvasWidth * 0.9;

        if (canvasWidth < 1800 || canvasHeight < 1000) {
            newWidth = Math.min(maxWidth, spriteWidth * buttonScale);
            newHeight = newWidth / bookAspectRatio;
            newY = posY * buttonScale;

            if (newHeight > maxHeight) {
                newHeight = maxHeight;
                newWidth = newHeight * bookAspectRatio;
                newY = posY * (newHeight / spriteHeight);
            }

            // Add additional offset for mobile devices in landscape orientation
            if (canvasWidth > canvasHeight && canvasWidth < 1024) {
                let additionalOffset = 50; // Adjust this value as needed
                if (newHeight + additionalOffset <= canvasHeight) {
                    newY += additionalOffset;
                    newHeight -= additionalOffset;
                    newWidth = newHeight * bookAspectRatio;
                }
            }
        } else {
            newWidth = spriteWidth * 0.8;
            newHeight = spriteHeight * 0.8;
            newY = posY * 0.8;
        }

        newScale = newWidth / spriteWidth;

        // if (canvasWidth < 1800 || canvasHeight < 1000) {
        //     newHeight = (canvasHeight - marginTop) * 0.9; // 90% of the canvas height, minus the top margin
        //     newWidth = newHeight * bookAspectRatio;
        //     newY = (canvasHeight - newHeight) / 2 + marginTop; // Recalculate posY and add the top margin
        //     newScale = newWidth / spriteWidth;  // Calculate the new scale

        //     if (newWidth > canvasWidth) {
        //         newWidth = canvasWidth * 0.9; // 90% of canvas width
        //         newHeight = newWidth / bookAspectRatio;
        //         newY = (canvasHeight - newHeight) / 2 + marginTop; // Recalculate posY and add the top margin
        //         newScale = newWidth / spriteWidth;  // Recalculate the new scale
        //     }
        // } else {
        //     newWidth = spriteWidth * 0.8;
        //     newHeight = (spriteHeight * 0.8) - marginTop; // Subtract the top margin from the new height
        //     newY = (canvasHeight - newHeight) / 2 + marginTop; // Recalculate posY and add the top margin
        //     newScale = 0.8;  // Set the scale to 0.8, because we are not resizing the book
        // }

        setWidthScale(newScale);
        setWindowWidth(p.windowWidth);
        setWindowHeight(p.windowHeight);

        newX = (canvasWidth - newWidth) / 2;
        p.imageMode(p.CORNER);
        p.image(sprite, newX, newY, newWidth, newHeight);

        // first page
        drawButtonInsideBook(importBookTitle, importBookTitleParam, newScale, newX, newY, "img");
        drawButtonInsideBook(bamboo, bambooParam, newScale, newX, newY, "img");
        drawButtonInsideBook(skillOrnament, skillOrnamentParam, newScale, newX, newY, "img");

        drawButtonInsideBook(hero, heroParam, newScale, newX, newY, "hero");

        drawButtonInsideBook(buttonSprite1, btSkill1, newScale, newX, newY, "button", activeSkill === 0);
        drawButtonInsideBook(buttonSprite2, btSkill2, newScale, newX, newY, "button", activeSkill === 1);
        drawButtonInsideBook(buttonSprite3, btSkill3, newScale, newX, newY, "button", activeSkill === 2);

        drawTextBlock(textNameBook, newScale, newX, newY, 'text'); // OBJ, newScale, newX, newY
        drawTextBlock(textNameHero, newScale, newX, newY, 'text');
        drawTextBlock(textNameType, newScale, newX, newY, 'text');
        drawTextBlock(skillTitle, newScale, newX, newY, 'text');

        drawTextBlock(btdescr1, newScale, newX, newY, 'text');
        drawTextBlock(btdescr2, newScale, newX, newY, 'text');
        drawTextBlock(btdescr3, newScale, newX, newY, 'text');
        // END FIRST PAGE

        // SEC PAGE
        drawButtonInsideBook(bigSkillOrnament, activeBigSkillOrnament, newScale, newX, newY, "img");
        drawButtonInsideBook(bigSkillIco, activeBigSkill, newScale, newX, newY, "hero");
        drawTextBlock(titlePaga2NameSkill, newScale, newX, newY, 'title');
        drawButtonInsideBook(aboutOrn, skillBigOrnamentParam, newScale, newX, newY, "img");
        drawTextBlock(textPaga2Descr, newScale, newX, newY, 'descr');


        drawButtonInsideBook(check, checkPos1, newScale, newX, newY, "img");
        p.dynamicTextGlobal && p.dynamicTextGlobal.onePanda && p.dynamicTextGlobal.onePanda.skills[0] && drawButtonInsideBook(check, checkPos2, newScale, newX, newY, "img");
        p.dynamicTextGlobal && p.dynamicTextGlobal.onePanda && p.dynamicTextGlobal.onePanda.skills[1] && drawButtonInsideBook(check, checkPos3, newScale, newX, newY, "img");

        if (activeSkill === 1 && p.dynamicTextGlobal && p.dynamicTextGlobal.onePanda && !p.dynamicTextGlobal.onePanda.skills[0] || activeSkill === 2 && p.dynamicTextGlobal && p.dynamicTextGlobal.onePanda && !p.dynamicTextGlobal.onePanda.skills[1]) {

            drawTextBlock(textPaga2priceTit, newScale, newX, newY, 'text');
            //drawTextBlock(textPaga2reg, newScale, newX, newY, 'text');
            drawButtonInsideBook(bookico, importResBookParam, newScale, newX, newY, "img");
            drawButtonInsideBook(wood, importResWoodParam, newScale, newX, newY, "img");
            drawButtonInsideBook(secRes, importResSecResParam, newScale, newX, newY, "img");
            drawButtonInsideBook(gems, importResGemsParam, newScale, newX, newY, "img");
            drawButtonInsideBook(bam, importResBamParam, newScale, newX, newY, "img");

            drawTextBlock(importResBooknum, newScale, newX, newY, 'text');
            drawTextBlock(importResWoodnum, newScale, newX, newY, 'text');
            drawTextBlock(importSecResnum, newScale, newX, newY, 'text');
            drawTextBlock(importResGemsnum, newScale, newX, newY, 'text');
            drawTextBlock(importResBamnum, newScale, newX, newY, 'text');

            drawButtonInsideBook(learnBtSprite, btLearn, newScale, newX, newY, "button");
        } else {

            drawButtonInsideBook(learned, learnParam, newScale, newX, newY, "img");
        }

        p.imageMode(p.CENTER);


    };

    //width, fullheight, x, y
    const drawButtonInsideBook = (sprite, obj, newScale, newX, newY, type, isActive) => {
        const button2NewX = obj[2] * newScale + newX;  // Use the new scale
        const button2NewY = obj[3] * newScale + newY;  // Use the new scale
        let spriteY = 0;

        if (type === "button") {
            const isMouseOverButton = p.mouseX >= button2NewX
                && p.mouseX <= button2NewX + obj[0] * newScale
                && p.mouseY >= button2NewY
                && p.mouseY <= button2NewY + (obj[1] / 2) * newScale;

            spriteY = isMouseOverButton || isActive ? obj[1] - (obj[1] / 2) : 0;

            p.image(
                sprite[0],
                button2NewX,
                button2NewY,
                obj[0] * newScale,
                (obj[1] / 2) * newScale,
                0,
                spriteY,
                obj[0],
                obj[1] - (obj[1] / 2)
            );

            if (sprite.length == 2) {
                p.image(
                    sprite[1],
                    button2NewX + ((obj[0]) - sprite[1].width) * newScale / 2,
                    button2NewY + ((obj[1] / 2) - sprite[1].height) * newScale / 2,
                    sprite[1].width * newScale,
                    sprite[1].height * newScale
                );
            }

        } else if (type === "hero") {
            p.image(
                sprite,
                button2NewX - (obj[0] / 2) * newScale,
                button2NewY - (obj[1] / 2) * newScale,
                obj[0] * newScale,
                (obj[1]) * newScale
            );
        } else {
            p.image(
                sprite,
                button2NewX,
                button2NewY,
                obj[0] * newScale,
                (obj[1]) * newScale
            );
        }
        // const buttonLeft = button2NewX;
        // const buttonRight = button2NewX + obj[0] * newScale;
        // const buttonTop = button2NewY;
        // const buttonBottom = button2NewY + (obj[1] / 2) * newScale;

        // рисуем прямоугольник, который представляет кнопку
        // p.fill(255, 0, 0);
        // p.rect(buttonLeft, buttonTop, buttonRight - buttonLeft, buttonBottom - buttonTop);
    };

    const drawTextBlock = (text, newScale, newX, newY, type) => {
        p.push();
        p.textFont(myFont);
        p.textAlign(p.LEFT, p.CENTER);
        p.textSize(text.fontSize * newScale);

        if (text.color && text.color.length) {
            p.fill(...text.color);
        } else {
            p.fill(0, 0, 0);
        }

        if (text.border && text.border.length) {
            p.stroke(...text.border);
            p.strokeWeight(text.borderWidth);
        }

        let xText = newX + text.left * newScale;
        let yText = newY + text.top * newScale;
        let textX = xText - (text.position == "center" ? (p.textWidth(text.text) / 2) : 0);

        if (text.width) {


            if (typeof text.text !== "string") {
                console.error('Invalid input: text.text should be a string');
                console.log("aaaaaaaaaaaaaaaa", text)
                return;
            }
            let words = text.text.split(' ');
            let line = '';
            let lineWidth = 0;
            let yLine = yText;
            let lines = [];

            words.forEach((word) => {
                let testLine = line + word + ' ';
                let testWidth = p.textWidth(testLine);
                if (testWidth > text.width * newScale && line) {
                    lines.push({ text: line, y: yLine, lineWidth: lineWidth });
                    line = word + ' ';
                    lineWidth = p.textWidth(line);
                    yLine += p.textSize();
                } else {
                    line = testLine;
                    lineWidth = testWidth;
                }
            });

            lines.push({ text: line, y: yLine, lineWidth: lineWidth });

            if (type == "title") {
                heightTitle = lines[lines.length - 1].y + 40 * newScale; // чтобы отступать от заголовка который может быть на 2 или 3 строки
            }

            lines.forEach(lineData => {
                let lineX = xText - (text.position == "center" ? (lineData.lineWidth / 2) : 0);
                p.text(lineData.text, lineX, type == 'descr' ? heightTitle : lineData.y);
                if (type == 'descr') {
                    heightTitle += p.textSize();
                }
            });
        } else {
            p.text(text.text, textX, yText);
        }

        p.noStroke();
        p.pop();
    };

    /*
    drawButtonInsideBook(buttonSprite1, btSkill1, newScale, newX, newY, "button");
        drawButtonInsideBook(buttonSprite2, btSkill2, newScale, newX, newY, "button");
        drawButtonInsideBook(buttonSprite3, btSkill3, newScale, newX, newY, "button");
        */

    p.mouseClicked = () => {
        checkButtonClicked(buttonSprite1, btSkill1, newScale, newX, newY, "button1");
        checkButtonClicked(buttonSprite2, btSkill2, newScale, newX, newY, "button2");
        checkButtonClicked(buttonSprite3, btSkill3, newScale, newX, newY, "button3");
        checkButtonClicked(learnBtSprite, btLearn, newScale, newX, newY, "learn");
    };

    const checkButtonClicked = (sprite, obj, newScale, newX, newY, type) => {
        const button2NewX = obj[2] * newScale + newX;  // Use the new scale
        const button2NewY = obj[3] * newScale + newY;  // Use the new scale
        let spriteY = 0;


        const isMouseOverButton = p.mouseX >= button2NewX
            && p.mouseX <= button2NewX + obj[0] * newScale
            && p.mouseY >= button2NewY
            && p.mouseY <= button2NewY + (obj[1] / 2) * newScale;

        if (isMouseOverButton) {
            if (type === "button1") {
                //console.log("type", type);
                activeSkill = 0;
            } else if (type === "button2") {
                //console.log("type", type);
                activeSkill = 1;
            } else if (type === "button3") {
                //console.log("type", type);
                activeSkill = 2;
            } else if (type === "learn") {
                //console.log("type", type);

                learnSkillsTrans(p.dynamicTextGlobal.onePanda.asset_id, activeSkill);
            }
            shouldRedraw = false;
        }


    };

    p.windowResized = () => {
        p.resizeCanvas(window.innerWidth, window.innerHeight);

    };

};

let learnSkillsTrans;

const Skills = (props) => {

    learnSkillsTrans = async (id, numberSkill) => {
        //console.log("hhhhhhhhhhhhhhhhhhh");
        try {
            const { activeUser } = props.ual;

            const { accountName } = activeUser;

            function getFirstNAssets(parentKey, childKey, n) {
                if (!allNFT.hasOwnProperty(parentKey)) {
                    return [];
                }

                let targetArray;

                if (childKey === "") {
                    targetArray = allNFT[parentKey];
                } else if (allNFT[parentKey].hasOwnProperty(childKey)) {
                    targetArray = allNFT[parentKey][childKey];
                } else {
                    return [];
                }

                return targetArray.slice(0, n).map(item => item.asset_id);
            }



            //var activeType = caseClasInfo(dynamicTextGlobal.onePanda.clas);
            var actyveRes = caseNeedRes(dynamicTextGlobal.onePanda.rarity);

            let res1 = getFirstNAssets("resources", actyveRes.resTmpl, actyveRes.res);
            //console.log(res1);

            let res2 = getFirstNAssets("resources", actyveRes.res2Tmpl, actyveRes.res2);
            //console.log(res2);

            let Gems = getFirstNAssets("gems", "", actyveRes.gems);
            //console.log(Gems); // возвращает первые 10 asset_id из массива "gems"

            let allResNft = [...res1, ...res2, ...Gems]
            var getIndexClass = skillArreyNumer.indexOf(dynamicTextGlobal.onePanda.clas);

            var actyveRes = caseNeedRes(dynamicTextGlobal.onePanda.rarity);
            const bookLimit = numberSkill == 1 ? actyveRes.books1 : actyveRes.books2;
            //importResBooknum.text = `${Math.min(bookCount, bookLimit)}/${bookLimit}`;

            const bookCount = allSkillBooksInGameState && allSkillBooksInGameState.books !== undefined && allSkillBooksInGameState.books[getIndexClass];

            if (allResNft.length < (actyveRes.gems + actyveRes.res2 + actyveRes.res)) {
                //alert("you don't have enough resources to learn this skill");
                handleError("you don't have enough resources to learn this skill");
            } else if (bookLimit > bookCount) {
                handleError("you don't have enough books to learn this skill");
            } else {
                const transactionPayload = learnSkills(accountName, id, numberSkill, allResNft);

                const transaction = await activeUser.signTransaction(transactionPayload, {
                    blocksBehind: 3,
                    expireSeconds: 30,
                });

                var activeSkillInThisPandas = [...dynamicTextGlobal.onePanda.skills];
                activeSkillInThisPandas[numberSkill - 1] = 1;

                setDynamicText(prevState => ({
                    ...prevState,
                    onePanda: {
                        ...prevState.onePanda,
                        skills: activeSkillInThisPandas  // Здесь установите новые значения для skills
                    }
                }));
            }

        } catch (e) {
            alert(e.toString());
            console.error(e);
            // if (e instanceof RpcError)
            //   console.log(JSON.stringify(e.json, null, 2));
        }
    }

    const history = useHistory();
    const canvasRef = useRef(null);
    const p5InstanceRef = useRef(null);

    const [widthScale, setWidthScale] = useState(null);
    const [windowWidth, setWindowWidth] = useState(null);
    const [windowHeight, setWindowHeight] = useState(null);

    const [userTable, setUserTable] = useState(null);

    const [allPandaInGame, setAllPandaInGame] = useState(null);
    const [allPandaWeapon, setAllPandaWeapon] = useState(null);
    const [allPandaJew, setAllPandaJew] = useState(null);
    const [allPandaArmor, setAllPandaArmor] = useState(null);

    const [allPandaInfo, setAllPandaInfo] = useState(null);

    const [wasLoadUser, setWasLoadUser] = useState(false);
    const [wasLoadPanda, setWasLoadPanda] = useState(false);
    const [wasLoadWeapon, setWasLoadWeapon] = useState(false);
    const [wasLoadJew, setWasLoadJew] = useState(false);
    const [wasLoadArmor, setWasLoadArmor] = useState(false);
    const [wasLoadAll, setWasLoadAll] = useState(false);
    const [allSkillBooksInGameState, setAllSkillBooksInGameState] = useState(null);
    const [allNFTState, setAllNFTState] = useState(null);
    const [wasLoadSkills, setWasLoadSkills] = useState(false);

    let p5Instance = null;

    const [user, setUser] = useState(null); // не забудьте установить начальное значение для пользователя

    let { allSkillBooksInGame } = useGetAllSkillBooks(user);
    //console.log("allSkillBooksInGame", allSkillBooksInGame);

    let shemas = ["resources", "gems"]

    let { allNFT } = useGetAllNftOnWallet(user, shemas);
    //console.log("allNFT", allNFT);

    useEffect(() => {
        if (allSkillBooksInGame && allSkillBooksInGame.username !== undefined && allSkillBooksInGame.username == user && !wasLoadSkills) {
            setAllSkillBooksInGameState(allSkillBooksInGame);
            setWasLoadSkills(true);
        }
    }, [allSkillBooksInGame]);

    // useEffect(() => {
    //     if (allSkillBooksInGame && allSkillBooksInGame.username !== undefined && allSkillBooksInGame.username == user && !wasLoadSkills) {
    //         setAllSkillBooksInGameState(allSkillBooksInGame);
    //         setWasLoadSkills(true);
    //     }
    // }, [allSkillBooksInGame]);

    const [manyBalls, setManyBalls] = useState(0);
    const [openHeroList, setOpenHeroList] = useState(false);
    const [openThankModal, setOpenThankModal] = useState(false);
    const [dynamicTextGlobal, setDynamicText] = useState(0);
    const [activePandaIndex, setActivePandaIndex] = useState(0);
    const [hasError1, setHasError] = useState(false);
    const [errorText1, setErrorText] = useState('');

    const handleError = (e) => {


        setHasError(true);
        setErrorText(e);
        console.warn(e);
        console.log("e", e);
        console.log("hasError", hasError1);
        console.log("errorText", errorText1);
    }

    const getAllInfo = async () => {
        try {
            let allInfo = allPandaInGame.map(item => {
                let copy = { ...item };

                if (item["weapon"] && allPandaWeapon && allPandaWeapon.length) {
                    copy["weapon"] = allPandaWeapon.find(weapon => item["weapon"] === weapon.asset_id);
                }
                if (item["jew"] && allPandaJew && allPandaJew.length) {
                    copy["jew"] = allPandaJew.find(jew => item["jew"] === jew.asset_id);
                }
                if (item["armor"] && allPandaArmor && allPandaArmor.length) {
                    copy["armor"] = allPandaArmor.find(armor => item["armor"] === armor.asset_id);
                }
                return copy;
            });

            const orderedArray = userTable.slots_count.map((item, index) => {
                if (item === 0) {
                    // вставить пустой объект или оставить ноль
                    return 0; // или return 0;
                } else {
                    // найти соответствующий объект в хаосном массиве
                    const pandaInfo = detalPanda(item, index, allInfo);

                    return pandaInfo;
                }
            });


            let index = orderedArray.findIndex(value => value !== 0 && value !== '');
            setAllPandaInfo(orderedArray);
            setWasLoadAll(true);
            setDynamicText(orderedArray[index]);


        } catch (e) {
            handleError(e);
        }
    }

    const createRpc = () => new JsonRpc("https://" + arrRpc[numRpc]);

    const callApi = async (params) => {
        try {
            const rpcc = createRpc();
            const results = await rpcc.get_table_rows(params);
            return results;
        } catch (e) {
            handleError(e);
        }
    }

    const [allDataLoaded, setAllDataLoaded] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (!allDataLoaded) { // Проверяем, все ли данные уже загружены
                const accountName = props.activeUser.accountName;
                setUser(accountName);
                console.log("accountName 2", accountName);
                await getArraySlots(accountName);
                await GetPandaListOnGame(accountName);
                await getItemsSlotWax(accountName, "nftweapons", setAllPandaWeapon, setWasLoadWeapon);
                await getItemsSlotWax(accountName, "nftjew", setAllPandaJew, setWasLoadJew);
                await getItemsSlotWax(accountName, "nftarmor", setAllPandaArmor, setWasLoadArmor);

                // Когда все асинхронные операции завершены, установите состояние allDataLoaded в true
                setAllDataLoaded(true);
            }
        };

        fetchData();
    }, [props.activeUser.accountName, allDataLoaded]); // Добавляем allDataLoaded в зависимости


    useEffect(() => {
        // Когда все данные загружены (allDataLoaded === true), вызовите функцию getAllInfo
        if (allDataLoaded) {
            getAllInfo();
        }
    }, [allDataLoaded]);

    const getArraySlots = async (accountName) => {
        try {
            const results = await callApi({
                json: true,
                code: process.env.REACT_APP_CONTRACT,
                scope: process.env.REACT_APP_CONTRACT,
                table: "usersnew",
                limit: 1,
                lower_bound: accountName,
                upper_bound: accountName,
                index_position: 1,
                reverse: false,
                show_payer: false,
            });

            const resultRow = results.rows[0];

            if (resultRow) {
                setUserTable(resultRow);
                setWasLoadUser(true);
            }
        } catch (e) {
            handleError(e);
        }
    }

    const GetPandaListOnGame = async (accountName) => {
        try {
            const results = await callApi({
                json: true,
                code: process.env.REACT_APP_CONTRACT,
                scope: process.env.REACT_APP_CONTRACT,
                table: "nftsongamec",
                key_type: "i64",
                lower_bound: accountName,
                upper_bound: accountName,
                limit: -1,
                reverse: !0,
                show_payer: false,
                index_position: 2,
            });

            const resultRow = results.rows;
            const resultRowFilter = resultRow.filter(
                (row) => row.storer === accountName && row.is_in_slot === 0
            );

            if (resultRow && resultRow.length) {
                const pandaObSlot = resultRow
                    .filter((panda) => panda.is_in_slot)
                //.map((panda) => ({ ...panda, activePanda: false })); ЭТО ФИЛЬТР ТОЛЬКО ДЛЯ ПАНД КОТОРЫЕ В СЛОТУ
                setAllPandaInGame(pandaObSlot);
                setWasLoadPanda(true);
            }
        } catch (e) {
            handleError(e);
        }
    }

    const getItemsSlotWax = async (accountName, table, setter, load) => {

        //console.log("table", table);
        try {
            const items = await callApi({
                json: true,
                code: process.env.REACT_APP_CONTRACT,
                scope: process.env.REACT_APP_CONTRACT,
                table: table,
                limit: 100,
                lower_bound: accountName,
                upper_bound: accountName,
                key_type: "i64",
                index_position: 2,
                reverse: false,
                show_payer: false,
            }).then(items => {
                if (items?.rows && items?.rows?.length) {
                    setter(items.rows);
                }
                load(true);
            });


        } catch (e) {
            handleError(e);
        }
    }

    const nowActivePanda = (index) => {
        setDynamicText(allPandaInfo[index]);
        setActivePandaIndex(index);
        //console.log(allPandaInfo[index]);
    }



    useEffect(() => {
        if (!p5InstanceRef.current) {
            p5InstanceRef.current = new p5((p) => createSketch(p, setWidthScale, setWindowWidth, setWindowHeight), canvasRef.current);
        }
        p5InstanceRef.current.dynamicTextGlobal = dynamicTextGlobal;
        p5InstanceRef.current.allSkillBooksInGameState = allSkillBooksInGameState;
        p5InstanceRef.current.balance = props.balanceGame;
        p5InstanceRef.current.allNFTCount = allNFT && allNFT.counts !== undefined && allNFT.counts;
        //p5InstanceRef.current.openInventModal = openInventModal;

        console.log("canvas start ")

        return () => {
            p5InstanceRef.current.remove();
        };
    }, []);

    useEffect(() => {
        if (p5InstanceRef.current) {
            p5InstanceRef.current.dynamicTextGlobal = dynamicTextGlobal;
            p5InstanceRef.current.allSkillBooksInGameState = allSkillBooksInGameState;
            p5InstanceRef.current.balance = props.balanceGame;
            p5InstanceRef.current.allNFTCount = allNFT && allNFT.counts !== undefined && allNFT.counts;
            //p5InstanceRef.current.openInventModal = openInventModal;
        }
    }, [dynamicTextGlobal, allSkillBooksInGameState, allNFT]);

    const closeThank = () => {
        setOpenThankModal(false);
        setManyBalls(0);
        //setOpenInventModal(false);
    }

    const clearError = () => {
        setHasError(false);
        setErrorText("");
    }

    // console.log("widthScale", widthScale);
    // console.log("wasLoadUser", wasLoadUser);
    // console.log("allPandaInGame", allPandaInGame);
    // console.log("allPandaWeapon", allPandaWeapon);
    // console.log("allPandaJew", allPandaJew);
    // console.log("allPandaArmor", allPandaArmor);
    // console.log("wasLoadAll", wasLoadAll);

    return (<div className='skill-page'>
        {hasError1 && errorText1 ? (
            <ErrorComponent
                errorMessage={errorText1}
                clearError={clearError}
            />
        ) : null}

        <div className="rotate-screen"></div>
        {openThankModal &&
            <ModalThank manyBalls={manyBalls} closeThank={closeThank} />
        }

        <Menu  {...props.ual} wax={props.balanceWax} bam={props.balanceBam} game={props.balanceGame} updateBalance={props.updateBalance} />

        <div ref={canvasRef}></div>

        {widthScale && wasLoadUser && allPandaInGame && /*allPandaWeapon && allPandaJew && allPandaArmor && */wasLoadAll &&
            <HeroList
                widthScale={widthScale}
                windowWidth={windowWidth}
                windowHeight={windowHeight}
                userTable={userTable}
                allPandaInfo={allPandaInfo}
                nowActivePanda={nowActivePanda}
                activePandaIndex={activePandaIndex}
            />
        }
    </div>
    );
};

export default Skills;
