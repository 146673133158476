import React from 'react';

const ErrorStake = (props) => {
    var pandaHasStake = props.hasStakeErrorInfo.nowStake;
    pandaHasStake = pandaHasStake.toFixed(4);
    var pandaNeedStake = props.hasStakeErrorInfo.needStake - props.hasStakeErrorInfo.nowStake;
    pandaNeedStake = pandaNeedStake.toFixed(4);
    return (<>
        <div className={`modal-stake-more ${props.hasStakeErrorInfo.hasStakeError ? 'open' : 'close'}`}>
            <div className="bamb-modal-body">
                <div className="bamb-modal-body-in">
                    <div className="bamb-modal">
                        <div className="bamb-modal-in">
                            <div className="modal-close" onClick={props.handleCloseModalStakeMore}></div>
                            <div className="bamb-modal-top"></div>
                            <div className="bamb-modal-bot"></div>
                            <div className="bamb-modal-wrap">
                                <div className="unstake-wrap">
                                    <div className="unstake-top">
                                        <div className="unstake-tit">
                                            Oops, to rank up you need to stake more {process.env.REACT_APP_VAL}
                                        </div>
                                    </div>
                                    <div className="unstake-bot">
                                        To increase the rank, you need to add more {process.env.REACT_APP_VAL} to stake. <br />At the moment you have {pandaHasStake} {process.env.REACT_APP_VAL} staked. You still need to stake <span>{pandaNeedStake} {process.env.REACT_APP_VAL}</span>.
                                    </div>

                                    <div className="unstake-form-in">

                                        <div className="stake-btns">
                                            <div className="one-stake-bam">
                                                <button className="def-bt min" onClick={props.handleCloseModalStakeMore}>
                                                    <span className="button-in">
                                                        <span className="button-name">
                                                            <span className="button-name-in">
                                                                <span className="button-name-value">
                                                                    Okay
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </button>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-modal"></div>
        </div>

        {/* <div className={`modal-error ${props.showModal ? 'open' : 'close'} `}>
                <div className="error-modal-table">
                    <div className="error-modal-table-top">
                        <span>
                            ERROR
                        </span>
                    </div>
                    <div className="error-modal-table-mid">
                        <div className="error-modal-table-mid-in">
                            {props.errorMessage}
                        </div>
                    </div>
                    <div className="error-modal-table-bot">
                        
                    </div>
                </div>
                <div className={`bg-wrror-modal ${props.showModalBg ? 'open' : 'close'} `}></div>
            </div>  */}
    </>);
}

export default ErrorStake;