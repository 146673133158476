import React, { useRef, useEffect, useMemo, useCallback, useLayoutEffect, useState } from 'react'
import p5 from "p5";

import bgmap from './../../../images/newMap/new-map.jpg'
import point from './../../../images/newMap/point.png'
import sword from './../../../images/newMap/sword.png'

import cave_of_terrabarium from './../../../images/newMap/cave_of_terrabarium.png'
import fields_of_asmar from './../../../images/newMap/fields_of_asmar.png'
import forest_of_nyoron from './../../../images/newMap/forest_of_nyoron.png'
import forest_of_ulrien from './../../../images/newMap/forest_of_ulrien.png'
import fields_of_wisdom from './../../../images/newMap/fields_of_wisdom.png'
import cloud from './../../../images/newMap/cloud.png'
import soon from './../../../images/newMap/soon.png'

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

const Newmap = (props) => {

    const canvasRef = useRef(null);

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }
    var animStatus = localStorage.getItem('animation') == 'true' || localStorage.getItem('animation') == null ? true : false;
    var windowInnerWidth = window.innerWidth;
    var windowInnerHeight = window.innerHeight;
    var imgSize = 2500 / 1300;
    var imgSizeRev = 1300 / 2500;
    var sizeBool = imgSize > windowInnerWidth / windowInnerHeight && windowInnerWidth <= 768;
    var { height, width } = getWindowDimensions();
    var scaleWidth = sizeBool ? imgSizeRev : height / 1300;
    var scaleHeight = sizeBool ? windowInnerHeight / 1300 : windowInnerHeight / 1300;
    var bgSizeWidth = sizeBool ? 2500 * imgSizeRev : 2500 / (1300 / height);
    var bgSizeHeight = sizeBool ? 1300 * scaleHeight : height;
    var canvaWidth = bgSizeWidth + 40;
    var canvaHeight = bgSizeHeight + 40;

    if (bgSizeWidth < width && windowInnerWidth > 768) {
        canvaWidth = width + 40;
        canvaHeight = 1300 / (2500 / width) + 40;

        scaleWidth = canvaHeight / 1300;
        scaleHeight = canvaHeight / 1300;
    }
    // const { showModalInside } = props;
    // const sketchRef = React.useRef();
    // const p5ContainerRef = React.useRef();
    let activeAdv = "";

    useEffect(() => {
        const myP5 = new p5(sketch, canvasRef.current);

        return () => {
            myP5.remove();
        };
    }, []);
    //
    const listCloud = useMemo(() => {
        const cloudList = [];
        for (let i = 0; i < 30; i++) {
            var x = getRandomInt(width);
            var y = getRandomInt(height);
            cloudList.push({
                x: x,
                y: y,
                goside_x: x >= width / 2 ? false : true,
                goside_y: y >= height / 2 ? false : true,
            });
        }
        return cloudList;
    }, []);

    let points = [
        {
            active: true,
            name: "cave_of_terrabarium",
            width: 57,
            height: 32,
            originWidth: 90,
            originHeight: 53,
            // fromx: 418,
            // fromy: 650,
            x: 482,
            y: 322,
            hovered: false,
            img: point,
            imgHover: bgmap,
            nameImg: cave_of_terrabarium,
            nameWidth: 186,
            nameHeight: 83,
        },
        {
            active: true,
            name: "forest_of_nyoron",
            width: 71,
            height: 42,
            originWidth: 90,
            originHeight: 53,
            // fromx: 418,
            // fromy: 650,
            x: 810,
            y: 520,
            hovered: false,
            img: point,
            imgHover: bgmap,
            nameImg: forest_of_nyoron,
            nameWidth: 160,
            nameHeight: 84,
        },
        {
            active: true,
            name: "forest_of_ulrien",
            width: 71,
            height: 42,
            originWidth: 90,
            originHeight: 53,
            // fromx: 100,
            // fromy: 280,
            x: 604,
            y: 602,
            hovered: false,
            img: point,
            imgHover: bgmap,
            nameImg: forest_of_ulrien,
            nameWidth: 148,
            nameHeight: 83,
        },
        {
            active: false,
            name: "fields_of_asmar",
            width: 90,
            height: 53,
            originWidth: 90,
            originHeight: 53,
            // fromx: 418,
            // fromy: 650,
            x: 643,
            y: 837,
            hovered: false,
            img: point,
            imgHover: bgmap,
            nameImg: fields_of_asmar,
            nameWidth: 144,
            nameHeight: 84,
        },
        {
            active: true,
            name: "fields_of_wisdom",
            width: 69,
            height: 40,
            originWidth: 90,
            originHeight: 53,
            // fromx: 418,
            // fromy: 650,
            x: 1260,
            y: 415,
            hovered: false,
            img: point,
            imgHover: bgmap,
            nameImg: fields_of_wisdom,
            nameWidth: 156,
            nameHeight: 90,
        },
    ];

    let activeClick = false;
    let activemap = true;

    const hoverObj = useCallback((x, y) => {
        for (let i = 0; i < points.length; i++) {
            var xPos = sizeBool ? points[i].x - (2500 / 100 * 10) : points[i].x;

            if (
                x >= xPos * scaleWidth && x <= (xPos * scaleWidth) + (points[i].width * scaleWidth) &&
                y >= (points[i].y - 100) * scaleHeight && y <= (points[i].y * scaleHeight) + (points[i].height * scaleHeight)
            ) {
                points[i].hovered = true;
                activeAdv = points[i].name;
            } else {
                points[i].hovered = false;
            }
        };

        var haveHover = points.find((value) => { return value.hovered == true });
        if (!haveHover) {
            activeAdv = "";
        }
    }, []);

    function sketch(p) {

        let pointAsmar, pointAsmarHover, swordPoint, bg, cloud_canvas, soonMap;
        let cave_of_terrabarium_name, fields_of_asmar_name, forest_of_nyoron_name, forest_of_ulrien_name;
        let images = {};
        let allSpeed = 2;
        let swordAnimation = 108;
        let rotateSword = -540;
        let speedRotate = 20 * allSpeed;
        let swordFromSpeed = 5 * allSpeed;
        let swordFromX = 0;
        let swordFromY = 0;
        let swordOpacity = 0;

        var global_pos_x = 0;
        var global_pos_y = 0;
        var global_size = 500;
        var opacyty_cloud = 0;
        var cloud_speed = 10;

        p.preload = () => {
            images = {
                bg: p.loadImage(bgmap),
                pointAsmar: p.loadImage(point),
                pointAsmarHover: p.loadImage(bgmap),
                swordPoint: p.loadImage(sword),
                cave_of_terrabarium_name: p.loadImage(cave_of_terrabarium),
                fields_of_asmar_name: p.loadImage(fields_of_asmar),
                forest_of_nyoron_name: p.loadImage(forest_of_nyoron),
                forest_of_ulrien_name: p.loadImage(forest_of_ulrien),
                fields_of_wisdom_name: p.loadImage(fields_of_wisdom),
                cloud_canvas: p.loadImage(cloud),
                soonMap: p.loadImage(soon)
            };
        };

        p.setup = () => {
            p.createCanvas(windowInnerWidth <= 768 ? canvaWidth - (canvaWidth / 100 * 10) : canvaWidth, canvaHeight);
            //drawBackground();
            p.image(images.bg, sizeBool ? -canvaWidth / 100 * 10 : 0, 0, canvaWidth, canvaHeight);

            drawPoints();
            //p.draw();
        }

        p.mousePressed = function () {
            if (!p.showModalInside) {
                if (!activeClick && activeAdv) {
                    if (activeAdv == "cave_of_terrabarium") {
                        props.showCloseToAdv('open', 'stones');
                    } else if (activeAdv == "forest_of_nyoron") {
                        props.showCloseToAdv('open', 'resources');
                    } else if (activeAdv == "forest_of_ulrien") {
                        props.showCloseToAdv('open', 'bamboo');
                    } else if (activeAdv == "fields_of_wisdom") {
                        props.showCloseToAdv('open', 'books');
                    }
                    activeClick = true;
                    activemap = false;
                }
            }
        }

        p.mouseReleased = function () {
            activeClick = false;
        }

        p.draw = () => {
            p.setup();

            //opacyty_cloud = 256;
            if (animStatus) {
                handleCloudAnimation();
            } else {
                opacyty_cloud = 256;
            }
            applyBlurFilter();

            if (opacyty_cloud > 255) {
                hoverObj(p.mouseX, p.mouseY);
            }
        }

        function drawBackground() {
            p.image(images.bg, sizeBool ? -canvaWidth / 100 * 10 : 0, 0, canvaWidth, canvaHeight);
        }

        function drawPoints() {
            var haveHover = points.find((value) => { return value.hovered == true && value.active == true });

            if (haveHover == undefined) {
                swordAnimation = 108;
                rotateSword = -540;
                swordFromX = 0;
                swordFromY = 0;
                swordOpacity = 0;
            }

            for (const point of points) {

                var xPos = sizeBool ? point.x - (2500 / 100 * 10) : point.x;

                p.push();
                //p.tint(255, opacyty_cloud < 255 ? opacyty_cloud : 255);
                p.image(
                    images.pointAsmar,
                    xPos * scaleWidth,
                    point.y * scaleHeight,
                    point.width * scaleWidth,
                    point.height * scaleHeight,
                    0, point.hovered ? (point.originHeight) : 0, point.originWidth, point.originHeight
                );
                if (!point.active) {
                    p.image(
                        images.soonMap,
                        (xPos - 40) * scaleWidth,
                        (point.y - 25) * scaleHeight,
                        89 * scaleWidth,
                        75 * scaleHeight,
                    );
                }
                p.image(
                    point.name == 'cave_of_terrabarium' ? images.cave_of_terrabarium_name :
                        point.name == 'fields_of_asmar' ? images.fields_of_asmar_name :
                            point.name == 'forest_of_nyoron' ? images.forest_of_nyoron_name :
                                point.name == 'fields_of_wisdom' ? images.fields_of_wisdom_name : images.forest_of_ulrien_name,

                    (xPos - (point.nameWidth / 2) + (point.width / 2)) * scaleWidth,
                    (point.y - (point.nameHeight / 2) + (point.height / 2) - point.nameHeight) * scaleHeight,
                    point.nameWidth * scaleWidth,
                    point.nameHeight * scaleHeight,
                    0, point.hovered ? 0 : (point.nameHeight), point.nameWidth, point.nameHeight
                );
                p.pop();

                if (point.hovered && !p.showModalInside && animStatus) {
                    if (swordFromX < xPos) {
                        swordFromX = xPos;
                    } else if (swordFromX < xPos) {
                        swordFromX += swordFromSpeed;
                    }
                    else if (swordFromX >= xPos) {
                        swordFromX = xPos;
                    }

                    if (swordFromY < point.y - 100) {
                        swordFromY = point.y - 100;
                    } else if (swordFromY < point.y) {
                        swordFromY += swordFromSpeed;
                    } else if (swordFromY >= point.y) {
                        swordFromY = point.y;
                    }

                    if (swordOpacity < 255) {
                        swordOpacity += 15;
                    }
                    p.push();

                    p.tint(255, swordOpacity);
                    p.translate((((swordFromX * scaleWidth) + (point.width / 2 * scaleWidth) - (38 / 2.2 * scaleWidth))),
                        (((swordFromY - swordAnimation + point.height / 2) * scaleHeight) + (swordAnimation * scaleHeight) / 2));
                    if (rotateSword <= 0) {
                        p.rotate(rotateSword * Math.PI / 360);
                    }
                    p.translate(-(((swordFromX * scaleWidth) + (point.width / 2 * scaleWidth) - (38 / 2.2 * scaleWidth))),
                        -(((swordFromY - swordAnimation + point.height / 2) * scaleHeight) + (swordAnimation * scaleHeight) / 2));
                    rotateSword += speedRotate;
                    p.image(images.swordPoint,
                        ((swordFromX * scaleWidth) + (point.width / 2 * scaleWidth) - (38 / 2.2 * scaleWidth)), //x
                        (swordFromY - swordAnimation + point.height / 2) * scaleHeight, //y
                        38 * scaleWidth,
                        swordAnimation * scaleHeight,

                        0, 0, 38, swordAnimation);

                    if (swordAnimation >= 60 && rotateSword > 0) {
                        swordAnimation -= 7;
                    }
                    p.pop();
                }

            };
        }
        function handleCloudAnimation() {
            if (global_pos_x <= canvaWidth) {
                for (let i = 0; i < listCloud.length; i++) {

                    //console.log(11111111111111111111111, global_pos_x);

                    const centerX = listCloud[i].x - (global_size / 2) + ((width / 2 + global_size) / 2);
                    const centerY = listCloud[i].y - (global_size / 2) + ((height / 2 + global_size) / 2);
                    const offsetX = listCloud[i].goside_x ? -global_pos_x : global_pos_x;
                    const offsetY = listCloud[i].goside_y ? -global_pos_y : global_pos_y;

                    p.push();




                    p.translate(centerX + offsetX, centerY + offsetY);

                    // if (rotateSword <= 0) {
                    //     const side = i % 2 ? -cloud_speed : cloud_speed;
                    //     p.rotate(side * Math.PI / 360);
                    // }
                    //////
                    p.translate(-(centerX + offsetX), -(centerY + offsetY));
                    /////
                    //p.tint(255, 255 - opacyty_cloud);

                    p.image(
                        images.cloud_canvas,
                        listCloud[i].x - global_size / 2 + offsetX,
                        listCloud[i].y - global_size / 2 + offsetY,
                        ((width)),
                        ((height))
                    );
                    p.pop();

                    if (i == listCloud.length - 1) {
                        global_size += 2;
                        opacyty_cloud += 3;
                        cloud_speed += 3;
                        global_pos_x += 10;
                        global_pos_y += 10;
                    }
                };
            }
        }
        function applyBlurFilter() {
            if (p.showModalInside) {
                p.drawingContext.filter = 'blur(10px)';
            } else {
                p.drawingContext.filter = 'blur(0px)';
            }
        }
    }
    return (
        <div className="new-adv">
            <div className={`map ${bgSizeWidth < width ? "hasVertScroll" : "hesHorScroll"}`}>
                <div className="canvas-wrap">
                    {/* <div ref={p5ContainerRef} /> */}
                    <div
                        ref={canvasRef}
                        id="canvas-container"
                        style={{ overflowX: "scroll", width: "100%", height: "100%" }}
                    ></div>
                </div>
            </div>
        </div>
    )
}

export default Newmap;