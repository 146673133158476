import React from 'react';

const InventoryEat = (props) => {
    var imgeat;
    return (

        <div className="burn-wrap">
            <div className="burn-wrap-in">
                {
                    Object.values(props.allinvent || {}).map((group, groupIndex) => {
                        var rareat;
                        var nameRar;
                        var nameFood;
                        var tmpl;
                        const firstItemInGroup = group.assets[0];

                        //console.log("group", group.assets);
                        //console.log("firstItemInGroup", firstItemInGroup);

                        switch (firstItemInGroup.template.template_id) {
                            case "350909":
                                imgeat = 'eat-common-nft';
                                rareat = 0;
                                nameRar = "Common";
                                nameFood = "pickled bamboo";
                                break;

                            case "350910":
                                imgeat = 'eat-uncommon-nft';
                                rareat = 1;
                                nameRar = "Uncommon";
                                nameFood = "bamboo with mushrooms";
                                break;

                            case "350911":
                                imgeat = 'eat-rare-nft';
                                rareat = 2;
                                nameRar = "Rare";
                                nameFood = "ramen";
                                break;

                            case "350912":
                                imgeat = 'eat-epic-nft';
                                rareat = 3;
                                nameRar = "Epic";
                                nameFood = "rice with bamboo";
                                break;

                            case "350913":
                                imgeat = 'eat-mythic-nft';
                                rareat = 5;
                                nameRar = "Mythic"
                                nameFood = "stake with bamboo";
                                break;

                            case "350914":
                                imgeat = 'eat-voucher-nft';
                                rareat = 6;
                                nameRar = "Voucher"
                                nameFood = "stake with bamboo";
                                break;

                            default:

                        }

                        return (
                            <div className={`one-invent-prod active ${nameRar} ${props.manyItems >= 50 && group.count == 0 && "disabled"}`} key={groupIndex} >
                                <div className="one-invent-prod-info tooltip">
                                    <div className="cust-tooltip right">
                                        <div className="tool-arrow"></div>
                                        <div className="tool-wrap">
                                            <div className="tool-wrap-1">
                                                <div className="tool-wrap-2">
                                                    <div className="tool-body">
                                                        <span>{nameRar} food</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="one-invent-prod-thumb">
                                    {props.manyItems < 50 || group.count != 0 ? <div className='linkinv' onClick={() => props.showCloseModal('open',
                                        {
                                            data: {
                                                name: nameFood,
                                                rarity: nameRar
                                            },
                                            schema: {
                                                schema_name: "food",
                                            },
                                            template: {
                                                template_id: firstItemInGroup.template.template_id
                                            },
                                            activeCount: group.count,
                                            allItems: group.assets.length
                                        }
                                    )} ></div> : null}
                                    <div className="value-inv-eat">
                                        {group.count}/{group.assets.length} {/* This will show the number of items in each group */}
                                    </div>
                                    <span></span>
                                    <div className="one-invent-prod-thumb-in">
                                        <picture>
                                            <source srcSet={`./images/shop/eat-${nameRar}.webp`} type="image/webp" />
                                            <source srcSet={`./images/shop/eat-${nameRar}.png`} type="image/png" />
                                            <img src={`./images/shop/eat-${nameRar}.png`} alt="" />
                                        </picture>
                                        <i></i>
                                        <i className="b"></i>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </ div>
        </div>
    );
}

export default InventoryEat;
