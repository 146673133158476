import React from "react";
import Menu from '../../components/Menu';

import Countdown from "react-countdown";

import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import StatisticsEvent from "./StatisticsEvent";
import { JsonRpc } from "eosjs";
import alienlogo from "../../images/logo-header.png"; // relative path to image
import alienlogow from "../../images/logo-header.png"; // relative path to image

import tit from "../../images/alien/leader-tit.png"; // relative path to image
import titw from "../../images/alien/leader-tit.webp"; // relative path to image

var numRpc = 0;
var arrRpc = ['wax.pink.gg', 'wax.cryptolions.io', 'wax.greymass.com', 'api.wax.alohaeos.com', 'api.waxsweden.org', 'wax.eosphere.io', 'api.wax.greeneosio.com', 'wax.eu.eosamsterdam.net', 'api.wax.bountyblok.io']

const dataStart = 1711983600;
//const dataStart = 1711977652; // test
const dataEnd = 1714521600;

const LiderDescr = (props) => {
    const { classN } = props;

    return (<div className={`wrapline ${classN}`}><div className={`red-line`}>
        {Date.now() <= 1696118400000 ?
            <>
                <div className="leader-alien-timer">
                    <Countdown date={1696118400000} />
                </div>
                <div className="leader-alien-descr">
                    <h4 style={{ fontSize: 28, marginBottom: 10 }}>ELGARD BURNING FESTIVAL LEADERBOARD</h4>
                    Here, you will find a list of the most bravest Elgard Heroes, who burn the most items!<br /> <span>The top 100 will receive amazing rewards!</span>
                </div>
            </>
            :
            <>
                {Date.now() >= dataStart * 1000 ?
                    <div className="min-tit">
                        April 1 15:00 UTC — May 1 00:00 UTC
                    </div>
                    : null}

                <div className="leader-alien-timer">
                    {Date.now() <= dataEnd * 1000 && Date.now() >= dataStart * 1000 ?
                        <Countdown date={dataEnd * 1000} />
                        : null
                    }

                </div>
                <div className="leader-alien-descr">
                    Here you will find a list of the most legendary heroes from the lands of Elgard.<br />
                    With their courage, they act as a shield and support for all the
                    inhabitants of Elgard. <br />
                    <span>The top 100 Heroes</span> will receive a special reward.
                </div>
            </>
        }
    </div>


        <div className="leader-bt">
            <div className="one-leader-bt">
                {Date.now() <= 1688169600000 ?
                    <a className="bt-alien first" target="_blank" href="https://nftpanda.medium.com/leaderboard-proposal-b4b9acdb60c">
                        more information
                    </a>
                    :
                    <a className="bt-alien first" target="_blank" href="https://nftpanda.medium.com/leaderboard-proposal-b4b9acdb60c">
                        more information
                    </a>
                }
            </div>
            {/* <div className="one-leader-bt">
                <NavLink className="bt-alien first" to="/alien-adventures">
                    back to adventures
                </NavLink>
            </div> */}
        </div>
    </div>
    );
}

class StatisticsEventComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            formInfo: [],
            formInfoChit: [],
            count: 0,
            priseList: [
                {
                    voucher: 150,
                    chests: 2,
                    nft: "Epic Armor"
                }, {
                    voucher: 130,
                    chests: 2,
                    nft: "Epic Armor"
                }, {
                    voucher: 120,
                    chests: 2,
                    nft: "Epic Armor"
                }, {
                    voucher: 100,
                    chests: 2,
                    nft: "Rare armor"
                }, {
                    voucher: 100,
                    chests: 2,
                    nft: "Rare armor"
                }, {
                    voucher: 100,
                    chests: 2,
                    nft: "Rare armor"
                }, {
                    voucher: 100,
                    chests: 2,
                    nft: "Rare armor"
                }, {
                    voucher: 100,
                    chests: 2,
                    nft: "Rare armor"
                }, {
                    voucher: 100,
                    chests: 2,
                    nft: "Rare armor"
                }, {
                    voucher: 100,
                    chests: 2,
                    nft: "Rare armor"
                }, {
                    voucher: 80,
                    chests: 1,
                    nft: "Uncommon armor"
                }, {
                    voucher: 80,
                    chests: 1,
                    nft: "Uncommon armor"
                }, {
                    voucher: 80,
                    chests: 1,
                    nft: "Uncommon armor"
                }, {
                    voucher: 80,
                    chests: 1,
                    nft: "Uncommon armor"
                }, {
                    voucher: 80,
                    chests: 1,
                    nft: "Uncommon armor"
                }, {
                    voucher: 80,
                    chests: 1,
                    nft: "Uncommon armor"
                }, {
                    voucher: 80,
                    chests: 1,
                    nft: "Uncommon armor"
                }, {
                    voucher: 80,
                    chests: 1,
                    nft: "Uncommon armor"
                }, {
                    voucher: 80,
                    chests: 1,
                    nft: "Uncommon armor"
                }, {
                    voucher: 80,
                    chests: 1,
                    nft: "Uncommon armor"
                }, {
                    voucher: 80,
                    chests: 1,
                    nft: "Uncommon armor"
                }, {
                    voucher: 80,
                    chests: 1,
                    nft: "Uncommon armor"
                }, {
                    voucher: 80,
                    chests: 1,
                    nft: "Uncommon armor"
                }, {
                    voucher: 80,
                    chests: 1,
                    nft: "Uncommon armor"
                }, {
                    voucher: 80,
                    chests: 1,
                    nft: "Uncommon armor"
                }, {
                    voucher: 50,
                    chests: 1,
                    nft: "Common armor"
                }, {
                    voucher: 50,
                    chests: 1,
                    nft: "Common armor"
                }, {
                    voucher: 50,
                    chests: 1,
                    nft: "Common armor"
                }, {
                    voucher: 50,
                    chests: 1,
                    nft: "Common armor"
                }, {
                    voucher: 50,
                    chests: 1,
                    nft: "Common armor"
                }, {
                    voucher: 50,
                    chests: 1,
                    nft: "Common armor"
                }, {
                    voucher: 50,
                    chests: 1,
                    nft: "Common armor"
                }, {
                    voucher: 50,
                    chests: 1,
                    nft: "Common armor"
                }, {
                    voucher: 50,
                    chests: 1,
                    nft: "Common armor"
                }, {
                    voucher: 50,
                    chests: 1,
                    nft: "Common armor"
                }, {
                    voucher: 50,
                    chests: 1,
                    nft: "Common armor"
                }, {
                    voucher: 50,
                    chests: 1,
                    nft: "Common armor"
                }, {
                    voucher: 50,
                    chests: 1,
                    nft: "Common armor"
                }, {
                    voucher: 50,
                    chests: 1,
                    nft: "Common armor"
                }, {
                    voucher: 50,
                    chests: 1,
                    nft: "Common armor"
                }, {
                    voucher: 50,
                    chests: 1,
                    nft: "Common armor"
                }, {
                    voucher: 50,
                    chests: 1,
                    nft: "Common armor"
                }, {
                    voucher: 50,
                    chests: 1,
                    nft: "Common armor"
                }, {
                    voucher: 50,
                    chests: 1,
                    nft: "Common armor"
                }, {
                    voucher: 50,
                    chests: 1,
                    nft: "Common armor"
                }, {
                    voucher: 50,
                    chests: 1,
                    nft: "Common armor"
                }, {
                    voucher: 50,
                    chests: 1,
                    nft: "Common armor"
                }, {
                    voucher: 50,
                    chests: 1,
                    nft: "Common armor"
                }, {
                    voucher: 50,
                    chests: 1,
                    nft: "Common armor"
                }, {
                    voucher: 50,
                    chests: 1,
                    nft: "Common armor"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }, {
                    voucher: 25,
                    chests: 1,
                    nft: "Common weapon"
                }
            ]
        };
    }

    componentDidMount() {
        this.userCheck();
    }

    async userCheck() {
        try {
            //console.log(555555555555555555555555555555555555555555555);
            //const activeUser = this.props.ual.activeUser;
            //const accountName = await activeUser.getAccountName();
            //console.log(activeUser);
            //console.log(accountName);
            var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);
            const results = await rpcc.get_table_rows({
                json: true,
                code: process.env.REACT_APP_CONTRACT, // contract who owns the table
                scope: process.env.REACT_APP_CONTRACT, // scope of the table
                table: "usertopev", // name of the table as specified by the contract abi
                limit: 300,
                key_type: "i64",
                reverse: !0,
                show_payer: false,
                index_position: 2,
            });
            const originRowUser = results.rows;

            this.setState({
                formInfo: originRowUser,
            });

            //this.userCheckChit();
        } catch (e) {
            //console.log(e.message)
            console.warn(e);
            this.setState({
                hasError: true,
                errorText: e.message,
            });
            console.log("\nCaught exception: " + e);
        }
    }

    render() {
        return (
            <>
                <Menu  {...this.props.ual} wax={this.props.balanceWax} bam={this.props.balanceBam} game={this.props.balanceGame} updateBalance={this.props.updateBalance} />
                <main className="slot-page alien-festivas">
                    <section id="leader-alien">
                        <div className="leader-alien">
                            <div className="leader-alien-top">
                                <div className="leader-alien-logo panda-log">
                                    <picture>
                                        <source srcSet={alienlogow} type="image/webp" />
                                        <source srcSet={alienlogo} type="image/png" />
                                        <img src={alienlogow} alt="" />
                                    </picture>
                                </div>
                                <div className="leader-alien-tit">
                                    <picture>
                                        <source srcSet={titw} type="image/webp" />
                                        <source srcSet={tit} type="image/png" />
                                        <img src={titw} alt="" />
                                    </picture>
                                </div>

                                <LiderDescr classN={"desc"} />
                            </div>
                            <div className="leader-alien-mid">
                                <LiderDescr classN={"mob"} />

                                <StatisticsEvent
                                    name={this.props.ual.activeUser.accountName}
                                    tableInfo={this.state.formInfo}
                                    priseList={this.state.priseList}
                                />
                            </div>
                            <div className="leader-alien-bot"></div>

                        </div>
                    </section>

                    <footer>
                        <div className="footer-nav">
                            <nav>
                                <ul>
                                    <li>
                                        <a
                                            className="foot-logo-1"
                                            target="_blank"
                                            href="https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&sort=created&symbol=WAX"
                                        >
                                            <span></span>
                                            <i>Market</i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="foot-logo-2"
                                            target="_blank"
                                            href="https://twitter.com/NftPanda/"
                                        >
                                            <span></span>
                                            <i>Twitter</i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="foot-logo-3"
                                            target="_blank"
                                            href="https://www.instagram.com/nftpanda/"
                                        >
                                            <span></span>
                                            <i>Instagram</i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="foot-logo-4"
                                            target="_blank"
                                            href="https://discord.gg/vCSgme4d5b"
                                        >
                                            <span></span>
                                            <i>Discord</i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="foot-logo-5"
                                            target="_blank"
                                            href={`https://wax.alcor.exchange/swap?output=${process.env.REACT_APP_VAL}-nftpandabamb&input=WAX-eosio.token`}
                                        >
                                            <span></span>
                                            <i>{process.env.REACT_APP_VAL} Trade</i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="foot-logo-6"
                                            target="_blank"
                                            href="https://discord.gg/vCSgme4d5b"
                                        >
                                            <span></span>
                                            <i>Support</i>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </footer>
                </main>
            </>
        );
    }
}

let mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(StatisticsEventComponent);
