import React from 'react';
import modaltop from '../../images/shop/modal-top.png';
import modalbot from '../../images/shop/modal-bot.png';

const ModalMultiEat = (props) => {
    var needfood = 0;

    props.problemWithEat.problemRar[0] ? needfood += props.problemWithEat.problemRar[0] : needfood = needfood;
    props.problemWithEat.problemRar[1] ? needfood += props.problemWithEat.problemRar[1] : needfood = needfood;
    props.problemWithEat.problemRar[2] ? needfood += props.problemWithEat.problemRar[2] : needfood = needfood;
    props.problemWithEat.problemRar[3] ? needfood += props.problemWithEat.problemRar[3] : needfood = needfood;
    props.problemWithEat.problemRar[4] ? needfood += props.problemWithEat.problemRar[4] : needfood = needfood;
    props.problemWithEat.problemRar[5] ? needfood += props.problemWithEat.problemRar[5] : needfood = needfood;


    return (
    <>
       
        <div id="thank-modal-shop" className={`modal-bay open anim`}>
            <div className="modal-bay-body">
                <div className="modal-bay-body-in">

                    <div className="modal-bay-head">
                        
                        <div className="modal-bay-top">
                            <button className="modal-bay-close" onClick={() => props.closeThenk()}><span></span></button>
                            <img src={modaltop} alt="" />
                        </div>
                        <div className="modal-bay-wrap">
                            
                            
                            <div className="modal-bay-part">
                                {/* {props.problemWithEat.problemEnergy ? 
                                <>
                                    Some of the selected pandas already have full energy
                                </>
                                :
                                null
                                }
                                <br /><br /> */}
                                {
                                // props.problemWithEat.problemRar[0] ||
                                // props.problemWithEat.problemRar[1] || 
                                // props.problemWithEat.problemRar[2] || 
                                // props.problemWithEat.problemRar[3] || 
                                // props.problemWithEat.problemRar[4] || 
                                // props.problemWithEat.problemRar[5] 
                                true ? 
                                <>
                                    <div className="modal-bay-tit tit-err-f">
                                        {/* Something is wrong. */}
                                        Multi panda feeder.
                                    </div>
                                    <div className="food-rar-error rar-er">
                                        <div className="food-rar-error-tit">
                                            {/* You do not have enough food: */}
                                            The amount of food to be consumed:
                                        </div>
                                        {props.problemWithEat.problemRar[0] || props.problemWithEat.needEat[0] ?
                                        <div className="one-eat-problem">
                                            <div className="one-eat-problem-in rar-0 tooltip">
                                                <div className="one-img-food">
                                                    <picture>
                                                        <source srcSet={`./images/shop/350909.webp`} type="image/webp" />
                                                        <source srcSet={`./images/shop/350909.png`} type="image/png" /> 
                                                        <img src={`./images/shop/350909.png`} alt="" />
                                                    </picture>
                                                </div>
                                                <div className={`one-eat-num ${props.problemWithEat.problemRar[0] && "red"}`}>
                                                    {props.problemWithEat.problemRar[0] ? "-" + props.problemWithEat.problemRar[0] : props.problemWithEat.needEat[0]}
                                                </div>
                                                <div className="cust-tooltip bottom">
                                                    <div className="tool-arrow"></div>
                                                    <div className="tool-wrap">
                                                        <div className="tool-wrap-1">
                                                            <div className="tool-wrap-2">
                                                                <div className="tool-body">
                                                                    common food
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null }

                                        {props.problemWithEat.problemRar[1] || props.problemWithEat.needEat[1] ?
                                        <div className="one-eat-problem">
                                            <div className="one-eat-problem-in rar-1 tooltip">
                                                <div className="one-img-food">
                                                    <picture>
                                                        <source srcSet={`./images/shop/350910.webp`} type="image/webp" />
                                                        <source srcSet={`./images/shop/350910.png`} type="image/png" /> 
                                                        <img src={`./images/shop/350910.png`} alt="" />
                                                    </picture>
                                                </div>
                                                <div className={`one-eat-num ${props.problemWithEat.problemRar[1] && "red"}`}>
                                                    {props.problemWithEat.problemRar[1] ? "-" + props.problemWithEat.problemRar[1] : props.problemWithEat.needEat[1]}
                                                </div>
                                                <div className="cust-tooltip bottom">
                                                    <div className="tool-arrow"></div>
                                                    <div className="tool-wrap">
                                                        <div className="tool-wrap-1">
                                                            <div className="tool-wrap-2">
                                                                <div className="tool-body">
                                                                    uncommon food
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null }
                                        
                                        {props.problemWithEat.problemRar[2] || props.problemWithEat.needEat[2] ?
                                        <div className="one-eat-problem">
                                            <div className="one-eat-problem-in rar-2 tooltip">
                                                <div className="one-img-food">
                                                    <picture>
                                                        <source srcSet={`./images/shop/350911.webp`} type="image/webp" />
                                                        <source srcSet={`./images/shop/350911.png`} type="image/png" /> 
                                                        <img src={`./images/shop/350911.png`} alt="" />
                                                    </picture>
                                                </div>
                                                <div className={`one-eat-num ${props.problemWithEat.problemRar[2] && "red"}`}>
                                                   {props.problemWithEat.problemRar[2] ? "-" + props.problemWithEat.problemRar[2] : props.problemWithEat.needEat[2]}
                                                </div>
                                                <div className="cust-tooltip bottom">
                                                    <div className="tool-arrow"></div>
                                                    <div className="tool-wrap">
                                                        <div className="tool-wrap-1">
                                                            <div className="tool-wrap-2">
                                                                <div className="tool-body">
                                                                    rare food
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null }

                                        {props.problemWithEat.problemRar[3] || props.problemWithEat.needEat[3] ?
                                        <div className="one-eat-problem">
                                            <div className="one-eat-problem-in rar-3 tooltip">
                                                <div className="one-img-food">
                                                    <picture>
                                                        <source srcSet={`./images/shop/350912.webp`} type="image/webp" />
                                                        <source srcSet={`./images/shop/350912.png`} type="image/png" /> 
                                                        <img src={`./images/shop/350912.png`} alt="" />
                                                    </picture>
                                                </div>
                                                <div className={`one-eat-num ${props.problemWithEat.problemRar[3] && "red"}`}>
                                                    {props.problemWithEat.problemRar[3] ? "-" + props.problemWithEat.problemRar[3] : props.problemWithEat.needEat[3]}
                                                </div>
                                                <div className="cust-tooltip bottom">
                                                    <div className="tool-arrow"></div>
                                                    <div className="tool-wrap">
                                                        <div className="tool-wrap-1">
                                                            <div className="tool-wrap-2">
                                                                <div className="tool-body">
                                                                    epic food
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null }

                                        {props.problemWithEat.problemRar[4] || props.problemWithEat.needEat[4] ?
                                        <div className="one-eat-problem">
                                            <div className="one-eat-problem-in rar-4 tooltip">
                                                <div className="one-img-food">
                                                    <picture>
                                                        <source srcSet={`./images/shop/350913.webp`} type="image/webp" />
                                                        <source srcSet={`./images/shop/350913.png`} type="image/png" /> 
                                                        <img src={`./images/shop/350913.png`} alt="" />
                                                    </picture>
                                                </div>
                                                <div className={`one-eat-num ${props.problemWithEat.problemRar[4] && "red"}`}>
                                                    {props.problemWithEat.problemRar[4] ? "-" + props.problemWithEat.problemRar[4] : props.problemWithEat.needEat[4]}
                                                </div>
                                                <div className="cust-tooltip bottom">
                                                    <div className="tool-arrow"></div>
                                                    <div className="tool-wrap">
                                                        <div className="tool-wrap-1">
                                                            <div className="tool-wrap-2">
                                                                <div className="tool-body">
                                                                    legendary food
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null }

                                        {props.problemWithEat.problemRar[5] || props.problemWithEat.needEat[5] ?
                                        <div className="one-eat-problem">
                                            <div className="one-eat-problem-in rar-5 tooltip">
                                                <div className="one-img-food">
                                                    <picture>
                                                        <source srcSet={`./images/shop/350914.webp`} type="image/webp" />
                                                        <source srcSet={`./images/shop/350914.png`} type="image/png" /> 
                                                        <img src={`./images/shop/350914.png`} alt="" />
                                                    </picture>
                                                    <i></i>
                                                    <i className="b"></i>
                                                </div>
                                                <div className={`one-eat-num ${props.problemWithEat.problemRar[5] && "red"}`}>
                                                    {props.problemWithEat.problemRar[5] ? "-" + props.problemWithEat.problemRar[5] : props.problemWithEat.needEat[5]}
                                                </div>
                                                <div className="cust-tooltip bottom">
                                                    <div className="tool-arrow"></div>
                                                    <div className="tool-wrap">
                                                        <div className="tool-wrap-1">
                                                            <div className="tool-wrap-2">
                                                                <div className="tool-body">
                                                                    mythic food
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                        }
                                    </div>
                                </>
                                : null }
                                
                                
                                {props.allEat[6] - needfood > 0 ? 
                                <>
                                    <div className="food-rar-error voucher-er">
                                        <div className="food-rar-error-tit">
                                        Do you have enough food vouchers to feed your pandas to do so?
                                        </div>
                                    </div>
                                    {/* <div className="feed-cont">
                                        <button className="def-bt norm" onClick={() => props.addEnergyMultiTrans()}>
                                            <span className="button-in">
                                                <span className="button-name">
                                                    <span className="button-name-in">
                                                        <span className="button-name-value">
                                                            continue feeding
                                                        </span> 
                                                    </span>
                                                </span>
                                            </span>
                                        </button>
                                    </div> */}
                                </>
                                : null
                                }
                            </div>
                            {/* <div className="modal-thank-info">
                                Purchase details
                            </div> */}

                            {/* <div className="total-sum">
                                
                                <div className="total-sum-body">
                                    <span>
                                        {parseInt(props.modal.item.price * props.countModalThank * 10000) / 10000}
                                    </span>
                                </div>
                            </div> */}

                            {props.allEat[6] - needfood >= 0 ? 
                            <div className="modal-bay-bt">
                                <button className="def-bt norm" onClick={() => props.addEnergyMultiTrans()}>
                                    <span className="button-in">
                                        <span className="button-name">
                                            <span className="button-name-in">
                                                <span className="button-name-value">
                                                    continue feeding
                                                </span> 
                                            </span>
                                        </span>
                                    </span>
                                </button>
                            </div>
                            : null
                            }
                        </div>
                        <div className="modal-bay-bot">
                            <img src={modalbot} alt="" />
                        </div>
                    </div>
                </div>
                <div className="modal-bay-bg-trans" onClick={() => props.closeThenk()}></div>
                
            </div>
            <div className="modal-bay-bg" ></div>
            
        </div>

        <div id="cooking-modal-shop" className={`modal-bay modal-made ${props.made ? "open" : null}`}>
            <div className="modal-bay-body">
                <div className="modal-bay-body-in">

                    <div className="modal-bay-head">
                        
                        <div className="modal-bay-top">
                            <img src={modaltop} alt="" />
                        </div>
                        <div className="modal-bay-wrap">
                           
                            <div className="modal-bay-tit">
                                We are working on your order
                            </div>
                            <div className="loading-modal"></div>
                            <div className="modal-thank-info">
                                It can take a few minutes, please wait
                            </div>
                           
                        </div>
                        <div className="modal-bay-bot">
                            <img src={modalbot} alt="" />
                        </div>
                    </div>
                </div>
                <div className="modal-bay-bg-trans" onClick={() => props.closeThenk()}></div>
                
            </div>
            <div className="modal-bay-bg" ></div>
            
        </div>

    </>);
}

export default ModalMultiEat;