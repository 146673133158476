import React from 'react';
import NeedForQuest from './NeedForQuest';
import Countdown from 'react-countdown';

const OneTab = (props) => {
   

    // needSteel: 50,
    // needTitawin: 10,
    // needKvessir: 3,
    // needGold: 2
    // }
    return (<>
        <div className="quest-modal-descr">
            <div className="quest-modal-name">
                {props.quests[props.activeTabLocal].nameQuest}
            </div>
            <div className="quest-modal-bring">
                <div className="quest-modal-bring-name">
                    bring:
                </div>
                <div className="quest-modal-bring-wrap">
                    {props.allresgroupLocal && props.allresgroupLocal.length ? <NeedForQuest balance={props.balanceGame} activeQuest={props.quests[props.activeTabLocal].bring} allresgroup={props.allresgroupLocal} infoquest={props.quest} /> : null }
                </div>
            </div>

            <div className="reward-item">
                <div className="reward-item-in">
                    <div className="reward-item-left">
                        <span>
                            reward:
                        </span>
                        <i dangerouslySetInnerHTML={{__html: props.quests[props.activeTabLocal].reward.name}}></i>
                    </div>
                    <div className="reward-item-one">
                        <div className="reward-item-thumb">
                            <div className="reward-item-thumb-in">
                                {props.quests[props.activeTabLocal].reward.tmpl2 ? 
                                <div className="one-drop-thumb-stone">
                                    
                                    <span className='st-1'>
                                        <picture>
                                            <source srcSet={`./images/${props.quests[props.activeTabLocal].reward.type == "weapon" ? "weapons/inventory/" : props.quests[props.activeTabLocal].reward.type == "jewelry" ? "jew/inventory/" : props.quests[props.activeTabLocal].reward.type == "armor" ? "armor/inventory/" : "shop/"}${props.quests[props.activeTabLocal].reward.tmpl1}.webp`} type="image/webp" />
                                            <source srcSet={`./images/${props.quests[props.activeTabLocal].reward.type == "weapon" ? "weapons/inventory/" : props.quests[props.activeTabLocal].reward.type == "jewelry" ? "jew/inventory/" : props.quests[props.activeTabLocal].reward.type == "armor" ? "armor/inventory/" : "shop/"}${props.quests[props.activeTabLocal].reward.tmpl1}.png`} type="image/png" /> 
                                            <img src={`./images/${props.quests[props.activeTabLocal].reward.type == "weapon" ? "weapons/inventory/" : props.quests[props.activeTabLocal].reward.type == "jewelry" ? "jew/inventory/"  : props.quests[props.activeTabLocal].reward.type == "armor" ? "armor/inventory/" : "shop/"}${props.quests[props.activeTabLocal].reward.tmpl1}.png`} alt="" />
                                        </picture>
                                    </span>
                                    <span className='st-2'>
                                        <picture>
                                            <source srcSet={`./images/${props.quests[props.activeTabLocal].reward.type == "weapon" ? "weapons/inventory/" : props.quests[props.activeTabLocal].reward.type == "jewelry" ? "jew/inventory/" : props.quests[props.activeTabLocal].reward.type == "armor" ? "armor/inventory/" : "shop/"}${props.quests[props.activeTabLocal].reward.tmpl2}.webp`} type="image/webp" />
                                            <source srcSet={`./images/${props.quests[props.activeTabLocal].reward.type == "weapon" ? "weapons/inventory/" : props.quests[props.activeTabLocal].reward.type == "jewelry" ? "jew/inventory/" : props.quests[props.activeTabLocal].reward.type == "armor" ? "armor/inventory/" : "shop/"}${props.quests[props.activeTabLocal].reward.tmpl2}.png`} type="image/png" /> 
                                            <img src={`./images/${props.quests[props.activeTabLocal].reward.type == "weapon" ? "weapons/inventory/" : props.quests[props.activeTabLocal].reward.type == "jewelry" ? "jew/inventory/" : props.quests[props.activeTabLocal].reward.type == "armor" ? "armor/inventory/" : "shop/"}${props.quests[props.activeTabLocal].reward.tmpl2}.png`} alt="" />
                                        </picture>
                                    </span>
                                    <span className='st-3'>
                                        <picture>
                                            <source srcSet={`./images/${props.quests[props.activeTabLocal].reward.type == "weapon" ? "weapons/inventory/" : props.quests[props.activeTabLocal].reward.type == "jewelry" ? "jew/inventory/" : props.quests[props.activeTabLocal].reward.type == "armor" ? "armor/inventory/" : "shop/"}${props.quests[props.activeTabLocal].reward.tmpl3}.webp`} type="image/webp" />
                                            <source srcSet={`./images/${props.quests[props.activeTabLocal].reward.type == "weapon" ? "weapons/inventory/" : props.quests[props.activeTabLocal].reward.type == "jewelry" ? "jew/inventory/" : props.quests[props.activeTabLocal].reward.type == "armor" ? "armor/inventory/" : "shop/"}${props.quests[props.activeTabLocal].reward.tmpl3}.png`} type="image/png" /> 
                                            <img src={`./images/${props.quests[props.activeTabLocal].reward.type == "weapon" ? "weapons/inventory/" : props.quests[props.activeTabLocal].reward.type == "jewelry" ? "jew/inventory/" : props.quests[props.activeTabLocal].reward.type == "armor" ? "armor/inventory/" : "shop/"}${props.quests[props.activeTabLocal].reward.tmpl3}.png`} alt="" />
                                        </picture>
                                    </span>
                                    <span className='st-4'>
                                        <picture>
                                            <source srcSet={`./images/${props.quests[props.activeTabLocal].reward.type == "weapon" ? "weapons/inventory/" : props.quests[props.activeTabLocal].reward.type == "jewelry" ? "jew/inventory/" : props.quests[props.activeTabLocal].reward.type == "armor" ? "armor/inventory/" : "shop/"}${props.quests[props.activeTabLocal].reward.tmpl4}.webp`} type="image/webp" />
                                            <source srcSet={`./images/${props.quests[props.activeTabLocal].reward.type == "weapon" ? "weapons/inventory/" : props.quests[props.activeTabLocal].reward.type == "jewelry" ? "jew/inventory/" : props.quests[props.activeTabLocal].reward.type == "armor" ? "armor/inventory/" : "shop/"}${props.quests[props.activeTabLocal].reward.tmpl4}.png`} type="image/png" /> 
                                            <img src={`./images/${props.quests[props.activeTabLocal].reward.type == "weapon" ? "weapons/inventory/" : props.quests[props.activeTabLocal].reward.type == "jewelry" ? "jew/inventory/" : props.quests[props.activeTabLocal].reward.type == "armor" ? "armor/inventory/" : "shop/"}${props.quests[props.activeTabLocal].reward.tmpl4}.png`} alt="" />
                                        </picture>
                                    </span>
                                    
                                </div>
                                :
                                <picture>
                                    <source srcSet={`./images/${props.quests[props.activeTabLocal].reward.type == "weapon" ? "weapons/inventory/" : "atr" ? "tavern/" : "shop/"}${props.quests[props.activeTabLocal].reward.tmpl1}.webp`} type="image/webp" />
                                    <source srcSet={`./images/${props.quests[props.activeTabLocal].reward.type == "weapon" ? "weapons/inventory/" : "atr" ? "tavern/" :  "shop/"}${props.quests[props.activeTabLocal].reward.tmpl1}.png`} type="image/png" /> 
                                    <img src={`./images/${props.quests[props.activeTabLocal].reward.type == "weapon" ? "weapons/inventory/" : "atr" ? "tavern/" :  "shop/"}${props.quests[props.activeTabLocal].reward.tmpl1}.png`} alt="" />
                                </picture>
                                }
                            </div>
                            <div className="num-revard">
                            {props.tavern["quest" + (props.activeTabLocal + 1)] + "/" + props.quests[props.activeTabLocal].reward.many}
                            </div>
                            <div className="one-shafl-prod-info tooltip">
                                <div className="cust-tooltip right">
                                    <div className="tool-arrow"></div>
                                    <div className="tool-wrap">
                                        <div className="tool-wrap-1">
                                            <div className="tool-wrap-2">
                                                <div className="tool-body">
                                                    <span>
                                                    {props.tavern["quest" + (props.activeTabLocal + 1)] + "/" + props.quests[props.activeTabLocal].reward.many}    
                                                    </span> <br />
                                                    items left for all Heroes
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {props.quests[props.activeTabLocal].reward.type2 &&
                        <div className="reward-item-one">
                            <div className="reward-item-thumb">
                                <div className="reward-item-thumb-in">
                            
                                        <div className="one-drop-thumb-stone">
                                            
                                            <span className='st-1'>
                                                <picture>
                                                    <source srcSet={`./images/${props.quests[props.activeTabLocal].reward.type2 == "weapon" ? "weapons/inventory/" : props.quests[props.activeTabLocal].reward.type2 == "jewelry" ? "jew/inventory/" : "shop/"}${props.quests[props.activeTabLocal].reward.tmpl5}.webp`} type2="image/webp" />
                                                    <source srcSet={`./images/${props.quests[props.activeTabLocal].reward.type2 == "weapon" ? "weapons/inventory/" : props.quests[props.activeTabLocal].reward.type2 == "jewelry" ? "jew/inventory/" : "shop/"}${props.quests[props.activeTabLocal].reward.tmpl5}.png`} type2="image/png" /> 
                                                    <img src={`./images/${props.quests[props.activeTabLocal].reward.type2 == "weapon" ? "weapons/inventory/" : props.quests[props.activeTabLocal].reward.type2 == "jewelry" ? "jew/inventory/" : "shop/"}${props.quests[props.activeTabLocal].reward.tmpl5}.png`} alt="" />
                                                </picture>
                                            </span>
                                            <span className='st-2'>
                                                <picture>
                                                    <source srcSet={`./images/${props.quests[props.activeTabLocal].reward.type2 == "weapon" ? "weapons/inventory/" : props.quests[props.activeTabLocal].reward.type2 == "jewelry" ? "jew/inventory/" : "shop/"}${props.quests[props.activeTabLocal].reward.tmpl5}.webp`} type2="image/webp" />
                                                    <source srcSet={`./images/${props.quests[props.activeTabLocal].reward.type2 == "weapon" ? "weapons/inventory/" : props.quests[props.activeTabLocal].reward.type2 == "jewelry" ? "jew/inventory/" : "shop/"}${props.quests[props.activeTabLocal].reward.tmpl5}.png`} type2="image/png" /> 
                                                    <img src={`./images/${props.quests[props.activeTabLocal].reward.type2 == "weapon" ? "weapons/inventory/" : props.quests[props.activeTabLocal].reward.type2 == "jewelry" ? "jew/inventory/" : "shop/"}${props.quests[props.activeTabLocal].reward.tmpl5}.png`} alt="" />
                                                </picture>
                                            </span>
                                            <span className='st-3'>
                                                <picture>
                                                    <source srcSet={`./images/${props.quests[props.activeTabLocal].reward.type2 == "weapon" ? "weapons/inventory/" : props.quests[props.activeTabLocal].reward.type2 == "jewelry" ? "jew/inventory/" : "shop/"}${props.quests[props.activeTabLocal].reward.tmpl6}.webp`} type2="image/webp" />
                                                    <source srcSet={`./images/${props.quests[props.activeTabLocal].reward.type2 == "weapon" ? "weapons/inventory/" : props.quests[props.activeTabLocal].reward.type2 == "jewelry" ? "jew/inventory/" : "shop/"}${props.quests[props.activeTabLocal].reward.tmpl6}.png`} type2="image/png" /> 
                                                    <img src={`./images/${props.quests[props.activeTabLocal].reward.type2 == "weapon" ? "weapons/inventory/" : props.quests[props.activeTabLocal].reward.type2 == "jewelry" ? "jew/inventory/" : "shop/"}${props.quests[props.activeTabLocal].reward.tmpl6}.png`} alt="" />
                                                </picture>
                                            </span>
                                            <span className='st-4'>
                                                <picture>
                                                    <source srcSet={`./images/${props.quests[props.activeTabLocal].reward.type2 == "weapon" ? "weapons/inventory/" : props.quests[props.activeTabLocal].reward.type2 == "jewelry" ? "jew/inventory/" : "shop/"}${props.quests[props.activeTabLocal].reward.tmpl7}.webp`} type2="image/webp" />
                                                    <source srcSet={`./images/${props.quests[props.activeTabLocal].reward.type2 == "weapon" ? "weapons/inventory/" : props.quests[props.activeTabLocal].reward.type2 == "jewelry" ? "jew/inventory/" : "shop/"}${props.quests[props.activeTabLocal].reward.tmpl7}.png`} type2="image/png" /> 
                                                    <img src={`./images/${props.quests[props.activeTabLocal].reward.type2 == "weapon" ? "weapons/inventory/" : props.quests[props.activeTabLocal].reward.type == "jewelry" ? "jew/inventory/" : "shop/"}${props.quests[props.activeTabLocal].reward.tmpl7}.png`} alt="" />
                                                </picture>
                                            </span>
                                            
                                        </div>
                                </div>
                                <div className="num-revard">
                                {props.tavern["quest" + (props.activeTabLocal + 1)] + "/" + props.quests[props.activeTabLocal].reward.many}
                                </div>
                                <div className="one-shafl-prod-info tooltip">
                                    <div className="cust-tooltip right">
                                        <div className="tool-arrow"></div>
                                        <div className="tool-wrap">
                                            <div className="tool-wrap-1">
                                                <div className="tool-wrap-2">
                                                    <div className="tool-body">
                                                        <span>
                                                        {props.tavern["quest" + (props.activeTabLocal + 1)] + "/" + props.quests[props.activeTabLocal].reward.many}    
                                                        </span> <br />
                                                        items left for all Heroes
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            
            
            <div className={`quest-active ${props.quests[props.activeTabLocal].timer < Date.now() / 1000 && "hide"}`}>
                {props.quests[props.activeTabLocal].timer != "9999999999" ? 
                <>
                <div className="quest-active-top">
                    {/* the quest is already over */}
                    quest is active: 
                </div>
                
                <div className={`quest-active-timer`}>
                    <Countdown overtime="true"  date={props.quests[props.activeTabLocal].timer * 1000} />
                </div>
                </>
                : null
                 }
            </div>
            
            {props.quest["questdone" + (props.activeTabLocal + 1)] ?
            <div className="quest-complate"></div> : null }

            { 
            props.quests[props.activeTabLocal].timer < Date.now() / 1000 && !props.quest["questdone" + props.activeTabLocal] || 
            props.tavern["quest" + (props.activeTabLocal + 1)] == 0 && !props.quest["questdone" + props.activeTabLocal] ? 
            <div className="quest-close">
            </div>
            : null
            }

            <div className="quest-bt">
                {/* {console.log("props.quests[props.activeTabLocal].timer > Date.now()", props.quests[props.activeTabLocal].timer > Date.now() / 1000)}
                {console.log('!props.quest["questdone" + (props.activeTabLocal + 1)]', !props.quest["questdone" + (props.activeTabLocal + 1)])}
                {console.log('props.tavern["quest" + (props.activeTabLocal + 1)]', props.tavern["quest" + (props.activeTabLocal + 1)])}
                {console.log('!props.quest["quest" + (props.activeTabLocal + 1)]', !props.quest["quest" + (props.activeTabLocal + 1)])}
                {console.log("")} */}
                {props.quests[props.activeTabLocal].timer > Date.now() / 1000 && !props.quest["questdone" + (props.activeTabLocal + 1)] && props.tavern["quest" + (props.activeTabLocal + 1)] != 0 ? 
                    //!props.quest.quest3 
                    !props.quest["quest" + (props.activeTabLocal + 1)] ?
                    <button className="def-bt mid" onClick={() => props.getQuest(props.activeTabLocal)}>
                        <span className="button-in">
                            <span className="button-name">
                                <span className="button-name-in">
                                    <span className="button-name-value">
                                        get Quest
                                    </span> 
                                </span>
                            </span>
                        </span>
                    </button>
                    :
                        props.canComplateLocal ?
                        <button className="def-bt mid"  onClick={() => props.sendQuest(props.activeTabLocal)}>
                            <span className="button-in">
                                <span className="button-name">
                                    <span className="button-name-in">
                                        <span className="button-name-value">
                                            get reward
                                        </span> 
                                    </span>
                                </span>
                            </span>
                        </button>
                        :
                        <button className="def-bt mid" disabled>
                            <span className="button-in">
                                <span className="button-name">
                                    <span className="button-name-in">
                                        <span className="button-name-value">
                                            get reward
                                        </span> 
                                    </span>
                                </span>
                            </span>
                        </button>
                : null
                }
            </div>

        </div>
    </>);

}

export default OneTab;