import React from 'react';

import { connect } from "react-redux";

import Error from './Error';

class ErrorComponent extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            showModal: false,
            showModalBg: false,
            //errorOnGame: false, // ошибка для вывода карточек в игре
        }

        //this.lvlUpPanda = this.lvlUpPanda.bind(this);
        this.closeEror = this.closeEror.bind(this);

    }

    componentDidMount() {

        console.log("this.props.errorMessage", this.props)
        if (this.props.errorMessage) {
            this.setState({
                showModal: true,
                showModalBg: true
            })
        }

        setTimeout(() => {
            if (this.props.errorMessage) {
                this.setState({
                    ...this.state,
                    showModalBg: false,
                })
            }
        }, 1000);

        setTimeout(() => {
            if (this.props.errorMessage) {
                this.setState({
                    ...this.state,
                    showModal: false,
                })
                this.props.clearError();
            }
        }, 7000);


        //console.log('disp');
    }

    closeEror(e) {

        this.setState({
            ...this.state,
            showModal: false,
        })
        this.props.clearError();

    };


    render() {
        return (<>
            <Error  {...this.props} {...this.state} closeEror={this.closeEror} />
        </>);
    }
}

let mapStateToProps = (state) => ({
    //error: state.errorRed.Error,
});

export default connect(mapStateToProps, {})(ErrorComponent);