import React from 'react';
import musicHit from './../../../music/hit1.mp3'
var audioMusicHit = new Audio(musicHit);

function backside(num) {
    //console.log(4);
    var items = document.getElementsByClassName('mod-list-jew');
    items[num].classList.toggle("active");
    if(localStorage.getItem('sound') === 'true') {
        audioMusicHit.currentTime = 0;
        audioMusicHit.volume = 0.1;
        audioMusicHit.play();
    }
}

const SingleJew = (props) => {
    //console.log();
  return ( <>
    {props.allJew.length ? props.allJew.map((item, index) => {
        {
           //console.log('item', item)
        } 
        return (   

            <div className="one-card-list mod-list mod-list-jew" key={item.asset_id} >
                {/* {console.log(item)} */}
                <div className="one-card-list-in">
                    <div className="img-wrap">
                        <div className="open-info" onClick={(e) => {
                            backside(index);
                        }}></div>
                        <picture>
                            {/* <source srcSet={`./images/${props.type == "Jew" ? "jew" : "armor"}/all/${item.template.template_id}.webp`} type="image/webp" />
                            <source srcSet={`./images/${props.type == "Jew" ? "jew" : "armor"}/all/${item.template.template_id}.png`} type="image/png" />  */}
                            <img src={`./images/${props.type == "Jew" ? "jew" : "armor"}/all/${item.template.template_id}.png`} alt="" />
                        </picture>
                        <div className="one-card-bt">
                            {props.type == "Jew" ?
                            <button className="def-bt min append-card" onClick={(e) => {
                                    props.sendJew(index, item);
                                }} href="/" >
                                <span className="button-in">
                                    <span className="button-name">
                                        <span className="button-name-in">
                                            <span className="button-name-value">
                                                equip
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </button>
                            : 
                            <button className="def-bt min append-card" onClick={(e) => {
                                    props.sendArmor(index, item);
                                }} href="/" >
                                <span className="button-in">
                                    <span className="button-name">
                                        <span className="button-name-in">
                                            <span className="button-name-value">
                                                equip
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </button>
                            }
                        </div>
                    </div>
                </div>

                <div className="card-back-side">
                            
                    <div className="close-back-side" onClick={(e) => {
                            backside(index);
                        }}></div>
                    <div className="card-back-side-in">
                        
                        <div className="card-top-avat-descr">
                            <div className="card-top-avat jew-av">
                            </div>
                            <div className="card-top-avat-descr-info">
                            {props.type == "Jew" ? "jewelry" : "armor"} info
                            </div>
                            <div className="card-top-avat-descr-name">
                                { item.data.name }
                            </div>
                            <div className="card-top-avat-descr-list">
                                <ul>
                                    <li>
                                        ID: {item.asset_id}
                                    </li>
                                    <li>
                                        RARITY: {item.data.rarity}
                                    </li>
                                    <li>
                                        element: {item.data.element}
                                    </li>
                                    <li>
                                        type: {item.data.type}
                                    </li>
                                </ul>
                            </div>
                            <div className="card-top-avat-descr-copy">
                                NFT Panda
                                <span>
                                World of fantasy
                                </span>
                            </div>
                        </div>
                    </div>
                    
                </div>

            </div>
            
        
        

        );
        
        
    })
    : <div className="weaponNo">
        {props.type == "Jew" ? "You don't have any jewelry yet, it's time to buy your first jewelry!" : "You don't have any armor yet, it's time to buy your first armor!"}
        </div>}
    </>
    
  )
}

export default SingleJew;