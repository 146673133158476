const EATLIST = 'EATLIST';


let initialState = {
    allinvent: null,
};

const inventoryReduser = (state = initialState, action) => {

    switch(action.type) {
       
        case EATLIST: {
           // console.log('EATLIST', action.eatlist);
            return {...state, allinvent: action.eatlist}
        }
        default:
            return state;
    }
}

export const eatlist = (eatlist) => ({type: EATLIST, eatlist })

export default inventoryReduser;
