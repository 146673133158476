import React, { useState, useEffect, useRef } from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  Link,
  NavLink,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import Countdown from "react-countdown";
import ModalSuccess from "./ModalSuccess";
import ModalInfo from "../ModalInfo/ModalInfo";

import top from "../../images/auction/lot-top.png";
import topw from "../../images/auction/lot-top.webp";
import topDet from "../../images/auction/lot-info-top.png";
import topDetw from "../../images/auction/lot-info-top.webp";
import botDet from "../../images/auction/lot-info-bot.png";
import botDetw from "../../images/auction/lot-info-bot.webp";
import topAct from "../../images/auction/active-rate-top.png";
import topActw from "../../images/auction/active-rate-top.webp";
import botAct from "../../images/auction/active-rate-bot.png";
import botActw from "../../images/auction/active-rate-bot.webp";

import topActMob from "../../images/auction/active-rate-top-mob.png";
import topActMobw from "../../images/auction/active-rate-top-mob.webp";
import botActMob from "../../images/auction/active-rate-bot-mob.png";
import botActMobw from "../../images/auction/active-rate-bot-mob.webp";

import topLast from "../../images/auction/last-bets-top.png";
import topLastw from "../../images/auction/last-bets-top.webp";
import botLast from "../../images/auction/last-bets-bot.png";
import botLastw from "../../images/auction/last-bets-bot.webp";

import { JsonRpc } from "eosjs";
import { ExplorerApi } from "atomicassets";
import { split } from "lodash";
const api = new ExplorerApi(
  "https://" + localStorage.getItem('rpcA'),
  "atomicassets",
  { fetch, rateLimit: 4 }
);

// это транзакция отправки ИЗ Слота
const bet = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "addbets",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: { user: "", money: "", lotid: "" },
    },
  ],
};

// это транзакция отправки ИЗ Слота
const getlottrans = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "getlots",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: { user: "", lotid: "" },
    },
  ],
};

const Auction = (props) => {
  const [allLots, setallLots] = useState(undefined);
  const [formInfo, setformInfo] = useState(undefined);
  const [lotInfoDetal, setlotInfoDetal] = useState(undefined);
  const [betNum, setbetNum] = useState(false);
  const [activeNum, setactiveNum] = useState(0);
  const [showPrise, setshowPrise] = useState(false);
  const [showInfo, setshowInfo] = useState(false);

  const [textInfo, settextInfo] = useState(
    `1. If the time before the end of the auction is less than the Update time, then after each bid, the auction is extended by the specified Update time.<br />2. The expected time depends on the availability of the auction rooms. You can switch between them by clicking on the buttons with numbers 1–5 and a hammer at the bottom of the screen.`
  );

  // const [textInfo, settextInfo] = useState(
  //   `At the auction today will be available: <br />• EPIC ARMOR — 5. Expected start time — 14 UTC.<br />The base auction time: 20 minutes. Update time: 3 minutes.<br />• LEGENDARY ARMOR — 3. Expected start time — 15 UTC.<br />The base auction time: 25 minutes. Update time: 4 minutes.<br />• MYTHIC ARMOR — 1. Expected start time — 16 UTC.<br />The base auction time: 30 minutes. Update time: 5 minutes.<br /><br />1. If the time before the end of the auction is less than the Update time, then after each bid, the auction is extended by the specified Update time.<br />2. The expected time depends on the availability of the auction rooms. You can switch between them by clicking on the buttons with numbers 1–5 and a hammer at the bottom of the screen.<br />Starting prices:<br />• EPIC ARMOR — 500 ${process.env.REACT_APP_VAL}. Min step: 50 ${process.env.REACT_APP_VAL}.<br />• LEGENDARY ARMOR — 2000 ${process.env.REACT_APP_VAL}. Min step: 100 ${process.env.REACT_APP_VAL}.<br />• MYTHIC ARMOR — 8000 ${process.env.REACT_APP_VAL}. Min step: 200 ${process.env.REACT_APP_VAL}.<br /><br />Learn details about how the auction works on our special medium post. <br /><br /> <div><a target='_blank' href='https://nftpanda.medium.com/auction-details-and-rules-nft-panda-world-of-fantasy-4e286b9450a4' class='def-bt min'><span class='button-in'><span class='button-name'><span class='button-name-in'><span class='button-name-value'>read more</span></span></span></span></a></div>`
  // );


  let { url } = useRouteMatch();
  let { id } = useParams();
  //let person = find(parseInt(id));
  //console.log("url", url);
  //console.log("id", id);

  const timerReload = useRef(10);

  useEffect(() => {
    //console.log("TEST");

    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");

    userCheck(id);
    //timerReload.current.innerText = 30;

    const updateTimer = setInterval(() => {
      if (timerReload.current.innerText == 0) {
        timerReload.current.innerText = 10;
        userCheck(id);
      }
      timerReload.current.innerText--;
    }, 1000);

    // const timerId = setInterval(() => {
    //     // do something
    // }, 2000);

    return () => clearInterval(updateTimer);
  }, [id]);

  async function userCheck(id) {
    try {
      //console.log(555555555555555555555555555555555555555555555);
      //const activeUser = props.ual.activeUser;
      //const accountName = await activeUser.getAccountName();
      //console.log(activeUser);
      //console.log(accountName);
      let rpcc;
      if (props.ual.activeUser.rpc !== undefined) {
        rpcc = props.ual.activeUser.rpc;
      } else if (
        props.ual.activeUser.rpc === undefined &&
        props.ual.activeUser.wax
      ) {
        rpcc = props.ual.activeUser.wax.rpc;
      } else {
        rpcc = new JsonRpc(localStorage.getItem("rpc"));
      }
      const results = await rpcc.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "auctiontab", // name of the table as specified by the contract abi
        //"table": "usersnew",    // name of the table as specified by the contract abi
        limit: 1,
        /*upper_bound: id,
                lower_bound: id,*/
        //"key_type": "i64",
        //"index_position": 2,
        //"limit": -1,
        reverse: !0,
        show_payer: false,
        //index_position: 1,
      });

      //console.log("results", results);

      if (results && results != undefined) {
        const originRowUser = results.rows.find((o) => o.keyauto == id);
        setallLots(results.rows.reverse());

        //console.log("originRowUser", originRowUser);
        var newLastBet = Object.assign({}, originRowUser);
        // newLastBet.activesumstate =
        //   originRowUser.activesum / 10000 + originRowUser.step / 10000;
        // setactiveNum(
        //   originRowUser.activesum / 10000 + originRowUser.step / 10000
        // );

        var fixetSum = 0;
        if (originRowUser.activesum != 0) {
          fixetSum = originRowUser.activesum / 10000;
        }

        newLastBet.activesumstate = fixetSum + originRowUser.step / 10000;
        setactiveNum(fixetSum + originRowUser.step / 10000);


        // this.setState({
        //     formInfo: newLastBet
        // })

        setformInfo(newLastBet);
        getInfoFromAtomic(newLastBet.tmpl);
        setbetNum(fixetSum);
        var loadingSlot = document.getElementsByTagName("body")[0];
        loadingSlot.classList.remove("loading");
      }

    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      props.hasError(true, e.message);
      // this.setState({
      //     hasError: true,
      //     errorText: e.message,
      // });
      var loadingSlot = document.getElementsByTagName("body")[0];
      loadingSlot.classList.remove("loading");

      console.log("\nCaught exception: " + e);
    }
  }

  async function getInfoFromAtomic(tmpl) {

    if (tmpl != 111111) {

      try {
        const asset = await api.getTemplate("nftpandawaxp", tmpl);
        // this.setState({
        //     lotInfoDetal: asset.immutable_data
        // });
        setlotInfoDetal(asset.immutable_data);
        //console.log('assets', asset );
      } catch (e) {
        //console.log(e.message)
        console.warn(e);
        // this.setState({
        //     hasError: true,
        //     errorText: e.message,
        // });
        props.hasError(true, e.message);
        console.log("\nCaught exception: " + e);
      }

    }
  }

  async function dobet(money, lotid) {
    const userName = props.ual.activeUser.accountName;
    const activeUser = props.ual.activeUser;
    var moneysum = parseFloat(money).toFixed(4) * 10000;
    bet.actions[0].authorization[0].actor = userName;
    bet.actions[0].data.user = userName;
    bet.actions[0].data.money = moneysum;
    bet.actions[0].data.lotid = lotid;
    //console.log(bet);

    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");

    try {
      await activeUser
        .signTransaction(bet, { blocksBehind: 3, expireSeconds: 30 })
        .then((response) => {
          if (response.status === "executed") {
            loadingSlot.classList.remove("loading");

            var allBet = Object.assign({}, formInfo);
            allBet.lastbets.unshift(userName);
            allBet.lastsum.unshift(moneysum);
            allBet.activesumstate = moneysum / 10000 + allBet.step / 10000;
            if (allBet.dataend * 1000 - Date.now() <= allBet.timerel * 1000) {
              allBet.dataend = parseInt(
                (Date.now() + allBet.timerel * 1000) / 1000
              );
            }

            props.updateBalance(false, moneysum / 10000);
            if (formInfo.lastbets[0] == props.ual.activeUser.accountName) {
              props.updateBalance(true, formInfo.lastsum[1] / 10000);
            }

            setactiveNum(moneysum / 10000 + allBet.step / 10000);

            //console.log("Date.now() / 1000", parseInt(Date.now() / 1000));

            setformInfo(allBet);
            // this.setState({
            //     formInfo: allBet
            // })

            timerReload.current.innerText = 10;
          }
        });
    } catch (error) {
      //console.log(error.message)
      loadingSlot.classList.remove("loading");
      console.warn(error);
      // this.setState({
      //     hasError: true,
      //     errorText: error.message,
      // });
      props.hasError(true, error.message);
      // if(localStorage.getItem('sound') === 'true') {
      //     this.audioMusicError.currentTime = 0;
      //     this.audioMusicError.volume = 0.1;
      //     this.audioMusicError.play();
      // }
    }
  }

  async function getlot(lotid) {
    //console.log("lotid", lotid);
    const userName = props.ual.activeUser.accountName;
    const activeUser = props.ual.activeUser;
    getlottrans.actions[0].authorization[0].actor = userName;
    getlottrans.actions[0].data.user = userName;
    getlottrans.actions[0].data.lotid = lotid;
    //console.log(getlot);

    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");

    try {
      await activeUser
        .signTransaction(getlottrans, { blocksBehind: 3, expireSeconds: 30 })
        .then((response) => {
          if (response.status === "executed") {
            loadingSlot.classList.remove("loading");
            document
              .getElementsByTagName("body")[0]
              .classList.add("open-modal");

            setshowPrise(true);

            var allBet = Object.assign({}, formInfo);
            allBet.winner = userName;

            setformInfo(allBet);
          }
        });
    } catch (error) {
      //console.log(error.message)
      loadingSlot.classList.remove("loading");
      console.warn(error);
      // this.setState({
      //     hasError: true,
      //     errorText: error.message,
      // });
      props.hasError(true, error.message);
      // if(localStorage.getItem('sound') === 'true') {
      //     this.audioMusicError.currentTime = 0;
      //     this.audioMusicError.volume = 0.1;
      //     this.audioMusicError.play();
      // }
    }
  }

  function handleChangeInputUn(event) {
    if (event.target.value >= 0) {
      setactiveNum(event.target.value);
    }
  }

  function max() {
    setactiveNum(props.balanceGame);
  }

  function closeModalPrise() {
    setshowPrise(false);
    document.getElementsByTagName("body")[0].classList.remove("open-modal");
  }

  function closeModalInfo(e) {
    if (e) {
      setshowInfo(true);
      document.getElementsByTagName("body")[0].classList.add("open-modal");
    } else {
      setshowInfo(false);
      document.getElementsByTagName("body")[0].classList.remove("open-modal");
    }
  }

  return (
    <>
      {formInfo != undefined && showPrise && (
        <ModalSuccess
          types={formInfo.types}
          tmpl={formInfo.tmpl}
          closeModalPrise={closeModalPrise}
        />
      )}
      {showInfo && (
        <ModalInfo closeModalInfo={closeModalInfo} textInfo={textInfo} />
      )}

      <div className="auction-in">
        <div className="nav-lots">
          {allLots != undefined &&
            allLots.map((item, index) => {
              if (
                props.ual.activeUser.accountName != "hwuuo.wam"
                //&& props.ual.activeUser.accountName != "itisonlymone"
              ) {
                return (
                  <NavLink
                    className={`one tooltip ${item.dataend * 1000 < Date.now() && "done"
                      }`}
                    to={`/auction/${item.keyauto}`}
                    activeClassName="active"
                  >
                    <span>{index + 1}</span>
                    <div className="cust-tooltip top">
                      <div className="tool-arrow"></div>
                      <div className="tool-wrap">
                        <div className="tool-wrap-1">
                          <div className="tool-wrap-2">
                            <div className="tool-body">
                              <div className="tool-auc">
                                <picture>
                                  <source
                                    srcSet={`../images/${item.types == "weapon"
                                      ? "weapons"
                                      : item.types == "mystery"
                                        ? "auction"
                                        : "armor"
                                      }/all/${item.tmpl}.png`}
                                    type="image/webp"
                                  />
                                  <source
                                    srcSet={`../images/${item.types == "weapon"
                                      ? "weapons"
                                      : item.types == "mystery"
                                        ? "auction"
                                        : "armor"
                                      }/all/${item.tmpl}.png`}
                                    type="image/png"
                                  />
                                  <img
                                    src={`../images/${item.types == "weapon"
                                      ? "weapons"
                                      : item.types == "mystery"
                                        ? "auction"
                                        : "armor"
                                      }/all/${item.tmpl}.png`}
                                    alt=""
                                  />
                                </picture>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                );
              } else if (
                props.ual.activeUser.accountName == "hwuuo.wam"
                //|| props.ual.activeUser.accountName == "itisonlymone"
              ) {
                return (
                  <NavLink key={index}
                    className={`one tooltip ${item.dataend * 1000 < Date.now() && "done"
                      }`}
                    to={`/auction/${item.keyauto}`}
                    activeClassName="active"
                  >
                    <span>{index + 1}</span>
                    <div className="cust-tooltip top show">
                      <div className="tool-arrow"></div>
                      <div className="tool-wrap">
                        <div className="tool-wrap-1">
                          <div className="tool-wrap-2">
                            <div className="tool-body">
                              <div className="tool-auc">
                                <picture>
                                  <source
                                    srcSet={`../images/${item.types == "weapon"
                                      ? "weapons"
                                      : item.types == "mystery"
                                        ? "auction"
                                        : "armor"
                                      }/all/${item.tmpl}.png`}
                                    type="image/webp"
                                  />
                                  <source
                                    srcSet={`../images/${item.types == "weapon"
                                      ? "weapons"
                                      : item.types == "mystery"
                                        ? "auction"
                                        : "armor"
                                      }/all/${item.tmpl}.png`}
                                    type="image/png"
                                  />
                                  <img
                                    src={`../images/${item.types == "weapon"
                                      ? "weapons"
                                      : item.types == "mystery"
                                        ? "auction"
                                        : "armor"
                                      }/all/${item.tmpl}.png`}
                                    alt=""
                                  />
                                </picture>
                              </div>
                              <div className="info-auc">
                                <div className="">
                                  <div className="name-param">start</div>
                                  <Countdown
                                    daysInHours
                                    date={item.datastart * 1000}
                                  />
                                </div>
                                <div className="">
                                  <div className="name-param">end</div>
                                  <Countdown
                                    daysInHours
                                    date={item.dataend * 1000}
                                  />
                                </div>
                                <div className="">
                                  <div className="name-param">sum</div>
                                  {item.activesum / 10000}
                                </div>
                                <div className="">
                                  <div className="name-param">user</div>
                                  {item.lastbets[0]}
                                  <br />
                                  {item.lastbets[1]}
                                  <br />
                                  {item.lastbets[2]}
                                  <br />
                                  {item.lastbets[3]}
                                  <br />
                                  {item.lastbets[4]}
                                  <br />
                                  {item.lastbets[5]}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                );
              }
            })}
        </div>

        <div className="active-rate">
          <div className="active-rate-top">
            <picture>
              <source
                srcSet={topActw}
                type="image/webp"
                media="(min-width: 768px)"
              />
              <source
                srcSet={topAct}
                type="image/jpg"
                media="(min-width: 768px)"
              />
              <source
                srcSet={topActMobw}
                type="image/webp"
                media="(max-width: 767px)"
              />
              <source
                srcSet={topActMob}
                type="image/jpg"
                media="(max-width: 767px)"
              />
              <img className="lot-img" src={topActw} alt="" />
            </picture>
            {formInfo &&
              formInfo.lastbets &&
              formInfo.lastbets.length &&
              formInfo.datastart * 1000 < Date.now() &&
              formInfo.dataend * 1000 > Date.now() ? (
              <div
                className={`auction-timer ${formInfo.dataend * 1000 - Date.now() <=
                  formInfo.timerel * 1000 && "red"
                  }`}
              >
                <div className="auction-timer-tit">Auction end in:</div>
                <div className="auction-timer-num">
                  <div className="inside-timer">
                    <Countdown
                      //daysInHours
                      date={formInfo.dataend * 1000}
                      onComplete={(e) => {
                        userCheck();
                      }}
                    />
                    <div className="auction-info tooltip">
                      <div className="cust-tooltip bottom">
                        <div className="tool-arrow"></div>
                        <div className="tool-wrap">
                          <div className="tool-wrap-1">
                            <div className="tool-wrap-2">
                              <div className="tool-body">
                                update time <span>{formInfo.timerel / 60}</span>{" "}
                                minutes
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              formInfo &&
              formInfo.lastbets &&
              formInfo.lastbets.length &&
              formInfo.dataend * 1000 < Date.now() && (
                <div className="auction-timer">
                  <div className="auction-timer-tit">Auction end</div>
                </div>
              )
            )}
          </div>
          <div className="active-rate-wrap">
            <div className="active-rate-wrap-in">
              {formInfo &&
                formInfo.datastart * 1000 < Date.now() &&
                formInfo.dataend * 1000 > Date.now() ? (
                <>
                  <div className="hight-bit">
                    {formInfo &&
                      formInfo.lastbets &&
                      formInfo.lastbets.length && (
                        <>
                          highest bet:
                          <span> {formInfo.lastsum[0] / 10000} </span>
                          bam
                          {formInfo.lastbets[0] && <br />}
                          {formInfo.lastbets[0] && "by "}
                          <i
                            className={`${formInfo.lastbets[0] ==
                              props.ual.activeUser.accountName && "active"
                              }`}
                          >
                            {formInfo.lastbets[0] && formInfo.lastbets[0]}
                          </i>
                        </>
                      )}
                  </div>
                  <div className="do-rate">
                    <div className="do-rate-in">
                      <input
                        type="text"
                        value={
                          betNum != 0 && activeNum === 0
                            ? formInfo.activesumstate
                            : activeNum
                        }
                        onChange={handleChangeInputUn}
                      />
                      <span>{process.env.REACT_APP_VAL}:</span>
                    </div>
                    <div className="do-rate-bt">
                      <span onClick={() => max()}>max</span>
                    </div>
                  </div>
                </>
              ) : formInfo &&
                formInfo.lastbets &&
                formInfo.lastbets.length &&
                formInfo.dataend * 1000 > Date.now() ? (
                <div className="timer-before-start">
                  <div className="timer-bef-top">auction starts in</div>
                  <div className="timer-bef-bot">
                    <Countdown
                      daysInHours
                      date={formInfo.datastart * 1000}
                      onComplete={(e) => {
                        userCheck();
                      }}
                    />
                  </div>
                </div>
              ) : formInfo &&
                formInfo.lastbets &&
                formInfo.lastbets.length &&
                formInfo.lastbets[0] == props.ual.activeUser.accountName ? (
                <div className="timer-before-start">
                  {formInfo.winner == "" ? (
                    <div className="you-win">
                      congratulation!
                      <span>your bet has won</span>
                    </div>
                  ) : (
                    <div className="you-win no-pad">
                      congratulation!
                      <span>you have already claim your reward</span>
                    </div>
                  )}
                </div>
              ) : (
                formInfo &&
                formInfo.lastbets &&
                formInfo.lastbets.length && (
                  <div className="timer-before-start">
                    <div className="timer-bef-top">auction is over!</div>
                    <div className="over-text">
                      lot goes to
                      <span>{formInfo.lastbets[0]}</span>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
          <div className="active-rate-bot">
            <picture>
              <source
                srcSet={botActw}
                media="(min-width: 768px)"
                type="image/webp"
              />
              <source
                srcSet={botAct}
                media="(min-width: 768px)"
                type="image/jpg"
              />
              <source
                srcSet={botActMobw}
                type="image/webp"
                media="(max-width: 767px)"
              />
              <source
                srcSet={botActMob}
                type="image/jpg"
                media="(max-width: 767px)"
              />
              <img className="lot-img" src={botActw} alt="" />
            </picture>
            {formInfo &&
              formInfo.lastbets &&
              formInfo.lastbets.length &&
              formInfo.datastart * 1000 < Date.now() &&
              formInfo.dataend * 1000 > Date.now() ? (
              <button
                className="def-bt norm"
                onClick={() => dobet(activeNum, id)}
              >
                <span className="button-in">
                  <span className="button-name">
                    <span className="button-name-in">
                      <span className="button-name-value">make a bet</span>
                    </span>
                  </span>
                </span>
              </button>
            ) : (
              formInfo &&
              formInfo.lastbets &&
              formInfo.lastbets.length &&
              formInfo.lastbets[0] == props.ual.activeUser.accountName &&
              formInfo.winner == "" && (
                <button className="def-bt norm" onClick={() => getlot(id)}>
                  <span className="button-in">
                    <span className="button-name">
                      <span className="button-name-in">
                        <span className="button-name-value">claim</span>
                      </span>
                    </span>
                  </span>
                </button>
              )
            )}
          </div>
        </div>

        <div className="lot">
          <NavLink className="back" to="/tavern"></NavLink>
          <div className="lot-top">
            <picture>
              <source srcSet={topw} type="image/webp" />
              <source srcSet={top} type="image/jpg" />
              <img className="lot-img" src={topw} alt="" />
            </picture>
            {formInfo != undefined && (
              <span>
                {lotInfoDetal && lotInfoDetal != undefined && lotInfoDetal.name}
              </span>
            )}
          </div>
          <div className="lot-wrap">
            <div className="lot-wrap-in">
              {formInfo != undefined && (
                <div className={`lot-thumb ${formInfo.types}`}>
                  <span>
                    {/* <picture>
                      <source
                        srcSet={`../images/${formInfo.types == "weapon" ? "weapons" : "armor"
                          }/all/${formInfo.tmpl}.png`}
                        type="image/webp"
                      />
                      <source
                        srcSet={`../images/${formInfo.types == "weapon" ? "weapons" : "armor"
                          }/all/${formInfo.tmpl}.png`}
                        type="image/png"
                      />
                      <img
                        src={`../images/${formInfo.types == "weapon" ? "weapons" : "armor"
                          }/all/${formInfo.tmpl}.png`}
                        alt=""
                      />
                    </picture> */}
                    <picture>
                      <source
                        srcSet={`../images/${formInfo.types == "weapon"
                          ? "weapons"
                          : formInfo.types == "mystery"
                            ? "auction"
                            : "armor"
                          }/all/${formInfo.tmpl}.png`}
                        type="image/webp"
                      />
                      <source
                        srcSet={`../images/${formInfo.types == "weapon"
                          ? "weapons"
                          : formInfo.types == "mystery"
                            ? "auction"
                            : "armor"
                          }/all/${formInfo.tmpl}.png`}
                        type="image/png"
                      />
                      <img
                        src={`../images/${formInfo.types == "weapon"
                          ? "weapons"
                          : formInfo.types == "mystery"
                            ? "auction"
                            : "armor"
                          }/all/${formInfo.tmpl}.png`}
                        alt=""
                      />
                    </picture>
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="lot-detal">
            <div className="lot-detal-top">
              <picture>
                <source srcSet={topDetw} type="image/webp" />
                <source srcSet={topDet} type="image/jpg" />
                <img className="lot-img" src={topDetw} alt="" />
              </picture>
              <span>details</span>
            </div>
            <div className="lot-body">
              <div className="lot-detal-wrap">
                <div className="params-lot">
                  {lotInfoDetal && lotInfoDetal != undefined && formInfo.tmpl != 111111 && (
                    <ul>
                      <li>
                        <span>type:</span>
                        {lotInfoDetal.type}
                      </li>
                      <li>
                        <span>element:</span>
                        {lotInfoDetal.element}
                      </li>
                      <li>
                        <span>rarity:</span>
                        {lotInfoDetal.rarity}
                      </li>
                    </ul>
                  )}

                  {formInfo != undefined && formInfo.tmpl == 111111 && (
                    <ul>
                      <li>
                        <span>type:</span>
                        Mystery box
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div className="lot-detal-bot">
              <picture>
                <source srcSet={botDetw} type="image/webp" />
                <source srcSet={botDet} type="image/jpg" />
                <img className="lot-img" src={botDetw} alt="" />
              </picture>
            </div>
          </div>
        </div>

        <div className="last-bets">
          <div className="info-auction">
            <div className="reload-page-after">
              reload <br />
              after <i ref={timerReload}></i> sec
            </div>

            <button
              className="def-bt norm"
              onClick={() => closeModalInfo(true)}
            >
              <span className="button-in">
                <span className="button-name">
                  <span className="button-name-in">
                    <span className="button-name-value">info</span>
                  </span>
                </span>
              </span>
            </button>
          </div>
          <div className="last-bets-top">
            <picture>
              <source srcSet={topLastw} type="image/webp" />
              <source srcSet={topLast} type="image/jpg" />
              <img className="lot-img" src={topLastw} alt="" />
            </picture>
            <span>
              <span>Latest bets</span>
            </span>
          </div>
          <div className="last-bets-wrap">
            <div className="last-bets-list">
              {formInfo &&
                formInfo.lastbets &&
                formInfo.lastbets.length &&
                formInfo.lastbets[0] != 0 &&
                formInfo.lastbets.map((item, index) => {
                  if (index != 5 && formInfo.lastbets[index] != 0) {
                    return (
                      <div className="one-bets">
                        <Link to={`../lk/${item}`}
                          className={`${item == props.ual.activeUser.accountName && "active"
                            }`}>
                          <span>
                            {item}
                          </span>{" "}
                        </Link>
                        raises <br />
                        the bet to{" "}
                        <span>{formInfo.lastsum[index] / 10000} {process.env.REACT_APP_VAL}</span>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
          <div className="last-bets-bot">
            <picture>
              <source srcSet={botLastw} type="image/webp" />
              <source srcSet={botLast} type="image/jpg" />
              <img className="lot-img" src={botLastw} alt="" />
            </picture>
          </div>
        </div>
      </div>
    </>
  );
};

export default Auction;
