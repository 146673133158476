import React, { useEffect, useRef, useState } from "react";
import p5 from "p5";
import History from "./History";
import Info from "./Info";
import PanelHero from "./PanelHero";
import ActivePandaInfo from "./ActivePandaInfo";
import { JsonRpc } from "eosjs";
import { detalPanda } from '../PandaNameInfo';

import "./../../css/boss.scss";

import bgmap from './../../images/BOSS/bg.jpg'
import boss from './../../images/BOSS/boss.webp'
import font from './../../fonts/troika.otf'

import panelBg from './../../images/BOSS/hero-panel.png'
import panelBgw from './../../images/BOSS/hero-panel.webp'

let Sketch = (p, setWidthScale, setWindowWidth) => {
    let myFont;
    //animation boss
    let sprite;
    let frames = [];
    let currentFrame = 0;
    const frameSize = 800; // size of individual frame
    const totalFrames = 20; // total number of frames
    const animationSpeed = 0.02; // controls the speed of animation
    let lastTime = 0;
    // end animation boss

    let img;
    let imgWidth = 2560;
    let imgHeight = 1440;

    let widthLine = 1120;
    let heightLine = 72;
    let topLine = 90;
    let widthScale = p.windowWidth / imgWidth;
    let spriteScale = p.windowHeight / imgHeight;
    setWidthScale(widthScale);
    setWindowWidth(p.windowWidth);

    let pointImages = [];

    p.preload = () => {
        myFont = p.loadFont(font);
        img = p.loadImage(bgmap); // bg
        sprite = p.loadImage(boss); // boss
    };

    p.setup = () => {
        let canvasHeight = p.windowHeight;
        let canvasWidth = p.windowWidth;

        imgWidth = img.width;
        imgHeight = img.height;

        p.createCanvas(canvasWidth, canvasHeight);
        p.imageMode(p.CENTER);
        drawImage(); // boss

        //boss
        for (let i = 0; i < totalFrames; i++) {
            let img = sprite.get(i * frameSize, 0, frameSize, frameSize);
            frames.push(img);
        }
        //end boss
    };

    p.draw = () => {
        p.clear();
        drawImage();
        let currentTime = p.millis() * animationSpeed;
        if (currentTime - lastTime > 1) {
            lastTime = currentTime;
            currentFrame = (currentFrame + 1) % totalFrames;
        }
        spriteScale = p.windowHeight / imgHeight;
        p.imageMode(p.CORNER);
        p.image(frames[currentFrame], (p.windowWidth / 2) - ((frameSize * spriteScale) / 2), p.windowHeight - ((280 * widthScale) + (frameSize * spriteScale)), frameSize * spriteScale, frameSize * spriteScale);
        p.imageMode(p.CENTER);

        drawHealth();

    };

    p.windowResized = () => {
        let canvasHeight = p.windowHeight;
        let canvasWidth = p.windowWidth;

        p.resizeCanvas(canvasWidth, canvasHeight);

        setWidthScale(widthScale);
        setWindowWidth(p.windowWidth);

        drawImage();
    };

    function drawImage() {
        let windowRatio = p.windowWidth / p.windowHeight;
        let imgRatio = imgWidth / imgHeight;

        let drawWidth, drawHeight;

        // Если соотношение сторон окна больше, чем соотношение сторон изображения
        if (windowRatio > imgRatio) {
            drawWidth = p.windowWidth;
            drawHeight = p.windowWidth / imgRatio;
        } else {
            drawHeight = p.windowHeight;
            drawWidth = p.windowHeight * imgRatio;
        }

        let x = (p.windowWidth - drawWidth) / 2;
        let y = p.windowHeight - drawHeight;

        p.imageMode(p.CORNER); // Устанавливаем режим отображения на CORNER
        p.image(img, x, y, drawWidth, drawHeight);
        p.imageMode(p.CENTER);
    }

    function drawHealth() {
        widthScale = p.windowWidth / imgWidth;
        setWidthScale(widthScale);
        let centerLine = (p.windowWidth - (widthLine) * widthScale) / 2;

        for (let i = (topLine + 17) * widthScale; i <= (topLine + 17 + 40) * widthScale; i++) {
            let c;
            if (i <= (topLine + 56 / 2) * widthScale) {
                let inter = p.map(i, 0, (topLine + 56 / 2) * widthScale, 0, 1);
                c = p.lerpColor(p.color(191, 60, 51), p.color(240, 128, 115), inter);
            } else {
                let inter = p.map(i, (topLine + 56 / 2) * widthScale, (topLine + 17 + 56) * widthScale, 0, 1);
                c = p.lerpColor(p.color(240, 128, 115), p.color(191, 60, 51), inter);
            }
            p.stroke(c);
            p.line(centerLine + (17 * widthScale) + (((widthLine - 34) * widthScale / 100) * (100 / 1000000 * 826247)), i, centerLine + (17 * widthScale), i);
        }

        // for (let i = 137 * widthScale; i <= 193 * widthScale; i++) {
        //     let c;
        //     if (i <= 155 * widthScale) {
        //         let inter = p.map(i, 0, 155 * widthScale, 0, 1);
        //         c = p.lerpColor(p.color(191, 60, 51), p.color(240, 128, 115), inter);
        //     } else {
        //         let inter = p.map(i, 155 * widthScale, 193 * widthScale, 0, 1);
        //         c = p.lerpColor(p.color(240, 128, 115), p.color(191, 60, 51), inter);
        //     }
        //     p.stroke(c);
        //     p.line(centerLine + (17 * widthScale) + (((widthLine - 34) * widthScale / 100) * (100 / 1000000 * 826247)), i, centerLine + (17 * widthScale), i);
        // }


        // отступ зліва, зправа, зверху, знизу
        p.stroke(0);
        p.strokeWeight(5 * widthScale);
        p.rect((centerLine), topLine * widthScale, widthLine * widthScale, heightLine * widthScale, 50); // Added corner radius

        p.stroke(0);
        p.stroke(215, 192, 177);
        p.strokeWeight(3 * widthScale);
        p.rect((centerLine) + 4 * widthScale, (topLine + 4) * widthScale, (widthLine - 8) * widthScale, (heightLine - 8) * widthScale, 50); // Added corner radius

        p.noFill();
        p.stroke(188, 143, 121);
        p.strokeWeight(3 * widthScale);
        p.rect(centerLine + 7 * widthScale, (topLine + 7) * widthScale, (widthLine - 14) * widthScale, (heightLine - 14) * widthScale, 50); // Added corner radius

        p.noFill();
        p.stroke(0);
        p.strokeWeight(3 * widthScale);
        p.rect(centerLine + 10 * widthScale, (topLine + 10) * widthScale, (widthLine - 20) * widthScale, (heightLine - 20) * widthScale, 50); // Added corner radius

        p.textFont(myFont);
        p.textSize(57 * widthScale);
        p.strokeWeight(10 * widthScale);
        p.fill(255);
        p.textAlign(p.CENTER, p.CENTER);
        p.text('nimnirax', 0, 15 * widthScale, p.width, 57 * widthScale);
        p.noFill();

        p.textFont(myFont);
        p.textSize(44 * widthScale);
        p.strokeWeight(10 * widthScale);
        p.fill(255);
        p.textAlign(p.CENTER, p.CENTER);
        p.text('826247/1000000', 0, 100 * widthScale, p.width, 44 * widthScale);
        p.noFill();
    }

    // p.mouseMoved = () => {
    //     p.setup();
    // };
};


let numRpc = 0;
let arrRpc = ['wax.pink.gg', 'wax.cryptolions.io', 'wax.greymass.com', 'api.wax.alohaeos.com', 'api.waxsweden.org', 'wax.eosphere.io', 'api.wax.greeneosio.com', 'wax.eu.eosamsterdam.net', 'api.wax.bountyblok.io']

const Boss = (props) => {
    const canvasRef = useRef(null);
    const [widthScale, setWidthScale] = useState(null);
    const [windowWidth, setWindowWidth] = useState(null);

    useEffect(() => {
        const myP5 = new p5((p) => Sketch(p, setWidthScale, setWindowWidth), canvasRef.current);

        return () => {
            myP5.remove();
        };
    }, []);

    const [accountName, setUser] = useState(null);
    const [hasError, setHasError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [userTable, setUserTable] = useState(null);

    const [allPandaInGame, setAllPandaInGame] = useState(null);
    const [allPandaWeapon, setAllPandaWeapon] = useState(null);
    const [allPandaJew, setAllPandaJew] = useState(null);
    const [allPandaArmor, setAllPandaArmor] = useState(null);

    const [allPandaInfo, setAllPandaInfo] = useState(null);

    const [wasLoadUser, setWasLoadUser] = useState(false);
    const [wasLoadPanda, setWasLoadPanda] = useState(false);
    const [wasLoadWeapon, setWasLoadWeapon] = useState(false);
    const [wasLoadJew, setWasLoadJew] = useState(false);
    const [wasLoadArmor, setWasLoadArmor] = useState(false);
    const [wasLoadAll, setWasLoadAll] = useState(false);

    const [activePandaIndex, setActivePandaIndex] = useState("");

    const getAccountName = async (activeUser) => {
        try {
            const accountName = await activeUser.getAccountName();
            setUser(accountName);
        } catch (error) {
            console.error('Error fetching account name:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const accountName = props.activeUser.accountName;
            setUser(accountName);
            await getArraySlots(accountName);
            await GetPandaListOnGame(accountName);
            await getItemsSlotWax(accountName, "nftweapons", setAllPandaWeapon, setWasLoadWeapon);
            await getItemsSlotWax(accountName, "nftjew", setAllPandaJew, setWasLoadJew);
            await getItemsSlotWax(accountName, "nftarmor", setAllPandaArmor, setWasLoadArmor);
        };

        fetchData();
    }, [props.activeUser.accountName]);

    useEffect(() => {
        if (wasLoadUser && wasLoadPanda && wasLoadWeapon && wasLoadJew && wasLoadArmor) {
            getAllInfo();
        }
    }, [wasLoadPanda, wasLoadWeapon, wasLoadJew, wasLoadArmor, wasLoadUser]);

    const handleError = (e) => {
        console.warn(e);
        setHasError(true);
        setErrorText(e.message);
    }

    const createRpc = () => new JsonRpc("https://" + arrRpc[numRpc]);

    const callApi = async (params) => {
        try {
            const rpcc = createRpc();
            const results = await rpcc.get_table_rows(params);
            return results;
        } catch (e) {
            handleError(e);
        }
    }

    const getArraySlots = async (accountName) => {
        try {
            const results = await callApi({
                json: true,
                code: process.env.REACT_APP_CONTRACT,
                scope: process.env.REACT_APP_CONTRACT,
                table: "usersnew",
                limit: 1,
                lower_bound: accountName,
                upper_bound: accountName,
                index_position: 1,
                reverse: false,
                show_payer: false,
            });

            const resultRow = results.rows[0];

            if (resultRow) {
                setUserTable(resultRow);
                setWasLoadUser(true);
                let firstNonZeroPandaIndex = resultRow.slots_count.findIndex(item => item !== 0);
                setActivePandaIndex(firstNonZeroPandaIndex);
            }
        } catch (e) {
            handleError(e);
        }
    }

    const GetPandaListOnGame = async (accountName) => {
        try {
            const results = await callApi({
                json: true,
                code: process.env.REACT_APP_CONTRACT,
                scope: process.env.REACT_APP_CONTRACT,
                table: "nftsongamec",
                key_type: "i64",
                lower_bound: accountName,
                upper_bound: accountName,
                limit: -1,
                reverse: !0,
                show_payer: false,
                index_position: 2,
            });

            const resultRow = results.rows;
            const resultRowFilter = resultRow.filter(
                (row) => row.storer === accountName && row.is_in_slot === 0
            );

            if (resultRow && resultRow.length) {
                const pandaObSlot = resultRow
                    .filter((panda) => panda.is_in_slot)
                    .map((panda) => ({ ...panda, activePanda: false }));
                setAllPandaInGame(pandaObSlot);
                setWasLoadPanda(true);
            }
        } catch (e) {
            handleError(e);
        }
    }

    const getItemsSlotWax = async (accountName, table, setter, load) => {
        try {
            const items = await callApi({
                json: true,
                code: process.env.REACT_APP_CONTRACT,
                scope: process.env.REACT_APP_CONTRACT,
                table: table,
                limit: 100,
                lower_bound: accountName,
                upper_bound: accountName,
                key_type: "i64",
                index_position: 2,
                reverse: false,
                show_payer: false,
            });

            if (items?.rows && items?.rows?.length) {
                setter(items.rows);
                load(true);
            }
        } catch (e) {
            handleError(e);
        }
    }

    const getAllInfo = async () => {
        try {

            let allInfo = allPandaInGame.map(item => {
                let copy = { ...item };

                if (item["weapon"] && allPandaWeapon && allPandaWeapon.length) {
                    copy["weapon"] = allPandaWeapon.find(weapon => item["weapon"] === weapon.asset_id);
                }
                if (item["jew"] && allPandaJew && allPandaJew.length) {
                    copy["jew"] = allPandaJew.find(jew => item["jew"] === jew.asset_id);
                }
                if (item["armor"] && allPandaArmor && allPandaArmor.length) {
                    copy["armor"] = allPandaArmor.find(armor => item["armor"] === armor.asset_id);
                }
                return copy;
            });

            const orderedArray = userTable.slots_count.map((item, index) => {
                if (item === 0) {
                    // вставить пустой объект или оставить ноль
                    return 0; // или return 0;
                } else {
                    // найти соответствующий объект в хаосном массиве
                    const pandaInfo = detalPanda(item, index, allInfo);

                    return pandaInfo;
                }
            });

            setAllPandaInfo(orderedArray);
            setWasLoadAll(true);


        } catch (e) {
            handleError(e);
        }
    }


    const nowActivePanda = (index) => {
        setActivePandaIndex(index);
    }

    /*
    const [wasLoadUser, setWasLoadUser] = useState(false);
    const [wasLoadPanda, setWasLoadPanda] = useState(false);
    const [wasLoadWeapon, setWasLoadWeapon] = useState(false);
    const [wasLoadJew, setWasLoadJew] = useState(false);
    const [wasLoadArmor, setWasLoadArmor] = useState(false);
    */


    return (
        <div className="boss-wrap">
            <div
                ref={canvasRef}
                id="canvas-container"
                style={{ overflowX: "scroll", width: "100%", height: "100%" }}
            ></div>
            {widthScale && <History widthScale={widthScale} />}
            {widthScale && <Info widthScale={widthScale} />}

            <div className="panel-hero">
                <div className="panel-hero-in">
                    <div className="panel-hero-bg">
                        <picture>
                            <source srcSet={panelBgw} type="image/webp" />
                            <source srcSet={panelBg} type="image/png" />
                            <img src={panelBgw} alt="" style={
                                {
                                    width: windowWidth > 1924 ? 1924 : widthScale * 2560,
                                    //width: windowWidth > 1756 ? 1756 : widthScale * 2560,
                                    //height: widthScale * 399
                                }
                            } />
                        </picture>
                    </div>

                    {widthScale && wasLoadUser && allPandaInGame && allPandaWeapon && allPandaJew && allPandaArmor && wasLoadAll &&
                        < PanelHero
                            widthScale={widthScale}
                            windowWidth={windowWidth}
                            userTable={userTable}
                            allPandaInfo={allPandaInfo}
                            activePandaIndex={activePandaIndex}
                            nowActivePanda={nowActivePanda}
                        />
                    }
                    {wasLoadAll &&
                        <ActivePandaInfo
                            activePandaInfo={allPandaInfo[activePandaIndex]}
                            widthScale={widthScale}
                            windowWidth={windowWidth}
                        />
                    }
                </div>
            </div>
        </div>
    );
};


export default Boss;