import React from 'react';
import modaltop from '../../../images/weapons/all-weapon-header.png';
import modalbot from '../../../images/weapons/all-weapon-footer.png';
import { NavLink } from 'react-router-dom';
import musicHit from './../../../music/hit1.mp3'

import SingleWeapon from './SingleWeapon';
var audioMusicHit = new Audio(musicHit);


const ModalAllWeapon = (props) => {
   
    return (<>
        
        <div className={`modal-all-weapon open`}>
            {props.modalAllWeapon.loadingWeapon ? <div className="loader-choose-hero"></div> : null }
            <div className="modal-all-weapon-body">
                <div className="modal-all-weapon-body-in">

                    <div className="modal-all-weapon-head">
                        
                        <div className="modal-all-weapon-top">
                            <button className="modal-all-weapon-close" onClick={() => props.showCloseAllWeaponModal('close')}><span></span></button>
                            <img src={modaltop} alt="" />
                        </div>
                        <div className="modal-all-weapon-wrap">
                            
                            <div className="modal-all-weapon-wrap-top">

                            </div>

                            <div className="modal-all-weapon-wrap-center">

                                <div className="modal-all-weapon-list">
                                    <div className="modal-all-weapon-tit">
                                        Choose your weapon
                                    </div>

                                    <div className="list-modal-weapon">
                                        <div className="list-modal-weapon-in">
                                            <SingleWeapon allWeapon={props.allWeapon} sendWeapon={props.sendWeapon} />
                                        </div>
                                    </div>

                                    <div className="modal-buy-bt">
                                        <a href="https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&schema_name=weapon&sort=created&symbol=WAX" className="def-bt mid shop-link-bt" >
                                        
                                            <span className="button-in">
                                                <span className="button-name">
                                                    <span className="button-name-in">
                                                        <span className="button-name-value">
                                                            buy weapon
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                                
                            </div>   

                        </div>
                        <div className="modal-all-weapon-bot">
                            <img src={modalbot} alt="" />
                        </div>
                    </div>
                </div>

                <div className="modal-weapon-bg-trans" onClick={() => props.showCloseAllWeaponModal("close")}></div>
            </div>
            
            <div className="modal-weapon-bg" ></div>
        </div>

    </>);
}

export default ModalAllWeapon;