import React from 'react';
import { useEffect } from 'react';
import Countdown from 'react-countdown';
import PandaVideoComp from './../PandaVideoComp';
import { detalPanda } from './../PandaNameInfo';

function backside(num) {
    //console.log(4);
    var items = document.getElementsByClassName('one-slot');
    items[num].classList.toggle("active");
}

const animationState = localStorage.getItem('animation') == 'true' || localStorage.getItem('animation') == null ? false : true;

const Slots = (props) => {
    return (<>
        {props.userSlots.map((item, index) => {
            // console.log("userSlots slot paga", props.userSlots);
            // console.log("pandaSlots slot paga", props.pandaSlots);
            // console.log("item", item);
            // console.log("props.pandaSlots", props.pandaSlots);
            // console.log("one panda", onePanda);
            // console.log("one weapon", oneWeapon);
            // console.log("one jew", oneJew);
            //console.log("item outside", item)pandaInfo.onePanda.energy

            if (item === 0 && index < props.usersRow) {
                return (
                    <div key={index} className="one-slot no-p" >
                        <div className="one-slot-in">

                            <div className="one-slot-number">
                                Slot
                                <span>
                                    {index + 1}
                                </span>
                            </div>
                            <div className="one-slot-body">
                                <div className="one-slot-nft-card">

                                </div>
                            </div>

                            <div className="choose-new-hiro">
                                <button className="def-bt min" onClick={(e) => {
                                    props.openModal(true, index);
                                }}>
                                    <span className="button-in">
                                        <span className="button-name">
                                            <span className="button-name-in">
                                                <span className="button-name-value">
                                                    choose a hero
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </button>
                            </div>

                            <div className="slot-steps">
                                <div className="slot-steps-in">
                                    <div className="slot-steps-wrap">
                                        <div className="slot-steps-rate" style={{ "width": "calc(0)" }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            else if (item !== 0) {

                var pandaInfo = detalPanda(item, index, props.pandaSlots);
                //console.log("pandaInfo", pandaInfo);


                return (
                    <div key={index} className="one-slot" >
                        <div className={`one-slot-in ${props.openMultiSt ? 'mult-active' : ''}`}>
                            {props.openMultiSt ?
                                <div className={`check-slot ${pandaInfo.onePanda.activePanda ? 'active' : ''}`}
                                    onClick={(e) => { props.activeSlot(pandaInfo.onePanda.asset_id); }}>
                                </div>
                                : null}

                            {pandaInfo.onePanda.energy < 500 && !pandaInfo.timer * 1000 && pandaInfo.oneWeapon && pandaInfo.oneWeapon.energy === 0 && pandaInfo.oneArmor && pandaInfo.oneArmor.energy === 0 ?
                                <div className={`error-info active`} onClick={(e) => { props.openModalError('error-profit-weapon-armor'); }}></div>
                                :
                                pandaInfo.onePanda.energy < 500 && !pandaInfo.timer * 1000 && pandaInfo.oneWeapon && pandaInfo.oneWeapon.energy === 0 ?
                                    <div className={`error-info active`} onClick={(e) => { props.openModalError('error-profit-weapon'); }}></div>
                                    :
                                    pandaInfo.onePanda.energy < 500 && !pandaInfo.timer * 1000 && pandaInfo.oneJew && pandaInfo.oneJew.energy === 0 ?
                                        <div className={`error-info active`} onClick={(e) => { props.openModalError('error-profit-jew'); }}></div>
                                        :
                                        pandaInfo.onePanda.energy < 500 && !pandaInfo.timer * 1000 && pandaInfo.oneArmor && pandaInfo.oneArmor.energy === 0 ?
                                            <div className={`error-info active`} onClick={(e) => { props.openModalError('error-profit-armor'); }}></div>
                                            :
                                            pandaInfo.oneWeapon && pandaInfo.oneWeapon.energy === 0 && !pandaInfo.timer * 1000 ?
                                                <div className={`error-info active`} onClick={(e) => { props.openModalError('error-weapon'); }}></div>
                                                :
                                                pandaInfo.onePanda.energy < 500 && !pandaInfo.timer * 1000 ?
                                                    <div className={`error-info active`} onClick={(e) => { props.openModalError('error-profit'); }}></div>
                                                    :
                                                    pandaInfo.oneJew && pandaInfo.oneJew.energy === 0 && !pandaInfo.timer * 1000 ?
                                                        <div className={`error-info active`} onClick={(e) => { props.openModalError('error-jew'); }}></div>
                                                        :
                                                        pandaInfo.oneArmor && pandaInfo.oneArmor.energy === 0 && !pandaInfo.timer * 1000 ?
                                                            <div className={`error-info active`} onClick={(e) => { props.openModalError('error-armor'); }}></div>
                                                            :
                                                            null}

                            <div className="energy">
                                <div className="energy-info">
                                    <div className="energy-info-number tooltip min">
                                        <span>{parseInt(pandaInfo.energy)}</span><i>%</i>

                                        <div className="cust-tooltip right">
                                            <div className="tool-arrow"></div>
                                            <div className="tool-wrap">
                                                <div className="tool-wrap-1">
                                                    <div className="tool-wrap-2">
                                                        <div className="tool-body">
                                                            {pandaInfo.energy} / 100 energy
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="energy-body">
                                    <div className={`energy-body-in ${pandaInfo.onePanda.energy < 1000 && pandaInfo.onePanda.energy >= 500 ? "active" : ""}`} data-en={pandaInfo.countBamb}>

                                    </div>
                                </div>
                                <div className="energy-bt tooltip">
                                    <button className={`eat-bt ${pandaInfo.onePanda.energy < 500 ? "active" : ""}`} onClick={(e) => { props.openModalEat(index, pandaInfo.onePanda.asset_id); }}></button>

                                    <div className="cust-tooltip bottom">
                                        <div className="tool-arrow"></div>
                                        <div className="tool-wrap">
                                            <div className="tool-wrap-1">
                                                <div className="tool-wrap-2">
                                                    <div className="tool-body">
                                                        Feed the hero
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="one-slot-number">
                                Slot
                                <span>
                                    {index + 1}
                                </span>
                            </div>
                            <div className="one-slot-body">
                                <div className="one-slot-nft-card">
                                    <div className="wrap-mob-rot">
                                        <div className="img-slot">
                                            <div className="open-info" onClick={(e) => {
                                                backside(index);
                                            }}></div>
                                            {/* <picture>
                                                <source srcSet={`./images/card-1x/${onePanda.name_pa}.webp`} type="image/webp" />
                                                <source srcSet={`./images/card-1x/${onePanda.name_pa}.png`} type="image/png" /> 
                                                <img src={`./images/card-1x/${onePanda.name_pa}.png`} alt="" />
                                            </picture> */}
                                            <PandaVideoComp fullNameRare={pandaInfo.onePanda.name_pa} pandaName={pandaInfo.onePandaName} cardName={pandaInfo.cardRare + pandaInfo.imgRare} element={pandaInfo.onePanda.element} />

                                            {pandaInfo.oneWeapon ?
                                                <div className={`adv-weapon tooltip active ${animationState ? "" : "anim"} ${pandaInfo.weaponElement} rar-${pandaInfo.oneWeapon.rarity}`} onClick={(e) => {
                                                    props.showCloseModal('open', pandaInfo.oneWeapon, pandaInfo.oneWeapon.energy, index, pandaInfo.oneWeapon.rarity);

                                                }}>
                                                    <div className="adv-weapon-in">
                                                        <i className="a"></i><i className="b"></i>
                                                        <div className={`adv-weapon-wrap ${animationState ? "" : "anim"}`}>
                                                            <picture>
                                                                <source srcSet={`./images/weapons/mini/${pandaInfo.nameWeapon}.webp`} type="image/webp" />
                                                                <source srcSet={`./images/weapons/mini/${pandaInfo.nameWeapon}.png`} type="image/png" />
                                                                <img src={`./images/weapons/mini/${pandaInfo.nameWeapon}.png`} alt="" />
                                                            </picture>
                                                        </div>
                                                    </div>
                                                    <div className="cust-tooltip bottom">
                                                        <div className="tool-arrow"></div>
                                                        <div className="tool-wrap">
                                                            <div className="tool-wrap-1">
                                                                <div className="tool-wrap-2">
                                                                    <div className={`tool-body ${pandaInfo.oneWeapon.energy < 2500 ? "red" : pandaInfo.oneWeapon.energy < 5000 ? "yelow" : null}`}>
                                                                        {pandaInfo.oneWeapon.energy / 100}%
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="adv-weapon" onClick={(e) => {
                                                    props.showCloseAllWeaponModal('open', pandaInfo.onePanda.asset_id);
                                                }}>
                                                    <div className="adv-weapon-in">
                                                        <div className="adv-weapon-wrap"></div>
                                                    </div>
                                                </div>
                                            }

                                            {pandaInfo.oneJew ?
                                                <div className={`adv-jew active ${animationState ? "" : "anim"} tooltip ${pandaInfo.jewElement} rar-${pandaInfo.oneJew.rarity}`} onClick={(e) => {
                                                    props.showCloseModalJew('open', pandaInfo.oneJew, pandaInfo.oneJew.energy, index, pandaInfo.oneJew.rarity, "jewelry");

                                                }}>
                                                    <div className="adv-jew-in">
                                                        <i className="a"></i><i className="b"></i>
                                                        <div className={`adv-jew-wrap ${animationState ? "" : "anim"}`}>
                                                            <picture>
                                                                <source srcSet={`./images/jew/mini/${pandaInfo.oneJew.tmpl}.webp`} type="image/webp" />
                                                                <source srcSet={`./images/jew/mini/${pandaInfo.oneJew.tmpl}.png`} type="image/png" />
                                                                <img src={`./images/jew/mini/${pandaInfo.oneJew.tmpl}.png`} alt="" />
                                                            </picture>
                                                        </div>
                                                    </div>
                                                    <div className="cust-tooltip bottom">
                                                        <div className="tool-arrow"></div>
                                                        <div className="tool-wrap">
                                                            <div className="tool-wrap-1">
                                                                <div className="tool-wrap-2">
                                                                    <div className={`tool-body ${pandaInfo.oneJew.energy < 2500 ? "red" : pandaInfo.oneJew.energy < 5000 ? "yelow" : null}`}>
                                                                        {pandaInfo.oneJew.energy / 100}%
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="adv-jew" onClick={(e) => {
                                                    props.showCloseAllJewModal('open', pandaInfo.onePanda.asset_id);
                                                }}>
                                                    <div className="adv-jew-in">
                                                        <div className="adv-jew-wrap"></div>
                                                    </div>
                                                </div>
                                            }

                                            {pandaInfo.oneArmor ?
                                                <div className={`adv-armor active ${animationState ? "" : "anim"} tooltip ${pandaInfo.armorElement} rar-${pandaInfo.oneArmor.rarity}`} onClick={(e) => {
                                                    props.showCloseModalJew('open', pandaInfo.oneArmor, pandaInfo.oneArmor.energy, index, pandaInfo.oneArmor.rarity, "armor");

                                                }}>
                                                    <div className="adv-armor-in">
                                                        <i className="a"></i><i className="b"></i>
                                                        <div className={`adv-armor-wrap ${animationState ? "" : "anim"} `}>
                                                            <picture>
                                                                <source srcSet={`./images/armor/mini/${pandaInfo.armorDetal.nameArmor}-${pandaInfo.armorDetal.nameRarArmor}.webp`} type="image/webp" />
                                                                <source srcSet={`./images/armor/mini/${pandaInfo.armorDetal.nameArmor}-${pandaInfo.armorDetal.nameRarArmor}.png`} type="image/png" />
                                                                <img src={`./images/armor/mini/${pandaInfo.armorDetal.nameArmor}-${pandaInfo.armorDetal.nameRarArmor}.png`} alt="" />
                                                            </picture>
                                                        </div>
                                                    </div>
                                                    <div className="cust-tooltip bottom">
                                                        <div className="tool-arrow"></div>
                                                        <div className="tool-wrap">
                                                            <div className="tool-wrap-1">
                                                                <div className="tool-wrap-2">
                                                                    <div className={`tool-body ${pandaInfo.oneArmor.energy < 2500 ? "red" : pandaInfo.oneArmor.energy < 5000 ? "yelow" : null}`}>
                                                                        {pandaInfo.oneArmor.energy / 100}%
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="adv-armor" onClick={(e) => {
                                                    props.showCloseAllJewModal('open', pandaInfo.onePanda.asset_id, "armor");
                                                }}>
                                                    <div className="adv-armor-in">
                                                        <div className="adv-armor-wrap"></div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="card-back-side">
                                            <div className="close-back-side" onClick={(e) => {
                                                backside(index);
                                            }}></div>
                                            <div className="card-back-side-in">

                                                <div className="card-top-avat-descr">
                                                    <div className="card-top-avat">
                                                    </div>
                                                    <div className="card-top-avat-descr-info">
                                                        Panda info
                                                    </div>
                                                    <div className="card-top-avat-descr-list">
                                                        <ul>
                                                            <li>
                                                                ID: {pandaInfo.onePanda.asset_id}
                                                            </li>
                                                            <li>
                                                                type: {pandaInfo.onePanda.clas}
                                                            </li>
                                                            <li>
                                                                RARITY: {pandaInfo.nameRarePanda}
                                                            </li>
                                                            <li>
                                                                ELEMENT: {pandaInfo.onePanda.element}
                                                            </li>
                                                            <li>
                                                                stake: {pandaInfo.onePanda.stake_count / 10000} {process.env.REACT_APP_VAL}
                                                            </li>
                                                            <li>
                                                                rank: {pandaInfo.nameRare}
                                                            </li>

                                                        </ul>
                                                    </div>
                                                    <div className="card-top-avat-descr-copy">
                                                        NFT Panda
                                                        <span>
                                                            World of fantasy
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                                <div className="one-slot-descr">

                                    <div className="one-slot-descr-in">
                                        <div className="one-slot-info">
                                            <div className="name-panda">
                                                {/* open, slot number, name, onePandaName */}
                                                {pandaInfo.onePandaName}
                                            </div>
                                            <ul>
                                                <li>id: {pandaInfo.onePanda.asset_id}</li>
                                                <li>rank: {pandaInfo.nameRare}</li>
                                                <li>rarity: {pandaInfo.nameRarePanda}</li>
                                                <li>element: {pandaInfo.onePanda.element}</li>
                                                <li>Type: {pandaInfo.onePanda.clas}</li>
                                            </ul>
                                        </div>
                                        {pandaInfo.timer ?
                                            <div className="timer">
                                                <div className="timer-tit">
                                                    hero is resting
                                                </div>
                                                <div className="timer-in">
                                                    <Countdown daysInHours date={pandaInfo.onePanda.timer * 1000} onComplete={(e) => {
                                                        props.handleUpdate(pandaInfo.onePanda.asset_id);
                                                    }} />
                                                </div>
                                            </div>
                                            :

                                            <div className="one-slot-bt">
                                                <div className="remove-panda" onClick={(e) => {
                                                    props.onHandleSendPandaFromSlot(pandaInfo.onePanda, index);
                                                }}></div>

                                                <div className="bt-top-slot">
                                                    <div className="one-top-slot-bt">
                                                        <button className="bt-graph change-bt" onClick={(e) => {
                                                            props.openModal(true, index);
                                                        }}>
                                                        </button>
                                                        <div className="one-top-slot-name">
                                                            CHANGE
                                                        </div>
                                                    </div>
                                                    <div className="one-top-slot-bt">
                                                        <button className="bt-graph stake-bt" onClick={(e) => {
                                                            props.stakePandaModal(pandaInfo.onePanda.asset_id, pandaInfo.onePanda.name, pandaInfo.onePanda.stake_count, `${pandaInfo.onePanda.name_pa}`, pandaInfo.onePanda, pandaInfo.lvlLoop, pandaInfo.youmastadd, pandaInfo.errorBamToLvlUp); // id, name, bam in stake, thumb name
                                                        }}>
                                                            {pandaInfo.errorBamToLvlUp / 10000 < props.balanceBam && pandaInfo.pandaSt < pandaInfo.errorBamToLvlUp ? <span className="can-stake"></span> : null}
                                                        </button>
                                                        <div className="one-top-slot-name">
                                                            STAKING
                                                        </div>
                                                    </div>
                                                    <div className="one-top-slot-bt">
                                                        <button className="bt-graph skills-bt" onClick={(e) => { props.openCloseSkills("open", pandaInfo.forSkillWindow); }}>
                                                            {pandaInfo.errorBamToLvlUp / 10000 < props.balanceBam && pandaInfo.pandaSt < pandaInfo.errorBamToLvlUp ? <span className="can-stake"></span> : null}
                                                        </button>
                                                        <div className="one-top-slot-name">
                                                            Skills
                                                        </div>
                                                    </div>
                                                    <div className="one-top-slot-bt">
                                                        <button className="bt-graph send-bt" onClick={() => props.openNewAdvMap('open', index, pandaInfo.onePanda, pandaInfo.exp, false)}>
                                                        </button>
                                                        <div className="one-top-slot-name">
                                                            send
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <button className="def-bt min" onClick={() => props.openNewAdvMap('open', index, onePanda, exp, false )}>
                                                    <span className="button-in">
                                                        <span className="button-name">
                                                            <span className="button-name-in">
                                                                <span className="button-name-value">
                                                                    SEND to adventure
                                                                </span> 
                                                            </span>
                                                        </span>
                                                    </span>
                                                </button> */}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            {

                                pandaInfo.exp ?
                                    <>
                                        <div className="you-mast-stake">
                                            To get the maximum reward, you must stake {pandaInfo.youmastadd} {process.env.REACT_APP_VAL}
                                        </div>
                                    </>
                                    :
                                    pandaInfo.showBtLvlUp && !pandaInfo.timer * 1000 ?

                                        pandaInfo.pandaSt < pandaInfo.errorBamToLvlUp ?

                                            <div className="level-up">
                                                <button className="def-bt mini" onClick={(e) => {
                                                    props.errorStake(pandaInfo.pandaSt, pandaInfo.errorBamToLvlUp); // 7200
                                                }}>
                                                    <span className="button-in">
                                                        <span className="button-name">
                                                            <span className="button-name-in">
                                                                <span className="button-name-value">
                                                                    RANK UP
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </button>
                                            </div>

                                            :

                                            <div className="level-up">
                                                <button className="def-bt mini" onClick={(e) => {
                                                    props.lvlUpPanda(pandaInfo.onePanda.asset_id); // 7200
                                                }}>
                                                    <span className="button-in">
                                                        <span className="button-name">
                                                            <span className="button-name-in">
                                                                <span className="button-name-value">
                                                                    RANK UP
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </button>
                                            </div>

                                        :

                                        !pandaInfo.timer * 1000 ?
                                            <div className="slot-steps">
                                                <div className="slot-steps-in">
                                                    {pandaInfo.pandaLvl < 12 ?
                                                        <>
                                                            <div className="slot-steps-wrap">
                                                                <div className="slot-steps-rate" style={{ "width": "calc(100% / " + `${props.resultRew.steps[pandaInfo.onePanda.lvl + 1]}` + "* " + `${pandaInfo.onePanda.steps ? pandaInfo.onePanda.steps : 0}` + ")" }}></div>
                                                            </div>
                                                            <div className="slot-step-number">
                                                                <div className="step-active">{pandaInfo.onePanda.steps}</div>
                                                                <div className="step-simb">/</div>
                                                                <div className="step-all">{props.resultRew.steps[pandaInfo.onePanda.lvl + 1]} <i>exp</i></div>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className="slot-steps-wrap">
                                                                <div className="slot-steps-rate" style={{ "width": "calc(100% / " + `${props.resultRew.steps[pandaInfo.onePanda.lvl + 1]}` + "* " + `${pandaInfo.onePanda.steps ? pandaInfo.onePanda.steps : 0}` + ")" }}></div>
                                                            </div>
                                                            <div className="slot-step-number">
                                                                <div className="step-simb text">max rank</div>
                                                            </div>
                                                        </>
                                                    }

                                                </div>
                                            </div>

                                            :
                                            null

                            }



                        </div>
                    </div>
                )
            } else if (index === props.usersRow) {
                return (
                    <div key={index} className="one-slot dis" >
                        <div className="one-slot-in">

                            <div className="one-slot-number">
                                Slot
                                <span>
                                    {index + 1}
                                </span>
                            </div>
                            <div className="one-slot-body">
                                <div className="one-slot-nft-card">

                                </div>
                            </div>

                            <div className="choose-new-hiro">
                                <button className="def-bt min" onClick={(e) => {
                                    props.openModalBySlot(true, index);
                                }}>
                                    <span className="button-in">
                                        <span className="button-name">
                                            <span className="button-name-in">
                                                <span className="button-name-value">
                                                    buy this slot
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </button>
                            </div>

                        </div>
                    </div>
                )
            }
            else {
                return (
                    <div key={index} className="one-slot dis sec" >
                        <div className="one-slot-in">

                            <div className="one-slot-number">
                                Slot
                                <span>
                                    {index + 1}
                                </span>
                            </div>
                            <div className="one-slot-body">
                                <div className="one-slot-nft-card">

                                </div>
                            </div>
                        </div>
                    </div>
                )
            }


        })
        }
    </>);

}

export default Slots;