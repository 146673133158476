import React from 'react';

const NeedForQuest = (props) => {
   
    var bam;
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
      ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
        return props.balance >= item.value;
    });
    bam = item ? (props.balance / item.value).toFixed(1).replace(rx, "$1") + item.symbol : "0";
    
    return (<>
        {props.activeQuest.map((item, index) => {
            //console.log(props.allresgroup);
            //console.log(props.allresgroup[0].length);
            var num = 0;
            if(props.allresgroup) {
                for (let i = 0; i < props.allresgroup.length; i++) { 
                    if (props.allresgroup[i].length && item.tmpl == props.allresgroup[i][0].template_id) {
                        num = props.allresgroup[i].length
                    } else if (props.allresgroup[i].length && item.type == "gem" && props.allresgroup[i][0].shema == "gems") {
                        num = props.allresgroup[i].length
                    } else if (props.allresgroup[i].length && item.type == "panda" && props.allresgroup[i][0].shema == "promo") {
                        num = props.allresgroup[i].length
                    }  else if (props.allresgroup[i].length && props.allresgroup[i][0].shema == "armor") {

                        //console.log("AAAAAAAAAAAAAAA ARMOR")
                        if(item.type == "questsenduncarm") {
                            const filteredArmor = props.allresgroup[i].filter((armor) => armor.rarity == "Uncommon");
                            num = filteredArmor.length
                            //console.log("AAAAAAAAAAAAAAA Uncommon", num)
                        }
                        if(item.type == "questsendrarearm") {
                            const filteredArmor = props.allresgroup[i].filter((armor) => armor.rarity == "Rare");
                            num = filteredArmor.length
                            //console.log("AAAAAAAAAAAAAAA Rare", num)
                        }

                        
                    }    else if (props.allresgroup[i].length && item.type == "jewelry" && props.allresgroup[i][0].shema == "jewelry") {
                        if(
                            item.tmpl1 == props.allresgroup[i][0].template_id ||
                            item.tmpl2 == props.allresgroup[i][0].template_id ||
                            item.tmpl3 == props.allresgroup[i][0].template_id ||
                            item.tmpl4 == props.allresgroup[i][0].template_id
                        ) {
                            num = props.allresgroup[i].length
                        }
                        
                    }
                }
            }

            return (
            
                <div key={index} className="one-bring">
                <div className="one-bring-thumb">
                    <div className="one-bring-thumb-in">
                        {item.type == "gem" ? 
                            <div className="one-drop-thumb-stone">
                                
                                <span className='st-1'>
                                    <picture>
                                        <source srcSet={`./images/jew/inventory/${item.tmpl1}.webp`} type="image/webp" />
                                        <source srcSet={`./images/jew/inventory/${item.tmpl1}.png`} type="image/png" /> 
                                        <img src={`./images/jew/inventory/${item.tmpl1}.png`} alt="" />
                                    </picture>
                                </span>
                                <span className='st-2'>
                                    <picture>
                                        <source srcSet={`./images/jew/inventory/${item.tmpl2}.webp`} type="image/webp" />
                                        <source srcSet={`./images/jew/inventory/${item.tmpl2}.png`} type="image/png" /> 
                                        <img src={`./images/jew/inventory/${item.tmpl2}.png`} alt="" />
                                    </picture>
                                </span>
                                <span className='st-3'>
                                    <picture>
                                        <source srcSet={`./images/jew/inventory/${item.tmpl3}.webp`} type="image/webp" />
                                        <source srcSet={`./images/jew/inventory/${item.tmpl3}.png`} type="image/png" /> 
                                        <img src={`./images/jew/inventory/${item.tmpl3}.png`} alt="" />
                                    </picture>
                                </span>
                                <span className='st-4'>
                                    <picture>
                                        <source srcSet={`./images/jew/inventory/${item.tmpl4}.webp`} type="image/webp" />
                                        <source srcSet={`./images/jew/inventory/${item.tmpl4}.png`} type="image/png" /> 
                                        <img src={`./images/jew/inventory/${item.tmpl4}.png`} alt="" />
                                    </picture>
                                </span>
                                
                            </div>
                        : item.type == "jewelry" && item.tmpl1 != undefined  ? 
                        <div className="one-drop-thumb-stone">
                            
                            <span className='st-1'>
                                <picture>
                                    <source srcSet={`./images/jew/inventory/${item.tmpl1}.webp`} type="image/webp" />
                                    <source srcSet={`./images/jew/inventory/${item.tmpl1}.png`} type="image/png" /> 
                                    <img src={`./images/jew/inventory/${item.tmpl1}.png`} alt="" />
                                </picture>
                            </span>
                            <span className='st-2'>
                                <picture>
                                    <source srcSet={`./images/jew/inventory/${item.tmpl2}.webp`} type="image/webp" />
                                    <source srcSet={`./images/jew/inventory/${item.tmpl2}.png`} type="image/png" /> 
                                    <img src={`./images/jew/inventory/${item.tmpl2}.png`} alt="" />
                                </picture>
                            </span>
                            <span className='st-3'>
                                <picture>
                                    <source srcSet={`./images/jew/inventory/${item.tmpl3}.webp`} type="image/webp" />
                                    <source srcSet={`./images/jew/inventory/${item.tmpl3}.png`} type="image/png" /> 
                                    <img src={`./images/jew/inventory/${item.tmpl3}.png`} alt="" />
                                </picture>
                            </span>
                            <span className='st-4'>
                                <picture>
                                    <source srcSet={`./images/jew/inventory/${item.tmpl4}.webp`} type="image/webp" />
                                    <source srcSet={`./images/jew/inventory/${item.tmpl4}.png`} type="image/png" /> 
                                    <img src={`./images/jew/inventory/${item.tmpl4}.png`} alt="" />
                                </picture>
                            </span>
                            
                        </div>
                    :
                        item.type == "panda" && item.tmpl1 != undefined ?
                            <div className="one-drop-thumb-stone">
                                
                                <span className='st-11 pp'>
                                    <picture>
                                        <source srcSet={`./images/card-1x/${item.tmpl1}.webp`} type="image/webp" />
                                        <source srcSet={`./images/card-1x/${item.tmpl1}.png`} type="image/png" /> 
                                        <img src={`./images/card-1x/${item.tmpl1}.png`} alt="" />
                                    </picture>
                                </span>
                                <span className='st-22 pp'>
                                    <picture>
                                        <source srcSet={`./images/card-1x/${item.tmpl2}.webp`} type="image/webp" />
                                        <source srcSet={`./images/card-1x/${item.tmpl2}.png`} type="image/png" /> 
                                        <img src={`./images/card-1x/${item.tmpl2}.png`} alt="" />
                                    </picture>
                                </span>
                                <span className='st-33 pp'>
                                    <picture>
                                        <source srcSet={`./images/card-1x/${item.tmpl3}.webp`} type="image/webp" />
                                        <source srcSet={`./images/card-1x/${item.tmpl3}.png`} type="image/png" /> 
                                        <img src={`./images/card-1x/${item.tmpl3}.png`} alt="" />
                                    </picture>
                                </span>
                                <span className='st-44 pp'>
                                    <picture>
                                        <source srcSet={`./images/card-1x/${item.tmpl4}.webp`} type="image/webp" />
                                        <source srcSet={`./images/card-1x/${item.tmpl4}.png`} type="image/png" /> 
                                        <img src={`./images/card-1x/${item.tmpl4}.png`} alt="" />
                                    </picture>
                                </span>
                                <span className='st-55 pp'>
                                    <picture>
                                        <source srcSet={`./images/card-1x/${item.tmpl5}.webp`} type="image/webp" />
                                        <source srcSet={`./images/card-1x/${item.tmpl5}.png`} type="image/png" /> 
                                        <img src={`./images/card-1x/${item.tmpl5}.png`} alt="" />
                                    </picture>
                                </span>
                                <span className='st-66 pp'>
                                    <picture>
                                        <source srcSet={`./images/card-1x/${item.tmpl6}.webp`} type="image/webp" />
                                        <source srcSet={`./images/card-1x/${item.tmpl6}.png`} type="image/png" /> 
                                        <img src={`./images/card-1x/${item.tmpl6}.png`} alt="" />
                                    </picture>
                                </span>
                                
                            </div>
                        :
                        item.type == "advbest" ?
                            <picture>
                                <source srcSet={`./images/quest100.png`} type="image/webp" />
                                <source srcSet={`./images/quest100.png`} type="image/png" /> 
                                <img src={`./images/quest100.png`} alt="" />
                            </picture>
                        :
                        item.type == "advpanda" ?
                            <picture>
                                <source srcSet={`./images/questadv.png`} type="image/webp" />
                                <source srcSet={`./images/questadv.png`} type="image/png" /> 
                                <img src={`./images/questadv.png`} alt="" />
                            </picture>
                        :
                        item.type == "onestory" ?
                            <picture>
                                <source srcSet={`./images/queststory.png`} type="image/webp" />
                                <source srcSet={`./images/queststory.png`} type="image/png" /> 
                                <img src={`./images/queststory.png`} alt="" />
                            </picture>
                        :
                        item.type == "questchange" ?
                            <picture>
                                <source srcSet={`./images/questchange.png`} type="image/webp" />
                                <source srcSet={`./images/questchange.png`} type="image/png" /> 
                                <img src={`./images/questchange.png`} alt="" />
                            </picture>
                        :
                        item.type == "questcraftcomarm" || item.type == "questsenduncarm" || item.type == "questchangewoodkves" || item.type == "questcraftuncarm" || item.type == "questsendrarearm" ?
                            <picture>
                                <source srcSet={`./images/${item.type}.png`} type="image/webp" />
                                <source srcSet={`./images/${item.type}.png`} type="image/png" /> 
                                <img src={`./images/${item.type}.png`} alt="" />
                            </picture>
                        :

                            <picture>
                                <source srcSet={`./images/${item.type == "gem" || item.type == "res" || item.type == "jew" ? "jew/inventory/" : item.type == "weapon" ? "weapons/inventory/" : item.type == "bam" ? "tavern/" : item.type == "panda" ? "card-1x/" : "shop/"}${item.tmpl}.webp`} type="image/webp" />
                                <source srcSet={`./images/${item.type == "gem" || item.type == "res" || item.type == "jew"  ? "jew/inventory/" : item.type == "weapon" ? "weapons/inventory/" : item.type == "bam" ? "tavern/" : item.type == "panda" ? "card-1x/" : "shop/"}${item.tmpl}.png`} type="image/png" /> 
                                <img src={`./images/${item.type == "gem" || item.type == "res" || item.type == "jew"  ? "jew/inventory/" : item.type == "weapon" ? "weapons/inventory/" : item.type == "bam" ? "tavern/" : item.type == "panda" ? "card-1x/" : "shop/"}${item.tmpl}.png`} alt="" />
                            </picture>
                        }
                    </div>
                    {item.craft && <div className="need-craft"></div>}
                    <div className="one-bring-num">
                        
                        {item.type == "bam" ? bam 
                        : 
                        item.type == "advbest" ?
                        props.infoquest[item.nameTable] ? props.infoquest[item.nameTable] > 0 && props.infoquest[item.nameTable] <= item.num ? props.infoquest[item.nameTable] : item.num : 0
                        :
                        item.type == "questcraftcomarm" || item.type == "questchangewoodkves" || item.type == "questcraftuncarm"  ?
                        props.infoquest[item.nameTable] ? props.infoquest[item.nameTable] > 0 && props.infoquest[item.nameTable] <= item.num ? props.infoquest[item.nameTable] : item.num : 0
                        :
                        item.type == "onestory" ?
                        props.infoquest[item.nameTable] == 24 ? 1 : 0
                        :
                        item.type == "advpanda" ?
                        props.infoquest[item.nameTable] ? props.infoquest[item.nameTable] > 0 && props.infoquest[item.nameTable] <= 50 ? props.infoquest[item.nameTable] : 50 : 0
                        :
                        item.type == "questchange" ?
                        props.infoquest[item.nameTable] ? props.infoquest[item.nameTable] > 0 && props.infoquest[item.nameTable] <= 50 ? props.infoquest[item.nameTable] : 0 : 0
                        :
                        item.craft ?  props.infoquest[item.nameTable] >= item.num && num >= item.num ? item.num : 0 
                        :
                        num < item.num ? num : item.num}/{item.type == "bam" ? item.numLetter : item.num}

                    </div>
                    <div className="one-shafl-prod-info tooltip">
                        <div className="cust-tooltip right">
                            <div className="tool-arrow"></div>
                            <div className="tool-wrap">
                                <div className="tool-wrap-1">
                                    <div className="tool-wrap-2">
                                        <div className="tool-body" dangerouslySetInnerHTML={{__html: item.tit}}>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            );
            /*}*/
        })
        }
        


        
    </>);

}

export default NeedForQuest;