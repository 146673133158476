import React from "react";
import {
  /*BrowserRouter, Redirect, Route, Switch,*/ Link,
  NavLink,
} from "react-router-dom";
import ErrorComponent from "./../../pages/Error/ErrorComponent";
import logoHeader from "../../images/logo-header.png"; // relative path to image
import logoHeaderW from "../../images/logo-header.webp"; // relative path to image
import musicOpen from "../../music/open.mp3";
import musicClose from "../../music/close.mp3";
import musicInOut from "../../music/in-out.mp3";
import musicError from "../../music/error.mp3";
import Artefacts from "../Artefacts/Artefacts";

import shafltop from "../../images/artefacts/title.png";
import shafltopw from "../../images/artefacts/title.webp";
import shaflbot from "../../images/artefacts/bottom.png";
import shaflbotw from "../../images/artefacts/bottom.webp";

import { JsonRpc } from 'eosjs'
import { ExplorerApi } from "atomicassets" // атомик для списка панд

const api = new ExplorerApi("https://" + localStorage.getItem('rpcA'), "atomicassets", { fetch, rateLimit: 4 });

var arrHub = [
  "aa-wax-public1.neftyblocks.com",
  localStorage.getItem('rpcA'),
  localStorage.getItem('rpcA'),
  localStorage.getItem('rpcA'),
  "api.atomic.greeneosio.com",
  "atomic.wax.eosrio.io",
  "wax-aa.eu.eosamsterdam.net",
  "atomic-wax-mainnet.wecan.dev",
  "atomic.sentnl.io",
  // "atomic.tokengamer.io",
  // "atomic.ledgerwise.io",
  // "api-wax-aa.eosarabia.net",
  // "wax.hkeos.com/aa",
  // "wax-atomic.wizardsguild.one",
  // "wax-atomic.eosiomadrid.io",
  // "aa.wax.blacklusion.io",
  // "wax-atomic-api.eosphere.io",
  // "wax-aa.eosdublin.io"
]


var numRpc = 0;
var arrRpc = ['wax.pink.gg', 'wax.cryptolions.io', 'wax.greymass.com', 'api.wax.alohaeos.com', 'api.waxsweden.org', 'wax.eosphere.io', 'api.wax.greeneosio.com', 'wax.eu.eosamsterdam.net', 'api.wax.bountyblok.io']


//const api2 = new ExplorerApi("https://" + localStorage.getItem('rpcA'), "atomicassets", { fetch, rateLimit: 4 });


class Menu extends React.Component {
  //export const Menu = (props) => {

  constructor(props) {
    super(props);

    this.state = {
      activeNoda: "wax.pink.gg",
      openLk: false,
      activeNodaAtomic: localStorage.getItem('rpcA'),
      artitem: [
        {
          img: "art-1",
          noimg: "art-1-no",
          name: "poster",
          id: "217111",
          has: false,
          title: "Trial of Pragnar",
          descr:
            "Special Collectible Art, series #1 <br /> Lore of Art: <br /> The High Council of the Water Village decided to send the best paladin to one of the ancient Guardians of the Mountain. Pragnar the son of Paus from Anorigar was ordered to learn more information about the evil that threatens Elgard…",
          height: "135",
          urlAtomic: "https://wax.atomichub.io/market?collection_name=nftpandawaxp&match=trial%20of%20pragnar&order=desc&schema_name=art&sort=created&symbol=WAX",
          bigArtDis: "art-0",
          bigArtACT: "art-1"
        },
        {
          img: "art-2",
          noimg: "art-2-no",
          name: "art-2-no",
          id: "606134",
          has: false,
          title: "The secret of the Tavern \"Drunken Goblin\"",
          descr:
            "The favorite place of the best Heroes in the Lands of Elgard, the tavern \"Drunken Goblin\" has always kept a secret. This legend is already so old that not even all the Heroes are sure that it is true. Oh how often visitors tried to get at least some information from Gutfried, but he was always silent about it. Does it mean that even he doesn't know the truth? But one day a secret message appeared in the tavern…",
          height: "140",
          urlAtomic: "https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&schema_name=art&search=The%20secret%20of%20the%20Tavern%20%22Drunken%20Goblin%22&sort=created&state=1&symbol=WAX",
          bigArtDis: "art-4",
          bigArtACT: "art-5"

        },
        {
          img: "art-3",
          noimg: "art-3-no",
          name: "art-3-no",
          id: "664448",
          has: false,
          title: "Elgard's Badge of Valor",
          descr:
            "Elgard's Badge of Valor is a rare and coveted reward wielded only by the bravest Heroes of the legendary Lands of Elgard. Its radiance is emblematic of courage, skill, and unwavering determination. Only those who have proven themselves on the battlefield and demonstrated exceptional bravery in the face of terrible danger are considered worthy to wield the Mark of Valor. To wear it is to bear the weight of a legacy that dates back through countless generations of heroes, and to be considered one of the finest warriors to ever walk the Lands of Elgard.",
          height: "155",
          urlAtomic: "https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&schema_name=art&search=Elgard%27s%20Badge%20of%20Valor&sort=created&state=1&symbol=WAX",
          bigArtDis: "art-2",
          bigArtACT: "art-3"

        },

      ],
      allparts: {},
    };



    this.clearError = this.clearError.bind(this);
    this.clickBg = this.clickBg.bind(this);

    this.audioOpen = new Audio(musicOpen);
    this.audioClose = new Audio(musicClose);
    this.audioMusicInOut = new Audio(musicInOut);
    this.audioMusicError = new Audio(musicError);
  }

  componentDidMount() {
    this.userCheck();
    this.GetPandaPartListArt();

    if (!localStorage.getItem("rpc")) {
      localStorage.setItem("rpc", "wax.pink.gg");
    } else {
      this.setState({
        activeNoda: localStorage.getItem("rpc"),
      });
    }

    if (!localStorage.getItem("rpcA")) {
      localStorage.setItem("rpcA", localStorage.getItem('rpcA'));
    } else {
      this.setState({
        activeNodaAtomic: localStorage.getItem("rpcA"),
      });
    }
  }

  componentDidUpdate() {
    // if(localStorage.getItem('tavernModal3') && this.state.openTavModal) {
    //   this.showCloseModalTav("close");
    // }
  }

  async userCheck() {
    try {
      //console.log(555555555555555555555555555555555555555555555);

      //console.log(activeUser);
      //console.log(accountName);
      var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);

      const results = await rpcc.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "usertop", // name of the table as specified by the contract abi
        //"table": "usersnew",    // name of the table as specified by the contract abi
        limit: 300,
        /*lower_bound: 0,*/
        //upper_bound: 0,
        //lower_bound: 0,
        key_type: "i64",
        //"index_position": 2,
        //"limit": -1,
        reverse: !0,
        show_payer: false,
        index_position: 2,
      });
      const originRowUser = results.rows;
      //console.log(originRowUser);

      /*function dynamicSort(property) {
                var sortOrder = -1;
                if(property[0] === "-") {
                    sortOrder = 1;
                    property = property.substr(1);
                }
                return function (a,b) {
                    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                    return result * sortOrder;
                }
            }

            originRowUser.sort(dynamicSort("allsum"));*/

      this.setState({
        formInfo: originRowUser,
      });
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
    }
  }

  async GetPandaPartListArt(parts) {
    (async () => {
      try {

        const asset = await api
          .getAssets({
            limit: 1000,
            owner: this.props.id,
            collection_name: "nftpandawaxp",
            schema_name: ["art"],
            //template_id: arr,
          })
          .then((assets) => {
            //console.log('GetPandaPartListRes', assets);
            let art1 = 0;
            let art2 = 0;
            let art3 = 0;
            for (var c in assets) {
              //console.log(byTemplate[c]);
              if (assets[c].template.template_id == 217111) {
                art1 = true;
              } else if (assets[c].template.template_id == 606134) {
                art2 = true;
              } else if (assets[c].template.template_id == 664448) {
                art3 = true;
              }
              //else if(assets[c].template.template_id == this.state.activeRecipe.stone) {
              //     stone += 1;
              // }
            }

            //console.log('this.state.artitem', this.state.artitem);

            var copyArt = Object.assign([], this.state.artitem);
            //console.log(copyArt);
            if (art1) {
              copyArt[0].has = true;
            }
            if (art2) {
              copyArt[1].has = true;
            }
            if (art3) {
              copyArt[2].has = true;
            }
            //console.log(copyArt[0]);

            this.setState({
              ...this.state,
              allparts: assets,
              artitem: copyArt,
            });

            //console.log('this.state.artitem', this.state.artitem);
          });
      } catch (e) {
        this.setState({
          hasError: true,
          errorText: e.message,
        });
        console.log("\nCaught exception: " + e);
        if (localStorage.getItem("sound") == "true") {
          this.audioMusicError.currentTime = 0;
          this.audioMusicError.volume = 0.1;
          this.audioMusicError.play();
        }
      }
    })();
  }

  async clearError() {
    this.setState({
      errorText: "",
      hasError: false,
    });
  }

  showCloseModalBal(method) {
    document.getElementsByTagName("body")[0].classList.add("open-modal");

    if (method === "open") {
      this.setState({
        ...this.state,
        modalBal: {
          ...this.state.modalBal,
          openModal: true,
        },
      });
      setTimeout(() => {
        this.setState({
          modalBal: {
            ...this.state.modalBal,
            showAnimModal: true,
          },
        });
      }, 300);

      if (localStorage.getItem("sound") === "true") {
        this.audioOpen.currentTime = 0;
        this.audioOpen.volume = 0.1;
        this.audioOpen.play();
      }
    } else {
      document.getElementsByTagName("body")[0].classList.remove("open-modal");
      this.setState({
        modalBal: {
          ...this.state.modalBal,
          openModal: false,
          showAnimModal: false,
          success: false,
          error: false,
          errorMes: "",
        },
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioClose.currentTime = 0;
        this.audioClose.volume = 0.1;
        this.audioClose.play();
      }
    }
  }

  clickBg = (event) => {
    document.getElementsByClassName("header-menu")[0].classList.remove("open");
    document.getElementsByClassName("bg-plane")[0].classList.remove("open");
    document.getElementsByClassName("show-hide-bt")[0].classList.remove("open");

    this.setState({
      ...this.state,
      opendrop: false,
      openLk: false,
    });
  };

  clickMenu = (event) => {
    if (window.innerWidth < 1410) {
      //console.log(event);
      //event.preventDefault();
      //var currentEventTarget = event.currentTarget;
      var menu = document.getElementsByClassName("show-hide-bt")[0];
      //console.log(menu);
      if (!menu.classList.contains("open")) {
        document.getElementsByClassName("header-menu")[0].classList.add("open");
        document.getElementsByClassName("bg-plane")[0].classList.add("open");
        document
          .getElementsByClassName("show-hide-bt ")[0]
          .classList.add("open");
        document
          .getElementsByClassName("header-lk")[0]
          .classList.remove("open");
        document
          .getElementsByClassName("header-lk-top")[0]
          .classList.remove("open");
      } else {
        document
          .getElementsByClassName("header-menu")[0]
          .classList.remove("open");
        document.getElementsByClassName("bg-plane")[0].classList.remove("open");
        document
          .getElementsByClassName("show-hide-bt ")[0]
          .classList.remove("open");
        document
          .getElementsByClassName("header-lk")[0]
          .classList.remove("open");
        document
          .getElementsByClassName("header-lk-top")[0]
          .classList.remove("open");
      }
      //console.log(currentEventTarget);
    }
  };

  render() {
    var bamBal = 0;
    var walletBal = 0;

    if (this.props.game !== null && this.props.bam !== null) {
      bamBal = Number(this.props.game);
      bamBal = bamBal.toFixed(2);
      walletBal = Number(this.props.bam);
      walletBal = walletBal.toFixed(2);
    }

    return (
      <>


        <div className="bg-plane" onClick={this.clickBg}></div>

        {this.state.hasError ? (
          <ErrorComponent
            errorMessage={this.state.errorText}
            clearError={this.clearError}
          />
        ) : null}


        <header className="headerabsol">
          <div className="container">
            <div className="header-in">
              <div className="show-mobile-menu">
                <button
                  className="show-hide-bt def-bt min"
                  onClick={this.clickMenu}
                >
                  <span className="button-in">
                    <span className="button-name">
                      <span className="button-name-in">
                        <span className="button-name-value">
                          <i></i>
                          <i></i>
                          <i></i>
                        </span>
                      </span>
                    </span>
                  </span>
                </button>
              </div>

              <div className="header-logo">

                <picture>
                  <source srcSet={logoHeaderW} type="image/webp" />
                  <source srcSet={logoHeader} type="image/png" />
                  <img src={logoHeader} alt="" />
                </picture>
              </div>


              <div className="art-header">
                <div className="art-in">
                  <div className="art-top">
                    <picture>
                      <source srcSet={shafltopw} type="image/webp" />
                      <source srcSet={shafltop} type="image/png" />
                      <img className="hidedesc" src={shafltop} alt="" />
                    </picture>
                  </div>

                  <div className="art-mid">
                    <div className="art-mid-in">
                      {this.state.artitem && this.state.artitem.length ? (
                        <Artefacts
                          showCloseInfoModal={this.showCloseInfoModal}
                          artitem={this.state.artitem}
                        />
                      ) : null}
                      {/* <div className="pol pol1"></div>
                        <div className="pol pol2"></div>
                        <div className="pol pol3"></div> */}
                    </div>
                  </div>

                  <div className="art-bot">
                    <picture>
                      <source srcSet={shaflbotw} type="image/webp" />
                      <source srcSet={shaflbot} type="image/png" />
                      <img className="hidedesc" src={shaflbot} alt="" />
                    </picture>
                  </div>
                </div>
              </div>


              <div className="menu-will-be left-part">
                <div className="one-menu-item tooltip">
                  <NavLink
                    className="icon-bt home"
                    to="/leaderboard"

                    activeClassName="active"
                  ></NavLink>
                  <div className="cust-tooltip bottom">
                    <div className="tool-arrow"></div>
                    <div className="tool-wrap">
                      <div className="tool-wrap-1">
                        <div className="tool-wrap-2">
                          <div className="tool-body">Home</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>




              <div className="header-menu">
                <div className="header-menu-in">
                  <div className="one-menu-item hide-desc">
                    <NavLink
                      className="def-bt min"
                      to="/leaderboard"

                      activeClassName="active"
                    >
                      <span className="button-in">
                        <span className="button-name">
                          <span className="button-name-in">
                            <span className="button-name-value">
                              Home
                            </span>
                          </span>
                        </span>
                      </span>
                    </NavLink>
                  </div>



                </div>
              </div>

            </div>
          </div>

        </header>

      </>
    );
  }
}

export default Menu;
