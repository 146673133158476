const QUESTITEMS = 'QUESTITEMS';
const SET_QUEST_INFO = 'SET_QUEST_INFO';
const SET_NEW_QUEST_INFO = 'SET_NEW_QUEST_INFO';
const SET_TAVERN_INFO = 'SET_TAVERN_INFO';
const CHANGE_TABS = 'CHANGE_TABS';
const CAN_COMPLATE = 'CAN_COMPLATE';
const GET_QUEST = 'GET_QUEST';
const GET_QUEST_NEW = 'GET_QUEST_NEW';
const DIS = 'DIS';
const NUMBER_ITEMS = 'NUMBER_ITEMS';

/*
name username;
    
    uint32_t manyitems1 = 1;
    uint32_t advbest1 = 4; // done

    uint32_t manyitems2 = 0;
    uint32_t craftjew2 = 0; // done
    uint32_t advpanda2 = 40; // done

    uint32_t manyitems3 = 2;
    uint32_t story3 = 99; // done

    uint32_t manyitems4 = 0;
    uint32_t craftjew4 = 0; // done
    uint32_t change4 = 40; // 
*/

let initialState = {
    quests: [
        {
            active: true,
            timer: "9999999999",
            nameQuest: "Arcane Mastery",
            max: 1,
            allItemTmpl: [],
            bring: [
                {
                    tmpl: 431186,
                    type: "res",
                    num: 60,
                    tit: "Dwemer"
                },
                {
                    tmpl: 431184,
                    type: "res",
                    num: 100,
                    tit: "Kvessir"
                },
                {
                    tmpl1: 430312,
                    tmpl2: 430161,
                    tmpl3: 430148,
                    tmpl4: 430136,
                    tmpl5: 430155,
                    type: "gem",
                    num: 90,
                    tit: "Any type of gems"
                },
                {
                    type: "questcraftuncarm",
                    nameTable: "craftuncarm1",
                    craft: true,
                    num: 2,
                    craft: 2,
                    tit: "You need to craft two Uncommon Armor"
                },
                {
                    type: "questsenduncarm",
                    nameTable: "sendarm1",
                    //craft: true,
                    num: 3,
                    tit: "You need send three Uncommon armor"
                },
                {
                    type: "questchangewoodkves",
                    nameTable: "change1",
                    num: 100,
                    tit: "You need to exchange any resources to get Kvessir"
                },

                {
                    tmpl: "bamboo",
                    type: "bam",
                    num: 1500,
                    numLetter: "1.5k",
                    tit: process.env.REACT_APP_VAL
                },
            ],
            reward: {
                name: "epic Armor",
                tmpl1: 556629,
                tmpl2: 556628,
                tmpl3: 556627,
                tmpl4: 556626,
                type: "armor",
                many: 20
            }
        }, // was first
        {
            active: true,
            timer: "9999999999",
            nameQuest: "Strength of Mind",
            max: 1,
            allItemTmpl: [],
            bring: [
                {
                    tmpl: 431186,
                    type: "res",
                    num: 200,
                    tit: "Dwemer"
                },
                {
                    tmpl: 431185,
                    type: "res",
                    num: 90,
                    tit: "Gold"
                },
                {
                    type: "questcraftuncarm",
                    nameTable: "craftuncarm2",
                    craft: true,
                    num: 2,
                    craft: 2,
                    tit: "You need to craft two Uncommon Armor"
                },
                {
                    type: "questsendrarearm",
                    nameTable: "sendrarearm2",
                    //craft: true,
                    num: 1,
                    tit: "You need send 1 rare armor"
                },
                {
                    type: "questsenduncarm",
                    nameTable: "senduncearm2",
                    //craft: true,
                    num: 4,
                    tit: "You need send 4 uncommon armor"
                },
                {
                    type: "advbest",
                    nameTable: "advbest2",
                    num: 5,
                    tit: "You need to finish 5 adventures with a +100% reward"
                },
                {
                    tmpl: "bamboo",
                    type: "bam",
                    num: 3000,
                    numLetter: "3k",
                    tit: "bam"
                },
            ],
            reward: {
                name: "Legendary <br />Armor",
                tmpl1: 583560,
                tmpl2: 583558,
                tmpl3: 583557,
                tmpl4: 583556,
                type: "armor",
                many: 10
            }
        }, // was sec
        {
            active: true,
            timer: "9999999999",
            nameQuest: "Elgard's Badge of Valor",
            allItemTmpl: [],
            bring: [
                {
                    tmpl: 431186,
                    type: "res",
                    num: 10,
                    tit: "Dwemer"
                },
                // {
                //     tmpl: 431185,
                //     type: "res",
                //     num: 20,
                //     tit: "Gold"
                // },
                // {
                //     tmpl: 431184,
                //     type: "res",
                //     num: 9,
                //     tit: "Kvessir"
                // },
                {
                    tmpl: 431183,
                    type: "res",
                    num: 30,
                    tit: "titawin"
                },
                {
                    tmpl: 431182,
                    type: "res",
                    num: 50,
                    tit: "steel"
                },
                {
                    tmpl: 431181,
                    type: "res",
                    num: 70,
                    tit: "Wood"
                },
                {
                    tmpl1: 430312,
                    tmpl2: 430161,
                    tmpl3: 430148,
                    tmpl4: 430136,
                    tmpl5: 430155,
                    type: "gem",
                    num: 90,
                    tit: "Any type of gems"
                },
                // {
                //     type: "onestory",
                //     num: 1,
                //     nameTable: "story3",
                //     tit: "You need to receive the story (Ogre) on the Forest of Ulrien"
                // },
                // {
                //     tmpl: "bamboo",
                //     type: "bam",
                //     num: 600,
                //     numLetter: "0.6k",
                //     tit: "bam"
                // },
            ],
            reward: {
                name: "Elgard's <br /> Badge of Valor",
                tmpl1: 664448,
                type: "art",
                many: 200
            }
        } // was third

    ],
    questsArt: [
        {
            active: true,
            timer: "9999999999",
            nameQuest: "Beginning of Way",
            max: 1,
            allItemTmpl: [],
            bring: [

                {
                    type: "lvlup",
                    nameTable: "lvlup1",
                    num: 1,
                    tit: "You need to increase the rank of Hero"
                },
                {
                    type: "eat",
                    nameTable: "eat1",
                    num: 1,
                    tit: "You need to feed a Hero"
                },
                {
                    type: "advbest",
                    nameTable: "advbest1",
                    num: 3,
                    tit: "You need to finish 3 adventures with a +100% reward"
                },
                {
                    type: "advbad",
                    nameTable: "advbad1",
                    num: 3,
                    tit: "You need to finish 3 adventures with a -100% reward"
                },
            ],
            reward: {
                name: "Wooden Chest",
                tmpl1: "questlootbox",
                tmpl: 626793,
                type: "chest",
                many: 1
            }
        }, // was first
        {
            active: true,
            timer: "9999999999",
            nameQuest: "Beginning of Craft",
            max: 1,
            allItemTmpl: [],
            bring: [

                {
                    type: "craftweapon",
                    nameTable: "craftweap2",
                    num: 1,
                    tit: "You need to craft one Weapon"
                },
                {
                    type: "advpandabam",
                    num: 5,
                    nameTable: "advpandabam2",
                    tit: "You need to send Hero to the Forest of Ulrien with equipped weapon 5 times"
                },
                {
                    type: "advpandares",
                    num: 5,
                    nameTable: "advpandares2",
                    tit: "You need to send Hero to the Forest of Nyoron with equipped weapon 5 times"
                },
                {
                    type: "advpandagem",
                    num: 5,
                    nameTable: "advpandagem2",
                    tit: "You need to send Hero to the Caves of Terrabarium with equipped weapon 5 times"
                },
            ],
            reward: {
                name: "Wooden Chest",
                tmpl1: "questlootbox",
                tmpl: 626793,
                type: "chest",
                many: 1
            }
        }, // was sec
        {
            active: true,
            timer: "9999999999",
            nameQuest: "Beginning of Story",
            allItemTmpl: [],
            bring: [


                {
                    type: "getwood",
                    nameTable: "getwood3",
                    num: 10,
                    tit: "You need to collect 10 pieces of wood from the adventures"
                },
                {
                    type: "getgems",
                    nameTable: "getgems3",
                    num: 10,
                    tit: "You need to collect 10 gems from the adventures"
                },
                {
                    type: "buyoneslot",
                    num: 1,
                    nameTable: "buyslot3",
                    tit: "You need to Buy at least one slot"
                },
                {
                    type: "getrank",
                    num: 1,
                    nameTable: "getrank3",
                    tit: "You need to have a hero with a rank of Second Lieutenant"
                },
            ],
            reward: {
                name: "Wooden Chest",
                tmpl1: "questlootbox",
                tmpl: 626793,
                type: "chest",
                many: 1
            }
        }, // was third
        {
            active: true,
            timer: "9999999999",
            nameQuest: "Beginning of Greatness",
            allItemTmpl: [],
            bring: [
                {
                    type: "questchange",
                    nameTable: "changetit4",
                    num: 5,
                    tit: "You need to exchange any resources to get Titawin"
                },
                {
                    type: "getset",
                    nameTable: "getset4",
                    num: 1,
                    tit: "You need to equip Hero in set and send him to any adventure"
                },
                {
                    type: "repareweapon",
                    nameTable: "repare4",
                    num: 1,
                    tit: "You need to repair any weapon"
                },
                {
                    type: "reparejew",
                    nameTable: "repare4",
                    num: 1,
                    tit: "You need to repair any jewelry"
                },
                {
                    type: "reparearmor",
                    nameTable: "repare4",
                    num: 1,
                    tit: "You need to repair any armor"
                },
            ],
            reward: {
                name: "Wooden Chest",
                tmpl1: "questlootbox",
                tmpl: 626793,
                type: "chest",
                many: 1
            }
        },
    ],
    allTmpl: [
        431186, 431185, 431184, 431183, 431182, 431181,
        429272, 428540, 428220, 428200,
        412726, 412736, 412750, 413107,
        111111,
        222222,  // pandas
        333333 // armor
    ],
    allresgroup: null,
    count: null,
    canComplate: false,
    quest: [],
    questNew: {},
    activeTab: 0,
    tavern: []
};

const questReduser = (state = initialState, action) => {

    switch (action.type) {

        case QUESTITEMS: {
            //console.log("QUESTITEMS", action.items);
            return { ...state, allresgroup: action.items }
        }
        case NUMBER_ITEMS: {
            //console.log("NUMBER_ITEMS", action.data.list);
            return { ...state, count: action.data.list }
        }
        case SET_QUEST_INFO: {
            //console.log("SET_QUEST_INFO", action.data.roww);
            return {
                ...state,
                quest: action.data.roww,
            }
        }
        case SET_NEW_QUEST_INFO: {
            //console.log("SET_QUEST_INFO", action.data.roww);
            return {
                ...state,
                questNew: action.data.roww,
            }
        }
        case SET_TAVERN_INFO: {
            //console.log("SET_TAVERN_INFO", action.data.rows);
            return {
                ...state,
                tavern: action.data.rows,
            }
        }
        case CHANGE_TABS: {
            //console.log("CHANGE_TABS", action.data.tab);
            return {
                ...state,
                activeTab: action.data.tab,
            }
        }
        case CAN_COMPLATE: {
            //console.log("CAN_COMPLATE", action.data.can);
            return {
                ...state,
                canComplate: action.data.can,
            }
        }
        case DIS: {
            //console.log("DIS", action.data.count);

            if (action.data.count == 0 || action.data.count == "first") {
                return {
                    ...state,
                    tavern: {
                        ...state.tavern,
                        quest1: state.tavern.quest1 - 1
                    }
                }
            } else if (action.data.count == 1 || action.data.count == "second") {
                return {
                    ...state,
                    tavern: {
                        ...state.tavern,
                        quest2: state.tavern.quest2 - 1
                    }
                }
            } else if (action.data.count == 2 || action.data.count == "third") {
                return {
                    ...state,
                    tavern: {
                        ...state.tavern,
                        quest2: state.tavern.quest3 - 1
                    }
                }
            } else if (action.data.count == 3 || action.data.count == "four") {
                return {
                    ...state,
                    tavern: {
                        ...state.tavern,
                        quest2: state.tavern.quest4 - 1
                    }
                }
            } else if (action.data.count == 4 || action.data.count == "five") {
                return {
                    ...state,
                    tavern: {
                        ...state.tavern,
                        quest2: state.tavern.quest5 - 1
                    }
                }
            } else if (action.data.count == 5 || action.data.count == "six") {
                return {
                    ...state,
                    tavern: {
                        ...state.tavern,
                        quest2: state.tavern.quest6 - 1
                    }
                }
            } else if (action.data.count == 6 || action.data.count == "seven") {
                return {
                    ...state,
                    tavern: {
                        ...state.tavern,
                        quest2: state.tavern.quest7 - 1
                    }
                }
            } else if (action.data.count == 7 || action.data.count == "eight") {
                return {
                    ...state,
                    tavern: {
                        ...state.tavern,
                        quest2: state.tavern.quest8 - 1
                    }
                }
            }
        }
        case GET_QUEST: {
            //console.log("GET_QUEST", action.data.count);

            if (action.data.done) {
                if (action.data.count == 0 || action.data.count == "first") {
                    return {
                        ...state,
                        quest: {
                            ...state.quest,
                            questdone1: 1
                        }
                    }
                } else if (action.data.count == 1 || action.data.count == "second") {
                    return {
                        ...state,
                        quest: {
                            ...state.quest,
                            questdone2: 1
                        }
                    }
                } else if (action.data.count == 2 || action.data.count == "third") {
                    return {
                        ...state,
                        quest: {
                            ...state.quest,
                            questdone3: 1
                        }
                    }
                } else if (action.data.count == 3 || action.data.count == "four") {
                    return {
                        ...state,
                        quest: {
                            ...state.quest,
                            questdone4: 1
                        }
                    }
                } else if (action.data.count == 4 || action.data.count == "five") {
                    return {
                        ...state,
                        quest: {
                            ...state.quest,
                            questdone5: 1
                        }
                    }
                } else if (action.data.count == 5 || action.data.count == "six") {
                    return {
                        ...state,
                        quest: {
                            ...state.quest,
                            questdone6: 1
                        }
                    }
                } else if (action.data.count == 6 || action.data.count == "seven") {
                    return {
                        ...state,
                        quest: {
                            ...state.quest,
                            questdone7: 1
                        }
                    }
                } else if (action.data.count == 7 || action.data.count == "eight") {
                    return {
                        ...state,
                        quest: {
                            ...state.quest,
                            questdone8: 1
                        }
                    }
                }
            }
            else {
                if (action.data.count == 0 || action.data.count == "first") {
                    return {
                        ...state,
                        quest: {
                            ...state.quest,
                            quest1: 1
                        }
                    }
                } else if (action.data.count == 1 || action.data.count == "second") {
                    return {
                        ...state,
                        quest: {
                            ...state.quest,
                            quest2: 1
                        }
                    }
                } else if (action.data.count == 2 || action.data.count == "third") {
                    return {
                        ...state,
                        quest: {
                            ...state.quest,
                            quest3: 1
                        }
                    }
                } else if (action.data.count == 3 || action.data.count == "four") {
                    return {
                        ...state,
                        quest: {
                            ...state.quest,
                            quest4: 1
                        }
                    }
                } else if (action.data.count == 4 || action.data.count == "five") {
                    return {
                        ...state,
                        quest: {
                            ...state.quest,
                            quest5: 1
                        }
                    }
                } else if (action.data.count == 5 || action.data.count == "six") {
                    return {
                        ...state,
                        quest: {
                            ...state.quest,
                            quest6: 1
                        }
                    }
                } else if (action.data.count == 6 || action.data.count == "seven") {
                    return {
                        ...state,
                        quest: {
                            ...state.quest,
                            quest7: 1
                        }
                    }
                } else if (action.data.count == 7 || action.data.count == "eight") {
                    return {
                        ...state,
                        quest: {
                            ...state.quest,
                            quest8: 1
                        }
                    }
                } else if (action.data.count == 8 || action.data.count == "nine") {
                    return {
                        ...state,
                        quest: {
                            ...state.quest,
                            quest9: 1
                        }
                    }
                } else if (action.data.count == 9 || action.data.count == "ten") {
                    return {
                        ...state,
                        quest: {
                            ...state.quest,
                            quest10: 1
                        }
                    }
                } else if (action.data.count == 10 || action.data.count == "eleven") {
                    return {
                        ...state,
                        quest: {
                            ...state.quest,
                            quest11: 1
                        }
                    }
                } else if (action.data.count == 11 || action.data.count == "twelve") {
                    return {
                        ...state,
                        quest: {
                            ...state.quest,
                            quest12: 1
                        }
                    }
                }
            }
        }
        case GET_QUEST_NEW: {
            //console.log("GET_QUEST", action.data.count);

            if (action.data.done) {
                if (action.data.count == 0) {
                    return {
                        ...state,
                        questNew: {
                            ...state.questNew,
                            questdone1: 1
                        }
                    }
                } else if (action.data.count == 1) {
                    return {
                        ...state,
                        questNew: {
                            ...state.questNew,
                            questdone2: 1
                        }
                    }
                } else if (action.data.count == 2) {
                    return {
                        ...state,
                        questNew: {
                            ...state.questNew,
                            questdone3: 1
                        }
                    }
                } else if (action.data.count == 3) {
                    return {
                        ...state,
                        questNew: {
                            ...state.questNew,
                            questdone4: 1
                        }
                    }
                }
            }
            else {
                if (action.data.count == 0) {
                    return {
                        ...state,
                        questNew: {
                            ...state.questNew,
                            quest1: 1
                        }
                    }
                } else if (action.data.count == 1) {
                    return {
                        ...state,
                        questNew: {
                            ...state.questNew,
                            quest2: 1
                        }
                    }
                } else if (action.data.count == 2) {
                    return {
                        ...state,
                        questNew: {
                            ...state.questNew,
                            quest3: 1
                        }
                    }
                } else if (action.data.count == 3) {
                    return {
                        ...state,
                        questNew: {
                            ...state.questNew,
                            quest4: 1
                        }
                    }
                }
            }
        }

        /*case JEW_ON_PANDA: {
           // console.log('EATLIST', action.eatlist);
            return {...state, pandaJew: action.pandaJew}
        }*/


        default:
            return state;
    }
}

export const questitems = (items) => ({ type: QUESTITEMS, items })
export const setQuestInfo = (roww) => ({ type: SET_QUEST_INFO, data: { roww } })
export const setNewQuestInfo = (roww) => ({ type: SET_NEW_QUEST_INFO, data: { roww } })
export const setTavernInfo = (rows) => ({ type: SET_TAVERN_INFO, data: { rows } })
export const changeTabs = (tab) => ({ type: CHANGE_TABS, data: { tab } })
export const canComplateQuest = (can) => ({ type: CAN_COMPLATE, data: { can } })
export const getQuestDisp = (count, done) => ({ type: GET_QUEST, data: { count, done } })
export const getQuestDispNew = (count, done) => ({ type: GET_QUEST_NEW, data: { count, done } })
export const dis = (count, done) => ({ type: DIS, data: { count } })
export const numberitems = (list) => ({ type: NUMBER_ITEMS, data: { list } })

export default questReduser;
