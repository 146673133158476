import React from 'react';

const ExchGem = (props) => {


    var numItems = 0;
    if (window.screen.width >= 1330) {
        numItems = 16
    } else {
        numItems = 100
    }
    return (<>
        {props.exchRes.map((item, index) => {
            if (index < props.activePageExch * numItems && index >= (props.activePageExch - 1) * numItems) {

                return (

                    <div key={index} className={`one-exch-item  ${props.activeExchItem.activeNum === index ? 'active' : null} ${props.activeItem.img == item.img ? 'def' : null} `}>
                        <div className="one-exch-item-in">
                            <div className="one-exch-prod-thumb">
                                {props.activeItem.img != item.img ? <div className='linkexch' onClick={() => props.chooseExch(2, item.img, Math.floor(props.activeItem.count / 2) * 2, index)} ></div> : null}
                                <div className="value-exch-eat">
                                    {
                                        props.activeItem.img ?
                                            Math.floor(props.activeItem.count / 2)
                                            :
                                            null
                                    }
                                </div>
                                <span></span>
                                <div className="one-exch-prod-thumb-in">
                                    <picture>
                                        <source srcSet={`./images/jew/inventory/${item.img}.webp`} type="image/webp" />
                                        <source srcSet={`./images/jew/inventory/${item.img}.png`} type="image/png" />
                                        <img src={`./images/jew/inventory/${item.img}.png`} alt="" />
                                    </picture>
                                    {/* <picture>
                                    <source srcSet={`./images/jew/inventory/431181.webp`} type="image/webp" />
                                    <source srcSet={`./images/jew/inventory/431181.png`} type="image/png" /> 
                                    <img src={`./images/jew/inventory/431181.png`} alt="" />
                                </picture> */}
                                    <i></i>
                                    <i className="b"></i>
                                </div>


                            </div>
                        </div>
                    </div>

                );
            }
        })
        }




    </>);

}

export default ExchGem;