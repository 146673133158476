const SET_PANDA_ON_GAME = 'SET_PANDA_ON_GAME';
const TOGGLE_IS_FETCHING_TOP = 'TOGGLE_IS_FETCHING';
const DO_TRANSFER_TO_USER = 'DO_TRANSFER_TO_USER';
const REMOVE_FROM_STATE = 'REMOVE_FROM_STATE';
const ADD_TO_GAME_STATE = 'ADD_TO_GAME_STATE';

let initialState = {
    pandaListOnGame: null,
    isFetching: true
};

const pandaReducer = (state = initialState, action) => {

    switch(action.type) {
        case SET_PANDA_ON_GAME: {
            return {...state, pandaListOnGame: action.pandaListOnGame}
        }
        case TOGGLE_IS_FETCHING_TOP: {
            return { ...state, isFetching: action.isFetching}
        }
        case DO_TRANSFER_TO_USER: {
            return { ...state, isFetching: action.transferToUser}
        }
        case REMOVE_FROM_STATE: {
            return { ...state, pandaListOnGame: state.pandaListOnGame.filter(n => n.asset_id !== action.removePandaFromState).map( u =>  {
                return u;
                })
            }
        }
        case ADD_TO_GAME_STATE: {
            console.log(action.addNft);
            return { ...state, pandaListOnGame: [action.addNft].concat(state.pandaListOnGame)
            }
        }
        default:
            return state;
    }
}


export const setPandaOnGame = (pandaListOnGame) => ({type: SET_PANDA_ON_GAME, pandaListOnGame})
export const toggleIsFetchingTop = (isFetching) => ({type: TOGGLE_IS_FETCHING_TOP, isFetching })
export const doTransferToUser = (transferToUser) => ({type: DO_TRANSFER_TO_USER, transferToUser })
export const doDelFromState = (removePandaFromState) => ({type: REMOVE_FROM_STATE, removePandaFromState })
export const addToGameState = (addNft) => ({type: ADD_TO_GAME_STATE, addNft })

export default pandaReducer;
