import React from "react";
import Menu from '../../components/Menu';

import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import {
  weaponlist,
  statusLoadinWeapon,
  addWeaponWhenRemove,
  addWeaponToPanda,
} from "../../GlobalState/weapon-reducer";

import ErrorComponent from "../Error/ErrorComponent";
import CraftHomeLoop from "./CraftHomeLoop";
import cryptoRandomString from "crypto-random-string";

import { JsonRpc } from "eosjs";
import "./../../css/craft.scss";

import musicError from "./../../music/error.mp3";
import musicOpenCraft from "./../../music/bt-craft.mp3";
import craftSuccess from "./../../music/craft-success.mp3";
import craftFail from "./../../music/craft-fail.mp3";
import newClick from "./../../music/newclick.mp3";

import { ExplorerApi } from "atomicassets"; // атомик для списка панд

const fetch = require("node-fetch");
const api = new ExplorerApi(
  "https://" + localStorage.getItem('rpcA'),
  "atomicassets",
  { fetch, rateLimit: 4 }
);

class CraftPageComponentJewelry extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      craftweapon: [
        {
          headWeapon: "bracelet",
          allelements: [
            {
              weapon: 414785,
              element: "Water",
              resource: 431181,
              stone: 430141,
              name: "Tihash",
            },
            {
              weapon: 415378,
              element: "Fire",
              resource: 431181,
              stone: 430152,
              name: "Tihash",
            },
            {
              weapon: 415402,
              element: "Wind",
              resource: 431181,
              stone: 430162,
              name: "Tihash",
            },
            {
              weapon: 415409,
              element: "Earth",
              resource: 431181,
              stone: 430312,
              name: "Tihash",
            },
          ],
        },
        {
          headWeapon: "necklace",
          allelements: [
            {
              weapon: 413131,
              element: "Water",
              resource: 431181,
              stone: 430131,
              name: "Apatite",
            },
            {
              weapon: 413857,
              element: "Fire",
              resource: 431181,
              stone: 430143,
              name: "Apatite",
            },
            {
              weapon: 413867,
              element: "Wind",
              resource: 431181,
              stone: 430155,
              name: "Apatite",
            },
            {
              weapon: 414774,
              element: "Earth",
              resource: 431181,
              stone: 430164,
              name: "Apatite",
            },
          ],
        },
        {
          headWeapon: "ring",
          allelements: [
            {
              weapon: 412726,
              element: "Water",
              resource: 431181,
              stone: 430138,
              name: "Kyanite",
            },
            {
              weapon: 412736,
              element: "Fire",
              resource: 431181,
              stone: 430148,
              name: "Kyanite",
            },
            {
              weapon: 412750,
              element: "Wind",
              resource: 431181,
              stone: 430158,
              name: "Kyanite",
            },
            {
              weapon: 413107,
              element: "Earth",
              resource: 431181,
              stone: 430310,
              name: "Kyanite",
            },
          ],
        },
        {
          headWeapon: "brooch",
          allelements: [
            {
              weapon: 416477,
              element: "Water",
              resource: 431181,
              stone: 430139,
              name: "Pliacrin",
            },
            {
              weapon: 416488,
              element: "Fire",
              resource: 431181,
              stone: 430151,
              name: "Pliacrin",
            },
            {
              weapon: 417238,
              element: "Wind",
              resource: 431181,
              stone: 430161,
              name: "Pliacrin",
            },
            {
              weapon: 417251,
              element: "Earth",
              resource: 431181,
              stone: 430311,
              name: "Pliacrin",
            },
          ],
        },
        {
          headWeapon: "earring",
          allelements: [
            {
              weapon: 428200,
              element: "Water",
              resource: 431181,
              stone: 430136,
              name: "Indicolite",
            },
            {
              weapon: 428220,
              element: "Fire",
              resource: 431181,
              stone: 430145,
              name: "Indicolite",
            },
            {
              weapon: 428540,
              element: "Wind",
              resource: 431181,
              stone: 430156,
              name: "Indicolite",
            },
            {
              weapon: 429272,
              element: "Earth",
              resource: 431181,
              stone: 430309,
              name: "Indicolite",
            },
          ],
        },
      ],
      activeWeaponName: "",
      activeRecipe: {
        name: "",
        weapon: "",
        element: "",
        resource: "",
        stone: "",
      },
      openRecipe: false,
      counPartWeapon: {
        resource: "",
        stone: "",
      },
      allparts: {}, // все части этого оружия который хотят скрафтить
      allThisWeapon: {}, // все оружия
      modalResult: {
        open: false, // показать модалку результата
        loading: true, // ожидание результата
      },
      craftpay: false, //
      modalcraft: {
        top: "Craft jewelry",
        title: "The blacksmith is ready to start work",
        button: "first",
        descr:
          `The blacksmith always takes money <span>(10 ${process.env.REACT_APP_VAL})<i></i></span> for the front, since nothing motivates like a salary. `,
      },
      craftfail: "",
      craftResult: "",
      activeId: "",
      activeEl: "",
      loadingNft: true,
    };

    this.clearError = this.clearError.bind(this);
    this.openRecipe = this.openRecipe.bind(this);
    this.backFromRecipe = this.backFromRecipe.bind(this);
    this.craftpay = this.craftpay.bind(this);
    this.opencraftmodal = this.opencraftmodal.bind(this);
    this.craft = this.craft.bind(this);

    this.medalCheck = this.medalCheck.bind(this);
    //this.GetPandaPartListStone = this.GetPandaPartListStone.bind(this);
    this.GetPandaPartListRes = this.GetPandaPartListRes.bind(this);
    //this.GetPandaWeaponList = this.GetPandaWeaponList.bind(this);
    this.closeModalResult = this.closeModalResult.bind(this);
    this.closeModalSteps = this.closeModalSteps.bind(this);
    this.audioMusicError = new Audio(musicError);
    this.audioMusicOpenCraft = new Audio(musicOpenCraft);
    this.audioMusicCraftSuccess = new Audio(craftSuccess);
    this.audioMusicCraftfail = new Audio(craftFail);
    this.audioMusicNewClick = new Audio(newClick);
  }

  componentDidMount() {
    this.medalCheck();

    let urlpage = new URLSearchParams(window.location.search);

    let id = urlpage.get("id");
    let el = urlpage.get("el");

    if (id && el) {
      this.setState(
        {
          activeId: id,
          activeEl: el,
        },
        this.openRecipe(id, el)
      );
    }
  }

  async medalCheck() {
    try {
      const activeUser = this.props.ual.activeUser;
      const accountName = await activeUser.getAccountName();
      //console.log(activeUser);
      //console.log(accountName);
      let rpcc;
      if (this.props.ual.activeUser.rpc != undefined) {
        rpcc = this.props.ual.activeUser.rpc;
      } else if (
        this.props.ual.activeUser.rpc == undefined &&
        this.props.ual.activeUser.wax
      ) {
        rpcc = this.props.ual.activeUser.wax.rpc;
      } else {
        rpcc = new JsonRpc(localStorage.getItem("rpc"));
      }
      const results = await rpcc.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "medaltop", // name of the table as specified by the contract abi
        //"table": "usersnew",    // name of the table as specified by the contract abi
        limit: 1,
        lower_bound: accountName,
        upper_bound: accountName,
        //lower_bound: 0,
        key_type: "i64",
        //"index_position": 2,
        //"limit": -1,
        reverse: !0,
        show_payer: false,
        index_position: 1,
      });
      const originRowUser = results.rows[0];
      console.log("originRowUser", originRowUser);

      var totalLuck = 0;
      if (results.rows[0]) {
        if (originRowUser.medal100) {
          totalLuck = 5;
          if (originRowUser.medal10) {
            totalLuck += 2.5;
            if (originRowUser.medal300) {
              totalLuck += 1.5;
            }
          } else if (originRowUser.medal300) {
            totalLuck += 1.5;
          }
        } else if (originRowUser.medal10) {
          totalLuck = 5;
          if (originRowUser.medal100) {
            totalLuck += 2.5;
            if (originRowUser.medal300) {
              totalLuck += 1.5;
            }
          } else if (originRowUser.medal300) {
            totalLuck += 1.5;
          }
        } else if (originRowUser.medal300) {
          totalLuck = 3;
          if (originRowUser.medal10) {
            totalLuck += 2.5;
            if (originRowUser.medal100) {
              totalLuck += 2.5;
            }
          } else if (originRowUser.medal100) {
            totalLuck += 2.5;
          }
        }
      }

      if (originRowUser != undefined) {
        this.setState({
          contractMedal: totalLuck,
        });
      }
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
    }
  }

  async clearError() {
    this.setState({
      errorText: "",
      hasError: false,
    });
  }

  openRecipe(index, element, refresh) {
    var openRecipe = true;
    if (refresh == "refresh") {
      openRecipe = false;
    }
    this.setState({
      ...this.state,
      openRecipe: openRecipe,
      activeId: index,
      activeEl: element,
      activeWeaponName: this.state.craftweapon[index].headWeapon,
      activeRecipe: this.state.craftweapon[index].allelements[element],
      loadingNft: true,
    });

    console.log("openRecipe", index, element, refresh, openRecipe);

    (async () => {
      try {
        let promRes = [];
        let promStone = [];

        for (let i = 0; i < 2; i++) {
          if (i == 0) {
            promRes.push(
              this.GetPandaPartListRes(
                this.state.craftweapon[index].allelements[element].resource,
                "res"
              )
            );
            //console.log(222)
          } else {
            promStone.push(
              this.GetPandaPartListRes(
                this.state.craftweapon[index].allelements[element].stone,
                "stone"
              )
            );
            //console.log(333)
          }
        }

        let result = await Promise.all(promRes);
        let resultS = await Promise.all(promStone);
        var resultNew = [];
        resultNew.push(...result[0], ...resultS[0]);
        console.log("result", ...result);
        //console.log('resultS', resultS[0]);

        this.setState({
          ...this.state,
          allparts: resultNew,
          counPartWeapon: {
            resource: result[0].length,
            stone: resultS[0].length,
          },
          loadingNft: false,
        });
      } catch (e) {
        //console.log(e.message)
        console.warn(e);
        this.setState({
          hasError: true,
          errorText: e.message,
        });
        console.log("\nCaught exception: " + e);
      }
    })();

    if (localStorage.getItem("sound") == "true") {
      this.audioMusicNewClick.currentTime = 0;
      this.audioMusicNewClick.volume = 0.4;
      this.audioMusicNewClick.play();
    }
  }

  async backFromRecipe(index, element) {
    this.setState({
      ...this.state,
      openRecipe: false,
      activeEl: "",
      activeId: "",
    });

    if (localStorage.getItem("sound") == "true") {
      this.audioMusicNewClick.currentTime = 0;
      this.audioMusicNewClick.volume = 0.4;
      this.audioMusicNewClick.play();
    }
  }

  // async GetPandaPartListStone(parts)   {
  //     (async () =>  {
  //         try {
  //             const userName = this.props.ual.activeUser.accountName;
  //             const activeUser = this.props.ual.activeUser;
  //             var arr = [parts.stone];
  //             //arr.push();
  //             // console.log("arr", arr);
  //             // console.log("this.state.activeRecipe.bluePrint", this.state.activeRecipe.bluePrint);
  //             const asset = await api.getAssets({
  //                 limit: 1000,
  //                 owner: userName,
  //                 collection_name: 'nftpandawaxp',
  //                 schema_name: ["gems"/*, "promo"*/],
  //                 template_id: arr,
  //                 }).then(assets => {

  //                 console.log('GetPandaPartListStone', assets);
  //                 let resource = 0;
  //                 let stone = 0;
  //                 for (var c in assets) {
  //                     //console.log(byTemplate[c]);
  //                     if(assets[c].template.template_id == this.state.activeRecipe.stone) {
  //                         stone += 1;
  //                     }
  //                 }

  //                 this.setState({
  //                     ...this.state,
  //                     allparts: assets,
  //                     counPartWeapon: {
  //                         stone: stone
  //                     }
  //                 });
  //             });
  //         } catch (e) {
  //             this.setState({
  //                 hasError: true,
  //                 errorText: e.message,
  //             });
  //             console.log('\nCaught exception: ' + e);
  //             if(localStorage.getItem('sound') == 'true') {
  //                 this.audioMusicError.currentTime = 0;
  //                 this.audioMusicError.volume = 0.1;
  //                 this.audioMusicError.play();
  //             }
  //         }
  //     })();
  // }

  async GetPandaPartListRes(parts, type) {
    var schema;
    if (type == "res") {
      schema = "resources";
    } else {
      schema = "gems";
    }
    console.log("parts", parts);
    console.log("type", type);
    console.log("schema", schema);

    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    //var arr = [parts.resource, parts.stone];
    //arr.push();
    // console.log("arr", arr);
    // console.log("this.state.activeRecipe.bluePrint", this.state.activeRecipe.bluePrint);
    return await api
      .getAssets({
        limit: 1000,
        owner: userName,
        collection_name: "nftpandawaxp",
        schema_name: schema,
        template_id: parts,
      })
      .then(
        (assets) => {
          return assets;
        }

        // assets.map(item => {
        //     // return {
        //     //     asset_id: item.asset_id,
        //     //     template_id: item.template.template_id,
        //     //     shema: item.schema.schema_name
        //     // }
        //     return item;

        // })
        //console.log(assets)
      );
  }

  // async GetPandaWeaponList(parts)   {
  //     (async () =>  {
  //         try {
  //             const userName = this.props.ual.activeUser.accountName;
  //             const activeUser = this.props.ual.activeUser;
  //             var arr = [parts.weapon];
  //             //arr.push();
  //             // console.log("arr", arr);
  //             // console.log("this.state.activeRecipe.bluePrint", this.state.activeRecipe.bluePrint);
  //             const asset = await api.getAssets({
  //                 limit: 1000,
  //                 owner: userName,
  //                 collection_name: 'nftpandawaxp',
  //                 schema_name: ["gems"/*, "promo"*/],
  //                 template_id: arr,
  //                 }).then(assets => {

  //                 //console.log('GetPandaWeaponList(parts)', assets);

  //                 this.setState({
  //                     ...this.state,
  //                     allThisWeapon: assets,
  //                 });
  //             });
  //         } catch (e) {
  //             this.setState({
  //                 hasError: true,
  //                 errorText: e.message,
  //             });
  //             console.log('\nCaught exception: ' + e);
  //             if(localStorage.getItem('sound') == 'true') {
  //                 this.audioMusicError.currentTime = 0;
  //                 this.audioMusicError.volume = 0.1;
  //                 this.audioMusicError.play();
  //             }
  //         }
  //     })();
  // }

  async opencraftmodal() {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("open-modal");
    // const number = cryptoRandomString({length: 14, type: 'numeric'});
    // const userName = this.props.ual.activeUser.accountName;
    // const activeUser = this.props.ual.activeUser;

    // try {
    //     let rpcc;
    //     if (this.props.ual.activeUser.rpc != undefined) {
    //         rpcc = this.props.ual.activeUser.rpc
    //     } else if(this.props.ual.activeUser.rpc == undefined && this.props.ual.activeUser.wax) {
    //         rpcc = this.props.ual.activeUser.wax.rpc
    //     } else {
    //         rpcc = new JsonRpc(localStorage.getItem('rpc'));
    //     }
    //     //const userName = this.props.ual.activeUser.accountName;
    //     const results = await rpcc.get_table_rows({
    //         "json": true,
    //         "code": process.env.REACT_APP_CONTRACT,    // contract who owns the table
    //         "scope": process.env.REACT_APP_CONTRACT,   // scope of the table
    //         "table": "oracletable",    // name of the table as specified by the contract abi
    //         //"key_type": "i64",
    //         "lower_bound": userName,
    //         "upper_bound": userName,
    //         //"limit": -1,
    //         //reverse: !0,
    //         show_payer: false,
    //         //index_position: 2,
    //     });
    //     const resultRow = results.rows;
    //     //console.log('resultRow', resultRow[0]);
    //     if(resultRow[0]) {
    //         if(resultRow[0].storer == userName) {
    //             this.setState({
    //                 ...this.setState,
    //                 modalcraft: {
    //                     top: "Craft",
    //                     title: "Now the blacksmith is ready to start work",
    //                     button: "second",
    //                     descr: "hand over all parts of the weapon so that he can start working on his craft. It can take a few minutes, please wait..."
    //                 }
    //             });
    //         }
    //     }
    // } catch (e) {
    //    // console.log(e.message)
    //     console.warn(e)
    //     this.setState({
    //         hasError: true,
    //         errorText: e.message,
    //     });
    //     console.log('\nCaught exception: ' + e);
    //     if(localStorage.getItem('sound') == 'true') {
    //         this.audioMusicError.currentTime = 0;
    //         this.audioMusicError.volume = 0.1;
    //         this.audioMusicError.play();
    //     }
    // }

    this.setState({
      ...this.setState,
      craftpay: true,
    });

    if (localStorage.getItem("sound") == "true") {
      this.audioMusicOpenCraft.currentTime = 0;
      this.audioMusicOpenCraft.volume = 0.3;
      this.audioMusicOpenCraft.play();
    }
  }

  async craftpay() {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");
    const number = cryptoRandomString({ length: 14, type: "numeric" });
    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;

    try {
      await activeUser
        .signTransaction(
          {
            actions: [
              {
                account: process.env.REACT_APP_TOKEN,
                name: "transfer",
                authorization: [
                  {
                    actor: userName, // use account that was logged in
                    permission: "active",
                  },
                ],
                data: {
                  from: userName,
                  to: process.env.REACT_APP_CONTRACT,
                  quantity: "20.0000 " + process.env.REACT_APP_VAL,
                  memo: "weaponpay " + this.state.activeRecipe.weapon + " work",
                },
              },
            ],
          },
          { blocksBehind: 3, expireSeconds: 30 }
        )
        .then((response) => {
          if (response.status === "executed") {
            loadingSlot.classList.remove("loading");
            this.props.updateBalance(false, parseFloat(20.0).toFixed(4));
            this.setState({
              ...this.setState,
              modalcraft: {
                top: "Craft",
                title: "Now the blacksmith is ready to start work",
                button: "second",
                descr:
                  "hand over all parts of the weapon so that he can start working on his craft. It can take a few minutes, please wait...",
              },
            });
          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
        chooseHeroLoading: false,
      });

      if (localStorage.getItem("sound") == "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
      loadingSlot.classList.remove("loading");
    }
  }

  async sendWombat(event) {
    const activeUser = this.props.ual.activeUser;
    const accountName = await activeUser.getAccountName();
    var dateNow = parseInt(Date.now() / 1000);
    var datas = {

      "username": accountName,
      "event": event,
      "eventtime": dateNow,
    }



  }

  async craft() {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");
    const number = cryptoRandomString({ length: 14, type: "numeric" });
    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    var resource = 0;
    var stone1 = 0;
    var stone2 = 0;
    var stone3 = 0;
    var stone4 = 0;
    var stone5 = 0;

    var resource1 = 0;
    var resource2 = 0;
    var resource3 = 0;
    var resource4 = 0;
    var resource5 = 0;
    // console.log('this.state.activeRecipe.bluePrint', this.state.activeRecipe.bluePrint);
    // console.log('this.state.activeRecipe.partOne', this.state.activeRecipe.partOne);
    // console.log('this.state.activeRecipe.partTwo', this.state.activeRecipe.partTwo);
    // console.log('this.state.activeRecipe.partThree', this.state.activeRecipe.partThree);

    this.state.allparts.map((u) => {
      console.log("u[0]", u.template.template_id);
      if (resource1 == 0 && u.template.template_id == 431181) {
        resource1 = u.asset_id;
      } else if (resource2 == 0 && u.template.template_id == 431181) {
        resource2 = u.asset_id;
      } else if (resource3 == 0 && u.template.template_id == 431181) {
        resource3 = u.asset_id;
      } else if (resource4 == 0 && u.template.template_id == 431181) {
        resource4 = u.asset_id;
      } else if (resource5 == 0 && u.template.template_id == 431181) {
        resource5 = u.asset_id;
      } else if (
        stone1 == 0 &&
        u.template.template_id == this.state.activeRecipe.stone
      ) {
        stone1 = u.asset_id;
      } else if (
        stone2 == 0 &&
        u.template.template_id == this.state.activeRecipe.stone
      ) {
        stone2 = u.asset_id;
      } else if (
        stone3 == 0 &&
        u.template.template_id == this.state.activeRecipe.stone
      ) {
        stone3 = u.asset_id;
      } else if (
        stone4 == 0 &&
        u.template.template_id == this.state.activeRecipe.stone
      ) {
        stone4 = u.asset_id;
      } else if (
        stone5 == 0 &&
        u.template.template_id == this.state.activeRecipe.stone
      ) {
        stone5 = u.asset_id;
      }
    });

    //console.log("parts", stone1, stone2, stone3, stone4, stone5, resource1, resource2, resource3, resource4, resource5);
    //console.log("this.state.activeRecipe.weapon", this.state.activeRecipe.weapon);
    // console.log("number", number);

    try {
      await activeUser
        .signTransaction(
          {
            actions: [
              {
                account: "atomicassets",
                name: "transfer",
                authorization: [
                  {
                    actor: userName, // use account that was logged in
                    permission: "active",
                  },
                ],
                data: {
                  from: userName,
                  to: process.env.REACT_APP_CONTRACT,
                  asset_ids: [
                    stone1,
                    stone2,
                    stone3,
                    stone4,
                    stone5,
                    resource1,
                    resource2,
                    resource3,
                    resource4,
                    resource5,
                  ],
                  memo:
                    "craftjew " +
                    this.state.activeRecipe.weapon +
                    " " +
                    number +
                    " for panda",
                },
              },
            ],
          },
          { blocksBehind: 3, expireSeconds: 30 }
        )
        .then((response) => {
          if (response.status === "executed") {
            loadingSlot.classList.remove("loading");
            this.props.updateBalance(false, parseFloat(10.0).toFixed(4));
            this.setState({
              ...this.setState,
              craftpay: false,
              modalResult: {
                open: true,
                loading: true,
              },
            });
            this.getresult();
            this.sendWombat("craft");
          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
        chooseHeroLoading: false,
      });
      if (localStorage.getItem("sound") == "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
      loadingSlot.classList.remove("loading");
    }
  }

  async getresult() {
    (async () => {
      try {
        //console.log('try');
        const userName = this.props.ual.activeUser.accountName;
        const activeUser = this.props.ual.activeUser;
        const weaponsID = this.state.activeRecipe.weapon;

        const asset = await api
          .getAssets({
            limit: 500,
            owner: userName,
            collection_name: "nftpandawaxp",
            schema_name: ["gems" /*, "promo"*/],
            template_id: weaponsID,
          })
          .then((assets) => {
            var perc = 55;
            if (this.state.contractMedal) {
              var perc = 55 + this.state.contractMedal;
            }

            this.interval = setInterval(() => {
              if (this.state.craftfail <= perc && this.state.craftfail != 0) {
                clearInterval(this.interval);

                console.log("YES IT DONE");
                this.setState({
                  ...this.state,
                  craftResult: "success",
                  modalResult: {
                    ...this.state.modalResult,
                    loading: false,
                  },
                });

                if (localStorage.getItem("sound") == "true") {
                  this.audioMusicCraftSuccess.currentTime = 0;
                  this.audioMusicCraftSuccess.volume = 0.4;
                  this.audioMusicCraftSuccess.play();
                }
              } else if (this.state.craftfail > perc) {
                console.log("NOOOOOOOOOOOOOOOOO");

                clearInterval(this.interval);
                this.setState({
                  ...this.state,
                  craftResult: "fail",
                  modalResult: {
                    ...this.state.modalResult,
                    loading: false,
                  },
                });

                if (localStorage.getItem("sound") == "true") {
                  this.audioMusicCraftfail.currentTime = 0;
                  this.audioMusicCraftfail.volume = 0.3;
                  this.audioMusicCraftfail.play();
                }
              }

              //this.GetPandaWeaponList(this.state.activeRecipe);
              this.getUserNoRes();
            }, 10000);
          });
      } catch (e) {
        //console.log(e.message)
        console.warn(e);
        this.setState({
          ...this.state,
          hasError: true,
          errorText: e.message,
          modalEat: {
            ...this.state.modalEat,
            loadingChooseEat: false,
          },
        });
        console.log("\nCaught exception: " + e);
        if (localStorage.getItem("sound") == "true") {
          this.audioMusicError.currentTime = 0;
          this.audioMusicError.volume = 0.1;
          this.audioMusicError.play();
        }
      }
    })();
  }

  async getUserNoRes() {
    const number = cryptoRandomString({ length: 14, type: "numeric" });
    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;

    try {
      let rpcc;
      if (this.props.ual.activeUser.rpc != undefined) {
        rpcc = this.props.ual.activeUser.rpc;
      } else if (
        this.props.ual.activeUser.rpc == undefined &&
        this.props.ual.activeUser.wax
      ) {
        rpcc = this.props.ual.activeUser.wax.rpc;
      } else {
        rpcc = new JsonRpc(localStorage.getItem("rpc"));
      }
      //const userName = this.props.ual.activeUser.accountName;
      const results = await rpcc.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "usersnew", // name of the table as specified by the contract abi
        //"key_type": "i64",
        lower_bound: userName,
        upper_bound: userName,
        //"limit": -1,
        //reverse: !0,
        show_payer: false,
        //index_position: 2,
      });
      const resultRow = results.rows;
      // console.log('resultRow getUserNoRes', resultRow[0]);
      if (resultRow[0]) {
        this.setState({
          ...this.setState,
          craftfail: resultRow[0].random,
        });
      }
    } catch (e) {
      // console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
      if (localStorage.getItem("sound") == "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  async closeModalResult() {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.remove("open-modal");
    this.setState(
      {
        ...this.state,
        // counPartWeapon: {
        //     resource: '',
        //     stone: '',
        // },
        allparts: {}, // все части этого оружия который хотят скрафтить
        allThisWeapon: {}, // все оружия
        modalResult: {
          open: false, // показать модалку результата
          loading: true, // ожидание результата
        },
        craftpay: false, //
        modalcraft: {
          top: "Craft",
          title: "First you have to pay the blacksmith for a job",
          button: "first",
          descr:
            "The blacksmith always takes money for the front, since nothing motivates like a salary",
        },
        craftfail: "",
        craftResult: "",
      },
      this.openRecipe(this.state.activeId, this.state.activeEl, "refresh")
    );

    // console.log("close result this.state.activeRecipe", this.state.activeRecipe);
    //this.GetPandaPartListStone(this.state.activeRecipe);
    //this.GetPandaPartListRes(this.state.activeRecipe);
    //this.GetPandaWeaponList(this.state.activeRecipe);

    if (localStorage.getItem("sound") == "true") {
      this.audioMusicNewClick.currentTime = 0;
      this.audioMusicNewClick.volume = 0.4;
      this.audioMusicNewClick.play();
    }
  }

  async closeModalSteps() {
    this.setState({
      ...this.setState,
      craftpay: false,
    });
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.remove("open-modal");

    if (localStorage.getItem("sound") == "true") {
      this.audioMusicNewClick.currentTime = 0;
      this.audioMusicNewClick.volume = 0.4;
      this.audioMusicNewClick.play();
    }
  }

  render() {
    return (
      <>
        <Menu  {...this.props.ual} wax={this.props.balanceWax} bam={this.props.balanceBam} game={this.props.balanceGame} updateBalance={this.props.updateBalance} />
        {this.state.hasError ? (
          <ErrorComponent
            errorMessage={this.state.errorText}
            clearError={this.clearError}
          />
        ) : null}
        <main className="craft-page jew-craft-page">
          <div className="loading-block"></div>

          <div className="craft-wrap craft-page-bg">
            {this.state.openRecipe ||
              (this.state.activeEl &&
                this.state.activeEl >= 0 &&
                this.state.activeId &&
                this.state.activeId >= 0) ? null : (
              <div className="craft-body-choose">
                <div className="choose-weapon-craft">
                  <div className="choose-weapon-craft-in">
                    <Link className="bt-back" to="/craft">
                      <span></span>
                    </Link>
                    <CraftHomeLoop
                      {...this.props}
                      {...this.state}
                      openRecipe={this.openRecipe}
                    />
                  </div>
                </div>
              </div>
            )}

            {this.state.openRecipe ||
              (this.state.activeEl &&
                this.state.activeEl >= 0 &&
                this.state.activeId &&
                this.state.activeId >= 0) ? (
              <div className="craft-recipe craft-recipe-jew">
                <div className="craft-recipe-in">
                  <div className="craft-recipe-top">
                    <Link
                      className="bt-back"
                      to="/craft-jewelry-page"
                      onClick={(e) => {
                        this.backFromRecipe();
                      }}
                    >
                      <span></span>
                    </Link>
                    <div
                      className="bt-reload"
                      onClick={(e) => {
                        this.openRecipe(
                          this.state.activeId,
                          this.state.activeEl
                        );
                      }}
                    >
                      <span></span>
                    </div>
                  </div>
                  <div className="craft-recipe-wrap">
                    <div
                      className={`craft-recipe-wrap-in ${this.state.loadingNft ? "loading" : "ok"
                        }`}
                    >
                      {/* one part */}
                      <div
                        className={`one-craft-part part-w ${this.state.counPartWeapon.resource >= 5
                          ? "some-ok"
                          : "disabled"
                          }`}
                      >
                        <div className="one-craft-part-in">
                          <div className="one-craft-part-thumb">
                            <picture>
                              <source
                                srcSet={`/images/jew/all/${this.state.activeRecipe.resource}.webp`}
                                type="image/webp"
                              />
                              <source
                                srcSet={`/images/jew/all/${this.state.activeRecipe.resource}.png`}
                                type="image/png"
                              />
                              <img
                                src={`/images/jew/all/${this.state.activeRecipe.resource}.png`}
                                alt=""
                              />
                            </picture>
                          </div>
                          <div className="one-part-bay">
                            <a
                              className="def-bt min"
                              href="https://wax.atomichub.io/market?collection_name=nftpandawaxp&data:text.rarity=Common&order=desc&schema_name=resources&sort=created&symbol=WAX"
                              target="_blank"
                            >
                              <span className="button-in">
                                <span className="button-name">
                                  <span className="button-name-in">
                                    <span className="button-name-value">
                                      buy
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </a>
                          </div>
                          <div className="part-num">
                            {this.state.counPartWeapon.resource}
                          </div>

                          <div className="need-parts">
                            {this.state.counPartWeapon.resource <= 5
                              ? this.state.counPartWeapon.resource
                              : "5"}
                            /5
                          </div>

                          <div
                            className={`dots-parts ${this.state.counPartWeapon.resource >= 5
                              ? "act-5"
                              : this.state.counPartWeapon.resource == 4
                                ? "act-4"
                                : this.state.counPartWeapon.stone == 3
                                  ? "act-3"
                                  : this.state.counPartWeapon.stone == 2
                                    ? "act-2"
                                    : this.state.counPartWeapon.stone == 1
                                      ? "act-1"
                                      : "act-0"
                              }`}
                          >
                            <div className="dots-parts-in">
                              <span></span>
                              <span></span>
                              <span></span>
                              <span></span>
                              <span></span>
                            </div>
                          </div>
                          <div className="loading-block"></div>
                        </div>
                      </div>
                      {/* one part */}
                      {/* one part */}
                      <div
                        className={`one-craft-part part-w ${this.state.counPartWeapon.stone >= 5
                          ? "some-ok"
                          : "disabled"
                          }`}
                      >
                        <div className="plus"></div>
                        <div className="one-craft-part-in">
                          <div className="one-craft-part-thumb">
                            <picture>
                              <source
                                srcSet={`/images/jew/all/${this.state.activeRecipe.stone}.webp`}
                                type="image/webp"
                              />
                              <source
                                srcSet={`/images/jew/all/${this.state.activeRecipe.stone}.png`}
                                type="image/png"
                              />
                              <img
                                src={`/images/jew/all/${this.state.activeRecipe.stone}.png`}
                                alt=""
                              />
                            </picture>
                          </div>
                        </div>
                        <div className="one-part-bay">
                          <a
                            className="def-bt min"
                            target="_blank"
                            href={`https://wax.atomichub.io/market?collection_name=nftpandawaxp&data:text.element=${this.state.activeRecipe.element}&data:text.type=${this.state.activeRecipe.name}&order=desc&schema_name=gems&sort=created&symbol=WAX`}
                          >
                            <span className="button-in">
                              <span className="button-name">
                                <span className="button-name-in">
                                  <span className="button-name-value">buy</span>
                                </span>
                              </span>
                            </span>
                          </a>
                        </div>
                        <div className="part-num">
                          {this.state.counPartWeapon.stone}
                        </div>

                        <div className="need-parts">
                          {this.state.counPartWeapon.stone <= 5
                            ? this.state.counPartWeapon.stone
                            : "5"}
                          /5
                        </div>

                        <div
                          className={`dots-parts ${this.state.counPartWeapon.stone >= 5
                            ? "act-5"
                            : this.state.counPartWeapon.stone == 4
                              ? "act-4"
                              : this.state.counPartWeapon.stone == 3
                                ? "act-3"
                                : this.state.counPartWeapon.stone == 2
                                  ? "act-2"
                                  : this.state.counPartWeapon.stone == 1
                                    ? "act-1"
                                    : "act-0"
                            }`}
                        >
                          <div className="dots-parts-in">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                          </div>
                        </div>
                        <div className="loading-block"></div>
                      </div>
                      {/* one part */}
                    </div>
                  </div>

                  <div className="price-craft">
                    <span>10</span>
                  </div>

                  <div className="craft-recipe-bt">
                    <div className="craft-bt">
                      {this.state.counPartWeapon.resource &&
                        this.state.counPartWeapon.stone >= 5 ? (
                        <span
                          onClick={(e) => {
                            this.opencraftmodal();
                          }}
                        ></span>
                      ) : (
                        <span className="disabled"></span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <div
              className={`modal-result ${this.state.modalResult.open ? "open" : null
                } ${this.state.craftResult == "success" ? "success" : null} ${this.state.craftResult == "fail" ? "fail" : null
                }`}
            >
              <div className="modal-result-body">
                <div className="modal-result-body-in">
                  <div className="modal-result-head">
                    {!this.state.modalResult.loading ? (
                      <div
                        className="close-result"
                        onClick={(e) => {
                          this.closeModalResult();
                        }}
                      >
                        <span></span>
                      </div>
                    ) : null}
                    <div className="weapon-modal-name">
                      {this.state.activeWeaponName}
                    </div>
                    <div className="weapon-modal-wrap">
                      <div className="fail-res"></div>
                      <div className="success-res"></div>
                      {this.state.modalResult.loading ? (
                        <div className="loading-res"></div>
                      ) : null}
                      <div className="weapon-modal-img">
                        {this.state.activeRecipe.weapon ? (
                          <picture>
                            <source
                              srcSet={`/images/jew/all/${this.state.activeRecipe.weapon}.webp`}
                              type="image/webp"
                            />
                            <source
                              srcSet={`/images/jew/all/${this.state.activeRecipe.weapon}.png`}
                              type="image/png"
                            />
                            <img
                              src={`/images/jew/all/${this.state.activeRecipe.weapon}.png`}
                              alt=""
                            />
                          </picture>
                        ) : null}
                      </div>
                      {!this.state.modalResult.loading ? (
                        <div className="close-bt">
                          <button
                            className="def-bt min"
                            onClick={(e) => {
                              this.closeModalResult();
                            }}
                          >
                            <span className="button-in">
                              <span className="button-name">
                                <span className="button-name-in">
                                  <span className="button-name-value">
                                    close
                                  </span>
                                </span>
                              </span>
                            </span>
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="modal-result-close-trans"></div>
              </div>
              <div className="modal-weapon-bg"></div>
            </div>

            <div
              className={`modal-result craftpay ${this.state.craftpay ? "open" : null
                }`}
            >
              <div className="modal-result-body">
                <div className="modal-result-body-in">
                  <div className="modal-result-head">
                    <div
                      className="close-result"
                      onClick={(e) => {
                        this.closeModalResult();
                      }}
                    >
                      <span></span>
                    </div>

                    <div className="weapon-modal-name">
                      {this.state.modalcraft.top}
                    </div>
                    <div className="weapon-modal-wrap">
                      <div className="weapon-modal-img">
                        <div className="step-wrap">
                          <div className="step-title">
                            {this.state.modalcraft.title}
                          </div>
                          <div className="step-loader">
                            <div className="loading-res"></div>
                            <div className="step-bt">
                              {/* {this.state.modalcraft.button != "first" ?  */}
                              <button
                                className="def-bt min"
                                onClick={(e) => {
                                  this.craft();
                                }}
                              >
                                <span className="button-in">
                                  <span className="button-name">
                                    <span className="button-name-in">
                                      <span className="button-name-value pad1">
                                        craft
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </button>
                              {/* :
                                                            <button className="def-bt min" onClick={(e) => {this.craft()}}>
                                                                <span className="button-in">
                                                                    <span className="button-name">
                                                                        <span className="button-name-in">
                                                                            <span className="button-name-value">
                                                                                craft
                                                                            </span> 
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </button>
                                                        } */}
                            </div>
                          </div>
                          {this.state.modalcraft.button == "first" ? (
                            <div className="info-55">
                              <span>Attention:</span> the chance of successful{" "}
                              <br /> crafting is{" "}
                              <i>
                                {this.state.contractMedal
                                  ? 55 + this.state.contractMedal
                                  : "55"}
                                %
                              </i>
                            </div>
                          ) : null}
                          <div
                            className="step-descr"
                            dangerouslySetInnerHTML={{
                              __html: this.state.modalcraft.descr,
                            }}
                          >
                            {/* {this.state.modalcraft.descr} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-result-close-trans"></div>
              </div>
              <div className="modal-weapon-bg"></div>
            </div>
          </div>

          <footer>
            <div className="footer-nav">
              <nav>
                <ul>
                  <li>
                    <a
                      className="foot-logo-1"
                      target="_blank"
                      href="https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&sort=created&symbol=WAX"
                    >
                      <span></span>
                      <i>Market</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-2"
                      target="_blank"
                      href="https://twitter.com/NftPanda/"
                    >
                      <span></span>
                      <i>Twitter</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-3"
                      target="_blank"
                      href="https://www.instagram.com/nftpanda/"
                    >
                      <span></span>
                      <i>Instagram</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-4"
                      target="_blank"
                      href="https://discord.gg/vCSgme4d5b"
                    >
                      <span></span>
                      <i>Discord</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-5"
                      target="_blank"
                      href={`https://wax.alcor.exchange/swap?output=${process.env.REACT_APP_VAL}-nftpandabamb&input=WAX-eosio.token`}
                    >
                      <span></span>
                      <i>{process.env.REACT_APP_VAL} Trade</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-6"
                      target="_blank"
                      href="https://discord.gg/vCSgme4d5b"
                    >
                      <span></span>
                      <i>Support</i>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </footer>
        </main>
      </>
    );
  }
}

// let mapStateToProps = (state) => ({
//     allinvent: state.inventoryReduser.allinvent,
// });

let mapStateToProps = (state) => ({
  /*allWeapon: state.weaponReduser.allWeapon,
    statusWeapon: state.weaponReduser.statusWeapon,*/
});

export default connect(mapStateToProps, {
  /*weaponlist,statusLoadinWeapon, addWeaponWhenRemove, addWeaponToPanda,*/
})(CraftPageComponentJewelry);
