import React from "react";

import Menu from '../../components/Menu';
//import Adventures from "./Adventures";
import AdventuresNew from "./AdventuresNew";
import ErrorComponent from "../Error/ErrorComponent";
import ErrorStakeComponent from "../ErrorStake/ErrorStakeComponent";
import ErrorModal from "../ErrorModal/ErrorModalComponent";
import { connect } from "react-redux";
//import cryptoRandomString from "crypto-random-string";
import { NavLink } from "react-router-dom";
import axios from "axios";
import ModalOneWeapon from "./weapon/ModalOneWeapon";
import ModalOneJew from "./jew/ModalOneJew";
import ModalAllWeapon from "./weapon/ModalAllWeapon";
import ModalAllJew from "./jew/ModalAllJew";

import Map from "./NewAdv/Map";
import SendToAdv from "./NewAdv/SendToAdv";
import GetReward from "./NewAdv/GetReward";
import DetalMultInfo from "./NewAdv/DetalMultInfo";
import ModalMultiEat from "./ModalMultiEat";
import ModalMultiWeapon from "./ModalMultiWeapon";
import ModalMultiJew from "./ModalMultiJew";
import Skills from "./Skills";

//import getAllMedalInGameClass from '../Get/getAllMedalInGameClass';

import {
  setSlotPandaOnGame,
  setPandaOnGame,
  toggleIsFetchingTop,
  doDelFromStates,
  addToSlotState,
  doDelFromSlot,
  setUsersRow,
  addSlot /*setTimerToState, */,
  setTimerEnd,
  removePandaAdv,
  removePandaAdvEnergy,
  changeStakeSum,
  changeStakeSumMinus,
  lvlUp,
  eat /*eatlist,*/,
  eatlistclear,
  removeWeaponSlot,
  addWeaponToSlot,
  addWeaponEnergy,
  removeJewSlot,
  addJewToSlot,
  addJewEnergy,
  removeArmorSlot,
  addArmorToSlot,
  addArmorEnergy,
  setPandaOnGameWax,
  setWeaponOnGameWax,
  setJewOnGameWax,
  setArmorOnGameWax,
  setWasLoadPandas,
  setWasLoadWeapon,
  setWasLoadJew,
  setWasLoadArmor,
  choosePanda,
  setUserSlots,
} from "./../../GlobalState/pandaonslot-reducer";

import {
  eatremove,
  eatlist,
  wasLoadinEat,
  wasBuyingEat,
} from "../../GlobalState/shop-reducer";
import {
  weaponlist,
  statusLoadinWeapon,
  addWeaponWhenRemove,
  addWeaponToPanda,
  setPandaWeapon,
} from "../../GlobalState/weapon-reducer";
import {
  jewlist,
  statusLoadinJew,
  addJewWhenRemove,
  addJewToPanda,
  setPandaJew,
} from "../../GlobalState/jew-reducer";
import {
  armorlist,
  statusLoadinArmor,
  addArmorWhenRemove,
  addArmorToPanda,
  setPandaArmor,
} from "../../GlobalState/armor-reducer";

import { ExplorerApi } from "atomicassets"; // атомик для списка панд
import { JsonRpc } from "eosjs";
import music from "./../../music/advanture.mp3";
import musicInOut from "./../../music/in-out.mp3";
//import musicSwitch from './../../music/switch.mp3'
//import musicHit from './../../music/hit1.mp3'
import musicError from "./../../music/error.mp3";
import musicRemove from "./../../music/remove.mp3";
import musicLvlUp from "./../../music/lvlup.mp3";
import musicSad from "./../../music/sad.mp3";
import musicNotGood from "./../../music/notgood.mp3";
import musicClose from "./../../music/close.mp3";
import musicOpen from "./../../music/open.mp3";
import musicTimer from "./../../music/timer.mp3";
import pandaEating from "./../../music/panda-eating.mp3";
import ThankMulti from "./ThankMulti";
const fetch = require("node-fetch");
const api = new ExplorerApi(
  "https://" + localStorage.getItem('rpcA'),
  "atomicassets",
  { fetch, rateLimit: 4 }
);
var canSendsendAll = true; // ЭТО для отправки всех панд
var initial; // timer console

// это транзакция отправки ИЗ
const cardTransferSlot = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "addtoslot",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: { username: "", asset_id: "", number_slot: "" },
    },
  ],
};

// это транзакция отправки ИЗ Слота
const cardTransferOutFromSlot = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "remfromslot",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: { username: "", asset_id: "", number_slot: "" },
    },
  ],
};

// это транзакция повышения уровня
const LvlTransfer = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "lvlup",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: { username: "", asset_id: "" },
    },
  ],
};

// это транзакция покупки Слота
const slotTransBay = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "payingame",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        storer: "",
        bamnum: "",
        ipfshash: "buyslot ",
      },
    },
  ],
};

// это транзакция стейкинга НФТ
const stakeTransNft = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "payingame",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        storer: "",
        bamnum: "",
        ipfshash: "",
      },
    },
  ],
};

// это транзакция отправки еды в игру
const cardTransferWeaponVoucher = {
  actions: [
    {
      account: "atomicassets",
      name: "transfer",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        from: "",
        to: process.env.REACT_APP_CONTRACT,
        asset_ids: [],
        memo: "",
      },
    },
  ],
};
// это транзакция отправки еды в игру
const cardTransfer = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "pandaeating",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        storer: "",
        //asspanda: '',
        //eatrar: ''
      },
    },
  ],
};

const cardTransferMulti = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "multieat",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        storer: "",
        pandaid: "",
      },
    },
  ],
};

const multyAdv = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "multiadv",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        storer: "",
        pandaid: "",
        typeadv: "",
        duration: "",
      },
    },
  ],
};

const unstakeTransNft = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "unstakenft",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: { username: "", asset_id: "", bam_sum: "" },
    },
  ],
};

// это транзакция отправки оружия на панду
const cardTransferWeaponToGame = {
  actions: [
    {
      account: "atomicassets",
      name: "transfer",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        from: "",
        to: process.env.REACT_APP_CONTRACT,
        asset_ids: [],
        memo: "",
      },
    },
  ],
};

// это транзакция отправки оружия обратно юзеру в кошелек
const cardTransferOutWeaponFromGame = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "weaponout",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        from: "",
        to: "",
        asset_id: [],
      },
    },
  ],
};

// это транзакция отправки оружия на панду
const cardTransferToolsToGame = {
  actions: [
    {
      account: "atomicassets",
      name: "transfer",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        from: "",
        to: process.env.REACT_APP_CONTRACT,
        asset_ids: [],
        memo: "",
      },
    },
  ],
};

// это транзакция отправки оружия обратно юзеру в кошелек
const cardTransferOutToolsFromGame = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "jewout",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        from: "",
        to: "",
        asset_id: [],
      },
    },
  ],
};

// это транзакция пополнгение энергии НФТ
const energyTransNft = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "payingame",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        storer: "",
        bamnum: "",
        ipfshash: "",
      },
    },
  ],
};
// это транзакция пополнгение энергии НФТ
const energyTransNftJew = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "payingame",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        storer: "",
        bamnum: "",
        ipfshash: "",
      },
    },
  ],
};



const MultEnergyWeapon = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "multiweap",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        storer: "",
        //weaponid: []
      },
    },
  ],
};

const MultEnergyJew = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "multijew",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        storer: "",
        jewid: [],
      },
    },
  ],
};

let userConst = null;

class AdventuresComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pandaInSlot: "",
      //allMedalInGame: [],
      errorOnGame: false, // ошибка для вывода карточек в игре
      isLoadedOnGame: false, // лоадинг для вывода карточек в игре
      cardOnGame: [], // сами карточки для вывода карточек в игре
      fastGetPandaOnSlot: [],
      playmusic: false,
      //userSlots: [],
      slotNFT: [],
      openedModal: false,
      openBuySlot: false,
      numberSlot: "",
      openModalChooseHero: false,
      userRow: [],
      rewardModalOpen: false,
      rewardModalText: {
        title: "",
        text: "",
        finalNumber: "",
        weaponPay: "",
        jewPay: "",
        animationNumber: "",
        resourceRew: [],
      },
      story: [
        {
          // start 0
          title: "An ordinary day",
          text: "It was the most common adventure, nothing bad or good happened to the hero, he just completed his mission.",
        },
        {
          title: "Meeting old friends",
          text: "Approaching the city, quite unexpectedly, the hero met his old friends. They offered to treat him to a glass of beer.",
        },
        {
          title: "A rainy day",
          text: "During the battle, a severe thunderstorm began, the hero got wet to the skin, but this did not affect the battle in any way, since he was ready to fight in any conditions.",
        },
        {
          title: "Snake bite",
          text: "Passing through the field, the hero stepped on a snake. Defending itself, the snake bit him, but fortunately, she was neither poisonous nor dangerous.",
        },
        {
          title: "Satin boots",
          text: "After successfully completing the mission, the hero decided to pamper himself with new boots. The joy did not last for long, as they developed a corn on his feet.",
        }, // end 0
        {
          // start -25
          title: "A setup near a potion shop",
          text: "Our hero wandered into a small village to recover his supplies. While walking, he heard a cry for help from a potion store. Running to the call for help, it turned out to be empty inside, and behind were the inhabitants who mistook our hero for a thief. Who let him down?",
        },
        {
          title: "The axe ambush",
          text: "The hero's path led through the forest, out of the corner of his eye he noticed that axes were flying at him. At the last moment, he managed to dodge, but one of the axes wounded his leg. Who was it? And why did they hide?",
        },
        {
          title: "A matter of trust",
          text: "On the way, the hero met a traveler who offered to show him a short way for a small reward. The hero agreed - but this path led him to a dead end, from which he had to return back.",
        },
        {
          title: "A broken leg",
          text: "On his way, the hero ran into wolves, tried to fight them off, stumbled and fell. Unfortunately, he broke his leg.",
        },
        {
          title: "Stolen clothes",
          text: "Tired of a long journey, passing by the river, the hero decided to swim, but returning back to the tree where he left his clothes, he could not find it. Now this adventure will be truly legendary.",
        }, // end -25
        {
          // start -50
          title: "A drowned raft",
          text: "Moving over the huge rapids of the river, the hero's raft capsized and sank with all of the provisions. The hero got out without problems, but it was difficult to continue the adventure without supplies.",
        },
        {
          title: "The monk swill",
          text: "Going to the tavern to quench their thirst, the hero met the monks. They insisted to treat our friend out of respect for the heroes. But what was this strong drink? Our hero could no longer stand on his feet.",
        },
        {
          title: "A sudden illness",
          text: "Waking up in the morning, our hero felt tired, this feeling intensified and a sore throat haunted him. Could it be because of the rainstorm that hit him yesterday?",
        },
        {
          title: "A poisoned blade",
          text: "Fighting bravely during the adventure, the hero was wounded by the blade. Later, after withdrawing from the battle, he felt very dizzy. Was the blade poisoned?",
        },
        {
          title: "A wolf attack",
          text: "Crossing the forest, the hero heard a terrible roar, a pack of wolves began to hunt our hero, it was not an easy task to fight them off.",
        }, // end -50
        {
          // start -75
          title: "A broken weapon",
          text: "Skillfully dodging and repelling enemy attacks, a loud snap was heard, the hero's weapon broke. Continuing the fight unarmed is not easy task.",
        },
        {
          title: "Cracked armor",
          text: "The hero skillfully fought against several opponents, but at some point, luck turned away from him and he received a strong blow. The only thing that broke was the armor. Continuing to fight without armor is another challenge.",
        },
        {
          title: "A trap",
          text: "While completing the task, the hero became interested in birdsong and did not notice how he fell into a trap set by his brothers. Without thinking for a long time, he cut the rope with a dagger and fell to the ground. Unfortunately, the fall was not gentle.",
        },
        {
          title: "Unverified path",
          text: "Having successfully completed the mission, the hero decided to go home a different way. But there was a failure ahead of him, after the rain the road was washed away, and our hero slipped and fell off the cliff.",
        },
        {
          title: "Witchcraft",
          text: "On the way to the mission, the hero spent the night in the forest. Strange sounds awakened him. When he woke up, he saw a thick fog and strange creatures. The hero got confused and ran away. Where did the hallucinations come from and who bewitched the hero?",
        }, // end -75
        {
          // start -100
          title: "A dragon",
          text: "Climbing up the rocks, the hero felt strange vibrations. In a moment, a giant dragon appeared above him, which easily destroyed the rock and threw our hero down.",
        },
        {
          title: "Golem",
          text: "After completing a difficult battle the hero heard a terrible roar. Turning around, he saw a huge golem stood in front of him, which with one blow struck our hero.",
        },
        {
          title: "Trust and help?",
          text: "The hero woke up from terrible cries for help, grabbing only a sword, he rushed towards the screams. Having run to the place, he did not find anyone. Discouraged, he returned back and realized that all his belongings had been stolen.",
        },
        {
          title: "Household chores",
          text: "The hero forgot to put out the fireplace so the house burned down. Therefore he needed to spend all his loot on building a new house.",
        },
        {
          title: "Ogre",
          text: "Resting after a difficult battle, our hero saw an ogre not far from him, but did not attach any importance to this, since he knew that they were very slow and clumsy. How wrong he was…",
        }, // end -100
        {
          // start 0
          title: "A bright day",
          text: "It was the most common adventure, nothing bad or good happened to the hero, he just completed his mission.",
        },
        {
          title: "The power of love",
          text: "On a quest, the hero met a beautiful panda who made him fall in love with her smile. After a short conversation, they agreed to meet, on condition that the hero successfully completes the mission. This is exactly what happened and our hero is in a hurry to his date.",
        },
        {
          title: "A heart-to-heart conversation",
          text: "Leaving the village, the hero heard a familiar voice, it was his friend. They got into conversation and did not notice how several hours passed.",
        },
        {
          title: "A short path",
          text: "On the way to the mission, the hero met a sage who told him the shortest path.",
        },
        {
          title: "A kind priest",
          text: "On the way to the mission, the hero met a good priest who told him a fascinating story. They could chat for a long time, but completing the mission is not the first place.",
        }, // end 0
        {
          // start 25
          title: "The apple of power",
          text: "Having met on the way to the assignment of an old acquaintance of a merchant, the hero helped him to fix the cart and the oldman gave him an apple in gratitude. It was a rare variety of apples, distinguished by its special beauty and giving strength, albeit not for long, to the one who eats it. This is exactly what our hero did and continued his mission.",
        },
        {
          title: "An old stash",
          text: "Returning from a successfully completed mission, the hero was once again lucky and on the way home he found some bamboo. What can make this day even better, right",
        },
        {
          title: "A Potion of Agility",
          text: "Climbing the ledge, the hero saw a huge number of enemies. He immediately remembered a gift from a magician, whom he had once helped. This gift was a potion of agility. The hero immediately had the advantage in battle and he easily completed the mission.",
        },
        {
          title: "A portion of Baozi",
          text: "Tired of the long journey, the hero went to have lunch at a tavern, but there he met robbers. Without hesitation, he dealt with them, and in gratitude from the owner of the establishment received a huge portion of dumplings. Satisfied and well fed, he continued the mission.",
        },
        {
          title: "A delicious ramen",
          text: "On the way to the mission, the hero got very hungry, but fortunately this did not last long, as he smelled the aroma of ramen. Following the scent, the hero found a place to make the best ramen in Elgard. Having satisfied his hunger, he continued his journey, well-fed and contented.",
        }, // end 25
        {
          // start 50
          title: "Weak points",
          text: "Today, the hero smiled unprecedented luck, he met a scout who told him about the weaknesses of the enemy. Needless to say, he completed the mission successfully.",
        },
        {
          title: "An abandoned boat",
          text: "Performing the task, the hero forgot that he would need to cross to the other side of the river. Luckily for him, he found a boat in the bushes.",
        },
        {
          title: "A place to relax",
          text: "Tired of a long journey and bad weather, the hero stumbled upon an old house. Going inside, he did not find anyone. Fortunately, the fireplace and comfortable bed helped him recuperate.",
        },
        {
          title: "The Charm",
          text: "Walking through the village, the hero saw a huge statue of the glorious hero Elgard. Going to it, he found a charm that brings good luck in battles.",
        },
        {
          title: "Travelers’ cart",
          text: "The road to the mission was very long and the hero did not have the opportunity to shorten it. But this time, luck was on his side and he met pandas with a cart, who offered to give him a lift.",
        }, // end 50
        {
          // start +75
          title: "The Fountain of Power",
          text: "Going on a mission to a new place, the hero forgot the map, got lost and was left exhausted from the long journey. Fortunately, he came across the ruins of an ancient village, in the center of which was a beautiful fountain. Having bathed in it, the hero gained extraordinary strength and the opportunity to continue his path.",
        },
        {
          title: "A lucky sign",
          text: "Stepping on a mission, the hero gazed at the beautiful landscapes and stepped on a piece of shit. And as everyone knows, this is a great success. Needless to say, he finished the mission with great success.",
        },
        {
          title: "Power Potion",
          text: "Fighting with enemies, the strength almost left our hero. When the mission was on the verge of collapse, he remembered about the potion of strength from the magician, whom he had helped a long time ago. After drinking the potion, the hero received incredible strength and completed the mission successfully.",
        },
        {
          title: "The Uninvited Scout",
          text: "Preparing a plan for completing the mission, the hero did not even think that he would meet a scout who would simplify his work. In the end, everything turned out even better than he expected.",
        },
        {
          title: "The Inspiration Power",
          text: "Walking the streets of the village and thinking about the upcoming difficult mission, the hero was saddened, but suddenly he saw the statue of the legendary Elgard and immediately remembered the stories that his mother told him in childhood. Inspired, he no longer worried about the outcome of the task, as he was sure that he could handle it.",
        }, // end -75
        {
          // start 100
          title: "Bamboo Grove",
          text: "After completing the mission, the hero stumbled upon a huge bamboo grove. Such luck is extremely rare, so our hero is a real lucky one.",
        },
        {
          title: "The Treasure map",
          text: "After defeating the enemies, he found a chest containing an old map. Oh, what a stroke of luck, this is a treasure map.",
        },
        {
          title: "The Treasure",
          text: "Returning from the mission, the hero dreamt and did not notice the hole in front of him, but it was even for the best. Falling into it, he found some treasure.",
        },
        {
          title: "A cowardly enemy",
          text: "Going out to battle with a crowd of enemies, to the great surprise of our hero, the enemy threw down their weapons and fled, leaving all their supplies. Why they ran away is not clear, but anyway it's a great luck.",
        },
        {
          title: "A Secret Ally",
          text: "All the way our hero was accompanied by the arrows of an ally, which saved him from enemies. Who is this ally and why he helped the hero, we never found out.",
        }, // end 100
      ],
      storyRes: [
        {
          // start 0
          title: "Lucky Hero",
          text: "This time the Hero was lucky and he found a gem.",
        },
        {
          // start 0
          title: "No Luck for Hero",
          text: "Unfortunately, this time the Hero was not lucky, he didn't find anything.",
        },
      ],
      stakeModalOpen: false,
      stakeInfo: {
        // модплка стейкинга
        id: "",
        name: "",
        sum: "",
        thumb: "",
        input: "",
        inputUn: "",
        allinfo: {},
        lvlLoop: "",
        youmastadd: "",
        errorBamToLvlUp: "",
      },
      resultRew: {
        // данные про награду
        rarNft: [0, 1, 2, 3, 4, 5, 6],
        numberNft: [6600, 4000, 3240, 1200, 720, 180, 60],
        numberNew: [0.1, 0.1, 0.18, 0.5, 0.85, 3.4, 10.0],
        allCardCount: 16000, // общее число панд
        baz: 0.05, // ставка которая влияет на награду
        rang: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // ставка которая влияет на награду
        steps: [0, 8, 13, 21, 34, 55, 89, 144, 233, 377, 610, 987, 1597], // ставка которая влияет на награду
        percStake: 1, // Какой процент от заработанных токенов нужно стейкать
        rangPercProfit: [
          0, 0.02, 0.03, 0.05, 0.08, 0.13, 0.21, 0.34, 0.55, 0.89, 1.44, 2.33,
          3.77,
        ],
      },
      hasError: false, // ошибка транзакции
      errorText: "", // ошибка транзакции
      hasStakeErrorInfo: {
        // ошибка стейкинга
        hasStakeError: false,
        nowStake: "",
        needStake: "",
      },
      hasModalErrorInfo: {
        // ошибка стейкинга
        hasModalError: false,
        title: "",
        description: "",
      },
      hasModalErrorInfo2: {
        // ошибка стейкинга
        hasModalError: false,
        title: "",
        description: "",
      },
      hasModalErrorInfo3: {
        // ошибка стейкинга
        hasModalError: false,
        title: "",
        description: "",
      },
      chooseHeroLoading: false, // loading slot
      modalEat: {
        openModal: false,
        slotIndex: "",
        assetid: "",
        eatid: "",
        loadingChooseEat: false,
        //alleat: {},
        style: {
          common: "",
          uncommon: "",
          rare: "",
          epic: "",
          legendary: "",
          mythic: "",
          uni: "",
        },
      },
      modalWeapon: {
        openModal: false, // открыто закрыто
        showAnimModal: false, // анимация
        weapon: null,
        weaponEnergy: "",
        index: "",
        cost: "",
        voucherCount: "",
        voucherList: [],
      },
      modalJew: {
        openModal: false, // открыто закрыто
        showAnimModal: false, // анимация
        jew: null,
        jewEnergy: "",
        index: "",
        cost: "",
      },
      modalAllWeapon: {
        loadingWeapon: false,
        openModal: false, // открыто закрыто
        loadingChooseWeapon: false, // лоадер перед загрузкой списка оружия
        idPanda: "",
      },
      modalAllJew: {
        loadingJew: false,
        openModal: false, // открыто закрыто
        loadingChooseJew: false, // лоадер перед загрузкой списка оружия
        idPanda: "",
      },
      modalAllArmor: {
        loadingArmor: false,
        openModal: false, // открыто закрыто
        loadingChooseArmor: false, // лоадер перед загрузкой списка оружия
        idPanda: "",
      },
      newAdvMap: {
        open: false,
        anim: false,
        animCloud: false,
        showModalInside: false,
        loading: false,
        duration: 0,
      },
      sendToAdv: {
        modalOpen: false,
        anim: false,
        type: "",
        slotIndex: "",
        pandaInfo: {},
        multi: false,
      },
      getReward: {
        modalOpen: false,
        anim: false,
        exp: false,
      },
      openMultiSt: false,
      activeSlotSt: [],
      activeRarPanda: [0, 0, 0, 0, 0, 0],
      problemWithEat: {
        active: false,
        problemRar: [0, 0, 0, 0, 0, 0],
        problemEnergy: false,
        needEat: [0, 0, 0, 0, 0, 0],
      },
      showModalMultiEat: false,
      showModalMultiWeapon: false,
      showModalMultiJew: false,
      showModalMultiSum: 0,
      showModalMultiType: 0,
      multiResult: [], // список панд из мультипутишествия
      // waxSlotPandas: [],
      // waxSlotWeapon: [],
      // waxSlotJew: []
      modalDetalRewOpen: false,
      modalDetalRewOpenAnim: false,
      selectAllBt: false,
      modalMultiThank: {
        open: false,
        title: "",
        descr: "",
      },
      openSkills: false,
      acivePandaSkills: {},
      user: "",
      wasLoadPandaOnGame: false
    };

    //this.url = music;
    this.audio = new Audio(music);
    //this.audio = useSound(music)
    this.audioMusicInOut = new Audio(musicInOut);
    //this.audioMusicSwitch = new Audio(musicSwitch);
    //this.audioMusicHit = new Audio(musicHit);
    this.audioMusicError = new Audio(musicError);
    this.audioMusicRemove = new Audio(musicRemove);
    this.audioLvlUp = new Audio(musicLvlUp);
    this.audioSad = new Audio(musicSad);
    this.audioNotGood = new Audio(musicNotGood);
    this.audioClose = new Audio(musicClose);
    this.audioOpen = new Audio(musicOpen);
    this.audioTimer = new Audio(musicTimer);
    this.audioPandaEating = new Audio(pandaEating);

    this.getArreySlots = this.getArreySlots.bind(this);
    this.getPandaSlot = this.getPandaSlot.bind(this);
    this.openModal = this.openModal.bind(this);
    this.openModalEat = this.openModalEat.bind(this);
    this.openModalBySlot = this.openModalBySlot.bind(this);
    this.onHandleSendPandaToSlot = this.onHandleSendPandaToSlot.bind(this);
    this.onHandleSendPandaFromSlot = this.onHandleSendPandaFromSlot.bind(this);
    //this.sendPandaToAdventures = this.sendPandaToAdventures.bind(this);
    this.baySlot = this.baySlot.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    //this.getModalRewardInfo = this.getModalRewardInfo.bind(this);
    //this.openModalReward = this.openModalReward.bind(this);
    this.stakePandaModal = this.stakePandaModal.bind(this);
    this.stakeBamNft = this.stakeBamNft.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleChangeInputUn = this.handleChangeInputUn.bind(this);
    this.unstakeOpenModal = this.unstakeOpenModal.bind(this);
    this.handleCloseUnstake = this.handleCloseUnstake.bind(this);
    this.unstakeBamNft = this.unstakeBamNft.bind(this);
    this.lvlUpPanda = this.lvlUpPanda.bind(this);
    this.errorStake = this.errorStake.bind(this);
    this.clearError = this.clearError.bind(this);
    this.handleCloseModalStakeMore = this.handleCloseModalStakeMore.bind(this);
    //this.onFormSubmit = this.onFormSubmit.bind(this);
    this.addEnergy = this.addEnergy.bind(this);
    this.closeModalEat = this.closeModalEat.bind(this);
    this.addNumToStake = this.addNumToStake.bind(this);
    this.openModalError = this.openModalError.bind(this);
    this.handleCloseModalError = this.handleCloseModalError.bind(this);
    this.clearDispEat = this.clearDispEat.bind(this);
    this.showCloseModal = this.showCloseModal.bind(this);
    this.showCloseModalJew = this.showCloseModalJew.bind(this);
    this.showCloseAllWeaponModal = this.showCloseAllWeaponModal.bind(this);
    this.sendWeapon = this.sendWeapon.bind(this);
    this.sendOutWeapon = this.sendOutWeapon.bind(this);
    this.addEnergyWeapon = this.addEnergyWeapon.bind(this);
    this.addEnergyWeaponByVoucher = this.addEnergyWeaponByVoucher.bind(this);
    this.showCloseAllJewModal = this.showCloseAllJewModal.bind(this);
    this.sendJew = this.sendJew.bind(this);
    this.sendArmor = this.sendArmor.bind(this);
    this.sendOutJew = this.sendOutJew.bind(this);
    this.sendOutArmor = this.sendOutArmor.bind(this);
    this.addEnergyJew = this.addEnergyJew.bind(this);
    this.openNewAdvMap = this.openNewAdvMap.bind(this);
    this.showCloseToAdv = this.showCloseToAdv.bind(this);
    this.showCloseReward = this.showCloseReward.bind(this);
    //this.sendPandaToRes = this.sendPandaToRes.bind(this);
    //this.sendPandaToStones = this.sendPandaToStones.bind(this);
    this.GetUserWeaponViucherList = this.GetUserWeaponViucherList.bind(this);
    this.activeSlot = this.activeSlot.bind(this);
    this.openMulti = this.openMulti.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.addEnergyMulti = this.addEnergyMulti.bind(this);
    this.addEnergyMultiWeapon = this.addEnergyMultiWeapon.bind(this);
    this.addEnergyMultiJew = this.addEnergyMultiJew.bind(this);
    this.addEnergyMultiTrans = this.addEnergyMultiTrans.bind(this);
    this.addEnergyMultiWeaponTrans = this.addEnergyMultiWeaponTrans.bind(this);
    this.addEnergyMultiJewTrans = this.addEnergyMultiJewTrans.bind(this);
    this.closeThenk = this.closeThenk.bind(this);

    this.getModalRewardInfoMult = this.getModalRewardInfoMult.bind(this);
    this.removeActiveAllBest = this.removeActiveAllBest.bind(this);

    //this.getPandaSlotWax = this.getPandaSlotWax.bind(this); // ненужно потому что мы воспользовались функцией  GetPandaListOnGame
    this.getWeaponsSlotWax = this.getWeaponsSlotWax.bind(this);
    this.getJewSlotWax = this.getJewSlotWax.bind(this);

    this.sendPandaToAdventuresMultArray =
      this.sendPandaToAdventuresMultArray.bind(this);
    this.showModalDetalRew = this.showModalDetalRew.bind(this);
    this.openCloseSkills = this.openCloseSkills.bind(this);
  }

  componentDidMount() {

    const activeUser = this.props.ual.activeUser;

    //this.getAllTMPL();
    if (activeUser !== this.state.user) {
      (async () => {
        try {
          const accountName = await activeUser.getAccountName();
          let allMedalInGame;
          // try {
          //   allMedalInGame = await getAllMedalInGameClass(this.props.accountName);
          // } catch (error) {
          //   console.error('Error fetching medals:', error);
          //   allMedalInGame = []; // return an empty array if there is an error
          // }
          this.setState({
            user: accountName,
            //allMedalInGame: allMedalInGame
          });

          //this.userCheck();
          this.getArreySlots();
          this.GetWeaponOnPanda();
          this.GetJewOnPanda();
          this.GetPandaEatList();
          this.GetPandaWeaponList();
          this.GetPandaJewList();
          this.GetPandaArmorList();
          window.onbeforeunload = function () {
            //console.log("reload");
            this.props.wasBuyingEat(false); // была куплена еда
            //this.props.statusLoadinWeapon(false); // был загружен списко оружия при открытии модалки
          }.bind(this);

          // if (localStorage.getItem("wasReload") === "true") {
          //   //console.log('statusLoadinWeapon false');
          //   this.props.statusLoadinWeapon(false);
          // } else {
          //   //console.log('statusLoadinWeapon true');
          //   this.props.statusLoadinWeapon(true);
          // }

          // if (localStorage.getItem("wasReloadJew") === "true") {
          //   //console.log('statusLoadinJew false');
          //   this.props.statusLoadinJew(false);
          // } else {
          //   //console.log('statusLoadinJew true');
          //   this.props.statusLoadinJew(true);
          // }

          // if (localStorage.getItem("wasReloadArmor") === "true") {
          //   //console.log('statusLoadinJew false');
          //   this.props.statusLoadinArmor(false);
          // } else {
          //   //console.log('statusLoadinJew true');
          //   this.props.statusLoadinArmor(true);
          // }

          this.GetUserWeaponViucherList();

        } catch (error) {
          console.error('Error fetching account name:', error);
        }
      })();

    }


  }

  componentWillUnmount() {
    //console.log('componentWillUnmount');
  }

  async userCheck() {
    // try {
    //   const activeUser = this.props.ual.activeUser;
    //   const accountName = await activeUser.getAccountName();
    //   let rpcc;
    //   if (this.props.ual.activeUser.rpc !== undefined) {
    //     rpcc = this.props.ual.activeUser.rpc;
    //   } else if (
    //     this.props.ual.activeUser.rpc === undefined &&
    //     this.props.ual.activeUser.wax
    //   ) {
    //     rpcc = this.props.ual.activeUser.wax.rpc;
    //   } else {
    //     rpcc = new JsonRpc(localStorage.getItem("rpc"));
    //   }

    //   /*console.log("rpcc",rpcc);
    //         console.log("accountName",accountName);
    //         console.log("activeUser",activeUser);*/
    //   const results = await rpcc.get_table_rows({
    //     json: true,
    //     code: process.env.REACT_APP_CONTRACT, // contract who owns the table
    //     scope: process.env.REACT_APP_CONTRACT, // scope of the table
    //     table: "usersnew", // name of the table as specified by the contract abi
    //     //"key_type": "i64",
    //     lower_bound: accountName,
    //     upper_bound: accountName,
    //     limit: 1,
    //     reverse: false,
    //     show_payer: false,
    //     index_position: 1,
    //   });
    //   const originRowUser = results;
    //   //const resultRow = results.rows[0].inrent;
    //   const resultRow = 0;

    //   if (resultRow === 1) {
    //     const resultRowName = results.rows[0].rent;
    //     userConst = resultRowName;
    //     this.getArreySlots();
    //     if (localStorage.getItem("wasReload") === "true") {
    //       //console.log('GetPandaListOnGame 1');
    //       //this.GetWeaponOnPanda();
    //       //this.GetPandaListOnGame();
    //     }
    //   } else {
    //     const resultRowName = results.rows[0].username;
    //     userConst = resultRowName;
    //     this.getArreySlots();
    //     if (localStorage.getItem("wasReload") === "true") {
    //       this.GetPandaWeaponList();
    //     }
    //     if (localStorage.getItem("wasReloadJew") === "true") {
    //       this.GetPandaJewList();
    //     }
    //     if (localStorage.getItem("wasReloadArmor") === "true") {
    //       this.GetPandaArmorList();
    //     }
    //   }
    // } catch (e) {
    //   console.log("\nCaught exception: " + e);
    // }
  }

  handleUpdate(e) {
    this.props.setTimerEnd(e);

    if (localStorage.getItem("sound") === "true") {
      this.audioTimer.currentTime = 0;
      this.audioTimer.volume = 0.1;
      this.audioTimer.play();
    }
  }

  openModal(event, index) {
    this.setState({
      openModalChooseHero: event,
      numberSlot: index,
    });
    // document
    //   .getElementsByClassName("all-modal-wrap")[0]
    //   .classList.toggle("open");
    document.getElementsByTagName("body")[0].classList.add("open-modal");
    if (localStorage.getItem("sound") === "true") {
      this.audioOpen.currentTime = 0;
      this.audioOpen.volume = 0.1;
      this.audioOpen.play();
    }
  }

  openModalEat(index, assteId) {
    //if(!this.props.wasbay && !this.props.statuseat) { // было куплено, или перезагрузили страницу
    this.setState({
      ...this.state,
      modalEat: {
        ...this.state.modalEat,
        openModal: true,
        loadingChooseEat: true,
        slotIndex: index,
        assetid: assteId,
        loadingChooseEat: false,
      },
    });
    //this.GetPandaEatList(index, assteId);
    /*} else {
            this.setState({
                ...this.state,
                modalEat: {
                    ...this.state.modalEat,
                    openModal: true,
                    loadingChooseEat: false,
                }
            });
            //this.GetPandaEatListLocal(index, assteId);
            this.GetPandaEatList(index, assteId);
        }*/
    document.getElementsByTagName("body")[0].classList.add("open-modal");
    if (localStorage.getItem("sound") === "true") {
      this.audioOpen.currentTime = 0;
      this.audioOpen.volume = 0.1;
      this.audioOpen.play();
    }
  }

  closeModalEat() {
    //console.log('close close close');
    this.setState({
      ...this.state,
      modalEat: {
        ...this.state.modalEat,
        openModal: false,
        pandaInfo: "",
        assetid: null,
        //eatid: '',
        loadingChooseEat: false,
      },
    });

    document.getElementsByTagName("body")[0].classList.remove("open-modal");
    if (localStorage.getItem("sound") === "true") {
      this.audioOpen.currentTime = 0;
      this.audioOpen.volume = 0.1;
      this.audioOpen.play();
    }
  }

  openModalBySlot(event, index) {
    this.setState({
      numberSlot: index,
      openBuySlot: event
    });
    // document
    //   .getElementsByClassName("modal-wrap-by-slote")[0]
    //   .classList.toggle("open");
    document.getElementsByTagName("body")[0].classList.add("open-modal");
    if (localStorage.getItem("sound") === "true") {
      this.audioOpen.currentTime = 0;
      this.audioOpen.volume = 0.1;
      this.audioOpen.play();
    }
  }

  errorStake(pandaSt, showBamBig) {
    //console.log('open');
    pandaSt = pandaSt / 10000;
    showBamBig = showBamBig / 10000;
    this.setState({
      ...this.state,
      hasStakeErrorInfo: {
        hasStakeError: true,
        nowStake: pandaSt,
        needStake: showBamBig,
      },
    });
  }

  handleCloseModalStakeMore() {
    //console.log('close');
    this.setState({
      ...this.state,
      hasStakeErrorInfo: {
        hasStakeError: false,
        nowStake: "",
        needStake: "",
      },
    });
    document.getElementsByTagName("body")[0].classList.remove("open-modal");
    if (localStorage.getItem("sound") === "true") {
      this.audioClose.currentTime = 0;
      this.audioClose.volume = 0.1;
      this.audioClose.play();
    }
  }

  handleClose() {
    this.setState({
      numberSlot: "",
      rewardModalOpen: false,
      openBuySlot: false,
      stakeModalOpen: false,
      openModalChooseHero: false,
      rewardModalText: {
        title: "",
        text: "",
        finalNumber: "",
        weaponPay: "",
        jewPay: "",
        animationNumber: "",
        resourceRew: [],
      },
    });

    document.getElementsByTagName("body")[0].classList.remove("open-modal");
    if (localStorage.getItem("sound") === "true") {
      this.audioClose.currentTime = 0;
      this.audioClose.volume = 0.1;
      this.audioClose.play();
    }
  }

  stakePandaModal(
    assetId,
    name,
    stakeSum,
    thumb,
    allinfo,
    lvlLoop,
    youmastadd,
    errorBamToLvlUp
  ) {
    stakeSum = stakeSum.toFixed(4);
    this.setState({
      stakeModalOpen: true,
      stakeInfo: {
        id: assetId,
        input: "",
        inputUn: "",
        name: name,
        sum: stakeSum / 10000,
        thumb: thumb,
        allinfo: allinfo,
        lvlLoop: lvlLoop,
        youmastadd: youmastadd,
        errorBamToLvlUp: errorBamToLvlUp,
      },
    });
    document.getElementsByTagName("body")[0].classList.add("open-modal");
    if (localStorage.getItem("sound") === "true") {
      this.audioOpen.currentTime = 0;
      this.audioOpen.volume = 0.1;
      this.audioOpen.play();
    }
  }

  addNumToStake(number) {
    this.setState({
      stakeInfo: {
        ...this.state.stakeInfo,
        input: number,
      },
    });
  }

  handleChangeInput(event) {
    if (event.target.value >= 0) {
      this.setState({
        stakeInfo: {
          ...this.state.stakeInfo,
          input: event.target.value,
        },
      });
    }
  }

  handleChangeInputUn(event) {
    if (event.target.value >= 0) {
      this.setState({
        stakeInfo: {
          ...this.state.stakeInfo,
          inputUn: event.target.value,
        },
      });
    }
  }

  unstakeOpenModal(event) {
    document.getElementsByClassName("modal-unstake")[0].classList.add("open");
    if (localStorage.getItem("sound") === "true") {
      this.audioOpen.currentTime = 0;
      this.audioOpen.volume = 0.1;
      this.audioOpen.play();
    }
  }

  handleCloseUnstake() {
    document
      .getElementsByClassName("modal-unstake")[0]
      .classList.remove("open");
    if (localStorage.getItem("sound") === "true") {
      this.audioClose.currentTime = 0;
      this.audioClose.volume = 0.1;
      this.audioClose.play();
    }
  }





  // отправка панды в слот
  async onHandleSendPandaToSlot(panda, indexPanda) {
    this.setState({
      chooseHeroLoading: true,
    });

    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    cardTransferSlot.actions[0].authorization[0].actor = userName;
    cardTransferSlot.actions[0].data.username = userName;
    cardTransferSlot.actions[0].data.asset_id = panda.asset_id;
    cardTransferSlot.actions[0].data.number_slot = this.state.numberSlot;
    //console.log(cardTransferSlot);

    try {
      await activeUser
        .signTransaction(cardTransferSlot, {
          blocksBehind: 3,
          expireSeconds: 30,
        })
        .then((response) => {
          if (response.status === "executed") {
            //this.removePandaFromState(panda, indexPanda);
            this.props.doDelFromStates(panda, this.state.numberSlot);

            this.setState({
              ...this.state,
              openModalChooseHero: false,
              chooseHeroLoading: false,
            });

            document.getElementsByTagName("body")[0].classList.remove("open-modal");

            var arrayPandaSlot = [...this.props.allUserInfo.slots_count]
            arrayPandaSlot = arrayPandaSlot.filter(function (val) {
              return val !== 0;
            });


            if (localStorage.getItem("sound") === "true") {
              this.audioMusicRemove.currentTime = 0;
              this.audioMusicRemove.volume = 0.1;
              this.audioMusicRemove.play();
            }
          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
        chooseHeroLoading: false,
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  // отправка панды из слота
  async onHandleSendPandaFromSlot(panda, indexSlot) {
    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    cardTransferOutFromSlot.actions[0].authorization[0].actor = userName;
    cardTransferOutFromSlot.actions[0].data.username = userName;
    cardTransferOutFromSlot.actions[0].data.asset_id = panda.asset_id;
    cardTransferOutFromSlot.actions[0].data.number_slot = indexSlot;
    //console.log(cardTransferOutFromSlot);

    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");

    try {
      await activeUser
        .signTransaction(cardTransferOutFromSlot, {
          blocksBehind: 3,
          expireSeconds: 30,
        })
        .then((response) => {
          if (response.status === "executed") {
            this.props.doDelFromSlot(panda, indexSlot);

            loadingSlot.classList.remove("loading");

            if (localStorage.getItem("sound") === "true") {
              this.audioMusicRemove.currentTime = 0;
              this.audioMusicRemove.volume = 0.1;
              this.audioMusicRemove.play();
            }
          }
        });
    } catch (error) {
      //console.log(error.message)
      loadingSlot.classList.remove("loading");
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  // отправка панды в путишествие
  async lvlUpPanda(idPanda) {
    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    LvlTransfer.actions[0].authorization[0].actor = userName;
    LvlTransfer.actions[0].data.username = userName;
    LvlTransfer.actions[0].data.asset_id = idPanda;
    //console.log(LvlTransfer);

    try {
      await activeUser
        .signTransaction(LvlTransfer, { blocksBehind: 3, expireSeconds: 30 })
        .then((response) => {
          if (response.status === "executed") {
            //this.addTimerToState(idPanda)
            this.props.lvlUp(idPanda);
            //console.log(response.status);

            if (localStorage.getItem("sound") === "true") {
              this.audioLvlUp.currentTime = 0;
              this.audioLvlUp.volume = 0.1;
              this.audioLvlUp.play();
            }
          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  // покупка слота
  async baySlot(moneysum) {
    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    slotTransBay.actions[0].authorization[0].actor = userName;
    slotTransBay.actions[0].data.storer = userName;
    slotTransBay.actions[0].data.bamnum =
      parseFloat(moneysum).toFixed(4) * 10000;
    //console.log(slotTransBay);

    try {
      await activeUser
        .signTransaction(slotTransBay, { blocksBehind: 3, expireSeconds: 30 })
        .then((response) => {
          if (response.status === "executed") {

            this.props.addSlot(this.props.usersRow + 1);
            this.setState({
              numberSlot: "",
              openBuySlot: false
            });

            document
              .getElementsByTagName("body")[0]
              .classList.remove("open-modal");
            this.props.updateBalance(false, parseFloat(moneysum).toFixed(4));
            //console.log(response.status);

            if (localStorage.getItem("sound") === "true") {
              this.audioMusicInOut.currentTime = 0;
              this.audioMusicInOut.volume = 0.1;
              this.audioMusicInOut.play();
            }
          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  // Стейкинг НФТ
  async stakeBamNft(asset_id, moneysum, allinfo) {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");

    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    stakeTransNft.actions[0].authorization[0].actor = userName;
    stakeTransNft.actions[0].data.storer = userName;
    stakeTransNft.actions[0].data.bamnum = parseInt(
      (this.state.stakeInfo.input * 10000).toFixed(4)
    );
    stakeTransNft.actions[0].data.ipfshash = "sendtogame " + asset_id + " ";
    console.log(stakeTransNft);

    try {
      await activeUser
        .signTransaction(stakeTransNft, { blocksBehind: 3, expireSeconds: 30 })
        .then((response) => {
          if (response.status === "executed") {
            loadingSlot.classList.remove("loading");
            this.props.changeStakeSum(
              asset_id,
              parseInt((this.state.stakeInfo.input * 10000).toFixed(4)),
              allinfo
            );
            var sumStake =
              parseFloat(this.state.stakeInfo.sum) + parseFloat(moneysum);
            sumStake = sumStake.toFixed(4);
            var resAfter =
              parseFloat(this.state.stakeInfo.youmastadd) -
              parseFloat(moneysum);
            resAfter = resAfter.toFixed(4);
            this.setState({
              stakeInfo: {
                ...this.state.stakeInfo,
                sum: sumStake,
                input: "",
                youmastadd: resAfter,
                allinfo: {
                  stake_count: sumStake * 10000,
                },
              },
            });
            this.props.updateBalance(false, parseFloat(moneysum).toFixed(4));
            //console.log(response.status);
            if (localStorage.getItem("sound") === "true") {
              this.audioMusicInOut.currentTime = 0;
              this.audioMusicInOut.volume = 0.1;
              this.audioMusicInOut.play();
            }
          }
        });
    } catch (error) {
      //console.log(error.message)
      loadingSlot.classList.remove("loading");
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  // Стейкинг НФТ
  async addEnergyWeapon(asset_id, pandaId) {
    //console.log('index', index)
    var moneysum = this.state.modalWeapon.cost;

    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");

    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    energyTransNft.actions[0].authorization[0].actor = userName;
    energyTransNft.actions[0].data.storer = userName;
    energyTransNft.actions[0].data.bamnum =
      parseFloat(moneysum).toFixed(4) * 10000;
    energyTransNft.actions[0].data.ipfshash =
      "addenergyweapon " + asset_id + " ";
    //console.log(stakeTransNft);

    try {
      await activeUser
        .signTransaction(energyTransNft, { blocksBehind: 3, expireSeconds: 30 })
        .then((response) => {
          if (response.status === "executed") {
            loadingSlot.classList.remove("loading");
            var sumStake =
              parseFloat(this.state.stakeInfo.sum) + parseFloat(moneysum);
            sumStake = sumStake.toFixed(4);
            var resAfter =
              parseFloat(this.state.stakeInfo.youmastadd) -
              parseFloat(moneysum);
            resAfter = resAfter.toFixed(4);
            this.setState({
              ...this.state,
              modalWeapon: {
                ...this.state.modalWeapon,
                weaponEnergy: 10000,
              },
            });
            this.props.updateBalance(false, parseFloat(moneysum).toFixed(4));
            this.props.addWeaponEnergy(pandaId);
            //console.log(response.status);
            if (localStorage.getItem("sound") === "true") {
              this.audioMusicInOut.currentTime = 0;
              this.audioMusicInOut.volume = 0.1;
              this.audioMusicInOut.play();
            }
          }
        });
    } catch (error) {
      //console.log(error.message)
      loadingSlot.classList.remove("loading");
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  // Стейкинг НФТ
  async addEnergyWeaponByVoucher(asset_id, weaponId) {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");

    //console.log('dateBt eat id', dateBt);
    //console.log('pandaId', pandaId);

    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    cardTransferWeaponVoucher.actions[0].authorization[0].actor = userName;
    cardTransferWeaponVoucher.actions[0].data.from = userName;
    cardTransferWeaponVoucher.actions[0].data.asset_ids = [asset_id];
    cardTransferWeaponVoucher.actions[0].data.memo =
      "energyweaponv " + weaponId + " ";
    console.log(cardTransferWeaponVoucher);
    try {
      await activeUser
        .signTransaction(cardTransferWeaponVoucher, {
          blocksBehind: 3,
          expireSeconds: 30,
        })
        .then((response) => {
          if (response.status === "executed") {
            loadingSlot.classList.remove("loading");
            var newArr = this.state.modalWeapon.voucherList;
            // console.log('newArr', newArr);
            var remFish = newArr.shift();
            //console.log('remFish', remFish);
            //console.log('newArr2', newArr);
            // console.log('this.state.modalWeapon.voucherList.shift()', this.state.modalWeapon.voucherList);
            this.setState({
              ...this.state.modalWeapon,
              modalWeapon: {
                ...this.state.modalWeapon,
                voucherList: newArr,
                weaponEnergy: 10000,
              },
            });
            this.props.addWeaponEnergy(asset_id);
          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      loadingSlot.classList.remove("loading");

      this.setState({
        hasError: true,
        errorText: error.message,
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.play();
      }
    }
  }

  // Стейкинг НФТ
  async addEnergyJew(asset_id, pandaId, jewtit) {
    //console.log('index', index)
    var moneysum = this.state.modalJew.cost;

    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");

    var typeTool;
    if (jewtit) {
      typeTool = "addenergyjew";
    } else {
      typeTool = "addenergyarmor";
    }

    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    energyTransNftJew.actions[0].authorization[0].actor = userName;
    energyTransNftJew.actions[0].data.storer = userName;
    energyTransNftJew.actions[0].data.bamnum = parseInt(
      parseFloat(moneysum).toFixed(4) * 10000
    );
    energyTransNftJew.actions[0].data.ipfshash =
      typeTool + " " + asset_id + " ";
    console.log(energyTransNftJew);

    try {
      await activeUser
        .signTransaction(energyTransNftJew, {
          blocksBehind: 3,
          expireSeconds: 30,
        })
        .then((response) => {
          if (response.status === "executed") {
            loadingSlot.classList.remove("loading");
            var sumStake =
              parseFloat(this.state.stakeInfo.sum) + parseFloat(moneysum);
            sumStake = sumStake.toFixed(4);
            var resAfter =
              parseFloat(this.state.stakeInfo.youmastadd) -
              parseFloat(moneysum);
            resAfter = resAfter.toFixed(4);
            this.setState({
              ...this.state,
              modalJew: {
                ...this.state.modalJew,
                jewEnergy: 10000,
              },
            });
            this.props.updateBalance(false, parseFloat(moneysum).toFixed(4));
            this.props.addJewEnergy(pandaId);
            //console.log(response.status);
            if (localStorage.getItem("sound") === "true") {
              this.audioMusicInOut.currentTime = 0;
              this.audioMusicInOut.volume = 0.1;
              this.audioMusicInOut.play();
            }
          }
        });
    } catch (error) {
      //console.log(error.message)
      loadingSlot.classList.remove("loading");
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  // отправка панды в путишествие
  async unstakeBamNft(asset_id, moneysum, allinfo) {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");

    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    unstakeTransNft.actions[0].authorization[0].actor = userName;
    unstakeTransNft.actions[0].data.username = userName;
    unstakeTransNft.actions[0].data.asset_id = asset_id;
    unstakeTransNft.actions[0].data.bam_sum =
      parseFloat(moneysum).toFixed(4) + " " + process.env.REACT_APP_VAL;
    console.log(unstakeTransNft);

    try {
      await activeUser
        .signTransaction(unstakeTransNft, {
          blocksBehind: 3,
          expireSeconds: 30,
        })
        .then((response) => {
          if (response.status === "executed") {
            loadingSlot.classList.remove("loading");
            moneysum = parseFloat(moneysum).toFixed(4);
            // console.log('moneysum', moneysum);
            // console.log('moneysum2', parseInt(moneysum * 10000));
            this.props.changeStakeSumMinus(
              asset_id,
              parseInt(moneysum * 10000),
              allinfo
            );
            var minStake =
              parseFloat(this.state.stakeInfo.sum) - parseFloat(moneysum);
            var minStake = minStake.toFixed(4);
            var resAfter =
              parseFloat(this.state.stakeInfo.youmastadd) +
              parseFloat(moneysum);
            resAfter = resAfter.toFixed(4);
            this.setState({
              stakeInfo: {
                ...this.state.stakeInfo,
                sum: minStake,
                inputUn: "",
                youmastadd: resAfter,
                allinfo: {
                  stake_count: minStake * 10000,
                },
              },
            });
            this.props.updateBalance(true, parseFloat(moneysum).toFixed(4));
            //console.log(response.status);
            if (localStorage.getItem("sound") === "true") {
              this.audioMusicInOut.currentTime = 0;
              this.audioMusicInOut.volume = 0.1;
              this.audioMusicInOut.play();
            }
          } else {
            //console.log(77777777777777777777)
            //console.log(response);
          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      loadingSlot.classList.remove("loading");
      this.setState({
        hasError: true,
        errorText: error.message,
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  // Показать панды в игре в модальном окне
  async GetPandaListOnGame() {
    this.props.toggleIsFetchingTop(true);
    try {
      let rpcc;
      if (this.props.ual.activeUser.rpc !== undefined) {
        rpcc = this.props.ual.activeUser.rpc;
      } else if (
        this.props.ual.activeUser.rpc === undefined &&
        this.props.ual.activeUser.wax
      ) {
        rpcc = this.props.ual.activeUser.wax.rpc;
      } else {
        rpcc = new JsonRpc(localStorage.getItem("rpc"));
      }
      //const userName = this.props.ual.activeUser.accountName;
      await rpcc
        ?.get_table_rows({
          json: true,
          code: process.env.REACT_APP_CONTRACT, // contract who owns the table
          scope: process.env.REACT_APP_CONTRACT, // scope of the table
          table: "nftsongamec", // name of the table as specified by the contract abi
          key_type: "i64",
          lower_bound: this.state.user,
          upper_bound: this.state.user,
          limit: -1,
          reverse: !0,
          show_payer: false,
          index_position: 2,
        })
        .then((results) => {
          const resultRow = results.rows;
          const resultRowFilter = resultRow.filter(
            (row) => row.storer === this.state.user && row.is_in_slot === 0
          ); // фильтр если нет в слоте

          //console.log("resultRow", resultRow);
          if (resultRow && resultRow.length) {
            var pandaObSlot = [];
            resultRow.map((panda) => {
              //console.log("ppp", panda);
              //panda["activePanda"] = false;
              if (panda.is_in_slot) {
                panda["activePanda"] = false;
                pandaObSlot.push(panda);
              }
            });

            //console.log('pandaObSlot', pandaObSlot);

            this.props.setPandaOnGameWax(pandaObSlot);
            //console.log("pandaObSlot", pandaObSlot);
          }
          this.props.setWasLoadPandas(true);

          this.setState({ cardOnGame: resultRow, wasLoadPandaOnGame: true });
          this.props.setPandaOnGame(resultRowFilter);
          this.props.toggleIsFetchingTop(false);
          //this.getPandaSlot();

          this.getWeaponsSlotWax();
          this.getJewSlotWax();
          this.getArmorSlotWax();
          //this.getPandaSlotWax();
        });
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  // Показать панды в игре в модальном окне
  async GetWeaponOnPanda() {
    try {
      /*const activeUser = this.props.ual.activeUser;
            const accountName = await activeUser.getAccountName();*/
      let rpcc;
      if (this.props?.ual.activeUser.rpc !== undefined) {
        rpcc = this.props.ual.activeUser.rpc;
      } else if (
        this.props?.ual.activeUser.rpc === undefined &&
        this.props?.ual.activeUser.wax
      ) {
        rpcc = this.props.ual.activeUser.wax.rpc;
      } else {
        rpcc = new JsonRpc(localStorage.getItem("rpc"));
      }
      //const userName = this.props?.ual.activeUser.accountName;
      // console.log("rpcc 2222222222222222", rpcc);
      // console.log("userName 2222222222222222", userName);

      await rpcc
        ?.get_table_rows({
          json: true,
          code: process.env.REACT_APP_CONTRACT, // contract who owns the table
          scope: process.env.REACT_APP_CONTRACT, // scope of the table
          table: "nftweapons", // name of the table as specified by the contract abi
          key_type: "i64",
          lower_bound: this.state.user,
          upper_bound: this.state.user,
          limit: -1,
          reverse: !0,
          show_payer: false,
          index_position: 2,
        })
        .then((results) => {
          const resultRow = results.rows;
          //console.log(resultRow);
          //console.log(userConst);
          //const resultRowWithoutFilter = resultRow.filter(row => row.storer === userConst); // фильтр если нет в слоте
          //console.log('resultRow', resultRow);
          //const resultRowFilter = resultRow.filter(row => row.storer === userConst); // фильтр если нет в слоте

          this.props.setPandaWeapon(resultRow);
        });
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  // Показать панды в игре в модальном окне
  async GetJewOnPanda() {
    try {
      let rpcc;
      if (this.props.ual.activeUser.rpc !== undefined) {
        rpcc = this.props.ual.activeUser.rpc;
      } else if (
        this.props.ual.activeUser.rpc === undefined &&
        this.props.ual.activeUser.wax
      ) {
        rpcc = this.props.ual.activeUser.wax.rpc;
      } else {
        rpcc = new JsonRpc(localStorage.getItem("rpc"));
      }
      //const userName = this.props.ual.activeUser.accountName;

      const results = await rpcc.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "nftjew", // name of the table as specified by the contract abi
        key_type: "i64",
        lower_bound: this.state.user,
        upper_bound: this.state.user,
        limit: -1,
        reverse: !0,
        show_payer: false,
        index_position: 2,
      });
      const resultRow = results.rows;
      //console.log(resultRow);
      //console.log(userConst);
      //const resultRowWithoutFilter = resultRow.filter(row => row.storer === userConst); // фильтр если нет в слоте
      //console.log('resultRow', resultRow);
      //const resultRowFilter = resultRow.filter(row => row.storer === userConst); // фильтр если нет в слоте

      this.props.setPandaJew(resultRow);
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  async clearDispEat() {
    //this.props.eatlistclear();
    //this.props.wasLoadinEat(false);
  }

  // Показать еду в игре в модальном окне
  async GetPandaEatList(index, assteId) {
    (async () => {
      try {
        const activeUser = this.props.ual.activeUser;
        //const accountName = await activeUser.getAccountName();
        let rpcc;
        if (this.props.ual.activeUser.rpc !== undefined) {
          rpcc = this.props.ual.activeUser.rpc;
        } else if (
          this.props.ual.activeUser.rpc === undefined &&
          this.props.ual.activeUser.wax
        ) {
          rpcc = this.props.ual.activeUser.wax.rpc;
        } else {
          rpcc = new JsonRpc(localStorage.getItem("rpc"));
        }
        const results = await rpcc.get_table_rows({
          json: true,
          code: process.env.REACT_APP_CONTRACT, // contract who owns the table
          scope: process.env.REACT_APP_CONTRACT, // scope of the table
          table: "eatable", // name of the table as specified by the contract abi
          //"key_type": "i64",
          lower_bound: this.state.user,
          upper_bound: this.state.user,
          limit: 1,
          reverse: false,
          show_payer: false,
          index_position: 1,
        });

        if (results.rows.length) {
          const resultRow = results.rows[0].eat_count;
          this.props.eatlist(resultRow);
          this.props.wasLoadinEat(true);
        } else {
          this.props.eatlist([0, 0, 0, 0, 0, 0]);
        }
      } catch (e) {
        //console.log(e.message)
        //console.warn(e)
        this.setState({
          ...this.state,
          hasError: true,
          errorText: e.message,
          modalEat: {
            ...this.state.modalEat,
            /*openModal: false,
                        slotIndex: '',
                        assetid: '',
                        eatid: '',*/
            loadingChooseEat: false,
            /*alleat: assets,*/
          },
        });
        // this.setState({
        //   hasError: true,
        //   errorText: e.message,
        // });
        console.log("\nCaught exception: " + e);
        if (localStorage.getItem("sound") === "true") {
          this.audioMusicError.currentTime = 0;
          this.audioMusicError.volume = 0.1;
          this.audioMusicError.play();
        }
      }
    })();
  }

  // ОТПРАВИТЬ еду на панду и пополнить энергию item.asset_id, index, item.steps, rareat
  async addEnergy(index, pandaId) {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");

    //console.log('dateBt eat id', dateBt);
    console.log("index", index);

    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    cardTransfer.actions[0].authorization[0].actor = userName;
    cardTransfer.actions[0].data.storer = userName;
    cardTransfer.actions[0].data.asspanda = pandaId;
    cardTransfer.actions[0].data.eatrar = index;
    console.log(cardTransfer);
    try {
      await activeUser
        .signTransaction(cardTransfer, { blocksBehind: 3, expireSeconds: 30 })
        .then((response) => {
          if (response.status === "executed") {
            loadingSlot.classList.remove("loading");
            loadingSlot.classList.remove("open-modal");

            this.props.eat(index, pandaId, index, 0, []);
            this.props.eatremove(index, pandaId, index, []);

            this.setState({
              ...this.state,
              modalEat: {
                ...this.state.modalEat,
                openModal: false,
              },
            });

            if (localStorage.getItem("sound") === "true") {
              this.audioPandaEating.currentTime = 0;
              this.audioPandaEating.volume = 0.1;
              this.audioPandaEating.play();
            }
          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      loadingSlot.classList.remove("loading");
      this.setState({
        ...this.state,
        modalEat: {
          ...this.state.modalEat,
          loadingChooseEat: false,
        },
        hasError: true,
        errorText: error.message,
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.play();
      }
    }
  }
  // ОТПРАВИТЬ еду на панду и пополнить энергию item.asset_id, index, item.steps, rareat
  async addEnergyMulti(index, pandaId) {
    //console.log('dateBt eat id', dateBt);
    //console.log('index', index);

    var arrayRar = [0, 0, 0, 0, 0, 0];
    var doTrans = true;
    var energyError = false;

    this.props.pandaSlotOnGameWax.map((item, index) => {
      if (item != 0 && item.activePanda) {
        if (item.energy === 10000) {
          if (!energyError) {
            energyError = true;
          }
          //doTrans = false;
        } else {
          if (item.rarity === 0) {
            arrayRar[0] += 1;
          } else if (item.rarity === 1) {
            arrayRar[1] += 1;
          } else {
            arrayRar[item.rarity - 1] += 1;
          }
        }
      }
    });

    //console.log("arrayRar", arrayRar);
    //console.log("this.props.alleat", this.props.alleat);
    var problemRarVar = [0, 0, 0, 0, 0, 0];
    //var showModalMultiEat = false;

    //if(!this.state.problemWithEat.problemEnergy) {
    for (let i = 0; i < 6; i++) {
      // выведет 0, затем 1, затем 2
      //console.log('i',i)
      if (arrayRar[i] != 0 && arrayRar[i] > this.props.alleat[i]) {
        problemRarVar[i] = arrayRar[i] - this.props.alleat[i];
        doTrans = false;
        //showModalMultiEat = true;
      }
    }
    this.setState({
      ...this.state,
      problemWithEat: {
        ...this.state.problemWithEat,
        active: true,
        problemRar: problemRarVar,
        needEat: arrayRar,
        //problemEnergy: energyError
      },
      showModalMultiEat: true,
    });
    //}

    /*if(doTrans) {
            this.addEnergyMultiTrans();
        }*/
  }

  async addEnergyMultiTrans(index, pandaId) {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");

    var pandaIdArrey = [];
    this.props.pandaSlotOnGameWax.map((item, index) => {
      if (item !== 0 && item.activePanda) {
        pandaIdArrey.push(item.asset_id);
      }
    });

    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    cardTransferMulti.actions[0].authorization[0].actor = userName;
    cardTransferMulti.actions[0].data.storer = userName;
    cardTransferMulti.actions[0].data.pandaid = pandaIdArrey;
    console.log(cardTransferMulti);
    try {
      await activeUser
        .signTransaction(cardTransferMulti, {
          blocksBehind: 3,
          expireSeconds: 30,
        })
        .then((response) => {
          if (response.status === "executed") {
            loadingSlot.classList.remove("loading");
            loadingSlot.classList.remove("open-modal");

            var arraynew = pandaIdArrey;
            this.props.eat(0, 0, 0, 0, arraynew);

            this.setState({
              ...this.state,
              modalMultiThank: {
                open: true,
                title: "you have successfully fed the heroes",
                descr: "All selected heroes are restored to full",
              },
              problemWithEat: {
                ...this.state.problemWithEat,
                active: false,
                problemRar: [],
                //problemEnergy: energyError
              },
              showModalMultiEat: false,
              //activeRarPanda: [0,0,0,0,0,0],
              //activeSlotSt: [],
              //openMultiSt: false
            });

            this.props.eatremove(
              index,
              pandaId,
              index,
              this.state.problemWithEat.needEat
            );

            if (localStorage.getItem("sound") === "true") {
              this.audioPandaEating.currentTime = 0;
              this.audioPandaEating.volume = 0.1;
              this.audioPandaEating.play();
            }
          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      loadingSlot.classList.remove("loading");
      this.setState({
        ...this.state,
        modalEat: {
          ...this.state.modalEat,
          hasError: true,
          errorText: error.message,
          loadingChooseEat: false,
        },
      });

      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.play();
      }
    }
  }

  async addEnergyMultiWeapon(type) {
    var moneysum = 0;

    var doTrans = true;
    var showModalMultiWeapon = true;

    if (type == "weapon") {
      this.props.pandaSlotOnGameWax.map((item, index) => {
        if (
          item != 0 &&
          item.activePanda &&
          item.weapon &&
          item.weapon.energy < 10000
        ) {
          if (item.weapon.rarity === 0) {
            moneysum += 10800;
          } else if (item.weapon.rarity === 1) {
            moneysum += 18000;
          } else if (item.weapon.rarity === 2) {
            moneysum += 28800;
          } else if (item.weapon.rarity === 3) {
            moneysum += 46800;
          } else if (item.weapon.rarity === 4) {
            moneysum += 75600;
          } else if (item.weapon.rarity === 5) {
            moneysum += 122400;
          }
        }
      });
    } else {
      this.props.pandaSlotOnGameWax.map((item, index) => {
        if (
          item != 0 &&
          item.activePanda &&
          item.armor &&
          item.armor.energy < 10000
        ) {
          if (item.armor.rarity === 0) {
            moneysum += 6000;
          } else if (item.armor.rarity === 1) {
            moneysum += 11000;
          } else if (item.armor.rarity === 2) {
            moneysum += 18000;
          } else if (item.armor.rarity === 3) {
            moneysum += 34000;
          } else if (item.armor.rarity === 4) {
            moneysum += 67000;
          } else if (item.armor.rarity === 5) {
            moneysum += 144000;
          }
        }
      });
    }

    this.setState({
      ...this.state,
      showModalMultiWeapon: showModalMultiWeapon,
      showModalMultiSum: moneysum,
      showModalMultiType: type,
    });

    // if(doTrans) {
    //     this.addEnergyMultiWeaponTrans();
    // }
  }

  async addEnergyMultiWeaponTrans(sum, type) {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");

    var weaponid = [];

    var actionType;
    if (type == "weapon") {
      actionType = "multiweap";
      this.props.pandaSlotOnGameWax.map((item, index) => {
        if (
          item != 0 &&
          item.activePanda &&
          item.weapon &&
          item.weapon.energy < 10000
        ) {
          weaponid.push(item.weapon.asset_id);
        }
      });
      MultEnergyWeapon.actions[0].data.weaponid = weaponid;
    } else {
      actionType = "multiarmor";
      this.props.pandaSlotOnGameWax.map((item, index) => {
        if (
          item != 0 &&
          item.activePanda &&
          item.armor &&
          item.armor.energy < 10000
        ) {
          weaponid.push(item.armor.asset_id);
        }
      });
      MultEnergyWeapon.actions[0].data.armorid = weaponid;
    }

    console.log("weaponid", weaponid);

    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    MultEnergyWeapon.actions[0].name = actionType;
    MultEnergyWeapon.actions[0].authorization[0].actor = userName;
    MultEnergyWeapon.actions[0].data.storer = userName;
    //MultEnergyWeapon.actions[0].data.rareat = index
    //cardTransfer.actions[0].data.asset_ids = [dateBt]
    //cardTransfer.actions[0].data.memo = "eatpanda " + pandaId + " ";
    console.log(MultEnergyWeapon);
    /*storer: '', 
        pandaid: '', 
        rareat: ''},*/
    try {
      await activeUser
        .signTransaction(MultEnergyWeapon, {
          blocksBehind: 3,
          expireSeconds: 30,
        })
        .then((response) => {
          if (response.status === "executed") {
            loadingSlot.classList.remove("loading");
            loadingSlot.classList.remove("open-modal");

            if (type == "weapon") {
              this.props.addWeaponEnergy(0, true); // true - мультиотправка
            } else {
              this.props.addArmorEnergy(0, true); // true - мультиотправка
            }

            this.props.updateBalance(false, parseFloat(sum).toFixed(4));

            if (type == "weapon") {
              this.setState({
                ...this.state,
                modalMultiThank: {
                  open: true,
                  title: "weapon repair was successful",
                  descr: "All weapons of your chosen heroes have been repaired",
                },
                showModalMultiWeapon: false,
                showModalMultiSum: "",
                showModalMultiType: "",
              });
            } else {
              this.setState({
                ...this.state,
                modalMultiThank: {
                  open: true,
                  title: "armor repair was successful",
                  descr: "All armors of your chosen heroes have been repaired",
                },
                showModalMultiWeapon: false,
                showModalMultiSum: "",
                showModalMultiType: "",
              });
            }

            if (localStorage.getItem("sound") === "true") {
              this.audioMusicInOut.currentTime = 0;
              this.audioMusicInOut.volume = 0.1;
              this.audioMusicInOut.play();
            }
          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      loadingSlot.classList.remove("loading");
      this.setState({
        ...this.state,
        modalEat: {
          ...this.state.modalEat,
          loadingChooseEat: false,
          hasError: true,
          errorText: error.message,
        },
      });

      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.play();
      }
    }
  }

  async addEnergyMultiJew(index, pandaId) {
    console.log("click");

    var moneysum = 0;

    var doTrans = true;
    var showModalMultiJew = true;

    this.props.pandaSlotOnGameWax.map((item, index) => {
      if (
        item != 0 &&
        item.activePanda &&
        item.jew &&
        item.jew.energy < 10000
      ) {
        if (item.jew.rarity === 0) {
          moneysum += 4200;
        } else if (item.jew.rarity === 1) {
          moneysum += 8000;
        } else if (item.jew.rarity === 2) {
          moneysum += 15200;
        } else if (item.jew.rarity === 3) {
          moneysum += 28800;
        } else if (item.jew.rarity === 4) {
          moneysum += 54400;
        } else if (item.jew.rarity === 5) {
          moneysum += 104000;
        }
      }
    });

    this.setState({
      ...this.state,
      showModalMultiJew: showModalMultiJew,
      showModalMultiSum: moneysum,
    });
  }

  async addEnergyMultiJewTrans(sum) {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");

    var jewid = [];
    this.props.pandaSlotOnGameWax.map((item, index) => {
      if (
        item != 0 &&
        item.activePanda &&
        item.jew &&
        item.jew.energy < 10000
      ) {
        jewid.push(item.jew.asset_id);
      }
    });

    console.log("jewid", jewid);

    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    MultEnergyJew.actions[0].authorization[0].actor = userName;
    MultEnergyJew.actions[0].data.storer = userName;
    MultEnergyJew.actions[0].data.jewid = jewid;
    //MultEnergyJew.actions[0].data.rareat = index
    //cardTransfer.actions[0].data.asset_ids = [dateBt]
    //cardTransfer.actions[0].data.memo = "eatpanda " + pandaId + " ";
    console.log(MultEnergyJew);
    /*storer: '', 
        pandaid: '', 
        rareat: ''},*/
    try {
      await activeUser
        .signTransaction(MultEnergyJew, { blocksBehind: 3, expireSeconds: 30 })
        .then((response) => {
          if (response.status === "executed") {
            loadingSlot.classList.remove("loading");
            loadingSlot.classList.remove("open-modal");

            this.props.addJewEnergy(0, true); // true - мультиотправка
            this.props.updateBalance(false, parseFloat(sum).toFixed(4));

            this.setState({
              ...this.state,
              modalMultiThank: {
                open: true,
                title: "jewelry repair was successful",
                descr: "All jewelry of your chosen heroes have been repaired",
              },
              showModalMultiJew: false,
              showModalMultiSum: "",
            });

            if (localStorage.getItem("sound") === "true") {
              this.audioMusicInOut.currentTime = 0;
              this.audioMusicInOut.volume = 0.1;
              this.audioMusicInOut.play();
            }
          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      loadingSlot.classList.remove("loading");
      this.setState({
        ...this.state,
        modalEat: {
          ...this.state.modalEat,
          loadingChooseEat: false,
          hasError: true,
          errorText: error.message,
        },
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.play();
      }
    }
  }

  // ФУНКЦИЯ которая берет значения с таблицы слотов ИМЕННО С МАСИВА СЛОТОВ [0,0,0,0,0,0,0,0,0,0,0,...20...0]
  async getArreySlots() {
    try {
      const activeUser = this.props.ual.activeUser;
      //const accountName = await activeUser.getAccountName();
      let rpcc;
      if (this.props.ual.activeUser.rpc !== undefined) {
        rpcc = this.props.ual.activeUser.rpc;
      } else if (
        this.props.ual.activeUser.rpc === undefined &&
        this.props.ual.activeUser.wax
      ) {
        rpcc = this.props.ual.activeUser.wax.rpc;
      } else {
        rpcc = new JsonRpc(localStorage.getItem("rpc"));
      }
      const results = await rpcc.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "usersnew", // name of the table as specified by the contract abi
        limit: 1,
        lower_bound: this.state.user,
        upper_bound: this.state.user,
        index_position: 1,
        reverse: false,
        show_payer: false,
      });
      const originRowUser = results;
      const resultRow = results.rows[0].slots_count;

      if (resultRow) {
        //console.log('yessssssssssss');
        this.props.setUserSlots(resultRow);
        /*this.setState({
                    userSlots: resultRow
                },  this.GetPandaListOnGame);*/
        this.GetPandaListOnGame();
        this.props.setUsersRow(originRowUser.rows[0].max_slots);
        // this.setState({
        //     userRow: originRowUser.rows[0]
        // });
      }
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }
  // КОНЕЦ ФУНКЦИЯ которая берет значения с таблицы слотов

  async getModalRewardInfoMult() {
    try {
      let rpcc;
      if (this.props.ual.activeUser.rpc !== undefined) {
        rpcc = this.props.ual.activeUser.rpc;
      } else if (
        this.props.ual.activeUser.rpc === undefined &&
        this.props.ual.activeUser.wax
      ) {
        rpcc = this.props.ual.activeUser.wax.rpc;
      } else {
        rpcc = new JsonRpc(localStorage.getItem("rpc"));
      }

      var activePandasId = [];
      this.props.pandaSlotOnGameWax.find((x) => {
        if (x.activePanda === true) {
          activePandasId.push(x.asset_id);
          //console.log("activePandasId", activePandasId);
        }
      });

      const results = await rpcc?.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "nftsongamec", // name of the table as specified by the contract abi
        key_type: "i64",
        lower_bound: this.state.user,
        upper_bound: this.state.user,
        limit: -1,
        reverse: !0,
        show_payer: false,
        index_position: 2,
      }); /*.then(results => {
                const resultRow = results.rows;
                const resultRowFilter = resultRow.filter(row => row.storer === userConst && row.is_in_slot === 0); // фильтр если нет в слоте

                //console.log("resultRow", resultRow);
                if( resultRow && resultRow.length) {
                    
                }
            })*/

      const pandasRows = results.rows;

      //console.log("pandasRows", pandasRows);

      var nowTime = Date.now() / 1000;
      var getActiveTimer = [];
      var hasMultiRew = true;
      var getPandaFinalNumber = [];

      this.interval = setTimeout(() => {
        pandasRows.map((item, index) => {
          if (activePandasId.includes(item.asset_id)) {
            console.log("nowTime", nowTime < item.timer);
            if (nowTime < item.timer) {
              /*console.log("item.finalnumber !== 0", item.finalnumber !== 0);
                            console.log("nowTime < item.timer", nowTime < item.timer);*/
              getActiveTimer.push(true);
              getPandaFinalNumber.push({
                asset_id: item.asset_id,
                finalnumber: item.finalnumber,
              });
            } else {
              getActiveTimer.push(false);
              hasMultiRew = false;
            }
          }
        });

        //console.log("getActiveTimer", getActiveTimer);
        //hasMultiRew = getActiveTimer.includes(false);
        //console.log("hasMultiRew 1", hasMultiRew)
        console.log("getPandaFinalNumber111", getPandaFinalNumber);

        if (hasMultiRew) {

          console.log("getPandaFinalNumber2222", getPandaFinalNumber);
          clearInterval(this.interval);

          //console.log("WTF!!!!!!!");

          this.setState({
            ...this.state,
            multiResult: getPandaFinalNumber,
            newAdvMap: {
              ...this.state.newAdvMap,
              loading: false,
            },
          });
          var timer;
          var timeX = 1;
          if (
            (this.state.newAdvMap.duration == 8 &&
              this.state.sendToAdv.type == "bamboo") ||
            (this.state.newAdvMap.duration == 12 &&
              this.state.sendToAdv.type != "bamboo")
          ) {
            timeX = 2;
          } else if (
            this.state.newAdvMap.duration == 12 &&
            this.state.sendToAdv.type == "bamboo"
          ) {
            timeX = 3;
          }

          if (this.state.sendToAdv.type == "bamboo") {
            timer = Date.now() / 1000 + 14400 * timeX;
          } else if (this.state.sendToAdv.type == "resources") {
            timer = Date.now() / 1000 + 21600 * timeX;
          } else {
            timer = Date.now() / 1000 + 21600 * timeX;
          }
          this.props.removePandaAdv(
            activePandasId,
            parseInt(timer.toFixed(0)),
            0.5,
            0.5,
            0.5,
            this.state.sendToAdv.type,
            true,
            this.state.newAdvMap.duration
          );
        } else {
          this.getModalRewardInfoMult();
        }
      }, 4000);
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
        newAdvMap: {
          ...this.state.newAdvMap,
          loading: true,
        },
        sendToAdv: {
          ...this.state.sendToAdv,
          modalOpen: false,
          anim: false,
          type: "",
        },
      });

      //console.log('\nCaught exception: ' + e);
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  async sendWombat(event) {
    const activeUser = this.props.ual.activeUser;
    const accountName = await activeUser.getAccountName();
    var dateNow = parseInt(Date.now() / 1000);
    var datas = {

      "username": accountName,
      "event": event,
      "eventtime": dateNow,
    }



  }

  async sendPandaToAdventuresMultArray(type, id, duration) {
    this.setState({
      ...this.state,
      newAdvMap: {
        ...this.state.newAdvMap,
        loading: true,
        duration: duration,
      },
    });

    var pandaIdArrey = [];
    if (id) {
      pandaIdArrey.push(id);
    } else {
      this.props.pandaSlotOnGameWax.map((item, index) => {
        if (item !== 0 && item.activePanda) {
          pandaIdArrey.push(item.asset_id);
        }
      });
    }

    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    //multyAdv.actions[0].neme = typeTrans;
    multyAdv.actions[0].authorization[0].actor = userName;
    multyAdv.actions[0].data.storer = userName;
    multyAdv.actions[0].data.pandaid = pandaIdArrey;
    multyAdv.actions[0].data.typeadv = type;
    multyAdv.actions[0].data.duration = duration;
    console.log(multyAdv);

    try {
      await activeUser
        .signTransaction(multyAdv, { blocksBehind: 3, expireSeconds: 30 })
        .then((response) => {
          if (response.status === "executed") {
            //console.log("sendPandaToAdventuresMultArray");
            this.showCloseReward("open");
            this.getModalRewardInfoMult();
            //this.sendWombat("panda_adventure");
          }
        });
    } catch (error) {
      console.log(error.message);
      console.warn(error);
      this.setState({
        ...this.state,
        hasError: true,
        errorText: error.message,
        newAdvMap: {
          ...this.state.newAdvMap,
          loading: false,
          showModalInside: false,
          duration: 0,
        },
        sendToAdv: {
          ...this.state.sendToAdv,
          modalOpen: false,
          anim: false,
          type: "",
        },
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  // цикл который выводит слоты и выводит инфу по пандам
  async getPandaSlot() {
    try {
      let arr = this.props.userSlots;

      let noZiroArr = arr.filter(function (val) {
        return val !== 0;
      });

      var newArr = noZiroArr.join();

      if (newArr) {
        const params = {
          limit: 100,
          owner: process.env.REACT_APP_CONTRACT,
          collection_name: "nftpandawaxp",
          schema_name: ["heroes", "promo"],
          ids: newArr,
        };

        await api.getAssets(params, 1, 100).then(assets => {

          var filteredAssets = [];

          if (assets && assets.length) {
            filteredAssets = assets.map((item) => {
              return {
                asset_id: item.asset_id,
                name: item.name,
                template: {
                  template_id: item.template.template_id,
                },
                data: {
                  rarity: item.data.rarity,
                  name: item.data.name,
                  type: item.data.type
                },
              };
            });
          }

          this.setState({
            fastGetPandaOnSlot: filteredAssets,
          });
          //console.log('assets', assets );
        });

        const calc = async (n, index) => {
          var b;
          var c;
          var d;
          var weapon;
          var weaponRow;
          var jew;
          var jewRow;
          if (n !== 0) {
            //console.log(this.props.ual.activeUser.accountName);

            b = this.state.fastGetPandaOnSlot.filter(
              (panda) => panda.asset_id === n
            );

            c = this.state.cardOnGame.filter((panda) => panda.asset_id === n);
            //console.log("from contract field", c);
            if (c[0].weapon !== 0) {
              //console.log(555555555555555555555555);
              weapon = await api.getAsset(c[0].weapon);

              //console.log('weapon', weapon);
            }

            this.props.pandaWeapon.map((u) => {
              // console.log('u.panda_id', u.panda_id);
              // console.log('b.asset_id', b.asset_id);
              if (u.panda_id === b[0].asset_id) {
                weaponRow = u;
              }
            });

            this.props.pandaJew.map((u) => {
              // console.log('u.panda_id', u.panda_id);
              // console.log('b.asset_id', b.asset_id);
              if (u.panda_id === b[0].asset_id) {
                jewRow = u;
              }
            });

            var copy7 = Object.assign({}, b);
            copy7.asset_id = n;

            return [
              b[0],
              c[0].steps,
              c[0].timer,
              c[0].panda_way,
              c[0].stake,
              c[0].stake_count,
              c[0].lvl,
              c[0],
              weapon,
              weaponRow,
              jewRow,
            ];

            // asset arrey, spets, timer, panda way, stake, stake count
          }
          return b;
        };
        const asyncFunc = async () => {
          //console.log('arr', arr);
          //const p = calc(arr, index);

          const p = arr.map((n, index) => calc(n, index));
          const results = await Promise.all(p);
          //console.log('results', results);
          this.props.setSlotPandaOnGame(results);

          var arraynew = 0;

          for (let i = 0; i < 20; i++) {
            // выведет 0, затем 1, затем 2
            if (results[i] !== undefined) {
              arraynew += 1;
            }
          }

          this.setState({
            ...this.state,
            pandaInSlot: arraynew,
          });
        };
        asyncFunc();
      } else {
        const results = [
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
        ];
        this.props.setSlotPandaOnGame(results);
      }
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  } // КОНЕЦ

  async getWeaponsSlotWax() {
    try {
      //const accountName = this.props.ual.activeUser.accountName;
      let rpcc;
      if (this.props.ual.activeUser.rpc !== undefined) {
        rpcc = this.props.ual.activeUser.rpc;
      } else if (
        this.props.ual.activeUser.rpc === undefined &&
        this.props.ual.activeUser.wax
      ) {
        rpcc = this.props.ual.activeUser.wax.rpc;
      } else {
        rpcc = new JsonRpc(localStorage.getItem("rpc"));
      }
      //console.log(rpcc);

      await rpcc
        ?.get_table_rows({
          json: true,
          code: process.env.REACT_APP_CONTRACT, // contract who owns the table
          scope: process.env.REACT_APP_CONTRACT, // scope of the table
          table: "nftweapons", // name of the table as specified by the contract abi
          limit: 100,
          lower_bound: this.state.user,
          upper_bound: this.state.user,
          key_type: "i64",
          index_position: 2,
          reverse: false,
          show_payer: false,
        })
        .then((weapons) => {
          if (weapons.rows && weapons.rows.length) {
            this.props.setWeaponOnGameWax(weapons.rows);
          }
          this.props.setWasLoadWeapon(true);
        });
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  } // КОНЕЦ

  async getJewSlotWax() {
    try {
      //const accountName = this.props.ual.activeUser.accountName;
      let rpcc;
      if (this.props.ual.activeUser.rpc !== undefined) {
        rpcc = this.props.ual.activeUser.rpc;
        //console.log(1);
      } else if (
        this.props.ual.activeUser.rpc === undefined &&
        this.props.ual.activeUser.wax
      ) {
        rpcc = this.props.ual.activeUser.wax.rpc;
        //console.log(2);
      } else {
        rpcc = new JsonRpc(localStorage.getItem("rpc"));
        //console.log(3);
      }

      /*console.log("rpcc 33",rpcc);
            console.log("accountName 33",accountName);
            //console.log("activeUser",activeUser);*/

      var jew = await rpcc?.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "nftjew", // name of the table as specified by the contract abi
        limit: 100,
        lower_bound: this.state.user,
        upper_bound: this.state.user,
        key_type: "i64",
        index_position: 2,
        reverse: false,
        show_payer: false,
      });

      //console.log("jew", jew);

      if (jew?.rows && jew?.rows?.length) {
        this.props.setJewOnGameWax(jew.rows);
      }
      this.props.setWasLoadJew(true);
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception 123: " + e);
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  } // КОНЕЦ

  async getArmorSlotWax() {
    try {
      //const accountName = this.props.ual.activeUser.accountName;
      let rpcc;
      if (this.props.ual.activeUser.rpc !== undefined) {
        rpcc = this.props.ual.activeUser.rpc;
        //console.log(1);
      } else if (
        this.props.ual.activeUser.rpc === undefined &&
        this.props.ual.activeUser.wax
      ) {
        rpcc = this.props.ual.activeUser.wax.rpc;
        //console.log(2);
      } else {
        rpcc = new JsonRpc(localStorage.getItem("rpc"));
        //console.log(3);
      }

      /*console.log("rpcc 33",rpcc);
            console.log("accountName 33",accountName);
            //console.log("activeUser",activeUser);*/

      var armor = await rpcc?.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "nftarmor", // name of the table as specified by the contract abi
        limit: 100,
        lower_bound: this.state.user,
        upper_bound: this.state.user,
        key_type: "i64",
        index_position: 2,
        reverse: false,
        show_payer: false,
      });

      //console.log("armor", armor);

      if (armor?.rows && armor?.rows?.length) {
        this.props.setArmorOnGameWax(armor.rows);
      }
      this.props.setWasLoadArmor(true);
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  } // КОНЕЦ

  // диспаччит таймер слот
  async clearError() {
    this.setState({
      errorText: "",
      hasError: false,
    });
  }

  openModalError(error) {
    //console.log(error);
    document.getElementsByTagName("body")[0].classList.add("open-modal");
    if (error === "error-profit") {
      this.setState({
        ...this.state,
        hasModalErrorInfo: {
          hasModalError: true,
          title: "The hero is too weak",
          description:
            "Your hero is too weak to bring the full reward. <br /> All he can do is drag and drop <span>20 percent</span> of the total reward. <br />He needs to eat to regain his strength.",
        },
      });
    } else if (error === "error-weapon") {
      this.setState({
        ...this.state,
        hasModalErrorInfo2: {
          hasModalError: true,
          title: "Weapon is Broken",

          description:
            "our Hero cannot fight with a broken weapon! <br />Therefore, the bonus from the weapon is <span>0</span>. <br />Also, you cannot go on adventures for resources! <br />You need to repair it!",
        },
      });
    } else if (error === "error-jew") {
      this.setState({
        ...this.state,
        hasModalErrorInfo2: {
          hasModalError: true,
          title: "Jewelry is Broken",
          description:
            "Your Hero defense is too weak with broken jewelry! <br /> Therefore, the bonus from the jewelry is <span>0</span>. <br />You need to repair it!",
        },
      });
    } else if (error === "error-armor") {
      this.setState({
        ...this.state,
        hasModalErrorInfo3: {
          hasModalError: true,
          title: "Armor is Broken",
          description:
            "Your Hero defense is too weak with broken armor! <br />Therefore, the bonus from the armor is <span>0</span>. <br />You need to repair it!",
        },
      });
    } else if (error === "error-profit-weapon") {
      this.setState({
        ...this.state,
        hasModalErrorInfo: {
          hasModalError: true,
          title: "The hero is too weak",
          description:
            "Your hero is too weak to bring the full reward. <br /> All he can do is drag and drop <span>20 percent</span> of the total reward. <br />He needs to eat to regain his strength.",
        },
        hasModalErrorInfo2: {
          hasModalError: true,
          title: "Weapon is Broken",
          description:
            "Your Hero cannot fight with a broken weapon! <br /> Therefore, the bonus from the weapon is <span>0</span>. <br />You need to repair it!",
        },
      });
    } else if (error === "error-profit-weapon-armor") {
      this.setState({
        ...this.state,
        hasModalErrorInfo: {
          hasModalError: true,
          title: "The hero is too weak",
          description:
            "Your hero is too weak to bring the full reward. <br /> All he can do is drag and drop <span>20 percent</span> of the total reward. <br />He needs to eat to regain his strength.",
        },
        hasModalErrorInfo2: {
          hasModalError: true,
          title: "Weapon is Broken",
          description:
            "Your Hero cannot fight with a broken weapon! <br /> Therefore, the bonus from the weapon is <span>0</span>. <br />You need to repair it!",
        },
        hasModalErrorInfo3: {
          hasModalError: true,
          title: "Armor is Broken",
          description:
            "Your Hero defense is too weak with broken armor! <br />Therefore, the bonus from the armor is <span>0</span>. <br />You need to repair it!",
        },
      });
    } else if (error === "error-profit-jew") {
      this.setState({
        ...this.state,
        hasModalErrorInfo: {
          hasModalError: true,
          title: "The hero is too weak",
          description:
            "Your hero is too weak to bring the full reward. <br /> All he can do is drag and drop <span>20 percent</span> of the total reward. <br />He needs to eat to regain his strength.",
        },
        hasModalErrorInfo2: {
          hasModalError: true,
          title: "Jewelry is Broken",
          description:
            "Your Hero defense is too weak with broken jewelry! <br /> Therefore, the bonus from the jewelry is <span>0</span>. <br />You need to repair it!",
        },
      });
    } else if (error === "error-profit-armor") {
      this.setState({
        ...this.state,
        hasModalErrorInfo: {
          hasModalError: true,
          title: "The hero is too weak",
          description:
            "Your hero is too weak to bring the full reward. <br /> All he can do is drag and drop <span>20 percent</span> of the total reward. <br />He needs to eat to regain his strength.",
        },
        hasModalErrorInfo3: {
          hasModalError: true,
          title: "Armor is Broken",
          description:
            "Your Hero defense is too weak with broken armor! <br />Therefore, the bonus from the armor is <span>0</span>. <br />You need to repair it!",
        },
      });
    }
  }

  handleCloseModalError() {
    //console.log('close');
    document.getElementsByTagName("body")[0].classList.remove("open-modal");
    this.setState({
      ...this.state,
      hasModalErrorInfo: {
        hasModalError: false,
        title: "",
        description: "",
      },
      hasModalErrorInfo2: {
        hasModalError: false,
        title: "",
        description: "",
      },
      hasModalErrorInfo3: {
        hasModalError: false,
        title: "",
        description: "",
      },
    });
  }

  async GetUserWeaponViucherList() {
    (async () => {
      try {
        const userName = this.props.ual.activeUser.accountName;
        const activeUser = this.props.ual.activeUser;

        const params = {
          limit: 500,
          owner: userName,
          collection_name: "nftpandawaxp",
          schema_name: "voucher",
          template_id: 437403,
        };

        await api.getAssets(params, 1, 100).then(assets => {

          var filteredAssets = [];

          if (assets && assets.length) {
            filteredAssets = assets.map((item) => {
              return {
                asset_id: item.asset_id,
                name: item.name,
                template: {
                  template_id: item.template.template_id,
                },
                data: {
                  rarity: item.data.rarity,
                  name: item.data.name,
                  type: item.data.type
                },
              };
            });
          }

          this.setState({
            ...this.state,
            modalWeapon: {
              ...this.state.modalWeapon,
              voucherCount: filteredAssets.length,
              voucherList: filteredAssets,
            },
          });
        });
      } catch (e) {
        // this.setState({
        //   hasError: true,
        //   errorText: e.message,
        // });
        console.log("\nCaught exception: " + e);
        // if(localStorage.getItem('sound') === 'true') {
        //     this.audioMusicError.currentTime = 0;
        //     this.audioMusicError.volume = 0.1;
        //     this.audioMusicError.play();
        // }
      }
    })();
  }

  showCloseModal(method, weapon, weaponEnergy, index, rar) {
    document.getElementsByTagName("body")[0].classList.add("open-modal");
    //console.log('rar', rar);

    var moneysum = 0;
    if (rar === 0) {
      moneysum = 1.08;
    } else if (rar === 1) {
      moneysum = 1.8;
    } else if (rar === 2) {
      moneysum = 2.88;
    } else if (rar === 3) {
      moneysum = 4.68;
    } else if (rar === 4) {
      moneysum = 7.56;
    } else if (rar === 5) {
      moneysum = 12.24;
    }

    //console.log(weapon);
    if (method === "open") {
      this.setState({
        ...this.state,
        modalWeapon: {
          ...this.state.modalWeapon,
          openModal: true,
          loadingChooseEat: true,
          weapon: weapon,
          weaponEnergy: weaponEnergy,
          index: index,
          cost: moneysum,
        },
      });
      setTimeout(() => {
        this.setState({
          modalWeapon: {
            ...this.state.modalWeapon,
            showAnimModal: true,
          },
        });
      }, 300);

      if (localStorage.getItem("sound") === "true") {
        this.audioOpen.currentTime = 0;
        this.audioOpen.volume = 0.1;
        this.audioOpen.play();
      }

      // if(localStorage.getItem('wasReload') === "true") {
      //     this.GetUserWeaponViucherList();
      // }
    } else {
      document.getElementsByTagName("body")[0].classList.remove("open-modal");
      this.setState({
        modalWeapon: {
          ...this.state.modalWeapon,
          openModal: false,
          showAnimModal: false,
          weapon: null,
          weaponEnergy: "",
          index: "",
          cost: "",
        },
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioClose.currentTime = 0;
        this.audioClose.volume = 0.1;
        this.audioClose.play();
      }
    }
  }

  showCloseModalJew(method, jew, jewEnergy, index, rar, type) {
    document.getElementsByTagName("body")[0].classList.add("open-modal");
    //console.log('rar', rar);

    var moneysum = 0;
    if (type == "armor") {
      if (rar === 0) {
        moneysum = 0.6;
      } else if (rar === 1) {
        moneysum = 1.1;
      } else if (rar === 2) {
        moneysum = 1.8;
      } else if (rar === 3) {
        moneysum = 3.4;
      } else if (rar === 4) {
        moneysum = 6.7;
      } else if (rar === 5) {
        moneysum = 14.4;
      }
    } else {
      if (rar === 0) {
        moneysum = 0.42;
      } else if (rar === 1) {
        moneysum = 0.8;
      } else if (rar === 2) {
        moneysum = 1.52;
      } else if (rar === 3) {
        moneysum = 2.88;
      } else if (rar === 4) {
        moneysum = 5.44;
      } else if (rar === 5) {
        moneysum = 10.4;
      }
    }

    //console.log(jew);
    if (method === "open") {
      this.setState({
        ...this.state,
        modalJew: {
          ...this.state.modalJew,
          openModal: true,
          loadingChooseEat: true,
          jew: jew,
          jewEnergy: jewEnergy,
          index: index,
          cost: moneysum,
        },
      });
      setTimeout(() => {
        this.setState({
          modalJew: {
            ...this.state.modalJew,
            showAnimModal: true,
          },
        });
      }, 300);

      if (localStorage.getItem("sound") === "true") {
        this.audioOpen.currentTime = 0;
        this.audioOpen.volume = 0.1;
        this.audioOpen.play();
      }
    } else {
      document.getElementsByTagName("body")[0].classList.remove("open-modal");
      this.setState({
        modalJew: {
          ...this.state.modalJew,
          openModal: false,
          showAnimModal: false,
          jew: null,
          jewEnergy: "",
          index: "",
          cost: "",
        },
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioClose.currentTime = 0;
        this.audioClose.volume = 0.1;
        this.audioClose.play();
      }
    }
  }

  showCloseAllWeaponModal(method, pandaId) {
    //console.log(method);
    document.getElementsByTagName("body")[0].classList.add("open-modal");
    if (method === "open") {
      // ЭТО ДЛЯ ГЛОБАЛЬНОГО СТЕЙТА (ТОЧНО КАК У ЕДЫ)
      if (/*!this.props.wasbay && */ !this.props.statusWeapon) {
        // было куплено, или перезагрузили страницу
        //console.log(111);
        this.setState({
          ...this.state,
          modalAllWeapon: {
            ...this.state.modalAllWeapon,
            openModal: true,
            loadingWeapon: true,
            idPanda: pandaId,
          },
        });
        this.GetPandaWeaponList();
      } else {
        //console.log(222);
        this.setState({
          ...this.state,
          modalAllWeapon: {
            ...this.state.modalAllWeapon,
            openModal: true,
            idPanda: pandaId,
          },
        });
        this.GetPandaWeaponListLocal();
      }

      setTimeout(() => {
        this.setState({
          modalAllWeapon: {
            ...this.state.modalAllWeapon,
            showAnimModal: true,
          },
        });
      }, 300);

      if (localStorage.getItem("sound") === "true") {
        this.audioOpen.currentTime = 0;
        this.audioOpen.volume = 0.1;
        this.audioOpen.play();
      }
    } else {
      document.getElementsByTagName("body")[0].classList.remove("open-modal");
      this.setState({
        modalAllWeapon: {
          ...this.state.modalAllWeapon,
          openModal: false,
        },
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioClose.currentTime = 0;
        this.audioClose.volume = 0.1;
        this.audioClose.play();
      }
    }
  }

  showCloseAllJewModal(method, pandaId, type) {
    //console.log(method);
    document.getElementsByTagName("body")[0].classList.add("open-modal");
    if (method === "open") {
      if (type === "armor") {
        this.setState({
          ...this.state,
          modalAllArmor: {
            ...this.state.modalAllArmor,
            openModal: true,
            //loadingJew: true,
            idPanda: pandaId,
          },
        });

        setTimeout(() => {
          this.setState({
            modalAllArmor: {
              ...this.state.modalAllArmor,
              showAnimModal: true,
            },
          });
        }, 300);
      } else {
        this.setState({
          ...this.state,
          modalAllJew: {
            ...this.state.modalAllJew,
            openModal: true,
            //loadingJew: true,
            idPanda: pandaId,
          },
        });

        setTimeout(() => {
          this.setState({
            modalAllJew: {
              ...this.state.modalAllJew,
              showAnimModal: true,
            },
          });
        }, 300);
      }

      if (localStorage.getItem("sound") === "true") {
        this.audioOpen.currentTime = 0;
        this.audioOpen.volume = 0.1;
        this.audioOpen.play();
      }
    } else {
      document.getElementsByTagName("body")[0].classList.remove("open-modal");
      this.setState({
        modalAllJew: {
          ...this.state.modalAllJew,
          openModal: false,
        },
        modalAllArmor: {
          ...this.state.modalAllArmor,
          openModal: false,
        },
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioClose.currentTime = 0;
        this.audioClose.volume = 0.1;
        this.audioClose.play();
      }
    }
  }

  async GetPandaWeaponList() {
    (async () => {
      try {
        const userName = this.props.ual.activeUser.accountName;
        const activeUser = this.props.ual.activeUser;

        const params = {
          limit: 500,
          owner: userName,
          collection_name: 'nftpandawaxp',
          schema_name: ["weapon"],
          'template_data:text.shape': 'Crafted'
        };

        await api.getAssets(params, 1, 100).then(assets => {

          var filteredAssets = [];

          if (assets && assets.length) {
            filteredAssets = assets.map((item) => {
              return {
                asset_id: item.asset_id,
                name: item.name,
                template: {
                  template_id: item.template.template_id,
                },
                data: {
                  rarity: item.data.rarity,
                  name: item.data.name,
                  type: item.data.type,
                  element: item.data.element
                },
              };
            });
          }

          this.props.weaponlist(filteredAssets);
          this.props.statusLoadinWeapon(true);
          this.setState({
            ...this.state,
            modalAllWeapon: {
              ...this.state.modalAllWeapon,
              loadingWeapon: false,
            }
          });
        });
      } catch (e) {
        //console.log(e.message)
        //console.warn(e)
        this.setState({
          ...this.state,
          modalAllWeapon: {
            ...this.state.modalAllWeapon,
            loadingWeapon: false,
          },
        });
        // this.setState({
        //   hasError: true,
        //   errorText: e.message,
        // });
        this.props.statusLoadinWeapon(false);

        console.log("\nCaught exception: " + e);
        // if (localStorage.getItem("sound") === "true") {
        //   this.audioMusicError.currentTime = 0;
        //   this.audioMusicError.volume = 0.1;
        //   this.audioMusicError.play();
        // }
      }
    })();
  }

  async GetPandaJewList() {
    (async () => {
      try {
        const userName = this.props.ual.activeUser.accountName;
        const activeUser = this.props.ual.activeUser;

        // var arr = [
        //   412726, 412728, 412730, 412732, 412733, 412735, 412736, 412737,
        //   412738, 412739, 412740, 412741, 412750, 412751, 412753, 412754,
        //   412755, 412757, 413107, 413108, 413111, 413112, 413114, 413115,
        //   413131, 413133, 413135, 413136, 413139, 413141, 413857, 413859,
        //   413860, 413862, 413863, 413865, 413867, 413868, 413869, 413872,
        //   413874, 413877, 414774, 414776, 414778, 414782, 414783, 414784,
        //   414785, 414786, 414798, 414800, 414801, 414804, 415378, 415381,
        //   415385, 415388, 415389, 415392, 415402, 415403, 415404, 415406,
        //   415407, 415408, 415409, 415575, 415576, 415577, 415578, 415579,
        //   416477, 416478, 416479, 416482, 416483, 416486, 416488, 416489,
        //   416490, 416491, 416492, 416493, 417238, 417239, 417240, 417241,
        //   417242, 417244, 417251, 417252, 417253, 417254, 417257, 417259,
        //   428200, 428202, 428204, 428207, 428213, 428215, 428220, 428222,
        //   428225, 428226, 428527, 428529, 428540, 428543, 428546, 428548,
        //   428550, 428553, 429272, 429274, 429275, 429276, 429277, 429295,
        //   431181, 431182, 431183, 431184, 431185, 431186,
        // ];

        const params = {
          limit: 500,
          owner: userName,
          collection_name: "nftpandawaxp",
          schema_name: "jewelry",
        };

        await api.getAssets(params, 1, 100).then((assets) => {

          var filteredAssets = [];

          if (assets && assets.length) {
            filteredAssets = assets.map((item) => {
              return {
                asset_id: item.asset_id,
                name: item.name,
                template: {
                  template_id: item.template.template_id,
                },
                data: {
                  rarity: item.data.rarity,
                  name: item.data.name,
                  type: item.data.type,
                  element: item.data.element
                },
              };
            });
          }

          this.props.jewlist(filteredAssets);
          this.props.statusLoadinJew(true);

          this.setState({
            ...this.state,
            modalAllJew: {
              ...this.state.modalAllJew,
              loadingJew: false,
            },
          });
        });
      } catch (e) {
        //console.log(e.message)
        //console.warn(e)
        this.setState({
          ...this.state,
          modalAllJew: {
            ...this.state.modalAllJew,
            loadingJew: false,
          },
          //hasError: true,
          //errorText: e.message,
        });
        this.props.statusLoadinJew(false);

        console.log("\nCaught exception: " + e);
        // if (localStorage.getItem("sound") === "true") {
        //   this.audioMusicError.currentTime = 0;
        //   this.audioMusicError.volume = 0.1;
        //   this.audioMusicError.play();
        // }
      }
    })();
  }

  async GetPandaArmorList() {
    (async () => {
      try {
        const userName = this.props.ual.activeUser.accountName;
        const activeUser = this.props.ual.activeUser;

        const params = {
          limit: 500,
          owner: userName,
          collection_name: "nftpandawaxp",
          schema_name: "armor",
        };

        await api.getAssets(params, 1, 100).then(assets => {

          var filteredAssets = [];

          if (assets && assets.length) {
            filteredAssets = assets.map((item) => {
              return {
                asset_id: item.asset_id,
                name: item.name,
                template: {
                  template_id: item.template.template_id,
                },
                data: {
                  rarity: item.data.rarity,
                  name: item.data.name,
                  type: item.data.type,
                  element: item.data.element
                },
              };
            });
          }

          this.props.armorlist(filteredAssets);
          this.props.statusLoadinArmor(true);

          this.setState({
            ...this.state,
            modalAllArmor: {
              ...this.state.modalAllArmor,
              loadingArmor: false,
            },
          });
        });
      } catch (e) {
        //console.log(e.message)
        //console.warn(e)
        this.setState({
          ...this.state,
          modalAllArmor: {
            ...this.state.modalAllArmor,
            loadingArmor: false,
          },
        });
        // this.setState({
        //   hasError: true,
        //   errorText: e.message,
        // });
        this.props.statusLoadiArmor(false);

        console.log("\nCaught exception: " + e);
        // if (localStorage.getItem("sound") === "true") {
        //   this.audioMusicError.currentTime = 0;
        //   this.audioMusicError.volume = 0.1;
        //   this.audioMusicError.play();
        // }
      }
    })();
  }

  async GetPandaWeaponListLocal() {
    (async () => {
      //console.log("open local weapon list");
      /* тут пока пусто а может так и будет */
    })();
  }

  // отправка оружия в слот
  async sendWeapon(index, allinfo) {
    // this.setState({
    //     chooseHeroLoading: true
    // });

    //console.log(allinfo);
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");

    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    cardTransferWeaponToGame.actions[0].authorization[0].actor = userName;
    cardTransferWeaponToGame.actions[0].data.from = userName;
    cardTransferWeaponToGame.actions[0].data.asset_ids = [allinfo.asset_id];
    cardTransferWeaponToGame.actions[0].data.memo =
      "sendweapon " + this.state.modalAllWeapon.idPanda + " for panda";
    //console.log(cardTransfer);
    try {
      await activeUser
        .signTransaction(cardTransferWeaponToGame, {
          blocksBehind: 3,
          expireSeconds: 30,
        })
        .then((response) => {
          if (response.status === "executed") {

            document
              .getElementsByTagName("body")[0]
              .classList.remove("open-modal");
            this.props.addWeaponToPanda(allinfo.asset_id);
            this.props.addWeaponToSlot(
              allinfo,
              this.state.modalAllWeapon.idPanda
            );
            if (localStorage.getItem("sound") === "true") {
              this.audioMusicRemove.currentTime = 0;
              this.audioMusicRemove.volume = 0.1;
              this.audioMusicRemove.play();
            }
            loadingSlot.classList.remove("loading");
            this.setState({
              modalAllWeapon: {
                ...this.state.modalAllWeapon,
                openModal: false,
              },
            });
          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
        chooseHeroLoading: false,
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
      loadingSlot.classList.remove("loading");
    }
  }

  // отправка jew в слот
  async sendJew(index, allinfo) {
    // this.setState({
    //     chooseHeroLoading: true
    // });

    //console.log(allinfo);
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");

    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    cardTransferToolsToGame.actions[0].authorization[0].actor = userName;
    cardTransferToolsToGame.actions[0].data.from = userName;
    cardTransferToolsToGame.actions[0].data.asset_ids = [allinfo.asset_id];
    cardTransferToolsToGame.actions[0].data.memo =
      "sendjew " + this.state.modalAllJew.idPanda + " for panda";
    //console.log(cardTransfer);
    try {
      await activeUser
        .signTransaction(cardTransferToolsToGame, {
          blocksBehind: 3,
          expireSeconds: 30,
        })
        .then((response) => {
          if (response.status === "executed") {

            document
              .getElementsByTagName("body")[0]
              .classList.remove("open-modal");
            this.props.addJewToPanda(allinfo.asset_id);
            this.props.addJewToSlot(allinfo, this.state.modalAllJew.idPanda);
            if (localStorage.getItem("sound") === "true") {
              this.audioMusicRemove.currentTime = 0;
              this.audioMusicRemove.volume = 0.1;
              this.audioMusicRemove.play();
            }
            loadingSlot.classList.remove("loading");
            this.setState({
              modalAllJew: {
                ...this.state.modalAllJew,
                openModal: false,
              },
            });
          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
        chooseHeroLoading: false,
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
      loadingSlot.classList.remove("loading");
    }
  }

  // отправка jew в слот
  async sendArmor(index, allinfo) {
    // this.setState({
    //     chooseHeroLoading: true
    // });

    //console.log(allinfo);
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");

    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    cardTransferToolsToGame.actions[0].authorization[0].actor = userName;
    cardTransferToolsToGame.actions[0].data.from = userName;
    cardTransferToolsToGame.actions[0].data.asset_ids = [allinfo.asset_id];
    cardTransferToolsToGame.actions[0].data.memo =
      "sendarmor " + this.state.modalAllArmor.idPanda + " for panda";
    //console.log(cardTransfer);
    try {
      await activeUser
        .signTransaction(cardTransferToolsToGame, {
          blocksBehind: 3,
          expireSeconds: 30,
        })
        .then((response) => {
          if (response.status === "executed") {

            document
              .getElementsByTagName("body")[0]
              .classList.remove("open-modal");
            this.props.addArmorToPanda(allinfo.asset_id);
            this.props.addArmorToSlot(
              allinfo,
              this.state.modalAllArmor.idPanda
            );
            if (localStorage.getItem("sound") === "true") {
              this.audioMusicRemove.currentTime = 0;
              this.audioMusicRemove.volume = 0.1;
              this.audioMusicRemove.play();
            }
            loadingSlot.classList.remove("loading");
            this.setState({
              modalAllArmor: {
                ...this.state.modalAllArmor,
                openModal: false,
              },
            });
          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
        chooseHeroLoading: false,
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
      loadingSlot.classList.remove("loading");
    }
  }

  // отправка оружия юзеру обратно
  async sendOutWeapon(weapon) {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");

    this.setState({
      chooseHeroLoading: true,
    });

    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    cardTransferOutWeaponFromGame.actions[0].authorization[0].actor = userName;
    cardTransferOutWeaponFromGame.actions[0].data.to = userName;
    cardTransferOutWeaponFromGame.actions[0].data.asset_id = [weapon.asset_id];
    //console.log(cardTransferOut);
    try {
      await activeUser
        .signTransaction(cardTransferOutWeaponFromGame, {
          blocksBehind: 3,
          expireSeconds: 30,
        })
        .then((response) => {
          if (response.status === "executed") {

            document
              .getElementsByTagName("body")[0]
              .classList.remove("open-modal");
            this.props.addWeaponWhenRemove(weapon);
            this.props.removeWeaponSlot(weapon.asset_id);
            loadingSlot.classList.remove("loading");
            this.setState({
              ...this.state,
              modalWeapon: {
                ...this.state.modalWeapon,
                openModal: false,
                showAnimModal: false,
                weapon: null,
                weapon: "",
                cost: "",
              },
            });
            if (localStorage.getItem("sound") === "true") {
              this.audioMusicRemove.currentTime = 0;
              this.audioMusicRemove.volume = 0.1;
              this.audioMusicRemove.play();
            }
          }
        });
    } catch (error) {
      //console.log(error.message)
      loadingSlot.classList.remove("loading");
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
        chooseHeroLoading: false,
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  // отправка оружия юзеру обратно
  async sendOutJew(jew, type) {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");

    this.setState({
      chooseHeroLoading: true,
    });

    console.log("type", type);

    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    if (type == "armor") {
      cardTransferOutToolsFromGame.actions[0].name = "armorout";
    }
    cardTransferOutToolsFromGame.actions[0].authorization[0].actor = userName;
    cardTransferOutToolsFromGame.actions[0].data.to = userName;
    cardTransferOutToolsFromGame.actions[0].data.asset_id = [jew.asset_id];

    console.log("cardTransferOutToolsFromGame", cardTransferOutToolsFromGame);

    try {
      await activeUser
        .signTransaction(cardTransferOutToolsFromGame, {
          blocksBehind: 3,
          expireSeconds: 30,
        })
        .then((response) => {
          if (response.status === "executed") {

            document
              .getElementsByTagName("body")[0]
              .classList.remove("open-modal");
            if (type == "armor") {
              this.props.addArmorWhenRemove(jew);
              this.props.removeArmorSlot(jew.asset_id);
            } else {
              this.props.addJewWhenRemove(jew);
              this.props.removeJewSlot(jew.asset_id);
            }
            loadingSlot.classList.remove("loading");
            this.setState({
              ...this.state,
              modalJew: {
                ...this.state.modalJew,
                openModal: false,
                showAnimModal: false,
                jew: null,
                jew: "",
                cost: "",
              },
            });
            if (localStorage.getItem("sound") === "true") {
              this.audioMusicRemove.currentTime = 0;
              this.audioMusicRemove.volume = 0.1;
              this.audioMusicRemove.play();
            }
          }
        });
    } catch (error) {
      //console.log(error.message)
      loadingSlot.classList.remove("loading");
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
        chooseHeroLoading: false,
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  // отправка оружия юзеру обратно
  async sendOutArmor(jew) {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");

    this.setState({
      chooseHeroLoading: true,
    });

    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    cardTransferOutToolsFromGame.actions[0].authorization[0].actor = userName;
    cardTransferOutToolsFromGame.actions[0].name = "armorout";
    cardTransferOutToolsFromGame.actions[0].data.to = userName;
    cardTransferOutToolsFromGame.actions[0].data.asset_id = [jew.asset_id];

    try {
      await activeUser
        .signTransaction(cardTransferOutToolsFromGame, {
          blocksBehind: 3,
          expireSeconds: 30,
        })
        .then((response) => {
          if (response.status === "executed") {

            document
              .getElementsByTagName("body")[0]
              .classList.remove("open-modal");
            this.props.addArmorWhenRemove(jew);
            this.props.removeArmorSlot(jew.asset_id);
            loadingSlot.classList.remove("loading");
            this.setState({
              ...this.state,
              modalJew: {
                ...this.state.modalJew,
                openModal: false,
                showAnimModal: false,
                jew: "",
                cost: "",
              },
            });
            if (localStorage.getItem("sound") === "true") {
              this.audioMusicRemove.currentTime = 0;
              this.audioMusicRemove.volume = 0.1;
              this.audioMusicRemove.play();
            }
          }
        });
    } catch (error) {
      //console.log(error.message)
      loadingSlot.classList.remove("loading");
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
        chooseHeroLoading: false,
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  async openNewAdvMap(event, index, onePanda, exp, mult) {
    if (event === "open") {
      document.getElementsByTagName("body")[0].classList.add("open-modal");

      console.log("onePanda", onePanda);
      if (onePanda && onePanda.asset_id) {
        this.props.choosePanda(onePanda.asset_id, "one");
      }

      this.setState({
        ...this.state,
        newAdvMap: {
          ...this.state.newAdvMap,
          modalOpen: true,
          duration: 0,
        },
        sendToAdv: {
          ...this.state.sendToAdv,
          slotIndex: index,
          pandaInfo: onePanda,
          multi: true,
        },
        getReward: {
          ...this.state.getReward,
          exp: exp,
        },
      });

      setTimeout(() => {
        this.setState({
          ...this.state,
          newAdvMap: {
            ...this.state.newAdvMap,
            anim: true,
          },
        });
      }, 100);

      setTimeout(() => {
        this.setState({
          ...this.state,
          newAdvMap: {
            ...this.state.newAdvMap,
            animCloud: true,
          },
        });
      }, 500);
    } else {
      var activePandasId = [];
      this.props.pandaSlotOnGameWax.find((x) => {
        if (x.activePanda === true) {
          activePandasId.push(x.asset_id);
          //console.log("activePandasId", activePandasId);
        }
      });
      if (index != "no-dispach") {
        this.props.removePandaAdvEnergy(
          activePandasId,
          this.state.sendToAdv.type,
          this.state.newAdvMap.duration
        );
      }

      document.getElementsByTagName("body")[0].classList.remove("open-modal");

      this.setState({
        ...this.state,
        newAdvMap: {
          ...this.state.newAdvMap,
          modalOpen: false,
          anim: false,
          animCloud: false,
          showModalInside: false,
          duration: 0,
        },
        sendToAdv: {
          ...this.state.sendToAdv,
          slotIndex: "",
          pandaInfo: null,
          modalOpen: false,
          //type: "",
          multi: false,
          multiResult: [],
        },
        getReward: {
          ...this.state.getReward,
          anim: false,
          modalOpen: false,
          exp: true,
        },
        rewardModalText: {
          title: "",
          text: "",
          finalNumber: "",
          weaponPay: "",
          jewPay: "",
          animationNumber: "",
        },
        openMultiSt: false,
        multiResult: [],
        modalDetalRewOpen: false,
        modalDetalRewOpenAnim: false,
      });
      this.props.choosePanda(0, "remove", onePanda, index); // index - duration, onePanda - type ///// 'close', props.duration, props.typeReward
    }
  }

  showCloseToAdv(method, type) {
    //document.getElementsByTagName('body')[0].classList.add("open-modal");
    if (method === "open") {
      this.setState({
        ...this.state,
        sendToAdv: {
          ...this.state.sendToAdv,
          modalOpen: true,
          type: type,
        },
        newAdvMap: {
          ...this.state.newAdvMap,
          showModalInside: true,
        },
      });

      setTimeout(() => {
        this.setState({
          ...this.state,
          sendToAdv: {
            ...this.state.sendToAdv,
            anim: true,
          },
        });
      }, 100);

      if (localStorage.getItem("sound") === "true") {
        this.audioOpen.currentTime = 0;
        this.audioOpen.volume = 0.1;
        this.audioOpen.play();
      }
    } else {
      //document.getElementsByTagName('body')[0].classList.remove("open-modal");
      this.setState({
        ...this.state,
        sendToAdv: {
          ...this.state.sendToAdv,
          modalOpen: false,
          type: "",
        },
        newAdvMap: {
          ...this.state.newAdvMap,
          showModalInside: false,
        },
        getReward: {
          ...this.state.getReward,
          anim: false,
          modalOpen: false,
        },
        selectAllBt: false,
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioClose.currentTime = 0;
        this.audioClose.volume = 0.1;
        this.audioClose.play();
      }
    }
  }

  showCloseReward(method) {
    //document.getElementsByTagName('body')[0].classList.add("open-modal");
    console.log("showCloseReward");
    if (method === "open") {
      this.setState({
        ...this.state,
        getReward: {
          ...this.state.getReward,
          modalOpen: true,
        },
        sendToAdv: {
          ...this.state.sendToAdv,
          anim: false,
          modalOpen: false,
        },
      });

      setTimeout(() => {
        this.setState({
          ...this.state,
          getReward: {
            ...this.state.getReward,
            anim: true,
          },
        });
      }, 500);

      if (localStorage.getItem("sound") === "true") {
        this.audioOpen.currentTime = 0;
        this.audioOpen.volume = 0.1;
        this.audioOpen.play();
      }
    } else {
      //document.getElementsByTagName('body')[0].classList.remove("open-modal");
      this.setState({
        ...this.state,
        getReward: {
          ...this.state.getReward,
          modalOpen: false,
        },
        sendToAdv: {
          ...this.state.sendToAdv,
          anim: true,
        },
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioClose.currentTime = 0;
        this.audioClose.volume = 0.1;
        this.audioClose.play();
      }
    }
  }

  showModalDetalRew(method) {
    //document.getElementsByTagName('body')[0].classList.add("open-modal");
    console.log("showModalDetalRew");
    if (method === "open") {
      this.setState({
        ...this.state,
        getReward: {
          ...this.state.getReward,
          modalOpen: false,
          anim: false,
        },
        modalDetalRewOpen: true,
      });

      setTimeout(() => {
        this.setState({
          ...this.state,
          modalDetalRewOpenAnim: true,
        });
      }, 500);

      if (localStorage.getItem("sound") === "true") {
        this.audioOpen.currentTime = 0;
        this.audioOpen.volume = 0.1;
        this.audioOpen.play();
      }
    } /* else {
            //document.getElementsByTagName('body')[0].classList.remove("open-modal");
            this.setState({
                ...this.state,
                getReward: {
                    ...this.state.getReward,
                    modalOpen: false,
                },
                sendToAdv: {
                    ...this.state.sendToAdv,
                    anim: true
                }
            });
            if(localStorage.getItem('sound') === 'true') {
                this.audioClose.currentTime = 0;
                this.audioClose.volume = 0.1;
                this.audioClose.play();
            }
        }*/
  }

  openMulti() {
    if (this.state.openMultiSt) {
      this.setState({
        ...this.state,
        openMultiSt: false,
      });
    } else {
      this.setState({
        ...this.state,
        openMultiSt: true,
      });
    }

    if (localStorage.getItem("sound") === "true") {
      this.audioOpen.currentTime = 0;
      this.audioOpen.volume = 0.1;
      this.audioOpen.play();
    }
  }

  activeSlot(pandaID) {

    console.log("pandaID click", pandaID)
    this.setState({
      ...this.state,
      selectAllBt: false,
    });
    this.props.choosePanda(pandaID, "one");

    if (localStorage.getItem("sound") === "true") {
      this.audioOpen.currentTime = 0;
      this.audioOpen.volume = 0.1;
      this.audioOpen.play();
    }
  }

  selectAll(a, type, duration) {
    if (a == "onlybest") {
      this.setState({
        ...this.state,
        selectAllBt: true,
      });
    }
    this.props.choosePanda(0, a, type, duration);

    if (localStorage.getItem("sound") === "true") {
      this.audioOpen.currentTime = 0;
      this.audioOpen.volume = 0.1;
      this.audioOpen.play();
    }
  }

  removeActiveAllBest() {
    this.setState({
      ...this.state,
      selectAllBt: false,
    });
  }

  async closeThenk() {
    document.getElementsByTagName("body")[0].classList.remove("open-modal");
    this.setState({
      ...this.state,
      showModalMultiEat: false,
      showModalMultiWeapon: false,
      showModalMultiJew: false,
      showModalMultiSum: 0,
      showModalMultiType: "",
      modalMultiThank: {
        open: false,
        title: "",
        descr: "",
      },
    });
    if (localStorage.getItem("sound") === "true") {
      this.audioClose.currentTime = 0;
      this.audioClose.volume = 0.1;
      this.audioClose.play();
    }
  }

  openCloseSkills(e, skills) {
    console.log("click");
    if (e) {
      this.setState({
        ...this.state,
        openSkills: true,
        acivePandaSkills: skills,
      });
      document.getElementsByTagName("body")[0].classList.add("open-modal");
    } else {
      this.setState({
        ...this.state,
        openSkills: false,
        acivePandaSkills: "",
      });
      document.getElementsByTagName("body")[0].classList.remove("open-modal");
    }
  }



  render() {
    return (
      <>
        {/* <div className="aaa" onClick={() => this.onFormSubmit()}>
          <br /><br /><br /><br /><br /><br /><br /><br /><br />1111111111111111111111111111111111111111111111111
        </div> */}

        <Menu  {...this.props.ual} wax={this.props.balanceWax} bam={this.props.balanceBam} game={this.props.balanceGame} updateBalance={this.props.updateBalance} />

        {localStorage.getItem("wasLogin") === "false" ? (
          <main className="slot-page original classic">
            <section id="list-all-panda">
              <div className="bamb-tree">
                <div className="bamb-tree-in">
                  <div className="bamb-tree-top"></div>
                  <div className="bamb-tree-bot"></div>

                  <div className="bamb-tree-wrap">
                    <div className="top-card-list">
                      <div className="you-have-no-panda">
                        <div className="tit-list">
                          To start your adventure, you must register, you can do
                          this on the page my heroes
                        </div>
                        <div className="begin-adv">
                          <NavLink className="def-bt mid" to="/my-heroes">
                            <span className="button-in">
                              <span className="button-name">
                                <span className="button-name-in">
                                  <span className="button-name-value">
                                    my heroes
                                  </span>
                                </span>
                              </span>
                            </span>
                          </NavLink>
                          <div id="btn-transfer"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        ) : (
          <>
            {this.state.hasError ? (
              <ErrorComponent
                errorMessage={this.state.errorText}
                clearError={this.clearError}
              />
            ) : null}

            {this.state.hasStakeErrorInfo.hasStakeError ? (
              <ErrorStakeComponent
                hasStakeErrorInfo={this.state.hasStakeErrorInfo}
                handleCloseModalStakeMore={this.handleCloseModalStakeMore}
              />
            ) : null}

            {this.state.hasModalErrorInfo.hasModalError ||
              this.state.hasModalErrorInfo2.hasModalError ||
              this.state.hasModalErrorInfo3.hasModalError ? (
              <ErrorModal
                hasModalErrorInfo={this.state.hasModalErrorInfo}
                hasModalErrorInfo2={this.state.hasModalErrorInfo2}
                hasModalErrorInfo3={this.state.hasModalErrorInfo3}
                handleCloseModalError={this.handleCloseModalError}
              />
            ) : null}

            {this.state.openSkills && (
              <Skills
                openCloseSkills={this.openCloseSkills}
                acivePandaSkills={this.state.acivePandaSkills}
              />
            )}



            {this.props.allWeapon && this.state.modalAllWeapon.openModal ? (
              <ModalAllWeapon
                modalAllWeapon={this.state.modalAllWeapon}
                showCloseAllWeaponModal={this.showCloseAllWeaponModal}
                allWeapon={this.props.allWeapon}
                sendWeapon={this.sendWeapon}
              />
            ) : null}

            {this.state.modalWeapon.openModal ? (
              <ModalOneWeapon
                modalWeapon={this.state.modalWeapon}
                showCloseModal={this.showCloseModal}
                sendOutWeapon={this.sendOutWeapon}
                addEnergyWeapon={this.addEnergyWeapon}
                addEnergyWeaponByVoucher={this.addEnergyWeaponByVoucher}
              />
            ) : null}

            {this.state.modalJew.openModal ? (
              <ModalOneJew
                modalJew={this.state.modalJew}
                showCloseModalJew={this.showCloseModalJew}
                sendOutJew={this.sendOutJew}
                sendOutArmor={this.sendOutArmor}
                addEnergyJew={this.addEnergyJew}
              />
            ) : null}

            {(this.props.allJew && this.state.modalAllJew.openModal) ||
              (this.props.allArmor && this.state.modalAllArmor.openModal) ? (
              <ModalAllJew
                modalAllJew={this.state.modalAllJew}
                showCloseAllJewModal={this.showCloseAllJewModal}
                allJew={this.props.allJew}
                sendJew={this.sendJew}
                modalAllArmor={this.state.modalAllArmor}
                showCloseAllArmorModal={this.showCloseAllArmorModal}
                allArmor={this.props.allArmor}
                sendArmor={this.sendArmor}
              />
            ) : null}

            {this.state.newAdvMap.modalOpen ? (
              <Map
                newAdvMap={this.state.newAdvMap}
                showCloseToAdv={this.showCloseToAdv}
                openNewAdvMap={this.openNewAdvMap}
              />
            ) : null}

            {this.state.sendToAdv.modalOpen ? (
              <SendToAdv
                sendToAdv={this.state.sendToAdv}
                showCloseToAdv={this.showCloseToAdv}
                showCloseReward={this.showCloseReward}
                //sendPandaToAdventures={this.sendPandaToAdventures}
                sendPandaToRes={this.sendPandaToRes}
                sendPandaToStones={this.sendPandaToStones}
                getReward={this.state.getReward}
                pandaSlots={this.props.pandaSlotOnGameWax}
                userSlots={this.props.userSlots}
                activeSlot={this.activeSlot}
                activeSlotArr={this.state.activeSlotSt}
                sendPandaToAdventuresMultArray={
                  this.sendPandaToAdventuresMultArray
                }
                selectAll={this.selectAll}
                selectAllBt={this.state.selectAllBt}
                removeActiveAllBest={this.removeActiveAllBest}
              />
            ) : null}

            {this.state.getReward.modalOpen ? (
              <GetReward
                getReward={this.state.getReward}
                showCloseReward={this.showCloseReward}
                openNewAdvMap={this.openNewAdvMap}
                typeReward={this.state.sendToAdv.type}
                rewardModalText={this.state.rewardModalText}
                multi={this.state.sendToAdv.multi}
                multiResult={this.state.multiResult}
                pandaSlots={this.props.pandaSlotOnGameWax}
                resultRew={this.state.resultRew}
                showModalDetalRew={this.showModalDetalRew}
                duration={this.state.newAdvMap.duration}
                updateBalance={this.props.updateBalance}
                allMedalInGame={this.state.allMedalInGame}
                user={this.state.user}
              />
            ) : null}

            {this.state.modalDetalRewOpen ? (
              <DetalMultInfo
                getReward={this.state.getReward}
                showCloseReward={this.showCloseReward}
                openNewAdvMap={this.openNewAdvMap}
                typeReward={this.state.sendToAdv.type}
                rewardModalText={this.state.rewardModalText}
                multi={this.state.sendToAdv.multi}
                multiResult={this.state.multiResult}
                pandaSlots={this.props.pandaSlotOnGameWax}
                resultRew={this.state.resultRew}
                story={this.state.story}
                modalDetalRewOpen={this.state.modalDetalRewOpen}
                modalDetalRewOpenAnim={this.state.modalDetalRewOpen}
                storyRes={this.state.storyRes}
                duration={this.state.newAdvMap.duration}
                user={this.state.user}
              />
            ) : null}

            {this.state.showModalMultiEat ? <ModalMultiEat
              modal={this.state.modal}
              showModalMultiEat={this.state.showModalMultiEat}
              closeThenk={this.closeThenk}
              made={this.state.made}
              addEnergyMultiTrans={this.addEnergyMultiTrans}
              problemWithEat={this.state.problemWithEat}
              allEat={this.props.alleat}
            /> : null}

            {this.state.showModalMultiWeapon ? <ModalMultiWeapon
              modal={this.state.modal}
              showModalMultiWeapon={this.state.showModalMultiWeapon}
              showModalMultiSum={this.state.showModalMultiSum}
              showModalMultiType={this.state.showModalMultiType}
              closeThenk={this.closeThenk}
              addEnergyMultiWeaponTrans={this.addEnergyMultiWeaponTrans}
            /> : null}

            {this.state.showModalMultiJew ? <ModalMultiJew
              modal={this.state.modal}
              showModalMultiJew={this.state.showModalMultiJew}
              showModalMultiSum={this.state.showModalMultiSum}
              closeThenk={this.closeThenk}
              addEnergyMultiJewTrans={this.addEnergyMultiJewTrans}
            /> : null}

            {this.state.modalMultiThank.open && (
              <ThankMulti
                modalMultiThank={this.state.modalMultiThank}
                closeThenk={this.closeThenk}
              />
            )}


            {
              this.state.wasLoadPandaOnGame &&
                this.props.wasLoadPandas &&
                this.props.wasLoadWeapon &&
                this.props.wasLoadJew &&
                this.props.pandaSlotOnGameWax /*&& this.props.pandaSlotOnGameWax.length*/ ? (
                <AdventuresNew
                  onFormSubmit={this.onFormSubmit}
                  slotNFT={this.props.pandaSlotOnGame}
                  pandaListOnGame={this.props.pandaListOnGame}
                  //cardOnGame={this.state.cardOnGame}
                  openModal={this.openModal}
                  openModalEat={this.openModalEat}
                  openModalBySlot={this.openModalBySlot}
                  openBuySlot={this.state.openBuySlot}
                  openedModal={this.state.openedModal}
                  onHandleSendPandaToSlot={this.onHandleSendPandaToSlot}
                  onHandleSendPandaFromSlot={this.onHandleSendPandaFromSlot}
                  //sendPandaToAdventures={this.sendPandaToAdventures}
                  handleClose={this.handleClose}
                  openModalChooseHero={this.state.openModalChooseHero}
                  baySlot={this.baySlot}
                  usersRow={this.props.usersRow}
                  handleUpdate={this.handleUpdate}
                  getModalRewardInfo={this.getModalRewardInfo}
                  rewardModalText={this.state.rewardModalText}
                  stakePandaModal={this.stakePandaModal}
                  stakeModalOpen={this.state.stakeModalOpen}
                  stakeInfo={this.state.stakeInfo}
                  stakeBamNft={this.stakeBamNft}
                  input={this.input}
                  handleChangeInput={this.handleChangeInput}
                  unstakeOpenModal={this.unstakeOpenModal}
                  handleCloseUnstake={this.handleCloseUnstake}
                  unstakeBamNft={this.unstakeBamNft}
                  handleChangeInputUn={this.handleChangeInputUn}
                  lvlUpPanda={this.lvlUpPanda}
                  errorStake={this.errorStake}
                  resultRew={this.state.resultRew}
                  chooseHeroLoading={this.state.chooseHeroLoading}
                  balanceBam={this.props.balanceBam}
                  modalEat={this.state.modalEat}
                  addEnergy={this.addEnergy}
                  closeModalEat={this.closeModalEat}
                  alleat={this.props.alleat}
                  addNumToStake={this.addNumToStake}
                  openModalError={this.openModalError}
                  rewardModalOpen={this.state.rewardModalOpen}
                  showCloseModal={this.showCloseModal}
                  showCloseModalJew={this.showCloseModalJew}
                  showCloseAllWeaponModal={this.showCloseAllWeaponModal}
                  showCloseAllJewModal={this.showCloseAllJewModal}
                  openNewAdvMap={this.openNewAdvMap}
                  activeSlot={this.activeSlot}
                  activeSlotSt={this.state.activeSlotSt}
                  openMulti={this.openMulti}
                  openMultiSt={this.state.openMultiSt}
                  pandaInSlot={this.state.pandaInSlot}
                  selectAll={this.selectAll}
                  addEnergyMulti={this.addEnergyMulti}
                  addEnergyMultiWeapon={this.addEnergyMultiWeapon}
                  addEnergyMultiJew={this.addEnergyMultiJew}
                  pandaSlots={this.props.pandaSlotOnGameWax}
                  //weaponSlots={this.props.pandaSlotWeaponWax}
                  //jewSlots={this.props.pandaSlotJewWax}
                  userSlots={this.props.userSlots}
                  openCloseSkills={this.openCloseSkills}
                  allUserInfo={this.props.allUserInfo}
                />
              ) : (
                <main className="slot-page classic"></main>
              )}
          </>
        )}
      </>
    );
  }
}

let mapStateToProps = (state) => ({
  pandaSlotOnGame: state.slotReducer.pandaSlotOnGame,
  pandaListOnGame: state.slotReducer.pandaListOnGame,
  usersRow: state.slotReducer.usersRow,
  //alleat: state.slotReducer.alleat,
  statuseat: state.showReduser.statuseat,
  alleat: state.showReduser.alleat,
  wasbay: state.showReduser.wasbay,
  allWeapon: state.weaponReduser.allWeapon,
  statusWeapon: state.weaponReduser.statusWeapon,
  pandaWeapon: state.weaponReduser.pandaWeapon,
  allJew: state.jewReduser.allJew,
  allArmor: state.armorReduser.allArmor,
  statusJew: state.jewReduser.statusJew,
  pandaJew: state.jewReduser.pandaJew,
  statusArmor: state.armorReduser.statusArmor,
  pandaArmor: state.armorReduser.pandaArmor,
  pandaSlotOnGameWax: state.slotReducer.pandaSlotOnGameWax,
  //pandaSlotWeaponWax: state.slotReducer.pandaSlotWeaponWax,
  //pandaSlotJewWax: state.slotReducer.pandaSlotJewWax,
  wasLoadPandas: state.slotReducer.wasLoadPandas,
  wasLoadWeapon: state.slotReducer.wasLoadWeapon,
  wasLoadJew: state.slotReducer.wasLoadJew,
  wasLoadArmor: state.slotReducer.wasLoadArmor,
  userSlots: state.slotReducer.userSlots,
});

export default connect(mapStateToProps, {
  setSlotPandaOnGame,
  setPandaOnGame,
  toggleIsFetchingTop,
  doDelFromStates,
  addToSlotState,
  doDelFromSlot,
  setUsersRow,
  addSlot /*setTimerToState,*/,
  setTimerEnd,
  removePandaAdv,
  removePandaAdvEnergy,
  changeStakeSum,
  changeStakeSumMinus,
  lvlUp,
  eat,
  eatremove,
  eatlist,
  eatlistclear,
  wasLoadinEat,
  wasBuyingEat,
  weaponlist,
  statusLoadinWeapon,
  removeWeaponSlot,
  addWeaponWhenRemove,
  addWeaponToPanda,
  addWeaponToSlot,
  setPandaWeapon,
  addWeaponEnergy,
  jewlist,
  statusLoadinJew,
  removeJewSlot,
  addJewWhenRemove,
  addJewToPanda,
  addJewToSlot,
  setPandaJew,
  addJewEnergy,
  setPandaOnGameWax,
  setWeaponOnGameWax,
  setJewOnGameWax,
  setArmorOnGameWax,
  setWasLoadPandas,
  setWasLoadWeapon,
  setWasLoadJew,
  setWasLoadArmor,
  choosePanda,
  setUserSlots,
  armorlist,
  statusLoadinArmor,
  removeArmorSlot,
  addArmorWhenRemove,
  addArmorToPanda,
  addArmorToSlot,
  setPandaArmor,
  addArmorEnergy,
})(AdventuresComponent);
