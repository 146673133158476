import React, { useState, useEffect, useRef } from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  Link,
  NavLink,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import LKAlien from "./LKAlien";

import { JsonRpc } from "eosjs";
import { ExplorerApi } from "atomicassets";
import { split } from "lodash";
const api = new ExplorerApi(
  "https://" + localStorage.getItem('rpcA'),
  "atomicassets",
  { fetch, rateLimit: 4 }
);

const LKAlienComponent = (props) => {
  let { id } = useParams();


  useEffect(() => {
  }, [id]);

  return (
    <>
      <LKAlien id={id} {...props} />
    </>
  );
};

export default LKAlienComponent;
