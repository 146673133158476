import React, { useState, useEffect } from 'react';
import ResultDetal from "./ResultDetal";
import "./alienAdvStyle.scss";
import {
    /*BrowserRouter, Redirect, Route, Switch,*/ Link,
    NavLink,
} from "react-router-dom";

import rewtop from '../../images/alien/result-top.png';
import rewtopw from '../../images/alien/result-top.webp';
import rewbot from '../../images/new-adv/multi-reward-bot.png';
import rewbotw from '../../images/new-adv/multi-reward-bot.webp';

import noPotion from '../../images/shop/potions/space-power-no-potion.jpg';
import noPotionw from '../../images/shop/potions/space-power-no-potion.webp';
import bigPotion from '../../images/shop/potions/space-power-potion.jpg';
import bigPotionw from '../../images/shop/potions/space-power-potion.webp';
import minPotion from '../../images/shop/potions/small-space-power-potion.jpg';
import minPotionw from '../../images/shop/potions/small-space-power-potion.webp';

const GoToAdvModal = (props) => {

    return (<>
        <div className={`modal-to-adv reward-window ${false ? "" : "open"} anim alien-rew`}>
            <div className="modal-to-adv-body">
                <div className={`modal-to-adv-body-in multi-rew`}>
                    <div className={`modal-go-to-adv`}>
                        <div className="mdl-gtav-top">
                            <picture>
                                <source srcSet={rewtopw} type="image/webp" />
                                <source srcSet={rewtop} type="image/png" />
                                <img src={rewtop} alt="" />
                            </picture>
                            <div className="close-mult-rew" onClick={() => props.openSendToAdvModal(false)}>
                                <span></span>
                            </div>
                        </div>

                        <div className="mdl-gtav-mid">
                            <div className="mdl-gtav-mid-in">
                                {/* one block */}
                                <div className="one-adv-bl">
                                    <div className="one-adv-bl-thumb ">
                                        <span>
                                            <picture>
                                                <source srcSet={noPotionw} type="image/webp" />
                                                <source srcSet={noPotion} type="image/png" />
                                                <img src={noPotionw} alt="" />
                                            </picture>

                                        </span>

                                    </div>
                                    <div className="one-adv-bl-descr">
                                        <div className="one-adv-bl-tit">
                                            without potion
                                        </div>
                                        <div className="one-adv-bl-bt">
                                            <div className="bt-alien first" onClick={() => props.sendToAdv(0, 0, "all", 4)}>
                                                send all <br />to 4 hours
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* one block */}

                                {/* one block */}
                                <div className="one-adv-bl">
                                    <div className={`one-adv-bl-thumb ${props.allAlienRes.res[2] === 0 && "no-potion"}`}>
                                        <span>
                                            <picture>
                                                <source srcSet={minPotionw} type="image/webp" />
                                                <source srcSet={minPotion} type="image/png" />
                                                <img src={minPotionw} alt="" />
                                            </picture>

                                            <div className="num-potions">
                                                {props.allAlienRes.res[2]}<i>X</i>
                                            </div>
                                        </span>
                                        <div className="buy-potions">
                                            <NavLink
                                                className="def-bt mini"
                                                to="/shop"
                                            >

                                                <span className="button-in">
                                                    <span className="button-name">
                                                        <span className="button-name-in">
                                                            <span className="button-name-value">
                                                                buy
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className="one-adv-bl-descr">
                                        <div className="one-adv-bl-tit">
                                            Potion for 8 Hours
                                        </div>
                                        <div className="one-adv-bl-bt">
                                            {props.allAlienRes.res[2] !== 0 ?
                                                <div className="bt-alien first" onClick={() => props.sendToAdv(0, 0, "all", 8)}>
                                                    send all <br />to 8 hours
                                                </div>
                                                :
                                                <div className="bt-alien first disabled">
                                                    send all <br />to 8 hours
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* one block */}

                                {/* one block */}
                                <div className="one-adv-bl">
                                    <div className={`one-adv-bl-thumb ${props.allAlienRes.res[3] === 0 && "no-potion"}`}>
                                        <span>
                                            <picture>
                                                <source srcSet={bigPotionw} type="image/webp" />
                                                <source srcSet={bigPotion} type="image/png" />
                                                <img src={bigPotionw} alt="" />
                                            </picture>

                                            <div className="num-potions">
                                                {props.allAlienRes.res[3]}<i>X</i>
                                            </div>
                                        </span>
                                        <div className="buy-potions">
                                            <NavLink
                                                className="def-bt mini"
                                                to="/shop"
                                            >

                                                <span className="button-in">
                                                    <span className="button-name">
                                                        <span className="button-name-in">
                                                            <span className="button-name-value">
                                                                buy
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className="one-adv-bl-descr">
                                        <div className="one-adv-bl-tit">
                                            Potion for 12 Hours
                                        </div>
                                        <div className="one-adv-bl-bt">

                                            {props.allAlienRes.res[3] !== 0 ?
                                                <div className="bt-alien first" onClick={() => props.sendToAdv(0, 0, "all", 12)}>
                                                    send all <br />to 12 hours
                                                </div>
                                                :
                                                <div className="bt-alien first disabled">
                                                    send all <br />to 12 hours
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* one block */}
                            </div>
                        </div>

                        <div className="mdl-gtav-bot">
                            <picture>
                                <source srcSet={rewbotw} type="image/webp" />
                                <source srcSet={rewbot} type="image/png" />
                                <img src={rewbot} alt="" />
                            </picture>
                        </div>


                    </div>


                </div>
                <div className="modal-weapon-bg-trans"></div>
            </div>

            <div className="modal-weapon-bg" ></div>
        </div>
    </>);

}

export default GoToAdvModal;