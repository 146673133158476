const SET_SLOT_PANDA_ON_GAME = 'SET_SLOT_PANDA_ON_GAME';
const SET_PANDA_ON_GAME = 'SET_PANDA_ON_GAME';
const TOGGLE_IS_FETCHING_TOP = 'TOGGLE_IS_FETCHING_TOP';
const REMOVE_FROM_STATES = 'REMOVE_FROM_STATES';
const ADD_TO_SLOT_STATE = 'ADD_TO_SLOT_STATE';
const REMOVE_FROM_SLOT = 'REMOVE_FROM_SLOT';
const SET_USERS_ROW = 'SET_USERS_ROW';
const ADD_SLOT = 'ADD_SLOT';
//const ADD_TIMER = 'ADD_TIMER';
const REMOVE_SLOT = 'REMOVE_SLOT';
const REMOVE_PANDA_ADV = 'REMOVE_PANDA_ADV';
const REMOVE_PANDA_ADV_ENERGY = 'REMOVE_PANDA_ADV_ENERGY';
const CHANGE_STAKE_SUM = 'CHANGE_STAKE_SUM';
const CHANGE_STAKE_SUM_MINUS = 'CHANGE_STAKE_SUM_MINUS';
const LVL_UP = 'LVL_UP';
const EAT = 'EAT';
const REMOVE_WEAPON_SLOT = 'REMOVE_WEAPON_SLOT';
const ADD_WEAPON_TO_SLOT = 'ADD_WEAPON_TO_SLOT';
const REMOVE_JEW_SLOT = 'REMOVE_JEW_SLOT';
const ADD_JEW_TO_SLOT = 'ADD_JEW_TO_SLOT';
const REMOVE_ARMOR_SLOT = 'REMOVE_ARMOR_SLOT';
const ADD_ARMOR_TO_SLOT = 'ADD_ARMOR_TO_SLOT';
//const EATLIST = 'EATLIST';
const EATLISTCLEAR = 'EATLISTCLEAR';
const ADD_ENERGY = 'ADD_ENERGY';
const ADD_ENERGY_JEW = 'ADD_ENERGY_JEW';
const ADD_ENERGY_ARMOR = 'ADD_ENERGY_ARMOR';
//const WAS_LOADING_EAT = 'WAS_LOADING_EAT';

const SET_PANDA_ON_GAME_WAX = 'SET_PANDA_ON_GAME_WAX';
const SET_WEAPON_ON_GAME_WAX = 'SET_WEAPON_ON_GAME_WAX';
const SET_JEW_ON_GAME_WAX = 'SET_JEW_ON_GAME_WAX';
const SET_ARMOR_ON_GAME_WAX = 'SET_ARMOR_ON_GAME_WAX';

const SET_WAS_LOAD_PANDAS = 'SET_WAS_LOAD_PANDAS';
const SET_WAS_LOAD_WEAPON = 'SET_WAS_LOAD_WEAPON';
const SET_WAS_LOAD_JEW = 'SET_WAS_LOAD_JEW';
const SET_WAS_LOAD_ARMOR = 'SET_WAS_LOAD_ARMOR';

const CHOOSE_PANDA = 'CHOOSE_PANDA';
const SET_USER_SLOTS = 'SET_USER_SLOTS';

let initialState = {
    pandaSlotOnGame: [],
    pandaListOnGame: [],
    isFetching: true,
    usersRow: null,
    //alleat: null,
    //statuseat: false
    pandaSlotOnGameWax: [],
    // pandaSlotWeaponWax: [],
    // pandaSlotJewWax: [],
    wasLoadPandas: false,
    wasLoadWeapon: false,
    wasLoadJew: false,
    wasLoadArmor: false,
    userSlots: []
};

const slotReducer = (state = initialState, action) => {

    switch (action.type) {
        // remove
        case SET_USER_SLOTS: {
            return { ...state, userSlots: action.pandas }
        }
        case SET_SLOT_PANDA_ON_GAME: {
            return { ...state, pandaSlotOnGame: action.pandaSlotOnGame }
        }
        // remove
        case SET_PANDA_ON_GAME: {
            return { ...state, pandaListOnGame: action.pandaListOnGame }
        }
        case TOGGLE_IS_FETCHING_TOP: {
            return { ...state, isFetching: action.isFetching }
        }
        case REMOVE_FROM_STATES: {
            //console.log("action.removePandaFromState", action.removePandaFromState);
            //var copyUserSlots = [...state.userSlots];

            var slotPanda = state.userSlots[action.data.indexPanda];
            var copyUserSlots = [...state.userSlots];
            //console.log("first copyUserSlots", state.userSlots);
            //copyUserSlots[action.data.numberSlot] = action.data.removePandaFromState.asset_id;
            console.log("action.data", action.data);

            if (copyUserSlots[action.data.indexPanda]) {

                copyUserSlots[action.data.indexPanda] = action.data.removePandaFromState.asset_id;

                var copyWaxState = [...state.pandaSlotOnGameWax];
                var stateListCopy = [...state.pandaListOnGame]
                var GoToState;
                var GoToSlot;

                state.pandaSlotOnGameWax.map((item) => {
                    if (item.asset_id === slotPanda) {
                        GoToState = item;
                    }
                })
                var copyPanda = Object.assign({}, GoToState);
                copyPanda.is_in_slot = 0;

                //console.log("copyPanda", copyPanda)

                stateListCopy = stateListCopy.filter(n => n.asset_id !== action.data.removePandaFromState.asset_id).map(u => { return u; })

                //console.log("stateListCopy", stateListCopy)

                state.pandaListOnGame.map((item) => {
                    if (item.asset_id === action.data.removePandaFromState.asset_id) {
                        GoToSlot = item;
                    }
                })
                var copyGoToSlot = Object.assign({}, GoToSlot);
                copyGoToSlot.is_in_slot = 1;

                //console.log("copyGoToSlot", copyGoToSlot);

                copyWaxState = copyWaxState.filter(n => n.asset_id !== slotPanda).map(u => {
                    return u;
                })

                //console.log("copyWaxState filter", copyWaxState);


                return {
                    ...state,
                    pandaSlotOnGameWax: [copyGoToSlot].concat(copyWaxState),
                    //userSlots: copyUserSlots,
                    pandaListOnGame: [copyPanda].concat(stateListCopy),
                    userSlots: copyUserSlots,
                }

            } else {
                var copyWaxState = [...state.pandaSlotOnGameWax];
                var GoToSlot;

                state.pandaListOnGame.map((item) => {
                    if (item.asset_id === action.data.removePandaFromState.asset_id) {
                        GoToSlot = item;
                    }
                })
                var copyGoToSlot = Object.assign({}, GoToSlot);
                copyGoToSlot.is_in_slot = 1;
                copyUserSlots[action.data.indexPanda] = action.data.removePandaFromState.asset_id;

                return {
                    ...state, pandaListOnGame: state.pandaListOnGame.filter(n => n.asset_id !== action.data.removePandaFromState.asset_id).map(u => {
                        return u;
                    }),
                    pandaSlotOnGameWax: [copyGoToSlot].concat(copyWaxState),
                    userSlots: copyUserSlots,
                }
            }
        }
        // case ADD_TO_SLOT_STATE: { // меняем одну панду на другую, проверяем если есть в слоте панда то меняем стейт

        //     /*var copyUserSlots = [...state.userSlots];

        //     copyUserSlots[action.data.numberSlot] = action.data.panda.asset_id;
        //     var concats = [...state.pandaSlotOnGameWax];

        //     concats.filter(n => n.asset_id !== slotPanda).map( u =>  {return u;})

        //     concats.push(action.data.panda);
        //     return {
        //     ...state,
        //         pandaSlotOnGameWax: concats,
        //         userSlots: copyUserSlots,
        //     }*/


        //     /*if(state.pandaSlotOnGame[action.data.addNftToSlot] === undefined) {
        //         return {
        //             ...state,
        //             pandaSlotOnGame: state.pandaSlotOnGame.map( (u, index) =>  {
        //                 if (index === action.data.addNftToSlot ) {
        //                     return [action.data.addNft, action.data.steps, 0, 0, 0, action.data.stakeCount, action.data.lvl, action.data.allinfo, action.data.energy, 0]// asset arrey, spets, timer, panda way, stake, stake count, lvl
        //                 }
        //                 return u;
        //             })
        //         }
        //     } else { // если панда уже есть в слоту
        //         return {
        //             ...state,
        //             pandaListOnGame: [state.pandaSlotOnGame[action.data.addNftToSlot][7]].concat(state.pandaListOnGame),
        //             pandaSlotOnGame: state.pandaSlotOnGame.map( (u, index) =>  {
        //                 if (index === action.data.addNftToSlot ) {
        //                     return [action.data.addNft, action.data.steps, u[2], u[3], u[4], action.data.stakeCount, action.data.lvl, action.data.allinfo, u[8], u[9], u[10]]// asset arrey, spets, timer, panda way, stake, stake count, lvl
        //                 }
        //                 return u;
        //             })
        //         }
        //     }*/

        // }
        case REMOVE_FROM_SLOT: {
            //console.log([action.removePandaFromSlot].concat(state.pandaListOnGame));
            //console.log('action.data.panda1111111111', action.data.panda);
            //console.log("WTF");
            var copyUserSlots = [...state.userSlots];
            copyUserSlots[action.data.indexSlot] = 0;
            return {
                ...state,
                pandaListOnGame: [action.data.panda].concat(state.pandaListOnGame),
                userSlots: copyUserSlots,
                pandaSlotOnGameWax: state.pandaSlotOnGameWax.filter(n => n.asset_id !== action.data.panda.asset_id).map(u => {
                    return u;
                })
            }
        }
        case SET_USERS_ROW: {
            return { ...state, usersRow: action.usersRow }
        }
        case ADD_SLOT: {
            return {
                ...state, usersRow: action.slotNum
            }
        }
        case REMOVE_SLOT: {
            return {
                ...state, pandaSlotOnGameWax: state.pandaSlotOnGameWax.map((item) => {
                    if (item.asset_id === action.removeId) {
                        var copyPanda = Object.assign({}, item);
                        copyPanda.timer = 0;
                        return copyPanda
                    }
                    return item;
                })
            }
        }

        case REMOVE_PANDA_ADV: {
            console.log("action.data.multi", action.data.multi);
            if (action.data.multi) {
                return {
                    ...state,
                    pandaSlotOnGameWax: state.pandaSlotOnGameWax.map((item, index) => {
                        if (action.data.removeId.includes(item.asset_id)) {
                            var copyPanda = Object.assign({}, item);
                            //console.log("copyPanda 1", copyPanda);

                            copyPanda.timer = action.data.timer;


                            return copyPanda;

                        }
                        return item;
                    })
                }
            } else {
                return {
                    ...state,
                    pandaSlotOnGameWax: state.pandaSlotOnGameWax.map((item, index) => {
                        if (item.asset_id == action.data.removeId) {
                            var copyPanda = Object.assign({}, item);
                            //console.log("copyPanda 1", copyPanda);

                            copyPanda.timer = action.data.timer;
                            return copyPanda;

                        }
                        return item;
                    })
                }
            }

        }
        case REMOVE_PANDA_ADV_ENERGY: {
            //console.log("action.data.multi", action.data.multi);
            // if (action.data.multi) {
            //     return {
            //         ...state,
            //         pandaSlotOnGameWax: state.pandaSlotOnGameWax.map((item, index) => {
            //             if (action.data.removeId.includes(item.asset_id)) {
            //                 var copyPanda = Object.assign({}, item);
            //                 //console.log("copyPanda 1", copyPanda);
            //                 var energy = 500;
            //                 if (action.data.duration == 8 && action.data.types == "bamboo" ||
            //                     action.data.duration == 12 && action.data.types != "bamboo") {
            //                     energy = 2000;
            //                 } else if (action.data.duration == 12 && action.data.types == "bamboo") {
            //                     energy = 4000;
            //                 }

            //                 var pandaRar = copyPanda.rarity;
            //                 var weapon = copyPanda.weapon;
            //                 var jew = copyPanda.jew;
            //                 var armor = copyPanda.armor;

            //                 if (weapon != 0 && jew != 0 && armor != 0) {
            //                     // panda  0,1 2 3 4 5 6
            //                     // weapon 0 1 2 3 4 5
            //                     if (pandaRar != 0) {
            //                         pandaRar = pandaRar - 1;
            //                     }
            //                     if (weapon.rarity >= pandaRar && jew.rarity >= pandaRar && armor.rarity >= pandaRar) {
            //                         energy = 400;
            //                         if (action.data.duration == 8 && action.data.types == "bamboo" ||
            //                             action.data.duration == 12 && action.data.types != "bamboo") {
            //                             energy = 1800;
            //                         } else if (action.data.duration == 12 && action.data.types == "bamboo") {
            //                             energy = 3600;
            //                         }
            //                     }
            //                 }

            //                 // console.log("dispach energy", energy);
            //                 // console.log("dispach action.data.duration", action.data.duration);

            //                 if (copyPanda.weapon && copyPanda.weapon.energy >= energy) {
            //                     var copyPandaWeapon = Object.assign({}, copyPanda.weapon);
            //                     copyPandaWeapon.energy = copyPanda.weapon.energy - energy;
            //                     copyPanda.weapon = copyPandaWeapon;
            //                 }

            //                 if (copyPanda.armor && copyPanda.armor.energy >= energy) {
            //                     var copyPandaArmor = Object.assign({}, copyPanda.armor);
            //                     copyPandaArmor.energy = copyPanda.armor.energy - energy;
            //                     copyPanda.armor = copyPandaArmor;
            //                 }

            //                 console.log("action.data.types", action.data.types);

            //                 if (action.data.types == "bamboo" || action.data.types == "books") {
            //                     if (copyPanda.jew && copyPanda.jew.energy >= energy) {
            //                         var copyPandaJew = Object.assign({}, copyPanda.jew);
            //                         //copyPandaJew.energy = copyPanda.jew.energy - energy;
            //                         //copyPanda.jew = copyPandaJew;
            //                     }
            //                 }

            //                 return copyPanda;

            //             }
            //             return item;
            //         })
            //     }
            // } else {

            // console.log("action.data.types", action.data.types);
            // console.log("action.data.removeId", action.data.removeId);
            // console.log("pandaSlotOnGameWax", state.pandaSlotOnGameWax);
            // console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
            return {
                ...state,
                pandaSlotOnGameWax: state.pandaSlotOnGameWax.map((item, index) => {
                    if (action.data.removeId.includes(item.asset_id)) {
                        var copyPanda = Object.assign({}, item);
                        //console.log("copyPanda 1", copyPanda);

                        var energy = 500;
                        if (action.data.duration == 8 && action.data.types == "bamboo" ||
                            action.data.duration == 12 && action.data.types != "bamboo") {
                            energy = 2000;
                        } else if (action.data.duration == 12 && action.data.types == "bamboo") {
                            energy = 4000;
                        }
                        var pandaRar = copyPanda.rarity;
                        var weapon = copyPanda.weapon;
                        var jew = copyPanda.jew;
                        var armor = copyPanda.armor;
                        if (weapon != 0 && jew != 0 && armor != 0) {
                            if (pandaRar != 0) {
                                pandaRar = pandaRar - 1;
                            }
                            if (weapon.rarity >= pandaRar && jew.rarity >= pandaRar && armor.rarity >= pandaRar) {
                                energy = 400;
                                if (action.data.duration == 8 && action.data.types == "bamboo" ||
                                    action.data.duration == 12 && action.data.types != "bamboo") {
                                    energy = 1800;
                                } else if (action.data.duration == 12 && action.data.types == "bamboo") {
                                    energy = 3600;
                                }
                            }
                        }
                        // console.log("dispach2 energy", energy);
                        // console.log("dispach2 action.data.duration", action.data.duration);

                        if (copyPanda.energy >= energy) {
                            copyPanda.energy = copyPanda.energy - energy;
                        }

                        if (copyPanda.weapon && copyPanda.weapon.energy >= energy) {
                            var copyPandaWeapon = Object.assign({}, copyPanda.weapon);
                            copyPandaWeapon.energy = copyPanda.weapon.energy - energy;
                            copyPanda.weapon = copyPandaWeapon;
                        }

                        if (copyPanda.armor && copyPanda.armor.energy >= energy) {
                            var copyPandaArmor = Object.assign({}, copyPanda.armor);
                            copyPandaArmor.energy = copyPanda.armor.energy - energy;
                            copyPanda.armor = copyPandaArmor;
                        }

                        if (action.data.types == "bamboo" || action.data.types == "books") {
                            if (copyPanda.jew && copyPanda.jew.energy >= energy) {
                                var copyPandaJew = Object.assign({}, copyPanda.jew);
                                copyPandaJew.energy = copyPanda.jew.energy - energy;
                                copyPanda.jew = copyPandaJew;

                                console.log("energy", energy);
                            }
                        }

                        return copyPanda;

                    }
                    return item;
                })
                //}
            }

        }
        case CHANGE_STAKE_SUM: {
            return {
                ...state, pandaSlotOnGameWax: state.pandaSlotOnGameWax.map((item) => {
                    if (item.asset_id === action.data.changeId) {
                        var copyPanda = Object.assign({}, item);
                        copyPanda.stake_count = item.stake_count + action.data.moneysum;;
                        return copyPanda
                    }
                    return item;
                })
            }
        }
        case CHANGE_STAKE_SUM_MINUS: {

            return {
                ...state, pandaSlotOnGameWax: state.pandaSlotOnGameWax.map((item) => {
                    if (item.asset_id === action.data.changeId) {
                        var copyPanda = Object.assign({}, item);
                        copyPanda.stake_count = item.stake_count - action.data.moneysum;
                        return copyPanda
                    }
                    return item;
                })
            }
        }
        case LVL_UP: {
            return {
                ...state, pandaSlotOnGameWax: state.pandaSlotOnGameWax.map((item) => {
                    if (item.asset_id === action.pandaId) {
                        var copyPanda = Object.assign({}, item);
                        copyPanda.lvl = item.lvl + 1;
                        copyPanda.steps = 0;
                        return copyPanda
                    }
                    return item;
                })
            }
        }
        case EAT: {

            return {
                ...state,
                pandaSlotOnGameWax: state.pandaSlotOnGameWax.map((item, index) => {
                    if (item.asset_id == action.data.pandaId && !action.data.mult.length) {
                        /*var copyPanda = Object.assign({}, item);
                        console.log("copyPanda 1", copyPanda);*/
                        var foodarr = [];
                        var newenergy = 0;
                        if (action.data.rareat === 0) {
                            foodarr.push(10000, 6060, 4909, 1818, 1091, 273, 91);
                        } else if (action.data.rareat === 1) {
                            foodarr.push(10000, 10000, 10000, 3704, 2222, 556, 185);
                        } else if (action.data.rareat === 2) {
                            foodarr.push(10000, 10000, 10000, 10000, 5999, 1500, 500);
                        } else if (action.data.rareat === 3) {
                            foodarr.push(10000, 10000, 10000, 10000, 10000, 2500, 833);
                        } else if (action.data.rareat === 4) {
                            foodarr.push(10000, 10000, 10000, 10000, 10000, 10000, 3333);
                        } else if (action.data.rareat === 5) {
                            foodarr.push(10000, 10000, 10000, 10000, 10000, 10000, 10000);
                        }

                        if (action.data.rareat > item.rarity) { // проверяем больша ли рарность у еды, и спец проверка по промке
                            newenergy = 10000;
                        } else if (action.data.rareat <= item.rarity) {
                            newenergy = item.energy + foodarr[item.rarity];
                        }

                        if (newenergy >= 10000) {
                            newenergy = 10000;
                        }

                        var pandaCopy = Object.assign({}, item);
                        pandaCopy.energy = newenergy;

                        return pandaCopy
                    } else if (action.data.mult.length && action.data.mult.includes(item.asset_id)) {
                        var pandaCopy = Object.assign({}, item);
                        pandaCopy.energy = 10000;
                        return pandaCopy
                    }
                    return item;

                })
            }
        }
        case REMOVE_WEAPON_SLOT: {
            return {
                ...state, pandaSlotOnGameWax: state.pandaSlotOnGameWax.map((item) => {
                    if (item.weapon && item.weapon.asset_id === action.weaponId) {
                        var copyPanda = Object.assign({}, item);
                        copyPanda.weapon = 0;
                        return copyPanda
                    }
                    return item;
                })
            }
        }
        case ADD_WEAPON_TO_SLOT: {

            var rar = 0;
            if (action.data.weapon.data.rarity == "Uncommon" || action.data.weapon.data.rarity == "1") {
                rar = 1
            } else if (action.data.weapon.data.rarity == "Rare" || action.data.weapon.data.rarity == "2") {
                rar = 2
            } else if (action.data.weapon.data.rarity == "Epic" || action.data.weapon.data.rarity == "3") {
                rar = 3
            } else if (action.data.weapon.data.rarity == "Legendary" || action.data.weapon.data.rarity == "4") {
                rar = 4
            } else if (action.data.weapon.data.rarity == "Mythic" || action.data.weapon.data.rarity == "5") {
                rar = 5
            }

            console.log("action.data.weapon", action.data.weapon);

            var element = (action.data.weapon.data.element).toLowerCase();
            var tmpl = parseInt(action.data.weapon.template.template_id);

            var objAtomic = {
                asset_id: action.data.weapon.asset_id,
                tmpl: tmpl,
                element: element,
                energy: 10000,
                rarity: rar
            }
            return {
                ...state, pandaSlotOnGameWax: state.pandaSlotOnGameWax.map((item) => {
                    if (item.asset_id === action.data.pandaId) {
                        var copyPanda = Object.assign({}, item);
                        copyPanda.weapon = objAtomic;
                        return copyPanda
                    }
                    return item;
                })
            }
        }
        case REMOVE_JEW_SLOT: {
            return {
                ...state, pandaSlotOnGameWax: state.pandaSlotOnGameWax.map((item) => {
                    if (item.jew && item.jew.asset_id === action.jewId) {
                        var copyPanda = Object.assign({}, item);
                        copyPanda.jew = 0;
                        return copyPanda
                    }
                    return item;
                })
            }
        }
        case REMOVE_ARMOR_SLOT: {

            console.log("action.armorId", action.armorId)
            return {
                ...state, pandaSlotOnGameWax: state.pandaSlotOnGameWax.map((item) => {
                    if (item.armor && item.armor.asset_id === action.armorId) {
                        var copyPanda = Object.assign({}, item);
                        copyPanda.armor = 0;
                        return copyPanda
                    }
                    return item;
                })
            }
        }
        case ADD_JEW_TO_SLOT: {

            var rar = 0;
            if (action.data.jew.data.rarity == "Uncommon" || action.data.jew.data.rarity == "1") {
                rar = 1
            } else if (action.data.jew.data.rarity == "Rare" || action.data.jew.data.rarity == "2") {
                rar = 2
            } else if (action.data.jew.data.rarity == "Epic" || action.data.jew.data.rarity == "3") {
                rar = 3
            } else if (action.data.jew.data.rarity == "Legendary" || action.data.jew.data.rarity == "4") {
                rar = 4
            } else if (action.data.jew.data.rarity == "Mythic" || action.data.jew.data.rarity == "5") {
                rar = 5
            }

            var element = (action.data.jew.data.element).toLowerCase();
            var tmpl = parseInt(action.data.jew.template.template_id);

            var objAtomic = {
                asset_id: action.data.jew.asset_id,
                tmpl: tmpl,
                element: element,
                energy: 10000,
                rarity: rar
            }
            return {
                ...state, pandaSlotOnGameWax: state.pandaSlotOnGameWax.map((item) => {
                    if (item.asset_id === action.data.pandaId) {
                        var copyPanda = Object.assign({}, item);
                        copyPanda.jew = objAtomic;
                        return copyPanda
                    }
                    return item;
                })
            }

        }

        case ADD_ARMOR_TO_SLOT: {

            var rar = 0;
            if (action.data.armor.data.rarity == "Uncommon" || action.data.armor.data.rarity == "1") {
                rar = 1
            } else if (action.data.armor.data.rarity == "Rare" || action.data.armor.data.rarity == "2") {
                rar = 2
            } else if (action.data.armor.data.rarity == "Epic" || action.data.armor.data.rarity == "3") {
                rar = 3
            } else if (action.data.armor.data.rarity == "Legendary" || action.data.armor.data.rarity == "4") {
                rar = 4
            } else if (action.data.armor.data.rarity == "Mythic" || action.data.armor.data.rarity == "5") {
                rar = 5
            }

            var element = (action.data.armor.data.element).toLowerCase();
            var tmpl = parseInt(action.data.armor.template.template_id);

            var objAtomic = {
                asset_id: action.data.armor.asset_id,
                tmpl: tmpl,
                element: element,
                energy: 10000,
                rarity: rar
            }
            return {
                ...state, pandaSlotOnGameWax: state.pandaSlotOnGameWax.map((item) => {
                    if (item.asset_id === action.data.pandaId) {
                        var copyPanda = Object.assign({}, item);
                        copyPanda.armor = objAtomic;
                        return copyPanda
                    }
                    return item;
                })
            }

        }

        case EATLISTCLEAR: {
            //console.log('EATLIST', action.eatlist);
            return { ...state, alleat: null }
        }
        case ADD_ENERGY: {
            //console.log("action.energy", action.energy);
            if (action.data.mult) {
                return {
                    ...state,
                    pandaSlotOnGameWax: state.pandaSlotOnGameWax.map((item, index) => {
                        if (item.activePanda) {
                            var copyPanda = Object.assign({}, item);

                            if (copyPanda.weapon) {
                                var copyPandaWeapon = Object.assign({}, copyPanda.weapon);
                                copyPandaWeapon.energy = 10000;
                                copyPanda.weapon = copyPandaWeapon;
                            }

                            return copyPanda;

                        }
                        return item;
                    })

                }
            } else {
                return {
                    ...state,
                    pandaSlotOnGameWax: state.pandaSlotOnGameWax.map((item, index) => {
                        if (item.asset_id == action.data.energy) {
                            var copyPanda = Object.assign({}, item);

                            if (copyPanda.weapon) {
                                var copyPandaWeapon = Object.assign({}, copyPanda.weapon);
                                copyPandaWeapon.energy = 10000;
                                copyPanda.weapon = copyPandaWeapon;
                            }

                            return copyPanda;

                        }
                        return item;
                    })

                }
            }

        }
        case ADD_ENERGY_JEW: {
            if (action.data.mult) {
                return {
                    ...state,
                    pandaSlotOnGameWax: state.pandaSlotOnGameWax.map((item, index) => {
                        if (item.activePanda) {
                            var copyPanda = Object.assign({}, item);

                            if (copyPanda.jew) {
                                var copyPandaJew = Object.assign({}, copyPanda.jew);
                                copyPandaJew.energy = 10000;
                                copyPanda.jew = copyPandaJew;
                            }

                            return copyPanda;

                        }
                        return item;
                    })

                }
            } else {
                return {
                    ...state,
                    pandaSlotOnGameWax: state.pandaSlotOnGameWax.map((item, index) => {
                        if (item.asset_id == action.data.energy) {
                            var copyPanda = Object.assign({}, item);

                            if (copyPanda.jew) {
                                var copyPandaJew = Object.assign({}, copyPanda.jew);
                                copyPandaJew.energy = 10000;
                                copyPanda.jew = copyPandaJew;
                            }

                            return copyPanda;

                        }
                        return item;
                    })

                }
            }
        }

        case ADD_ENERGY_ARMOR: {
            if (action.data.mult) {
                return {
                    ...state,
                    pandaSlotOnGameWax: state.pandaSlotOnGameWax.map((item, index) => {
                        if (item.activePanda) {
                            var copyPanda = Object.assign({}, item);

                            if (copyPanda.armor) {
                                var copyPandaArmor = Object.assign({}, copyPanda.armor);
                                copyPandaArmor.energy = 10000;
                                copyPanda.armor = copyPandaArmor;
                            }

                            return copyPanda;

                        }
                        return item;
                    })

                }
            } else {
                return {
                    ...state,
                    pandaSlotOnGameWax: state.pandaSlotOnGameWax.map((item, index) => {
                        if (item.asset_id == action.data.energy) {
                            var copyPanda = Object.assign({}, item);

                            if (copyPanda.armor) {
                                var copyPandaArmor = Object.assign({}, copyPanda.armor);
                                copyPandaArmor.energy = 10000;
                                copyPanda.armor = copyPandaArmor;
                            }

                            return copyPanda;

                        }
                        return item;
                    })

                }
            }
        }

        case SET_PANDA_ON_GAME_WAX: {
            return { ...state, pandaSlotOnGameWax: action.pandas }
        }
        case SET_WEAPON_ON_GAME_WAX: {
            return {
                ...state,
                pandaSlotOnGameWax: state.pandaSlotOnGameWax.map((item, index) => {
                    if (item.weapon) {
                        for (let e = 0; e < action.weapon.length; e++) {
                            if (item.weapon === action.weapon[e].asset_id) {
                                var copy = Object.assign({}, item);
                                copy.weapon = action.weapon[e];

                                return copy
                            }
                        }
                    }
                    return item;
                }),
                pandaListOnGame: state.pandaListOnGame.map((item, index) => {
                    if (item.weapon) {
                        for (let e = 0; e < action.weapon.length; e++) {
                            if (item.weapon === action.weapon[e].asset_id) {
                                var copy = Object.assign({}, item);
                                copy.weapon = action.weapon[e];

                                return copy
                            }
                        }
                    }
                    return item;
                })
            }
        }
        case SET_JEW_ON_GAME_WAX: {
            return {
                ...state,
                pandaSlotOnGameWax: state.pandaSlotOnGameWax.map((item, index) => {
                    if (item.jew) {
                        for (let e = 0; e < action.jew.length; e++) {
                            if (item.jew === action.jew[e].asset_id) {
                                var copy = Object.assign({}, item);
                                copy.jew = action.jew[e];

                                return copy
                            }
                        }
                    }
                    return item;
                }),
                pandaListOnGame: state.pandaListOnGame.map((item, index) => {
                    if (item.jew) {
                        for (let e = 0; e < action.jew.length; e++) {
                            if (item.jew === action.jew[e].asset_id) {
                                var copy = Object.assign({}, item);
                                copy.jew = action.jew[e];

                                return copy
                            }
                        }
                    }
                    return item;
                })
            }
        }
        case SET_ARMOR_ON_GAME_WAX: {
            return {
                ...state,
                pandaSlotOnGameWax: state.pandaSlotOnGameWax.map((item, index) => {
                    if (item.armor) {
                        for (let e = 0; e < action.armor.length; e++) {
                            if (item.armor === action.armor[e].asset_id) {
                                var copy = Object.assign({}, item);
                                copy.armor = action.armor[e];

                                return copy
                            }
                        }
                    }
                    return item;
                }),
                pandaListOnGame: state.pandaListOnGame.map((item, index) => {
                    if (item.armor) {
                        for (let e = 0; e < action.armor.length; e++) {
                            if (item.armor === action.armor[e].asset_id) {
                                var copy = Object.assign({}, item);
                                copy.armor = action.armor[e];

                                return copy
                            }
                        }
                    }
                    return item;
                })
            }
        }

        case SET_WAS_LOAD_PANDAS: {
            return { ...state, wasLoadPandas: action.pandas }
        }
        case SET_WAS_LOAD_WEAPON: {
            return { ...state, wasLoadWeapon: action.weapon }
        }
        case SET_WAS_LOAD_JEW: {
            return { ...state, wasLoadJew: action.jew }
        }
        case SET_WAS_LOAD_ARMOR: {
            return { ...state, wasLoadArmor: action.armor }
        }

        case CHOOSE_PANDA: {
            //console.log("action.data.type", action.data.type)
            //console.log("action.data.duration", action.data.duration)
            // console.log("action.data.type", action.data.type)

            var timer = Date.now();
            // var energy = 500;
            // if (action.data.duration == 8 && action.data.type == "bamboo" ||
            //     action.data.duration == 12 && action.data.type != "bamboo") {
            //     energy = 2000;
            // } else if (action.data.duration == 12 && action.data.type == "bamboo") {
            //     energy = 4000;
            // }
            //console.log(timer);
            return {
                ...state, pandaSlotOnGameWax: state.pandaSlotOnGameWax.map((u) => {

                    var energy = 500;
                    if (action.data.duration == 8 && action.data.types == "bamboo" ||
                        action.data.duration == 12 && action.data.types != "bamboo") {
                        energy = 2000;
                    } else if (action.data.duration == 12 && action.data.types == "bamboo") {
                        energy = 4000;
                    }
                    var pandaRar = u.rarity;
                    var weapon = u.weapon;
                    var jew = u.jew;
                    var armor = u.armor;
                    if (weapon != 0 && jew != 0 && armor != 0) {
                        if (pandaRar != 0) {
                            pandaRar = pandaRar - 1;
                        }
                        if (weapon.rarity >= pandaRar && jew.rarity >= pandaRar && armor.rarity >= pandaRar) {
                            energy = 400;
                            if (action.data.duration == 8 && action.data.types == "bamboo" ||
                                action.data.duration == 12 && action.data.types != "bamboo") {
                                energy = 1800;
                            } else if (action.data.duration == 12 && action.data.types == "bamboo") {
                                energy = 3600;
                            }
                        }
                    }

                    //console.log(u);
                    if (state.userSlots.includes(u.asset_id)) {
                        if (action.data.mult === "one") {
                            if (u && u.asset_id === action.data.panda) {
                                var copy = Object.assign({}, u);
                                copy.activePanda = !copy.activePanda;

                                return copy
                            }
                        } else if (action.data.type === "bamboo" && action.data.mult !== "remove") {

                            if (timer > u.timer * 1000 && action.data.duration == 4 ||
                                timer > u.timer * 1000 && action.data.duration == 8 && u.energy > energy ||
                                timer > u.timer * 1000 && action.data.duration == 8 && u.energy > energy) {
                                var copy = Object.assign({}, u);
                                copy.activePanda = true;
                            } else {
                                var copy = Object.assign({}, u);
                                copy.activePanda = false;
                            }
                            return copy

                        } else if (action.data.mult === "all") {


                            var copy = Object.assign({}, u);
                            copy.activePanda = true;

                            return copy


                        } else if (action.data.mult === "onlybest"
                            && action.data.type !== "bamboo"
                            && timer > u.timer * 1000
                            && u.weapon
                            && u.weapon.energy >= energy) {
                            var copy = Object.assign({}, u);

                            var onePanda = copy;
                            var pandaRar = onePanda.rarity;  // (NFT) rare
                            var pandaLvl = onePanda.lvl; // (РАНГ) 8.67 далдно быть застейкано
                            var pandaSt = onePanda.stake_count; // берес количество застейканого бамам на панде

                            var pandaStake = {
                                "0": [0, 8700, 22900, 45800, 82900, 142900, 240000, 397100, 651300, 1062600, 1728100, 2804900, 4547100],
                                "1": [0, 14400, 37800, 75600, 136800, 235800, 395800, 655000, 1074400, 1753000, 2851000, 4627600, 7502200],
                                "2": [0, 17700, 46600, 93300, 168900, 291100, 488900, 808900, 1326700, 2164500, 3520100, 5713500, 9262400],
                                "3": [0, 48000, 126000, 252000, 456000, 786000, 1320000, 2184000, 3582000, 5844000, 9504000, 15426000, 25008000],
                                "4": [0, 80000, 210000, 420000, 760000, 1310000, 2200000, 3640000, 5970000, 9740000, 15840000, 25710000, 41680000],
                                "5": [0, 320000, 840000, 1680000, 3040000, 5240000, 8800000, 14560000, 23880000, 38960000, 63360000, 102840000, 166720000],
                                "6": [0, 960000, 2520000, 5040000, 9120000, 15720000, 26400000, 43680000, 71640000, 116880000, 190080000, 308520000, 500160000]
                            }

                            var howmcount = pandaStake[pandaRar][pandaLvl];
                            var youmastadd = (howmcount - pandaSt) / 10000; // вы должны добавитть бам
                            var lvlLoop = pandaLvl; // левел для цыкла

                            for (var i = pandaLvl; i >= 0; i--) {

                                if (pandaSt < howmcount) {
                                    lvlLoop = i;
                                }
                            }

                            // var exp;
                            // if (lvlLoop !== pandaLvl) {
                            //     copy.activePanda = false;
                            // } else {
                            //     copy.activePanda = true;
                            // }

                            if (lvlLoop < 2) {
                                copy.activePanda = false;
                            } else {
                                copy.activePanda = true;
                            }

                            return copy;
                        }
                        else if (action.data.type === "bamboo" && action.data.mult === "remove") {

                            //console.log("we are here");
                            if (u.activePanda) {
                                //console.log("u", u);
                                var copyPanda = Object.assign({}, u);
                                copyPanda.activePanda = false;


                                if (copyPanda.energy >= energy) {
                                    copyPanda.energy = copyPanda.energy - energy;
                                }

                                if (copyPanda.weapon && copyPanda.weapon.energy >= energy) {
                                    var copyPandaWeapon = Object.assign({}, copyPanda.weapon);
                                    copyPandaWeapon.energy = copyPanda.weapon.energy - energy;
                                    copyPanda.weapon = copyPandaWeapon;
                                }

                                /*if (copyPanda.armor && copyPanda.armor.energy >= energy) {
                                    var copyPandaArmor = Object.assign({}, copyPanda.armor);
                                    copyPandaArmor.energy = copyPanda.armor.energy - energy;
                                    copyPanda.armor = copyPandaArmor;
                                }*/

                                if (action.data.type == "bamboo") {
                                    if (copyPanda.jew && copyPanda.jew.energy >= energy) {
                                        var copyPandaJew = Object.assign({}, copyPanda.jew);
                                        copyPandaJew.energy = copyPanda.jew.energy - energy;
                                        copyPanda.jew = copyPandaJew;
                                    }
                                }

                                return copyPanda;

                            }
                        } else {
                            var copy = Object.assign({}, u);
                            copy.activePanda = false;

                            return copy
                        }
                    }
                    return u;

                })
            }
        }

        default:
            return state;
    }
}

export const setSlotPandaOnGame = (pandaSlotOnGame) => ({ type: SET_SLOT_PANDA_ON_GAME, pandaSlotOnGame }) // панды в слотах
export const setPandaOnGame = (pandaListOnGame) => ({ type: SET_PANDA_ON_GAME, pandaListOnGame }) // панда в модалке
export const toggleIsFetchingTop = (isFetching) => ({ type: TOGGLE_IS_FETCHING_TOP, isFetching })
export const doDelFromStates = (removePandaFromState, indexPanda) => ({ type: REMOVE_FROM_STATES, data: { removePandaFromState, indexPanda } })
export const addToSlotState = (numberSlot, panda) => ({ type: ADD_TO_SLOT_STATE, data: { numberSlot, panda }, })
export const doDelFromSlot = (panda, indexSlot) => ({ type: REMOVE_FROM_SLOT, data: { panda, indexSlot } })
export const setUsersRow = (usersRow) => ({ type: SET_USERS_ROW, usersRow })
export const addSlot = (slotNum) => ({ type: ADD_SLOT, slotNum })
//export const setTimerToState = (addId, addTime) => ({type: ADD_TIMER,  data: {addId, addTime} })
export const setTimerEnd = (removeId) => ({ type: REMOVE_SLOT, removeId })
export const removePandaAdv = (removeId, timer, energyPanda, energyWeapon, energyJew, types, multi, duration) => ({ type: REMOVE_PANDA_ADV, data: { removeId, timer, energyPanda, energyWeapon, energyJew, types, multi, duration } })
export const removePandaAdvEnergy = (removeId, types, duration) => ({ type: REMOVE_PANDA_ADV_ENERGY, data: { removeId, types, duration } })
export const changeStakeSum = (changeId, moneysum, allinfo) => ({ type: CHANGE_STAKE_SUM, data: { changeId, moneysum, allinfo } })
export const changeStakeSumMinus = (changeId, moneysum, allinfo) => ({ type: CHANGE_STAKE_SUM_MINUS, data: { changeId, moneysum, allinfo } })
export const lvlUp = (pandaId) => ({ type: LVL_UP, pandaId })
export const eat = (index, pandaId, rareat, dateBt, mult) => ({ type: EAT, data: { index, pandaId, rareat, dateBt, mult } })
//export const eatlist = (eatlist) => ({type: EATLIST, eatlist })
export const eatlistclear = () => ({ type: EATLISTCLEAR })

export const removeWeaponSlot = (weaponId) => ({ type: REMOVE_WEAPON_SLOT, weaponId })
export const addWeaponToSlot = (weapon, pandaId) => ({ type: ADD_WEAPON_TO_SLOT, data: { weapon, pandaId } })
export const addWeaponEnergy = (energy, mult) => ({ type: ADD_ENERGY, data: { energy, mult } })

export const removeJewSlot = (jewId) => ({ type: REMOVE_JEW_SLOT, jewId })
export const addJewToSlot = (jew, pandaId) => ({ type: ADD_JEW_TO_SLOT, data: { jew, pandaId } })
export const addJewEnergy = (energy, mult) => ({ type: ADD_ENERGY_JEW, data: { energy, mult } })

export const removeArmorSlot = (armorId) => ({ type: REMOVE_ARMOR_SLOT, armorId })
export const addArmorToSlot = (armor, pandaId) => ({ type: ADD_ARMOR_TO_SLOT, data: { armor, pandaId } })
export const addArmorEnergy = (energy, mult) => ({ type: ADD_ENERGY_ARMOR, data: { energy, mult } })

export const setPandaOnGameWax = (pandas) => ({ type: SET_PANDA_ON_GAME_WAX, pandas }) // панды в слотах
export const setWeaponOnGameWax = (weapon) => ({ type: SET_WEAPON_ON_GAME_WAX, weapon }) // панды в слотах
export const setJewOnGameWax = (jew) => ({ type: SET_JEW_ON_GAME_WAX, jew }) // панды в слотах
export const setArmorOnGameWax = (armor) => ({ type: SET_ARMOR_ON_GAME_WAX, armor }) // панды в слотах

export const setWasLoadPandas = (pandas) => ({ type: SET_WAS_LOAD_PANDAS, pandas }) // панды в слотах
export const setWasLoadWeapon = (weapon) => ({ type: SET_WAS_LOAD_WEAPON, weapon }) // панды в слотах
export const setWasLoadJew = (jew) => ({ type: SET_WAS_LOAD_JEW, jew }) // панды в слотах
export const setWasLoadArmor = (armor) => ({ type: SET_WAS_LOAD_ARMOR, armor }) // панды в слотах

export const choosePanda = (panda, mult, type, duration) => ({ type: CHOOSE_PANDA, data: { panda, mult, type, duration } }) // панды в слотах

export const setUserSlots = (pandas) => ({ type: SET_USER_SLOTS, pandas }) // панды в слотах

export default slotReducer;
