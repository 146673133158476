import React from "react";
import Menu from '../../components/Menu';
import axios from "axios";

import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import {
  weaponlist,
  statusLoadinWeapon,
  addWeaponWhenRemove,
  addWeaponToPanda,
} from "../../GlobalState/weapon-reducer";

import ErrorComponent from "../Error/ErrorComponent";
import UpgradeHomeLoop from "./UpgradeHomeLoop";
import cryptoRandomString from "crypto-random-string";

import { JsonRpc } from "eosjs";
import "./../../css/craft.scss";

import musicError from "./../../music/error.mp3";
import musicOpenCraft from "./../../music/bt-craft.mp3";
import craftSuccess from "./../../music/craft-success.mp3";
import craftFail from "./../../music/craft-fail.mp3";
import newClick from "./../../music/newclick.mp3";

import { ExplorerApi } from "atomicassets"; // атомик для списка панд
import { compose } from "glamor";

const fetch = require("node-fetch");
const api = new ExplorerApi(
  "https://" + localStorage.getItem('rpcA'),
  "atomicassets",
  { fetch, rateLimit: 4 }
);

// это транзакция отправки ИЗ
const cardTransferOut = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "transferout",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        from: "",
        to: "",
        asset_id: [],
      },
    },
  ],
};

class UpgradePageComponentArmor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      craftweapon: [
        {
          headWeapon: "sword",
          allelements: [
            {
              weapon: 376557,
              element: "water",
            },
            {
              weapon: 376582,
              element: "earth",
            },
            {
              weapon: 376577,
              element: "wind",
            },
            {
              weapon: 376570,
              element: "fire",
            },
          ],
        },
        {
          headWeapon: "bow",
          allelements: [
            {
              weapon: 373659,
              element: "water",
              bluePrint: 373647,
              partOne: 373649,
              partTwo: 373650,
              partThree: 373653,
            },
            {
              weapon: 373736,
              element: "earth",
              bluePrint: 373683,
              partOne: 373732,
              partTwo: 373734,
              partThree: 373735,
            },
            {
              weapon: 373682,
              element: "wind",
              bluePrint: 373671,
              partOne: 373677,
              partTwo: 373678,
              partThree: 373679,
            },
            {
              weapon: 373669,
              element: "fire",
              bluePrint: 373661,
              partOne: 373665,
              partTwo: 373666,
              partThree: 373667,
            },
          ],
        },
        {
          headWeapon: "hammer",
          allelements: [
            {
              weapon: 375234,
              element: "water",
              bluePrint: 375229,
              partOne: 375231,
              partTwo: 375232,
              partThree: 375233,
            },
            {
              weapon: 376151,
              element: "earth",
              bluePrint: 376146,
              partOne: 376147,
              partTwo: 376149,
              partThree: 376150,
            },
            {
              weapon: 376145,
              element: "wind",
              bluePrint: 376141,
              partOne: 376142,
              partTwo: 376143,
              partThree: 376144,
            },
            {
              weapon: 375243,
              element: "fire",
              bluePrint: 375236,
              partOne: 375239,
              partTwo: 375240,
              partThree: 375241,
            },
          ],
        },
        {
          headWeapon: "axe",
          allelements: [
            {
              weapon: 373391,
              element: "water",
              bluePrint: 373376,
              partOne: 373380,
              partTwo: 373384,
              partThree: 373387,
            },
            {
              weapon: 373457,
              element: "earth",
              bluePrint: 373452,
              partOne: 373453,
              partTwo: 373454,
              partThree: 373456,
            },
            {
              weapon: 373448,
              element: "wind",
              bluePrint: 373439,
              partOne: 373442,
              partTwo: 373444,
              partThree: 373447,
            },
            {
              weapon: 373394,
              element: "fire",
              bluePrint: 373661,
              partOne: 373406,
              partTwo: 373427,
              partThree: 373431,
            },
          ],
        },
        {
          headWeapon: "stick",
          allelements: [
            {
              weapon: 376320,
              element: "water",
              bluePrint: 376312,
              partOne: 376313,
              partTwo: 376315,
              partThree: 376319,
            },
            {
              weapon: 376407,
              element: "earth",
              bluePrint: 376397,
              partOne: 376398,
              partTwo: 376399,
              partThree: 376400,
            },
            {
              weapon: 376374,
              element: "wind",
              bluePrint: 376330,
              partOne: 376334,
              partTwo: 376337,
              partThree: 376342,
            },
            {
              weapon: 376329,
              element: "fire",
              bluePrint: 376322,
              partOne: 376325,
              partTwo: 376327,
              partThree: 376328,
            },
          ],
        },
        {
          headWeapon: "mech",
          allelements: [
            {
              weapon: 376156,
              element: "water",
              bluePrint: 376152,
              partOne: 376153,
              partTwo: 376154,
              partThree: 376155,
            },
            {
              weapon: 376178,
              element: "earth",
              bluePrint: 376173,
              partOne: 376174,
              partTwo: 376175,
              partThree: 376176,
            },
            {
              weapon: 376172,
              element: "wind",
              bluePrint: 376165,
              partOne: 376166,
              partTwo: 376167,
              partThree: 376170,
            },
            {
              weapon: 376162,
              element: "fire",
              bluePrint: 376157,
              partOne: 376158,
              partTwo: 376160,
              partThree: 376161,
            },
          ],
        },
        {
          headWeapon: "claws",
          allelements: [
            {
              weapon: 374507,
              element: "water",
              bluePrint: 373737,
              partOne: 373741,
              partTwo: 373742,
              partThree: 373743,
            },
            {
              weapon: 374535,
              element: "earth",
              bluePrint: 374526,
              partOne: 374528,
              partTwo: 374531,
              partThree: 374532,
            },
            {
              weapon: 374525,
              element: "wind",
              bluePrint: 374519,
              partOne: 374521,
              partTwo: 374522,
              partThree: 374523,
            },
            {
              weapon: 374515,
              element: "fire",
              bluePrint: 374508,
              partOne: 374510,
              partTwo: 374511,
              partThree: 374512,
            },
          ],
        },
        {
          headWeapon: "slingshot",
          allelements: [
            {
              weapon: 376198,
              element: "water",
              bluePrint: 376194,
              partOne: 376194,
              partTwo: 376195,
              partThree: 376196,
            },
            {
              weapon: 376310,
              element: "earth",
              bluePrint: 376300,
              partOne: 376304,
              partTwo: 376306,
              partThree: 376308,
            },
            {
              weapon: 376302,
              element: "wind",
              bluePrint: 376295,
              partOne: 376296,
              partTwo: 376297,
              partThree: 376299,
            },
            {
              weapon: 376204,
              element: "fire",
              bluePrint: 376200,
              partOne: 376201,
              partTwo: 376202,
              partThree: 376203,
            },
          ],
        },
        {
          headWeapon: "gun",
          allelements: [
            {
              weapon: 374606,
              element: "water",
              bluePrint: 374597,
              partOne: 374598,
              partTwo: 374601,
              partThree: 374604,
            },
            {
              weapon: 374653,
              element: "earth",
              bluePrint: 374641,
              partOne: 374643,
              partTwo: 374648,
              partThree: 374651,
            },
            {
              weapon: 374640,
              element: "wind",
              bluePrint: 374629,
              partOne: 374632,
              partTwo: 374637,
              partThree: 374639,
            },
            {
              weapon: 374615,
              element: "fire",
              bluePrint: 374608,
              partOne: 374611,
              partTwo: 374612,
              partThree: 374614,
            },
          ],
        },
        {
          headWeapon: "dagger",
          allelements: [
            {
              weapon: 374544,
              element: "water",
              bluePrint: 374537,
              partOne: 374538,
              partTwo: 374539,
              partThree: 374542,
            },
            {
              weapon: 374596,
              element: "earth",
              bluePrint: 374591,
              partOne: 374592,
              partTwo: 374593,
              partThree: 374594,
            },
            {
              weapon: 374590,
              element: "wind",
              bluePrint: 374585,
              partOne: 374587,
              partTwo: 374588,
              partThree: 374589,
            },
            {
              weapon: 374570,
              element: "fire",
              bluePrint: 374545,
              partOne: 374558,
              partTwo: 374561,
              partThree: 374565,
            },
          ],
        },
      ],
      activeWeaponName: "",
      activeRecipe: {
        arreyIndex: "",
        weapon: "",
        tempaleID: "",
        res: "",
        resNum: "",
        resIndex: "",
      },
      openRecipe: false,
      allUserWeapon: {}, // все оружия
      allUserRes: {}, // все оружия
      modalResult: {
        open: false, // показать модалку результата
        loading: true, // ожидание результата
      },
      craftpay: false, //
      modalcraft: {
        top: "Upgrade weapon",
        title: "The blacksmith is ready to start work",
        button: "first",
        descr:
          `The blacksmith always takes money <span>(15 ${process.env.REACT_APP_VAL})<i></i></span> for the front, since nothing motivates like a salary. `,
      },
      craftfail: "",
      craftResult: "",
      activePage: 1,
      countPage: "2",
      loadingNft: true,
    };

    this.clearError = this.clearError.bind(this);
    this.GetUserWeaponList = this.GetUserWeaponList.bind(this);
    this.openRecipe = this.openRecipe.bind(this);
    this.backFromRecipe = this.backFromRecipe.bind(this);

    this.craftpay = this.craftpay.bind(this);
    this.opencraftmodal = this.opencraftmodal.bind(this);
    this.craft = this.craft.bind(this);
    //this.GetPandaPartList = this.GetPandaPartList.bind(this);
    //this.GetPandaWeaponList = this.GetPandaWeaponList.bind(this);
    this.closeModalResult = this.closeModalResult.bind(this);
    this.closeModalSteps = this.closeModalSteps.bind(this);
    this.medalCheck = this.medalCheck.bind(this);

    this.audioMusicError = new Audio(musicError);
    this.audioMusicOpenCraft = new Audio(musicOpenCraft);
    this.audioMusicCraftSuccess = new Audio(craftSuccess);
    this.audioMusicCraftfail = new Audio(craftFail);
    this.audioMusicNewClick = new Audio(newClick);
  }

  componentDidMount() {
    //this.GetUserWeaponList();
    this.medalCheck();
    this.getAllNft();
  }

  async getAllNft() {
    try {
      let promResWood = [];
      let promResSteel = [];
      let promResTit = [];
      let promResKves = [];
      let promResGold = [];
      let promResDwem = [];
      let promStone = [];

      this.setState({
        ...this.state,
        loadingNft: true,
      });

      var arr = [
        373391, 376750, 376753, 376755, 376756, 376760, 373433, 376762, 376763,
        376767, 376768, 376769, 373448, 376770, 376772, 376773, 376774, 376775,
        373457, 376776, 376777, 376778, 376794, 376795, 373659, 376991, 376993,
        376994, 376995, 376996, 373669, 376997, 376998, 376999, 377000, 377001,
        373682, 377002, 377009, 377011, 377012, 377015, 373736, 377019, 377283,
        377285, 377286, 377287, 374507, 378777, 378778, 378779, 378780, 378781,
        374515, 378783, 378784, 378785, 378787, 378789, 374525, 378790, 378791,
        378794, 378795, 378796, 374535, 378799, 378801, 378810, 378815, 378821,
        374544, 378165, 378171, 378089, 378176, 378178, 374570, 378290, 378292,
        378083, 378296, 378299, 374590, 378302, 378305, 378040, 378311, 378313,
        374596, 378316, 378317, 378031, 378322, 378323, 374606, 382639, 382643,
        382647, 382670, 382671, 374615, 382672, 382675, 382678, 382680, 382684,
        374640, 378037, 382688, 382691, 382693, 382698, 374653, 382702, 382709,
        382714, 382724, 382726, 375234, 377469, 377472, 377473, 377474, 377475,
        375243, 377476, 377477, 377481, 377483, 377484, 376145, 377486, 377487,
        377488, 377489, 377490, 376151, 377492, 378015, 378017, 378023, 378025,
        376156, 382574, 382575, 382577, 382578, 382579, 376162, 382580, 382581,
        382588, 382591, 382594, 376172, 382604, 382609, 382613, 382616, 382620,
        376178, 382621, 382623, 382627, 382628, 382629, 376198, 379070, 379072,
        380265, 380268, 380270, 376204, 380271, 380272, 380273, 380275, 380276,
        376302, 380277, 380280, 380281, 380284, 380286, 376310, 382044, 382046,
        382050, 382052, 382054, 376320, 377302, 377303, 377304, 377306, 377307,
        376329, 377347, 377352, 377355, 377359, 377362, 376374, 377365, 377368,
        377373, 377377, 377381, 376407, 377387, 377392, 377397, 377401, 377404,
        376557, 378029, 378030, 378031, 378034, 378036, 376570, 378037, 378038,
        378040, 378075, 378077, 376577, 378080, 378081, 378083, 378084, 378085,
        376582, 378087, 378088, 378089, 378090, 378091, 376787, 378174, 378295,
        378320, 382685, 378307,
      ];

      //promResWood.push(this.GetUserWeaponList(431181, 'res'));
      promResSteel.push(this.GetUserWeaponList(431182, "res"));
      promResTit.push(this.GetUserWeaponList(431183, "res"));
      promResKves.push(this.GetUserWeaponList(431184, "res"));
      promResGold.push(this.GetUserWeaponList(431185, "res"));
      promResDwem.push(this.GetUserWeaponList(431186, "res"));
      promStone.push(this.GetUserWeaponList(arr, "armor"));

      let resultW = await Promise.all(promResWood);
      let resultSt = await Promise.all(promResSteel);
      let resultT = await Promise.all(promResTit);
      let resultK = await Promise.all(promResKves);
      let resultG = await Promise.all(promResGold);
      let resultD = await Promise.all(promResDwem);
      let resultS = await Promise.all(promStone);

      var resultNew = [];
      var resultNewRes = [];
      resultNewRes.push(
        ...resultSt[0],
        ...resultT[0],
        ...resultK[0],
        ...resultG[0],
        ...resultD[0]
      );
      resultNew.push(...resultS[0]);

      //console.log("resultNew", resultNew);
      let newData = {};
      Object.entries(resultNew).forEach((pair) => {
        let [key, value] = pair;
        //console.log(value);

        if (!newData[value.template.template_id])
          newData[value.template.template_id] = [];
        newData[value.template.template_id].push(value);
      });

      let newDataRes = {};
      Object.entries(resultNewRes).forEach((pair) => {
        let [key, value] = pair;
        //console.log(value);

        if (!newDataRes[value.template.template_id])
          newDataRes[value.template.template_id] = [];
        newDataRes[value.template.template_id].push(value);
      });
      //console.log("newDataRes", newDataRes)
      var arreyObj = Object.values(newData);
      var arreyObjRes = Object.values(newDataRes);

      //console.log('arreyObj.length', arreyObj.length)

      this.setState({
        ...this.state,
        allUserWeapon: arreyObj,
        allUserRes: arreyObjRes,
        countPage: arreyObj.length / 8,
        loadingNft: false,
      });

      // result = result[0].map( (item) => {
      //     return {
      //         asset_id: item.asset_id,
      //         template_id: item.template.template_id,
      //         schema_name: item.schema.schema_name
      //     }
      // })

      // resultS = resultS[0].map( (item) => {
      //     return {
      //         asset_id: item.asset_id,
      //         template_id: item.template.template_id,
      //         schema_name: item.schema.schema_name
      //     }
      // })

      // var resultNew = [];
      // resultNew.push(...result, ...resultS);

      // this.setState({
      //     ...this.state,
      //     allparts: resultNew,
      //     counPartWeapon: {
      //         resource: result.length,
      //         stone: resultS.length
      //     },
      //     loadingNft: false
      // });
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
    }
  }

  async medalCheck() {
    try {
      //console.log(555555555555555555555555555555555555555555555);
      const activeUser = this.props.ual.activeUser;
      const accountName = await activeUser.getAccountName();
      let rpcc;
      if (this.props.ual.activeUser.rpc != undefined) {
        rpcc = this.props.ual.activeUser.rpc;
      } else if (
        this.props.ual.activeUser.rpc == undefined &&
        this.props.ual.activeUser.wax
      ) {
        rpcc = this.props.ual.activeUser.wax.rpc;
      } else {
        rpcc = new JsonRpc(localStorage.getItem("rpc"));
      }
      const results = await rpcc.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "medaltop", // name of the table as specified by the contract abi
        //"table": "usersnew",    // name of the table as specified by the contract abi
        limit: 1,
        lower_bound: accountName,
        upper_bound: accountName,
        //lower_bound: 0,
        key_type: "i64",
        //"index_position": 2,
        //"limit": -1,
        reverse: !0,
        show_payer: false,
        index_position: 1,
      });
      const originRowUser = results.rows[0];
      console.log("originRowUser", originRowUser);

      var totalLuck = 0;
      if (results.rows[0]) {
        if (originRowUser.medal100) {
          totalLuck = 5;
          if (originRowUser.medal10) {
            totalLuck += 2.5;
            if (originRowUser.medal300) {
              totalLuck += 1.5;
            }
          } else if (originRowUser.medal300) {
            totalLuck += 1.5;
          }
        } else if (originRowUser.medal10) {
          totalLuck = 5;
          if (originRowUser.medal100) {
            totalLuck += 2.5;
            if (originRowUser.medal300) {
              totalLuck += 1.5;
            }
          } else if (originRowUser.medal300) {
            totalLuck += 1.5;
          }
        } else if (originRowUser.medal300) {
          totalLuck = 3;
          if (originRowUser.medal10) {
            totalLuck += 2.5;
            if (originRowUser.medal100) {
              totalLuck += 2.5;
            }
          } else if (originRowUser.medal100) {
            totalLuck += 2.5;
          }
        }
      }

      if (originRowUser != undefined) {
        this.setState({
          contractMedal: totalLuck,
        });
      }
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
    }
  }

  async clearError() {
    this.setState({
      errorText: "",
      hasError: false,
    });
  }

  async GetQuestList(shema) {
    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;

    /*console.log("tmpl", tmpl);
        console.log("shemaname", shemaname);*/
    return await api
      .getAssets({
        limit: 1000,
        owner: userName,
        collection_name: "nftpandawaxp",
        schema_name: shema,
        //template_id: tmpl,
      })
      .then((assets) =>
        //console.log(assets);

        assets.map((item) => {
          return {
            asset_id: item.asset_id,
            template: {
              template_id: item.template.template_id,
            },
            schema: {
              schema_name: item.schema.schema_name,
            },
            data: {
              rarity: item.data.rarity,
              name: item.data.name,
            },
          };
        })
      );
  }

  async GetUserWeaponList(parts, type) {
    var schema;
    var many = 1000;
    var params;
    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;

    if (type == "res") {
      schema = "resources";
      many = 100;
      params = {
        limit: many,
        owner: userName,
        collection_name: "nftpandawaxp",
        schema_name: schema,
        //schema_name: ["armor"/*, "promo"*/],
        template_id: parts,
      };
    } else {
      schema = "weapon";
      params = {
        // limit: 500,
        // owner: userName,
        // collection_name: 'nftpandawaxp',
        // schema_name: ["weapon"],
        limit: many,
        owner: userName,
        collection_name: "nftpandawaxp",
        schema_name: schema,
        //schema_name: ["armor"/*, "promo"*/],
        template_id: parts,
        'template_data:text.shape': 'Crafted'
      };
    }
    // console.log('parts', parts);
    // console.log('type', type);
    // console.log('schema', schema);


    //arr.push();
    // console.log("arr", arr);
    // console.log("this.state.activeRecipe.bluePrint", this.state.activeRecipe.bluePrint);


    //await api.getAssets(params, 1, 100).then(assets => {


    return await api.getAssets(params, 1, 100).then((assets) => {
      return assets;
    });
  }

  async openRecipe(index, templateId) {
    //console.log(this.state.allUserWeapon);
    //var jewUpgr = 0;
    var weaponUpgr = 0;
    var resUpgr = 0;

    //console.log("templateId", templateId)

    switch (parseInt(templateId)) {
      // sword water
      case 376557:
        weaponUpgr = 378029;
        resUpgr = 431182;
        break;
      case 378029:
        weaponUpgr = 378030;
        resUpgr = 431183;
        break;
      case 378030:
        weaponUpgr = 378031;
        resUpgr = 431184;
        break;
      case 378031:
        weaponUpgr = 378034;
        resUpgr = 431185;
        break;
      case 378034:
        weaponUpgr = 378036;
        resUpgr = 431186;
        break;
      // end sword water
      // sword fire
      case 376570:
        weaponUpgr = 378037;
        resUpgr = 431182;
        break;
      case 378037:
        weaponUpgr = 378038;
        resUpgr = 431183;
        break;
      case 378038:
        weaponUpgr = 378040;
        resUpgr = 431184;
        break;
      case 378040:
        weaponUpgr = 378075;
        resUpgr = 431185;
        break;
      case 378075:
        weaponUpgr = 378077;
        resUpgr = 431186;
        break;
      // end sword fire
      // sword wind
      case 376577:
        weaponUpgr = 378080;
        resUpgr = 431182;
        break;
      case 378080:
        weaponUpgr = 378081;
        resUpgr = 431183;
        break;
      case 378081:
        weaponUpgr = 378083;
        resUpgr = 431184;
        break;
      case 378083:
        weaponUpgr = 378084;
        resUpgr = 431185;
        break;
      case 378084:
        weaponUpgr = 378085;
        resUpgr = 431186;
        break;
      // end sword wind
      // sword earth
      case 376582:
        weaponUpgr = 378087;
        resUpgr = 431182;
        break;
      case 378087:
        weaponUpgr = 378088;
        resUpgr = 431183;
        break;
      case 378088:
        weaponUpgr = 378089;
        resUpgr = 431184;
        break;
      case 378089:
        weaponUpgr = 378090;
        resUpgr = 431185;
        break;
      case 378090:
        weaponUpgr = 378091;
        resUpgr = 431186;
        break;
      // end sword earth

      // axe water
      case 373391:
        weaponUpgr = 376750;
        resUpgr = 431182;
        break;
      case 376750:
        weaponUpgr = 376753;
        resUpgr = 431183;
        break;
      case 376753:
        weaponUpgr = 376755;
        resUpgr = 431184;
        break;
      case 376755:
        weaponUpgr = 376756;
        resUpgr = 431185;
        break;
      case 376756:
        weaponUpgr = 376760;
        resUpgr = 431186;
        break;
      // end axe water
      // axe fire
      case 373433:
        weaponUpgr = 376762;
        resUpgr = 431182;
        break;
      case 376762:
        weaponUpgr = 376763;
        resUpgr = 431183;
        break;
      case 376763:
        weaponUpgr = 376767;
        resUpgr = 431184;
        break;
      case 376767:
        weaponUpgr = 376768;
        resUpgr = 431185;
        break;
      case 376768:
        weaponUpgr = 376769;
        resUpgr = 431186;
        break;
      // end axe fire
      // axe wind
      case 373448:
        weaponUpgr = 376770;
        resUpgr = 431182;
        break;
      case 376770:
        weaponUpgr = 376772;
        resUpgr = 431183;
        break;
      case 376772:
        weaponUpgr = 376773;
        resUpgr = 431184;
        break;
      case 376773:
        weaponUpgr = 376774;
        resUpgr = 431185;
        break;
      case 376774:
        weaponUpgr = 376775;
        resUpgr = 431186;
        break;
      // end axe wind
      // axe earth
      case 373457:
        weaponUpgr = 376776;
        resUpgr = 431182;
        break;
      case 376776:
        weaponUpgr = 376777;
        resUpgr = 431183;
        break;
      case 376777:
        weaponUpgr = 376787;
        resUpgr = 431184;
        break;
      case 376787:
        weaponUpgr = 376794;
        resUpgr = 431185;
        break;
      case 376794:
        weaponUpgr = 376795;
        resUpgr = 431186;
        break;
      // end axe earth

      // Bow water
      case 373659:
        weaponUpgr = 376991;
        resUpgr = 431182;
        break;
      case 376991:
        weaponUpgr = 376993;
        resUpgr = 431183;
        break;
      case 376993:
        weaponUpgr = 376994;
        resUpgr = 431184;
        break;
      case 376994:
        weaponUpgr = 376995;
        resUpgr = 431185;
        break;
      case 376995:
        weaponUpgr = 376996;
        resUpgr = 431186;
        break;
      // end Bow water
      // Bow fire
      case 373669:
        weaponUpgr = 376997;
        resUpgr = 431182;
        break;
      case 376997:
        weaponUpgr = 376998;
        resUpgr = 431183;
        break;
      case 376998:
        weaponUpgr = 376999;
        resUpgr = 431184;
        break;
      case 376999:
        weaponUpgr = 377000;
        resUpgr = 431185;
        break;
      case 377000:
        weaponUpgr = 377001;
        resUpgr = 431186;
        break;
      // end Bow fire
      // Bow wind
      case 373682:
        weaponUpgr = 377002;
        resUpgr = 431182;
        break;
      case 377002:
        weaponUpgr = 377009;
        resUpgr = 431183;
        break;
      case 377009:
        weaponUpgr = 377011;
        resUpgr = 431184;
        break;
      case 377011:
        weaponUpgr = 377012;
        resUpgr = 431185;
        break;
      case 377012:
        weaponUpgr = 377015;
        resUpgr = 431186;
        break;
      // end Bow wind
      // Bow earth
      case 373736:
        weaponUpgr = 377019;
        resUpgr = 431182;
        break;
      case 377019:
        weaponUpgr = 377283;
        resUpgr = 431183;
        break;
      case 377283:
        weaponUpgr = 377285;
        resUpgr = 431184;
        break;
      case 377285:
        weaponUpgr = 377286;
        resUpgr = 431185;
        break;
      case 377286:
        weaponUpgr = 377287;
        resUpgr = 431186;
        break;
      // end Bow earth

      // Claws water
      case 374507:
        weaponUpgr = 378777;
        resUpgr = 431182;
        break;
      case 378777:
        weaponUpgr = 378778;
        resUpgr = 431183;
        break;
      case 378778:
        weaponUpgr = 378779;
        resUpgr = 431184;
        break;
      case 378779:
        weaponUpgr = 378780;
        resUpgr = 431185;
        break;
      case 378780:
        weaponUpgr = 378781;
        resUpgr = 431186;
        break;
      // end Claws water
      // Claws fire
      case 374515:
        weaponUpgr = 378783;
        resUpgr = 431182;
        break;
      case 378783:
        weaponUpgr = 378784;
        resUpgr = 431183;
        break;
      case 378784:
        weaponUpgr = 378785;
        resUpgr = 431184;
        break;
      case 378785:
        weaponUpgr = 378787;
        resUpgr = 431185;
        break;
      case 378787:
        weaponUpgr = 378789;
        resUpgr = 431186;
        break;
      // end Claws fire
      // Claws wind
      case 374525:
        weaponUpgr = 378790;
        resUpgr = 431182;
        break;
      case 378790:
        weaponUpgr = 378791;
        resUpgr = 431183;
        break;
      case 378791:
        weaponUpgr = 378794;
        resUpgr = 431184;
        break;
      case 378794:
        weaponUpgr = 378795;
        resUpgr = 431185;
        break;
      case 378795:
        weaponUpgr = 378796;
        resUpgr = 431186;
        break;
      // end Claws wind
      // Claws earth
      case 374535:
        weaponUpgr = 378799;
        resUpgr = 431182;
        break;
      case 378799:
        weaponUpgr = 378801;
        resUpgr = 431183;
        break;
      case 378801:
        weaponUpgr = 378810;
        resUpgr = 431184;
        break;
      case 378810:
        weaponUpgr = 378815;
        resUpgr = 431185;
        break;
      case 378815:
        weaponUpgr = 378821;
        resUpgr = 431186;
        break;
      // end Claws earth

      // Dagger water
      case 374544:
        weaponUpgr = 378165;
        resUpgr = 431182;
        break;
      case 378165:
        weaponUpgr = 378171;
        resUpgr = 431183;
        break;
      case 378171:
        weaponUpgr = 378174;
        resUpgr = 431184;
        break;
      case 378174:
        weaponUpgr = 378176;
        resUpgr = 431185;
        break;
      case 378176:
        weaponUpgr = 378178;
        resUpgr = 431186;
        break;
      // end Dagger water
      // Dagger fire
      case 374570:
        weaponUpgr = 378290;
        resUpgr = 431182;
        break;
      case 378290:
        weaponUpgr = 378292;
        resUpgr = 431183;
        break;
      case 378292:
        weaponUpgr = 378295;
        resUpgr = 431184;
        break;
      case 378295:
        weaponUpgr = 378296;
        resUpgr = 431185;
        break;
      case 378296:
        weaponUpgr = 378299;
        resUpgr = 431186;
        break;
      // end Dagger fire
      // Dagger wind
      case 374590:
        weaponUpgr = 378302;
        resUpgr = 431182;
        break;
      case 378302:
        weaponUpgr = 378305;
        resUpgr = 431183;
        break;
      case 378305:
        weaponUpgr = 378307;
        resUpgr = 431184;
        break;
      case 378307:
        weaponUpgr = 378311;
        resUpgr = 431185;
        break;
      case 378311:
        weaponUpgr = 378313;
        resUpgr = 431186;
        break;
      // end Dagger wind
      // Dagger earth
      case 374596:
        weaponUpgr = 378316;
        resUpgr = 431182;
        break;
      case 378316:
        weaponUpgr = 378317;
        resUpgr = 431183;
        break;
      case 378317:
        weaponUpgr = 378320;
        resUpgr = 431184;
        break;
      case 378320:
        weaponUpgr = 378322;
        resUpgr = 431185;
        break;
      case 378322:
        weaponUpgr = 378323;
        resUpgr = 431186;
        break;
      // end Dagger earth

      // Gun water
      case 374606:
        weaponUpgr = 382639;
        resUpgr = 431182;
        break;
      case 382639:
        weaponUpgr = 382643;
        resUpgr = 431183;
        break;
      case 382643:
        weaponUpgr = 382647;
        resUpgr = 431184;
        break;
      case 382647:
        weaponUpgr = 382670;
        resUpgr = 431185;
        break;
      case 382670:
        weaponUpgr = 382671;
        resUpgr = 431186;
        break;
      // end Gun water
      // Gun fire
      case 374615:
        weaponUpgr = 382672;
        resUpgr = 431182;
        break;
      case 382672:
        weaponUpgr = 382675;
        resUpgr = 431183;
        break;
      case 382675:
        weaponUpgr = 382678;
        resUpgr = 431184;
        break;
      case 382678:
        weaponUpgr = 382680;
        resUpgr = 431185;
        break;
      case 382680:
        weaponUpgr = 382684;
        resUpgr = 431186;
        break;
      // end Gun fire
      // Gun wind
      case 374640:
        weaponUpgr = 382685;
        resUpgr = 431182;
        break;
      case 382685:
        weaponUpgr = 382688;
        resUpgr = 431183;
        break;
      case 382688:
        weaponUpgr = 382691;
        resUpgr = 431184;
        break;
      case 382691:
        weaponUpgr = 382693;
        resUpgr = 431185;
        break;
      case 382693:
        weaponUpgr = 382698;
        resUpgr = 431186;
        break;
      // end Gun wind
      // Gun earth
      case 374653:
        weaponUpgr = 382702;
        resUpgr = 431182;
        break;
      case 382702:
        weaponUpgr = 382709;
        resUpgr = 431183;
        break;
      case 382709:
        weaponUpgr = 382714;
        resUpgr = 431184;
        break;
      case 382714:
        weaponUpgr = 382724;
        resUpgr = 431185;
        break;
      case 382724:
        weaponUpgr = 382726;
        resUpgr = 431186;
        break;
      // end Gun earth

      // Hammer water
      case 375234:
        weaponUpgr = 377469;
        resUpgr = 431182;
        break;
      case 377469:
        weaponUpgr = 377472;
        resUpgr = 431183;
        break;
      case 377472:
        weaponUpgr = 377473;
        resUpgr = 431184;
        break;
      case 377473:
        weaponUpgr = 377474;
        resUpgr = 431185;
        break;
      case 377474:
        weaponUpgr = 377475;
        resUpgr = 431186;
        break;
      // end Hammer water
      // Hammer fire
      case 375243:
        weaponUpgr = 377476;
        resUpgr = 431182;
        break;
      case 377476:
        weaponUpgr = 377477;
        resUpgr = 431183;
        break;
      case 377477:
        weaponUpgr = 377481;
        resUpgr = 431184;
        break;
      case 377481:
        weaponUpgr = 377483;
        resUpgr = 431185;
        break;
      case 377483:
        weaponUpgr = 377484;
        resUpgr = 431186;
        break;
      // end Hammer fire
      // Hammer wind
      case 376145:
        weaponUpgr = 377486;
        resUpgr = 431182;
        break;
      case 377486:
        weaponUpgr = 377487;
        resUpgr = 431183;
        break;
      case 377487:
        weaponUpgr = 377488;
        resUpgr = 431184;
        break;
      case 377488:
        weaponUpgr = 377489;
        resUpgr = 431185;
        break;
      case 377489:
        weaponUpgr = 377490;
        resUpgr = 431186;
        break;
      // end Hammer wind
      // Hammer earth
      case 376151:
        weaponUpgr = 377492;
        resUpgr = 431182;
        break;
      case 377492:
        weaponUpgr = 378015;
        resUpgr = 431183;
        break;
      case 378015:
        weaponUpgr = 378017;
        resUpgr = 431184;
        break;
      case 378017:
        weaponUpgr = 378023;
        resUpgr = 431185;
        break;
      case 378023:
        weaponUpgr = 378025;
        resUpgr = 431186;
        break;
      // end Hammer earth

      // Mechanical Arm water
      case 376156:
        weaponUpgr = 382574;
        resUpgr = 431182;
        break;
      case 382574:
        weaponUpgr = 382575;
        resUpgr = 431183;
        break;
      case 382575:
        weaponUpgr = 382577;
        resUpgr = 431184;
        break;
      case 382577:
        weaponUpgr = 382578;
        resUpgr = 431185;
        break;
      case 382578:
        weaponUpgr = 382579;
        resUpgr = 431186;
        break;
      // end Mechanical Arm water
      // Mechanical Arm fire
      case 376162:
        weaponUpgr = 382580;
        resUpgr = 431182;
        break;
      case 382580:
        weaponUpgr = 382581;
        resUpgr = 431183;
        break;
      case 382581:
        weaponUpgr = 382588;
        resUpgr = 431184;
        break;
      case 382588:
        weaponUpgr = 382591;
        resUpgr = 431185;
        break;
      case 382591:
        weaponUpgr = 382594;
        resUpgr = 431186;
        break;
      // end Mechanical Arm fire
      // Mechanical Arm wind
      case 376172:
        weaponUpgr = 382604;
        resUpgr = 431182;
        break;
      case 382604:
        weaponUpgr = 382609;
        resUpgr = 431183;
        break;
      case 382609:
        weaponUpgr = 382613;
        resUpgr = 431184;
        break;
      case 382613:
        weaponUpgr = 382616;
        resUpgr = 431185;
        break;
      case 382616:
        weaponUpgr = 382620;
        resUpgr = 431186;
        break;
      // end Mechanical Arm wind
      // Mechanical Arm earth
      case 376178:
        weaponUpgr = 382621;
        resUpgr = 431182;
        break;
      case 382621:
        weaponUpgr = 382623;
        resUpgr = 431183;
        break;
      case 382623:
        weaponUpgr = 382627;
        resUpgr = 431184;
        break;
      case 382627:
        weaponUpgr = 382628;
        resUpgr = 431185;
        break;
      case 382628:
        weaponUpgr = 382629;
        resUpgr = 431186;
        break;
      // end Mechanical Arm earth

      // Slingshot water
      case 376198:
        weaponUpgr = 379070;
        resUpgr = 431182;
        break;
      case 379070:
        weaponUpgr = 379072;
        resUpgr = 431183;
        break;
      case 379072:
        weaponUpgr = 380265;
        resUpgr = 431184;
        break;
      case 380265:
        weaponUpgr = 380268;
        resUpgr = 431185;
        break;
      case 380268:
        weaponUpgr = 380270;
        resUpgr = 431186;
        break;
      // end Slingshot water
      // Slingshot fire
      case 376204:
        weaponUpgr = 380271;
        resUpgr = 431182;
        break;
      case 380271:
        weaponUpgr = 380272;
        resUpgr = 431183;
        break;
      case 380272:
        weaponUpgr = 380273;
        resUpgr = 431184;
        break;
      case 380273:
        weaponUpgr = 380275;
        resUpgr = 431185;
        break;
      case 380275:
        weaponUpgr = 380276;
        resUpgr = 431186;
        break;
      // end Slingshot fire
      // Slingshot wind
      case 376302:
        weaponUpgr = 380277;
        resUpgr = 431182;
        break;
      case 380277:
        weaponUpgr = 380280;
        resUpgr = 431183;
        break;
      case 380280:
        weaponUpgr = 380281;
        resUpgr = 431184;
        break;
      case 380281:
        weaponUpgr = 380284;
        resUpgr = 431185;
        break;
      case 380284:
        weaponUpgr = 380286;
        resUpgr = 431186;
        break;
      // end Slingshot wind
      // Slingshot earth
      case 376310:
        weaponUpgr = 382044;
        resUpgr = 431182;
        break;
      case 382044:
        weaponUpgr = 382046;
        resUpgr = 431183;
        break;
      case 382046:
        weaponUpgr = 382050;
        resUpgr = 431184;
        break;
      case 382050:
        weaponUpgr = 382052;
        resUpgr = 431185;
        break;
      case 382052:
        weaponUpgr = 382054;
        resUpgr = 431186;
        break;
      // end Slingshot earth

      // Stick water
      case 376320:
        weaponUpgr = 377302;
        resUpgr = 431182;
        break;
      case 377302:
        weaponUpgr = 377303;
        resUpgr = 431183;
        break;
      case 377303:
        weaponUpgr = 377304;
        resUpgr = 431184;
        break;
      case 377304:
        weaponUpgr = 377306;
        resUpgr = 431185;
        break;
      case 377306:
        weaponUpgr = 377307;
        resUpgr = 431186;
        break;
      // end Stick water
      // Stick fire
      case 376329:
        weaponUpgr = 377347;
        resUpgr = 431182;
        break;
      case 377347:
        weaponUpgr = 377352;
        resUpgr = 431183;
        break;
      case 377352:
        weaponUpgr = 377355;
        resUpgr = 431184;
        break;
      case 377355:
        weaponUpgr = 377359;
        resUpgr = 431185;
        break;
      case 377359:
        weaponUpgr = 377362;
        resUpgr = 431186;
        break;
      // end Stick fire
      // Stick wind
      case 376374:
        weaponUpgr = 377365;
        resUpgr = 431182;
        break;
      case 377365:
        weaponUpgr = 377368;
        resUpgr = 431183;
        break;
      case 377368:
        weaponUpgr = 377373;
        resUpgr = 431184;
        break;
      case 377373:
        weaponUpgr = 377377;
        resUpgr = 431185;
        break;
      case 377377:
        weaponUpgr = 377381;
        resUpgr = 431186;
        break;
      // end Stick wind
      // Stick earth
      case 376407:
        weaponUpgr = 377387;
        resUpgr = 431182;
        break;
      case 377387:
        weaponUpgr = 377392;
        resUpgr = 431183;
        break;
      case 377392:
        weaponUpgr = 377397;
        resUpgr = 431184;
        break;
      case 377397:
        weaponUpgr = 377401;
        resUpgr = 431185;
        break;
      case 377401:
        weaponUpgr = 377404;
        resUpgr = 431186;
        break;
      // end Stick earth

      default:
        console.log("FTF");
        weaponUpgr = 0;
        break;
    }
    var needRes =
      this.state.allUserWeapon[index][0].data.rarity == "Common"
        ? 431182
        : this.state.allUserWeapon[index][0].data.rarity == "Uncommon"
          ? 431183
          : this.state.allUserWeapon[index][0].data.rarity == "Rare"
            ? 431184
            : this.state.allUserWeapon[index][0].data.rarity == "Epic"
              ? 431185
              : 431186;
    //console.log("needRes", needRes);
    var indexRes;
    var needNum;
    for (let i = 0; i < this.state.allUserRes.length; i++) {
      if (this.state.allUserRes[i][0].template.template_id == needRes) {
        indexRes = i;
        needNum = this.state.allUserRes[i].length;
        //console.log("this.state.allUserRes[i]", this.state.allUserRes[i]);
      }
    }

    this.setState({
      ...this.state,
      openRecipe: true,
      activeWeaponName: this.state.allUserWeapon[index][0].data.name,
      activeRecipe: {
        arreyIndex: index,
        weapon: weaponUpgr,
        tempaleID: templateId,
        res: needRes,
        resNum: needNum,
        resIndex: indexRes,
      },
    });

    if (localStorage.getItem("sound") == "true") {
      this.audioMusicNewClick.currentTime = 0;
      this.audioMusicNewClick.volume = 0.4;
      this.audioMusicNewClick.play();
    }

    // this.GetPandaPartList(this.state.craftweapon[index].allelements[element]);
    // this.GetPandaWeaponList(this.state.craftweapon[index].allelements[element]);
  }

  async backFromRecipe(index, element) {
    this.setState({
      ...this.state,
      openRecipe: false,
      activeWeaponName: "",
      activeRecipe: {
        arreyIndex: "",
        arreyResIndex: "",
        weapon: "",
        res: "",
        tempaleID: "",
      },
    });

    if (localStorage.getItem("sound") == "true") {
      this.audioMusicNewClick.currentTime = 0;
      this.audioMusicNewClick.volume = 0.4;
      this.audioMusicNewClick.play();
    }
  }

  async opencraftmodal() {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("open-modal");
    const number = cryptoRandomString({ length: 14, type: "numeric" });
    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;

    // try {
    //   let rpcc;
    //   if (this.props.ual.activeUser.rpc != undefined) {
    //     rpcc = this.props.ual.activeUser.rpc;
    //   } else if (
    //     this.props.ual.activeUser.rpc == undefined &&
    //     this.props.ual.activeUser.wax
    //   ) {
    //     rpcc = this.props.ual.activeUser.wax.rpc;
    //   } else {
    //     rpcc = new JsonRpc(localStorage.getItem("rpc"));
    //   }
    //   //const userName = this.props.ual.activeUser.accountName;
    //   const results = await rpcc.get_table_rows({
    //     json: true,
    //     code: process.env.REACT_APP_CONTRACT, // contract who owns the table
    //     scope: process.env.REACT_APP_CONTRACT, // scope of the table
    //     table: "oracletable", // name of the table as specified by the contract abi
    //     //"key_type": "i64",
    //     lower_bound: userName,
    //     upper_bound: userName,
    //     //"limit": -1,
    //     //reverse: !0,
    //     show_payer: false,
    //     //index_position: 2,
    //   });
    //   const resultRow = results.rows;
    //   //console.log('resultRow', resultRow[0]);
    //   if (resultRow[0]) {
    //     if (resultRow[0].storer == userName) {
    //       this.setState({
    //         ...this.setState,
    //         modalcraft: {
    //           top: "upgrade",
    //           title: "Now the blacksmith is ready to start work",
    //           button: "second",
    //           descr:
    //             "hand over all parts of the weapon so that he can start working on his craft. It can take a few minutes, please wait...",
    //         },
    //       });
    //     }
    //   }
    // } catch (e) {
    //   // console.log(e.message)
    //   console.warn(e);
    //   this.setState({
    //     hasError: true,
    //     errorText: e.message,
    //   });
    //   console.log("\nCaught exception: " + e);
    //   if (localStorage.getItem("sound") == "true") {
    //     this.audioMusicError.currentTime = 0;
    //     this.audioMusicError.volume = 0.1;
    //     this.audioMusicError.play();
    //   }
    // }

    this.setState({
      ...this.setState,
      craftpay: true,
    });

    if (localStorage.getItem("sound") == "true") {
      this.audioMusicOpenCraft.currentTime = 0;
      this.audioMusicOpenCraft.volume = 0.3;
      this.audioMusicOpenCraft.play();
    }
  }

  async craftpay() {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");
    const number = cryptoRandomString({ length: 14, type: "numeric" });
    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;

    try {
      await activeUser
        .signTransaction(
          {
            actions: [
              {
                account: process.env.REACT_APP_TOKEN,
                name: "transfer",
                authorization: [
                  {
                    actor: userName, // use account that was logged in
                    permission: "active",
                  },
                ],
                data: {
                  from: userName,
                  to: process.env.REACT_APP_CONTRACT,
                  quantity: "15.0000 " + process.env.REACT_APP_VAL,
                  memo: "weaponpay " + this.state.activeRecipe.weapon + " work",
                },
              },
            ],
          },
          { blocksBehind: 3, expireSeconds: 30 }
        )
        .then((response) => {
          if (response.status === "executed") {
            loadingSlot.classList.remove("loading");
            this.setState({
              ...this.setState,
              modalcraft: {
                top: "upgrade",
                title: "Now the blacksmith is ready to start work",
                button: "second",
                descr:
                  "hand over all parts of the weapon so that he can start working on his craft. It can take a few minutes, please wait...",
              },
            });
            this.props.updateBalance(false, parseFloat(20.0).toFixed(4));
          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
        chooseHeroLoading: false,
      });

      if (localStorage.getItem("sound") == "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
      loadingSlot.classList.remove("loading");
    }
  }

  async sendWombat(event) {
    const activeUser = this.props.ual.activeUser;
    const accountName = await activeUser.getAccountName();
    var dateNow = parseInt(Date.now() / 1000);
    var datas = {

      "username": accountName,
      "event": event,
      "eventtime": dateNow,
    }

  }

  async craft() {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");
    const number = cryptoRandomString({ length: 14, type: "numeric" });
    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    var firstWeapon =
      this.state.allUserWeapon[this.state.activeRecipe.arreyIndex][0].asset_id;
    var secondWeapon =
      this.state.allUserWeapon[this.state.activeRecipe.arreyIndex][1].asset_id;

    var resource = [];

    this.state.allUserRes[this.state.activeRecipe.resIndex].map((u, index) => {
      //console.log("INDEX", index);
      if (index < 5) {
        resource.push(u.asset_id);
      }
      //console.log("u[0]", u.template.template_id)
    });

    try {
      await activeUser
        .signTransaction(
          {
            actions: [
              {
                account: "atomicassets",
                name: "transfer",
                authorization: [
                  {
                    actor: userName, // use account that was logged in
                    permission: "active",
                  },
                ],
                data: {
                  from: userName,
                  to: process.env.REACT_APP_CONTRACT,
                  asset_ids: [firstWeapon, secondWeapon, ...resource],
                  memo:
                    "upgrade " +
                    this.state.activeRecipe.weapon +
                    " " +
                    number +
                    " weapon for panda",
                },
              },
            ],
          },
          { blocksBehind: 3, expireSeconds: 30 }
        )
        .then((response) => {
          if (response.status === "executed") {
            loadingSlot.classList.remove("loading");
            this.props.updateBalance(false, parseFloat(15.0).toFixed(4));
            this.setState({
              ...this.setState,
              craftpay: false,
              modalResult: {
                open: true,
                loading: true,
              },
            });
            this.getresult();
            switch (this.state.activeRecipe.weapon) {
              case 376756: case 376768: case 376774: case 376794: case 376995: case 377000: case 377012: case 377286: case 378780: case 378787: case 378795:
              case 378815: case 378176: case 378296: case 378311: case 378322: case 382670: case 382680: case 382693: case 382724: case 377474: case 377483:
              case 377489: case 378023: case 382578: case 382591: case 382616: case 382628: case 380268: case 380275: case 380284: case 382052: case 377306:
              case 377359: case 377377: case 377401: case 378034: case 378075: case 378084: case 378090:

                this.sendWombat("craft");
                break;
              default:

                break;
            }
          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
        chooseHeroLoading: false,
      });
      if (localStorage.getItem("sound") == "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
      loadingSlot.classList.remove("loading");
    }
  }

  async getresult() {
    (async () => {
      try {
        //console.log('try');
        const userName = this.props.ual.activeUser.accountName;
        const activeUser = this.props.ual.activeUser;
        const weaponsID = this.state.activeRecipe.weapon;
        var perc = 55;
        if (this.state.contractMedal) {
          var perc = 55 + this.state.contractMedal;
        }

        this.interval = setInterval(() => {
          //console.log('setTimeout');
          if (this.state.craftfail <= perc && this.state.craftfail != 0) {
            clearInterval(this.interval);

            console.log("YES IT DONE");
            this.setState({
              ...this.state,
              craftResult: "success",
              modalResult: {
                ...this.state.modalResult,
                loading: false,
              },
            });
            if (localStorage.getItem("sound") == "true") {
              this.audioMusicCraftSuccess.currentTime = 0;
              this.audioMusicCraftSuccess.volume = 0.4;
              this.audioMusicCraftSuccess.play();
            }
          } else if (this.state.craftfail > perc) {
            console.log("NOOOOOOOOOOOOOOOOO");

            clearInterval(this.interval);
            this.setState({
              ...this.state,
              craftResult: "fail",
              modalResult: {
                ...this.state.modalResult,
                loading: false,
              },
            });

            if (localStorage.getItem("sound") == "true") {
              this.audioMusicCraftfail.currentTime = 0;
              this.audioMusicCraftfail.volume = 0.3;
              this.audioMusicCraftfail.play();
            }
          }

          // console.log("timer");
          // console.log("this.state.allThisWeapon", this.state.allThisWeapon);
          // console.log("assets", assets);
          // console.log("this.state.craftfail", this.state.craftfail);

          //this.GetPandaWeaponList(this.state.activeRecipe);
          this.getUserNoRes();
        }, 10000);
      } catch (e) {
        //console.log(e.message)
        console.warn(e);
        this.setState({
          ...this.state,
          hasError: true,
          errorText: e.message,
          modalEat: {
            ...this.state.modalEat,
            loadingChooseEat: false,
          },
        });
        console.log("\nCaught exception: " + e);
        if (localStorage.getItem("sound") == "true") {
          this.audioMusicError.currentTime = 0;
          this.audioMusicError.volume = 0.1;
          this.audioMusicError.play();
        }
      }
    })();
  }

  async getUserNoRes() {
    const number = cryptoRandomString({ length: 14, type: "numeric" });
    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;

    try {
      let rpcc;
      if (this.props.ual.activeUser.rpc != undefined) {
        rpcc = this.props.ual.activeUser.rpc;
      } else if (
        this.props.ual.activeUser.rpc == undefined &&
        this.props.ual.activeUser.wax
      ) {
        rpcc = this.props.ual.activeUser.wax.rpc;
      } else {
        rpcc = new JsonRpc(localStorage.getItem("rpc"));
      }
      //const userName = this.props.ual.activeUser.accountName;
      const results = await rpcc.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "usersnew", // name of the table as specified by the contract abi
        //"key_type": "i64",
        lower_bound: userName,
        upper_bound: userName,
        //"limit": -1,
        //reverse: !0,
        show_payer: false,
        //index_position: 2,
      });
      const resultRow = results.rows;
      //console.log('resultRow getUserNoRes', resultRow[0]);
      if (resultRow[0]) {
        this.setState({
          ...this.setState,
          craftfail: resultRow[0].random,
        });
      }
    } catch (e) {
      // console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
      if (localStorage.getItem("sound") == "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  async closeModalResult() {
    console.log("close");
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.remove("open-modal");
    this.setState({
      ...this.state,
      allparts: {}, // все части этого оружия который хотят скрафтить
      allThisWeapon: {}, // все оружия
      modalResult: {
        open: false, // показать модалку результата
        loading: true, // ожидание результата
      },
      craftpay: false, //
      modalcraft: {
        top: "Upgrade weapon",
        title: "The blacksmith is ready to start work",
        button: "first",
        descr:
          `The blacksmith always takes money <span>(15 ${process.env.REACT_APP_VAL})<i></i></span> for the front, since nothing motivates like a salary. `,
      },
      craftfail: "",
      craftResult: "",
      openRecipe: false,
    });

    setTimeout(() => {
      this.getAllNft();
    }, 300);
  }

  async closeModalSteps() {
    this.setState({
      ...this.setState,
      craftpay: false,
    });
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.remove("open-modal");
    //console.log('closeModalSteps');
    this.getAllNft();
  }

  navpage(goto) {
    if (goto == "next") {
      this.setState({
        activePage: this.state.activePage + 1,
      });
    } else {
      this.setState({
        activePage: this.state.activePage - 1,
      });
    }
  }

  render() {
    return (
      <>
        <Menu  {...this.props.ual} wax={this.props.balanceWax} bam={this.props.balanceBam} game={this.props.balanceGame} updateBalance={this.props.updateBalance} />
        {this.state.hasError ? (
          <ErrorComponent
            errorMessage={this.state.errorText}
            clearError={this.clearError}
          />
        ) : null}
        <main className="craft-page">
          <div className="loading-block"></div>

          <div className="craft-wrap upgrade-page-bg upgr-jew">
            {this.state.openRecipe ? null : (
              <div className="craft-body-choose">
                <div className="choose-weapon-upgade">
                  <div
                    className={`choose-weapon-upgade-in ${this.state.loadingNft ? "loading" : "ok"
                      }`}
                  >
                    <Link className="bt-back" to="/craft">
                      <span></span>
                    </Link>

                    <div
                      className="bt-reload"
                      onClick={(e) => {
                        this.getAllNft();
                      }}
                    >
                      <span></span>
                    </div>

                    <UpgradeHomeLoop
                      {...this.props}
                      {...this.state}
                      openRecipe={this.openRecipe}
                    />

                    {this.state.loadingNft &&
                      this.state.allUserWeapon &&
                      !this.state.allUserWeapon.length && (
                        <div className="loading-block"></div>
                      )}
                  </div>

                  <div className="upgr-nav">
                    <div className="upgr-nav-in">
                      {this.state.countPage > 1 && this.state.activePage > 1 ? (
                        <button
                          className="nav-link nav-prev"
                          onClick={() => this.navpage("prev")}
                        >
                          <span></span>
                        </button>
                      ) : null}

                      <div className="nav-num">{this.state.activePage}</div>

                      {this.state.countPage > 1 &&
                        this.state.countPage >= this.state.activePage &&
                        this.state.countPage != this.state.activePage ? (
                        <button
                          className="nav-link nav-next"
                          onClick={() => this.navpage("next")}
                        >
                          <span></span>
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {this.state.openRecipe ? (
              <div className="upgr-recipe">
                <div className="upgr-recipe-in">
                  <div className="upgr-recipe-top">
                    <div className="bt-back">
                      <span
                        onClick={(e) => {
                          this.backFromRecipe();
                        }}
                      ></span>
                    </div>
                  </div>
                  <div className="upgr-recipe-wrap">
                    <div
                      className={`runa ${this.state.allUserWeapon[
                        this.state.activeRecipe.arreyIndex
                      ][0] &&
                        this.state.allUserWeapon[
                        this.state.activeRecipe.arreyIndex
                        ][1] &&
                        this.state.activeRecipe.resNum >= 5
                        ? "active"
                        : null
                        }`}
                    ></div>

                    <div className="upgr-recipe-wrap-in">
                      {/* one */}
                      <div
                        className={`one-card-upgr ${this.state.allUserWeapon[
                          this.state.activeRecipe.arreyIndex
                        ][0]
                          ? "active"
                          : "disable"
                          }`}
                      >
                        <div className="one-card-upgr-in">
                          <div className="one-card-upgr-thumb">
                            <picture>
                              <source
                                srcSet={`./images/jew/all/${this.state.activeRecipe.res}.webp`}
                                type="image/webp"
                              />
                              <source
                                srcSet={`./images/jew/all/${this.state.activeRecipe.res}.png`}
                                type="image/png"
                              />
                              <img
                                src={`./images/jew/all/${this.state.activeRecipe.res}.png`}
                                alt=""
                              />
                            </picture>
                          </div>

                          {!this.state.allUserWeapon[
                            this.state.activeRecipe.arreyIndex
                          ][0] ? (
                            <div className="btns-bay-craft">
                              <div className="one-bt-bc">
                                <a
                                  className="def-bt mid"
                                  href="https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&schema_name=weapon&sort=created&symbol=WAX"
                                >
                                  <span className="button-in">
                                    <span className="button-name">
                                      <span className="button-name-in">
                                        <span className="button-name-value">
                                          BUY
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </a>
                              </div>
                            </div>
                          ) : null}

                          <div className="part-num">
                            {this.state.activeRecipe.resNum}
                          </div>

                          <div className="need-parts">
                            {this.state.activeRecipe.resNum <= 5
                              ? this.state.activeRecipe.resNum
                              : "5"}
                            /5
                          </div>
                        </div>

                        <div
                          className={`dots-parts ${this.state.activeRecipe.resNum >= 5
                            ? "act-5"
                            : this.state.activeRecipe.resNum == 4
                              ? "act-4"
                              : this.state.activeRecipe.resNum == 3
                                ? "act-3"
                                : this.state.activeRecipe.resNum == 2
                                  ? "act-2"
                                  : this.state.activeRecipe.resNum == 1
                                    ? "act-1"
                                    : "act-0"
                            }`}
                        >
                          <div className="dots-parts-in">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                          </div>
                        </div>
                      </div>
                      {/* / one */}
                      {/* one */}
                      <div
                        className={`one-card-upgr ${this.state.allUserWeapon[
                          this.state.activeRecipe.arreyIndex
                        ][0]
                          ? "active"
                          : "disable"
                          }`}
                      >
                        <div className="one-card-upgr-in">
                          <div className="one-card-upgr-thumb">
                            <picture>
                              <source
                                srcSet={`./images/weapons/all/${this.state.activeRecipe.tempaleID}.webp`}
                                type="image/webp"
                              />
                              <source
                                srcSet={`./images/weapons/all/${this.state.activeRecipe.tempaleID}.png`}
                                type="image/png"
                              />
                              <img
                                src={`./images/weapons/all/${this.state.activeRecipe.tempaleID}.png`}
                                alt=""
                              />
                            </picture>
                          </div>

                          {!this.state.allUserWeapon[
                            this.state.activeRecipe.arreyIndex
                          ][0] ? (
                            <div className="btns-bay-craft">
                              <div className="one-bt-bc">
                                <NavLink
                                  className="def-bt mid"
                                  to="/craft-weapon-page"
                                >
                                  <span className="button-in">
                                    <span className="button-name">
                                      <span className="button-name-in">
                                        <span className="button-name-value">
                                          craft
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </NavLink>
                              </div>
                              <div className="one-bt-bc">
                                <a
                                  className="def-bt mid"
                                  target="_blank"
                                  href={`https://wax.atomichub.io/market?collection_name=nftpandawaxp&data:text.rarity=Rare&match=${this.state.activeWeaponName.replace(
                                    / /g,
                                    "%20"
                                  )}&order=desc&schema_name=weapon&sort=created&symbol=WAX`}
                                >
                                  <span className="button-in">
                                    <span className="button-name">
                                      <span className="button-name-in">
                                        <span className="button-name-value">
                                          BUY
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </a>
                              </div>
                            </div>
                          ) : null}

                          <div className="part-num">
                            {
                              this.state.allUserWeapon[
                                this.state.activeRecipe.arreyIndex
                              ].length
                            }
                          </div>

                          <div className="need-parts">
                            {this.state.allUserWeapon[
                              this.state.activeRecipe.arreyIndex
                            ].length <= 2
                              ? this.state.allUserWeapon[
                                this.state.activeRecipe.arreyIndex
                              ].length
                              : "2"}
                            /2
                          </div>
                        </div>

                        <div
                          className={`dots-parts ${this.state.allUserWeapon[
                            this.state.activeRecipe.arreyIndex
                          ].length >= 2
                            ? "act-2"
                            : this.state.allUserWeapon[
                              this.state.activeRecipe.arreyIndex
                            ].length == 1
                              ? "act-1"
                              : "act-0"
                            }`}
                        >
                          <div className="dots-parts-in">
                            <span></span>
                            <span></span>
                          </div>
                        </div>
                      </div>
                      {/* / one */}
                    </div>
                  </div>

                  <div className="upgr-bt-do-upg">
                    <div className="upgr-bt">
                      {this.state.allUserWeapon[
                        this.state.activeRecipe.arreyIndex
                      ][0] &&
                        this.state.allUserWeapon[
                        this.state.activeRecipe.arreyIndex
                        ][1] &&
                        this.state.activeRecipe.resNum >= 5 ? (
                        <span
                          onClick={(e) => {
                            this.opencraftmodal();
                          }}
                        ></span>
                      ) : (
                        <span className="disabled"></span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <div
              className={`modal-result ${this.state.modalResult.open ? "open" : null
                } ${this.state.craftResult == "success" ? "success" : null} ${this.state.craftResult == "fail" ? "fail" : null
                }`}
            >
              <div className="modal-result-body">
                <div className="modal-result-body-in">
                  <div className="modal-result-head">
                    {!this.state.modalResult.loading ? (
                      <div
                        className="close-result"
                        onClick={(e) => {
                          this.closeModalResult();
                        }}
                      >
                        <span></span>
                      </div>
                    ) : null}
                    <div className="weapon-modal-name">
                      {this.state.activeWeaponName}
                    </div>
                    <div className="weapon-modal-wrap">
                      <div className="fail-res"></div>
                      <div className="success-res"></div>
                      {this.state.modalResult.loading ? (
                        <div className="loading-res"></div>
                      ) : null}
                      <div className="weapon-modal-img">
                        {this.state.activeRecipe.weapon ? (
                          <picture>
                            <source
                              srcSet={`/images/weapons/all/${this.state.activeRecipe.weapon}.webp`}
                              type="image/webp"
                            />
                            <source
                              srcSet={`/images/weapons/all/${this.state.activeRecipe.weapon}.png`}
                              type="image/png"
                            />
                            <img
                              src={`/images/weapons/all/${this.state.activeRecipe.weapon}.png`}
                              alt=""
                            />
                          </picture>
                        ) : null}
                      </div>
                      {!this.state.modalResult.loading ? (
                        <div className="close-bt">
                          <button
                            className="def-bt min"
                            onClick={(e) => {
                              this.closeModalResult();
                            }}
                          >
                            <span className="button-in">
                              <span className="button-name">
                                <span className="button-name-in">
                                  <span className="button-name-value">
                                    close
                                  </span>
                                </span>
                              </span>
                            </span>
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="modal-result-close-trans"></div>
              </div>
              <div className="modal-weapon-bg"></div>
            </div>

            <div
              className={`modal-result craftpay ${this.state.craftpay ? "open" : null
                }`}
            >
              <div className="modal-result-body">
                <div className="modal-result-body-in">
                  <div className="modal-result-head">
                    <div
                      className="close-result"
                      onClick={(e) => {
                        this.closeModalResult();
                      }}
                    >
                      <span></span>
                    </div>

                    <div className="weapon-modal-name">
                      {this.state.modalcraft.top}
                    </div>
                    <div className="weapon-modal-wrap">
                      <div className="weapon-modal-img">
                        <div className="step-wrap">
                          <div className="step-title">
                            {this.state.modalcraft.title}
                          </div>
                          <div className="step-loader">
                            <div className="loading-res"></div>
                            <div className="step-bt">
                              {/* {this.state.modalcraft.button !== "first" ?  */}
                              <button
                                className="def-bt min"
                                onClick={(e) => {
                                  this.craft();
                                }}
                              >
                                <span className="button-in">
                                  <span className="button-name">
                                    <span className="button-name-in">
                                      <span className="button-name-value">
                                        upgrade
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </button>
                              {/* :
                                                            <button className="def-bt min" onClick={(e) => {this.craft()}}>
                                                                <span className="button-in">
                                                                    <span className="button-name">
                                                                        <span className="button-name-in">
                                                                            <span className="button-name-value">
                                                                                upgarde
                                                                            </span> 
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </button>
                                                        } */}
                            </div>
                          </div>
                          {this.state.modalcraft.button == "first" ? (
                            <div className="info-55">
                              <span>Attention:</span> the chance of successful{" "}
                              <br /> crafting is{" "}
                              <i>
                                {this.state.contractMedal
                                  ? 55 + this.state.contractMedal
                                  : "55"}
                                %
                              </i>
                            </div>
                          ) : null}
                          <div
                            className="step-descr"
                            dangerouslySetInnerHTML={{
                              __html: this.state.modalcraft.descr,
                            }}
                          >
                            {/* {this.state.modalcraft.descr} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-result-close-trans"></div>
              </div>
              <div className="modal-weapon-bg"></div>
            </div>
          </div>

          <footer>
            <div className="footer-nav">
              <nav>
                <ul>
                  <li>
                    <a
                      className="foot-logo-1"
                      target="_blank"
                      href="https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&sort=created&symbol=WAX"
                    >
                      <span></span>
                      <i>Market</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-2"
                      target="_blank"
                      href="https://twitter.com/NftPanda/"
                    >
                      <span></span>
                      <i>Twitter</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-3"
                      target="_blank"
                      href="https://www.instagram.com/nftpanda/"
                    >
                      <span></span>
                      <i>Instagram</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-4"
                      target="_blank"
                      href="https://discord.gg/vCSgme4d5b"
                    >
                      <span></span>
                      <i>Discord</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-5"
                      target="_blank"
                      href={`https://wax.alcor.exchange/swap?output=${process.env.REACT_APP_VAL}-nftpandabamb&input=WAX-eosio.token`}
                    >
                      <span></span>
                      <i>{process.env.REACT_APP_VAL} Trade</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-6"
                      target="_blank"
                      href="https://discord.gg/vCSgme4d5b"
                    >
                      <span></span>
                      <i>Support</i>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </footer>
        </main>
      </>
    );
  }
}

// let mapStateToProps = (state) => ({
//     allinvent: state.inventoryReduser.allinvent,
// });

let mapStateToProps = (state) => ({
  /*allWeapon: state.weaponReduser.allWeapon,
    statusWeapon: state.weaponReduser.statusWeapon,*/
});

export default connect(mapStateToProps, {
  /*weaponlist,statusLoadinWeapon, addWeaponWhenRemove, addWeaponToPanda,*/
})(UpgradePageComponentArmor);
