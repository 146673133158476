const ARMORLIST = 'ARMORLIST';
const WAS_LOADING_ARMOR = 'WAS_LOADING_ARMOR';
const ADD_ARMOR_WHEN_REMOVE = 'ADD_ARMOR_WHEN_REMOVE';
const ADD_ARMOR_TO_PANDA = 'ADD_ARMOR_TO_PANDA';
const ARMOR_ON_PANDA = 'ARMOR_ON_PANDA';

let initialState = {
    allArmor: null,
    /*wasbay: false,*/
    statusArmor: false,
    pandaArmor: null
};

const armorReduser = (state = initialState, action) => {

    switch(action.type) {
       
        case ARMORLIST: {
           //console.log('ARMORLIST', action.armorlist);
            return {...state, allArmor: action.armorlist}
        }
       
        case ARMOR_ON_PANDA: {
           // console.log('EATLIST', action.eatlist);
            return {...state, pandaArmor: action.pandaArmor}
        }
        case ADD_ARMOR_WHEN_REMOVE: {
            //console.log('ADD_ARMOR_WHEN_REMOVE', action.armor);
            var new_obj = {
                asset_id: action.armor.asset_id,
                template: {
                    template_id: action.armor.tmpl
                },
                data: {
                    rarity:  action.armor.rarity,
                    element:  action.armor.element,
                    type:  'armorelry',
                    name:  'armorelry',
                }
            }
            return {
                ...state,
                allArmor: [new_obj].concat(state.allArmor),
            }
            
        }
        case ADD_ARMOR_TO_PANDA: {
            console.log('action.armor', action.armor);
            return { ...state, allArmor: state.allArmor.filter(n => n.asset_id !== action.armor).map( u =>  {
                return u;
                })
            }
        }
        case WAS_LOADING_ARMOR: {
            //console.log('EATLIST', action.eatlist);
            return {...state, statusArmor: action.status}
        }
        

        default:
            return state;
    }
}

export const armorlist = (armorlist) => ({type: ARMORLIST, armorlist })
export const setPandaArmor = (pandaArmor) => ({type: ARMOR_ON_PANDA, pandaArmor })
export const statusLoadinArmor = (status) => ({type: WAS_LOADING_ARMOR, status })
export const addArmorWhenRemove = (armor) => ({type: ADD_ARMOR_WHEN_REMOVE,  armor })
export const addArmorToPanda = (armor) => ({type: ADD_ARMOR_TO_PANDA,  armor })

export default armorReduser;
