import React from 'react';

import map from '../../../images/new-adv/map-bg.jpg';
import mapw from '../../../images/new-adv/map-bg.webp';
import Newmap from './Newmap';

const Map = (props) => {

    return (
        <>

            {props.newAdvMap.loading ? <div className="map-loading"></div> : null}

            <div className={`wrap-new-adv ${props.newAdvMap.anim ? "anim" : null}  ${props.newAdvMap.animCloud ? "anim-cloud" : null}  ${props.newAdvMap.showModalInside ? "open-other-modal" : null}`}>

                <div className="back-bt" onClick={() => props.openNewAdvMap('close', "no-dispach")}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120.48 120.48"><defs></defs><g id="Слой_2" data-name="Слой 2"><g id="Слой_1-2" data-name="Слой 1"><path className="cls-back-1" d="M60.24,0A60.22,60.22,0,0,0,18.13,103.29l14.27-10-4.14,18A60.24,60.24,0,1,0,60.24,0Z" /><path className="cls-back-2" d="M57.1,9c-29.44,0-47,22.68-47,52.12,0,14.93,3.47,12.51,13.36,22.19l7.36.24,3-1.25C42,87.49,48,102,58.38,102c29.44,0,52-10.24,52-39.68A53.31,53.31,0,0,0,57.1,9ZM93,89.09,88.41,86l-.79-.5c-4.73-3-9.47-5.94-14.19-8.95C70,74.38,66.66,72.18,63.29,70c-2.85-1.86-5.16-8.62-8-10.49-.42-.27-1.51,3.71-2.13,3.39,0,8.5.1,17.5.1,26.21-1.69-1-3.14-1.84-4.56-2.72-.51-.32-.94-.79-1.45-1.11-4.59-2.92-9.2-5.8-13.78-8.72-3.4-2.17-6.77-4.37-10.15-6.58-2.89-1.89-5.77-3.81-8.67-5.69a7.82,7.82,0,0,0-1.39-.58v-.63l8.31-5.25q6.5-4.11,13-8.24,4.83-3.09,9.61-6.25c2.36-1.56,4.69-3.18,7-4.75.56-.37,1.16-.68,2-1.14V63.14c5.48-3.47,10.74-6.78,16-10.14,2.77-1.78,5.49-3.66,8.27-5.45,4.22-2.72,8.47-5.38,12.7-8.09.93-.6,1.8-1.32,2.92-2.15Z" /><path className="cls-back-3" d="M60.1,2.94A53.3,53.3,0,0,0,22.84,94.35l12.63-8.83-3.67,15.9A53.31,53.31,0,1,0,60.1,2.94ZM96,83l-4.62-3.09-.79-.5c-4.73-3-9.47-5.94-14.19-9-3.4-2.16-6.77-4.36-10.14-6.57-2.85-1.86-5.68-3.76-8.53-5.63-.42-.27-.89-.46-1.51-.78V83c-1.69-1-3.14-1.84-4.56-2.73-.51-.31-.94-.78-1.45-1.11-4.59-2.91-9.2-5.79-13.78-8.72-3.4-2.16-6.77-4.36-10.15-6.57C23.42,62,20.54,60,17.64,58.16a7.82,7.82,0,0,0-1.39-.58V57l8.31-5.25c4.33-2.75,8.66-5.48,13-8.24q4.83-3.09,9.61-6.25c2.36-1.56,4.69-3.18,7-4.75.56-.37,1.16-.68,2-1.14V57c5.48-3.47,10.74-6.78,16-10.14,2.77-1.78,5.49-3.67,8.27-5.45,4.22-2.72,8.47-5.38,12.7-8.09.93-.6,1.8-1.32,2.92-2.15Z" /></g></g></svg>
                </div>

                

                        <Newmap showCloseToAdv={props.showCloseToAdv} showModalInside={props.newAdvMap.showModalInside} />
            </div>
        </>);
}

export default Map;