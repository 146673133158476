import React from 'react';
import modaltop from '../../images/new-adv/modal-top.svg';
import modalbot from '../../images/new-adv/modal-bot.svg';

import medal10 from '../../images/artefacts/medal-top-10.png';
import medal10w from '../../images/artefacts/medal-top-10.webp';
import medal100 from '../../images/artefacts/medal-top-100.png';
import medal100w from '../../images/artefacts/medal-top-100.webp';
import medal300 from '../../images/artefacts/medal-top-300.png';
import medal300w from '../../images/artefacts/medal-top-300.webp';

const ModalMedal = (props) => {

    var totalLuck = 0;
    if (props.contractMedal.medal100) {
        totalLuck = 5;
        if (props.contractMedal.medal10) {
            totalLuck += 2.5;
            if (props.contractMedal.medal300) {
                totalLuck += 1.5;
            }
        } else if (props.contractMedal.medal300) {
            totalLuck += 1.5;
        }
    } else if (props.contractMedal.medal10) {
        totalLuck = 5;
        if (props.contractMedal.medal100) {
            totalLuck += 2.5;
            if (props.contractMedal.medal300) {
                totalLuck += 1.5;
            }
        } else if (props.contractMedal.medal300) {
            totalLuck += 1.5;
        }
    } else if (props.contractMedal.medal300) {
        totalLuck = 3;
        if (props.contractMedal.medal10) {
            totalLuck += 2.5;
            if (props.contractMedal.medal100) {
                totalLuck += 2.5;
            }
        } else if (props.contractMedal.medal100) {
            totalLuck += 2.5;
        }
    }
       
    return (
    <>
        
        <div className={`modal-to-adv open ${props.addMedal.anim ? " anim" : null}`}>
            
            <div className="modal-to-adv-body">
                <div className="loading-block"></div>
                <div className="modal-to-adv-body-in">

                    <div className="modal-to-adv-head">
                        <div className="modal-to-adv-top">
                            {/* <div className="modal-to-adv-close" onClick={() => props.showCloseMedal('close')}>
                                <svg width="86" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76.11 48.28"><defs></defs><g id="Слой_2" data-name="Слой 2"><g id="Приключение_копия_" data-name="Приключение (копия)"><g id="Модалка_копия_" data-name="Модалка (копия)"><g id="Слой_5_копия_" data-name="Слой 5 (копия)"><g id="Слой_2_копия_5" data-name="Слой 2 копия 5"><path className="cls-back-1" d="M71.34,4.09H4.76C2.13,4.09,0,5.61,0,7.49v33.3c0,1.88,2.13,3.4,4.76,3.4H71.34c2.63,0,4.77-1.52,4.77-3.4V7.49C76.11,5.61,74,4.09,71.34,4.09Z"/></g><g id="Слой_2_копия_5-2" data-name="Слой 2 копия 5"><rect className="cls-back-2" x="5.38" width="65.35" height="48.28" rx="5"/><rect className="cls-back-3" x="11.57" y="2.93" width="52.01" height="39.02"/><g id="_1btMbR.tif" data-name="1btMbR.tif"><path className="cls-back-4" d="M17.19,22.23l4-2.53c2.09-1.33,4.18-2.64,6.26-4q2.32-1.49,4.63-3c1.14-.75,2.26-1.53,3.4-2.29.26-.18.55-.32.94-.55v12.4c2.64-1.67,5.18-3.27,7.71-4.89,1.34-.86,2.65-1.76,4-2.62,2-1.31,4.08-2.6,6.12-3.9.45-.29.87-.64,1.41-1v25l-2.23-1.49L53,33.05c-2.28-1.44-4.57-2.87-6.84-4.32-1.64-1-3.27-2.1-4.89-3.16-1.38-.9-2.74-1.82-4.11-2.72-.21-.13-.43-.22-.73-.38V34.78c-.81-.49-1.51-.89-2.2-1.32-.25-.15-.45-.37-.7-.53-2.21-1.41-4.43-2.8-6.64-4.2Q24.47,27.16,22,25.56c-1.4-.91-2.79-1.84-4.18-2.75a4,4,0,0,0-.67-.28Z"/></g></g></g></g></g></g></svg>
                            </div> */}
                            <img src={`${modaltop}`} alt="" />
                            
                        </div>

                        <div className="modal-to-adv-mid">
                            <div className="medals-wrap">

                                <div className={`one-medal ${props.contractMedal.medal10 ? 'have active' : props.atomicMedal.medal10 ? 'have' : 'no'}`}>
                                    <div className="one-medal-in">
                                        <div className="one-medal-thumb">
                                            <picture>
                                                <source srcSet={medal10w} type="image/webp" />
                                                <source srcSet={medal10} type="image/png" /> 
                                                <img src={medal10} alt="" />
                                            </picture>
                                            <div className="buy-medal">
                                                <a href="https://wax.atomichub.io/market?collection_name=nftpandawaxp&match=Diamond%20Medallion&order=desc&schema_name=awards&sort=created&symbol=WAX" target="_blank" className="def-bt mini">
                                                    <span className="button-in">
                                                        <span className="button-name">
                                                            <span className="button-name-in">
                                                                <span className="button-name-value">
                                                                    buy medal
                                                                </span> 
                                                            </span>
                                                        </span>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="one-medal-check">
                                            <div className="one-medal-check-in have active">
                                            {props.contractMedal.medal10 ? 
                                                    <span  onClick={() => props.sendMedalOut(props.contractMedal.medal10, 10)}>
                                                        activated
                                                    </span>
                                                :
                                                props.atomicMedal.medal10 ? 
                                                    <span  onClick={() => props.sendMedal(props.atomicMedal.medal10, 10)}>
                                                        inactive
                                                    </span>
                                                : 
                                                    <span>
                                                        inactive
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                        <div className="one-medal-info">
                                            <div className="one-medal-tit">
                                                Top 10 medal
                                            </div>
                                            <div className="one-medal-text">
                                                <span>+5%</span> luck for craft
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={`one-medal ${props.contractMedal.medal100 ? 'have active' : props.atomicMedal.medal100 ? 'have' : 'no'}`}>
                                    <div className="one-medal-in">
                                        <div className="one-medal-thumb">
                                            <picture>
                                                <source srcSet={medal100w} type="image/webp" />
                                                <source srcSet={medal100} type="image/png" /> 
                                                <img src={medal100} alt="" />
                                            </picture>
                                            <div className="buy-medal">
                                                <a href="https://wax.atomichub.io/market?collection_name=nftpandawaxp&match=Gold%20Medallion&order=desc&schema_name=awards&sort=created&symbol=WAX" target="_blank" className="def-bt mini">
                                                    <span className="button-in">
                                                        <span className="button-name">
                                                            <span className="button-name-in">
                                                                <span className="button-name-value">
                                                                    buy medal
                                                                </span> 
                                                            </span>
                                                        </span>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="one-medal-check">
                                            <div className="one-medal-check-in">
                                                {props.contractMedal.medal100 ? 
                                                    <span  onClick={() => props.sendMedalOut(props.contractMedal.medal100, 100)}>
                                                        activated
                                                    </span>
                                                :
                                                props.atomicMedal.medal100 ? 
                                                    <span  onClick={() => props.sendMedal(props.atomicMedal.medal100, 100)}>
                                                        inactive
                                                    </span>
                                                : 
                                                    <span>
                                                        inactive
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                        <div className="one-medal-info">
                                            <div className="one-medal-tit">
                                                Top 100 medal
                                            </div>
                                            <div className="one-medal-text">
                                                <span>+5%</span> luck for craft
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={`one-medal ${props.contractMedal.medal300 ? 'have active' : props.atomicMedal.medal300 ? 'have' : 'no'}`}>
                                    <div className="one-medal-in">
                                        <div className="one-medal-thumb">
                                            <picture>
                                                <source srcSet={medal300w} type="image/webp" />
                                                <source srcSet={medal300} type="image/png" /> 
                                                <img src={medal300} alt="" />
                                            </picture>
                                            <div className="buy-medal">
                                                <a href="https://wax.atomichub.io/market?collection_name=nftpandawaxp&match=Silver%20Medallion&order=desc&schema_name=awards&sort=created&symbol=WAX" target="_blank" className="def-bt mini">
                                                    <span className="button-in">
                                                        <span className="button-name">
                                                            <span className="button-name-in">
                                                                <span className="button-name-value">
                                                                    buy medal
                                                                </span> 
                                                            </span>
                                                        </span>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="one-medal-check">
                                            <div className="one-medal-check-in">
                                                {props.contractMedal.medal300 ? 
                                                    <span  onClick={() => props.sendMedalOut(props.contractMedal.medal300, 300)}>
                                                        activated
                                                    </span>
                                                :
                                                props.atomicMedal.medal300 ? 
                                                    <span  onClick={() => props.sendMedal(props.atomicMedal.medal300, 300)}>
                                                        inactive
                                                    </span>
                                                : 
                                                    <span>
                                                        inactive
                                                    </span>
                                                }
                                                
                                            </div>
                                        </div>
                                        <div className="one-medal-info">
                                            <div className="one-medal-tit">
                                                Top 300 medal
                                            </div>
                                            <div className="one-medal-text">
                                                <span>+3%</span> luck for craft
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="total-medal">
                                Your total additional luck: <span>{totalLuck}%</span>
                            </div>
                        </div>

                        <div className="modal-to-adv-bot">
                            <img src={`${modalbot}`} alt="" />

                            <div className="modal-to-adv-bt">
                                <button className="def-bt norm" onClick={() => props.showCloseMedal()}>
                                    <span className="button-in">
                                        <span className="button-name">
                                            <span className="button-name-in">
                                                <span className="button-name-value">
                                                    close
                                                </span> 
                                            </span>
                                        </span>
                                    </span>
                                </button> 
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal-weapon-bg-trans" onClick={() => props.showCloseMedal()}></div>
            </div>
            
            <div className="modal-weapon-bg" ></div>
        </div> 
        
    </>);
}

export default ModalMedal;