import React from 'react';

import {connect} from "react-redux";
import Calculator from './Calculator'; 


class CalculatorComponent extends React.Component {

    constructor(props) {
        super(props)
    
        this.state = {
            rarNft: [0,1,2,3,4,5,6],
            numberNft: [6600,4000,3240,1200,720,180,60],
            allCardCount: 16000, // общее число панд
            baz: 0.05, // ставка которая влияет на награду
            rang: [0,1,2,3,4,5,6,7,8,9,10,11], // ставка которая влияет на награду
            steps: [8,13,21,34,55,89,144,233,377,610,987,1597], // ставка которая влияет на награду
            percStake: 1, // Какой процент от заработанных токенов нужно стейкать
            rangPercProfit: [0.02,0.03,0.05,0.08,0.13,0.21,0.34,0.55,0.89,1.44,2.33,3.77]
        }
        
    }

    componentDidMount() {
       
    }

    render() {
        return (<>
           <main className="slot-page">
                <section id="staking-page">
                    <div className="staking-in table-top">
                        <div className="staking-title">
                            CALCULATOR DEMO
                        </div>
                        <div className="staking-descr">
                            panda profit
                        </div>
                        {/* <div className="sub-st-title">
                            Title
                        </div> */}
                        <div className="staking-table">
                            <div className="staking-table-in">
                                <Calculator {...this.state} />
                            </div>
                        </div>
                    </div>                
                </section>
           </main>
            
        </>);
    }
}

let mapStateToProps = (state) => ({
    
});

export default connect(mapStateToProps, {})(CalculatorComponent);