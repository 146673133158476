const WEAPONLIST = 'WEAPONLIST';
const WAS_LOADING_WEAPON = 'WAS_LOADING_WEAPON';
const ADD_WEAPON_WHEN_REMOVE = 'ADD_WEAPON_WHEN_REMOVE';
const ADD_WEAPON_TO_PANDA = 'ADD_WEAPON_TO_PANDA';
const WEAPON_ON_PANDA = 'WEAPON_ON_PANDA';

let initialState = {
    allWeapon: [],
    /*wasbay: false,*/
    statusWeapon: false,
    pandaWeapon: null
};

const weaponReduser = (state = initialState, action) => {

    switch(action.type) {
       
        case WEAPONLIST: {
           // console.log('EATLIST', action.eatlist);
            return {...state, allWeapon: action.weaponlist}
        }
       
        case WEAPON_ON_PANDA: {
           // console.log('EATLIST', action.eatlist);
            return {...state, pandaWeapon: action.pandaWeapon}
        }
        case ADD_WEAPON_WHEN_REMOVE: {
            console.log('ADD_WEAPON_WHEN_REMOVE', action.weapon);
            var objAtomic = {
                asset_id: action.weapon.asset_id,
                template: {template_id: action.weapon.tmpl},
                data: {
                    element: action.weapon.element,
                    name: "name error"
                }
                
            }
            return {
                ...state,
                allWeapon: [objAtomic].concat(state.allWeapon),
            }
            
        }
        case ADD_WEAPON_TO_PANDA: {
            return { ...state, allWeapon: state.allWeapon.filter(n => n.asset_id !== action.weapon).map( u =>  {
                return u;
                })
            }
        }
        case WAS_LOADING_WEAPON: {
            //console.log('EATLIST', action.eatlist);
            return {...state, statusWeapon: action.status}
        }
        

        default:
            return state;
    }
}

export const weaponlist = (weaponlist) => ({type: WEAPONLIST, weaponlist })
export const setPandaWeapon = (pandaWeapon) => ({type: WEAPON_ON_PANDA, pandaWeapon })
export const statusLoadinWeapon = (status) => ({type: WAS_LOADING_WEAPON, status })
export const addWeaponWhenRemove = (weapon) => ({type: ADD_WEAPON_WHEN_REMOVE,  weapon })
export const addWeaponToPanda = (weapon) => ({type: ADD_WEAPON_TO_PANDA,  weapon })

export default weaponReduser;
