import React from 'react';
import baltop from './../images/balance-top.png';
import baltopwebp from './../images/balance-top.webp';


const Wallet = (props) => {


    return (
        <>
            {props.modalBal.openModal ?
                <div className={`modal-balance ${props.modalBal.openModal ? "open" : null} ${props.modalBal.showAnimModal ? " anim" : null}`}>
                    <div className="modal-balance-body">
                        <div className="modal-balance-body-in">

                            <div className="modal-balance-head">

                                <div className="tabs-bal">
                                    <div className={`one-bal-tab ${props.modalBal.activeTab == 1 ? 'active' : 'dis'}`} onClick={() => props.changeTab('1')}>
                                        <span>

                                        </span>
                                    </div>
                                    <div className={`one-bal-tab ${props.modalBal.activeTab != 1 ? 'active' : 'dis'}`} onClick={() => props.changeTab('2')}>
                                        <span>

                                        </span>
                                    </div>
                                </div>

                                <div className="modal-balance-top">
                                    <button className="modal-balance-close" onClick={() => props.showCloseModalBal('close')}><span></span></button>
                                    <picture>
                                        <source srcSet={`${baltop}`} type="image/webp" />
                                        <source srcSet={`${baltopwebp}`} type="image/png" />
                                        <img src={`${baltopwebp}`} alt="" />
                                    </picture>
                                </div>

                                <div className="modal-balance-wrap">
                                    <div className="modal-balance-tab">
                                        {props.modalBal.activeTab == 1 ?
                                            <>
                                                <div className="modal-balance-tit">
                                                    Wax wallet balance:
                                                </div>
                                                <div className="modal-balance-number">
                                                    {props.bam}
                                                    <span>
                                                        {process.env.REACT_APP_VAL}
                                                    </span>
                                                </div>
                                                <div className="modal-balance-field">
                                                    <div className="modal-balance-field-in">
                                                        <input type="number" min="0" value={props.inputBal} onChange={props.handleChangeInput} placeholder="enter token amount" />
                                                        <span onClick={() => props.allSum(props.bam)}></span>
                                                    </div>
                                                    <div className={`alert-bal-mess ${props.modalBal.error ? 'error' : null} ${props.modalBal.success ? 'success' : null}`}>
                                                        {props.modalBal.error ? props.modalBal.errorMes : null}
                                                        {props.modalBal.success ? props.modalBal.errorMes : null}
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="modal-balance-tit">
                                                    game balance:
                                                </div>
                                                <div className="modal-balance-number">
                                                    {props.game}
                                                    <span>
                                                        {process.env.REACT_APP_VAL}
                                                    </span>
                                                </div>
                                                <div className="modal-balance-field">
                                                    <div className="modal-balance-field-in">
                                                        <input type="number" min="0" value={props.inputBal} onChange={props.handleChangeInput} placeholder="enter token amount" />
                                                        <span onClick={() => props.allSum(props.game)}></span>
                                                    </div>
                                                    <div className={`alert-bal-mess ${props.modalBal.error ? 'error' : null} ${props.modalBal.success ? 'success' : null}`}>
                                                        {props.modalBal.error ? props.modalBal.errorMes : null}
                                                        {props.modalBal.success ? props.modalBal.errorMes : null}
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>

                                <div className="modal-balance-bottom">
                                    <div className="bal-bt">
                                        {props.modalBal.activeTab == 1 ?
                                            <button className="def-bt min" onClick={() => props.addBamToGame(props.inputBal)}>
                                                <span className="button-in">
                                                    <span className="button-name">
                                                        <span className="button-name-in">
                                                            <span className="button-name-value">
                                                                Deposit
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </button>
                                            :
                                            <button className="def-bt min" onClick={() => props.removeBamFromGame(props.inputBal)}>
                                                <span className="button-in">
                                                    <span className="button-name">
                                                        <span className="button-name-in">
                                                            <span className="button-name-value">
                                                                withdraw
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </button>
                                        }
                                    </div>

                                    <picture>
                                        <source srcSet={`${baltop}`} type="image/webp" />
                                        <source srcSet={`${baltopwebp}`} type="image/png" />
                                        <img src={`${baltopwebp}`} alt="" />
                                    </picture>
                                </div>
                            </div>
                        </div>

                        <div className="modal-weapon-bg-trans" onClick={() => props.showCloseModalBal()}></div>
                    </div>

                    <div className="modal-balance-bg" ></div>
                </div>
                :
                null}
        </>);
}

export default Wallet;