import React, { useState, useEffect } from 'react';
import ResultDetal from "./ResultDetal";

import rewtop from '../../images/alien/result-top.png';
import rewtopw from '../../images/alien/result-top.webp';
import rewbot from '../../images/new-adv/multi-reward-bot.png';
import rewbotw from '../../images/new-adv/multi-reward-bot.webp';

import chest from '../../images/alien/chest.png';
import chestw from '../../images/alien/chest.webp';

import cryst from '../../images/alien/crystals.png';
import crystw from '../../images/alien/crystals.webp';

import res1 from '../../images/alien/res-ico-min-1.png';
import res1w from '../../images/alien/res-ico-min-1.webp';

import res2 from '../../images/alien/res-ico-min-2.png';
import res2w from '../../images/alien/res-ico-min-2.webp';

import res3 from '../../images/alien/res-ico-min-3.png';
import res3w from '../../images/alien/res-ico-min-3.webp';



// var CommonCrew = [
//     "141561",
//     "141555",
//     "141554",
//     "141553",
//     "141551",
//     "141545",
//     "141544",
//     "141543",
//     "141540",
//     "141533",
//     "141532",
//     "141531",
//     "141528",
//     "141520",
//     "141519",
//     "141518",
//     "19647",
//     "19640",
//     "19639",
//     "19638",
//     "19635",
//     "19628",
//     "19627",
//     "19626"
// ];
// var RareCrew = [
//     "254356",
//     "254354",
//     "254352",
//     "254350",
//     "235646",
//     "235644",
//     "235642",
//     "235640",
//     "141574",
//     "141572",
//     "141570",
//     "141568",
//     "141566",
//     "141564",
//     "141560",
//     "141558",
//     "141556",
//     "141550",
//     "141548",
//     "141546",
//     "141539",
//     "141536",
//     "141534",
//     "141527",
//     "141524",
//     "141522",
//     "20993",
//     "20992",
//     "20991",
//     "20990",
//     "20989",
//     "20988",
//     "19646",
//     "19643",
//     "19641",
//     "19634",
//     "19631",
//     "19629"
// ];
// var EpicCrew = [
//     "254357",
//     "254355",
//     "254353",
//     "254351",
//     "235647",
//     "235645",
//     "235643",
//     "235641",
//     "141573",
//     "141569",
//     "141567",
//     "141563",
//     "141562",
//     "141559",
//     "141552",
//     "141549",
//     "141542",
//     "141541",
//     "141538",
//     "141529",
//     "141526",
//     "141516",
//     "44933",
//     "28422",
//     "19645",
//     "19636",
//     "19633",
//     "19624"
// ];
// var LegendaryCrew = [
//     "141571",
//     "141565",
//     "141557",
//     "141547",
//     "141535",
//     "141523",
//     "28424",
//     "28423",
//     "19642",
//     "19630"
// ];

// var CommonArms = [
//     "141484",
//     "141483",
//     "141480",
//     "141477",
//     "141470",
//     "141460",
//     "141457",
//     "141454",
//     "141452",
//     "141451",
//     "141450",
//     "141445",
//     "141444",
//     "141440",
//     "141437",
//     "141428",
//     "141418",
//     "141415",
//     "141412",
//     "141410",
//     "141409",
//     "141408",
//     "19621",
//     "19620",
//     "19616",
//     "19613",
//     "19604",
//     "19594",
//     "19591",
//     "19588",
//     "19586",
//     "19585",
//     "19584"
// ];
// var RareArms = [
//     "141511",
//     "141505",
//     "141504",
//     "141503",
//     "141502",
//     "141501",
//     "141496",
//     "141492",
//     "141491",
//     "141490",
//     "141489",
//     "141481",
//     "141473",
//     "141472",
//     "141471",
//     "141469",
//     "141468",
//     "141463",
//     "141458",
//     "141456",
//     "141455",
//     "141453",
//     "141441",
//     "141431",
//     "141430",
//     "141429",
//     "141427",
//     "141426",
//     "141421",
//     "141416",
//     "141414",
//     "141413",
//     "141411",
//     "20983",
//     "20982",
//     "20981",
//     "20980",
//     "20979",
//     "20978",
//     "20977",
//     "20976",
//     "20975",
//     "20974",
//     "20973",
//     "19617",
//     "19607",
//     "19606",
//     "19605",
//     "19603",
//     "19602",
//     "19597",
//     "19592",
//     "19590",
//     "19589",
//     "19587"
// ];
// var EpicArms = [
//     "141513",
//     "141510",
//     "141508",
//     "141500",
//     "141498",
//     "141495",
//     "141494",
//     "141493",
//     "141485",
//     "141479",
//     "141476",
//     "141467",
//     "141465",
//     "141462",
//     "141461",
//     "141459",
//     "141446",
//     "141439",
//     "141436",
//     "141425",
//     "141423",
//     "141420",
//     "141419",
//     "141417",
//     "19622",
//     "19615",
//     "19612",
//     "19601",
//     "19599",
//     "19596",
//     "19595",
//     "19593"
// ];
// var LegendaryArms = [
//     "141515",
//     "141512",
//     "141507",
//     "141506",
//     "141499",
//     "141497",
//     "141487",
//     "141482",
//     "141475",
//     "141474",
//     "141466",
//     "141464",
//     "141448",
//     "141442",
//     "141435",
//     "141432",
//     "141424",
//     "141422",
//     "56042",
//     "19618",
//     "19611",
//     "19608",
//     "19600",
//     "19598"
// ];
// var MythicalArms = [
//     "141514",
//     "141509",
//     "141488",
//     "141486",
//     "141478",
//     "141449",
//     "141447",
//     "141438",
//     "141406",
//     "19623",
//     "19614",
//     "19582"
// ];

const caseAlienTmpl = (tmpl) => {
    var ballali = 0;
    switch (tmpl) {
        case 19647: case 19640: case 19639: case 19638: case 19635: case 19628: case 19627: case 19626: case 19621: case 19620:
        case 19616: case 19613: case 19604: case 19594: case 19591: case 19588: case 19586: case 19585: case 19584:
            ballali += 2500;
            break;

        case 141540: case 141533: case 141532: case 141531: case 141528: case 141520: case 141519: case 141518: case 141445:
        case 141444: case 141440: case 141437: case 141428: case 141418: case 141415: case 141412: case 141410: case 141409:
        case 141408:
            ballali += 3000;
            break;


        case 141561: case 141555: case 141554: case 141553: case 141551: case 141545: case 141544: case 141543: case 141484:
        case 141483: case 141480: case 141477: case 141470: case 141460: case 141457: case 141454: case 141452: case 141451:
        case 141450:
            ballali += 3125;
            break;

        case 19657: case 19656: case 19651: case 19650:
            ballali += 500;
            break;

        case 48128: case 48127: case 48122: case 48121: case 19655: case 19654: case 19653: case 19652: case 17453:
            ballali += 600;
            break;

        case 48141: case 48140: case 48135: case 48134:
            ballali += 625;
            break;

        case 254350: case 235640: case 19646: case 19643: case 19641: case 19634: case 19631: case 19629: case 19617:
        case 19607: case 19606: case 19605: case 19603: case 19602: case 19597: case 19592: case 19590: case 19589: case 19587:
            ballali += 2900;
            break;

        case 254352: case 235642: case 141539: case 141536: case 141534: case 141527: case 141524: case 141522: case 141441:
        case 141431: case 141430: case 141429: case 141427: case 141426: case 141421: case 141416: case 141414: case 141413:
        case 141411:
            ballali += 3480;
            break;

        case 254354: case 235644: case 141560: case 141558: case 141556: case 141550: case 141548: case 141546: case 141481:
        case 141473: case 141472: case 141471: case 141469: case 141468: case 141463: case 141458: case 141456: case 141455:
        case 141453:
            ballali += 3625;
            break;

        case 254356: case 235646: case 141574: case 141572: case 141570: case 141568: case 141566: case 141564: case 141511:
        case 141505: case 141504: case 141503: case 141502: case 141501: case 141496: case 141492: case 141491: case 141490:
        case 141489:
            ballali += 3770;
            break;

        case 20993: case 20992: case 20991: case 20990: case 20989: case 20988: case 20983: case 20982: case 20981:
        case 20980: case 20979: case 20978: case 20977: case 20976: case 20975: case 20974: case 20973:
            ballali += 3915;
            break;

        case 48126: case 48125: case 48124: case 48123: case 48118: case 48117:
            ballali += 720;
            break;

        case 48139: case 48138: case 48137: case 48136: case 48133: case 48132:
            ballali += 750;
            break;

        case 48150: case 48149: case 48148: case 48147: case 48146: case 48145:
            ballali += 780;
            break;

        case 20987: case 20986: case 20985: case 20984:
            ballali += 810;
            break;

        case 254351: case 235641: case 28422: case 19645: case 19636: case 19633: case 19624: case 19622: case 19615:
        case 19612: case 19601: case 19599: case 19596: case 19595: case 19593:
            ballali += 3200;
            break;

        case 254353: case 235643: case 141541: case 141538: case 141529: case 141526: case 141516: case 141446:
        case 141439: case 141436: case 141425: case 141423: case 141420: case 141419: case 141417:
            ballali += 3840;
            break;

        case 254355: case 235645: case 141562: case 141559: case 141552: case 141549: case 141542: case 141485:
        case 141479: case 141476: case 141467: case 141465: case 141462: case 141461: case 141459:
            ballali += 4000;
            break;


        case 254357: case 235647: case 141573: case 141569: case 141567: case 141563: case 141513: case 141510:
        case 141508: case 141500: case 141498: case 141495: case 141494: case 141493:
            ballali += 4160;
            break;

        case 44933:
            ballali += 4320;
            break;


        case 19660: case 19658:
            ballali += 700;
            break;

        case 48130: case 48116:
            ballali += 840;
            break;

        case 48143: case 48131:
            ballali += 875;
            break;

        case 48152: case 48144:
            ballali += 910;
            break;

        case 44934:
            ballali += 945;
            break;

        case 19642: case 19630: case 56042: case 19618: case 19611: case 19608: case 19600: case 19598:
            ballali += 3400;
            break;

        case 141535: case 141523: case 141448: case 141442: case 141435: case 141432: case 141424: case 141422:
            ballali += 4080;
            break;

        case 141557: case 141547: case 141487: case 141482: case 141475: case 141474: case 141466: case 141464:
            ballali += 4250;
            break;

        case 141571: case 141565: case 141515: case 141512: case 141507: case 141506: case 141499: case 141497:
            ballali += 4420;
            break;

        case 28424: case 28423:
            ballali += 4590;
            break;

        case 19659:
            ballali += 800;
            break;

        case 48129:
            ballali += 960;
            break;

        case 48142:
            ballali += 1000;
            break;


        case 48151:
            ballali += 1040;
            break;

        case 19623: case 19614: case 19582:
            ballali += 3500;
            break;

        case 141447: case 141438: case 141406:
            ballali += 4200;
            break;

        case 141486: case 141478: case 141449:
            ballali += 4375;
            break;

        case 141514: case 141509: case 141488:
            ballali += 4550;
            break;

        case 652725: case 28425:
            ballali += 900;
            break;
    }

    return ballali
}

const casePandaRar = (rar) => {
    var sum;
    switch (rar) {
        case 0:
            sum = 1000;
            break;
        case 1:
            sum = 1000;
            break;
        case 2:
            sum = 1400;
            break;
        case 3:
            sum = 1800;
            break;
        case 4:
            sum = 2100;
            break;
        case 5:
            sum = 2300;
            break;
        case 6:
            sum = 2500;
            break;
        default:
            sum = 0;
            break;
    }

    return sum;
}



const Result = (props) => {

    const [showDetail, setShowDetail] = useState(false);
    const [allSumVar, setAllSumVar] = useState(0);

    //var allSumVar = 0;
    var prize = [0, 0, 0] // mini potion, bigpotion,  sharpering
    const potionNames = ['small space power potion', 'space power potion', 'space sharpening potion'];
    var hasPrise = false;

    function getAlienInfoById(asset_id, alienOnGame) {
        return alienOnGame.find(alien => alien.asset_id === asset_id);
    }

    function getPandaById(asset_id, pandasInSlot) {
        return pandasInSlot.find(panda => panda.asset_id === asset_id);
    }

    function getBall(lastAdv) {
        const { userSlotsAlien, userSlotsPanda, userRow, alienOnGame, pandasInSlot, userSlotsAlienAvatar } = props;


        let slotsToCheck;
        let slotsAvatar;
        let slotsPanda;
        let startLoop = 0;
        let endLoop = 20;

        if (lastAdv.type == "all") {
            slotsToCheck = userSlotsAlien;
            slotsAvatar = userSlotsAlienAvatar;
            slotsPanda = userSlotsPanda;
        } else {
            startLoop = lastAdv.numberslot;
            endLoop = lastAdv.numberslot + 1;
        }

        let allBal = 0;

        for (let index = startLoop; index < endLoop; index++) {
            let onePandaObj;
            let oneAlienInfo;
            let oneAlienInfoAvat;
            let randBumb;

            var ball = 0;
            var ballali = 0;
            var ballaliavat = 0;
            var ballpa = 0;
            const percentages = [0, 3, 7, 12, 19, 28, 39, 52, 66, 82, 100];

            let slotsToCheck = userSlotsAlien[index];
            let slotsAvatar = userSlotsAlienAvatar[index];
            let slotsPanda = userSlotsPanda[index];

            if (slotsToCheck !== 0 || slotsAvatar !== 0 || slotsPanda !== 0) {



                if (slotsToCheck) {
                    oneAlienInfo = getAlienInfoById(slotsToCheck, alienOnGame)
                    //console.log("oneAlienInfo", oneAlienInfo)
                    ballali += caseAlienTmpl(oneAlienInfo.tmpl);

                    var sharpLvlAlien;
                    sharpLvlAlien = props.allLvlAssets.find(i => i.asset_id === slotsToCheck)?.lvl || 0;
                    if (sharpLvlAlien > 0) {
                        ballali = ballali + ballali * percentages[sharpLvlAlien] / 100
                    }
                    randBumb = oneAlienInfo.random;
                }

                if (slotsAvatar) {
                    oneAlienInfoAvat = getAlienInfoById(slotsAvatar, alienOnGame)
                    //console.log("oneAlienInfoAvat", oneAlienInfoAvat)
                    ballaliavat += caseAlienTmpl(oneAlienInfoAvat.tmpl);

                    var sharpLvlAlienAvatar;
                    sharpLvlAlienAvatar = props.allLvlAssets.find(i => i.asset_id === slotsAvatar)?.lvl || 0;
                    if (sharpLvlAlienAvatar > 0) {
                        ballaliavat = ballaliavat + ballaliavat * percentages[sharpLvlAlienAvatar] / 100
                    }
                    randBumb = oneAlienInfoAvat.random;
                }

                if (slotsPanda) {
                    onePandaObj = getPandaById(slotsPanda, pandasInSlot);
                    //console.log("onePandaObj", onePandaObj)

                    var sharpLvlPanda;
                    const pandaRar = onePandaObj.rarity;
                    ballpa = casePandaRar(pandaRar);
                    sharpLvlPanda = props.allLvlAssets.find(i => i.asset_id === onePandaObj.asset_id)?.lvl || 0;

                    if (sharpLvlPanda > 0) {
                        ballpa = ballpa + ballpa * percentages[sharpLvlPanda] / 100
                    }
                    randBumb = onePandaObj.finalnumber;
                }

                // console.log("slotsToCheck", slotsToCheck)
                // console.log("slotsAvatar", slotsAvatar)
                // console.log("slotsPanda", slotsPanda)

                // console.log("onePandaObj", onePandaObj)
                // console.log("oneAlienInfo", oneAlienInfo)
                // console.log("oneAlienInfoAvat", oneAlienInfoAvat)

                //const index = userSlotsAlien.indexOf(item);

                var payout_amount;

                // console.log("sharpLvlAlien", sharpLvlAlien)
                // console.log("sharpLvlAlienAvatar", sharpLvlAlienAvatar)
                // console.log("ballali", ballali)
                // console.log("ballaliavat", ballaliavat)
                // console.log("ballpa", ballpa)
                // console.log("randBumb", randBumb)

                ball = ballali + ballpa + ballaliavat;


                let num1 = Math.floor(randBumb / 20);
                //console.log("num1", num1);
                switch (num1) {
                    case 0: case 1: case 2: case 3: case 4:
                        payout_amount = ball;
                        break;
                    case 5: case 6: case 7: case 8: case 9:
                        payout_amount = ball - (ball * 0.25);
                        break;
                    case 10: case 11: case 12: case 13: case 14:
                        payout_amount = ball - (ball * 0.50);
                        break;
                    case 15: case 16: case 17: case 18: case 19:
                        payout_amount = ball - (ball * 0.75);
                        break;
                    case 20: case 21: case 22: case 23: case 24:
                        payout_amount = 0;
                        break;
                    case 25: case 26: case 27: case 28: case 29:
                        payout_amount = ball;
                        break;
                    case 30: case 31: case 32: case 33: case 34:
                        payout_amount = ball + (ball * 0.25);
                        break;
                    case 35: case 36: case 37: case 38: case 39:
                        payout_amount = ball + (ball * 0.50);
                        break;
                    case 40: case 41: case 42: case 43: case 44:
                        payout_amount = ball + (ball * 0.75);
                        break;
                    case 45: case 46: case 47: case 48: case 49: case 50:
                        payout_amount = ball * 2;
                        break;
                    default:
                        payout_amount = 0;
                        break;
                }

                //console.log("payout_amount", payout_amount);

                var xfactor = 1;
                if (props.lastAdv.duration == 8) {
                    payout_amount = payout_amount * 2;
                    xfactor = 2;
                } else if (props.lastAdv.duration == 12) {
                    payout_amount = payout_amount * 3;
                    xfactor = 3;
                }

                if (randBumb < 5) { // 0,5% шанс (5 из 1000)
                    prize[1] += 1 * xfactor;
                } else if (randBumb < 15) { // 1% шанс (10 из 1000)
                    prize[0] += 1 * xfactor;
                } else if (randBumb < 20) { // 0,5% шанс (5 из 1000)
                    prize[2] += 1 * xfactor;
                }
                //console.log("payout_amount", payout_amount);

                allBal += payout_amount;

            }

            setAllSumVar(allBal);
        }
    }

    //console.log("props.lastAdv", props.lastAdv);
    //getBall(props.lastAdv.panda);
    // if (props.lastAdv.type === "one") {
    //     getBall(props.lastAdv.panda);
    // } else {
    //     getBall();
    // }
    useEffect(() => {
        getBall(props.lastAdv);
    }, [props.lastAdv])

    if (prize.some(el => el !== 0)) {
        hasPrise = true;
    }

    useEffect(() => {
        var wasload = false;
        //console.log("allSumVar", allSumVar);
        //props.updateBalance(true, parseFloat(allSumVar).toFixed(4));

    }, [allSumVar])

    return (<>
        <div className={`modal-to-adv reward-window ${showDetail ? "" : "open"} anim alien-rew`}>
            <div className="modal-to-adv-body">
                <div className={`modal-to-adv-body-in multi-rew`}>



                    <div className="multi-reward">
                        <div className="multi-reward-top">
                            <picture>
                                <source srcSet={rewtopw} type="image/webp" />
                                <source srcSet={rewtop} type="image/png" />
                                <img src={rewtop} alt="" />
                            </picture>
                            <div className="close-mult-rew" onClick={() => props.openModalResult(false, allSumVar)}>
                                <span></span>
                            </div>
                        </div>
                        <div className="multi-reward-wrap">
                            <div className="multi-reward-wrap-in">

                                <div className="bamboo-reward">
                                    <div className="bamboo-reward-chester">
                                        <div className={`chest-wrap-in`}>
                                            <picture>
                                                <source srcSet={chestw} type="image/webp" />
                                                <source srcSet={chest} type="image/png" />
                                                <img src={chestw} alt="" />
                                            </picture>
                                        </div>
                                    </div>
                                    <div className="bamboo-reward-detal">
                                        <div className="bamboo-reward-tit">
                                            general Reward:
                                        </div>
                                        <div className="bamboo-reward-count">
                                            <span>
                                                +{allSumVar / 1000}
                                            </span>
                                            <i>
                                                <picture>
                                                    <source srcSet={crystw} type="image/webp" />
                                                    <source srcSet={cryst} type="image/png" />
                                                    <img height={60} src={crystw} alt="" />
                                                </picture>
                                            </i>
                                        </div>
                                        {/* <div className="bamboo-reward-tit">
                                            space crystals
                                        </div> */}
                                        {hasPrise ?
                                            <div className="bonus-reward-detal">
                                                <div className="bonus-reward-tit">
                                                    you have bonus prize:
                                                </div>
                                                <div className="bonus-reward-count">
                                                    {prize.map((value, index) => (
                                                        value !== 0 ?
                                                            // <span key={index}>
                                                            //     {potionNames[index]}: +{value}
                                                            // </span>
                                                            index == 0 ?
                                                                <div class="one-drop">
                                                                    <div class="one-drop-thumb">
                                                                        <span>
                                                                            <picture>
                                                                                <source srcSet={res1w} type="image/webp" />
                                                                                <source srcSet={res1} type="image/png" />
                                                                                <img src={res1w} alt="" />
                                                                            </picture>
                                                                        </span>
                                                                    </div>
                                                                    <div class="one-drop-name">
                                                                        +1
                                                                    </div>
                                                                </div>
                                                                : index == 1 ?
                                                                    <div class="one-drop">
                                                                        <div class="one-drop-thumb">
                                                                            <span>
                                                                                <picture>
                                                                                    <source srcSet={res2w} type="image/webp" />
                                                                                    <source srcSet={res2} type="image/png" />
                                                                                    <img src={res2w} alt="" />
                                                                                </picture>
                                                                            </span>
                                                                        </div>
                                                                        <div class="one-drop-name">
                                                                            +1
                                                                        </div>
                                                                    </div>
                                                                    : index == 2 ?
                                                                        <div class="one-drop">
                                                                            <div class="one-drop-thumb">
                                                                                <span>
                                                                                    <picture>
                                                                                        <source srcSet={res3w} type="image/webp" />
                                                                                        <source srcSet={res3} type="image/png" />
                                                                                        <img src={res3w} alt="" />
                                                                                    </picture>
                                                                                </span>
                                                                            </div>
                                                                            <div class="one-drop-name">
                                                                                +1
                                                                            </div>
                                                                        </div>
                                                                        : null


                                                            : null
                                                    ))}

                                                </div>
                                            </div>
                                            : null
                                        }
                                    </div>

                                </div>

                                <div className="show-all-rew">
                                    <button className="def-bt mid" onClick={() => setShowDetail(true)}>
                                        <span className="button-in">
                                            <span className="button-name">
                                                <span className="button-name-in">
                                                    <span className="button-name-value">
                                                        Details
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </button>
                                </div>

                            </div>
                        </div>
                        <div className="multi-reward-bot">
                            <picture>
                                <source srcSet={rewbotw} type="image/webp" />
                                <source srcSet={rewbot} type="image/png" />
                                <img src={rewbot} alt="" />
                            </picture>
                        </div>
                    </div>


                </div>

                <div className="modal-weapon-bg-trans"></div>
            </div>

            <div className="modal-weapon-bg" ></div>
        </div>

        {showDetail ? <ResultDetal
            caseAlienTmpl={caseAlienTmpl}
            casePandaRar={casePandaRar}
            setShowDetail={setShowDetail}
            allSumVar={allSumVar}
            {...props}
        /> : null}
    </>);

}

export default Result;