import React from 'react';
import modaltop from '../../images/new-adv/modal-top.svg';
import modalbot from '../../images/new-adv/modal-bot.svg';

const InfoModal = (props) => {

    return (
    <>
        
        <div className={`modal-to-adv open ${props.infoModal.anim ? " anim" : null}`}>
            
            <div className="modal-to-adv-body">
                <div className="loading-block"></div>
                <div className="modal-to-adv-body-in">

                    <div className="modal-to-adv-head">
                        <div className="modal-to-adv-top">
                            {/* <div className="modal-to-adv-close" onClick={() => props.showCloseMedal('close')}>
                                <svg width="86" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76.11 48.28"><defs></defs><g id="Слой_2" data-name="Слой 2"><g id="Приключение_копия_" data-name="Приключение (копия)"><g id="Модалка_копия_" data-name="Модалка (копия)"><g id="Слой_5_копия_" data-name="Слой 5 (копия)"><g id="Слой_2_копия_5" data-name="Слой 2 копия 5"><path className="cls-back-1" d="M71.34,4.09H4.76C2.13,4.09,0,5.61,0,7.49v33.3c0,1.88,2.13,3.4,4.76,3.4H71.34c2.63,0,4.77-1.52,4.77-3.4V7.49C76.11,5.61,74,4.09,71.34,4.09Z"/></g><g id="Слой_2_копия_5-2" data-name="Слой 2 копия 5"><rect className="cls-back-2" x="5.38" width="65.35" height="48.28" rx="5"/><rect className="cls-back-3" x="11.57" y="2.93" width="52.01" height="39.02"/><g id="_1btMbR.tif" data-name="1btMbR.tif"><path className="cls-back-4" d="M17.19,22.23l4-2.53c2.09-1.33,4.18-2.64,6.26-4q2.32-1.49,4.63-3c1.14-.75,2.26-1.53,3.4-2.29.26-.18.55-.32.94-.55v12.4c2.64-1.67,5.18-3.27,7.71-4.89,1.34-.86,2.65-1.76,4-2.62,2-1.31,4.08-2.6,6.12-3.9.45-.29.87-.64,1.41-1v25l-2.23-1.49L53,33.05c-2.28-1.44-4.57-2.87-6.84-4.32-1.64-1-3.27-2.1-4.89-3.16-1.38-.9-2.74-1.82-4.11-2.72-.21-.13-.43-.22-.73-.38V34.78c-.81-.49-1.51-.89-2.2-1.32-.25-.15-.45-.37-.7-.53-2.21-1.41-4.43-2.8-6.64-4.2Q24.47,27.16,22,25.56c-1.4-.91-2.79-1.84-4.18-2.75a4,4,0,0,0-.67-.28Z"/></g></g></g></g></g></g></svg>
                            </div> */}
                            <img src={`${modaltop}`} alt="" />
                            
                        </div>

                        <div className="modal-to-adv-mid bamb">
                            {props.artitem[props.infoModal.index].has ?
                                <>
                                    <div className="modal-info-tit">
                                        {props.artitem[props.infoModal.index].title}
                                    </div>
                                    <div className="modal-info-thumb">
                                        <picture>
                                            <source srcSet={`./images/artefacts/bigart/${props.artitem[props.infoModal.index].bigArtACT}.webp`} type="image/webp" />
                                            <source srcSet={`./images/artefacts/bigart/${props.artitem[props.infoModal.index].bigArtACT}.png`} type="image/png" /> 
                                            <img src={`./images/artefacts/bigart/${props.artitem[props.infoModal.index].bigArtACT}.png`} alt="" />
                                        </picture>
                                    </div>
                                    <div className="modal-info-descr">
                                        <div className="modal-info-descr-tit">
                                            Descriptions:
                                        </div>
                                        <div className="modal-info-descr-text">
                                            <div dangerouslySetInnerHTML={{__html: props.artitem[props.infoModal.index].descr}} />
                                        </div>
                                    </div>
                                </>
                            :
                                <>
                                    <div className="modal-info-tit">
                                        You don't have this art
                                    </div>
                                    <div className="modal-info-thumb">
                                        <picture>
                                            <source srcSet={`./images/artefacts/bigart/${props.artitem[props.infoModal.index].bigArtDis}.webp`} type="image/webp" />
                                            <source srcSet={`./images/artefacts/bigart/${props.artitem[props.infoModal.index].bigArtDis}.png`} type="image/png" /> 
                                            <img src={`./images/artefacts/bigart/${props.artitem[props.infoModal.index].bigArtDis}.png`} alt="" />
                                        </picture>                                        
                                    </div>
                                    <div className="modal-info-descr">
                                        <div className="modal-info-descr-tit">
                                            you can buy it:
                                        </div>
                                        <div className="modal-info-descr-text">
                                            <a className="def-bt min" target="_blank" href={props.artitem[props.infoModal.index].urlAtomic}>
                                                <span className="button-in">
                                                    <span className="button-name">
                                                        <span className="button-name-in">
                                                            <span className="button-name-value">
                                                                buy now
                                                            </span> 
                                                        </span>
                                                    </span>
                                                </span>
                                            </a> 
                                        </div>
                                    </div>
                                </>
                            }
                        </div>

                        <div className="modal-to-adv-bot">
                            <img src={`${modalbot}`} alt="" />

                            <div className="modal-to-adv-bt">
                                <button className="def-bt norm" onClick={() => props.showCloseInfoModal()}>
                                    <span className="button-in">
                                        <span className="button-name">
                                            <span className="button-name-in">
                                                <span className="button-name-value">
                                                    close
                                                </span> 
                                            </span>
                                        </span>
                                    </span>
                                </button> 
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal-weapon-bg-trans" onClick={() => props.showCloseInfoModal()}></div>
            </div>
            
            <div className="modal-weapon-bg" ></div>
        </div> 
        
    </>);
}

export default InfoModal;