import React, { useEffect, useRef, useState } from "react";
import OnePandaPanelHero from "./OnePandaPanelHero";

import skall from "../../images/skills/all.png";
import sk1 from "../../images/skills/sk-1.png";
import sk2 from "../../images/skills/sk-2.png";
import sk3 from "../../images/skills/sk-3.png";
import sk4 from "../../images/skills/sk-4.png";
import sk5 from "../../images/skills/sk-5.png";
import sk6 from "../../images/skills/sk-6.png";
import sk7 from "../../images/skills/sk-7.png";
import sk8 from "../../images/skills/sk-8.png";
import sk9 from "../../images/skills/sk-9.png";
import sk10 from "../../images/skills/sk-10.png";
import sk11 from "../../images/skills/sk-11.png";
import sk12 from "../../images/skills/sk-12.png";
import sk13 from "../../images/skills/sk-13.png";
import sk14 from "../../images/skills/sk-14.png";
import sk15 from "../../images/skills/sk-15.png";
import bgHero from "../../images/skills/bg-all-hero.png";

const menu = [
    {
        name: "all",
        ico: skall,
    },
    {
        name: "elder",
        ico: sk15,
    },
    {
        name: "healer",
        ico: sk14,
    },
    {
        name: "priest",
        ico: sk13,
    },
    {
        name: "dreneya",
        ico: sk12,
    },
    {
        name: "druid",
        ico: sk11,
    },
    {
        name: "mage",
        ico: sk10,
    },
    {
        name: "tauren",
        ico: sk9,
    },
    {
        name: "smith",
        ico: sk8,
    },
    {
        name: "engineer",
        ico: sk7,
    },
    {
        name: "berserker",
        ico: sk6,
    },
    {
        name: "bard",
        ico: sk5,
    },
    {
        name: "shooter",
        ico: sk4,
    },
    {
        name: "archer",
        ico: sk3,
    },
    {
        name: "warrior",
        ico: sk2,
    },
    {
        name: "paladin",
        ico: sk1,
    },
]

const HeroList = (props) => {

    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
    const [activeFilt, setActiveFilt] = useState("all");
    const imgRef = useRef();

    const handleLoad = () => {
        setImageDimensions({
            width: imgRef.current.clientWidth,
            height: imgRef.current.clientHeight
        });
    }

    const activeFilter = (type) => {
        console.log("type", type);
        setActiveFilt(type);
    }

    const widthScale = props.widthScale;
    const windowWidth = props.windowWidth;
    const windowHeight = props.windowHeight;
    const [activeTab, setActiveTab] = useState('res');
    const [activePage, setActivePage] = useState(1);
    const [countPage, setCountPage] = useState(0);
    const [shopitem, setShopitem] = useState(0);

    //console.log("windowHeight - (widthScale * imageDimensions)", windowHeight - (widthScale * imageDimensions.height))
    //console.log("widthScale * (942 + 200)", widthScale * (942 + 200 + (widthScale * imageDimensions.height)))
    //console.log("imageDimensions.height", imageDimensions.height)

    return (
        <div className="hero-list" style={{
            top: windowHeight < widthScale * (942 + 150 + imageDimensions.height) ? widthScale * (942 + 250) : "auto",
            bottom: windowHeight > widthScale * (942 + 150 + imageDimensions.height) ? 0 : "auto",
        }}>
            <div className="bg-skill-img">
                <picture>
                    <source srcSet={bgHero} type="image/webp" />
                    <source srcSet={bgHero} type="image/png" />
                    <img src={bgHero} alt="" ref={imgRef} onLoad={handleLoad} />
                </picture>
            </div>
            <div className="hero-list-in" style={{
                width: widthScale * 1800,
                height: widthScale * 292,
                //backgroundSize: (widthScale * 2379),
                padding: `0px ${(widthScale * 315)}px 0px ${(widthScale * 315)}px`
            }}>


                <div className="hero-skills-list-wrap">
                    <div className="hero-skills-list-top">
                        <nav>
                            <ul>

                                {menu.map((item, index) => {
                                    return (
                                        <li onClick={() => activeFilter(item.name)} key={index} className={`${index == 0 ? "sk-all" : "sk-" + (16 - index)} tooltip ${activeFilt === item.name ? "active" : "def"}`}>
                                            <span>
                                                <picture>
                                                    <source srcSet={item.ico} type="image/webp" />
                                                    <source srcSet={item.ico} type="image/png" />
                                                    <img src={item.ico} alt="" />
                                                </picture>
                                            </span>
                                            <div className="cust-tooltip top">
                                                <div className="tool-arrow"></div>
                                                <div className="tool-wrap">
                                                    <div className="tool-wrap-1">
                                                        <div className="tool-wrap-2">
                                                            <div className="tool-body">
                                                                {item.name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </nav>
                    </div>
                    <div className="hero-skills-list-body">
                        {//console.log("allPandaInfo", props.allPandaInfo)
                        }

                        {

                            imageDimensions && imageDimensions.height !== undefined && props.allPandaInfo.map((item, index) => {
                                if (item !== 0) {
                                    return (
                                        <OnePandaPanelHero
                                            key={index}
                                            index={index}
                                            widthScale={widthScale}
                                            windowWidth={windowWidth}
                                            pandaInfo={item}
                                            isActive={props.activePandaIndex === index ? "active" : "dis"}
                                            isOnFilter={activeFilt === item.onePanda.clas || activeFilt === "all" ? "filt" : "def"}
                                            nowActivePanda={props.nowActivePanda}
                                            imageDimensions={imageDimensions}
                                        />
                                    )
                                }
                                else {
                                    return (
                                        <div key={index} className="one-skills-panda-wrap">
                                            <div className="one-skills-panda" style={
                                                {
                                                    width: imageDimensions.height * 0.35,
                                                    height: imageDimensions.height * 0.35
                                                    //width: windowWidth > 2100 ? 120 : widthScale * 110,
                                                    //height: windowWidth > 2100 ? 120 : widthScale * 110,
                                                }
                                            }>
                                                <div className="one-skills-panda-in no-p">

                                                </div>
                                            </div>
                                        </div>
                                    )
                                }


                            })
                        }
                    </div>
                </div>
                {/* <div className="bt-nav-bt-skill prev-page-skill">
                    <span></span>
                </div>
                <div className="bt-nav-bt-skill next-page-skill">
                    <span></span>
                </div> */}
                {/* <div className="hero-list-l">
                    <picture>
                        <source srcSet={leftstonew} type="image/webp" />
                        <source srcSet={leftstone} type="image/png" />
                        <img style={
                            {
                                width: widthScale * 404,
                                height: widthScale * 384,
                            }
                        } src={leftstonew} alt="" />
                    </picture>
                </div>
                <div className="hero-list-r">
                    <picture>
                        <source srcSet={rightstonew} type="image/webp" />
                        <source srcSet={rightstone} type="image/png" />
                        <img style={
                            {
                                width: widthScale * 409,
                                height: widthScale * 392,
                            }
                        } src={rightstonew} alt="" />
                    </picture>
                </div> */}
            </div>
        </div>
    );
};


export default HeroList;