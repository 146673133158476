import React from 'react';

const InventoryRes = (props) => {
    var numItems = 0;
    if (window.screen.width >= 1330) {
        numItems = 16
    } else {
        numItems = 100
    }
    // }
    return (<>
        {props.allinvent && props.allinvent.length ? props.allinvent.map((item, index) => {
            if (index < props.activePage * numItems && index >= (props.activePage - 1) * numItems) {
                if (item.length != 0) {
                    return (
                        <div key={index} className={`one-exch-item ${props.activeItem.activeNum === index ? 'active' : null}`}>
                            <div key={index} v className="one-exch-item-in">
                                <div className="one-exch-prod-thumb nft">
                                    <div className='linkexch' onClick={() => props.chooseRes(item.length, item[0].template.template_id, index)} ></div>
                                    <div className="value-exch-eat">
                                        {item.length}
                                    </div>
                                    <span></span>
                                    <div className="one-exch-prod-thumb-in">
                                        <picture>
                                            <source srcSet={`./images/shop/${item[0].template.template_id}.webp`} type="image/webp" />
                                            <source srcSet={`./images/shop/${item[0].template.template_id}.png`} type="image/png" />
                                            <img src={`./images/shop/${item[0].template.template_id}.png`} alt="" />
                                        </picture>
                                        {/* <picture>
                                            <source srcSet={`./images/jew/inventory/431181.webp`} type="image/webp" />
                                            <source srcSet={`./images/jew/inventory/431181.png`} type="image/png" /> 
                                            <img src={`./images/jew/inventory/431181.png`} alt="" />
                                        </picture> */}
                                        <i></i>
                                        <i className="b"></i>
                                    </div>


                                </div>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div className="one-exch-item no">
                            <div className="one-exch-item-in">
                                <div className="one-exch-prod-thumb">

                                </div>
                            </div>
                        </div>
                    );
                }

            }
        })

            : <></>}





    </>);

}

export default InventoryRes;