import React from 'react';

const Referal = (props) => {

    return (<>


        <table>
            <tbody>
                <tr>
                    <th>
                        Position
                    </th>
                    <th>
                        Player
                    </th>
                    {/* <th>
                    Max Rank
                </th> */}
                    <th>
                        Total {process.env.REACT_APP_VAL}
                    </th>
                </tr>
                {props.tableInfo.map((s, index) => {

                    return (
                        <tr key={index}>
                            <td>
                                #{index + 1}
                            </td>
                            <td>
                                {s.username}
                            </td>
                            <td>
                                {s.total / 10000}
                            </td>
                            {/* <td>
                            {nameRare}
                        </td>
                        <td>
                            {s.allsum / 10000}
                        </td> */}
                        </tr>
                    )
                }
                )}
            </tbody>
        </table>

    </>);
}

export default Referal;