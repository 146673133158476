import React from 'react';
import Heromodal from './Heromodal';

const openfilter = function (rar) {
    //console.log(4);
    var showcard = document.querySelector('.filter-mod')
    var items = document.getElementsByClassName(rar);
    showcard.classList.toggle("open");
}

const filter = function (rar) {
    //console.log(4);
    var showm = document.querySelector('.filter-mod');
    var allcard = document.querySelectorAll('.mod-list');
    if (rar === "all") {
        for (var i = 0; i < allcard.length; ++i) {
            allcard[i].classList.remove('hide');
        }
    }
    else {
        for (var i = 0; i < allcard.length; ++i) {
            allcard[i].classList.add('hide');
        }
        var showcard = document.querySelectorAll('[data-rar=' + rar + ']');
        for (var i = 0; i < showcard.length; ++i) {
            showcard[i].classList.remove('hide');
        }
    }
    showm.classList.remove("open");
}

const ChooseAlienOrPanda = (props) => {

    return (
        <div className="all-modal-wrap open">
            {props.chooseHeroLoading ? <div className="loader-choose-hero"></div> : null}
            <div className="bamb-modal-body">

                <div className="bamb-modal-body-in">
                    <div className="bamb-modal">
                        <div className="bamb-modal-in">
                            <div className="modal-close" onClick={() => props.chooseAlienOrPanda(false, 0)}></div>
                            <div className="bamb-modal-top"></div>
                            <div className="bamb-modal-bot"></div>
                            <div className="bamb-modal-wrap">
                                <div className="bamb-modal-tit">
                                    {props.typeOfNft == "alien" ?
                                        "Choose AW Crew or Arm NFT"
                                        : props.typeOfNft == "avatar" ?
                                            "Choose avatar"
                                            :
                                            "Choose a hero"
                                    }
                                </div>

                                {props.allPandasSlot.length && false ? <div className="filter">

                                    <div className="filter-in">
                                        <div className="filter-tit">
                                            filters:
                                        </div>
                                        <div className="filter-one filter-mod">
                                            <button className="def-bt min" onClick={(e) => {
                                                openfilter();
                                            }}>
                                                <span className="button-in">
                                                    <span className="button-name">
                                                        <span className="button-name-in">
                                                            <span className="button-name-value">
                                                                All heroes
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </button>

                                            <div className="filter-drop min">
                                                <div className="filter-drop-in">
                                                    <ul>
                                                        <li onClick={(e) => {
                                                            filter('all');
                                                        }}>
                                                            All heroes
                                                        </li>
                                                        <li onClick={(e) => {
                                                            filter('Common');
                                                        }}>
                                                            Common
                                                        </li>
                                                        <li onClick={(e) => {
                                                            filter('Promo');
                                                        }}>
                                                            Promo
                                                        </li>
                                                        <li onClick={(e) => {
                                                            filter('Uncommon');
                                                        }}>
                                                            Uncommon
                                                        </li>
                                                        <li onClick={(e) => {
                                                            filter('Rare');
                                                        }}>
                                                            Rare
                                                        </li>
                                                        <li onClick={(e) => {
                                                            filter('Epic');
                                                        }}>
                                                            Epic
                                                        </li>
                                                        <li onClick={(e) => {
                                                            filter('Legendary');
                                                        }}>
                                                            Legendary
                                                        </li>
                                                        <li onClick={(e) => {
                                                            filter('Mythic');
                                                        }}>
                                                            Mythic
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    : null}

                                <div className="bamb-modal-cards">
                                    {console.log(props.userSlotsAlien.filter((item) => item !== 0).length)}
                                    {(props.allPandasSlot && props.allPandasSlot.length && props.typeOfNft == "panda") ||
                                        (props.allAlien && props.allAlien.length != props.userSlotsAlien.filter((item) => item !== 0).length && props.typeOfNft == "alien") ||
                                        (props.allAlien && props.allAlien.length != props.userSlotsAlienAvatar.filter((item) => item !== 0).length && props.typeOfNft == "avatar") ?
                                        <Heromodal
                                            userSlotsAlien={props.userSlotsAlien}
                                            userSlotsAlienAvatar={props.userSlotsAlienAvatar}
                                            typeOfNft={props.typeOfNft}
                                            allPandasSlot={props.allPandasSlot}
                                            allAlien={props.allAlien}
                                            allAlienSlot={props.allAlienSlot}
                                            onHandleSendPandaToSlot={props.onHandleSendPandaToSlot} /> :
                                        props.typeOfNft == "panda" ?
                                            <div className="modal-buy-bt">
                                                <div className="modal-buy-text">
                                                    SORRY, BUT YOU DON’T HAVE ANY HEROES YET. PLEASE BUY YOUR FIRST HERO!
                                                </div>
                                                <a className="def-bt mid" target="_blank" href="https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&schema_name=heroes&sort=created&state=1&symbol=WAX">
                                                    <span className="button-in">
                                                        <span className="button-name">
                                                            <span className="button-name-in">
                                                                <span className="button-name-value">
                                                                    Buy hero
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </a>
                                            </div>
                                            :
                                            <div className="modal-buy-bt">
                                                <div className="modal-buy-text">
                                                    Sorry, but you don’t have any AW Crew of Arm NFT.
                                                </div>
                                                <a className="def-bt mid" target="_blank" href="https://wax.atomichub.io/market?collection_name=alien.worlds">
                                                    <span className="button-in">
                                                        <span className="button-name">
                                                            <span className="button-name-in">
                                                                <span className="button-name-value">
                                                                    Buy nft
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </a>
                                            </div>

                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-modal"></div>
        </div>
    );

}

export default ChooseAlienOrPanda;