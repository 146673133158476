import React, { useEffect, useRef } from "react";
import OnePandaPanelHero from "./OnePandaPanelHero";

const PanelHero = (props) => {

    const widthScale = props.widthScale;
    const windowWidth = props.windowWidth;

    return (

        <div className="panel-loop">

            {props.allPandaInfo.map((item, index) => {
                if (item !== 0) {
                    return (
                        <OnePandaPanelHero key={index} index={index} widthScale={widthScale} windowWidth={windowWidth} pandaInfo={item} isActive={props.activePandaIndex === index} nowActivePanda={props.nowActivePanda} />
                    )
                }
                else {
                    return (
                        <div className="one-panel-panda-wrap">
                            <div className="one-panel-panda" key={index} style={
                                {
                                    width: windowWidth > 1924 ? 108 : widthScale * 142,
                                    height: windowWidth > 1924 ? 109 : widthScale * 144,
                                }
                            }>
                                <div className="one-panel-panda-in no-p">

                                </div>
                            </div>
                        </div>
                    )
                }


            })
            }
        </div>
    );
};


export default PanelHero;