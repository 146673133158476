import React from "react";
import Menu from '../../components/Menu';
import axios from "axios";

import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import {
  weaponlist,
  statusLoadinWeapon,
  addWeaponWhenRemove,
  addWeaponToPanda,
} from "../../GlobalState/weapon-reducer";

import ErrorComponent from "../Error/ErrorComponent";
import UpgradeHomeLoop from "./UpgradeHomeLoop";
import cryptoRandomString from "crypto-random-string";

import { JsonRpc } from "eosjs";
import "./../../css/craft.scss";

import musicError from "./../../music/error.mp3";
import musicOpenCraft from "./../../music/bt-craft.mp3";
import craftSuccess from "./../../music/craft-success.mp3";
import craftFail from "./../../music/craft-fail.mp3";
import newClick from "./../../music/newclick.mp3";

import { ExplorerApi } from "atomicassets"; // атомик для списка панд
import { compose } from "glamor";

const fetch = require("node-fetch");
const api = new ExplorerApi(
  "https://" + localStorage.getItem('rpcA'),
  "atomicassets",
  { fetch, rateLimit: 4 }
);

// это транзакция отправки ИЗ
const cardTransferOut = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "transferout",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        from: "",
        to: "",
        asset_id: [],
      },
    },
  ],
};

var arr = [
  [549570, 550246, 551825, 552775, 582961, 595073],
  [549569, 550244, 551824, 552774, 582960, 595072],
  [549572, 550247, 551826, 552776, 582963, 595074],
  [549568, 550243, 551823, 552773, 582959, 595066],
  [549575, 550251, 551862, 552781, 582966, 595077],
  [549574, 550250, 551861, 552780, 582965, 595076],
  [549576, 550252, 551863, 552782, 582967, 595078],
  [549573, 550249, 551860, 552779, 582964, 595075],
  [549582, 550256, 551946, 552820, 583000, 595081],
  [549580, 550255, 551944, 552819, 582999, 595080],
  [549583, 550257, 551947, 552821, 583001, 595082],
  [549579, 550253, 551943, 552818, 582998, 595079],
  [549587, 550269, 552241, 552825, 583004, 595085],
  [549586, 550268, 552240, 552824, 583003, 595084],
  [549588, 550270, 552244, 552826, 583005, 595086],
  [549585, 550266, 552239, 552823, 583002, 595083],
  [549592, 550439, 552257, 552853, 583249, 595092],
  [549590, 550438, 552255, 552852, 583248, 595091],
  [549593, 550440, 552260, 552855, 583251, 595093],
  [549589, 550437, 552251, 552851, 583247, 595090],
  [549601, 550609, 552269, 556260, 583254, 595097],
  [549599, 550608, 552268, 556259, 583253, 595096],
  [549603, 550610, 552270, 556264, 583255, 595098],
  [549598, 550607, 552267, 556258, 583252, 595095],
  [549845, 550640, 552276, 556372, 583258, 595101],
  [549844, 550639, 552275, 556371, 583257, 595100],
  [549846, 550642, 552278, 556373, 583259, 595102],
  [549843, 550638, 552274, 556370, 583256, 595099],
  [549850, 550652, 552511, 556377, 583262, 595105],
  [549849, 550651, 552509, 556376, 583261, 595104],
  [549851, 550653, 552513, 556378, 583264, 595106],
  [549848, 550649, 552507, 556375, 583260, 595103],
  [549854, 550662, 552533, 556414, 583267, 595110],
  [549853, 550661, 552532, 556412, 583266, 595109],
  [549855, 550663, 552534, 556418, 583269, 595111],
  [549852, 550660, 552530, 556410, 583265, 595108],
  [549860, 550667, 552538, 556470, 583558, 595114],
  [549859, 550666, 552537, 556467, 583557, 595113],
  [549861, 550668, 552539, 556471, 583560, 595115],
  [549857, 550665, 552536, 556466, 583556, 595112],
  [549866, 550674, 552555, 556477, 583563, 595120],
  [549864, 550672, 552554, 556476, 583562, 595117],
  [549867, 550675, 552556, 556478, 583564, 595122],
  [549863, 550671, 552551, 556475, 583561, 595116],
  [549870, 551011, 552563, 556611, 583567, 595126],
  [549869, 551010, 552562, 556609, 583566, 595125],
  [549871, 551013, 552564, 556612, 583568, 595127],
  [549868, 551008, 552561, 556608, 583565, 595124],
  [549877, 551028, 552567, 556623, 583607, 595176],
  [549876, 551027, 552566, 556622, 583603, 595175],
  [549878, 551029, 552568, 556624, 583608, 595177],
  [549874, 551026, 552565, 556621, 583600, 595174],
  [549882, 551037, 552572, 556628, 583856, 595181],
  [549880, 551035, 552571, 556627, 583855, 595180],
  [549884, 551039, 552574, 556629, 583857, 595182],
  [549879, 551034, 552570, 556626, 583854, 595179],
  [549890, 551222, 552578, 556632, 583864, 595187],
  [549889, 551221, 552577, 556631, 583863, 595184],
  [549892, 551223, 552580, 556633, 583865, 595188],
  [549887, 551220, 552576, 556630, 583862, 595183],
];

class UpgradePageComponentArmor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      craftweapon: [
        {
          headWeapon: "warrior",
          allelements: [
            {
              weapon: "595188",
              element: "wind",
              type: "warrior",
            },
            {
              weapon: "595187",
              element: "water",
              type: "warrior",
            },
            {
              weapon: "595184",
              element: "fire",
              type: "warrior",
            },
            {
              weapon: "595183",
              element: "earth",
              type: "warrior",
            },
          ],
        },
        {
          headWeapon: "tauren",
          allelements: [
            {
              weapon: "595182",
              element: "wind",
              type: "tauren",
            },
            {
              weapon: "595181",
              element: "water",
              type: "tauren",
            },
            {
              weapon: "595180",
              element: "fire",
              type: "tauren",
            },
            {
              weapon: "595179",
              element: "earth",
              type: "tauren",
            },
          ],
        },
        {
          headWeapon: "smith",
          allelements: [
            {
              weapon: "595177",
              element: "wind",
              type: "smith",
            },
            {
              weapon: "595176",
              element: "water",
              type: "smith",
            },
            {
              weapon: "595175",
              element: "fire",
              type: "smith",
            },
            {
              weapon: "595174",
              element: "earth",
              type: "smith",
            },
          ],
        },
        {
          headWeapon: "shooter",
          allelements: [
            {
              weapon: "595127",
              element: "wind",
              type: "shooter",
            },
            {
              weapon: "595126",
              element: "water",
              type: "shooter",
            },
            {
              weapon: "595125",
              element: "fire",
              type: "shooter",
            },
            {
              weapon: "595124",
              element: "earth",
              type: "shooter",
            },
          ],
        },
        {
          headWeapon: "priest",
          allelements: [
            {
              weapon: "595122",
              element: "wind",
              type: "priest",
            },
            {
              weapon: "595120",
              element: "water",
              type: "priest",
            },
            {
              weapon: "595117",
              element: "fire",
              type: "priest",
            },
            {
              weapon: "595116",
              element: "earth",
              type: "priest",
            },
          ],
        },
        {
          headWeapon: "paladin",
          allelements: [
            {
              weapon: "595115",
              element: "wind",
              type: "paladin",
            },
            {
              weapon: "595114",
              element: "water",
              type: "paladin",
            },
            {
              weapon: "595113",
              element: "fire",
              type: "paladin",
            },
            {
              weapon: "595112",
              element: "earth",
              type: "paladin",
            },
          ],
        },
        {
          headWeapon: "mage",
          allelements: [
            {
              weapon: "595111",
              element: "wind",
              type: "mage",
            },
            {
              weapon: "595110",
              element: "water",
              type: "mage",
            },
            {
              weapon: "595109",
              element: "fire",
              type: "mage",
            },
            {
              weapon: "595108",
              element: "earth",
              type: "mage",
            },
          ],
        },
        {
          headWeapon: "healer",
          allelements: [
            {
              weapon: "595106",
              element: "wind",
              type: "healer",
            },
            {
              weapon: "595105",
              element: "water",
              type: "healer",
            },
            {
              weapon: "595104",
              element: "fire",
              type: "healer",
            },
            {
              weapon: "595103",
              element: "earth",
              type: "healer",
            },
          ],
        },
        {
          headWeapon: "engineer",
          allelements: [
            {
              weapon: "595102",
              element: "wind",
              type: "engineer",
            },
            {
              weapon: "595101",
              element: "water",
              type: "engineer",
            },
            {
              weapon: "595100",
              element: "fire",
              type: "engineer",
            },
            {
              weapon: "595099",
              element: "earth",
              type: "engineer",
            },
          ],
        },
        {
          headWeapon: "elder",
          allelements: [
            {
              weapon: "595098",
              element: "wind",
              type: "elder",
            },
            {
              weapon: "595097",
              element: "water",
              type: "elder",
            },
            {
              weapon: "595096",
              element: "fire",
              type: "elder",
            },
            {
              weapon: "595095",
              element: "earth",
              type: "elder",
            },
          ],
        },
        {
          headWeapon: "druid",
          allelements: [
            {
              weapon: "595093",
              element: "wind",
              type: "druid",
            },
            {
              weapon: "595092",
              element: "water",
              type: "druid",
            },
            {
              weapon: "595091",
              element: "fire",
              type: "druid",
            },
            {
              weapon: "595090",
              element: "earth",
              type: "druid",
            },
          ],
        },
        {
          headWeapon: "dreneya",
          allelements: [
            {
              weapon: "595086",
              element: "wind",
              type: "dreneya",
            },
            {
              weapon: "595085",
              element: "water",
              type: "dreneya",
            },
            {
              weapon: "595084",
              element: "fire",
              type: "dreneya",
            },
            {
              weapon: "595083",
              element: "earth",
              type: "dreneya",
            },
          ],
        },
        {
          headWeapon: "berserker",
          allelements: [
            {
              weapon: "595082",
              element: "wind",
              type: "berserker",
            },
            {
              weapon: "595081",
              element: "water",
              type: "berserker",
            },
            {
              weapon: "595080",
              element: "fire",
              type: "berserker",
            },
            {
              weapon: "595079",
              element: "earth",
              type: "berserker",
            },
          ],
        },
        {
          headWeapon: "bard",
          allelements: [
            {
              weapon: "595078",
              element: "wind",
              type: "bard",
            },
            {
              weapon: "595077",
              element: "water",
              type: "bard",
            },
            {
              weapon: "595076",
              element: "fire",
              type: "bard",
            },
            {
              weapon: "595075",
              element: "earth",
              type: "bard",
            },
          ],
        },
        {
          headWeapon: "archer",
          allelements: [
            {
              weapon: "595074",
              element: "wind",
              type: "archer",
            },
            {
              weapon: "595073",
              element: "water",
              type: "archer",
            },
            {
              weapon: "595072",
              element: "fire",
              type: "archer",
            },
            {
              weapon: "595066",
              element: "earth",
              type: "archer",
            },
          ],
        },
        {
          headWeapon: "warrior",
          allelements: [
            {
              weapon: "583865",
              element: "wind",
              type: "warrior",
            },
            {
              weapon: "583864",
              element: "water",
              type: "warrior",
            },
            {
              weapon: "583863",
              element: "fire",
              type: "warrior",
            },
            {
              weapon: "583862",
              element: "earth",
              type: "warrior",
            },
          ],
        },
        {
          headWeapon: "tauren",
          allelements: [
            {
              weapon: "583857",
              element: "wind",
              type: "tauren",
            },
            {
              weapon: "583856",
              element: "water",
              type: "tauren",
            },
            {
              weapon: "583855",
              element: "fire",
              type: "tauren",
            },
            {
              weapon: "583854",
              element: "earth",
              type: "tauren",
            },
          ],
        },
        {
          headWeapon: "smith",
          allelements: [
            {
              weapon: "583608",
              element: "wind",
              type: "smith",
            },
            {
              weapon: "583607",
              element: "water",
              type: "smith",
            },
            {
              weapon: "583603",
              element: "fire",
              type: "smith",
            },
            {
              weapon: "583600",
              element: "earth",
              type: "smith",
            },
          ],
        },
        {
          headWeapon: "shooter",
          allelements: [
            {
              weapon: "583568",
              element: "wind",
              type: "shooter",
            },
            {
              weapon: "583567",
              element: "water",
              type: "shooter",
            },
            {
              weapon: "583566",
              element: "fire",
              type: "shooter",
            },
            {
              weapon: "583565",
              element: "earth",
              type: "shooter",
            },
          ],
        },
        {
          headWeapon: "priest",
          allelements: [
            {
              weapon: "583564",
              element: "wind",
              type: "priest",
            },
            {
              weapon: "583563",
              element: "water",
              type: "priest",
            },
            {
              weapon: "583562",
              element: "fire",
              type: "priest",
            },
            {
              weapon: "583561",
              element: "earth",
              type: "priest",
            },
          ],
        },
        {
          headWeapon: "paladin",
          allelements: [
            {
              weapon: "583560",
              element: "wind",
              type: "paladin",
            },
            {
              weapon: "583558",
              element: "water",
              type: "paladin",
            },
            {
              weapon: "583557",
              element: "fire",
              type: "paladin",
            },
            {
              weapon: "583556",
              element: "earth",
              type: "paladin",
            },
          ],
        },
        {
          headWeapon: "mage",
          allelements: [
            {
              weapon: "583269",
              element: "wind",
              type: "mage",
            },
            {
              weapon: "583267",
              element: "water",
              type: "mage",
            },
            {
              weapon: "583266",
              element: "fire",
              type: "mage",
            },
            {
              weapon: "583265",
              element: "earth",
              type: "mage",
            },
          ],
        },
        {
          headWeapon: "healer",
          allelements: [
            {
              weapon: "583264",
              element: "wind",
              type: "healer",
            },
            {
              weapon: "583262",
              element: "water",
              type: "healer",
            },
            {
              weapon: "583261",
              element: "fire",
              type: "healer",
            },
            {
              weapon: "583260",
              element: "earth",
              type: "healer",
            },
          ],
        },
        {
          headWeapon: "engineer",
          allelements: [
            {
              weapon: "583259",
              element: "wind",
              type: "engineer",
            },
            {
              weapon: "583258",
              element: "water",
              type: "engineer",
            },
            {
              weapon: "583257",
              element: "fire",
              type: "engineer",
            },
            {
              weapon: "583256",
              element: "earth",
              type: "engineer",
            },
          ],
        },
        {
          headWeapon: "elder",
          allelements: [
            {
              weapon: "583255",
              element: "wind",
              type: "elder",
            },
            {
              weapon: "583254",
              element: "water",
              type: "elder",
            },
            {
              weapon: "583253",
              element: "fire",
              type: "elder",
            },
            {
              weapon: "583252",
              element: "earth",
              type: "elder",
            },
          ],
        },
        {
          headWeapon: "druid",
          allelements: [
            {
              weapon: "583251",
              element: "wind",
              type: "druid",
            },
            {
              weapon: "583249",
              element: "water",
              type: "druid",
            },
            {
              weapon: "583248",
              element: "fire",
              type: "druid",
            },
            {
              weapon: "583247",
              element: "earth",
              type: "druid",
            },
          ],
        },
        {
          headWeapon: "dreneya",
          allelements: [
            {
              weapon: "583005",
              element: "wind",
              type: "dreneya",
            },
            {
              weapon: "583004",
              element: "water",
              type: "dreneya",
            },
            {
              weapon: "583003",
              element: "fire",
              type: "dreneya",
            },
            {
              weapon: "583002",
              element: "earth",
              type: "dreneya",
            },
          ],
        },
        {
          headWeapon: "berserker",
          allelements: [
            {
              weapon: "583001",
              element: "wind",
              type: "berserker",
            },
            {
              weapon: "583000",
              element: "water",
              type: "berserker",
            },
            {
              weapon: "582999",
              element: "fire",
              type: "berserker",
            },
            {
              weapon: "582998",
              element: "earth",
              type: "berserker",
            },
          ],
        },
        {
          headWeapon: "bard",
          allelements: [
            {
              weapon: "582967",
              element: "wind",
              type: "bard",
            },
            {
              weapon: "582966",
              element: "water",
              type: "bard",
            },
            {
              weapon: "582965",
              element: "fire",
              type: "bard",
            },
            {
              weapon: "582964",
              element: "earth",
              type: "bard",
            },
          ],
        },
        {
          headWeapon: "archer",
          allelements: [
            {
              weapon: "582963",
              element: "wind",
              type: "archer",
            },
            {
              weapon: "582961",
              element: "water",
              type: "archer",
            },
            {
              weapon: "582960",
              element: "fire",
              type: "archer",
            },
            {
              weapon: "582959",
              element: "earth",
              type: "archer",
            },
          ],
        },
        {
          headWeapon: "warrior",
          allelements: [
            {
              weapon: "556633",
              element: "wind",
              type: "warrior",
            },
            {
              weapon: "556632",
              element: "water",
              type: "warrior",
            },
            {
              weapon: "556631",
              element: "fire",
              type: "warrior",
            },
            {
              weapon: "556630",
              element: "earth",
              type: "warrior",
            },
          ],
        },
        {
          headWeapon: "tauren",
          allelements: [
            {
              weapon: "556629",
              element: "wind",
              type: "tauren",
            },
            {
              weapon: "556628",
              element: "water",
              type: "tauren",
            },
            {
              weapon: "556627",
              element: "fire",
              type: "tauren",
            },
            {
              weapon: "556626",
              element: "earth",
              type: "tauren",
            },
          ],
        },
        {
          headWeapon: "smith",
          allelements: [
            {
              weapon: "556624",
              element: "wind",
              type: "smith",
            },
            {
              weapon: "556623",
              element: "water",
              type: "smith",
            },
            {
              weapon: "556622",
              element: "fire",
              type: "smith",
            },
            {
              weapon: "556621",
              element: "earth",
              type: "smith",
            },
          ],
        },
        {
          headWeapon: "shooter",
          allelements: [
            {
              weapon: "556612",
              element: "wind",
              type: "shooter",
            },
            {
              weapon: "556611",
              element: "water",
              type: "shooter",
            },
            {
              weapon: "556609",
              element: "fire",
              type: "shooter",
            },
            {
              weapon: "556608",
              element: "earth",
              type: "shooter",
            },
          ],
        },
        {
          headWeapon: "priest",
          allelements: [
            {
              weapon: "556478",
              element: "wind",
              type: "priest",
            },
            {
              weapon: "556477",
              element: "water",
              type: "priest",
            },
            {
              weapon: "556476",
              element: "fire",
              type: "priest",
            },
            {
              weapon: "556475",
              element: "earth",
              type: "priest",
            },
          ],
        },
        {
          headWeapon: "paladin",
          allelements: [
            {
              weapon: "556471",
              element: "wind",
              type: "paladin",
            },
            {
              weapon: "556470",
              element: "water",
              type: "paladin",
            },
            {
              weapon: "556467",
              element: "fire",
              type: "paladin",
            },
            {
              weapon: "556466",
              element: "earth",
              type: "paladin",
            },
          ],
        },
        {
          headWeapon: "mage",
          allelements: [
            {
              weapon: "556418",
              element: "wind",
              type: "mage",
            },
            {
              weapon: "556414",
              element: "water",
              type: "mage",
            },
            {
              weapon: "556412",
              element: "fire",
              type: "mage",
            },
            {
              weapon: "556410",
              element: "earth",
              type: "mage",
            },
          ],
        },
        {
          headWeapon: "healer",
          allelements: [
            {
              weapon: "556378",
              element: "wind",
              type: "healer",
            },
            {
              weapon: "556377",
              element: "water",
              type: "healer",
            },
            {
              weapon: "556376",
              element: "fire",
              type: "healer",
            },
            {
              weapon: "556375",
              element: "earth",
              type: "healer",
            },
          ],
        },
        {
          headWeapon: "engineer",
          allelements: [
            {
              weapon: "556373",
              element: "wind",
              type: "engineer",
            },
            {
              weapon: "556372",
              element: "water",
              type: "engineer",
            },
            {
              weapon: "556371",
              element: "fire",
              type: "engineer",
            },
            {
              weapon: "556370",
              element: "earth",
              type: "engineer",
            },
          ],
        },
        {
          headWeapon: "elder",
          allelements: [
            {
              weapon: "556264",
              element: "wind",
              type: "elder",
            },
            {
              weapon: "556260",
              element: "water",
              type: "elder",
            },
            {
              weapon: "556259",
              element: "fire",
              type: "elder",
            },
            {
              weapon: "556258",
              element: "earth",
              type: "elder",
            },
          ],
        },
        {
          headWeapon: "druid",
          allelements: [
            {
              weapon: "552855",
              element: "wind",
              type: "druid",
            },
            {
              weapon: "552853",
              element: "water",
              type: "druid",
            },
            {
              weapon: "552852",
              element: "fire",
              type: "druid",
            },
            {
              weapon: "552851",
              element: "earth",
              type: "druid",
            },
          ],
        },
        {
          headWeapon: "dreneya",
          allelements: [
            {
              weapon: "552826",
              element: "wind",
              type: "dreneya",
            },
            {
              weapon: "552825",
              element: "water",
              type: "dreneya",
            },
            {
              weapon: "552824",
              element: "fire",
              type: "dreneya",
            },
            {
              weapon: "552823",
              element: "earth",
              type: "dreneya",
            },
          ],
        },
        {
          headWeapon: "berserker",
          allelements: [
            {
              weapon: "552821",
              element: "wind",
              type: "berserker",
            },
            {
              weapon: "552820",
              element: "water",
              type: "berserker",
            },
            {
              weapon: "552819",
              element: "fire",
              type: "berserker",
            },
            {
              weapon: "552818",
              element: "earth",
              type: "berserker",
            },
          ],
        },
        {
          headWeapon: "bard",
          allelements: [
            {
              weapon: "552782",
              element: "wind",
              type: "bard",
            },
            {
              weapon: "552781",
              element: "water",
              type: "bard",
            },
            {
              weapon: "552780",
              element: "fire",
              type: "bard",
            },
            {
              weapon: "552779",
              element: "earth",
              type: "bard",
            },
          ],
        },
        {
          headWeapon: "archer",
          allelements: [
            {
              weapon: "552776",
              element: "wind",
              type: "archer",
            },
            {
              weapon: "552775",
              element: "water",
              type: "archer",
            },
            {
              weapon: "552774",
              element: "fire",
              type: "archer",
            },
            {
              weapon: "552773",
              element: "earth",
              type: "archer",
            },
          ],
        },
        {
          headWeapon: "warrior",
          allelements: [
            {
              weapon: "552580",
              element: "wind",
              type: "warrior",
            },
            {
              weapon: "552578",
              element: "water",
              type: "warrior",
            },
            {
              weapon: "552577",
              element: "fire",
              type: "warrior",
            },
            {
              weapon: "552576",
              element: "earth",
              type: "warrior",
            },
          ],
        },
        {
          headWeapon: "tauren",
          allelements: [
            {
              weapon: "552574",
              element: "wind",
              type: "tauren",
            },
            {
              weapon: "552572",
              element: "water",
              type: "tauren",
            },
            {
              weapon: "552571",
              element: "fire",
              type: "tauren",
            },
            {
              weapon: "552570",
              element: "earth",
              type: "tauren",
            },
          ],
        },
        {
          headWeapon: "smith",
          allelements: [
            {
              weapon: "552568",
              element: "wind",
              type: "smith",
            },
            {
              weapon: "552567",
              element: "water",
              type: "smith",
            },
            {
              weapon: "552566",
              element: "fire",
              type: "smith",
            },
            {
              weapon: "552565",
              element: "earth",
              type: "smith",
            },
          ],
        },
        {
          headWeapon: "shooter",
          allelements: [
            {
              weapon: "552564",
              element: "wind",
              type: "shooter",
            },
            {
              weapon: "552563",
              element: "water",
              type: "shooter",
            },
            {
              weapon: "552562",
              element: "fire",
              type: "shooter",
            },
            {
              weapon: "552561",
              element: "earth",
              type: "shooter",
            },
          ],
        },
        {
          headWeapon: "priest",
          allelements: [
            {
              weapon: "552556",
              element: "wind",
              type: "priest",
            },
            {
              weapon: "552555",
              element: "water",
              type: "priest",
            },
            {
              weapon: "552554",
              element: "fire",
              type: "priest",
            },
            {
              weapon: "552551",
              element: "earth",
              type: "priest",
            },
          ],
        },
        {
          headWeapon: "paladin",
          allelements: [
            {
              weapon: "552539",
              element: "wind",
              type: "paladin",
            },
            {
              weapon: "552538",
              element: "water",
              type: "paladin",
            },
            {
              weapon: "552537",
              element: "fire",
              type: "paladin",
            },
            {
              weapon: "552536",
              element: "earth",
              type: "paladin",
            },
          ],
        },
        {
          headWeapon: "mage",
          allelements: [
            {
              weapon: "552534",
              element: "wind",
              type: "mage",
            },
            {
              weapon: "552533",
              element: "water",
              type: "mage",
            },
            {
              weapon: "552532",
              element: "fire",
              type: "mage",
            },
            {
              weapon: "552530",
              element: "earth",
              type: "mage",
            },
          ],
        },
        {
          headWeapon: "healer",
          allelements: [
            {
              weapon: "552513",
              element: "wind",
              type: "healer",
            },
            {
              weapon: "552511",
              element: "water",
              type: "healer",
            },
            {
              weapon: "552509",
              element: "fire",
              type: "healer",
            },
            {
              weapon: "552507",
              element: "earth",
              type: "healer",
            },
          ],
        },
        {
          headWeapon: "engineer",
          allelements: [
            {
              weapon: "552278",
              element: "wind",
              type: "engineer",
            },
            {
              weapon: "552276",
              element: "water",
              type: "engineer",
            },
            {
              weapon: "552275",
              element: "fire",
              type: "engineer",
            },
            {
              weapon: "552274",
              element: "earth",
              type: "engineer",
            },
          ],
        },
        {
          headWeapon: "elder",
          allelements: [
            {
              weapon: "552270",
              element: "wind",
              type: "elder",
            },
            {
              weapon: "552269",
              element: "water",
              type: "elder",
            },
            {
              weapon: "552268",
              element: "fire",
              type: "elder",
            },
            {
              weapon: "552267",
              element: "earth",
              type: "elder",
            },
          ],
        },
        {
          headWeapon: "druid",
          allelements: [
            {
              weapon: "552260",
              element: "wind",
              type: "druid",
            },
            {
              weapon: "552257",
              element: "water",
              type: "druid",
            },
            {
              weapon: "552255",
              element: "fire",
              type: "druid",
            },
            {
              weapon: "552251",
              element: "earth",
              type: "druid",
            },
          ],
        },
        {
          headWeapon: "dreneya",
          allelements: [
            {
              weapon: "552244",
              element: "wind",
              type: "dreneya",
            },
            {
              weapon: "552241",
              element: "water",
              type: "dreneya",
            },
            {
              weapon: "552240",
              element: "fire",
              type: "dreneya",
            },
            {
              weapon: "552239",
              element: "earth",
              type: "dreneya",
            },
          ],
        },
        {
          headWeapon: "berserker",
          allelements: [
            {
              weapon: "551947",
              element: "wind",
              type: "berserker",
            },
            {
              weapon: "551946",
              element: "water",
              type: "berserker",
            },
            {
              weapon: "551944",
              element: "fire",
              type: "berserker",
            },
            {
              weapon: "551943",
              element: "earth",
              type: "berserker",
            },
          ],
        },
        {
          headWeapon: "bard",
          allelements: [
            {
              weapon: "551863",
              element: "wind",
              type: "bard",
            },
            {
              weapon: "551862",
              element: "water",
              type: "bard",
            },
            {
              weapon: "551861",
              element: "fire",
              type: "bard",
            },
            {
              weapon: "551860",
              element: "earth",
              type: "bard",
            },
          ],
        },
        {
          headWeapon: "archer",
          allelements: [
            {
              weapon: "551826",
              element: "wind",
              type: "archer",
            },
            {
              weapon: "551825",
              element: "water",
              type: "archer",
            },
            {
              weapon: "551824",
              element: "fire",
              type: "archer",
            },
            {
              weapon: "551823",
              element: "earth",
              type: "archer",
            },
          ],
        },
        {
          headWeapon: "warrior",
          allelements: [
            {
              weapon: "551223",
              element: "wind",
              type: "warrior",
            },
            {
              weapon: "551222",
              element: "water",
              type: "warrior",
            },
            {
              weapon: "551221",
              element: "fire",
              type: "warrior",
            },
            {
              weapon: "551220",
              element: "earth",
              type: "warrior",
            },
          ],
        },
        {
          headWeapon: "tauren",
          allelements: [
            {
              weapon: "551039",
              element: "wind",
              type: "tauren",
            },
            {
              weapon: "551037",
              element: "water",
              type: "tauren",
            },
            {
              weapon: "551035",
              element: "fire",
              type: "tauren",
            },
            {
              weapon: "551034",
              element: "earth",
              type: "tauren",
            },
          ],
        },
        {
          headWeapon: "smith",
          allelements: [
            {
              weapon: "551029",
              element: "wind",
              type: "smith",
            },
            {
              weapon: "551028",
              element: "water",
              type: "smith",
            },
            {
              weapon: "551027",
              element: "fire",
              type: "smith",
            },
            {
              weapon: "551026",
              element: "earth",
              type: "smith",
            },
          ],
        },
        {
          headWeapon: "shooter",
          allelements: [
            {
              weapon: "551013",
              element: "wind",
              type: "shooter",
            },
            {
              weapon: "551011",
              element: "water",
              type: "shooter",
            },
            {
              weapon: "551010",
              element: "fire",
              type: "shooter",
            },
            {
              weapon: "551008",
              element: "earth",
              type: "shooter",
            },
          ],
        },
        {
          headWeapon: "priest",
          allelements: [
            {
              weapon: "550675",
              element: "wind",
              type: "priest",
            },
            {
              weapon: "550674",
              element: "water",
              type: "priest",
            },
            {
              weapon: "550672",
              element: "fire",
              type: "priest",
            },
            {
              weapon: "550671",
              element: "earth",
              type: "priest",
            },
          ],
        },
        {
          headWeapon: "paladin",
          allelements: [
            {
              weapon: "550668",
              element: "wind",
              type: "paladin",
            },
            {
              weapon: "550667",
              element: "water",
              type: "paladin",
            },
            {
              weapon: "550666",
              element: "fire",
              type: "paladin",
            },
            {
              weapon: "550665",
              element: "earth",
              type: "paladin",
            },
          ],
        },
        {
          headWeapon: "mage",
          allelements: [
            {
              weapon: "550663",
              element: "wind",
              type: "mage",
            },
            {
              weapon: "550662",
              element: "water",
              type: "mage",
            },
            {
              weapon: "550661",
              element: "fire",
              type: "mage",
            },
            {
              weapon: "550660",
              element: "earth",
              type: "mage",
            },
          ],
        },
        {
          headWeapon: "healer",
          allelements: [
            {
              weapon: "550653",
              element: "wind",
              type: "healer",
            },
            {
              weapon: "550652",
              element: "water",
              type: "healer",
            },
            {
              weapon: "550651",
              element: "fire",
              type: "healer",
            },
            {
              weapon: "550649",
              element: "earth",
              type: "healer",
            },
          ],
        },
        {
          headWeapon: "engineer",
          allelements: [
            {
              weapon: "550642",
              element: "wind",
              type: "engineer",
            },
            {
              weapon: "550640",
              element: "water",
              type: "engineer",
            },
            {
              weapon: "550639",
              element: "fire",
              type: "engineer",
            },
            {
              weapon: "550638",
              element: "earth",
              type: "engineer",
            },
          ],
        },
        {
          headWeapon: "elder",
          allelements: [
            {
              weapon: "550610",
              element: "wind",
              type: "elder",
            },
            {
              weapon: "550609",
              element: "water",
              type: "elder",
            },
            {
              weapon: "550608",
              element: "fire",
              type: "elder",
            },
            {
              weapon: "550607",
              element: "earth",
              type: "elder",
            },
          ],
        },
        {
          headWeapon: "druid",
          allelements: [
            {
              weapon: "550440",
              element: "wind",
              type: "druid",
            },
            {
              weapon: "550439",
              element: "water",
              type: "druid",
            },
            {
              weapon: "550438",
              element: "fire",
              type: "druid",
            },
            {
              weapon: "550437",
              element: "earth",
              type: "druid",
            },
          ],
        },
        {
          headWeapon: "dreneya",
          allelements: [
            {
              weapon: "550270",
              element: "wind",
              type: "dreneya",
            },
            {
              weapon: "550269",
              element: "water",
              type: "dreneya",
            },
            {
              weapon: "550268",
              element: "fire",
              type: "dreneya",
            },
            {
              weapon: "550266",
              element: "earth",
              type: "dreneya",
            },
          ],
        },
        {
          headWeapon: "berserker",
          allelements: [
            {
              weapon: "550257",
              element: "wind",
              type: "berserker",
            },
            {
              weapon: "550256",
              element: "water",
              type: "berserker",
            },
            {
              weapon: "550255",
              element: "fire",
              type: "berserker",
            },
            {
              weapon: "550253",
              element: "earth",
              type: "berserker",
            },
          ],
        },
        {
          headWeapon: "bard",
          allelements: [
            {
              weapon: "550252",
              element: "wind",
              type: "bard",
            },
            {
              weapon: "550251",
              element: "water",
              type: "bard",
            },
            {
              weapon: "550250",
              element: "fire",
              type: "bard",
            },
            {
              weapon: "550249",
              element: "earth",
              type: "bard",
            },
          ],
        },
        {
          headWeapon: "archer",
          allelements: [
            {
              weapon: "550247",
              element: "wind",
              type: "archer",
            },
            {
              weapon: "550246",
              element: "water",
              type: "archer",
            },
            {
              weapon: "550244",
              element: "fire",
              type: "archer",
            },
            {
              weapon: "550243",
              element: "earth",
              type: "archer",
            },
          ],
        },
        {
          headWeapon: "warrior",
          allelements: [
            {
              weapon: "549892",
              element: "wind",
              type: "warrior",
            },
            {
              weapon: "549890",
              element: "water",
              type: "warrior",
            },
            {
              weapon: "549889",
              element: "fire",
              type: "warrior",
            },
            {
              weapon: "549887",
              element: "earth",
              type: "warrior",
            },
          ],
        },
        {
          headWeapon: "tauren",
          allelements: [
            {
              weapon: "549884",
              element: "wind",
              type: "tauren",
            },
            {
              weapon: "549882",
              element: "water",
              type: "tauren",
            },
            {
              weapon: "549880",
              element: "fire",
              type: "tauren",
            },
            {
              weapon: "549879",
              element: "earth",
              type: "tauren",
            },
          ],
        },
        {
          headWeapon: "smith",
          allelements: [
            {
              weapon: "549878",
              element: "wind",
              type: "smith",
            },
            {
              weapon: "549877",
              element: "water",
              type: "smith",
            },
            {
              weapon: "549876",
              element: "fire",
              type: "smith",
            },
            {
              weapon: "549874",
              element: "earth",
              type: "smith",
            },
          ],
        },
        {
          headWeapon: "shooter",
          allelements: [
            {
              weapon: "549871",
              element: "wind",
              type: "shooter",
            },
            {
              weapon: "549870",
              element: "water",
              type: "shooter",
            },
            {
              weapon: "549869",
              element: "fire",
              type: "shooter",
            },
            {
              weapon: "549868",
              element: "earth",
              type: "shooter",
            },
          ],
        },
        {
          headWeapon: "priest",
          allelements: [
            {
              weapon: "549867",
              element: "wind",
              type: "priest",
            },
            {
              weapon: "549866",
              element: "water",
              type: "priest",
            },
            {
              weapon: "549864",
              element: "fire",
              type: "priest",
            },
            {
              weapon: "549863",
              element: "earth",
              type: "priest",
            },
          ],
        },
        {
          headWeapon: "paladin",
          allelements: [
            {
              weapon: "549861",
              element: "wind",
              type: "paladin",
            },
            {
              weapon: "549860",
              element: "water",
              type: "paladin",
            },
            {
              weapon: "549859",
              element: "fire",
              type: "paladin",
            },
            {
              weapon: "549857",
              element: "earth",
              type: "paladin",
            },
          ],
        },
        {
          headWeapon: "mage",
          allelements: [
            {
              weapon: "549855",
              element: "wind",
              type: "mage",
            },
            {
              weapon: "549854",
              element: "water",
              type: "mage",
            },
            {
              weapon: "549853",
              element: "fire",
              type: "mage",
            },
            {
              weapon: "549852",
              element: "earth",
              type: "mage",
            },
          ],
        },
        {
          headWeapon: "healer",
          allelements: [
            {
              weapon: "549851",
              element: "wind",
              type: "healer",
            },
            {
              weapon: "549850",
              element: "water",
              type: "healer",
            },
            {
              weapon: "549849",
              element: "fire",
              type: "healer",
            },
            {
              weapon: "549848",
              element: "earth",
              type: "healer",
            },
          ],
        },
        {
          headWeapon: "engineer",
          allelements: [
            {
              weapon: "549846",
              element: "wind",
              type: "engineer",
            },
            {
              weapon: "549845",
              element: "water",
              type: "engineer",
            },
            {
              weapon: "549844",
              element: "fire",
              type: "engineer",
            },
            {
              weapon: "549843",
              element: "earth",
              type: "engineer",
            },
          ],
        },
        {
          headWeapon: "elder",
          allelements: [
            {
              weapon: "549603",
              element: "wind",
              type: "elder",
            },
            {
              weapon: "549601",
              element: "water",
              type: "elder",
            },
            {
              weapon: "549599",
              element: "fire",
              type: "elder",
            },
            {
              weapon: "549598",
              element: "earth",
              type: "elder",
            },
          ],
        },
        {
          headWeapon: "druid",
          allelements: [
            {
              weapon: "549593",
              element: "wind",
              type: "druid",
            },
            {
              weapon: "549592",
              element: "water",
              type: "druid",
            },
            {
              weapon: "549590",
              element: "fire",
              type: "druid",
            },
            {
              weapon: "549589",
              element: "earth",
              type: "druid",
            },
          ],
        },
        {
          headWeapon: "dreneya",
          allelements: [
            {
              weapon: "549588",
              element: "wind",
              type: "dreneya",
            },
            {
              weapon: "549587",
              element: "water",
              type: "dreneya",
            },
            {
              weapon: "549586",
              element: "fire",
              type: "dreneya",
            },
            {
              weapon: "549585",
              element: "earth",
              type: "dreneya",
            },
          ],
        },
        {
          headWeapon: "berserker",
          allelements: [
            {
              weapon: "549583",
              element: "wind",
              type: "berserker",
            },
            {
              weapon: "549582",
              element: "water",
              type: "berserker",
            },
            {
              weapon: "549580",
              element: "fire",
              type: "berserker",
            },
            {
              weapon: "549579",
              element: "earth",
              type: "berserker",
            },
          ],
        },
        {
          headWeapon: "bard",
          allelements: [
            {
              weapon: "549576",
              element: "wind",
              type: "bard",
            },
            {
              weapon: "549575",
              element: "water",
              type: "bard",
            },
            {
              weapon: "549574",
              element: "fire",
              type: "bard",
            },
            {
              weapon: "549573",
              element: "earth",
              type: "bard",
            },
          ],
        },
        {
          headWeapon: "archer",
          allelements: [
            {
              weapon: "549572",
              element: "wind",
              type: "archer",
            },
            {
              weapon: "549570",
              element: "water",
              type: "archer",
            },
            {
              weapon: "549569",
              element: "fire",
              type: "archer",
            },
            {
              weapon: "549568",
              element: "earth",
              type: "archer",
            },
          ],
        },
      ],
      activeWeaponName: "",
      activeRecipe: {
        arreyIndex: "",
        weapon: "",
        tempaleID: "",
        res: "",
        resNum: "",
        resIndex: "",
      },
      openRecipe: false,
      allUserWeapon: {}, // все оружия
      allUserRes: {}, // все оружия
      modalResult: {
        open: false, // показать модалку результата
        loading: true, // ожидание результата
      },
      craftpay: false, //
      modalcraft: {
        top: "upgrade armor",
        title: "The blacksmith is ready to start work",
        button: "first",
        descr:
          `The blacksmith always takes money <span>(30 ${process.env.REACT_APP_VAL})<i></i></span> for the front, since nothing motivates like a salary. `,
      },
      craftfail: "",
      craftResult: "",
      activePage: 1,
      countPage: "2",
      loadingNft: true,
    };

    this.clearError = this.clearError.bind(this);
    this.GetUserWeaponList = this.GetUserWeaponList.bind(this);
    this.openRecipe = this.openRecipe.bind(this);
    this.backFromRecipe = this.backFromRecipe.bind(this);

    this.craftpay = this.craftpay.bind(this);
    this.opencraftmodal = this.opencraftmodal.bind(this);
    this.craft = this.craft.bind(this);
    this.medalCheck = this.medalCheck.bind(this);
    //this.GetPandaPartList = this.GetPandaPartList.bind(this);
    //this.GetPandaWeaponList = this.GetPandaWeaponList.bind(this);
    this.closeModalResult = this.closeModalResult.bind(this);
    this.closeModalSteps = this.closeModalSteps.bind(this);
    this.getAllNft = this.getAllNft.bind(this);

    this.audioMusicError = new Audio(musicError);
    this.audioMusicOpenCraft = new Audio(musicOpenCraft);
    this.audioMusicCraftSuccess = new Audio(craftSuccess);
    this.audioMusicCraftfail = new Audio(craftFail);
    this.audioMusicNewClick = new Audio(newClick);
  }

  componentDidMount() {
    this.getAllNft();
    this.medalCheck();

    //var newArmors = '';
    //var res = [431182, 431183, 431184, 431185, 431186];
    // arr.map((item => {
    //     if (item.length) {
    //         for (let i = 0; i < item.length - 1; i++) {
    //             newArmors += `case ${item[i]}: weaponUpgr = ${item[i + 1]};
    //             break;`
    //         }

    //     }
    // }))
    // console.log("newArmors", newArmors)
  }

  async medalCheck() {
    try {
      const activeUser = this.props.ual.activeUser;
      const accountName = await activeUser.getAccountName();
      //console.log(activeUser);
      //console.log(accountName);
      let rpcc;
      if (this.props.ual.activeUser.rpc != undefined) {
        rpcc = this.props.ual.activeUser.rpc;
      } else if (
        this.props.ual.activeUser.rpc == undefined &&
        this.props.ual.activeUser.wax
      ) {
        rpcc = this.props.ual.activeUser.wax.rpc;
      } else {
        rpcc = new JsonRpc(localStorage.getItem("rpc"));
      }
      const results = await rpcc.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "medaltop", // name of the table as specified by the contract abi
        //"table": "usersnew",    // name of the table as specified by the contract abi
        limit: 1,
        lower_bound: accountName,
        upper_bound: accountName,
        //lower_bound: 0,
        key_type: "i64",
        //"index_position": 2,
        //"limit": -1,
        reverse: !0,
        show_payer: false,
        index_position: 1,
      });
      const originRowUser = results.rows[0];
      console.log("originRowUser", originRowUser);

      var totalLuck = 0;
      if (results.rows[0]) {
        if (originRowUser.medal100) {
          totalLuck = 5;
          if (originRowUser.medal10) {
            totalLuck += 2.5;
            if (originRowUser.medal300) {
              totalLuck += 1.5;
            }
          } else if (originRowUser.medal300) {
            totalLuck += 1.5;
          }
        } else if (originRowUser.medal10) {
          totalLuck = 5;
          if (originRowUser.medal100) {
            totalLuck += 2.5;
            if (originRowUser.medal300) {
              totalLuck += 1.5;
            }
          } else if (originRowUser.medal300) {
            totalLuck += 1.5;
          }
        } else if (originRowUser.medal300) {
          totalLuck = 3;
          if (originRowUser.medal10) {
            totalLuck += 2.5;
            if (originRowUser.medal100) {
              totalLuck += 2.5;
            }
          } else if (originRowUser.medal100) {
            totalLuck += 2.5;
          }
        }
      }

      if (originRowUser != undefined) {
        this.setState({
          contractMedal: totalLuck,
        });
      }
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
    }
  }

  async clearError() {
    this.setState({
      errorText: "",
      hasError: false,
    });
  }

  async getAllNft() {
    try {
      let promResWood = [];
      let promResSteel = [];
      let promResTit = [];
      let promResKves = [];
      let promResGold = [];
      let promResDwem = [];
      let promStone = [];

      this.setState({
        ...this.state,
        loadingNft: true,
      });

      //promResWood.push(this.GetUserWeaponList(431181, 'res'));
      promResSteel.push(this.GetUserWeaponList(431182, "res"));
      promResTit.push(this.GetUserWeaponList(431183, "res"));
      promResKves.push(this.GetUserWeaponList(431184, "res"));
      promResGold.push(this.GetUserWeaponList(431185, "res"));
      promResDwem.push(this.GetUserWeaponList(431186, "res"));
      promStone.push(this.GetUserWeaponList("", "armor"));

      let resultW = await Promise.all(promResWood);
      let resultSt = await Promise.all(promResSteel);
      let resultT = await Promise.all(promResTit);
      let resultK = await Promise.all(promResKves);
      let resultG = await Promise.all(promResGold);
      let resultD = await Promise.all(promResDwem);
      let resultS = await Promise.all(promStone);

      var resultNew = [];
      var resultNewRes = [];
      resultNewRes.push(
        ...resultSt[0],
        ...resultT[0],
        ...resultK[0],
        ...resultG[0],
        ...resultD[0]
      );
      resultNew.push(...resultS[0]);

      //console.log("resultNew", resultNew);
      let newData = {};
      Object.entries(resultNew).forEach((pair) => {
        let [key, value] = pair;
        //console.log(value);

        if (!newData[value.template.template_id])
          newData[value.template.template_id] = [];
        newData[value.template.template_id].push(value);
      });

      let newDataRes = {};
      Object.entries(resultNewRes).forEach((pair) => {
        let [key, value] = pair;
        //console.log(value);

        if (!newDataRes[value.template.template_id])
          newDataRes[value.template.template_id] = [];
        newDataRes[value.template.template_id].push(value);
      });
      //console.log("newDataRes", newDataRes)
      var arreyObj = Object.values(newData);
      var arreyObjRes = Object.values(newDataRes);

      //console.log('arreyObj.length', arreyObj.length)

      this.setState({
        ...this.state,
        allUserWeapon: arreyObj,
        allUserRes: arreyObjRes,
        countPage: arreyObj.length / 8,
        loadingNft: false,
      });

      // result = result[0].map( (item) => {
      //     return {
      //         asset_id: item.asset_id,
      //         template_id: item.template.template_id,
      //         schema_name: item.schema.schema_name
      //     }
      // })

      // resultS = resultS[0].map( (item) => {
      //     return {
      //         asset_id: item.asset_id,
      //         template_id: item.template.template_id,
      //         schema_name: item.schema.schema_name
      //     }
      // })

      // var resultNew = [];
      // resultNew.push(...result, ...resultS);

      // this.setState({
      //     ...this.state,
      //     allparts: resultNew,
      //     counPartWeapon: {
      //         resource: result.length,
      //         stone: resultS.length
      //     },
      //     loadingNft: false
      // });
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
    }
  }

  async GetUserWeaponList(parts, type) {
    var schema;
    var many = 1000;
    if (type == "res") {
      schema = "resources";
      many = 100;
    } else {
      schema = "armor";
    }
    // console.log('parts', parts);
    // console.log('type', type);
    // console.log('schema', schema);

    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    //arr.push();
    // console.log("arr", arr);
    // console.log("this.state.activeRecipe.bluePrint", this.state.activeRecipe.bluePrint);
    return await api
      .getAssets({
        limit: many,
        owner: userName,
        collection_name: "nftpandawaxp",
        schema_name: schema,
        //schema_name: ["armor"/*, "promo"*/],
        template_id: parts,
      })
      .then((assets) => {
        return assets;
      });
  }

  async openRecipe(index, templateId) {
    //console.log("index", index);
    var weaponUpgr = 0;

    switch (parseInt(templateId)) {
      case 549570:
        weaponUpgr = 550246;
        break;
      case 550246:
        weaponUpgr = 551825;
        break;
      case 551825:
        weaponUpgr = 552775;
        break;
      case 552775:
        weaponUpgr = 582961;
        break;
      case 582961:
        weaponUpgr = 595073;
        break;
      case 549569:
        weaponUpgr = 550244;
        break;
      case 550244:
        weaponUpgr = 551824;
        break;
      case 551824:
        weaponUpgr = 552774;
        break;
      case 552774:
        weaponUpgr = 582960;
        break;
      case 582960:
        weaponUpgr = 595072;
        break;
      case 549572:
        weaponUpgr = 550247;
        break;
      case 550247:
        weaponUpgr = 551826;
        break;
      case 551826:
        weaponUpgr = 552776;
        break;
      case 552776:
        weaponUpgr = 582963;
        break;
      case 582963:
        weaponUpgr = 595074;
        break;
      case 549568:
        weaponUpgr = 550243;
        break;
      case 550243:
        weaponUpgr = 551823;
        break;
      case 551823:
        weaponUpgr = 552773;
        break;
      case 552773:
        weaponUpgr = 582959;
        break;
      case 582959:
        weaponUpgr = 595066;
        break;
      case 549575:
        weaponUpgr = 550251;
        break;
      case 550251:
        weaponUpgr = 551862;
        break;
      case 551862:
        weaponUpgr = 552781;
        break;
      case 552781:
        weaponUpgr = 582966;
        break;
      case 582966:
        weaponUpgr = 595077;
        break;
      case 549574:
        weaponUpgr = 550250;
        break;
      case 550250:
        weaponUpgr = 551861;
        break;
      case 551861:
        weaponUpgr = 552780;
        break;
      case 552780:
        weaponUpgr = 582965;
        break;
      case 582965:
        weaponUpgr = 595076;
        break;
      case 549576:
        weaponUpgr = 550252;
        break;
      case 550252:
        weaponUpgr = 551863;
        break;
      case 551863:
        weaponUpgr = 552782;
        break;
      case 552782:
        weaponUpgr = 582967;
        break;
      case 582967:
        weaponUpgr = 595078;
        break;
      case 549573:
        weaponUpgr = 550249;
        break;
      case 550249:
        weaponUpgr = 551860;
        break;
      case 551860:
        weaponUpgr = 552779;
        break;
      case 552779:
        weaponUpgr = 582964;
        break;
      case 582964:
        weaponUpgr = 595075;
        break;
      case 549582:
        weaponUpgr = 550256;
        break;
      case 550256:
        weaponUpgr = 551946;
        break;
      case 551946:
        weaponUpgr = 552820;
        break;
      case 552820:
        weaponUpgr = 583000;
        break;
      case 583000:
        weaponUpgr = 595081;
        break;
      case 549580:
        weaponUpgr = 550255;
        break;
      case 550255:
        weaponUpgr = 551944;
        break;
      case 551944:
        weaponUpgr = 552819;
        break;
      case 552819:
        weaponUpgr = 582999;
        break;
      case 582999:
        weaponUpgr = 595080;
        break;
      case 549583:
        weaponUpgr = 550257;
        break;
      case 550257:
        weaponUpgr = 551947;
        break;
      case 551947:
        weaponUpgr = 552821;
        break;
      case 552821:
        weaponUpgr = 583001;
        break;
      case 583001:
        weaponUpgr = 595082;
        break;
      case 549579:
        weaponUpgr = 550253;
        break;
      case 550253:
        weaponUpgr = 551943;
        break;
      case 551943:
        weaponUpgr = 552818;
        break;
      case 552818:
        weaponUpgr = 582998;
        break;
      case 582998:
        weaponUpgr = 595079;
        break;
      case 549587:
        weaponUpgr = 550269;
        break;
      case 550269:
        weaponUpgr = 552241;
        break;
      case 552241:
        weaponUpgr = 552825;
        break;
      case 552825:
        weaponUpgr = 583004;
        break;
      case 583004:
        weaponUpgr = 595085;
        break;
      case 549586:
        weaponUpgr = 550268;
        break;
      case 550268:
        weaponUpgr = 552240;
        break;
      case 552240:
        weaponUpgr = 552824;
        break;
      case 552824:
        weaponUpgr = 583003;
        break;
      case 583003:
        weaponUpgr = 595084;
        break;
      case 549588:
        weaponUpgr = 550270;
        break;
      case 550270:
        weaponUpgr = 552244;
        break;
      case 552244:
        weaponUpgr = 552826;
        break;
      case 552826:
        weaponUpgr = 583005;
        break;
      case 583005:
        weaponUpgr = 595086;
        break;
      case 549585:
        weaponUpgr = 550266;
        break;
      case 550266:
        weaponUpgr = 552239;
        break;
      case 552239:
        weaponUpgr = 552823;
        break;
      case 552823:
        weaponUpgr = 583002;
        break;
      case 583002:
        weaponUpgr = 595083;
        break;
      case 549592:
        weaponUpgr = 550439;
        break;
      case 550439:
        weaponUpgr = 552257;
        break;
      case 552257:
        weaponUpgr = 552853;
        break;
      case 552853:
        weaponUpgr = 583249;
        break;
      case 583249:
        weaponUpgr = 595092;
        break;
      case 549590:
        weaponUpgr = 550438;
        break;
      case 550438:
        weaponUpgr = 552255;
        break;
      case 552255:
        weaponUpgr = 552852;
        break;
      case 552852:
        weaponUpgr = 583248;
        break;
      case 583248:
        weaponUpgr = 595091;
        break;
      case 549593:
        weaponUpgr = 550440;
        break;
      case 550440:
        weaponUpgr = 552260;
        break;
      case 552260:
        weaponUpgr = 552855;
        break;
      case 552855:
        weaponUpgr = 583251;
        break;
      case 583251:
        weaponUpgr = 595093;
        break;
      case 549589:
        weaponUpgr = 550437;
        break;
      case 550437:
        weaponUpgr = 552251;
        break;
      case 552251:
        weaponUpgr = 552851;
        break;
      case 552851:
        weaponUpgr = 583247;
        break;
      case 583247:
        weaponUpgr = 595090;
        break;
      case 549601:
        weaponUpgr = 550609;
        break;
      case 550609:
        weaponUpgr = 552269;
        break;
      case 552269:
        weaponUpgr = 556260;
        break;
      case 556260:
        weaponUpgr = 583254;
        break;
      case 583254:
        weaponUpgr = 595097;
        break;
      case 549599:
        weaponUpgr = 550608;
        break;
      case 550608:
        weaponUpgr = 552268;
        break;
      case 552268:
        weaponUpgr = 556259;
        break;
      case 556259:
        weaponUpgr = 583253;
        break;
      case 583253:
        weaponUpgr = 595096;
        break;
      case 549603:
        weaponUpgr = 550610;
        break;
      case 550610:
        weaponUpgr = 552270;
        break;
      case 552270:
        weaponUpgr = 556264;
        break;
      case 556264:
        weaponUpgr = 583255;
        break;
      case 583255:
        weaponUpgr = 595098;
        break;
      case 549598:
        weaponUpgr = 550607;
        break;
      case 550607:
        weaponUpgr = 552267;
        break;
      case 552267:
        weaponUpgr = 556258;
        break;
      case 556258:
        weaponUpgr = 583252;
        break;
      case 583252:
        weaponUpgr = 595095;
        break;
      case 549845:
        weaponUpgr = 550640;
        break;
      case 550640:
        weaponUpgr = 552276;
        break;
      case 552276:
        weaponUpgr = 556372;
        break;
      case 556372:
        weaponUpgr = 583258;
        break;
      case 583258:
        weaponUpgr = 595101;
        break;
      case 549844:
        weaponUpgr = 550639;
        break;
      case 550639:
        weaponUpgr = 552275;
        break;
      case 552275:
        weaponUpgr = 556371;
        break;
      case 556371:
        weaponUpgr = 583257;
        break;
      case 583257:
        weaponUpgr = 595100;
        break;
      case 549846:
        weaponUpgr = 550642;
        break;
      case 550642:
        weaponUpgr = 552278;
        break;
      case 552278:
        weaponUpgr = 556373;
        break;
      case 556373:
        weaponUpgr = 583259;
        break;
      case 583259:
        weaponUpgr = 595102;
        break;
      case 549843:
        weaponUpgr = 550638;
        break;
      case 550638:
        weaponUpgr = 552274;
        break;
      case 552274:
        weaponUpgr = 556370;
        break;
      case 556370:
        weaponUpgr = 583256;
        break;
      case 583256:
        weaponUpgr = 595099;
        break;
      case 549850:
        weaponUpgr = 550652;
        break;
      case 550652:
        weaponUpgr = 552511;
        break;
      case 552511:
        weaponUpgr = 556377;
        break;
      case 556377:
        weaponUpgr = 583262;
        break;
      case 583262:
        weaponUpgr = 595105;
        break;
      case 549849:
        weaponUpgr = 550651;
        break;
      case 550651:
        weaponUpgr = 552509;
        break;
      case 552509:
        weaponUpgr = 556376;
        break;
      case 556376:
        weaponUpgr = 583261;
        break;
      case 583261:
        weaponUpgr = 595104;
        break;
      case 549851:
        weaponUpgr = 550653;
        break;
      case 550653:
        weaponUpgr = 552513;
        break;
      case 552513:
        weaponUpgr = 556378;
        break;
      case 556378:
        weaponUpgr = 583264;
        break;
      case 583264:
        weaponUpgr = 595106;
        break;
      case 549848:
        weaponUpgr = 550649;
        break;
      case 550649:
        weaponUpgr = 552507;
        break;
      case 552507:
        weaponUpgr = 556375;
        break;
      case 556375:
        weaponUpgr = 583260;
        break;
      case 583260:
        weaponUpgr = 595103;
        break;
      case 549854:
        weaponUpgr = 550662;
        break;
      case 550662:
        weaponUpgr = 552533;
        break;
      case 552533:
        weaponUpgr = 556414;
        break;
      case 556414:
        weaponUpgr = 583267;
        break;
      case 583267:
        weaponUpgr = 595110;
        break;
      case 549853:
        weaponUpgr = 550661;
        break;
      case 550661:
        weaponUpgr = 552532;
        break;
      case 552532:
        weaponUpgr = 556412;
        break;
      case 556412:
        weaponUpgr = 583266;
        break;
      case 583266:
        weaponUpgr = 595109;
        break;
      case 549855:
        weaponUpgr = 550663;
        break;
      case 550663:
        weaponUpgr = 552534;
        break;
      case 552534:
        weaponUpgr = 556418;
        break;
      case 556418:
        weaponUpgr = 583269;
        break;
      case 583269:
        weaponUpgr = 595111;
        break;
      case 549852:
        weaponUpgr = 550660;
        break;
      case 550660:
        weaponUpgr = 552530;
        break;
      case 552530:
        weaponUpgr = 556410;
        break;
      case 556410:
        weaponUpgr = 583265;
        break;
      case 583265:
        weaponUpgr = 595108;
        break;
      case 549860:
        weaponUpgr = 550667;
        break;
      case 550667:
        weaponUpgr = 552538;
        break;
      case 552538:
        weaponUpgr = 556470;
        break;
      case 556470:
        weaponUpgr = 583558;
        break;
      case 583558:
        weaponUpgr = 595114;
        break;
      case 549859:
        weaponUpgr = 550666;
        break;
      case 550666:
        weaponUpgr = 552537;
        break;
      case 552537:
        weaponUpgr = 556467;
        break;
      case 556467:
        weaponUpgr = 583557;
        break;
      case 583557:
        weaponUpgr = 595113;
        break;
      case 549861:
        weaponUpgr = 550668;
        break;
      case 550668:
        weaponUpgr = 552539;
        break;
      case 552539:
        weaponUpgr = 556471;
        break;
      case 556471:
        weaponUpgr = 583560;
        break;
      case 583560:
        weaponUpgr = 595115;
        break;
      case 549857:
        weaponUpgr = 550665;
        break;
      case 550665:
        weaponUpgr = 552536;
        break;
      case 552536:
        weaponUpgr = 556466;
        break;
      case 556466:
        weaponUpgr = 583556;
        break;
      case 583556:
        weaponUpgr = 595112;
        break;
      case 549866:
        weaponUpgr = 550674;
        break;
      case 550674:
        weaponUpgr = 552555;
        break;
      case 552555:
        weaponUpgr = 556477;
        break;
      case 556477:
        weaponUpgr = 583563;
        break;
      case 583563:
        weaponUpgr = 595120;
        break;
      case 549864:
        weaponUpgr = 550672;
        break;
      case 550672:
        weaponUpgr = 552554;
        break;
      case 552554:
        weaponUpgr = 556476;
        break;
      case 556476:
        weaponUpgr = 583562;
        break;
      case 583562:
        weaponUpgr = 595117;
        break;
      case 549867:
        weaponUpgr = 550675;
        break;
      case 550675:
        weaponUpgr = 552556;
        break;
      case 552556:
        weaponUpgr = 556478;
        break;
      case 556478:
        weaponUpgr = 583564;
        break;
      case 583564:
        weaponUpgr = 595122;
        break;
      case 549863:
        weaponUpgr = 550671;
        break;
      case 550671:
        weaponUpgr = 552551;
        break;
      case 552551:
        weaponUpgr = 556475;
        break;
      case 556475:
        weaponUpgr = 583561;
        break;
      case 583561:
        weaponUpgr = 595116;
        break;
      case 549870:
        weaponUpgr = 551011;
        break;
      case 551011:
        weaponUpgr = 552563;
        break;
      case 552563:
        weaponUpgr = 556611;
        break;
      case 556611:
        weaponUpgr = 583567;
        break;
      case 583567:
        weaponUpgr = 595126;
        break;
      case 549869:
        weaponUpgr = 551010;
        break;
      case 551010:
        weaponUpgr = 552562;
        break;
      case 552562:
        weaponUpgr = 556609;
        break;
      case 556609:
        weaponUpgr = 583566;
        break;
      case 583566:
        weaponUpgr = 595125;
        break;
      case 549871:
        weaponUpgr = 551013;
        break;
      case 551013:
        weaponUpgr = 552564;
        break;
      case 552564:
        weaponUpgr = 556612;
        break;
      case 556612:
        weaponUpgr = 583568;
        break;
      case 583568:
        weaponUpgr = 595127;
        break;
      case 549868:
        weaponUpgr = 551008;
        break;
      case 551008:
        weaponUpgr = 552561;
        break;
      case 552561:
        weaponUpgr = 556608;
        break;
      case 556608:
        weaponUpgr = 583565;
        break;
      case 583565:
        weaponUpgr = 595124;
        break;
      case 549877:
        weaponUpgr = 551028;
        break;
      case 551028:
        weaponUpgr = 552567;
        break;
      case 552567:
        weaponUpgr = 556623;
        break;
      case 556623:
        weaponUpgr = 583607;
        break;
      case 583607:
        weaponUpgr = 595176;
        break;
      case 549876:
        weaponUpgr = 551027;
        break;
      case 551027:
        weaponUpgr = 552566;
        break;
      case 552566:
        weaponUpgr = 556622;
        break;
      case 556622:
        weaponUpgr = 583603;
        break;
      case 583603:
        weaponUpgr = 595175;
        break;
      case 549878:
        weaponUpgr = 551029;
        break;
      case 551029:
        weaponUpgr = 552568;
        break;
      case 552568:
        weaponUpgr = 556624;
        break;
      case 556624:
        weaponUpgr = 583608;
        break;
      case 583608:
        weaponUpgr = 595177;
        break;
      case 549874:
        weaponUpgr = 551026;
        break;
      case 551026:
        weaponUpgr = 552565;
        break;
      case 552565:
        weaponUpgr = 556621;
        break;
      case 556621:
        weaponUpgr = 583600;
        break;
      case 583600:
        weaponUpgr = 595174;
        break;
      case 549882:
        weaponUpgr = 551037;
        break;
      case 551037:
        weaponUpgr = 552572;
        break;
      case 552572:
        weaponUpgr = 556628;
        break;
      case 556628:
        weaponUpgr = 583856;
        break;
      case 583856:
        weaponUpgr = 595181;
        break;
      case 549880:
        weaponUpgr = 551035;
        break;
      case 551035:
        weaponUpgr = 552571;
        break;
      case 552571:
        weaponUpgr = 556627;
        break;
      case 556627:
        weaponUpgr = 583855;
        break;
      case 583855:
        weaponUpgr = 595180;
        break;
      case 549884:
        weaponUpgr = 551039;
        break;
      case 551039:
        weaponUpgr = 552574;
        break;
      case 552574:
        weaponUpgr = 556629;
        break;
      case 556629:
        weaponUpgr = 583857;
        break;
      case 583857:
        weaponUpgr = 595182;
        break;
      case 549879:
        weaponUpgr = 551034;
        break;
      case 551034:
        weaponUpgr = 552570;
        break;
      case 552570:
        weaponUpgr = 556626;
        break;
      case 556626:
        weaponUpgr = 583854;
        break;
      case 583854:
        weaponUpgr = 595179;
        break;
      case 549890:
        weaponUpgr = 551222;
        break;
      case 551222:
        weaponUpgr = 552578;
        break;
      case 552578:
        weaponUpgr = 556632;
        break;
      case 556632:
        weaponUpgr = 583864;
        break;
      case 583864:
        weaponUpgr = 595187;
        break;
      case 549889:
        weaponUpgr = 551221;
        break;
      case 551221:
        weaponUpgr = 552577;
        break;
      case 552577:
        weaponUpgr = 556631;
        break;
      case 556631:
        weaponUpgr = 583863;
        break;
      case 583863:
        weaponUpgr = 595184;
        break;
      case 549892:
        weaponUpgr = 551223;
        break;
      case 551223:
        weaponUpgr = 552580;
        break;
      case 552580:
        weaponUpgr = 556633;
        break;
      case 556633:
        weaponUpgr = 583865;
        break;
      case 583865:
        weaponUpgr = 595188;
        break;
      case 549887:
        weaponUpgr = 551220;
        break;
      case 551220:
        weaponUpgr = 552576;
        break;
      case 552576:
        weaponUpgr = 556630;
        break;
      case 556630:
        weaponUpgr = 583862;
        break;
      case 583862:
        weaponUpgr = 595183;
        break;

      default:
        //console.log('FTF')
        weaponUpgr = 0;
        break;
    }

    //console.log("this.state.allUserWeapon[index][0].data.rarity", this.state.allUserWeapon[index][0].data.rarity);

    var needRes =
      this.state.allUserWeapon[index][0].data.rarity == "Common"
        ? 431182
        : this.state.allUserWeapon[index][0].data.rarity == "Uncommon"
          ? 431183
          : this.state.allUserWeapon[index][0].data.rarity == "Rare"
            ? 431184
            : this.state.allUserWeapon[index][0].data.rarity == "Epic"
              ? 431185
              : 431186;
    //console.log("needRes", needRes);
    var indexRes;
    var needNum;
    for (let i = 0; i < this.state.allUserRes.length; i++) {
      if (this.state.allUserRes[i][0].template.template_id == needRes) {
        indexRes = i;
        needNum = this.state.allUserRes[i].length;
        //console.log("this.state.allUserRes[i]", this.state.allUserRes[i]);
      }
    }

    this.setState({
      ...this.state,
      openRecipe: true,
      activeWeaponName: this.state.allUserWeapon[index][0].data.name,
      activeRecipe: {
        arreyIndex: index,
        weapon: weaponUpgr,
        tempaleID: templateId,
        res: needRes,
        resNum: needNum,
        resIndex: indexRes,
      },
    });

    if (localStorage.getItem("sound") == "true") {
      this.audioMusicNewClick.currentTime = 0;
      this.audioMusicNewClick.volume = 0.4;
      this.audioMusicNewClick.play();
    }
  }

  async backFromRecipe(index, element) {
    this.setState({
      ...this.state,
      openRecipe: false,
      activeWeaponName: "",
      activeRecipe: {
        arreyIndex: "",
        weapon: "",
        tempaleID: "",
        res: "",
        resNum: "",
        resIndex: "",
      },
    });

    if (localStorage.getItem("sound") == "true") {
      this.audioMusicNewClick.currentTime = 0;
      this.audioMusicNewClick.volume = 0.4;
      this.audioMusicNewClick.play();
    }
  }

  async opencraftmodal() {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("open-modal");
    //const number = cryptoRandomString({length: 14, type: 'numeric'});
    const userName = this.props.ual.activeUser.accountName;
    //const activeUser = this.props.ual.activeUser;

    // try {
    //   let rpcc;
    //   if (this.props.ual.activeUser.rpc != undefined) {
    //     rpcc = this.props.ual.activeUser.rpc;
    //   } else if (
    //     this.props.ual.activeUser.rpc == undefined &&
    //     this.props.ual.activeUser.wax
    //   ) {
    //     rpcc = this.props.ual.activeUser.wax.rpc;
    //   } else {
    //     rpcc = new JsonRpc(localStorage.getItem("rpc"));
    //   }
    //   //const userName = this.props.ual.activeUser.accountName;
    //   const results = await rpcc.get_table_rows({
    //     json: true,
    //     code: process.env.REACT_APP_CONTRACT, // contract who owns the table
    //     scope: process.env.REACT_APP_CONTRACT, // scope of the table
    //     table: "oracletable", // name of the table as specified by the contract abi
    //     //"key_type": "i64",
    //     lower_bound: userName,
    //     upper_bound: userName,
    //     //"limit": -1,
    //     //reverse: !0,
    //     show_payer: false,
    //     //index_position: 2,
    //   });
    //   const resultRow = results.rows;
    //   //console.log('resultRow', resultRow[0]);
    //   if (resultRow[0]) {
    //     if (resultRow[0].storer == userName) {
    //       this.setState({
    //         ...this.setState,
    //         modalcraft: {
    //           top: "upgrade",
    //           title: "Now the blacksmith is ready to start work",
    //           button: "second",
    //           descr:
    //             "hand over all parts of the weapon so that he can start working on his craft. It can take a few minutes, please wait...",
    //         },
    //       });
    //     }
    //   }
    // } catch (e) {
    //   // console.log(e.message)
    //   console.warn(e);
    //   this.setState({
    //     hasError: true,
    //     errorText: e.message,
    //   });
    //   console.log("\nCaught exception: " + e);
    //   if (localStorage.getItem("sound") == "true") {
    //     this.audioMusicError.currentTime = 0;
    //     this.audioMusicError.volume = 0.1;
    //     this.audioMusicError.play();
    //   }
    // }

    this.setState({
      ...this.setState,
      craftpay: true,
    });

    if (localStorage.getItem("sound") == "true") {
      this.audioMusicOpenCraft.currentTime = 0;
      this.audioMusicOpenCraft.volume = 0.3;
      this.audioMusicOpenCraft.play();
    }
  }

  async craftpay() {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");
    const number = cryptoRandomString({ length: 14, type: "numeric" });
    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;

    try {
      await activeUser
        .signTransaction(
          {
            actions: [
              {
                account: process.env.REACT_APP_TOKEN,
                name: "transfer",
                authorization: [
                  {
                    actor: userName, // use account that was logged in
                    permission: "active",
                  },
                ],
                data: {
                  from: userName,
                  to: process.env.REACT_APP_CONTRACT,
                  quantity: "30.0000 " + process.env.REACT_APP_VAL,
                  memo: "weaponpay " + this.state.activeRecipe.weapon + " work",
                },
              },
            ],
          },
          { blocksBehind: 3, expireSeconds: 30 }
        )
        .then((response) => {
          if (response.status === "executed") {
            loadingSlot.classList.remove("loading");
            this.setState({
              ...this.setState,
              modalcraft: {
                top: "upgrade",
                title: "Now the blacksmith is ready to start work",
                button: "second",
                descr:
                  "hand over all parts of the weapon so that he can start working on his craft. It can take a few minutes, please wait...",
              },
            });
            this.props.updateBalance(false, parseFloat(20.0).toFixed(4));
          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
        chooseHeroLoading: false,
      });

      if (localStorage.getItem("sound") == "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
      loadingSlot.classList.remove("loading");
    }
  }

  async sendWombat(event) {
    const activeUser = this.props.ual.activeUser;
    const accountName = await activeUser.getAccountName();
    var dateNow = parseInt(Date.now() / 1000);
    var datas = {

      "username": accountName,
      "event": event,
      "eventtime": dateNow,
    }

  }

  async craft() {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");
    const number = cryptoRandomString({ length: 14, type: "numeric" });
    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    var firstWeapon =
      this.state.allUserWeapon[this.state.activeRecipe.arreyIndex][0].asset_id;
    var secondWeapon =
      this.state.allUserWeapon[this.state.activeRecipe.arreyIndex][1].asset_id;
    var resource = [];

    this.state.allUserRes[this.state.activeRecipe.resIndex].map((u, index) => {
      //console.log("INDEX", index);
      if (index < 5) {
        resource.push(u.asset_id);
      }
      //console.log("u[0]", u.template.template_id)
    });

    //console.log("resource", resource);

    // console.log('first weapon', firstWeapon);
    // console.log('secon weapon', secondWeapon);

    // console.log("this.state.activeRecipe.weapon", this.state.activeRecipe.weapon);
    console.log("number", [firstWeapon, secondWeapon, ...resource]);

    try {
      await activeUser
        .signTransaction(
          {
            actions: [
              {
                account: "atomicassets",
                name: "transfer",
                authorization: [
                  {
                    actor: userName, // use account that was logged in
                    permission: "active",
                  },
                ],
                data: {
                  from: userName,
                  to: process.env.REACT_APP_CONTRACT,
                  asset_ids: [firstWeapon, secondWeapon, ...resource],
                  memo:
                    "upgrade " +
                    this.state.activeRecipe.weapon +
                    " " +
                    number +
                    " armor for panda",
                },
              },
            ],
          },
          { blocksBehind: 3, expireSeconds: 30 }
        )
        .then((response) => {
          if (response.status === "executed") {
            loadingSlot.classList.remove("loading");
            this.props.updateBalance(false, parseFloat(30.0).toFixed(4));
            this.setState({
              ...this.setState,
              craftpay: false,
              modalResult: {
                open: true,
                loading: true,
              },
            });
            this.getresult();
            switch (this.state.activeRecipe.weapon) {
              case 582961: case 582960: case 582963: case 582959: case 582966: case 582965: case 582967: case 582964: case 583000:
              case 582999: case 583001: case 582998: case 583004: case 583003: case 583005: case 583002: case 583249: case 583248:
              case 583251: case 583247: case 583254: case 583253: case 583255: case 583252: case 583258: case 583257: case 583259:
              case 583256: case 583262: case 583261: case 583264: case 583260: case 583267: case 583266: case 583269: case 583265:
              case 583558: case 583557: case 583560: case 583556: case 583563: case 583562: case 583564: case 583561: case 583567:
              case 583566: case 583568: case 583565: case 583607: case 583603: case 583608: case 583600: case 583856: case 583855:
              case 583857: case 583854: case 583864: case 583863: case 583865: case 583862:
                this.sendWombat("craft");
                break;
              default:

                break;
            }

          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
        chooseHeroLoading: false,
      });
      if (localStorage.getItem("sound") == "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
      loadingSlot.classList.remove("loading");
    }
  }

  async getresult() {
    (async () => {
      try {
        //console.log('try');
        const userName = this.props.ual.activeUser.accountName;
        const activeUser = this.props.ual.activeUser;
        const weaponsID = this.state.activeRecipe.weapon;
        var perc = 55;
        if (this.state.contractMedal) {
          var perc = 55 + this.state.contractMedal;
        }

        this.interval = setInterval(() => {
          //console.log('setTimeout');
          if (this.state.craftfail <= perc && this.state.craftfail != 0) {
            clearInterval(this.interval);

            console.log("YES IT DONE");
            this.setState({
              ...this.state,
              craftResult: "success",
              modalResult: {
                ...this.state.modalResult,
                loading: false,
              },
            });
            if (localStorage.getItem("sound") == "true") {
              this.audioMusicCraftSuccess.currentTime = 0;
              this.audioMusicCraftSuccess.volume = 0.4;
              this.audioMusicCraftSuccess.play();
            }
          } else if (this.state.craftfail > perc) {
            console.log("NOOOOOOOOOOOOOOOOO");

            clearInterval(this.interval);
            this.setState({
              ...this.state,
              craftResult: "fail",
              modalResult: {
                ...this.state.modalResult,
                loading: false,
              },
            });

            if (localStorage.getItem("sound") == "true") {
              this.audioMusicCraftfail.currentTime = 0;
              this.audioMusicCraftfail.volume = 0.3;
              this.audioMusicCraftfail.play();
            }
          }

          // console.log("timer");
          // console.log("this.state.allThisWeapon", this.state.allThisWeapon);
          // console.log("assets", assets);
          // console.log("this.state.craftfail", this.state.craftfail);

          //this.GetPandaWeaponList(this.state.activeRecipe);
          this.getUserNoRes();
        }, 10000);
      } catch (e) {
        //console.log(e.message)
        console.warn(e);
        this.setState({
          ...this.state,
          hasError: true,
          errorText: e.message,
          modalEat: {
            ...this.state.modalEat,
            loadingChooseEat: false,
          },
        });
        console.log("\nCaught exception: " + e);
        if (localStorage.getItem("sound") == "true") {
          this.audioMusicError.currentTime = 0;
          this.audioMusicError.volume = 0.1;
          this.audioMusicError.play();
        }
      }
    })();
  }

  async getUserNoRes() {
    const number = cryptoRandomString({ length: 14, type: "numeric" });
    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;

    try {
      let rpcc;
      if (this.props.ual.activeUser.rpc != undefined) {
        rpcc = this.props.ual.activeUser.rpc;
      } else if (
        this.props.ual.activeUser.rpc == undefined &&
        this.props.ual.activeUser.wax
      ) {
        rpcc = this.props.ual.activeUser.wax.rpc;
      } else {
        rpcc = new JsonRpc(localStorage.getItem("rpc"));
      }
      //const userName = this.props.ual.activeUser.accountName;
      const results = await rpcc.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "usersnew", // name of the table as specified by the contract abi
        //"key_type": "i64",
        lower_bound: userName,
        upper_bound: userName,
        //"limit": -1,
        //reverse: !0,
        show_payer: false,
        //index_position: 2,
      });
      const resultRow = results.rows;
      //console.log('resultRow getUserNoRes', resultRow[0]);
      if (resultRow[0]) {
        this.setState({
          ...this.setState,
          craftfail: resultRow[0].random,
        });
      }
    } catch (e) {
      // console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
      if (localStorage.getItem("sound") == "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  async closeModalResult() {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.remove("open-modal");
    this.setState({
      ...this.state,
      allparts: {}, // все части этого оружия который хотят скрафтить
      allThisWeapon: {}, // все оружия
      modalResult: {
        open: false, // показать модалку результата
        loading: true, // ожидание результата
      },
      craftpay: false, //
      modalcraft: {
        top: "Upgrade armor",
        title: "The blacksmith is ready to start work",
        button: "first",
        descr:
          `The blacksmith always takes money <span>(30 ${process.env.REACT_APP_VAL})<i></i></span> for the front, since nothing motivates like a salary. `,
      },
      craftfail: "",
      craftResult: "",
      openRecipe: false,
    });

    setTimeout(() => {
      this.getAllNft();
    }, 300);
  }

  async closeModalSteps() {
    this.setState({
      ...this.setState,
      craftpay: false,
    });
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.remove("open-modal");
    //console.log('closeModalSteps');
    //this.GetUserWeaponList();
  }

  navpage(goto) {
    if (goto == "next") {
      this.setState({
        activePage: this.state.activePage + 1,
      });
    } else {
      this.setState({
        activePage: this.state.activePage - 1,
      });
    }
  }

  render() {
    return (
      <>
        <Menu  {...this.props.ual} wax={this.props.balanceWax} bam={this.props.balanceBam} game={this.props.balanceGame} updateBalance={this.props.updateBalance} />
        {this.state.hasError ? (
          <ErrorComponent
            errorMessage={this.state.errorText}
            clearError={this.clearError}
          />
        ) : null}
        <main className="craft-page">
          <div className="loading-block"></div>

          <div className="craft-wrap upgrade-page-bg upgr-weapon">
            {this.state.openRecipe ? null : (
              <div className="craft-body-choose">
                <div className="choose-weapon-upgade">
                  <div
                    className={`choose-weapon-upgade-in ${this.state.loadingNft ? "loading" : "ok"
                      }`}
                  >
                    <Link className="bt-back" to="/craft">
                      <span></span>
                    </Link>

                    <div
                      className="bt-reload"
                      onClick={(e) => {
                        this.getAllNft();
                      }}
                    >
                      <span></span>
                    </div>

                    <UpgradeHomeLoop
                      {...this.props}
                      {...this.state}
                      openRecipe={this.openRecipe}
                    />

                    {this.state.loadingNft &&
                      this.state.allUserWeapon &&
                      !this.state.allUserWeapon.length && (
                        <div className="loading-block"></div>
                      )}
                  </div>

                  <div className="upgr-nav">
                    <div className="upgr-nav-in">
                      {this.state.countPage > 1 && this.state.activePage > 1 ? (
                        <button
                          className="nav-link nav-prev"
                          onClick={() => this.navpage("prev")}
                        >
                          <span></span>
                        </button>
                      ) : null}

                      <div className="nav-num">{this.state.activePage}</div>

                      {this.state.countPage > 1 &&
                        this.state.countPage >= this.state.activePage &&
                        this.state.countPage != this.state.activePage ? (
                        <button
                          className="nav-link nav-next"
                          onClick={() => this.navpage("next")}
                        >
                          <span></span>
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {this.state.openRecipe ? (
              <div className="upgr-recipe">
                <div className="upgr-recipe-in">
                  <div className="upgr-recipe-top">
                    <div className="bt-back">
                      <span
                        onClick={(e) => {
                          this.backFromRecipe();
                        }}
                      ></span>
                    </div>
                  </div>
                  <div className="upgr-recipe-wrap">
                    <div
                      className={`runa ${this.state.allUserWeapon[
                        this.state.activeRecipe.arreyIndex
                      ][0] &&
                        this.state.allUserWeapon[
                        this.state.activeRecipe.arreyIndex
                        ][1] &&
                        this.state.activeRecipe.resNum >= 5
                        ? "active"
                        : null
                        }`}
                    ></div>

                    <div className="upgr-recipe-wrap-in">
                      {/* one */}
                      <div
                        className={`one-card-upgr ${this.state.allUserWeapon[
                          this.state.activeRecipe.arreyIndex
                        ][0]
                          ? "active"
                          : "disable"
                          }`}
                      >
                        <div className="one-card-upgr-in">
                          <div className="one-card-upgr-thumb">
                            <picture>
                              {/* <source srcSet={`./images/armor/all/${this.state.activeRecipe.tempaleID}.webp`} type="image/webp" />
                                                        <source srcSet={`./images/armor/all/${this.state.activeRecipe.tempaleID}.png`} type="image/png" />  */}
                              <img
                                src={`./images/jew/all/${this.state.activeRecipe.res}.png`}
                                alt=""
                              />
                            </picture>
                          </div>

                          {!this.state.allUserWeapon[
                            this.state.activeRecipe.arreyIndex
                          ][0] ? (
                            <div className="btns-bay-craft">
                              <div className="one-bt-bc">
                                <a
                                  className="def-bt mid"
                                  href="https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&schema_name=weapon&sort=created&symbol=WAX"
                                >
                                  <span className="button-in">
                                    <span className="button-name">
                                      <span className="button-name-in">
                                        <span className="button-name-value">
                                          BUY
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </a>
                              </div>
                            </div>
                          ) : null}

                          <div className="part-num">
                            {this.state.activeRecipe.resNum}
                          </div>

                          <div className="need-parts">
                            {this.state.activeRecipe.resNum <= 5
                              ? this.state.activeRecipe.resNum
                              : "5"}
                            /5
                          </div>
                        </div>

                        <div
                          className={`dots-parts ${this.state.activeRecipe.resNum >= 5
                            ? "act-5"
                            : this.state.activeRecipe.resNum == 4
                              ? "act-4"
                              : this.state.activeRecipe.resNum == 3
                                ? "act-3"
                                : this.state.activeRecipe.resNum == 2
                                  ? "act-2"
                                  : this.state.activeRecipe.resNum == 1
                                    ? "act-1"
                                    : "act-0"
                            }`}
                        >
                          <div className="dots-parts-in">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                          </div>
                        </div>
                      </div>
                      {/* / one */}
                      {/* one */}
                      <div
                        className={`one-card-upgr ${this.state.allUserWeapon[
                          this.state.activeRecipe.arreyIndex
                        ][0]
                          ? "active"
                          : "disable"
                          }`}
                      >
                        <div className="one-card-upgr-in">
                          <div className="one-card-upgr-thumb">
                            <picture>
                              {/* <source srcSet={`./images/armor/all/${this.state.activeRecipe.tempaleID}.webp`} type="image/webp" />
                                                        <source srcSet={`./images/armor/all/${this.state.activeRecipe.tempaleID}.png`} type="image/png" />  */}
                              <img
                                src={`./images/armor/all/${this.state.activeRecipe.tempaleID}.png`}
                                alt=""
                              />
                            </picture>
                          </div>

                          {!this.state.allUserWeapon[
                            this.state.activeRecipe.arreyIndex
                          ][0] ? (
                            <div className="btns-bay-craft">
                              <div className="one-bt-bc">
                                <NavLink
                                  className="def-bt mid"
                                  to="/craft-weapon-page"
                                >
                                  <span className="button-in">
                                    <span className="button-name">
                                      <span className="button-name-in">
                                        <span className="button-name-value">
                                          craft
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </NavLink>
                              </div>
                              <div className="one-bt-bc">
                                <a
                                  className="def-bt mid"
                                  target="_blank"
                                  href={`https://wax.atomichub.io/market?collection_name=nftpandawaxp&data:text.rarity=Rare&match=${this.state.activeWeaponName.replace(
                                    / /g,
                                    "%20"
                                  )}&order=desc&schema_name=weapon&sort=created&symbol=WAX`}
                                >
                                  <span className="button-in">
                                    <span className="button-name">
                                      <span className="button-name-in">
                                        <span className="button-name-value">
                                          BUY
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </a>
                              </div>
                            </div>
                          ) : null}

                          <div className="part-num">
                            {
                              this.state.allUserWeapon[
                                this.state.activeRecipe.arreyIndex
                              ].length
                            }
                          </div>

                          <div className="need-parts">
                            {this.state.allUserWeapon[
                              this.state.activeRecipe.arreyIndex
                            ].length <= 2
                              ? this.state.allUserWeapon[
                                this.state.activeRecipe.arreyIndex
                              ].length
                              : "2"}
                            /2
                          </div>
                        </div>

                        <div
                          className={`dots-parts ${this.state.allUserWeapon[
                            this.state.activeRecipe.arreyIndex
                          ].length >= 2
                            ? "act-2"
                            : this.state.allUserWeapon[
                              this.state.activeRecipe.arreyIndex
                            ].length == 1
                              ? "act-1"
                              : "act-0"
                            }`}
                        >
                          <div className="dots-parts-in">
                            <span></span>
                            <span></span>
                          </div>
                        </div>
                      </div>
                      {/* / one */}
                    </div>
                  </div>

                  <div className="upgr-bt-do-upg">
                    <div className="upgr-bt">
                      {this.state.allUserWeapon[
                        this.state.activeRecipe.arreyIndex
                      ][0] &&
                        this.state.allUserWeapon[
                        this.state.activeRecipe.arreyIndex
                        ][1] &&
                        this.state.activeRecipe.resNum >= 5 ? (
                        <span
                          onClick={(e) => {
                            this.opencraftmodal();
                          }}
                        ></span>
                      ) : (
                        <span className="disabled"></span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <div
              className={`modal-result ${this.state.modalResult.open ? "open" : null
                } ${this.state.craftResult == "success" ? "success" : null} ${this.state.craftResult == "fail" ? "fail" : null
                }`}
            >
              <div className="modal-result-body">
                <div className="modal-result-body-in">
                  <div className="modal-result-head">
                    {!this.state.modalResult.loading ? (
                      <div
                        className="close-result"
                        onClick={(e) => {
                          this.closeModalResult();
                        }}
                      >
                        <span></span>
                      </div>
                    ) : null}
                    <div className="weapon-modal-name">
                      {this.state.activeWeaponName}
                    </div>
                    <div className="weapon-modal-wrap">
                      <div className="fail-res"></div>
                      <div className="success-res"></div>
                      {this.state.modalResult.loading ? (
                        <div className="loading-res"></div>
                      ) : null}
                      <div className="weapon-modal-img">
                        {this.state.activeRecipe.weapon ? (
                          <picture>
                            {/* <source srcSet={`/images/armor/all/${this.state.activeRecipe.weapon}.webp`} type="image/webp" />
                                                    <source srcSet={`/images/armor/all/${this.state.activeRecipe.weapon}.png`} type="image/png" /> */}
                            <img
                              src={`/images/armor/all/${this.state.activeRecipe.weapon}.png`}
                              alt=""
                            />
                          </picture>
                        ) : null}
                      </div>
                      {!this.state.modalResult.loading ? (
                        <div className="close-bt">
                          <button
                            className="def-bt min"
                            onClick={(e) => {
                              this.closeModalResult();
                            }}
                          >
                            <span className="button-in">
                              <span className="button-name">
                                <span className="button-name-in">
                                  <span className="button-name-value">
                                    close
                                  </span>
                                </span>
                              </span>
                            </span>
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="modal-result-close-trans"></div>
              </div>
              <div className="modal-weapon-bg"></div>
            </div>

            <div
              className={`modal-result craftpay ${this.state.craftpay ? "open" : null
                }`}
            >
              <div className="modal-result-body">
                <div className="modal-result-body-in">
                  <div className="modal-result-head">
                    <div
                      className="close-result"
                      onClick={(e) => {
                        this.closeModalResult();
                      }}
                    >
                      <span></span>
                    </div>

                    <div className="weapon-modal-name">
                      {this.state.modalcraft.top}
                    </div>
                    <div className="weapon-modal-wrap">
                      <div className="weapon-modal-img">
                        <div className="step-wrap">
                          <div className="step-title">
                            {this.state.modalcraft.title}
                          </div>
                          <div className="step-loader">
                            <div className="loading-res"></div>
                            <div className="step-bt">
                              {/* {this.state.modalcraft.button != "first" ?  */}
                              <button
                                className="def-bt min"
                                onClick={(e) => {
                                  this.craft();
                                }}
                              >
                                <span className="button-in">
                                  <span className="button-name">
                                    <span className="button-name-in">
                                      <span className="button-name-value ">
                                        upgrade
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </button>
                              {/* :
                                                            <button className="def-bt min" onClick={(e) => {this.craft()}}>
                                                                <span className="button-in">
                                                                    <span className="button-name">
                                                                        <span className="button-name-in">
                                                                            <span className="button-name-value">
                                                                                upgrade
                                                                            </span> 
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </button>
                                                        } */}
                            </div>
                          </div>
                          {this.state.modalcraft.button == "first" ? (
                            <div className="info-55">
                              <span>Attention:</span> the chance of successful{" "}
                              <br /> crafting is{" "}
                              <i>
                                {this.state.contractMedal
                                  ? 55 + this.state.contractMedal
                                  : "55"}
                                %
                              </i>
                            </div>
                          ) : null}
                          <div
                            className="step-descr"
                            dangerouslySetInnerHTML={{
                              __html: this.state.modalcraft.descr,
                            }}
                          >
                            {/* {this.state.modalcraft.descr} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-result-close-trans"></div>
              </div>
              <div className="modal-weapon-bg"></div>
            </div>
          </div>

          <footer>
            <div className="footer-nav">
              <nav>
                <ul>
                  <li>
                    <a
                      className="foot-logo-1"
                      target="_blank"
                      href="https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&sort=created&symbol=WAX"
                    >
                      <span></span>
                      <i>Market</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-2"
                      target="_blank"
                      href="https://twitter.com/NftPanda/"
                    >
                      <span></span>
                      <i>Twitter</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-3"
                      target="_blank"
                      href="https://www.instagram.com/nftpanda/"
                    >
                      <span></span>
                      <i>Instagram</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-4"
                      target="_blank"
                      href="https://discord.gg/vCSgme4d5b"
                    >
                      <span></span>
                      <i>Discord</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-5"
                      target="_blank"
                      href={`https://wax.alcor.exchange/swap?output=${process.env.REACT_APP_VAL}-nftpandabamb&input=WAX-eosio.token`}
                    >
                      <span></span>
                      <i>{process.env.REACT_APP_VAL} Trade</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-6"
                      target="_blank"
                      href="https://discord.gg/vCSgme4d5b"
                    >
                      <span></span>
                      <i>Support</i>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </footer>
        </main>
      </>
    );
  }
}

// let mapStateToProps = (state) => ({
//     allinvent: state.inventoryReduser.allinvent,
// });

let mapStateToProps = (state) => ({
  /*allWeapon: state.weaponReduser.allWeapon,
    statusWeapon: state.weaponReduser.statusWeapon,*/
});

export default connect(mapStateToProps, {
  /*weaponlist,statusLoadinWeapon, addWeaponWhenRemove, addWeaponToPanda,*/
})(UpgradePageComponentArmor);
