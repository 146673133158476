import React from 'react';
import modaltop from '../../images/shop/modal-top.png';
import modalbot from '../../images/shop/modal-bot.png';

const ThankMulti = (props) => {
    

    return (
    <>
       
        <div id="thank-modal-multi" className={`modal-bay open`}>
            <div className="modal-bay-body">
                <div className="modal-bay-body-in">

                    <div className="modal-bay-head">
                        
                        <div className="modal-bay-top">
                            <button className="modal-bay-close" onClick={() => props.closeThenk()}><span></span></button>
                            <img src={modaltop} alt="" />
                        </div>
                        <div className="modal-bay-wrap">
                            
                            <div className="modal-bay-tit">
                                {props.modalMultiThank.title}
                            </div>
                            <div className="modal-bay-part">
                                {props.modalMultiThank.descr}
                            </div>

                            <div className="ico-weap-energy">
                                <button className="def-bt mid" onClick={(e) => {
                                    props.closeThenk();
                                }}>
                                    <span className="button-in">
                                        <span className="button-name">
                                            <span className="button-name-in">
                                                <span className="button-name-value">
                                                    Close
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </button>
                            </div>

                       
                        </div>
                        <div className="modal-bay-bot">
                            <img src={modalbot} alt="" />
                        </div>
                    </div>
                </div>
                <div className="modal-bay-bg-trans" onClick={() => props.closeThenk()}></div>
                
            </div>
            <div className="modal-bay-bg" ></div>
            
        </div>
    </>);
}

export default ThankMulti;