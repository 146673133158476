import React, { useState, useEffect, useRef } from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  Link,
  NavLink,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import LK from "./LK";

import { JsonRpc } from "eosjs";
import { ExplorerApi } from "atomicassets";
import { split } from "lodash";
const api = new ExplorerApi(
  "https://" + localStorage.getItem('rpcA'),
  "atomicassets",
  { fetch, rateLimit: 4 }
);

const LKComponent = (props) => {
  let { id } = useParams();
  //let person = find(parseInt(id));
  //console.log("url", url);
  //console.log("id", id);


  useEffect(() => {
    //console.log("TEST");

    // var loadingSlot = document.getElementsByTagName("body")[0];
    // loadingSlot.classList.add("loading");

    //userCheck(id);




    //return () => clearInterval(updateTimer);
  }, [id]);

  // async function userCheck(id) {
  //   try {
  //     let rpcc;
  //     if (props.ual.activeUser.rpc !== undefined) {
  //       rpcc = props.ual.activeUser.rpc;
  //     } else if (
  //       props.ual.activeUser.rpc === undefined &&
  //       props.ual.activeUser.wax
  //     ) {
  //       rpcc = props.ual.activeUser.wax.rpc;
  //     } else {
  //       rpcc = new JsonRpc(localStorage.getItem("rpc"));
  //     }
  //     const results = await rpcc.get_table_rows({
  //       json: true,
  //       code: process.env.REACT_APP_CONTRACT, // contract who owns the table
  //       scope: process.env.REACT_APP_CONTRACT, // scope of the table
  //       table: "auctiontab", // name of the table as specified by the contract abi
  //       //"table": "usersnew",    // name of the table as specified by the contract abi
  //       limit: 9,
  //       /*upper_bound: id,
  //               lower_bound: id,*/
  //       //"key_type": "i64",
  //       //"index_position": 2,
  //       //"limit": -1,
  //       reverse: !0,
  //       show_payer: false,
  //       //index_position: 1,
  //     });

  //     //console.log("results", results);

  //     const originRowUser = results.rows.find((o) => o.keyauto == id);
  //     setallLots(results.rows.reverse());

  //     //console.log("originRowUser", originRowUser);
  //     var newLastBet = Object.assign({}, originRowUser);
  //     newLastBet.activesumstate =
  //       originRowUser.activesum / 10000 + originRowUser.step / 10000;
  //     setactiveNum(
  //       originRowUser.activesum / 10000 + originRowUser.step / 10000
  //     );

  //     // this.setState({
  //     //     formInfo: newLastBet
  //     // })

  //     setformInfo(newLastBet);

  //     getInfoFromAtomic(newLastBet.tmpl);

  //     setbetNum(newLastBet.activesum / 10000);

  //     var loadingSlot = document.getElementsByTagName("body")[0];
  //     loadingSlot.classList.remove("loading");
  //   } catch (e) {
  //     //console.log(e.message)
  //     console.warn(e);
  //     props.hasError(true, e.message);
  //     // this.setState({
  //     //     hasError: true,
  //     //     errorText: e.message,
  //     // });
  //     var loadingSlot = document.getElementsByTagName("body")[0];
  //     loadingSlot.classList.remove("loading");

  //     console.log("\nCaught exception: " + e);
  //   }
  // }


  return (
    <>
      <LK id={id} {...props} />
    </>
  );
};

export default LKComponent;
