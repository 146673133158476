import React from 'react';

const Error = (props) => {
    
    return (<>
        
        <>
            <div className={`modal-error ${props.showModal ? 'open' : 'close'} `}>
                
                <div className="error-modal-table">
                    <div className="close-error" onClick={(e) => {props.closeEror();}}></div>
                    <div className="error-modal-table-top">
                        <span>
                            ERROR
                        </span>
                    </div>
                    <div className="error-modal-table-mid">
                        <div className="error-modal-table-mid-in">
                            {props.errorMessage}
                        </div>
                    </div>
                    <div className="error-modal-table-bot">
                        
                    </div>
                </div>
                <div className={`bg-wrror-modal ${props.showModalBg ? 'open' : 'close'} `}></div>
            </div> 
        </>

    </>);
}

export default Error;