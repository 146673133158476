const EATLIST = 'EATLIST';
const WAS_LOADING_EAT = 'WAS_LOADING_EAT';
const EAT_REMOVE = 'EAT_REMOVE';
const WAS_BUYING_EAT = 'WAS_BUYING_EAT';
const PARTLIST = 'PARTLIST';

let initialState = {
    alleat: [],
    allpartlist: null,
    wasbay: false,
    statuseat: false
};

const showReduser = (state = initialState, action) => {

    switch(action.type) {
       
        case EATLIST: {
           // console.log('EATLIST', action.eatlist);
            return {...state, alleat: action.eatlist}
        }
       
        case PARTLIST: {
           // console.log('EATLIST', action.eatlist);
            return {...state, allpartlist: action.partlist}
        }
        case EAT_REMOVE: {
            //console.log('EAT_REMOVE: action.data.dateBt', action.data.dateBt);
            //var newCount = action.data.rareat;
            var numbersCopy = state.alleat.map((x) => x);
            if (action.data.arraynew.length) {
                for (let i = 0; i < 6; i++) { // выведет 0, затем 1, затем 2
                    if(action.data.arraynew[i] !== 0) { 
                        numbersCopy[i] = state.alleat[i] - action.data.arraynew[i];
                    }
                }
            } else {
                
                numbersCopy[action.data.rareat] = state.alleat[action.data.rareat] - 1;
            }
            return {
                ...state, alleat: numbersCopy
            }
        }
        case WAS_LOADING_EAT: {
            //console.log('EATLIST', action.eatlist);
            return {...state, statuseat: action.status}
        }
        case WAS_BUYING_EAT: {
            return {...state, wasbay: action.wasbay}
        }

        default:
            return state;
    }
}

export const eatlist = (eatlist) => ({type: EATLIST, eatlist })
export const partlist = (partlist) => ({type: PARTLIST, partlist })
export const wasLoadinEat = (status) => ({type: WAS_LOADING_EAT, status })
export const eatremove = (index, pandaId, rareat, arraynew) => ({type: EAT_REMOVE,  data: { index, pandaId, rareat, arraynew} })
export const wasBuyingEat = (wasbay) => ({type: WAS_BUYING_EAT, wasbay })

export default showReduser;
