import React, { useEffect, useRef } from "react";

import bt from './../../images/BOSS/hist-bt.png'
import btw from './../../images/BOSS/hist-bt.webp'

const History = (props) => {

    const widthScale = props.widthScale;

    return (
        <>
            <div className="hist-attac" style={
                {
                    width: widthScale * 336,
                    top: widthScale * 67,
                    left: widthScale * 190,
                }
            }>
                <div className="hist-attac-bt" style={
                    {
                        width: widthScale * 104,
                        height: widthScale * 104
                    }
                }>
                    <picture>
                        <source srcSet={btw} type="image/webp" />
                        <source srcSet={bt} type="image/png" />
                        <img src={btw} alt="" />
                    </picture>
                    <span>
                        <picture>
                            <source srcSet={btw} type="image/webp" />
                            <source srcSet={bt} type="image/png" />
                            <img src={btw} alt="" />
                        </picture>
                    </span>
                </div>
                <div className="hist-attac-descr" style={
                    {
                        top: widthScale * 40,
                    }
                }>
                    <div className="hist-attac-descr-in">
                        <div className="hist-attac-tit" style={
                            {
                                fontSize: widthScale * 27,
                                marginBottom: widthScale * 20
                            }
                        }>
                            battle history
                        </div>
                        <div className="hist-attac-list" style={
                            {
                                fontSize: widthScale * 18,
                            }
                        }>
                            <ul>
                                <li>
                                    <span>
                                        nifuriel:
                                    </span>
                                    <i className="red">
                                        2300 crit dmg
                                    </i>
                                </li>
                                <li>
                                    <span>
                                        nifuriel:
                                    </span>
                                    <i className="orange">
                                        2300 crit dmg
                                    </i>
                                </li>
                                <li>
                                    <span>
                                        nifuriel:
                                    </span>
                                    <i className="green">
                                        2300 crit dmg
                                    </i>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


export default History;