import React, { useState, useEffect } from 'react';


import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import Countdown from 'react-countdown';
import modaltop from '../../../images/new-adv/modal-top.svg';
import modalbot from '../../../images/new-adv/modal-bot.svg';
import back from '../../../images/new-adv/modal-back.svg';
import titleOne from '../../../images/new-adv/SVG/modal-tit-2.svg';
import titleTwo from '../../../images/new-adv/SVG/modal-tit-1.svg';
import titleThree from '../../../images/new-adv/SVG/modal-tit-3.svg';
import titleFour from '../../../images/new-adv/SVG/modal-tit-4.png';

import multtop from '../../../images/new-adv/multi-pandas-top.png';
import multtopw from '../../../images/new-adv/multi-pandas-top.webp';
import multbot from '../../../images/new-adv/multi-pandas-bot.png';
import multbotw from '../../../images/new-adv/multi-pandas-bot.webp';

import multtopmob from '../../../images/new-adv/multi-pandas-top-mob.png';
import multtopmobw from '../../../images/new-adv/multi-pandas-top-mob.webp';
import multbotmob from '../../../images/new-adv/multi-pandas-bot-mob.png';
import multbotmobw from '../../../images/new-adv/multi-pandas-bot-mob.webp';

import MultPanda from '../NewAdv/MultPanda';

const SendToAdv = (props) => {

    const [pandaInfo, setPandaInfo] = useState({});
    const [pandaRank, setPandaRank] = useState("test rank");
    const [pandaRankLvl, setPandaRankLvl] = useState(0);
    const [activeMob, setActiveMob] = useState("");

    const [minSlide, setMinSlide] = useState(6);
    const [maxSlide, setMaxSlide] = useState(12);
    const [defSlide, setDefSlide] = useState(6);
    const [stepSlide, setStepSlide] = useState(6);
    const [value, setValueSlide] = useState(6);
    const [rewardDisc, setRewardDisc] = useState(-5);

    useEffect(() => {
        if (props.sendToAdv.type !== 'bamboo') {
            setMinSlide(6);
            setMaxSlide(12);
            setDefSlide(6);
            setStepSlide(6);
            setValueSlide(6);
        } else {
            setMinSlide(4);
            setMaxSlide(12);
            setDefSlide(4);
            setStepSlide(4);
            setValueSlide(4);
        }
    }, [])

    var resWood = 0,
        resSteel = 0,
        resTitawin = 0,
        resKvessir = 0,
        resGold = 0,
        resDwemer = 0,

        stonePerc = 0;

    var pandaPercRes = 10,
        weaponPercRes = 10,
        pandaPercStone = 24,
        weaponPercStone = 24;
    var energy = 500;

    /* res % 

    common promo - 10%
    uncommon  - 8%
    rare  - 6%
    epic  - 4%
    legendary  - 2%
    mythic  - 0%

    end res % */

    if (props.sendToAdv.pandaInfo.weapon) {
        // for panda
        switch (props.sendToAdv.pandaInfo.rarity) {
            case 0: case 1:
                pandaPercRes = 10;
                pandaPercStone = 24;
                break;
            case 2:
                pandaPercRes = 8;
                pandaPercStone = 18;
                break;
            case 3:
                pandaPercRes = 6;
                pandaPercStone = 12;
                break;
            case 4:
                pandaPercRes = 4;
                pandaPercStone = 8;
                break;
            case 5:
                pandaPercRes = 2;
                pandaPercStone = 4;
                break;
            case 6:
                pandaPercRes = 0;
                pandaPercStone = 0;
                break;
            default:
                pandaPercRes = 10;
                pandaPercStone = 24;
                break;
        }
        // end for panda

        // for weapon
        switch (props.sendToAdv.pandaInfo.weapon.rarity) {
            case 0:
                weaponPercRes = 10;
                weaponPercStone = 24;
                break;
            case 1:
                weaponPercRes = 8;
                weaponPercStone = 18;
                break;
            case 2:
                weaponPercRes = 6;
                weaponPercStone = 12;
                break;
            case 3:
                weaponPercRes = 4;
                weaponPercStone = 8;
                break;
            case 4:
                weaponPercRes = 2;
                weaponPercStone = 4;
                break;
            case 5:
                weaponPercRes = 0;
                weaponPercStone = 0;
                break;
            default:
                weaponPercRes = 10;
                weaponPercStone = 24;
                break;
        }
        //end for weapon

        // for all
        /*console.log('weaponPercRes', weaponPercRes);
        console.log('pandaPercRes', pandaPercRes);
        console.log('props.getReward.exp', props.getReward.exp);*/

        switch (weaponPercRes + pandaPercRes) {
            case 20:
                resWood = 500;
                resSteel = 235;
                resTitawin = 40;
                resKvessir = 15;
                resGold = 8;
                resDwemer = 2;
                break;
            case 18:
                resWood = 490;
                resSteel = 235;
                resTitawin = 50;
                resKvessir = 25;
                resGold = 14;
                resDwemer = 6;
                break;
            case 16:
                resWood = 480;
                resSteel = 230;
                resTitawin = 65;
                resKvessir = 35;
                resGold = 20;
                resDwemer = 10;
                break;
            case 14:
                resWood = 470;
                resSteel = 230;
                resTitawin = 75;
                resKvessir = 45;
                resGold = 26;
                resDwemer = 14;
                break;
            case 12:
                resWood = 460;
                resSteel = 226;
                resTitawin = 87;
                resKvessir = 56;
                resGold = 32;
                resDwemer = 19;
                break;
            case 10:
                resWood = 450;
                resSteel = 226;
                resTitawin = 94;
                resKvessir = 65;
                resGold = 40;
                resDwemer = 25;
                break;
            case 8:
                resWood = 440;
                resSteel = 226;
                resTitawin = 103;
                resKvessir = 75;
                resGold = 46;
                resDwemer = 30;
                break;
            case 6:
                resWood = 430;
                resSteel = 220;
                resTitawin = 115;
                resKvessir = 85;
                resGold = 55;
                resDwemer = 35;
                break;
            case 4:
                resWood = 422;
                resSteel = 215;
                resTitawin = 125;
                resKvessir = 95;
                resGold = 63;
                resDwemer = 40;
                break;
            case 2:
                resWood = 415;
                resSteel = 215;
                resTitawin = 135;
                resKvessir = 100;
                resGold = 70;
                resDwemer = 45;
                break;
            case 0:
                resWood = 400;
                resSteel = 210;
                resTitawin = 150;
                resKvessir = 110;
                resGold = 80;
                resDwemer = 50;
                break;
            default:
                break;
        }


        switch (weaponPercStone + pandaPercStone) {
            case 48:
                stonePerc = 26;
                break;
            case 42:
                stonePerc = 29;
                break;
            case 36:
                stonePerc = 32;
                break;
            case 32:
                stonePerc = 34;
                break;
            case 30:
                stonePerc = 35;
                break;
            case 28:
                stonePerc = 36;
                break;
            case 26:
                stonePerc = 37;
                break;
            case 24:
                stonePerc = 38;
                break;
            case 22:
                stonePerc = 39;
                break;
            case 20:
                stonePerc = 40;
                break;
            case 18:
                stonePerc = 41;
                break;
            case 16:
                stonePerc = 42;
                break;
            case 12:
                stonePerc = 44;
                break;
            case 8:
                stonePerc = 46;
                break;
            case 4:
                stonePerc = 48;
                break;
            case 0:
                stonePerc = 50;
                break;
            default:
                break;
        }

        stonePerc = stonePerc * 4;



        //end for all
    }

    function getPandaInfo(panda, pandaRealRank, lvlloop) {
        if (pandaInfo.asset_id != undefined && pandaInfo.asset_id == panda.asset_id) {
            closePandaInfo();
        } else {
            setPandaInfo(panda);
            setPandaRank(pandaRealRank);
            setPandaRankLvl(lvlloop);
        }
    }

    function activeMobFunc(panda) {
        console.log("click", panda);
        if (activeMob.asset_id != panda.asset_id) {
            setActiveMob(panda.asset_id);
        } else {
            setActiveMob(0);
        }
    }

    function closePandaInfo() {
        setPandaInfo({});
        setPandaRank("");
        setPandaRankLvl("");
    }

    var multiError = [];
    var manyPand = 0;
    if (props.sendToAdv.multi && props.pandaSlots && props.pandaSlots.length) {
        props.pandaSlots.map((item, index) => {
            if (item !== 0 && item.activePanda) {
                manyPand += 1;
                var onePanda = item;
                var oneWeapon;
                var oneJew;

                if (onePanda.weapon) {
                    oneWeapon = onePanda.weapon;
                }

                if (onePanda.jew) {
                    oneJew = onePanda.jew;
                }

                var pandaRar = onePanda.rarity;  // (NFT) rare
                var pandaLvl = onePanda.lvl; // (РАНГ) 8.67 далдно быть застейкано
                var pandaSt = onePanda.stake_count; // берес количество застейканого бамам на панде

                var pandaStake = {
                    "0": [0, 8700, 22900, 45800, 82900, 142900, 240000, 397100, 651300, 1062600, 1728100, 2804900, 4547100],
                    "1": [0, 14400, 37800, 75600, 136800, 235800, 395800, 655000, 1074400, 1753000, 2851000, 4627600, 7502200],
                    "2": [0, 17700, 46600, 93300, 168900, 291100, 488900, 808900, 1326700, 2164500, 3520100, 5713500, 9262400],
                    "3": [0, 48000, 126000, 252000, 456000, 786000, 1320000, 2184000, 3582000, 5844000, 9504000, 15426000, 25008000],
                    "4": [0, 80000, 210000, 420000, 760000, 1310000, 2200000, 3640000, 5970000, 9740000, 15840000, 25710000, 41680000],
                    "5": [0, 320000, 840000, 1680000, 3040000, 5240000, 8800000, 14560000, 23880000, 38960000, 63360000, 102840000, 166720000],
                    "6": [0, 960000, 2520000, 5040000, 9120000, 15720000, 26400000, 43680000, 71640000, 116880000, 190080000, 308520000, 500160000]
                }

                var howmcount = pandaStake[pandaRar][pandaLvl];
                var lvlLoop = pandaLvl; // левел для цыкла

                if (value == 8 && props.sendToAdv.type == 'bamboo' || value == 12 && props.sendToAdv.type != 'bamboo') {
                    energy = 2000;
                } else if (value == 12 && props.sendToAdv.type == 'bamboo') {
                    energy = 4000;
                }

                var weapon = onePanda.weapon;
                var jew = onePanda.jew;
                var armor = onePanda.armor;

                if (weapon != 0 && jew != 0 && armor != 0) {
                    if (pandaRar == 1) {
                        pandaRar = pandaRar - 1;
                    }
                    if (weapon.rarity >= pandaRar && jew.rarity >= pandaRar && armor.rarity >= pandaRar) {
                        energy = 400;
                        if (value == 8 && props.sendToAdv.type == "bamboo" ||
                            value == 12 && props.sendToAdv.type != "bamboo") {
                            energy = 1800;
                        } else if (value == 12 && props.sendToAdv.type == "bamboo") {
                            energy = 3600;
                        }
                    }
                }

                for (var i = pandaLvl; i >= 0; i--) {

                    if (pandaSt < howmcount) {
                        lvlLoop = i;
                    }
                }

                //var hasError = false;
                if (props.sendToAdv.type !== 'bamboo') {
                    if (!oneWeapon || lvlLoop < 2 || onePanda.energy < energy || oneWeapon.energy < energy) {
                        multiError.push(true);
                    }
                }
            }
        });
    }
    var hasMultiError = multiError.includes(true);
    /*console.log("multiError", multiError);
    console.log("hasMultiError", hasMultiError);*/

    function onSliderChange(value) {
        setValueSlide(value);
        if (props.sendToAdv.type !== 'bamboo') {
            if (value == 12) {
                setRewardDisc(-20);
            } else {
                setRewardDisc(-5);
            }
        } else {
            if (value == 8) {
                setRewardDisc(-20);
            } else if (value == 12) {
                setRewardDisc(-40);
            } else {
                setRewardDisc(-5);
            }
        }
        props.removeActiveAllBest();
    };

    return (
        <>

            <div className={`modal-to-adv open anim ${props.sendToAdv.modalOpen ? " anim" : null} ${props.sendToAdv.type === 'bamboo' ? 'bamb' : props.sendToAdv.type === 'stones' ? 'terr' : 'ress'}`}>
                <div className={`modal-to-adv-body ${props.sendToAdv.multi && "multi"}`}>

                    {/* <div className={`bg-close-multpanda ${pandaInfo.asset_id != undefined && "show"}`}></div> */}

                    <div className="modal-to-adv-body-in">

                        <div className="modal-to-adv-head">
                            <div className="modal-to-adv-top">
                                <div className="modal-to-adv-close" onClick={() => props.showCloseToAdv('close')}>
                                    <svg width="86" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76.11 48.28"><defs></defs><g id="Слой_2" data-name="Слой 2"><g id="Приключение_копия_" data-name="Приключение (копия)"><g id="Модалка_копия_" data-name="Модалка (копия)"><g id="Слой_5_копия_" data-name="Слой 5 (копия)"><g id="Слой_2_копия_5" data-name="Слой 2 копия 5"><path className="cls-back-1" d="M71.34,4.09H4.76C2.13,4.09,0,5.61,0,7.49v33.3c0,1.88,2.13,3.4,4.76,3.4H71.34c2.63,0,4.77-1.52,4.77-3.4V7.49C76.11,5.61,74,4.09,71.34,4.09Z" /></g><g id="Слой_2_копия_5-2" data-name="Слой 2 копия 5"><rect className="cls-back-2" x="5.38" width="65.35" height="48.28" rx="5" /><rect className="cls-back-3" x="11.57" y="2.93" width="52.01" height="39.02" /><g id="_1btMbR.tif" data-name="1btMbR.tif"><path className="cls-back-4" d="M17.19,22.23l4-2.53c2.09-1.33,4.18-2.64,6.26-4q2.32-1.49,4.63-3c1.14-.75,2.26-1.53,3.4-2.29.26-.18.55-.32.94-.55v12.4c2.64-1.67,5.18-3.27,7.71-4.89,1.34-.86,2.65-1.76,4-2.62,2-1.31,4.08-2.6,6.12-3.9.45-.29.87-.64,1.41-1v25l-2.23-1.49L53,33.05c-2.28-1.44-4.57-2.87-6.84-4.32-1.64-1-3.27-2.1-4.89-3.16-1.38-.9-2.74-1.82-4.11-2.72-.21-.13-.43-.22-.73-.38V34.78c-.81-.49-1.51-.89-2.2-1.32-.25-.15-.45-.37-.7-.53-2.21-1.41-4.43-2.8-6.64-4.2Q24.47,27.16,22,25.56c-1.4-.91-2.79-1.84-4.18-2.75a4,4,0,0,0-.67-.28Z" /></g></g></g></g></g></g></svg>
                                </div>
                                <img src={`${modaltop}`} alt="" />

                            </div>

                            <div className="modal-to-adv-mid has-tree">
                                <div className="modal-to-adv-title">
                                    {props.sendToAdv.type === 'bamboo' ?
                                        <img width={268} src={`${titleOne}`} alt="" />
                                        :
                                        props.sendToAdv.type === 'resources' ?
                                            <img width={230} src={`${titleTwo}`} alt="" />
                                            :
                                            props.sendToAdv.type === 'books' ?
                                                <img width={230} src={`${titleFour}`} alt="" />
                                                :
                                                <img width={340} src={`${titleThree}`} alt="" />
                                    }
                                </div>

                                {/* props.sendToAdv.type !== 'bamboo' && !props.sendToAdv.multi ? 
                                !props.sendToAdv.pandaInfo.weapon || 
                                props.sendToAdv.pandaInfo.lvl < 2 || 
                                props.sendToAdv.pandaInfo.energy < energy || 
                                props.getReward.exp || 
                                props.sendToAdv.pandaInfo.weapon.energy < energy ?
                                <div className="req">
                                    <div className="req-top">
                                        <span>
                                            REQUIRED
                                        </span>
                                    </div>
                                    <div className="req-text">
                                        {props.sendToAdv.pandaInfo.lvl < 2 || props.getReward.exp ?
                                        <div>Rank SECOND-LIEUTENANT</div>
                                        : null}

                                        {!props.sendToAdv.pandaInfo.weapon ?  
                                            <div>Weapon common</div>
                                        : props.sendToAdv.pandaInfo.weapon.energy < energy ?  
                                            <div>WEAPON IS BROKEN</div>
                                        : null}


                                        {props.sendToAdv.pandaInfo.energy < energy ?  
                                            <div>THE HERO IS TOO WEAK</div>
                                        : null}
                                    </div>                            
                                </div>
                                : 
                                null
                                : */props.sendToAdv.multi && hasMultiError || props.sendToAdv.multi && !manyPand ?
                                        <div className="req">
                                            <div className="req-top">
                                                <span>
                                                    REQUIRED
                                                </span>
                                            </div>
                                            <div className="req-text">
                                                {!manyPand ?
                                                    <div>You must choose one or more heroes.</div>
                                                    :
                                                    <>
                                                        <div>some of your heroes can't go on this adventure.</div>
                                                    </>
                                                }

                                            </div>
                                        </div>
                                        : null
                                }

                                <div className="drop-list">
                                    <div className="drop-list-tit">
                                        your chance to drop
                                        <span>
                                            rewards
                                        </span>
                                    </div>
                                    <div className="drop-list-wrap">
                                        {props.sendToAdv.type === 'bamboo' ?

                                            <>
                                                <div className="one-drop">
                                                    <div className="one-drop-thumb">
                                                        <span>
                                                            <picture>
                                                                <source srcSet={`./images/box-9-2x.webp`} type="image/webp" />
                                                                <source srcSet={`./images/box-9-2x.png`} type="image/png" />
                                                                <img width={200} src={`./images/box-9-2x.png`} alt="" />

                                                            </picture>
                                                        </span>
                                                    </div>
                                                    <div className="one-drop-name">
                                                        Bamboo
                                                    </div>
                                                </div>
                                            </>

                                            :

                                            props.sendToAdv.type === 'resources' ?

                                                <>
                                                    <div className="one-drop">
                                                        <div className="one-drop-thumb">
                                                            <span>
                                                                <picture>
                                                                    <source srcSet={`./images/new-adv/res/res-common.webp`} type="image/webp" />
                                                                    <source srcSet={`./images/new-adv/res/res-common.png`} type="image/png" />
                                                                    <img src={`./images/new-adv/res/res-common.png`} alt="" />
                                                                </picture>
                                                            </span>
                                                        </div>
                                                        <div className="one-drop-name">
                                                            wood
                                                            {!props.sendToAdv.multi && <span>
                                                                {resWood / 10}%
                                                            </span>}
                                                        </div>
                                                    </div>

                                                    <div className="one-drop">
                                                        <div className="one-drop-thumb">
                                                            <span>
                                                                <picture>
                                                                    <source srcSet={`./images/new-adv/res/res-uncommon.webp`} type="image/webp" />
                                                                    <source srcSet={`./images/new-adv/res/res-uncommon.png`} type="image/png" />
                                                                    <img src={`./images/new-adv/res/res-uncommon.png`} alt="" />
                                                                </picture>
                                                            </span>
                                                        </div>
                                                        <div className="one-drop-name">
                                                            steel
                                                            {!props.sendToAdv.multi && <span>
                                                                {resSteel / 10}%
                                                            </span>}
                                                        </div>
                                                    </div>

                                                    <div className="one-drop">
                                                        <div className="one-drop-thumb">
                                                            <span>
                                                                <picture>
                                                                    <source srcSet={`./images/new-adv/res/res-rare.webp`} type="image/webp" />
                                                                    <source srcSet={`./images/new-adv/res/res-rare.png`} type="image/png" />
                                                                    <img src={`./images/new-adv/res/res-rare.png`} alt="" />
                                                                </picture>
                                                            </span>
                                                        </div>
                                                        <div className="one-drop-name">
                                                            titawin
                                                            {!props.sendToAdv.multi && <span>
                                                                {resTitawin / 10}%
                                                            </span>}
                                                        </div>
                                                    </div>

                                                    <div className="one-drop">
                                                        <div className="one-drop-thumb">
                                                            <span>
                                                                <picture>
                                                                    <source srcSet={`./images/new-adv/res/res-epic.webp`} type="image/webp" />
                                                                    <source srcSet={`./images/new-adv/res/res-epic.png`} type="image/png" />
                                                                    <img src={`./images/new-adv/res/res-epic.png`} alt="" />
                                                                </picture>
                                                            </span>
                                                        </div>
                                                        <div className="one-drop-name">
                                                            Kvessir
                                                            {!props.sendToAdv.multi && <span>
                                                                {resKvessir / 10}%
                                                            </span>}
                                                        </div>
                                                    </div>

                                                    <div className="one-drop">
                                                        <div className="one-drop-thumb">
                                                            <span>
                                                                <picture>
                                                                    <source srcSet={`./images/new-adv/res/res-legendary.webp`} type="image/webp" />
                                                                    <source srcSet={`./images/new-adv/res/res-legendary.png`} type="image/png" />
                                                                    <img src={`./images/new-adv/res/res-legendary.png`} alt="" />
                                                                </picture>
                                                            </span>
                                                        </div>
                                                        <div className="one-drop-name">
                                                            Gold
                                                            {!props.sendToAdv.multi && <span>
                                                                {resGold / 10}%
                                                            </span>}
                                                        </div>
                                                    </div>

                                                    <div className="one-drop">
                                                        <div className="one-drop-thumb">
                                                            <span>
                                                                <picture>
                                                                    <source srcSet={`./images/new-adv/res/res-mythic.webp`} type="image/webp" />
                                                                    <source srcSet={`./images/new-adv/res/res-mythic.png`} type="image/png" />
                                                                    <img src={`./images/new-adv/res/res-mythic.png`} alt="" />
                                                                </picture>
                                                            </span>
                                                        </div>
                                                        <div className="one-drop-name">
                                                            Dwemer
                                                            {!props.sendToAdv.multi && <span>
                                                                {resDwemer / 10}%
                                                            </span>}
                                                        </div>
                                                    </div>

                                                </>

                                                : props.sendToAdv.type === 'books' ?

                                                    <>
                                                        <div className="one-drop stone-one">
                                                            <div className="one-drop-thumb">
                                                                <div className="one-drop-thumb-stone books">
                                                                    <span className='st-1'>
                                                                        <picture>
                                                                            <source srcSet={`./images/skills/needres/archer.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/skills/needres/archer.png`} type="image/png" />
                                                                            <img src={`./images/skills/needres/archer.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                    <span className='st-2'>
                                                                        <picture>
                                                                            <source srcSet={`./images/skills/needres/bard.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/skills/needres/bard.png`} type="image/png" />
                                                                            <img src={`./images/skills/needres/bard.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                    <span className='st-3'>
                                                                        <picture>
                                                                            <source srcSet={`./images/skills/needres/berserker.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/skills/needres/berserker.png`} type="image/png" />
                                                                            <img src={`./images/skills/needres/berserker.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                    <span className='st-4'>
                                                                        <picture>
                                                                            <source srcSet={`./images/skills/needres/engineer.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/skills/needres/engineer.png`} type="image/png" />
                                                                            <img src={`./images/skills/needres/engineer.png`} alt="" />
                                                                        </picture>
                                                                    </span>

                                                                </div>

                                                            </div>
                                                            <div className="one-drop-name">
                                                                Skill books
                                                                {!props.sendToAdv.multi && <span>
                                                                    {stonePerc / 10}%
                                                                </span>}
                                                            </div>
                                                        </div>
                                                        <div className="one-drop stone-one">
                                                            <div className="one-drop-thumb">
                                                                <div className="one-drop-thumb-stone books">
                                                                    <span className='st-1'>
                                                                        <picture>
                                                                            <source srcSet={`./images/skills/needres/dreneya.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/skills/needres/dreneya.png`} type="image/png" />
                                                                            <img src={`./images/skills/needres/dreneya.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                    <span className='st-2'>
                                                                        <picture>
                                                                            <source srcSet={`./images/skills/needres/druid.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/skills/needres/druid.png`} type="image/png" />
                                                                            <img src={`./images/skills/needres/druid.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                    <span className='st-3'>
                                                                        <picture>
                                                                            <source srcSet={`./images/skills/needres/elder.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/skills/needres/elder.png`} type="image/png" />
                                                                            <img src={`./images/skills/needres/elder.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                    <span className='st-4'>
                                                                        <picture>
                                                                            <source srcSet={`./images/skills/needres/healer.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/skills/needres/healer.png`} type="image/png" />
                                                                            <img src={`./images/skills/needres/healer.png`} alt="" />
                                                                        </picture>
                                                                    </span>

                                                                </div>

                                                            </div>
                                                            <div className="one-drop-name">
                                                                Skill scrolls
                                                                {!props.sendToAdv.multi && <span>
                                                                    {stonePerc / 10}%
                                                                </span>}
                                                            </div>
                                                        </div>

                                                    </>

                                                    :

                                                    <>
                                                        <div className="one-drop stone-one">
                                                            <div className="one-drop-thumb">
                                                                <div className="one-drop-thumb-stone">
                                                                    <span className='st-1'>
                                                                        <picture>
                                                                            <source srcSet={`./images/new-adv/res/stone-kyanite-water.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/new-adv/res/stone-kyanite-water.png`} type="image/png" />
                                                                            <img src={`./images/new-adv/res/stone-kyanite-water.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                    <span className='st-2'>
                                                                        <picture>
                                                                            <source srcSet={`./images/new-adv/res/stone-kyanite-fire.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/new-adv/res/stone-kyanite-fire.png`} type="image/png" />
                                                                            <img src={`./images/new-adv/res/stone-kyanite-fire.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                    <span className='st-3'>
                                                                        <picture>
                                                                            <source srcSet={`./images/new-adv/res/stone-kyanite-wind.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/new-adv/res/stone-kyanite-wind.png`} type="image/png" />
                                                                            <img src={`./images/new-adv/res/stone-kyanite-wind.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                    <span className='st-4'>
                                                                        <picture>
                                                                            <source srcSet={`./images/new-adv/res/stone-kyanite-earth.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/new-adv/res/stone-kyanite-earth.png`} type="image/png" />
                                                                            <img src={`./images/new-adv/res/stone-kyanite-earth.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                </div>

                                                            </div>
                                                            <div className="one-drop-name">
                                                                kyanite
                                                                {!props.sendToAdv.multi && <span>
                                                                    {stonePerc / 10}%
                                                                </span>}
                                                            </div>
                                                        </div>

                                                        <div className="one-drop stone-one">
                                                            <div className="one-drop-thumb">
                                                                <div className="one-drop-thumb-stone">
                                                                    <span className='st-2'>
                                                                        <picture>
                                                                            <source srcSet={`./images/new-adv/res/stone-pliacrin-water.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/new-adv/res/stone-pliacrin-water.png`} type="image/png" />
                                                                            <img src={`./images/new-adv/res/stone-pliacrin-water.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                    <span className='st-3'>
                                                                        <picture>
                                                                            <source srcSet={`./images/new-adv/res/stone-pliacrin-fire.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/new-adv/res/stone-pliacrin-fire.png`} type="image/png" />
                                                                            <img src={`./images/new-adv/res/stone-pliacrin-fire.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                    <span className='st-4'>
                                                                        <picture>
                                                                            <source srcSet={`./images/new-adv/res/stone-pliacrin-wind.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/new-adv/res/stone-pliacrin-wind.png`} type="image/png" />
                                                                            <img src={`./images/new-adv/res/stone-pliacrin-wind.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                    <span className='st-1'>
                                                                        <picture>
                                                                            <source srcSet={`./images/new-adv/res/stone-pliacrin-earth.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/new-adv/res/stone-pliacrin-earth.png`} type="image/png" />
                                                                            <img src={`./images/new-adv/res/stone-pliacrin-earth.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                </div>

                                                            </div>
                                                            <div className="one-drop-name">
                                                                Pliacrin
                                                                {!props.sendToAdv.multi && <span>
                                                                    {stonePerc / 10}%
                                                                </span>}
                                                            </div>
                                                        </div>

                                                        <div className="one-drop stone-one">
                                                            <div className="one-drop-thumb">
                                                                <div className="one-drop-thumb-stone">
                                                                    <span className='st-3'>
                                                                        <picture>
                                                                            <source srcSet={`./images/new-adv/res/stone-apatite-water.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/new-adv/res/stone-apatite-water.png`} type="image/png" />
                                                                            <img src={`./images/new-adv/res/stone-apatite-water.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                    <span className='st-4'>
                                                                        <picture>
                                                                            <source srcSet={`./images/new-adv/res/stone-apatite-fire.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/new-adv/res/stone-apatite-fire.png`} type="image/png" />
                                                                            <img src={`./images/new-adv/res/stone-apatite-fire.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                    <span className='st-1'>
                                                                        <picture>
                                                                            <source srcSet={`./images/new-adv/res/stone-apatite-wind.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/new-adv/res/stone-apatite-wind.png`} type="image/png" />
                                                                            <img src={`./images/new-adv/res/stone-apatite-wind.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                    <span className='st-2'>
                                                                        <picture>
                                                                            <source srcSet={`./images/new-adv/res/stone-apatite-earth.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/new-adv/res/stone-apatite-earth.png`} type="image/png" />
                                                                            <img src={`./images/new-adv/res/stone-apatite-earth.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                </div>

                                                            </div>
                                                            <div className="one-drop-name">
                                                                apatite
                                                                {!props.sendToAdv.multi && <span>
                                                                    {stonePerc / 10}%
                                                                </span>}
                                                            </div>
                                                        </div>

                                                        <div className="one-drop stone-one">
                                                            <div className="one-drop-thumb">
                                                                <div className="one-drop-thumb-stone">
                                                                    <span className='st-4'>
                                                                        <picture>
                                                                            <source srcSet={`./images/new-adv/res/stone-indicolite-water.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/new-adv/res/stone-indicolite-water.png`} type="image/png" />
                                                                            <img src={`./images/new-adv/res/stone-indicolite-water.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                    <span className='st-1'>
                                                                        <picture>
                                                                            <source srcSet={`./images/new-adv/res/stone-indicolite-fire.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/new-adv/res/stone-indicolite-fire.png`} type="image/png" />
                                                                            <img src={`./images/new-adv/res/stone-indicolite-fire.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                    <span className='st-2'>
                                                                        <picture>
                                                                            <source srcSet={`./images/new-adv/res/stone-indicolite-wind.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/new-adv/res/stone-indicolite-wind.png`} type="image/png" />
                                                                            <img src={`./images/new-adv/res/stone-indicolite-wind.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                    <span className='st-3'>
                                                                        <picture>
                                                                            <source srcSet={`./images/new-adv/res/stone-indicolite-earth.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/new-adv/res/stone-indicolite-earth.png`} type="image/png" />
                                                                            <img src={`./images/new-adv/res/stone-indicolite-earth.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                </div>

                                                            </div>
                                                            <div className="one-drop-name">
                                                                indicolite
                                                                {!props.sendToAdv.multi && <span>
                                                                    {stonePerc / 10}%
                                                                </span>}
                                                            </div>
                                                        </div>

                                                        <div className="one-drop stone-one">
                                                            <div className="one-drop-thumb">
                                                                <div className="one-drop-thumb-stone">
                                                                    <span className='st-1'>
                                                                        <picture>
                                                                            <source srcSet={`./images/new-adv/res/stone-tihash-water.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/new-adv/res/stone-tihash-water.png`} type="image/png" />
                                                                            <img src={`./images/new-adv/res/stone-tihash-water.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                    <span className='st-2'>
                                                                        <picture>
                                                                            <source srcSet={`./images/new-adv/res/stone-tihash-fire.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/new-adv/res/stone-tihash-fire.png`} type="image/png" />
                                                                            <img src={`./images/new-adv/res/stone-tihash-fire.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                    <span className='st-3'>
                                                                        <picture>
                                                                            <source srcSet={`./images/new-adv/res/stone-tihash-wind.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/new-adv/res/stone-tihash-wind.png`} type="image/png" />
                                                                            <img src={`./images/new-adv/res/stone-tihash-wind.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                    <span className='st-4'>
                                                                        <picture>
                                                                            <source srcSet={`./images/new-adv/res/stone-tihash-earth.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/new-adv/res/stone-tihash-earth.png`} type="image/png" />
                                                                            <img src={`./images/new-adv/res/stone-tihash-earth.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                </div>

                                                            </div>
                                                            <div className="one-drop-name">
                                                                tihash
                                                                {!props.sendToAdv.multi && <span>
                                                                    {stonePerc / 10}%
                                                                </span>}
                                                            </div>
                                                        </div>
                                                    </>

                                        }

                                    </div>
                                </div>

                                <div className="slider-time">
                                    <div className="slider-time-tit">
                                        choose time
                                        for adventure: <span>{value}</span> hours
                                        {rewardDisc == -5 ? <div className="reward-disc">
                                            Energy: <span>{rewardDisc}<i>%</i></span>
                                        </div> : <div className="reward-disc red">
                                            Energy: <span>{rewardDisc}<i>%</i></span>
                                        </div>}

                                        {rewardDisc == -5 ? <div className="reward-disc">
                                            reward: <span>normal</span>
                                        </div> : rewardDisc == -20 ? <div className="reward-disc green">
                                            reward: <span>x2</span>
                                        </div> : rewardDisc == -40 ? <div className="reward-disc green">
                                            reward: <span>x3</span>
                                        </div> : null}
                                    </div>
                                    {true ?
                                        <Slider step={stepSlide} min={minSlide} max={maxSlide} onChange={onSliderChange} defaultValue={minSlide} />
                                        :
                                        <Slider step={this.state.sliderOptions.step} min={this.state.sliderOptions.min} max={this.state.sliderOptions.max} defaultValue={this.state.sliderOptions.min} value={this.state.value} onChange={this.onSliderChange} disabled />
                                    }
                                </div>

                            </div>



                            <div className="modal-to-adv-bot">
                                <img src={`${modalbot}`} alt="" />

                                {/* {!props.sendToAdv.multi && 
                            <div className="modal-to-adv-bt">
                                {props.sendToAdv.type === 'bamboo' ? 
                                    <button className="def-bt norm" onClick={() => props.sendPandaToAdventuresMultArray(props.sendToAdv.type, props.sendToAdv.pandaInfo.asset_id, value)}>
                                        <span className="button-in">
                                            <span className="button-name">
                                                <span className="button-name-in">
                                                    <span className="button-name-value">
                                                        SEND to adventure
                                                    </span> 
                                                </span>
                                            </span>
                                        </span>
                                    </button>
                                :

                                !props.sendToAdv.pandaInfo.weapon || props.sendToAdv.pandaInfo.lvl < 2 || props.sendToAdv.pandaInfo.energy < energy || props.getReward.exp || props.sendToAdv.pandaInfo.weapon.energy < energy ?

                                    <button className="def-bt norm disabled" disabled="disabled">
                                        <span className="button-in">
                                            <span className="button-name">
                                                <span className="button-name-in">
                                                    <span className="button-name-value">
                                                        SEND to adventure
                                                    </span> 
                                                </span>
                                            </span>
                                        </span>
                                    </button> 
                                : 
                                    props.sendToAdv.type === 'resources' ?
                                    <button className="def-bt norm" onClick={() => props.sendPandaToAdventuresMultArray(props.sendToAdv.type, props.sendToAdv.pandaInfo.asset_id, value)}>
                                        <span className="button-in">
                                            <span className="button-name">
                                                <span className="button-name-in">
                                                    <span className="button-name-value">
                                                        SEND to adventure
                                                    </span> 
                                                </span>
                                            </span>
                                        </span>
                                    </button>
                                    :
                                    <button className="def-bt norm" onClick={() => props.sendPandaToAdventuresMultArray(props.sendToAdv.type, props.sendToAdv.pandaInfo.asset_id, value)}>
                                        <span className="button-in">
                                            <span className="button-name">
                                                <span className="button-name-in">
                                                    <span className="button-name-value">
                                                        SEND to adventure
                                                    </span> 
                                                </span>
                                            </span>
                                        </span>
                                    </button>
                                }
                                
                            </div>
                            } */}

                                {props.sendToAdv.multi && manyPand != 0 &&
                                    <div className="modal-to-adv-bt">
                                        {props.sendToAdv.type === 'bamboo' && manyPand ?
                                            <button className="def-bt norm" onClick={() => props.sendPandaToAdventuresMultArray(props.sendToAdv.type, 0, value)}>
                                                <span className="button-in">
                                                    <span className="button-name">
                                                        <span className="button-name-in">
                                                            <span className="button-name-value">
                                                                SEND to adventure
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </button>
                                            :

                                            hasMultiError || !manyPand ?

                                                <button className="def-bt norm disabled" disabled="disabled">
                                                    <span className="button-in">
                                                        <span className="button-name">
                                                            <span className="button-name-in">
                                                                <span className="button-name-value">
                                                                    SEND to adventure
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </button>
                                                :
                                                props.sendToAdv.type === 'resources' ?
                                                    <button className="def-bt norm" onClick={() => props.sendPandaToAdventuresMultArray(props.sendToAdv.type, 0, value)}>
                                                        <span className="button-in">
                                                            <span className="button-name">
                                                                <span className="button-name-in">
                                                                    <span className="button-name-value">
                                                                        SEND to adventure
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </button>
                                                    :
                                                    <button className="def-bt norm" onClick={() => props.sendPandaToAdventuresMultArray(props.sendToAdv.type, 0, value)}>
                                                        <span className="button-in">
                                                            <span className="button-name">
                                                                <span className="button-name-in">
                                                                    <span className="button-name-value">
                                                                        SEND to adventure
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </button>
                                        }

                                    </div>
                                }
                            </div>

                            {/* {props.sendToAdv.multi || !props.sendToAdv.multi &&
                        <> */}
                            <div className={`bg-close-multpanda ${pandaInfo.asset_id != undefined && "show"}`} onClick={(e) => { closePandaInfo() }}></div>

                            <div className={`mult-panda  ${pandaInfo.asset_id != undefined && "open"}`}>
                                <div className={`bg-close-multpanda ${pandaInfo.asset_id != undefined && "show"}`} onClick={(e) => { closePandaInfo() }}></div>
                                <div className={`pandaInfo ${pandaInfo.asset_id != undefined && "open"}`}>
                                    <div className="close-mult-rew" onClick={(e) => { closePandaInfo() }}></div>
                                    {pandaInfo.asset_id != undefined &&
                                        <div className="panda-info-in">
                                            <div className="panda-info-top">
                                                <picture>
                                                    <source srcSet={multtopmobw} type="image/webp" media="(max-width: 768px)" />
                                                    <source srcSet={multtopmob} type="image/png" media="(max-width: 768px)" />
                                                    <img src={multtopmob} alt="" />
                                                </picture>
                                                <span>hero details</span>
                                            </div>
                                            <div className="panda-info-wrap">
                                                <div className="panda-info-thumb">
                                                    <span>
                                                        <picture>
                                                            <source srcSet={`./images/card-1x/${pandaInfo.name_pa}.webp`} type="image/webp" />
                                                            <source srcSet={`./images/card-1x/${pandaInfo.name_pa}.png`} type="image/png" />
                                                            <img src={`./images/card-1x/${pandaInfo.name_pa}.png`} alt="" />
                                                        </picture>
                                                    </span>
                                                </div>
                                                <div className="panda-info-descr">
                                                    <div className="panda-info-name">
                                                        {pandaInfo.name_pa.substring(pandaInfo.name_pa.indexOf("-") + 1)}
                                                    </div>
                                                    <div className="panda-info-list">
                                                        <ul>
                                                            <li><span>id: </span> {pandaInfo.asset_id}</li>
                                                            <li><span>rang: </span>
                                                                {pandaRank}
                                                            </li>
                                                            <li>
                                                                <span>rarity: </span>
                                                                {pandaInfo.rarity == 0 && "Promo"}
                                                                {pandaInfo.rarity == 1 && "Common"}
                                                                {pandaInfo.rarity == 2 && "Uncommon"}
                                                                {pandaInfo.rarity == 3 && "Rare"}
                                                                {pandaInfo.rarity == 4 && "Epic"}
                                                                {pandaInfo.rarity == 5 && "Legendary"}
                                                                {pandaInfo.rarity == 6 && "Mythic"}
                                                            </li>
                                                            <li><span>element: </span> {pandaInfo.element}</li>
                                                            <li><span>type: </span> {pandaInfo.clas}</li>
                                                        </ul>
                                                    </div>
                                                    {(pandaRankLvl < 2 && props.sendToAdv.type !== 'bamboo') ||
                                                        (!pandaInfo.weapon && props.sendToAdv.type !== 'bamboo') ||
                                                        (pandaInfo.weapon && pandaInfo.weapon.energy < energy && props.sendToAdv.type !== 'bamboo') ||
                                                        (pandaInfo.armor && pandaInfo.armor.energy < energy) ||
                                                        (pandaInfo.energy < energy) ?

                                                        <div className="panda-info-reg">
                                                            <div className="panda-info-reg-tit">
                                                                <span>
                                                                    required
                                                                </span>
                                                            </div>
                                                            <div className="panda-info-reg-list">
                                                                {pandaRankLvl < 2 && props.sendToAdv.type !== 'bamboo' &&
                                                                    <div>Rank SECOND-LIEUTENANT</div>}

                                                                {!pandaInfo.weapon && props.sendToAdv.type !== 'bamboo' ?
                                                                    <div>Weapon common</div>
                                                                    : pandaInfo.weapon.energy < energy ?
                                                                        <div>WEAPON IS BROKEN</div>
                                                                        : null}

                                                                {pandaInfo.armor.energy < energy ?
                                                                    <div>Armor IS BROKEN</div>
                                                                    : null
                                                                }


                                                                {pandaInfo.energy < energy && <div>THE HERO IS TOO WEAK</div>}
                                                            </div>
                                                        </div>
                                                        : null
                                                    }
                                                    {Date.now() < pandaInfo.timer * 1000 &&
                                                        <div className="info-timer">
                                                            <Countdown daysInHours date={pandaInfo.timer * 1000} />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="panda-info-bot">
                                                <picture>
                                                    <source srcSet={multbotmobw} type="image/webp" media="(max-width: 767px)" />
                                                    <source srcSet={multbotmob} type="image/png" media="(max-width: 767px)" />
                                                    <img src={multbotmob} alt="" />
                                                </picture>
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className="mult-panda-in">
                                    <div className="mult-panda-top">
                                        <picture>
                                            <source srcSet={multtopw} type="image/webp" media="(min-width: 768px)" />
                                            <source srcSet={multtop} type="image/png" media="(min-width: 768px)" />
                                            <source srcSet={multtopmobw} type="image/webp" media="(max-width: 768px)" />
                                            <source srcSet={multtopmob} type="image/png" media="(max-width: 768px)" />
                                            <img src={multtop} alt="" />
                                        </picture>
                                        <span>
                                            selected heroes
                                        </span>
                                    </div>
                                    <div className="mult-panda-body">
                                        <div className="mult-panda-wrap">
                                            <div className="remove-all-mob" onClick={(e) => {
                                                activeMobFunc(0);
                                            }}></div>
                                            <MultPanda
                                                pandaSlots={props.pandaSlots}
                                                userSlots={props.userSlots}
                                                sendToAdv={props.sendToAdv}
                                                activeSlot={props.activeSlot}
                                                activeSlotArr={props.activeSlotArr}
                                                getPandaInfo={getPandaInfo}
                                                pandaInfo={pandaInfo}
                                                activeMobFunc={activeMobFunc}
                                                activeMob={activeMob}
                                                duration={value}
                                            />
                                            {props.sendToAdv.multi &&
                                                <div className="choose-only-good-mob">
                                                    <button className="def-bt min" onClick={() => props.selectAll("onlybest", props.sendToAdv.type, value)}>
                                                        <span className="button-in">
                                                            <span className="button-name">
                                                                <span className="button-name-in">
                                                                    <span className="button-name-value">
                                                                        Select all trained heroes
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </button>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                    <div className="mult-panda-bot">
                                        <picture>
                                            <source srcSet={multbotw} type="image/webp" media="(min-width: 768px)" />
                                            <source srcSet={multbot} type="image/png" media="(min-width: 768px)" />
                                            <source srcSet={multbotmobw} type="image/webp" media="(max-width: 767px)" />
                                            <source srcSet={multbotmob} type="image/png" media="(max-width: 767px)" />
                                            <img src={multbot} alt="" />
                                        </picture>
                                    </div>
                                </div>

                                <div className="all-hero-send">
                                    <button className={`all-hero-bt ${props.selectAllBt ? "active" : "dis"}`} onClick={() => props.selectAll("onlybest", props.sendToAdv.type, value)}>

                                    </button>
                                </div>
                            </div>
                            {/* </>
                        } */}
                        </div>


                    </div>

                    <div className="modal-weapon-bg-trans" onClick={() => props.showCloseToAdv()}></div>
                </div>

                <div className="modal-weapon-bg" ></div>
            </div>

        </>);
}

export default SendToAdv;