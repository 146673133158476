import React from 'react';

const InventoryRes = (props) => {



    // }
    return (<>
        {props.allinvent && props.allinvent.length ? props.allinvent.map((item, index) => {
            if (index < props.activePage * 12 && index >= (props.activePage - 1) * 12) {
                return (

                    <div key={index} className={`one-invent-prod  ${item[0].data.rarity} active`} >
                        <div className="one-invent-prod-info tooltip">
                            <div className="cust-tooltip right">
                                <div className="tool-arrow"></div>
                                <div className="tool-wrap">
                                    <div className="tool-wrap-1">
                                        <div className="tool-wrap-2">
                                            <div className="tool-body">
                                                <span>{item[0].name}</span> <br />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="one-invent-prod-thumb">
                            <div className='linkinv' onClick={() => props.showCloseModal('open', item)} ></div>
                            <div className="value-inv-eat">
                                {item.length}
                            </div>
                            <span></span>
                            <div className="one-invent-prod-thumb-in">
                                <picture>
                                    <source srcSet={`./images/jew/inventory/${item[0].template.template_id}.webp`} type="image/webp" />
                                    <source srcSet={`./images/jew/inventory/${item[0].template.template_id}.png`} type="image/png" />
                                    <img src={`./images/jew/inventory/${item[0].template.template_id}.png`} alt="" />
                                </picture>
                                <i></i>
                                <i className="b"></i>
                            </div>


                        </div>

                    </div>

                );
            }
        })
            : <></>}




    </>);

}

export default InventoryRes;