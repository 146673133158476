import React from "react";

import Menu from './Menu';
import AdventuresNew from "./AdventuresNew";
import ErrorComponent from "../Error/ErrorComponent";
import ErrorModal from "../ErrorModal/ErrorModalComponent";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import ModalOneWeapon from "./weapon/ModalOneWeapon";
import ModalOneJew from "./jew/ModalOneJew";

import {
  setSlotPandaOnGame,
  setPandaOnGame,
  toggleIsFetchingTop,
  doDelFromStates,
  addToSlotState,
  doDelFromSlot,
  setUsersRow,
  addSlot /*setTimerToState, */,
  setTimerEnd,
  removePandaAdv,
  removePandaAdvEnergy,
  changeStakeSum,
  changeStakeSumMinus,
  lvlUp,
  eat /*eatlist,*/,
  eatlistclear,
  removeWeaponSlot,
  addWeaponToSlot,
  addWeaponEnergy,
  removeJewSlot,
  addJewToSlot,
  addJewEnergy,
  removeArmorSlot,
  addArmorToSlot,
  addArmorEnergy,
  setPandaOnGameWax,
  setWeaponOnGameWax,
  setJewOnGameWax,
  setArmorOnGameWax,
  setWasLoadPandas,
  setWasLoadWeapon,
  setWasLoadJew,
  setWasLoadArmor,
  choosePanda,
  setUserSlots,
} from "./../../GlobalState/pandaonslot-reducer";

import {
  eatremove,
  eatlist,
  wasLoadinEat,
  wasBuyingEat,
} from "../../GlobalState/shop-reducer";
import {
  weaponlist,
  statusLoadinWeapon,
  addWeaponWhenRemove,
  addWeaponToPanda,
  setPandaWeapon,
} from "../../GlobalState/weapon-reducer";
import {
  jewlist,
  statusLoadinJew,
  addJewWhenRemove,
  addJewToPanda,
  setPandaJew,
} from "../../GlobalState/jew-reducer";
import {
  armorlist,
  statusLoadinArmor,
  addArmorWhenRemove,
  addArmorToPanda,
  setPandaArmor,
} from "../../GlobalState/armor-reducer";

import { ExplorerApi } from "atomicassets"; // атомик для списка панд
import { JsonRpc } from "eosjs";

import musicError from "./../../music/error.mp3";
import musicTimer from "./../../music/timer.mp3";

const fetch = require("node-fetch");
const api = new ExplorerApi(
  "https://" + localStorage.getItem('rpcA'),
  "atomicassets",
  { fetch, rateLimit: 4 }
);
var canSendsendAll = true; // ЭТО для отправки всех панд
var initial; // timer console

let userConst = null;

var arrHub = [
  "aa-wax-public1.neftyblocks.com",
  localStorage.getItem('rpcA'),
  localStorage.getItem('rpcA'),
  localStorage.getItem('rpcA'),
  "api.atomic.greeneosio.com",
  "atomic.wax.eosrio.io",
  "wax-aa.eu.eosamsterdam.net",
  "atomic-wax-mainnet.wecan.dev",
  "atomic.sentnl.io",
  // "atomic.tokengamer.io",
  // "atomic.ledgerwise.io",
  // "api-wax-aa.eosarabia.net",
  // "wax.hkeos.com/aa",
  // "wax-atomic.wizardsguild.one",
  // "wax-atomic.eosiomadrid.io",
  // "aa.wax.blacklusion.io",
  // "wax-atomic-api.eosphere.io",
  // "wax-aa.eosdublin.io"
]


var numRpc = 0;
var arrRpc = ['wax.pink.gg', 'wax.cryptolions.io', 'wax.greymass.com', 'api.wax.alohaeos.com', 'api.waxsweden.org', 'wax.eosphere.io', 'api.wax.greeneosio.com', 'wax.eu.eosamsterdam.net', 'api.wax.bountyblok.io']



class LK extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newListPandaSlot: false,
      pandaInSlot: "",
      errorOnGame: false, // ошибка для вывода карточек в игре
      isLoadedOnGame: false, // лоадинг для вывода карточек в игре
      cardOnGame: [], // сами карточки для вывода карточек в игре
      fastGetPandaOnSlot: [],
      playmusic: false,
      //userSlots: [],
      slotNFT: [],
      openedModal: false,
      numberSlot: "",
      userRow: [],
      rewardModalOpen: false,
      rewardModalText: {
        title: "",
        text: "",
        finalNumber: "",
        weaponPay: "",
        jewPay: "",
        animationNumber: "",
        resourceRew: [],
      },
      resultRew: {
        // данные про награду
        rarNft: [0, 1, 2, 3, 4, 5, 6],
        numberNft: [6600, 4000, 3240, 1200, 720, 180, 60],
        numberNew: [0.1, 0.1, 0.18, 0.5, 0.85, 3.4, 10.0],
        allCardCount: 16000, // общее число панд
        baz: 0.05, // ставка которая влияет на награду
        rang: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // ставка которая влияет на награду
        steps: [0, 8, 13, 21, 34, 55, 89, 144, 233, 377, 610, 987, 1597], // ставка которая влияет на награду
        percStake: 1, // Какой процент от заработанных токенов нужно стейкать
        rangPercProfit: [
          0, 0.02, 0.03, 0.05, 0.08, 0.13, 0.21, 0.34, 0.55, 0.89, 1.44, 2.33,
          3.77,
        ],
      },



      hasError: false, // ошибка транзакции
      errorText: "", // ошибка транзакции

      chooseHeroLoading: false, // loading slot
      openMultiSt: false,

      openSkills: false,
      acivePandaSkills: {},
      modalWeapon: {
        openModal: false, // открыто закрыто
        showAnimModal: false, // анимация
        weapon: null,
        weaponEnergy: "",
        index: "",
        cost: "",
        voucherCount: "",
        voucherList: [],
      },
      modalJew: {
        openModal: false, // открыто закрыто
        showAnimModal: false, // анимация
        jew: null,
        jewEnergy: "",
        index: "",
        cost: "",
      },
    };


    this.audioMusicError = new Audio(musicError);
    this.audioTimer = new Audio(musicTimer);

    this.getArreySlots = this.getArreySlots.bind(this);
    this.getArmorSlotWax = this.getArmorSlotWax.bind(this);
    this.getJewSlotWax = this.getJewSlotWax.bind(this);
    this.userCheck = this.userCheck.bind(this);
    this.GetPandaListOnGame = this.GetPandaListOnGame.bind(this);
    this.GetWeaponOnPanda = this.GetWeaponOnPanda.bind(this);
    this.GetJewOnPanda = this.GetJewOnPanda.bind(this);
    this.getArreySlots = this.getArreySlots.bind(this);
    this.getWeaponsSlotWax = this.getWeaponsSlotWax.bind(this);


    this.showCloseModalJew = this.showCloseModalJew.bind(this);
    this.showCloseModal = this.showCloseModal.bind(this);


    this.clearError = this.clearError.bind(this);
  }

  componentDidMount() {
    //console.log("componentDidMount");

    //console.log('this.props.id', this.props.id);


    if (this.props.id != this.state.id) {
      console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA")
      this.setState({
        id: this.props.id
      });

      this.userCheck();
      this.GetWeaponOnPanda();
      this.GetJewOnPanda();

    }



  }

  componentWillUnmount() {
    //console.log('componentWillUnmount');

  }

  showCloseModal(method, weapon, weaponEnergy, index, rar) {
    document.getElementsByTagName("body")[0].classList.add("open-modal");
    //console.log('rar', rar);

    var moneysum = 0;
    if (rar === 0) {
      moneysum = 1.08;
    } else if (rar === 1) {
      moneysum = 1.8;
    } else if (rar === 2) {
      moneysum = 2.88;
    } else if (rar === 3) {
      moneysum = 4.68;
    } else if (rar === 4) {
      moneysum = 7.56;
    } else if (rar === 5) {
      moneysum = 12.24;
    }

    //console.log(weapon);
    if (method === "open") {
      this.setState({
        ...this.state,
        modalWeapon: {
          ...this.state.modalWeapon,
          openModal: true,
          loadingChooseEat: true,
          weapon: weapon,
          weaponEnergy: weaponEnergy,
          index: index,
          cost: moneysum,
        },
      });
      setTimeout(() => {
        this.setState({
          modalWeapon: {
            ...this.state.modalWeapon,
            showAnimModal: true,
          },
        });
      }, 300);



      // if(localStorage.getItem('wasReload') === "true") {
      //     this.GetUserWeaponViucherList();
      // }
    } else {
      document.getElementsByTagName("body")[0].classList.remove("open-modal");
      this.setState({
        modalWeapon: {
          ...this.state.modalWeapon,
          openModal: false,
          showAnimModal: false,
          weapon: null,
          weaponEnergy: "",
          index: "",
          cost: "",
        },
      });

    }
  }

  showCloseModalJew(method, jew, jewEnergy, index, rar, type) {
    document.getElementsByTagName("body")[0].classList.add("open-modal");
    //console.log('rar', rar);

    var moneysum = 0;
    if (type == "armor") {
      if (rar === 0) {
        moneysum = 0.6;
      } else if (rar === 1) {
        moneysum = 1.1;
      } else if (rar === 2) {
        moneysum = 1.8;
      } else if (rar === 3) {
        moneysum = 3.4;
      } else if (rar === 4) {
        moneysum = 6.7;
      } else if (rar === 5) {
        moneysum = 14.4;
      }
    } else {
      if (rar === 0) {
        moneysum = 0.42;
      } else if (rar === 1) {
        moneysum = 0.8;
      } else if (rar === 2) {
        moneysum = 1.52;
      } else if (rar === 3) {
        moneysum = 2.88;
      } else if (rar === 4) {
        moneysum = 5.44;
      } else if (rar === 5) {
        moneysum = 10.4;
      }
    }

    //console.log(jew);
    if (method === "open") {
      this.setState({
        ...this.state,
        modalJew: {
          ...this.state.modalJew,
          openModal: true,
          loadingChooseEat: true,
          jew: jew,
          jewEnergy: jewEnergy,
          index: index,
          cost: moneysum,
        },
      });
      setTimeout(() => {
        this.setState({
          modalJew: {
            ...this.state.modalJew,
            showAnimModal: true,
          },
        });
      }, 300);


    } else {
      document.getElementsByTagName("body")[0].classList.remove("open-modal");
      this.setState({
        modalJew: {
          ...this.state.modalJew,
          openModal: false,
          showAnimModal: false,
          jew: null,
          jewEnergy: "",
          index: "",
          cost: "",
        },
      });

    }
  }

  async userCheck() {
    try {
      const activeUser = this.props.ual.activeUser;
      //const accountName = await activeUser.getAccountName();
      const accountName = this.props.id;
      var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);

      console.log("numRpc", numRpc);
      console.log("rrRpc[numRpc]", arrRpc[numRpc]);


      const results = await rpcc.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "usersnew", // name of the table as specified by the contract abi
        //"key_type": "i64",
        lower_bound: accountName,
        upper_bound: accountName,
        limit: 1,
        reverse: false,
        show_payer: false,
        index_position: 1,
      });
      const originRowUser = results;
      //const resultRow = results.rows[0].inrent;
      const resultRow = 0;

      if (resultRow === 1) {
        const resultRowName = results.rows[0].rent;
        userConst = resultRowName;
        this.getArreySlots();
        if (localStorage.getItem("wasReload") === "true") {
          //console.log('GetPandaListOnGame 1');
          //this.GetWeaponOnPanda();
          //this.GetPandaListOnGame();
        }
      } else {
        const resultRowName = results.rows[0].username;
        userConst = resultRowName;
        this.getArreySlots();

      }
    } catch (e) {
      console.log("\nCaught exception: " + e);
      setTimeout(() => {
        this.userCheck();
        if (numRpc == 8) {
          numRpc = 0;
        } else {
          numRpc++;
        }
      }, 1000);
    }
  }

  // Показать панды в игре в модальном окне
  async GetPandaListOnGame() {
    this.props.toggleIsFetchingTop(true);
    try {
      var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);

      console.log("numRpc", numRpc);
      console.log("rrRpc[numRpc]", arrRpc[numRpc]);
      //const userName = this.props.ual.activeUser.accountName;
      await rpcc
        ?.get_table_rows({
          json: true,
          code: process.env.REACT_APP_CONTRACT, // contract who owns the table
          scope: process.env.REACT_APP_CONTRACT, // scope of the table
          table: "nftsongamec", // name of the table as specified by the contract abi
          key_type: "i64",
          lower_bound: userConst,
          upper_bound: userConst,
          limit: -1,
          reverse: !0,
          show_payer: false,
          index_position: 2,
        })
        .then((results) => {
          const resultRow = results.rows;
          const resultRowFilter = resultRow.filter(
            (row) => row.storer === userConst && row.is_in_slot === 0
          ); // фильтр если нет в слоте

          //console.log("resultRow", resultRow);
          if (resultRow && resultRow.length) {
            var pandaObSlot = [];
            resultRow.map((panda) => {
              //console.log("ppp", panda);
              //panda["activePanda"] = false;
              if (panda.is_in_slot) {
                panda["activePanda"] = false;
                pandaObSlot.push(panda);
              }
            });

            //console.log('pandaObSlot', pandaObSlot);

            this.props.setPandaOnGameWax(pandaObSlot);
            this.setState({
              ...this.state,
              newListPandaSlot: true
            });
          }
          this.props.setWasLoadPandas(true);

          this.setState({ cardOnGame: resultRow });
          this.props.setPandaOnGame(resultRowFilter);
          this.props.toggleIsFetchingTop(false);
          //this.getPandaSlot();

          this.getWeaponsSlotWax();
          this.getJewSlotWax();
          this.getArmorSlotWax();
          //this.getPandaSlotWax();
        });
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      setTimeout(() => {
        this.GetPandaListOnGame();
        if (numRpc == 8) {
          numRpc = 0;
        } else {
          numRpc++;
        }
      }, 1000);
      console.log("\nCaught exception: " + e);
      // if (localStorage.getItem("sound") === "true") {
      //   this.audioMusicError.currentTime = 0;
      //   this.audioMusicError.volume = 0.1;
      //   this.audioMusicError.play();
      // }
    }
  }

  // Показать панды в игре в модальном окне
  async GetWeaponOnPanda() {
    try {
      /*const activeUser = this.props.ual.activeUser;
            const accountName = await activeUser.getAccountName();*/
      var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);

      console.log("numRpc", numRpc);
      console.log("rrRpc[numRpc]", arrRpc[numRpc]);
      const userName = this.props?.ual.activeUser.accountName;
      // console.log("rpcc 2222222222222222", rpcc);
      // console.log("userName 2222222222222222", userName);

      await rpcc
        ?.get_table_rows({
          json: true,
          code: process.env.REACT_APP_CONTRACT, // contract who owns the table
          scope: process.env.REACT_APP_CONTRACT, // scope of the table
          table: "nftweapons", // name of the table as specified by the contract abi
          key_type: "i64",
          lower_bound: userName,
          upper_bound: userName,
          limit: -1,
          reverse: !0,
          show_payer: false,
          index_position: 2,
        })
        .then((results) => {
          const resultRow = results.rows;
          //console.log(resultRow);
          //console.log(userConst);
          //const resultRowWithoutFilter = resultRow.filter(row => row.storer === userConst); // фильтр если нет в слоте
          //console.log('resultRow', resultRow);
          //const resultRowFilter = resultRow.filter(row => row.storer === userConst); // фильтр если нет в слоте

          this.props.setPandaWeapon(resultRow);
        });
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      setTimeout(() => {
        this.GetWeaponOnPanda();
        if (numRpc == 8) {
          numRpc = 0;
        } else {
          numRpc++;
        }
      }, 1000);
      console.log("\nCaught exception: " + e);

    }
  }

  // Показать панды в игре в модальном окне
  async GetJewOnPanda() {
    try {
      var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);

      console.log("numRpc", numRpc);
      console.log("rrRpc[numRpc]", arrRpc[numRpc]);
      const userName = this.props.id;

      const results = await rpcc.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "nftjew", // name of the table as specified by the contract abi
        key_type: "i64",
        lower_bound: userName,
        upper_bound: userName,
        limit: -1,
        reverse: !0,
        show_payer: false,
        index_position: 2,
      });
      const resultRow = results.rows;
      //console.log(resultRow);
      //console.log(userConst);
      //const resultRowWithoutFilter = resultRow.filter(row => row.storer === userConst); // фильтр если нет в слоте
      //console.log('resultRow', resultRow);
      //const resultRowFilter = resultRow.filter(row => row.storer === userConst); // фильтр если нет в слоте

      this.props.setPandaJew(resultRow);
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      setTimeout(() => {
        this.GetJewOnPanda();
        if (numRpc == 8) {
          numRpc = 0;
        } else {
          numRpc++;
        }
      }, 1000);
      console.log("\nCaught exception: " + e);
      // if (localStorage.getItem("sound") === "true") {
      //   this.audioMusicError.currentTime = 0;
      //   this.audioMusicError.volume = 0.1;
      //   this.audioMusicError.play();
      // }
    }
  }

  // ФУНКЦИЯ которая берет значения с таблицы слотов ИМЕННО С МАСИВА СЛОТОВ [0,0,0,0,0,0,0,0,0,0,0,...20...0]
  async getArreySlots() {
    try {
      const activeUser = this.props.ual.activeUser;
      //const accountName = await activeUser.getAccountName();
      var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);

      console.log("numRpc", numRpc);
      console.log("rrRpc[numRpc]", arrRpc[numRpc]);
      const results = await rpcc.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "usersnew", // name of the table as specified by the contract abi
        limit: 1,
        lower_bound: userConst,
        upper_bound: userConst,
        index_position: 1,
        reverse: false,
        show_payer: false,
      });
      const originRowUser = results;
      const resultRow = results.rows[0].slots_count;

      if (resultRow) {
        console.log('yessssssssssss');
        this.props.setUserSlots(resultRow);
        this.GetPandaListOnGame();
        this.props.setUsersRow(originRowUser.rows[0].max_slots);
      }
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      setTimeout(() => {
        this.getArreySlots();
        if (numRpc == 8) {
          numRpc = 0;
        } else {
          numRpc++;
        }
      }, 1000);
      console.log("\nCaught exception: " + e);
      // if (localStorage.getItem("sound") === "true") {
      //   this.audioMusicError.currentTime = 0;
      //   this.audioMusicError.volume = 0.1;
      //   this.audioMusicError.play();
      // }
    }
  }
  // КОНЕЦ ФУНКЦИЯ которая берет значения с таблицы слотов

  async getWeaponsSlotWax() {
    try {
      const accountName = this.props.id;
      var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);

      console.log("numRpc", numRpc);
      console.log("rrRpc[numRpc]", arrRpc[numRpc]);
      //console.log(rpcc);

      await rpcc
        ?.get_table_rows({
          json: true,
          code: process.env.REACT_APP_CONTRACT, // contract who owns the table
          scope: process.env.REACT_APP_CONTRACT, // scope of the table
          table: "nftweapons", // name of the table as specified by the contract abi
          limit: 100,
          lower_bound: accountName,
          upper_bound: accountName,
          key_type: "i64",
          index_position: 2,
          reverse: false,
          show_payer: false,
        })
        .then((weapons) => {
          if (weapons.rows && weapons.rows.length) {
            this.props.setWeaponOnGameWax(weapons.rows);
          }
          this.props.setWasLoadWeapon(true);
        });
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      setTimeout(() => {
        this.getWeaponsSlotWax();
        if (numRpc == 8) {
          numRpc = 0;
        } else {
          numRpc++;
        }
      }, 1000);
      console.log("\nCaught exception: " + e);
      // if (localStorage.getItem("sound") === "true") {
      //   this.audioMusicError.currentTime = 0;
      //   this.audioMusicError.volume = 0.1;
      //   this.audioMusicError.play();
      // }
    }
  } // КОНЕЦ

  async getJewSlotWax() {
    try {
      const accountName = this.props.id;
      var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);

      console.log("numRpc", numRpc);
      console.log("rrRpc[numRpc]", arrRpc[numRpc]);

      /*console.log("rpcc 33",rpcc);
            console.log("accountName 33",accountName);
            //console.log("activeUser",activeUser);*/

      var jew = await rpcc?.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "nftjew", // name of the table as specified by the contract abi
        limit: 100,
        lower_bound: accountName,
        upper_bound: accountName,
        key_type: "i64",
        index_position: 2,
        reverse: false,
        show_payer: false,
      });

      //console.log("jew", jew);

      if (jew?.rows && jew?.rows?.length) {
        this.props.setJewOnGameWax(jew.rows);
      }
      this.props.setWasLoadJew(true);
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      setTimeout(() => {
        this.getJewSlotWax();
        if (numRpc == 8) {
          numRpc = 0;
        } else {
          numRpc++;
        }
      }, 1000);
      console.log("\nCaught exception 123: " + e);
      // if (localStorage.getItem("sound") === "true") {
      //   this.audioMusicError.currentTime = 0;
      //   this.audioMusicError.volume = 0.1;
      //   this.audioMusicError.play();
      // }
    }
  } // КОНЕЦ

  async getArmorSlotWax() {
    try {
      const accountName = this.props.id;
      var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);

      console.log("numRpc", numRpc);
      console.log("rrRpc[numRpc]", arrRpc[numRpc]);

      /*console.log("rpcc 33",rpcc);
            console.log("accountName 33",accountName);
            //console.log("activeUser",activeUser);*/

      var armor = await rpcc.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "nftarmor", // name of the table as specified by the contract abi
        limit: 100,
        lower_bound: accountName,
        upper_bound: accountName,
        key_type: "i64",
        index_position: 2,
        reverse: false,
        show_payer: false,
      });

      //console.log("armor", armor);

      if (armor?.rows && armor?.rows?.length) {
        this.props.setArmorOnGameWax(armor.rows);
      }
      this.props.setWasLoadArmor(true);
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      setTimeout(() => {
        this.getArmorSlotWax();
        if (numRpc == 8) {
          numRpc = 0;
        } else {
          numRpc++;
        }
      }, 1000);
      console.log("\nCaught exception: " + e);
      // if (localStorage.getItem("sound") === "true") {
      //   this.audioMusicError.currentTime = 0;
      //   this.audioMusicError.volume = 0.1;
      //   this.audioMusicError.play();
      // }
    }
  } // КОНЕЦ

  // диспаччит таймер слот
  async clearError() {
    this.setState({
      errorText: "",
      hasError: false,
    });
  }



  render() {
    return (
      <>
        <Menu id={this.props.id}  {...this.props.ual} wax={this.props.balanceWax} bam={this.props.balanceBam} game={this.props.balanceGame} updateBalance={this.props.updateBalance} />


        <>
          {this.state.hasError ? (
            <ErrorComponent
              errorMessage={this.state.errorText}
              clearError={this.clearError}
            />
          ) : null}

          {this.state.modalWeapon.openModal ? (
            <ModalOneWeapon
              modalWeapon={this.state.modalWeapon}
              showCloseModal={this.showCloseModal}
              sendOutWeapon={this.sendOutWeapon}
              addEnergyWeapon={this.addEnergyWeapon}
              addEnergyWeaponByVoucher={this.addEnergyWeaponByVoucher}
            />
          ) : null}

          {this.state.modalJew.openModal ? (
            <ModalOneJew
              modalJew={this.state.modalJew}
              showCloseModalJew={this.showCloseModalJew}
              sendOutJew={this.sendOutJew}
              sendOutArmor={this.sendOutArmor}
              addEnergyJew={this.addEnergyJew}
            />
          ) : null}


          {this.props.wasLoadPandas &&
            this.props.wasLoadWeapon &&
            this.props.wasLoadJew &&
            this.props.pandaSlotOnGameWax &&
            this.state.newListPandaSlot
            ? (
              <AdventuresNew
                onFormSubmit={this.onFormSubmit}
                slotNFT={this.props.pandaSlotOnGame}
                pandaListOnGame={this.props.pandaListOnGame}
                showCloseModalJew={this.showCloseModalJew}
                showCloseModal={this.showCloseModal}


                usersRow={this.props.usersRow}

                resultRew={this.state.resultRew}

                chooseHeroLoading={this.state.chooseHeroLoading}

                pandaInSlot={this.state.pandaInSlot}
                pandaSlots={this.props.pandaSlotOnGameWax}
                userSlots={this.props.userSlots}
                allUserInfo={this.props.allUserInfo}
              />
            ) : (
              <main className="slot-page top-user"></main>
            )}
        </>

      </>
    );
  }
}

let mapStateToProps = (state) => ({
  pandaSlotOnGame: state.slotReducer.pandaSlotOnGame,
  pandaListOnGame: state.slotReducer.pandaListOnGame,
  usersRow: state.slotReducer.usersRow,
  //alleat: state.slotReducer.alleat,
  statuseat: state.showReduser.statuseat,
  alleat: state.showReduser.alleat,
  wasbay: state.showReduser.wasbay,
  allWeapon: state.weaponReduser.allWeapon,
  statusWeapon: state.weaponReduser.statusWeapon,
  pandaWeapon: state.weaponReduser.pandaWeapon,
  allJew: state.jewReduser.allJew,
  allArmor: state.armorReduser.allArmor,
  statusJew: state.jewReduser.statusJew,
  pandaJew: state.jewReduser.pandaJew,
  statusArmor: state.armorReduser.statusArmor,
  pandaArmor: state.armorReduser.pandaArmor,
  pandaSlotOnGameWax: state.slotReducer.pandaSlotOnGameWax,
  //pandaSlotWeaponWax: state.slotReducer.pandaSlotWeaponWax,
  //pandaSlotJewWax: state.slotReducer.pandaSlotJewWax,
  wasLoadPandas: state.slotReducer.wasLoadPandas,
  wasLoadWeapon: state.slotReducer.wasLoadWeapon,
  wasLoadJew: state.slotReducer.wasLoadJew,
  wasLoadArmor: state.slotReducer.wasLoadArmor,
  userSlots: state.slotReducer.userSlots,
});

export default connect(mapStateToProps, {
  setSlotPandaOnGame,
  setPandaOnGame,
  toggleIsFetchingTop,
  doDelFromStates,
  addToSlotState,
  doDelFromSlot,
  setUsersRow,
  addSlot /*setTimerToState,*/,
  setTimerEnd,
  removePandaAdv,
  removePandaAdvEnergy,
  changeStakeSum,
  changeStakeSumMinus,
  lvlUp,
  eat,
  eatremove,
  eatlist,
  eatlistclear,
  wasLoadinEat,
  wasBuyingEat,
  weaponlist,
  statusLoadinWeapon,
  removeWeaponSlot,
  addWeaponWhenRemove,
  addWeaponToPanda,
  addWeaponToSlot,
  setPandaWeapon,
  addWeaponEnergy,
  jewlist,
  statusLoadinJew,
  removeJewSlot,
  addJewWhenRemove,
  addJewToPanda,
  addJewToSlot,
  setPandaJew,
  addJewEnergy,
  setPandaOnGameWax,
  setWeaponOnGameWax,
  setJewOnGameWax,
  setArmorOnGameWax,
  setWasLoadPandas,
  setWasLoadWeapon,
  setWasLoadJew,
  setWasLoadArmor,
  choosePanda,
  setUserSlots,
  armorlist,
  statusLoadinArmor,
  removeArmorSlot,
  addArmorWhenRemove,
  addArmorToPanda,
  addArmorToSlot,
  setPandaArmor,
  addArmorEnergy,
})(LK);
