import { JsonRpc } from 'eosjs';

var numRpc = 0;
var arrRpc = ['wax.pink.gg', 'wax.cryptolions.io', 'wax.greymass.com', 'api.wax.alohaeos.com', 'api.waxsweden.org', 'wax.eosphere.io', 'api.wax.greeneosio.com', 'wax.eu.eosamsterdam.net', 'api.wax.bountyblok.io']

const getAllTopAlienUsers = (accountName) => {

    const resultRow = [];
    const limit = 1000;
    return new Promise(async (resolve, reject) => {
        try {
            while (true) {
                var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);
                const results = await rpcc.get_table_rows({
                    json: true,
                    code: process.env.REACT_APP_CONTRACT,
                    scope: process.env.REACT_APP_CONTRACT,
                    table: "alientop",
                    limit: limit,
                    key_type: "i64",
                    reverse: !0,
                    show_payer: false,
                    index_position: 2,
                });

                if (results && results.rows && results.rows.length) {
                    resultRow.push(...results.rows);
                }

                if (results.rows.length < limit) break;
            }

            //console.log("resultRow", resultRow);
            resolve(resultRow);

        } catch (e) {
            console.warn(e);
            reject(e.message);
            setTimeout(() => {
                if (numRpc == 8) {
                    numRpc = 0;
                } else {
                    getAllTopAlienUsers(accountName);
                    numRpc++;
                }
            }, 1000);
            console.log("\nCaught exception: " + e);
        }
    });
}

export default getAllTopAlienUsers;
