import React from "react";
import Menu from '../../components/Menu';
import { Link, NavLink } from "react-router-dom";

import { connect } from "react-redux";
import {
  weaponlist,
  statusLoadinWeapon,
  addWeaponWhenRemove,
  addWeaponToPanda,
} from "../../GlobalState/weapon-reducer";

import ErrorComponent from "../Error/ErrorComponent";

import { JsonRpc } from "eosjs";
import "./../../css/craft.scss";

import inventbotw from "../../images/craft/bg-craft.jpg";

import musicError from "./../../music/error.mp3";

import { ExplorerApi } from "atomicassets"; // атомик для списка панд

const fetch = require("node-fetch");
const api = new ExplorerApi(
  "https://" + localStorage.getItem('rpcA'),
  "atomicassets",
  { fetch, rateLimit: 4 }
);

// это транзакция отправки ИЗ
const cardTransferOut = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "transferout",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        from: "",
        to: "",
        asset_id: [],
      },
    },
  ],
};

class CraftComponentWeapon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shopitem: null,
    };

    this.clearError = this.clearError.bind(this);
    this.closeModalResult = this.closeModalResult.bind(this);
    this.audioMusicError = new Audio(musicError);
  }

  componentDidMount() { }

  async clearError() {
    this.setState({
      errorText: "",
      hasError: false,
    });
  }

  render() {
    return (
      <>
        <Menu  {...this.props.ual} wax={this.props.balanceWax} bam={this.props.balanceBam} game={this.props.balanceGame} updateBalance={this.props.updateBalance} />
        {this.state.hasError ? (
          <ErrorComponent
            errorMessage={this.state.errorText}
            clearError={this.clearError}
          />
        ) : null}
        <main className="craft-page">
          <div className="loading-block"></div>

          <div className="craft-wrap">
            {/* <div className="img-mob">
                        <img src={inventbotw} alt="" />
                    </div>
                    <div className="img-mob">
                        <img className='mob' src={inventbotw} alt="" />
                    </div>
                    <div className="craft-body">
                        <NavLink className="craft-part weapon-part" to="/craft-weapon-page">
                        </NavLink>
                        <NavLink className="upgrade-part weapon-part" to="/upgrade-weapon-page">
                        </NavLink>
                    </div> */}
          </div>

          <footer>
            <div className="footer-nav">
              <nav>
                <ul>
                  <li>
                    <a
                      className="foot-logo-1"
                      target="_blank"
                      href="https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&sort=created&symbol=WAX"
                    >
                      <span></span>
                      <i>Market</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-2"
                      target="_blank"
                      href="https://twitter.com/NftPanda/"
                    >
                      <span></span>
                      <i>Twitter</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-3"
                      target="_blank"
                      href="https://www.instagram.com/nftpanda/"
                    >
                      <span></span>
                      <i>Instagram</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-4"
                      target="_blank"
                      href="https://discord.gg/vCSgme4d5b"
                    >
                      <span></span>
                      <i>Discord</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-5"
                      target="_blank"
                      href={`https://wax.alcor.exchange/swap?output=${process.env.REACT_APP_VAL}-nftpandabamb&input=WAX-eosio.token`}
                    >
                      <span></span>
                      <i>{process.env.REACT_APP_VAL} Trade</i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="foot-logo-6"
                      target="_blank"
                      href="https://discord.gg/vCSgme4d5b"
                    >
                      <span></span>
                      <i>Support</i>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </footer>
        </main>
      </>
    );
  }
}

// let mapStateToProps = (state) => ({
//     allinvent: state.inventoryReduser.allinvent,
// });

let mapStateToProps = (state) => ({
  allWeapon: state.weaponReduser.allWeapon,
  statusWeapon: state.weaponReduser.statusWeapon,
});

export default connect(mapStateToProps, {
  weaponlist,
  statusLoadinWeapon,
  addWeaponWhenRemove,
  addWeaponToPanda,
})(CraftComponentWeapon);
