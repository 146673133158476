import React from "react";
import {
  /*BrowserRouter, Redirect, Route, Switch,*/ Link,
  NavLink,
} from "react-router-dom";
import ErrorComponent from "./../../pages/Error/ErrorComponent";
import logoHeader from "../../images/logo-header.png"; // relative path to image
import logoHeaderW from "../../images/logo-header.webp"; // relative path to image
import musicOpen from "../../music/open.mp3";
import musicClose from "../../music/close.mp3";
import musicInOut from "../../music/in-out.mp3";
import musicError from "../../music/error.mp3";


import { JsonRpc } from 'eosjs'
import { ExplorerApi } from "atomicassets" // атомик для списка панд

const api = new ExplorerApi("https://" + localStorage.getItem('rpcA'), "atomicassets", { fetch, rateLimit: 4 });

var arrHub = [
  "aa-wax-public1.neftyblocks.com",
  localStorage.getItem('rpcA'),
  localStorage.getItem('rpcA'),
  localStorage.getItem('rpcA'),
  "api.atomic.greeneosio.com",
  "atomic.wax.eosrio.io",
  "wax-aa.eu.eosamsterdam.net",
  "atomic-wax-mainnet.wecan.dev",
  "atomic.sentnl.io",
  // "atomic.tokengamer.io",
  // "atomic.ledgerwise.io",
  // "api-wax-aa.eosarabia.net",
  // "wax.hkeos.com/aa",
  // "wax-atomic.wizardsguild.one",
  // "wax-atomic.eosiomadrid.io",
  // "aa.wax.blacklusion.io",
  // "wax-atomic-api.eosphere.io",
  // "wax-aa.eosdublin.io"
]


var numRpc = 0;
var arrRpc = ['wax.pink.gg', 'wax.cryptolions.io', 'wax.greymass.com', 'api.wax.alohaeos.com', 'api.waxsweden.org', 'wax.eosphere.io', 'api.wax.greeneosio.com', 'wax.eu.eosamsterdam.net', 'api.wax.bountyblok.io']


//const api2 = new ExplorerApi("https://" + localStorage.getItem('rpcA'), { fetch, rateLimit: 4 });


class Menu extends React.Component {
  //export const Menu = (props) => {

  constructor(props) {
    super(props);

    this.state = {
      activeNoda: "wax.pink.gg",
      openLk: false,
      activeNodaAtomic: localStorage.getItem('rpcA'),
      allparts: {},
    };



    this.clearError = this.clearError.bind(this);
    this.clickBg = this.clickBg.bind(this);

    this.audioOpen = new Audio(musicOpen);
    this.audioClose = new Audio(musicClose);
    this.audioMusicInOut = new Audio(musicInOut);
    this.audioMusicError = new Audio(musicError);
  }

  componentDidMount() {
    this.userCheck();

    if (!localStorage.getItem("rpc")) {
      localStorage.setItem("rpc", "wax.pink.gg");
    } else {
      this.setState({
        activeNoda: localStorage.getItem("rpc"),
      });
    }

    if (!localStorage.getItem("rpcA")) {
      localStorage.setItem("rpcA", localStorage.getItem('rpcA'));
    } else {
      this.setState({
        activeNodaAtomic: localStorage.getItem("rpcA"),
      });
    }
  }

  componentDidUpdate() {
    // if(localStorage.getItem('tavernModal3') && this.state.openTavModal) {
    //   this.showCloseModalTav("close");
    // }
  }

  async userCheck() {
    try {
      //console.log(555555555555555555555555555555555555555555555);

      //console.log(activeUser);
      //console.log(accountName);
      var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);

      const results = await rpcc.get_table_rows({
        json: true,
        code: process.env.REACT_APP_CONTRACT, // contract who owns the table
        scope: process.env.REACT_APP_CONTRACT, // scope of the table
        table: "usertop", // name of the table as specified by the contract abi
        //"table": "usersnew",    // name of the table as specified by the contract abi
        limit: 300,
        /*lower_bound: 0,*/
        //upper_bound: 0,
        //lower_bound: 0,
        key_type: "i64",
        //"index_position": 2,
        //"limit": -1,
        reverse: !0,
        show_payer: false,
        index_position: 2,
      });
      const originRowUser = results.rows;
      //console.log(originRowUser);

      /*function dynamicSort(property) {
                var sortOrder = -1;
                if(property[0] === "-") {
                    sortOrder = 1;
                    property = property.substr(1);
                }
                return function (a,b) {
                    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                    return result * sortOrder;
                }
            }

            originRowUser.sort(dynamicSort("allsum"));*/

      this.setState({
        formInfo: originRowUser,
      });
    } catch (e) {
      //console.log(e.message)
      console.warn(e);
      this.setState({
        hasError: true,
        errorText: e.message,
      });
      console.log("\nCaught exception: " + e);
    }
  }


  async clearError() {
    this.setState({
      errorText: "",
      hasError: false,
    });
  }

  showCloseModalBal(method) {
    document.getElementsByTagName("body")[0].classList.add("open-modal");

    if (method === "open") {
      this.setState({
        ...this.state,
        modalBal: {
          ...this.state.modalBal,
          openModal: true,
        },
      });
      setTimeout(() => {
        this.setState({
          modalBal: {
            ...this.state.modalBal,
            showAnimModal: true,
          },
        });
      }, 300);

      if (localStorage.getItem("sound") === "true") {
        this.audioOpen.currentTime = 0;
        this.audioOpen.volume = 0.1;
        this.audioOpen.play();
      }
    } else {
      document.getElementsByTagName("body")[0].classList.remove("open-modal");
      this.setState({
        modalBal: {
          ...this.state.modalBal,
          openModal: false,
          showAnimModal: false,
          success: false,
          error: false,
          errorMes: "",
        },
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioClose.currentTime = 0;
        this.audioClose.volume = 0.1;
        this.audioClose.play();
      }
    }
  }

  clickBg = (event) => {
    document.getElementsByClassName("header-menu")[0].classList.remove("open");
    document.getElementsByClassName("bg-plane")[0].classList.remove("open");
    document.getElementsByClassName("show-hide-bt")[0].classList.remove("open");

    this.setState({
      ...this.state,
      opendrop: false,
      openLk: false,
    });
  };

  clickMenu = (event) => {
    if (window.innerWidth < 1410) {
      //console.log(event);
      //event.preventDefault();
      //var currentEventTarget = event.currentTarget;
      var menu = document.getElementsByClassName("show-hide-bt")[0];
      //console.log(menu);
      if (!menu.classList.contains("open")) {
        document.getElementsByClassName("header-menu")[0].classList.add("open");
        document.getElementsByClassName("bg-plane")[0].classList.add("open");
        document
          .getElementsByClassName("show-hide-bt ")[0]
          .classList.add("open");
        document
          .getElementsByClassName("header-lk")[0]
          .classList.remove("open");
        document
          .getElementsByClassName("header-lk-top")[0]
          .classList.remove("open");
      } else {
        document
          .getElementsByClassName("header-menu")[0]
          .classList.remove("open");
        document.getElementsByClassName("bg-plane")[0].classList.remove("open");
        document
          .getElementsByClassName("show-hide-bt ")[0]
          .classList.remove("open");
        document
          .getElementsByClassName("header-lk")[0]
          .classList.remove("open");
        document
          .getElementsByClassName("header-lk-top")[0]
          .classList.remove("open");
      }
      //console.log(currentEventTarget);
    }
  };

  render() {
    var bamBal = 0;
    var walletBal = 0;

    if (this.props.game !== null && this.props.bam !== null) {
      bamBal = Number(this.props.game);
      bamBal = bamBal.toFixed(2);
      walletBal = Number(this.props.bam);
      walletBal = walletBal.toFixed(2);
    }

    return (
      <>


        <div className="bg-plane" onClick={this.clickBg}></div>

        {this.state.hasError ? (
          <ErrorComponent
            errorMessage={this.state.errorText}
            clearError={this.clearError}
          />
        ) : null}


        <header className="headerabsol">
          <div className="container">
            <div className="header-in">
              <div className="show-mobile-menu">
                <button
                  className="show-hide-bt def-bt min"
                  onClick={this.clickMenu}
                >
                  <span className="button-in">
                    <span className="button-name">
                      <span className="button-name-in">
                        <span className="button-name-value">
                          <i></i>
                          <i></i>
                          <i></i>
                        </span>
                      </span>
                    </span>
                  </span>
                </button>
              </div>

              <div className="header-logo">

                <picture>
                  <source srcSet={logoHeaderW} type="image/webp" />
                  <source srcSet={logoHeader} type="image/png" />
                  <img src={logoHeader} alt="" />
                </picture>
              </div>



              <div className="menu-will-be left-part">
                <div className="one-menu-item tooltip">
                  <NavLink
                    className="icon-bt home"
                    to="/alien-leaderboard"

                    activeClassName="active"
                  ></NavLink>
                  <div className="cust-tooltip bottom">
                    <div className="tool-arrow"></div>
                    <div className="tool-wrap">
                      <div className="tool-wrap-1">
                        <div className="tool-wrap-2">
                          <div className="tool-body">Home</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>




              <div className="header-menu">
                <div className="header-menu-in">
                  <div className="one-menu-item hide-desc">
                    <NavLink
                      className="def-bt min"
                      to="/alien-leaderboard"

                      activeClassName="active"
                    >
                      <span className="button-in">
                        <span className="button-name">
                          <span className="button-name-in">
                            <span className="button-name-value">
                              Home
                            </span>
                          </span>
                        </span>
                      </span>
                    </NavLink>
                  </div>



                </div>
              </div>

            </div>
          </div>

        </header>

      </>
    );
  }
}

export default Menu;
