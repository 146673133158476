import React from 'react';
import NeedForQuestNew from './NeedForQuestNew';
import Countdown from 'react-countdown';

const OneTabNewQuest = (props) => {
   

    // needSteel: 50,
    // needTitawin: 10,
    // needKvessir: 3,
    // needGold: 2
    // }
    return (<>
        <div className="quest-modal-descr">
            <div className="quest-modal-name">
                {props.questsArt[props.activeTabLocal].nameQuest}
            </div>
            <div className="quest-modal-bring">
                <div className="quest-modal-bring-name">
                    task:
                </div>
                <div className="quest-modal-bring-wrap">
                    {true ? <NeedForQuestNew balance={props.balanceGame} activeQuest={props.questsArt[props.activeTabLocal].bring} infoquest={props.questNew} alluserinfo={props.allUserInfo} activeTab={props.activeTabLocal} /> : null }
                </div>
            </div>

            <div className="reward-item">
                <div className="reward-item-in">
                    <div className="reward-item-left">
                        <span>
                            reward:
                        </span>
                        <i dangerouslySetInnerHTML={{__html: props.questsArt[props.activeTabLocal].reward.name}}></i>
                    </div>
                    <div className="reward-item-one">
                        <div className="reward-item-thumb">
                            <div className="reward-item-thumb-in">
                                
                                
                                <picture>
                                    <source srcSet={`./images/${props.questsArt[props.activeTabLocal].reward.type == "weapon" ? "weapons/inventory/" : ""}${props.questsArt[props.activeTabLocal].reward.tmpl1}.webp`} type="image/webp" />
                                    <source srcSet={`./images/${props.questsArt[props.activeTabLocal].reward.type == "weapon" ? "weapons/inventory/" : ""}${props.questsArt[props.activeTabLocal].reward.tmpl1}.png`} type="image/png" /> 
                                    <img src={`./images/${props.questsArt[props.activeTabLocal].reward.type == "weapon" ? "weapons/inventory/" : ""}${props.questsArt[props.activeTabLocal].reward.tmpl1}.png`} alt="" />
                                </picture>
                                
                            </div>
                            <div className="num-revard">
                            1
                            </div>
                            <div className="one-shafl-prod-info tooltip">
                                <div className="cust-tooltip right">
                                    <div className="tool-arrow"></div>
                                    <div className="tool-wrap">
                                        <div className="tool-wrap-1">
                                            <div className="tool-wrap-2">
                                                <div className="tool-body">
                                                    <span>
                                                    1    
                                                    </span> <br />
                                                    items left for you
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            
            
            
            
            {props.questNew["questdone" + (props.activeTabLocal + 1)] ?
            <div className="quest-complate"></div> : null }

           

            <div className="quest-bt">

                
                
                {   
                    !props.questNew["quest" + (props.activeTabLocal + 1)] ?
                    <button className="def-bt mid" onClick={() => props.getNewQuest(props.activeTabLocal)}>
                        <span className="button-in">
                            <span className="button-name">
                                <span className="button-name-in">
                                    <span className="button-name-value">
                                        get Quest
                                    </span> 
                                </span>
                            </span>
                        </span>
                    </button>
                    :
                        props.canComplateLocal ?
                        <button className="def-bt mid"  onClick={() => props.sendQuestNew(props.activeTabLocal)}>
                            <span className="button-in">
                                <span className="button-name">
                                    <span className="button-name-in">
                                        <span className="button-name-value">
                                            get reward
                                        </span> 
                                    </span>
                                </span>
                            </span>
                        </button>
                        :
                        <button className="def-bt mid" disabled>
                            <span className="button-in">
                                <span className="button-name">
                                    <span className="button-name-in">
                                        <span className="button-name-value">
                                            get reward
                                        </span> 
                                    </span>
                                </span>
                            </span>
                        </button>
                
                }
            </div>

        </div>
    </>);

}

export default OneTabNewQuest;