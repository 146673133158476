import { JsonRpc } from 'eosjs';

var numRpc = 0;
var arrRpc = ['wax.pink.gg', 'wax.cryptolions.io', 'wax.greymass.com', 'api.wax.alohaeos.com', 'api.waxsweden.org', 'wax.eosphere.io', 'api.wax.greeneosio.com', 'wax.eu.eosamsterdam.net', 'api.wax.bountyblok.io']

const getAllLvlAssets = (accountName) => {


    return new Promise(async (resolve, reject) => {
        try {
            var rpcc = new JsonRpc("https://" + arrRpc[numRpc]);
            const results = await rpcc.get_table_rows({
                json: true,
                code: process.env.REACT_APP_CONTRACT,
                scope: process.env.REACT_APP_CONTRACT,
                //code: "nftpandawofg",
                //scope: "nftpandawofg",
                table: "alienlvl",
                limit: 100,
                key_type: "i64",
                lower_bound: accountName,
                upper_bound: accountName,
                limit: -1,
                reverse: !0,
                show_payer: false,
                index_position: 2,
            });

            var resultRow = [];
            if (results && results.rows && results.rows.length) {
                resultRow = results.rows;
            }

            //console.log(results);
            resolve(resultRow);

        } catch (e) {
            console.warn(e);
            reject(e.message);
            setTimeout(() => {
                if (numRpc == 8) {
                    numRpc = 0;
                } else {
                    getAllLvlAssets(accountName);
                    numRpc++;
                }
            }, 1000);
            console.log("\nCaught exception: " + e);
        }
    });
}

export default getAllLvlAssets;
