import React from 'react';

const TotalInfo = (props) => {
    //allAlienRes
    //allTopAlienUsers
    var topNumber = 0;
    if (props.allTopAlienUsers && props.allTopAlienUsers.length) {
        topNumber = props.allTopAlienUsers.findIndex(user => user.username === props.user);
    }

    return (
        <div className="totalInfo">
            <div className="totalInfo-wrap">
                <div className="totalInfo-top">
                    <div className="totalInfo-name">
                        Total Space Crystals:
                    </div>
                    <div className="totalInfo-val">
                        {(props.allAlienRes?.res[0] / 10000000 ?? 0).toFixed(2)}
                    </div>
                </div>
                <div className="totalInfo-bot">
                    <div className="totalInfo-name">
                        Leaderboard position:
                    </div>
                    <div className="totalInfo-val">
                        {topNumber + 1}
                    </div>
                </div>
            </div>
            <div className="total-info-bt" onClick={() => props.closeModalInfo(true)}>
                information
            </div>
        </div>
    );

}

export default TotalInfo;