import React, { useEffect, useRef, useState } from "react";
import InventoryEat from "./Inventory/InventoryEat";
import InventoryRes from "./Inventory/InventoryRes";
import InventoryVoucher from "./Inventory/InventoryVoucher";


import inventtop from "../../images/fire/modal-top.png";
import inventbot from "../../images/fire/modal-bot.png";
import inventtopw from "../../images/fire/modal-top.webp";
import inventbotw from "../../images/fire/modal-bot.webp";

const InventModal = (props) => {

    const widthScale = props.widthScale;
    const [activeTab, setActiveTab] = useState('res');
    const [activePage, setActivePage] = useState(1);
    const [countPage, setCountPage] = useState(0);
    const [shopitem, setShopitem] = useState(0);

    const tabsnav = (tab) => {

        setActivePage(1);
        if (props.allItems) {
            if (tab === 1) {
                setActiveTab("eat");
                setCountPage(props.allItems.food && props.allItems.food.length / 12);
            } else if (tab === 2) {
                setActiveTab("res");
                setCountPage(props.allItems.resources && props.allItems.resources.length / 12);
            } else if (tab === 3) {
                setActiveTab("gems");
                setCountPage(props.allItems.gems && props.allItems.gems.length / 12);
            } else if (tab === 4) {
                setActiveTab("voucher");
                setCountPage(props.allItems.vouchers && props.allItems.vouchers.length / 12);
            }
        }
    }

    return (
        <div className="modal-inv">
            <div className="loading-block"></div>
            <div className="modal-inv-in">
                <div className="burn-body">
                    <div className="burn-top">
                        <b className="modal-bay-close" onClick={() => props.closeInvent()}><span></span></b>
                        <picture>
                            <source srcSet={inventtopw} type="image/webp" />
                            <source srcSet={inventtop} type="image/png" />
                            <img className="hidemob" src={inventtop} alt="" />
                        </picture>

                        <div className="title-burn-modal">
                            add to burn
                        </div>

                        <div className="max-burn">
                            max 50 nft, you can still add {50 - props.manyItems} items
                        </div>

                        <div className="exch-tabs burn-tab">
                            <ul>



                                <li
                                    className={`${activeTab === "res" ? "res active" : "res"
                                        }`}
                                >
                                    <span
                                        className={`${activeTab === "res" ? "active" : ""
                                            }`}
                                        onClick={() => tabsnav(2)}
                                    ></span>
                                </li>
                                <li
                                    className={`${activeTab === "gems" ? "gem active" : "gem"
                                        }`}
                                >
                                    <span
                                        className={`${activeTab === "gems" ? "active" : ""
                                            }`}
                                        onClick={() => tabsnav(3)}
                                    ></span>
                                </li>
                                <li
                                    className={`${activeTab === "eat" ? "eat active" : "eat"
                                        }`}
                                >
                                    <span
                                        className={`${activeTab === "eat" ? "active" : ""
                                            }`}
                                        onClick={() => tabsnav(1)}
                                    ></span>
                                </li>
                                <li
                                    className={`${activeTab === "vouchers" ? "vouchers active" : "vouchers"
                                        }`}
                                >
                                    <span
                                        className={`${activeTab === "vouchers" ? "active" : ""
                                            }`}
                                        onClick={() => tabsnav(4)}
                                    ></span>
                                </li>

                            </ul>
                        </div>

                    </div>

                    {activeTab === "eat" && props.allItems.food ? (
                        <InventoryEat
                            allinvent={props.allItems.food}
                            showCloseModal={props.showCloseModal}
                            changeCountBt={props.changeCountBt}
                            manyItems={props.manyItems}
                        />
                    ) : activeTab === "res" && props.allItems.resources || activeTab === "gems" && props.allItems.gems ? (
                        <InventoryRes
                            allinvent={activeTab === "res" ? props.allItems.resources : activeTab === "gems" ? props.allItems.gems : null}
                            activePage={activePage}
                            showCloseModal={props.showCloseModal}
                            changeCountBt={props.changeCountBt}
                            manyItems={props.manyItems}
                        />
                    ) : activeTab === "voucher" && props.allItems.voucher ? (
                        <InventoryVoucher
                            allinvent={props.allItems.voucher}
                            activePage={activePage}
                            showCloseModal={props.showCloseModal}
                            changeCountBt={props.changeCountBt}
                            manyItems={props.manyItems}
                        />
                    ) : null}

                    <div className="burn-bot">

                        <picture>
                            <source srcSet={inventbotw} type="image/webp" />
                            <source srcSet={inventbot} type="image/png" />
                            <img className="bot-inv-nav" src={inventbot} alt="" />
                        </picture>

                        <div className="add-ball">
                            <button className="def-bt mid" onClick={() => props.addpoints()}>
                                <span className="button-in">
                                    <span className="button-name">
                                        <span className="button-name-in">
                                            <span className="button-name-value">
                                                add {props.manyBalls / 100} points
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </button>
                        </div>


                        {/* <div className="burn-nav">
                            <div className="burn-nav-in">
                                {countPage > 1 && activePage > 1 ? (
                                    <button
                                        className="nav-link nav-prev"
                                        onClick={() => this.navpage("prev")}
                                    >
                                        <span></span>
                                    </button>
                                ) : null}

                                <div className="nav-num">{activePage}</div>

                                {countPage > 1 &&
                                    countPage >= activePage ? (
                                    <button
                                        className="nav-link nav-next"
                                        onClick={() => this.navpage("next")}
                                    >
                                        <span></span>
                                    </button>
                                ) : null}
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};


export default InventModal;