import React from "react";
import Menu from '../../components/Menu';

import { connect } from "react-redux";
/*import Rent from './Rent'; 
import Rentfrom from './Rentfrom'; */

const addRentTrans = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "addrent",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: { username: "", add_user: "" },
    },
  ],
};

const addRentTransFrom = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "addrentfrom",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: { username: "", add_user: "" },
    },
  ],
};

class RentComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      input: "",
      inputFrom: "",
    };

    //this.lvlUpPanda = this.lvlUpPanda.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.addRent = this.addRent.bind(this);
    this.handleChangeInputFrom = this.handleChangeInputFrom.bind(this);
    this.addRentFrom = this.addRentFrom.bind(this);
  }

  componentDidMount() { }

  handleChangeInput(event) {
    this.setState({
      input: event.target.value,
    });
  }

  handleChangeInputFrom(event) {
    this.setState({
      inputFrom: event.target.value,
    });
  }

  // отправка панды в путишествие
  addRent(add_user) {
    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    addRentTrans.actions[0].authorization[0].actor = userName;
    addRentTrans.actions[0].data.username = userName;
    addRentTrans.actions[0].data.add_user = add_user;
    console.log(addRentTrans);

    activeUser
      .signTransaction(addRentTrans, { blocksBehind: 3, expireSeconds: 30 })
      .then((response) => {
        if (response.status === "executed") {
          //this.props.changeStakeSumMinus(asset_id, parseInt(moneysum));
          this.setState({
            input: "",
          });
          console.log(response.status);
        }
      });
  }

  // отправка панды в путишествие
  addRentFrom(add_user) {
    const userName = this.props.ual.activeUser.accountName;
    const activeUser = this.props.ual.activeUser;
    addRentTransFrom.actions[0].authorization[0].actor = userName;
    addRentTransFrom.actions[0].data.username = userName;
    addRentTransFrom.actions[0].data.add_user = add_user;
    console.log(addRentTransFrom);

    activeUser
      .signTransaction(addRentTransFrom, { blocksBehind: 3, expireSeconds: 30 })
      .then((response) => {
        if (response.status === "executed") {
          //this.props.changeStakeSumMinus(asset_id, parseInt(moneysum));
          this.setState({
            inputFrom: "",
          });
          console.log(response.status);
        }
      });
  }

  render() {
    return (
      <>
        <Menu  {...this.props.ual} wax={this.props.balanceWax} bam={this.props.balanceBam} game={this.props.balanceGame}  updateBalance={this.props.updateBalance} />
        <main className="soon-panda"></main>
        {/* <main className="other-page">
                <section id="list-all-panda">
                    <div className="bamb-tree">
                        <div className="bamb-tree-in">
                            <div className="bamb-tree-top"></div>
                            <div className="bamb-tree-bot"></div>
    
                            <div className="bamb-tree-wrap">
                                <div className="top-card-list">
                                    <div className="tit-list">
                                        Give the user the rights to use my pandas
                                    </div>
    
                                    <div className="top-card-list-in on-game">
                                        <Rent 
                                        handleChangeInput={this.handleChangeInput} 
                                        addRent={this.addRent} 
                                        input={this.state.input} 
                                        />
                                    </div>
    
                                </div>
                                
                                
                                <div className="bot-card-list">
                                    <div className="tit-list">
                                        I rent pandas from another user
                                    </div>
    
                                    <div className="top-card-list-in on-game">
                                        <Rentfrom 
                                        handleChangeInputFrom={this.handleChangeInputFrom} 
                                        addRentFrom={this.addRentFrom} 
                                        inputFrom={this.state.inputFrom} 
                                        />
                                    </div>
    
                                </div>

                            </div>
                        </div>
                    </div>
                
                </section>
           </main> */}
      </>
    );
  }
}

let mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(RentComponent);
