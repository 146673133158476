export const chooseWeaponTmpl = function (tmpl) {
    switch (tmpl) {
        // axe
        case 373391:
        case 373433:
        case 373448:
        case 373457:
            return "Axe-Common";
        case 376750:
        case 376762:
        case 376770:
        case 376776:
            return "Axe-Uncommon"
        case 376753:
        case 376763:
        case 376772:
        case 376777:
            return "Axe-Rare"
        case 376755:
        case 376767:
        case 376773:
        case 376787:
            return "Axe-Epic"
        case 376756:
        case 376768:
        case 376774:
        case 376794:
            return "Axe-Legendary"
        case 376760:
        case 376769:
        case 376775:
        case 376795:
            return "Axe-Mythic"
        // end axe

        // bow
        case 373659:
        case 373669:
        case 373682:
        case 373736:
            return "Bow-Common"
        case 376991:
        case 376997:
        case 377002:
        case 377019:
            return "Bow-Uncommon"
        case 376993:
        case 376998:
        case 377009:
        case 377283:
            return "Bow-Rare"
        case 376994:
        case 376999:
        case 377011:
        case 377285:
            return "Bow-Epic"
        case 376995:
        case 377000:
        case 377012:
        case 377286:
            return "Bow-Legendary"
        case 376996:
        case 377001:
        case 377015:
        case 377287:
            return "Bow-Mythic"
        // end bow

        // Claws
        case 374507:
        case 374515:
        case 374525:
        case 374535:
            return "Claws-Common"
        case 378777:
        case 378783:
        case 378790:
        case 378799:
            return "Claws-Uncommon"
        case 378778:
        case 378784:
        case 378791:
        case 378801:
            return "Claws-Rare"
        case 378779:
        case 378785:
        case 378794:
        case 378810:
            return "Claws-Epic"
        case 378780:
        case 378787:
        case 378795:
        case 378815:
            return "Claws-Legendary"
        case 378781:
        case 378789:
        case 378796:
        case 378821:
            return "Claws-Mythic"
        // end Claws

        // Dagger
        case 374544:
        case 374570:
        case 374590:
        case 374596:
            return "Dagger-Common"
        case 378165:
        case 378290:
        case 378302:
        case 378316:
            return "Dagger-Uncommon"
        case 378171:
        case 378292:
        case 378305:
        case 378317:
            return "Dagger-Rare"
        case 378174:
        case 378295:
        case 378307:
        case 378320:
            return "Dagger-Epic"
        case 378176:
        case 378296:
        case 378311:
        case 378322:
            return "Dagger-Legendary"
        case 378178:
        case 378299:
        case 378313:
        case 378323:
            return "Dagger-Mythic"
        // end Dagger

        // Gun
        case 374606:
        case 374615:
        case 374640:
        case 374653:
            return "Gun-Common"
        case 382639:
        case 382672:
        case 382685:
        case 382702:
            return "Gun-Uncommon"
        case 382643:
        case 382675:
        case 382688:
        case 382709:
            return "Gun-Rare"
        case 382647:
        case 382678:
        case 382691:
        case 382714:
            return "Gun-Epic"
        case 382670:
        case 382680:
        case 382693:
        case 382724:
            return "Gun-Legendary"
        case 382671:
        case 382684:
        case 382698:
        case 382726:
            return "Gun-Mythic"
        // end Gun

        // Hammer
        case 375234:
        case 375243:
        case 376145:
        case 376151:
            return "Hammer-Common"
        case 377469:
        case 377476:
        case 377486:
        case 377492:
            return "Hammer-Uncommon"
        case 377472:
        case 377477:
        case 377487:
        case 378015:
            return "Hammer-Rare"
        case 377473:
        case 377481:
        case 377488:
        case 378017:
            return "Hammer-Epic"
        case 377474:
        case 377483:
        case 377489:
        case 378023:
            return "Hammer-Legendary"
        case 377475:
        case 377484:
        case 377490:
        case 378025:
            return "Hammer-Mythic"
        // end Hammer

        // MechanicalArm
        case 376156:
        case 376162:
        case 376172:
        case 376178:
            return "MechanicalArm-Common"
        case 382574:
        case 382580:
        case 382604:
        case 382621:
            return "MechanicalArm-Uncommon"
        case 382575:
        case 382581:
        case 382609:
        case 382623:
            return "MechanicalArm-Rare"
        case 382577:
        case 382588:
        case 382613:
        case 382627:
            return "MechanicalArm-Epic"
        case 382578:
        case 382591:
        case 382616:
        case 382628:
            return "MechanicalArm-Legendary"
        case 382579:
        case 382594:
        case 382620:
        case 382629:
            return "MechanicalArm-Mythic"
        // end MechanicalArm

        // Slingshot
        case 376198:
        case 376204:
        case 376302:
        case 376310:
            return "Slingshot-Common"
        case 379070:
        case 380271:
        case 380277:
        case 382044:
            return "Slingshot-Uncommon"
        case 379072:
        case 380272:
        case 380280:
        case 382046:
            return "Slingshot-Rare"
        case 380265:
        case 380273:
        case 380281:
        case 382050:
            return "Slingshot-Epic"
        case 380268:
        case 380275:
        case 380284:
        case 382052:
            return "Slingshot-Legendary"
        case 380270:
        case 380276:
        case 380286:
        case 382054:
            return "Slingshot-Mythic"
        // end Slingshot

        // Stick
        case 376320:
        case 376329:
        case 376374:
        case 376407:
            return "Stick-Common"
        case 377302:
        case 377347:
        case 377365:
        case 377387:
            return "Stick-Uncommon"
        case 377303:
        case 377352:
        case 377368:
        case 377392:
            return "Stick-Rare"
        case 377304:
        case 377355:
        case 377373:
        case 377397:
            return "Stick-Epic"
        case 377306:
        case 377359:
        case 377377:
        case 377401:
            return "Stick-Legendary"
        case 377307:
        case 377362:
        case 377381:
        case 377404:
            return "Stick-Mythic"
        // end Stick

        // Sword
        case 376557:
        case 376570:
        case 376577:
        case 376582:
            return "Sword-Common"
        case 378029:
        case 378037:
        case 378080:
        case 378087:
            return "Sword-Uncommon"
        case 378030:
        case 378038:
        case 378081:
        case 378088:
            return "Sword-Rare"
        case 378031:
        case 378040:
        case 378083:
        case 378089:
            return "Sword-Epic"
        case 378034:
        case 378075:
        case 378084:
        case 378090:
            return "Sword-Legendary"
        case 378036:
        case 378077:
        case 378085:
        case 378091:
            return "Sword-Mythic"
        // end Sword

        default:
            return "NOBADY"
    }
}

export const chooseDetalArmor = function (tmpl) {
    var nameRarArmor;
    var nameArmor;
    switch (tmpl) {
        case 549570: case 549569: case 549572: case 549568: case 549575: case 549574: case 549576: case 549573: case 549582: case 549580: case 549583:
        case 549579: case 549587: case 549586: case 549588: case 549585: case 549592: case 549590: case 549593: case 549589: case 549601: case 549599:
        case 549603: case 549598: case 549845: case 549844: case 549846: case 549843: case 549850: case 549849: case 549851: case 549848: case 549854:
        case 549853: case 549855: case 549852: case 549860: case 549859: case 549861: case 549857: case 549866: case 549864: case 549867: case 549863:
        case 549870: case 549869: case 549871: case 549868: case 549877: case 549876: case 549878: case 549874: case 549882: case 549880: case 549884:
        case 549879: case 549890: case 549889: case 549892: case 549887:
            nameRarArmor = "Common"
            break;
        case 550246: case 550244: case 550247: case 550243: case 550251: case 550250: case 550252: case 550249: case 550256: case 550255: case 550257:
        case 550253: case 550269: case 550268: case 550270: case 550266: case 550439: case 550438: case 550440: case 550437: case 550609: case 550608:
        case 550610: case 550607: case 550640: case 550639: case 550642: case 550638: case 550652: case 550651: case 550653: case 550649: case 550662:
        case 550661: case 550663: case 550660: case 550667: case 550666: case 550668: case 550665: case 550674: case 550672: case 550675: case 550671:
        case 551011: case 551010: case 551013: case 551008: case 551028: case 551027: case 551029: case 551026: case 551037: case 551035: case 551039:
        case 551034: case 551222: case 551221: case 551223: case 551220:
            nameRarArmor = "Uncommon"
            break;
        case 551825: case 551824: case 551826: case 551823: case 551862: case 551861: case 551863: case 551860: case 551946: case 551944: case 551947:
        case 551943: case 552241: case 552240: case 552244: case 552239: case 552257: case 552255: case 552260: case 552251: case 552269: case 552268:
        case 552270: case 552267: case 552276: case 552275: case 552278: case 552274: case 552511: case 552509: case 552513: case 552507: case 552533:
        case 552532: case 552534: case 552530: case 552538: case 552537: case 552539: case 552536: case 552555: case 552554: case 552556: case 552551:
        case 552563: case 552562: case 552564: case 552561: case 552567: case 552566: case 552568: case 552565: case 552572: case 552571: case 552574:
        case 552570: case 552578: case 552577: case 552580: case 552576: case 552253:
            nameRarArmor = "Rare"
            break;
        case 552775: case 552774: case 552776: case 552773: case 552781: case 552780: case 552782: case 552779: case 552820: case 552819: case 552821:
        case 552818: case 552825: case 552824: case 552826: case 552823: case 552853: case 552852: case 552855: case 552851: case 556260: case 556259:
        case 556264: case 556258: case 556372: case 556371: case 556373: case 556370: case 556377: case 556376: case 556378: case 556375: case 556414:
        case 556412: case 556418: case 556410: case 556470: case 556467: case 556471: case 556466: case 556477: case 556476: case 556478: case 556475:
        case 556611: case 556609: case 556612: case 556608: case 556623: case 556622: case 556624: case 556621: case 556628: case 556627: case 556629:
        case 556626: case 556632: case 556631: case 556633: case 556630:
            nameRarArmor = "Epic"
            break;
        case 582961: case 582960: case 582963: case 582959: case 582966: case 582965: case 582967: case 582964: case 583000: case 582999: case 583001:
        case 582998: case 583004: case 583003: case 583005: case 583002: case 583249: case 583248: case 583251: case 583247: case 583254: case 583253:
        case 583255: case 583252: case 583258: case 583257: case 583259: case 583256: case 583262: case 583261: case 583264: case 583260: case 583267:
        case 583266: case 583269: case 583265: case 583558: case 583557: case 583560: case 583556: case 583563: case 583562: case 583564: case 583561:
        case 583567: case 583566: case 583568: case 583565: case 583607: case 583603: case 583608: case 583600: case 583856: case 583855: case 583857:
        case 583854: case 583864: case 583863: case 583865: case 583862:
            nameRarArmor = "Legendary"
            break;
        case 595073: case 595072: case 595074: case 595066: case 595077: case 595076: case 595078: case 595075: case 595081: case 595080: case 595082:
        case 595079: case 595085: case 595084: case 595086: case 595083: case 595092: case 595091: case 595093: case 595090: case 595097: case 595096:
        case 595098: case 595095: case 595101: case 595100: case 595102: case 595099: case 595105: case 595104: case 595106: case 595103: case 595110:
        case 595109: case 595111: case 595108: case 595114: case 595113: case 595115: case 595112: case 595120: case 595117: case 595122: case 595116:
        case 595126: case 595125: case 595127: case 595124: case 595176: case 595175: case 595177: case 595174: case 595181: case 595180: case 595182:
        case 595179: case 595187: case 595184: case 595188: case 595183:
            nameRarArmor = "Mythic"
            break;
        // end axe
        default:
            nameRarArmor = "NOBADY"
            break;
    }

    switch (tmpl) {
        case 549570: case 550246: case 551825: case 552775: case 582961: case 595073: case 549569: case 550244: case 551824: case 552774: case 582960: case 595072:
        case 549572: case 550247: case 551826: case 552776: case 582963: case 595074: case 549568: case 550243: case 551823: case 552773: case 582959: case 595066:
        case 552253:
            nameArmor = "Archer"
            break;
        /////
        case 549575: case 550251: case 551862: case 552781: case 582966: case 595077: case 549574: case 550250: case 551861: case 552780: case 582965: case 595076:
        case 549576: case 550252: case 551863: case 552782: case 582967: case 595078: case 549573: case 550249: case 551860: case 552779: case 582964: case 595075:
            nameArmor = "Bard"
            break;
        /////
        case 549582: case 550256: case 551946: case 552820: case 583000: case 595081: case 549580: case 550255: case 551944: case 552819: case 582999: case 595080:
        case 549583: case 550257: case 551947: case 552821: case 583001: case 595082: case 549579: case 550253: case 551943: case 552818: case 582998: case 595079:
            nameArmor = "Berserker"
            break;
        /////
        case 549587: case 550269: case 552241: case 552825: case 583004: case 595085: case 549586: case 550268: case 552240: case 552824: case 583003: case 595084:
        case 549588: case 550270: case 552244: case 552826: case 583005: case 595086: case 549585: case 550266: case 552239: case 552823: case 583002: case 595083:
            nameArmor = "Dreneya"
            break;
        /////
        case 549592: case 550439: case 552257: case 552853: case 583249: case 595092: case 549590: case 550438: case 552255: case 552852: case 583248: case 595091:
        case 549593: case 550440: case 552260: case 552855: case 583251: case 595093: case 549589: case 550437: case 552251: case 552851: case 583247: case 595090:
            nameArmor = "Druid"
            break;
        /////
        case 549601: case 550609: case 552269: case 556260: case 583254: case 595097: case 549599: case 550608: case 552268: case 556259: case 583253: case 595096:
        case 549603: case 550610: case 552270: case 556264: case 583255: case 595098: case 549598: case 550607: case 552267: case 556258: case 583252: case 595095:
            nameArmor = "Elder"
            break;
        /////
        case 549845: case 550640: case 552276: case 556372: case 583258: case 595101: case 549844: case 550639: case 552275: case 556371: case 583257: case 595100:
        case 549846: case 550642: case 552278: case 556373: case 583259: case 595102: case 549843: case 550638: case 552274: case 556370: case 583256: case 595099:
            nameArmor = "Engineer"
            break;
        /////
        case 549850: case 550652: case 552511: case 556377: case 583262: case 595105: case 549849: case 550651: case 552509: case 556376: case 583261: case 595104:
        case 549851: case 550653: case 552513: case 556378: case 583264: case 595106: case 549848: case 550649: case 552507: case 556375: case 583260: case 595103:
            nameArmor = "Healer"
            break;
        /////
        case 549854: case 550662: case 552533: case 556414: case 583267: case 595110: case 549853: case 550661: case 552532: case 556412: case 583266: case 595109:
        case 549855: case 550663: case 552534: case 556418: case 583269: case 595111: case 549852: case 550660: case 552530: case 556410: case 583265: case 595108:
            nameArmor = "Mage"
            break;
        /////
        case 549860: case 550667: case 552538: case 556470: case 583558: case 595114: case 549859: case 550666: case 552537: case 556467: case 583557: case 595113:
        case 549861: case 550668: case 552539: case 556471: case 583560: case 595115: case 549857: case 550665: case 552536: case 556466: case 583556: case 595112:
            nameArmor = "Paladin"
            break;
        /////
        case 549866: case 550674: case 552555: case 556477: case 583563: case 595120: case 549864: case 550672: case 552554: case 556476: case 583562: case 595117:
        case 549867: case 550675: case 552556: case 556478: case 583564: case 595122: case 549863: case 550671: case 552551: case 556475: case 583561: case 595116:
            nameArmor = "Priest"
            break;
        /////
        case 549870: case 551011: case 552563: case 556611: case 583567: case 595126: case 549869: case 551010: case 552562: case 556609: case 583566: case 595125:
        case 549871: case 551013: case 552564: case 556612: case 583568: case 595127: case 549868: case 551008: case 552561: case 556608: case 583565: case 595124:
            nameArmor = "Shooter"
            break;
        /////
        case 549877: case 551028: case 552567: case 556623: case 583607: case 595176: case 549876: case 551027: case 552566: case 556622: case 583603: case 595175:
        case 549878: case 551029: case 552568: case 556624: case 583608: case 595177: case 549874: case 551026: case 552565: case 556621: case 583600: case 595174:
            nameArmor = "Smith"
            break;
        /////
        case 549882: case 551037: case 552572: case 556628: case 583856: case 595181: case 549880: case 551035: case 552571: case 556627: case 583855: case 595180:
        case 549884: case 551039: case 552574: case 556629: case 583857: case 595182: case 549879: case 551034: case 552570: case 556626: case 583854: case 595179:
            nameArmor = "Tauren"
            break;
        /////
        case 549890: case 551222: case 552578: case 556632: case 583864: case 595187: case 549889: case 551221: case 552577: case 556631: case 583863: case 595184:
        case 549892: case 551223: case 552580: case 556633: case 583865: case 595188: case 549887: case 551220: case 552576: case 556630: case 583862: case 595183:
            nameArmor = "Warrior"
            break;
        default:
            nameArmor = "NOBADY"
            break;
    }

    return {
        nameRarArmor: nameRarArmor,
        nameArmor: nameArmor
    }
}

export const getRank = function (lvlLoop) {
    switch (lvlLoop) {
        case 0:
            return "Rookie"
        case 1:
            return "Officer-cadet"
        case 2:
            return "Second-Lieutenant"
        case 3:
            return "Lieutenant"
        case 4:
            return "Captain"
        case 5:
            return "Major"
        case 6:
            return "Lieutenant-Colonel"
        case 7:
            return "Colonel"
        case 8:
            return "Brigadier"
        case 9:
            return "Major-General"
        case 10:
            return "Lieutenant-General"
        case 11:
            return "General"
        case 12:
            return "legend"
        default:
            return "NOBADY"
    }
}

export const getNameRar = function (pandaRar) {
    switch (pandaRar) {
        case 0:
            return "Common"
        case 1:
            return "Promo"
        case 2:
            return "Uncommon"
        case 3:
            return "Rare"
        case 4:
            return "Epic"
        case 5:
            return "Legendary"
        case 6:
            return "Mythic"
        default:
            return "NOBADY"
    }
}

export const detalPanda = function (item, index, pandaSlots) {

    //console.log("item", item);
    //console.log("index", index);
    //console.log("pandaSlots", pandaSlots);
    var onePanda = item;
    var onePandaName;
    var oneWeapon;
    var nameWeapon;
    var armorDetal;
    var oneJew;
    var oneArmor;
    var showBtLvlUp = false;

    if (pandaSlots && pandaSlots.length) {
        for (let i = 0; i < pandaSlots.length; i++) {
            if (item === pandaSlots[i].asset_id) {
                onePanda = pandaSlots[i];
                onePandaName = onePanda.name_pa;
                onePandaName = onePandaName.substring(onePandaName.indexOf("-") + 1)
            }
        }
    } else if (index === -1) {
        onePanda = pandaSlots;
        onePandaName = onePanda.name_pa;
        onePandaName = onePandaName.substring(onePandaName.indexOf("-") + 1)
    }

    if (onePanda.weapon) {
        oneWeapon = onePanda.weapon;
        nameWeapon = chooseWeaponTmpl(oneWeapon.tmpl);
    }

    if (onePanda.armor) {
        oneArmor = onePanda.armor;
        armorDetal = chooseDetalArmor(oneArmor.tmpl);
    }

    if (onePanda.jew) {
        oneJew = onePanda.jew;
    }

    if (onePanda.armor) {
        oneArmor = onePanda.armor;
    }

    if (
        onePanda.lvl === 0 && onePanda.steps >= 8 ||
        onePanda.lvl === 1 && onePanda.steps >= 13 ||
        onePanda.lvl === 2 && onePanda.steps >= 21 ||
        onePanda.lvl === 3 && onePanda.steps >= 34 ||
        onePanda.lvl === 4 && onePanda.steps >= 55 ||
        onePanda.lvl === 5 && onePanda.steps >= 89 ||
        onePanda.lvl === 6 && onePanda.steps >= 144 ||
        onePanda.lvl === 7 && onePanda.steps >= 233 ||
        onePanda.lvl === 8 && onePanda.steps >= 377 ||
        onePanda.lvl === 9 && onePanda.steps >= 610 ||
        onePanda.lvl === 10 && onePanda.steps >= 987 ||
        onePanda.lvl === 11 && onePanda.steps >= 1597) {
        showBtLvlUp = true;
    }
    var pandaRar = onePanda.rarity;  // (NFT) rare
    var pandaLvl = onePanda.lvl; // (РАНГ) 8.67 далдно быть застейкано
    var pandaSt = onePanda.stake_count; // берес количество застейканого бамам на панде
    var pandaStake = {
        "0": [0, 8700, 22900, 45800, 82900, 142900, 240000, 397100, 651300, 1062600, 1728100, 2804900, 4547100],
        "1": [0, 14400, 37800, 75600, 136800, 235800, 395800, 655000, 1074400, 1753000, 2851000, 4627600, 7502200],
        "2": [0, 17700, 46600, 93300, 168900, 291100, 488900, 808900, 1326700, 2164500, 3520100, 5713500, 9262400],
        "3": [0, 48000, 126000, 252000, 456000, 786000, 1320000, 2184000, 3582000, 5844000, 9504000, 15426000, 25008000],
        "4": [0, 80000, 210000, 420000, 760000, 1310000, 2200000, 3640000, 5970000, 9740000, 15840000, 25710000, 41680000],
        "5": [0, 320000, 840000, 1680000, 3040000, 5240000, 8800000, 14560000, 23880000, 38960000, 63360000, 102840000, 166720000],
        "6": [0, 960000, 2520000, 5040000, 9120000, 15720000, 26400000, 43680000, 71640000, 116880000, 190080000, 308520000, 500160000]
    }
    var howmcount = pandaStake[pandaRar][pandaLvl];
    var youmastadd = (howmcount - pandaSt) / 10000; // вы должны добавитть бам
    var lvlLoop = pandaLvl; // левел для цыкла

    for (var i = pandaLvl; i >= 0; i--) {
        //howmcount = pandaBamsum * (props.resultRew.steps[lvlLoop]) * percStake; 
        if (showBtLvlUp) {
            howmcount = pandaStake[pandaRar][pandaLvl]
        }
        else {
            howmcount = pandaStake[pandaRar][pandaLvl]
        }

        if (pandaSt < howmcount) {
            lvlLoop = i;
        }
    }
    var errorBamToLvlUp;
    if (showBtLvlUp) {
        howmcount = pandaStake[pandaRar][pandaLvl + 1]
        errorBamToLvlUp = howmcount;
        errorBamToLvlUp = errorBamToLvlUp
    }
    var nameRare = getRank(lvlLoop);
    var nameRarePanda = getNameRar(pandaRar);
    var energy = onePanda.energy / 100;
    var countBamb = 10;

    if (energy >= 100) {
        countBamb = 100;
    } else if (energy >= 90) {
        countBamb = 90;
    } else if (energy >= 80) {
        countBamb = 80;
    } else if (energy >= 70) {
        countBamb = 70;
    } else if (energy >= 60) {
        countBamb = 60;
    } else if (energy >= 50) {
        countBamb = 50;
    } else if (energy >= 40) {
        countBamb = 40;
    } else if (energy >= 30) {
        countBamb = 30;
    } else if (energy >= 20) {
        countBamb = 20;
    } else if (energy >= 10) {
        countBamb = 10;
    } else {
        countBamb = 0;
    }

    var weaponElement = oneWeapon && oneWeapon.element;
    var jewElement = oneJew && oneJew.element;
    var armorElement = oneArmor && oneArmor.element;

    var exp;
    if (lvlLoop !== pandaLvl) {
        exp = true;
    } else {
        exp = false;
    }

    var timer = false;
    if (Date.now() < onePanda.timer * 1000) {
        timer = true;
    }

    var cardRare;
    if (onePandaName.substring(0, 1) == "A") {
        cardRare = "serial-1-"
    } else if (onePandaName.substring(0, 1) == "N") {
        cardRare = "serial-2-"
    } else if (onePandaName.substring(0, 1) == "P") {
        cardRare = "serial-3-"
    }

    var imgRare = nameRarePanda.toLowerCase();
    if (imgRare == "promo") {
        imgRare = "common";
    }

    var forSkillWindow = {
        name_pa: onePanda.name_pa,
        onePandaName: onePandaName,
        cardRare: cardRare,
        imgRare: imgRare,
        element: onePanda.element,
        slot: index,
        pandaRar: pandaRar,
    }

    if (oneWeapon) {
        forSkillWindow.weaponElement = weaponElement;
        forSkillWindow.oneWeaponRarity = oneWeapon.rarity;
        forSkillWindow.nameWeapon = nameWeapon;
    }

    if (oneJew) {
        forSkillWindow.jewElement = jewElement;
        forSkillWindow.oneJewRarity = oneJew.rarity;
        forSkillWindow.oneJewTmpl = oneJew.tmpl;
    }


    if (oneArmor) {
        forSkillWindow.armorElement = armorElement;
        forSkillWindow.oneArmorRarity = oneArmor.rarity;
        forSkillWindow.nameArmor = armorDetal.nameArmor;
        forSkillWindow.nameRarArmor = armorDetal.nameRarArmor;
    }

    return {
        onePanda: onePanda,
        timer: timer,
        oneWeapon: oneWeapon,
        oneArmor: oneArmor,
        oneJew: oneJew,
        imgRare: imgRare,
        cardRare: cardRare,
        energy: energy,
        countBamb: countBamb,
        nameRare: nameRare,
        youmastadd: youmastadd,
        nameWeapon: nameWeapon,
        weaponElement: weaponElement,
        jewElement: jewElement,
        armorElement: armorElement,
        forSkillWindow: forSkillWindow,
        nameRarePanda: nameRarePanda,
        nameRare: nameRare,

        onePandaName: onePandaName,
        armorDetal: armorDetal,
        showBtLvlUp: showBtLvlUp,
        armorDetal: armorDetal,
        errorBamToLvlUp: errorBamToLvlUp,
        exp: exp,
        pandaLvl: pandaLvl
    }
}
