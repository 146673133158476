import React from 'react';
import modaltop from '../../images/shop/modal-top.png';
import modalbot from '../../images/shop/modal-bot.png';

const ModalMultiJew = (props) => {
    

    return (
    <>
       
        <div id="thank-modal-shop" className={`modal-bay modal-weapon open anim`}>
            <div className="modal-bay-body">
                <div className="modal-bay-body-in">

                    <div className="modal-bay-head">
                        
                        <div className="modal-bay-top">
                            <button className="modal-bay-close" onClick={() => props.closeThenk()}><span></span></button>
                            <img src={modaltop} alt="" />
                        </div>
                        <div className="modal-bay-wrap jew-mod-part-rep">
                            
                            <div className="modal-bay-tit tit-err-f">
                                multi-jewelry repair for all selected heroes
                            </div>

                            <div className="total-sum mult-en">
                                <div className="total-sum-top">
                                    total price
                                </div>
                                <div className="total-sum-body">
                                    <span>
                                        {props.showModalMultiSum / 10000}
                                    </span>
                                </div>
                            </div>

                            <div className="ico-weap-energy">
                                
                                <button className="def-bt mid" onClick={(e) => {
                                    props.addEnergyMultiJewTrans(props.showModalMultiSum / 10000);
                                }}>
                                    <span className="button-in">
                                        <span className="button-name">
                                            <span className="button-name-in">
                                                <span className="button-name-value">
                                                    CONFIRM
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </button>
                            </div>

                       
                        </div>
                        <div className="modal-bay-bot">
                            <img src={modalbot} alt="" />
                        </div>
                        
                    </div>
                </div>
                <div className="modal-bay-bg-trans" onClick={() => props.closeThenk()}></div>
                
            </div>
            <div className="modal-bay-bg" ></div>
            
        </div>
    </>);
}

export default ModalMultiJew;