import React from 'react';

const UpgradeHomeLoop = (props) => {
   
    return (<>
            { props.allUserWeapon.length ? props.allUserWeapon.map((item, index) => {
                //console.log('item[0]', item[0]);
                if (index < props.activePage * 8 && index >= (props.activePage - 1) * 8 ) {
                    return (
                        <div key={index} className={`one-upgr-prod ${item[0].data.rarity}`}>
                            <div className="one-upgr-prod-info tooltip">
                                <div className="cust-tooltip right">
                                    <div className="tool-arrow"></div>
                                    <div className="tool-wrap">
                                        <div className="tool-wrap-1">
                                            <div className="tool-wrap-2">
                                                <div className="tool-body">
                                                    <span>{item[0].data.name}</span> <br />
                                                    {item[0].data.rarity}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`one-upgr-prod-element tooltip ${item[0].data.element}` }>
                                <div className="cust-tooltip right">
                                    <div className="tool-arrow"></div>
                                    <div className="tool-wrap">
                                        <div className="tool-wrap-1">
                                            <div className="tool-wrap-2">
                                                <div className="tool-body">
                                                    {item[0].data.element}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="one-upgr-prod-thumb">
                                <a></a>
                                <span></span>
                                <div className="one-upgr-prod-thumb-in">
                                    {item[0].schema.schema_name == 'jewelry' ?
                                    <picture>
                                        <source srcSet={`./images/jew/inventory/${item[0].template.template_id}.webp`} type="image/webp" />
                                        <source srcSet={`./images/jew/inventory/${item[0].template.template_id}.png`} type="image/png" /> 
                                        <img src={`./images/jew/inventory/${item[0].template.template_id}.png`} alt="" />
                                    </picture>
                                    : 
                                    item[0].schema.schema_name == 'weapon' ?
                                    <picture>
                                        <source srcSet={`./images/weapons/inventory/${item[0].template.template_id}.webp`} type="image/webp" />
                                        <source srcSet={`./images/weapons/inventory/${item[0].template.template_id}.png`} type="image/png" /> 
                                        <img src={`./images/weapons/inventory/${item[0].template.template_id}.png`} alt="" />
                                    </picture>
                                    :
                                    <picture>
                                        <source srcSet={`./images/armor/inventory/${item[0].template.template_id}.webp`} type="image/webp" />
                                        <source srcSet={`./images/armor/inventory/${item[0].template.template_id}.png`} type="image/png" /> 
                                        <img src={`./images/armor/inventory/${item[0].template.template_id}.png`} alt="" />
                                    </picture>
                                    }
                                    <i></i>
                                    <i className='b'></i>
                                </div>
                                
                                <div className="value-craft-weapon">
                                    {item.length}
                                </div>
                                <div className="hover-bt-upg">
                                    <button className="def-bt mini" onClick={(e) => {props.openRecipe(index, item[0].template.template_id)}}>
                                        <span className="button-in">
                                            <span className="button-name">
                                                <span className="button-name-in">
                                                    <span className="button-name-value">
                                                        UPGRADE
                                                    </span> 
                                                </span>
                                            </span>
                                        </span>
                                    </button>
                                </div>
                            </div>

                            <div className="loading-block"></div>
                            
                        </div>
                       
                    );
                }
                
            }) : null}
        
        </>);
}

export default UpgradeHomeLoop;