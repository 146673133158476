import React from 'react';

const Calculator = (props) => {


    var pandaRar = 1;  // (NFT) rare
    var pandaLvl = 2; // (РАНГ) 8.67 далдно быть застейкано

    var pandaRarity = props.numberNft[pandaRar] / props.allCardCount; // редкость
    var pandaBamsum = props.baz * (1 / pandaRarity); // награда за поход

    var pandaProfit = pandaBamsum + (pandaBamsum * props.rangPercProfit[pandaLvl]);

    //13.333 * 0.13
    return (<>
        <br />
        {/* Count PANDAS with rar {pandaRar} in the world - {props.numberNft[pandaRar]} <br />
        Rarity by table - {pandaRarity} <br />
        Если ушла панда с рарность {pandaRar}" и с рангом(лвл) {pandaLvl} <br />

        Тогда у нее автоматом от рарности профит "Награда за поход" 0,667 ({pandaBamsum.toFixed(3)}) БАМ + ({props.rangPercProfit[pandaLvl]}) за LVL <br />
        то она получит ({pandaProfit.toFixed(4)}) {process.env.REACT_APP_VAL} */}

        <table>
            <tr>
                <th>
                    rar
                </th>
                <th>
                    count
                </th>
                <th>
                    rarity percent
                </th>
                <th>
                    {process.env.REACT_APP_VAL} Profit
                </th>
            </tr>
            {props.rarNft.map((s, index) => {
                var rarName; // имя рарности
                var rarity; // редкость в процентах
                var bamsum; // Сколько получает награды панда
                switch (s) {
                    case 0:
                        rarName = 'common';
                        break;
                    case 1:
                        rarName = 'promo';
                        break;
                    case 2:
                        rarName = 'uncommon';
                        break;
                    case 3:
                        rarName = 'rare';
                        break;
                    case 4:
                        rarName = 'epic';
                        break;
                    case 5:
                        rarName = 'legendary';
                        break;
                    case 6:
                        rarName = 'mythic';
                        break;
                    default:
                    //alert( "NOOOOO" );
                }

                rarity = props.numberNft[s] / props.allCardCount; // редкость
                bamsum = props.baz * (1 / rarity); // награда за поход

                return (
                    <tr key={index}>
                        <td>
                            {rarName}
                        </td>
                        <td>
                            {props.numberNft[s]}
                        </td>
                        <td>
                            {rarity}
                        </td>
                        <td>
                            {bamsum.toFixed(4)}
                        </td>
                    </tr>
                )
            }
            )}
        </table>


        <div className="staking-title">
            <br /><br />
            RANG & STAKE
        </div>
        <div className="staking-descr">
            panda profit
        </div>

        <table>
            <tr>
                <th>

                </th>
                {props.rang.map((s, index) => {
                    return (
                        <th key={index}>
                            rang {s}
                        </th>
                    )
                }
                )}
            </tr>
            <tr>
                <td>
                    steps
                </td>
                {props.steps.map((s, index) => {
                    return (
                        <td key={index}>
                            {s}
                        </td>
                    )
                }
                )}
            </tr>
            <tr>
                {props.steps.map((s, index) => {
                    return (
                        <td key={index}>
                            -----
                        </td>
                    )
                }
                )}
            </tr>
            {props.rarNft.map((s, index) => {
                var rarName; // имя рарности
                var rarity; // редкость в процентах
                var bamsum; // Сколько получает награды панда
                var percStake = props.percStake; // Какой процент от заработанных токенов нужно стейкать
                var stakingSum; // Сколько нужно застейкать чтобы перейти на другой ранг
                switch (s) {
                    case 0:
                        rarName = 'common';
                        break;
                    case 1:
                        rarName = 'promo';
                        break;
                    case 2:
                        rarName = 'uncommon';
                        break;
                    case 3:
                        rarName = 'rare';
                        break;
                    case 4:
                        rarName = 'epic';
                        break;
                    case 5:
                        rarName = 'legendary';
                        break;
                    case 6:
                        rarName = 'mythic';
                        break;
                    default:
                    //alert( "NOOOOO" );
                }

                rarity = props.numberNft[s] / props.allCardCount;
                bamsum = props.baz * (1 / rarity); // награджа за бам


                return (
                    <tr key={index}>
                        <td>
                            {rarName}
                        </td>
                        {props.steps.map((s, index) => {
                            stakingSum = bamsum * (props.steps[index]) * percStake;
                            return (
                                <td key={index}>
                                    {stakingSum.toFixed(4)}
                                </td>
                            )
                        }
                        )}
                    </tr>
                )
            }
            )}

            <tr>
                <td>

                </td>
                {props.rangPercProfit.map((s, index) => {
                    return (
                        <td key={index}>
                            {s}
                        </td>
                    )
                }
                )}
            </tr>

        </table>





    </>);
}

export default Calculator;