import React from 'react';
import { BrowserRouter, Redirect, Route, Switch, Link, NavLink } from 'react-router-dom';


import l1 from "../../images/alien/leader-1.png";
import l1w from "../../images/alien/leader-1.webp";
import l2 from "../../images/alien/leader-2.png";
import l2w from "../../images/alien/leader-2.webp";
import l3 from "../../images/alien/leader-3.png";
import l3w from "../../images/alien/leader-3.webp";
import you from "../../images/alien/you.png";
import youw from "../../images/alien/you.webp";
import cr from "../../images/alien/crystals.png";
import crw from "../../images/alien/crystals.webp";
import box from "../../images/alien/box.png";
import boxw from "../../images/alien/box.webp";


const AlienLeaderboard = (props) => {



    return (<>

        <div className="wrap-table">
            <table className='alien-top'>
                <tbody>
                    <tr>
                        <th>
                            Position
                        </th>
                        <th>
                            Player
                        </th>
                        <th>
                            space crystals
                        </th>
                        <th>

                        </th>
                    </tr>
                    {props.tableInfo.map((s, index) => {
                        return (
                            <tr className={`${s.username === props.name ? 'active' : null} ${index < 210 ? 'top100' : null}`} key={index}>
                                <td>
                                    {index == 0 ?
                                        <span>
                                            #{index + 1}
                                            <picture>
                                                <source srcSet={l1w} type="image/webp" />
                                                <source srcSet={l1} type="image/png" />
                                                <img src={l1w} alt="" />
                                            </picture>
                                            {s.username === props.name ?
                                                <i className='tooltip'>
                                                    <picture>
                                                        <source srcSet={youw} type="image/webp" />
                                                        <source srcSet={you} type="image/png" />
                                                        <img src={youw} alt="" />
                                                    </picture>
                                                    <div className="cust-tooltip right">
                                                        <div className="tool-arrow"></div>
                                                        <div className="tool-wrap">
                                                            <div className="tool-wrap-1">
                                                                <div className="tool-wrap-2">
                                                                    <div className="tool-body">
                                                                        Your current position
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </i>
                                                : null}
                                        </span>
                                        : index == 1 ?
                                            <span>
                                                #{index + 1}
                                                <picture>
                                                    <source srcSet={l2w} type="image/webp" />
                                                    <source srcSet={l2} type="image/png" />
                                                    <img src={l2w} alt="" />
                                                </picture>
                                                {s.username === props.name ?
                                                    <i className='tooltip'>
                                                        <picture>
                                                            <source srcSet={youw} type="image/webp" />
                                                            <source srcSet={you} type="image/png" />
                                                            <img src={youw} alt="" />
                                                        </picture>
                                                        <div className="cust-tooltip right">
                                                            <div className="tool-arrow"></div>
                                                            <div className="tool-wrap">
                                                                <div className="tool-wrap-1">
                                                                    <div className="tool-wrap-2">
                                                                        <div className="tool-body">
                                                                            Your current position
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </i>
                                                    : null}
                                            </span>
                                            : index == 2 ?
                                                <span>
                                                    #{index + 1}
                                                    <picture>
                                                        <source srcSet={l3w} type="image/webp" />
                                                        <source srcSet={l3} type="image/png" />
                                                        <img src={l3w} alt="" />
                                                    </picture>
                                                    {s.username === props.name ?
                                                        <i className='tooltip'>
                                                            <picture>
                                                                <source srcSet={youw} type="image/webp" />
                                                                <source srcSet={you} type="image/png" />
                                                                <img src={youw} alt="" />
                                                            </picture>
                                                            <div className="cust-tooltip right">
                                                                <div className="tool-arrow"></div>
                                                                <div className="tool-wrap">
                                                                    <div className="tool-wrap-1">
                                                                        <div className="tool-wrap-2">
                                                                            <div className="tool-body">
                                                                                Your current position
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </i>
                                                        : null}
                                                </span>
                                                :
                                                <span>
                                                    #{index + 1}
                                                    {s.username === props.name ?
                                                        <i className='tooltip'>
                                                            <picture>
                                                                <source srcSet={youw} type="image/webp" />
                                                                <source srcSet={you} type="image/png" />
                                                                <img src={youw} alt="" />
                                                            </picture>
                                                            <div className="cust-tooltip right">
                                                                <div className="tool-arrow"></div>
                                                                <div className="tool-wrap">
                                                                    <div className="tool-wrap-1">
                                                                        <div className="tool-wrap-2">
                                                                            <div className="tool-body">
                                                                                Your current position
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </i>
                                                        : null}
                                                </span>
                                    }
                                </td>
                                <td>
                                    <Link to={`/lk-alien/${s.username}`}>{s.username}</Link>
                                </td>
                                <td>
                                    <span>
                                        {s.allsum / 10000000} <picture>
                                            <source srcSet={crw} type="image/webp" />
                                            <source srcSet={cr} type="image/png" />
                                            <img style={{ marginLeft: "5px" }} width={40} src={crw} alt="" />
                                        </picture>
                                    </span>

                                </td>
                                <td>
                                    {index < 200 ?
                                        <span>
                                            <i className='tooltip'>
                                                <picture>
                                                    <source srcSet={boxw} type="image/webp" />
                                                    <source srcSet={box} type="image/png" />
                                                    <img width={50} src={boxw} alt="" />
                                                </picture>
                                                {index < 200 ?
                                                    <div className="cust-tooltip right">
                                                        <div className="tool-arrow"></div>
                                                        <div className="tool-wrap">
                                                            <div className="tool-wrap-1">
                                                                <div className="tool-wrap-2">
                                                                    <div className="tool-body">
                                                                        <span>{props.priseList[index].sum} MAG</span> <br />
                                                                        <span>{props.priseList[index].voucher} vouchers</span><br />
                                                                        {props.priseList[index].chests ? <><span>{props.priseList[index].chests} chests</span><br /></> : null}
                                                                        <span>{props.priseList[index].nft} special nft</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    // : index > 49 && index < 100 ?
                                                    //     <div className="cust-tooltip right">
                                                    //         <div className="tool-arrow"></div>
                                                    //         <div className="tool-wrap">
                                                    //             <div className="tool-wrap-1">
                                                    //                 <div className="tool-wrap-2">
                                                    //                     <div className="tool-body">


                                                    //                         <span>{props.priseList[50].sum} MAG</span> <br />
                                                    //                         <span>{props.priseList[50].voucher} vouchers</span><br />
                                                    //                         <span>{props.priseList[50].chests} chests</span><br />
                                                    //                         <span>{props.priseList[50].nft} special nft</span>


                                                    //                     </div>
                                                    //                 </div>
                                                    //             </div>
                                                    //         </div>
                                                    //     </div>
                                                    //     :
                                                    //     index > 99 && index < 120 ?
                                                    //         <div className="cust-tooltip right">
                                                    //             <div className="tool-arrow"></div>
                                                    //             <div className="tool-wrap">
                                                    //                 <div className="tool-wrap-1">
                                                    //                     <div className="tool-wrap-2">
                                                    //                         <div className="tool-body">


                                                    //                             <span>350 MAG</span> <br />
                                                    //                             <span>1 special nft</span>


                                                    //                         </div>
                                                    //                     </div>
                                                    //                 </div>
                                                    //             </div>
                                                    //         </div>
                                                    //         :
                                                    //         index > 119 && index < 150 ?
                                                    //             <div className="cust-tooltip right">
                                                    //                 <div className="tool-arrow"></div>
                                                    //                 <div className="tool-wrap">
                                                    //                     <div className="tool-wrap-1">
                                                    //                         <div className="tool-wrap-2">
                                                    //                             <div className="tool-body">


                                                    //                                 <span>200 MAG</span> <br />
                                                    //                                 <span>1 special nft</span>


                                                    //                             </div>
                                                    //                         </div>
                                                    //                     </div>
                                                    //                 </div>
                                                    //             </div>
                                                    //             :
                                                    //             index > 149 && index < 180 ?
                                                    //                 <div className="cust-tooltip right">
                                                    //                     <div className="tool-arrow"></div>
                                                    //                     <div className="tool-wrap">
                                                    //                         <div className="tool-wrap-1">
                                                    //                             <div className="tool-wrap-2">
                                                    //                                 <div className="tool-body">


                                                    //                                     <span>150 MAG</span> <br />
                                                    //                                     <span>1 special nft</span>


                                                    //                                 </div>
                                                    //                             </div>
                                                    //                         </div>
                                                    //                     </div>
                                                    //                 </div>
                                                    //                 :
                                                    //                 index > 179 && index < 200 ?
                                                    //                     <div className="cust-tooltip right">
                                                    //                         <div className="tool-arrow"></div>
                                                    //                         <div className="tool-wrap">
                                                    //                             <div className="tool-wrap-1">
                                                    //                                 <div className="tool-wrap-2">
                                                    //                                     <div className="tool-body">


                                                    //                                         <span>100 MAG</span> <br />
                                                    //                                         <span>1 special nft</span>


                                                    //                                     </div>
                                                    //                                 </div>
                                                    //                             </div>
                                                    //                         </div>
                                                    //                     </div>
                                                    //                     :
                                                    //                     index > 199 && index < 210 ?
                                                    //                         <div className="cust-tooltip right">
                                                    //                             <div className="tool-arrow"></div>
                                                    //                             <div className="tool-wrap">
                                                    //                                 <div className="tool-wrap-1">
                                                    //                                     <div className="tool-wrap-2">
                                                    //                                         <div className="tool-body">


                                                    //                                             <span>50 MAG</span> <br />
                                                    //                                             <span>1 special nft</span>


                                                    //                                         </div>
                                                    //                                     </div>
                                                    //                                 </div>
                                                    //                             </div>
                                                    //                         </div>
                                                    :
                                                    null}
                                            </i>
                                        </span> : null}

                                </td>
                            </tr>
                        )
                    }
                    )}
                </tbody>
            </table>
        </div>

    </>);
}

export default AlienLeaderboard;