import React from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import musicHit from './../../../music/hit1.mp3'
import PandaVideoComp from './../../PandaVideoComp';
var audioMusicHit = new Audio(musicHit);

function backside(num) {
    //console.log(4);
    var items = document.getElementsByClassName('one-list-top');
    items[num].classList.toggle("active");
    if (localStorage.getItem('sound') === 'true') {
        audioMusicHit.currentTime = 0;
        audioMusicHit.volume = 0.1;
        audioMusicHit.play();
    }
}

function filter(rar) {
    //console.log(4);
    var showm = document.querySelector('.filter-top');
    if (rar === "all") {
        var allcard = document.querySelectorAll('.one-list-top');

        for (var i = 0; i < allcard.length; ++i) {
            allcard[i].classList.remove('hide');
        }
    }
    else {
        var allcard = document.querySelectorAll('.one-list-top');
        for (var i = 0; i < allcard.length; ++i) {
            allcard[i].classList.add('hide');
        }
        var showcard = document.querySelectorAll('[data-rar=' + rar + ']');
        for (var i = 0; i < showcard.length; ++i) {
            showcard[i].classList.remove('hide');
        }
    }
    showm.classList.remove("open");
}

function openfilter(rar) {
    //console.log(4);
    var showcard = document.querySelector('.filter-top')
    var items = document.getElementsByClassName(rar);
    showcard.classList.toggle("open");
}

if (localStorage.getItem('wasLogin') === 'false') {
    window.addEventListener('click', function (e) {
        var showcard = document.querySelector('.filter-top')
        if (!showcard.contains(e.target) /*&& !button.contains(e.target)*/) {
            // Ниже код, который нужно выполнить при срабатывании события.
            showcard.classList.remove('open');
        }
    });
}

const Heropagetop = (props) => {
    var rarName = ["Rookie", "Officer-cadet", "Second-Lieutenant", "Lieutenant", "Captain", "Major", "Lieutenant-Colonel", "Colonel", "Brigadier", "Major-General", "Lieutenant-General", "General", "legend"]
    return (<>
        {props.pandaListOnGame.length ?
            <>
                <div className="tit-filt-top">
                    <div className="tit-list">
                        Heroes in game
                    </div>

                    <div className="filter">

                        <div className="filter-in">
                            <div className="filter-tit">
                                filters:
                            </div>
                            <div className="filter-one filter-top">
                                <button className="def-bt min" onClick={(e) => {
                                    openfilter();
                                }}>
                                    <span className="button-in">
                                        <span className="button-name">
                                            <span className="button-name-in">
                                                <span className="button-name-value">
                                                    All heroes
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </button>

                                <div className="filter-drop min">
                                    <div className="filter-drop-in">
                                        <ul>
                                            <li onClick={(e) => {
                                                filter('all');
                                            }}>
                                                All heroes
                                            </li>
                                            <li onClick={(e) => {
                                                filter('Common');
                                            }}>
                                                Common
                                            </li>
                                            <li onClick={(e) => {
                                                filter('Promo');
                                            }}>
                                                Promo
                                            </li>
                                            <li onClick={(e) => {
                                                filter('Uncommon');
                                            }}>
                                                Uncommon
                                            </li>
                                            <li onClick={(e) => {
                                                filter('Rare');
                                            }}>
                                                Rare
                                            </li>
                                            <li onClick={(e) => {
                                                filter('Epic');
                                            }}>
                                                Epic
                                            </li>
                                            <li onClick={(e) => {
                                                filter('Legendary');
                                            }}>
                                                Legendary
                                            </li>
                                            <li onClick={(e) => {
                                                filter('Mythic');
                                            }}>
                                                Mythic
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="tit-filt-bot">
                    <div className="top-card-list-in on-game">
                        {props.pandaListOnGame ? props.pandaListOnGame.map((item, index) => (

                            <div className="one-card-list one-list-top" key={item.asset_id} data-rar={item.rarity === 1 ? 'Promo' : item.name_pa.substr(0, item.name_pa.indexOf('-'))}>

                                <div className="one-card-list-in">
                                    <div className="img-wrap">
                                        <div className="open-info" onClick={(e) => {
                                            backside(index);
                                        }}></div>
                                        {/* <picture>
                                    <source srcSet={`/images/card-1x/${item.name_pa}.webp`} type="image/webp" />
                                    <source srcSet={`/images/card-1x/${item.name_pa}.png`} type="image/png" /> 
                                    <img src={`/images/card-1x/${item.name_pa}.png`} alt="" />
                                </picture> */}
                                        <PandaVideoComp inslot={item.is_in_slot} fullNameRare={item.name_pa} pandaName={item.name_pa.split('-')[1]} cardName={`${item.name_pa.split('-')[1].substring(0, 1) == "A" ? "serial-1-" : item.name_pa.split('-')[1].substring(0, 1) == "N" ? "serial-2-" : item.name_pa.split('-')[1].substring(0, 1) == "P" ? "serial-3-" : null}${item.name_pa.toLowerCase().substr(0, item.name_pa.indexOf('-'))}`} element={item.element} />


                                        <div className="one-card-bt">
                                            <button className="def-bt min append-card" onClick={(e) => {
                                                props.onHandleSendPandaToSlot(item, index);
                                            }} href="/" >
                                                <span className="button-in">
                                                    <span className="button-name">
                                                        <span className="button-name-in">
                                                            <span className="button-name-value">
                                                                CHOOSE
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </button>
                                        </div>
                                        <div className="one-card-bt">
                                            <button className="def-bt min append-card" onClick={(e) => {
                                                props.openWarningModal(item);
                                            }} href="/" >
                                                <span className="button-in">
                                                    <span className="button-name">
                                                        <span className="button-name-in">
                                                            <span className="button-name-value">
                                                                Out from game
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-back-side">

                                    <div className="close-back-side" onClick={(e) => {
                                        backside(index);
                                    }}></div>
                                    <div className="card-back-side-in">

                                        <div className="card-top-avat-descr">
                                            <div className="card-top-avat">
                                            </div>
                                            <div className="card-top-avat-descr-info">
                                                Panda info
                                            </div>
                                            <div className="card-top-avat-descr-name">
                                                {item.name_pa.split('-')[1]}
                                            </div>
                                            <div className="card-top-avat-descr-list">
                                                <ul>
                                                    <li>
                                                        ID: {item.asset_id}
                                                    </li>
                                                    <li>
                                                        RARITY: {item.rarity === 1 ? 'PROMO' : item.name_pa.substr(0, item.name_pa.indexOf('-'))}
                                                    </li>
                                                    <li>
                                                        stake: {item.stake_count / 10000} {process.env.REACT_APP_VAL}
                                                    </li>
                                                    <li>
                                                        rank: {rarName[item.lvl]}
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="card-top-avat-descr-copy">
                                                NFT Panda
                                                <span>
                                                    World of fantasy
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )) : <>oops</>}
                    </div>

                    <div className="begin-adv">
                        <NavLink className="def-bt mid" to="/adventures">
                            <span className="button-in">
                                <span className="button-name">
                                    <span className="button-name-in">
                                        <span className="button-name-value">
                                            Begin adventure
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </NavLink>


                        <div id="btn-transfer"></div>
                    </div>

                    <div className={`modal-stake-more ${props.warning.hasError ? 'open' : 'close'}`}>
                        <div className="bamb-modal-body">
                            <div className="bamb-modal-body-in">
                                <div className="bamb-modal">
                                    <div className="bamb-modal-in">
                                        <div className="modal-close" onClick={props.handleCloseModalWarning}></div>
                                        <div className="bamb-modal-top"></div>
                                        <div className="bamb-modal-bot"></div>
                                        <div className="bamb-modal-wrap">
                                            <div className="unstake-wrap">
                                                <div className="unstake-top">
                                                    <div className="unstake-tit">
                                                        {props.warning.title}
                                                    </div>
                                                </div>
                                                <div className="unstake-bot">
                                                    {props.warning.message}
                                                </div>

                                                <div className="unstake-form-in">

                                                    <div className="stake-btns">
                                                        <div className="one-stake-bam">
                                                            {props.warning.information ?
                                                                <button className="def-bt min" onClick={(e) => {
                                                                    props.handleCloseModalWarning();
                                                                }}>
                                                                    <span className="button-in">
                                                                        <span className="button-name">
                                                                            <span className="button-name-in">
                                                                                <span className="button-name-value">
                                                                                    Close
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </button>
                                                                :
                                                                <button className="def-bt min" onClick={(e) => {
                                                                    props.onHandleSendPandaToUser(props.warning.idAssets);
                                                                }}>
                                                                    <span className="button-in">
                                                                        <span className="button-name">
                                                                            <span className="button-name-in">
                                                                                <span className="button-name-value">
                                                                                    Out from game
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </button>
                                                            }

                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-modal"></div>
                    </div>
                </div>
            </>

            : null}

    </>);
}

export default Heropagetop;