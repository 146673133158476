import React from 'react';
import musicHit from './../../../music/hit1.mp3'
import PandaVideoComp from './../../PandaVideoComp';
var audioMusicHit = new Audio(musicHit);

function backside(num) {
    //console.log(4);
    var items = document.getElementsByClassName('one-list-bott');
    items[num].classList.toggle("active");
    if(localStorage.getItem('sound') === 'true') {
        audioMusicHit.currentTime = 0;
        audioMusicHit.volume = 0.1;
        audioMusicHit.play();
    }
}

function filter(rar) {
    //console.log(4);
    var showm = document.querySelector('.filter-bot');
    if(rar === "all") {
        var allcard = document.querySelectorAll('.one-list-bott');
        
        for (var i = 0; i < allcard.length; ++i) {
            allcard[i].classList.remove('hide');
        }
    }
    else {
        var allcard = document.querySelectorAll('.one-list-bott');
        
        
        for (var i = 0; i < allcard.length; ++i) {
            allcard[i].classList.add('hide');
        }
        var showcard = document.querySelectorAll('[data-rar='+rar+']');
        for (var i = 0; i < showcard.length; ++i) {
            showcard[i].classList.remove('hide');
        }

        
    }
    showm.classList.remove("open");
    
    //var items = document.getElementsByClassName(rar);
    // items[num].classList.toggle("active");
    // if(localStorage.getItem('sound') === 'true') {
    //     audioMusicHit.currentTime = 0;
    //     audioMusicHit.volume = 0.1;
    //     audioMusicHit.play();
    // }
}

function openfilter(rar) {
    //console.log(4);
    var showcard = document.querySelector('.filter-bot') 
    var items = document.getElementsByClassName(rar);
    showcard.classList.toggle("open");
    // if(localStorage.getItem('sound') === 'true') {
    //     audioMusicHit.currentTime = 0;
    //     audioMusicHit.volume = 0.1;
    //     audioMusicHit.play();
    // }
}

if(localStorage.getItem('wasLogin') === 'false') {
    window.addEventListener('click', function (e) {
        var showcard = document.querySelector('.filter-bot') 
        if (!showcard.contains(e.target) /*&& !button.contains(e.target)*/) {
            // Ниже код, который нужно выполнить при срабатывании события.
            showcard.classList.remove('open');
        }
    });
}

const Heropagebot = (props) => {

    

    return (<>
        <div className="top-card-list">
            <div className="tit-filt-top">
                
                {props.pandaListOnUser.length > 0 ? <div className="tit-list">Heroes in your wallet</div> : null}

                <div className="filter">
                    
                    <div className="filter-in">
                        <div className="filter-tit">
                            filters:
                        </div>
                        <div className="filter-one filter-bot">
                            <button className="def-bt min" onClick={(e) => {
                                        openfilter();
                                    }}>
                                <span className="button-in">
                                    <span className="button-name">
                                        <span className="button-name-in">
                                            <span className="button-name-value">
                                                All heroes
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </button>

                            <div className="filter-drop min">
                                <div className="filter-drop-in">
                                    <ul>
                                        <li onClick={(e) => {
                                            filter('all');
                                        }}>
                                            All heroes
                                        </li>
                                        <li onClick={(e) => {
                                            filter('Common');
                                        }}>
                                            Common
                                        </li>
                                        <li onClick={(e) => {
                                            filter('Promo');
                                        }}>
                                            Promo
                                        </li>
                                        <li onClick={(e) => {
                                            filter('Uncommon');
                                        }}>
                                            Uncommon
                                        </li>
                                        <li onClick={(e) => {
                                            filter('Rare');
                                        }}>
                                            Rare
                                        </li>
                                        <li onClick={(e) => {
                                            filter('Epic');
                                        }}>
                                            Epic
                                        </li>
                                        <li onClick={(e) => {
                                            filter('Legendary');
                                        }}>
                                            Legendary
                                        </li>
                                        <li onClick={(e) => {
                                            filter('Mythic');
                                        }}>
                                            Mythic
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="tit-filt-bot">
                <div className="top-card-list-in on-user">
                    {props.pandaListOnUser && props.pandaListOnUser.length > 0 ? props.pandaListOnUser.map((item, index) => (
                                            
                        <div className="one-card-list one-list-bott" key={item.asset_id} data-rar={item.template.template_id === 314592 || item.template.template_id === 287222 || item.template.template_id === 157195 || item.template.template_id === 162161 || item.template.template_id === 174053 ? 'Promo' : item.data.rarity} >
                            {/* {console.log(item)} */}
                            <div className="one-card-list-in">
                                
                                <div className="img-wrap">
                                    <div className="open-info" onClick={(e) => {
                                        backside(index);
                                    }}></div>
                                    {/* <picture>
                                        <source srcSet={`./images/card-1x/${item['data'].rarity}-${item.name}.webp`} type="image/webp" />
                                        <source srcSet={`./images/card-1x/${item['data'].rarity}-${item.name}.png`} type="image/png" /> 
                                        <img src={`./images/card-1x/${item['data'].rarity}-${item.name}.png`} alt="" />
                                    </picture> */}
                                    
                                    <PandaVideoComp fullNameRare={item['data'].rarity + "-" + item.name} pandaName={item.name} cardName={`${item.name.substring(0, 1) == "A" ? "serial-1-" : item.name.substring(0, 1) == "N" ? "serial-2-" : item.name.substring(0, 1) == "P" ? "serial-3-" : null }${ item['data'].rarity.toLowerCase()}`} element={item.data.element}  />

                                    <div className="one-card-bt">
                                        <button className="def-bt min append-card" onClick={(e) => {
                                                props.onHandleSendPandaToGame(item.asset_id, item['data'].rarity + '-' + item.name);
                                            }} href="/" >
                                            <span className="button-in">
                                                <span className="button-name">
                                                    <span className="button-name-in">
                                                        <span className="button-name-value">
                                                        use in game
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                
                            </div>

                            <div className="card-back-side">
                                
                                <div className="close-back-side" onClick={(e) => {
                                        backside(index);
                                    }}></div>
                                <div className="card-back-side-in">
                                    
                                    <div className="card-top-avat-descr">
                                        <div className="card-top-avat">
                                        </div>
                                        <div className="card-top-avat-descr-info">
                                            Panda info
                                        </div>
                                        <div className="card-top-avat-descr-name">
                                            {item.name}
                                        </div>
                                        <div className="card-top-avat-descr-list">
                                            <ul>
                                                <li>
                                                    ID: {item.asset_id}
                                                </li>
                                                <li>
                                                    type: {item.data.type}
                                                </li>
                                                <li>
                                                    RARITY: {item.template.template_id === 314592 || item.template.template_id === 287222 || item.template.template_id === 157195 || item.template.template_id === 162161 || item.template.template_id === 174053 ? 'PROMO' : item.data.rarity} 
                                                </li>
                                                <li>
                                                    ELEMENT: {item.data.element}
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="card-top-avat-descr-copy">
                                            NFT Panda
                                            <span>
                                            World of fantasy
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    )) : 
                    <>
                        <div className="you-have-no-panda">
                            <div className="tit-list">
                                Sorry, but you don’t have any heroes. <br />  But, You can buy more hero!
                            </div>
                            <div className="begin-adv">
                                <a className="def-bt mid"  target="_blank" href="https://wax.atomichub.io/market?collection_name=nftpandawaxp&order=desc&schema_name=heroes&sort=created&state=1&symbol=WAX">
                                    <span className="button-in">
                                        <span className="button-name">
                                            <span className="button-name-in">
                                                <span className="button-name-value">
                                                    Buy hero
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </a>
                                <div id="btn-transfer"></div>
                            </div>
                        </div>

                    </>
                    }
                </div>
            </div>
        </div>
    </>);
}

export default Heropagebot;