import React, { useState, useEffect } from 'react';
import CountUp from 'react-countup';
import useGetAllMedalInGame from './../../Get/useGetAllMedalInGame'

import modaltop from '../../../images/new-adv/modal-top.svg';
import modalbot from '../../../images/new-adv/modal-bot.svg';
import rewtop from '../../../images/new-adv/multi-reward-top.png';
import rewtopw from '../../../images/new-adv/multi-reward-top.webp';
import rewbot from '../../../images/new-adv/multi-reward-bot.png';
import rewbotw from '../../../images/new-adv/multi-reward-bot.webp';
import back from '../../../images/new-adv/modal-back.svg';
import titleOne from '../../../images/new-adv/modal-tit-1.svg';

import chest from '../../../images/box-2x.png';
import chest1 from '../../../images/box-1-2x.png';
import chest2 from '../../../images/box-2-2x.png';
import chest3 from '../../../images/box-3-2x.png';
import chest4 from '../../../images/box-4-2x.png';
import chest5 from '../../../images/box-5-2x.png';
import chest6 from '../../../images/box-6-2x.png';
import chest7 from '../../../images/box-7-2x.png';
import chest8 from '../../../images/box-8-2x.png';
import chest9 from '../../../images/box-9-2x.png';




const GetReward = (props) => {
    const [allSum, setAllSum] = useState(0);

    //console.log("props", props);
    const { allMedalInGame } = useGetAllMedalInGame(props.user);

    var finalNumber = 0;
    var weaponPay = 0;
    var jewPay = 0;
    var allSumVar = 0;
    var countExp = 0;
    var resWoodNum = 0,
        resSteelNum = 0,
        resTitawinNum = 0,
        resKvessirNum = 0,
        resGoldNum = 0,
        resDwemerNum = 0;

    var rewardX = 1;

    if (props.rewardModalText.finalNumber) {
        finalNumber = props.rewardModalText.finalNumber;
    }

    if (props.rewardModalText.weaponPay) {
        weaponPay = props.rewardModalText.weaponPay;
    }

    if (props.rewardModalText.jewPay) {
        jewPay = props.rewardModalText.jewPay;
    }

    let skillsName = [
        {
            name: "elder",
            count: 0,
        },
        {
            name: "healer",
            count: 0,
        },
        {
            name: "priest",
            count: 0,
        },
        {
            name: "dreneya",
            count: 0,
        },
        {
            name: "druid",
            count: 0,
        },
        {
            name: "mage",
            count: 0,
        },
        {
            name: "tauren",
            count: 0,
        },
        {
            name: "smith",
            count: 0,
        },
        {
            name: "engineer",
            count: 0,
        },
        {
            name: "berserker",
            count: 0,
        },
        {
            name: "bard",
            count: 0,
        },
        {
            name: "shooter",
            count: 0,
        },
        {
            name: "archer",
            count: 0,
        },
        {
            name: "warrior",
            count: 0,
        },
        {
            name: "paladin",
            count: 0,
        }
    ]

    var yourResRew = [];
    //var allRew = []
    var getRew = function (panda, finalnumber) {
        var num1 = finalnumber;
        var pandaRar = panda.rarity;  // (NFT) rare
        var pandaLvl = panda.lvl; // (РАНГ) 8.67 далдно быть застейкано
        var pandaSt = panda.stake_count; // берес количество застейканого бамам на панде
        var pandaEnergy = panda.energy;
        var pandaWeapon = panda.weapon;
        var pandaJew = panda.jew;
        var pandaArmor = panda.armor;
        var energy = 500;
        if (props.duration == 8 && props.typeReward == 'bamboo') {
            energy = 2000;
        } else if (props.duration == 12 && props.typeReward == 'bamboo') {
            energy = 4000;
        }



        var pandaWeaponEnergy;
        var pandaJewEnergy;
        var pandaArmorEnergy;
        if (pandaWeapon) {
            pandaWeaponEnergy = pandaWeapon.energy;
        }
        if (pandaJew) {
            pandaJewEnergy = pandaJew.energy;
        }
        if (pandaArmor) {
            pandaArmorEnergy = pandaArmor.energy;
        }
        var percStake = 1;
        var numberNew = props.resultRew.numberNew;
        //var pandaRarity = props.resultRew.numberNft[pandaRar] / props.resultRew.allCardCount; // редкость
        //var pandaBamsum = props.resultRew.baz * (1 / pandaRarity); // награда за поход

        var pandaBamsum = numberNew[pandaRar];


        // console.log("panda", panda);
        // console.log("pandaRar", pandaRar);
        // console.log("pandaBamsum", pandaBamsum);
        // console.log("numberNew", numberNew);


        var howmcount = pandaBamsum * (props.resultRew.steps[pandaLvl]) * percStake;
        var showBam = howmcount.toFixed(2);
        var showBamBig = showBam * 10000;

        var lvlLoop = pandaLvl;
        var pandaStake = {
            "0": [0, 8700, 22900, 45800, 82900, 142900, 240000, 397100, 651300, 1062600, 1728100, 2804900, 4547100],
            "1": [0, 14400, 37800, 75600, 136800, 235800, 395800, 655000, 1074400, 1753000, 2851000, 4627600, 7502200],
            "2": [0, 17700, 46600, 93300, 168900, 291100, 488900, 808900, 1326700, 2164500, 3520100, 5713500, 9262400],
            "3": [0, 48000, 126000, 252000, 456000, 786000, 1320000, 2184000, 3582000, 5844000, 9504000, 15426000, 25008000],
            "4": [0, 80000, 210000, 420000, 760000, 1310000, 2200000, 3640000, 5970000, 9740000, 15840000, 25710000, 41680000],
            "5": [0, 320000, 840000, 1680000, 3040000, 5240000, 8800000, 14560000, 23880000, 38960000, 63360000, 102840000, 166720000],
            "6": [0, 960000, 2520000, 5040000, 9120000, 15720000, 26400000, 43680000, 71640000, 116880000, 190080000, 308520000, 500160000]
        }

        var howmcount = pandaStake[pandaRar][pandaLvl];
        var youmastadd = (howmcount - pandaSt) / 10000; // вы должны добавитть бам
        var lvlLoop = pandaLvl; // левел для цыкла
        var weaponPay = 0;
        var armorPay = 0;
        var jewPay = 0;
        var pandaProfit = (pandaBamsum + (pandaBamsum * props.resultRew.rangPercProfit[lvlLoop]));
        var pandaProfitWithWeapon = (pandaBamsum + (pandaBamsum * props.resultRew.rangPercProfit[lvlLoop])) + weaponPay;
        var pandaProfitWithJew = (pandaBamsum + (pandaBamsum * props.resultRew.rangPercProfit[lvlLoop])) + jewPay;
        var pandaProfitWithArmor = (pandaBamsum + (pandaBamsum * props.resultRew.rangPercProfit[lvlLoop])) + armorPay;
        var payout_amount;
        var payout_amount_all;
        var payout_amountWithWeapon;
        var payout_amountWithJew;
        var payout_amountWithArmor;
        var animNumber;
        var finelNumbNew;
        var pandaProfitAll = 0;

        // res

        var resWood = 0,
            resSteel = 0,
            resTitawin = 0,
            resKvessir = 0,
            resGold = 0,
            resDwemer = 0;
        var pandaPercRes = 20,
            weaponPercRes = 20,
            armorPercRes = 40;
        var endStory = 0;

        // stones
        var stonePerc = 0;
        var pandaPercStone = 25,
            weaponPercStone = 25,
            armorPercStone = 30;
        var pandaWeaponRar = pandaWeapon.rarity;
        var pandaArmorRar = pandaArmor.rarity;

        if (pandaWeapon && pandaJew && pandaArmor) {
            if (pandaRar != 0) {
                pandaRar = pandaRar - 1;
            }

            if (pandaWeapon.rarity >= pandaRar && pandaJew.rarity >= pandaRar && pandaArmor.rarity >= pandaRar) {
                energy = 400;
                if (props.duration == 12) {
                    energy = 1800;
                }
            }
        }

        for (var i = pandaLvl; i >= 0; i--) {
            howmcount = pandaStake[pandaRar][pandaLvl]
            if (pandaSt < howmcount) {
                lvlLoop = i;
            }
        }

        if (props.typeReward === 'bamboo') {
            if (props.duration == 8) {
                rewardX = 2
            } else if (props.duration == 12) {
                rewardX = 3
            }

            if (pandaWeapon && pandaWeaponEnergy !== 0) {

                if (pandaWeaponRar === 0) {
                    weaponPay = 0.2;
                } else if (pandaWeaponRar === 1) {
                    weaponPay = 0.33;
                } else if (pandaWeaponRar === 2) {
                    weaponPay = 0.56;
                } else if (pandaWeaponRar === 3) {
                    weaponPay = 1.00;
                } else if (pandaWeaponRar === 4) {
                    weaponPay = 1.6;
                } else if (pandaWeaponRar === 5) {
                    weaponPay = 2.8;
                }
            }

            if (pandaJew !== undefined && pandaJewEnergy !== 0) {
                var pandaJewRar = pandaJew.rarity;
                if (pandaJewRar === 0) {
                    jewPay = 0.07;
                } else if (pandaJewRar === 1) {
                    jewPay = 0.15;
                } else if (pandaJewRar === 2) {
                    jewPay = 0.30;
                } else if (pandaJewRar === 3) {
                    jewPay = 0.59;
                } else if (pandaJewRar === 4) {
                    jewPay = 1.17;
                } else if (pandaJewRar === 5) {
                    jewPay = 2.35;
                }
            }

            if (pandaArmor && pandaArmorEnergy !== 0) {

                if (pandaArmorRar === 0) {
                    armorPay = 0.11;
                } else if (pandaArmorRar === 1) {
                    armorPay = 0.20;
                } else if (pandaArmorRar === 2) {
                    armorPay = 0.33;
                } else if (pandaArmorRar === 3) {
                    armorPay = 0.60;
                } else if (pandaArmorRar === 4) {
                    armorPay = 1.2;
                } else if (pandaArmorRar === 5) {
                    armorPay = 2.56;
                }
            }

            // console.log("pandaWeapon", pandaWeapon);
            // console.log("weaponPay", weaponPay);
            // console.log("pandaJew", pandaJew);
            // console.log("jewPay", jewPay);
            // console.log("pandaArmor", pandaArmor);
            // console.log("armorPay", armorPay);

            // console.log("pandaBamsum", pandaBamsum);
            // console.log("props.resultRew.rangPercProfit[lvlLoop]", props.resultRew.rangPercProfit[lvlLoop]);

            pandaProfitAll = (pandaBamsum + (pandaBamsum * props.resultRew.rangPercProfit[lvlLoop])) + weaponPay + jewPay + armorPay;
            pandaProfit = (pandaBamsum + (pandaBamsum * props.resultRew.rangPercProfit[lvlLoop]));
            pandaProfitWithWeapon = (pandaBamsum + (pandaBamsum * props.resultRew.rangPercProfit[lvlLoop])) + weaponPay;
            pandaProfitWithJew = (pandaBamsum + (pandaBamsum * props.resultRew.rangPercProfit[lvlLoop])) + jewPay;
            pandaProfitWithArmor = (pandaBamsum + (pandaBamsum * props.resultRew.rangPercProfit[lvlLoop])) + armorPay;

            var exp;
            if (lvlLoop !== pandaLvl) {
                exp = false;
            } else {
                exp = true;
            }

            switch (num1) {
                case 1: case 2: case 3: case 4: case 5:
                    payout_amount = pandaProfit;
                    payout_amountWithJew = pandaProfitWithJew;
                    payout_amountWithArmor = pandaProfitWithArmor;
                    payout_amountWithWeapon = pandaProfitWithWeapon;

                    payout_amount_all = pandaProfitAll;
                    animNumber = 5;
                    if (exp) {
                        countExp += 2 * rewardX;
                    }
                    break;
                case 6: case 7: case 8: case 9: case 10:
                    payout_amount = pandaProfit - (pandaProfit * 0.25);
                    payout_amountWithWeapon = pandaProfitWithWeapon - (pandaProfitWithWeapon * 0.25);
                    payout_amountWithJew = pandaProfitWithJew - (pandaProfitWithJew * 0.25);
                    payout_amountWithArmor = pandaProfitWithArmor - (pandaProfitWithArmor * 0.25);

                    payout_amount_all = pandaProfitAll - (pandaProfitAll * 0.25);
                    animNumber = 4;
                    if (exp) {
                        countExp += 1 * rewardX;
                    }
                    break;
                case 11: case 12: case 13: case 14: case 15:
                    payout_amount = pandaProfit - (pandaProfit * 0.50);
                    payout_amountWithWeapon = pandaProfitWithWeapon - (pandaProfitWithWeapon * 0.50);
                    payout_amountWithJew = pandaProfitWithJew - (pandaProfitWithJew * 0.50);
                    payout_amountWithArmor = pandaProfitWithArmor - (pandaProfitWithArmor * 0.50);

                    payout_amount_all = pandaProfitAll - (pandaProfitAll * 0.50);
                    animNumber = 3;
                    if (exp) {
                        countExp += 1 * rewardX;
                    }
                    break;
                case 16: case 17: case 18: case 19: case 20:
                    payout_amount = pandaProfit - (pandaProfit * 0.75);
                    payout_amountWithWeapon = pandaProfitWithWeapon - (pandaProfitWithWeapon * 0.75);
                    payout_amountWithJew = pandaProfitWithJew - (pandaProfitWithJew * 0.75);
                    payout_amountWithArmor = pandaProfitWithArmor - (pandaProfitWithArmor * 0.75);

                    payout_amount_all = pandaProfitAll - (pandaProfitAll * 0.75);
                    animNumber = 2;
                    if (exp) {
                        countExp += 1 * rewardX;
                    }
                    break;
                case 21: case 22: case 23: case 24: case 25:
                    payout_amount = 0;
                    payout_amountWithWeapon = 0;
                    animNumber = 1;
                    weaponPay = 0;
                    payout_amountWithJew = 0;
                    payout_amountWithArmor = 0;

                    payout_amount_all = 0;
                    jewPay = 0;
                    break;
                case 26: case 27: case 28: case 29: case 30:
                    payout_amount = pandaProfit;
                    payout_amountWithWeapon = pandaProfitWithWeapon;
                    payout_amountWithJew = pandaProfitWithJew;
                    payout_amountWithArmor = pandaProfitWithArmor;

                    payout_amount_all = pandaProfitAll;
                    animNumber = 5;
                    if (exp) {
                        countExp += 2 * rewardX;
                    }
                    break;
                case 31: case 32: case 33: case 34: case 35:
                    payout_amount = pandaProfit + (pandaProfit * 0.25);
                    payout_amountWithWeapon = pandaProfitWithWeapon + (pandaProfitWithWeapon * 0.25);
                    payout_amountWithJew = pandaProfitWithJew + (pandaProfitWithJew * 0.25);
                    payout_amountWithArmor = pandaProfitWithArmor + (pandaProfitWithArmor * 0.25);

                    payout_amount_all = pandaProfitAll + pandaProfitAll * 0.25;
                    animNumber = 6;
                    if (exp) {
                        countExp += 2 * rewardX;
                    }
                    break;
                case 36: case 37: case 38: case 39: case 40:
                    payout_amount = pandaProfit + (pandaProfit * 0.50);
                    payout_amountWithWeapon = pandaProfitWithWeapon + (pandaProfitWithWeapon * 0.50);
                    payout_amountWithJew = pandaProfitWithJew + (pandaProfitWithJew * 0.50);
                    payout_amountWithArmor = pandaProfitWithArmor + (pandaProfitWithArmor * 0.50);

                    payout_amount_all = pandaProfitAll + pandaProfitAll * 0.50;
                    animNumber = 7;
                    if (exp) {
                        countExp += 2 * rewardX;
                    }
                    break;
                case 41: case 42: case 43: case 44: case 45:
                    payout_amount = pandaProfit + (pandaProfit * 0.75);
                    payout_amountWithWeapon = pandaProfitWithWeapon + (pandaProfitWithWeapon * 0.75);
                    payout_amountWithJew = pandaProfitWithJew + (pandaProfitWithJew * 0.75);
                    payout_amountWithArmor = pandaProfitWithArmor + (pandaProfitWithArmor * 0.75);

                    payout_amount_all = pandaProfitAll + pandaProfitAll * 0.75;
                    animNumber = 8;
                    if (exp) {
                        countExp += 2 * rewardX;
                    }
                    break;
                case 46: case 47: case 48: case 49: case 50:
                    payout_amount = pandaProfit * 2;
                    payout_amountWithWeapon = pandaProfitWithWeapon * 2;
                    payout_amountWithJew = pandaProfitWithJew * 2;
                    payout_amountWithArmor = pandaProfitWithArmor * 2;

                    payout_amount_all = pandaProfitAll + pandaProfitAll * 2;
                    animNumber = 9;
                    if (exp) {
                        countExp += 3 * rewardX;
                    }
                    break;
                default:
                    payout_amount = 0;
                    payout_amountWithWeapon = 0;
                    payout_amountWithJew = 0;
                    payout_amountWithArmor = 0;
                    payout_amount_all = 0;
                    animNumber = 0;
                    break;
            }

            if (pandaEnergy >= energy) {
                finelNumbNew = parseInt(payout_amount * rewardX * 10000) / 10000;
                weaponPay = parseInt(((payout_amountWithWeapon * rewardX) - (payout_amount * rewardX)) * 10000) / 10000;
                jewPay = parseInt(((payout_amountWithJew * rewardX) - (payout_amount * rewardX)) * 10000) / 10000;
                armorPay = parseInt(((payout_amountWithArmor * rewardX) - (payout_amount * rewardX)) * 10000) / 10000;
            } else {
                finelNumbNew = parseInt(payout_amount * rewardX * 10000 * 0.2) / 10000;
                weaponPay = parseInt(((payout_amountWithWeapon * rewardX) - (payout_amount * rewardX)) * 10000 * 0.2) / 10000;
                jewPay = parseInt(((payout_amountWithJew * rewardX) - (payout_amount * rewardX)) * 10000 * 0.2) / 10000;
                armorPay = parseInt(((payout_amountWithArmor * rewardX) - (payout_amount * rewardX)) * 10000 * 0.2) / 10000;
            }


            if (pandaJew && pandaJewEnergy === 0 && rewardX == 1 ||
                pandaJew && pandaJewEnergy < energy && rewardX == 2 ||
                pandaJew && pandaJewEnergy < energy && rewardX == 3
            ) {
                jewPay = 0;
            }

            if (pandaArmor && pandaArmorEnergy === 0 && rewardX == 1 ||
                pandaArmor && pandaArmorEnergy < energy && rewardX == 2 ||
                pandaArmor && pandaArmorEnergy < energy && rewardX == 3
            ) {
                armorPay = 0;
            }

            if (pandaWeapon && pandaWeaponEnergy === 0 && rewardX == 1 ||
                pandaWeapon && pandaWeaponEnergy < energy && rewardX == 2 ||
                pandaWeapon && pandaWeaponEnergy < energy && rewardX == 3
            ) {
                weaponPay = 0;
            }

            allSumVar += finelNumbNew + weaponPay + jewPay + armorPay;

            // console.log("payout_amount_all", payout_amount_all);
            // console.log("allSumVar", allSumVar);
            // console.log("finelNumbNew", finelNumbNew);
            // console.log("weaponPay", weaponPay);
            // console.log("jewPay", jewPay);
            // console.log("armorPay", armorPay);

            // console.log("pandaWeapon", pandaWeapon);
            // console.log("weaponPay", weaponPay);
            // console.log("pandaJew", pandaJew);
            // console.log("jewPay", jewPay);
            // console.log("pandaArmor", pandaArmor);
            // console.log("armorPay", armorPay);

            var objRes = {
                pandaRew: finelNumbNew,
                weaponRew: weaponPay,
                jewRew: jewPay,
                animNumber: animNumber,
                armorRew: jewPay,
            }

            return objRes;
        } else if (props.typeReward === 'resources') {
            console.log("pandaRar", pandaRar)
            switch (pandaRar) {
                case 0: case 1:
                    pandaPercRes = 20;
                    break;
                case 2:
                    pandaPercRes = 15;
                    break;
                case 3:
                    pandaPercRes = 10;
                    break;
                case 4:
                    pandaPercRes = 5;
                    break;
                case 5:
                    pandaPercRes = 2;
                    break;
                case 6:
                    pandaPercRes = 0;
                    break;
                default:
                    pandaPercRes = 20;
                    break;
            }
            // end for panda
            console.log("pandaPercRes", pandaPercRes)

            console.log("pandaWeaponRar", pandaWeaponRar)
            // for weapon
            switch (pandaWeaponRar) {
                case 0:
                    weaponPercRes = 20;
                    break;
                case 1:
                    weaponPercRes = 15;
                    break;
                case 2:
                    weaponPercRes = 10;
                    break;
                case 3:
                    weaponPercRes = 5;
                    break;
                case 4:
                    weaponPercRes = 2;
                    break;
                case 5:
                    weaponPercRes = 0;
                    break;
                default:
                    weaponPercRes = 20;
                    break;
            }
            console.log("weaponPercRes", weaponPercRes)
            //end for weapon

            // armor
            console.log("pandaArmorRar", pandaArmorRar)
            if (pandaArmor.energy >= energy) {
                switch (pandaArmorRar) {
                    case 0:
                        armorPercRes = 20;
                        break;
                    case 1:
                        armorPercRes = 15;
                        break;
                    case 2:
                        armorPercRes = 10;
                        break;
                    case 3:
                        armorPercRes = 5;
                        break;
                    case 4:
                        armorPercRes = 2;
                        break;
                    case 5:
                        armorPercRes = 0;
                        break;
                    default:
                        armorPercRes = 20;
                        break;
                }
            }
            console.log("armorPercRes", armorPercRes)
            // end armor

            console.log("weaponPercRes + pandaPercRes + armorPercRes", weaponPercRes + pandaPercRes + armorPercRes);

            console.log("num1", num1)

            switch (weaponPercRes + pandaPercRes + armorPercRes) {

                case 80:
                    resWood = 150;
                    resSteel = 40;
                    resTitawin = 8;
                    resKvessir = 2;
                    resGold = 0;
                    resDwemer = 0;
                    break;
                case 75:
                    resWood = 185;
                    resSteel = 50;
                    resTitawin = 10;
                    resKvessir = 4;
                    resGold = 1;
                    resDwemer = 0;
                    break;
                case 70:
                    resWood = 210;
                    resSteel = 62;
                    resTitawin = 15;
                    resKvessir = 10;
                    resGold = 2;
                    resDwemer = 1;
                    break;
                case 65:
                    resWood = 240;
                    resSteel = 70;
                    resTitawin = 20;
                    resKvessir = 15;
                    resGold = 3;
                    resDwemer = 2;
                    break;
                case 62:
                    resWood = 260;
                    resSteel = 80;
                    resTitawin = 20;
                    resKvessir = 15;
                    resGold = 3;
                    resDwemer = 2;
                    break;
                case 60:
                    resWood = 270;
                    resSteel = 85;
                    resTitawin = 22;
                    resKvessir = 18;
                    resGold = 3;
                    resDwemer = 2;
                    break;
                case 57:
                    resWood = 300;
                    resSteel = 85;
                    resTitawin = 22;
                    resKvessir = 18;
                    resGold = 3;
                    resDwemer = 2;
                    break;
                case 55:
                    resWood = 310;
                    resSteel = 90;
                    resTitawin = 25;
                    resKvessir = 18;
                    resGold = 4;
                    resDwemer = 3;
                    break;
                case 52:
                    resWood = 330;
                    resSteel = 95;
                    resTitawin = 28;
                    resKvessir = 19;
                    resGold = 5;
                    resDwemer = 3;
                    break;
                case 50:
                    resWood = 340;
                    resSteel = 100;
                    resTitawin = 30;
                    resKvessir = 20;
                    resGold = 6;
                    resDwemer = 4;
                    break;
                case 47:
                    resWood = 350;
                    resSteel = 110;
                    resTitawin = 35;
                    resKvessir = 23;
                    resGold = 7;
                    resDwemer = 5;
                    break;
                case 45:
                    resWood = 360;
                    resSteel = 120;
                    resTitawin = 35;
                    resKvessir = 23;
                    resGold = 7;
                    resDwemer = 5;
                    break;
                case 44:
                    resWood = 360;
                    resSteel = 123;
                    resTitawin = 38;
                    resKvessir = 25;
                    resGold = 8;
                    resDwemer = 6;
                    break;
                case 42:
                    resWood = 370;
                    resSteel = 125;
                    resTitawin = 40;
                    resKvessir = 28;
                    resGold = 10;
                    resDwemer = 7;
                    break;
                case 40:
                    resWood = 380;
                    resSteel = 135;
                    resTitawin = 40;
                    resKvessir = 28;
                    resGold = 10;
                    resDwemer = 7;
                    break;
                case 37:
                    resWood = 390;
                    resSteel = 140;
                    resTitawin = 45;
                    resKvessir = 30;
                    resGold = 15;
                    resDwemer = 10;
                    break;
                case 35:
                    resWood = 400;
                    resSteel = 150;
                    resTitawin = 45;
                    resKvessir = 30;
                    resGold = 15;
                    resDwemer = 10;
                    break;
                case 32:
                    resWood = 400;
                    resSteel = 170;
                    resTitawin = 50;
                    resKvessir = 32;
                    resGold = 17;
                    resDwemer = 11;
                    break;
                case 30:
                    resWood = 400;
                    resSteel = 180;
                    resTitawin = 55;
                    resKvessir = 35;
                    resGold = 18;
                    resDwemer = 12;
                    break;
                case 27:
                    resWood = 400;
                    resSteel = 196;
                    resTitawin = 60;
                    resKvessir = 40;
                    resGold = 20;
                    resDwemer = 14;
                    break;
                case 25:
                    resWood = 400;
                    resSteel = 200;
                    resTitawin = 70;
                    resKvessir = 44;
                    resGold = 21;
                    resDwemer = 15;
                    break;
                case 24:
                    resWood = 410;
                    resSteel = 200;
                    resTitawin = 70;
                    resKvessir = 44;
                    resGold = 21;
                    resDwemer = 15;
                    break;
                case 22:
                    resWood = 400;
                    resSteel = 205;
                    resTitawin = 80;
                    resKvessir = 50;
                    resGold = 25;
                    resDwemer = 20;
                    break;
                case 20:
                    resWood = 400;
                    resSteel = 210;
                    resTitawin = 90;
                    resKvessir = 55;
                    resGold = 25;
                    resDwemer = 20;
                    break;
                case 19:
                    resWood = 400;
                    resSteel = 210;
                    resTitawin = 90;
                    resKvessir = 60;
                    resGold = 30;
                    resDwemer = 20;
                    break;
                case 17:
                    resWood = 390;
                    resSteel = 200;
                    resTitawin = 110;
                    resKvessir = 80;
                    resGold = 30;
                    resDwemer = 20;
                    break;
                case 15:
                    resWood = 380;
                    resSteel = 190;
                    resTitawin = 130;
                    resKvessir = 90;
                    resGold = 40;
                    resDwemer = 20;
                    break;
                case 14:
                    resWood = 380;
                    resSteel = 190;
                    resTitawin = 130;
                    resKvessir = 90;
                    resGold = 40;
                    resDwemer = 30;
                    break;
                case 12:
                    resWood = 380;
                    resSteel = 190;
                    resTitawin = 130;
                    resKvessir = 100;
                    resGold = 50;
                    resDwemer = 30;
                    break;
                case 10:
                    resWood = 370;
                    resSteel = 180;
                    resTitawin = 140;
                    resKvessir = 110;
                    resGold = 65;
                    resDwemer = 35;
                    break;
                case 9:
                    resWood = 370;
                    resSteel = 180;
                    resTitawin = 140;
                    resKvessir = 110;
                    resGold = 70;
                    resDwemer = 40;
                    break;
                case 7:
                    resWood = 360;
                    resSteel = 180;
                    resTitawin = 140;
                    resKvessir = 120;
                    resGold = 80;
                    resDwemer = 50;
                    break;
                case 6:
                    resWood = 360;
                    resSteel = 180;
                    resTitawin = 140;
                    resKvessir = 120;
                    resGold = 85;
                    resDwemer = 55;
                    break;
                case 5:
                    resWood = 350;
                    resSteel = 180;
                    resTitawin = 150;
                    resKvessir = 120;
                    resGold = 90;
                    resDwemer = 60;
                    break;
                case 4:
                    resWood = 340;
                    resSteel = 170;
                    resTitawin = 160;
                    resKvessir = 120;
                    resGold = 100;
                    resDwemer = 70;
                    break;
                case 2:
                    resWood = 340;
                    resSteel = 170;
                    resTitawin = 150;
                    resKvessir = 130;
                    resGold = 110;
                    resDwemer = 80;
                    break;
                case 0:
                    resWood = 330;
                    resSteel = 160;
                    resTitawin = 150;
                    resKvessir = 140;
                    resGold = 120;
                    resDwemer = 100;
                default:
                    break;
            }


            if (num1 <= resWood) { // 400
                resWoodNum += 1;
                countExp += 2;
            } else if (num1 > resWood && num1 <= resSteel + resWood) {
                resSteelNum += 1;
                countExp += 2;
            } else if (num1 > resSteel + resWood && num1 <= resTitawin + resSteel + resWood) {
                resTitawinNum += 1;
                countExp += 2;
            } else if (num1 > resTitawin + resSteel + resWood && num1 <= resKvessir + resTitawin + resSteel + resWood) {
                resKvessirNum += 1;
                countExp += 2;
            } else if (num1 > resKvessir + resTitawin + resSteel + resWood && num1 <= resGold + resKvessir + resTitawin + resSteel + resWood) {
                resGoldNum += 1;
                countExp += 2;
            } else if (num1 > resGold + resKvessir + resTitawin + resSteel + resWood && num1 <= resDwemer + resGold + resKvessir + resTitawin + resSteel + resWood) {
                resDwemerNum += 1;
                countExp += 2;
            } else if (num1 > resDwemer) {
                yourResRew.push({ name: 'noooooooooooooo' });
                endStory = 1;
            }

            if (lvlLoop < 2 && endStory == 1) {
                countExp -= 2;
            }


            if (props.duration == 12) {
                rewardX = 2
            }


            return objRes;

        } else if (props.typeReward === 'books') {

            // console.log("pandaRar", pandaRar);
            // console.log("pandaWeaponRar", pandaWeaponRar);
            // console.log("pandaArmorRar", pandaArmorRar);

            // console.log("weaponPercStone", weaponPercStone);
            // console.log("pandaPercStone", pandaPercStone);
            // console.log("armorPercStone", armorPercStone);

            // console.log("stonePerc", stonePerc);
            // console.log("num1", num1);

            // console.log("allMedalInGame", allMedalInGame);

            var totalLuck = 20;

            if (allMedalInGame && allMedalInGame.medal10 != undefined && allMedalInGame.medal10) {
                totalLuck = 35;
            } else if (allMedalInGame && allMedalInGame.medal100 != undefined && allMedalInGame.medal100) {
                totalLuck = 30;
            } else if (allMedalInGame && allMedalInGame.medal300 != undefined && allMedalInGame.medal300) {
                totalLuck = 25;
            }

            var typebook;
            if (num1 < totalLuck) {
                typebook = 0;
            } else if (num1 >= totalLuck * 15) {
                typebook = 999;
                endStory = 1;
            } else {
                typebook = Math.floor(num1 / totalLuck) - 1;
            }

            //console.log("typebook", typebook);

            var manyaAdd = props.duration == 12 ? 2 : props.duration == 6 ? 1 : 0;

            if (typebook < 15) {
                skillsName[typebook].count += manyaAdd;
                //console.log("skillsName[typebook].count", skillsName[typebook].count);
            }

            //console.log("manyaAdd", manyaAdd);

            //console.log("skillsName", skillsName);


            if (endStory === 0) {
                countExp += 1;
            }

            if (props.duration == 12) {
                rewardX = 2
            }

        } else {
            // for panda
            switch (pandaRar) {
                case 0: case 1:
                    pandaPercStone = 25;
                    break;
                case 2:
                    pandaPercStone = 21;
                    break;
                case 3:
                    pandaPercStone = 15;
                    break;
                case 4:
                    pandaPercStone = 10;
                    break;
                case 5:
                    pandaPercStone = 5;
                    break;
                case 6:
                    pandaPercStone = 0;
                    break;
                default:
                    pandaPercStone = 25;
                    break;
            }
            // end for panda

            // for weapon
            switch (pandaWeaponRar) {
                case 0:
                    weaponPercStone = 25;
                    break;
                case 1:
                    weaponPercStone = 21;
                    break;
                case 2:
                    weaponPercStone = 15;
                    break;
                case 3:
                    weaponPercStone = 10;
                    break;
                case 4:
                    weaponPercStone = 5;
                    break;
                case 5:
                    weaponPercStone = 0;
                    break;
                default:
                    weaponPercStone = 25;
                    break;
            }

            if (pandaArmor && pandaArmorEnergy !== 0) {
                var pandaArmorRar = pandaArmor.rarity;
                //console.log("gems pandaArmorRar", pandaArmorRar);
                switch (pandaArmorRar) {
                    case 0:
                        armorPercStone = 25;
                        break;
                    case 1:
                        armorPercStone = 21;
                        break;
                    case 2:
                        armorPercStone = 15;
                        break;
                    case 3:
                        armorPercStone = 10;
                        break;
                    case 4:
                        armorPercStone = 5;
                        break;
                    case 5:
                        armorPercStone = 0;
                        break;
                    default:
                        armorPercStone = 25;
                        break;
                }
            }

            // console.log("pandaRar", pandaRar);
            // console.log("pandaWeaponRar", pandaWeaponRar);
            // console.log("pandaArmorRar", pandaArmorRar);

            // console.log("weaponPercStone", weaponPercStone);
            // console.log("pandaPercStone", pandaPercStone);
            // console.log("armorPercStone", armorPercStone);

            //console.log("stonePerc", stonePerc);
            //console.log("num1", num1);

            stonePerc = parseInt(((100 - weaponPercStone - pandaPercStone - armorPercStone) * 10) / 20)

            //console.log("stonePerc2", stonePerc);

            if (num1 <= stonePerc) { // 400
                yourResRew.push({ name: 'tihash', element: 'earth' });
                countExp += 2;
            } else if (num1 > stonePerc && num1 <= stonePerc * 2) {
                yourResRew.push({ name: 'tihash', element: 'wind' });
                countExp += 2;
            } else if (num1 > stonePerc * 2 && num1 <= stonePerc * 3) {
                yourResRew.push({ name: 'tihash', element: 'fire' });
                countExp += 2;
            } else if (num1 > stonePerc * 3 && num1 <= stonePerc * 4) {
                yourResRew.push({ name: 'tihash', element: 'water' });
                countExp += 2;
            }

            else if (num1 > stonePerc * 4 && num1 <= stonePerc * 5) {
                yourResRew.push({ name: 'pliacrin', element: 'earth' });
                countExp += 2;
            }
            else if (num1 > stonePerc * 5 && num1 <= stonePerc * 6) {
                yourResRew.push({ name: 'pliacrin', element: 'wind' });
                countExp += 2;
            }
            else if (num1 > stonePerc * 6 && num1 <= stonePerc * 7) {
                yourResRew.push({ name: 'pliacrin', element: 'fire' });
                countExp += 2;
            }
            else if (num1 > stonePerc * 7 && num1 <= stonePerc * 8) {
                yourResRew.push({ name: 'pliacrin', element: 'water' });
                countExp += 2;
            }

            else if (num1 > stonePerc * 8 && num1 <= stonePerc * 9) {
                yourResRew.push({ name: 'kyanite', element: 'earth' });
                countExp += 2;
            }
            else if (num1 > stonePerc * 9 && num1 <= stonePerc * 10) {
                yourResRew.push({ name: 'kyanite', element: 'wind' });
                countExp += 2;
            }
            else if (num1 > stonePerc * 10 && num1 <= stonePerc * 11) {
                yourResRew.push({ name: 'kyanite', element: 'fire' });
                countExp += 2;
            }
            else if (num1 > stonePerc * 11 && num1 <= stonePerc * 12) {
                yourResRew.push({ name: 'kyanite', element: 'water' });
                countExp += 2;
            }

            else if (num1 > stonePerc * 12 && num1 <= stonePerc * 13) {
                yourResRew.push({ name: 'indicolite', element: 'earth' });
                countExp += 2;
            }
            else if (num1 > stonePerc * 13 && num1 <= stonePerc * 14) {
                yourResRew.push({ name: 'indicolite', element: 'wind' });
                countExp += 2;
            }
            else if (num1 > stonePerc * 14 && num1 <= stonePerc * 15) {
                yourResRew.push({ name: 'indicolite', element: 'fire' });
                countExp += 2;
            }
            else if (num1 > stonePerc * 15 && num1 <= stonePerc * 16) {
                yourResRew.push({ name: 'indicolite', element: 'water' });
                countExp += 2;
            }

            else if (num1 > stonePerc * 16 && num1 <= stonePerc * 17) {
                yourResRew.push({ name: 'apatite', element: 'earth' });
                countExp += 2;
            }
            else if (num1 > stonePerc * 17 && num1 <= stonePerc * 18) {
                yourResRew.push({ name: 'apatite', element: 'wind' });
                countExp += 2;
            }
            else if (num1 > stonePerc * 18 && num1 <= stonePerc * 19) {
                yourResRew.push({ name: 'apatite', element: 'fire' });
                countExp += 2;
            }
            else if (num1 > stonePerc * 19 && num1 <= stonePerc * 20) {
                yourResRew.push({ name: 'apatite', element: 'water' });
                countExp += 2;
            } else if (num1 > stonePerc * 20) {
                //yourResRew.push({name: 'noooooooooooooo'});
                endStory = 1;
            }

            //console.log("yourResRew", yourResRew);

            if (lvlLoop < 2 && endStory == 1) {
                countExp -= 2;
            }

            if (props.duration == 12) {
                rewardX = 2
            }

        }
    }


    let newData = {};

    if (props.multi && props.multiResult && props.multiResult.length) {
        props.multiResult.map((item, index) => {
            var onePanda;
            for (let i = 0; i < props.pandaSlots.length; i++) {
                if (item.asset_id === props.pandaSlots[i].asset_id) {

                    onePanda = props.pandaSlots[i];
                    getRew(onePanda, item.finalnumber);
                }
            }
        });

        yourResRew.map(item => {
            if (!newData[item.name + item.element])
                newData[item.name + item.element] = { name: item.name, element: item.element, sum: [] };
            newData[item.name + item.element].sum.push("+1");
        });

        //console.log("LOOOOOP INFO");
    }

    useEffect(() => {
        var wasload = false;
        //console.log("allSumVar", allSumVar);
        props.updateBalance(true, parseFloat(allSumVar).toFixed(4));

    }, [allSumVar])

    return (
        <>

            <div className={`modal-to-adv reward-window open ${props.getReward.anim ? " anim" : null}  ${props.typeReward === 'bamboo' ? "bamboo-rew" : props.typeReward === 'resources' ? 'res-rew' : "stone-rew"}`}>
                <div className="modal-to-adv-body">
                    <div className={`modal-to-adv-body-in ${props.multi && "multi-rew"}`}>


                        {props.multi ?
                            <div className="multi-reward">
                                <div className="multi-reward-top">
                                    <picture>
                                        <source srcSet={rewtopw} type="image/webp" />
                                        <source srcSet={rewtop} type="image/png" />
                                        <img src={rewtop} alt="" />
                                    </picture>
                                    <div className="close-mult-rew" onClick={() => props.openNewAdvMap('close', props.duration, props.typeReward)}>
                                        <span></span>
                                    </div>
                                </div>
                                <div className="multi-reward-wrap">
                                    <div className="multi-reward-wrap-in">
                                        {props.typeReward === 'bamboo' &&
                                            <div className="bamboo-reward">
                                                <div className="bamboo-reward-chester">
                                                    <div className={`chest-wrap-in`}>
                                                        <img className="chest" src={chest6} alt="" />
                                                    </div>
                                                </div>
                                                <div className="bamboo-reward-detal">
                                                    <div className="bamboo-reward-tit">
                                                        general award:
                                                    </div>
                                                    <div className="bamboo-reward-count">
                                                        <span>
                                                            {allSumVar.toFixed(4)}
                                                        </span>
                                                        bam
                                                    </div>
                                                    <div className="bamboo-reward-tit">
                                                        gain Experience:
                                                    </div>
                                                    <div className="bamboo-reward-exp">
                                                        <span>
                                                            +{countExp}
                                                            <i></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>}

                                        {props.typeReward === 'resources' &&
                                            <div className="get-new-rew-ress">
                                                <div className="get-new-rew-ress-in">

                                                    {resWoodNum > 0 &&
                                                        <div className="one-rew-ress" >
                                                            <div className="one-rew-ress-thumb">
                                                                <span>
                                                                    <picture>
                                                                        <source srcSet={`./images/new-adv/res/res-common.webp`} type="image/webp" />
                                                                        <source srcSet={`./images/new-adv/res/res-common.png`} type="image/png" />
                                                                        <img src={`./images/new-adv/res/res-common.png`} alt="" />
                                                                    </picture>
                                                                </span>
                                                            </div>
                                                            <div className="one-rew-ress-name">
                                                                <i>
                                                                    +{resWoodNum * rewardX}
                                                                </i>
                                                                wood
                                                            </div>
                                                        </div>}
                                                    {resSteelNum > 0 &&
                                                        <div className="one-rew-ress" >
                                                            <div className="one-rew-ress-thumb">
                                                                <span>
                                                                    <picture>
                                                                        <source srcSet={`./images/new-adv/res/res-uncommon.webp`} type="image/webp" />
                                                                        <source srcSet={`./images/new-adv/res/res-uncommon.png`} type="image/png" />
                                                                        <img src={`./images/new-adv/res/res-uncommon.png`} alt="" />
                                                                    </picture>
                                                                </span>
                                                            </div>
                                                            <div className="one-rew-ress-name">
                                                                <i>
                                                                    +{resSteelNum * rewardX}
                                                                </i>
                                                                steel
                                                            </div>
                                                        </div>}

                                                    {resTitawinNum > 0 &&
                                                        <div className="one-rew-ress" >
                                                            <div className="one-rew-ress-thumb">
                                                                <span>
                                                                    <picture>
                                                                        <source srcSet={`./images/new-adv/res/res-rare.webp`} type="image/webp" />
                                                                        <source srcSet={`./images/new-adv/res/res-rare.png`} type="image/png" />
                                                                        <img src={`./images/new-adv/res/res-rare.png`} alt="" />
                                                                    </picture>
                                                                </span>
                                                            </div>
                                                            <div className="one-rew-ress-name">
                                                                <i>
                                                                    +{resTitawinNum * rewardX}
                                                                </i>
                                                                titawin
                                                            </div>
                                                        </div>}

                                                    {resKvessirNum > 0 &&
                                                        <div className="one-rew-ress" >
                                                            <div className="one-rew-ress-thumb">
                                                                <span>
                                                                    <picture>
                                                                        <source srcSet={`./images/new-adv/res/res-epic.webp`} type="image/webp" />
                                                                        <source srcSet={`./images/new-adv/res/res-epic.png`} type="image/png" />
                                                                        <img src={`./images/new-adv/res/res-epic.png`} alt="" />
                                                                    </picture>
                                                                </span>
                                                            </div>
                                                            <div className="one-rew-ress-name">
                                                                <i>
                                                                    +{resKvessirNum * rewardX}
                                                                </i>
                                                                kvessir
                                                            </div>
                                                        </div>}
                                                    {resGoldNum > 0 &&
                                                        <div className="one-rew-ress" >
                                                            <div className="one-rew-ress-thumb">
                                                                <span>
                                                                    <picture>
                                                                        <source srcSet={`./images/new-adv/res/res-legendary.webp`} type="image/webp" />
                                                                        <source srcSet={`./images/new-adv/res/res-legendary.png`} type="image/png" />
                                                                        <img src={`./images/new-adv/res/res-legendary.png`} alt="" />
                                                                    </picture>
                                                                </span>
                                                            </div>
                                                            <div className="one-rew-ress-name">
                                                                <i>
                                                                    +{resGoldNum * rewardX}
                                                                </i>
                                                                gold
                                                            </div>
                                                        </div>}
                                                    {resDwemerNum > 0 &&
                                                        <div className="one-rew-ress" >
                                                            <div className="one-rew-ress-thumb">
                                                                <span>
                                                                    <picture>
                                                                        <source srcSet={`./images/new-adv/res/res-mythic.webp`} type="image/webp" />
                                                                        <source srcSet={`./images/new-adv/res/res-mythic.png`} type="image/png" />
                                                                        <img src={`./images/new-adv/res/res-mythic.png`} alt="" />
                                                                    </picture>
                                                                </span>
                                                            </div>
                                                            <div className="one-rew-ress-name">
                                                                <i>
                                                                    +{resDwemerNum * rewardX}
                                                                </i>
                                                                dwemer
                                                            </div>
                                                        </div>}

                                                    <div className="one-rew-ress">
                                                        <div className="one-rew-ress-thumb">
                                                            <span>
                                                                <picture>
                                                                    <source srcSet={`./images/new-adv/exp.webp`} type="image/webp" />
                                                                    <source srcSet={`./images/new-adv/exp.png`} type="image/png" />
                                                                    <img src={`./images/new-adv/exp.png`} alt="" />
                                                                </picture>
                                                            </span>
                                                        </div>
                                                        <div className="one-rew-ress-name">
                                                            <i>+{countExp * rewardX}</i> exp
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>}

                                        {props.typeReward === 'stones' &&
                                            <div className="get-new-rew-ress stone-type">
                                                <div className="get-new-rew-ress-in">
                                                    {
                                                        //Object.keys(newData).map(function(key, index) {
                                                        Object.keys(newData).map((s, index) => {
                                                            /*console.log(newData[s]);
                                                            console.log(s);*/
                                                            return (
                                                                <div className="one-rew-ress">
                                                                    <div className="one-rew-ress-thumb">
                                                                        <span>
                                                                            <picture>
                                                                                <source srcSet={`./images/new-adv/res/stone-${newData[s].name}-${newData[s].element}.webp`} type="image/webp" />
                                                                                <source srcSet={`./images/new-adv/res/stone-${newData[s].name}-${newData[s].element}.png`} type="image/png" />
                                                                                <img src={`./images/new-adv/res/stone-${newData[s].name}-${newData[s].element}.png`} alt="" />
                                                                            </picture>
                                                                        </span>
                                                                    </div>
                                                                    <div className="one-rew-ress-name">
                                                                        <i>
                                                                            +{newData[s].sum.length * rewardX}
                                                                        </i>
                                                                        {newData[s].name}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }


                                                    <div className="one-rew-ress">
                                                        <div className="one-rew-ress-thumb">
                                                            <span>
                                                                <picture>
                                                                    <source srcSet={`./images/new-adv/exp.webp`} type="image/webp" />
                                                                    <source srcSet={`./images/new-adv/exp.png`} type="image/png" />
                                                                    <img src={`./images/new-adv/exp.png`} alt="" />
                                                                </picture>
                                                            </span>
                                                        </div>
                                                        <div className="one-rew-ress-name">
                                                            <i>+{countExp * rewardX}</i> exp
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>}

                                        {props.typeReward === 'books' &&
                                            <div className="get-new-rew-ress stone-type">
                                                <div className="get-new-rew-ress-in">
                                                    {

                                                        // Object.keys(newData).map((s, index) => {
                                                        //     return (
                                                        //         <div className="one-rew-ress">
                                                        //             <div className="one-rew-ress-thumb">
                                                        //                 <span>
                                                        //                     <picture>
                                                        //                         <source srcSet={`./images/new-adv/res/stone-${newData[s].name}-${newData[s].element}.webp`} type="image/webp" />
                                                        //                         <source srcSet={`./images/new-adv/res/stone-${newData[s].name}-${newData[s].element}.png`} type="image/png" />
                                                        //                         <img src={`./images/new-adv/res/stone-${newData[s].name}-${newData[s].element}.png`} alt="" />
                                                        //                     </picture>
                                                        //                 </span>
                                                        //             </div>
                                                        //             <div className="one-rew-ress-name">
                                                        //                 <i>
                                                        //                     +{newData[s].sum.length * rewardX}
                                                        //                 </i>
                                                        //                 {newData[s].name}
                                                        //             </div>
                                                        //         </div>
                                                        //     )
                                                        // })
                                                        skillsName.map(skill => skill.count !== 0 && (
                                                            <div className="one-rew-ress">
                                                                <div className="one-rew-ress-thumb">
                                                                    <span>
                                                                        <picture>
                                                                            <source srcSet={`./images/skills/needres/${skill.name}.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/skills/needres/${skill.name}.png`} type="image/png" />
                                                                            <img src={`./images/skills/needres/${skill.name}.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                </div>
                                                                <div className="one-rew-ress-name">
                                                                    <i>
                                                                        +{skill.count}
                                                                    </i>
                                                                    {skill.name}
                                                                </div>
                                                            </div>
                                                        ))
                                                    }


                                                    <div className="one-rew-ress">
                                                        <div className="one-rew-ress-thumb">
                                                            <span>
                                                                <picture>
                                                                    <source srcSet={`./images/new-adv/exp.webp`} type="image/webp" />
                                                                    <source srcSet={`./images/new-adv/exp.png`} type="image/png" />
                                                                    <img src={`./images/new-adv/exp.png`} alt="" />
                                                                </picture>
                                                            </span>
                                                        </div>
                                                        <div className="one-rew-ress-name">
                                                            <i>+{countExp * rewardX}</i> exp
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>}

                                        <div className="show-all-rew">
                                            <button className="def-bt mid" onClick={() => props.showModalDetalRew('open')}>
                                                <span className="button-in">
                                                    <span className="button-name">
                                                        <span className="button-name-in">
                                                            <span className="button-name-value">
                                                                Details
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                                <div className="multi-reward-bot">
                                    <picture>
                                        <source srcSet={rewbotw} type="image/webp" />
                                        <source srcSet={rewbot} type="image/png" />
                                        <img src={rewbot} alt="" />
                                    </picture>
                                </div>
                            </div>

                            :

                            <div className="modal-to-adv-head">
                                <div className="modal-to-adv-top">

                                    <img src={`${modaltop}`} alt="" />

                                </div>

                                <div className="modal-to-adv-mid">
                                    <div className="get-new-rew-wrap">
                                        <div className={`rew-story story-${props.rewardModalText.animationNumber}`}>
                                            <div className="rew-story-in">
                                                <div className="story-tit">
                                                    {props.rewardModalText.title}
                                                </div>
                                                <div className="story-text">
                                                    {props.rewardModalText.text}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="get-new-rew-descr">
                                            <div className="get-new-rew-tit">
                                                YOUr REWARD
                                                {props.typeReward === 'bamboo' ?
                                                    <span> <CountUp end={finalNumber + weaponPay + jewPay} decimals={4} duration={2} /></span>
                                                    : null}
                                            </div>


                                            {props.typeReward === 'bamboo' ?
                                                /* bamboo reward */
                                                <div className="get-new-rew-bamboo">
                                                    <div className="get-new-rew-chest">
                                                        <div className="get-new-rew-chest-in">
                                                            <div className={`chest-wrap-in act${props.rewardModalText.animationNumber}`}>
                                                                <img className="chest" src={chest} alt="" />
                                                                {props.rewardModalText.animationNumber === 1 ? <img className={`chest1 active`} src={chest1} alt="" /> : null}
                                                                {props.rewardModalText.animationNumber === 2 ? <img className={`chest2 active`} src={chest2} alt="" /> : null}
                                                                {props.rewardModalText.animationNumber === 3 ? <img className={`chest3 active`} src={chest3} alt="" /> : null}
                                                                {props.rewardModalText.animationNumber === 4 ? <img className={`chest4 active`} src={chest4} alt="" /> : null}
                                                                {props.rewardModalText.animationNumber === 5 ? <img className={`chest5 active`} src={chest5} alt="" /> : null}
                                                                {props.rewardModalText.animationNumber === 6 ? <img className={`chest6 active`} src={chest6} alt="" /> : null}
                                                                {props.rewardModalText.animationNumber === 7 ? <img className={`chest7 active`} src={chest7} alt="" /> : null}
                                                                {props.rewardModalText.animationNumber === 8 ? <img className={`chest8 active`} src={chest8} alt="" /> : null}
                                                                {props.rewardModalText.animationNumber === 9 ? <img className={`chest9 active`} src={chest9} alt="" /> : null}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="get-new-rew-bamboo-list">
                                                        <ul>
                                                            <li>
                                                                <span>
                                                                    hero REWARD:
                                                                </span>
                                                                <i>
                                                                    <CountUp end={props.rewardModalText.finalNumber} decimals={4} duration={2} />
                                                                </i>
                                                            </li>
                                                            <li>
                                                                <span>
                                                                    Weapon bonus:
                                                                </span>
                                                                <i>
                                                                    <CountUp end={props.rewardModalText.weaponPay} decimals={4} duration={2} />
                                                                </i>
                                                            </li>
                                                            <li>
                                                                <span>
                                                                    Jewelry bonus:
                                                                </span>
                                                                <i>
                                                                    <CountUp end={props.rewardModalText.jewPay} decimals={4} duration={2} />
                                                                </i>
                                                            </li>
                                                            <li>
                                                                <span>
                                                                    gain Experience:
                                                                </span>
                                                                <i>
                                                                    {props.getReward.exp ? '0' : '+1'}
                                                                </i>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                /* THE END bamboo reward */
                                                :

                                                props.typeReward === 'resources' ?

                                                    /* resourse reward */
                                                    <div className="get-new-rew-ress">
                                                        <div className="get-new-rew-ress-in">

                                                            {
                                                                props.rewardModalText.resourceRew !== undefined &&
                                                                    props.rewardModalText.resourceRew.length ?
                                                                    props.rewardModalText.resourceRew.map((s, index) => {

                                                                        //console.log('s', s.name);
                                                                        if (s.name === 'wood') {
                                                                            return (
                                                                                <div key={index} className="one-rew-ress" >
                                                                                    <div className="one-rew-ress-thumb">
                                                                                        <span>
                                                                                            <picture>
                                                                                                <source srcSet={`./images/new-adv/res/res-common.webp`} type="image/webp" />
                                                                                                <source srcSet={`./images/new-adv/res/res-common.png`} type="image/png" />
                                                                                                <img src={`./images/new-adv/res/res-common.png`} alt="" />
                                                                                            </picture>
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="one-rew-ress-name">
                                                                                        <i>
                                                                                            +1
                                                                                        </i>
                                                                                        wood
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        } else if (s.name === 'steel') {
                                                                            return (
                                                                                <div key={index} className="one-rew-ress" >
                                                                                    <div className="one-rew-ress-thumb">
                                                                                        <span>
                                                                                            <picture>
                                                                                                <source srcSet={`./images/new-adv/res/res-uncommon.webp`} type="image/webp" />
                                                                                                <source srcSet={`./images/new-adv/res/res-uncommon.png`} type="image/png" />
                                                                                                <img src={`./images/new-adv/res/res-uncommon.png`} alt="" />
                                                                                            </picture>
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="one-rew-ress-name">
                                                                                        <i>
                                                                                            +1
                                                                                        </i>
                                                                                        steel
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        } else if (s.name === 'titawin') {
                                                                            return (
                                                                                <div key={index} className="one-rew-ress" >
                                                                                    <div className="one-rew-ress-thumb">
                                                                                        <span>
                                                                                            <picture>
                                                                                                <source srcSet={`./images/new-adv/res/res-rare.webp`} type="image/webp" />
                                                                                                <source srcSet={`./images/new-adv/res/res-rare.png`} type="image/png" />
                                                                                                <img src={`./images/new-adv/res/res-rare.png`} alt="" />
                                                                                            </picture>
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="one-rew-ress-name">
                                                                                        <i>
                                                                                            +1
                                                                                        </i>
                                                                                        titawin
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        } else if (s.name === 'kvessir') {
                                                                            return (
                                                                                <div key={index} className="one-rew-ress" >
                                                                                    <div className="one-rew-ress-thumb">
                                                                                        <span>
                                                                                            <picture>
                                                                                                <source srcSet={`./images/new-adv/res/res-epic.webp`} type="image/webp" />
                                                                                                <source srcSet={`./images/new-adv/res/res-epic.png`} type="image/png" />
                                                                                                <img src={`./images/new-adv/res/res-epic.png`} alt="" />
                                                                                            </picture>
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="one-rew-ress-name">
                                                                                        <i>
                                                                                            +1
                                                                                        </i>
                                                                                        kvessir
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        } else if (s.name === 'gold') {
                                                                            return (
                                                                                <div key={index} className="one-rew-ress" >
                                                                                    <div className="one-rew-ress-thumb">
                                                                                        <span>
                                                                                            <picture>
                                                                                                <source srcSet={`./images/new-adv/res/res-legendary.webp`} type="image/webp" />
                                                                                                <source srcSet={`./images/new-adv/res/res-legendary.png`} type="image/png" />
                                                                                                <img src={`./images/new-adv/res/res-legendary.png`} alt="" />
                                                                                            </picture>
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="one-rew-ress-name">
                                                                                        <i>
                                                                                            +1
                                                                                        </i>
                                                                                        gold
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        } else if (s.name === 'dwemer') {
                                                                            return (
                                                                                <div key={index} className="one-rew-ress" >
                                                                                    <div className="one-rew-ress-thumb">
                                                                                        <span>
                                                                                            <picture>
                                                                                                <source srcSet={`./images/new-adv/res/res-mythic.webp`} type="image/webp" />
                                                                                                <source srcSet={`./images/new-adv/res/res-mythic.png`} type="image/png" />
                                                                                                <img src={`./images/new-adv/res/res-mythic.png`} alt="" />
                                                                                            </picture>
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="one-rew-ress-name">
                                                                                        <i>
                                                                                            +1
                                                                                        </i>
                                                                                        dwemer
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        } else {

                                                                        }
                                                                    })
                                                                    :
                                                                    null
                                                            }

                                                            <div className="one-rew-ress">
                                                                <div className="one-rew-ress-thumb">
                                                                    <span>
                                                                        <picture>
                                                                            <source srcSet={`./images/new-adv/exp.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/new-adv/exp.png`} type="image/png" />
                                                                            <img src={`./images/new-adv/exp.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                </div>
                                                                <div className="one-rew-ress-name">
                                                                    <i>
                                                                        +1
                                                                    </i>
                                                                    exp
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    /* THE END resourse reward */

                                                    :

                                                    /* resourse reward */
                                                    <div className="get-new-rew-ress">
                                                        <div className="get-new-rew-ress-in">

                                                            {
                                                                props.rewardModalText.resourceRew !== undefined && props.rewardModalText.resourceRew.length ? props.rewardModalText.resourceRew.map((s, index) => {

                                                                    //console.log('s', s.name);
                                                                    // if(s.name === 'tihash') {
                                                                    return (
                                                                        <div className="one-rew-ress">
                                                                            <div className="one-rew-ress-thumb">
                                                                                <span>
                                                                                    <picture>
                                                                                        <source srcSet={`./images/new-adv/res/stone-${s.name}-${s.element}.webp`} type="image/webp" />
                                                                                        <source srcSet={`./images/new-adv/res/stone-${s.name}-${s.element}.png`} type="image/png" />
                                                                                        <img src={`./images/new-adv/res/stone-${s.name}-${s.element}.png`} alt="" />
                                                                                    </picture>
                                                                                </span>
                                                                            </div>
                                                                            <div className="one-rew-ress-name">
                                                                                <i>
                                                                                    +1
                                                                                </i>
                                                                                {s.name}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                    //} 
                                                                })
                                                                    :
                                                                    null
                                                            }

                                                            <div className="one-rew-ress">
                                                                <div className="one-rew-ress-thumb">
                                                                    <span>
                                                                        <picture>
                                                                            <source srcSet={`./images/new-adv/exp.webp`} type="image/webp" />
                                                                            <source srcSet={`./images/new-adv/exp.png`} type="image/png" />
                                                                            <img src={`./images/new-adv/exp.png`} alt="" />
                                                                        </picture>
                                                                    </span>
                                                                </div>
                                                                <div className="one-rew-ress-name">
                                                                    <i>
                                                                        +1
                                                                    </i>
                                                                    exp
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                /* THE END resourse reward */

                                            }

                                        </div>
                                    </div>
                                </div>

                                <div className="modal-to-adv-bot">
                                    <img src={`${modalbot}`} alt="" />

                                    <div className="modal-to-adv-bt">
                                        <button className="def-bt norm" onClick={() => props.openNewAdvMap('close')}>
                                            <span className="button-in">
                                                <span className="button-name">
                                                    <span className="button-name-in">
                                                        <span className="button-name-value">
                                                            Close
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>

                    <div className="modal-weapon-bg-trans"></div>
                </div>

                <div className="modal-weapon-bg" ></div>
            </div>

        </>);
}

export default GetReward;