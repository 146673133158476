import React, { useEffect, useRef } from "react";

import bt from './../../images/BOSS/detal-bt.png'
import btw from './../../images/BOSS/detal-bt.webp'
import opt1 from './../../images/BOSS/boss-skill-1.png'
import opt1w from './../../images/BOSS/boss-skill-1.webp'
import opt2 from './../../images/BOSS/boss-skill-2.png'
import opt2w from './../../images/BOSS/boss-skill-2.webp'
import opt3 from './../../images/BOSS/boss-skill-3.png'
import opt3w from './../../images/BOSS/boss-skill-3.webp'

const Info = (props) => {

    const widthScale = props.widthScale;
    const bossInfo = {
        element: {
            ico: [opt1, opt1w],
            size: [31, 30],
            name: "earth"
        },
        ability: {
            ico: [opt2, opt2w],
            size: [29, 30],
            name: "melee"
        },
        magic: {
            ico: [opt3, opt3w],
            size: [24, 29],
            name: "no"
        },
        race: {
            ico: [],
            size: [],
            name: "goblin"
        },
    }

    return (
        <>
            <div className="info-boss" style={
                {
                    width: widthScale * 403,
                    top: widthScale * 67,
                    right: widthScale * 150,
                }
            }>
                <div className="info-boss-bt" style={
                    {
                        width: widthScale * 104,
                        height: widthScale * 104
                    }
                }>
                    <picture>
                        <source srcSet={btw} type="image/webp" />
                        <source srcSet={bt} type="image/png" />
                        <img src={btw} alt="" />
                    </picture>
                    <span>
                        <picture>
                            <source srcSet={btw} type="image/webp" />
                            <source srcSet={bt} type="image/png" />
                            <img src={btw} alt="" />
                        </picture>
                    </span>
                </div>
                <div className="info-boss-descr" style={
                    {
                        top: widthScale * 40,
                    }
                }>
                    <div className="info-boss-descr-in">
                        <div className="info-boss-tit" style={
                            {
                                fontSize: widthScale * 44,
                                marginBottom: widthScale * 14
                            }
                        }>
                            nimnirax
                        </div>
                        <div className="info-boss-list" style={
                            {
                                fontSize: widthScale * 24,
                            }
                        }>
                            <ul>
                                {Object.entries(bossInfo).map(([key, { ico, name, size }]) => (
                                    <li key={key}>
                                        <span>
                                            {key}:
                                        </span>
                                        {ico.length &&
                                            <i>

                                                <picture>
                                                    <source srcSet={ico[1]} type="image/webp" />
                                                    <source srcSet={ico[0]} type="image/png" />
                                                    <img width={size[0] * widthScale} src={ico[0]} alt="" />
                                                </picture>

                                            </i>
                                        }
                                        <b>
                                            {name}

                                        </b>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="storys">
                            <div className="storys-tit" style={
                                {
                                    fontSize: widthScale * 36,
                                    marginBottom: widthScale * 14
                                }
                            }>
                                Story
                            </div>
                            <div className="storys-text" style={
                                {
                                    fontSize: widthScale * 22
                                }
                            }>
                                Nimnirax skull
                                crusher, a huge troll
                                who once inhabited
                                and terrorized the
                                Lands of Elgard but
                                was driven out by
                                the glorious Heroes
                                during the first
                                battles!
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};


export default Info;