import { ExplorerApi, RpcApi } from 'atomicassets';

const api = new ExplorerApi(
    "https://" + localStorage.getItem('rpcA'),
    "atomicassets",
    { fetch, rateLimit: 4 }
);

export const getAllNftOnWalletClass = async (accountName, shemas, page = 1, limit = 1000) => {
    try {
        const fetchAssets = async (page) => {
            const params = {
                limit: limit,
                page: page,
                owner: accountName,
                collection_name: process.env.REACT_APP_COLLECTION,
                schema_name: shemas,
            };

            const assets = await api.getAssets(params);

            if (assets && assets.length) {
                const filteredAssets = assets.map((item) => {
                    return {
                        asset_id: item.asset_id,
                        schema: {
                            schema_name: item.schema.schema_name
                        },
                        template: {
                            template_id: item.template.template_id,
                        },
                        data: {
                            rarity: item.data.Rarity,
                            img: item.data.img,
                            name: item.data.name,
                        },
                    };
                });
                return filteredAssets;
            }
            return [];
        };

        const allAssets = [];
        let currentPage = page;
        let hasMorePages = true;

        while (hasMorePages) {
            const assets = await fetchAssets(currentPage);

            if (assets.length === 0) {
                hasMorePages = false;
            } else {
                allAssets.push(...assets);
                if (assets.length === limit) {
                    currentPage += 1;
                } else {
                    hasMorePages = false;
                }
            }
        }

        let groupedAssets = {};

        allAssets.forEach((asset) => {
            const { schema_name } = asset.schema;
            if (!groupedAssets[schema_name]) {
                groupedAssets[schema_name] = schema_name === 'resources' ? {} : [];
            }

            if (schema_name === 'resources') {
                const template_id = asset.template.template_id;
                if (!groupedAssets[schema_name][template_id]) {
                    groupedAssets[schema_name][template_id] = [];
                }
                groupedAssets[schema_name][template_id].push(asset);
            } else {
                groupedAssets[schema_name].push(asset);
            }
        });

        let assetCount = {};

        for (let schema in groupedAssets) {
            let schemaAssets = groupedAssets[schema];

            // Если структура как у 'resources', то подсчитываем каждый template_id отдельно
            if (typeof schemaAssets === 'object' && !Array.isArray(schemaAssets)) {
                for (let template in schemaAssets) {
                    let templateAssets = schemaAssets[template];
                    assetCount[template] = templateAssets.length;
                }
            } else {
                // В остальных случаях просто подсчитываем общее количество
                assetCount[schema] = schemaAssets.length;
            }
        }

        groupedAssets["counts"] = assetCount;

        return groupedAssets;
    } catch (e) {
        console.warn(e);
        console.log("\nCaught exception: " + e);
        throw e; // We need to throw the error to be able to catch it later
    }
};
