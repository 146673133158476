import React from 'react';
import Countdown from 'react-countdown';
import PandaVideoComp from './../PandaVideoComp';

function backside(num) {
    //console.log(4);
    var items = document.getElementsByClassName('one-slot');
    items[num].classList.toggle("active");
}

const caseAlienTmpl = (tmpl) => {
    var sum;
    switch (tmpl) {
        case 141561: case 141555: case 141554: case 141553: case 141551: case 141545: case 141544: case 141543: case 141540: 
        case 141533: case 141532: case 141531: case 141528: case 141520: case 141519: case 141518: case 19647: case 19640: 
        case 19639: case 19638: case 19635: case 19628: case 19627: case 19626: case 141484: case 141483: case 141480: case 141477: 
        case 141470: case 141460: case 141457: case 141454: case 141452: 
        case 141451: case 141450: case 141445: case 141444: case 141440: case 141437: case 141428: case 141418: case 141415: 
        case 141412: case 141410: case 141409: case 141408: case 19621: case 19620: case 19616: case 19613: case 19604: 
        case 19594: case 19591: case 19588: case 19586: case 19585: case 19584:
            sum = "common";
        break;

        case 254356: case 254354: case 254352: case 254350: case 235646: case 235644: case 235642: case 235640: case 141574: 
        case 141572: case 141570: case 141568: case 141566: case 141564: case 141560: case 141558: case 141556: case 141550: 
        case 141548: case 141546: case 141539: case 141536: case 141534: case 141527: case 141524: case 141522: case 20993: 
        case 20992: case 20991: case 20990: case 20989: case 20988: case 19646: case 19643: case 19641: case 19634: 
        case 19631: case 19629: case 141511: case 141505: case 141504: case 141503: case 141502: case 141501: case 141496: case 141492: case 141491: 
        case 141490: case 141489: case 141481: case 141473: case 141472: case 141471: case 141469: case 141468: case 141463: 
        case 141458: case 141456: case 141455: case 141453: case 141441: case 141431: case 141430: case 141429: case 141427: 
        case 141426: case 141421: case 141416: case 141414: case 141413: case 141411: case 20983: case 20982: case 20981: 
        case 20980: case 20979: case 20978: case 20977: case 20976: case 20975: case 20974: case 20973: case 19617: 
        case 19607: case 19606: case 19605: case 19603: case 19602: case 19597: case 19592: case 19590: case 19589: case 19587:
            sum = "rare";
        break;

        case 254357: case 254355: case 254353: case 254351: case 235647: case 235645: case 235643: case 235641: case 141573: 
        case 141569: case 141567: case 141563: case 141562: case 141559: case 141552: case 141549: case 141542: case 141541: 
        case 141538: case 141529: case 141526: case 141516: case 44933: case 28422: case 19645: case 19636: case 19633: case 19624:
        case 141513: case 141510: case 141508: case 141500: case 141498: case 141495: case 141494: case 141493: case 141485: 
        case 141479: case 141476: case 141467: case 141465: case 141462: case 141461: case 141459: case 141446: case 141439: 
        case 141436: case 141425: case 141423: case 141420: case 141419: case 141417: case 19622: case 19615: case 19612: 
        case 19601: case 19599: case 19596: case 19595: case 19593:
            sum = "Epic";
        break;
        
        case 141571: case 141565: case 141557: case 141547: case 141535: case 141523: case 28424: case 28423: case 19642: 
        case 19630:
        case 141515: case 141512: case 141507: case 141506: case 141499: case 141497: case 141487: case 141482: case 141475: 
        case 141474: case 141466: case 141464: case 141448: case 141442: case 141435: case 141432: case 141424: case 141422: 
        case 56042: case 19618: case 19611: case 19608: case 19600: case 19598:
            sum = "Legendary";
        break;

        case 141514: case 141509: case 141488: case 141486: case 141478: case 141449: case 141447: case 141438: case 141406: 
        case 19623: case 19614: case 19582:
            sum = "Mithical";
        break;

        default: 
        sum = 0;
        break;
    }

    return sum
}


const Slots = (props) => {
    console.log("userRow", props.userRow);
    console.log("userSlotsAlien", props.userSlotsAlien);
    console.log("alienOnGame", props.alienOnGame);
    console.log("userSlotsPanda", props.userSlotsPanda);
    console.log("props.pandasInSlot", props.pandasInSlot);

    return (<>
        {props.userSlotsAlien.map((item, index) => {
        //console.log("slot number", index);
        if (index < props.userRow ) {
            //console.log("has alien or panda");
            //console.log("item",item);
            //console.log("userSlotsPanda[index]", props.userSlotsPanda[index]);
            var onePanda = props.userSlotsPanda[index];
            var onePandaName;
            var oneAlienInfo;
            var timer = false;
            var alienRarName = "";

            if (props.alienOnGame && props.alienOnGame.length) {
                for (let i = 0; i < props.alienOnGame.length; i++) {
                    if (item === props.alienOnGame[i].asset_id) {
                        oneAlienInfo = props.alienOnGame[i];

                        
                        if (Date.now() < oneAlienInfo.timer * 1000) {
                            timer = true;
                        }
                    }
                }
            }

            console.log("oneAlienInfo", oneAlienInfo);

            if(item) {
                alienRarName = caseAlienTmpl(parseInt(oneAlienInfo.tmpl));
            }
            //console.log("oneAlienInfo", oneAlienInfo);
            console.log("onePanda", onePanda);
            
            if(onePanda) {
                if (props.pandasInSlot && props.pandasInSlot.length) {
                    for (let i = 0; i < props.pandasInSlot.length; i++) {
                        if (onePanda === props.pandasInSlot[i].asset_id) {
                            onePanda = props.pandasInSlot[i];
                            onePandaName = onePanda.name_pa;
                            onePandaName = onePandaName.substring(onePandaName.indexOf("-") + 1)
                        }
                    }
                }

                var pandaRar = onePanda.rarity;  // (NFT) rare
                var pandaLvl = onePanda.lvl; // (РАНГ) 8.67 далдно быть застейкано

                var nameRare = '';
                switch (pandaLvl) {
                    case 0:
                        nameRare = "Rookie"
                        break;
                    case 1:
                        nameRare = "Officer-cadet"
                        break;
                    case 2:
                        nameRare = "Second-Lieutenant"
                        break;
                    case 3:
                        nameRare = "Lieutenant"
                        break;
                    case 4:
                        nameRare = "Captain"
                        break;
                    case 5:
                        nameRare = "Major"
                        break;
                    case 6:
                        nameRare = "Lieutenant-Colonel"
                        break;
                    case 7:
                        nameRare = "Colonel"
                        break;
                    case 8:
                        nameRare = "Brigadier"
                        break;
                    case 9:
                        nameRare = "Major-General"
                        break;
                    case 10:
                        nameRare = "Lieutenant-General"
                        break;
                    case 11:
                        nameRare = "General"
                        break;
                    case 12:
                        nameRare = "legend"
                        break;
                    default:
                        nameRare = "NOBADY"
                        break;
                }

                var nameRarePanda = '';
                switch (pandaRar) {
                    case 0:
                        nameRarePanda = "Common"
                        break;
                    case 1:
                        nameRarePanda = "Promo"
                        break;
                    case 2:
                        nameRarePanda = "Uncommon"
                        break;
                    case 3:
                        nameRarePanda = "Rare"
                        break;
                    case 4:
                        nameRarePanda = "Epic"
                        break;
                    case 5:
                        nameRarePanda = "Legendary"
                        break;
                    case 6:
                        nameRarePanda = "Mythic"
                        break;
                    default:
                        nameRarePanda = "NOBADY"
                        break;
                }
            
                var cardRare;
                if (onePandaName.substring(0, 1) == "A") {
                    cardRare = "serial-1-"
                } else if (onePandaName.substring(0, 1) == "N") {
                    cardRare = "serial-2-"
                } else if (onePandaName.substring(0, 1) == "P") {
                    cardRare = "serial-3-"
                }

                var imgRare = nameRarePanda.toLowerCase();
                if (imgRare == "promo") {
                    imgRare = "common";
                }
            }

            return (
                <div key={index} className="one-slot" >
                    <div className={`one-slot-in ${props.openMultiSt ? 'mult-active' : ''}`}>
                        <div className="one-slot-number">
                            Slot
                            <span>
                                {index + 1}
                            </span>
                        </div>

                        <div className="one-alien-slot">
                            <div className="left-slot">
                                <div className={`alien-thumb`}>
                                    {item ?
                                    <span className='hasnft'>
                                        <div className="alien-info tooltip">
                                            <div className="cust-tooltip right">
                                                <div className="tool-arrow"></div>
                                                <div className="tool-wrap">
                                                    <div className="tool-wrap-1">
                                                        <div className="tool-wrap-2">
                                                            <div className="tool-body">
                                                                <span>ASSET ID: </span>
                                                                {oneAlienInfo.asset_id} <br />
                                                                <span>Rarity: </span>
                                                                {alienRarName}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <picture>
                                            <source srcSet={`/images/alien/all/${oneAlienInfo.tmpl}.gif`} type="image/gif" />
                                            <source srcSet={`/images/alien/all/${oneAlienInfo.tmpl}.gif`} type="image/gif" /> 
                                            <img src={`/images/alien/all/${oneAlienInfo.tmpl}.gif`} alt="" />
                                        </picture>
                                    </span>
                                    :
                                    <span>
                                        <div className="choose-new-hiro">
                                            
                                        </div>
                                    </span>
                                    }
                                </div>
                            </div>
                            <div className="right-slot">
                                <div className={`alien-thumb ${localStorage.getItem("animation") == "true" ? "anim" : "notanim"}`}>
                                    {onePanda ? 
                                    <span className='hasnft'>
                                        <div className="alien-info tooltip">
                                            <div className="cust-tooltip right">
                                                <div className="tool-arrow"></div>
                                                <div className="tool-wrap">
                                                    <div className="tool-wrap-1">
                                                        <div className="tool-wrap-2">
                                                            <div className="tool-body">
                                                                <span>ASSET ID: </span>
                                                                {onePanda.asset_id} <br />
                                                                <span>Rarity: </span>
                                                                {nameRarePanda}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <PandaVideoComp fullNameRare={onePanda.name_pa} pandaName={onePandaName} cardName={cardRare + imgRare} element={onePanda.element} />
                                    </span>
                                    : 
                                    <span>
                                        <div className="choose-new-hiro">
                                            
                                        </div>
                                    </span>
                                    }
                                </div>
                            </div>
                           
                        </div>
                    

                    
                    </div>
                </div>
            )
        } else if (index === props.userRow) {
            return (
                <div key={index} className="one-slot dis" >
                    <div className="one-slot-in">

                        <div className="one-slot-number">
                            Slot
                            <span>
                                {index + 1}
                            </span>
                        </div>
                        <div className="one-slot-body">
                            <div className="one-slot-nft-card">

                            </div>
                        </div>

                        <div className="choose-new-hiro">
                           
                        </div>

                    </div>
                </div>
            )
        } else {
            return (
                <div key={index} className="one-slot dis sec" >
                    <div className="one-slot-in">

                        <div className="one-slot-number">
                            Slot
                            <span>
                                {index + 1}
                            </span>
                        </div>
                        <div className="one-slot-body">
                            <div className="one-slot-nft-card">

                            </div>
                        </div>
                    </div>
                </div>
            )
        }
       

        })
        }
    </>);

}

export default Slots;