import React from "react";
import {
  /*BrowserRouter, Redirect, Route, Switch,*/ Link,
  NavLink,
} from "react-router-dom";
import ErrorComponent from "./../pages/Error/ErrorComponent";
import logoHeader from "../images/logo-header.png"; // relative path to image
import logoHeaderW from "../images/logo-header.webp"; // relative path to image
import newyear from "../images/lights-def.png"; // relative path to image
import newyearwebp from "../images/lights-def.webp"; // relative path to image
import newyearact from "../images/lights-act.png"; // relative path to image
import newyearactwebp from "../images/lights-act.webp"; // relative path to image
import avatar from "../images/avat.svg"; // relative path to image
import music from "../music/panda-track-cut.mp3";
import musicSwitch from "../music/switch.mp3";
import musicOpen from "../music/open.mp3";
import musicClose from "../music/close.mp3";
import musicInOut from "../music/in-out.mp3";
import musicError from "../music/error.mp3";

//import anonce from '../images/auction/anonce-5.jpg';
//import anoncew from '../images/auction/anonce-5.webp';

import tavern from "../images/tavern-modal.png";
import tavernw from "../images/tavern-modal.webp";

import Wallet from "./../components/Wallet";

var audioMusicSwitch = new Audio(musicSwitch);

class PlayMusic extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      play: false,
      startplay: false,
    };
    this.audio = new Audio(music);

    this.audio.addEventListener(
      "ended",
      function () {
        //console.log(this.currentTime);
        this.currentTime = 28;
        this.play();
      },
      false
    );
    this.togglePlay = this.togglePlay.bind(this);
    this.audioMusicSwitch = new Audio(musicSwitch);

    //console.log('props.match.params');
  }

  componentDidMount() {
    document.addEventListener("click", (e) => {
      const musicPlay = localStorage.getItem("music") === "true";
      if (!this.state.startplay && !musicPlay) {
        this.setState({ startplay: true });
        this.setState({ play: true });
        this.audio.volume = 0.1;
        //this.audio.play()
      }
    });

    if (!this.props.activeUser) {
      this.audio.pause();
    }
  }

  componentWillUnmount() {
    this.audio.pause();
  }

  togglePlay() {
    this.audioMusicSwitch.currentTime = 0;
    this.audioMusicSwitch.volume = 0.1;
    this.audioMusicSwitch.play();

    const wasPlaying = this.state.play;
    this.setState({
      play: !wasPlaying,
    });

    if (wasPlaying) {
      this.audio.pause();
      localStorage.setItem("music", true);
    } else {
      this.audio.play();
      localStorage.setItem("music", false);
    }
  }

  render() {
    return (
      <div className="music-bt">
        <div className="music-bt-name">music</div>
        <button
          id="audioBtn"
          className={this.state.play ? "active" : "def"}
          onClick={this.togglePlay}
        >
          <div className="music-status">
            <span>{this.state.play ? "ON" : "OFF"}</span>
          </div>
          {/* <div className="music-circ"></div> */}
        </button>
      </div>
    );
  }
}

class PlaySound extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      valSound: true,
    };

    this.togglePlay = this.togglePlay.bind(this);
    this.audioMusicSwitch = new Audio(musicSwitch);
  }

  componentDidMount() {
    //console.log('sound1');
    //console.log(localStorage.getItem('sound'));
    if (localStorage.getItem("sound") === "true") {
      //console.log(111);
      this.setState({
        valSound: true,
      });
    } else if (localStorage.getItem("sound") === "false") {
      //console.log(222);
      this.setState({
        valSound: false,
      });
    } else {
      localStorage.setItem("sound", true);
    }
  }

  componentDidUpdate() {
    //console.log('sound2');
    //console.log(localStorage.getItem('sound'));
    if (this.state.valSound && !localStorage.getItem("sound")) {
      if (localStorage.getItem("sound") === "true") {
        this.setState({ valSound: true });
      } else if (localStorage.getItem("sound") === "false") {
        this.setState({ valSound: false });
      }
    } else if (localStorage.getItem("sound") === "null") {
      localStorage.setItem("sound", true);
      this.setState({ valSound: false });
    }
  }

  componentWillUnmount() { }

  togglePlay() {
    this.audioMusicSwitch.currentTime = 0;
    this.audioMusicSwitch.volume = 0.1;
    this.audioMusicSwitch.play();

    const wasPlaying = this.state.valSound;
    this.setState({
      valSound: !wasPlaying,
    });

    if (wasPlaying) {
      localStorage.setItem("sound", false);
    } else {
      localStorage.setItem("sound", true);
    }
  }

  render() {
    return (
      <div className="music-bt">
        <div className="music-bt-name">Sound</div>
        <button
          id="audioBtn"
          className={this.state.valSound ? "active" : "def"}
          onClick={this.togglePlay}
        >
          <div className="music-status">
            <span>{this.state.valSound ? "ON" : "OFF"}</span>
          </div>
          {/* <div className="music-circ"></div> */}
        </button>
      </div>
    );
  }
}

// это транзакция стейкинга НФТ
const addBamToGame = {
  actions: [
    {
      account: process.env.REACT_APP_TOKEN,
      name: "transfer",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: {
        from: "",
        to: process.env.REACT_APP_CONTRACT,
        quantity: "",
        memo: "",
      },
    },
  ],
};

const removeBamFromGame = {
  actions: [
    {
      account: process.env.REACT_APP_CONTRACT,
      name: "bamout",
      authorization: [
        {
          actor: "", // use account that was logged in
          permission: "active",
        },
      ],
      data: { username: "", bam_sum: "" },
    },
  ],
};

class Menu extends React.Component {
  //export const Menu = (props) => {

  constructor(props) {
    super(props);

    this.state = {
      modalBal: {
        openModal: false,
        showAnimModal: false,
        activeTab: 1,
        error: false,
        success: false,
        errorMes: "",
      },
      inputBal: "",
      hasError: false, // ошибка транзакции
      errorText: "",
      openTavModal: false,
      opendrop: false,
      activeNoda: "wax.pink.gg",
      openLk: false,
      activeNodaAtomic: "wax.api.atomicassets.io",
      opendropA: false,
    };

    this.showCloseModalBal = this.showCloseModalBal.bind(this);
    this.changeTab = this.changeTab.bind(this);

    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleChangeInputUn = this.handleChangeInputUn.bind(this);
    this.removeBamFromGame = this.removeBamFromGame.bind(this);
    this.addBamToGame = this.addBamToGame.bind(this);
    this.clearError = this.clearError.bind(this);
    this.allSum = this.allSum.bind(this);
    this.showCloseModalTav = this.showCloseModalTav.bind(this);
    this.chooseNode = this.chooseNode.bind(this);
    this.chooseNodeAtomic = this.chooseNodeAtomic.bind(this);
    this.clickBg = this.clickBg.bind(this);
    this.toggleAnimation = this.toggleAnimation.bind(this);

    this.audioOpen = new Audio(musicOpen);
    this.audioClose = new Audio(musicClose);
    this.audioMusicInOut = new Audio(musicInOut);
    this.audioMusicError = new Audio(musicError);
  }

  toggleAnimation() {
    console.log("click animation", localStorage.getItem("animation"));
    console.log("type", typeof localStorage.getItem("animation"));
    if (
      localStorage.getItem("animation") == null ||
      localStorage.getItem("animation") == "true"
    ) {
      localStorage.setItem("animation", false);
      this.setState({
        animation: false,
      });
      window.location.reload();
    } else if (localStorage.getItem("animation") == "false") {
      localStorage.setItem("animation", true);
      this.setState({
        animation: true,
      });
      window.location.reload();
    }
  }

  componentDidMount() {
    // if(!localStorage.getItem('tavernModal3')) {
    //   this.showCloseModalTav("open");
    // }
    if (localStorage.getItem("animation") === null) {
      localStorage.setItem("animation", "false");
    }

    if (!localStorage.getItem("rpc")) {
      localStorage.setItem("rpc", "wax.pink.gg");
    } else {
      this.setState({
        activeNoda: localStorage.getItem("rpc"),
      });
    }

    if (!localStorage.getItem("rpcA")) {
      localStorage.setItem("rpcA", "wax.api.atomicassets.io");
    } else {
      this.setState({
        activeNodaAtomic: localStorage.getItem("rpcA"),
      });
    }
  }

  componentDidUpdate() {
    // if(localStorage.getItem('tavernModal3') && this.state.openTavModal) {
    //   this.showCloseModalTav("close");
    // }
  }

  async clearError() {
    this.setState({
      errorText: "",
      hasError: false,
    });
  }

  showCloseModalBal(method) {
    document.getElementsByTagName("body")[0].classList.add("open-modal");

    if (method === "open") {
      this.setState({
        ...this.state,
        modalBal: {
          ...this.state.modalBal,
          openModal: true,
        },
      });
      setTimeout(() => {
        this.setState({
          modalBal: {
            ...this.state.modalBal,
            showAnimModal: true,
          },
        });
      }, 300);

      if (localStorage.getItem("sound") === "true") {
        this.audioOpen.currentTime = 0;
        this.audioOpen.volume = 0.1;
        this.audioOpen.play();
      }
    } else {
      document.getElementsByTagName("body")[0].classList.remove("open-modal");
      this.setState({
        modalBal: {
          ...this.state.modalBal,
          openModal: false,
          showAnimModal: false,
          success: false,
          error: false,
          errorMes: "",
        },
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioClose.currentTime = 0;
        this.audioClose.volume = 0.1;
        this.audioClose.play();
      }
    }
  }

  changeTab(tab) {
    this.setState({
      ...this.state,
      modalBal: {
        ...this.state.modalBal,
        activeTab: tab,
        error: false,
        success: false,
        errorMes: "",
      },
      inputBal: "",
    });
    if (localStorage.getItem("sound") === "true") {
      this.audioOpen.currentTime = 0;
      this.audioOpen.volume = 0.1;
      this.audioOpen.play();
    }
  }

  allSum(sum) {
    this.setState({
      ...this.state,
      inputBal: sum,
    });
  }

  handleChangeInput(event) {
    if (event.target.value >= 0) {
      this.setState({
        inputBal: event.target.value,
      });
    }
  }

  handleChangeInputUn(event) {
    if (event.target.value >= 0) {
      this.setState({
        inputBal: event.target.value,
      });
    }
  }

  async addBamToGame(moneysum) {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");

    //console.log(' this.props.activeUser',  this.props);

    const userName = this.props.activeUser.accountName;
    const activeUser = this.props.activeUser;
    addBamToGame.actions[0].authorization[0].actor = userName;
    addBamToGame.actions[0].data.from = userName;
    addBamToGame.actions[0].data.quantity =
      parseFloat(moneysum).toFixed(4) + " " + process.env.REACT_APP_VAL;
    addBamToGame.actions[0].data.memo = "sendtogame ";
    //console.log(addBamToGame);

    try {
      await activeUser
        .signTransaction(addBamToGame, { blocksBehind: 3, expireSeconds: 30 })
        .then((response) => {
          if (response.status === "executed") {
            loadingSlot.classList.remove("loading");
            this.setState({
              ...this.state,
              modalBal: {
                ...this.state.modalBal,
                error: false,
                success: true,
                errorMes: "You have successfully added " + process.env.REACT_APP_VAL,
              },
            });

            this.props.updateBalance(
              true,
              parseFloat(moneysum).toFixed(4),
              true
            );
            //console.log(response.status);
            if (localStorage.getItem("sound") === "true") {
              this.audioMusicInOut.currentTime = 0;
              this.audioMusicInOut.volume = 0.1;
              this.audioMusicInOut.play();
            }
          }
        });
    } catch (error) {
      //console.log(error.message)
      loadingSlot.classList.remove("loading");
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  async removeBamFromGame(moneysum) {
    var loadingSlot = document.getElementsByTagName("body")[0];
    loadingSlot.classList.add("loading");

    const userName = this.props.activeUser.accountName;
    const activeUser = this.props.activeUser;
    removeBamFromGame.actions[0].authorization[0].actor = userName;
    removeBamFromGame.actions[0].data.username = userName;
    removeBamFromGame.actions[0].data.bam_sum =
      parseFloat(moneysum).toFixed(4) + " " + process.env.REACT_APP_VAL;
    //console.log(removeBamFromGame);

    try {
      await activeUser
        .signTransaction(removeBamFromGame, {
          blocksBehind: 3,
          expireSeconds: 30,
        })
        .then((response) => {
          if (response.status === "executed") {
            loadingSlot.classList.remove("loading");
            this.setState({
              ...this.state,
              modalBal: {
                ...this.state.modalBal,
                success: true,
                error: false,
                errorMes: "You have successfully withdraw " + process.env.REACT_APP_VAL,
              },
            });
            this.props.updateBalance(
              false,
              parseFloat(moneysum).toFixed(4),
              true
            );
            //console.log(response.status);
            if (localStorage.getItem("sound") === "true") {
              this.audioMusicInOut.currentTime = 0;
              this.audioMusicInOut.volume = 0.1;
              this.audioMusicInOut.play();
            }
          } else {
            //console.log(77777777777777777777)
            //console.log(response);
          }
        });
    } catch (error) {
      //console.log(error.message)
      console.warn(error);
      loadingSlot.classList.remove("loading");
      console.warn(error);
      this.setState({
        hasError: true,
        errorText: error.message,
      });
      if (localStorage.getItem("sound") === "true") {
        this.audioMusicError.currentTime = 0;
        this.audioMusicError.volume = 0.1;
        this.audioMusicError.play();
      }
    }
  }

  clickMenu = (event) => {
    if (window.innerWidth < 1410) {
      //console.log(event);
      //event.preventDefault();
      //var currentEventTarget = event.currentTarget;
      var menu = document.getElementsByClassName("show-hide-bt")[0];
      //console.log(menu);
      if (!menu.classList.contains("open")) {
        document.getElementsByClassName("header-menu")[0].classList.add("open");
        document.getElementsByClassName("bg-plane")[0].classList.add("open");
        document
          .getElementsByClassName("show-hide-bt ")[0]
          .classList.add("open");
        document
          .getElementsByClassName("header-lk")[0]
          .classList.remove("open");
        document
          .getElementsByClassName("header-lk-top")[0]
          .classList.remove("open");
      } else {
        document
          .getElementsByClassName("header-menu")[0]
          .classList.remove("open");
        document.getElementsByClassName("bg-plane")[0].classList.remove("open");
        document
          .getElementsByClassName("show-hide-bt ")[0]
          .classList.remove("open");
        document
          .getElementsByClassName("header-lk")[0]
          .classList.remove("open");
        document
          .getElementsByClassName("header-lk-top")[0]
          .classList.remove("open");
      }
      //console.log(currentEventTarget);
    }

    if (localStorage.getItem("sound") === "true") {
      audioMusicSwitch.currentTime = 0;
      audioMusicSwitch.volume = 0.1;
      audioMusicSwitch.play();
    }
  };

  clickAvatar = (event) => {
    //if (window.innerWidth < 768) {
    // if(event.target.classList.contains('open')) {
    //   document.getElementsByClassName('header-lk')[0].classList.remove("open");
    //   document.getElementsByClassName('header-lk-top')[0].classList.remove("open");
    //   document.getElementsByClassName('bg-plane')[0].classList.remove("open");
    //   document.getElementsByClassName('header-menu')[0].classList.remove("open");
    //   document.getElementsByClassName('show-hide-bt ')[0].classList.remove("open");
    // }
    // else {
    //   document.getElementsByClassName('header-lk')[0].classList.add("open");
    //   document.getElementsByClassName('header-lk-top')[0].classList.add("open");
    //   document.getElementsByClassName('header-menu')[0].classList.remove("open");
    //   document.getElementsByClassName('show-hide-bt ')[0].classList.remove("open");
    //   document.getElementsByClassName('bg-plane')[0].classList.add("open");
    // }
    if (this.state.openLk) {
      this.setState({
        ...this.state,
        openLk: false,
      });
      document.getElementsByClassName("bg-plane")[0].classList.remove("open");
      document
        .getElementsByClassName("header-menu")[0]
        .classList.remove("open");
      document
        .getElementsByClassName("show-hide-bt ")[0]
        .classList.remove("open");
    } else {
      this.setState({
        ...this.state,
        openLk: true,
      });

      document
        .getElementsByClassName("header-menu")[0]
        .classList.remove("open");
      document
        .getElementsByClassName("show-hide-bt ")[0]
        .classList.remove("open");
      document.getElementsByClassName("bg-plane")[0].classList.add("open");
    }

    if (localStorage.getItem("sound") === "true") {
      audioMusicSwitch.currentTime = 0;
      audioMusicSwitch.volume = 0.1;
      audioMusicSwitch.play();
    }
  };

  showCloseModalTav(method) {
    if (method == "open") {
      document.getElementsByTagName("body")[0].classList.add("open-modal");
      this.setState({
        ...this.state,
        openTavModal: true,
      });
    } else {
      document.getElementsByTagName("body")[0].classList.remove("open-modal");
      this.setState({
        ...this.state,
        openTavModal: false,
      });
      localStorage.setItem("tavernModal3", true);
    }
  }

  async openDrop(event) {
    if (!this.state.opendrop) {
      this.setState({
        ...this.state,
        opendrop: true,
      });
    } else {
      this.setState({
        ...this.state,
        opendrop: false,
      });
    }
  }

  async openDropA(event) {
    if (!this.state.opendrop) {
      this.setState({
        ...this.state,
        opendropA: true,
      });
    } else {
      this.setState({
        ...this.state,
        opendropA: false,
      });
    }
  }

  async chooseNode(noda) {
    this.setState({
      ...this.state,
      activeNoda: noda,
      opendrop: false,
    });
    localStorage.setItem("rpc", noda);
  }

  async chooseNodeAtomic(noda) {
    this.setState({
      ...this.state,
      activeNodaAtomic: noda,
      opendropA: false,
    });
    localStorage.setItem("rpcA", noda);
  }

  clickBg = (event) => {
    document.getElementsByClassName("header-menu")[0].classList.remove("open");
    document.getElementsByClassName("bg-plane")[0].classList.remove("open");
    document.getElementsByClassName("show-hide-bt")[0].classList.remove("open");

    this.setState({
      ...this.state,
      opendrop: false,
      openLk: false,
    });
  };

  render() {
    var bamBal = 0;
    var walletBal = 0;

    if (this.props.game !== null && this.props.bam !== null) {
      bamBal = Number(this.props.game);
      bamBal = bamBal.toFixed(2);
      walletBal = Number(this.props.bam);
      walletBal = walletBal.toFixed(2);
    }

    return (
      <>
        {this.state.openTavModal && (
          <div className="tav-modal">
            <div className="tav-modal-wrap">
              <div className="tav-modal-body">
                <div className="tav-modal-body-in">
                  <div
                    className="modal-all-weapon-close"
                    onClick={() => this.showCloseModalTav("close")}
                  >
                    <span></span>
                  </div>
                  <picture>
                    <source srcSet={tavernw} type="image/webp" />
                    <source srcSet={tavern} type="image/png" />
                    <img src={tavern} alt="" />
                  </picture>
                  <div className="go-to-tavern">
                    <NavLink className="def-bt mid" to="/tavern">
                      <span className="button-in">
                        <span className="button-name">
                          <span className="button-name-in">
                            <span className="button-name-value">enter</span>
                          </span>
                        </span>
                      </span>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="tav-modal-bg"></div>
          </div>
        )}

        <div className="bg-plane" onClick={this.clickBg}></div>

        {this.state.hasError ? (
          <ErrorComponent
            errorMessage={this.state.errorText}
            clearError={this.clearError}
          />
        ) : null}

        {this.props.activeUser && (
          <header>
            <div className="container">
              <div className="header-in">
                <div className="show-mobile-menu">
                  <button
                    className="show-hide-bt def-bt min"
                    onClick={this.clickMenu}
                  >
                    <span className="button-in">
                      <span className="button-name">
                        <span className="button-name-in">
                          <span className="button-name-value">
                            <i></i>
                            <i></i>
                            <i></i>
                          </span>
                        </span>
                      </span>
                    </span>
                  </button>
                </div>

                <div className="header-logo">

                  <picture>
                    <source srcSet={logoHeaderW} type="image/webp" />
                    <source srcSet={logoHeader} type="image/png" />
                    <img src={logoHeader} alt="" />
                  </picture>
                </div>
                <div className={`header-lk ${this.state.openLk && "open"}`}>
                  <div
                    className={`header-lk-top ${this.state.openLk && "open"}`}
                  >
                    <i onClick={this.clickAvatar}></i>
                    <div className="header-lk-avat" onClick={this.clickAvatar}>
                      <div className="if-close"></div>
                      <img width="78" src={avatar} alt="" />
                    </div>
                    <div className="header-lk-info">
                      <div className="header-wall">
                        <a
                          target="_blank"
                          href={`https://wax.bloks.io/account/${this.props.activeUser.accountName}`}
                        >
                          {this.props.activeUser.accountName}
                        </a>
                        <div className="bay-bam-bt">
                          <span
                            onClick={() => this.showCloseModalBal("open")}
                          ></span>
                        </div>
                      </div>
                      <div className="header-list">
                        <div className="header-list-one bam-header game">
                          {process.env.REACT_APP_VAL}:
                          <span>
                            {" "}
                            {bamBal}
                            <span className="add-curr">
                              <a
                                target="_blank"
                                href="https://wax.alcor.exchange/trade/bam-nftpandabamb_wax-eosio.token"
                              ></a>
                            </span>
                          </span>
                        </div>
                        <div className="header-list-one bam-header wallet">
                          {process.env.REACT_APP_VAL}:
                          <span>
                            {" "}
                            {walletBal}
                            <span className="add-curr">
                              <span
                                onClick={() => this.showCloseModalBal("open")}
                              ></span>
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="header-lk-drop">
                    <div className="header-lk-drop-in">
                      <div className="header-wall">
                        <a
                          target="_blank"
                          href={`https://wax.bloks.io/account/${this.props.activeUser.accountName}`}
                        >
                          {this.props.activeUser.accountName}
                        </a>
                        <div className="bay-bam-bt">
                          <span
                            onClick={() => this.showCloseModalBal("open")}
                          ></span>
                        </div>
                      </div>
                      <div className="header-list">
                        <div className="header-list-one bam-header game">
                          {process.env.REACT_APP_VAL}:
                          <span>
                            {" "}
                            {bamBal}
                            <span className="add-curr">
                              <a
                                target="_blank"
                                href="https://wax.alcor.exchange/trade/bam-nftpandabamb_wax-eosio.token"
                              ></a>
                            </span>
                          </span>
                        </div>
                        <div className="header-list-one bam-header wallet">
                          {process.env.REACT_APP_VAL}:
                          <span>
                            {" "}
                            {walletBal}
                            <span className="add-curr">
                              <span
                                onClick={() => this.showCloseModalBal("open")}
                              ></span>
                            </span>
                          </span>
                        </div>
                      </div>

                      <div className="choose-node-wrap">
                        <div className="sub-tit-node">Blockchain Node</div>
                        <div
                          className={`choose-node chain ${this.state.opendrop ? "open" : "close"
                            }`}
                        >
                          <div
                            className="choose-sel-act"
                            tabIndex="0"
                            onClick={() => this.openDrop("open")}
                          >
                            {this.state.activeNoda}
                            <i>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 59 49.5"
                              >
                                <g id="Слой_2" data-name="Слой 2">
                                  .
                                  <g
                                    id="Слой_3_копия_"
                                    data-name="Слой 3 (копия)"
                                  >
                                    <g id="Слой_5" data-name="Слой 5">
                                      <rect
                                        className="drop-cl-1"
                                        width="59"
                                        height="49.5"
                                        rx="5"
                                      />
                                      <rect
                                        className="drop-cl-2"
                                        x="4.67"
                                        y="3"
                                        width="49.62"
                                        height="40"
                                      />
                                      <polygon
                                        className="drop-cl-3"
                                        points="12.5 16.83 30 34.33 46.5 16.83 12.5 16.83"
                                      />
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </i>
                          </div>
                          <div
                            className={`choose-sel-drop ${this.state.opendrop ? "open" : "close"
                              }`}
                            tabIndex="0"
                          >
                            <ul>
                              {/* <li onClick={() => this.chooseNode('chain.wax.io')}>chain.wax.io</li> */}
                              <li
                                onClick={() => this.chooseNode("wax.pink.gg")}
                              >
                                wax.pink.gg
                              </li>
                              <li
                                onClick={() =>
                                  this.chooseNode("wax.cryptolions.io")
                                }
                              >
                                wax.cryptolions.io
                              </li>
                              <li
                                onClick={() =>
                                  this.chooseNode("wax.greymass.com")
                                }
                              >
                                wax.greymass.com
                              </li>
                              <li
                                onClick={() =>
                                  this.chooseNode("api.wax.alohaeos.com")
                                }
                              >
                                api.wax.alohaeos.com
                              </li>
                              <li
                                onClick={() =>
                                  this.chooseNode("api.waxsweden.org")
                                }
                              >
                                api.waxsweden.org
                              </li>
                              <li
                                onClick={() =>
                                  this.chooseNode("wax.eosphere.io")
                                }
                              >
                                wax.eosphere.io
                              </li>
                              <li
                                onClick={() =>
                                  this.chooseNode("api.wax.greeneosio.com")
                                }
                              >
                                api.wax.greeneosio.com
                              </li>
                              <li
                                onClick={() =>
                                  this.chooseNode("wax.eu.eosamsterdam.net")
                                }
                              >
                                wax.eu.eosamsterdam.net
                              </li>
                              <li
                                onClick={() =>
                                  this.chooseNode("api.wax.bountyblok.io")
                                }
                              >
                                api.wax.bountyblok.io
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="use-node">
                          <a
                            className="def-bt mini"
                            href={`${window.location.href}`}
                          >
                            <span className="button-in">
                              <span className="button-name">
                                <span className="button-name-in">
                                  <span className="button-name-value">use</span>
                                </span>
                              </span>
                            </span>
                          </a>
                        </div>
                      </div>

                      <div className="choose-node-wrap">
                        <div className="sub-tit-node">Atomic Node</div>
                        <div className="choose-node">
                          <div
                            className="choose-sel-act"
                            tabIndex="0"
                            onClick={() => this.openDropA("open")}
                          >
                            {this.state.activeNodaAtomic}
                            <i>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 59 49.5"
                              >
                                <g id="Слой_2" data-name="Слой 2">
                                  .
                                  <g
                                    id="Слой_3_копия_"
                                    data-name="Слой 3 (копия)"
                                  >
                                    <g id="Слой_5" data-name="Слой 5">
                                      <rect
                                        className="drop-cl-1"
                                        width="59"
                                        height="49.5"
                                        rx="5"
                                      />
                                      <rect
                                        className="drop-cl-2"
                                        x="4.67"
                                        y="3"
                                        width="49.62"
                                        height="40"
                                      />
                                      <polygon
                                        className="drop-cl-3"
                                        points="12.5 16.83 30 34.33 46.5 16.83 12.5 16.83"
                                      />
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </i>
                          </div>
                          <div
                            className={`choose-sel-drop ${this.state.opendropA ? "open" : "close"
                              }`}
                            tabIndex="0"
                          >
                            <ul>
                              <li
                                onClick={() =>
                                  this.chooseNodeAtomic(
                                    "aa-wax-public1.neftyblocks.com"
                                  )
                                }
                              >
                                aa-wax-public1.neftyblocks.com
                              </li>
                              <li
                                onClick={() =>
                                  this.chooseNodeAtomic(
                                    "wax.api.atomicassets.io"
                                  )
                                }
                              >
                                wax.api.atomicassets.io
                              </li>
                              <li
                                onClick={() =>
                                  this.chooseNodeAtomic(
                                    "api.wax-aa.bountyblok.io"
                                  )
                                }
                              >
                                api.wax-aa.bountyblok.io
                              </li>
                              <li
                                onClick={() =>
                                  this.chooseNodeAtomic("aa.dapplica.io")
                                }
                              >
                                aa.dapplica.io
                              </li>
                              <li
                                onClick={() =>
                                  this.chooseNodeAtomic(
                                    "api.atomic.greeneosio.com"
                                  )
                                }
                              >
                                api.atomic.greeneosio.com
                              </li>
                              <li
                                onClick={() =>
                                  this.chooseNodeAtomic("atomic.wax.eosrio.io")
                                }
                              >
                                atomic.wax.eosrio.io
                              </li>
                              <li
                                onClick={() =>
                                  this.chooseNodeAtomic(
                                    "wax-aa.eu.eosamsterdam.net"
                                  )
                                }
                              >
                                wax-aa.eu.eosamsterdam.net
                              </li>
                              <li
                                onClick={() =>
                                  this.chooseNodeAtomic(
                                    "atomic-wax-mainnet.wecan.dev"
                                  )
                                }
                              >
                                atomic-wax-mainnet.wecan.dev
                              </li>
                              <li
                                onClick={() =>
                                  this.chooseNodeAtomic("atomic.sentnl.io")
                                }
                              >
                                atomic.sentnl.io
                              </li>
                              <li
                                onClick={() =>
                                  this.chooseNodeAtomic("atomic.tokengamer.io")
                                }
                              >
                                atomic.tokengamer.io
                              </li>
                              <li
                                onClick={() =>
                                  this.chooseNodeAtomic("atomic.ledgerwise.io")
                                }
                              >
                                atomic.ledgerwise.io
                              </li>
                              <li
                                onClick={() =>
                                  this.chooseNodeAtomic(
                                    "api-wax-aa.eosarabia.net"
                                  )
                                }
                              >
                                api-wax-aa.eosarabia.net
                              </li>
                              <li
                                onClick={() =>
                                  this.chooseNodeAtomic("wax.hkeos.com/aa")
                                }
                              >
                                wax.hkeos.com/aa
                              </li>
                              <li
                                onClick={() =>
                                  this.chooseNodeAtomic(
                                    "wax-atomic.wizardsguild.one"
                                  )
                                }
                              >
                                wax-atomic.wizardsguild.one
                              </li>
                              <li
                                onClick={() =>
                                  this.chooseNodeAtomic(
                                    "wax-atomic.eosiomadrid.io"
                                  )
                                }
                              >
                                wax-atomic.eosiomadrid.io
                              </li>
                              <li
                                onClick={() =>
                                  this.chooseNodeAtomic("aa.wax.blacklusion.io")
                                }
                              >
                                aa.wax.blacklusion.io
                              </li>
                              <li
                                onClick={() =>
                                  this.chooseNodeAtomic(
                                    "wax-atomic-api.eosphere.io"
                                  )
                                }
                              >
                                wax-atomic-api.eosphere.io
                              </li>
                              <li
                                onClick={() =>
                                  this.chooseNodeAtomic("wax-aa.eosdublin.io")
                                }
                              >
                                wax-aa.eosdublin.io
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="use-node">
                          <a
                            className="def-bt mini"
                            href={`${window.location.href}`}
                          >
                            <span className="button-in">
                              <span className="button-name">
                                <span className="button-name-in">
                                  <span className="button-name-value">use</span>
                                </span>
                              </span>
                            </span>
                          </a>
                        </div>
                      </div>

                      <div className="animation-check">
                        <div className="animation-in">
                          <div className="music-bt-name">animation</div>
                          <button
                            id="audioBtn"
                            className={
                              this.state.animation ||
                                localStorage.getItem("animation") == "true" ||
                                localStorage.getItem("animation") == null
                                ? "active"
                                : "def"
                            }
                            onClick={this.toggleAnimation}
                          >
                            <div className="music-status">
                              <span>
                                {this.state.animation ||
                                  localStorage.getItem("animation") == "true" ||
                                  localStorage.getItem("animation") == null
                                  ? "ON"
                                  : "OFF"}
                              </span>
                            </div>
                            {/* <div className="music-circ"></div> */}
                          </button>
                        </div>
                      </div>

                      <div className="go-to-rent">
                        <Link to="/referrals" className="def-bt mini">
                          <span className="button-in">
                            <span className="button-name">
                              <span className="button-name-in">
                                <span className="button-name-value">
                                  Referrals
                                </span>
                              </span>
                            </span>
                          </span>
                        </Link>
                      </div>

                      <div className="music-nav">
                        <PlayMusic activeUser={this.props.activeUser} />
                        <PlaySound activeUser={this.props.activeUser} />
                      </div>
                      <div className="ual-btn-wrapper">
                        <Link
                          to="/"
                          className="def-bt mini"
                          onClick={this.props.logout}
                        >
                          <span className="button-in">
                            <span className="button-name">
                              <span className="button-name-in">
                                <span className="button-name-value">
                                  Log out
                                </span>
                              </span>
                            </span>
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="menu-will-be">
                  <div className="one-menu-item tooltip">
                    <NavLink
                      className="icon-bt hero"
                      to="/my-heroes"
                      onClick={this.clickMenu}
                      activeclassname="active"
                    ></NavLink>
                    <div className="cust-tooltip bottom">
                      <div className="tool-arrow"></div>
                      <div className="tool-wrap">
                        <div className="tool-wrap-1">
                          <div className="tool-wrap-2">
                            <div className="tool-body">My heroes</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="one-menu-item tooltip">
                    <NavLink
                      className="icon-bt adv"
                      to="/adventures"
                      onClick={this.clickMenu}
                      activeclassname="active"
                    ></NavLink>
                    <div className="cust-tooltip bottom">
                      <div className="tool-arrow"></div>
                      <div className="tool-wrap">
                        <div className="tool-wrap-1">
                          <div className="tool-wrap-2">
                            <div className="tool-body">Adventures</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="one-menu-item tooltip">
                    <NavLink
                      className="icon-bt skill"
                      to="/skills"
                      onClick={this.clickMenu}
                      activeclassname="active"
                    ></NavLink>
                    <div className="cust-tooltip bottom">
                      <div className="tool-arrow"></div>
                      <div className="tool-wrap">
                        <div className="tool-wrap-1">
                          <div className="tool-wrap-2">
                            <div className="tool-body">skills</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="one-menu-item tooltip">
                    <NavLink
                      className="icon-bt exchange"
                      to="/exchange"
                      onClick={this.clickMenu}
                      activeclassname="active"
                    ></NavLink>
                    <div className="cust-tooltip bottom">
                      <div className="tool-arrow"></div>
                      <div className="tool-wrap">
                        <div className="tool-wrap-1">
                          <div className="tool-wrap-2">
                            <div className="tool-body">exchange</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="one-menu-item tooltip">
                    <a
                      className="icon-bt info"
                      target="_blank"
                      href="https://nft-panda.gitbook.io/"
                      onClick={this.clickMenu}
                      activeclassname="active"
                    ></a>
                    <div className="cust-tooltip bottom">
                      <div className="tool-arrow"></div>
                      <div className="tool-wrap">
                        <div className="tool-wrap-1">
                          <div className="tool-wrap-2">
                            <div className="tool-body">Info</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="one-menu-item tooltip">
                    <NavLink
                      className="icon-bt leaderboard"
                      to="/leaderboard"
                      onClick={this.clickMenu}
                      activeclassname="active"
                    ></NavLink>
                    <div className="cust-tooltip bottom">
                      <div className="tool-arrow"></div>
                      <div className="tool-wrap">
                        <div className="tool-wrap-1">
                          <div className="tool-wrap-2">
                            <div className="tool-body">leaderboard</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="one-menu-item">
                    <NavLink
                      className="icon-bt alien-ev"
                      to="/alien-adventures"
                      onClick={this.clickMenu}
                      activeclassname="active"
                    ></NavLink>

                    <div className="drop-menu">
                      <div className="drop-menu-in">

                        <div className="one-menu-item tooltip">
                          <NavLink
                            className="icon-bt alien-adv"
                            to="/alien-adventures"
                            onClick={this.clickMenu}
                            activeclassname="active"
                          ></NavLink>
                          <div className="cust-tooltip bottom">
                            <div className="tool-arrow"></div>
                            <div className="tool-wrap">
                              <div className="tool-wrap-1">
                                <div className="tool-wrap-2">
                                  <div className="tool-body">alien adventures</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="one-menu-item tooltip">
                          <NavLink
                            className="icon-bt alien-leader"
                            to="/alien-leaderboard"
                            onClick={this.clickMenu}
                            activeclassname="active"
                          ></NavLink>
                          <div className="cust-tooltip bottom">
                            <div className="tool-arrow"></div>
                            <div className="tool-wrap">
                              <div className="tool-wrap-1">
                                <div className="tool-wrap-2">
                                  <div className="tool-body">alien leaderboard</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                  <div className="one-menu-item tooltip">
                    <NavLink
                      className="icon-bt shop"
                      to="/shop"
                      onClick={this.clickMenu}
                      activeclassname="active"
                    ></NavLink>
                    <div className="cust-tooltip bottom">
                      <div className="tool-arrow"></div>
                      <div className="tool-wrap">
                        <div className="tool-wrap-1">
                          <div className="tool-wrap-2">
                            <div className="tool-body">Shop</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="one-menu-item tooltip">
                    <NavLink
                      className="icon-bt craft"
                      to="/craft"
                      onClick={this.clickMenu}
                      activeclassname="active"
                    ></NavLink>
                    <div className="cust-tooltip bottom">
                      <div className="tool-arrow"></div>
                      <div className="tool-wrap">
                        <div className="tool-wrap-1">
                          <div className="tool-wrap-2">
                            <div className="tool-body">craft</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div className="one-menu-item tooltip">
                    <NavLink
                      className="icon-bt tavern"
                      to="/tavern"
                      onClick={this.clickMenu}
                      activeclassname="active"
                    ></NavLink>
                    <div className="cust-tooltip bottom">
                      <div className="tool-arrow"></div>
                      <div className="tool-wrap">
                        <div className="tool-wrap-1">
                          <div className="tool-wrap-2">
                            <div className="tool-body">tavern</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="one-menu-item">
                    <NavLink
                      className="icon-bt lk"
                      to="/inventory"
                      onClick={this.clickMenu}
                      activeclassname="active"
                    ></NavLink>

                    <div className="drop-menu">
                      <div className="drop-menu-in">

                        <div className="one-menu-item tooltip">
                          <NavLink
                            className="icon-bt inv"
                            to="/inventory"
                            onClick={this.clickMenu}
                            activeclassname="active"
                          ></NavLink>
                          <div className="cust-tooltip bottom">
                            <div className="tool-arrow"></div>
                            <div className="tool-wrap">
                              <div className="tool-wrap-1">
                                <div className="tool-wrap-2">
                                  <div className="tool-body">inventory</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="one-menu-item tooltip">
                          <NavLink
                            className="icon-bt artefacts"
                            to="/artifacts"
                            onClick={this.clickMenu}
                            activeclassname="active"
                          ></NavLink>
                          <div className="cust-tooltip bottom">
                            <div className="tool-arrow"></div>
                            <div className="tool-wrap">
                              <div className="tool-wrap-1">
                                <div className="tool-wrap-2">
                                  <div className="tool-body">artifacts</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="one-menu-item tooltip">
                          <NavLink
                            className="icon-bt rent"
                            to="/rent"
                            onClick={this.clickMenu}
                            activeclassname="active"
                          ></NavLink>
                          <div className="cust-tooltip bottom">
                            <div className="tool-arrow"></div>
                            <div className="tool-wrap">
                              <div className="tool-wrap-1">
                                <div className="tool-wrap-2">
                                  <div className="tool-body">rent</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="one-menu-item  tooltip">
                          <NavLink
                            className="icon-bt burn"
                            to="/burning-festival"
                            onClick={this.clickMenu}
                            activeclassname="active"
                          ></NavLink>

                          <div className="cust-tooltip bottom">
                            <div className="tool-arrow"></div>
                            <div className="tool-wrap">
                              <div className="tool-wrap-1">
                                <div className="tool-wrap-2">
                                  <div className="tool-body">burning festival</div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>



                      </div>

                    </div>
                  </div>

                </div>

                <div className="header-menu">
                  <div className="header-menu-in">
                    <div className="one-menu-item hide-desc">
                      <NavLink
                        className="def-bt min"
                        to="/my-heroes"
                        onClick={this.clickMenu}
                        activeclassname="active"
                      >
                        <span className="button-in">
                          <span className="button-name">
                            <span className="button-name-in">
                              <span className="button-name-value">
                                My heroes
                              </span>
                            </span>
                          </span>
                        </span>
                      </NavLink>
                    </div>
                    <div className="one-menu-item hide-desc">
                      <NavLink
                        className="def-bt min"
                        to="/adventures"
                        onClick={this.clickMenu}
                        activeclassname="active"
                      >
                        <span className="button-in">
                          <span className="button-name">
                            <span className="button-name-in">
                              <span className="button-name-value">
                                Adventures
                              </span>
                            </span>
                          </span>
                        </span>
                      </NavLink>
                    </div>

                    <div className="one-menu-item hide-desc">
                      <NavLink
                        className="def-bt min"
                        to="/skills"
                        onClick={this.clickMenu}
                        activeclassname="active"
                      >
                        <span className="button-in">
                          <span className="button-name">
                            <span className="button-name-in">
                              <span className="button-name-value">skills</span>
                            </span>
                          </span>
                        </span>
                      </NavLink>
                    </div>

                    <div className="one-menu-item hide-desc">
                      <NavLink
                        className="def-bt min"
                        to="/alien-adventures"
                        onClick={this.clickMenu}
                        activeclassname="active"
                      >
                        <span className="button-in">
                          <span className="button-name">
                            <span className="button-name-in">
                              <span className="button-name-value">
                                Alien Adventures
                              </span>
                            </span>
                          </span>
                        </span>
                      </NavLink>
                    </div>
                    <div className="one-menu-item hide-desc">
                      <NavLink
                        className="def-bt min"
                        to="/exchange"
                        onClick={this.clickMenu}
                        activeclassname="active"
                      >
                        <span className="button-in">
                          <span className="button-name">
                            <span className="button-name-in">
                              <span className="button-name-value">
                                exchange
                              </span>
                            </span>
                          </span>
                        </span>
                      </NavLink>
                    </div>
                    <div className="one-menu-item hide-desc">
                      <a
                        className="def-bt min"
                        target="_blank"
                        href="https://nft-panda.gitbook.io/"
                        onClick={this.clickMenu}
                        activeclassname="active"
                      >
                        <span className="button-in">
                          <span className="button-name">
                            <span className="button-name-in">
                              <span className="button-name-value">Info</span>
                            </span>
                          </span>
                        </span>
                      </a>
                    </div>
                    <div className="one-menu-item hide-desc">
                      <NavLink
                        className="def-bt min"
                        to="/leaderboard"
                        onClick={this.clickMenu}
                        activeclassname="active"
                      >
                        <span className="button-in">
                          <span className="button-name">
                            <span className="button-name-in">
                              <span className="button-name-value">
                                leaderboard
                              </span>
                            </span>
                          </span>
                        </span>
                      </NavLink>
                    </div>
                    <div className="one-menu-item hide-desc">
                      <NavLink
                        className="def-bt min"
                        to="/alien-leaderboard"
                        onClick={this.clickMenu}
                        activeclassname="active"
                      >
                        <span className="button-in">
                          <span className="button-name">
                            <span className="button-name-in">
                              <span className="button-name-value">
                                alien leaderboard
                              </span>
                            </span>
                          </span>
                        </span>
                      </NavLink>
                    </div>
                    <div className="one-menu-item hide-desc">
                      <NavLink
                        className="def-bt min"
                        to="/rent"
                        onClick={this.clickMenu}
                        activeclassname="active"
                      >
                        <span className="button-in">
                          <span className="button-name">
                            <span className="button-name-in">
                              <span className="button-name-value">rent</span>
                            </span>
                          </span>
                        </span>
                      </NavLink>
                    </div>


                    <div className="one-menu-item hide-desc">
                      <NavLink
                        className="def-bt min"
                        to="/burning-festival"
                        onClick={this.clickMenu}
                        activeclassname="active"
                      >
                        <span className="button-in">
                          <span className="button-name">
                            <span className="button-name-in">
                              <span className="button-name-value">
                                burning festival
                              </span>
                            </span>
                          </span>
                        </span>
                      </NavLink>
                    </div>

                    <div className="one-menu-item hide-desc">
                      <NavLink
                        className="def-bt min"
                        to="/shop"
                        onClick={this.clickMenu}
                        activeclassname="active"
                      >
                        <span className="button-in">
                          <span className="button-name">
                            <span className="button-name-in">
                              <span className="button-name-value">shop</span>
                            </span>
                          </span>
                        </span>
                      </NavLink>
                    </div>

                    <div className="one-menu-item hide-desc">
                      <NavLink
                        className="def-bt min"
                        to="/inventory"
                        onClick={this.clickMenu}
                        activeclassname="active"
                      >
                        <span className="button-in">
                          <span className="button-name">
                            <span className="button-name-in">
                              <span className="button-name-value">
                                inventory
                              </span>
                            </span>
                          </span>
                        </span>
                      </NavLink>
                    </div>

                    <div className="one-menu-item hide-desc">
                      <NavLink
                        className="def-bt min"
                        to="/craft"
                        onClick={this.clickMenu}
                        activeclassname="active"
                      >
                        <span className="button-in">
                          <span className="button-name">
                            <span className="button-name-in">
                              <span className="button-name-value">craft</span>
                            </span>
                          </span>
                        </span>
                      </NavLink>
                    </div>

                    <div className="one-menu-item hide-desc">
                      <NavLink
                        className="def-bt min"
                        to="/artifacts"
                        onClick={this.clickMenu}
                        activeclassname="active"
                      >
                        <span className="button-in">
                          <span className="button-name">
                            <span className="button-name-in">
                              <span className="button-name-value">
                                artifacts
                              </span>
                            </span>
                          </span>
                        </span>
                      </NavLink>
                    </div>
                    <div className="one-menu-item hide-desc">
                      <NavLink
                        className="def-bt min"
                        to="/tavern"
                        onClick={this.clickMenu}
                        activeclassname="active"
                      >
                        <span className="button-in">
                          <span className="button-name">
                            <span className="button-name-in">
                              <span className="button-name-value">tavern</span>
                            </span>
                          </span>
                        </span>
                      </NavLink>
                    </div>

                    {/* <div className="one-menu-item">
                      <NavLink className="def-bt min" to="/calc" onClick={this.clickMenu} activeclassname="active">
                        <span className="button-in">
                          <span className="button-name">
                              <span className="button-name-in">
                                  <span className="button-name-value">
                                  calc
                                  </span>
                              </span>
                          </span>
                        </span>
                      </NavLink>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="new-year">
              <div className="new-year-def">
                <picture>
                  <source srcSet={newyearwebp} type="image/webp" />
                  <source srcSet={newyear} type="image/png" />
                  <img src={newyear} alt="" />
                </picture>
                <picture>
                  <source srcSet={newyearwebp} type="image/webp" />
                  <source srcSet={newyear} type="image/png" />
                  <img src={newyear} alt="" />
                </picture>
              </div>

              <div className="new-year-hover">
                <picture>
                  <source srcSet={newyearactwebp} type="image/webp" />
                  <source srcSet={newyearact} type="image/png" />
                  <img src={newyearact} alt="" />
                </picture>
                <picture>
                  <source srcSet={newyearactwebp} type="image/webp" />
                  <source srcSet={newyearact} type="image/png" />
                  <img src={newyearact} alt="" />
                </picture>
              </div>
            </div> */}

            {/* {//Date.now() <= 1664064000000 && 
            <div className="anonce">
              <NavLink to="/leaderboard-event">
                  <picture>
                      <source srcSet={anoncew} type="image/webp" />
                      <source srcSet={anonce} type="image/png" /> 
                      <img src={anoncew} alt="" />
                  </picture>
              </NavLink>
            </div>
            } */}
          </header>
        )}
        <Wallet
          showCloseModalBal={this.showCloseModalBal}
          changeTab={this.changeTab}
          modalBal={this.state.modalBal}
          bam={this.props.bam}
          game={this.props.game}
          handleChangeInput={this.handleChangeInput}
          handleChangeInputUn={this.handleChangeInputUn}
          inputBal={this.state.inputBal}
          addBamToGame={this.addBamToGame}
          removeBamFromGame={this.removeBamFromGame}
          allSum={this.allSum}
        />
      </>
    );
  }
}

export default Menu;
