import React, { useState, useEffect, useRef } from 'react';
import PandaVideoComp from './../PandaVideoComp';
import ModalInfo from './../ModalInfo/ModalInfo';
import tit from '../../images/new-adv/skils-tit.png';
import titw from '../../images/new-adv/skils-tit.webp';


const Skills = (props) => {


    function closeModalInfo(e) {
        if (e) {
            setshowInfo(true);
            document.getElementsByTagName('body')[0].classList.add("open-modal");
        } else {
            setshowInfo(false);
            document.getElementsByTagName('body')[0].classList.remove("open-modal");
        }
    }

    const onePanda = {
        cardName: "serial-3-common",
        element: "earth",
        fullNameRare: "Common-Paz",
        pandaName: "Paz"
    }

    const onePandaName = "Paz";

    const [showInfo, setshowInfo] = useState(false);
    const [textInfo, settextInfo] = useState(``);
    const [skillStatus, setSkillStatus] = useState(false);

    var pandaRar = props.acivePandaSkills.pandaRar;
    var weapon = 0;
    var jew = 0;
    var armor = 0;
    //var skillStatus = false;

    if (props.acivePandaSkills.nameWeapon && props.acivePandaSkills.nameWeapon != undefined) {
        weapon = 1;
    }
    if (props.acivePandaSkills.oneJewTmpl && props.acivePandaSkills.oneJewTmpl != undefined) {
        jew = 1;
    }
    if (props.acivePandaSkills.nameArmor && props.acivePandaSkills.nameArmor != undefined) {
        armor = 1;
    }

    useEffect(() => {


        if (weapon != 0 && jew != 0 && armor != 0) {

            var pandaRar = props.acivePandaSkills.pandaRar;

            if (pandaRar != 0) {
                pandaRar = pandaRar - 1;
            }

            if (props.acivePandaSkills.oneWeaponRarity >= pandaRar && props.acivePandaSkills.oneJewRarity >= pandaRar && props.acivePandaSkills.oneArmorRarity >= pandaRar) {
                setSkillStatus(true);
            }

            settextInfo(`
        <h3>Set is active.</h3> 
        <br />
        Consumption of Hero energy and equipment durability has been reduced. 
        <br /><br />
        Bam adventure: <br />
        <ul>
            <li>- 4 hours: from 5% to <span class="green">4%</span></li>
            <li>- 8 hours: from 20% to <span class="green">18%</span></li>
        <li>- 12 hours: from 40% to <span class="green">36%</span></li>
        </ul>
        <br />
        Resources/Gem adventures:
        <ul>
            <li>- 6 hours: from 5% to <span class="green">4%</span></li>
            <li>- 12 hours: from 20% to <span class="green">18%</span></li>
        </ul>
        `);
        } else {
            setSkillStatus(false);
            settextInfo(`
        <h3>Set is not active.</h3> 
        <br />
        To make the set active, equip your Hero with a weapon, armor, and jewelry of the same or higher rarity than your hero's rarity is.<br />
        Active set will reduce the consumption of energy and the consumption of equipment durability of Hero.
        <br /><br />
        Bam adventure: <br />
        <ul>
            <li>- 4 hours: from 5% to <span class="green">4%</span></li>
            <li>- 8 hours: from 20% to <span class="green">18%</span></li>
        <li>- 12 hours: from 40% to <span class="green">36%</span></li>
        </ul>
        <br />
        Resources/Gem adventures:
        <ul>
            <li>- 6 hours: from 5% to <span class="green">4%</span></li>
            <li>- 12 hours: from 20% to <span class="green">18%</span></li>
        </ul>
        `)
        }
    }, [])


    return (<>
        {showInfo && <ModalInfo closeModalInfo={closeModalInfo} textInfo={textInfo} />}
        <div className={`modal-weapon open anim`}>
            <div className="modal-weapon-body">
                <div className="modal-weapon-body-in skill-body">
                    <div className="skills">
                        <button className="modal-bay-close" onClick={() => props.openCloseSkills()}><span></span></button>

                        <div className="skills-right">
                            <div className="skills-list-tit">
                                <picture>
                                    <source srcSet={titw} type="image/webp" />
                                    <source srcSet={tit} type="image/png" />
                                    <img src={titw} alt="" />
                                </picture>
                            </div>
                        </div>

                        <div className="skills-left">
                            <div className="skills-slot">
                                Slot {props.acivePandaSkills.slot + 1}
                            </div>
                            <div className="skills-name">
                                {props.acivePandaSkills.onePandaName}
                            </div>
                            <div className="skills-nft">
                                <picture>
                                    <source srcSet={`./images/card-1x/${props.acivePandaSkills.name_pa}.webp`} type="image/webp" />
                                    <source srcSet={`./images/card-1x/${props.acivePandaSkills.name_pa}.png`} type="image/png" />
                                    <img src={`./images/card-1x/${props.acivePandaSkills.name_pa}.png`} alt="" onLoad={() => this.setState({ isLoading: true })} />
                                </picture>
                            </div>
                            <div className="skills-amm">
                                <div className="skills-amm-name">
                                    Equipment
                                </div>
                                <div className="skills-amm-in">
                                    <div className="one-amm">
                                        {props.acivePandaSkills.nameWeapon && props.acivePandaSkills.nameWeapon != undefined ?
                                            <div className={`adv-weapon active ${props.acivePandaSkills.weaponElement} rar-${props.acivePandaSkills.oneWeaponRarity}`} >
                                                <div className="adv-weapon-in">
                                                    <i className="a"></i><i className="b"></i>
                                                    <div className="adv-weapon-wrap">
                                                        <picture>
                                                            <source srcSet={`./images/weapons/mini/${props.acivePandaSkills.nameWeapon}.webp`} type="image/webp" />
                                                            <source srcSet={`./images/weapons/mini/${props.acivePandaSkills.nameWeapon}.png`} type="image/png" />
                                                            <img src={`./images/weapons/mini/${props.acivePandaSkills.nameWeapon}.png`} alt="" />
                                                        </picture>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="adv-weapon">
                                                <div className="adv-weapon-in">
                                                    <div className="adv-weapon-wrap"></div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="one-amm">
                                        {props.acivePandaSkills.oneJewTmpl && props.acivePandaSkills.oneJewTmpl != undefined ?
                                            <div className={`adv-jew active ${props.acivePandaSkills.jewElement} rar-${props.acivePandaSkills.oneJewRarity}`}>
                                                <div className="adv-jew-in">
                                                    <i className="a"></i><i className="b"></i>
                                                    <div className="adv-jew-wrap">
                                                        <picture>
                                                            <source srcSet={`./images/jew/mini/${props.acivePandaSkills.oneJewTmpl}.webp`} type="image/webp" />
                                                            <source srcSet={`./images/jew/mini/${props.acivePandaSkills.oneJewTmpl}.png`} type="image/png" />
                                                            <img src={`./images/jew/mini/${props.acivePandaSkills.oneJewTmpl}.png`} alt="" />
                                                        </picture>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="adv-jew">
                                                <div className="adv-jew-in">
                                                    <div className="adv-jew-wrap"></div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="one-amm">
                                        {props.acivePandaSkills.nameArmor && props.acivePandaSkills.nameArmor != undefined ?
                                            <div className={`adv-armor active ${props.acivePandaSkills.armorElement} rar-${props.acivePandaSkills.oneArmorRarity}`}>
                                                <div className="adv-armor-in">
                                                    <i className="a"></i><i className="b"></i>
                                                    <div className="adv-armor-wrap">
                                                        <picture>
                                                            <source srcSet={`./images/armor/mini/${props.acivePandaSkills.nameArmor}-${props.acivePandaSkills.nameRarArmor}.webp`} type="image/webp" />
                                                            <source srcSet={`./images/armor/mini/${props.acivePandaSkills.nameArmor}-${props.acivePandaSkills.nameRarArmor}.png`} type="image/png" />
                                                            <img src={`./images/armor/mini/${props.acivePandaSkills.nameArmor}-${props.acivePandaSkills.nameRarArmor}.png`} alt="" />
                                                        </picture>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="adv-armor" >
                                                <div className="adv-armor-in">
                                                    <div className="adv-armor-wrap"></div>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="skills-right">
                            <div className="skills-list">

                                <div className="skills-one-list">
                                    <div className="skills-list-name">
                                        Active
                                    </div>
                                    <div className="one-skill">
                                        <div className="one-skill-thumb soon">
                                            <span></span>
                                        </div>
                                        <div className="one-skill-descr">
                                            <div className="one-skill-tit">
                                                coming soon
                                            </div>
                                            {/* <div className="one-skill-text">
                                                Lorem Ipsum Lorem Ipsum Lorem Ipsum
                                            </div> */}
                                        </div>
                                    </div>
                                </div>

                                <div className="skills-one-list">
                                    <div className="skills-list-name">
                                        Passive
                                    </div>
                                    <div className="one-skill">
                                        <div className={`one-skill-thumb set ${skillStatus && "active"}`} onClick={(e) => {
                                            closeModalInfo('open');
                                        }}>
                                            <span></span>
                                        </div>
                                        <div className="one-skill-descr">
                                            <div className="one-skill-tit">
                                                Full set <div className="show-info" onClick={(e) => {
                                                    closeModalInfo('open');
                                                }}><span></span></div>
                                            </div>
                                            <div className="one-skill-text">
                                                {skillStatus ?
                                                    "Set is active"
                                                    : "Set is not active"
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="one-skill">
                                        <div className="one-skill-thumb soon">
                                            <span></span>
                                        </div>
                                        <div className="one-skill-descr">
                                            <div className="one-skill-tit">
                                                coming soon
                                            </div>
                                            <div className="one-skill-text">
                                                Lorem Ipsum Lorem Ipsum Lorem Ipsum
                                            </div>
                                        </div>
                                    </div> */}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-weapon-bg-trans" onClick={() => props.showCloseModalJew()}></div>
            </div>

            <div className="modal-weapon-bg" ></div>
        </div>
    </>
    )
}

export default Skills;




